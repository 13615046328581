import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import router from '@/router'
import type { App } from 'vue'
import { globalConstants } from '@/constants/globals'
import { isLocalhost } from '@/utils/utils'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from './web3/usePwnWagmiConfig'
import { AxiosError } from 'axios'
import { isStarknet } from './pwnSpace/pwnSpaceDetail'

export default function useSentry() {
  // todo also optimize sentry for tree shaking? https://docs.sentry.io/platforms/javascript/configuration/tree-shaking/
  const initSentry = (app: App<Element>) => {
    // there is also sentry build config in vite.config.js

    Sentry.init({
      app,
      attachProps: true,
      attachStacktrace: true,
      beforeSend(event, hint) {
        if (isLocalhost()) {
          return null
        }
        return event
      },
      dsn: globalConstants.sentryDsn,
      // enabled: !isLocalhost(),
      environment: globalConstants.environment,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: [/api.pwn.xyz/, /api-staging.pwn.xyz/], // without beta.pwn.xyz since we do not use Sentry there
        }),
      ],
      tracesSampleRate: 1.0, // todo check dynamic sampling
      trackComponents: false, // todo enable on dev? https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/
      tunnel: `${globalConstants.backendUrl}/sentryproxy`,
      logErrors: true,
    })
    Sentry.addGlobalEventProcessor((event, hint) => {
      // todo enable or disable logs on prod?
      // TODO is this AxiosError check correct?
      if (globalConstants.environment !== 'production' && 'level' in event && !(hint.originalException instanceof AxiosError)) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        if (['fatal', 'error'].includes(event.level)) {
          // eslint-disable-next-line no-console
          console.error(hint.originalException)
        } else {
          // eslint-disable-next-line no-console
          console.log(hint.originalException)
        }
      }

      if (!isStarknet) {
        const { chainId: connectedChainId, address: userAddress } = getAccount(pwnWagmiConfig)

        event.tags ||= {}
        event.tags.connectedChainId = connectedChainId
        event.tags.connectedUserAddress = userAddress
        event.tags.appVersion = __APP_VERSION__
      }

      return event
    })
  }

  return {
    initSentry,
  }
}
