<template>
  <KyberSwapAssetDescription
    v-if="assetCategory === CustomAssetDescription.KyberSwap && !props.asset.name.toLowerCase().includes('closed')"
    :asset="props.asset"/>
</template>

<script lang="ts" setup>
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { computed } from 'vue'
import {
  CustomAssetDescription,
  getAssetCustomDescriptionKey,
} from '@/modules/pages/asset/nft-page/CustomAssetDescription/index'
import KyberSwapAssetDescription from '@/modules/pages/asset/nft-page/CustomAssetDescription/KyberSwapAssetDescription.vue'

type Props = {
  asset: AssetMetadata;
};

const props = defineProps<Props>()

const assetCategory = computed(() => {
  return getAssetCustomDescriptionKey(props.asset, props.asset.chainId)
})
</script>
