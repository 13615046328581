import { useLocalStorage } from '@vueuse/core'

export enum CollateralTypeFilter {
  All = 'all',
  Tokens = 'erc20',
  NFTs = 'nft',
}

export const useCollateralTypeFilter = () => {
  const COLLATERAL_TYPE_LOCAL_STORAGE_KEY = 'collateral-type'

  const persistentCollateralType = useLocalStorage<CollateralTypeFilter>(COLLATERAL_TYPE_LOCAL_STORAGE_KEY, CollateralTypeFilter.All)

  const updateCollateralType = (collateralType: CollateralTypeFilter) => {
    persistentCollateralType.value = collateralType
  }

  return {
    collateralType: persistentCollateralType,
    actions: {
      handleUpdateCollateralType: updateCollateralType,
    },
  }
}
