import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import {
  pwnV1_2RevokedNonceAbi,
  readPwnV1_2SimpleLoanFungibleProposalEncodeProposalData,
  readPwnV1_2SimpleLoanGetLenderSpecHash,
} from '@/contracts/generated'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { getStarknetLenderSpecHash, readStarknetFungibleProposalEncodeData, revokeStarknetNonces } from '@/revamp/hooks/thesis/starknet/proposals'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import { SupportedChain } from '@/constants/chains/types'
import type { V1_2SimpleLoanFungibleProposalStruct } from '@/contracts/structs'

export const getLenderSpecHash = async (
  proposal: BaseProposal,
) => {
  if (isStarknet) {
    return getStarknetLenderSpecHash(proposal)
  }

  return readPwnV1_2SimpleLoanGetLenderSpecHash(pwnWagmiConfig, {
    address: proposal.loanContractAddress,
    chainId: proposal.chainId,
    args: [{ sourceOfFunds: proposal.sourceOfFunds ? proposal.sourceOfFunds : proposal.proposer }],
  })
}

export const revokeNonces = async (
  noncesToRevoke: Partial<Record<SupportedChain, bigint[]>>,
) => {
  if (isStarknet) {
    if (!noncesToRevoke[SupportedChain.StarknetSepolia]) {
      throw new Error('No nonces to revoke on Starknet')
    }
    return revokeStarknetNonces(noncesToRevoke[SupportedChain.StarknetSepolia])
  }

  for (const chainId of Object.keys(noncesToRevoke)) {
    const revokeContractAddress = CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnRevokedNonce

    const reciept = await sendTransaction(
      {
        address: revokeContractAddress,
        abi: pwnV1_2RevokedNonceAbi,
        functionName: 'revokeNonces',
        args: [noncesToRevoke[chainId]],
        chainId: Number(chainId),
      },
    )

    if (reciept.status === 'success') {
      return
    }
  }
}

export const readPWNFungibleProposalEncodeData = async (
  proposal: BaseProposal,
  proposalStruct: V1_2SimpleLoanFungibleProposalStruct,
  proposalValues: {
    collateralAmount: bigint
  },
) => {
  if (isStarknet) {
    return await readStarknetFungibleProposalEncodeData(proposal, proposalStruct, proposalValues)
  }

  return readPwnV1_2SimpleLoanFungibleProposalEncodeProposalData(pwnWagmiConfig, {
    address: proposal.proposalContractAddress,
    chainId: proposal.chainId,
    args: [proposalStruct, proposalValues],
  })
}
