<template>
  <form
    @submit.prevent.stop="form.handleSubmit">
    <div
      ref="tableContainerRef"
      class="container"
      :style="{
        height: !props.isSelectable ? '400px' : '250px',
        overflow: 'auto',
        position: 'relative',
      }">
      <table class="user-tokens-with-amount-table">
        <thead class="user-tokens-with-amount-table__head">
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
            class="user-tokens-with-amount-table__head-row">
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              :colSpan="header.colSpan"
              :style="{
                width: `${header.column.getSize()}px`,
              }">
              <FlexRender
                v-if="!header.isPlaceholder"
                :render="header.column.columnDef.header"
                :props="header.getContext()"/>
            </th>
          </tr>
        </thead>
        <form.Field name="assets">
          <template #default>
            <tbody
              class="user-tokens-with-amount-table__body"
              style="display: grid; height: 100%; position: relative;"
              :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
              <tr
                v-for="row in virtualRows"
                :key="rows[row.index].id"
                :ref="customRefHandler"
                class="user-tokens-with-amount-table__row"
                :class="{
                  'user-tokens-with-amount-table__row--disabled': rows[row.index].original.amount === '0',
                }"
                :data-index="row.index"
                :style="{
                  display: 'flex',
                  position: 'absolute',
                  transform: `translateY(${row.start}px)`,
                  width: '100%',
                  justifyContent: !props.isSelectable ? 'space-between' : 'flex-start',
                }">
                <td v-if="props.isSelectable">
                  <BaseCheckbox
                    :model-value="rows[row.index].getIsSelected()"
                    @update:model-value="(value) => handleUpdateCheckbox(value, row.index)"/>
                </td>
                <td
                  v-for="cell in rows[row.index].getVisibleCells()"
                  :key="cell.id"
                  :style="{
                    width: `${cell.column.getSize()}px`,
                    marginLeft: cell.id.endsWith('_own') && props.isSelectable ? 'auto' : '0',
                  }">
                  <FlexRender
                    :render="cell.column.columnDef.cell"
                    :props="cell.getContext()"/>
                </td>
              </tr>
              <BaseSkeletor v-if="props.isLoading"/>
            </tbody>
          </template>
        </form.Field>
      </table>
    </div>

    <div class="user-tokens-with-amount-table__footer">
      <div
        v-if="!props.isSelectable"
        class="user-tokens-with-amount-table__amount-summary">
        <div
          class="user-tokens-with-amount-table__amount-total">
          Total: {{ totalCommitedAmount.formatted }}
        </div>

        <RewardsForThesis
          :assets-for-rewards="rewardsAssets"
          :apr="props.apr"
          is-with-text
          :credit-amount="String(totalCommitedAmount.raw)"/>
      </div>
      <div
        v-else
        class="user-tokens-with-amount-table__back-button">
        <BaseButton
          button-text="Go Back"
          :color="ButtonColor.White"
          :size="ButtonSize.S"
          :font="ButtonFont.Supreme"
          :variant="ButtonVariant.Underline"
          @click.stop="handleGoBack"/>
      </div>

      <form.Subscribe>
        <template #default="{ canSubmit }">
          <BaseButton
            :is-disabled="!canSubmit || formIsSubmitting || (userAssetsWithBalance.length === 0 && Object.keys(assetsBalances || {}).length === 0)"
            is-type-submit
            button-text="Confirm"
            class="user-tokens-with-amount-table__submit"/>
        </template>
      </form.Subscribe>
    </div>

    <div
      v-if="errorText"
      class="user-tokens-with-amount-table__error">
      {{ errorText }}
    </div>
  </form>
</template>

<script lang="ts" setup>
import { FlexRender, getCoreRowModel, useVueTable } from '@tanstack/vue-table'
import type { RowSelectionState } from '@tanstack/vue-table'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, h, ref, unref, watchEffect } from 'vue'
import { isMobile } from '@/utils/mediaQueries'
import AssetAmountWithAppraisal from '@/revamp/components/tables/cells/assets/AssetAmountWithAppraisal.vue'
import { useVirtualizer } from '@tanstack/vue-virtual'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import SelectAmount from '@/revamp/components/modals/select-your-collateral/SelectAmount.vue'
import { useForm } from '@tanstack/vue-form'
import { useAssetListPrice } from '@/revamp/hooks/asset-prices/useAssetListPrice'
import { toRefs } from '@vueuse/core'
import { compareAddresses, compareAssets } from '@/utils/utils'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import BaseButton, { ButtonColor, ButtonVariant, ButtonFont, ButtonSize } from '@/general-components/BaseButton.vue'
import RewardsForThesis from '@/revamp/features/points/RewardsForThesis.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useAssetBalances } from '@/revamp/hooks/thesis/useAssetBalances'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import AssetInfoCellWithProtocol from '@/revamp/components/tables/cells/assets/AssetInfoCellWithProtocol.vue'

const selectedRows = ref<RowSelectionState>({})

type Props = {
  userTokens: AssetWithAmount[];
  collateralAssets?: AssetWithAmount[];
  // IF THIS THING IS TYPED THE BUILD HANGS AND YOU'LL PROBABLY NEVER KNOW WHY
  tokensToDisplay: any[];
  isLoading?: boolean;
  formIsSubmitting?: boolean;
  handleSubmit: (v: AssetWithAmount[]) => void;

  errorText?: string;
  isSelectable?: boolean;
  goBack?: VoidFunction

  apr?: string;
};

const props = withDefaults(defineProps<Props>(), {
  collateralAssets: () => [],
})
const { userTokens } = toRefs(props)
const tableContainerRef = ref(null)

const { address: userAddress } = useCustomAccount()

const assetsBalances = useAssetBalances(props.tokensToDisplay, userAddress)

const tokensToDisplayWithUserBalance = computed(() => {
  return props.tokensToDisplay.map((v) => {
    if (v.protocol) {
      const aToken = userTokens.value.find((token) => {
        return compareAddresses(token.address, v.aTokenAddress)
      })
      return v?.updateAssetAmounts({
        amount: aToken?.amount || '0',
      })
    }
    let token: AssetWithAmount | undefined

    if (!isStarknet) {
      token = userTokens.value.find((token) => {
        return compareAssets({
          assetA: v,
          assetB: token,
        })
      })
    } else {
      token = unref(assetsBalances)?.[v.address]
    }

    return v?.updateAssetAmounts({
      amount: token?.amount || '0',
    })
  })
})

watchEffect(() => {
  const selected = {}
  props.tokensToDisplay.forEach((v) => {
    selected[v.id] = true
  })

  selectedRows.value = selected
})

const userAssetsWithBalance = computed(() => {
  return props.userTokens.map((v) => {
    const token = props.userTokens.find((token) => {
      return compareAssets({
        assetA: v,
        assetB: token,
      })
    })

    return token
  }).filter((v) => v && v.amount !== '0')
})

const form = useForm({
  get defaultValues() {
    return {
      assets: tokensToDisplayWithUserBalance.value,
    }
  },
  onSubmit: (params) => {
    if (props.isSelectable) {
      const selectedAssets = params.value.assets.filter((v) => selectedRows.value[v.id])

      props.handleSubmit(selectedAssets)
    } else {
      props.handleSubmit(params.value.assets)
    }
  },
})

const formState = form.useStore(v => v.values)

const columns = computed(() => {
  const data = [
    {
      header: `Assets (${props.tokensToDisplay.length})`,
      id: 'name',
      cell: (ctx) => {
        return h(AssetInfoCellWithProtocol, {
          asset: ctx.row.original,
        })
      },
      size: isMobile.value ? 120 : 540,
    },
    {
      header: 'You Own',
      id: 'own',
      accessorFn: (row) => row.amount,
      cell: (ctx) => {
        return h(AssetAmountWithAppraisal, {
          asset: ctx.row.original,
          amount: ctx.row.original.amount,
          class: 'user-tokens-with-amount-table__amount',
        })
      },
      size: 100,
    },
  ]

  if (!props.isSelectable) {
    data.push({
      header: 'Select Amount',
      accessorFn: (row) => {
        return row.amount
      },
      id: 'amount',
      cell: (ctx) => {
        return h(SelectAmount, {
          formKey: ctx.row.index,
          name: `assets[${ctx.row.index}].amountInput`,
          asset: ctx.row.original,
          form,
        })
      },
      size: 175,
    })
  }
  return data
})

const table = useVueTable({
  get data() {
    return tokensToDisplayWithUserBalance.value
  },
  state: {
    get rowSelection() {
      return selectedRows.value
    },
  },
  getRowId: (row) => String(row.id),
  get columns() {
    return columns.value
  },
  enableRowSelection(row) {
    return props.isSelectable
  },
  enableMultiRowSelection: (row) => {
    return props.isSelectable
  },
  getCoreRowModel: getCoreRowModel(),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rows.value.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 42,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

const formAssets = computed(() => formState.value.assets)

const nonEmptyAssets = computed(() => formAssets.value.filter((v) => v.amountInput !== '0' && v.amountInput !== ''))

const { summarizedAmount: totalCommitedAmount } = useAssetListPrice(nonEmptyAssets)

const handleUpdateCheckbox = (value: boolean, index: number) => {
  const row = rows.value[index]
  selectedRows.value = {
    ...selectedRows.value,
    [row.id]: value,
  }

  row.toggleSelected(value)
}

const handleGoBack = () => {
  props.goBack?.()
}

const rewardsAssets = computed(() => {
  return formAssets.value.filter((v) => v.amountInput !== '0' && v.amountInput !== '').map((v) => {
    return {
      chainId: v.chainId,
      address: v.address,
      type: 'credit',
    }
  })
})
</script>

<style scoped>
.container {
  width: 100%;
  min-width: 930px;

  @media only screen and (--small-viewport) {
    min-width: 100%;
  }
}

th {
  font-weight: 400;
  font-family: var(--font-family-supreme);

  &:first-child {
    text-align: left;
  }

  &:nth-child(2) {
    text-align: right;
  }

  &:nth-child(3) {
    text-align: left;
  }
}

td {
  display: flex;
  align-items: center;
}

.user-tokens-with-amount-table {
  display: grid;

  padding-right: 1rem;

  &__head {
    position: sticky;
    top: 0;
    z-index: 5;

    display: flex;

    background: var(--gray-3);
    padding: 0 0.5rem;
  }

  &__head-row {
    width: 100%;
    padding: 0.25rem;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family-screener);
  }

  &__row {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;

    padding: 0.5rem 1rem;
    will-change: background-color;
    transition: background-color 0.3s linear;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__amount {
    margin-left: auto;
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }

  &__amount-total {
    font-family: var(--font-family-supreme);
    font-size: 1rem;
    font-weight: 400;
    margin-right: 1.5rem;
  }

  &__footer {
    padding-top: 2rem;
    border-top: 1px solid var(--gray-2);

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  &__summary {
    display: flex;
    flex-flow: column nowrap;
  }

  &__error {
    color: var(--warning-1);
    font-family: var(--font-family-supreme);
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;

    text-align: right;
  }

  &__back-button {
    margin-right: auto;
  }
}

.user-tokens-table--fade,
.user-tokens-table--fade-move {
  transition: opacity 0.5s;

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave-active {
    position: absolute;
  }
}
</style>
