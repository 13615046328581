<template>
  <div
    class="matcher-box"
    :class="[`matcher-box--${props.type}`]">
    <div :class="['matcher-box__header', `matcher-box__header--${props.type}`]">
      <span v-if="props.type === 'lending'">
        Earn <BaseSkeletor
          v-if="isLoading"
          width="8"
          height="10"/>{{ props.apr }} on any stablecoin
      </span>
      <span v-else>
        Borrow against <BaseSkeletor
          v-if="isLoading"
          width="8"
          height="10"/>{{ props.collateralSymbol }} (No Liquidation)
      </span>
    </div>

    <div class="matcher-box__widgets">
      <slot name="widgets"/>
    </div>

    <div class="matcher-box__footer">
      <slot/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'

type Props = {
  apr: string
  isLoading: boolean
  type: 'lending' | 'borrowing'
  collateralSymbol?: string | null
}

const props = defineProps<Props>()

</script>

<style scoped>
.matcher-box {
  padding: 1rem 1rem 1.25rem;

  border: 1px solid var(--border-border-primary, #434343);
  background: var(--gray-950, #111);

  &--lending {
    box-shadow: 0 4px 24px 0 #022431;
  }

  &--borrowing {
    box-shadow: 0 4px 24px 0 #0a331f;
  }

  &__header {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.25rem;
    font-family: var(--font-family-screener);
    color: var(--black-input);

    text-transform: uppercase;
    white-space: nowrap;

    padding: 0.5rem 1rem;

    &--lending {
      background-color: #10bfff;
    }

    &--borrowing {
      background-color: #27d883;
    }
  }

  &__widgets {
    display: flex;
    flex-direction: row nowrap;
    gap: 1.5rem;

    margin-top: 1.5rem;
  }

  &__footer {
    margin-top: 2.5rem;
  }
}
</style>
