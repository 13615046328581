<template>
  <BaseButton
    :color="ButtonColor.White"
    :button-text="buttonText"
    :variant="ButtonVariant.Outline"
    :size="buttonSize"
    :font="font"
    :is-disabled="isButtonDisabled"
    @on-button-click="handleClickOnCreateLoanOffer">
    <template #icon>
      <CaretFilledIcon
        width="15"
        class="button-scroll-to-bottom__triangle-icon"
        alt="caret triangle"/>
    </template>
  </BaseButton>
</template>

<script setup lang="ts">
import BaseButton, { ButtonSize, ButtonColor, ButtonVariant, ButtonFont } from '@/general-components/BaseButton.vue'
import { isMobile } from '@/utils/mediaQueries'
import { onMounted, onBeforeUnmount, ref, computed } from 'vue'
import CaretFilledIcon from '@/assets/icons/caret-filled.svg'

const isScrollToMakeOfferButtonDisabled = ref(false)

interface Props {
  buttonText: string
  isDisabled?: boolean
  buttonSize?: ButtonSize
  font?: ButtonFont
}
const props = withDefaults(defineProps<Props>(), {
  buttonSize: ButtonSize.L,
  font: ButtonFont.OxygenMono,
})

const emit = defineEmits<{(e: 'on-scroll', isScrolling: boolean): void }>()

const checkScroll = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop
  const scrollHeight = document.documentElement.scrollHeight
  const clientHeight = document.documentElement.clientHeight

  const distanceFromBottom = scrollHeight - (scrollTop + clientHeight)

  if (distanceFromBottom > 200) {
    isScrollToMakeOfferButtonDisabled.value = false
  } else {
    isScrollToMakeOfferButtonDisabled.value = true
  }
}

const isButtonDisabled = computed(() => {
  if (!props.isDisabled) {
    return false
  }
  return isScrollToMakeOfferButtonDisabled.value || props.isDisabled
})

onMounted(() => {
  window.addEventListener('scroll', checkScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', checkScroll)
})

const handleClickOnCreateLoanOffer = () => {
  window.document.body.style.overflowX = 'hidden'
  const PADDING_FOR_MOBILE = 1350
  if (isMobile.value) {
    window.scrollTo(0, document.body.scrollHeight - PADDING_FOR_MOBILE)
  } else {
    window.scrollTo(0, document.body.scrollHeight)
  }

  emit('on-scroll', true)
  setTimeout(() => {
    emit('on-scroll', false)
    window.document.body.style.overflowX = 'auto'
    // time during the overflowX keeping hidden value, due issue with appearing of scrollbar in some browsers. 3 seconds are based on token-page-make-offer-form__highlight-animation
  }, 3000)
}

</script>

<style scoped>
.button-scroll-to-bottom__triangle-icon {
  margin-left: 0.5rem;
}

</style>
