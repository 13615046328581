import { getAddress, isAddress } from 'viem'
import type { Address } from 'viem'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { validateAndParseAddress } from 'starknet'

export const parseAddress = (address: string): Address => {
  if (isStarknet) {
    return validateAndParseAddress(address) as Address
  }
  // prevents throwing possible exception inside getAddress
  return isAddress(address) ? getAddress(address) : address as Address
}
