<template>
  <FormField
    :key="formKey"
    :validators="{
      onChange: ({ value }) => {
        if (parseFloat(value) > parseFloat(asset.amount)) {
          return 'Amount exceeds your balance'
        }
      }
    }"
    :name="name">
    <template #default="{field: subField}">
      <div
        class="select-amount__amount-and-appraisal"
        :class="{
          'select-amount__amount-and-appraisal--error': subField.state.meta.errors.length
        }">
        <input
          :id="subField.name"
          v-maska="maskTokenAmount(asset?.decimals)"
          class="select-amount__amount"
          autocomplete="off"
          type="text"
          inputmode="decimal"
          step="any"
          :name="name"
          placeholder="0"
          :value="subField.state.value"
          @blur="subField.handleBlur"
          @keydown="handleSafeKeydownWithMaxAmount"
          @input="(e) => handleChange(e, subField)">

        <AssetAppraisal
          v-if="assetMetadata"
          class="select-amount__appraisal"
          :asset-amount="subField.state.value"
          :asset-metadata="assetMetadata"/>

        <span
          v-if="asset.isFungible"
          class="select-amount__max-amount"
          @click="() => handleMaxAmountClick(subField)">
          MAX
        </span>
      </div>
    </template>
  </FormField>
</template>

<script setup lang="ts">

import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed } from 'vue'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
import { maskTokenAmount } from '@/revamp/utils/inputs'
import { formatDecimalPoint } from '@/utils/utils'

interface Props {
  name: string
  asset: AssetWithAmount
  form: any
  formKey: number
}

const props = defineProps<Props>()
const FormField = props.form.Field
const handleChange = (e: Event, subField) => {
  if (!props.asset.isErc20 && /^\d*$/.test((e.target as HTMLInputElement).value)) {
    subField.handleChange((e.target as HTMLInputElement).value)
  } else if (props.asset.isErc20) {
    subField.handleChange((e.target as HTMLInputElement).value)
  }
}

const handleMaxAmountClick = (subField) => {
  subField.handleChange(formatDecimalPoint(props.asset.amount).replace(',', ''))
}

const handleSafeKeydownWithMaxAmount = (e: KeyboardEvent) => {
  if (e.key !== 'Backspace') {
    const v = (e.target as HTMLInputElement).value

    if (Number(v) > Number(props.asset.amount)) {
      e.preventDefault()
    }
  }
}

const assetMetadata = computed(() => {
  if (!props.asset) return null

  return {
    chainId: props.asset.chainId,
    address: props.asset.address,
    tokenId: props.asset.tokenId ? String(props.asset?.tokenId) : null,
  }
})

</script>

<style scoped>
.select-amount {
  &__amount-and-appraisal {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.35rem;
    border: 1px solid var(--gray, #696678);
    background: var(--input-color, #080808);
    width: 9.5rem;
    padding: 0.5rem;
    height: 3rem;

    position: relative;

    &--error {
      border-color: var(--warning-1);
    }
  }

  &__max-amount {
    position: absolute;

    right: 0.875rem;
    top: 0.875rem;

    font-size: 0.75rem;
    font-family: var(--font-family-screener);
    color: var(--primary-color-1);
    background-color: #0d4d45;

    padding: 0.125rem;

    cursor: pointer;
    user-select: none;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
    z-index: 10;
  }

  &__amount {
    all: unset;
    flex: 1;
    color: var(--text-color);
    background: var(--input-color, #080808);
  }

  &__appraisal {
    color: var(--separation-grey, #828282);
    font-size: 0.75rem;
    right: 1rem;
    top: 1rem;

    text-align: left;
  }
}
</style>
