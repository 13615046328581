import type { MaybeRef } from 'vue'
import { allProposalHashesForRoot } from '@/modules/common/backend/generated'
import type { AmountInEthAndUsdBackendSchema, ThesisCreditStatsSchemaWorkaroundBackendSchema, ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { SimpleMerkleTree } from '@openzeppelin/merkle-tree'
import { unref } from 'vue'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { Address } from 'viem'
import { parseAddress } from '@/utils/address-utils'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { merkle } from 'starknet'
import { getCollateralOrCreditOrLtv } from '@/revamp/components/proposal-form/proposalFormCalculations'

export const usePersistentThesisMerkleTree = (
  merkleHash: MaybeRef<string>,
) => {
  const getEvmTree = async () => {
    const leafs = await allProposalHashesForRoot(unref(merkleHash))
    return SimpleMerkleTree.of(leafs.data.proposal_hashes)
  }

  const getStarknetTree = async () => {
    const leafs = await allProposalHashesForRoot(unref(merkleHash))
    return new merkle.MerkleTree(leafs.data.proposal_hashes)
  }

  const getInclusionProof = async (
    proposalHash: MaybeRef<string>,
  ) => {
    if (isStarknet) {
      const tree = await getStarknetTree()

      for (const v of tree.leaves) {
        if (v === proposalHash) {
          return tree.getProof(v) as `0x${string}`[]
        }
      }
    }

    const tree = await getEvmTree()
    for (const [i, v] of tree.entries()) {
      if (v === proposalHash) {
        return tree.getProof(i) as `0x${string}`[]
      }
    }
  }

  return {
    getTree: getEvmTree,
    getInclusionProof,
  }
}

export const parseThesisAsset = (asset: any): AssetWithAmount => {
  return new AssetWithAmount({
    ...asset,
    id: Number(asset.id),
    address: asset.address as Address,
    name: asset.name || '',
    category: asset.category,
    chainId: asset.chainId,
    amount: '',
    symbol: asset.symbol || '',
    decimals: asset.decimals || 0,
    isVerified: asset.isVerified || false,
    tokenId: asset.tokenId ? BigInt(asset.tokenId) : undefined,
    image: asset.thumbnailUrl,
    isKycRequired: asset.isKycRequired,
  })
}

export type ThesisCreditStatsData = Record<Address, ThesisCreditStatsSchemaWorkaroundBackendSchema>

export const processThesisCreditStats = (statsData: ThesisCreditStatsSchemaWorkaroundBackendSchema[] | undefined): ThesisCreditStatsData => {
  const result: ThesisCreditStatsData = {}

  if (statsData) {
    for (const data of statsData) {
      const uniqueKey = `${data.creditAssetMetadata.chainId}-${parseAddress(data.creditAssetMetadata.address)}`

      result[uniqueKey] = data
    }
  }

  return result
}

export const calculateCollateralAmountByLtv = (
  creditAsset: AssetWithAmount,
  collateralAsset: AssetWithAmount,
  ltv: number,
  assetPrices?: Partial<Record<`${number}-${string}`, AmountInEthAndUsdBackendSchema>>): string => {
  const collateralPrice = assetPrices?.[`${collateralAsset.chainId}-${parseAddress(collateralAsset.address)}`]
  const creditPrice = assetPrices?.[`${creditAsset.chainId}-${parseAddress(creditAsset.address)}`]

  if (!collateralPrice || !creditPrice || !collateralPrice.usd_amount || !creditPrice.usd_amount) {
    if (!collateralPrice) {
      throw new Error(`Couldn't find asset prices for ${collateralAsset.symbol}. Please remove asset without valuation`)
    }
    if (!creditPrice) {
      throw new Error(`Couldn't find asset prices for ${creditAsset.symbol}. Please remove asset without valuation`)
    }
    throw new Error('Couldn\'t find asset price. Please remove asset without valuation')
  }

  const collateralAmount = getCollateralOrCreditOrLtv({
    whatToReturn: 'collateralAmount',
    collateralAmount: '',
    collateralUnitPrice: collateralPrice.usd_amount,
    creditAmount: creditAsset.amount,
    creditUnitPrice: creditPrice.usd_amount,
    ltv,
  })

  return String(collateralAmount)
}

export const getThesisCollateralAssetsLtv = (thesis: ThesisSchemaWorkaroundBackendSchema) => {
  const ltv = [...new Set(thesis.collateralAssets?.map(v => Number(v.ltv)) || [])]

  if (ltv.length === 1) {
    return `${ltv[0]}%`
  }

  return `${Math.min(...ltv)}% - ${Math.max(...ltv)}%`
}

export const getThesisCollateralAssetsApr = (thesis: ThesisSchemaWorkaroundBackendSchema) => {
  const apr = [...new Set(thesis.collateralAssets?.map(v => v.apr ? Number(v.apr) : thesis.aprMin) || [])]

  if (apr.length === 1) {
    return `${apr[0]}%`
  }

  return `${Math.min(...apr)}% - ${Math.max(...apr)}%`
}
