<template>
  <RevampBaseModal
    v-if="props.isVisible"
    class-name="thesis-select-commited-asset"
    is-open
    heading-align="left"
    heading="Select Assets to Commit"
    @is-closed="onClose">
    <template #body>
      <div class="thesis-select-commited-asset__body">
        <div class="thesis-select-commited-asset__filters-row">
          <ChainGroupFilter
            :disabled-chains="disabledChains"
            :selected-chains="availableChains.length === 1 ? [availableChains[0]] : selectedChains"
            :show-all-networks="(availableChains.length - disabledChains.length) >= 1"
            :set-chain="handleSetSelectedChain"/>

          <div class="thesis-select-commited-asset__currency-switcher-wrap">
            <CurrencySwitcher class="select-their-collateral__currency-switcher"/>
          </div>

          <div class="thesis-select-commited-asset__refresh">
            <RefreshButton
              :handle-refresh="reload"
              class="thesis-select-commited-asset__refresh-button"
              :is-loading="tokensAreLoading"/>
          </div>
        </div>
        <div class="thesis-select-commited-asset__content">
          <KeepAlive>
            <UserTokensWithAmountTable
              :error-text="errorText"
              :user-tokens="tokens"
              :collateral-assets="collateralAssets"
              :tokens-to-display="tokensToDisplay"
              :form-is-submitting="props.isSubmitting"
              :is-loading="tokensAreLoading"
              :apr="`${props.thesis.aprMin}%`"
              :handle-submit="onConfirm"/>
          </KeepAlive>
        </div>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script lang="ts" setup>
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useUserTokens } from '@/revamp/hooks/useUserTokens'
import { computed, ref, unref } from 'vue'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import type { SupportedChain } from '@/constants/chains/types'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import CurrencySwitcher from '@/layout/header/CurrencySwitcher.vue'
import RefreshButton from '@/modules/common/assets/components/RefreshButton.vue'
import UserTokensWithAmountTable
  from '@/revamp/components/modals/thesis-select-commited-asset/UserTokensWithAmountTable.vue'
import type { ThesisCreditStatsSchemaWorkaroundBackendSchema, ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { parseThesisAsset } from '@/revamp/hooks/thesis/utils'
import { formatUnits } from 'viem'
import type { Address } from 'viem'
import { parseAddress } from '@/utils/address-utils'

const props = defineProps<{
  thesis: ThesisSchemaWorkaroundBackendSchema;
  selectedAssets: AssetWithAmount[];
  statsData?: Record<Address, ThesisCreditStatsSchemaWorkaroundBackendSchema>

  errorText?: string;

  onConfirm:(asset: AssetWithAmount[]) => void;
  onClose: () => void;
  isVisible: boolean;
  isSubmitting?: boolean
}>()

const collateralAssets = computed(() => props.thesis?.collateralAssets?.map(parseThesisAsset) || [])
const availableChains = computed(() => [...new Set(collateralAssets.value.map(asset => asset.chainId))] || [])

const selectedChains = ref<'all' | SupportedChain[]>('all')

const disabledChains = computed(() => {
  const assetChains = availableChains.value
  const disabled: SupportedChain[] = []

  for (const supportedChain of enabledChains) {
    if (!assetChains.includes(supportedChain)) {
      disabled.push(supportedChain)
    }
  }

  return disabled
})

const handleSetSelectedChain = (chain: SetChainFilterOption) => {
  if (chain === 'all') {
    selectedChains.value = 'all'
  } else {
    const currentSelectedChains = selectedChains.value
    if (currentSelectedChains === 'all') {
      selectedChains.value = [chain]
    } else {
      const currentSelectedChainsArray = Array.from(currentSelectedChains ?? [])
      const chainIndex = currentSelectedChainsArray.indexOf(chain)
      if (chainIndex !== -1) {
        currentSelectedChainsArray.splice(chainIndex, 1)
      } else {
        currentSelectedChainsArray.push(chain)
      }
      selectedChains.value = currentSelectedChainsArray as SupportedChain[] | 'all'
    }
  }
}

const { address } = useCustomAccount()

const { tokens, reload, isLoading: tokensAreLoading } = useUserTokens(address, availableChains)

const tokensToDisplay = computed(() => {
  const result: AssetWithAmount[] = []

  const assets = unref(props.selectedAssets)

  for (const asset of assets) {
    const alreadyCommitedAmount: ThesisCreditStatsSchemaWorkaroundBackendSchema | undefined = asset.aTokenAddress ? props.statsData?.[`${asset.chainId}-${parseAddress(asset.aTokenAddress)}`] : props.statsData?.[`${asset.chainId}-${parseAddress(asset.address)}`]
    if (alreadyCommitedAmount) {
      const amount = (BigInt(alreadyCommitedAmount.amountsStats.totalCommittedAmount) - BigInt(alreadyCommitedAmount.amountsStats.totalUtilizedAmount))
      asset.amountInput = formatUnits(amount, asset.decimals)
    }

    result.push(asset)
  }

  return result.filter(asset => selectedChains.value === 'all' || selectedChains.value.includes(asset.chainId))
})

</script>

<style lang="postcss" scoped>
.thesis-select-commited-asset {
  &__body {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 1.5rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  &__filters-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
  }
}
</style>

<style lang="postcss">
.thesis-select-commited-asset__currency-switcher-wrap {
  .multiselect__tags {
    padding: 0.5rem;
  }

  .multiselect__single {
    margin-bottom: 0;
  }

  &__tab {
    .button__text {
      font-size: 1rem;
    }

    &:hover {
      .button__text {
        color: var(--primary-color-1);
      }

      svg * {
        fill: var(--primary-color-1);
      }
    }
  }
}

.thesis-select-commited-asset__body {
  .multiselect__single {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
