import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

export default function ltv(collateral: AssetWithAmount, loanAsset: AssetWithAmount): number | undefined {
  const collateralAmount = collateral?.amount
  const collateralEthPrice = collateral?.appraisal?.price?.ethAmount || collateral?.appraisalFullAmount?.ethAmount
  const assetToBorrowAmount = loanAsset?.amount
  const assetToBorrowEthPrice = loanAsset?.appraisal?.price?.ethAmount || loanAsset?.appraisalFullAmount?.ethAmount

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const collateralAppraisal = collateral?.appraisal?.price?.ethAmount && collateralEthPrice ? +collateralAmount * +collateralEthPrice : +collateral?.appraisalFullAmount?.ethAmount
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const assetToBorrowAppraisal = loanAsset?.appraisal?.price?.ethAmount && assetToBorrowEthPrice ? +assetToBorrowAmount * +assetToBorrowEthPrice : +loanAsset?.appraisalFullAmount?.ethAmount

  if (!assetToBorrowAppraisal || !collateralAppraisal) {
    return undefined
  }

  return +(assetToBorrowAppraisal / collateralAppraisal * 100).toFixed(0)
}
