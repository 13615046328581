/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `error` - Error
* `danger` - Warning
* `success` - Success
* `info` - Info
* `pending` - Pending
 */
export type NotificationTypeEnumBackendSchema = typeof NotificationTypeEnumBackendSchema[keyof typeof NotificationTypeEnumBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationTypeEnumBackendSchema = {
  error: 'error',
  danger: 'danger',
  success: 'success',
  info: 'info',
  pending: 'pending',
} as const;
