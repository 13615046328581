import type { UseAccountParameters, UseAccountReturnType } from '@wagmi/vue'
import { useAccount as wagmiUseAccount } from '@wagmi/vue'
import type { Config, ResolvedRegister } from '@wagmi/core'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import type { StarknetWindowObject } from '@starknet-io/get-starknet'
import { reactive, ref, toRefs, watch } from 'vue'
import type { Address } from 'viem'
import { validateAndParseAddress, WalletAccount } from 'starknet'
import { getStarknetTransport } from '@/modules/common/starknet/usePwnStarknetConfig'
import { SupportedChain } from '@/constants/chains/types'

type ADDRESS = string

export const starknetWallet = ref<StarknetWindowObject>()
export const starknetAccount = ref<WalletAccount>()
export type CustomAddress = Address | ADDRESS

export const handleAccount = (accounts: string[] | undefined) => {
  if (accounts?.length && starknetWallet.value) {
    const newWallet = { ...starknetWallet.value, selectedAddress: accounts[0] }
    const account = new WalletAccount(getStarknetTransport(SupportedChain.Sepolia), newWallet)
    starknetWallet.value = newWallet
    starknetAccount.value = account
  } else {
    starknetWallet.value = undefined
  }
}
export const isStarknetAddress = (address?: CustomAddress): address is ADDRESS => {
  // todo: add checksum here?
  return typeof address === 'string' && /^0x[a-fA-F0-9]{62,63}$/.test(address)
}

// todo: fix this error and thighten the types to remove unnecessary properties
// @ts-expect-error Types of property 'isConnected' are incompatible.
const starknetConfig = reactive<UseAccountReturnType>({
  address: ref(undefined),
  addresses: ref(undefined),
  chain: ref(undefined),
  chainId: ref(undefined),
  connector: ref(undefined),
  isConnected: ref(false),
  isConnecting: ref(false),
  isDisconnected: ref(true),
  isReconnecting: ref(false),
  status: ref('disconnected'),
})
watch(starknetWallet, (newWallet) => {
  if (newWallet && starknetAccount.value?.address) {
    starknetConfig.address = validateAndParseAddress(starknetAccount.value?.address) as `0x${string}`
    starknetConfig.isConnected = true
    starknetConfig.isDisconnected = false
    starknetConfig.status = 'connected'
  } else {
    starknetConfig.address = undefined
    starknetConfig.isConnected = false
    starknetConfig.isDisconnected = true
    starknetConfig.status = 'disconnected'
  }
})

export function useCustomAccount<config extends Config = ResolvedRegister['config']>(
  parameters: UseAccountParameters<config> = {},
): UseAccountReturnType<config> {
  if (!isStarknet) {
    return wagmiUseAccount(parameters)
  } else {
    return toRefs(starknetConfig)
  }
}
