<template>
  <div class="waiting-step__container">
    <p>
      {{ topParagraphText }}
    </p>
    <div class="waiting-step__button-container">
      <BaseButton
        :button-text="claimButtonText!"
        :is-disabled="isWaitingForTxConfirmation"
        @on-button-click="handleStartBurnOrClaim"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/general-components/BaseButton.vue'
import { computed, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import usePwnSafeClaimAndBurn, { ClaimAndBurnActionType } from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import usePwnSafeClaimAndBurnModal from '@/modules/pages/pwn-safe/claim-and-burn/modal/usePwnSafeClaimAndBurnModal'
import { useMutation } from '@tanstack/vue-query'
import { queryMutations } from '@/modules/mutations'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import { storeToRefs } from 'pinia'
import { sleep } from '@/modules/common/utils'

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const { assetsToBeClaimedInfo, atrTokensInfo, isWaitingForTxConfirmation } = usePwnSafeClaimAndBurnModal()
const { claimAndBurnActionType, atrTokensToClaimAndBurn } = usePwnSafeClaimAndBurn()

const topParagraphText = computed(() => {
  switch (claimAndBurnActionType.value) {
  case ClaimAndBurnActionType.ClaimAndBurn:
    return `Claiming ${assetsToBeClaimedInfo.value} and burning ${atrTokensInfo.value}...`
  case ClaimAndBurnActionType.Burn:
    return `Burning ${atrTokensInfo.value}...`
  case ClaimAndBurnActionType.Claim:
    return `Claiming ${assetsToBeClaimedInfo.value}...`
  default:
    // todo any log here?
    return null
  }
})

const claimButtonText = computed(() => {
  switch (claimAndBurnActionType.value) {
  case ClaimAndBurnActionType.ClaimAndBurn:
    return isWaitingForTxConfirmation.value ? 'Claiming and Burning' : 'Claim and Burn'
  case ClaimAndBurnActionType.Burn:
    return isWaitingForTxConfirmation.value ? 'Burning' : 'Burn'
  case ClaimAndBurnActionType.Claim:
    return isWaitingForTxConfirmation.value ? 'Claiming' : 'Claim'
  default:
    // todo any log here?
    return null
  }
})

const mutations = queryMutations()
const resetAssetsCacheMutation = useMutation(mutations.safe.batchRefreshAssets)

const burnOrClaimAtrMutation = useMutation({
  ...mutations.safe.burnOrClaimAtrTokens,
  async onSuccess(data, variables, context) {
    // @ts-expect-error FIXME: strictNullChecks
    mutations.safe.burnOrClaimAtrTokens.onSuccess(data, variables, context)
    const delayBeforeRefresh = 7 * 1000
    await sleep(delayBeforeRefresh)
    resetAssetsCacheMutation.mutate({
      // @ts-expect-error FIXME: strictNullChecks
      safesToRefetch: [selectedPwnSafe.value],
      // @ts-expect-error FIXME: strictNullChecks
      selectedChains: [selectedPwnSafe.value.chainId],
      userAddress: getAccount(pwnWagmiConfig).address,
    })
  },
})

let continueFlow
const toast = ref<Toast>()

const handleStartBurnOrClaim = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_BURN_ATR_TOKEN](atrTokensToClaimAndBurn.value)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      // @ts-expect-error FIXME: strictNullChecks
      title: claimButtonText.value,
      // @ts-expect-error FIXME: strictNullChecks
      chainId: selectedPwnSafe.value.chainId,
      steps: [new ToastStep({
        // @ts-expect-error FIXME: strictNullChecks
        text: claimButtonText.value,
        async fn(step) {
          // TODO add const composedImage = pwnSafeDetailStore.createBatchImage(atrTokens) for toast
          await burnOrClaimAtrMutation.mutateAsync({
            assetsToTokenize: atrTokensToClaimAndBurn.value,
            // @ts-expect-error FIXME: strictNullChecks
            safe: selectedPwnSafe.value,
          })
          return true
        },
      })],
    }, NotificationFrontendOnlyActionEnum.TX_BURN_ATR_TOKEN, atrTokensToClaimAndBurn.value);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}

onMounted(() => {
  handleStartBurnOrClaim()
})
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.waiting-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__button-container {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
