import { readPwnV1_2SimpleLoanLoanRepaymentAmount } from '@/contracts/generated'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { Address } from 'viem'
import type { SupportedChain } from '@/constants/chains/types'
import { starknetGetLoanRepaymentAmount } from '@/revamp/hooks/thesis/starknet/proposals'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

export const getAnyLoanRepayment = async (loanId: bigint, loanContractAddress: Address, chainId: SupportedChain) => {
  if (isStarknet) {
    return starknetGetLoanRepaymentAmount(loanId)
  }

  return await readPwnV1_2SimpleLoanLoanRepaymentAmount(pwnWagmiConfig, {
    address: loanContractAddress,
    chainId,
    args: [BigInt(loanId)],
  })
}
