/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useQuery
} from '@tanstack/vue-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  LoanAdminBackendSchema,
  ProposalAdminBackendSchema,
  PwnadminBorrowingProposalsListParams,
  PwnadminLendingProposalsListParams,
  PwnadminLoansV2ListParams,
  SubdomainConfigBackendSchema
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * pass only_lending parameter to get lending proposals
pass only_borrowing parameter to get borrowing proposals
 */
export const pwnadminBorrowingProposalsList = (
    params?: MaybeRef<PwnadminBorrowingProposalsListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<ProposalAdminBackendSchema[]>(
      {url: `/pwnadmin/borrowing_proposals/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnadminBorrowingProposalsListQueryKey = (params?: MaybeRef<PwnadminBorrowingProposalsListParams>,) => {
    return ['pwnadmin','borrowing_proposals', ...(params ? [params]: [])] as const;
    }

    
export const getPwnadminBorrowingProposalsListQueryOptions = <TData = Awaited<ReturnType<typeof pwnadminBorrowingProposalsList>>, TError = unknown>(params?: MaybeRef<PwnadminBorrowingProposalsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnadminBorrowingProposalsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnadminBorrowingProposalsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnadminBorrowingProposalsList>>> = ({ signal }) => pwnadminBorrowingProposalsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnadminBorrowingProposalsList>>, TError, TData> 
}

export type PwnadminBorrowingProposalsListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnadminBorrowingProposalsList>>>
export type PwnadminBorrowingProposalsListQueryError = unknown

export const usePwnadminBorrowingProposalsList = <TData = Awaited<ReturnType<typeof pwnadminBorrowingProposalsList>>, TError = unknown>(
 params?: MaybeRef<PwnadminBorrowingProposalsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnadminBorrowingProposalsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnadminBorrowingProposalsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * pass only_lending parameter to get lending proposals
pass only_borrowing parameter to get borrowing proposals
 */
export const pwnadminLendingProposalsList = (
    params?: MaybeRef<PwnadminLendingProposalsListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<ProposalAdminBackendSchema[]>(
      {url: `/pwnadmin/lending_proposals/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnadminLendingProposalsListQueryKey = (params?: MaybeRef<PwnadminLendingProposalsListParams>,) => {
    return ['pwnadmin','lending_proposals', ...(params ? [params]: [])] as const;
    }

    
export const getPwnadminLendingProposalsListQueryOptions = <TData = Awaited<ReturnType<typeof pwnadminLendingProposalsList>>, TError = unknown>(params?: MaybeRef<PwnadminLendingProposalsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnadminLendingProposalsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnadminLendingProposalsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnadminLendingProposalsList>>> = ({ signal }) => pwnadminLendingProposalsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnadminLendingProposalsList>>, TError, TData> 
}

export type PwnadminLendingProposalsListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnadminLendingProposalsList>>>
export type PwnadminLendingProposalsListQueryError = unknown

export const usePwnadminLendingProposalsList = <TData = Awaited<ReturnType<typeof pwnadminLendingProposalsList>>, TError = unknown>(
 params?: MaybeRef<PwnadminLendingProposalsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnadminLendingProposalsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnadminLendingProposalsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Verify that the current user is authenticated.
 */
export const pwnadminLoansV2List = (
    params?: MaybeRef<PwnadminLoansV2ListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<LoanAdminBackendSchema[]>(
      {url: `/pwnadmin/loans_v2/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnadminLoansV2ListQueryKey = (params?: MaybeRef<PwnadminLoansV2ListParams>,) => {
    return ['pwnadmin','loans_v2', ...(params ? [params]: [])] as const;
    }

    
export const getPwnadminLoansV2ListQueryOptions = <TData = Awaited<ReturnType<typeof pwnadminLoansV2List>>, TError = unknown>(params?: MaybeRef<PwnadminLoansV2ListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnadminLoansV2List>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnadminLoansV2ListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnadminLoansV2List>>> = ({ signal }) => pwnadminLoansV2List(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnadminLoansV2List>>, TError, TData> 
}

export type PwnadminLoansV2ListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnadminLoansV2List>>>
export type PwnadminLoansV2ListQueryError = unknown

export const usePwnadminLoansV2List = <TData = Awaited<ReturnType<typeof pwnadminLoansV2List>>, TError = unknown>(
 params?: MaybeRef<PwnadminLoansV2ListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnadminLoansV2List>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnadminLoansV2ListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const pwnadminSubdomainConfigRetrieve = (
    subdomainName: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      subdomainName = unref(subdomainName);
      
      return customInstance<SubdomainConfigBackendSchema>(
      {url: `/pwnadmin/subdomain-config/${encodeURIComponent(String(subdomainName))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnadminSubdomainConfigRetrieveQueryKey = (subdomainName: MaybeRef<string>,) => {
    return ['pwnadmin','subdomain-config',subdomainName] as const;
    }

    
export const getPwnadminSubdomainConfigRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnadminSubdomainConfigRetrieve>>, TError = unknown>(subdomainName: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnadminSubdomainConfigRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnadminSubdomainConfigRetrieveQueryKey(subdomainName);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnadminSubdomainConfigRetrieve>>> = ({ signal }) => pwnadminSubdomainConfigRetrieve(subdomainName, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(subdomainName))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnadminSubdomainConfigRetrieve>>, TError, TData> 
}

export type PwnadminSubdomainConfigRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnadminSubdomainConfigRetrieve>>>
export type PwnadminSubdomainConfigRetrieveQueryError = unknown

export const usePwnadminSubdomainConfigRetrieve = <TData = Awaited<ReturnType<typeof pwnadminSubdomainConfigRetrieve>>, TError = unknown>(
 subdomainName: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnadminSubdomainConfigRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnadminSubdomainConfigRetrieveQueryOptions(subdomainName,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



