import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import AssetType from '@/modules/common/assets/AssetType'
import { ALL_SUPPORTED_CHAINS_WAGMI, isChainWithLooksRareExplorer, SupportedChain } from '@/constants/chains/types'
import type { ChainName, ChainNameLowercase } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import CronosIconSvg from '@/assets/icons/cronos.svg'
import CronosIconSvgUrl from '@/assets/icons/cronos.svg?url'
import BaseIconSvg from '@/assets/icons/base.svg'
import BaseWhiteIconSvg from '@/assets/icons/base-white.svg'
import OptimismIconSvg from '@/assets/icons/optimism.svg'
import OptimismWhiteIconSvg from '@/assets/icons/op-icon.svg'
import EthereumIconSvg from '@/assets/icons/ethereum.svg'
import EthereumIconSvgUrl from '@/assets/icons/ethereum.svg?url'
import EthereumWhiteIconSvg from '@/assets/icons/ethereum-white.svg'
import SepoliaIconSvg from '@/assets/icons/sepolia.svg'
import SepoliaIconSvgUrl from '@/assets/icons/sepolia.svg?url'
import SepoliaWhiteIconSvg from '@/assets/icons/sepolia-white.svg'
import PolygonIconSvg from '@/assets/icons/polygonscan.svg'
import PolygonIconSvgUrl from '@/assets/icons/polygon.svg?url'
import PolygonWhiteIconSvg from '@/assets/icons/polygon-white.svg'
import ArbitrumIconSvg from '@/assets/icons/arbitrum.svg'
import ArbitrumWhiteIconSvg from '@/assets/icons/arbitrum-white.svg'
import GnosisIconSvg from '@/assets/icons/Gnosis.svg'
import GnosisWhiteIconSvg from '@/assets/icons/gnosis-white.svg'
import XdaiIcon from '@/assets/icons/xdai.png?url'
import BscIconSvg from '@/assets/icons/bsc.svg'
import UnichainIconSvg from '@/assets/icons/unichain.svg'
import UnichainIconSvgUrl from '@/assets/icons/unichain.svg?url'
import BscIconWhiteSvg from '@/assets/icons/bsc-white.svg'
import BnbIconSvgUrl from '@/assets/icons/bnb.svg?url'
import StarknetIconSvg from '@/assets/icons/starknet-white.svg'
import type { Component } from 'vue'
import type { Address, Chain } from 'viem'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'

export const isChainSupported = (chainId: SupportedChain): boolean => enabledChains.includes(chainId)

export const getNftDetailLinkOnRelatedMarketplace = (marketplace: DataSourceType, chainId: SupportedChain, address: Address, tokenId: string): string => {
  const chainConstants = CHAINS_CONSTANTS[chainId]
  if (marketplace === DataSourceType.LOOKSRARE && isChainWithLooksRareExplorer(chainConstants)) {
    return chainConstants.explorer.looksRareNftDetailsLink(address, tokenId)
  } else if (marketplace === DataSourceType.OPENSEA) {
    // Opensea is default NFT explorer, that's why we use here (at least so far) the explorer.nftDetailsLink property
    return chainConstants.explorer.nftDetailsLink(address, tokenId)
  } else {
    throw new TypeError(`Unknown 'listing.marketplace' value: ${marketplace}.`)
  }
}

export const getAssetDetailsExplorerLink = ({ category, address, tokenId, chainId, isNativeToken, isUnsupportedToken }: AssetMetadata): string => {
  const chainConstants = CHAINS_CONSTANTS[chainId]
  if (!chainConstants) return ''
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (isChainWithLooksRareExplorer(chainConstants) && address === chainConstants.nftContractsAddresses.chickenBonds) {
    return chainConstants.explorer.looksRareNftDetailsLink(address, tokenId)
  }

  if (isNativeToken) {
    // @ts-expect-error TS(2322) FIXME: Type '{ readonly name: "Arbiscan"; readonly url: "... Remove this comment to see the full error message
    return ALL_SUPPORTED_CHAINS_WAGMI.find(chainWagmi => chainWagmi.id === chainId)?.blockExplorers?.default
  }

  return category === AssetType.ERC20 || isUnsupportedToken
    ? chainConstants.explorer.contractDetailsLink(address)
    : chainConstants.explorer.nftDetailsLink(address, tokenId)
}

export const getAssetExplorerName = ({ category, chainId, tokenId, isUnsupportedToken }: AssetMetadata): string => {
  if (!CHAINS_CONSTANTS[chainId]) return ''

  return category === AssetType.ERC20 || isUnsupportedToken
    ? CHAINS_CONSTANTS[chainId].explorer.blockExplorerName
    : CHAINS_CONSTANTS[chainId].explorer.nftExplorerName
}

export const getAssetExplorerIcon = ({ category, chainId, tokenId, isUnsupportedToken }: AssetMetadata): Component | null => {
  if (!CHAINS_CONSTANTS[chainId]) return null

  return category === AssetType.ERC20 || isUnsupportedToken
    ? CHAINS_CONSTANTS[chainId].explorer.blockExplorerIcon
    : CHAINS_CONSTANTS[chainId].explorer.nftExplorerIcon
}

export const getChainName = (chainId: SupportedChain): ChainName => {
  switch (chainId) {
  case SupportedChain.Ethereum: return 'Ethereum'
  case SupportedChain.Polygon: return 'Polygon'
  case SupportedChain.Sepolia: return 'Sepolia'
  case SupportedChain.Cronos: return 'Cronos'
  case SupportedChain.Base: return 'Base'
  case SupportedChain.Optimism: return 'Optimism'
  case SupportedChain.Arbitrum: return 'Arbitrum'
  case SupportedChain.Bsc: return 'BSC'
  case SupportedChain.UnichainSepolia: return 'Unichain Sepolia'
  case SupportedChain.Gnosis: return 'Gnosis'
  case SupportedChain.StarknetSepolia: return 'Starknet Sepolia'
  case SupportedChain.StarknetMainnet: return 'Starknet Mainnet'
  }
}

export const getChainIdFromChainName = (chainName: ChainName | ChainNameLowercase | 'mainnet'): SupportedChain => {
  switch (chainName.toLowerCase() as Lowercase<ChainName> | 'mainnet') {
  case 'ethereum': return SupportedChain.Ethereum
  case 'mainnet': return SupportedChain.Ethereum // note: to preserve backwards compatibility when we used mainnet instead of ethereum
  case 'polygon': return SupportedChain.Polygon
  case 'sepolia': return SupportedChain.Sepolia
  case 'cronos': return SupportedChain.Cronos
  case 'base': return SupportedChain.Base
  case 'optimism': return SupportedChain.Optimism
  case 'arbitrum': return SupportedChain.Arbitrum
  case 'bsc': return SupportedChain.Bsc
  case 'unichain sepolia': return SupportedChain.UnichainSepolia
  case 'gnosis': return SupportedChain.Gnosis
  case 'starknet sepolia': return SupportedChain.StarknetSepolia
  case 'starknet mainnet': return SupportedChain.StarknetMainnet
  }
}

export const getChainIcon = (chainId: SupportedChain): Component => {
  switch (chainId) {
  case SupportedChain.Ethereum: return EthereumIconSvg
  case SupportedChain.Sepolia: return SepoliaIconSvg
  case SupportedChain.Polygon: return PolygonIconSvg
  case SupportedChain.Cronos: return CronosIconSvg
  case SupportedChain.Base: return BaseIconSvg
  case SupportedChain.Optimism: return OptimismIconSvg
  case SupportedChain.Arbitrum: return ArbitrumIconSvg
  case SupportedChain.Bsc: return BscIconSvg
  case SupportedChain.UnichainSepolia: return UnichainIconSvg
  case SupportedChain.Gnosis: return GnosisIconSvg
  case SupportedChain.StarknetSepolia: return StarknetIconSvg
  case SupportedChain.StarknetMainnet: return StarknetIconSvg
  default: throw new TypeError(`Unknown chain with id ${chainId} in ChainInfo.vue.`)
  }
}

export const getMonoChromeChainIcon = (chainId: SupportedChain): Component => {
  switch (chainId) {
  case SupportedChain.Ethereum: return EthereumWhiteIconSvg
  case SupportedChain.Sepolia: return SepoliaWhiteIconSvg
  case SupportedChain.Polygon: return PolygonWhiteIconSvg
  case SupportedChain.Cronos: return CronosIconSvg // TODO add WhiteIcon?
  case SupportedChain.Base: return BaseWhiteIconSvg
  case SupportedChain.Optimism: return OptimismWhiteIconSvg
  case SupportedChain.Arbitrum: return ArbitrumWhiteIconSvg
  case SupportedChain.Bsc: return BscIconWhiteSvg
  case SupportedChain.UnichainSepolia: return UnichainIconSvg
  case SupportedChain.Gnosis: return GnosisWhiteIconSvg
  case SupportedChain.StarknetSepolia: return StarknetIconSvg
  case SupportedChain.StarknetMainnet: return StarknetIconSvg
  default: throw new TypeError(`Unknown chain with id ${chainId} in ChainInfo.vue.`)
  }
}

export const getNativeTokenIconUrl = (chainId: SupportedChain): string => {
  switch (chainId) {
  case SupportedChain.Ethereum:
  case SupportedChain.Base:
  case SupportedChain.Optimism:
  case SupportedChain.Arbitrum:
    return EthereumIconSvgUrl
  case SupportedChain.Sepolia: return SepoliaIconSvgUrl
  case SupportedChain.Polygon: return PolygonIconSvgUrl
  case SupportedChain.Cronos: return CronosIconSvgUrl
  case SupportedChain.Bsc: return BnbIconSvgUrl
  case SupportedChain.UnichainSepolia: return UnichainIconSvgUrl
  case SupportedChain.Gnosis: return XdaiIcon
  default: throw new TypeError(`Unknown chain with id ${chainId} in ChainInfo.vue.`)
  }
}

export const getNativeTokenWrapper = (chainId: SupportedChain): {address: Address, symbol: string} => {
  switch (chainId) {
  case SupportedChain.Ethereum: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.weth!,
    symbol: 'WETH',
  }
  case SupportedChain.Sepolia: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.weth!,
    symbol: 'WETH',
  }
  case SupportedChain.Polygon: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.wmatic!,
    symbol: 'WMATIC',
  }
  case SupportedChain.Cronos: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.wcro!,
    symbol: 'WCRO',
  }
  case SupportedChain.Base: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.weth!,
    symbol: 'WETH',
  }
  case SupportedChain.Optimism: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.weth!,
    symbol: 'WETH',
  }
  case SupportedChain.Arbitrum: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.weth!,
    symbol: 'WETH',
  }
  case SupportedChain.Bsc: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.wbnb!,
    symbol: 'WBNB',
  }
  case SupportedChain.UnichainSepolia: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.weth!,
    symbol: 'WETH',
  }
  case SupportedChain.Gnosis: return {
    address: CHAINS_CONSTANTS[chainId].topTokens.wxdai!,
    symbol: 'WXDAI',
  }
  default: throw new TypeError(`Unknown chain with id ${chainId} in getNativeTokenWrapper.`)
  }
}

export const getWagmiChain = (chainId: SupportedChain): Chain => {
  return ALL_SUPPORTED_CHAINS_WAGMI.find(wagmiChain => wagmiChain.id === chainId)!
}
