<template>
  <div class="thesis-my-commitments">
    <div>
      <div class="thesis-my-commitments__credit-stats-title">
        Your Commitments ({{ assetsToShow.length }})
      </div>

      <ThesisMyCommited
        :on-update="props.onUpdate"
        :data="creditAssets"
        :thesis-stats-data="props.thesisStatsData"
        :thesis="thesis"
        :all-credit-assets="allCreditAssets"

        :is-loading="props.isLoading"/>
    </div>

    <div>
      <div class="thesis-my-commitments__credit-stats-title">
        Loans Created By Strategy
      </div>

      <ProposalsTable
        type="all"
        :user-address="address"
        include-unverified-collateral
        include-collateral-without-price
        include-unverified-credit
        include-credit-without-price
        include-loans
        show-history
        :thesis-id="thesisId"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ThesisCreditStatsSchemaWorkaroundBackendSchema, ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import type { Address } from 'viem'
import { computed } from 'vue'
import type { ComputedRef } from 'vue'
import ThesisMyCommited from './ThesisMyCommited.vue'
import { parseThesisAsset } from '@/revamp/hooks/thesis/utils'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import ProposalsTable from '@/revamp/components/tables/ProposalsTable.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

type Props = {
    thesisStatsData: Record<Address, ThesisCreditStatsSchemaWorkaroundBackendSchema>
    isLoading: boolean
    thesis: ThesisSchemaWorkaroundBackendSchema & {
      parsedCollateralAssets: ComputedRef<AssetWithAmount[] | undefined>,
    }
    onUpdate: (thesisId: string) => void
}

const props = defineProps<Props>()

const assetsToShow = computed(() => Object.values(props.thesisStatsData).filter(v => v.amountsStats.totalCommittedAmount !== '0').map(v => v.creditAssetMetadata))
const allCreditAssets = props.thesis.creditsStats?.map((v) => parseThesisAsset(v.creditAssetMetadata))

const creditAssets = computed(() => assetsToShow.value.map(v => parseThesisAsset(v)))
const { address } = useCustomAccount()

const thesisId = computed(() => props.thesis.id)

</script>

<style scoped>
.thesis-my-commitments {
  display: flex;
  flex-flow: column nowrap;

  &__credit-stats-title {
    background-color: var(--gray-3);
    padding: 0.5rem 1rem;

    font-family: var(--font-family-screener);
    font-size: 1.125rem;
    font-weight: 400;

    margin-bottom: 1rem;
  }
}
</style>
