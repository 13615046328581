<template>
  <div class="homepage">
    <HeroHomepage
      :active-tab="activeTab"
      :set-borrow-tab="setBorrowTab"
      :select-collateral="selectCollateral"
      :selected-collateral="selectedCollateral"
      :select-credit="selectCredit"
      :selected-credit="selectedCredit"
      :has-stables-filter="hasStablesFilter"
      :set-lend-tab="setLendTab"
      @assets-to-filter="handleAssetsToFilter"/>

    <div class="homepage__split-view">
      <div class="homepage__split-view--left">
        <div
          v-if="!isStarknet"
          class="homepage__tabs">
          <button
            class="homepage__tab"
            :class="{
              'homepage__tab--active': activeTab === Tabs.Lend,
            }"
            @click="setActiveTab(Tabs.Lend)">
            Lend
          </button>

          <button
            class="homepage__tab"
            :class="{
              'homepage__tab--active': activeTab === Tabs.Borrow,
            }"
            @click="setActiveTab(Tabs.Borrow)">
            Borrow
          </button>
        </div>
        <div
          class="homepage__filters"
          :class="{
            'homepage__filters--starknet': isStarknet,
          }">
          <SearchBar
            v-model="searchQuery"
            class="homepage__filters-search-bar"
            placeholder="Search assets by name, ID or address"/>

          <CollateralTypeFilter/>

          <ActiveProposalFilters
            :selected-collateral="isCollateralToBeBundled ? null : selectedCollateral"
            :selected-credit="selectedCredit"
            :has-stables-filter="hasStablesFilter"
            :selected-stable-coins="hasStablesFilter ? currentlyActiveStablecoinsPlusWeth : []"
            :clear-stables-filter="handleClearStablesFilter"
            :clear-collateral-selection="
              () => {

                if (activeTab === Tabs.Borrow) {
                  setQueryParams(null);
                }
                selectedCollateral = null
                return
              }
            "
            :clear-credit-selection="
              () => {
                if (activeTab === Tabs.Lend) {
                  setQueryParams(null);
                }
                return (selectedCredit = null);
              }
            "/>
        </div>

        <ProposalFilters
          class="homepage__proposal-filters"
          :show-history="proposalFiltersStore.filters.showHistory"
          @update:show-history="proposalFiltersStore.actions.updateShowHistory"/>

        <RouterView v-slot="{ Component, route }">
          <Transition name="move-to-left">
            <div :key="route.path">
              <KeepAlive>
                <component
                  :is="Component"
                  :q="searchQuery"
                  :selected-chains="currentlySelectedChains"
                  :collateral-type="proposalFiltersStore.filters.collateralType"
                  :show-history="proposalFiltersStore.filters.showHistory"
                  :selected-collateral-filter-assets="collateralAssetsToFilter"
                  :include-collateral-without-price="globalFiltersStore.showAssetsWithoutPricing"
                  :include-credit-without-price="globalFiltersStore.showAssetsWithoutPricing"
                  :include-unverified-collateral="globalFiltersStore.showUnverifiedAssets"
                  :include-unverified-credit="globalFiltersStore.showUnverifiedAssets"
                  :selected-credit-filter-assets="creditAssetsToFilter"
                  :with-title="false"
                  :type="activeTab === Tabs.Borrow ? 'borrow' : 'lend'"/>
              </KeepAlive>
            </div>
          </Transition>
        </RouterView>
      </div>

      <ProposalForm
        v-if="activeTab !== Tabs.Discovery && !isStarknet"
        :selected-collateral="selectedCollateral"
        :selected-credit="selectedCredit"
        :select-collateral="selectCollateral"
        :select-credit="selectCredit"
        has-tabs
        :is-active-tab-borrow="isActiveTabBorrow"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import ProposalFilters from '@/revamp/components/proposal-filters/ProposalFilters.vue'
import ProposalForm from '@/revamp/components/proposal-form/ProposalForm.vue'
import SearchBar from '@/revamp/components/SearchBar.vue'
import CollateralTypeFilter from '@/modules/common/filters/collateral-type/CollateralTypeFilter.vue'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import RouteName from '@/router/RouteName'
import HeroHomepage from '@/revamp/components/HeroHomepage.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import type { ChainFilterOptions } from '@/modules/pages/pwn-explorer/types'
import router from '@/router'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import AssetType from '@/modules/common/assets/AssetType'
import { useSelectedAssetsStore } from '@/revamp/hooks/useSelectedAssetsStore'

import { usePageParamsAsset } from '@/revamp/hooks/usePageParamsAsset'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import ActiveProposalFilters from '@/revamp/components/proposal-filters/ActiveProposalFilters.vue'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

const selectedChainStore = useSelectedChainsStore()
const { selectedChains } = storeToRefs(selectedChainStore)
const proposalFiltersStore = useProposalFilters()

const selectedAssetsStore = useSelectedAssetsStore()

const { searchQuery } = storeToRefs(proposalFiltersStore)
const { selectedCollateral: elseWhereSelectedCollateral, selectedCredit: elseWhereSelectedCredit } = storeToRefs(selectedAssetsStore)

const globalFiltersStore = useGlobalFiltersStore()

const { setQueryParams, parsedAsset } = usePageParamsAsset()

const selectedCollateral = ref<AssetWithAmount | null>(null)
const selectedCredit = ref<AssetWithAmount | null>(null)

const setActiveTab = (tab: Tabs) => {
  switch (tab) {
  case Tabs.Borrow:
    router.replace({
      name: RouteName.RevampBorrow,
    })
    break
  case Tabs.Lend:
    router.replace({
      name: RouteName.RevampLend,
    })
    break
  case Tabs.Discovery:
    router.push({
      name: RouteName.RevampExplorer,
    })
    break
  }
}

enum Tabs {
  Borrow = 'borrow',
  Lend = 'lend',
  Discovery = 'Discovery',
}

const activeTab = computed(() => {
  if (isStarknet) {
    return Tabs.Borrow
  }

  if (router.currentRoute.value.name === RouteName.RevampBorrow) {
    return Tabs.Borrow
  }

  if (router.currentRoute.value.name === RouteName.RevampExplorer) {
    return Tabs.Discovery
  }

  return Tabs.Lend
})

const setBorrowTab = () => {
  setActiveTab(Tabs.Borrow)
  if (selectedCredit.value) {
    if (selectedCredit.value.symbol !== '$PWN-ALL-YOUR-STABLES') {
      selectedCollateral.value = selectedCredit.value
    }
    selectedCredit.value = null
  }

  if (selectedCollateral.value) {
    selectedCredit.value = selectedCollateral.value
    selectedCollateral.value = null
  }
}

const setLendTab = () => {
  setActiveTab(Tabs.Lend)

  if (selectedCollateral.value) {
    selectedCredit.value = selectedCollateral.value
    selectedCollateral.value = null
  }

  if (selectedCredit.value) {
    selectedCollateral.value = selectedCredit.value
    selectedCredit.value = null
  }
}

const isActiveTabBorrow = computed(() => activeTab.value === Tabs.Borrow)

const bundledAssets = ref<Array<AssetMetadata | AssetWithAmount>>([])
const selectCollateral = (asset?: AssetWithAmount | AssetMetadata | Array<AssetMetadata | AssetWithAmount>) => {
  if (Array.isArray(asset)) {
    bundledAssets.value = asset
    const temporaryCollateral = new AssetWithAmount({
      amount: '1',
      address: CHAINS_CONSTANTS[bundledAssets.value[0].chainId]?.tokenBundlerContract,
      category: AssetType.ERC721,
      name: 'PWN Bundle',
      symbol: 'PWN Bundle',
      isVerified: false,
      decimals: 0,
      tokenId: BigInt(0),
      chainId: bundledAssets.value[0].chainId,
      bundleAssets: bundledAssets.value as AssetWithAmount[],
    })
    selectedCollateral.value = new AssetWithAmount(temporaryCollateral)
  } else {
    selectedCollateral.value = asset ? new AssetWithAmount(asset) : null
    if (isActiveTabBorrow.value) { setQueryParams(selectedCollateral.value) }
  }
}

const collateralToFilterFromHeroHomepage = ref()
const creditToFilterFromHeroHomepage = ref()

const handleAssetsToFilter = (assets: {
  creditAssets: Array<AssetMetadata | AssetWithAmount>;
  collateralAssets: Array<AssetMetadata | AssetWithAmount>;
}) => {
  collateralToFilterFromHeroHomepage.value = assets.collateralAssets
  creditToFilterFromHeroHomepage.value = assets.creditAssets
}

const handleClearStablesFilter = () => {
  collateralToFilterFromHeroHomepage.value = []
  creditToFilterFromHeroHomepage.value = []
}

const collateralAssetsToFilter = computed(() => {
  if (selectedCollateral.value) {
    return [selectedCollateral.value]
  } else {
    return collateralToFilterFromHeroHomepage.value
  }
})

const creditAssetsToFilter = computed(() => {
  if (selectedCredit.value) {
    if (selectedCredit.value.symbol === '$PWN-ALL-YOUR-STABLES') {
      return selectedCredit.value.bundleAssets
    }
    return [selectedCredit.value]
  } else {
    return creditToFilterFromHeroHomepage.value
  }
})

const hasStablesFilter = computed(() => {
  return collateralToFilterFromHeroHomepage.value?.length > 1 || creditToFilterFromHeroHomepage.value?.length > 1
})

const selectCredit = (asset?: AssetWithAmount | AssetMetadata) => {
  selectedCredit.value = asset ? new AssetWithAmount(asset) : null
  if (!isActiveTabBorrow.value && asset && selectedCredit.value?.symbol !== '$PWN-ALL-YOUR-STABLES') {
    setQueryParams(selectedCredit.value)
  }
}

const { chainId } = useCustomAccount()

const discoveryTabSelectedChain = ref(chainId.value)

watchEffect(() => {
  if (activeTab.value === Tabs.Discovery) {
    if (selectedChains.value !== 'all') {
      discoveryTabSelectedChain.value = selectedChains.value?.[0]
    }
  }
})

const currentlySelectedChains = computed(() => {
  if (activeTab.value === Tabs.Discovery) {
    return [discoveryTabSelectedChain.value] as ChainFilterOptions
  }
  return selectedChains.value as ChainFilterOptions
})

watchEffect(() => {
  if (elseWhereSelectedCollateral.value) {
    selectedCollateral.value = elseWhereSelectedCollateral.value
  }
})

watchEffect(() => {
  if (elseWhereSelectedCredit.value) {
    selectedCredit.value = elseWhereSelectedCredit.value
  }
})

watchEffect(() => {
  if (parsedAsset.value) {
    if (isActiveTabBorrow.value) {
      selectedCollateral.value = parsedAsset.value
    } else {
      selectedCredit.value = parsedAsset.value
    }
  }
})

const isCollateralToBeBundled = computed(() => {
  return selectedCollateral.value?.address === '0x0000000000000000000000000000000000000000' && selectedCollateral.value?.name === 'PWN Bundle'
})

const { getStablecoinsPlusWethByChainId } = useTopTokensStore()
const selectedChainsStore = useSelectedChainsStore()
const { selectedValues } = storeToRefs(selectedChainsStore)
const currentlyActiveStablecoinsPlusWeth = computed(() => {
  const result: AssetMetadata[] = []

  for (const selectedNetwork of selectedValues.value || []) {
    const tokens: AssetMetadata[] = getStablecoinsPlusWethByChainId(selectedNetwork)
    if (tokens) {
      result.push(...tokens)
    }
  }

  return result
})
</script>

<style scoped>
.homepage {
  display: flex;
  flex-flow: column nowrap;

  margin-top: 2.5rem;

  &__proposal-filters {
    border-bottom: 1px solid #434343;
    padding-bottom: 1rem;
  }

  &__filters {
    display: flex;
    gap: 1rem;
    align-items: center;
    border-top: 1px solid #434343;
    padding-bottom: 1rem;
    padding-top: 1rem;

    &--starknet {
      border-top: none;
      padding-top: 0;
    }

    @media only screen and (--small-viewport) {
      flex-flow: column nowrap;
      gap: 1rem;
    }
  }

  &__filters-search-bar {
    height: 2rem;

    @media only screen and (--small-viewport) {
      width: 100% !important;
    }
  }

  &__split-view {
    display: flex;
    flex-flow: row nowrap;

    gap: 24px;
    padding-bottom: 4rem;

    @media only screen and (--mobile-viewport) {
      flex-flow: column-reverse nowrap;
      gap: 1rem;
      align-items: center;
    }

    &--left {
      flex: 1;
      border: 1px solid #434343;
      padding: 1rem;

      min-width: 907px;

      @media only screen and (--small-viewport) {
        width: 640px;
        min-width: 320px;
      }

      @media only screen and (--mobile-viewport) {
        width: 100%;
        min-width: 290px;

        max-width: 24rem;
      }
    }
  }

  &__tabs {
    display: flex;
    gap: 24px;
    margin-bottom: 1rem;

    width: 100%;
    justify-content: space-between;
  }

  &__search {
    padding: 0.875rem 1rem;
    background-color: transparent;
    border: 1px solid #828282 !important;
  }

  &__tab {
    background-color: transparent;
    border: 1px solid var(--text-color);
    color: var(--text-color);
    padding: 0.675rem 2rem;
    width: 100%;
    cursor: pointer;

    text-transform: uppercase;
    font-family: var(--font-family-screener);
    font-weight: 400;
    user-select: none;

    will-change: border-color, color;
    transition: border-color 0.3s linear,
      color 0.3s linear;

    &:hover {
      border-color: var(--primary-color-1);
    }

    &--active {
      border-color: var(--primary-color-1);
      color: var(--primary-color-1);
    }
  }

  .move-to-right,
  .move-to-right-move {
    &-enter-active,
    &-leave-active {
      transition: transform 0.3s,
        width 0.3s,
        opacity 0.3s;
      width: auto;
    }

    &-enter-from {
      transform: translateX(100%);
      width: 0;
      opacity: 0;
    }

    &-enter-to {
      transform: translateX(0);
      width: auto;
      opacity: 1;
    }

    &-leave-to {
      transform: translateX(140%);
      width: 0;
      opacity: 0;
    }

    &-leave-active {
      position: absolute;
      right: 0;
    }
  }
}
</style>
