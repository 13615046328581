<template>
  <div
    ref="tableContainerRef"
    class="container"
    style="overflow: auto; position: relative; height: 400px;">
    <table class="user-tokens-table">
      <thead class="user-tokens-table__head">
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
          class="user-tokens-table__head-row">
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            :colSpan="header.colSpan">
            <FlexRender
              v-if="!header.isPlaceholder"
              :render="header.column.columnDef.header"
              :props="header.getContext()"/>
          </th>
        </tr>
      </thead>
      <tbody
        class="user-tokens-table__body"
        style="display: grid; height: 100%; position: relative;"
        :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
        <TransitionGroup name="user-tokens-table--fade">
          <tr
            v-for="row in virtualRows"
            :key="rows[row.index].id"
            :ref="customRefHandler"
            :class="['user-tokens-table__row', { 'user-tokens-table__row--disabled': !rows[row.index].getCanSelect() }]"
            :data-index="row.index"
            :style="{
              display: 'flex',
              position: 'absolute',
              transform: `translateY(${row.start}px)`,
              width: '100%',
              justifyContent: 'space-between',
            }"
            @click="() => handleRowClick(rows[row.index])">
            <td v-if="props.selectMultiple">
              <BaseCheckbox
                :is-disabled="!rows[row.index].getCanSelect()"
                :model-value="rows[row.index].getIsSelected()"/>
            </td>
            <div style="display: flex; justify-content: space-between; width: 100%;">
              <td
                v-for="cell in rows[row.index].getVisibleCells()"
                :key="cell.id"
                :style="{
                  width: `${cell.column.getSize()}px`,
                }">
                <FlexRender
                  :render="cell.column.columnDef.cell"
                  :props="cell.getContext()"/>
              </td>
            </div>
          </tr>
        </TransitionGroup>
        <BaseSkeletor v-if="props.isLoading"/>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { FlexRender, getCoreRowModel, getFilteredRowModel, useVueTable } from '@tanstack/vue-table'
import type { Row, RowSelectionState } from '@tanstack/vue-table'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, h, ref } from 'vue'
import AssetInfoCell from '@/revamp/components/tables/cells/assets/AssetInfoCell.vue'
import AssetAmountWithAppraisal from '@/revamp/components/tables/cells/assets/AssetAmountWithAppraisal.vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import { useVirtualizer } from '@tanstack/vue-virtual'
import { isAssetIncludedInSearchResults } from '@/utils/search'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import useSelectAssetModal from '@/revamp/components/modals/select-your-collateral/useSelectAssetModal'
import type { SupportedChain } from '@/constants/chains/types'
import { isMobile } from '@/utils/mediaQueries'
import type { AssetPriceLog } from '@/revamp/modules/asset-prices/types'
import { useAssetsWithSmallBalancesStore } from '@/revamp/hooks/useAssetsWithSmallBalancesStore'

type Props = {
  data: AssetWithAmount[];
  selectMultiple: boolean;
  selectedRows: RowSelectionState;
  search?: string;
  isLoading: boolean;
  selectedChain?: SupportedChain;
};

const props = defineProps<Props>()
const tableContainerRef = ref(null)
const { multipleAssetsSelected } = useSelectAssetModal()
const emit = defineEmits<{(e:'remove-selected-row', value:string): void, (e:'add-selected-row', value:string): void}>()

const store = useAssetsWithSmallBalancesStore()

const columnFilters = computed(() => {
  if (props.search) {
    return [
      {
        id: 'name',
        value: props.search,
      },
    ]
  }
  return []
})

const table = useVueTable({
  get data() {
    return props.data
  },
  state: {
    get rowSelection() {
      return props.selectedRows
    },
    get columnFilters() {
      return columnFilters.value
    },
  },
  getRowId: (row) => String(row.id),
  columns: [
    {
      header: 'Token Name',
      id: 'name',
      accessorFn: (row) => row.name,
      filterFn: (row, filterValue, v) => {
        return isAssetIncludedInSearchResults(row.original, v.toLowerCase())
      },
      cell: (ctx) => {
        return h(AssetInfoCell, {
          asset: ctx.row.original,
        })
      },
      size: isMobile ? 220 : 340,
    },
    {
      header: 'You Own',
      accessorFn: (row) => row.amount,
      cell: (ctx) => {
        return h(AssetAmountWithAppraisal, {
          asset: ctx.row.original,
          amount: ctx.row.original.amount,
          onAssetAppraisalUpdated: (log: AssetPriceLog) => {
            store.addAsset(ctx.row.original, log)
          },
        })
      },
      size: 100,
    },
  ],
  getCoreRowModel: getCoreRowModel(),
  enableMultiRowSelection: (row) => {
    if (!props.selectMultiple) {
      return false
    }

    if (props.selectMultiple) {
      return row.original.isValid
    }

    return true
  },
  enableRowSelection(row) {
    if (!props.selectedChain || !multipleAssetsSelected.value) {
      return row.original.isValid // todo: check if this is correct
    }
    return row.original.isValid && row.original.chainId === props.selectedChain
  },
  getFilteredRowModel: getFilteredRowModel(),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rows.value.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 42,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

const handleUpdateCheckbox = (value: boolean, index: number) => {
  const row = rows.value[index]
  row.toggleSelected(value)

  if (value) {
    emit('add-selected-row', row.id)
  } else {
    emit('remove-selected-row', row.id)
  }
}

const handleRowClick = (row: Row<AssetWithAmount>) => {
  if (!props.selectMultiple) {
    emit('add-selected-row', row.id)
  } else if (row.getCanSelect()) {
    handleUpdateCheckbox(!row.getIsSelected(), row.index)
  }
}
</script>

<style scoped>
.user-tokens-table {
  display: grid;

  padding-right: 1rem;

  &__head {
    position: sticky;
    top: 0;
    z-index: 5;

    display: flex;

    background: var(--gray-3);
    padding: 0 0.5rem;
  }

  &__head-row {
    width: 100%;
    padding: 0.25rem;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family-screener);
  }

  &__row {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;

    padding: 0.5rem 1rem;
    will-change: background-color;
    transition: background-color 0.3s linear;
    cursor: pointer;

    &--disabled {
      opacity: 0.5;
    }

    &:hover {
      background-color: var(--gray-3);
    }
  }
}

.user-tokens-table--fade,
.user-tokens-table--fade-move {
  transition: opacity 0.5s;

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave-active {
    position: absolute;
  }
}
</style>
