<template>
  <div class="mobile-nav-bar">
    <div class="mobile-nav-bar__content">
      <TheWallet :open-direction="'top'"/>

      <div class="mobile-nav-bar__actions">
        <CurrencySwitcher :open-direction="'top'"/>
        <GlobalFilters
          v-if="!isPwnSpace"
          y-direction="top"
          x-direction="left"
          :display-amount-filter="false"/>
        <ReputationScoreModal
          v-if="!isStarknet && isConnected && userAddress"
          :address="userAddress"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import TheWallet from '@/layout/header/TheWallet.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import CurrencySwitcher from '@/layout/header/CurrencySwitcher.vue'
import ReputationScoreModal from '@/revamp/components/modals/reputation-score/ReputationScoreModal.vue'
import { isPwnSpace, isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import GlobalFilters from '@/modules/common/filters/global/GlobalFilters.vue'

const { isConnected, address: userAddress } = useCustomAccount()
</script>

<style scoped>
  .mobile-nav-bar {
    position: fixed;
    bottom: 0;
    height: 4rem;
    width: 100vw;
    background: var(--background-color);
    z-index: var(--z-index-app-top-menu);

    &__content {
      display: flex;
      flex-flow: row nowrap;
      padding: 1rem;
      box-sizing: border-box;
      margin: auto;
    }

    &__actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: auto;
      gap: 1rem;
    }

    @media only screen and (min-width: 1258px) {
      display: none;
    }
  }
</style>

<style>
.mobile-nav-bar .multiselect__content-wrapper {
  right: 0;
}
</style>
