import { defineStore } from 'pinia'
import { ref } from 'vue'
import StoreIds from '@/constants/storeIds'
import type { V1_2SimpleLoanFungibleProposal } from '@/modules/common/pwn/proposals/ProposalClasses'

export const useAcceptFungibleProposalModalStore = defineStore(StoreIds.acceptFungibleProposalModalStore, () => {
  const isOpen = ref(false)
  const proposalClass = ref<V1_2SimpleLoanFungibleProposal>()
  const collateralAmount = ref<string>('')

  return {
    isOpen,
    open() {
      isOpen.value = true
    },
    close() {
      isOpen.value = false
    },
    proposalClass,
    collateralAmount,
  }
})
