<template>
  <div
    v-if="isTokenBundle"
    class="verification-badge__container">
    <div
      v-if="isMixedBundle"
      class="verification-badge__mixed-bundle">
      Mixed Bundle
    </div>
    <div v-else-if="!displayText && numberOfVerifiedAssets! > 0">
      <component
        :is="VerificationIcon"
        class="verification-badge__icon"
        alt="Verified"/>
    </div>
    <div
      v-else-if="displayText"
      class="verification-badge__container">
      <span
        v-if="numberOfVerifiedAssets! > 0"
        class="verification-badge__verified">
        <VerificationIconSvg
          class="verification-badge__icon--verified-bundle"
          alt="Verified Icon"/>
        <span> {{ numberOfVerifiedAssets }} Verified </span>
      </span>
      <span
        v-if="numberOfUnverifiedAssets > 0"
        class="verification-badge__not-verified">
        <UnverifiedIconSvg
          class="verification-badge__icon--unverified"
          alt="Unverified Icon"/>
        <span> {{ numberOfUnverifiedAssets }} Not verified</span>
      </span>
    </div>
  </div>
  <div
    v-else-if="token.isVerified"
    class="verification-badge__container">
    <div :class="[{ 'verification-badge__wrapper': displayText }, badgeClass]">
      <BaseTooltip
        v-if="isGenericallyVerified"
        is-interactive
        :has-tooltip="hasTooltip"
        append-to-body
        :max-width="400"
        :border-color="TooltipBorderColor.White">
        <template #trigger>
          <VerificationIconSvg
            :class="{'verification-badge__question-mark-icon': hasTooltip}"
            alt="question mark icon"/>
        </template>
        <p class="verification-badge__tooltip-text-verification--upper verification-badge__tooltip-text-verification">
          Verification shows if the asset you are interacting with is the real asset
        </p>
        <p class="verification-badge__tooltip-text-verification--lower verification-badge__tooltip-text-verification">
          <BaseLink
            class="link link--primary link--font-oxygen-mono"
            :to="linkTo.verificationGuide">
            Learn more about asset verification
          </BaseLink>
        </p>
      </BaseTooltip>
      <component
        :is="VerificationIcon"
        v-if="displayText"
        :style="iconWidth ? { width: iconWidth } : {}"
        :class="['verification-badge__icon', `verification-badge__icon--${verificationType}`]"
        alt="Verified"/>
      <span
        v-if="displayText"
        :class="['verification-badge__is-verified', `verification-badge__is-verified--${verificationType}`]"> Verified
      </span>
    </div>
  </div>
  <div
    v-else-if="!token.isVerified && displayText"
    class="verification-badge__container">
    <span class="verification-badge__not-verified">
      <UnverifiedIconSvg
        class="verification-badge__icon--unverified"
        alt="Unverified Icon"/>Not verified</span>
  </div>
</template>

<script setup lang="ts">
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { computed, toRefs } from 'vue'
import type { Component } from 'vue'
import UniswapIconSvg from '@/assets/icons/uniswap.svg'
import OpenseaIconSvg from '@/assets/icons/opensea-colored.svg'
import CronaSwapIconSvg from '@/assets/icons/crona-swap.svg'
import OptimismIconSvg from '@/assets/icons/optimism.svg'
import ArbitrumIconSvg from '@/assets/icons/arbitrum.svg'
import EbisusBayIconSvg from '@/assets/icons/ebisusbay.svg'
import BoggedFinanceIconSvg from '@/assets/icons/boggedfinance.svg'
import PwnIconSvg from '@/assets/icons/pwn.svg'
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import linkTo from '@/constants/links'
import NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import BaseLink from '@/general-components/BaseLink.vue'
import UnverifiedIconSvg from '@/assets/icons/close.svg'
import BundlerIconSvg from '@/assets/icons/bundler.svg'
import VerificationIconSvg from '@/assets/icons/verified.svg'
import BscIconSvg from '@/assets/icons/bsc.svg'
import { MetadataSourceBackendSchema } from '@/modules/common/backend/generated'

interface Props {
  token: AssetMetadata | NFTAssetCollection,
  displayText?: boolean,
  isGenericVerification?: boolean
  isInTableColumn?: boolean
  iconWidth?: string | null
  hasTooltip?: boolean
}

enum VerificationTypes {
  Generic = 'verified',
  Opensea = 'opensea',
  Uniswap = 'uniswap',
  EbisusBay = 'ebisusbay',
  CronaSwap = 'cronaswap',
  BoggedFinance = 'boggedfinance',
  Optimism = 'optimism',
  Pwn = 'pwn',
  ArbitrumOne = 'arbitrumone',
  Bsc = 'bsc',
}

type AssetType = Props['token']
const props = withDefaults(defineProps<Props>(), {
  displayText: true,
  iconWidth: null,
  hasTooltip: true,
})
const { token, displayText, isGenericVerification } = toRefs(props)

const isGenericallyVerified = computed(() => {
  return token.value.isVerified && isGenericVerification.value
})

const isTokenBundle = computed(() => token.value instanceof AssetMetadata && token.value.isTokenBundle)

const getAssetVerificationSource = (asset: AssetType) => {
  if (asset.isVerified && (asset instanceof NFTAssetCollection || asset.isNft)) {
    switch (asset.isVerifiedSource) {
    case MetadataSourceBackendSchema.EBISUSBAY:
      return VerificationTypes.EbisusBay
    case MetadataSourceBackendSchema.PWN_OVERWRITE:
    case MetadataSourceBackendSchema.TOKEN_LIST_PWN:
      return VerificationTypes.Pwn
    default:
      return VerificationTypes.Opensea
    }
  } else if (asset.isVerified && (!(asset instanceof NFTAssetCollection) && !asset.isNft)) {
    switch (asset.isVerifiedSource) {
    case MetadataSourceBackendSchema.TOKEN_LIST_CRONASWAP:
      return VerificationTypes.CronaSwap
    case MetadataSourceBackendSchema.TOKEN_LIST_BOGGED:
      return VerificationTypes.BoggedFinance
    case MetadataSourceBackendSchema.TOKEN_LIST_OPTIMISM:
      return VerificationTypes.Optimism
    case MetadataSourceBackendSchema.TOKEN_LIST_ARBITRUM_ONE:
      return VerificationTypes.ArbitrumOne
    case MetadataSourceBackendSchema.TOKEN_LIST_BNB_SMART_CHAIN:
      return VerificationTypes.Bsc
    case MetadataSourceBackendSchema.PWN_OVERWRITE:
    case MetadataSourceBackendSchema.TOKEN_LIST_PWN:
      return VerificationTypes.Pwn
    default:
      return VerificationTypes.Uniswap
    }
  } else {
    return ''
  }
}

const verificationType = computed(() => {
  if (isGenericallyVerified.value) {
    return VerificationTypes.Generic
  }

  return getAssetVerificationSource(token.value)
})
const badgeClass = computed(() => {
  if (!displayText.value) return ''
  if (isTokenBundle.value) return `verification-badge__wrapper--${VerificationTypes.Generic}`
  return `verification-badge__wrapper--${verificationType.value}`
})

// @ts-expect-error FIXME: strictNullChecks
const VerificationIcon = computed<Component>(() => {
  if (isTokenBundle.value) {
    return BundlerIconSvg
  }

  switch (verificationType.value) {
  case VerificationTypes.Pwn:
    return PwnIconSvg
  case VerificationTypes.Generic:
    return VerificationIconSvg
  case VerificationTypes.Opensea:
    return OpenseaIconSvg
  case VerificationTypes.Uniswap:
    return UniswapIconSvg
  case VerificationTypes.CronaSwap:
    return CronaSwapIconSvg
  case VerificationTypes.BoggedFinance:
    return BoggedFinanceIconSvg
  case VerificationTypes.EbisusBay:
    return EbisusBayIconSvg
  case VerificationTypes.Optimism:
    return OptimismIconSvg
  case VerificationTypes.ArbitrumOne:
    return ArbitrumIconSvg
  case VerificationTypes.Bsc:
    return BscIconSvg
  default:
    return null
  }
})

const numberOfVerifiedAssets = computed(() => {
  return token.value instanceof AssetMetadata && token.value.isTokenBundle ? token.value.verifiedBundledAssets : 0
})

const numberOfUnverifiedAssets = computed(() => {
  if (token.value instanceof AssetMetadata && token.value.isTokenBundle) {
    return token.value.totalBundledAssets! - token.value.verifiedBundledAssets!
  } else return token.value.isVerified ? 0 : 1
})

const isMixedBundle = computed(() => props.isInTableColumn && token.value instanceof AssetMetadata && token.value.isMixedBundle)

</script>

<style scoped>
.verification-badge {
  &__wrapper {
    line-height: 1rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 1.125rem;

    &--verified {
      background: #0f2926;
    }

    &--uniswap {
      background: rgb(255 0 122 / 10%);
    }

    &--opensea {
      background: rgb(32 129 226 / 10%);
    }

    &--ebisusbay {
      background: rgb(251 195 154 / 10%);
    }

    &--cronaswap {
      background: #191928;
    }

    &--boggedfinance {
      background: #2a2d2b;
    }

    &--pwn {
      background: var(--teal-4);
    }

    &--optimism {
      background: #212121;
    }

    &--bsc,
    &--arbitrumone {
      background: #212121;
    }
  }

  &__missing-info {
    font-size: 0.875rem;
    color: var(--text-color);
  }

  &__container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    object-fit: cover;

    &--verified {
      height: 1.25rem;
    }

    &--uniswap {
      height: 1.25rem;
    }

    &--ebisusbay {
      height: 1.25rem;
    }

    &--cronaswap {
      height: 1.25rem;
    }

    &--boggedfinance {
      height: 1.25rem;
    }

    &--pwn {
      height: 1.25rem;
    }

    &--optimism {
      height: 1.25rem;
    }

    &--arbitrumone {
      height: 1.25rem;
    }

    &--unverified {
      height: 1rem;
      width: 1rem;
      margin-right: 0.5rem;
      padding-bottom: 0.15rem;
      vertical-align: middle;
      /* stylelint-disable-next-line max-line-length */
      filter: brightness(0) saturate(100%) invert(68%) sepia(46%) saturate(6318%) hue-rotate(358deg) brightness(94%) contrast(106%);
    }

    &--verified-bundle {
      height: 1rem;
      width: 1rem;
      margin-right: 0.5rem;
      vertical-align: middle;
    }
  }

  &__is-verified {
    font-size: 0.875rem;
    margin-left: 0.5rem;

    &--verified {
      color: var(--primary-color-1);
      padding-top: 0.1rem;
    }

    &--uniswap {
      color: #ff007a;
      padding-top: 0.1rem;
    }

    &--opensea {
      color: #2081e2;
      padding-top: 0.1rem;
    }

    &--ebisusbay {
      color: #fbc39a;
      padding-top: 0.1rem;
    }

    &--cronaswap {
      color: var(--text-color);
      padding-top: 0.1rem;
    }

    &--boggedfinance {
      color: #e4f7ea;
      padding-top: 0.1rem;
    }

    &--pwn {
      color: var(--primary-color-1);
      padding-top: 0.1rem;
    }

    &--optimism {
      color: #e4f7ea;
      padding-top: 0.1rem;
    }

    &--arbitrumone {
      color: #fff;
      padding-top: 0.1rem;
    }
  }

  &__not-verified {
    line-height: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1.125rem;
    background: rgb(249 118 0 / 10%);
    color: var(--negative-1);
    font-size: 0.875rem;
    white-space: nowrap;
  }

  &__tooltip-text-verification {
    text-align: center;

    &--upper {
      margin-top: 0;
    }

    &--lower {
      margin-bottom: 0;
    }
  }

  &__question-mark-icon {
    cursor: help;
  }

  &__verified {
    line-height: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1.125rem;
    background: rgb(0 255 224 / 10%);
    color: var(--primary-color-1);
    font-size: 0.875rem;
    white-space: nowrap;
  }

  &__mixed-bundle {
    line-height: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1.125rem;
    background: linear-gradient(180deg, rgb(249 118 0 / 10%) 0%, rgb(0 255 224 / 10%) 100%);
    color: var(--negative-1);
    font-size: 0.875rem;
    white-space: nowrap;
  }
}
</style>

<style>
.verification-badge__container .pwn-logo__img rect {
  fill: var(--primary-color-1);
}
</style>
