<template>
  <div class="card">
    <h2 class="card__heading">
      {{ heading }}
    </h2>
    <div class="card__subheading">
      {{ subheading }}
    </div>
    <slot/>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps<{
  heading: string
  subheading: string
}>()
const { heading, subheading } = toRefs(props)
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: var(--border-white-dashed);
  padding: 2.8rem;

  &__heading {
    font-family: var(--font-family-autoscape);
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: 0.625rem;
  }

  &__subheading {
    font-size: 1.25rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  @media only screen and (--mobile-viewport) {
    padding: 1.5rem;

    &__heading {
      font-size: 1.5rem;
    }

    &__subheading {
      font-size: 1rem;
    }
  }
}
</style>
