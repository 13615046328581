<template>
  <div class="commit-button">
    <div class="commit-button__first-row">
      <div class="commit-button__first-row-text">
        {{ props.text }}
      </div>

      <div class="commit-button__first-row-icon">
        <div class="commit-button__icons">
          <div class="commit-button__icons-wrapper">
            <slot name="icons"/>
          </div>
        </div>
      </div>
    </div>

    <div class="commit-button__second-row">
      <div class="commit-button__rewards">
        <slot name="rewards"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
type Props = {
  isLoading: boolean
  text: string
}

const props = defineProps<Props>()
</script>

<style scoped>
.commit-button {
  display: flex;
  flex-flow: column nowrap;

  border: 1px solid #00ffe0;
  background: var(--teal-teal-10, #0f2926);

  padding: 1rem;

  gap: 0.625rem;

  transition: transform 0.2s ease-in-out;

  &:hover {
    cursor: pointer;

    transform: scale(1.01);
  }

  &__icons {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    gap: 0.5rem;

    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      margin-left: auto;
    }
  }

  &__first-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &-text {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25rem;
      font-family: var(--font-family-screener);
      color: var(--teal-1);

      text-transform: uppercase;
    }
  }
}
</style>
