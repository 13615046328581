<template>
  <AssetHeader/>
  <div class="nft-page">
    <!-- DESKTOP -->
    <div v-if="!isSmallScreen">
      <div class="nft-page__description">
        <div class="nft-page__image-wrapper">
          <NftPageModalImage/>
        </div>
        <div class="nft-page__details">
          <BaseSkeletor
            v-if="!asset"
            height="140"/>
          <AssetBasicDescription v-else-if="asset"/>
        </div>
      </div>
    </div>

    <!-- MOBILE -->
    <div
      v-else
      class="nft-page__column-mobile">
      <NftPageModalImage/>
      <BaseSkeletor
        v-if="isLoadingBasicAssetDetails"
        height="140"/>
      <AssetBasicDescription v-else/>
      <NftPageTabsAndProposals/>
      <NftPageSections/>
    </div>
  </div>

  <!-- DESKTOP -->
  <div v-if="!isSmallScreen">
    <NftPageTabsAndProposals/>
    <NftPageSections/>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router'
import AssetHeader from '@/modules/pages/asset/nft-page/AssetHeader.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import { watch, onUnmounted, onMounted } from 'vue'
import AssetBasicDescription from '@/modules/pages/asset/nft-page/AssetBasicDescription.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { isSmallScreen } from '@/utils/mediaQueries'
import { compareAddresses } from '@/utils/utils'
import type { Address } from 'viem'
import { getChainIdFromChainName } from '@/utils/chain'
import type { ChainName, ChainNameLowercase } from '@/constants/chains/types'
import NftPageModalImage from '@/revamp/pages/nft/NftPageModalImage.vue'
import NftPageTabsAndProposals from '@/revamp/pages/nft/NftPageTabsAndProposals.vue'
import NftPageSections from '@/revamp/pages/nft/NftPageSections.vue'

const { asset, loadNFTAssetPage, isLoadingBasicAssetDetails, clearAssetPageData, lastParams } = useAssetPage()

interface Props {
  type?: string
}
defineProps<Props>()

const route = useRoute()
const unwatchRouteParams = watch(
  [() => route.params?.contractAddress, () => route.params?.tokenId, () => route.params?.chainName],
  async () => {
    if (!route.params?.contractAddress || route.params?.tokenId === undefined || !route.params.chainName) {
      return
    }
    const currentParams = String(route.params.contractAddress) + String(route.params.tokenId) + String(route.params.chainName)
    if (lastParams.value === currentParams) {
      return
    }
    lastParams.value = currentParams

    await loadNFTAssetPage({
      // @ts-expect-error FIXME: strictNullChecks
      chainName: route.params.chainName as string,
      // @ts-expect-error FIXME: strictNullChecks
      contractAddress: route.params.contractAddress as string,
      tokenId: route.params.tokenId as string,
      loanId: route.params.loanId as string,
    })
  },
  { immediate: true },
)

onUnmounted(() => {
  unwatchRouteParams()
})

onMounted(() => {
  if (asset.value?.tokenId?.toString() !== route.params?.tokenId ||
    !compareAddresses(asset.value?.address, route.params?.contractAddress as Address) ||
    asset.value?.chainId !== getChainIdFromChainName(route.params?.chainName as ChainName | ChainNameLowercase)
  ) {
    clearAssetPageData()
  }
})

</script>

<style scoped>
.nft-page {
  position: relative;
  margin-bottom: 3.5rem;

  &__description {
    display: flex;
    gap: 2rem;
  }

  @media only screen and (--small-viewport) {
    &__description {
      flex-direction: column;
      align-items: center;
    }

    &__column-mobile {
      display: flex;
      flex-flow: column;
      row-gap: 2rem;
    }

    &__highest-offer {
      width: 100%;
    }
  }
}
</style>
