<template>
  <button
    class="back-button"
    @click="props.hasEmit ? emit('go-back') : goBackOrFallback()">
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 22"
      fill="none"
      class="back-button__icon"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 20.4865L0.938193 11L16 1.51352L16 20.4865Z"
        stroke="#696678"
        stroke-width="2px"/>
    </svg>

    <span>back</span>
  </button>
</template>

<script setup lang="ts">
import { goBackOrFallback } from '@/utils/utils'
interface Props {
  hasEmit?: boolean
}
const props = defineProps<Props>()

const emit = defineEmits<{(e: 'go-back'): void}>()
</script>

<style scoped>
.back-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  font-family: var(--font-family-screener);
  transition: all var(--primary-transition-time);
  color: var(--gray);

  &:hover {
    opacity: 0.7;
  }

  &__icon {
    margin-bottom: 0.2rem;
  }
}
</style>
