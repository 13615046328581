<template>
  <BaseModal
    v-model:is-open="isOpen"
    :heading="titleText"
    :heading-icon="trashIcon.default"
    heading-align="left"
    :size="ModalSize.Large">
    <template #trigger>
      <BaseButton
        :color="ButtonColor.Orange"
        :size="ButtonSize.M"
        :button-text="Object.keys(nonces).length > 1 ? 'Cancel All' : 'Cancel'"
        :is-icon-before-text="true"
        @on-button-click="handleOpenModal"/>
    </template>
    <template #subheading>
      <div class="revoke-thesis-proposals__subheading">
        <div class="revoke-thesis-proposals__subheading-info">
          Are you sure you want to cancel your {{ assetName }} proposals generated by Thesis {{ formattedThesisId }}?
        </div>
      </div>
    </template>

    <template #body>
      <div class="revoke-thesis-proposals__body">
        <div class="revoke-thesis-proposals__controls">
          <BaseButton
            :is-disabled="isLoading"
            class="revoke-thesis-proposals__button-cancel"
            button-text="Cancel Proposals"
            :color="ButtonColor.Orange"
            :is-full-width="true"
            @on-button-click="handleCancel"/>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import * as trashIcon from '@/assets/icons/trash-orange.svg'
import ModalSize from '@/general-components/ModalSize'
import BaseModal from '@/revamp/components/RevampBaseModal.vue'
import BaseButton, { ButtonColor, ButtonSize } from '@/general-components/BaseButton.vue'
import { computed, ref } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'

import { useMutation } from '@tanstack/vue-query'
import { revokeNonces } from '@/modules/common/adapters/proposal-utils'

type Props = {
    nonces: Partial<Record<SupportedChain, bigint[]>>
    titleText: string
    assetName: string
    thesisId: string
    onUpdate?: (thesisId: string) => void
}
const props = defineProps<Props>()

const isOpen = ref(false)

const formattedThesisId = computed(() => `#${props.thesisId.padStart(4, '0')}`)

const handleOpenModal = () => {
  isOpen.value = true
}

const revokeNoncesMutation = useMutation({
  mutationFn: async () => {
    if (!Object.keys(props.nonces).length) return
    return revokeNonces(props.nonces)
  },
  mutationKey: ['revoke-thesis-nonces'],
  onSuccess: () => {
    // hack, otherwise it's not always processed in time
    setTimeout(() => {
      props.onUpdate?.(props.thesisId)
    }, 1500) // todo: increase if update is not happening upon revoke
    isOpen.value = false
  },
})

const isLoading = revokeNoncesMutation.isPending

const handleCancel = () => {
  revokeNoncesMutation.mutate()
}

</script>

<style scoped>
.revoke-thesis-proposals__body {
  width: 100%;
}
</style>
