import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import { parseSourceFromBackend } from '@/general-components/data-source/DataSourceType'
import type { ValuationSource } from '@/general-components/data-source/DataSourceType'
import type { CollectionStatsBackendSchema } from '@/modules/common/backend/generated'

export default class CollectionStats {
  floorPrice: AmountInEthAndUsd
  weekFloorPriceChangePercentage: string

  averageAppraisal: AmountInEthAndUsd

  marketCap: AmountInEthAndUsd
  weekMarketCapChangePercentage: string

  totalVolume: AmountInEthAndUsd
  pastDayVolume: AmountInEthAndUsd
  pastDayAverage: AmountInEthAndUsd
  pastWeekVolume: AmountInEthAndUsd
  pastWeekAverage: AmountInEthAndUsd

  pastMonthSales: string
  totalSales: string

  lastUpdated: Date
  dataSource: ValuationSource

  constructor(collectionStats: Partial<CollectionStats>) {
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.floorPrice = collectionStats?.floorPrice
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.weekFloorPriceChangePercentage = collectionStats?.weekFloorPriceChangePercentage
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.averageAppraisal = collectionStats?.averageAppraisal
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.marketCap = collectionStats?.marketCap
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.weekMarketCapChangePercentage = collectionStats?.weekMarketCapChangePercentage
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.totalVolume = collectionStats?.totalVolume
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.pastDayVolume = collectionStats?.pastDayVolume
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.pastDayAverage = collectionStats?.pastDayAverage
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.pastWeekVolume = collectionStats?.pastWeekVolume
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.pastWeekAverage = collectionStats?.pastWeekAverage
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.pastMonthSales = collectionStats?.pastMonthSales
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalSales = collectionStats?.totalSales
    // @ts-expect-error TS(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
    this.lastUpdated = collectionStats?.lastUpdated
    // @ts-expect-error TS(2322) FIXME: Type 'DataSourceType | undefined' is not assignabl... Remove this comment to see the full error message
    this.dataSource = collectionStats?.dataSource
  }

  public static createFromBackendModel(collectionStats: CollectionStatsBackendSchema): CollectionStats {
    return new CollectionStats({
      floorPrice: AmountInEthAndUsd.createFromBackendModel(collectionStats.floor_price),
      weekFloorPriceChangePercentage: collectionStats.week_floor_price_change_percentage ?? undefined,
      averageAppraisal: AmountInEthAndUsd.createFromBackendModel(collectionStats.average_appraisal),
      marketCap: AmountInEthAndUsd.createFromBackendModel(collectionStats.market_cap),
      weekMarketCapChangePercentage: collectionStats.week_market_cap_change_percentage ?? undefined,
      totalVolume: AmountInEthAndUsd.createFromBackendModel(collectionStats.total_volume),
      pastDayVolume: AmountInEthAndUsd.createFromBackendModel(collectionStats.past_day_volume),
      pastDayAverage: AmountInEthAndUsd.createFromBackendModel(collectionStats.past_day_average),
      pastWeekVolume: AmountInEthAndUsd.createFromBackendModel(collectionStats.past_week_volume),
      pastWeekAverage: AmountInEthAndUsd.createFromBackendModel(collectionStats.past_week_average),
      pastMonthSales: collectionStats.past_month_sales ?? undefined,
      totalSales: collectionStats.total_sales ?? undefined,
      lastUpdated: new Date(collectionStats.last_updated), // todo check
      dataSource: parseSourceFromBackend(collectionStats.data_sources[0]) as ValuationSource,
    })
  }
}
