import useApprove from '@/modules/common/assets/useApprove'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { Address, Hex } from 'viem'
import { fromHex, maxUint256 } from 'viem'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import type { PartialWithRequired } from '@/modules/common/typings/customTypes'
import fixSignatureIfInvalid from '@/utils/fixSignatureIfInvalid'
import { pwnV1_2SimpleLoanAbi } from '@/contracts/generated'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'
import { usePersistentThesisMerkleTree } from '@/revamp/hooks/thesis/utils'
import { starknetCreateLoan } from '@/revamp/hooks/thesis/starknet/proposals'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

type ApprovePropsType = {
  assetToApprove: AssetWithAmount;
  loanAmountRaw?: bigint;
  walletAddress?: Address;
  step?: ToastStep;
};
export default abstract class BaseProposalContract<TProposal extends BaseProposal = BaseProposal> {
  public abstract createProposalStruct(proposalClass: TProposal): Promise<Record<string, unknown>>;
  public abstract createProposalHash(proposalClass: TProposal): Promise<{ proposalHash: Hex }>;

  public async acceptProposal({
    proposalClass,
    acceptor,
    encodedProposalData,
    nonceToRevoke,
    step,
  }: {
    proposalClass: BaseProposal;
    acceptor: Address;
    encodedProposalData: Hex;
    nonceToRevoke?: bigint;
    step?: ToastStep;
  }) {
    let proposalInclusionProof: `0x${string}`[] = []

    const merkleRootIsNotEmpty = proposalClass.multiproposalMerleRoot && fromHex(proposalClass.multiproposalMerleRoot, 'number') > 0

    if (merkleRootIsNotEmpty && proposalClass.multiproposalMerleRoot && !isStarknet) {
      const { getInclusionProof } = usePersistentThesisMerkleTree(proposalClass.multiproposalMerleRoot)
      const proof = await getInclusionProof(proposalClass.proposalHash!)
      if (!proof) {
        throw new Error('Invalid proof')
      }
      proposalInclusionProof = proof
    }

    if (isStarknet) {
      // @ts-expect-error types mismatch here but we don't care
      return await starknetCreateLoan(proposalClass, encodedProposalData as unknown as bigint[], proposalInclusionProof as bigint[], proposalClass.proposalSignature as `0x${string}`, nonceToRevoke)
    }

    const validatedProposalSignature = fixSignatureIfInvalid(proposalClass.proposalSignature as Hex)
    const proposalSpec = {
      proposalContract: proposalClass.proposalContractAddress,
      proposalData: encodedProposalData,
      proposalInclusionProof,
      signature: validatedProposalSignature,
    }

    const lenderSpec = {
      sourceOfFunds: proposalClass.sourceOfFunds ? proposalClass.sourceOfFunds : (proposalClass.isOffer ? proposalClass.proposer : acceptor),
    }
    const callerSpec = {
      refinancingLoanId: 0n,
      revokeNonce: Boolean(nonceToRevoke),
      nonce: nonceToRevoke ?? 0n,
      permitData: '' as Hex,
    }

    // additional custom data
    const extra = '0x'

    const receipt = await sendTransaction(
      {
        abi: pwnV1_2SimpleLoanAbi,
        functionName: 'createLOAN',
        args: [proposalSpec, lenderSpec, callerSpec, extra],
        address: CHAINS_CONSTANTS[proposalClass.chainId].pwnV1_2Contracts?.pwnSimpleLoan || '0x',
        chainId: proposalClass.chainId,
      },
      { step },
    )
    return receipt
  }

  public async isApprovedForMakeProposal({
    assetToApprove,
    loanAmountRaw,
    walletAddress,
  }: PartialWithRequired<ApprovePropsType, 'assetToApprove' | 'walletAddress'>): Promise<boolean> {
    const { chainId, category, address, amountRaw } = assetToApprove
    const amountToCheck = loanAmountRaw || amountRaw
    if (!CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan) {
      throw new Error('pwnSimpleLoan is not defined')
    }

    return await useApprove().isApproved(
      chainId,
      category,
      address,
      walletAddress,
      CHAINS_CONSTANTS[chainId].pwnV1_2Contracts?.pwnSimpleLoan || '0x',
      amountToCheck,
    )
  }

  public async approveForMakeProposal({ assetToApprove, loanAmountRaw, step }: ApprovePropsType): Promise<boolean> {
    const { chainId, amountRaw } = assetToApprove
    const amountToCheck = loanAmountRaw || amountRaw

    if (!CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan) {
      throw new Error('pwnSimpleLoan is not defined')
    }

    return await useApprove().approve(
      {
        asset: assetToApprove,
        spender: CHAINS_CONSTANTS[chainId].pwnV1_2Contracts?.pwnSimpleLoan || '0x',
        amount: maxUint256,
        minAllowanceAmountToCheck: amountToCheck,
      },
      step,
    )
  }

  public async isApprovedForAcceptProposal({
    assetToApprove,
    loanAmountRaw,
    walletAddress,
  }: PartialWithRequired<ApprovePropsType, 'assetToApprove' | 'walletAddress'>): Promise<boolean> {
    const { chainId, category, address, amountRaw } = assetToApprove
    const amountToCheck = loanAmountRaw || amountRaw

    if (!CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan) {
      throw new Error('pwnSimpleLoan is not defined')
    }

    return await useApprove().isApproved(
      chainId,
      category,
      address,
      walletAddress,
      CHAINS_CONSTANTS[chainId].pwnV1_2Contracts?.pwnSimpleLoan || '0x',
      amountToCheck,
    )
  }

  public async approveForAcceptProposal({ assetToApprove, loanAmountRaw, step }: ApprovePropsType): Promise<boolean> {
    const { chainId, amountRaw } = assetToApprove
    const amountToCheck = loanAmountRaw || amountRaw

    if (!CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan) {
      throw new Error('pwnSimpleLoan is not defined')
    }

    return await useApprove().approve(
      {
        asset: assetToApprove,
        spender: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan || '0x',
        amount: maxUint256,
        minAllowanceAmountToCheck: amountToCheck,
      },
      step,
    )
  }
}
