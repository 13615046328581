<template>
  <BaseTooltip
    class="danger-asset-icon__fake-asset"
    has-tooltip
    append-to-body
    :border-color="TooltipBorderColor.Orange">
    <template #trigger>
      <DangerFakeIcon
        width="18"
        height="18"
        alt="fake asset"/>
    </template>
    <div class="danger-asset-icon__fake-asset">
      {{ tooltipText }}
    </div>
  </BaseTooltip>
</template>

<script setup lang="ts">

import DangerFakeIcon from '@/assets/icons/danger-fake.svg'
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'

interface Props {
  tooltipText?: string
}

const props = defineProps<Props>()
const tooltipText = props.tooltipText || 'This proposal likely includes a fake asset.'
</script>

<style scoped>
.danger-asset-icon {
  &__fake-asset {
    z-index: var(--z-index-danger-icon);
  }
}
</style>
