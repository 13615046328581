import type { Directive } from 'vue'

const vFocus: Directive = {
  mounted(el: HTMLInputElement, binding, vnode) {
    // binding.arg = argument that we pass to the directive. This allows us to conditionally apply
    // this v-focus directive (e.g. `v-focus` applies this directive, but `v-focus="false"` not
    if (!Object.prototype.hasOwnProperty.call(binding, 'arg') || binding.arg) {
      el.focus()
    }
  },
}
export default vFocus
