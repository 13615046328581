import type { Ref } from 'vue'
import deepcopy from '@/utils/deepcopyinstance'
import { compareAddresses, compareAssets } from '@/utils/utils'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

export default function useAssetsSelection() {
  const isAssetSelectionValid = (selectedAssets: AssetWithAmount[]) => {
    if (!selectedAssets?.length) return false
    return selectedAssets.every(selectedAsset => selectedAsset.isAssetAmountInputValidOrEmpty)
  }

  const isAssetSelected = ({ asset, selectedAssets }: {asset: AssetWithAmount, selectedAssets: Ref<AssetWithAmount[]>}): boolean => {
    if (asset.isNativeToken) {
      return selectedAssets.value.some(selectedAsset => selectedAsset.isNativeToken && selectedAsset.chainId === asset.chainId)
    }
    return selectedAssets.value.some(selectedAsset => compareAssets({ assetA: selectedAsset, assetB: asset }))
  }

  const addToSelectedAssets = ({ asset, selectedAssets }: {asset: AssetWithAmount, selectedAssets: Ref<AssetWithAmount[]>}): void => {
    const assetCopy = deepcopy(asset)
    selectedAssets.value.push(assetCopy)
  }

  const addOrRemoveAssetSelection = ({ asset, selectedAssets }: {asset: AssetWithAmount, selectedAssets: Ref<AssetWithAmount[]>}): boolean => {
    // in case we want disable selection of invalid and unsupported tokens
    // TODO what about this? the isValid is also affected by v1_1_compatible, which is specific to pwn protocol,
    // but we use this function also with e.g. bundles or pwn safe assets selection, so it does not make sense
    // to mix it universaly to this function IMHO
    // if (!asset.isValid) return
    const writableAsset = deepcopy(asset)
    let assetIndex: number
    if (writableAsset.isNativeToken) {
      assetIndex = selectedAssets.value.findIndex(selectedAsset => selectedAsset.isNativeToken && selectedAsset.chainId === writableAsset.chainId)
    } else {
      assetIndex = selectedAssets.value.findIndex(selectedAsset => compareAssets({ assetA: selectedAsset, assetB: writableAsset }))
    }

    if (assetIndex === -1) {
      addToSelectedAssets({ asset: writableAsset, selectedAssets })
    } else {
      selectedAssets.value.splice(assetIndex, 1)
    }
    return assetIndex !== -1
  }

  const selectionChangeAfterWrapNativeToken = ({ nativeAndWrappedNativeToken, selectedAssets }: {
    selectedAssets: Ref<AssetWithAmount[]>,
    nativeAndWrappedNativeToken: {
      nativeToken: AssetWithAmount,
      wrappedNativeToken: AssetWithAmount
    }
  }) => {
    const { nativeToken, wrappedNativeToken } = nativeAndWrappedNativeToken
    wrappedNativeToken.amountInput = nativeToken.amountInput

    addOrRemoveAssetSelection({ asset: nativeToken, selectedAssets })
    addOrRemoveAssetSelection({ asset: wrappedNativeToken, selectedAssets })

    const indexOfWrappedNativeToken = selectedAssets.value.findIndex(asset => compareAddresses(asset.address, wrappedNativeToken.address))
    selectedAssets.value[indexOfWrappedNativeToken].amountInput = nativeToken.amountInput
  }

  return {
    isAssetSelected,
    addToSelectedAssets,
    addOrRemoveAssetSelection,
    isAssetSelectionValid,
    selectionChangeAfterWrapNativeToken,
  }
}
