import type { BreadcrumbActiveStep } from '@/general-components/IBreadcrumbsHeader'
import { ref } from 'vue'
import type { RouteLocationNormalized, Router } from 'vue-router'
import CREATE_PWN_SAFE_STEPS_DEFINITION from '@/modules/pages/pwn-safe/create-new-pwn-safe/createNewPwnSafeStepsDefinition'
import RouteName from '@/router/RouteName'
import numeral from 'numeral'
import type { SupportedChain } from '@/constants/chains/types'
import { formatEther, getContract } from 'viem'
import { estimateFeesPerGas, getAccount, getChainId, getPublicClient } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { pwnSafeFactoryAbi } from '@/contracts/generated'

const activeStep = ref<BreadcrumbActiveStep>(CREATE_PWN_SAFE_STEPS_DEFINITION[0])
const safeName = ref('')
const chainId = ref<SupportedChain | null>(null)
const MAX_SAFE_NAME_LENGTH = 40
const gasCost = ref('N/A')

export default function useCreateNewPwnSafe() {
  const setActiveStep = (step: BreadcrumbActiveStep): void => {
    activeStep.value = step
    window.scrollTo({ top: 0 })
  }

  const checkEnterStep = (route: RouteLocationNormalized, router: Router) => {
    if (route.name === RouteName.PwnSafeCreateNewPwnSafe) {
      return
    }
    if ([RouteName.PwnSafeCreateConfirmation].includes(route.name as RouteName)) {
      return router.push({ name: RouteName.PwnSafeCreateNewPwnSafe })
    }
  }

  const initPage = (route: RouteLocationNormalized, router: Router) => {
    checkEnterStep(route, router)
    activeStep.value = CREATE_PWN_SAFE_STEPS_DEFINITION[0]
    // TODO is this correct?
    chainId.value = getChainId(pwnWagmiConfig)
  }

  const submitName = async (router: Router) => {
    setActiveStep(CREATE_PWN_SAFE_STEPS_DEFINITION[1])
    router.push({ name: RouteName.PwnSafeCreateConfirmation })
  }

  const estimatePwnSafeDeployCost = async (): Promise<void> => {
    if (!chainId.value) {
      return
    }

    const { address: userAddress } = getAccount(pwnWagmiConfig)

    const pwnSafeFactoryAddress = CHAINS_CONSTANTS[chainId.value]?.pwnSafeContracts?.pwnSafeFactory
    if (!pwnSafeFactoryAddress) {
      gasCost.value = 'N/A'
    }
    const client = getPublicClient(pwnWagmiConfig, { chainId: chainId.value })

    const pwnSafeContract = getContract({
      abi: pwnSafeFactoryAbi,
      address: pwnSafeFactoryAddress,
      client: {
        public: client!,
      },
    })

    const [{ maxFeePerGas }, gasUnits] = await Promise.all([
      estimateFeesPerGas(pwnWagmiConfig, {
        chainId: chainId.value,
      }),
      // TODO pass any additonal 2nd argument here?
      // @ts-expect-error FIXME: strictNullCheks
      pwnSafeContract.estimateGas.deployProxy([[userAddress], 1n], {}),
    ])

    const transactionFeeWei = BigInt(gasUnits * maxFeePerGas)
    const transactionFeeETH = formatEther(transactionFeeWei)

    gasCost.value = numeral(transactionFeeETH).format('0,0.[00000000]')
  }

  return {
    activeStep,
    safeName,
    MAX_SAFE_NAME_LENGTH,
    gasCost,
    setActiveStep,
    initPage,
    submitName,
    estimatePwnSafeDeployCost,
    chainId,
  }
}
