<template>
  <div class="collection-assets-tab">
    <div class="collection-assets-tab__navigator">
      <NavigateByTokenId/>
    </div>

    <BaseTable
      :class="['collection-assets-tab__table',
               { 'collection-assets-tab__table--bottom-dashed-line': !hasEmptyState}]"
      :columns="ASSETS_TABLE_DEFINITIONS"
      :items="collectionAssets"
      :is-virtual="true"
      table-max-height="640px"
      :virtual-table-overscan="limit"
      :item-height="72.5"
      :table-min-width="'700px'"
      :is-fetching="isFetchingCollectionAssets"
      :display-loading-bottom="true"
      @on-row-click="navigateToAssetPage"
      @on-load-more="store.actions.fetchNext">
      <template #name="{ item }">
        <NftAssetTableRepresentation
          :title="item.name"
          :image="item.animation_url"
          :chain-id="collection.chainId"
          :is-verified="collection.isVerified"
          :collection-name="collection.name!"/>
      </template>

      <template #appraisal="{ item }">
        <BaseSkeletor
          v-if="store.actions.getAssetPriceStats(item) === undefined"
          class="collection-assets-tab__inline-loader"/>
        <EthAndUsdPriceLabel
          v-else
          :decimals="4"
          :amount="store.actions.getAssetPriceStats(item)?.appraisal"
          :price-source="store.actions.getAssetPriceStats(item)?.appraisalSource ?? undefined"/>
      </template>

      <template #actions="{ item }">
        <BaseButton
          class="collection-assets-tab__actions"
          :variant="ButtonVariant.Default"
          button-text="Lend"
          @click.stop="(event: MouseEvent) => navigateToAssetPage(item, event)"/>
      </template>
    </BaseTable>
  </div>
</template>

<script setup lang="ts">
import BaseTable from '@/general-components/BaseTable.vue'
import { ASSETS_TABLE_DEFINITIONS } from '@/revamp/pages/collection/tabs/CollectionOffersPageAssetsTableDefinitions'
import { useCollectionAssetsStore } from '@/revamp/pages/collection/useCollectionAssetsStore'
import { storeToRefs } from 'pinia'
import NftAssetTableRepresentation from '@/modules/common/assets/components/NftAssetTableRepresentation.vue'
import { useCollectionPageStore } from '@/revamp/pages/collection/useCollectionPageStore'
import router from '@/router'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import BaseButton, { ButtonVariant } from '@/general-components/BaseButton.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import { computed } from 'vue'
import NavigateByTokenId from '@/revamp/pages/collection/NavigateByTokenId.vue'
import type { BaseNFTAssetSchemaBackendSchema } from '@/modules/common/backend/generated'

const collectionStore = useCollectionPageStore()
const store = useCollectionAssetsStore()
const { collectionAssets, isFetchingCollectionAssets, limit } = storeToRefs(store)
const { collection } = storeToRefs(collectionStore)

const navigateToAssetPage = (nftAsset: BaseNFTAssetSchemaBackendSchema, event: MouseEvent) => {
  const assetRoute = {
    name: RouteName.NftPage,
    params: {
      chainName: CHAINS_CONSTANTS[collectionStore.collection.chainId].general.chainName.toLowerCase(),
      contractAddress: nftAsset.contract.address,
      tokenId: nftAsset.token_id,
    },
  }
  if (event && (event.ctrlKey || event.metaKey)) {
    const resolvedRoute = router.resolve(assetRoute)
    window.open(resolvedRoute.href, '_blank')
    return
  }
  router.push(assetRoute)
}

const hasEmptyState = computed(() => (
  !isFetchingCollectionAssets.value &&
  collectionAssets.value?.length === 0
))
</script>

<style scoped>
.collection-assets-tab {
  &__actions {
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5rem;
  }

  &__inline-loader {
    max-width: 5.6rem;
  }
}

</style>

<style>
.collection-assets-tab {
  &__table {
    margin: 1rem 0;
    padding: 0;

    &--bottom-dashed-line {
      padding: 0;

      &::after {
        content: "";
        display: block;
        height: 1px;
        background-image: var(--border-gray-dashed);
        background-size: auto 2px;
        /* small hack how to hide buggy double border (top + bottom), when height is 1px */
      }
    }
  }

  &__navigator {
    max-width: 28rem;
  }

  @media only screen and (--small-viewport) {
    margin-top: 1rem;

    &__table {
      overflow: auto auto;
    }
  }
}
</style>
