/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `NUMBER` - NUMBER
* `STRING` - STRING
* `DATE` - DATE
* `RANKING` - RANKING
* `OBJECT` - OBJECT
 */
export type DisplayTypeEnumBackendSchema = typeof DisplayTypeEnumBackendSchema[keyof typeof DisplayTypeEnumBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DisplayTypeEnumBackendSchema = {
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  DATE: 'DATE',
  RANKING: 'RANKING',
  OBJECT: 'OBJECT',
} as const;
