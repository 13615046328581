import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { computed, h, reactive, ref } from 'vue'
import type { Router } from 'vue-router'
import RouteName from '@/router/RouteName'
import '@/modules/pages/pwn-safe/pwn-safe-detail/pwn-safe-batch-image.css'
import { compareAddresses, isEmpty } from '@/utils/utils'
import type { Address } from 'viem'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import { useConnectedSafeWalletStore } from '@/modules/common/web3/useSafeWalletSdk'

export const usePwnSafeDetailStore = defineStore(StoreIds.pwnSafeDetail, () => {
  const { chainId: connectedChainId, address: safeOrWalletAddress } = useCustomAccount()
  const connectedSafeWalletStore = useConnectedSafeWalletStore()

  const selectedPwnSafe = ref<PwnSafe>()
  const pwnSafes: Record<Address, PwnSafe> = reactive({})
  const searchTerm = ref('')

  // TODO walletAsSafe and ownerAddressAsSafe is more like workaround how to pass Current wallet or owner wallet into transfer options. It should be refactored or changed after design for pwn safe transfer will be completed
  const walletAsSafe = computed(() => new PwnSafe({
    chainId: connectedChainId.value,
    name: 'Current Wallet',
    safeAddress: safeOrWalletAddress.value,
  }))

  const ownerAddressAsSafe = computed(() => {
    return new PwnSafe({
      chainId: connectedChainId.value,
      name: 'Wallet',
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      safeAddress: connectedSafeWalletStore.connectedSafeInfo?.owners?.[0],
    })
  })

  const hasPwnSafe = computed(() => !isEmpty(pwnSafes))
  const hasMoreThanOneSafe = computed(() => Object.keys(pwnSafes)?.length > 1)
  const isSelectedCurrentWallet = computed(() => {
    if (!selectedPwnSafe.value?.safeAddress && safeOrWalletAddress.value) {
      return true
    }
    return compareAddresses(selectedPwnSafe.value?.safeAddress, safeOrWalletAddress.value)
  })

  const disabledChains = computed(() => {
    const unsupportedPwnSafeChains = enabledChains.filter(
      (chain) => !CHAINS_CONSTANTS[chain].pwnSafeContracts,
    )

    if (isSelectedCurrentWallet.value || !selectedPwnSafe.value) {
      return unsupportedPwnSafeChains
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    return enabledChains.filter((chain) => chain !== selectedPwnSafe.value.chainId)
  })

  const setSelectedPwnSafe = (pwnSafe: PwnSafe): void => {
    selectedPwnSafe.value = pwnSafe
  }

  const goBack = (router: Router) => {
    router.push({
      name: RouteName.PwnSafeDetailAssets,
      params: {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        safeAddress: selectedPwnSafe.value.safeAddress,
        chainId: connectedChainId.value,
      },
    })
  }

  //  TODO refactor into BatchImage https://www.notion.so/pwndao/0f177f2e103c4ba0a1fad0c4d2717c79?v=9a8f838972a44acea77fae62931c9d0d&p=be6b3d7566924b9ebbf0e4353d8c934f&pm=c
  const createBatchImage = (assets: AssetWithAmount[]) => {
    // css for pwn-safe-batch-image is defined in Tokenize.vue
    const containerClass = assets[0].isAtrToken
      ? 'pwn-safe-batch-image__container-atr-tokens'
      : 'pwn-safe-batch-image__container-assets'

    const assetClass = assets[0].isAtrToken ? 'pwn-safe-batch-image__atr-token' : 'pwn-safe-batch-image__asset'

    const assetsPlusAmount = assets.length - 3
    switch (assets.length) {
    case 2:
      return h('div', { class: containerClass }, [
        h('img', { class: assetClass, '.src': assets[0].image }),
        h('div', { class: `${assetClass}--empty` }),
        h('div', { class: `${assetClass}--empty` }),
        h('img', { class: assetClass, '.src': assets[1].image }),
      ])
    case 3:
      return h('div', { class: containerClass }, [
        h('img', { class: assetClass, '.src': assets[0].image }),
        h('img', { class: assetClass, '.src': assets[1].image }),
        h('img', { class: assetClass, '.src': assets[2].image }),
      ])
    case 4:
      return h('div', { class: containerClass }, [
        h('img', { class: assetClass, '.src': assets[0].image }),
        h('img', { class: assetClass, '.src': assets[1].image }),
        h('img', { class: assetClass, '.src': assets[2].image }),
        h('img', { class: assetClass, '.src': assets[3].image }),
      ])
    default:
      return h('div', { class: containerClass }, [
        h('img', { class: assetClass, '.src': assets[0].image }),
        h('img', { class: assetClass, '.src': assets[1].image }),
        h('img', { class: assetClass, '.src': assets[2].image }),
        h('div', { class: `${assetClass}--down-right-amount` }, `+${assetsPlusAmount}`),
      ])
    }
  }

  return {
    selectedPwnSafe,
    goBack,
    setSelectedPwnSafe,
    createBatchImage,
    searchTerm,
    pwnSafes,
    hasPwnSafe,
    walletAsSafe,
    disabledChains,
    hasMoreThanOneSafe,
    isSelectedCurrentWallet,
    ownerAddressAsSafe,
  }
})
