<template>
  <div class="pwn-safe-tokenize-modal-only-tokenizing-step__container">
    <p>{{ topParagraphText }}</p>
    <div class="pwn-safe-tokenize-modal-only-tokenizing-step__image-wrapper">
      <component
        :is="BatchImageComponent"
        v-if="isTokenizingMultipleAssets"/>
      <TokenMedia
        v-else
        width="120"
        height="120"
        :token="singleAssetToTokenize!"/>
    </div>
    <p>Please accept the transaction in your wallet</p>
    <div class="pwn-safe-tokenize-modal-only-tokenizing-step__buttons-container">
      <BaseButton
        button-text="previous step"
        :color="ButtonColor.White"
        :is-disabled="isWaitingOnTxConfirmation"
        :variant="ButtonVariant.Underline"
        @on-button-click="goToPreviousStep"/>
      <BaseButton
        :button-text="tokenizeButtonText"
        :is-disabled="isWaitingOnTxConfirmation"
        @on-button-click="startMintAtrTokenMutation"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { computed, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import usePwnSafeTokenizeModal, {
  PwnSafeTokenizeModalStep,
} from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModal'
import { useMutation } from '@tanstack/vue-query'
import { queryMutations } from '@/modules/mutations'
import { sleep } from '@/modules/common/utils'
import { storeToRefs } from 'pinia'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import useActionFlow from '@/modules/common/notifications/useActionFlow'

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const { selectedAssetsToTokenize, isTokenizingMultipleAssets, pwnSafeWhereTokenizing } = usePwnSafeTokenize()

const { activeStep } = usePwnSafeTokenizeModal()

const BatchImageComponent = computed(() => {
  if (!isTokenizingMultipleAssets.value) {
    return
  }

  return pwnSafeDetailStore.createBatchImage(selectedAssetsToTokenize.value)
})

const singleAssetToTokenize = computed(() => {
  if (isTokenizingMultipleAssets.value) {
    return null
  }
  return selectedAssetsToTokenize.value[0]
})

const topParagraphText = computed(() => {
  let assetsInfo: string
  if (isTokenizingMultipleAssets.value) {
    assetsInfo = selectedAssetsToTokenize.value.map((asset) => asset?.primaryInfoWithAmountInput).join(', ')
  } else {
    // @ts-expect-error FIXME: strictNullChecks
    assetsInfo = singleAssetToTokenize.value?.primaryInfoWithAmountInput
  }
  // @ts-expect-error FIXME: strictNullChecks
  return `Minting ATR token for ${assetsInfo} in ${pwnSafeWhereTokenizing.value.name}...`
})

const mutations = queryMutations()
const resetAssetsCacheMutation = useMutation(mutations.safe.batchRefreshAssets)

const mintAtrMutation = useMutation({
  ...mutations.safe.mintAtrTokens,
  async onSuccess(data, variables, context) {
    mutations.safe.mintAtrTokens.onSuccess?.(data, variables, context)
    activeStep.value = PwnSafeTokenizeModalStep.Confirmation

    // This delay is needed to ensure that the assets are refreshed after the transfer and mint
    const delayBeforeRefresh = 7 * 1000
    await sleep(delayBeforeRefresh)
    // TODO do we need this here?
    resetAssetsCacheMutation.mutate({
      safesToRefetch: [variables.safe],
      selectedChains: [variables.safe.chainId!],
      userAddress: getAccount(pwnWagmiConfig).address,
    })
  },
})

const isWaitingOnTxConfirmation = computed(() => {
  return mintAtrMutation.isPending.value
})

const tokenizeButtonText = computed(() => (isWaitingOnTxConfirmation.value ? 'Minting ATR Token' : 'Mint ATR'))

const goToPreviousStep = () => {
  activeStep.value = PwnSafeTokenizeModalStep.AssetDetail
}

let continueFlow
const toast = ref<Toast>()

const startMintAtrTokenMutation = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_MINT_ATR_TOKEN](selectedAssetsToTokenize.value)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      title: 'Mint ATR token',
      chainId: selectedAssetsToTokenize.value?.[0]?.chainId,
      steps: [new ToastStep({
        text: 'Minting...',
        async fn(step) {
          await mintAtrMutation.mutateAsync({
            assetsToTokenize: selectedAssetsToTokenize.value,
            safe: selectedPwnSafe.value!,
            step,
          })
          return true
        },
      })],
    }, NotificationFrontendOnlyActionEnum.TX_MINT_ATR_TOKEN, selectedAssetsToTokenize.value);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}

onMounted(() => {
  startMintAtrTokenMutation()
})
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.pwn-safe-tokenize-modal-only-tokenizing-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
