<template>
  <div class="expiration">
    <div
      v-if="!revokedAt && !isExpired && !isAccepted && !isUnacceptable"
      class="expiration__countdown">
      <div class="expiration__status-dot-container">
        <span>Expires in </span>
      </div>
      <Countdown
        v-if="expiration"
        smallest-units="seconds"
        :expiration-date="new Date(expiration * 1000)"/>
    </div>
    <div
      v-else
      class="expiration__expired">
      <div class="expiration__status-dot-container">
        <span
          v-if="isAccepted"
          class="expiration__status-dot-accepted"/>
        <span
          v-else-if="revokedAt || isExpired"
          class="expiration__status-dot-expired"/>
        <span
          v-else-if="isUnacceptable"
          class="expiration__status-dot-unacceptable"/>
        <span v-if="isAccepted">Accepted</span>
        <span v-else-if="revokedAt">Cancelled</span>
        <span v-else-if="isExpired">Expired</span>
        <span v-else-if="isUnacceptable">Unacceptable</span>
      </div>
      <span v-if="revokedAt">{{ DateUtils.displayDate((revokedAt)) }}</span>
      <span v-else-if="isExpired"> {{ DateUtils.displayDate(new Date(expiration * 1000)) }} </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DateUtils from '@/utils/DateUtils'
import Countdown from '@/general-components/Countdown.vue'
import { computed } from 'vue'
import { DateTime } from 'luxon'

type Props = {
  expiration: number
  revokedAt?: Date | null
  isAccepted?: boolean
  isUnacceptable?: boolean
}
const props = defineProps<Props>()

const isExpired = computed(() => {
  if (!props.expiration) return null

  const expirationTime = DateTime.fromJSDate(new Date(props.expiration * 1000))
  return expirationTime.diffNow().as('milliseconds') < 0
})
</script>

<style lang="postcss" scoped>
.expiration {
  &__expired,
  &__countdown {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__status-dot-container {
    display: flex;
    align-items: baseline;
  }

  &__status-dot-expired {
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
    background: var(--gray-2);
  }

  &__status-dot-accepted {
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
    background: var(--status-active);
  }

  &__status-dot-unacceptable {
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
    background: var(--danger-1);
  }
}
</style>
