<template>
  <BaseModal
    v-model:is-open="store.isOpen"
    :custom-z-index="Number(zIndex)"
    :size="ModalSize.Small"
    :heading="asset?.kycTitle || asset?.collection?.kycTitle"
    :custom-heading-styles="{ 'font-size': '1rem' }"
    :heading-align="'left'"
    :heading-icon-size="ModalHeadingIconSize.Medium"
    :heading-icon="headingIcon">
    <template #body>
      <div
        v-if="asset"
        class="kyc-modal__body">
        <img
          class="kyc-modal__icon"
          :src="asset?.kycLogo || asset?.collection?.kycLogo"
          alt="KYC icon">
        <span class="kyc-modal__description">
          {{ asset?.kycDescription || asset?.collection?.kycDescription }}
        </span>
        <div class="kyc-modal__footer">
          <BaseLink
            v-if="asset?.kycUrl || asset?.collection?.kycUrl"
            :to="asset?.kycUrl || asset?.collection?.kycUrl!"
            target="_blank"
            class="link--font-oxygen-mono">
            {{ 'Read more...' }}
          </BaseLink>
          <div class="kyc-modal__controls">
            <BaseCheckbox
              :model-value="store.skipModal"
              label="Don’t show this again"
              @update:model-value="store.setSkipModal"/>
            <BaseButton
              button-text="Confirm"
              @click="submitModal"/>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">

import BaseModal from '@/general-components/BaseModal.vue'
import { useModalKycStore } from '@/general-components/kyc-modal/useModalKycStore'
import { storeToRefs } from 'pinia'
import headingIcon from '@/assets/icons/exclamation-mark.svg'
import ModalSize from '@/general-components/ModalSize'
import BaseLink from '@/general-components/BaseLink.vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import BaseButton from '@/general-components/BaseButton.vue'
import ModalHeadingIconSize from '@/general-components/ModalHeadingIconSize'
import { useCssVar } from '@vueuse/core'

const store = useModalKycStore()
const { asset } = storeToRefs(store)
const zIndex = useCssVar('--z-index-kyc-modal')
const submitModal = () => {
  store.isOpen = false
  store.handleSkipModal()
  store.submitAction?.()
}
</script>

<style scoped>
.kyc-modal {
  &__body {
    font-size: 0.875rem;
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__icon {
    max-width: fit-content;
    max-height: fit-content;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
  }
}
</style>
