<template>
  <div class="notification-center-settings-header">
    <div class="notification-center-settings-header__title">
      <BaseBackButton/>
      <div class="notification-center-settings-header__wrap">
        <div class="notification-center-settings-header__header-icon-container">
          <BellPlusIconSvg
            class="notification-center-settings-header__header-icon"
            alt="bell icon"/>
        </div>
        <div>
          <h2>Notification Settings</h2>
        </div>
      </div>
    </div>
    <div class="notification-center-settings-header__controls">
      <div
        class="notification-center-settings-header__default-settings-container"
        @click="setDefaultSettings">
        <div class="notification-center-settings-header__default-settings-icon-container">
          <img
            src="@/assets/icons/revert.svg?url"
            alt="revert">
        </div>
        <span class="notification-center-settings-header__default-settings-text">Set to default settings</span>
      </div>
      <div class="notification-center-settings-header__wrap">
        <DiscordNotificationModal/>
        <PushNotificationModal/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'
import PushNotificationModal from '@/modules/pages/notification-center/settings/modals/PushNotificationModal.vue'
import DiscordNotificationModal from '@/modules/pages/notification-center/settings/modals/DiscordNotificationModal.vue'
import BellPlusIconSvg from '@/assets/icons/bell-plus.svg'

const { setDefaultSettings } = useNotificationCenterSettings()
</script>

<style scoped>
.notification-center-settings-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-family: var(--font-family-autoscape);
  padding-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1.5rem;

  &__title {
    display: flex;
  }

  &__wrap {
    display: flex;
    gap: 0.5rem;
  }

  &__header-icon-container {
    display: flex;
  }

  &__header-icon {
    filter: grayscale(1);
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  &__controls {
    display: flex;
    gap: 0.5rem;
  }

  &__default-settings-container {
    display: flex;
    font-family: var(--font-family-oxygen-mono);
    margin-right: 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__default-settings-icon-container,
  &__default-settings-text {
    margin: auto;
  }

  @media only screen and (max-width: 465px) {
    &__controls {
      flex-direction: column;
    }

    &__title {
      flex-direction: column;
      font-size: small;
      gap: 0.5rem;
    }

    &__header-icon {
      margin-left: 0;
    }
  }
}
</style>
