<template>
  <PwnSafePageHeader
    :label="selectedPwnSafe!.name"
    :icon="walletIcon.default"
    :address="selectedPwnSafe!.safeAddress"
    @go-back="goBack"/>
  <PwnSafeActionHeader title="Claim & Burn">
    <template #icons>
      <ClaimIconSvg
        alt="claim"
        class="pwn-safe-claim-and-burn-header__icon"/>
    </template>
    <template #subheading>
      <div class="pwn-safe-transfer-header__subheading">
        {{ actionDescription }}
      </div>
    </template>
  </PwnSafeActionHeader>
  <div class="pwn-safe-claim-and-burn-header__sorting-and-filtering">
    <BaseSearch
      v-model="searchTerm"
      :has-clear-button="hasSearchValue"
      :class="['pwn-safe-claim-and-burn-header__search', {'pwn-safe-claim-and-burn-header__search--selected': hasSearchValue}]"
      search-placeholder="Search asset name/ATR name/address"/>
  </div>
</template>

<script setup lang="ts">
import PwnSafeActionHeader from '@/modules/pages/pwn-safe/PwnSafeActionHeader.vue'
import PwnSafePageHeader from '@/modules/pages/pwn-safe/PwnSafePageHeader.vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import { computed } from 'vue'
import usePwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import * as walletIcon from '@/assets/icons/wallet.svg'
import RouteName from '@/router/RouteName'
import { useRouter } from 'vue-router'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import ClaimIconSvg from '@/assets/icons/claim.svg'
import { storeToRefs } from 'pinia'

const { searchTerm, actionDescription, isClaimBurnInPwnSafe } = usePwnSafeClaimAndBurn()
// TODO fix - selectedPwnSafe is used also for current wallet as workaround
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const router = useRouter()

const hasSearchValue = computed(() => searchTerm.value.length > 0)

const goBack = () => {
  if (isClaimBurnInPwnSafe.value) {
    pwnSafeDetailStore.goBack(router)
  } else {
    router.push({ name: RouteName.PwnSafeCurrentWalletAssets })
  }
}
</script>

<style scoped>
.pwn-safe-claim-and-burn-header {
  &__icon {
    width: 2.375rem;
  }

  &__sorting-and-filtering {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__search {
    min-width: 24rem;
    font-size: 0.75rem;
    border: 1px solid var(--gray);
    padding: 0.25rem;
    height: 2.5rem;

    &--selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__sort {
    margin-left: 1rem;
  }
}
</style>
