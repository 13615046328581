<template>
  <PwnSafeSelectionTable
    v-model:active-sort-option="selectedSelectionSortOption"
    :table-definition="TOKENIZE_SELECTION_TABLE_DEFINITION"
    :sorted-assets="sortedAndFilteredAssetsToTransfer"
    :is-asset-selected="handleIsAssetSelected"
    heading="Selection "
    @on-asset-select="handleOnAssetSelect"/>
  <div class="pwn-safe-tokenize-selection__divider"/>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useAssetsSelection from '@/modules/common/assets/useAssetsSelection'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import PwnSafeSelectionTable from '@/modules/pages/pwn-safe/tables/PwnSafeSelectionTable.vue'
import { TOKENIZE_SELECTION_TABLE_DEFINITION } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'

const { sortedAndFilteredAssetsToTransfer, selectedSelectionSortOption } = usePwnSafeTokenize()
const { addOrRemoveAssetSelection, isAssetSelected } = useAssetsSelection()

const handleOnAssetSelect = (asset: AssetWithAmount) => {
  addOrRemoveAssetSelection({ asset, selectedAssets: sortedAndFilteredAssetsToTransfer })
}

const handleIsAssetSelected = (asset: AssetWithAmount) => {
  return isAssetSelected({ asset, selectedAssets: sortedAndFilteredAssetsToTransfer })
}
</script>

<style scoped>
.pwn-safe-tokenize-selection {
  &__divider {
    margin: 2rem 0;
    height: 1px;
    width: 100%;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px;
  }
}
</style>
