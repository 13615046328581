<template>
  <BaseModal
    v-model:is-open="isOpen"
    :heading="title"
    :size="ModalSize.Medium"
    heading-align="left">
    <template #trigger>
      <BaseButton
        :class="['cancel-proposal-modal__trigger', {'cancel-proposal-modal__trigger--smaller': isSmallerTrigger}]"
        :color="ButtonColor.Orange"
        is-no-enlarge-effect
        has-transparent-background
        :button-text="label ? label : 'Cancel Proposal'"
        is-full-width
        :variant="ButtonVariant.Outline"
        :is-icon-before-text="true"
        @click.stop
        @on-button-click="handleOpenModal">
        <template
          v-if="hasTrashIcon"
          #iconBeforeText>
          <img
            class="cancel-proposal-modal__trigger-icon"
            width="18"
            src="@/assets/icons/trash-orange.svg?url"
            alt="trash icon">
        </template>
      </BaseButton>
    </template>
    <template #body>
      <div class="cancel-proposal-modal__body">
        Are you sure you want to cancel this proposal?
        <BaseButton
          :color="ButtonColor.Orange"
          :size="ButtonSize.XXL"
          :font="ButtonFont.Screener"
          is-full-width
          button-text="Cancel Proposal"
          :is-disabled="isPending"
          @on-button-click="() => handleCancelProposalMutateAsync()"/>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import BaseButton, { ButtonColor, ButtonSize, ButtonVariant, ButtonFont } from '@/general-components/BaseButton.vue'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import ModalSize from '@/general-components/ModalSize'
import BaseModal from '@/general-components/BaseModal.vue'
import { Toast, TOAST_ACTION_ID_TO_UNIQUE_ID_FN, ToastStep } from '@/modules/common/notifications/useToastsStore'
import { getChainId, waitForTransactionReceipt } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import {
  NotificationActionEnumBackendSchema,
} from '@/modules/common/backend/generated'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import type { Ref } from 'vue'
import { useMutation } from '@tanstack/vue-query'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import {
  pwnV1SimpleLoanListOfferAbi,
  pwnV1SimpleLoanSimpleOfferAbi,
  pwnV1SimpleLoanSimpleRequestAbi,
} from '@/contracts/generated'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { revokeStarknetNonces } from '@/revamp/hooks/thesis/starknet/proposals'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

const isOpen = ref(false)

interface Props {
    proposalClass: BaseProposal
    label?: string
    isSmallerTrigger?: boolean
    isProposalPage?: boolean
    hasTrashIcon?: boolean
}
const props = defineProps<Props>()

const handleOpenModal = () => {
  isOpen.value = true
}

const title = computed(() => `Cancel Proposal #${props.proposalClass.id}`)
const emit = defineEmits<{(e: 'on-cancel-proposal'): void;}>()

const handleV1_1Proposal = async (step: ToastStep) => {
  // TODO remove after all proposals v1.1 will expire or be canceled?
  if (props.proposalClass.isOffer) {
    if (props.proposalClass.isV1_1CollectionOffer) {
      const receipt = await sendTransaction({
        abi: pwnV1SimpleLoanListOfferAbi,
        functionName: 'revokeOfferNonce',
        args: [props.proposalClass.nonce],
        address: CHAINS_CONSTANTS[props.proposalClass.chainId].pwnV1Contracts.pwnSimpleLoanListOffer,
        chainId: props.proposalClass.chainId,
      }, { step })
      return receipt?.status === 'success'
    } else {
      const receipt = await sendTransaction({
        abi: pwnV1SimpleLoanSimpleOfferAbi,
        address: CHAINS_CONSTANTS[props.proposalClass.chainId].pwnV1Contracts.pwnSimpleLoanSimpleOffer,
        args: [props.proposalClass.nonce],
        chainId: props.proposalClass.chainId,
        functionName: 'revokeOfferNonce',
      }, { step })
      return receipt?.status === 'success'
    }
  }

  const receipt = await sendTransaction({
    abi: pwnV1SimpleLoanSimpleRequestAbi,
    address: CHAINS_CONSTANTS[props.proposalClass.chainId].pwnV1Contracts.pwnSimpleLoanSimpleRequest,
    args: [props.proposalClass.nonce],
    chainId: props.proposalClass.chainId,
    functionName: 'revokeRequestNonce',
  }, { step })
  return receipt?.status === 'success'
}

const handleCancelProposal = async () => {
  let continueFlow: () => Promise<void>
  const toast = ref<Toast>()
  const getCreateLoanToastSteps = () => {
    const toastSteps: ToastStep[] = []

    if (!isStarknet) {
      const connectedChainId = getChainId(pwnWagmiConfig)
      if (connectedChainId !== props.proposalClass.chainId) {
        toastSteps.push(ToastStep.createSwitchChainStep(props.proposalClass.chainId))
      }
    }

    toastSteps.push(new ToastStep({
      text: 'Revoking proposal...',
      async fn(step) {
        if (isStarknet) {
          return await revokeStarknetNonces([props.proposalClass.nonce])
        }

        if (props.proposalClass.isV1_1Proposal) {
          return await handleV1_1Proposal(step)
        }

        const hash = await props.proposalClass.revokeNonce()
        const receipt = await waitForTransactionReceipt(pwnWagmiConfig, { hash })
        return receipt?.status === 'success'
      },
    }))

    return toastSteps
  }

  const handleCancelProposal = async () => {
    // TODO for what we use loanId?
    const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationActionEnumBackendSchema.PROPOSAL_REVOKED](props.proposalClass.id)
    if (toast.value?.id !== actionId) {
      toast.value = new Toast({
        title: `Proposal #${props.proposalClass.id}`,
        chainId: props.proposalClass.chainId,
        steps: getCreateLoanToastSteps(),
        firstAsset: props.proposalClass.collateral,
        secondAsset: props.proposalClass.creditAsset,
      }, NotificationActionEnumBackendSchema.PROPOSAL_REVOKED, props.proposalClass.id);
      ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
    }

    await continueFlow()
  }
  await handleCancelProposal()
}

const { mutateAsync: handleCancelProposalMutateAsync, isPending } = useMutation({
  mutationFn: async() => { return await handleCancelProposal() },
  onSuccess: (data, variables, context) => {
    isOpen.value = false
    emit('on-cancel-proposal')
    // invalidate and re-fetch queries are handled by sseListener
  },
})

</script>

<style lang="postcss" scoped>
.cancel-proposal-modal {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    font-size: 18px;
    margin-top: 2rem;
  }

  &__trigger-icon {
    margin-right: 0.2rem;
  }

  &__trigger {
    background: transparent !important;

    &--smaller {
      width: 6rem;
      height: 2rem;
    }
  }
}

</style>
