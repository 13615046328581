import type { AmountInEthAndUsd, PriceRange } from '@/modules/common/assets/typings/prices'

export enum StatValueTypeEnum {
  Price = 'price',
  PercentChange = 'percent-change',
  PredictedRange = 'predicted-range',
  Date = 'date',
  Text = 'text',
}

export type AllowedStatValueTypes = AmountInEthAndUsd | string | number | Date | PriceRange

export interface CollectionOrAppraisalRow {
  description: {
    label: string
    questionMarkTooltip: string
  }
  value: {
    type: StatValueTypeEnum
    value: AllowedStatValueTypes
  },
}
