/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

export type NFTOrderTypeEnumBackendSchema = typeof NFTOrderTypeEnumBackendSchema[keyof typeof NFTOrderTypeEnumBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NFTOrderTypeEnumBackendSchema = {
  listing: 'listing',
  offer: 'offer',
} as const;
