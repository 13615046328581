import { computed, readonly, ref } from 'vue'
import NFTEvent from '@/modules/common/assets/typings/NFTEvent'
import type { SupportedChain } from '@/constants/chains/types'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import { fetchNftSaleEvents, fetchNftTransferAndMintEvents } from '@/modules/common/backend/generated'
import to from '@/utils/await-to-js'
import { sortNftEventsByDate } from '@/general-components/sorting/SortFunctions'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import type { Address } from 'viem'

const saleEvents = ref<NFTEvent[]>([])
const transferAndMintEvents = ref<NFTEvent[]>([])
const nftEvents = computed<NFTEvent[]>(() => {
  const allEvents = saleEvents.value.concat(transferAndMintEvents.value)
  return sortNftEventsByDate(allEvents, SortDirection.Ascending)
})
const isFetchingSaleEvents = ref(false)
const isFetchingTransferEvents = ref(false)

export default function useSectionNFTEvents() {
  const clearEvents = (): void => {
    saleEvents.value = []
    transferAndMintEvents.value = []
  }

  const loadAllTransferAndMintEvents = async (chainId: SupportedChain, contractAddress: Address, tokenId: string): Promise<void> => {
    isFetchingTransferEvents.value = true
    const [error, response] = await to(fetchNftTransferAndMintEvents(
      String(chainId),
      contractAddress,
      tokenId,
    ))
    if (!error && response?.data) {
      for (const event of response.data.nft_events ?? []) {
        const parsedEvent = NFTEvent.createFromBackendResponse(event)
        if (parsedEvent) {
          transferAndMintEvents.value.push(parsedEvent)
        }
      }
    }
    isFetchingTransferEvents.value = false
  }

  const loadAllSaleEventsFromMarketplace = async (marketplace: DataSourceType.OPENSEA | DataSourceType.LOOKSRARE, chainId: SupportedChain, contractAddress: Address, tokenId: string): Promise<void> => {
    isFetchingSaleEvents.value = true
    let next: string | undefined
    do {
      const [error, response] = await to(fetchNftSaleEvents(
        String(chainId),
        contractAddress,
        tokenId,
        {
          marketplace,
          ...(next && { page: next }),
        }))
      if (error || !response?.data) {
        break
      }

      next = response?.data?.next ?? undefined

      for (const nftEvent of response.data.nft_events ?? []) {
        const parsedEvent = NFTEvent.createFromBackendResponse(nftEvent)
        if (parsedEvent) {
          saleEvents.value.push(parsedEvent)
        }
      }
    } while (next)
    saleEvents.value.sort((a, b) => a.date.getTime() - b.date.getTime())
    isFetchingSaleEvents.value = false
  }

  const loadAllNftEvents = async (chainId: SupportedChain, contractAddress: Address, tokenId: string): Promise<void> => {
    const MARKETPLACES_TO_FETCH = [DataSourceType.OPENSEA, DataSourceType.LOOKSRARE] as const
    await to(Promise.all([
      ...MARKETPLACES_TO_FETCH.map(marketplace => loadAllSaleEventsFromMarketplace(
        marketplace,
        chainId,
        contractAddress,
        tokenId,
      )),
      loadAllTransferAndMintEvents(chainId, contractAddress, tokenId),
    ]), { additionalErrorInfo: { chainIdToFetch: chainId, contractAddressToFetch: contractAddress, tokenIdToFetch: tokenId } })
  }

  const hasSaleEvents = computed(() => saleEvents.value?.length)

  const isFetchingEvents = computed(() => isFetchingSaleEvents.value || isFetchingTransferEvents.value)

  return {
    nftEvents: computed(() => nftEvents.value),
    saleEvents,
    hasSaleEvents,
    loadAllNftEvents,
    isFetchingSaleEvents: readonly(isFetchingSaleEvents),
    isFetchingTransferEvents: readonly(isFetchingTransferEvents),
    isFetchingEvents,
    clearEvents,
  }
}
