import { computed, ref, unref } from 'vue'
import type { MaybeRef } from 'vue'
import { useFetchAssetMetadata } from '@/modules/common/backend/generated'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useRouteQuery } from '@vueuse/router'
import { getAddress, isAddress, zeroAddress } from 'viem'
import type { Address } from 'viem'
import router from '@/router'
import { useUserNfts } from '@/revamp/hooks/useUserNfts'
import { useUserTokens } from '@/revamp/hooks/useUserTokens'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import AssetType from '@/modules/common/assets/AssetType'
import { compareAddresses } from '@/utils/utils'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import { useQuery } from '@tanstack/vue-query'

export const usePageParamsAsset = () => {
  const tokenId = useRouteQuery('p', null, {
    transform(val) {
      return val ? String(val) : ''
    },
  })
  const address = useRouteQuery('w', '', {
    transform(val) {
      if (val === '') return null
      if (val === 'stables') return zeroAddress
      return getAddress(val)
    },
  })
  const chainId = useRouteQuery('n', null, {
    transform(val) {
      return val ? String(val) : '1'
    },
  })

  const assetIsNativeToken = computed(() => address.value && compareAddresses(zeroAddress, address.value))

  const isQueryEnabled = computed(() => {
    return !!address.value && !!chainId.value && !assetIsNativeToken.value
  })

  const isPositionAsset = ref(false)

  const tokenIdOrNone = computed(() => {
    return tokenId.value
  })

  const metadata = useFetchAssetMetadata(
    chainId as MaybeRef<string>,
    address as MaybeRef<Address>,
    tokenIdOrNone as MaybeRef<string>,
    {},
    {
      query: {
        enabled: isQueryEnabled,
      },
    },
  )
  const { address: userAddress } = useCustomAccount()
  const parsedChainId = computed(() => Number(chainId.value))

  const { fetchNativeToken } = useERC20Fetch()

  const nativeTokenFetchIsEnabled = computed(() => {
    return !!(userAddress.value && assetIsNativeToken.value && isAddress(userAddress.value))
  })

  const nativeTokenData = useQuery({
    queryKey: ['nativeToken', parsedChainId, userAddress],
    queryFn: async ({ queryKey }) => {
      const [, chainId, address] = queryKey
      return fetchNativeToken(Number(chainId), String(address) as Address)
    },
    enabled: nativeTokenFetchIsEnabled,
  })

  const { nfts } = useUserNfts(userAddress, parsedChainId)
  const { tokens } = useUserTokens(userAddress, parsedChainId)

  const getAssetBalanceFromExistingTokens = (asset: AssetWithAmount) => {
    if (asset.category === AssetType.ERC20) {
      const token = tokens.value.find((t) => compareAddresses(t.address, asset.address))
      return token?.amount || ''
    } else {
      const nft = nfts.value.find((n) => compareAddresses(n.address, asset.address) && n.tokenId === asset.tokenId)
      return nft?.amount || ''
    }
  }

  const parsedAsset = computed(() => {
    if (isPositionAsset.value) {
      return
    }
    if (nativeTokenData.isSuccess && assetIsNativeToken.value) {
      return nativeTokenData.data.value
    }
    if (metadata.data.value?.data && metadata.isSuccess && unref(address)) {
      const asset = AssetWithAmount.createFromBackendModel({
        ...metadata.data.value.data,
        balance: '',
      })

      const balance = getAssetBalanceFromExistingTokens(asset)

      const assetWithBalance = asset.updateAssetAmounts({ amount: balance })
      return assetWithBalance
    }

    return null
  })

  return {
    parsedAsset,
    setQueryParams: (asset: AssetWithAmount | null) => {
      if (!asset) {
        router.replace({
          query: {},
        })
        return
      }
      const isAllStables = asset.symbol === '$PWN-ALL-YOUR-STABLES'
      if (isAllStables) {
        return
      }

      const query = {
        p: asset.tokenId ? String(asset.tokenId) : '',
        w: asset.address,
        n: String(asset.chainId),
      }

      if (asset.sourceOfFunds) {
        isPositionAsset.value = true
      } else isPositionAsset.value = false

      router.replace({
        query,
      })
    },
  }
}
