<template>
  <div class="pwn-safe-transfer-modal-transferring-step__container">
    <p>
      {{ topParagraphText }}
    </p>
    <p>Please accept the transaction in your wallet</p>
    <div class="pwn-safe-transfer-modal-transferring-step__buttons-container">
      <BaseButton
        button-text="previous step"
        :color="ButtonColor.White"
        :variant="ButtonVariant.Underline"
        @on-button-click="goToPreviousStep"/>
      <BaseButton
        :button-text="transferButtonText"
        :is-disabled="isPending"
        @on-button-click="handleTransferAssetsClick"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import { computed, onMounted } from 'vue'
import usePwnSafeTransferModal, {
  TransferModalStep,
} from '@/modules/pages/pwn-safe/transfer/modal/usePwnSafeTransferModal'
import { useMutation } from '@tanstack/vue-query'
import { queryMutations } from '@/modules/mutations'
import usePwnSafeTransferAssetsTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransferAssetsTransfer'
import { sleep } from '@/modules/common/utils'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { activeStep, transferringAssetsInfo, isOpen } = usePwnSafeTransferModal()
const { fromWallet, toWallet, assetsToTransfer } = usePwnSafeTransfer()
const { transferAssets } = usePwnSafeTransferAssetsTransfer()

const mutations = queryMutations()
const resetAssetsCacheMutation = useMutation(mutations.safe.batchRefreshAssets)
const { address: userAddress } = useCustomAccount()

const { mutateAsync: transferAssetsMutateAsync, isPending } = useMutation({
  ...mutations.safe.transferAssets,
  // it's declared here as function uses scope context
  mutationFn: (variables) => {
    return transferAssets(variables.assetsToTransfer)
  },
  async onSuccess(data, variables, context) {
    mutations.safe.transferAssets.onSuccess?.(data, variables, context)
    // extra logic here
    isOpen.value = false

    const delayBeforeRefresh = 7 * 1000
    await sleep(delayBeforeRefresh)
    resetAssetsCacheMutation.mutate({
      safesToRefetch: [variables.fromSafe, variables.toSafe],
      selectedChains: [variables.fromSafe.chainId!],
      userAddress: userAddress.value,
    })
  },
})

const handleTransferAssetsClick = () => {
  transferAssetsMutateAsync({
    toSafe: toWallet.value!,
    fromSafe: fromWallet.value!,
    assetsToTransfer: assetsToTransfer.value,
  })
}

const transferButtonText = computed(() => (isPending.value ? 'Transferring' : 'Transfer'))

const topParagraphText = computed(() => {
  return `Transferring ${transferringAssetsInfo.value} to ${toWallet.value?.name}...`
})

const goToPreviousStep = () => {
  activeStep.value = TransferModalStep.Input
}

onMounted(() => {
  handleTransferAssetsClick()
})
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.pwn-safe-transfer-modal-transferring-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
