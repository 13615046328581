import type { BaseSearchResult } from '@/modules/common/pwn/explorer/models/index'
import type { UserDocumentBackendSchema } from '@/modules/common/backend/generated'
import type { Address } from 'viem'
import { useEnsStore } from '@/modules/common/web3/useEnsStore'
import { shortenAddress } from '@/utils/utils'
import type { RouteLocationRaw } from 'vue-router'
import RouteName from '@/router/RouteName'
import { getAddress } from 'viem'

export class WalletSearchResult implements BaseSearchResult {
  address: Address
  name: string | null | undefined

  image: string
  isVerified = false
  chainId = undefined

  constructor(wallet: Partial<WalletSearchResult>) {
    this.address = getAddress(wallet.address!)
    this.name = wallet?.name
    this.image = wallet?.image ? wallet.image : ''
  }

  public static async createFromBackendModel(wallet: UserDocumentBackendSchema): Promise<WalletSearchResult> {
    const instance = new WalletSearchResult({
      address: wallet?.wallet_address as Address,
      name: wallet?.ens_name,
    })
    await instance.resolveEns()
    return instance
  }

  async resolveEns(): Promise<void> {
    const resolvedAddress = await useEnsStore().resolveAddress(this.address)
    if (!resolvedAddress) {
      return
    }
    this.name = resolvedAddress.name
    this.image = resolvedAddress.avatar ? resolvedAddress.avatar : ''
  }

  get title(): string {
    if (this.name) {
      return this.name
    }
    return this.addressShort
  }

  get addressShort(): string {
    return shortenAddress(this.address)
  }

  get key(): string {
    return this.address
  }

  pageRoute(): RouteLocationRaw {
    return {
      name: RouteName.Dashboard,
      params: {
        user: this.address,
      },
    }
  }
}
