import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import {
  Toast,
  TOAST_ACTION_ID_TO_UNIQUE_ID_FN,
  ToastStep,
  useToastsStore,
} from '@/modules/common/notifications/useToastsStore'
import { NotificationActionEnumBackendSchema } from '@/modules/common/backend/generated'
import { ref, h } from 'vue'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import type { Address, Hex, TransactionReceipt } from 'viem'
import { maxUint256, parseEventLogs, zeroAddress } from 'viem'
import { pwnV1_2SimpleLoanAbi, pwnV1SimpleLoanAbi } from '@/contracts/abis'
import { getChainName } from '@/utils/chain'
import BaseButton from '@/general-components/BaseButton.vue'
import ArrowRightPrimaryColor from '@/assets/icons/arrow-right-primary-color.svg'
import RouteName from '@/router/RouteName'
import router from '@/router'
import { V1SimpleLoan } from '@/modules/common/pwn/loans/LoanClasses'
import { AssetMetadata, DesiredLoanTerms } from '@/modules/common/assets/AssetClasses'
import { V1SimpleLoanListOffer, V1SimpleLoanSimpleOffer } from '@/modules/common/pwn/offers/OfferClasses'
import useApprove from '@/modules/common/assets/useApprove'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { getLoanTokenAddressForProposalOrLoan } from '@/modules/common/pwn/utils'
import { useAnyChainId } from '@/modules/common/adapters/connecting-utils'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { useApproveThesisAssets } from '@/revamp/hooks/thesis/useApproveThesisAssets'
import { V1_2SimpleLoanFungibleProposal } from '@/modules/common/pwn/proposals/ProposalClasses'

export const useAcceptProposal = () => {
  const txReceipt = ref<TransactionReceipt>()
  const toastsStore = useToastsStore()

  return {
    acceptProposal: async ({
      acceptorAddress,
      proposalClass,
      isAcceptorApproved,
      isProposalPage = false,
      collateralBigIntAmountToCheck,
    }: {
     acceptorAddress: Address,
     proposalClass: BaseProposal,
     isAcceptorApproved: boolean,
     isProposalPage?: boolean,
     collateralBigIntAmountToCheck?: bigint
   },
    ) => {
      if (!proposalClass) return false

      let continueFlow: () => Promise<void>
      const toast = ref<Toast>()

      if (!isProposalPage) {
        toastsStore.customBottomComponent = h(
          BaseButton,
          {
            to: {
              name: RouteName.RevampLoanDetail,
              params: {
                id: '0',
                chainName: getChainName(proposalClass.chainId).toLowerCase(),
                loanTokenContractAddress: getLoanTokenAddressForProposalOrLoan(proposalClass.chainId, proposalClass.type!),
              },
            },
            'button-text': 'Go to loan',
            'is-disabled': true,
          },
          {
            'icon-after-text': () => h(ArrowRightPrimaryColor),
          })
      }

      const acceptV1_1Proposal = async (step: ToastStep) => {
        if (!proposalClass.isOffer) {
          const loan = new V1SimpleLoan({
            borrower: proposalClass.proposer,
            chainId: proposalClass.chainId,
            collateral: proposalClass.collateral,
            contractAddress: CHAINS_CONSTANTS[proposalClass.chainId].pwnV1Contracts.pwnSimpleLoanSimpleRequest,
            desiredLoanTerms: new DesiredLoanTerms({
              address: proposalClass.creditAsset.address,
              amount: proposalClass.creditAmount.toString(),
              fixedInterestAmount: proposalClass.fixedInterestAmount,
              loanRequestSignature: proposalClass.proposalSignature,
              desiredRepayment: String(Number(proposalClass.repaymentAmount) / (10 ** (proposalClass.creditAsset?.decimals ?? 0))),
              durationDays: proposalClass.loanDurationDays,
              loanExpirationDate: new Date(proposalClass.expiration * 1000), // TODO check this
              loanRequestNonce: proposalClass.nonce,
            }),
            lender: acceptorAddress,
          })
          return await loan.loanRequestContract.fundInstantlyLoan(loan, step)
        }

        if (proposalClass.isV1_1SimpleOffer) {
          const offer = new V1SimpleLoanSimpleOffer({
            borrower: zeroAddress,
            chainId: proposalClass.chainId,
            collateral: proposalClass.collateral,
            contractAddress: CHAINS_CONSTANTS[proposalClass.chainId].pwnV1Contracts.pwnSimpleLoanSimpleOffer,
            expiration: proposalClass.expiration, // TODO check this
            fixedInterestAmount: proposalClass.fixedInterestAmount,
            lender: proposalClass.proposer,
            loanAsset: proposalClass.creditAsset,
            loanDurationDays: proposalClass.loanDurationDays,
            nonce: proposalClass.nonce,
            offerSignature: proposalClass.proposalSignature as Hex,
            repaymentAmount: String(Number(proposalClass.repaymentAmount) / (10 ** (proposalClass.creditAsset?.decimals ?? 0))),
          })
          return await offer.offerContract.acceptOffer(offer, undefined, step)
        }

        if (proposalClass.isV1_1CollectionOffer) {
          const listOffer = new V1SimpleLoanListOffer({
            chainId: proposalClass.chainId,
            collateral: proposalClass.collateral,
            contractAddress: CHAINS_CONSTANTS[proposalClass.chainId].pwnV1Contracts.pwnSimpleLoanListOffer,
            expiration: proposalClass.expiration,
            fixedInterestAmount: proposalClass.fixedInterestAmount, // TODO check this value
            lender: proposalClass.proposer,
            loanAsset: proposalClass.creditAsset,
            loanDurationDays: proposalClass.loanDurationDays,
            nonce: proposalClass.nonce,
            offerSignature: proposalClass.proposalSignature as Hex,
            repaymentAmount: String(Number(proposalClass.repaymentAmount) / (10 ** (proposalClass.creditAsset?.decimals ?? 0))),
          })
          return await listOffer.offerContract.acceptOffer(listOffer, undefined, step)
        }
      }

      const getCreateLoanToastSteps = () => {
        const toastSteps: ToastStep[] = []

        const connectedChainId = useAnyChainId()
        // todo: handle for starknet
        if (connectedChainId !== proposalClass.chainId) {
          toastSteps.push(ToastStep.createSwitchChainStep(proposalClass.chainId))
        }

        if (!isAcceptorApproved) {
          // here we are checking the opposite asset to approve
          const assetToCheck = proposalClass?.isOffer ? proposalClass?.collateral : proposalClass?.creditAsset
          if (!assetToCheck) throw new Error('Asset to check is not defined')
          if (useApprove().isNeedRemovePreviousApproval(proposalClass.chainId, assetToCheck.address, assetToCheck.category)) {
            toastSteps.push(new ToastStep({
              text: 'Checking approval...',
              async fn(step) {
                if (!proposalClass) return false
                if (!proposalClass) return false
                const existingAllowance = await useApprove().getPreviousApprovalAmount(
                  proposalClass.chainId,
                  assetToCheck.address,
                  assetToCheck.category,
                  CHAINS_CONSTANTS[proposalClass.chainId].pwnV1_2Contracts.pwnSimpleLoan,
                  acceptorAddress,
                )
                if (existingAllowance !== null && existingAllowance > 0n && useApprove().isNeedRemovePreviousApproval(
                  proposalClass.chainId,
                  assetToCheck.address,
                  assetToCheck.category,
                )) {
                  step.text = 'Removing previous approval...'
                  const isApproved = await useApprove().approve({
                    asset: assetToCheck,
                    amount: 0n,
                    spender: CHAINS_CONSTANTS[proposalClass.chainId].pwnV1_2Contracts.pwnSimpleLoan,
                  }, step)
                  return Boolean(isApproved)
                }
                return true
              },
            }))
          }
        }

        if (!proposalClass.isOffer && !isAcceptorApproved) {
          if (isStarknet) {
            // TODO: Might introduce a slight delay after this since sometimes the create loan transaction cannot be accepted in Argent because it thinks it's going to fail, when you try again, it works. So I think it's because it thinks the asset is not approved
            const result = useApproveThesisAssets().approveThesisAssets([proposalClass.collateral], proposalClass.loanContractAddress, proposalClass.chainId)
            toastSteps.push(...result)
          } else {
            toastSteps.push(new ToastStep({
              text: proposalClass.isOffer ? 'Approving Collateral' : 'Approving Asset...',
              async fn(step) {
                const minAllowanceAmountToCheck = proposalClass instanceof V1_2SimpleLoanFungibleProposal
                  ? proposalClass.creditAmountToAccept ?? proposalClass.creditAsset.amountRaw
                  : proposalClass.creditAsset.amountRaw
                return await useApprove().approve({
                  asset: new AssetMetadata({
                    address: proposalClass.creditAsset.address,
                    chainId: proposalClass.creditAsset.chainId,
                    category: proposalClass.creditAsset.category,
                  }),
                  spender: proposalClass.loanContractAddress || '0x',
                  amount: maxUint256,
                  minAllowanceAmountToCheck,
                })
              },
            }))
          }
        }

        if (proposalClass.isOffer && !isAcceptorApproved) {
          if (isStarknet) {
            const result = useApproveThesisAssets().approveThesisAssets([proposalClass.collateral], proposalClass.loanContractAddress, proposalClass.chainId)
            toastSteps.push(...result)
          } else {
            toastSteps.push(new ToastStep({
              text: 'Approving Collateral',
              async fn(step) {
                return await useApprove().approve({
                  asset: new AssetMetadata({
                    address: proposalClass.collateral.address,
                    chainId: proposalClass.collateral.chainId,
                    category: proposalClass.collateral.category,
                  }),
                  spender: proposalClass.loanContractAddress || '0x',
                  amount: maxUint256,
                  minAllowanceAmountToCheck: collateralBigIntAmountToCheck ?? proposalClass.collateral.amountRaw,
                })
              },
            }))
          }
        }

        toastSteps.push(new ToastStep({
          text: 'Creating loan...',
          async fn(step) {
            if (proposalClass.isV1_1Proposal) {
              txReceipt.value = await acceptV1_1Proposal(step)
              return true
            }
            txReceipt.value = await proposalClass.acceptProposal(acceptorAddress, step)
            return true
          },
        }))

        return toastSteps
      }

      const handleCreateLoan = async () => {
        const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationActionEnumBackendSchema.LOAN_CREATED](proposalClass.collateral)
        if (toast.value?.id !== actionId) {
          toast.value = new Toast({
            title: `Accept Proposal #${proposalClass.id}`,
            chainId: proposalClass.chainId,
            steps: getCreateLoanToastSteps(),
            firstAsset: proposalClass.collateral,
          }, NotificationActionEnumBackendSchema.LOAN_CREATED, proposalClass.collateral);
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
        }

        await continueFlow()
      }

      await handleCreateLoan()
      if (!txReceipt.value) return

      const logs = !isStarknet
        ? parseEventLogs({
          abi: proposalClass.isV1_1Proposal ? pwnV1SimpleLoanAbi : pwnV1_2SimpleLoanAbi,
          eventName: 'LOANCreated',
          logs: txReceipt.value?.logs,
        })
        : ''
      // @ts-expect-error Property 'loan_id' does not exist on type 'TransactionReceipt'.
      const loanId = !isStarknet ? logs[0].args.loanId.toString() : txReceipt.value?.loan_id

      if (!isProposalPage) {
        toastsStore.customBottomComponent = h(
          BaseButton,
          {
            to: {
              name: RouteName.RevampLoanDetail,
              params: {
                id: loanId,
                chainName: getChainName(proposalClass.chainId).toLowerCase(),
                loanTokenContractAddress: getLoanTokenAddressForProposalOrLoan(proposalClass.chainId, proposalClass.type!),
              },
            },
            'button-text': 'Go to loan',
            'is-disabled': false,
          },
          {
            'icon-after-text': () => h(ArrowRightPrimaryColor),
          })
      }

      if (isProposalPage) {
        await router.push({
          name: RouteName.RevampLoanDetail,
          params: {
            id: loanId,
            chainName: getChainName(proposalClass.chainId).toLowerCase(),
            loanTokenContractAddress: getLoanTokenAddressForProposalOrLoan(proposalClass.chainId, proposalClass.type!),
          },
        })
      }

      // invalidate and re-fetch queries are handled by sseListener
    },
  }
}
