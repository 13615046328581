import { ChainIdEnumBackendSchema } from '@/modules/common/backend/generated'
import { SupportedChain } from '@/constants/chains/types'

export const parseChainIdFromResponse = (chainId: ChainIdEnumBackendSchema): SupportedChain => {
  switch (chainId) {
  case ChainIdEnumBackendSchema.NUMBER_1: return SupportedChain.Ethereum
  case ChainIdEnumBackendSchema.NUMBER_11155111: return SupportedChain.Sepolia
  case ChainIdEnumBackendSchema.NUMBER_10: return SupportedChain.Optimism
  case ChainIdEnumBackendSchema.NUMBER_137: return SupportedChain.Polygon
  case ChainIdEnumBackendSchema.NUMBER_25: return SupportedChain.Cronos
  case ChainIdEnumBackendSchema.NUMBER_8453: return SupportedChain.Base
  case ChainIdEnumBackendSchema.NUMBER_42161: return SupportedChain.Arbitrum
  case ChainIdEnumBackendSchema.NUMBER_56: return SupportedChain.Bsc
  case ChainIdEnumBackendSchema.NUMBER_100: return SupportedChain.Gnosis
  case ChainIdEnumBackendSchema.NUMBER_1301: return SupportedChain.UnichainSepolia
  case ChainIdEnumBackendSchema.NUMBER_112211: return SupportedChain.StarknetMainnet
  case ChainIdEnumBackendSchema.NUMBER_11155112: return SupportedChain.StarknetSepolia
  default: throw new TypeError(`Unknown chainId: ${chainId} received from backend.`)
  }
}

export const parseChainIdToResponse = (chainId: SupportedChain): ChainIdEnumBackendSchema => {
  switch (chainId) {
  case SupportedChain.Ethereum: return ChainIdEnumBackendSchema.NUMBER_1
  case SupportedChain.Sepolia: return ChainIdEnumBackendSchema.NUMBER_11155111
  case SupportedChain.Optimism: return ChainIdEnumBackendSchema.NUMBER_10
  case SupportedChain.Polygon: return ChainIdEnumBackendSchema.NUMBER_137
  case SupportedChain.Cronos: return ChainIdEnumBackendSchema.NUMBER_25
  case SupportedChain.Base: return ChainIdEnumBackendSchema.NUMBER_8453
  case SupportedChain.Arbitrum: return ChainIdEnumBackendSchema.NUMBER_42161
  case SupportedChain.Bsc: return ChainIdEnumBackendSchema.NUMBER_56
  case SupportedChain.UnichainSepolia: return ChainIdEnumBackendSchema.NUMBER_1301
  case SupportedChain.Gnosis: return ChainIdEnumBackendSchema.NUMBER_100
  case SupportedChain.StarknetMainnet: return ChainIdEnumBackendSchema.NUMBER_112211
  case SupportedChain.StarknetSepolia: return ChainIdEnumBackendSchema.NUMBER_11155112
  default: throw new TypeError(`Unknown chainId: ${chainId} received from backend.`)
  }
}
