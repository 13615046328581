<template>
  <RevampBaseModal
    v-model:is-open="isOpen"
    :heading="headerText || 'Select Your Lent Asset'"
    class-name="select-lent-asset"
    heading-align="left">
    <template #trigger>
      <button
        class="select-lent-asset__token-select"
        tabindex="0"
        type="button"
        @click="isOpen = true">
        <span v-if="!isAssetSelected"> Select Asset </span>
        <div
          v-else-if="tokenIsMultiStables"
          class="select-lent-asset__selected-asset-container">
          <div class="select-lent-asset__selected-asset">
            <div class="select-lent-asset__selected-asset-media">
              <TokenMedia
                v-for="(asset, index) in props.selectedToken?.bundleAssets"
                :key="asset.id"
                :token="asset"
                :style="{
                  marginLeft: index > 0 ? '-15px' : '0',
                  zIndex: index,
                }"
                height="20"
                width="20"/>
            </div>
            Stables
          </div>
        </div>
        <div
          v-else-if="selectedToken"
          class="select-lent-asset__selected-asset-container">
          <div class="select-lent-asset__selected-asset">
            <TokenMedia
              :token="selectedToken"
              height="20"
              width="20"/>
            <SafeText :text="selectedToken.symbol"/>
          </div>
        </div>
        <ArrowSelectSvg/>
      </button>
    </template>
    <template #body>
      <div
        v-if="!userSelectsCredits"
        class="select-lent-asset__search">
        <SearchBar
          v-model="searchBar"
          class="select-lent-asset__search-bar"
          placeholder="Select asset name or address"/>
        <RefreshButton
          :handle-refresh="handleRefreshAssets"
          class="select-lent-asset__refresh-button"
          :is-loading="isRefreshingCache"/>
      </div>

      <div
        v-if="!userSelectsCredits"
        class="select-lent-asset__filters-row">
        <ChainGroupFilter
          :show-all-networks="false"
          :selected-chains="[Number(selectedChain)]"
          :disabled-chains="disabledChains"
          :set-chain="handleSetSelectedChain"/>
        <div class="select-lent-asset__currency-switcher">
          <CurrencySwitcher/>
        </div>

        <GlobalFilters
          v-if="!isPwnSpace && !userSelectsCredits"
          x-direction="left"
          y-direction="bottom"
          :display-pricing-filter="false">
          <template #appendOnRight>
            <span
              v-if="assetsHiddenByFilter >= 1"
              class="select-lent-asset__filter-detail">
              Hidden Assets ({{ assetsHiddenByFilter }})
            </span>
          </template>
        </GlobalFilters>
      </div>

      <ConnectWallet v-if="status !== 'connected'"/>

      <div
        v-else-if="userSelectsCredits"
        class="select-lent-asset__content">
        <UserTokensWithAmountTable
          :user-tokens="tokens"
          :tokens-to-display="allStablesTokens"
          :form-is-submitting="false"
          is-selectable
          :handle-submit="handleSelectedMultipleCredits"
          :go-back="() => {
            userSelectsCredits = false
          }"/>
      </div>
      <div
        v-else
        class="select-lent-asset__content">
        <div
          class="select-lent-asset__tabs">
          <BaseButton
            is-full-width
            :class="[
              'select-lent-asset__tab',
              {
                'select-lent-asset__tab--active': isTabSelected(Tabs.ASSETS),
              },
            ]"
            :color="isTabSelected(Tabs.ASSETS) ? ButtonColor.Primary : ButtonColor.White"
            button-text="Tokens"
            style="width: 100%;"
            :variant="ButtonVariant.Outline"
            @on-button-click="() => setSelectedTab(Tabs.ASSETS)">
            <template #iconBeforeText>
              <TokenIcon class="select-lent-asset__tab-icon"/>
            </template>
          </BaseButton>
          <!-- TODO: Change button text when adding other positions -->

          <BaseButton
            is-new
            is-full-width
            :class="[
              'select-lent-asset__tab',
              {
                'select-lent-asset__tab--active': isTabSelected(Tabs.POOL),
              },
            ]"
            :color="isTabSelected(Tabs.POOL) ? ButtonColor.Primary : ButtonColor.White"
            button-text="Aave/Compound"
            style="width: 100%;"
            :variant="ButtonVariant.Outline"
            @on-button-click="() => setSelectedTab(Tabs.POOL)">
            <template #iconBeforeText>
              <NFTIcon class="select-lent-asset__tab-icon"/>
            </template>
          </BaseButton>
        </div>
        <TokensTable
          v-if="selectedTab === Tabs.ASSETS"
          :q="throttledSearchBar"
          :chain-id="props.chainId || selectedChain"
          :all-existing-tokens="tokens"
          :existing-tokens="filteredTokens"
          only-existing
          :is-loading="shouldShowLoader"
          :with-all-stables-option="props.isFungibleProposal !== true"
          :stables="allStablesTokens"
          @select-asset="handleSelectedAsset"/>
        <TokensTable
          v-else
          :q="throttledSearchBar"
          :chain-id="props.chainId || selectedChain"
          :existing-tokens="filteredPositions"
          is-third-party
          :is-loading="isLoadingPositions"
          only-existing
          :with-all-stables-option="false"
          @select-asset="handleSelectedAsset"/>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import SafeText from '@/general-components/SafeText.vue'
import SearchBar from '@/revamp/components/SearchBar.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import CurrencySwitcher from '@/layout/header/CurrencySwitcher.vue'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import { computed, ref, watch, watchEffect } from 'vue'
import type { VNode } from 'vue'
import { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useDebounce, useThrottle } from '@vueuse/core'
import { SupportedChain } from '@/constants/chains/types'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import TokensTable from '@/revamp/components/modals/select-their-collateral/TokensTable.vue'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useUserTokens } from '@/revamp/hooks/useUserTokens'
import RefreshButton from '@/modules/common/assets/components/RefreshButton.vue'
import useDashboardCache from '@/modules/pages/dashboard/hooks/useDashboardCache'
import useSelectLentAssetModal from '@/revamp/components/modals/select-lent-asset/useSelectLentAssetModal'
import ConnectWallet from '@/revamp/components/modals/ConnectWallet.vue'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import { useIsFetching, useQuery } from '@tanstack/vue-query'
import useDepositedAssets from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import TokenIcon from '@/assets/icons/token.svg'
import NFTIcon from '@/assets/icons/asset-offer.svg'
import BaseButton, { ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import { isPwnSpace } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'
import GlobalFilters from '@/modules/common/filters/global/GlobalFilters.vue'
import UserTokensWithAmountTable from '@/revamp/components/modals/thesis-select-commited-asset/UserTokensWithAmountTable.vue'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import { storeToRefs } from 'pinia'
import AssetType from '@/modules/common/assets/AssetType'
import ArrowSelectSvg from '@/assets/icons/arrow-select.svg'
import { compareAddresses } from '@/utils/utils'

interface Props {
  selectedToken?: AssetMetadata;
  chainId?: SupportedChain;
  headerText?: string;
  isEdit?: boolean;
  isModalOpen?: boolean;
  bundleImage?: VNode;
  hideYouOwnColumn?: boolean;
  isFungibleProposal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isModalOpen: false,
})
const emit = defineEmits<{(e: 'select-asset', asset: AssetWithAmount | AssetMetadata) }>()

const tokenIsMultiStables = computed(() => {
  return props.selectedToken?.symbol === '$PWN-ALL-YOUR-STABLES'
})

const { chainId: connectedChainId, address, status } = useCustomAccount()
const selectedChain = ref<SupportedChain>(connectedChainId.value || SupportedChain.Ethereum)
const { reload: reloadTokens, tokens, isLoading } = useUserTokens(address, selectedChain)
const { resetCacheState } = useDashboardCache()
const web3Modal = useWeb3Modal()
const globalFiltersStore = useGlobalFiltersStore()
const localTopTokensStore = useTopTokensStore()
const { topTokens: localTopTokens } = storeToRefs(localTopTokensStore)

const topTokensByChain = computed(() => {
  const _chainId = props.chainId || selectedChain.value
  return _chainId ? localTopTokens.value[_chainId].map(v => new AssetWithAmount(v)) : []
})

const { isOpen } = useSelectLentAssetModal()

const searchBar = ref('')
const assetsHiddenByFilter = ref(0)

watch(() => props.isModalOpen, (newVal) => {
  isOpen.value = newVal
})

watch(isOpen, async (newIsOpen) => {
  if (newIsOpen && status.value !== 'connected') {
    await web3Modal.open()
  }
})

const throttledSearchBar = useThrottle(searchBar, 300)

watchEffect(() => {
  if (!props.chainId && connectedChainId.value) {
    selectedChain.value = connectedChainId.value
    return
  }

  if (props.chainId) {
    selectedChain.value = props.chainId
  }
})

const handleSetSelectedChain = (chain: SetChainFilterOption) => {
  if (!props.chainId) {
    if (chain !== 'all') {
      selectedChain.value = chain
    }
  }
}

const assetIsMultiCreditPlaceholder = (asset: AssetMetadata) => {
  return asset.symbol === '$PWN-ALL-YOUR-STABLES'
}

const constructMultiCreditAsset = (assets: AssetWithAmount[]) => {
  const asset = new AssetMetadata({
    address: '0x0',
    chainId: assets[0].chainId,
    category: AssetType.ERC20,

    name: 'Multi Credit Asset',
    symbol: '$PWN-ALL-YOUR-STABLES',
    decimals: 18,
    bundleAssets: assets,
    id: Math.random() * -100,
  })

  return new AssetWithAmount({
    ...asset,
    amount: '1',
  })
}

const userSelectsCredits = ref(false)

const handleSelectedAsset = (asset?: AssetMetadata | AssetWithAmount) => {
  if (!asset) return
  if (assetIsMultiCreditPlaceholder(asset)) {
    userSelectsCredits.value = true
  } else {
    emit('select-asset', asset)
    isOpen.value = false
  }
}

const handleSelectedMultipleCredits = (assets: AssetWithAmount[]) => {
  emit('select-asset', constructMultiCreditAsset(assets))
  userSelectsCredits.value = true
  isOpen.value = false
}

const isAssetSelected = computed(() => !!props.selectedToken?.address || tokenIsMultiStables.value)

const isRefreshingCache = ref(false)

const disabledChains = computed(() =>
  enabledChains.filter((chain) => (!props.chainId ? false : chain !== props.chainId)),
)

const userAddress = ref(address.value)

// Watch for changes in the address and update the ref
watch(() => address.value, (newAddress) => {
  userAddress.value = newAddress
})

const { data: positions, isLoading: isLoadingPositions } = useQuery({
  queryKey: ['graphData', userAddress],
  queryFn: async () => {
    return useDepositedAssets().fetchAllPositions(userAddress.value)
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 30,
})

const filteredPositions = computed(() => {
  if (positions.value === undefined) { return [] }
  return positions.value?.filter((asset) => asset.chainId === selectedChain.value)
})

const filterOutUnverifiedAssets = (assets: AssetWithAmount[]) => {
  const withAppliedSmallBalanceFilter = globalFiltersStore.applyShowAssetsWithLesserAmountFilter(assets)
  const withAppliedFilter = globalFiltersStore.applyShowUnverifiedAssetsFilter(withAppliedSmallBalanceFilter)
  const totalRemoved = assets.length - withAppliedFilter.length
  assetsHiddenByFilter.value = totalRemoved
  return withAppliedFilter
}

const filteredTokens = computed(() => {
  return filterOutUnverifiedAssets(tokens.value)
})

const allStablesTokens = computed(() => {
  const stables = ['usdt', 'usdc', 'dai', 'frax', 'busd', 'usdc.e']
  return topTokensByChain.value.filter(v => stables.includes(v.symbol.toLowerCase()))
})

const handleRefreshAssets = async () => {
  if (!selectedChain.value) return
  const selectedChains = [selectedChain.value].map((v) => v) || []

  const cachePurgeQueries = [
    ...selectedChains.map((v) =>
      resetCacheState({
        userAddress: address.value!,
        chainId: v,
      }),
    ),
  ]
  isRefreshingCache.value = true
  await Promise.allSettled(cachePurgeQueries)
  reloadTokens()
  isRefreshingCache.value = false
}

enum Tabs {
  ASSETS = 'Assets',
  POOL = 'Aave/Compound/Morpho',
}

const selectedTab = ref<Tabs>(Tabs.ASSETS)
const setSelectedTab = (tab: Tabs) => {
  selectedTab.value = tab
}
const isTabSelected = (tab: Tabs): boolean => selectedTab.value === tab

const numOfPricesFetching = useIsFetching({
  queryKey: ['api', 'v1', 'asset', 'price'],
  fetchStatus: 'fetching',
  exact: false,
  predicate: (query) => {
    const chainId = query.queryKey[4]
    const address = query.queryKey[5] as `0x${string}`
    const tokenId = query.queryKey[6]

    const token = tokens.value.find(v => {
      return compareAddresses(v.address, address) && v.chainId.toString() === chainId && v.tokenId ? v.tokenId?.toString() === tokenId : true
    })

    return !!token
  },
})

const filterAppliedLoading = computed(() => {
  return !globalFiltersStore.showAssetsWithLesserAmount && numOfPricesFetching.value > 0
})

const debouncedFilterAppliedLoading = useDebounce(filterAppliedLoading, 300)

const shouldShowLoader = computed(() => {
  return isLoading.value || debouncedFilterAppliedLoading.value
})

</script>

<style scoped>
.select-lent-asset {
  &__tabs {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    width: 100%;
  }

  &__tab {
    font-size: 1rem;

    &--active {
      .select-their-collateral__tab-icon * {
        fill: var(--primary-color-1);
      }
    }
  }

  &__tab-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__search {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  &__search-bar {
    width: 100%;
  }

  &__refresh-button {
    height: 3rem;
    width: 3rem;
  }

  &__token-select {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--background-color);
    height: 28px;
    width: 136px;
    font-size: 1rem;
    border: 1px solid var(--text-color);
    color: var(--text-color);

    user-select: none;

    &:hover {
      border: 1px solid var(--text-color);
    }
  }

  &__selected-asset {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 10.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__selected-asset-media {
    display: flex;
    flex-flow: row nowrap;
  }

  &__selected-asset-container {
    display: contents;
  }

  &__filters-row {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__filter-detail {
    font-size: 0.75rem;
    color: #a4a4a4;

    border-left: 1px solid #a4a4a4;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>

<style>
.select-lent-asset__tab--active path {
  fill: var(--primary-color-1);
}

.select-lent-asset__currency-switcher .multiselect__tags {
  padding: 0.5rem;
}

.select-lent-asset__currency-switcher .multiselect {
  height: auto;
  min-height: auto;
}

.select-lent-asset__currency-switcher .multiselect__single {
  margin-bottom: 0;
  display: flex;
}

.select-lent-asset {
  .modal__body {
    min-width: 944px;

    @media only screen and (--small-viewport) {
      min-width: 100%;
    }
  }
}

.select-lent-asset__filters-row {
  .global-filters__icon-wrapper {
    height: 2.5rem;
  }
}
</style>

<style lang="postcss">
.select-lent-asset {
  .multiselect__single {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__refresh-button {
    .button {
      width: 3rem !important;
      height: 3rem !important;

      padding: 0.25rem !important;
    }
  }
}
</style>
