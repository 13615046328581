import type { ValuationSource } from '@/general-components/data-source/DataSourceType'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import linkTo from '@/constants/links'
import { EBISUSBAY_NAME } from '@/constants/chains/cronos'

const BASE_ERC20_PRICE_SOURCE_TEXT = 'An estimated value for this token is fetched from '
const BASE_NFT_PRICE_SOURCE_TEXT = 'An estimated value for an NFT is an appraisal fetched from '

export interface ValuationSourceDetail {
  link: string
  linkText: string
  text: typeof BASE_ERC20_PRICE_SOURCE_TEXT | typeof BASE_NFT_PRICE_SOURCE_TEXT
}

const allValuationSources: Record<ValuationSource, ValuationSourceDetail> = {
  [DataSourceType.COINGECKO]: {
    link: linkTo.coingecko,
    linkText: 'CoinGecko',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.COINMARKETCAP]: {
    link: linkTo.coinMarketCap,
    linkText: 'CoinMarketCap',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.DEXGURU]: {
    link: linkTo.dexguru,
    linkText: 'DexGuru',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.MORALIS]: {
    link: linkTo.moralis,
    linkText: 'Moralis',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.NFTBANK]: {
    link: linkTo.nftbank,
    linkText: 'NFTBank',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.UPSHOT]: {
    link: linkTo.upshot,
    linkText: 'Upshot',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.OPENSEA]: {
    link: linkTo.opensea,
    linkText: 'Opensea',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.LOOKSRARE]: {
    link: linkTo.looksRare,
    linkText: 'LooksRare',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.EBISUSBAY]: {
    link: linkTo.ebisusBay,
    linkText: EBISUSBAY_NAME,
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.VENO_FINANCE]: {
    link: linkTo.venoFinance,
    linkText: 'Veno Finance',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.PENDLE]: {
    link: linkTo.pendle,
    linkText: 'Pendle',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.SWAAP]: {
    link: linkTo.swaap,
    linkText: 'Swaap',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.REVEST]: {
    link: linkTo.revest,
    linkText: 'Revest',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.SUPERFORM]: {
    link: linkTo.superform,
    linkText: 'Superform',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.KYBER_SWAP]: {
    link: linkTo.kyberswap,
    linkText: 'KyberSwap',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.SOMMELIER]: {
    link: linkTo.sommelier,
    linkText: 'Sommelier',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.INSTADAPP]: {
    link: linkTo.instadapp,
    linkText: 'Instadapp',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.YEARN]: {
    link: linkTo.yearn,
    linkText: 'Yearn',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.BACKED]: {
    link: linkTo.backed,
    linkText: 'Backed',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.BEEFY]: {
    link: linkTo.beefy,
    linkText: 'Beefy',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.SUSHI]: {
    link: linkTo.sushi,
    linkText: 'Sushi',
    text: BASE_NFT_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.MORPHO]: {
    link: linkTo.morpho,
    linkText: 'Morpho',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.KERNEL]: {
    link: linkTo.kernel,
    linkText: 'Kernel',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.MELLOW]: {
    link: linkTo.mellow,
    linkText: 'Mellow Finance',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.RESERVE]: {
    link: linkTo.reserve,
    linkText: 'Reserve',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.SPECTRA]: {
    link: linkTo.spectra,
    linkText: 'Spectra Finance',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.SEPOLIA]: {
    link: linkTo.pwnAppProduction,
    linkText: '[TEST] Unichain Sepolia',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
  [DataSourceType.RESOLV]: {
    link: linkTo.resolv,
    linkText: 'Resolv',
    text: BASE_ERC20_PRICE_SOURCE_TEXT,
  },
}

export default allValuationSources
