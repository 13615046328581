<template>
  <div class="user-info">
    <UserAvatar
      v-if="hasAvatar"
      :class="['user-info__avatar', {'user-info__avatar--small': isSmall}]"
      :avatar-src="addressProfile?.avatar"/>

    <div class="user-info__details">
      <div
        v-if="addressProfile?.name"
        :class="['user-info__name', {'user-info__name--small': isSmall}]">
        {{ addressProfile.name }}
        <EFPComponent
          v-if="props.address && !isStarknet"
          :icon-size="props.isSmall ? 14 : 24"
          :address="props.address"/>
      </div>

      <div
        :class="['user-info__address', {
          'user-info__address-no-name': !addressProfile?.name,
          'user-info__address--small': isSmall,
        }]">
        <span :title="props.address!">
          {{ shortAddress }} <RepScore
            v-if="props.address"
            :icon-size="repScoreIconSize"
            :address="props.address"/>
        </span>

        <div class="user-info__actions">
          <BaseTooltip
            has-tooltip
            :tooltip-text="isSupported ? props.address! : 'Not supported'">
            <template #trigger>
              <CopyIconSvg
                v-if="isSupported"
                :class="['user-info__copy-icon', {'user-info__copy-icon--small': isSmall}]"
                @click="copy(props.address!)"/>
              <CopyBottomLeftIconSvg
                v-if="copied"
                class="user-info__copy-text-success"
                alt="copied"/>
            </template>
            <template #tooltip>
              <div class="user-info__tooltip">
                {{ copied ? 'Copied' : props.address }}
              </div>
            </template>
          </BaseTooltip>
          <EFPComponent
            v-if="props.address && !addressProfile?.name && !isStarknet"
            :icon-size="props.isSmall ? 14 : 24"
            :address="props.address"/>
        </div>
      </div>
      <SelectChainExplorer
        :address="props.address"
        :class="[{'user-info__selection-chain-explorer--small': isSmall}]"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CopyIconSvg from '@/assets/icons/copy.svg'
import UserAvatar from '@/general-components/UserAvatar.vue'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import { computed, toRefs } from 'vue'
import { shortenAddress } from '@/utils/utils'
import { useClipboard } from '@vueuse/core'
import CopyBottomLeftIconSvg from '@/assets/icons/copy-bottom-left.svg'
import type { Address } from 'viem'
import { useAddressProfile } from '@/modules/common/web3/useAddressProfile'
import RepScore from '@/modules/pages/dashboard/RepScore.vue'
import EFPComponent from '@/modules/pages/dashboard/EFPComponent.vue'
import SelectChainExplorer from '@/modules/pages/dashboard/SelectChainExplorer.vue'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

type Props = {
  hasAvatar: boolean;
  address: Address | null
  isSmall?: boolean
}

const props = defineProps<Props>()
const { address } = toRefs(props)

const addressProfileQuery = useAddressProfile(address)
const addressProfile = computed(() => addressProfileQuery.data.value)
const shortAddress = computed(() => shortenAddress(props.address))

const repScoreIconSize = computed(() => {
  if (props.isSmall) {
    return 12
  }
  return addressProfile.value?.name ? 16 : 24
})

const { copy, copied, isSupported } = useClipboard({ source: props.address ?? undefined })
</script>

<style scoped lang="postcss">
  .user-info {
    display: flex;
    align-items: center;
    z-index: var(--z-index-header-chain-explorer);

    &__avatar {
      width: 5rem;
      height: 5rem;
      margin-right: 1rem;

      &--small {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
      }
    }

    &__details {
      display: flex;
      flex-flow: column wrap;
      row-gap: 0.5rem;
    }

    &__name {
      font-size: 1.875rem;
      font-weight: 400;
      font-family: var(--font-family-screener);
      margin-top: 0.25rem;

      &--small {
        font-size: 1rem;
        margin-top: 0;
      }
    }

    &__address {
      font-size: 1.25rem;
      font-weight: 400;
      font-family: var(--font-family-screener);

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      gap: 0.5rem;

      &--small {
        font-size: 0.75rem !important;
        margin-top: 0 !important;
      }

      &-no-name {
        font-size: 1.875rem;
        font-weight: 400;
        font-family: var(--font-family-screener);
        margin-top: 1.5rem;

        &--small {
          font-size: 1rem;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
    }

    &__explorer-link {
      display: inline-flex;
    }

    &__explorer-icon,
    &__copy-icon {
      width: 1.5rem;
      height: 1.5rem;

      transition: fill var(--primary-transition-time) linear;
      cursor: pointer;

      &--small {
        width: 1rem;
        height: 1rem;
      }

      &:active {
        transform: scale(0.9);
      }
    }

    &__copy-text-success {
      width: 7.5rem;
      top: 0;
      position: absolute;
      margin: -0.3rem -0.3rem 0 0;
      transform-origin: left;
      animation: scale 2s ease-in both;
      right: -7rem;
    }

    &__selection-chain-explorer--small {
      scale: 0.85;
      translate: -12px -3px;
      z-index: 1;
    }
  }
</style>

<style>
.user-info__copy-icon {
  --primary-color-1: var(--gray-2);
}

.user-info__copy-icon:hover {
  --primary-color-1: rgb(var(--primary-color-base));
}

</style>
