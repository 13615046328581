<template>
  <BaseTooltip
    is-interactive
    :max-width="350">
    <template #trigger>
      <slot/>
    </template>
    <template v-if="asset.hasAtrToken">
      <div v-if="asset.isTotalAmountDivisible">
        <div
          v-for="atrToken in asset.atrTokens"
          :key="String(atrToken.tokenId)">
          Tokens locked in
          <BaseLink
            class="link--primary link--font-oxygen-mono"
            :to="getAtrTokenRoute(atrToken)">
            {{ getAtrTokenIdFormatted(atrToken) }}
          </BaseLink>: {{ formatAmountWithDecimals(atrToken.tokenizedAmount, asset.decimals) }}
        </div>
        <div v-if="asset.maxAvailableAmountRaw > 0n">
          Tokens unlocked: {{ asset.maxAvailableAmount }}
        </div>
      </div>
      <div v-else-if="!asset.isTotalAmountDivisible && asset?.atrTokens?.[0]">
        This asset has its Transfer Rights inside of <BaseLink
          class="link--primary link--font-oxygen-mono"
          :to="getAtrTokenRoute(asset.atrTokens[0])"
          target="_blank">
          {{ getAtrTokenIdFormatted(asset.atrTokens[0]) }}
        </BaseLink>
      </div>
      <div
        v-if="hasAnyAtrTokenInDifferentSafe"
        class="tokenized-asset-info-tooltip__different-safe-warning">
        <br>
        <span
          v-for="(atrToken, index) in atrTokensInDifferentSafe"
          :key="String(atrToken.tokenId)">
          <span v-if="index !== 0">, </span>
          {{ getAtrTokenIdFormatted(atrToken) }}
        </span>
        {{ atrTokensInDifferentSafe?.length <= 1 ? 'is' : 'are' }} not in this {{ locationWarningText }}
      </div>
    </template>
  </BaseTooltip>
</template>

<script setup lang="ts">
import type { AssetWithAmount, AtrTokenInfo } from '@/modules/common/assets/AssetClasses'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import { computed, toRefs } from 'vue'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { compareAssets, formatAmountWithDecimals } from '@/utils/utils'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import AssetType from '@/modules/common/assets/AssetType'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { storeToRefs } from 'pinia'
import type { RouteLocationRaw } from 'vue-router'

interface Props {
    asset: AssetWithAmount
}
const props = defineProps<Props>()
const { asset } = toRefs(props)

const getAtrTokenRoute = (atrToken: AtrTokenInfo): RouteLocationRaw => {
  return {
    name: RouteName.NftPage,
    params: {
      chainName: CHAINS_CONSTANTS[asset.value.chainId].general.chainName.toLowerCase(),
      contractAddress: atrToken.contractAddress,
      tokenId: String(atrToken.tokenId),
    },
  }
}

const getAtrTokenIdFormatted = (atrToken: AtrTokenInfo) => `ATR #${atrToken.tokenId}`

const connectedAccountTypeStore = useConnectedAccountTypeStore()
const { isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe, isSelectedCurrentWallet } = storeToRefs(pwnSafeDetailStore)

const atrTokensInDifferentSafe = computed(() => {
  return asset.value.atrTokens.filter(atrToken => {
    // @ts-expect-error FIXME: strictNullChecks
    return selectedPwnSafe.value.nfts.every(nft => !compareAssets({
      assetA: { chainId: atrToken.chainId, address: atrToken.contractAddress, tokenId: atrToken.tokenId, category: AssetType.ERC721 }, // ATR token is ERC721
      assetB: nft,
    }))
  })
})
const hasAnyAtrTokenInDifferentSafe = computed(() => atrTokensInDifferentSafe.value?.length)
const locationWarningText = computed(() => isSelectedCurrentWallet.value && !isConnectedPwnSafe.value ? 'wallet' : 'safe')
</script>

<style scoped>
.tokenized-asset-info-tooltip {
  &__different-safe-warning {
    color: var(--orange-1);
  }
}
</style>
