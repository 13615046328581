import type { MetadataSourceBackendSchema } from '@/modules/common/backend/generated'
import type { PickEnum } from '@/modules/common/typings/utilityTypes'
import * as Sentry from '@sentry/vue'

export enum DataSourceType {
  LOOKSRARE = 'LOOKSRARE',
  OPENSEA = 'OPENSEA',
  UPSHOT = 'UPSHOT',
  NFTBANK = 'NFTBANK',
  ETHERSCAN = 'ETHERSCAN',
  COINMARKETCAP = 'COINMARKETCAP',
  COINGECKO = 'COINGECKO',
  DEXGURU = 'DEXGURU',
  MORALIS = 'MORALIS',
  ALCHEMY = 'ALCHEMY',
  EBISUSBAY = 'EBISUSBAY',
  UNMARSHAL = 'UNMARSHAL',
  VENO_FINANCE = 'VENO_FINANCE',
  PENDLE = 'PENDLE',
  SWAAP = 'SWAAP',
  REVEST = 'REVEST',
  PWN_OVERWRITE = 'PWN_OVERWRITE',
  SUPERFORM = 'SUPERFORM',
  KYBER_SWAP = 'KYBER_SWAP',
  SOMMELIER = 'SOMMELIER',
  INSTADAPP = 'INSTADAPP',
  YEARN = 'YEARN',
  BACKED = 'BACKED',
  BEEFY = 'BEEFY',
  SUSHI = 'SUSHI',
  MORPHO = 'MORPHO',
  KERNEL = 'KERNEL',
  MELLOW = 'MELLOW',
  RESERVE = 'RESERVE',
  SPECTRA = 'SPECTRA',
  SEPOLIA = 'SEPOLIA',
  RESOLV = 'RESOLV',
}
export default DataSourceType

export type ValuationSource = PickEnum<
  DataSourceType,
  | DataSourceType.COINGECKO
  | DataSourceType.COINMARKETCAP
  | DataSourceType.DEXGURU
  | DataSourceType.MORALIS
  | DataSourceType.NFTBANK
  | DataSourceType.UPSHOT
  | DataSourceType.OPENSEA
  | DataSourceType.LOOKSRARE
  | DataSourceType.EBISUSBAY
  | DataSourceType.VENO_FINANCE
  | DataSourceType.PENDLE
  | DataSourceType.SWAAP
  | DataSourceType.REVEST
  | DataSourceType.SUPERFORM
  | DataSourceType.KYBER_SWAP
  | DataSourceType.SOMMELIER
  | DataSourceType.INSTADAPP
  | DataSourceType.YEARN
  | DataSourceType.BACKED
  | DataSourceType.BEEFY
  | DataSourceType.SUSHI
  | DataSourceType.MORPHO
  | DataSourceType.KERNEL
  | DataSourceType.MELLOW
  | DataSourceType.RESERVE
  | DataSourceType.SPECTRA
  | DataSourceType.SEPOLIA
  | DataSourceType.RESOLV
>

// `${MetadataSource}` template literal type is used here since e.g. subclasses of MetadataSource like MarketplaceEnum
// are not structured as a child of MetadataSource
export const parseSourceFromBackend = (source: `${MetadataSourceBackendSchema}`): ValuationSource | undefined => {
  const parsedSource = DataSourceType[source]
  if (parsedSource === undefined) {
    Sentry.captureMessage(`Metadata source = ${source} received from BE is not present in DataSourceType enum on FE.`)
  }
  return parsedSource
}
