<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    :heading-icon="transferIcon.default"
    heading-align="left"
    heading="Transfer">
    <template #body>
      <component :is="activeStepComponent"/>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import usePwnSafeTransferModal from '@/modules/pages/pwn-safe/transfer/modal/usePwnSafeTransferModal'
import * as transferIcon from '@/assets/icons/transfer.svg'
import ModalSize from '@/general-components/ModalSize'

const { isOpen, activeStepComponent } = usePwnSafeTransferModal()
</script>
