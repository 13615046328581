import AssetType from '@/modules/common/assets/AssetType'
import {
  assetTokenStatsList,
  fetchAssetMetadata,
  fetchErc20Price,
  fetchNFTAssetCollectionMetadata,
  fetchNFTAssetCollectionMetadata2,
  fetchNftPrice,
  fetchCollectionStats as _fetchCollectionStats,
  fetchNFTPriceStats as _fetchNFTPriceStats,
  web3authUserProfileStatsRetrieve,
} from '@/modules/common/backend/generated'
import type { AssetPriceSchemaWithAssetMetadataBackendSchema, NFTAssetCollectionDetailSchemaBackendSchema } from '@/modules/common/backend/generated'
import { SupportedChain } from '@/constants/chains/types'
import CollectionStats from '@/modules/common/assets/typings/CollectionStats'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import to from '@/utils/await-to-js'
import { AssetPrice, NFTAppraisal } from '@/modules/common/assets/typings/AssetPriceClasses'
import NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'
import TokenPriceStats from '@/modules/common/assets/typings/TokenPriceStats'
import { compareAddresses, generateRandomBoolean } from '@/utils/utils'
import { PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS, PWN_FAUCET_ERC721_SEPOLIA_ADDRESS, generateFakeAssetPrice, generateFakeCollectionStats, generateFakeErc20Price, generateFakeErc20PriceStats, generateFakeNftAppraisal, generateFakeNftPriceStats } from '@/modules/common/assets/fakeAppraisals'
import SEPOLIA_CONSTANTS from '@/constants/chains/sepolia'
import { isAddress } from 'viem'
import type { Address } from 'viem'
import type { AxiosResponse } from 'axios'
import { WalletSearchStats } from '@/modules/common/pwn/explorer/models/WalletSearchStats'

export default function useMetadataFetch() {
  const fetchErc20Metadata = async ({
    chainId,
    contractAddress,
    isInBundle,
    userAddress,
    refresh,
  }:{
    chainId: SupportedChain,
    contractAddress: Address,
    isInBundle?: boolean,
    userAddress?: Address
    refresh?: boolean
  }):Promise<AssetMetadata | null> => {
    const [err, response] = await to(fetchAssetMetadata(
      String(chainId),
      contractAddress,
      '',
      { refresh },
    ))
    if (err || !response.data) return null
    return AssetMetadata.createFromBackendModel(response.data)!
  }

  type fetchNftMetadataParams = {
    contractAddress: Address,
    tokenId: bigint,
    chainId: SupportedChain,
    refresh?: boolean,
    useCachedMetadata?: boolean,
    isInBundle?: boolean,
    userAddress?: Address
  }

  const fetchNftMetadata = async ({
    contractAddress,
    tokenId,
    chainId,
    refresh = false,
    useCachedMetadata = false,
    isInBundle,
    userAddress,
  }: fetchNftMetadataParams): Promise<AssetMetadata | null> => {
    // if (!refresh && useCachedMetadata) {
    //  const cachedNft = useUserAssetsStore().userNfts.find(asset => compareAddresses(asset.address, contractAddress) && asset.tokenId === tokenId)
    //  if (cachedNft) {
    //    return deepcopy(cachedNft) // todo what to do with this?
    //  }
    // }

    const [err, response] = await to(fetchAssetMetadata(
      String(chainId),
      contractAddress,
      BigInt(tokenId).toString(),
      {
        ...(refresh && { refresh }),
      },
    ))
    if (err || !response.data) return null
    return AssetMetadata.createFromBackendModel(response.data)!
  }

  const fetchNFTPriceStats = async (
    contractAddress: Address,
    tokenId: string,
    chainId: SupportedChain,
    { signal = undefined }: { signal?: AbortSignal } = {},
  ) => {
    if (chainId === SupportedChain.Sepolia) {
      if (compareAddresses(contractAddress, PWN_FAUCET_ERC721_SEPOLIA_ADDRESS) || compareAddresses(contractAddress, PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS)) {
        // only return fake NFT price stats for PWNFaucet NFTs
        return generateFakeNftPriceStats(contractAddress)
      } else {
        // do not fetch price stats on testnet
        return null
      }
    }

    const [err, response] = await to(_fetchNFTPriceStats(
      String(chainId),
      contractAddress,
      BigInt(tokenId).toString(),
      undefined,
      { ...(signal && { signal }) },
    ))
    if (err || !response.data) return null
    return NFTPriceStats.createFromBackendModel(response.data)
  }

  const fetchCollectionStats = async (
    chainId: SupportedChain,
    collectionAddress: Address,
    refresh = false,
    { signal = undefined }: { signal?: AbortSignal } = {},
  ) => {
    if (chainId === SupportedChain.Sepolia) {
      if (compareAddresses(collectionAddress, PWN_FAUCET_ERC721_SEPOLIA_ADDRESS) || compareAddresses(collectionAddress, PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS)) {
        // only return fake collection stats for PWNFaucet NFT collections
        return generateFakeCollectionStats(collectionAddress)
      } else {
        // do not fetch collection stats on testnet
        return undefined
      }
    }

    const [error, response] = await to(_fetchCollectionStats(
      String(chainId),
      collectionAddress,
      { refresh },
      { ...(signal && { signal }) },
    ))
    if (error || !response.data) return undefined
    return CollectionStats.createFromBackendModel(response.data)
  }

  const fetchERC20PriceStats = async (
    chainId: SupportedChain,
    contractAddress: Address,
    { signal = undefined }: { signal?: AbortSignal } = {},
  ): Promise<TokenPriceStats | null> => {
    if (chainId === SupportedChain.Sepolia) {
      if (compareAddresses(contractAddress, SEPOLIA_CONSTANTS.topTokens.pwnd) || compareAddresses(contractAddress, SEPOLIA_CONSTANTS.topTokens.pwns)) {
        // only return fake ERC20 price stats for PWNFaucet ERC20s (PWND, PWNS)
        return generateFakeErc20PriceStats(contractAddress)
      } else {
        // do not return erc20 price stats on testnet
        return null
      }
    }

    const [error, response] = await to(assetTokenStatsList(chainId, contractAddress, { ...(signal && { signal }) }))

    if (error || !response) return null
    return TokenPriceStats.createFromBackendModel(response.data)
  }

  const fetchNFTAppraisal = async (chainId: SupportedChain, contractAddress: Address, tokenId: string, formattedAmount?: string | undefined, refresh?: boolean | undefined): Promise<NFTAppraisal | null> => {
    if (chainId === SupportedChain.Sepolia) {
      if (compareAddresses(contractAddress, PWN_FAUCET_ERC721_SEPOLIA_ADDRESS) || compareAddresses(contractAddress, PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS)) {
        // only return fake NFT appraisal for PWNFaucet NFTs
        return generateFakeNftAppraisal(contractAddress)
      } else {
        // do not return NFT appraisal on testnet
        return null
      }
    }

    const [error, response] = await to(
      fetchNftPrice(
        String(chainId),
        contractAddress,
        tokenId,
        {
          ...((formattedAmount !== null && formattedAmount !== undefined) && { formatted_amount: formattedAmount }),
          ...(refresh !== undefined && { refresh }),
        },
      ),
    )
    if (error || !response.data) return null
    return NFTAppraisal.createFromBackendModel(response.data)
  }

  const fetchERC20Price = async (chainId: SupportedChain, contractAddress: Address, formattedAmount?: string | undefined, refresh?: boolean | undefined): Promise<AssetPrice | null> => {
    if (chainId === SupportedChain.Sepolia) {
      if (compareAddresses(contractAddress, SEPOLIA_CONSTANTS.topTokens.pwnd) || compareAddresses(contractAddress, SEPOLIA_CONSTANTS.topTokens.pwns)) {
        // only return fake ERC20 price for PWNFaucet ERC20s (PWND, PWNS)
        return generateFakeErc20Price(contractAddress)
      } else {
        // do not fetch erc20 price on testnets
        return null
      }
    }

    const [error, response] = await to(
      fetchErc20Price(
        String(chainId),
        contractAddress,
        {
          ...((formattedAmount !== null && formattedAmount !== undefined) && { formatted_amount: formattedAmount }),
          ...(refresh !== undefined && { refresh }),
        },
      ),
    )
    if (error || !response.data) return null
    return AssetPrice.createFromBackendModel(response.data)
  }

  // TODO change arg type to TokenBundle after asset classes refactoring
  const fetchBundleAssetsAppraisal = async (bundle: AssetMetadata, refresh?: boolean | undefined): Promise<void> => {
    if (bundle.chainId === SupportedChain.Sepolia) {
      for (const bundleAsset of bundle.bundleAssets) {
        if (generateRandomBoolean()) {
          // assign appraisal only to some of the bundle assets
          bundleAsset.appraisal = generateFakeAssetPrice(bundleAsset.address, bundleAsset.category)
        }
      }
      return
    }

    const promises: Promise<AxiosResponse<AssetPriceSchemaWithAssetMetadataBackendSchema, any>>[] = []
    for (const bundleAsset of bundle.bundleAssets) {
      if (bundleAsset.isNft) {
        promises.push(fetchNftPrice(
          String(bundleAsset.chainId),
          bundleAsset.address,
          String(bundleAsset.tokenId),
        ))
      } else {
        promises.push(fetchErc20Price(
          String(bundleAsset.chainId),
          bundleAsset.address,
        ))
      }
    }
    const promiseResults = await Promise.allSettled(promises)
    for (const [i, result] of promiseResults.entries()) {
      if (result.status === 'fulfilled' && result.value?.data?.price?.usd_amount) {
        bundle.bundleAssets[i].appraisal = bundle.bundleAssets[i].category === AssetType.ERC20 ? AssetPrice.createFromBackendModel(result.value.data) : NFTAppraisal.createFromBackendModel(result.value.data)
      }
    }
  }

  const fetchNftCollection = async (contractAddressOrSlug: string, chainId: SupportedChain | null, refresh = false) => {
    let error: Error | null = null; let response: AxiosResponse<NFTAssetCollectionDetailSchemaBackendSchema> | undefined
    if (isAddress(contractAddressOrSlug)) {
      // we are fetching by chain id + contract address
      [error, response] = await to(fetchNFTAssetCollectionMetadata2(
        String(chainId),
        contractAddressOrSlug,
        { refresh },
      ))
    } else {
      // we are fetching by collection opensea slug
      [error, response] = await to(fetchNFTAssetCollectionMetadata(
        contractAddressOrSlug,
        { refresh },
      ))
    }

    if (error || !response?.data) return undefined
    return NFTAssetCollection.createFromBackendModel(response.data)
  }

  const fetchWalletStats = async (
    userAddress: Address) => {
    const [error, response] = await to(web3authUserProfileStatsRetrieve(
      userAddress,
    ))

    if (error || !response?.data) return null
    return WalletSearchStats.createFromBackendModel(response.data)
  }

  return {
    fetchErc20Metadata,
    fetchNftMetadata,
    fetchCollectionStats,
    fetchNFTAppraisal,
    fetchNFTPriceStats,
    fetchBundleAssetsAppraisal,
    fetchERC20Price,
    fetchERC20PriceStats,
    fetchNftCollection,
    fetchWalletStats,
  }
}
