import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import OpenseaIconSvg from '@/assets/icons/opensea.svg'
import type { Address } from 'viem'
import BscIcon from '@/assets/icons/bsc-white.svg'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://bscscan.com/'

const BSC_CONSTANTS: ChainConstants = {
  // TODO is it the https://safe-transaction-opbnb-mainnet.bnbchain.org/ ?
  gnosisSafe: { chainName: 'bnb', transactionServiceUrl: 'https://safe-transaction-bsc.safe.global/' },
  pwnSafeContracts: {
    // @ts-expect-error TS(2322) FIXME: Type '""' is not assignable to type '`0x${string}`... Remove this comment to see the full error message
    assetTransferRightsGuardProxy: '', // todo: change when deployed
    // @ts-expect-error TS(2322) FIXME: Type '""' is not assignable to type '`0x${string}`... Remove this comment to see the full error message
    assetTransferRights: '', // todo: change when deployed
    // @ts-expect-error TS(2322) FIXME: Type '""' is not assignable to type '`0x${string}`... Remove this comment to see the full error message
    pwnSafeFactory: '', // todo: change when deployed
  },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x57c88D78f6D08b5c88b4A3b7BbB0C1AA34c3280A',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x4A75a527E97d853109aA6998a2B9E45a87A31e9f',
  topTokens: new TopTokens({
    dai: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    usdc: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    usdt: '0x55d398326f99059ff775485246999027b3197955',
    wbnb: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  }),
  general: {
    chainId: SupportedChain.Bsc,
    chainName: 'BSC',
  },
  nodeProvider: {
    httpNodeUrl: `https://go.getblock.io/${import.meta.env.VITE_BSC_GETBLOCK_HTTP_NODE_KEY}`,
    wsNodeUrl: `wss://go.getblock.io/${import.meta.env.VITE_BSC_GETBLOCK_WS_NODE_KEY}`,
  },
  nftContractsAddresses: {
    kyberSwapElastic: '0xe222fBE074A436145b255442D919E4E3A6c6a480',
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'bscscan',
    blockExplorerIcon: BscIcon,
    nftDetailsLink: (tokenAddress, tokenId) => `https://opensea.io/assets/bsc/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: OpenseaIconSvg,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://opensea.io/collection/${slug}`,
  },
  aave: {
    uiPoolDataProvider: '0x78F8Bd884C3D738B74B420540659c82f392820e0',
    poolAddressesProvider: '0xff75B6da14FfbbfD355Daf7a2731456b3562Ba6D',
    pool: '0x6807dc923806fE8Fd134338EABCA509979a7e0cB',
  },
}

export default BSC_CONSTANTS
