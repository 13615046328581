<template>
  <div class="pwn-safe-tokenize-modal-tokenizing-with-transfers-step__container">
    <p>
      {{ topParagraphText }}
    </p>
    <WalletSafeWalletTransferGraphic
      :assets="assetsInTransferGraphic"
      :pwn-safe-name="pwnSafeWhereTokenizing?.name"
      :is-transferring-from-user="isTransferAssetFromUserStep"
      :is-transferring-from-pwn-safe="isTransferAtrToUserStep"
      :is-tokenizing="isTokenizeStep"/>
    <p>
      {{ bottomParagraphText }}
    </p>
    <div class="pwn-safe-tokenize-modal-tokenizing-with-transfers-step__buttons-container">
      <BaseButton
        :button-text="buttonText"
        :is-disabled="isButtonDisabled"
        @on-button-click="handleButtonAction"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import usePwnSafeTransferAssetsTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransferAssetsTransfer'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import WalletSafeWalletTransferGraphic from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/WalletSafeWalletTransferGraphic.vue'
import BaseButton from '@/general-components/BaseButton.vue'
import usePwnSafeTokenizeModal, {
  PwnSafeTokenizeModalStep,
} from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModal'
import usePwnSafeTokenizeModalTokenizingWithTransfersStep, {
  CurrentAction,
} from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModalTokenizingWithTransfersStep'
import { useMutation } from '@tanstack/vue-query'
import { queryMutations } from '@/modules/mutations'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import { storeToRefs } from 'pinia'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

const { currentAction } = usePwnSafeTokenizeModalTokenizingWithTransfersStep()

const isTransferAssetFromUserStep = computed(() => {
  return [CurrentAction.ConfirmTransferAssetFromUser, CurrentAction.TransferringAssetFromUser].includes(
    currentAction.value,
  )
})

const isTokenizeStep = computed(() => {
  return [CurrentAction.ConfirmTokenize, CurrentAction.Tokenizing].includes(currentAction.value)
})

const isTransferAtrToUserStep = computed(() => {
  return [CurrentAction.ConfirmTransferAtrToUser, CurrentAction.TransferringAtrToUser].includes(currentAction.value)
})

const { selectedAssetsToTokenize, pwnSafeWhereTokenizing, successfullyTokenizedAssets } = usePwnSafeTokenize()
const { activeStep } = usePwnSafeTokenizeModal()
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { walletAsSafe } = storeToRefs(pwnSafeDetailStore)

const { toWallet, fromWallet } = usePwnSafeTransfer()
const { transferAssets } = usePwnSafeTransferAssetsTransfer()

const assetsInTransferGraphic = computed(() => {
  return isTransferAssetFromUserStep.value || isTokenizeStep.value
    ? selectedAssetsToTokenize.value
    : successfullyTokenizedAssets.value
})

const topParagraphText = computed(() => {
  let assetsInfo: string
  if (isTransferAssetFromUserStep.value || isTokenizeStep.value) {
    assetsInfo = selectedAssetsToTokenize.value.map((asset) => asset.primaryInfoWithAmountInput).join(', ')
  } else if (isTransferAtrToUserStep.value) {
    assetsInfo = successfullyTokenizedAssets.value.map((asset) => asset.primaryInfoFormatted).join(', ')
  }

  if (isTransferAssetFromUserStep.value) {
    // @ts-expect-error FIXME: strictNullChecks
    return `Transferring ${assetsInfo} to ${toWallet.value?.name}...`
  } else if (isTokenizeStep.value) {
    // @ts-expect-error FIXME: strictNullChecks
    return `Minting ATR token for ${assetsInfo} in ${pwnSafeWhereTokenizing.value.name}...`
  } else if (isTransferAtrToUserStep.value) {
    // @ts-expect-error FIXME: strictNullChecks
    return `Transferring ${assetsInfo} from ${pwnSafeWhereTokenizing.value.name} to your wallet...`
  } else {
    return ''
  }
})

const bottomParagraphText = computed(() => {
  if (isTransferAssetFromUserStep.value) {
    return 'Please accept the following transaction if you wish to transfer your asset.'
  } else if (isTokenizeStep.value) {
    return 'Please accept the following transaction if you wish to mint an ATR token for your asset.'
  } else if (isTransferAtrToUserStep.value) {
    return 'Please accept the following transaction if you wish to transfer your minted ATR token back to your wallet.'
  } else {
    return ''
  }
})

const mutations = queryMutations()
const { mutateAsync: transferAssetsToPwnSafeMutateAsync, isPending: isTransferringAssetsToPwnSafe } = useMutation({
  ...mutations.safe.transferAssets,
  mutationFn: (variables) => {
    return transferAssets(variables.assetsToTransfer)
  },
  onMutate(variables) {
    currentAction.value = CurrentAction.TransferringAssetFromUser
    fromWallet.value = walletAsSafe.value
    return mutations.safe.transferAssets.onMutate?.(variables)
  },
  onSuccess(data, variables, context) {
    mutations.safe.transferAssets.onSuccess?.(data, variables, context)
    currentAction.value = CurrentAction.ConfirmTokenize
  },
  onError(_, variables, context) {
    currentAction.value = CurrentAction.ConfirmTransferAssetFromUser
  },
})

const transferAssetsToPwnSafeStep = new ToastStep({
  text: 'Transferring assets to PWN Safe...',
  async fn(step) {
    const assets = await transferAssetsToPwnSafeMutateAsync({
      fromSafe: walletAsSafe.value,
      // @ts-expect-error FIXME: strictNullChecks
      toSafe: pwnSafeWhereTokenizing.value,
      assetsToTransfer: selectedAssetsToTokenize.value,
    })
    return assets?.length > 0
  },
})

const mintedAtrTokens = ref<AssetWithAmount[]>([])

const { mutateAsync: mintAtrTokensMutateAsync, isPending: isMintingAtrTokens } = useMutation({
  ...mutations.safe.mintAtrTokens,
  mutationFn: async (variables) => {
    currentAction.value = CurrentAction.Tokenizing
    // @ts-expect-error strictNullChecks
    mintedAtrTokens.value = await mutations.safe.mintAtrTokens.mutationFn(variables)
    return mintedAtrTokens.value
  },
  onError(_, variables, context) {
    currentAction.value = CurrentAction.ConfirmTokenize
  },
  onSuccess(data, variables, context) {
    mutations.safe.mintAtrTokens.onSuccess?.(data, variables, context)
    currentAction.value = CurrentAction.ConfirmTransferAtrToUser
    // TODO maybe in success trigger next step?
  },
})

const mintAtrTokensStep = new ToastStep({
  text: 'Minting ATR tokens...',
  async fn(step) {
    const assets = await mintAtrTokensMutateAsync({
      safe: pwnSafeWhereTokenizing.value!,
      assetsToTokenize: selectedAssetsToTokenize.value,
      step,
    })
    return assets?.length > 0
  },
  // TODO maybe in success trigger next step?
})

const { mutateAsync: transferAtrTokensToUserMutateAsync, isPending: isTransferringAtrTokensToUser } = useMutation({
  ...mutations.safe.transferAssets,
  mutationFn: (variables) => {
    return transferAssets(variables.assetsToTransfer)
  },
  onMutate(variables) {
    currentAction.value = CurrentAction.TransferringAtrToUser
    fromWallet.value = pwnSafeWhereTokenizing.value
    toWallet.value = walletAsSafe.value
    return mutations.safe.transferAssets.onMutate?.(variables)
  },
  onSuccess(data, variables, context) {
    mutations.safe.transferAssets.onSuccess?.(data, variables, context)
    // reset currentAction
    currentAction.value = CurrentAction.ConfirmTransferAssetFromUser
    activeStep.value = PwnSafeTokenizeModalStep.Confirmation
  },
  onError(_, variables, context) {
    currentAction.value = CurrentAction.ConfirmTransferAtrToUser
  },
})

const transferAtrTokensToUserStep = new ToastStep({
  text: 'Transferring ATR token to user...',
  async fn(step) {
    const assets = await transferAtrTokensToUserMutateAsync({
      // @ts-expect-error FIXME: strictNullChecks
      fromSafe: fromWallet.value,
      // @ts-expect-error FIXME: strictNullChecks
      toSafe: toWallet.value,
      assetsToTransfer: mintedAtrTokens.value,
    })
    return assets?.length > 0
  },
})

const getToastSteps = () => {
  const steps: ToastStep[] = []
  if (currentAction.value <= CurrentAction.ConfirmTransferAssetFromUser) {
    steps.push(transferAssetsToPwnSafeStep)
  }

  if (currentAction.value <= CurrentAction.ConfirmTokenize) {
    steps.push(mintAtrTokensStep)
  }

  if (currentAction.value <= CurrentAction.ConfirmTransferAtrToUser) {
    steps.push(transferAtrTokensToUserStep)
  }

  return steps
}

const buttonText = computed(() => {
  switch (currentAction.value) {
  case CurrentAction.ConfirmTransferAssetFromUser:
  case CurrentAction.ConfirmTransferAtrToUser:
    return 'Transfer'
  case CurrentAction.TransferringAssetFromUser:
  case CurrentAction.TransferringAtrToUser:
    return 'Transferring'
  case CurrentAction.ConfirmTokenize:
    return 'Mint ATR'
  case CurrentAction.Tokenizing:
    return 'Minting ATR'
  default:
    // todo any log here?
    return ''
  }
})

let continueFlow
const toast = ref<Toast>()

const handleButtonAction = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_MINT_ATR_TOKEN](selectedAssetsToTokenize.value)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      steps: getToastSteps(),
      chainId: selectedAssetsToTokenize.value[0].chainId,
      title: 'Mint ATR token',
      // TODO what to pass here as a firstAsset?
      firstAsset: selectedAssetsToTokenize.value[0],
    }, NotificationFrontendOnlyActionEnum.TX_MINT_ATR_TOKEN, selectedAssetsToTokenize.value);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}

const isButtonDisabled = computed(() => isTransferringAssetsToPwnSafe.value || isMintingAtrTokens.value || isTransferringAtrTokensToUser.value)

onMounted(() => {
  handleButtonAction()
})
</script>

<style scoped>
p {
  margin: 0;
}

.pwn-safe-tokenize-modal-tokenizing-with-transfers-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__buttons-container {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
