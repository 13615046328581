import { SortDirection } from '@/general-components/sorting/SortDirection'
import type NFTEvent from '@/modules/common/assets/typings/NFTEvent'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { NFTOrder } from '@/modules/common/assets/typings/NFTOrder'
import type { CompareFunctionReturn } from '@/modules/common/typings/customTypes'
import type Notification from '@/modules/common/notifications/Notification'

export function compareNumbers(a: number, b: number, order: SortDirection): CompareFunctionReturn {
  a = a || 0
  b = b || 0

  if (a > b) {
    return order === SortDirection.Ascending ? 1 : -1
  } else if (a < b) {
    return order === SortDirection.Ascending ? -1 : 1
  } else {
    return 0
  }
}

export function compareStrings(a: string, b: string, order: SortDirection): CompareFunctionReturn {
  a = a?.toUpperCase() || ''
  b = b?.toUpperCase() || ''

  if (a.localeCompare(b) > 0) {
    return order === SortDirection.Ascending ? 1 : -1
  } else if (a.localeCompare(b) < 0) {
    return order === SortDirection.Ascending ? -1 : 1
  } else {
    return 0
  }
}

function compareDates(a: Date, b: Date, order: SortDirection): CompareFunctionReturn {
  const aTimestamp = a.getTime() ?? 0
  const bTimestamp = b.getTime() ?? 0
  return compareNumbers(aTimestamp, bTimestamp, order)
}

function _sortFnAssetsByPrice(a: AssetWithAmount, b: AssetWithAmount, order: SortDirection): CompareFunctionReturn {
  if (!a?.appraisalPrice?.ethAmount) {
    return 1 // asset without appraisal data will be always at the end of the list
  } else if (!b?.appraisalPrice?.ethAmount) {
    return -1 // asset without appraisal data will be always at the end of the list
  } else {
    return compareNumbers(Number(a.appraisalPrice.ethAmount), Number(b.appraisalPrice.ethAmount), order)
  }
}

// TODO change .slice(0).sort() to .toSorted() once the browser support is full
export function copyingSortAssetsByPrice(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.slice(0).sort((a, b) => _sortFnAssetsByPrice(a, b, order))
}

export function sortAssetsByPrice(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.sort((a, b) => _sortFnAssetsByPrice(a, b, order))
}

function _sortFnAssetsByAppraisal(a: AssetWithAmount, b: AssetWithAmount, order: SortDirection): CompareFunctionReturn {
  if (!a?.appraisalFullAmount?.ethAmount) {
    return 1 // asset without appraisal data will be always at the end of the list
  } else if (!b?.appraisalFullAmount?.ethAmount) {
    return -1 // asset without appraisal data will be always at the end of the list
  } else {
    return compareNumbers(Number(a.appraisalFullAmount?.ethAmount), Number(b.appraisalFullAmount?.ethAmount), order)
  }
}

// TODO change .slice(0).sort() to .toSorted() once the browser support is full
export function copyingSortAssetsByAppraisal(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.slice(0).sort((a, b) => _sortFnAssetsByAppraisal(a, b, order))
}

export function sortAssetsByAppraisal(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.sort((a, b) => _sortFnAssetsByAppraisal(a, b, order))
}

function _sortFnAssetsByAmount(a: AssetWithAmount, b: AssetWithAmount, order: SortDirection): CompareFunctionReturn {
  const assetA = a?.isAtrToken ? a.assetOfAtrToken : a
  const assetB = b?.isAtrToken ? b.assetOfAtrToken : b
  return compareNumbers(Number(assetA.amount), Number(assetB.amount), order)
}

// TODO change .slice(0).sort() to .toSorted() once the browser support is full
export function copyingSortAssetsByAmount(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.slice(0).sort((a, b) => _sortFnAssetsByAmount(a, b, order))
}

export function sortAssetsByAmount(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.sort((a, b) => _sortFnAssetsByAmount(a, b, order))
}

export function sortBundlesByAssetsAmount(bundles: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return bundles.sort((a, b) => compareNumbers(Number(a.bundleAssets.length), Number(b.bundleAssets.length), order))
}

export function sortAssetsByVerification(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.sort((a, b) => {
    const assetA = a?.isAtrToken ? a.assetOfAtrToken : a
    const assetB = b?.isAtrToken ? b.assetOfAtrToken : b
    return compareNumbers(Number(assetA.isVerified), Number(assetB.isVerified), order)
  })
}

function _sortFnAssetsByName(a: AssetWithAmount, b: AssetWithAmount, order: SortDirection): CompareFunctionReturn {
  const assetA = a?.isAtrToken ? a.assetOfAtrToken : a
  const assetB = b?.isAtrToken ? b.assetOfAtrToken : b
  return compareStrings(assetA?.name, assetB?.name, order)
}

// TODO change .slice(0).sort() to .toSorted() once the browser support is full
export function copyingSortAssetsByName(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.slice(0).sort((a, b) => _sortFnAssetsByName(a, b, order))
}

export function sortAssetsByName(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.sort((a, b) => _sortFnAssetsByName(a, b, order))
}

export function sortAssetsByPrimaryInfo<TAsset extends AssetMetadata>(assets: TAsset[], order: SortDirection): TAsset[] {
  return assets.sort((a, b) => {
    const assetA = a?.isAtrToken ? a.assetOfAtrToken : a
    const assetB = b?.isAtrToken ? b.assetOfAtrToken : b
    return compareStrings(assetA?.primaryInfoWithoutAmount, assetB?.primaryInfoWithoutAmount, order)
  })
}

export function sortAssetsBySymbol(assets: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return assets.sort((a, b) => {
    const assetA = a?.isAtrToken ? a.assetOfAtrToken : a
    const assetB = b?.isAtrToken ? b.assetOfAtrToken : b
    return compareStrings(assetA.symbol, assetB.symbol, order)
  })
}

export function sortNotificationsByTime(notifications: Notification[], order: SortDirection): Notification[] {
  return notifications.sort((a, b) => compareDates(a.created, b.created, order))
}

export function sortNftEventsByDate(nftEvents: NFTEvent[], order: SortDirection): NFTEvent[] {
  return nftEvents.sort((a, b) => compareDates(a.date, b.date, order))
}

export function sortNftOrdersByUsdValue(nftOrders: NFTOrder[], order: SortDirection): NFTOrder[] {
  return nftOrders.sort((a, b) => compareNumbers(Number(a.orderAsset?.appraisal?.price?.usdAmount), Number(b.orderAsset?.appraisal?.price?.usdAmount), order))
}

export function sortAtrTokensById(atrTokens: AssetWithAmount[], order: SortDirection): AssetWithAmount[] {
  return atrTokens.sort((a, b) => compareNumbers(Number(a.tokenId), Number(b.tokenId), order))
}
