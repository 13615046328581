import { computed, ref } from 'vue'
import { SORT_OPTIONS_LOOKUP } from '@/modules/sections/your-assets/your-coins/YourCoinsDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useYourCoins from '@/modules/sections/your-assets/your-coins/useYourCoins'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { filterAssetsInSearchResults } from '@/utils/search'
import { storeToRefs } from 'pinia'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

const searchTerm = ref('')

export default function usePwnSafeCoins() {
  const { selectedSortOption } = useYourCoins()
  const pwnSafeDetailStore = usePwnSafeDetailStore()

  const sortedPwnSafeCoins = computed<AssetWithAmount[]>(() => {
    const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

    if (!selectedPwnSafe.value) {
      return []
    }

    let coins = selectedPwnSafe.value.erc20s
    if (searchTerm.value) {
      coins = filterAssetsInSearchResults(coins, searchTerm.value)
    }

    coins = useGlobalFiltersStore().applyShowUnverifiedAssetsFilter(coins)

    return SORT_OPTIONS_LOOKUP[selectedSortOption.value.id](coins, selectedSortOption.value.direction)
  })

  return {
    selectedSortOption,
    sortedPwnSafeCoins,
    searchTerm,
  }
}
