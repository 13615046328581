import type { Address } from 'viem'
import { useCustomAccount, starknetAccount } from '@/modules/common/web3/useCustomAccount'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { ToastStep } from '@/modules/common/notifications/useToastsStore'
import type { SupportedChain } from '@/constants/chains/types'
import { prepareApproveMultiERC20s, readAnyMultipleERC20Allowances } from '@/modules/common/adapters/erc20-utils'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import type { Call } from 'starknet'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import type { SendTransactionOptions } from '@/modules/common/web3/useTransactions'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'

export const useApproveThesisAssets = () => {
  const { address } = useCustomAccount()

  const checkPreviousApprovals = async (thesisAssets: AssetWithAmount[], contractAddress: Address) => {
    if (!thesisAssets.length) {
      throw new Error('No assets to check')
    }

    if (!address.value) {
      throw new Error('Account not connected')
    }

    return readAnyMultipleERC20Allowances(thesisAssets, thesisAssets[0].chainId, address.value, contractAddress)
  }

  const approveThesisAssets = (thesisAssets: (AssetWithAmount | AssetMetadata)[], contractAddress: Address, chainId: SupportedChain): ToastStep[] => {
    const toastSteps: ToastStep[] = []
    if (!thesisAssets.length) {
      return toastSteps
    }

    if (!address.value) {
      throw new Error('Account not connected')
    }

    const approvalCalls = prepareApproveMultiERC20s(thesisAssets, chainId, address.value, contractAddress)

    if (isStarknet) {
      toastSteps.push(new ToastStep({
        text: 'Approving Credit Assets',
        fn: async (step) => {
          const account = starknetAccount.value
          if (!account) {
            throw new Error('Wallet not connected')
          }

          const multicall = approvalCalls as unknown as Call[]
          try {
            const result = await account.execute(multicall)
            return !!result
          } catch (error) {
            for (const call of multicall) {
              // const wallet = starknetWallet.value
              // debugger
              await account.callContract(call)
            }
            throw new Error(`Batch approval failed: ${error}`)
          }
        },
      }))

      return toastSteps
      // eslint-disable-next-line brace-style
    }

    // // todo; is never true. implement once sendCalls works
    // // @es-lint-disable
    // if (isContractWallet && false) {
    //   try {
    //     const connections = getConnections(pwnWagmiConfig)

    //     await sendCalls(pwnWagmiConfig, {
    //       calls: approvalCalls,
    //       chainId: sepolia.id,
    //       connector: connections[0]?.connector,
    //     })
    //   } catch (error) {
    //     throw new Error(`Batch approval failed: ${error}`)
    //   }
    // } else {
    else if (!isStarknet) {
      for (let i = 0; i < approvalCalls.length; i++) {
        const call = approvalCalls[i]
        const assetName = thesisAssets[i].name // Assuming thesisAssets has a 'name' property
        toastSteps.push(new ToastStep({
          text: `Approving ${assetName}`,
          fn: async (step) => {
            const extraParams: Partial<SendTransactionOptions> = {}

            if (useConnectedAccountTypeStore().isConnectedContractWallet && address.value) {
              extraParams.safeAddress = address.value
            }
            // happens only for starknet, cannot happen here.
            if (!('args' in call)) return false

            const result = await sendTransaction({
              address: call.address,
              abi: call.abi,
              args: call.args,
              chainId,
              functionName: call.functionName,
            }, extraParams)

            return result !== undefined
          },
        }))
      }
    }

    return toastSteps
  }

  return {
    checkPreviousApprovals,
    approveThesisAssets,
  }
}
