<template>
  <div class="notification-center-settings-section">
    <h2 class="notification-center-settings-section__title">
      {{ title }}
    </h2>
    <div class="notification-center-settings-section__options">
      <slot name="options"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'

const props = defineProps<{
  title: string,
}>()
const { title } = toRefs(props)
</script>

<style scoped>
.notification-center-settings-section {
  position: relative;
  margin-bottom: 2.25rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 1px;
    width: 100%;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px; /* small hack how to hide buggy double border (top + bottom), when height is 1px */
  }

  &__title {
    font-family: var(--font-family-autoscape);
    margin-bottom: 1.5rem;
    padding-top: 2rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  &__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5rem;
  }
}
</style>
