<template>
  <div class="the-wallet">
    <TheWalletTrigger @toggle-menu="toggleMenu"/>
    <Transition :name="isSmallScreen ? 'move-to-left' : 'move-to-right'">
      <div
        v-show="isMenuOpen && hasAddress"
        :class="['the-wallet__menu', `the-wallet__menu--${openDirection}`]"
        @click.stop>
        <div
          class="the-wallet__close"
          @click.stop="toggleMenu">
          <ArrowRightSvg
            width="24"
            height="24"
            alt="close menu"/>
        </div>
        <div :class="['the-wallet__menu-content', {'the-wallet__menu-content--starknet' : isStarknet}]">
          <TheWalletHeader
            :is-open-tab-notifications-settings="isOpenTabNotificationsSettings"
            @toggle-notifications-setting="handleToggleNotificationSettings"/>
          <TheWalletNotificationsSettings
            v-if="isOpenTabNotificationsSettings && !isStarknet"
            @close-notifications-setting="handleCloseNotificationSettings"/>
          <TheWalletNotifications v-else/>
          <TheWalletLinks/>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { shortenAddress } from '@/utils/utils'
import ArrowRightSvg from '@/assets/icons/arrow-right-white.svg'
import TheWalletHeader from '@/layout/header/TheWalletHeader.vue'
import TheWalletLinks from '@/layout/header/TheWalletLinks.vue'
import TheWalletNotifications from '@/layout/header/TheWalletNotifications.vue'
import TheWalletTrigger from '@/layout/header/TheWalletTrigger.vue'
import { isSmallScreen } from '@/utils/mediaQueries'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import TheWalletNotificationsSettings from '@/layout/header/TheWalletNotificationsSettings.vue'

const { address: userAddress } = useCustomAccount()

const isMenuOpen = ref(false)
const isOpenTabNotificationsSettings = ref(false)

type Props = {
  openDirection?: 'top' | 'bottom'
}
defineProps<Props>()

const addressShort = computed(() => userAddress.value ? shortenAddress(userAddress.value) : '')
const hasAddress = computed(() => userAddress.value && addressShort.value.length > 0)

const hideDropdownMenu = () => {
  isMenuOpen.value = false
}

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
  if (isMenuOpen.value) {
    document.addEventListener('click', hideDropdownMenu)
  } else {
    document.removeEventListener('click', hideDropdownMenu)
  }
}

watch(() => isMenuOpen.value, () => {
  document.body.style.overflowX = 'hidden'
  setTimeout(() => {
    document.body.style.overflowX = ''
  }, 350)
})

const handleToggleNotificationSettings = () => {
  isOpenTabNotificationsSettings.value = !isOpenTabNotificationsSettings.value
}

const handleCloseNotificationSettings = () => {
  isOpenTabNotificationsSettings.value = false
}
</script>

<style>
.the-wallet__chain-switcher-wrapper .multiselect__tags {
  padding: 0;
  display: flex;
  align-items: center;
}

.the-wallet__chain-switcher-wrapper .multiselect__tags .multiselect__single {
  margin: 0;
}
</style>

<style scoped>
.multiselect__tags {
  padding: 0;
}

.the-wallet {
  position: relative;
  font-family: var(--font-family-screener);

  &__menu {
    width: 500px;
    height: 880px;
    position: absolute;
    top: 2.625rem;
    right: 0;

    @media only screen and (--small-viewport) {
      display: flex;
      align-items: end;
    }

    &--bottom {
      top: 2.625rem;
      right: 0;
    }

    &--top {
      bottom: 3rem;
      top: auto;
      left: 0;
    }
  }

  &__menu-content {
    border: 1px solid rgb(56 56 56);
    background: var(--background-color);
    width: 460px;
    height: 880px;
    max-height: 80vh;
    margin-left: auto;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 93vw;
    box-shadow: 0 5px 16px 5px var(--background-color);

    @media only screen and (--small-viewport) {
      position: relative;
      margin-left: unset;
    }

    &--starknet {
      height: auto;
    }
  }

  &__close {
    top: 0;
    left: 0;
    width: 42px;
    height: 73px;
    z-index: 1;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url("@/assets/images/menu-close-bg.svg");
    position: absolute;
    border-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;

      --text-color: var(--primary-color-1);
    }
  }

  &__chain-switcher-wrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 0.75rem;
    background: none;
    justify-content: space-between;
  }

  &__chain-switcher-wrapper span {
    white-space: nowrap; /* Prevents text from wrapping */
  }
}

.move-to-right {
  &-enter-active,
  &-leave-active {
    transition: transform 0.3s,
      width 0.3s,
      opacity 0.3s;
  }

  &-enter-from {
    transform: translateX(100%);
  }

  &-enter-to {
    transform: translateX(0);
  }

  &-leave-to {
    transform: translateX(140%);
  }

  &-leave-active {
    position: absolute;
  }
}

.move-to-left {
  &-enter-active,
  &-leave-active {
    transition: transform 0.3s,
      width 0.3s,
      opacity 0.3s;
  }

  &-enter-from {
    transform: translateX(-100%);
  }

  &-enter-to {
    transform: translateX(0);
  }

  &-leave-to {
    transform: translateX(-140%);
  }

  &-leave-active {
    position: absolute;
  }
}

</style>
