import { sendTransaction } from '@/modules/common/web3/useTransactions'
import type { ChainIdEnumBackendSchema, SafeOffchainDataRequestBackendSchema } from '@/modules/common/backend/generated'
import { pwnsafeSafeOffChainDataCreate } from '@/modules/common/backend/generated'
import useAuth from '@/modules/common/backend/useAuth'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { SupportedChain } from '@/constants/chains/types'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { parseEventLogs } from 'viem'
import type { Address } from 'viem'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { pwnSafeFactoryAbi } from '@/contracts/generated'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export default function usePwnSafeFactory() {
  const submitPwnSafeOffChainData = async (safeName: string, transactionHash: string, createdSafeAddress: Address, chainId: SupportedChain) => {
    const { address: userAddress } = getAccount(pwnWagmiConfig)
    const requestBody: SafeOffchainDataRequestBackendSchema = {
      name: safeName,
      chain_id: chainId,
      transaction_hash: transactionHash,
      wallet_address: userAddress!,
    }

    // TODO add additional toast step for login if needed?
    const response = await useAuth().fetchRestrictedEndpoint(() => pwnsafeSafeOffChainDataCreate(String(chainId), requestBody))
    return PwnSafe.parseResponseFromBackendModel({
      contract_address: createdSafeAddress,
      name: response.data.name ?? undefined,
      chain_id: response.data.chain_id as ChainIdEnumBackendSchema,
      owners: [userAddress!],
    })
  }

  const deployPwnSafe = async (safeName: string, chainId: SupportedChain, owners: Address[], threshold: bigint, step?: ToastStep): Promise<PwnSafe > => {
    const pwnSafeFactory = CHAINS_CONSTANTS[chainId].pwnSafeContracts?.pwnSafeFactory
    if (!pwnSafeFactory) {
      throw new Error(`Trying deploy pwn safe on unsupported chain ID=${chainId}`)
    }
    const receipt = await sendTransaction({
      abi: pwnSafeFactoryAbi,
      functionName: 'deployProxy',
      args: [owners, threshold],
      chainId,
      address: pwnSafeFactory,
    }, { step })

    const parsedEventLogs = parseEventLogs({
      abi: pwnSafeFactoryAbi,
      logs: receipt.logs,
      eventName: 'PWNSafeDeployed',
    })

    const createdSafeAddress = parsedEventLogs[0].args.safe
    const createdSafe = await submitPwnSafeOffChainData(safeName, receipt.transactionHash, createdSafeAddress, chainId)
    return createdSafe
  }

  return {
    deployPwnSafe,
  }
}
