<template>
  <div ref="target">
    <BaseSkeletor v-if="isFetching"/>
    <EthAndUsdPriceLabel
      v-else
      :align="align"
      :asset="asset"
      :price-source="showSource ? dataSource : undefined"
      :amount="assetPrice"/>
  </div>
</template>

<script lang="ts" setup>
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import { computed, ref, toRefs } from 'vue'
import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import type { QueryFunctionContext } from '@tanstack/vue-query'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import type { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import type { SupportedChain } from '@/constants/chains/types'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type { AssetPrice } from '@/modules/common/assets/typings/AssetPriceClasses'
import NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'
import type { Address } from 'viem'
import { useElementVisibility } from '@vueuse/core'

type Props = {
  asset: AssetWithAmount;
  type: 'floorPrice' | 'appraisal' | 'token-price' | 'token-appraisal'
  align?: 'left' | 'right'
  showSource?: boolean
};

const props = withDefaults(defineProps<Props>(), {
  align: 'left',
  showSource: false,
})
const { asset, type, showSource } = toRefs(props)

const target = ref<HTMLElement | null>(null)
const isVisiable = useElementVisibility(target)

const isAppraisal = computed(() => (type.value === 'appraisal' || type.value === 'token-appraisal'))
const dataSource = computed(() => isAppraisal.value ? asset.value.appraisalPriceSource : asset.value.floorPriceSource)

const assetAddressToFetch = computed(() => {
  if (asset.value.isBundleWithSingleAsset) {
    return asset.value.bundleAssets[0].address
  }
  return asset.value.address
})

const query = useQuery({
  queryKey: ['assetPrice', type, asset.value.chainId, assetAddressToFetch.value, String(asset.value.tokenId)],
  queryFn: async (context: QueryFunctionContext) => {
    const [, typeValue, chainId, address, tokenId] = context.queryKey

    const fetchingAddress = address as Address

    if (typeValue === 'token-price' || typeValue === 'token-appraisal') {
      const result = await useMetadataFetch().fetchERC20Price(chainId as SupportedChain, fetchingAddress)
      return result?.price || null
    }

    if (typeValue === 'appraisal') {
      return await useMetadataFetch().fetchNFTAppraisal(chainId as SupportedChain, fetchingAddress, tokenId as string)
    }

    return await useMetadataFetch().fetchNFTPriceStats(fetchingAddress, tokenId as string, chainId as SupportedChain)
  },
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  retry: false,
  enabled: isVisiable,
  placeholderData: keepPreviousData,
  staleTime: 1000 * 60 * 5,
})

const assetPrice = computed(() => {
  const price = query.data.value

  if (type.value === 'floorPrice') {
    const existingPrice = asset.value.floorPrice

    if (!existingPrice && price instanceof NFTPriceStats) {
      return price.floorPrice
    }
    return existingPrice
  }

  if (type.value === 'appraisal') {
    if (asset.value.isFungible) {
      return asset.value.appraisalFullAmountInput || asset.value.appraisalFullAmount
    }
    if (price) {
      return asset.value.getAppraisalFullAmount(price as AssetPrice)
    } else {
      return asset.value.appraisalFullAmount
    }
  }

  if (type.value === 'token-appraisal' && price) {
    return asset.value.calculateFullAmountInput(price as AmountInEthAndUsd)
  }

  if (price) {
    return price as AmountInEthAndUsd
  }

  if (type.value === 'token-price') {
    return asset.value.appraisal?.price
  }

  return null
})

const isFetching = computed(() => {
  return query.isPending.value
})
</script>
