import { h, ref } from 'vue'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { CreateProposalRequestSchemaRequestBackendSchema } from '@/modules/common/backend/generated'

const isOpen = ref(false)
const proposalClass = ref<BaseProposal>()
const proposalToBeSemiComplete = ref<CreateProposalRequestSchemaRequestBackendSchema>()
const isEdit = ref(false)
const isProposalPosted = ref(false)
const nonceToRevoke = ref()
const ltvInProposalModal = ref<number>(0)
const noRedirectToProposalPage = ref(false)
const bundledAssets = ref<AssetWithAmount[] | null>(null)
const createBatchImageBundle = (assets: AssetWithAmount[], isPreview?: boolean) => {
  const baseClass = isPreview ? 'pwn-safe-batch-image' : 'bundle-image'
  const containerClass = assets[0].isAtrToken
    ? `${baseClass}__container-atr-tokens`
    : `${baseClass}__container-assets`

  const assetClass = assets[0].isAtrToken ? `${baseClass}__atr-token` : `${baseClass}__asset`

  const assetsPlusAmount = assets.length - 3
  switch (assets.length) {
  case 1:
    return h('div', { class: containerClass }, [
      h('img', { class: assetClass, '.src': assets[0].image }),
    ])
  case 2:
    return h('div', { class: containerClass }, [
      h('img', { class: assetClass, '.src': assets[0].image }),
      h('img', { class: assetClass, '.src': assets[1].image }),
    ])
  case 3:
    return h('div', { class: containerClass }, [
      h('img', { class: assetClass, '.src': assets[0].image }),
      h('img', { class: assetClass, '.src': assets[1].image }),
      h('img', { class: assetClass, '.src': assets[2].image }),
    ])
  case 4:
    return h('div', { class: containerClass }, [
      h('img', { class: assetClass, '.src': assets[0].image }),
      h('img', { class: assetClass, '.src': assets[1].image }),
      h('img', { class: assetClass, '.src': assets[2].image }),
      h('img', { class: assetClass, '.src': assets[3].image }),
    ])
  default:
    return h('div', { class: containerClass }, [
      h('img', { class: assetClass, '.src': assets[0].image }),
      h('img', { class: assetClass, '.src': assets[1].image }),
      h('img', { class: assetClass, '.src': assets[2].image }),
      h('div', { class: `${assetClass}--down-right-amount` }, `+${assetsPlusAmount}`),
    ])
  }
}

export default function useProposalPreviewModal() {
  return {
    isOpen,
    proposalClass,
    proposalToBeSemiComplete,
    isEdit,
    isProposalPosted,
    nonceToRevoke,
    ltvInProposalModal,
    noRedirectToProposalPage,
    bundledAssets,
    createBatchImageBundle,
    isRedirectToProposalPage: noRedirectToProposalPage,
  }
}
