/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useQuery
} from '@tanstack/vue-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ERC20AssetSuggestBackendSchema,
  NFTAssetCollectionSuggestBackendSchema,
  NFTAssetSearchMoreSchemaBackendSchema,
  NFTAssetSuggestBackendSchema,
  NFTCollectionSearchMoreSchemaBackendSchema,
  PaginatedERC20AssetDocumentListBackendSchema,
  PaginatedERC20AssetSuggestListBackendSchema,
  PaginatedNFTAssetCollectionDocumentListBackendSchema,
  PaginatedNFTAssetCollectionSuggestListBackendSchema,
  PaginatedNFTAssetDocumentListBackendSchema,
  PaginatedNFTAssetSuggestListBackendSchema,
  PaginatedUserSuggestListBackendSchema,
  PwnseaErc20AssetRetrieveParams,
  PwnseaErc20AssetSuggestListParams,
  PwnseaNftAssetRetrieveParams,
  PwnseaNftAssetSuggestListParams,
  PwnseaNftCollectionRetrieveParams,
  PwnseaNftCollectionSuggestListParams,
  PwnseaUserRetrieveParams,
  PwnseaUserSuggestListParams,
  SearchNFTCollectionMoreParams,
  SearchNFTMoreParams,
  UserDocumentBackendSchema,
  UserSuggestBackendSchema
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Used for suggesting search items based on the character user types in
 */
export const pwnseaErc20AssetSuggestList = (
    params?: MaybeRef<PwnseaErc20AssetSuggestListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedERC20AssetSuggestListBackendSchema>(
      {url: `/api/v1/pwnsea/erc20-asset-suggest/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnseaErc20AssetSuggestListQueryKey = (params?: MaybeRef<PwnseaErc20AssetSuggestListParams>,) => {
    return ['api','v1','pwnsea','erc20-asset-suggest', ...(params ? [params]: [])] as const;
    }

    
export const getPwnseaErc20AssetSuggestListQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetSuggestList>>, TError = unknown>(params?: MaybeRef<PwnseaErc20AssetSuggestListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaErc20AssetSuggestListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestList>>> = ({ signal }) => pwnseaErc20AssetSuggestList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestList>>, TError, TData> 
}

export type PwnseaErc20AssetSuggestListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestList>>>
export type PwnseaErc20AssetSuggestListQueryError = unknown

export const usePwnseaErc20AssetSuggestList = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetSuggestList>>, TError = unknown>(
 params?: MaybeRef<PwnseaErc20AssetSuggestListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaErc20AssetSuggestListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Used for suggesting search items based on the character user types in
 */
export const pwnseaErc20AssetSuggestRetrieve = (
    id: MaybeRef<number>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customInstance<ERC20AssetSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/erc20-asset-suggest/${encodeURIComponent(String(id))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaErc20AssetSuggestRetrieveQueryKey = (id: MaybeRef<number>,) => {
    return ['api','v1','pwnsea','erc20-asset-suggest',id] as const;
    }

    
export const getPwnseaErc20AssetSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetSuggestRetrieve>>, TError = unknown>(id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaErc20AssetSuggestRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestRetrieve>>> = ({ signal }) => pwnseaErc20AssetSuggestRetrieve(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestRetrieve>>, TError, TData> 
}

export type PwnseaErc20AssetSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestRetrieve>>>
export type PwnseaErc20AssetSuggestRetrieveQueryError = unknown

export const usePwnseaErc20AssetSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetSuggestRetrieve>>, TError = unknown>(
 id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaErc20AssetSuggestRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Functional suggest functionality.

:param request:
:return:
 */
export const pwnseaErc20AssetSuggestFunctionalSuggestRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ERC20AssetSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/erc20-asset-suggest/functional_suggest/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaErc20AssetSuggestFunctionalSuggestRetrieveQueryKey = () => {
    return ['api','v1','pwnsea','erc20-asset-suggest','functional_suggest'] as const;
    }

    
export const getPwnseaErc20AssetSuggestFunctionalSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetSuggestFunctionalSuggestRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestFunctionalSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaErc20AssetSuggestFunctionalSuggestRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestFunctionalSuggestRetrieve>>> = ({ signal }) => pwnseaErc20AssetSuggestFunctionalSuggestRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestFunctionalSuggestRetrieve>>, TError, TData> 
}

export type PwnseaErc20AssetSuggestFunctionalSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestFunctionalSuggestRetrieve>>>
export type PwnseaErc20AssetSuggestFunctionalSuggestRetrieveQueryError = unknown

export const usePwnseaErc20AssetSuggestFunctionalSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetSuggestFunctionalSuggestRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestFunctionalSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaErc20AssetSuggestFunctionalSuggestRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Suggest functionality.
 */
export const pwnseaErc20AssetSuggestSuggestRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<ERC20AssetSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/erc20-asset-suggest/suggest/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaErc20AssetSuggestSuggestRetrieveQueryKey = () => {
    return ['api','v1','pwnsea','erc20-asset-suggest','suggest'] as const;
    }

    
export const getPwnseaErc20AssetSuggestSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetSuggestSuggestRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaErc20AssetSuggestSuggestRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestSuggestRetrieve>>> = ({ signal }) => pwnseaErc20AssetSuggestSuggestRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestSuggestRetrieve>>, TError, TData> 
}

export type PwnseaErc20AssetSuggestSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestSuggestRetrieve>>>
export type PwnseaErc20AssetSuggestSuggestRetrieveQueryError = unknown

export const usePwnseaErc20AssetSuggestSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetSuggestSuggestRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetSuggestSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaErc20AssetSuggestSuggestRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const pwnseaErc20AssetRetrieve = (
    query: MaybeRef<string>,
    params?: MaybeRef<PwnseaErc20AssetRetrieveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      query = unref(query);
params = unref(params);
      
      return customInstance<PaginatedERC20AssetDocumentListBackendSchema>(
      {url: `/api/v1/pwnsea/erc20-asset/${encodeURIComponent(String(query))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnseaErc20AssetRetrieveQueryKey = (query: MaybeRef<string>,
    params?: MaybeRef<PwnseaErc20AssetRetrieveParams>,) => {
    return ['api','v1','pwnsea','erc20-asset',query, ...(params ? [params]: [])] as const;
    }

    
export const getPwnseaErc20AssetRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetRetrieve>>, TError = unknown>(query: MaybeRef<string>,
    params?: MaybeRef<PwnseaErc20AssetRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaErc20AssetRetrieveQueryKey(query,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaErc20AssetRetrieve>>> = ({ signal }) => pwnseaErc20AssetRetrieve(query,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(query))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetRetrieve>>, TError, TData> 
}

export type PwnseaErc20AssetRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaErc20AssetRetrieve>>>
export type PwnseaErc20AssetRetrieveQueryError = unknown

export const usePwnseaErc20AssetRetrieve = <TData = Awaited<ReturnType<typeof pwnseaErc20AssetRetrieve>>, TError = unknown>(
 query: MaybeRef<string>,
    params?: MaybeRef<PwnseaErc20AssetRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaErc20AssetRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaErc20AssetRetrieveQueryOptions(query,params,options)

  const _query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return _query;
}



export const searchNFTMore = (
    query: MaybeRef<string>,
    params?: MaybeRef<SearchNFTMoreParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      query = unref(query);
params = unref(params);
      
      return customInstance<NFTAssetSearchMoreSchemaBackendSchema>(
      {url: `/api/v1/pwnsea/nft-asset-more/${encodeURIComponent(String(query))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getSearchNFTMoreQueryKey = (query: MaybeRef<string>,
    params?: MaybeRef<SearchNFTMoreParams>,) => {
    return ['api','v1','pwnsea','nft-asset-more',query, ...(params ? [params]: [])] as const;
    }

    
export const getSearchNFTMoreQueryOptions = <TData = Awaited<ReturnType<typeof searchNFTMore>>, TError = unknown>(query: MaybeRef<string>,
    params?: MaybeRef<SearchNFTMoreParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchNFTMore>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getSearchNFTMoreQueryKey(query,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchNFTMore>>> = ({ signal }) => searchNFTMore(query,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(query))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchNFTMore>>, TError, TData> 
}

export type SearchNFTMoreQueryResult = NonNullable<Awaited<ReturnType<typeof searchNFTMore>>>
export type SearchNFTMoreQueryError = unknown

export const useSearchNFTMore = <TData = Awaited<ReturnType<typeof searchNFTMore>>, TError = unknown>(
 query: MaybeRef<string>,
    params?: MaybeRef<SearchNFTMoreParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchNFTMore>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchNFTMoreQueryOptions(query,params,options)

  const _query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return _query;
}



/**
 * Used for suggesting search items based on the character user types in
 */
export const pwnseaNftAssetSuggestList = (
    params?: MaybeRef<PwnseaNftAssetSuggestListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedNFTAssetSuggestListBackendSchema>(
      {url: `/api/v1/pwnsea/nft-asset-suggest/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnseaNftAssetSuggestListQueryKey = (params?: MaybeRef<PwnseaNftAssetSuggestListParams>,) => {
    return ['api','v1','pwnsea','nft-asset-suggest', ...(params ? [params]: [])] as const;
    }

    
export const getPwnseaNftAssetSuggestListQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftAssetSuggestList>>, TError = unknown>(params?: MaybeRef<PwnseaNftAssetSuggestListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftAssetSuggestListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftAssetSuggestList>>> = ({ signal }) => pwnseaNftAssetSuggestList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestList>>, TError, TData> 
}

export type PwnseaNftAssetSuggestListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftAssetSuggestList>>>
export type PwnseaNftAssetSuggestListQueryError = unknown

export const usePwnseaNftAssetSuggestList = <TData = Awaited<ReturnType<typeof pwnseaNftAssetSuggestList>>, TError = unknown>(
 params?: MaybeRef<PwnseaNftAssetSuggestListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftAssetSuggestListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Used for suggesting search items based on the character user types in
 */
export const pwnseaNftAssetSuggestRetrieve = (
    id: MaybeRef<number>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customInstance<NFTAssetSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/nft-asset-suggest/${encodeURIComponent(String(id))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaNftAssetSuggestRetrieveQueryKey = (id: MaybeRef<number>,) => {
    return ['api','v1','pwnsea','nft-asset-suggest',id] as const;
    }

    
export const getPwnseaNftAssetSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftAssetSuggestRetrieve>>, TError = unknown>(id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftAssetSuggestRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftAssetSuggestRetrieve>>> = ({ signal }) => pwnseaNftAssetSuggestRetrieve(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestRetrieve>>, TError, TData> 
}

export type PwnseaNftAssetSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftAssetSuggestRetrieve>>>
export type PwnseaNftAssetSuggestRetrieveQueryError = unknown

export const usePwnseaNftAssetSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaNftAssetSuggestRetrieve>>, TError = unknown>(
 id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftAssetSuggestRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Functional suggest functionality.

:param request:
:return:
 */
export const pwnseaNftAssetSuggestFunctionalSuggestRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<NFTAssetSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/nft-asset-suggest/functional_suggest/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaNftAssetSuggestFunctionalSuggestRetrieveQueryKey = () => {
    return ['api','v1','pwnsea','nft-asset-suggest','functional_suggest'] as const;
    }

    
export const getPwnseaNftAssetSuggestFunctionalSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftAssetSuggestFunctionalSuggestRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestFunctionalSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftAssetSuggestFunctionalSuggestRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftAssetSuggestFunctionalSuggestRetrieve>>> = ({ signal }) => pwnseaNftAssetSuggestFunctionalSuggestRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestFunctionalSuggestRetrieve>>, TError, TData> 
}

export type PwnseaNftAssetSuggestFunctionalSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftAssetSuggestFunctionalSuggestRetrieve>>>
export type PwnseaNftAssetSuggestFunctionalSuggestRetrieveQueryError = unknown

export const usePwnseaNftAssetSuggestFunctionalSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaNftAssetSuggestFunctionalSuggestRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestFunctionalSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftAssetSuggestFunctionalSuggestRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Suggest functionality.
 */
export const pwnseaNftAssetSuggestSuggestRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<NFTAssetSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/nft-asset-suggest/suggest/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaNftAssetSuggestSuggestRetrieveQueryKey = () => {
    return ['api','v1','pwnsea','nft-asset-suggest','suggest'] as const;
    }

    
export const getPwnseaNftAssetSuggestSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftAssetSuggestSuggestRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftAssetSuggestSuggestRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftAssetSuggestSuggestRetrieve>>> = ({ signal }) => pwnseaNftAssetSuggestSuggestRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestSuggestRetrieve>>, TError, TData> 
}

export type PwnseaNftAssetSuggestSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftAssetSuggestSuggestRetrieve>>>
export type PwnseaNftAssetSuggestSuggestRetrieveQueryError = unknown

export const usePwnseaNftAssetSuggestSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaNftAssetSuggestSuggestRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetSuggestSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftAssetSuggestSuggestRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const pwnseaNftAssetRetrieve = (
    query: MaybeRef<string>,
    params?: MaybeRef<PwnseaNftAssetRetrieveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      query = unref(query);
params = unref(params);
      
      return customInstance<PaginatedNFTAssetDocumentListBackendSchema>(
      {url: `/api/v1/pwnsea/nft-asset/${encodeURIComponent(String(query))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnseaNftAssetRetrieveQueryKey = (query: MaybeRef<string>,
    params?: MaybeRef<PwnseaNftAssetRetrieveParams>,) => {
    return ['api','v1','pwnsea','nft-asset',query, ...(params ? [params]: [])] as const;
    }

    
export const getPwnseaNftAssetRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftAssetRetrieve>>, TError = unknown>(query: MaybeRef<string>,
    params?: MaybeRef<PwnseaNftAssetRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftAssetRetrieveQueryKey(query,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftAssetRetrieve>>> = ({ signal }) => pwnseaNftAssetRetrieve(query,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(query))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetRetrieve>>, TError, TData> 
}

export type PwnseaNftAssetRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftAssetRetrieve>>>
export type PwnseaNftAssetRetrieveQueryError = unknown

export const usePwnseaNftAssetRetrieve = <TData = Awaited<ReturnType<typeof pwnseaNftAssetRetrieve>>, TError = unknown>(
 query: MaybeRef<string>,
    params?: MaybeRef<PwnseaNftAssetRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftAssetRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftAssetRetrieveQueryOptions(query,params,options)

  const _query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return _query;
}



export const searchNFTCollectionMore = (
    query: MaybeRef<string>,
    params?: MaybeRef<SearchNFTCollectionMoreParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      query = unref(query);
params = unref(params);
      
      return customInstance<NFTCollectionSearchMoreSchemaBackendSchema>(
      {url: `/api/v1/pwnsea/nft-collection-more/${encodeURIComponent(String(query))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getSearchNFTCollectionMoreQueryKey = (query: MaybeRef<string>,
    params?: MaybeRef<SearchNFTCollectionMoreParams>,) => {
    return ['api','v1','pwnsea','nft-collection-more',query, ...(params ? [params]: [])] as const;
    }

    
export const getSearchNFTCollectionMoreQueryOptions = <TData = Awaited<ReturnType<typeof searchNFTCollectionMore>>, TError = unknown>(query: MaybeRef<string>,
    params?: MaybeRef<SearchNFTCollectionMoreParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchNFTCollectionMore>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getSearchNFTCollectionMoreQueryKey(query,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchNFTCollectionMore>>> = ({ signal }) => searchNFTCollectionMore(query,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(query))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchNFTCollectionMore>>, TError, TData> 
}

export type SearchNFTCollectionMoreQueryResult = NonNullable<Awaited<ReturnType<typeof searchNFTCollectionMore>>>
export type SearchNFTCollectionMoreQueryError = unknown

export const useSearchNFTCollectionMore = <TData = Awaited<ReturnType<typeof searchNFTCollectionMore>>, TError = unknown>(
 query: MaybeRef<string>,
    params?: MaybeRef<SearchNFTCollectionMoreParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchNFTCollectionMore>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchNFTCollectionMoreQueryOptions(query,params,options)

  const _query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return _query;
}



/**
 * Used for suggesting search items based on the character user types in
 */
export const pwnseaNftCollectionSuggestList = (
    params?: MaybeRef<PwnseaNftCollectionSuggestListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedNFTAssetCollectionSuggestListBackendSchema>(
      {url: `/api/v1/pwnsea/nft-collection-suggest/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnseaNftCollectionSuggestListQueryKey = (params?: MaybeRef<PwnseaNftCollectionSuggestListParams>,) => {
    return ['api','v1','pwnsea','nft-collection-suggest', ...(params ? [params]: [])] as const;
    }

    
export const getPwnseaNftCollectionSuggestListQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionSuggestList>>, TError = unknown>(params?: MaybeRef<PwnseaNftCollectionSuggestListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftCollectionSuggestListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestList>>> = ({ signal }) => pwnseaNftCollectionSuggestList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestList>>, TError, TData> 
}

export type PwnseaNftCollectionSuggestListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestList>>>
export type PwnseaNftCollectionSuggestListQueryError = unknown

export const usePwnseaNftCollectionSuggestList = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionSuggestList>>, TError = unknown>(
 params?: MaybeRef<PwnseaNftCollectionSuggestListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftCollectionSuggestListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Used for suggesting search items based on the character user types in
 */
export const pwnseaNftCollectionSuggestRetrieve = (
    id: MaybeRef<number>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customInstance<NFTAssetCollectionSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/nft-collection-suggest/${encodeURIComponent(String(id))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaNftCollectionSuggestRetrieveQueryKey = (id: MaybeRef<number>,) => {
    return ['api','v1','pwnsea','nft-collection-suggest',id] as const;
    }

    
export const getPwnseaNftCollectionSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionSuggestRetrieve>>, TError = unknown>(id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftCollectionSuggestRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestRetrieve>>> = ({ signal }) => pwnseaNftCollectionSuggestRetrieve(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestRetrieve>>, TError, TData> 
}

export type PwnseaNftCollectionSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestRetrieve>>>
export type PwnseaNftCollectionSuggestRetrieveQueryError = unknown

export const usePwnseaNftCollectionSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionSuggestRetrieve>>, TError = unknown>(
 id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftCollectionSuggestRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Functional suggest functionality.

:param request:
:return:
 */
export const pwnseaNftCollectionSuggestFunctionalSuggestRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<NFTAssetCollectionSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/nft-collection-suggest/functional_suggest/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaNftCollectionSuggestFunctionalSuggestRetrieveQueryKey = () => {
    return ['api','v1','pwnsea','nft-collection-suggest','functional_suggest'] as const;
    }

    
export const getPwnseaNftCollectionSuggestFunctionalSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionSuggestFunctionalSuggestRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestFunctionalSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftCollectionSuggestFunctionalSuggestRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestFunctionalSuggestRetrieve>>> = ({ signal }) => pwnseaNftCollectionSuggestFunctionalSuggestRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestFunctionalSuggestRetrieve>>, TError, TData> 
}

export type PwnseaNftCollectionSuggestFunctionalSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestFunctionalSuggestRetrieve>>>
export type PwnseaNftCollectionSuggestFunctionalSuggestRetrieveQueryError = unknown

export const usePwnseaNftCollectionSuggestFunctionalSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionSuggestFunctionalSuggestRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestFunctionalSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftCollectionSuggestFunctionalSuggestRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Suggest functionality.
 */
export const pwnseaNftCollectionSuggestSuggestRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<NFTAssetCollectionSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/nft-collection-suggest/suggest/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaNftCollectionSuggestSuggestRetrieveQueryKey = () => {
    return ['api','v1','pwnsea','nft-collection-suggest','suggest'] as const;
    }

    
export const getPwnseaNftCollectionSuggestSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionSuggestSuggestRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftCollectionSuggestSuggestRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestSuggestRetrieve>>> = ({ signal }) => pwnseaNftCollectionSuggestSuggestRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestSuggestRetrieve>>, TError, TData> 
}

export type PwnseaNftCollectionSuggestSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestSuggestRetrieve>>>
export type PwnseaNftCollectionSuggestSuggestRetrieveQueryError = unknown

export const usePwnseaNftCollectionSuggestSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionSuggestSuggestRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionSuggestSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftCollectionSuggestSuggestRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const pwnseaNftCollectionRetrieve = (
    query: MaybeRef<string>,
    params?: MaybeRef<PwnseaNftCollectionRetrieveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      query = unref(query);
params = unref(params);
      
      return customInstance<PaginatedNFTAssetCollectionDocumentListBackendSchema>(
      {url: `/api/v1/pwnsea/nft-collection/${encodeURIComponent(String(query))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnseaNftCollectionRetrieveQueryKey = (query: MaybeRef<string>,
    params?: MaybeRef<PwnseaNftCollectionRetrieveParams>,) => {
    return ['api','v1','pwnsea','nft-collection',query, ...(params ? [params]: [])] as const;
    }

    
export const getPwnseaNftCollectionRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionRetrieve>>, TError = unknown>(query: MaybeRef<string>,
    params?: MaybeRef<PwnseaNftCollectionRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaNftCollectionRetrieveQueryKey(query,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaNftCollectionRetrieve>>> = ({ signal }) => pwnseaNftCollectionRetrieve(query,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(query))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionRetrieve>>, TError, TData> 
}

export type PwnseaNftCollectionRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaNftCollectionRetrieve>>>
export type PwnseaNftCollectionRetrieveQueryError = unknown

export const usePwnseaNftCollectionRetrieve = <TData = Awaited<ReturnType<typeof pwnseaNftCollectionRetrieve>>, TError = unknown>(
 query: MaybeRef<string>,
    params?: MaybeRef<PwnseaNftCollectionRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaNftCollectionRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaNftCollectionRetrieveQueryOptions(query,params,options)

  const _query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return _query;
}



/**
 * Used for suggesting search items based on the character user types in
 */
export const pwnseaUserSuggestList = (
    params?: MaybeRef<PwnseaUserSuggestListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedUserSuggestListBackendSchema>(
      {url: `/api/v1/pwnsea/user-suggest/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnseaUserSuggestListQueryKey = (params?: MaybeRef<PwnseaUserSuggestListParams>,) => {
    return ['api','v1','pwnsea','user-suggest', ...(params ? [params]: [])] as const;
    }

    
export const getPwnseaUserSuggestListQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaUserSuggestList>>, TError = unknown>(params?: MaybeRef<PwnseaUserSuggestListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaUserSuggestListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaUserSuggestList>>> = ({ signal }) => pwnseaUserSuggestList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestList>>, TError, TData> 
}

export type PwnseaUserSuggestListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaUserSuggestList>>>
export type PwnseaUserSuggestListQueryError = unknown

export const usePwnseaUserSuggestList = <TData = Awaited<ReturnType<typeof pwnseaUserSuggestList>>, TError = unknown>(
 params?: MaybeRef<PwnseaUserSuggestListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaUserSuggestListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Used for suggesting search items based on the character user types in
 */
export const pwnseaUserSuggestRetrieve = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customInstance<UserSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/user-suggest/${encodeURIComponent(String(id))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaUserSuggestRetrieveQueryKey = (id: MaybeRef<string>,) => {
    return ['api','v1','pwnsea','user-suggest',id] as const;
    }

    
export const getPwnseaUserSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaUserSuggestRetrieve>>, TError = unknown>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaUserSuggestRetrieveQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaUserSuggestRetrieve>>> = ({ signal }) => pwnseaUserSuggestRetrieve(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestRetrieve>>, TError, TData> 
}

export type PwnseaUserSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaUserSuggestRetrieve>>>
export type PwnseaUserSuggestRetrieveQueryError = unknown

export const usePwnseaUserSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaUserSuggestRetrieve>>, TError = unknown>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaUserSuggestRetrieveQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Functional suggest functionality.

:param request:
:return:
 */
export const pwnseaUserSuggestFunctionalSuggestRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/user-suggest/functional_suggest/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaUserSuggestFunctionalSuggestRetrieveQueryKey = () => {
    return ['api','v1','pwnsea','user-suggest','functional_suggest'] as const;
    }

    
export const getPwnseaUserSuggestFunctionalSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaUserSuggestFunctionalSuggestRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestFunctionalSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaUserSuggestFunctionalSuggestRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaUserSuggestFunctionalSuggestRetrieve>>> = ({ signal }) => pwnseaUserSuggestFunctionalSuggestRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestFunctionalSuggestRetrieve>>, TError, TData> 
}

export type PwnseaUserSuggestFunctionalSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaUserSuggestFunctionalSuggestRetrieve>>>
export type PwnseaUserSuggestFunctionalSuggestRetrieveQueryError = unknown

export const usePwnseaUserSuggestFunctionalSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaUserSuggestFunctionalSuggestRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestFunctionalSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaUserSuggestFunctionalSuggestRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Suggest functionality.
 */
export const pwnseaUserSuggestSuggestRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserSuggestBackendSchema>(
      {url: `/api/v1/pwnsea/user-suggest/suggest/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnseaUserSuggestSuggestRetrieveQueryKey = () => {
    return ['api','v1','pwnsea','user-suggest','suggest'] as const;
    }

    
export const getPwnseaUserSuggestSuggestRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaUserSuggestSuggestRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaUserSuggestSuggestRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaUserSuggestSuggestRetrieve>>> = ({ signal }) => pwnseaUserSuggestSuggestRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestSuggestRetrieve>>, TError, TData> 
}

export type PwnseaUserSuggestSuggestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaUserSuggestSuggestRetrieve>>>
export type PwnseaUserSuggestSuggestRetrieveQueryError = unknown

export const usePwnseaUserSuggestSuggestRetrieve = <TData = Awaited<ReturnType<typeof pwnseaUserSuggestSuggestRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserSuggestSuggestRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaUserSuggestSuggestRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const pwnseaUserRetrieve = (
    query: MaybeRef<string>,
    params?: MaybeRef<PwnseaUserRetrieveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      query = unref(query);
params = unref(params);
      
      return customInstance<UserDocumentBackendSchema[]>(
      {url: `/api/v1/pwnsea/user/${encodeURIComponent(String(query))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnseaUserRetrieveQueryKey = (query: MaybeRef<string>,
    params?: MaybeRef<PwnseaUserRetrieveParams>,) => {
    return ['api','v1','pwnsea','user',query, ...(params ? [params]: [])] as const;
    }

    
export const getPwnseaUserRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnseaUserRetrieve>>, TError = unknown>(query: MaybeRef<string>,
    params?: MaybeRef<PwnseaUserRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnseaUserRetrieveQueryKey(query,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnseaUserRetrieve>>> = ({ signal }) => pwnseaUserRetrieve(query,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(query))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserRetrieve>>, TError, TData> 
}

export type PwnseaUserRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnseaUserRetrieve>>>
export type PwnseaUserRetrieveQueryError = unknown

export const usePwnseaUserRetrieve = <TData = Awaited<ReturnType<typeof pwnseaUserRetrieve>>, TError = unknown>(
 query: MaybeRef<string>,
    params?: MaybeRef<PwnseaUserRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnseaUserRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnseaUserRetrieveQueryOptions(query,params,options)

  const _query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  _query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return _query;
}



