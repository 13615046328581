import { ref } from 'vue'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'

const isFetchingAppraisals = ref(false)

export default function useSectionBundleAssetsTable() {
  const loadAllAppraisals = async (bundle: AssetMetadata, refresh?: boolean | undefined): Promise<void> => {
    isFetchingAppraisals.value = true
    await useMetadataFetch().fetchBundleAssetsAppraisal(bundle, refresh)
    isFetchingAppraisals.value = false
  }

  return {
    loadAllAppraisals,
    isFetchingAppraisals,
  }
}
