import { useQuery } from '@tanstack/vue-query'
import { useBytecode } from '@wagmi/vue'
import { computed } from 'vue'
import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import { queries } from '@/modules/queries'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

export const useConnectedAccountTypeStore = defineStore(StoreIds.connectedAccountType, () => {
  if (isStarknet) {
    return {
      isConnectedContractWallet: computed(() => false),
      isConnectedPwnSafe: computed(() => false),
    }
  }

  const { address: userAddress, chainId: connectedChainId, isConnected } = useCustomAccount()

  const { data: bytecodeAtUserAddress } = useBytecode({
    address: userAddress,
    chainId: connectedChainId,
    // TODO scope key?
    query: {
      enabled: isConnected,
      staleTime: Infinity,
      // TODO select parameter?
    },
  })
  const isConnectedContractWallet = computed(() => !!bytecodeAtUserAddress.value)

  const { data: connectedPwnSafe } = useQuery({
    ...queries.safe.detail(userAddress, connectedChainId),
    enabled: isConnected,
    staleTime: Infinity,
    retry: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
  const isConnectedPwnSafe = computed(() => !!connectedPwnSafe.value)

  return {
    isConnectedContractWallet,
    isConnectedPwnSafe,
  }
})
