const preProcessZeroWithDecimalPoint = (value: string) => {
  value = value.replace(/[,]/g, '.')

  if (value.startsWith('0') && value.length > 1 && !value.startsWith('0.')) {
    return '0.' + value.slice(1)
  }
  return value
}

export const maskPercentTwoDecimals = {
  mask: '*.##%',
  tokens: {
    '*': { pattern: /[0-9]/, repeat: true, multiple: true },
    '%': { pattern: /%/, optional: true },
    '#': { pattern: /[0-9]/, optional: true },
    '.': { pattern: /./, optional: true },
  },
  preProcess: preProcessZeroWithDecimalPoint,
}

export const maskTokenAmount = (maxDecimals = 18) => {
  return {
    mask: `*.${'#'.repeat(maxDecimals)}`,
    tokens: {
      '*': { pattern: /[0-9]/, repeat: true, multiple: true },
    },
    preProcess: preProcessZeroWithDecimalPoint,
  }
}

export const maskPercentCappedIntegers = (maxIntegers: number) => {
  return {
    mask: `${'*'.repeat(maxIntegers)}%`,
    tokens: {
      '*': { pattern: /[0-9]/, optional: true },
      '%': { pattern: /%/, optional: true },
    },
  }
}

export const maskDaysCappedIntegers = (maxIntegers: number) => {
  return {
    mask: `${'#'.repeat(maxIntegers)}-Days`,
    tokens: {
      '#': { pattern: /[0-9]/, optional: true },
      '-': { pattern: /\s/, optional: true },
      D: { pattern: /[Dd]/, optional: true },
      a: { pattern: /[Aa]/, optional: true },
      y: { pattern: /[Yy]/, optional: true },
      s: { pattern: /[Ss]/, optional: true },
    },
  }
}
