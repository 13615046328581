import type { BaseSearchResult } from '@/modules/common/pwn/explorer/models/index'
import type { SupportedChain } from '@/constants/chains/types'
import { parseChainIdFromResponse } from '@/modules/common/backend/backendUtils'
import type { RouteLocationRaw } from 'vue-router'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { getNftUniqueKey } from '@/utils/unique-keys'
import type { NftUniqueKey } from '@/utils/unique-keys'
import { getAddress } from 'viem'
import type { Address } from 'viem'
import type { NFTAssetDocumentBackendSchema } from '@/modules/common/backend/generated'

export class NFTAssetSearchResult implements BaseSearchResult {
  chainId: SupportedChain
  contractAddress: Address
  name: string
  collectionName: string
  image: string
  isVerified: boolean
  tokenId: string

  constructor(asset: Partial<NFTAssetSearchResult>) {
    // @ts-expect-error TS(2322) FIXME: Type 'SupportedChain | undefined' is not assignabl... Remove this comment to see the full error message
    this.chainId = asset?.chainId
    // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
    this.contractAddress = getAddress(asset?.contractAddress)
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.name = asset?.name
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.image = asset?.image
    this.isVerified = asset?.isVerified ?? false
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.tokenId = asset?.tokenId
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.collectionName = asset.collectionName
  }

  public static createFromBackendModel(nftAssetDocument: NFTAssetDocumentBackendSchema): NFTAssetSearchResult {
    return new NFTAssetSearchResult({
      chainId: parseChainIdFromResponse(nftAssetDocument?.contract?.chain_id),
      contractAddress: nftAssetDocument?.contract?.address as Address | undefined,
      name: nftAssetDocument?.name,
      collectionName: nftAssetDocument?.collection?.name ?? undefined,
      image: nftAssetDocument?.thumbnail_url,
      isVerified: nftAssetDocument?.is_verified ?? false,
      tokenId: nftAssetDocument?.token_id,
    })
  }

  get title(): string {
    return this.name
  }

  get key(): NftUniqueKey {
    return getNftUniqueKey(this)
  }

  pageRoute(): RouteLocationRaw {
    return {
      name: RouteName.NftPage,
      params: {
        contractAddress: this.contractAddress,
        tokenId: this.tokenId,
        chainName: CHAINS_CONSTANTS[this.chainId].general.chainName.toLowerCase(),
      },
    }
  }
}
