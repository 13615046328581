import type { LoanRequestFilterSettingsBackendSchema, LoanRequestFilterSettingsRequestBackendSchema } from '@/modules/common/backend/generated'

export default class UserLoanRequestFilterSettings {
  public onlyVerifiedCollateral?: boolean
  public filterDuration?: boolean
  public minDurationDays?: number
  public maxDurationDays?: number
  public filterLtv?: boolean
  public minLtv?: number
  public maxLtv?: number

  constructor(loanRequestFilterSettings: UserLoanRequestFilterSettings) {
    this.onlyVerifiedCollateral = loanRequestFilterSettings.onlyVerifiedCollateral
    this.filterDuration = loanRequestFilterSettings.filterDuration
    this.minDurationDays = loanRequestFilterSettings.minDurationDays
    this.maxDurationDays = loanRequestFilterSettings.maxDurationDays
    this.filterLtv = loanRequestFilterSettings.filterLtv
    this.minLtv = loanRequestFilterSettings.minLtv
    this.maxLtv = loanRequestFilterSettings.maxLtv
  }

  public static createFromBackendModel(loanRequestFilterSettings: LoanRequestFilterSettingsBackendSchema): UserLoanRequestFilterSettings {
    return new UserLoanRequestFilterSettings({
      onlyVerifiedCollateral: loanRequestFilterSettings.only_verified_collateral,
      filterDuration: loanRequestFilterSettings.filter_duration,
      minDurationDays: loanRequestFilterSettings.min_duration_days ?? undefined,
      maxDurationDays: loanRequestFilterSettings.max_duration_days ?? undefined,
      filterLtv: loanRequestFilterSettings.filter_ltv,
      minLtv: loanRequestFilterSettings.min_ltv ?? undefined,
      maxLtv: loanRequestFilterSettings.max_ltv ?? undefined,
    })
  }

  public static createRequestBody(loanRequestFilterSettings: UserLoanRequestFilterSettings): LoanRequestFilterSettingsRequestBackendSchema {
    return {
      only_verified_collateral: loanRequestFilterSettings.onlyVerifiedCollateral,
      filter_duration: loanRequestFilterSettings.filterDuration,
      min_duration_days: loanRequestFilterSettings.minDurationDays,
      max_duration_days: loanRequestFilterSettings.maxDurationDays,
      filter_ltv: loanRequestFilterSettings.filterLtv,
      min_ltv: loanRequestFilterSettings.minLtv,
      max_ltv: loanRequestFilterSettings.maxLtv,
    }
  }
}
