import { computed, reactive, ref } from 'vue'
import type { RouteLocationNormalized, Router } from 'vue-router'
import BUNDLE_ASSETS_STEPS_DEFINITION from '@/modules/pages/token-bundler/bundle-assets/bundleAssetsStepsDefinition'
import RouteName from '@/router/RouteName'
import router from '@/router'
import type { BreadcrumbActiveStep } from '@/general-components/IBreadcrumbsHeader'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

const selectedAssetsToBundle = ref<AssetWithAmount[]>([])

const activeStep = ref<BreadcrumbActiveStep>()

export default function useBundleAssets() {
  const setActiveStep = (step: BreadcrumbActiveStep): void => {
    activeStep.value = step
    window.scrollTo({ top: 0 })
    // useAnalytics().trackEvent({ name: EventNames.LoanFlow, props: { action: step.stepName } })
  }

  const checkEnterStep = (route: RouteLocationNormalized, router: Router) => {
    if (route.name === RouteName.BundleAssetsChooseAssets) {
      return
    }
    if ([RouteName.BundleAssetsSubmit].includes(route.name as RouteName)) {
      return router.push({ name: RouteName.BundleAssetsChooseAssets })
    }
  }

  const initPage = (route: RouteLocationNormalized, router: Router) => {
    checkEnterStep(route, router)
    activeStep.value = BUNDLE_ASSETS_STEPS_DEFINITION[0]
  }

  const goToBundleAssetsSubmit = (): void => {
    selectedAssetsToBundle.value.forEach(asset => {
      if (asset.amountInput === '') {
        asset.amountInput = asset.maxAvailableAmount
      }
    })
    router.push({ name: RouteName.BundleAssetsSubmit })
  }

  return {
    initPage,
    activeStep: reactive(activeStep),
    setActiveStep,
    selectedAssetsToBundle,
    selectedAssetsChainId: computed(() => selectedAssetsToBundle.value[0]?.chainId),
    goToBundleAssetsSubmit,
  }
}
