import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useSearchPwnExplorer } from '@/modules/common/pwn/explorer/useSearchStore'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type TokenPriceStats from '@/modules/common/assets/typings/TokenPriceStats'
import type { SupportedChain } from '@/constants/chains/types'
import type { ERC20AssetSearchResult } from '@/modules/common/pwn/explorer/models/ERC20AssetSearchResult'
import { watchArray } from '@vueuse/core'
import { useCancelableRequests } from '@/modules/common/useCancellableRequest'
import { ExplorerStores } from '@/modules/common/pwn/explorer/constants'
import type { Address } from 'viem'

type ChainIdAndAddress = `${SupportedChain}-${Address}`

type TokenPriceStatsResult = Record<ChainIdAndAddress, TokenPriceStats>

export const useERC20SearchStatsStore = defineStore(ExplorerStores.TokenSearchStats, () => {
  const statsResult = ref<TokenPriceStatsResult | null>(null)
  const isFetched = ref(false)
  const shouldBeFetched = ref(false)
  const store = useSearchPwnExplorer()

  const tokenSearchResults = computed(() => {
    if (!shouldBeFetched.value) return []
    return useSearchPwnExplorer().results.tokens ?? []
  })

  const { setCancellableRequest, cancelRequests } = useCancelableRequests<ChainIdAndAddress>()

  watchArray<ERC20AssetSearchResult>(tokenSearchResults, async (currentTokenSearchResults, _, added) => {
    cancelRequests()
    // todo: add logic for empty searchTerm (dont fetch the data and create it from the existing ones which were fetched with the topToke
    if (!currentTokenSearchResults?.length || !added?.length || store.isSearchEmpty) {
      isFetched.value = true
      return
    }

    isFetched.value = false

    const tokenStats = {}

    const { fetchERC20PriceStats } = useMetadataFetch()

    const toFetch = added.length ? added : currentTokenSearchResults

    await Promise.allSettled(
      toFetch.map(async (token) => {
        const key: ChainIdAndAddress = token.key as ChainIdAndAddress
        if (statsResult.value?.[key]) {
          tokenStats[key] = statsResult.value?.[key]
          return
        }

        const controller = new AbortController()
        setCancellableRequest(key, controller)
        const stats = await fetchERC20PriceStats(
          token.chainId,
          token.contractAddress,
          { signal: controller.signal },
        )

        if (!stats) {
          return
        }

        tokenStats[key] = stats
      }),
    )

    statsResult.value = tokenStats
    isFetched.value = true
  })

  const getTokenPriceStats = (token: ERC20AssetSearchResult): TokenPriceStats | null => {
    if (token.stats) {
      return token.stats
    }
    const res: TokenPriceStats | undefined = statsResult.value?.[token.key]
    if (res?.volume24h?.usdAmount === '0') {
      return null
    }
    return res || null
  }

  const shouldFetch = (v: boolean) => {
    shouldBeFetched.value = v
  }

  return {
    isFetched,
    actions: {
      shouldFetch,
      getTokenPriceStats,
    },
  }
})
