import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { ref } from 'vue'
import router from '@/router'
import RouteName from '@/router/RouteName'

export const useSelectedAssetsStore = defineStore(StoreIds.useSelectedCollateral, () => {
  const selectedCollateral = ref<AssetWithAmount>()
  const selectedCredit = ref<AssetWithAmount>()

  return {
    selectedCollateral,
    selectedCredit,

    actions: {
      navigateWithSelected(selectedAsset: AssetWithAmount): void {
        selectedCollateral.value = selectedAsset

        router.push({
          name: RouteName.RevampBorrow,
        })
      },
      navigateWithSelectedCredit(selectedAsset: AssetWithAmount): void {
        selectedCredit.value = selectedAsset

        router.push({
          name: RouteName.RevampLend,
        })
      },
      resetCredit(): void {
        selectedCredit.value = undefined
      },
      reset(): void {
        selectedCollateral.value = undefined
      },
    },
  }
})
