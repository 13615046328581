<template>
  <div class="pwn-safe-tokenize-modal-asset-detail-step__container">
    <p class="pwn-safe-tokenize-modal-asset-detail-step__action-description">
      You are about to Mint an ATR token for the following asset:
    </p>
    <div class="pwn-safe-tokenize-modal-asset-detail-step__asset-cards-container">
      <SelectedAssetCard
        :asset="assetToTokenize"
        :display-amount="false"/>
      <div
        class="pwn-safe-tokenize-modal-asset-detail-step__add-more-assets"
        @click="handleClickTokenizeMoreAssets">
        Add More Assets +
      </div>
    </div>
    <p>
      This allows you to keep the asset in your PWN Safe while also keeping the benefits that are provided from holding it.
    </p>
    <div class="pwn-safe-tokenize-modal-asset-detail-step__buttons-container">
      <BaseButton
        button-text="cancel"
        :color="ButtonColor.White"
        :variant="ButtonVariant.Underline"
        @on-button-click="closeModal"/>
      <BaseButton
        button-text="Mint ATR"
        :is-disabled="isTokenizationDisabled"
        @on-button-click="handleStartTokenization"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick } from 'vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import SelectedAssetCard from '@/general-components/SelectedAssetCard.vue'
import usePwnSafeTokenizeModal from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModal'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import { useSafesList } from '@/modules/queryHooks/safe/useSafesList'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const router = useRouter()
const { closeModal, handleConfirmSelectedAssetsToTokenize } = usePwnSafeTokenizeModal()
const { selectedAssetsToTokenize, isTokenizationDisabled, chainTarget } = usePwnSafeTokenize()
const { address: userAddress } = useCustomAccount()

// @ts-expect-error FIXME: strictNullChecks
const { safes } = useSafesList(userAddress, chainTarget)

const assetToTokenize = computed(() => selectedAssetsToTokenize.value?.[0])

const handleClickTokenizeMoreAssets = async () => {
  const assetToTokenizeCopy = assetToTokenize.value
  await router.push({ name: RouteName.PwnSafeTokenize })
  closeModal()
  await nextTick()
  // we are closing the modal when rerouting to tokenize multiple page
  // due to the watcher in PwnSafeTokenizeModal that resets the selected assets
  // to tokenize when the modal is closed, we need to manually reassign here after closing
  selectedAssetsToTokenize.value = [assetToTokenizeCopy]
}

const handleStartTokenization = () => {
  // @ts-expect-error FIXME: strictNullChecks
  handleConfirmSelectedAssetsToTokenize(safes.value[0])
}
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.pwn-safe-tokenize-modal-asset-detail-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__asset-cards-container {
    display: flex;
    column-gap: 1rem;
  }

  &__add-more-assets {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    border: 1px solid var(--primary-color-1);
    color: var(--primary-color-1);

    transition: all 0.3s;

    &:hover {
      background-color: var(--primary-color-3);
      cursor: pointer;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
