import type { ColumnProperties } from '@/general-components/ColumnProperties'
import {
  sortAssetsByAmount,
  copyingSortAssetsByAppraisal,
  sortAssetsBySymbol,
} from '@/general-components/sorting/SortFunctions'

export enum SortOption {
  Symbol = 'symbol',
  Amount = 'amount',
  Appraisal = 'appraisal',
  Name = 'name'
}

export const SORT_OPTIONS_LOOKUP = {
  [SortOption.Symbol]: sortAssetsBySymbol,
  [SortOption.Amount]: sortAssetsByAmount,
  [SortOption.Appraisal]: copyingSortAssetsByAppraisal,
  [SortOption.Name]: sortAssetsBySymbol,
}

export const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Asset',
    width: '37%',
    slotName: 'asset-info',
    sortOptionName: SortOption.Symbol,
  },
  {
    title: 'Amount',
    width: '17%',
    slotName: 'amount',
    sortOptionName: SortOption.Amount,
  },
  {
    title: 'Appraisal',
    width: '13%',
    slotName: 'appraisal',
  },
  {
    title: '',
    width: '20%',
    slotName: 'action',
    align: 'right',
  },
]

export const SELECT_COINS_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '6%',
    slotName: 'checkbox',
  },
  {
    title: 'Asset',
    width: '42%',
    slotName: 'asset-info',
    sortOptionName: SortOption.Symbol,
  },
  {
    title: '',
    width: '26%',
    slotName: 'verification',
  },
  {
    title: 'Amount',
    width: '26%',
    slotName: 'amount',
    sortOptionName: SortOption.Amount,
  },
]
