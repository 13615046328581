<template>
  <div class="thesis-lender-commit">
    <BaseButton
      :variant="ButtonVariant.Outline"
      :font="ButtonFont.Screener"
      :size="ButtonSize.M"
      :color="ButtonColor.Primary"
      :button-text="props.forUser ? `Commit More` : `Commit`"
      @click.stop="() => (modalIsVisible = true)"/>

    <RevokeThesisProposals
      v-if="Object.values(noncesToRevoke).some((v) => v.length) && !props.hideCancelButton"
      :title-text="titleText"
      :nonces="noncesToRevoke"
      :thesis-id="thesis.id"
      :on-update="props.onUpdate"
      :asset-name="'all'"/>

    <ThesisSelectCommitedAsset
      :thesis="thesis"
      :error-text="errorText"
      :on-confirm="handleContinueWithSelected"
      :on-close="() => (modalIsVisible = false)"
      :is-submitting="formIsSubmitting"
      :stats-data="userThesisStatsData"
      :selected-assets="parsedCreditAssets"
      :is-visible="modalIsVisible"/>
  </div>
</template>

<script lang="ts" setup>
import BaseButton, { ButtonColor, ButtonFont, ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import ThesisSelectCommitedAsset from '@/revamp/components/modals/thesis-select-commited-asset/ThesisSelectCommitedAsset.vue'
import { computed, ref } from 'vue'
import { usePopulateThesis } from '@/revamp/hooks/thesis/usePopulateThesis'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { ThesisDetailParams, ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { useThesisDetail } from '@/modules/common/backend/generated'
import { parseThesisAsset, processThesisCreditStats } from '@/revamp/hooks/thesis/utils'
import type { SupportedChain } from '@/constants/chains/types'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import RevokeThesisProposals from '@/revamp/components/modals/thesis/RevokeThesisProposals.vue'
import type { AssetWithExtraThesisParams } from '@/revamp/hooks/thesis/types'
import { extractErrorMessage } from '@/modules/common/utils'
import { createDepositedAssets } from '@/constants/depositedAssets'
import { compareAddresses } from '@/utils/utils'
import { parseAddress } from '@/utils/address-utils'

const modalIsVisible = ref(false)

type Props = {
  thesis: ThesisSchemaWorkaroundBackendSchema;
  forUser?: boolean;
  onUpdate?: (thesisId: string) => void;

  hideCancelButton?: boolean;
};

const { address } = useCustomAccount()

const props = defineProps<Props>()
const titleText = computed(() => `Cancel ${props.thesis.title} ${'#' + props.thesis.id.padStart(4, '0')} Proposals`)

const reactiveThesisId = computed(() => props.thesis.id)

const userStatsParams = computed<ThesisDetailParams>(() => {
  return {
    user_address: address.value,
  }
})

const userThesisQueryIsEnabled = computed(() => {
  if (!address.value) return false

  return parseAddress(address.value) && !!reactiveThesisId.value
})

const thesisDetail = useThesisDetail(reactiveThesisId, userStatsParams, {
  query: {
    enabled: userThesisQueryIsEnabled,
  },
})

const userThesisCreditStats = computed(() => {
  return thesisDetail.data.value?.data.creditsStats
})

const userThesisStatsData = computed(() => {
  return processThesisCreditStats(userThesisCreditStats.value)
})

const parsedCollateralAssets = computed(() =>
  props.thesis.collateralAssets!.map((v) => {
    const parsedAsset = parseThesisAsset(v)
    return {
      ...parsedAsset,
      allocationPercentage: v.allocationPercentage,
    } as AssetWithExtraThesisParams
  }),
)

const parsedCreditAssets = computed(() => {
  const parsedThesisAssets = props.thesis.creditsStats?.map((v) => parseThesisAsset(v.creditAssetMetadata))
  if (!parsedThesisAssets) return []

  const { transformedDepositedAssets, assetsToFilter } = createDepositedAssets(parsedThesisAssets, parsedThesisAssets)
  const filteredParsedThesisAssets = parsedThesisAssets.filter((v) => !assetsToFilter.some((asset) => compareAddresses(asset.address, v.address)))
  return [...(filteredParsedThesisAssets || []), ...(transformedDepositedAssets || [])]
})

const { populateThesisMutation, getProposalsToCreate, checkCollateralKyc, thesis } = usePopulateThesis(
  parsedCollateralAssets,
  parsedCreditAssets,
  props.thesis,
  () => {
    modalIsVisible.value = false
  },
)

const errorText = computed(() => {
  if (!populateThesisMutation.error.value) return ''
  return extractErrorMessage(populateThesisMutation.error.value)
})

const noncesToRevoke = computed(() => {
  const result: Partial<Record<SupportedChain, bigint[]>> = {}

  const stats = Object.values(userThesisStatsData.value)

  stats.forEach((v) => {
    const chainId = v.creditAssetMetadata.chainId
    if (!result[chainId]) {
      result[chainId] = []
    }

    if (v.proposalNonces) {
      result[chainId]!.push(...v.proposalNonces.map(BigInt))
    }
  })

  return result
})

const formIsSubmitting = populateThesisMutation.isPending

const submitAction = (selectedAssets: AssetWithAmount[]) => {
  populateThesisMutation.mutate(
    {
      values: getProposalsToCreate(selectedAssets, props.thesis),
      previousNonces: noncesToRevoke.value,
    },
    {
      onSuccess: () => {
        props.onUpdate?.(props.thesis.id)
        thesisDetail.refetch()
      },
    },
  )
}
const handleContinueWithSelected = async (selectedAssets: AssetWithAmount[]) => {
  const isKyc = await checkCollateralKyc(props.thesis, () => submitAction(selectedAssets))
  if (isKyc) return
  submitAction(selectedAssets)
}

</script>

<style lang="postcss" scoped>
.thesis-lender-commit {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
</style>
