import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { ALL_SUPPORTED_CHAINS, TESTNET_CHAINS } from '@/constants/chains/types'
import type { SupportedChain, TopTokens } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import StoreIds from './storeIds'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Address } from 'viem'
import type { AssetInListSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import type { ERC20AssetSearchResult } from '@/modules/common/pwn/explorer/models/ERC20AssetSearchResult'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

export const useTopTokensStore = defineStore(StoreIds.topTokens, () => {
  const TOKENS_TO_PARSING_FUNCTIONS_MAPPING: Record<keyof TopTokens, (chainId: SupportedChain, address: Address) => AssetMetadata> = {
    dai: AssetMetadata.createDaiAssetMetadata,
    usdc: AssetMetadata.createUsdcAssetMetadata,
    usdt: AssetMetadata.createUsdtAssetMetadata,
    weth: AssetMetadata.createWethAssetMetadata,
    wmatic: AssetMetadata.createWmaticAssetMetadata,
    wcro: AssetMetadata.createWcronosAssetMetadata,
    wbnb: AssetMetadata.createWbnbAssetMetadata,
    pwnd: AssetMetadata.createPwndAssetMetadata,
    pwns: AssetMetadata.createPwnsAssetMetadata,
    'usdc.e': AssetMetadata.createPolygonUsdceAssetMetadata,
    wxdai: AssetMetadata.createGnosisWxdaiAssetMetadata,
  }

  const STABLECOINS_SYMBOLS = ['DAI', 'USDC', 'USDT', 'USDC.E']

  const parseTopTokensForChain = (chainId: SupportedChain): AssetMetadata[] => {
    const tokens = CHAINS_CONSTANTS[chainId].topTokens
    return Object.entries(tokens).flatMap(([tokenSymbol, tokenAddress]) => {
      if (!tokenAddress) {
        return []
      }

      if (!TESTNET_CHAINS.includes(chainId) && (!!tokens.pwnd || !!tokens.pwns)) {
        throw new Error('PWND & PWNS tokens should be added to top tokens only on testnets!')
      }

      return [TOKENS_TO_PARSING_FUNCTIONS_MAPPING[tokenSymbol](chainId, tokenAddress)]
    })
  }

  const topTokens = ref(ALL_SUPPORTED_CHAINS.reduce((topTokensMapping, chainId) => {
    topTokensMapping[chainId] = parseTopTokensForChain(chainId)
    return topTokensMapping
  }, {} as Record<SupportedChain, AssetMetadata[]>))

  const getStablecoinsByChainId = (chainId: SupportedChain) => topTokens.value[chainId].filter(token => STABLECOINS_SYMBOLS.includes(token.symbol.toUpperCase()))
  const getStablecoinsPlusWethByChainId = (chainId: SupportedChain) => topTokens.value[chainId]?.filter(token => STABLECOINS_SYMBOLS.includes(token.symbol.toUpperCase()) || token.symbol === 'WETH')

  const isAssetFake = (asset: AssetInListSchemaWorkaroundBackendSchema | AssetMetadata | AssetWithAmount | undefined | ERC20AssetSearchResult) => {
    if (!asset || isStarknet) {
      return false
    }

    const topTokens = getStablecoinsPlusWethByChainId(asset.chainId)
    // todo: how to solve this for bundles?
    const assetAddress = 'contractAddress' in asset ? asset.contractAddress : asset.address
    const assetSymbol = 'symbol' in asset ? asset.symbol : asset.name

    return topTokens.some((topToken) =>
      !asset.isVerified &&
      topToken.address.toLowerCase() !== assetAddress?.toLowerCase() &&
      topToken.symbol.toLowerCase() === assetSymbol?.toLowerCase(),
    )
  }

  return {
    topTokens,
    getStablecoinsByChainId,
    getStablecoinsPlusWethByChainId,
    isAssetFake,
  }
})
