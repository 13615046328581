<template>
  <div class="token-page-header__wrapper">
    <header class="token-page-header__container">
      <BaseBackButton
        class="token-page-header__back-button"/>
      <h1 class="token-page-header__title">
        Token overview
      </h1>
      <span
        v-if="isMobile && chainId"
        class="token-page-header__chain-info">
        <ChainIcon
          class="token-page-header__chain-icon"
          width="17"
          height="17"
          alt="chain icon"/>
        <span>{{ chainName }}</span>
      </span>
      <div class="token-page-header__right-section">
        <span v-if="!isMobile && chainId">
          <ChainIcon
            class="token-page-header__chain-icon"
            width="17"
            height="17"
            alt="chain icon"/>
          <span>{{ chainName }}</span>
        </span>
        <ModalShare
          heading="Share Asset"
          share-message="Hey, check out this asset I've found on PWN.xyz"/>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
import ModalShare from '@/general-components/ModalShare.vue'
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import { computed } from 'vue'
import { getChainName, getChainIcon } from '@/utils/chain'
import { isMobile } from '@/utils/mediaQueries'
import type { SupportedChain } from '@/constants/chains/types'

const props = defineProps<{
  chainId?: SupportedChain | null
  refreshErc20:() => void
  isAssetRefreshing: boolean
}>()

const chainName = computed(() => props.chainId ? getChainName(props.chainId) : null)
const ChainIcon = computed(() => props.chainId ? getChainIcon(props.chainId) : null)

</script>

<style scoped>
.token-page-header {
  &__wrapper {
    position: sticky;
    top: var(--menu-height);
    z-index: var(--z-index-token-page-header);

    margin-bottom: var(--space-40);

    &--borderless {
      margin-bottom: 2rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: var(--background-color);
    padding: 1rem 0;
    /* for fixing the content under this sticky header to be visible (1px stripe on the left) */

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      width: 2px;
      height: 100%;
      background: inherit;
    }

    &:not(&--borderless) {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-image: var(--border-gray-dashed);
        background-size: auto 2px;
        /* small hack how to hide buggy double border (top + bottom), when height is 1px */
      }
    }
  }

  &__chain-icon {
    margin-right: 10px;
  }

  &__back-button {
    margin-right: 1rem;
  }

  &__edit-button {
    margin-right: 1rem;
  }

  &__status {
    margin: 0 0 0 var(--space-20);
  }

  &__right-section {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 0.85rem;
  }

  &__chain-info {
    margin-right: 1rem;
  }

  &__triangle-icon {
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: 840px) {
    &__container {
      flex-flow: column nowrap;
      row-gap: 1rem;
      align-items: start;
    }

    &__right-section {
      flex-flow: row-reverse nowrap;
      margin-left: 0;
    }

    &__title {
      font-size: 1.25rem;
      line-height: 1.5rem;
      align-self: flex-start;
    }

    &__back-button {
      align-self: flex-start;
    }

    &__chain-info {
      position: absolute;
      top: 0;
      margin-right: 0;
    }
  }

  @keyframes spinning {
    from {
      transform: rotate(360deg);
    }

    to {
      transform: rotate(0deg);
    }
  }

  &__icon {
    &--spinning {
      animation: spinning 1s linear infinite;
    }
  }
}
</style>
