<template>
  <div
    v-if="notification"
    :class="['notification', `notification--${notification.variant}`, { 'notification--new': !notification.hasSeen && markAsSeenOnHover, 'notification--hoverable': markAsSeenOnHover }]"
    @mouseover="hoverAction">
    <div class="notification__body">
      <NotificationMessageAndStatus
        :notification="notification"
        :loan-data="loanData"
        :proposal-class="proposalClass"
        :has-repay-button="hasRepayButton"
        :has-claim-button="hasClaimButton"
        @loan-id-click="emit('loan-id-click')"/>
      <div v-if="notification.customFunctionAction">
        <span
          class="link link--font-oxygen-mono"
          @click="notification.customFunctionAction">
          {{ notification.customFunctionActionName }}
        </span>
      </div>
      <RepayButton
        v-if="hasRepayButton && loanData"
        :loan-data="loanData"
        :loan-id="loanId"
        :chain-id-where-is-loan="Number(chainIdWhereIsLoan)"
        is-in-notification
        :asset-to-repay-address="loanData.creditAsset?.address ? loanData.creditAsset.address as Hex : '0x'"
        :loan-contract-address="loanData.contractAddress as Hex"/>
      <ClaimButton
        v-if="hasClaimButton && loanData"
        :loan-data="loanData"
        is-in-notification
        :has-claim-button="!!loanData.hasClaimButton"
        :loan-id="loanId"
        :chain-id-where-is-loan="Number(chainIdWhereIsLoan)"/>
      <NotificationDateTxLinkAndChain
        :notification="notification"
        :loan-data="loanData"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import type Notification from '@/modules/common/notifications/Notification'
import useNotifications from '@/modules/common/notifications/useNotifications'
import { SupportedChain } from '@/constants/chains/types'
import { getAddress } from 'viem'
import type { Hex } from 'viem'
import {
  useLoanDetail,
  useProposalDetail,
} from '@/modules/common/backend/generated'
import { compareAddresses } from '@/utils/utils'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import { asyncComputed } from '@vueuse/core'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import RepayButton from '@/revamp/components/actions/RepayButton.vue'
import ClaimButton from '@/revamp/components/actions/ClaimButton.vue'
import NotificationMessageAndStatus from '@/general-components/notification/NotificationMessageAndStatus.vue'
import NotificationDateTxLinkAndChain from '@/general-components/notification/NotificationDateTxLinkAndChain.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { address: userAddress } = useCustomAccount()
interface Props {
  notification: Notification
  markAsSeenOnHover: boolean
}

const props = defineProps<Props>()
const { markAsSeenOnHover, notification } = toRefs(props)

const emit = defineEmits<{(e: 'loan-id-click'): void}>()
const markNotificationAsSeenOnHover = async (): Promise<void> => {
  if (!notification.value) return
  return await useNotifications().markNotificationsAsSeen([notification.value.id!])
}
const hoverAction = computed(() => {
  return markAsSeenOnHover.value && !notification.value.hasSeen ? markNotificationAsSeenOnHover : undefined
})

const chainIdWhereIsLoan = computed(() => String(notification.value?.chainId))
const loanTokenContractAddress = computed(() => notification.value?.loanTokenContractAddress ? getAddress(notification.value?.loanTokenContractAddress as string) : '')
const loanId = computed(() => String(notification.value?.loanOnChainId))
const proposalId = computed(() => Number(notification.value?.proposalId))
const isLoanDetailQueryEnabled = computed(() => Boolean(loanId.value && chainIdWhereIsLoan.value && loanTokenContractAddress.value))

const loanDetailQuery = useLoanDetail(chainIdWhereIsLoan, loanTokenContractAddress, loanId, {
  query: {
    retry: 3,
    // since we are on unichain sepolia, we need to retry every 60 seconds
    // event listener is super slow there, so that's the way
    retryDelay: chainIdWhereIsLoan.value === String(SupportedChain.UnichainSepolia) ? 60000 : 1000,
    enabled: isLoanDetailQueryEnabled,
  },
})

const loanData = computed(() => {
  const data = loanDetailQuery.data?.value?.data
  if (!data) {
    return null
  }
  const isBorrower = compareAddresses(userAddress.value, data?.borrower as Hex)
  const isLoanOwner = compareAddresses(userAddress.value, data.loanOwner as Hex)
  const isRunningLoan = LoanStatus.Accepted === +data.status as LoanStatus
  const isPaidBack = (LoanStatus.PaidBack === +data.status as LoanStatus || data.paidBackAt)
  const isDefaulted = LoanStatus.Defaulted === +data.status as LoanStatus
  const hasRepayButton = isBorrower && isRunningLoan
  const hasClaimButton = isLoanOwner && (isPaidBack || isDefaulted) && !data.claimedAt

  return { ...data, hasRepayButton, isRunningLoan, hasClaimButton }
})

const hasRepayButton = computed(() => {
  return Boolean(loanData.value?.hasRepayButton && loanData.value?.contractAddress)
})

const hasClaimButton = computed(() => {
  return Boolean(loanData.value?.contractAddress &&
    loanData.value?.hasClaimButton &&
    !loanData.value?.hasRepayButton)
})

const isProposalDetailQueryEnabled = computed(() => Boolean(proposalId.value && !loanId.value))

const proposalDetailQuery = useProposalDetail(proposalId, {
  query: {
    staleTime: 1000 * 60 * 5,
    enabled: isProposalDetailQueryEnabled,
  },
})

const proposalClass = asyncComputed(async () => {
  const v = proposalDetailQuery.data?.value?.data
  if (!v) {
    return null
  }
  return await ProposalFactory.createProposalFromBackendModel(v)
})

</script>

<style scoped>
.notification {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0.5rem;
  font-family: var(--font-family-oxygen-mono);
  font-size: 1rem;
  line-height: 24px;
  background: var(--background-color);
  background-image: var(--border-white-dashed);
  border: 1px solid #434343;

  &--hoverable {
    &:hover {
      background-color: var(--gray-3);
    }
  }

  &--new {
    background-color: var(--teal-4);
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
</style>
