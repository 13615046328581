<template>
  <div class="bundle-assets-choose-assets__wrapper">
    <BaseSearch
      v-model="searchTerm"
      :class="['bundle-assets-choose-assets__search', { 'bundle-assets-choose-assets__search--selected': searchTerm }]"
      search-placeholder="Search asset name"
      has-clear-button/>
    <div class="bundle-assets-choose-assets__actions">
      <RefreshAssets :show-text="false"/>
      <ChainGroupFilter
        :selected-chains="selectedChains!"
        :set-chain="selectedChainsStore.actions.setSelectedChain"/>
    </div>
  </div>
  <div class="bundle-assets-choose-assets__user-assets">
    <SectionYourNFTs
      :is-dashboard="false"
      table-min-width="0"
      :assets="userNfts"
      :amount-of-nfts="userNfts.length"
      :is-fetching-nfts="userNftsIsPending"
      :has-first-column-padding="false"
      :table-definition="SELECT_NFTS_TABLE_DEFINITION"
      :is-expandable="false"
      :is-asset-selected="handleIsAssetSelected"
      :is-asset-disabled="handleAssetDisabled"
      @on-asset-select="handleOnAssetSelect"/>
    <SectionYourCoins
      :is-dashboard="false"
      table-min-width="0"
      :assets="userErc20s"
      :amount-of-coins="userErc20s.length"
      :is-fetching-coins="userErc20sIsPending"
      :has-first-column-padding="false"
      :is-asset-selected="handleIsAssetSelected"
      :is-asset-disabled="handleAssetDisabled"
      :table-definition="SELECT_COINS_TABLE_DEFINITION"
      :is-expandable="false"
      hide-invalid-asset-tooltip
      @on-asset-select="handleOnAssetSelect"/>
  </div>
  <SelectedAssetsSection
    :selected-assets="selectedAssetsToBundle"
    class="bundle-assets-choose-assets__selected"
    @on-asset-amount-change="handleOnAssetAmountChange"
    @on-asset-select="handleOnAssetSelect"/>
  <div class="bundle-assets-choose-assets__continue">
    <div class="bundle-assets-choose-assets__approve-and-bundle">
      <div
        v-if="selectedNativeToken"
        class="bundle-assets-choose-assets__native-token-note">
        Note: {{ selectedNativeToken.symbol }} must be wrapped in order to be used on PWN
      </div>
      <ModalWrapNativeToken
        v-if="selectedNativeToken"
        button-text="Wrap and continue"
        :native-token="selectedNativeToken"
        @on-wrap-native-token="handleOnWrapNativeToken"/>
      <BaseButton
        v-else
        button-text="Continue"
        :is-disabled="!isContinueButtonEnabled"
        @on-button-click="goToBundleAssetsSubmit"/>
    </div>
  </div>
</template>

<script setup lang="ts">

import SectionYourNFTs from '@/modules/sections/your-assets/your-nfts/SectionYourNFTs.vue'
import SectionYourCoins from '@/modules/sections/your-assets/your-coins/SectionYourCoins.vue'
import { SELECT_NFTS_TABLE_DEFINITION } from '@/modules/sections/your-assets/your-nfts/YourNFTsDefinitions'
import { SELECT_COINS_TABLE_DEFINITION } from '@/modules/sections/your-assets/your-coins/YourCoinsDefinitions'
import BaseButton from '@/general-components/BaseButton.vue'
import { computed, onMounted, provide } from 'vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import useBundleAssets from '@/modules/pages/token-bundler/bundle-assets/useBundleAssets'
import useAssetsSelection from '@/modules/common/assets/useAssetsSelection'
import SelectedAssetsSection from '@/modules/sections/selected-assets/SelectedAssetsSection.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useBundleApprove from '@/modules/common/assets/useBundleApprove'
import ModalWrapNativeToken from '@/general-components/ModalWrapNativeToken.vue'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import { appliedFiltersKey } from '@/modules/common/injection-keys'
import RefreshAssets from '@/modules/pages/dashboard/RefreshAssets.vue'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'

const { addOrRemoveAssetSelection, isAssetSelectionValid, isAssetSelected, selectionChangeAfterWrapNativeToken } = useAssetsSelection()
const { isBundleApproved, checkSelectedAssetsApprovalsForBundler } = useBundleApprove()
const { goToBundleAssetsSubmit, selectedAssetsToBundle, selectedAssetsChainId } = useBundleAssets()
const userAssetsStore = useUserAssetsStore()
const { userNfts, userErc20s, userNftsIsPending, userErc20sIsPending } = storeToRefs(userAssetsStore)
const { searchTerm } = useDashboard()
const selectedChainsStore = useSelectedChainsStore()
const { selectedChains } = storeToRefs(selectedChainsStore)

const appliedFiltersOrSorting = computed(() =>
  // we create snapshot of all applied filters to pass it down to virtual table
  // component and trigger scroll event once value is changed
  JSON.stringify({
    // ...selectedSortOption.value,
    selectedChains: selectedChains.value,
    searchTerm: searchTerm.value,
  }))

provide(appliedFiltersKey, appliedFiltersOrSorting)

const handleOnAssetSelect = async (asset: AssetWithAmount) => {
  if (selectedAssetsChainId.value && selectedAssetsChainId.value !== asset.chainId) {
    return
  }
  addOrRemoveAssetSelection({ asset, selectedAssets: selectedAssetsToBundle })
  isBundleApproved.value = await checkSelectedAssetsApprovalsForBundler(selectedAssetsToBundle.value)
}

const handleOnAssetAmountChange = async (asset: AssetWithAmount) => {
  isBundleApproved.value = await checkSelectedAssetsApprovalsForBundler(selectedAssetsToBundle.value)
}

const handleIsAssetSelected = (asset: AssetWithAmount) => {
  return isAssetSelected({ asset, selectedAssets: selectedAssetsToBundle })
}

const handleAssetDisabled = (asset: AssetWithAmount) => {
  if (selectedAssetsToBundle.value.length >= 1) {
    return selectedAssetsChainId.value !== asset.chainId
  }

  return false
}

onMounted(() => {
  userAssetsStore.loadUserAssets(getAccount(pwnWagmiConfig).address!)
})

const selectedNativeToken = computed(() => selectedAssetsToBundle.value.find(asset => asset.isNativeToken))

const handleOnWrapNativeToken = async (nativeAndWrappedNativeToken: {
  nativeToken: AssetWithAmount,
  wrappedNativeToken: AssetWithAmount
}) => {
  selectionChangeAfterWrapNativeToken({ nativeAndWrappedNativeToken, selectedAssets: selectedAssetsToBundle })

  goToBundleAssetsSubmit()
}

const isContinueButtonEnabled = computed(() => isAssetSelectionValid(selectedAssetsToBundle.value))
</script>

<style scoped>
.bundle-assets-choose-assets {
  &__user-assets {
    display: flex;
    gap: 1.25rem;
    margin-top: 2.5rem;
    height: 450px;
  }

  &__selected {
    margin: 2.5rem 0 0.5rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 3.5rem;
    justify-content: space-between;
    column-gap: 0.5rem;
  }

  &__search {
    flex: 1 0;
    border: 1px solid var(--gray);
    padding: 0.3rem;
    font-family: var(--font-family-oxygen-mono);
    max-height: 42px;

    &--selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__continue {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: 1rem;
    padding-top: 1rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 1px;
      width: 100%;
      background-image: var(--border-gray-dashed);
      background-size: auto 2px;
      /* small hack how to hide buggy double border (top + bottom), when height is 1px */
    }
  }

  &__approve-and-bundle {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__native-token-note {
    display: flex;
    font-family: var(--font-family-oxygen-mono);
    color: var(--warning);
    align-self: center;
    font-size: 0.875rem;
  }

  @media only screen and (--small-viewport) {
    &__wrapper {
      flex-flow: column nowrap;
      row-gap: 1rem;
      align-items: flex-start;
    }
  }
}
</style>
