import type { SupportedChain } from '@/constants/chains/types'
import { BETA_PROPOSAL_TYPE, ALL_V1_1_PROPOSAL_TYPES, ALL_V1_2_PROPOSAL_TYPES } from './proposals/ProposalClasses'
import type { ProposalType } from './proposals/ProposalClasses'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { BETA_LOAN_TYPE, V1_1_LOAN_TYPE, V1_2_LOAN_TYPE } from '@/modules/common/pwn/loans/LoanClasses'
import type { LoanType } from '@/modules/common/pwn/loans/LoanClasses'
import type { ProposalAndLoanListSchemaWorkaroundBackendSchemaType } from '@/modules/common/backend/generated'
import { LoanDetailSchemaWorkaroundTypeEnumBackendSchema } from '@/modules/common/backend/generated'
import { formatAmountWithDecimals } from '@/utils/utils'
import { CREDIT_PER_COLLATERAL_UNIT_DENOMINATOR } from '@/constants/loans'
import Decimal from 'decimal.js'

export const isBetaProposalOrLoan = (type: ProposalType | LoanType): boolean => {
  return [BETA_PROPOSAL_TYPE, BETA_LOAN_TYPE].includes(type)
}

export const isAnyV1ProposalOrLoan = (type: ProposalType | LoanType): boolean => {
  return [
    ...ALL_V1_1_PROPOSAL_TYPES,
    ...ALL_V1_2_PROPOSAL_TYPES,
    V1_1_LOAN_TYPE,
    V1_2_LOAN_TYPE,
  ].includes(type)
}

export const getLoanTokenAddressForProposalOrLoan = (chainId: SupportedChain, type: ProposalType | LoanType): string | undefined => {
  if (isBetaProposalOrLoan(type)) {
    return CHAINS_CONSTANTS[chainId]?.pwnBetaContracts?.pwnLoan
  } else if (isAnyV1ProposalOrLoan(type)) {
    return CHAINS_CONSTANTS[chainId]?.pwnV1Contracts?.pwnLoanToken
  } else {
    // eslint-disable-next-line no-console
    console.error(`Unknown proposal or loan type: ${type}.`)
    return undefined
  }
}

export type ProposalAndLoanTypes = typeof ProposalAndLoanListSchemaWorkaroundBackendSchemaType[keyof typeof ProposalAndLoanListSchemaWorkaroundBackendSchemaType]
export const isLoan = (type: ProposalAndLoanTypes) => {
  const loanTypes = Object.values(LoanDetailSchemaWorkaroundTypeEnumBackendSchema) as string[]
  return loanTypes.includes(type)
}

export enum ProposalOrLoanEnum {
  V1_1 = 'v1.1',
  BETA = 'beta',
  V1_2 = 'v1.2',
}

export const getProposalOrLoanType = (type?: ProposalAndLoanTypes | null) => {
  switch (type) {
  case 'pwn_contracts.v1_2simpleloan':
  case 'pwn_contracts.v1_2simpleloanlistproposal':
  case 'pwn_contracts.v1_2simpleloanfungibleproposal':
  case 'pwn_contracts.v1_2simpleloandutchauctionproposal':
    return ProposalOrLoanEnum.V1_2
  case 'pwn_contracts.v1_1simpleloan':
  case 'pwn_contracts.v1_1simpleloansimpleproposal':
  case 'pwn_contracts.v1_1simpleloanlistproposal':
    return ProposalOrLoanEnum.V1_1
  case 'pwn_contracts.betaloan':
  case 'pwn_contracts.betaproposal':
    return ProposalOrLoanEnum.BETA
  default:
    return null
  }
}

export const calculateCreditPerCollateralUnit = (creditBigInt: bigint, collateralBigInt: bigint) => {
  const result = (creditBigInt * BigInt(10) ** BigInt(CREDIT_PER_COLLATERAL_UNIT_DENOMINATOR)) / collateralBigInt
  return result.toString()
}

export const calculateCollateralAmountFungibleProposal = ({
  creditPerCollateralUnit,
  collateralDecimals,
  availableCreditLimit,
  returnBigInt,
}: {
  creditPerCollateralUnit: string;
  collateralDecimals: number;
  availableCreditLimit: string;
  returnBigInt: boolean;
}) => {
  const availableCreditLimitBigInt = BigInt(availableCreditLimit)
  const creditPerCollateralUnitBigInt = BigInt(creditPerCollateralUnit)
  const maxPossibleCollateralAmountBigInt = (availableCreditLimitBigInt * BigInt(10) ** BigInt(CREDIT_PER_COLLATERAL_UNIT_DENOMINATOR)) / creditPerCollateralUnitBigInt

  if (returnBigInt) {
    return maxPossibleCollateralAmountBigInt
  } else {
    return formatAmountWithDecimals(maxPossibleCollateralAmountBigInt, collateralDecimals)
  }
}

export const calculateMinCreditAmount = (minCollateralAmount: bigint, creditPerCollateralUnit: bigint): bigint => {
  const result = new Decimal(String(minCollateralAmount)).times(String(creditPerCollateralUnit)).div(10 ** CREDIT_PER_COLLATERAL_UNIT_DENOMINATOR)
  // toFixed(0) here is just in case the result in wei is e.g. 33333333333333333333333.99 , so we round the last digit to 4
  return BigInt(String(result.toFixed(0)))
}
