<template>
  <div
    :class="['mobile-nav-search', {
      'mobile-nav-search--active': searchIsOpen
    }]">
    <div
      v-if="!searchIsOpen"
      :class="['mobile-nav-search__wrapper', {
        'mobile-nav-search__wrapper--active': searchIsOpen
      }]"
      @click="handleOpenSearch">
      <SearchIcon
        class="mobile-nav-search__icon"
        alt="Search icon"/>
    </div>

    <NavSearchBar
      v-if="searchIsOpen"
      variant="explorer"
      :show-results="showResults"
      placeholder="Search"
      :class="['mobile-nav-search__bar', {
        'mobile-nav-search__bar--active': searchIsOpen
      }]"/>

    <span
      :class="['mobile-nav-search__cancel-button', {
        'mobile-nav-search__cancel-button--active': searchIsOpen
      }]"
      @click="handleCloseSearch">
      Cancel
    </span>
  </div>
</template>

<script setup lang="ts">

import NavSearchBar from '@/modules/sections/nav-searchbar/NavSearchBar.vue'
import SearchIcon from '@/assets/icons/search-left.svg'
import { computed } from 'vue'
import router from '@/router'
import RouteName from '@/router/RouteName'

const showResults = computed(() => {
  const homePageRoutes = [RouteName.PwnExplorerPageLoans, RouteName.PwnExplorerPageCollections, RouteName.PwnExplorerPageAssets, RouteName.PwnExplorerPage, RouteName.LendExplorerPageAssets, RouteName.LendExplorerPageCollections, RouteName.LendExplorerPageLoans]
  return !homePageRoutes.includes(<RouteName>router.currentRoute.value.name)
})
type Props = {
  searchIsOpen: boolean
  handleOpenSearch: () => void
  handleCloseSearch: () => void
}

defineProps<Props>()

</script>

<style scoped>
.mobile-nav-search {
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  transform: translateX(0);

  &--active {
    transform: translateX(-40px);
    width: 100%;
  }

  &__bar {
    padding-right: 80px;
    width: 246px;
    box-sizing: content-box;
    transition: all 0.3s ease-in-out;

    &--active {
      margin: 0 0 0 -40px;
    }
  }

  &__wrapper {
    flex: 1;
    width: 2rem;
    height: 2rem;
    border: 1px solid rgb(var(--text-color-base) / 50%);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid var(--primary-color-1);
      cursor: pointer;
    }
  }

  &__cancel-button {
    font-size: 1rem;
    color: var(--primary-color-1);
    display: none;
    cursor: pointer;
    z-index: var(--z-index-app-top-menu);

    &--active {
      display: block;
      position: absolute;
      right: 0;
    }
  }

  &__button {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
</style>

<style>
.mobile-nav-search .search-results {
  width: 100%;
}

.mobile-nav-search .search {
  border: 1px solid rgb(var(--text-color-base) / 50%);
  box-sizing: border-box;
  height: 40px;
}

.mobile-nav-search .search__input {
  width: 100%;
}
</style>
