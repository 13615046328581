/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useInfiniteQuery,
  useQuery
} from '@tanstack/vue-query'
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ParsedLeaderboardPositionResponseListBackendSchema,
  ReputationLeaderboardRetriveParams,
  UserPotentialReputationPerLoanRepayResponseBackendSchema,
  UserPotentialReputationPerLoanRepayRetriveParams,
  UserPotentialReputationPerProposalFundResponseBackendSchema,
  UserPotentialReputationPerProposalFundRetriveParams,
  UserReputationResponseBackendSchema,
  UserReputationScoreRetriveParams,
  UserReputationScoreWithParametersRetriveParams,
  UserReputationWithParametersResponseBackendSchema
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const userReputationScoreWithParametersRetrive = (
    walletAddress: MaybeRef<string>,
    params?: MaybeRef<UserReputationScoreWithParametersRetriveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
params = unref(params);
      
      return customInstance<UserReputationWithParametersResponseBackendSchema>(
      {url: `/api/v1/reputation_scoring/reputation_score/detail/${encodeURIComponent(String(walletAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getUserReputationScoreWithParametersRetriveQueryKey = (walletAddress: MaybeRef<string>,
    params?: MaybeRef<UserReputationScoreWithParametersRetriveParams>,) => {
    return ['api','v1','reputation_scoring','reputation_score','detail',walletAddress, ...(params ? [params]: [])] as const;
    }

    
export const getUserReputationScoreWithParametersRetriveQueryOptions = <TData = Awaited<ReturnType<typeof userReputationScoreWithParametersRetrive>>, TError = unknown>(walletAddress: MaybeRef<string>,
    params?: MaybeRef<UserReputationScoreWithParametersRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userReputationScoreWithParametersRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getUserReputationScoreWithParametersRetriveQueryKey(walletAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userReputationScoreWithParametersRetrive>>> = ({ signal }) => userReputationScoreWithParametersRetrive(walletAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userReputationScoreWithParametersRetrive>>, TError, TData> 
}

export type UserReputationScoreWithParametersRetriveQueryResult = NonNullable<Awaited<ReturnType<typeof userReputationScoreWithParametersRetrive>>>
export type UserReputationScoreWithParametersRetriveQueryError = unknown

export const useUserReputationScoreWithParametersRetrive = <TData = Awaited<ReturnType<typeof userReputationScoreWithParametersRetrive>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    params?: MaybeRef<UserReputationScoreWithParametersRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userReputationScoreWithParametersRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserReputationScoreWithParametersRetriveQueryOptions(walletAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const reputationLeaderboardRetrive = (
    params?: MaybeRef<ReputationLeaderboardRetriveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<ParsedLeaderboardPositionResponseListBackendSchema>(
      {url: `/api/v1/reputation_scoring/reputation_score/leaderboard/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getReputationLeaderboardRetriveQueryKey = (params?: MaybeRef<ReputationLeaderboardRetriveParams>,) => {
    return ['api','v1','reputation_scoring','reputation_score','leaderboard', ...(params ? [params]: [])] as const;
    }

    
export const getReputationLeaderboardRetriveInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, ReputationLeaderboardRetriveParams['offset']>, TError = unknown>(params?: MaybeRef<ReputationLeaderboardRetriveParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, TError, TData, Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, QueryKey, ReputationLeaderboardRetriveParams['offset']>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getReputationLeaderboardRetriveQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, QueryKey, ReputationLeaderboardRetriveParams['offset']> = ({ signal, pageParam }) => reputationLeaderboardRetrive({...unref(params), offset: pageParam || unref(params)?.['offset']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, TError, TData, Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, QueryKey, ReputationLeaderboardRetriveParams['offset']> 
}

export type ReputationLeaderboardRetriveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>>
export type ReputationLeaderboardRetriveInfiniteQueryError = unknown

export const useReputationLeaderboardRetriveInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, ReputationLeaderboardRetriveParams['offset']>, TError = unknown>(
 params?: MaybeRef<ReputationLeaderboardRetriveParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, TError, TData, Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, QueryKey, ReputationLeaderboardRetriveParams['offset']>>, request?: SecondParameter<typeof customInstance>}

  ): UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReputationLeaderboardRetriveInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getReputationLeaderboardRetriveQueryOptions = <TData = Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, TError = unknown>(params?: MaybeRef<ReputationLeaderboardRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getReputationLeaderboardRetriveQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>> = ({ signal }) => reputationLeaderboardRetrive(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, TError, TData> 
}

export type ReputationLeaderboardRetriveQueryResult = NonNullable<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>>
export type ReputationLeaderboardRetriveQueryError = unknown

export const useReputationLeaderboardRetrive = <TData = Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, TError = unknown>(
 params?: MaybeRef<ReputationLeaderboardRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof reputationLeaderboardRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReputationLeaderboardRetriveQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const claimReputationMessageRetrive = (
    walletAddress: MaybeRef<string>,
    parameterName: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
parameterName = unref(parameterName);
      
      return customInstance<string>(
      {url: `/api/v1/reputation_scoring/reputation_score/message_to_sign/${encodeURIComponent(String(walletAddress))}/${encodeURIComponent(String(parameterName))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getClaimReputationMessageRetriveQueryKey = (walletAddress: MaybeRef<string>,
    parameterName: MaybeRef<string>,) => {
    return ['api','v1','reputation_scoring','reputation_score','message_to_sign',walletAddress,parameterName] as const;
    }

    
export const getClaimReputationMessageRetriveQueryOptions = <TData = Awaited<ReturnType<typeof claimReputationMessageRetrive>>, TError = unknown>(walletAddress: MaybeRef<string>,
    parameterName: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof claimReputationMessageRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getClaimReputationMessageRetriveQueryKey(walletAddress,parameterName);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof claimReputationMessageRetrive>>> = ({ signal }) => claimReputationMessageRetrive(walletAddress,parameterName, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress) && unref(parameterName))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof claimReputationMessageRetrive>>, TError, TData> 
}

export type ClaimReputationMessageRetriveQueryResult = NonNullable<Awaited<ReturnType<typeof claimReputationMessageRetrive>>>
export type ClaimReputationMessageRetriveQueryError = unknown

export const useClaimReputationMessageRetrive = <TData = Awaited<ReturnType<typeof claimReputationMessageRetrive>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    parameterName: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof claimReputationMessageRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClaimReputationMessageRetriveQueryOptions(walletAddress,parameterName,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const userPotentialReputationPerLoanRepayRetrive = (
    walletAddress: MaybeRef<string>,
    params: MaybeRef<UserPotentialReputationPerLoanRepayRetriveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
params = unref(params);
      
      return customInstance<UserPotentialReputationPerLoanRepayResponseBackendSchema>(
      {url: `/api/v1/reputation_scoring/reputation_score/potential-rep-per-loan-repay/${encodeURIComponent(String(walletAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getUserPotentialReputationPerLoanRepayRetriveQueryKey = (walletAddress: MaybeRef<string>,
    params: MaybeRef<UserPotentialReputationPerLoanRepayRetriveParams>,) => {
    return ['api','v1','reputation_scoring','reputation_score','potential-rep-per-loan-repay',walletAddress, ...(params ? [params]: [])] as const;
    }

    
export const getUserPotentialReputationPerLoanRepayRetriveQueryOptions = <TData = Awaited<ReturnType<typeof userPotentialReputationPerLoanRepayRetrive>>, TError = unknown>(walletAddress: MaybeRef<string>,
    params: MaybeRef<UserPotentialReputationPerLoanRepayRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPotentialReputationPerLoanRepayRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getUserPotentialReputationPerLoanRepayRetriveQueryKey(walletAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userPotentialReputationPerLoanRepayRetrive>>> = ({ signal }) => userPotentialReputationPerLoanRepayRetrive(walletAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userPotentialReputationPerLoanRepayRetrive>>, TError, TData> 
}

export type UserPotentialReputationPerLoanRepayRetriveQueryResult = NonNullable<Awaited<ReturnType<typeof userPotentialReputationPerLoanRepayRetrive>>>
export type UserPotentialReputationPerLoanRepayRetriveQueryError = unknown

export const useUserPotentialReputationPerLoanRepayRetrive = <TData = Awaited<ReturnType<typeof userPotentialReputationPerLoanRepayRetrive>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    params: MaybeRef<UserPotentialReputationPerLoanRepayRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPotentialReputationPerLoanRepayRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserPotentialReputationPerLoanRepayRetriveQueryOptions(walletAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const userPotentialReputationPerProposalFundRetrive = (
    walletAddress: MaybeRef<string>,
    params: MaybeRef<UserPotentialReputationPerProposalFundRetriveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
params = unref(params);
      
      return customInstance<UserPotentialReputationPerProposalFundResponseBackendSchema>(
      {url: `/api/v1/reputation_scoring/reputation_score/potential-rep-per-proposal-fund/${encodeURIComponent(String(walletAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getUserPotentialReputationPerProposalFundRetriveQueryKey = (walletAddress: MaybeRef<string>,
    params: MaybeRef<UserPotentialReputationPerProposalFundRetriveParams>,) => {
    return ['api','v1','reputation_scoring','reputation_score','potential-rep-per-proposal-fund',walletAddress, ...(params ? [params]: [])] as const;
    }

    
export const getUserPotentialReputationPerProposalFundRetriveQueryOptions = <TData = Awaited<ReturnType<typeof userPotentialReputationPerProposalFundRetrive>>, TError = unknown>(walletAddress: MaybeRef<string>,
    params: MaybeRef<UserPotentialReputationPerProposalFundRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPotentialReputationPerProposalFundRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getUserPotentialReputationPerProposalFundRetriveQueryKey(walletAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userPotentialReputationPerProposalFundRetrive>>> = ({ signal }) => userPotentialReputationPerProposalFundRetrive(walletAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userPotentialReputationPerProposalFundRetrive>>, TError, TData> 
}

export type UserPotentialReputationPerProposalFundRetriveQueryResult = NonNullable<Awaited<ReturnType<typeof userPotentialReputationPerProposalFundRetrive>>>
export type UserPotentialReputationPerProposalFundRetriveQueryError = unknown

export const useUserPotentialReputationPerProposalFundRetrive = <TData = Awaited<ReturnType<typeof userPotentialReputationPerProposalFundRetrive>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    params: MaybeRef<UserPotentialReputationPerProposalFundRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPotentialReputationPerProposalFundRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserPotentialReputationPerProposalFundRetriveQueryOptions(walletAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const refreshUserReputationScore = (
    walletAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
      
      return customInstance<void>(
      {url: `/api/v1/reputation_scoring/reputation_score/refresh/${encodeURIComponent(String(walletAddress))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getRefreshUserReputationScoreQueryKey = (walletAddress: MaybeRef<string>,) => {
    return ['api','v1','reputation_scoring','reputation_score','refresh',walletAddress] as const;
    }

    
export const getRefreshUserReputationScoreQueryOptions = <TData = Awaited<ReturnType<typeof refreshUserReputationScore>>, TError = unknown>(walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof refreshUserReputationScore>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getRefreshUserReputationScoreQueryKey(walletAddress);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof refreshUserReputationScore>>> = ({ signal }) => refreshUserReputationScore(walletAddress, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof refreshUserReputationScore>>, TError, TData> 
}

export type RefreshUserReputationScoreQueryResult = NonNullable<Awaited<ReturnType<typeof refreshUserReputationScore>>>
export type RefreshUserReputationScoreQueryError = unknown

export const useRefreshUserReputationScore = <TData = Awaited<ReturnType<typeof refreshUserReputationScore>>, TError = unknown>(
 walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof refreshUserReputationScore>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRefreshUserReputationScoreQueryOptions(walletAddress,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const userReputationScoreRetrive = (
    walletAddress: MaybeRef<string>,
    params?: MaybeRef<UserReputationScoreRetriveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
params = unref(params);
      
      return customInstance<UserReputationResponseBackendSchema>(
      {url: `/api/v1/reputation_scoring/reputation_score/simple/${encodeURIComponent(String(walletAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getUserReputationScoreRetriveQueryKey = (walletAddress: MaybeRef<string>,
    params?: MaybeRef<UserReputationScoreRetriveParams>,) => {
    return ['api','v1','reputation_scoring','reputation_score','simple',walletAddress, ...(params ? [params]: [])] as const;
    }

    
export const getUserReputationScoreRetriveQueryOptions = <TData = Awaited<ReturnType<typeof userReputationScoreRetrive>>, TError = unknown>(walletAddress: MaybeRef<string>,
    params?: MaybeRef<UserReputationScoreRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userReputationScoreRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getUserReputationScoreRetriveQueryKey(walletAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userReputationScoreRetrive>>> = ({ signal }) => userReputationScoreRetrive(walletAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userReputationScoreRetrive>>, TError, TData> 
}

export type UserReputationScoreRetriveQueryResult = NonNullable<Awaited<ReturnType<typeof userReputationScoreRetrive>>>
export type UserReputationScoreRetriveQueryError = unknown

export const useUserReputationScoreRetrive = <TData = Awaited<ReturnType<typeof userReputationScoreRetrive>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    params?: MaybeRef<UserReputationScoreRetriveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userReputationScoreRetrive>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserReputationScoreRetriveQueryOptions(walletAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const claimReputationSubmitMessage = (
    walletAddress: MaybeRef<string>,
    chainId: MaybeRef<number>,
    parameterName: MaybeRef<string>,
    signature: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
chainId = unref(chainId);
parameterName = unref(parameterName);
signature = unref(signature);
      
      return customInstance<string>(
      {url: `/api/v1/reputation_scoring/reputation_score/submit_message/${encodeURIComponent(String(walletAddress))}/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(parameterName))}/${encodeURIComponent(String(signature))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getClaimReputationSubmitMessageQueryKey = (walletAddress: MaybeRef<string>,
    chainId: MaybeRef<number>,
    parameterName: MaybeRef<string>,
    signature: MaybeRef<string>,) => {
    return ['api','v1','reputation_scoring','reputation_score','submit_message',walletAddress,chainId,parameterName,signature] as const;
    }

    
export const getClaimReputationSubmitMessageQueryOptions = <TData = Awaited<ReturnType<typeof claimReputationSubmitMessage>>, TError = unknown>(walletAddress: MaybeRef<string>,
    chainId: MaybeRef<number>,
    parameterName: MaybeRef<string>,
    signature: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof claimReputationSubmitMessage>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getClaimReputationSubmitMessageQueryKey(walletAddress,chainId,parameterName,signature);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof claimReputationSubmitMessage>>> = ({ signal }) => claimReputationSubmitMessage(walletAddress,chainId,parameterName,signature, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress) && unref(chainId) && unref(parameterName) && unref(signature))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof claimReputationSubmitMessage>>, TError, TData> 
}

export type ClaimReputationSubmitMessageQueryResult = NonNullable<Awaited<ReturnType<typeof claimReputationSubmitMessage>>>
export type ClaimReputationSubmitMessageQueryError = unknown

export const useClaimReputationSubmitMessage = <TData = Awaited<ReturnType<typeof claimReputationSubmitMessage>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    chainId: MaybeRef<number>,
    parameterName: MaybeRef<string>,
    signature: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof claimReputationSubmitMessage>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getClaimReputationSubmitMessageQueryOptions(walletAddress,chainId,parameterName,signature,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



