<template>
  <div
    class="notifications"
    @click.stop>
    <BaseSkeletor v-if="isFetchingNotificationsForWalletMenu"/>
    <div
      v-for="(notification, index) in notificationsToDisplay"
      :key="index"
      class="notifications__item">
      <NotificationInMenu
        v-if="notification"
        :notification="notification"
        mark-as-seen-on-hover
        :style="{ backgroundImage: 'unset', boxShadow: 'unset' }"/>
    </div>
    <br>
    <BaseSkeletor v-if="isFetchingNotifications"/>
    <div class="notifications__spacer"/>
    <div class="notifications__semi-transparent-curtain"/>

    <div
      v-if="hasViewAllNotifications"
      class="notifications__view-more"
      @click="handleViewMoreNotifications">
      {{ viewMoreNotifications ? 'View less' : 'View all' }}
    </div>

    <div
      v-if="amountOfUnseenNotifications > 0"
      class="notifications__mark-all-as-seen"
      @click="markAllUnseenNotificationsAsSeen">
      Mark all as seen <MarkAsSeenIconSvg
        width="14"
        height="14"
        class="notifications__mark-all-as-seen-icon"
        alt="Mark all as seen"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import NotificationInMenu from '@/general-components/notification/NotificationInMenu.vue'
import MarkAsSeenIconSvg from '@/assets/icons/mark-as-seen.svg'
import useNotifications from '@/modules/common/notifications/useNotifications'
import useNotificationsFetch from '@/modules/common/notifications/useNotificationsFetch'
import { computed, ref } from 'vue'

const { notificationsInMenu, markAllUnseenNotificationsAsSeen, notifications, amountOfUnseenNotifications } = useNotifications()
const { isFetchingNotificationsForWalletMenu, hasViewAllNotifications } = useNotificationsFetch()
const viewMoreNotifications = ref(false)
const { isFetchingNotifications, loadAllUserNotifications } = useNotificationsFetch()

const notificationsToDisplay = computed(() => {
  return viewMoreNotifications.value ? notifications.value : notificationsInMenu.value
})

const handleViewMoreNotifications = async() => {
  await loadAllUserNotifications()
  viewMoreNotifications.value = !viewMoreNotifications.value
}

</script>

<style scoped>
.notifications {
  border: var(--header-bell-menu-list-border);
  background: var(--background-color);
  overflow: hidden auto;
  max-height: calc(100vh - var(--menu-height));
  margin-right: -1rem;
  padding-right: 1rem;

  &__item:not(:last-child) {
    position: relative;
    margin-bottom: -1px;

    &:hover {
      filter: contrast(0.9);
    }
  }

  &__spacer {
    height: 10rem;
  }

  &__semi-transparent-curtain {
    position: absolute;
    width: calc(100% - 2rem);
    height: 8rem;
    bottom: 3.1rem;
    background: linear-gradient(0deg, var(--background-color) 0%, var(--background-color) 20%, transparent 100%);
  }

  &__mark-all-as-seen {
    right: 1rem;
  }

  &__view-more {
    left: 1rem;
  }

  &__view-more,
  &__mark-all-as-seen {
    font-family: var(--font-family-oxygen-mono);
    font-size: 0.875rem;
    position: absolute;
    display: flex;
    gap: 0.2rem;
    text-decoration: underline;

    --primary-color-1: var(--text-color);
    bottom: 3.9rem;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    &-icon {
      vertical-align: middle;
    }
  }
}
</style>
