import DateUtils from '@/utils/DateUtils'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { UnixTimestamp } from '@/modules/common/typings/customTypes'
import { zeroAddress } from 'viem'
import type { Address, Hash, Hex } from 'viem'
import V1_2SimpleLoanSimpleProposalContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanSimpleProposalContract'
import V1_2SimpleLoanDutchAuctionProposalContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanDutchAuctionProposalContract'
import V1_2SimpleLoanContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanContract'
import V1_2RevokedNonceContract from '@/modules/common/pwn/contracts/v1.2/V1_2RevokedNonceContract'
import { DEFAULT_LOAN_DURATION_IN_DAYS } from '@/constants/loans'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { SupportedChain } from '@/constants/chains/types'
import V1_2SimpleLoanListProposalContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanListProposalContract'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'
import { compareAddresses } from '@/utils/utils'
import V1_2SimpleLoanFungibleProposalContract
  from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanFungibleProposalContract'
import { compoundCometBalanceOf } from '@/contracts/abis'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

export enum V1_2ProposalType {
  SIMPLE_LOAN_SIMPLE_PROPOSAL = 'pwn_contracts.v1_2simpleloansimpleproposal',
  SIMPLE_LOAN_LIST_PROPOSAL = 'pwn_contracts.v1_2simpleloanlistproposal',
  SIMPLE_LOAN_FUNGIBLE_PROPOSAL = 'pwn_contracts.v1_2simpleloanfungibleproposal',
  SIMPLE_LOAN_DUTCH_AUCTION_PROPOSAL = 'pwn_contracts.v1_2simpleloandutchauctionproposal',
}

export enum V1_1ProposalType {
  SIMPLE_LOAN_SIMPLE_PROPOSAL = 'pwn_contracts.v1_1simpleloansimpleproposal',
  SIMPLE_LOAN_LIST_PROPOSAL = 'pwn_contracts.v1_1simpleloanlistproposal',
}

export const BETA_PROPOSAL_TYPE = 'pwn_contracts.betaproposal' as const

export const ALL_V1_2_PROPOSAL_TYPES: Array<`${V1_2ProposalType}`> = Object.values(V1_2ProposalType)
export const ALL_V1_1_PROPOSAL_TYPES: Array<`${V1_1ProposalType}`> = Object.values(V1_1ProposalType)

export const ALL_PROPOSAL_TYPES = [
  BETA_PROPOSAL_TYPE,
  ...ALL_V1_1_PROPOSAL_TYPES,
  ...ALL_V1_2_PROPOSAL_TYPES,
] as const

export type ProposalType = typeof ALL_PROPOSAL_TYPES[number]

export abstract class BaseProposal {
  public abstract acceptProposal(acceptor: Address, step? : ToastStep, nonceToRevoke?: bigint): Promise<any>

  public loanContract: V1_2SimpleLoanContract
  public nonceContract: V1_2RevokedNonceContract
  public proposalContract: V1_2SimpleLoanDutchAuctionProposalContract | V1_2SimpleLoanSimpleProposalContract | V1_2SimpleLoanListProposalContract | V1_2SimpleLoanFungibleProposalContract
  loanContractAddress: Address

  id: string
  proposalType: V1_2ProposalType
  isOnChain?: boolean
  revokedAt?: Date
  createdAt?: Date
  status?: number
  type?: ProposalType

  // contract values
  proposalContractAddress: Address
  collateral: AssetWithAmount
  creditAsset: AssetWithAmount
  chainId: SupportedChain
  isOffer: boolean
  proposer: Address
  acceptor?: Address
  nonceSpace: bigint
  nonce: bigint
  proposalHash?: Hex // rename to hash?
  proposalSignature?: string // rename to signature?
  fixedInterestAmount: bigint
  loanDurationDays: number
  accruingInterestAPR: number // in %
  expiration: UnixTimestamp
  proposerSpecHash: string
  collateralAmount: bigint
  creditAmount: bigint
  sourceOfFunds?: Address

  // not used so far
  refinancingLoanId: bigint
  checkCollateralStateFingerprint: boolean
  collateralStateFingerprint: string
  availableCreditLimit: bigint // revoke nonce if credit limit is 0, proposal can be accepted only once
  allowedAcceptor?: Address // if is zero address anybody can accept loan
  multiproposalMerleRoot?: Hash
  repaymentAmount?: string

  constructor(proposal: BaseProposal) {
    this.loanContract = new V1_2SimpleLoanContract()
    this.nonceContract = new V1_2RevokedNonceContract()
    this.proposalContract = proposal.proposalContract
    this.loanContractAddress = proposal.loanContractAddress

    this.id = proposal.id
    this.proposalType = proposal.proposalType
    this.isOnChain = proposal?.isOnChain
    this.revokedAt = proposal?.revokedAt
    this.createdAt = proposal?.createdAt
    this.status = proposal?.status
    this.type = proposal?.type

    this.collateralAmount = proposal.collateralAmount
    this.creditAmount = proposal.creditAmount
    this.proposalContractAddress = proposal.proposalContractAddress
    this.isOffer = proposal.isOffer
    this.chainId = proposal.chainId
    this.proposer = proposal.proposer
    this.acceptor = proposal.acceptor || zeroAddress
    this.collateral = proposal.collateral
    this.creditAsset = proposal.creditAsset
    this.nonceSpace = proposal?.nonceSpace
    this.nonce = proposal?.nonce
    this.proposalHash = proposal?.proposalHash
    this.proposalSignature = proposal?.proposalSignature
    this.fixedInterestAmount = proposal?.fixedInterestAmount
    this.loanDurationDays = proposal?.loanDurationDays ?? DEFAULT_LOAN_DURATION_IN_DAYS
    this.accruingInterestAPR = proposal?.accruingInterestAPR
    this.expiration = proposal?.expiration
    this.proposerSpecHash = proposal?.proposerSpecHash
    this.sourceOfFunds = proposal?.sourceOfFunds

    // not used so far
    this.refinancingLoanId = proposal?.refinancingLoanId
    this.checkCollateralStateFingerprint = proposal?.checkCollateralStateFingerprint
    this.collateralStateFingerprint = proposal?.collateralStateFingerprint
    this.availableCreditLimit = proposal?.availableCreditLimit
    this.allowedAcceptor = proposal?.allowedAcceptor
    this.multiproposalMerleRoot = proposal?.multiproposalMerleRoot
    this.repaymentAmount = proposal?.repaymentAmount
  }

  get uniqueIdentifier(): string {
    return `${this.collateral.uniqueIdentifier}_${this.creditAsset.uniqueIdentifier}_${this.loanDurationDays}_${this.accruingInterestAPR}_${this.expiration}`
  }

  get loanDurationSeconds(): number {
    if (!this.loanDurationDays) return 0
    return this.loanDurationDays * 24 * 60 * 60
  }

  get isV1_1Proposal(): boolean {
    if (isStarknet) return false
    return compareAddresses(this.loanContractAddress, CHAINS_CONSTANTS[this.chainId]?.pwnV1Contracts?.pwnSimpleLoan)
  }

  get isV1_1CollectionOffer(): boolean {
    return this.type === V1_1ProposalType.SIMPLE_LOAN_LIST_PROPOSAL
  }

  get isV1_1SimpleOffer(): boolean {
    return this.type === V1_1ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL
  }

  get isFungibleProposal(): boolean {
    return this.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL
  }

  public async approveBorrower(loanAmountRaw: bigint, step?: ToastStep): Promise<boolean> {
    if (!this.collateral || !this.creditAsset) return false
    if (this.isOffer) {
      return await this.proposalContract.approveForAcceptProposal({
        assetToApprove: this.collateral,
        loanAmountRaw,
        step,
      })
    } else {
      return await this.proposalContract.approveForMakeProposal({
        assetToApprove: this.collateral,
        loanAmountRaw,
        step,
      })
    }
  }

  public async approveLender(loanAmountRaw: bigint, step: ToastStep): Promise<boolean> {
    if (!this.creditAsset) return false

    if (this.isOffer) {
      return await this.proposalContract.approveForMakeProposal({
        assetToApprove: this.creditAsset,
        loanAmountRaw,
        step,
      })
    } else {
      return await this.proposalContract.approveForAcceptProposal({
        assetToApprove: this.creditAsset,
        loanAmountRaw,
        step,
      })
    }
  }

  public async revokeNonce(nonce?: bigint): Promise<Address> {
    const _nonce = nonce ?? this.nonce
    if (_nonce === undefined) throw new Error('_nonce is not defined')

    const nonceContractAddress = CHAINS_CONSTANTS[this.chainId]?.pwnV1_2Contracts?.pwnRevokedNonce
    if (!nonceContractAddress) throw new Error('pwnRevokedNonce contract address is not defined')

    return await this.nonceContract.revokeNonce(nonceContractAddress, this.chainId, _nonce)
  }

  public isNonceUsable(nonceSpace: bigint, nonce: bigint): Promise<boolean> {
    const nonceContractAddress = CHAINS_CONSTANTS[this.chainId]?.pwnV1_2Contracts?.pwnRevokedNonce
    if (!nonceContractAddress) throw new Error('pwnRevokedNonce contract address is not defined')
    return this.nonceContract.isNonceUsable({
      nonceContractAddress,
      chainId: this.chainId,
      owner: this.proposer,
      nonceSpace,
      nonce,
    })
  }

  public async currentNonceSpace(): Promise<bigint> {
    const nonceContractAddress = CHAINS_CONSTANTS[this.chainId]?.pwnV1_2Contracts?.pwnRevokedNonce
    if (!nonceContractAddress) throw new Error('pwnRevokedNonce contract address is not defined')
    return await this.nonceContract.currentNonceSpace(
      nonceContractAddress,
      this.chainId,
      this.proposer,
    )
  }
}

export class V1_2SimpleLoanSimpleProposal extends BaseProposal {
  proposalContract: V1_2SimpleLoanSimpleProposalContract

  constructor(proposal: V1_2SimpleLoanSimpleProposal) {
    super(proposal)
    this.proposalContract = new V1_2SimpleLoanSimpleProposalContract()
    this.proposalType = V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL
  }

  public async acceptProposal(acceptor: Address, step?: ToastStep, nonceToRevoke?: bigint): Promise<any> {
    // check balance of compound asset in case the proposer would go into debt
    if (this.sourceOfFunds && CHAINS_CONSTANTS[String(this.chainId)].compound.pools.includes(this.sourceOfFunds)) {
      const balanceOf = await compoundCometBalanceOf(pwnWagmiConfig, {
        address: this.sourceOfFunds,
        chainId: this.chainId,
        args: [this.proposer],
      }) as bigint
      if (balanceOf < this.creditAmount) {
        throw new Error('Proposer has insufficient balance')
      }
    }

    return this.proposalContract.acceptProposal({
      proposalClass: this,
      acceptor,
      encodedProposalData: await this.proposalContract.encodeProposalData(this),
      nonceToRevoke,
      step,
    })
  }
}

export class V1_2SimpleLoanSimpleListProposal extends BaseProposal {
  proposalContract: V1_2SimpleLoanListProposalContract

  constructor(proposal: V1_2SimpleLoanSimpleProposal) {
    super(proposal)
    this.proposalContract = new V1_2SimpleLoanListProposalContract()
    this.proposalType = V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL
  }

  public async acceptProposal(acceptor: Address, step?: ToastStep, nonceToRevoke?: bigint): Promise<any> {
    return this.proposalContract.acceptProposal({
      proposalClass: this,
      acceptor,
      encodedProposalData: await this.proposalContract.encodeProposalData(this),
      nonceToRevoke,
      step,
    })
  }
}

export class V1_2SimpleLoanDutchAuctionProposal extends BaseProposal {
  proposalContract: V1_2SimpleLoanDutchAuctionProposalContract

  minCreditAmount: bigint
  maxCreditAmount: bigint
  auctionStart: UnixTimestamp
  auctionDuration: number
  slippage?: bigint

  constructor(proposal: V1_2SimpleLoanDutchAuctionProposal) {
    super(proposal)
    this.proposalContract = new V1_2SimpleLoanDutchAuctionProposalContract()
    this.proposalContractAddress = CHAINS_CONSTANTS[this.chainId]?.pwnV1_2Contracts?.pwnSimpleLoanDutchAuctionProposal || '0x'

    this.proposalType = V1_2ProposalType.SIMPLE_LOAN_DUTCH_AUCTION_PROPOSAL
    this.minCreditAmount = proposal.minCreditAmount
    this.maxCreditAmount = proposal.maxCreditAmount
    this.auctionStart = proposal.auctionStart // UnixTimestamp in seconds
    this.auctionDuration = proposal.auctionDuration
    this.slippage = proposal.slippage
  }

  get isDutchAuction(): boolean {
    return true
  }

  // Dynamic value depending on time of accepting dutch auction. Amount of tokens which acceptor intends to borrow.
  public async intendedCreditAmount(): Promise<bigint> {
    return await this.proposalContract.getCreditAmount(this, await DateUtils.getCurrentBlockTimestamp())
  }

  public async defaultSlippage(): Promise<bigint> {
    const currentBlockTimestamp = await DateUtils.getCurrentBlockTimestamp()
    const tenMinutesInSeconds = 10 * 60
    const creditAmount = await this.proposalContract.getCreditAmount(this, currentBlockTimestamp)
    const creditAmountPlus10Min = await this.proposalContract.getCreditAmount(this, currentBlockTimestamp + tenMinutesInSeconds)
    const slippage = this.isOffer
      ? creditAmountPlus10Min - creditAmount
      : creditAmount - creditAmountPlus10Min

    return slippage
  }

  public async acceptProposal(acceptor: Address, step?: ToastStep, nonceToRevoke?: bigint): Promise<any> {
    return this.proposalContract.acceptProposal({
      proposalClass: this,
      acceptor,
      encodedProposalData: await this.proposalContract.encodeProposalData(this),
      nonceToRevoke,
      step,
    })
  }
}

export class V1_2SimpleLoanFungibleProposal extends BaseProposal {
  proposalContract: V1_2SimpleLoanFungibleProposalContract
  minCollateralAmount: bigint
  creditPerCollateralUnit: bigint
  collateralAmountToAccept?: bigint
  creditAmountToAccept?: bigint
  fullRemainingCreditAmount?: bigint

  constructor(proposal: V1_2SimpleLoanFungibleProposal) {
    super(proposal)
    this.proposalContract = new V1_2SimpleLoanFungibleProposalContract()
    this.proposalType = V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL
    this.minCollateralAmount = proposal.minCollateralAmount
    this.creditPerCollateralUnit = proposal.creditPerCollateralUnit
    this.collateralAmountToAccept = proposal?.collateralAmountToAccept
    this.creditAmountToAccept = proposal?.creditAmountToAccept
    this.fullRemainingCreditAmount = proposal?.fullRemainingCreditAmount
  }

  public async acceptProposal(acceptor: Address, step?: ToastStep, nonceToRevoke?: bigint): Promise<any> {
    return this.proposalContract.acceptProposal({
      proposalClass: this,
      acceptor,
      encodedProposalData: await this.proposalContract.encodeProposalData(this),
      nonceToRevoke,
      step,
    })
  }
}

// ////////////////////////////////// //
// // PWNSimpleLoanSimpleProposal // ///
// ///////////////////////////////// //
//

// ////////////////////////////////// //
// // PWNSimpleLoanListProposal // ///
// ///////////////////////////////// //
// bytes32 collateralIdsWhitelistMerkleRoot;
//

// ////////////////////////////////// //
// // PWNSimpleLoanDutchAuctionProposal //
// ///////////////////////////////// //
// intendedCreditAmount?: bigint
// slippage?: bigint
// uint256 minCreditAmount;
// uint256 maxCreditAmount;
// uint40 auctionStart;
// uint40 auctionDuration;
//

// ////////////////////////////////// //
// // PWNSimpleLoanFungibleProposal //
// ///////////////////////////////// //
// uint256 minCollateralAmount;
// uint256 creditPerCollateralUnit;
//
