import type { ColumnProperties } from '@/general-components/ColumnProperties'

export const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Price',
    width: '27%',
    slotName: 'price',
  },
  {
    title: 'Floor Difference',
    width: '28%',
    slotName: 'floorDifference',
    headSlotName: 'floorDifferenceHead',
  },
  {
    title: 'Expiration',
    width: '25%',
    slotName: 'expiration',
  },
  {
    title: 'From',
    width: '20%',
    slotName: 'from',
  },
]
