<template>
  <form
    class="navigate-by-token-id__form"
    @submit.prevent="store.actions.openAssetPage">
    <BaseSearch
      id="navigate-by-token-id"
      :class="['navigate-by-token-id', {
        'navigate-by-token-id--focused': isFocused,
      }]"
      :only-digits="true"
      search-placeholder="Token ID"
      :model-value="String(userInput)"
      :set-focus="store.actions.setFocused"
      @update:model-value="store.actions.setUserInput($event)">
      <template #actions="{ clearSearch }">
        <div class="navigate-by-token-id__actions">
          <Transition
            type="transition"
            name="fade"
            mode="out-in">
            <BaseButton
              v-if="String(userInput).length >= 1 && inputIsValid"
              is-type-submit
              class="navigate-by-token-id__action-button"
              :font="ButtonFont.OxygenMono"
              :size="ButtonSize.M"
              button-text="Enter"/>
          </Transition>

          <img
            class="navigate-by-token-id__close"
            src="../../../assets/icons/close-gray.svg?url"
            alt="Search icon"
            @click="clearSearch">
        </div>
      </template>
    </BaseSearch>
    <label
      for="navigate-by-token-id"
      :class="['navigate-by-token-id__error', {
        'navigate-by-token-id__error--displayed': inputIsValid === false,
      }]">
      Please enter a valid token ID
    </label>
  </form>
</template>

<script lang="ts" setup>
import { useCollectionToAssetNavigator } from '@/revamp/pages/collection/useCollectionToAssetNavigator'
import BaseButton, { ButtonFont, ButtonSize } from '@/general-components/BaseButton.vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import { storeToRefs } from 'pinia'

const store = useCollectionToAssetNavigator()
const { userInput, isFocused, inputIsValid } = storeToRefs(store)

</script>

<style scoped>
.navigate-by-token-id {
  padding: 0.5rem;
  border: 1px solid var(--gray);

  transition: border-color 0.2s ease-in-out;

  &__form {
    display: flex;
    flex-flow: column nowrap;
  }

  &--focused {
    border-color: var(--text-color);
  }

  &__action-button {
    height: 1.5rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
  }

  &__close {
    margin-left: 0.5rem;
    cursor: pointer;
  }

  &:focus {
    border: 1px solid red;
  }

  &__error {
    color: var(--negative-1);
    font-size: 0.875rem;

    margin-top: 0.5rem;

    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &--displayed {
      opacity: 1;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.3s ease-in;
  transform: translateX(0);
  will-change: transform;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(-50px);
}
</style>

<style>
@media only screen and (--mobile-viewport) {
  .search__input {
    width: 6.25rem;
  }
}
</style>
