<template>
  <BaseSection
    class="pwn-safe-atr-table__section"
    :is-expandable="false"
    :heading="heading"
    :secondary-heading="secondaryHeadingAssets">
    <template #controls>
      <TokenTypeFilter
        @on-switch-display-nfts="handleOnSwitchDisplayNfts"
        @on-switch-display-coins="handleOnSwitchDisplayCoins"/>
    </template>
    <BaseTable
      v-model:active-sort-option="activeSortOptionLocal"
      class="pwn-safe-atr-table__table"
      table-max-height="450px"
      :table-min-width="tableMinWidth"
      :is-hoverable="isRowSelectable"
      :variant="TableVariant.AssetPage"
      :items="sortedAtrTokens"
      :columns="tableDefinition"
      :is-fetching="isFetchingAtrTokens"
      @on-row-click="emitOnAssetSelect">
      <template #checkbox="{ item: token }">
        <BaseCheckbox
          v-if="isAssetSelected !== undefined"
          :is-disabled="!token.isValid"
          :model-value="isAssetSelected(token)"
          @click.prevent/>
      </template>

      <template #asset-info="{ item: token }">
        <AssetInfo
          :asset="token"
          :show-link="!isRowSelectable"/>
      </template>

      <template #holding-rights="{ item: token }">
        <AssetInfo
          class="pwn-safe-atr-table__asset-info"
          :asset="token.assetOfAtrToken"
          show-amount
          :show-link="!isRowSelectable"
          :show-unpack-tooltip="false"/>
      </template>

      <template #action-atr="{ item: token }">
        <div class="pwn-safe-atr-table__asset-action-container">
          <div
            class="pwn-safe-atr-table__asset-action"
            @click="emit('handle-transfer', token)">
            <TransferIconSvg
              class="pwn-safe-atr-table__asset-action-icon"
              height="25"
              alt="transfer"/>
            <SafeText
              :allow-click-through="false"
              text="Transfer"/>
          </div>
          <div
            class="pwn-safe-atr-table__asset-action"
            @click="emit('handle-claim-and-burn', token)">
            <ClaimIconSvg
              class="pwn-safe-atr-table__asset-action-icon"
              width="25"
              height="25"
              alt="claim & burn"/>
            <SafeText
              :allow-click-through="false"
              text="Claim & Burn"/>
          </div>
        </div>
      </template>
    </BaseTable>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import BaseTable from '@/general-components/BaseTable.vue'
import AssetInfo from '@/general-components/AssetInfo.vue'
import SafeText from '@/general-components/SafeText.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, toRefs } from 'vue'
import TableVariant from '@/general-components/TableVariant'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import TokenTypeFilter from '@/general-components/TokenTypeFilter.vue'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import ClaimIconSvg from '@/assets/icons/claim.svg'
import TransferIconSvg from '@/assets/icons/transfer.svg'
import { isMobile } from '@/utils/mediaQueries'

interface Props {
  heading: string
  sortedAtrTokens: AssetWithAmount[]
  activeSortOption?: ActiveSortOption
  isFetchingAtrTokens?: boolean
  tableDefinition: ColumnProperties[]
  isAssetSelected?: (asset: AssetWithAmount) => boolean
  displayNfts?: boolean
  displayCoins?: boolean
  isRowSelectable: boolean
}

const props = defineProps<Props>()
const { heading, sortedAtrTokens, activeSortOption, isFetchingAtrTokens, tableDefinition, isRowSelectable } = toRefs(props)
const tableMinWidth = computed(() => (isMobile.value ? '700px' : '0px'))
// eslint-disable-next-line
const emit = defineEmits<{
  (e: 'handle-transfer', asset: AssetWithAmount): void;
  (e: 'handle-claim-and-burn', asset: AssetWithAmount): void;
  (e: 'on-atr-select', asset: AssetWithAmount): void;
  (e: 'on-switch-display-nfts', value: boolean): void;
  (e: 'on-switch-display-coins', value: boolean): void;
  (e: 'update:activeSortOption', newActiveSortOption: ActiveSortOption | undefined): void;
}>()

const activeSortOptionLocal = computed({
  get: () => activeSortOption.value,
  set: (value: ActiveSortOption | undefined) => emit('update:activeSortOption', value),
})

const secondaryHeadingAssets = computed(() => `(${sortedAtrTokens.value.length})`)
const emitOnAssetSelect = (asset: AssetWithAmount) => {
  emit('on-atr-select', asset)
}

const handleOnSwitchDisplayNfts = (val: boolean) => {
  emit('on-switch-display-nfts', val)
}
const handleOnSwitchDisplayCoins = (val: boolean) => {
  emit('on-switch-display-coins', val)
}
</script>

<style scoped>
.pwn-safe-atr-table {
  &__section {
    margin-bottom: 4rem;
  }

  &__amount {
    display: flex;
    text-align: right;
    margin-right: 1.25rem;
  }

  &__asset-action-container {
    display: flex;
    gap: 1rem;
  }

  &__asset-action {
    display: flex;
    flex: 1 1 0;
    font-family: var(--font-family-autoscape);
    color: var(--primary-color-1);
    cursor: pointer;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  &__asset-action-icon {
    margin-right: 0.5rem;
  }

  @media only screen and (--mobile-viewport) {
    &__table {
      overflow: auto auto;
    }

    &__asset-info {
      margin-left: 1rem;
    }
  }
}
</style>
