import type { SupportedChain } from '@/constants/chains/types'
import type { RouteLocationRaw } from 'vue-router'

export interface BaseSearchResult {
  key: string
  title: string | undefined
  chainId: SupportedChain | undefined
  image: string | undefined
  isVerified: boolean

  pageRoute(): RouteLocationRaw
}

export enum SearchCategory {
    COLLECTIONS = 'collections',
    NFTS = 'nfts',
    TOKENS = 'tokens',
    WALLETS = 'wallets',
}
