import { computed, ref, watch } from 'vue'
import PwnSafeClaimAndBurnModalConfirmationStep from '@/modules/pages/pwn-safe/claim-and-burn/modal/PwnSafeClaimAndBurnModalConfirmationStep.vue'
import PwnSafeClaimAndBurnModalSingleAssetDetailStep from '@/modules/pages/pwn-safe/claim-and-burn/modal/PwnSafeClaimAndBurnModalSingleAssetDetailStep.vue'
import PwnSafeClaimAndBurnModalWaitingStep from '@/modules/pages/pwn-safe/claim-and-burn/modal/PwnSafeClaimAndBurnModalWaitingStep.vue'
import usePwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'

export enum PwnSafeClaimAndBurnModalStep {
    SingleAssetDetail,
    Waiting,
    Confirmation,
}

const isOpen = ref(false)
const activeStep = ref(PwnSafeClaimAndBurnModalStep.SingleAssetDetail)

const activeStepComponent = computed(() => {
  switch (activeStep.value) {
  case PwnSafeClaimAndBurnModalStep.SingleAssetDetail:
    return PwnSafeClaimAndBurnModalSingleAssetDetailStep
  case PwnSafeClaimAndBurnModalStep.Waiting:
    return PwnSafeClaimAndBurnModalWaitingStep
  case PwnSafeClaimAndBurnModalStep.Confirmation:
    return PwnSafeClaimAndBurnModalConfirmationStep
  default:
    // todo some logging here?
    return null
  }
})

const isWaitingForTxConfirmation = ref(false)

export default function usePwnSafeClaimAndBurnModal() {
  const { atrTokensToClaimAndBurn, isBurnAtrTokens } = usePwnSafeClaimAndBurn()

  const assetsToBeClaimedInfo = computed(() => atrTokensToClaimAndBurn.value.map(({ assetOfAtrToken }) => assetOfAtrToken.primaryInfo).join(', '))
  const atrTokensInfo = computed(() => atrTokensToClaimAndBurn.value.map(atrToken => atrToken.primaryInfo).join(', '))

  const resetModalState = () => {
    activeStep.value = PwnSafeClaimAndBurnModalStep.SingleAssetDetail
    atrTokensToClaimAndBurn.value = []
    isBurnAtrTokens.value = false
  }

  watch(isOpen, (newIsOpen, oldIsOpen) => {
    if (!newIsOpen) {
      resetModalState()
    }
  })

  const closeModal = () => {
    isOpen.value = false
  }

  return {
    isOpen,
    activeStep,
    activeStepComponent,
    closeModal,
    assetsToBeClaimedInfo,
    atrTokensInfo,
    isWaitingForTxConfirmation,
    resetModalState,
  }
}
