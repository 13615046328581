import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import useApprove from '@/modules/common/assets/useApprove'
import type { ChainWithDeprecatedV1Contracts, SupportedChain } from '@/constants/chains/types'
import { maxUint256 } from 'viem'
import type { Address, TransactionReceipt } from 'viem'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export default abstract class BaseLoanContract<TLoan extends BaseLoan = BaseLoan> {
    public abstract getChainIdToPwnVaultAddressMapping(simpleOfferContractAddress: Address): Record<SupportedChain, Address> | Record<ChainWithDeprecatedV1Contracts, Address>;
    public abstract payBack(loan: TLoan, step: ToastStep): Promise<TransactionReceipt>;
    public abstract claimLoan(loan: TLoan, step: ToastStep): Promise<TransactionReceipt>;
    public abstract extendLoanExpirationDate(loan: TLoan, newExpiration: number): Promise<TransactionReceipt>

    public async isApprovedForPayBack({ acceptedOffer, chainId }: TLoan, walletAddress: Address): Promise<boolean> {
      return await useApprove().isApproved(chainId, acceptedOffer.loanAsset.category, acceptedOffer.loanAssetAddress, walletAddress, this.getChainIdToPwnVaultAddressMapping(acceptedOffer.contractAddress)[chainId], acceptedOffer.repaymentAmountRaw)
    }

    public async approveForPayback({ collateral, acceptedOffer, chainId, contractAddress }: TLoan, step: ToastStep): Promise<boolean> {
      const isApproved = await useApprove().approve({
        asset: acceptedOffer.loanAsset,
        spender: this.getChainIdToPwnVaultAddressMapping(acceptedOffer.contractAddress)[chainId],
        amount: maxUint256,
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ asset: AssetWithAmount; spende... Remove this comment to see the full error message
        allowanceAmountToCheck: acceptedOffer.repaymentAmountRaw,
      }, step)
      // todo POST request to create new notification on backend?

      return !!isApproved
    }
}
