import { formatAmountWithDecimals, getRawAmount, parseToNumber } from '@/utils/utils'

export const calculateInterest = (loanAmount: string | undefined, toBePaidAmount: string | undefined): number | '' => {
  const INVALID_INTEREST = ''

  try {
    // @ts-expect-error FIXME: strictNullChecks/
    const loanAmountNumber = parseToNumber(loanAmount)
    // @ts-expect-error FIXME: strictNullChecks/
    const toBePaidAmountNumber = parseToNumber(toBePaidAmount)
    if (!loanAmountNumber || loanAmountNumber <= 0 || !toBePaidAmountNumber || toBePaidAmountNumber <= 0) {
      return INVALID_INTEREST
    }

    const interest = ((toBePaidAmountNumber - loanAmountNumber) / loanAmountNumber) * 100
    return parseFloat(interest.toFixed(2))
  } catch (error) {
    return INVALID_INTEREST
  }
}

export const calculateRepaymentByInterest = (
  loanAmount: string,
  interest: number,
  decimals?: number,
): string => {
  const loanAmountNumber = parseToNumber(loanAmount)
  if (!loanAmountNumber || loanAmountNumber <= 0) return '0'

  const loanAmountBigInt = getRawAmount(String(loanAmount), decimals)
  const interestDecimalPoints = interest.toString().split('.')[1]?.replace(/0+$/, '').length || 0
  const interestBigInt = getRawAmount(String(interest), interestDecimalPoints)

  let interestAmount = loanAmountBigInt * interestBigInt
  interestAmount = interestAmount / BigInt(`${100}${'0'.repeat(interestDecimalPoints)}`)

  const repaymentBigNumber = loanAmountBigInt + interestAmount

  return formatAmountWithDecimals(repaymentBigNumber, decimals)
}

// Also used for APY calculations, since we do not have compound interest - APY === APR.
export const calculateApr = (loanAmount: string | undefined, toBePaidAmount: string | undefined, duration: number | undefined): number | '' => {
  const INVALID_APR = ''

  try {
    // @ts-expect-error FIXME: strictNullChecks/
    const loanAmountNumber = parseToNumber(loanAmount)
    // @ts-expect-error FIXME: strictNullChecks/
    const toBePaidAmountNumber = parseToNumber(toBePaidAmount)
    if (!loanAmountNumber || loanAmountNumber <= 0 || !toBePaidAmountNumber || toBePaidAmountNumber <= 0) {
      return INVALID_APR
    }

    // @ts-expect-error FIXME: strictNullChecks/
    const apr = (((toBePaidAmountNumber - loanAmountNumber) / loanAmountNumber) / duration) * 365 * 100
    return parseFloat(apr.toFixed(2))
  } catch (error) {
    return INVALID_APR
  }
}

// Also used for APY calculations, since we do not have compound interest - APY === APR.
export const aprToInterest = (duration: number, apr: number): number => {
  const aprDaily = apr / 365
  return aprDaily * duration
}
