<template>
  <BaseModal
    v-model:is-open="isOpen"
    :heading="heading"
    :size="ModalSize.Small">
    <template #trigger>
      <BaseButton
        class="modal-share-dashboard__options-btn"
        :color="ButtonColor.Gray"
        title="Share"
        :variant="ButtonVariant.Outline"
        @click="isOpen = true">
        <template #icon>
          <ShareIconSvg
            width="20"
            height="20"
            alt="share icon"/>
        </template>
      </BaseButton>
    </template>
    <template #subheading>
      <h3 class="modal-share__subheading">
        Share link via
      </h3>
    </template>
    <template #body>
      <div class="modal-share__social-buttons">
        <BaseLink
          class="modal-share__button--twitter"
          :to="twitterLink"
          target="_blank">
          <div class="modal-share__icon-container">
            <TwitterIconSvg
              height="40"
              width="40"/>
          </div>
        </BaseLink>

        <BaseLink
          :to="telegramLink"
          target="_blank">
          <div class="modal-share__icon-container">
            <img
              class="modal-share__icon-social"
              src="@/assets/icons/telegram-colored.svg?url"
              alt="telegram">
          </div>
        </BaseLink>

        <BaseLink
          :to="heyLink"
          target="_blank">
          <div class="modal-share__icon-container">
            <img
              class="modal-share__icon-social"
              src="@/assets/icons/hey-colored.svg?url"
              alt="hey">
          </div>
        </BaseLink>

        <BaseLink
          :to="warpcastLink"
          target="_blank">
          <div class="modal-share__icon-container">
            <WarpcastIconSvg
              height="40"
              width="40"/>
          </div>
        </BaseLink>
      </div>
      <p class="modal-share__copy-label">
        Or copy link
      </p>
      <div class="modal-share__copy-button-and-content">
        <div :class="['modal-share__copy-content', {'modal-share__copy-content--copied': isCopied}]">
          {{ linkToThisPage }}
        </div>
        <BaseButton
          class="modal-share__copy-button"
          :font="ButtonFont.AutoScape"
          is-full-width
          :button-text="buttonText"
          @on-button-click="copy">
          <template #icon>
            <CopyIconSvg
              class="modal-share__icon-copy"
              alt="copy"/>
          </template>
        </BaseButton>
      </div>

      <BaseButton
        class="modal-share__button-done"
        is-full-width
        button-text="Done"
        @on-button-click="isOpen = false"/>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import BaseButton, { ButtonFont, ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import ModalSize from '@/general-components/ModalSize'
import { ref, computed, toRefs } from 'vue'
import BaseLink from '@/general-components/BaseLink.vue'
import CopyIconSvg from '@/assets/icons/copy.svg'
import ShareIconSvg from '@/assets/icons/share.svg'
import TwitterIconSvg from '@/assets/icons/twitter.svg'
import WarpcastIconSvg from '@/assets/icons/warpcaster.svg'

interface Props {
  heading: string
  shareMessage: string
}

const props = defineProps<Props>()
const { heading, shareMessage } = toRefs(props)
const isOpen = ref(false)
const isCopied = ref(false)
const encodedLinkToThisPage = encodeURIComponent(window?.location.href)

const twitterLink = computed(() => `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage.value)}&url=${encodedLinkToThisPage}`)
const heyLink = computed(() => `https://hey.xyz/?text=${encodeURIComponent(shareMessage.value)}&url=${encodedLinkToThisPage}`)
const telegramLink = computed(() => `https://t.me/share/url?url=${encodedLinkToThisPage}&text=${shareMessage.value}`)
const warpcastLink = computed(() => `https://warpcast.com/~/compose?text=${shareMessage.value}%20${encodedLinkToThisPage}`)

const linkToThisPage = computed(() => `${window?.location.host}/${window?.location.hash}`)
const copy = async () => {
  await navigator.clipboard.writeText(linkToThisPage.value)
  isCopied.value = true
  setTimeout(() => {
    isCopied.value = false
  }, 3500)
}
const buttonText = computed(() => isCopied.value ? 'Copied!' : 'Copy')

</script>

<style scoped>
.modal-share {
  &__subheading {
    margin: 1.5rem 0;
    text-align: center;
    font-size: 1.25rem;
    font-weight: normal;
  }

  &__social-buttons {
    display: flex;
    justify-content: center;
    margin: 1.75rem 0;
    gap: 1.5rem;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    height: 4rem;
    border: 1px solid rgb(29 155 240 / 25%);
  }

  &__icon-social {
    width: 2.375rem;
    height: 2.375rem;
  }

  &__icon-copy {
    margin-left: 0.6rem;
  }

  &__copy-label {
    font-size: 1.25rem;
    text-align: left;
    font-weight: normal;
    margin: 3rem 0 0.5rem;
  }

  &__copy-button {
    min-width: 7.5rem;
    height: 2.1875rem;
  }

  &__copy-button-and-content {
    display: flex;
    gap: 0.5rem;
  }

  &__copy-content {
    --content-height: 2.1875rem;
    height: var(--content-height);
    line-height: var(--content-height);
    padding: 0 1.25rem;
    background-color: var(--dark-separator-color);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--copied {
      color: var(--primary-color-1);
    }
  }

  &__button-done {
    margin: 3rem auto 0;
    display: flex;
    width: 18rem;
    justify-content: center;
  }
}
</style>
