import { getAddress } from 'viem'
import type { Address } from 'viem'
import type { WalletBackendSchema, WalletRequestBackendSchema } from '@/modules/common/backend/generated'

export default class WalletProfile {
  public walletAddress: Address
  public ensName?: string | null

  constructor(wallet: WalletProfile) {
    this.walletAddress = wallet.walletAddress
    this.ensName = wallet.ensName
  }

  public static createFromBackendModel(wallet: WalletBackendSchema): WalletProfile {
    return new WalletProfile({
      walletAddress: getAddress(wallet.wallet_address),
      ensName: wallet.ens_name,
    })
  }

  public static createRequestBody(wallet: WalletProfile): WalletRequestBackendSchema {
    return {
      wallet_address: wallet.walletAddress,
      ens_name: wallet.ensName,
    }
  }
}
