<template>
  <BaseSelect
    :model-value="store.selectedCurrency"
    :options="store.currencyOptions"
    :searchable="false"
    :border-type="SelectBorderType.Solid"
    class="currency-switcher"
    :dropdown-caret-type="DropdownCaretType.None"
    :open-direction="openDirection"
    @update:model-value="store.onCurrencySwitch">
    <template #beforeList>
      <div class="currency-switcher__select-currency-label">
        Select Currency
      </div>
    </template>
    <template #option="{ option }">
      <div :class="['currency-switcher__option']">
        <component
          :is="option.icon"
          width="16"
          height="16"
          :alt="option.label"/>
        <span class="currency-switcher__option-text">
          {{ option.label }}
        </span>
      </div>
    </template>

    <template #singleLabel>
      <component
        :is="store.selectedCurrency.icon"
        width="16"
        height="16"
        :alt="store.selectedCurrency.label"/>
    </template>
  </BaseSelect>
</template>

<script setup lang="ts">
import BaseSelect from '@/general-components/BaseSelect.vue'
import { DropdownCaretType, SelectBorderType } from '@/general-components/BaseSelectProperties'
import { useCurrencySwitcherStore } from '@/layout/header/useCurrencySwitcherStore'

const store = useCurrencySwitcherStore()

interface Props {
  openDirection?: 'top' | 'bottom'
}
withDefaults(defineProps<Props>(), {
  openDirection: 'bottom',
})

</script>

<style scoped>
.currency-switcher {
  &__option,
  &__single-label {
    display: flex;
    align-items: center;
  }

  &__option {
    font-size: 0.875rem;
    width: 11.25rem;

    &-text {
      padding-top: 2px;
      margin-left: 0.75rem;
    }
  }

  &__single-label {
    font-size: 1.125rem;
    display: flex;
    justify-content: center;

    &-text {
      padding-top: 4px;
      margin-left: 0.75rem;
    }
  }

  &__select-currency-label {
    font-size: 0.875rem;
    color: var(--text-color-secondary);
    padding: 0.5rem 1rem;
  }
}

</style>
