<template>
  <RevampBaseModal
    v-model:is-open="startLoanModalStore.isOpen"
    custom-max-width="49rem"
    :size="ModalSize.Medium">
    <template #custom-header>
      <header
        v-if="isFungibleProposal"
        class="start-loan-modal__header">
        <h2 class="start-loan-modal__title">
          Loan Preview
        </h2>
        <div class="start-loan-modal__breadcrumbs-and-close">
          <span
            class="start-loan-modal__breadcrumb-select-collateral-amount"
            @click="handleGoBack">{{ parsedProposal?.isOffer ? '1. Select Collateral Amount' : '1. Select Lend Amount' }}</span>
          <span class="start-loan-modal__breadcrumb-loan-preview"> > 2. Loan Preview </span>
          <CloseIconSvg
            alt="close"
            class="start-loan-modal__close"
            @click.stop="startLoanModalStore.close()"/>
        </div>
      </header>
      <header
        v-else
        class="start-loan-modal__header">
        <h2 class="start-loan-modal__title">
          Loan Preview
        </h2>
        <div class="start-loan-modal__breadcrumbs-and-close">
          <CloseIconSvg
            alt="close"
            class="start-loan-modal__close"
            @click.stop="startLoanModalStore.close()"/>
        </div>
      </header>
    </template>
    <template #body>
      <div class="start-loan-modal__collateral-and-proposal-info">
        <CollateralDetail
          v-if="parsedProposal?.collateral"
          class="start-loan-modal__collateral-detail"
          :collateral="{
            chainId: parsedProposal.chainId,
            address: parsedProposal.collateral.address,
            category: parsedProposal.collateral.category ?? null,
            tokenId: storeSelectCollectionAsset?.selectedCollateral ? storeSelectCollectionAsset.selectedCollateral.tokenId.toString() ?? 0 : parsedProposal.collateral.tokenId ?? 0,
          }"
          is-proposal-preview
          :amount="collateralAmount"/>
        <ProposalDetail
          v-if="parsedProposal?.creditAsset"
          :credit-asset="parsedProposal.creditAsset as AssetDetail"
          :collateral-asset="parsedProposal.collateral as AssetDetail"
          :ltv="parsedProposal.creditData.ltv ?? 0"
          :credit-amount="creditAmount"
          :apr="parsedProposal.creditData.accruingInterestApr ?? 0"
          :credit-amount-label="parsedProposal.isOffer ? 'Borrow' : 'Lend'"
          :duration="parsedProposal.duration"
          :total-repayment="String(amountToRepayEnd)"
          :is-offer="parsedProposal.isOffer"
          :proposer="parsedProposal.proposer"
          :chain-id="parsedProposal.chainId"
          :type="parsedProposal.type"
          :proposal="parsedProposal"/>
      </div>
      <AprChart
        v-if="amountToRepayEnd && amountToRepayStart && loanDurationDays"
        :loan-duration-days="loanDurationDays"
        :amount-to-repay-start="+amountToRepayStart"
        :token-symbol="parsedProposal?.creditAsset?.symbol || ''"
        :amount-to-repay-end="+amountToRepayEnd"/>
      <div class="start-loan-modal__button-wrapper">
        <BaseButton
          v-if="isFungibleProposal"
          :font="ButtonFont.Supreme"
          button-text="Go Back"
          :color="ButtonColor.White"
          :variant="ButtonVariant.Underline"
          @click="handleGoBack"/>
        <span v-else/>

        <div class="start-loan-modal__start-loan-and-rep-score">
          <RewardsTooltip
            v-if="parsedProposal && parsedProposal.creditAsset?.address"
            :assets="rewardsAssets"
            :proposal-id="proposalId"
            :apr="apr">
            <template #trigger>
              <div class="start-loan-modal__trigger-rewards-tooltip">
                Rewards to be earned
                <RepScoreIcon
                  width="16"
                  class="start-loan-modal__trigger-icon"
                  height="16"/>
              </div>
            </template>
          </RewardsTooltip>

          <BaseButton
            :size="ButtonSize.L"
            button-text="Start Loan"
            :is-disabled="startLoanModalStore?.proposalMutation?.isPending"
            @on-button-click="startLoanModalStore.handleAcceptProposalMutateAsync"/>
        </div>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import ModalSize from '@/general-components/ModalSize'
import { computed } from 'vue'
import AprChart from '@/revamp/components/modals/proposal-preview-modal/AprChart.vue'
import { formatUnits } from 'viem'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import { parseAndTransform, zProposalDetail } from '@/revamp/modules/proposals/types'
import type { AssetDetail } from '@/revamp/modules/proposals/types'
import ProposalDetail from '@/revamp/components/ProposalDetail.vue'
import CollateralDetail from '@/revamp/components/CollateralDetail.vue'
import AssetType from '@/modules/common/assets/AssetType'
import { useProposalDetail } from '@/modules/common/backend/generated'
import DateUtils from '@/utils/DateUtils'
import BaseButton, { ButtonColor, ButtonFont, ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import { useStartLoanModalStore } from '@/revamp/components/actions/useStartLoanModalStore'
import {
  useSelectCollectionAssetModalStore,
} from '@/revamp/components/modals/select-collection-asset/useSelectCollectionAssetModalStore'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import {
  useAcceptFungibleProposalModalStore,
} from '@/revamp/components/modals/accept-fungible-proposal/useAcceptFungibleProposalModalStore'
import { formatAmountWithDecimals, getRawAmount } from '@/utils/utils'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import CloseIconSvg from '@/assets/icons/close.svg'
import RewardsTooltip from '@/general-components/promo/RewardsTooltip.vue'
import RepScoreIcon from '@/assets/icons/rep-score-primary-color.svg'
import { parseAddress } from '@/utils/address-utils'

const startLoanModalStore = useStartLoanModalStore()
const storeSelectCollectionAsset = useSelectCollectionAssetModalStore()
const fungibleProposalModalStore = useAcceptFungibleProposalModalStore()

const proposalId = computed(() => Number(startLoanModalStore.proposalId))
const proposalDetailQuery = useProposalDetail(proposalId, {
  query: {
    staleTime: 1000 * 60 * 5,
  },
})

const parsedProposal = computed(() => {
  if (!proposalDetailQuery.data.value?.data) {
    return null
  }
  return parseAndTransform(zProposalDetail, proposalDetailQuery.data.value?.data)
})

const isFungibleProposal = computed(() => parsedProposal.value?.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL)

const creditAmount = computed(() => {
  if (isFungibleProposal.value) {
    if (!fungibleProposalModalStore.proposalClass) return '0'
    if (!fungibleProposalModalStore.proposalClass.isOffer) {
      return String(fungibleProposalModalStore.proposalClass.creditAmountToAccept)
    }
    const maxCreditAmount = fungibleProposalModalStore.proposalClass.creditAsset.amount
    const maxCollateralAmount = fungibleProposalModalStore.proposalClass.collateral.amount
    const collateralAmount = formatAmountWithDecimals(fungibleProposalModalStore.proposalClass.collateralAmountToAccept ?? 0n, fungibleProposalModalStore.proposalClass.collateral.decimals)
    const creditAmountFungibleProposal = (+maxCreditAmount * +collateralAmount) / +maxCollateralAmount
    return getRawAmount(String(creditAmountFungibleProposal), fungibleProposalModalStore.proposalClass.creditAsset.decimals).toString()
  }
  return parsedProposal.value?.creditData?.amount ?? '0'
})

const collateralAmount = computed(() => {
  if (isFungibleProposal.value) {
    return fungibleProposalModalStore.proposalClass?.collateralAmountToAccept?.toString()
  }
  if (parsedProposal.value?.collateral?.category === AssetType.ERC721) {
    return 1
  }
  return parsedProposal.value?.collateralAmount
})

const loanDurationDays = computed(() => parsedProposal.value && DateUtils.convertSecondsToDays(parsedProposal.value.duration))
const amountToRepayStart = computed(() => parsedProposal.value && formatUnits(BigInt(creditAmount.value), parsedProposal.value?.creditAsset?.decimals ?? 18))
const amountToRepayEnd = computed(() => {
  if (!parsedProposal.value || !amountToRepayStart.value || !loanDurationDays.value) return null
  const apr = formatUnits(BigInt(parsedProposal.value.creditData.accruingInterestApr ?? 0), APR_DECIMAL_POINT_PADDING)
  return +amountToRepayStart.value * (1 + (+apr / 100) * (loanDurationDays.value / 365))
})

const apr = computed(() => {
  if (!parsedProposal.value?.creditData.accruingInterestApr) return 'N/A'
  return (parsedProposal.value?.creditData.accruingInterestApr * 10 ** -APR_DECIMAL_POINT_PADDING).toFixed(0) + '%'
})
const handleGoBack = () => {
  startLoanModalStore.close()
  fungibleProposalModalStore.open()
}

const rewardsAssets = computed(() => {
  if (!parsedProposal.value?.creditAsset?.address || !parsedProposal.value?.collateral?.address) return []
  return [{ chainId: parsedProposal.value.chainId, address: parseAddress(parsedProposal.value.creditAsset.address) }, { chainId: parsedProposal.value.chainId, address: parseAddress(parsedProposal.value.collateral.address) }]
})

</script>

<style scoped>
.start-loan-modal {
  &__body {
    margin-top: 5rem;
    flex-direction: column;
    height: 452px;
    display: flex;
  }

  &__button-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__go-back-button {
    font-family: var(--font-family-supreme);
  }

  &__button {
    margin-right: 0.3rem;
  }

  &__collateral-and-proposal-info {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    width: 100%;
  }

  &__collateral-detail {
    width: 100%;
    border-right: 1px solid gray;
    padding: 0 40px 2rem 0;
  }

  &__expires-in {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 14px;
  }

  &__header {
    display: flex;
    position: relative;
  }

  &__title {
    font-size: 1.5rem;
    font-family: var(--font-family-screener);
    width: 50%;
    font-weight: 400;
  }

  &__breadcrumbs-and-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.2rem;
    width: 100%;
  }

  &__close {
    width: 30px;
    height: 30px;
    margin-left: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__breadcrumb-select-collateral-amount {
    color: var(--separation-grey, #828282);
    font-family: var(--font-family-supreme);
    cursor: pointer;

    &:hover {
      color: var(--primary-color-1);
    }
  }

  &__breadcrumb-loan-preview {
    color: var(--primary-color-1);
    font-family: var(--font-family-supreme);
  }

  &__start-loan-and-rep-score {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__trigger-rewards-tooltip {
    display: flex;
    align-items: center;
    color: var(--primary-color-1);
    gap: 0.5rem;
    font-weight: bold;
    border-radius: 3rem;
    border: 1px solid var(--primary-color-1);
    padding: 0.5rem 1rem;
    cursor: help;
  }
}
</style>
