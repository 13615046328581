import { compareAddresses, compareAssets, formatAmountWithDecimals } from '@/utils/utils'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { AtrTokenInfo } from '@/modules/common/assets/AssetClasses'

const transferredAssetPriceUpdater = (
  existingAssets: AssetWithAmount[],
  transferredAssets: AssetWithAmount[],
  updaterFn: (existingAsset: AssetWithAmount, transferredAsset: AssetWithAmount) => Partial<AssetWithAmount>,
) => {
  if (!existingAssets?.length) return

  let results: AssetWithAmount[] = []

  let foundExistingAsset = false

  for (const existingNft of existingAssets) {
    const assetIfWasMutated = transferredAssets.find((nftToTransfer) =>
      compareAssets({
        assetA: existingNft,
        assetB: nftToTransfer,
      }),
    )

    if (assetIfWasMutated) {
      foundExistingAsset = true
      const updaterRes = updaterFn(existingNft, assetIfWasMutated)

      const updatedExistingNft = Object.assign(existingNft, updaterRes)

      if (updatedExistingNft.amountRaw === 0n) continue // if the amount is zero, we don't want to show it anymore
      results.push(updatedExistingNft)
    } else {
      results.push(existingNft)
    }
  }

  if (!foundExistingAsset) {
    results = results.concat(transferredAssets)
  }

  return results
}

const subtractAssetAmount = (
  existingAsset: AssetWithAmount,
  transferredAsset: AssetWithAmount,
): Partial<Pick<AssetWithAmount, 'amount' | 'tokenizedAmount'>> => {
  const assetAmount =
    transferredAsset.amountInput === '' ? transferredAsset.amountRaw : transferredAsset.amountInputRaw
  return {
    amount: formatAmountWithDecimals(existingAsset.amountRaw - assetAmount, existingAsset.decimals),
  }
}

const sumAmounts = (
  existingAsset: AssetWithAmount,
  transferredAsset: AssetWithAmount,
): Partial<Pick<AssetWithAmount, 'amount' | 'tokenizedAmount'>> => {
  const assetAmount =
    transferredAsset.amountInput === '' ? transferredAsset.amountRaw : transferredAsset.amountInputRaw

  return {
    amount: formatAmountWithDecimals(existingAsset.amountRaw + assetAmount, existingAsset.decimals),
  }
}

export const sumAssetAmounts = (
  existingAssets: AssetWithAmount[],
  transferredAssets: AssetWithAmount[],
// @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount[] | undefined' is not assign... Remove this comment to see the full error message
): AssetWithAmount[] => transferredAssetPriceUpdater(existingAssets, transferredAssets, sumAmounts)

export const subtractAssetAmounts = (
  existingAssets: AssetWithAmount[],
  transferredAssets: AssetWithAmount[],
// @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount[] | undefined' is not assign... Remove this comment to see the full error message
): AssetWithAmount[] => transferredAssetPriceUpdater(existingAssets, transferredAssets, subtractAssetAmount)

const incrementTokenizedAmountAndLinkATRs =
  (mintedATRs: AssetWithAmount[]) =>
    (existingAsset: AssetWithAmount, mintedUnderlyingAsset: AssetWithAmount): Partial<AssetWithAmount> => {
      const mintedAtrTokenRef = mintedATRs.find((mintedAtr) =>
        compareAssets({
          assetA: mintedAtr.assetOfAtrToken,
          assetB: mintedUnderlyingAsset,
        }),
      )
      if (!mintedAtrTokenRef) {
        throw Error('Minted ATR token not found')
      }

      const amountToAdd =
      mintedUnderlyingAsset.amountInput === '' ? mintedUnderlyingAsset.amountRaw : mintedUnderlyingAsset.amountInputRaw
      existingAsset.atrTokens.push(AtrTokenInfo.createFromAsset(mintedAtrTokenRef, amountToAdd.toString()))

      existingAsset = existingAsset.updateAssetAmounts({
        tokenizedAmount: formatAmountWithDecimals(
          existingAsset.tokenizedAmountRaw + amountToAdd,
          existingAsset.decimals,
        ),
      })

      return existingAsset
    }

const decrimentTokenizedAmountAndUnlinkATRs =
  (burnedATRs: AssetWithAmount[]) =>
    (existingAsset: AssetWithAmount, burnedUnderlyingAsset: AssetWithAmount): Partial<AssetWithAmount> => {
      const burnedAtrTokenRef = burnedATRs.find((burnedAtr) =>
        compareAssets({
          assetA: burnedAtr.assetOfAtrToken,
          assetB: burnedUnderlyingAsset,
        }),
      )
      if (!burnedAtrTokenRef) {
        throw Error('Burned ATR token not found')
      }

      const amountToSubtract =
      burnedUnderlyingAsset.amountInput === '' ? burnedUnderlyingAsset.amountRaw : burnedUnderlyingAsset.amountInputRaw

      existingAsset.atrTokens = existingAsset.atrTokens.filter(
        (atrToken) => (atrToken.tokenId !== burnedAtrTokenRef.tokenId) && (compareAddresses(atrToken.contractAddress, burnedAtrTokenRef.address)),
      )

      existingAsset = existingAsset.updateAssetAmounts({
        tokenizedAmount: formatAmountWithDecimals(
          existingAsset.tokenizedAmountRaw - amountToSubtract,
          existingAsset.decimals,
        ),
      })

      return existingAsset
    }

export const updateAndIncrementTokenizedAmount = (
  existingAssets: AssetWithAmount[],
  mintedATRs: AssetWithAmount[],
  underlyingAssets: AssetWithAmount[],
): AssetWithAmount[] =>
  // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount[] | undefined' is not assign... Remove this comment to see the full error message
  transferredAssetPriceUpdater(existingAssets, underlyingAssets, incrementTokenizedAmountAndLinkATRs(mintedATRs))

export const updateAndDecrementTokenizedAmount = (
  existingAssets: AssetWithAmount[],
  burnedATRs: AssetWithAmount[],
  underlyingAssets: AssetWithAmount[],
): AssetWithAmount[] =>
  // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount[] | undefined' is not assign... Remove this comment to see the full error message
  transferredAssetPriceUpdater(existingAssets, underlyingAssets, decrimentTokenizedAmountAndUnlinkATRs(burnedATRs))
