<template>
  <BaseTooltip
    v-if="asset.isTokenBundle && isUnpackBundleTooltip"
    is-interactive
    append-to-body
    :max-width="350"
    :border-color="TooltipBorderColor.Teal">
    <template #trigger>
      ⓘ
    </template>
    <div class="unpack-bundle-tooltip__bundle-tooltip-content">
      You can <span
        class="unpack-bundle-tooltip__link-to-unpack-bundle"
        @click="handleGoToUnpackBundle">unpack</span> this bundle to get the individual assets out of it.
    </div>
  </BaseTooltip>
</template>

<script setup lang="ts">
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import useUnpackBundle from '@/modules/pages/token-bundler/unpack-bundle/useUnpackBundle'
import router from '@/router'
import RouteName from '@/router/RouteName'
import { computed, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const route = useRoute()
const { selectedBundle } = useUnpackBundle()

const { address: userAddress } = useCustomAccount()

const isUserOwnDashboard = computed(() => {
  if (!route.params.user) return true
  else if (!userAddress.value) return false
  else return (route.params.user as string).toLowerCase() === userAddress.value.toLowerCase()
})

const isUnpackBundleTooltip = computed(() => route.name !== RouteName.UnpackBundleChooseBundle && route.name !== RouteName.LoanDetail && isUserOwnDashboard.value)

interface Props {
  asset: AssetWithAmount
}

const props = defineProps<Props>()
const { asset } = toRefs(props)

const handleGoToUnpackBundle = () => {
  selectedBundle.value = asset.value
  router.push({ name: RouteName.UnpackBundleChooseBundle })
}
</script>

<style scoped>
.unpack-bundle-tooltip {
  &__bundle-tooltip-content {
    text-align: center;
  }

  &__link-to-unpack-bundle {
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary-color-1);

    &:hover {
      opacity: 0.7;
    }
  }
}
  </style>
