<template>
  <div v-if="asset">
    <div
      :class="[
        'asset-basic-description__secondary',
        { 'asset-basic-description__missing': asset.isSecondaryInfoMissing },
      ]">
      <BaseLink
        :to="asset.assetCollection.routeToCollection"
        @click="handleOnGoToCollectionOffers">
        <div class="asset-basic-description__secondary-title">
          <SafeText
            :text="asset.collectionName"
            class="link link--primary"/>
        </div>
      </BaseLink>

      <div class="asset-basic-description__badge">
        <VerificationBadge :token="asset"/>
      </div>
    </div>
    <div :class="['asset-basic-description__main', { 'asset-basic-description__missing': asset.isPrimaryInfoMissing }]">
      <SafeText
        :text="asset.displayedName"
        :allow-click-through="false"/>
    </div>
    <div
      v-if="showOwnedBy"
      class="asset-basic-description__owner">
      owned by:
      <BaseSkeletor
        v-if="isFetchingOwnerOfERC721"
        class="asset-basic-description__owner-load-skeletor"/>
      <CryptoAddress
        v-else-if="!isFetchingOwnerOfERC721 && asset.ownerAddress"
        class="asset-basic-description__ens"
        :address="asset.ownerAddress"
        :chain-id="asset.chainId"
        is-link-to-dashboard/>
    </div>

    <div
      v-if="getAssetCustomDescriptionKey(asset, asset.chainId)"
      class="asset-basic-description__stats">
      <CustomAssetDescription :asset="asset"/>
    </div>
    <div
      v-else
      class="asset-basic-description__stats">
      <AssetStatInfo
        v-if="isFloorPriceShown"
        label="Floor price"
        tooltip-text="The floor price refers to the lowest-priced listing of an NFT within a collection"
        :amount-in-eth-and-usd="collectionStats?.floorPrice"
        :price-source="collectionStats?.dataSource"/>
      <AssetStatInfo
        label="Appraisal"
        :amount-in-eth-and-usd="estimatedValue?.price"
        tooltip-text="Estimated value of this NFT"
        :tooltip-max-width="650"
        :price-source="estimatedValue?.priceSource">
        <template #base-tooltip>
          <ValuationTooltip/>
        </template>
      </AssetStatInfo>
      <AssetStatInfo
        v-if="!asset.isTokenBundle"
        label="Highest Purchase offer"
        :amount-in-eth-and-usd="highestPurchaseOffer"
        :price-source="highestPurchaseOfferMarket!"
        tooltip-text="Highest ETH value offer"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SafeText from '@/general-components/SafeText.vue'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import VerificationBadge from '@/general-components/VerificationBadge.vue'
import { computed } from 'vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { useCollectionPageStore } from '@/revamp/pages/collection/useCollectionPageStore'
import BaseLink from '@/general-components/BaseLink.vue'
import AssetStatInfo from '@/general-components/AssetStatInfo.vue'
import useSectionNFTAppraisal from '@/modules/pages/asset/nft-page/SectionNFTAppraisal/useSectionNFTAppraisal'
import useSectionOffers from '@/modules/pages/asset/nft-page/SectionOffers/useSectionOffers'
import useSectionCollectionStats from '@/modules/pages/asset/nft-page/SectionCollectionStats/useSectionCollectionStats'
import ValuationTooltip from '@/general-components/ValuationTooltip.vue'
import { getAssetCustomDescriptionKey } from '@/modules/pages/asset/nft-page/CustomAssetDescription'
import CustomAssetDescription from '@/modules/pages/asset/nft-page/CustomAssetDescription/CustomAssetDescription.vue'
import { NFT_CATEGORIES } from '@/modules/common/assets/AssetType'

const storeCollectionOffersPage = useCollectionPageStore()
const { collectionStats } = useSectionCollectionStats()
const { asset, isFetchingOwnerOfERC721 } = useAssetPage()
// @ts-expect-error FIXME: strictNullChecks
const showOwnedBy = computed(() => asset?.value.hasSingleOwner)
const { nftAppraisal } = useSectionNFTAppraisal()
const { highestPurchaseOffer, highestPurchaseOfferMarket } = useSectionOffers()

const estimatedValue = computed(() => {
  if (nftAppraisal.value?.price) return nftAppraisal.value
  // @ts-expect-error FIXME: strictNullChecks
  if (asset.value.appraisal?.price) return asset.value.appraisal
  return null
})

const handleOnGoToCollectionOffers = () => {
  // @ts-expect-error FIXME: strictNullChecks
  if (asset.value.isErc20) return

  // @ts-expect-error FIXME: strictNullChecks
  storeCollectionOffersPage.collection = asset.value.assetCollection
  // @ts-expect-error FIXME: strictNullChecks
  storeCollectionOffersPage.collectionStats = collectionStats.value
}

const isFloorPriceShown = computed(() => {
  // @ts-expect-error FIXME: strictNullChecks
  if (asset.value.isBundleWithMultipleAssets) {
    return false
    // @ts-expect-error FIXME: strictNullChecks
  } else if (asset.value.isBundleWithSingleAsset && !NFT_CATEGORIES.includes(asset.value.bundleAssets?.[0]?.category)) {
    return false
  }

  return true
})
</script>

<style scoped>
.asset-basic-description {
  &__note {
    color: var(--negative-1);
    font-family: var(--font-family-oxygen-mono);
    line-height: 1.5rem;
    margin-bottom: 0;
  }

  &__text {
    margin-top: 0;
  }

  &__description {
    display: flex;
    gap: 1.25rem;
  }

  &__stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2.5rem;
    margin-top: 2.5rem;
  }

  &__main {
    max-width: 43rem;
    font-family: var(--font-family-screener);
    font-size: 1.875rem;
    line-height: 2.375rem;
  }

  &__missing {
    color: var(--negative-1);
  }

  &__secondary {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
    font-family: var(--font-family-autoscape);
    font-size: 1.25rem;
    font-weight: 550;
    line-height: 1.75rem;
  }

  &__secondary-title {
    margin-top: 0.15rem;
    max-width: 30rem;
  }

  &__owner {
    margin-top: 1rem;
    display: flex;
    font-family: var(--font-family-autoscape);
    font-size: 1.125rem;
    font-weight: 550;
    line-height: 1.625rem;
  }

  &__owner-load-skeletor {
    width: 25%;
    margin-top: 0.125rem;
  }

  &__owner-load-skeletor,
  &__ens {
    display: flex;
    align-items: center;

    margin-left: 1rem;
  }

  &__ens {
    cursor: pointer;
  }

  &__badge {
    padding-bottom: 0.9rem;
  }

  &__icon-external {
    margin: 0 0 0.3rem 0.5rem;
  }

  @media only screen and (--small-viewport) {
    &__stats {
      grid-template-columns: 1fr;
      row-gap: 1.5rem;
    }
  }

  @media only screen and (--mobile-viewport) {
    &__secondary {
      margin-top: 0;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }

    &__main {
      margin-top: 2rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
    }

    &__stats {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
