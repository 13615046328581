<template>
  <BaseTooltip
    :tooltip-text="getExpirationDate"
    is-interactive>
    <template #trigger>
      <div class="loan-countdown">
        {{ label }}<span v-if="!withoutInfoSign">&nbsp;</span>
      </div>
    </template>
  </BaseTooltip>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue'
import { DateTime, Duration } from 'luxon'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import DateUtils from '@/utils/DateUtils'
import type { IntervalId } from '@/modules/common/typings/customTypes'

interface Props {
  expirationDate: Date,
  withoutInfoSign?: boolean,
  smallestUnits?: 'minutes' | 'seconds'
}

const props = withDefaults(defineProps<Props>(), {
  smallestUnits: 'minutes',
})

const emit = defineEmits<{(e: 'on-expiration'): void}>()

const { expirationDate, smallestUnits } = toRefs(props)

const getExpirationDate = computed(() => DateUtils.displayLongDate(expirationDate.value))

const isPast = (expirationDate: Date) => {
  const expirationTime = DateTime.fromJSDate((expirationDate))
  return expirationTime.diffNow().as('milliseconds') < 0
}

const updatingInterval = computed(() => {
  const updateDurationUnit = smallestUnits.value || 'seconds'
  return Duration.fromObject({ [updateDurationUnit]: 1 }).toMillis()
})

const label = ref(DateUtils.getExpirationRelativeDetailed(expirationDate.value, smallestUnits.value))
const intervalRef = ref<IntervalId>()

const update = () => {
  if (isPast(expirationDate.value)) {
    emit('on-expiration')
    clearInterval(intervalRef.value)
  }
  label.value = DateUtils.getExpirationRelativeDetailed(expirationDate.value, smallestUnits.value)
}

watch(() => expirationDate.value, () => {
  if (isPast(expirationDate.value)) {
    emit('on-expiration')
  }
  label.value = DateUtils.getExpirationRelativeDetailed(expirationDate.value, smallestUnits.value)
})

onMounted(() => {
  if (isPast(expirationDate.value)) return

  intervalRef.value = setInterval(update, updatingInterval.value)
})

onBeforeUnmount(() => (clearInterval(intervalRef.value)))
</script>

<style scoped>
.loan-countdown {
  display: inline-flex;
  cursor: help;
}
</style>
