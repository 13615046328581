/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

export type LoanDetailSchemaWorkaroundTypeEnumBackendSchema = typeof LoanDetailSchemaWorkaroundTypeEnumBackendSchema[keyof typeof LoanDetailSchemaWorkaroundTypeEnumBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoanDetailSchemaWorkaroundTypeEnumBackendSchema = {
  pwn_contractsbetaloan: 'pwn_contracts.betaloan',
  pwn_contractsv1_1simpleloan: 'pwn_contracts.v1_1simpleloan',
  pwn_contractsv1_2simpleloan: 'pwn_contracts.v1_2simpleloan',
} as const;
