<template>
  <PwnSafeTokenizeModal/>
  <PwnSafeTokenizeHeader
    :has-no-search-results="hasNoSearchResults"
    :selected-chain="currentSafeChain!"
    :set-selected-chain="setSelectedChain"/>
  <PwnSafeTokenizeCoinsAndNftsSection
    :has-no-search-results="hasNoSearchResults"
    :tokens="sortedCoinsInTokenizeSection"
    :nfts="sortedNFTsInTokenizeSection"/>
  <PwnSafeTokenizeSelection/>
  <BaseButton
    class="pwn-safe-detail-tokenize__button"
    button-text="Mint ATR"
    :is-disabled="isTokenizationDisabled"
    @on-button-click="handleTokenizeButtonClick"/>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import usePwnSafeTokenizeCoinsAndNfts from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenizeCoinsAndNfts'
import PwnSafeTokenizeCoinsAndNftsSection from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/PwnSafeTokenizeCoinsAndNftsSection.vue'
import PwnSafeTokenizeHeader from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/PwnSafeTokenizeHeader.vue'
import PwnSafeTokenizeSelection from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/PwnSafeTokenizeSelection.vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import router from '@/router'
import RouteName from '@/router/RouteName'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import BaseButton from '@/general-components/BaseButton.vue'
import usePwnSafeTokenizeModal from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModal'
import PwnSafeTokenizeModal from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/PwnSafeTokenizeModal.vue'
import { useSafeAssetsDetail } from '@/modules/queryHooks/safe/useSafeAssetsDetail'
import { compareAddresses } from '@/utils/utils'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import { useSafesList } from '@/modules/queryHooks/safe/useSafesList'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { storeToRefs } from 'pinia'

const { isTokenizationDisabled, pwnSafeWhereTokenizing, chainTarget } = usePwnSafeTokenize()
const { handleConfirmSelectedAssetsToTokenize, isOpen } = usePwnSafeTokenizeModal()
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const { getSortedAssetsInTokenizeSection, searchTerm } = usePwnSafeTokenizeCoinsAndNfts()
const { address: userAddress } = useCustomAccount()

const selectedChain = ref(chainTarget.value || selectedPwnSafe.value?.chainId)

const pwnSafe = computed(() => {
  // @ts-expect-error FIXME: strictNullChecks
  if (compareAddresses(selectedPwnSafe.value.safeAddress, userAddress.value)) {
    return new PwnSafe({
      ...selectedPwnSafe.value,
      chainId: selectedChain.value,
    })
  } else {
    return selectedPwnSafe.value
  }
})

const currentSafeChain = computed(() => {
  if (chainTarget.value) return chainTarget.value
  // @ts-expect-error FIXME: strictNullChecks
  if (!chainTarget.value && compareAddresses(selectedPwnSafe.value.safeAddress, userAddress.value)) {
    return selectedChain.value
  }

  // @ts-expect-error FIXME: strictNullChecks
  return pwnSafe.value.chainId
})

// @ts-expect-error FIXME: strictNullChecks
const { nonAtrNfts, atrNfts, safeTokens } = useSafeAssetsDetail(pwnSafe)

const allNfts = computed(() => [...nonAtrNfts.value, ...atrNfts.value])

const sortedNFTsInTokenizeSection = computed(() => {
  return getSortedAssetsInTokenizeSection(allNfts.value)
})

const sortedCoinsInTokenizeSection = computed(() => {
  return getSortedAssetsInTokenizeSection(safeTokens.value)
})

const hasNoSearchResults = computed(() => {
  return searchTerm.value?.length >= 1 &&
    sortedNFTsInTokenizeSection.value.length === 0 &&
    sortedCoinsInTokenizeSection.value.length === 0
})

// @ts-expect-error FIXME: strictNullChecks
const { safes } = useSafesList(userAddress, selectedChain)

// TODO const composedImage = pwnSafeDetailStore.createBatchImage(assets) for the notification
const handleTokenizeButtonClick = () => {
  isOpen.value = true
  // @ts-expect-error FIXME: strictNullChecks
  const userWallet = safes.value.find((safe) => compareAddresses(safe.safeAddress, userAddress.value))
  // @ts-expect-error FIXME: strictNullChecks
  handleConfirmSelectedAssetsToTokenize(userWallet)
}

const setSelectedChain = (chain: SetChainFilterOption) => {
  if (chain === 'all' || chainTarget.value) return
  selectedChain.value = chain
}

watch(pwnSafe, () => {
  if (pwnSafe.value) {
    pwnSafeWhereTokenizing.value = pwnSafe.value
  }
})

onMounted(() => {
  if (!selectedPwnSafe.value) router.push({ name: RouteName.PwnSafe })
  else if (!pwnSafeWhereTokenizing.value) pwnSafeWhereTokenizing.value = pwnSafe.value

  searchTerm.value = ''
})

watch(userAddress, () => {
  router.push({ name: RouteName.PwnSafe })
})
</script>

<style scoped>
.pwn-safe-detail-tokenize {
  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
}
</style>
