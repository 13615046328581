<template>
  <img
    v-if="avatarSrc && !avatarLoadError"
    alt="ENS"
    class="user-avatar"
    :src="avatarSrc"
    @error="handleOnError">
  <UserIconSvg
    v-else
    class="user-avatar"
    alt="ENS"/>
</template>

<script setup lang="ts">
import UserIconSvg from '@/assets/icons/user.svg'
import { ref, toRefs } from 'vue'

const props = defineProps<{
  avatarSrc?: string | undefined | null
}>()
const { avatarSrc } = toRefs(props)

const avatarLoadError = ref(false)
const handleOnError = () => {
  avatarLoadError.value = true
}
</script>

<style scoped>
.user-avatar {
  width: 1em;
  height: 1em;
  margin-right: 0.5rem;
  object-fit: contain;
  vertical-align: bottom;
}
</style>
