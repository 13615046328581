<template>
  <BaseSkeletor
    v-if="isLoading"
    :height="8"
    :width="30"/>
  <BaseLink
    v-else-if="hasEfpData"
    :to="`https://ethfollow.xyz/${props.address}`"
    target="_blank">
    <BaseTooltip>
      <template #trigger>
        <img
          src="@/assets/icons/efp-logo.svg?url"
          alt="EFP Logo"
          :width="iconSize"
          :height="iconSize">
      </template>

      <div class="efp-component__tooltip-content">
        <span>
          Followers: {{ efpData.data.value?.followers_count }}
        </span>
        <span>
          Following: {{ efpData.data.value?.following_count }}
        </span>
      </div>
    </BaseTooltip>
  </BaseLink>
</template>

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import type { Address } from 'viem'
import { computed, toRefs, withDefaults } from 'vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import BaseTooltip from '@/general-components/BaseTooltip.vue'

type Props = {
  address: Address,
  iconSize?: number
}

const props = withDefaults(defineProps<Props>(), {
  iconSize: 24,
})

const { address } = toRefs(props)

type EFPApiResponse = {
  followers_count: number
  following_count: number
}

const getEfpData = async (address: string): Promise<EFPApiResponse> => {
  const response = await fetch(`https://api.ethfollow.xyz/api/v1/users/${address}/stats`)
  return response.json() as Promise<EFPApiResponse>
}

const efpData = useQuery({
  queryKey: ['efpData', address],
  queryFn: (ctx) => getEfpData(ctx.queryKey[1]),
  enabled: !!address.value,
  retry: 2,
  retryDelay: 700,
})

const isLoading = computed(() => efpData.isLoading.value)

const hasEfpData = computed(() => {
  const { followers_count, following_count } = efpData?.data?.value ?? {}

  if (!followers_count || !following_count) {
    return false
  }

  return (
    (followers_count ?? 0) > 0 || (following_count ?? 0) > 0
  )
})

</script>

<style scoped>
.efp-component__tooltip-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
}
</style>
