import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { zeroAddress } from 'viem'
import AssetType from '@/modules/common/assets/AssetType'
import type { SupportedChain } from '@/constants/chains/types'
import type NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'

export const useDivider = () => {
  const isDivider = (asset?: AssetMetadata | AssetWithAmount | NFTAssetCollection) => {
    return asset?.name === 'DIVIDER'
  }

  const getDivider = (chainId?: SupportedChain) => {
    return new AssetMetadata({
      chainId,
      address: zeroAddress,
      category: AssetType.ERC20,
      name: 'DIVIDER',
      symbol: '',
      image: '',
      isVerified: true,
      decimals: 6,
      id: (chainId || 1) * -1, // negative id to avoid conflicts
    })
  }

  return {
    isDivider,
    getDivider,
  }
}
