import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import StoreIds from '@/constants/storeIds'
import { typeSafeObjectFromEntries } from '@/utils/typescriptWrappers'
import { ALL_SUPPORTED_CHAINS } from '@/constants/chains/types'
import type { AssetWithAmount, AssetMetadata } from '@/modules/common/assets/AssetClasses'

export const useModalWrapNativeTokenStore = defineStore(StoreIds.modalWrapNativeToken, () => {
  const isOpen = reactive(
    typeSafeObjectFromEntries(
      ALL_SUPPORTED_CHAINS.map((chainId) => {
        return [chainId, false]
      }),
    ),
  )

  const openModal = ({ chainId, address }: AssetWithAmount | AssetMetadata) => {
    isOpen[`${chainId}${address}`] = true
  }

  const closeModal = ({ chainId, address }: AssetWithAmount | AssetMetadata) => {
    isOpen[`${chainId}${address}`] = false
  }

  const getIsOpen = ({ chainId, address }: AssetWithAmount | AssetMetadata) => {
    return computed(() => isOpen[`${chainId}${address}`])
  }

  return {
    getIsOpen,
    openModal,
    closeModal,
  }
})
