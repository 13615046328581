import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as cronosIcon from '@/assets/icons/cronos.svg'
import * as ebisusBayIcon from '@/assets/icons/ebisusbay.svg'
import linkTo from '@/constants/links'
import type { Address } from 'viem'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

export const EBISUSBAY_NAME = 'Ebisu\'s Bay'
const BLOCK_EXPLORER_URL = 'https://cronoscan.com'

const CRONOS_CONSTANTS: ChainConstants = {
  gnosisSafe: { chainName: '', transactionServiceUrl: '' },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x4188C513fd94B0458715287570c832d9560bc08a',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x973E09e96E64E4bf17e383a8A497Fb566284c707',
  topTokens: new TopTokens({
    dai: '0xF2001B145b43032AAF5Ee2884e456CCd805F677D',
    usdc: '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
    usdt: '0x66e428c3f67a68878562e79A0234c1F83c208770',
    weth: '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
    wcro: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
  }),
  general: {
    chainId: SupportedChain.Cronos,
    chainName: 'Cronos',
  },
  nodeProvider: {
    httpNodeUrl: `https://nd-993-755-972.p2pify.com/${import.meta.env.VITE_CRONOS_CHAINSTACK_NODE_KEY}`,
    wsNodeUrl: `wss://ws-nd-993-755-972.p2pify.com/${import.meta.env.VITE_CRONOS_CHAINSTACK_NODE_KEY}`,
  },
  nftContractsAddresses: {
    kyberSwapElastic: '0xe222fBE074A436145b255442D919E4E3A6c6a480',
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'cronoscan',
    blockExplorerIcon: cronosIcon.default,
    nftDetailsLink: (tokenAddress, tokenId) => `${linkTo.ebisusBay}collection/${tokenAddress}/${tokenId}`,
    nftExplorerName: EBISUSBAY_NAME,
    nftExplorerIcon: ebisusBayIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
  },
}

export default CRONOS_CONSTANTS
