/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

export type MetadataSourceBackendSchema = typeof MetadataSourceBackendSchema[keyof typeof MetadataSourceBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetadataSourceBackendSchema = {
  PWN_OVERWRITE: 'PWN_OVERWRITE',
  ALCHEMY: 'ALCHEMY',
  CONTRACT: 'CONTRACT',
  COVALENTHQ: 'COVALENTHQ',
  LOOKSRARE: 'LOOKSRARE',
  MORALIS: 'MORALIS',
  OPENSEA: 'OPENSEA',
  UPSHOT: 'UPSHOT',
  UNMARSHAL: 'UNMARSHAL',
  NFTBANK: 'NFTBANK',
  EBISUSBAY: 'EBISUSBAY',
  DEXGURU: 'DEXGURU',
  COINGECKO: 'COINGECKO',
  COINMARKETCAP: 'COINMARKETCAP',
  SIMPLE_HASH: 'SIMPLE_HASH',
  DEXSCREENER: 'DEXSCREENER',
  VENO_FINANCE: 'VENO_FINANCE',
  KYBER_SWAP: 'KYBER_SWAP',
  SOMMELIER: 'SOMMELIER',
  INSTADAPP: 'INSTADAPP',
  YEARN: 'YEARN',
  BACKED: 'BACKED',
  SWAAP: 'SWAAP',
  REVEST: 'REVEST',
  BEEFY: 'BEEFY',
  PENDLE: 'PENDLE',
  SUPERFORM: 'SUPERFORM',
  SUSHI: 'SUSHI',
  ETHERFI: 'ETHERFI',
  HARVEST: 'HARVEST',
  MORPHO: 'MORPHO',
  KERNEL: 'KERNEL',
  MELLOW: 'MELLOW',
  RESERVE: 'RESERVE',
  SEPOLIA: 'SEPOLIA',
  SPECTRA: 'SPECTRA',
  RESOLV: 'RESOLV',
  TOKEN_LIST_PWN: 'TOKEN_LIST_PWN',
  TOKEN_LIST_UNISWAP: 'TOKEN_LIST_UNISWAP',
  TOKEN_LIST_ZAPPER: 'TOKEN_LIST_ZAPPER',
  TOKEN_LIST_OPTIMISM: 'TOKEN_LIST_OPTIMISM',
  TOKEN_LIST_ARBITRUM_ONE: 'TOKEN_LIST_ARBITRUM_ONE',
  TOKEN_LIST_MATICNETWORK: 'TOKEN_LIST_MATICNETWORK',
  TOKEN_LIST_CRONASWAP: 'TOKEN_LIST_CRONASWAP',
  TOKEN_LIST_BOGGED: 'TOKEN_LIST_BOGGED',
  TOKEN_LIST_COINGECKO: 'TOKEN_LIST_COINGECKO',
  TOKEN_LIST_BNB_SMART_CHAIN: 'TOKEN_LIST_BNB_SMART_CHAIN',
  TOKEN_LIST_STARKNET: 'TOKEN_LIST_STARKNET',
  TOKEN_LIST_COWSWAP: 'TOKEN_LIST_COWSWAP',
} as const;
