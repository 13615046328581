<template>
  <div class="price-range">
    <div class="price-range__low">
      <div class="price-range__label-and-question-mark-tooltip">
        <span>Low</span>

        <QuestionMarkTooltip
          tooltip-text="The lower boundary for the predicted price range of an asset"/>
      </div>
      <div class="price-range__price">
        <BasePriceLabel
          :amount="low?.ethAmount"
          symbol="ETH"/>
        <BaseTooltip>
          <template #trigger>
            <span class="price-range__dollar-tooltip">&nbsp;($)</span>
          </template>
          {{ low?.formattedUsdAmount }}
        </BaseTooltip>
      </div>
    </div>
    <img
      class="price-range__range-icon"
      src="@/assets/icons/bar-range.svg?url"
      alt="range icon">

    <div class="price-range__right">
      <div class="price-range__label-and-question-mark-tooltip">
        <span>High</span>
        <QuestionMarkTooltip
          tooltip-text="The upper boundary for the predicted price range of an asset"/>
      </div>
      <div class="price-range__price">
        <BasePriceLabel
          :amount="high?.ethAmount"
          symbol="ETH"/>
        <BaseTooltip>
          <template #trigger>
            <span class="price-range__dollar-tooltip">&nbsp;($)</span>
          </template>
          {{ high?.formattedUsdAmount }}
        </BaseTooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import { toRefs } from 'vue'
import BasePriceLabel from '@/general-components/BasePriceLabel.vue'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import type { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'

interface Props {
  low: AmountInEthAndUsd
  high: AmountInEthAndUsd
}
const props = defineProps<Props>()
const { low, high } = toRefs(props)
</script>

<style scoped>
.price-range {
  position: relative;
  display: flex;
  justify-content: space-between;

  &__question-mark {
    margin-left: 0.2rem;
    line-height: 0;
  }

  &__price {
    display: flex;
    margin-top: 1.5rem;
  }

  &__range-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__dollar-tooltip {
    font-size: 0.75rem;
    color: var(--gray);
  }

  &__label-and-question-mark-tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
  }
}
</style>
