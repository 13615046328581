import type { BreadcrumbActiveStep } from '@/general-components/IBreadcrumbsHeader'
import RouteName from '@/router/RouteName'

const UNPACK_BUNDLE_STEPS_DEFINITION: BreadcrumbActiveStep[] = [
  {
    stepNumber: 1,
    headingText: 'Choose Assets',
    hint: 'Please choose the assets you want to bundle.',
    stepName: '1. Choose Assets',
    route: RouteName.BundleAssetsChooseAssets,
  },
  {
    stepNumber: 2,
    headingText: 'Bundle',
    stepName: '2. Bundle',
    route: RouteName.BundleAssetsSubmit,
  },
]
export default UNPACK_BUNDLE_STEPS_DEFINITION
