<template>
  <div
    :class="['toggle', {'toggle--selected': selected}]"
    @click="toggle">
    <div :class="['toggle__box', {'toggle__box--selected': selected}]">
      <RectangleIconSvg alt="rectangle"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import RectangleIconSvg from '@/assets/icons/rectangle.svg'

const props = defineProps<{
  selected: boolean | undefined
}>()

const { selected } = toRefs(props)
const emit = defineEmits<{(e: 'toggle', value: boolean): void}>()
const toggle = () => {
  emit('toggle', selected.value)
}
</script>

<style scoped>
.toggle {
  display: flex;
  width: 2.7rem;
  height: 1.5rem;
  background: var(--gray-3);
  border: 1px solid var(--gray-2);
  cursor: pointer;

  &--selected {
    border: 1px solid var(--primary-color-1);
  }

  &__box {
    display: flex;
    margin: auto auto auto 0.25rem;
    filter: grayscale(1);

    &--selected {
      filter: grayscale(0);
      margin: auto 0.25rem auto auto;
    }
  }
}
</style>
