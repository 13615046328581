<template>
  <BaseTooltip
    v-if="token"
    is-interactive
    :has-tooltip="hasTooltip">
    <template #trigger>
      <div :class="['token-and-eth-and-usd-price-label__trigger-container', {'token-and-eth-and-usd-price-label__trigger-container--only-amount': isOnlyAmount}]">
        <TokenMedia
          v-if="!isOnlyAmount"
          class="token-and-eth-and-usd-price-label__token-logo"
          :token="token"
          :width="tokenLogoSizePx"
          :height="tokenLogoSizePx"/>
        <SafeText
          :has-tooltip="false"
          :text="tokenAmountInTrigger"
          @on-overflow="handleTriggerOverflown"/>

        <slot/>
      </div>
    </template>
    <template #default>
      <div class="token-and-eth-and-usd-price-label__tooltip-container">
        <div
          v-if="isTokenAmountDisplayedInTooltip && !isOnlyAddress"
          class="token-and-eth-and-usd-price-label__amount-with-icon-container">
          <TokenMedia
            :token="token"
            width="16"
            height="16"/>
          <span>{{ token.displayAmount }} {{ token.symbol }}</span>
        </div>
        <div
          v-if="price?.formattedEthAmount || token.appraisal?.price?.formattedEthAmount && !isOnlyAddress"
          class="token-and-eth-and-usd-price-label__amount-with-icon-container">
          <EthereumIcon
            width="16"
            height="16"
            class="eth-and-usd-price-label__eth-icon"/>
          <span>{{ price?.formattedEthAmount || token.appraisal.price.formattedEthAmount }} ETH</span>
        </div>
        <div
          v-if="price?.formattedUsdAmount || token.appraisal?.price?.formattedUsdAmount && !isOnlyAddress"
          class="token-and-eth-and-usd-price-label__amount-with-icon-container">
          <img
            width="16"
            height="16"
            class="eth-and-usd-price-label__eth-icon"
            src="@/assets/icons/usd.svg?url"
            alt="ETH">
          <span>{{ price?.formattedUsdAmount || token.appraisal.price.formattedUsdAmount }} USD</span>
        </div>
        <div
          v-if="hasShownAddress && props.token"
          class="token-and-eth-and-usd-price-label__actions">
          <!--          <BaseTooltip-->
          <!--            :has-tooltip="true"-->
          <!--            is-interactive-->
          <!--            :tooltip-text="isSupported ? props.token.address : 'Not supported'">-->
          <!--            <template #trigger>-->
          <span
            class="token-and-eth-and-usd-price-label__copy"
            @click="copy(props.token.address)">
            <span class="token-and-eth-and-usd-price-label__copy-short-address">
              {{ copied ? 'Copied!' : shortAddress }}
            </span>
            <CopyIconSvg
              v-if="isSupported"
              class="token-and-eth-and-usd-price-label__copy-icon"/>
          </span>
          <!--            </template>-->
          <!--            <template #tooltip>-->
          <!--              <div>-->
          <!--                {{ props.token.address }}-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </BaseTooltip>-->
          <BaseLink
            class="token-and-eth-and-usd-price-label__explorer-link"
            :to="getExplorerAddressLink"
            target="_blank">
            <ExplorerIcon
              width="16"
              height="16"/>
          </BaseLink>
        </div>
      </div>
    </template>
  </BaseTooltip>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, toRefs, ref } from 'vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import SafeText from '@/general-components/SafeText.vue'
import EthereumIcon from '@/assets/icons/ethereum-white.svg'
import { shortenAddress, shortenNumber } from '@/utils/utils'
import { useClipboard } from '@vueuse/core'
import BaseLink from './BaseLink.vue'
import CopyIconSvg from '@/assets/icons/copy.svg'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'

interface Props {
  tokenLogoSizePx?: string
  token: AssetWithAmount
  isOnlyAmount?: boolean
  hasShownAddress?: boolean
  price?: AmountInEthAndUsd
  isOnlyAddress?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  tokenLogoSizePx: '16',
  isOnlyAmount: false,
  hasShownAddress: false,
})

const { tokenLogoSizePx, token, isOnlyAmount, price } = toRefs(props)

const { copy, copied, isSupported } = useClipboard({ source: props.token.address })
const ExplorerIcon = computed(() => CHAINS_CONSTANTS[token.value.chainId].explorer?.blockExplorerIcon)
const getExplorerAddressLink = computed(() => {
  return CHAINS_CONSTANTS[token.value.chainId].explorer.addressDetailsLink(props.token.address)
})
const shortAddress = computed(() => shortenAddress(props.token.address))

const isTriggerOverflown = ref(false)

const isTokenAmountDisplayedInTooltip = computed(() =>
  token.value?.amount &&
  (
    token.value.amountShortened !== token.value.displayAmount ||
    isTriggerOverflown.value
  ),
)

const hasTooltip = computed(() => {
  return Boolean(
    isTokenAmountDisplayedInTooltip.value ||
    token.value?.appraisal?.price?.formattedEthAmount ||
    token.value?.appraisal?.price?.formattedUsdAmount ||
    isTriggerOverflown.value,
  )
})
const tokenAmountInTrigger = computed(() => isOnlyAmount.value ? shortenNumber(token.value?.amountFormatted).toUpperCase() : token.value.amountFormattedWithCurrency)

const handleTriggerOverflown = (isOverflow: boolean) => {
  isTriggerOverflown.value = isOverflow
}

</script>

<style scoped>
.token-and-eth-and-usd-price-label {
  &__eth-icon {
    margin-right: 0.5rem;

    --text-color: var(--primary-color-1);
  }

  &__token-logo {
    height: 1em;
    margin-right: 0.5rem;
  }

  &__trigger-container {
    display: flex;
    align-items: center;
    vertical-align: middle;

    &--only-amount {
      padding-right: 0;
    }
  }

  &__tooltip-container {
    font-size: 1rem;
    line-height: 1.5;
    font-family: var(--font-family-oxygen-mono);
  }

  &__amount-with-icon-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__actions {
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-end;
  }

  &__copy {
    display: flex;
    width: 6.5rem;
    gap: 0.2rem;
    cursor: copy;
  }

  &__copy-icon {
    margin-bottom: 0.25rem;
    align-self: flex-end;

    --primary-color-1: var(--gray-2);
  }

  &__copy-short-address {
    width: 6rem;
  }

  &__explorer-link {
    margin-left: 1rem;
    line-height: 1;
  }
}

</style>

<style>
.nouns-subdomain .eth-and-usd-price-label__eth-icon * {
  fill: var(--text-color);
}

.token-and-eth-and-usd-price-label__copy:hover > .token-and-eth-and-usd-price-label__copy-icon {
  --primary-color-1: rgb(var(--primary-color-base));
}
</style>
