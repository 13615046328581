<template>
  <div :class="`eth-and-usd-price-label__trigger-wrapper eth-and-usd-price-label__trigger-wrapper--${align}`">
    <BaseTooltip
      is-interactive
      :has-tooltip="hasTooltip">
      <template #trigger>
        <div>
          <div
            v-if="amount?.ethAmount"
            class="eth-and-usd-price-label__container">
            <EthereumIcon
              v-if="isEthPrimary"
              class="eth-and-usd-price-label__eth-icon eth-and-usd-price-label__eth-icon--trigger"
              :style="{width: ethIconWidthPx}"
              alt="ETH"/>
            <span
              v-if="isEthPrimary"
              class="eth-and-usd-price-label__eth-amount">{{ decimals ? displayAmount(amount.ethAmount) : amount.shortenedEthAmount }}</span>
            <span
              v-else
              class="eth-and-usd-price-label__eth-amount">${{ shortenUsdAmount ? shortenNumber(amount.usdAmount).toUpperCase() : amount.formattedUsdAmount }}</span>
            <AssetPriceSourceIcon
              v-if="priceSource"
              :link="coingeckoUrl"
              :data-source-icon-width-px="dataSourceIconWidthPx"
              class="eth-and-usd-price-label__source-icon"
              :opensea-override="asset?.collection?.openseaUrl"
              :data-source="priceSource"/>
          </div>
          <div v-else-if="isTextNoDataFallback">
            <div
              class="eth-and-usd-price-label__question-mark-tooltip">
              No data
              <QuestionMarkTooltip
                :tooltip-text="noDataTooltipText"/>
            </div>
          </div>
          <div v-else>
            ---
          </div>
        </div>
      </template>
      <template #default>
        <div class="eth-and-usd-price-label__tooltip-container">
          <div
            v-if="amount?.formattedEthAmount"
            class="eth-and-usd-price-label__amount-with-icon-container">
            <EthereumIcon
              class="eth-and-usd-price-label__eth-icon"
              width="16"
              height="16"/>
            <span>{{ amount.formattedEthAmount }} ETH</span>
          </div>
          <div
            v-if="amount?.formattedUsdAmount"
            class="eth-and-usd-price-label__amount-with-icon-container">
            <img
              width="16"
              height="16"
              src="@/assets/icons/usd.svg?url"
              alt="ETH">
            <span>{{ amount.formattedUsdAmount }} USD</span>
          </div>
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import type { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import AssetPriceSourceIcon from '@/general-components/data-source/AssetPriceSourceIcon.vue'
import { displayAmount, shortenNumber } from '@/utils/utils'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import EthereumIcon from '@/assets/icons/ethereum-white.svg'
import { useCurrencySwitcherStore } from '@/layout/header/useCurrencySwitcherStore'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import type { ValuationSource } from '@/general-components/data-source/DataSourceType'
import { useGetCoingeckoUrl } from '@/modules/common/backend/generated'

const currencySwitcherStore = useCurrencySwitcherStore()

interface Props {
  amount: AmountInEthAndUsd | null | undefined
  priceSource?: ValuationSource
  isTextNoDataFallback?: boolean
  hasTooltip?: boolean
  dataSourceIconWidthPx?: string
  ethIconWidthPx?: string
  decimals?: number
  align?: 'left' | 'right'
  asset?: AssetWithAmount | AssetMetadata | null | undefined
  noDataTooltipText?: string
  shortenUsdAmount?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasTooltip: true,
  align: 'left',
  noDataTooltipText: 'Asset appraisal not found',
  shortenUsdAmount: true,
})

const isEthPrimary = computed(() => currencySwitcherStore.selectedCurrency.label === 'ETH')

const { amount, priceSource } = toRefs(props)

const hasTooltip = computed(() => {
  return props.hasTooltip && Boolean(amount.value?.formattedEthAmount || amount.value?.formattedUsdAmount)
})

const chainId = computed(() => Number(props.asset?.chainId))
const assetAddressToFetch = computed(() => props.asset?.address ?? '')
const queryIsEnabled = computed(() => !!chainId.value && !!assetAddressToFetch.value && priceSource.value === DataSourceType.COINGECKO)

const coingeckoUrlQuery = useGetCoingeckoUrl(
  chainId,
  assetAddressToFetch,
  {
    query: {
      enabled: queryIsEnabled,
      staleTime: 1000 * 60 * 120,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: 2,
    },
  },
)

const coingeckoUrl = computed(() => coingeckoUrlQuery ? coingeckoUrlQuery.data?.value?.data : undefined)
</script>

<style scoped>
.eth-and-usd-price-label {
  &__container {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &__eth-icon {
    height: 1em;

    &--trigger {
      margin-right: 0.5rem;
    }
  }

  &__eth-amount {
    font-family: var(--font-family-oxygen-mono);
  }

  &__source-icon {
    margin-left: 0.5rem;
  }

  &__tooltip-container {
    font-size: 1rem;
    line-height: 1.5;
    font-family: var(--font-family-oxygen-mono);
  }

  &__amount-with-icon-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__trigger-wrapper {
    display: flex;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__appraisal-text {
    width: fit-content;
  }

  &__question-mark-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: var(--gray-2);
  }
}
</style>

<style>
.nouns-subdomain .eth-and-usd-price-label__eth-icon * {
  fill: var(--text-color);
}
</style>
