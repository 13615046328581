import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import type { SupportedChain } from '@/constants/chains/types'
import { parseChainIdFromResponse } from '@/modules/common/backend/backendUtils'
import type { BaseSearchResult } from '@/modules/common/pwn/explorer/models/index'
import type { RouteLocationRaw } from 'vue-router'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { getAddress } from 'viem'
import type { Address, Hex } from 'viem'
import type { NFTAssetCollectionDetailWithStatsSchemaBackendSchema, NFTAssetCollectionDocumentBackendSchema } from '@/modules/common/backend/generated'

export class CollectionSearchResult implements BaseSearchResult {
  chainId: SupportedChain
  contractAddress: Address
  name: string
  image: string
  isVerified: boolean
  symbol: string
  tlvValue: AmountInEthAndUsd
  openseaSlug: string

  constructor(collection: Partial<CollectionSearchResult>) {
    // @ts-expect-error TS(2322) FIXME: Type 'SupportedChain | undefined' is not assignabl... Remove this comment to see the full error message
    this.chainId = collection?.chainId
    // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
    this.contractAddress = getAddress(collection?.contractAddress)
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.name = collection?.name
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.image = collection?.image
    this.isVerified = collection?.isVerified ?? false
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.symbol = collection?.symbol
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.tlvValue = collection?.tlvValue
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.openseaSlug = collection?.openseaSlug
  }

  public static createFromBackendModel(collection: NFTAssetCollectionDocumentBackendSchema): CollectionSearchResult {
    return new CollectionSearchResult({
      ...collection,
      chainId: parseChainIdFromResponse(collection?.contract?.chain_id),
      contractAddress: collection?.contract?.address as Address,
      name: collection?.name,
      image: collection?.thumbnail_url,
      isVerified: collection?.is_verified ?? false,
      symbol: collection?.symbol,
      tlvValue: new AmountInEthAndUsd(
        String(collection?.tlv_value_eth),
        String(collection?.tlv_value_usd),
      ),
      openseaSlug: collection?.opensea_slug,
    })
  }

  public static createFromNFTAssetCollectionDetailWithStats(collection: NFTAssetCollectionDetailWithStatsSchemaBackendSchema): CollectionSearchResult {
    return new CollectionSearchResult({
      ...collection,
      chainId: parseChainIdFromResponse(collection?.contract?.chain_id),
      contractAddress: collection?.contract?.address as Hex,
      name: collection?.name ?? undefined,
      image: collection?.thumbnail_url ?? undefined,
      isVerified: collection?.is_verified ?? false,
      symbol: collection?.symbol ?? undefined,
      tlvValue: new AmountInEthAndUsd(
        String(collection?.tlv_value_eth),
        String(collection?.tlv_value_usd),
      ),
      openseaSlug: collection?.opensea_slug ?? undefined,
    })
  }

  get title(): string {
    return this.name
  }

  get key(): string {
    return `${this.chainId}-${this.contractAddress}`
  }

  pageRoute(): RouteLocationRaw {
    return {
      name: RouteName.CollectionByContractAddress,
      params: {
        chainName: CHAINS_CONSTANTS[this.chainId].general.chainName,
        contractAddress: this.contractAddress,
      },
    }
  }
}
