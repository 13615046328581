<template>
  <div
    v-if="asset"
    class="section-activity">
    <BaseAssetPageSection
      icon="activity"
      question-mark-tooltip="Historical view of events related to this asset"
      :has-data="hasData"
      :is-fetching="isFetchingEvents"
      label="Activity">
      <template #content>
        <div class="section-activity__content">
          <BaseTable
            :columns="TABLE_DEFINITION"
            :variant="TableVariant.AssetPage"
            :is-hoverable="false"
            table-max-height="254px"
            table-min-width="700px"
            :is-fetching="isFetchingEvents"
            :items="nftEvents">
            <template #eventType="{ item: nftEvent }">
              <img
                :src="getNftEventIconSrc(nftEvent)"
                :alt="nftEvent.eventType"
                class="section-activity__event-icon"> {{ nftEvent.eventType }}
            </template>

            <template #price="{ item: nftEvent }">
              <TokenAndEthAndUsdPriceLabel
                v-if="nftEvent.isSaleEvent"
                class="section-activity__price"
                :token="nftEvent.token"/>
            </template>

            <template #from="{ item: nftEvent }">
              <div class="section-activity__ens">
                <CryptoAddress
                  :address="nftEvent.fromAddress"
                  :chain-id="asset.chainId"/>
              </div>
            </template>

            <template #to="{ item: nftEvent }">
              <div class="section-activity__ens">
                <CryptoAddress
                  :address="nftEvent.toAddress"
                  :chain-id="asset.chainId"/>
              </div>
            </template>

            <template #date="{ item: nftEvent }">
              <div
                v-if="nftEvent.date"
                class="section-activity__date">
                {{ DateUtils.displayDate(nftEvent.date) }}
              </div>
            </template>
          </BaseTable>
        </div>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import BaseTable from '@/general-components/BaseTable.vue'
import { TABLE_DEFINITION } from '@/modules/pages/asset/nft-page/SectionNFTEvents/SectionNFTEventsTableDefinitions'
import DateUtils from '@/utils/DateUtils'
import TableVariant from '@/general-components/TableVariant'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import useSectionNFTEvents from '@/modules/pages/asset/nft-page/SectionNFTEvents/useSectionNFTEvents'
import TokenAndEthAndUsdPriceLabel from '@/general-components/TokenAndEthAndUsdPriceLabel.vue'
import { computed } from 'vue'
import type NFTEvent from '@/modules/common/assets/typings/NFTEvent'

const { asset } = useAssetPage()
const { nftEvents, isFetchingEvents } = useSectionNFTEvents()

const hasData = computed(() => nftEvents.value.length > 0)

const getNftEventIconSrc = (nftEvent: NFTEvent) => {
  return new URL(`/src/assets/icons/${nftEvent.eventType.toLowerCase()}.svg`, import.meta.url).href
}
</script>

<style scoped>
.section-activity {
  &__content {
    height: var(--default-asset-section-height);
    overflow-x: auto;
  }

  &__ens {
    padding: 0 0.2rem;
  }
}
</style>

<style>
.nouns-subdomain .section-activity__event-icon {
  filter: invert(1);
}
</style>
