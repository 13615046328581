import type { UserProfileRequestRequestBackendSchema, UserProfileResponseBackendSchema, UserProfileSerializerAuthenticatedBackendSchema } from '@/modules/common/backend/generated'

const responseIsAuthenticated = (response: UserProfileResponseBackendSchema): response is UserProfileSerializerAuthenticatedBackendSchema => {
  return (response as UserProfileSerializerAuthenticatedBackendSchema).is_email_verified !== undefined
}

export default class UserProfile {
  public description?: string
  public discordUserId?: string
  public email?: string
  public emailVerified?: boolean

  constructor(userProfileModel: UserProfile) {
    this.description = userProfileModel.description
    this.discordUserId = userProfileModel.discordUserId
    this.email = userProfileModel.email
    this.emailVerified = userProfileModel.emailVerified
  }

  public static createFromBackendModel(notificationSettings: UserProfileResponseBackendSchema): UserProfile {
    const modelFields: UserProfile = {
      description: notificationSettings.description,
      discordUserId: notificationSettings.discord_user_id,
    }

    if (responseIsAuthenticated(notificationSettings)) {
      modelFields.email = notificationSettings.email ?? undefined
      modelFields.emailVerified = notificationSettings.is_email_verified
    }

    return new UserProfile({
      ...modelFields,
    })
  }

  public static createRequestBody(notificationSettings: UserProfile): UserProfileRequestRequestBackendSchema {
    return {
      description: notificationSettings.description,
      discord_user_id: notificationSettings.discordUserId,
      email: notificationSettings?.email,
    }
  }
}
