import { ref, computed } from 'vue'

export enum CurrentAction {
    ConfirmTransferAssetFromUser,
    TransferringAssetFromUser,
    ConfirmTokenize,
    Tokenizing,
    ConfirmTransferAtrToUser,
    TransferringAtrToUser,
}

const currentAction = ref(CurrentAction.ConfirmTransferAssetFromUser)

export default function usePwnSafeTokenizeModalTokenizingWithTransfersStep() {
  const isCloseDisabled = computed(() => [CurrentAction.TransferringAssetFromUser, CurrentAction.Tokenizing, CurrentAction.TransferringAtrToUser].includes(currentAction.value))

  return {
    currentAction,
    isCloseDisabled,
  }
}
