<template>
  <BaseSection
    class="pwn-safe-asset-table"
    :is-expandable="false"
    :heading="heading"
    :secondary-heading="secondaryHeadingAssets">
    <BaseTable
      v-model:active-sort-option="activeSortOptionLocal"
      table-max-height="450px"
      :table-min-width="tableMinWidth"
      :variant="TableVariant.AssetPage"
      :items="sortedAssets"
      :columns="tableDefinition"
      is-virtual
      :item-height="56"
      :virtual-table-overscan="3"
      :is-fetching="isFetchingAssets"
      :is-disabled="isRowDisabled"
      :is-hoverable="isRowSelectable"
      @on-row-click="emitOnAssetSelect">
      <template #checkbox="{ item: token }">
        <BaseCheckbox
          v-if="isAssetSelected !== undefined"
          :is-disabled="!token.isValid || isRowDisabled?.(token)"
          :model-value="isAssetSelected(token)"
          @click.prevent/>
      </template>

      <template #asset-info="{ item: token }">
        <AssetInfo
          :asset="token"
          :show-verification-badge="true"
          :badge-size="'small'"
          :show-link="!isRowSelectable"/>
      </template>

      <template #amount="{ item: token }">
        <div class="pwn-safe-asset-table__amount">
          <TokenizedAssetInfoTooltip
            v-if="token.hasAtrToken"
            :asset="token">
            <div class="pwn-safe-asset-table__locked-info">
              <div>
                <LockIconSvg
                  class="pwn-safe-asset-table__lock-icon"
                  alt="locked amount"/>
              </div>
              <div
                v-if="token.isTotalAmountDivisible"
                class="pwn-safe-asset-table__locked-amount-wrapper">
                (<BasePriceLabel
                  class="pwn-safe-asset-table__amount-price-label"
                  :amount="token.tokenizedAmount"
                  :is-enabled-tooltip="false"/>)
              </div>
            </div>
          </TokenizedAssetInfoTooltip>
          <BasePriceLabel
            class="pwn-safe-asset-table__amount-price-label"
            :amount="token.amountFormatted"
            :text-align="TextAlign.Right"/>
        </div>
      </template>

      <template #action-asset="{ item: token }">
        <div class="pwn-safe-asset-table__asset-action-container">
          <TransferDisabledTooltip
            :asset="token"
            :selected-pwn-safe="selectedPwnSafe!">
            <div
              :class="['pwn-safe-asset-table__asset-action', {'pwn-safe-asset-table__asset-action--disabled': token.isTransferDisabled}]"
              @click="handleTransferClick(token)">
              <TransferIconSvg
                class="pwn-safe-asset-table__asset-action-icon"
                alt="transfer"
                width="16"/>
              <SafeText
                :allow-click-through="false"
                text="Transfer"/>
            </div>
          </TransferDisabledTooltip>
          <TokenizeDisabledTooltip
            :asset="token"
            :selected-pwn-safe="selectedPwnSafe!"
            :has-tooltip="checkIsTokenizeDisabled(token)"
            :is-used-in-loan="checkIsTokenUsedInActiveLoan(token)">
            <div
              :class="['pwn-safe-asset-table__asset-action', {'pwn-safe-asset-table__asset-action--disabled': checkIsTokenizeDisabled(token)}]"
              @click="handleTokenizeClick(token)">
              <TokenizeIconSvg
                class="pwn-safe-asset-table__asset-action-icon"
                alt="tokenize"
                width="20"/>
              <SafeText
                :allow-click-through="false"
                text="Mint ATR"/>
            </div>
          </TokenizeDisabledTooltip>
        </div>
      </template>
    </BaseTable>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import BaseTable from '@/general-components/BaseTable.vue'
import AssetInfo from '@/general-components/AssetInfo.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, provide, toRefs } from 'vue'
import TableVariant from '@/general-components/TableVariant'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import BasePriceLabel, { TextAlign } from '@/general-components/BasePriceLabel.vue'
import TransferDisabledTooltip from '@/modules/pages/pwn-safe/tables/tooltips/TransferDisabledTooltip.vue'
import TokenizeDisabledTooltip from '@/modules/pages/pwn-safe/tables/tooltips/TokenizeDisabledTooltip.vue'
import TokenizedAssetInfoTooltip from '@/modules/pages/pwn-safe/tables/tooltips/TokenizedAssetInfoTooltip.vue'
import SafeText from '@/general-components/SafeText.vue'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import LockIconSvg from '@/assets/icons/lock.svg'
import TokenizeIconSvg from '@/assets/icons/tokenize.svg'
import TransferIconSvg from '@/assets/icons/transfer.svg'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { appliedFiltersKey } from '@/modules/common/injection-keys'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import usePwnSafeNfts from '@/modules/pages/pwn-safe/pwn-safe-detail/safe-assets/usePwnSafeNfts'
import { storeToRefs } from 'pinia'
import { isMobile } from '@/utils/mediaQueries'

interface Props {
  heading: string
  sortedAssets: AssetWithAmount[]
  activeSortOption?: ActiveSortOption
  isFetchingAssets?: boolean
  tableDefinition: ColumnProperties[]
  isAssetSelected?: (asset: AssetWithAmount) => boolean
  isRowDisabled?: (asset: AssetWithAmount) => boolean
  isRowSelectable: boolean
}
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)
const tableMinWidth = computed(() => (isMobile.value ? '700px' : '0px'))
const { checkIsTokenUsedInActiveLoan, checkIsTokenizeDisabled } = usePwnSafeTokenize()
const selectedChainsStore = useSelectedChainsStore()
const { searchTerm } = usePwnSafeNfts()

const props = defineProps<Props>()
const { heading, activeSortOption, tableDefinition, isRowSelectable, isRowDisabled } = toRefs(props)
// eslint-disable-next-line
const emit = defineEmits<{
  (e: 'handle-transfer', asset: AssetWithAmount): void;
  (e: 'handle-tokenize', asset: AssetWithAmount): void;
  (e: 'on-asset-select', asset: AssetWithAmount): void;
  (e: 'update:activeSortOption', newActiveSortOption: ActiveSortOption | undefined): void;
}>()

const activeSortOptionLocal = computed({
  get: () => activeSortOption.value,
  set: (value: ActiveSortOption | undefined) => emit('update:activeSortOption', value),
})

const appliedFiltersOrSorting = computed(() =>
  // we create snapshot of all applied filters to pass it down to virtual table
  // component and trigger scroll event once value is changed
  JSON.stringify({
    // ...selectedSortOption.value,
    selectedChains: selectedChainsStore.selectedChains,
    sortOption: activeSortOptionLocal.value,
    searchTerm: searchTerm.value,
  }))

provide(appliedFiltersKey, appliedFiltersOrSorting)

const secondaryHeadingAssets = computed(() => `(${props.sortedAssets.length})`)
const emitOnAssetSelect = (asset: AssetWithAmount) => {
  if (isRowDisabled.value?.(asset)) {
    return
  }
  emit('on-asset-select', asset)
}

const handleTransferClick = (asset: AssetWithAmount) => {
  if (asset.isTransferDisabled) {
    return
  }
  emit('handle-transfer', asset)
}

const handleTokenizeClick = (asset: AssetWithAmount) => {
  if (checkIsTokenizeDisabled(asset)) {
    return
  }
  emit('handle-tokenize', asset)
}
</script>

<style scoped>
.pwn-safe-asset-table {
  &__amount {
    display: flex;
    justify-content: end;
    padding-right: 1.25rem;
  }

  &__amount-price-label {
    width: fit-content;
  }

  &__asset-action-container {
    display: flex;
  }

  &__asset-action {
    display: flex;
    flex: 1 1 0;
    font-family: var(--font-family-autoscape);
    color: var(--primary-color-1);

    &:not(&--disabled):hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &--disabled {
      filter: opacity(50%);
      cursor: not-allowed;
    }
  }

  &__asset-action-icon {
    margin-right: 0.5rem;
    width: 20px;
  }

  &__locked-info {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 0.5rem;
  }

  &__lock-icon {
    margin-bottom: 0.125rem;
  }

  &__locked-amount-wrapper {
    margin-left: auto;
    color: gray;
  }

  &__asset-action-text {
    font-size: 0.875rem;
  }

  @media only screen and (--mobile-viewport) {
    overflow: auto auto;
  }
}

</style>

<style>
.pwn-safe-asset-table .table .table__head-column--amount .table__head-container {
  justify-content: end;
  margin-right: 1.25rem;
}
</style>
