<template>
  <div class="asset-amount-with-appraisal">
    <div class="asset-amount-with-appraisal__amount">
      <SafeText :text="formattedAmount">
        <template #default>
          {{ formattedAmount }}
        </template>
        <template #text>
          <SafeDisplayDecimals :value="formattedAmount"/>
        </template>
      </SafeText>
    </div>

    <AssetAppraisal
      v-if="formattedAsset && amount"
      class="asset-amount-with-appraisal__appraisal"
      :asset-amount="amount || '-'"
      :category="asset.category"
      :asset-metadata="formattedAsset"
      @asset-appraisal-updated="handleAssetAppraisalUpdated"/>
  </div>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
import { computed, toRefs } from 'vue'
import { zProposal } from '@/revamp/modules/proposals/types'
import SafeText from '@/general-components/SafeText.vue'
import SafeDisplayDecimals from '@/general-components/safe-display-decimals/SafeDisplayDecimals.vue'
import { formatDecimalPoint } from '@/utils/utils'
import type { AssetPriceLog } from '@/revamp/modules/asset-prices/types'

type Props = {
  asset: AssetWithAmount;
  amount?: string;
};

const emit = defineEmits<{(e: 'asset-appraisal-updated', log: AssetPriceLog): void }>()

const props = defineProps<Props>()
const { asset, amount } = toRefs(props)

const formattedAsset = computed(() => {
  const collateralSchema = zProposal.pick({
    collateral: true,
  })

  const parsed = collateralSchema.safeParse({
    collateral: {
      chainId: asset.value.chainId,
      address: asset.value.address,
      tokenId: asset.value.tokenId ? String(asset.value.tokenId) : null,
      thumbnailUrl: asset.value.image,
      decimals: asset.value.decimals,
    },
  })

  if (!parsed.success) {
    throw new Error('Failed to parse asset metadata')
  }

  return parsed.data.collateral
})

const formattedAmount = computed(() => {
  if (!amount.value) return ''
  return `${formatDecimalPoint(parseFloat(amount.value))} ${asset.value.isErc20 ? asset.value.symbol : ''}`
})

const handleAssetAppraisalUpdated = (log: AssetPriceLog) => {
  emit('asset-appraisal-updated', log)
}
</script>

<style scoped>
.asset-amount-with-appraisal {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;

  &__amount {
    text-align: right;
    white-space: nowrap;
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 100%;
  }

  &__appraisal {
    text-align: right;
    font-size: 0.75rem;
    color: var(--separation-grey);

    line-height: 100%;
  }
}
</style>
