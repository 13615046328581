<template>
  <div v-if="props.isEnabled">
    <div
      v-bind="containerProps"
      class="base-virtual-table-enabled"
      :style="{height: containerHeight + 'px'}"
      style="overflow-x: hidden;"
      @scroll.passive="handleLoadMoreOnScroll">
      <div v-bind="wrapperProps">
        <slot
          name="content"
          :items="itemsToRender"/>
      </div>
    </div>
  </div>
  <div v-else>
    <slot
      name="content"
      :items="itemsToRender"/>
  </div>
</template>

<script setup lang="ts">

import { useVirtualList } from '@vueuse/core'
import type { Ref } from 'vue'
import { computed, inject, ref, toRefs, watch } from 'vue'
import type { TableItem } from '@/general-components/BaseTable.vue'
import { appliedFiltersKey } from '@/modules/common/injection-keys'

type Props = {
  isEnabled: boolean
  items: TableItem[] | any[]
  maxHeight: number | null
  itemHeight: number | undefined
  overscan?: number
}

const props = withDefaults(defineProps<Props>(), {
  overscan: 10,
})

const maxHeight = computed(() => props.maxHeight ?? 600)
const emits = defineEmits<{(e: 'on-load-more'): void}>()

const {
  items,
} = toRefs(props)

const injectFallback = ref('')

const containerHeight = computed(() => {
  // @ts-expect-error FIXME: strictNullChecks
  return Math.min(items.value.length * props.itemHeight, maxHeight.value)
})

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
  items,
  {
    // @ts-expect-error FIXME: strictNullChecks
    itemHeight: props.itemHeight,
    overscan: props.overscan,
  },
)

const handleLoadMoreOnScroll = () => {
  const container = containerProps.ref.value
  // 10px is the padding, without that it did not work on some mobile devices
  // @ts-expect-error FIXME: strictNullChecks
  if (((container.scrollTop + container.clientHeight) - container.scrollHeight) > -10) {
    emits('on-load-more')
  }
}

const itemsToRender = computed(() => {
  return props.isEnabled ? list.value.map(d => d.data) : props.items
})

const filters = inject<Ref<string>>(appliedFiltersKey, injectFallback)

watch(filters, async () => {
  scrollTo(0)
})

</script>

<style>
.base-virtual-table-enabled {
  overflow: auto;
}
</style>
