<template>
  <div class="big-tab-buttons">
    <BaseButton
      v-for="tab in tabs"
      :key="tab"
      :button-text="tabHeading(tab)"
      :size="ButtonSize.XXL"
      :variant="ButtonVariant.Outline"
      :font="ButtonFont.AutoScape"
      :color="getTabColor(tab)"
      class="big-tab-buttons__button"
      is-smaller-enlarge-effect
      is-full-width
      @on-button-click="handleTabSelect(tab)">
      <template #icon>
        <slot
          name="icon"
          :tab="tab"/>
      </template>
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonFont, ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import { ref, toRefs } from 'vue'

const props = defineProps<{
  tabs: string[]
  hasAllTabsDisplayedAsActive?: boolean
  selectedTabDefault?: string
  tabsHeading?: Record<string, string>
}>()

const { tabs, hasAllTabsDisplayedAsActive, selectedTabDefault, tabsHeading } = toRefs(props)
const emit = defineEmits<{(e: 'on-tab-select', tab: string): void;}>()
const selectedTab = ref(selectedTabDefault.value || tabs.value[0])

const handleTabSelect = (tab: string) => {
  emit('on-tab-select', tab)
  selectedTab.value = tab
}

const tabHeading = (tab: string) => tabsHeading.value?.[tab] || tab

const getTabColor = (tab: string) => {
  return (selectedTab.value === tab || hasAllTabsDisplayedAsActive.value) ? ButtonColor.Primary : ButtonColor.White
}

</script>

<style scoped>
.big-tab-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;

  &__button {
    width: 100%;
  }
}
</style>
