<template>
  <div
    v-show="false"
    class="hero"
    :class="[`hero--${activeTab}`]">
    <h1
      class="hero__cta"
      @click="handleChangeActiveTab()">
      I want to
      <TransitionGroup name="glow-on-mount">
        <span
          v-if="activeTab === Tabs.Borrow"
          class="hero__cta--accent"> borrow </span>
        <span
          v-else
          class="hero__cta--accent"> lend </span>
      </TransitionGroup>
      {{ activeTab === Tabs.Borrow ? 'against' : 'with' }}
    </h1>

    <div class="hero__assets">
      <BaseSelect
        :options="toolsOptions"
        :searchable="false"
        :border-type="SelectBorderType.None"
        class="tool-select"
        track-by="trackBy"
        :is-dropdown-caret="false"
        :dropdown-caret-type="DropdownCaretType.None"
        @on-select-close="handleIsSelectOpen(false)"
        @on-select-open="handleIsSelectOpen(true)"
        @update:model-value="handleOptionSelect">
        <template #option="{ option }">
          <div class="tool-select__option">
            <component
              :is="option.icon"
              width="20"
              height="20"
              :alt="option.label"/>
            <span>
              {{ option.label }}
            </span>
          </div>
        </template>

        <template #placeholder>
          <div class="tool-select__single-label">
            <span class="tool-select__text">
              {{ assetSymbol }}
            </span>
            <CaretFilledIconSvg
              :class="['tool-select__caret-icon', { 'tool-select__caret-icon--open': isSelectOpen }]"
              alt="caret"/>
          </div>
        </template>
      </BaseSelect>
    </div>
  </div>

  <SelectTheirCollateralModal
    :with-trigger="false"
    header-text="Select Your Collateral Asset"
    @select-asset="selectCollateral"/>
</template>

<script setup lang="ts">
import BaseSelect from '@/general-components/BaseSelect.vue'
import type { OptionItem } from '@/general-components/BaseSelectProperties'
import { SelectBorderType, DropdownCaretType } from '@/general-components/BaseSelectProperties'
import { computed, onMounted, ref, toRefs, watch, watchEffect } from 'vue'
import type { Component } from 'vue'
import type RouteName from '@/router/RouteName'
import CaretFilledIconSvg from '@/assets/icons/caret-filled.svg'
import { useLocalStorage } from '@vueuse/core'
import AssetsIconSvg from '@/assets/icons/assets.svg'
import CursorIconSvg from '@/assets/icons/cursor.svg'
import DollarIconSvg from '@/assets/icons/dollar.svg'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import type { AssetWithAmount, AssetMetadata } from '@/modules/common/assets/AssetClasses'
import useSelectLentAssetModal from '@/revamp/components/modals/select-lent-asset/useSelectLentAssetModal'
import useSelectTheirCollateralModal
  from '@/revamp/components/modals/select-their-collateral/useSelectTheirCollateralModal'
import SelectTheirCollateralModal from '@/revamp/components/modals/select-their-collateral/SelectTheirCollateralModal.vue'
import { isPwnSpace, pwnSpaceConfig } from '@/modules/common/pwnSpace/pwnSpaceDetail'

type Props = {
  activeTab: Tabs
  setBorrowTab: VoidFunction
  setLendTab: VoidFunction
  selectCollateral: (asset?: AssetWithAmount | AssetMetadata) => void
  selectedCollateral: AssetWithAmount | AssetMetadata | null

  selectCredit: (asset?: AssetWithAmount | AssetMetadata) => void
  selectedCredit: AssetWithAmount | AssetMetadata | null
  hasStablesFilter?: boolean
}

const props = defineProps<Props>()
const { activeTab, selectedCollateral: collateralAsset, selectedCredit: creditAsset } = toRefs(props)

const { isOpen: isOpenSelectCollateral } = useSelectTheirCollateralModal()
const { isOpen: isOpenLendingAssetCollateral } = useSelectLentAssetModal()

const { getStablecoinsPlusWethByChainId } = useTopTokensStore()

const selectedChainsStore = useSelectedChainsStore()
const { selectedValues } = storeToRefs(selectedChainsStore)

const currentlyActiveStablecoinsPlusWeth = computed(() => {
  const result: AssetMetadata[] = []

  for (const selectedNetwork of selectedValues.value || []) {
    const tokens: AssetMetadata[] = getStablecoinsPlusWethByChainId(selectedNetwork)
    if (tokens) {
      result.push(...tokens)
    }
  }

  return result
})

const emit = defineEmits<{(e: 'assets-to-filter', { creditAssets, collateralAssets }:
    {
      creditAssets: Array<AssetMetadata | AssetWithAmount>,
      collateralAssets: Array<AssetMetadata | AssetWithAmount>
    }
  )
}>()

enum Tabs {
  Borrow = 'borrow',
  Lend = 'lend',
  Discovery = 'Discovery',
}

enum Options {
  AllAssets = 'all-assets',
  SelectAsset = 'select-asset',
  StablesEth = 'stables-eth',
}

const activeOption = useLocalStorage<Options>('selected-option-homepage', Options.AllAssets)

interface ToolOption extends OptionItem<RouteName, Options> {
  icon: Component;
}

const isSelectOpen = ref()

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

const toolsOptions = computed<ToolOption[]>(() => [
  {
    trackBy: Options.AllAssets,
    label: isPwnSpace && activeTab.value === Tabs.Borrow ? `${capitalize((pwnSpaceConfig?.pwnSpaceName || 'all'))} assets` : 'All assets',
    icon: AssetsIconSvg,
  },
  {
    trackBy: Options.SelectAsset,
    label: 'Select Asset',
    icon: CursorIconSvg,
  },
  {
    trackBy: Options.StablesEth,
    label: 'Stables / ETH',
    icon: DollarIconSvg,
  },
])

const getActiveOptionLabel = () => {
  return toolsOptions.value.find((option) => option.trackBy === activeOption.value)?.label
}

const handleIsSelectOpen = (isOpen: boolean) => {
  isSelectOpen.value = isOpen
}

const handleChangeActiveTab = () => {
  if (activeTab.value === Tabs.Borrow) {
    props.setLendTab()
  } else {
    props.setBorrowTab()
  }
}

const handleSelectAllAssets = () => {
  activeOption.value = Options.AllAssets
  emit('assets-to-filter', { creditAssets: [], collateralAssets: [] })

  props.selectCredit()
  props.selectCollateral()
}

const handleSelectStables = () => {
  activeOption.value = Options.StablesEth
  if (activeTab.value === Tabs.Lend) {
    emit('assets-to-filter', { creditAssets: currentlyActiveStablecoinsPlusWeth.value, collateralAssets: [] })
  } else {
    emit('assets-to-filter', { creditAssets: [], collateralAssets: currentlyActiveStablecoinsPlusWeth.value })
  }
}

const isCollateralToBeBundled = computed(() => collateralAsset.value?.address === '0x0000000000000000000000000000000000000000' && collateralAsset.value?.name === 'PWN Bundle')

watchEffect(() => {
  if (activeTab.value === Tabs.Borrow) {
    if (collateralAsset.value && !isCollateralToBeBundled.value) {
      activeOption.value = Options.SelectAsset
    }
  } else if (activeTab.value === Tabs.Lend) {
    if (creditAsset.value) {
      activeOption.value = Options.SelectAsset
    }
  }
})

watch(() => props.hasStablesFilter, (newVal) => {
  if (newVal) {
    activeOption.value = Options.StablesEth
  } else if (!newVal && !collateralAsset.value && !creditAsset.value) {
    activeOption.value = Options.AllAssets
  } else {
    activeOption.value = Options.SelectAsset
  }
}, { immediate: true })

onMounted(() => {
  if (activeOption.value === Options.StablesEth) {
    handleSelectStables()
  } else {
    activeOption.value = Options.AllAssets
    emit('assets-to-filter', { creditAssets: [], collateralAssets: [] })
  }
})

const optionHandlers = {
  [Options.AllAssets]: () => {
    handleSelectAllAssets()
  },
  [Options.SelectAsset]: () => {
    if (activeTab.value === Tabs.Borrow) {
      isOpenSelectCollateral.value = true
    } else {
      isOpenLendingAssetCollateral.value = true
    }
  },
  [Options.StablesEth]: handleSelectStables,
}

const handleOptionSelect = (selectedOption: ToolOption) => {
  activeOption.value = selectedOption.trackBy!

  if (selectedOption.trackBy) {
    optionHandlers[selectedOption.trackBy]()
  }
}

const assetSymbol = computed(() => {
  if (activeTab.value === Tabs.Borrow && collateralAsset.value && !isCollateralToBeBundled.value) {
    if (collateralAsset.value?.symbol && collateralAsset.value?.symbol !== 'Missing symbol') {
      return collateralAsset.value?.symbol
    }

    if (collateralAsset.value?.name) {
      return collateralAsset.value?.name
    }

    return collateralAsset.value?.symbol
  }

  if (activeTab.value === Tabs.Lend && creditAsset.value) {
    if (creditAsset.value?.symbol && creditAsset.value?.symbol !== 'Missing symbol') {
      return creditAsset.value?.symbol
    }

    if (creditAsset.value?.name) {
      return creditAsset.value?.name
    }

    return creditAsset.value?.symbol
  }

  if (activeOption.value !== Options.SelectAsset) {
    return getActiveOptionLabel()?.toLowerCase()
  }

  return getActiveOptionLabel()?.toLowerCase()
})

watch(assetSymbol, () => {
  if (activeOption.value === Options.SelectAsset && assetSymbol.value === 'select asset') {
    activeOption.value = Options.AllAssets
  }
})

</script>

<style scoped>
.glow-on-mount,
.glow-on-mount-move {
  &-enter-active,
  &-leave-active {
    transition: text-shadow 0.5s,
      opacity 0.5s,
      transform 0.5s;
  }

  &-enter,
  &-leave-to {
    text-shadow: 0 0 8px var(--primary-color-1);
    transform: translateY(-2rem);
  }

  &-leave-active {
    position: absolute;
    opacity: 0;
    right: 0;
    left: 155px;
  }
}

.hero {
  /*
  parent styles
   */
  align-self: center;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-size: 1.875rem;
  margin: 4rem 0;
  gap: 0.5rem;

  transition: width 1s ease-in-out;

  --width-one: 680px;
  --width-two: calc(var(--width-one) - 60px);

  @media only screen and (--mobile-viewport) {
    flex-flow: column nowrap;
  }

  @keyframes width-transition {
    0% {
      width: var(--width-one);
    }

    50% {
      min-width: var(--width-two);
    }
  }

  &--borrow {
    animation: width-transition 1s ease-in-out;
  }

  &--lend {
    width: 650px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .glow-on-mount,
    .glow-on-mount-move {
      &-leave-active {
        left: 125px;
      }
    }
  }

  &__cta {
    font-family: var(--font-family-screener);

    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;

    cursor: pointer;

    position: relative;

    color: var(--text-color);
    font-size: 1.875rem;
    font-weight: 400;

    @media only screen and (--mobile-viewport) {
      font-size: 1.5rem !important;
    }

    &--accent {
      color: var(--primary-color-1);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.tool-select {
  &__option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__single-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.875rem;
    white-space: nowrap;
    color: var(--primary-color-1);

    font-family: var(--font-family-screener);
    font-weight: 400;

    @media only screen and (--mobile-viewport) {
      font-size: 1.5rem !important;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__caret-icon {
    margin-right: 0.5rem;
    transition: transform var(--primary-transition-time);

    fill: var(--primary-color-1);

    &--open {
      transform: rotate(180deg);
      fill: var(--primary-color-1);
    }
  }
}
</style>

<style lang="postcss">
.hero .multiselect__tags {
  padding-right: 0;
  padding-left: 0;
}
</style>
