<template>
  <BaseSection
    heading="Notification Center"
    class="notification-center"
    :is-expandable="false">
    <div class="notification-center__subheading">
      <div class="notification-center__search-n-button">
        <BaseSearch
          v-model="searchTerm"
          :class="['notification-center__search', { 'notificenter__tablecation-center__search--selected': searchTerm }]"
          search-placeholder="Search asset name/address or wallet address/ENS"
          class="notification-center__search"/>
        <BaseButton
          class="notification-center__button-mark-as-seen-mobile"
          :color="ButtonColor.PrimaryDark"
          :variant="ButtonVariant.Outline"
          @on-button-click="markAllUnseenNotificationsAsSeen">
          <template #icon>
            <MarkAsSeenIconSvg alt="mark as seen"/>
          </template>
        </BaseButton>
      </div>
      <div class="notification-center__buttons-container">
        <DiscordNotificationModal/>
        <PushNotificationModal/>
        <EmailNotificationModal/>
        <BaseButton
          class="notification-center__button"
          :color="ButtonColor.PrimaryDark"
          :variant="ButtonVariant.Outline"
          is-full-width
          button-text="Notification Settings"
          is-icon-before-text
          @on-button-click="routeToNotificationSettings">
          <template #icon>
            <BellPlusIconSvg alt="bell icon"/>
          </template>
        </BaseButton>
        <BaseButton
          class="notification-center__button notification-center__button-mark-as-seen"
          :color="ButtonColor.PrimaryDark"
          :variant="ButtonVariant.Outline"
          @on-button-click="markAllUnseenNotificationsAsSeen">
          <template #icon>
            <MarkAsSeenIconSvg alt="mark as seen"/>
          </template>
        </BaseButton>
      </div>
    </div>
    <NotificationCenterTable class="notification-center__table"/>
    <NotificationCenterGrid class="notification-center__grid"/>
    <BaseEmptyStateText
      v-if="hasEmptyState"
      text="You don't have any notifications"/>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import useNotificationCenter from '@/modules/pages/notification-center/useNotificationCenter'
import NotificationCenterGrid from '@/modules/pages/notification-center/NotificationCenterGrid.vue'
import NotificationCenterTable from '@/modules/pages/notification-center/NotificationCenterTable.vue'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import { computed, watch } from 'vue'
import useNotificationsFetch from '@/modules/common/notifications/useNotificationsFetch'
import BaseButton, { ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import useNotifications from '@/modules/common/notifications/useNotifications'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import PushNotificationModal from '@/modules/pages/notification-center/settings/modals/PushNotificationModal.vue'
import DiscordNotificationModal from '@/modules/pages/notification-center/settings/modals/DiscordNotificationModal.vue'
import BellPlusIconSvg from '@/assets/icons/bell-plus.svg'
import MarkAsSeenIconSvg from '@/assets/icons/mark-as-seen.svg'
import EmailNotificationModal from '@/modules/pages/notification-center/settings/modals/EmailNotificationModal.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { isFetchingNotifications, loadAllUserNotifications, isAllNotificationsFetched } = useNotificationsFetch()
const { searchTerm, sortedNotifications } = useNotificationCenter()
const { markAllUnseenNotificationsAsSeen } = useNotifications()
const router = useRouter()
const { address: userAddress } = useCustomAccount()

const hasEmptyState = computed(() => !isFetchingNotifications.value && !sortedNotifications.value?.length)
const routeToNotificationSettings = () => router.push(RouteName.NotificationCenterSettings)

watch(userAddress, async (newUserAddress, oldUserAddress) => {
  if (!newUserAddress || isAllNotificationsFetched.value) {
    return
  }

  await loadAllUserNotifications()
}, { immediate: true })
</script>

<style scoped>
.notification-center {
  margin: 3rem 0 5rem;

  &__search {
    margin-bottom: 2.5rem;
    font-size: 0.75rem;
    border: 1px solid var(--gray);
    padding: 0.25rem;
    min-width: 23rem;
    height: 2.5rem;
    width: 27rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__search-n-button {
    display: flex;
    gap: 0.5rem;
  }

  &__subheading {
    display: flex;
    justify-content: space-between;
  }

  &__buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &__button {
    &:last-child {
      margin-right: 0;
    }
  }

  &__table {
    overflow: auto;
  }

  &__grid {
    display: none;
  }

  &__discord-n-push {
    display: flex;
    gap: 0.5rem;
  }

  &__button-mark-as-seen {
    display: block;
  }

  &__button-mark-as-seen-mobile {
    display: none;
  }

  @media only screen and (--small-viewport) {
    &__subheading {
      flex-direction: column;
      margin-bottom: 2.5rem;
    }

    &__search {
      margin-bottom: 1rem;
      min-width: 0;
      max-width: 27rem;
      width: 100%;
    }

    &__button {
      font-size: small;
    }

    &__table {
      display: none;
    }

    &__grid {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  @media only screen and (--mobile-viewport) {
    margin-top: 0.5rem;

    &__discord-n-push {
      width: 100%;
    }

    &__search {
      max-width: 100%;
    }

    &__button {
      width: 100%;
    }

    &__button-mark-as-seen {
      display: none;
    }

    &__button-mark-as-seen-mobile {
      display: block;
    }
  }
}
</style>
