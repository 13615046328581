<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    :variant="ModalVariant.Error"
    :custom-z-index="210"
    heading="Multi-sig Wallet Detected!">
    <template #body>
      <p class="multisig-wallet-login-modal__text">
        Please do not exit PWN and Safe{Wallet} apps to prevent cancelling the signature/transaction.
      </p>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import ModalVariant from '@/general-components/ModalVariant'
import ModalSize from '@/general-components/ModalSize'
import { useMultisigWalletModalStore } from '@/general-components/multisig-wallet/useMultisigWalletSignModalStore'
import { storeToRefs } from 'pinia'

const multisigWalletSignStore = useMultisigWalletModalStore()
const { isOpen } = storeToRefs(multisigWalletSignStore)
</script>

<style scoped>
.multisig-wallet-login-modal {
  &__text {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
