<template>
  <div class="pwn-matcher-page">
    <MatcherBox
      class="pwn-matcher-page__box"
      type="lending"
      :is-loading="isPending"
      :apr="formattedAPR"
      :collateral-symbol="strategy.collateralAssets?.[0]?.symbol">
      <template #widgets>
        <MatcherProperty
          label="APR">
          {{ formattedAPR }}
        </MatcherProperty>

        <MatcherProperty
          label="Duration">
          {{ formattedDuration }}
        </MatcherProperty>

        <MatcherProperty
          label="Chains">
          StarkNet
        </MatcherProperty>
      </template>

      <CommitButton
        text="Lend and earn fixed interest"
        :is-loading="isPending"
        @click="handleOpenLendingModal">
        <template #rewards>
          <RewardsForThesis
            :assets-for-rewards="rewardsAssets"
            :apr="formattedAPR"
            is-with-text/>
        </template>

        <template #icons>
          <div
            v-for="(asset, index) in parsedCreditAssets"
            :key="asset.id"
            :style="{
              position: 'relative',
              zIndex: index + 1,
              marginLeft: index !== 0 ? '-0.5rem' : '0'
            }">
            <TokenMedia
              :token="asset"
              class="thesis-committed__commit-icon"/>
          </div>
        </template>
      </CommitButton>
    </MatcherBox>

    <MatcherBox
      class="pwn-matcher-page__box"
      type="borrowing"
      :is-loading="isPending"
      :apr="formattedAPR"
      :collateral-symbol="strategy.collateralAssets?.[0]?.symbol">
      <template #widgets>
        <MatcherProperty
          label="APR">
          {{ formattedAPR }}
        </MatcherProperty>

        <MatcherProperty
          label="Duration">
          {{ formattedDuration }}
        </MatcherProperty>

        <MatcherProperty
          label="Chains">
          StarkNet
        </MatcherProperty>
      </template>
    </MatcherBox>

    <ThesisSelectCommitedAsset
      :thesis="thesis"
      :error-text="''"
      :on-confirm="values => handleContinueWithSelected(values, thesis)"
      :on-close="() => {lendingModalIsVisible = false}"
      :is-submitting="false"
      :selected-assets="parsedCreditAssets"
      :stats-data="{}"
      :is-visible="lendingModalIsVisible"/>
  </div>
</template>

<script lang="ts" setup>
import MatcherBox from '@/revamp/features/matcher/MatcherBox.vue'
import MatcherProperty from '@/revamp/features/matcher/MatcherProperty.vue'
import CommitButton from '@/revamp/features/matcher/CommitButton.vue'
import RewardsForThesis from '@/revamp/features/points/RewardsForThesis.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import ThesisSelectCommitedAsset from '@/revamp/components/modals/thesis-select-commited-asset/ThesisSelectCommitedAsset.vue'
import { useGetThesis } from '@/revamp/hooks/thesis/useGetThesis'
import { singularOrPlural, compareAddresses } from '@/utils/utils'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { parseThesisAsset, processThesisCreditStats } from '@/revamp/hooks/thesis/utils'
import { createDepositedAssets } from '@/constants/depositedAssets'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useThesisDetail } from '@/modules/common/backend/generated'
import type { ThesisDetailParams, ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { usePopulateThesis } from '@/revamp/hooks/thesis/usePopulateThesis'
import type { AssetWithExtraThesisParams } from '@/revamp/hooks/thesis/types'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { parseAddress } from '@/utils/address-utils'
import type { SupportedChain } from '@/constants/chains/types'

const route = useRoute()
const thesisId = route.params.id as string

const { thesis, isPending } = useGetThesis(thesisId)
const reactiveThesisId = computed(() => thesis.value.id)

const { address } = useCustomAccount()

const userStatsParams = computed<ThesisDetailParams>(() => {
  return {
    user_address: address.value,
  }
})

const userThesisQueryIsEnabled = computed(() => {
  if (!address.value) return false

  return parseAddress(address.value) && !!reactiveThesisId.value
})

const thesisDetail = useThesisDetail(reactiveThesisId, userStatsParams, {
  query: {
    enabled: userThesisQueryIsEnabled,
  },
})

const lendingModalIsVisible = ref(false)

const strategy = computed(() => thesis.value)

const formattedAPR = computed(() => {
  if (!strategy.value.collateralAssets || strategy.value.collateralAssets.length === 0) {
    return '-'
  }
  return `${strategy.value.collateralAssets[0].apr}%`
})

const formattedDuration = computed(() => {
  return `${strategy.value.loanDurationDays} ${singularOrPlural(strategy.value.loanDurationDays, 'Day')}`
})

const parsedCollateralAssets = computed(() =>
  (strategy.value.collateralAssets || []).map((v) => {
    const parsedAsset = parseThesisAsset(v)
    return {
      ...parsedAsset,
      allocationPercentage: v.allocationPercentage,
    } as AssetWithExtraThesisParams
  }),
)

const parsedCreditAssets = computed(() => {
  const parsedThesisAssets = strategy.value.creditsStats?.map((v) => parseThesisAsset(v.creditAssetMetadata))
  if (!parsedThesisAssets) return []

  const { transformedDepositedAssets, assetsToFilter } = createDepositedAssets(parsedThesisAssets, parsedThesisAssets)
  const filteredParsedThesisAssets = parsedThesisAssets.filter((v) => { return !assetsToFilter.some((asset) => compareAddresses(asset.address, v.address)) })
  return [...(filteredParsedThesisAssets || []), ...(transformedDepositedAssets || [])]
})

const rewardsAssets = computed(() => {
  const allAssets = [...(parsedCollateralAssets.value || []), ...(parsedCreditAssets.value || [])]
  return allAssets.map((v) => {
    return {
      chainId: v.chainId,
      address: v.address,
    }
  })
})

const userThesisCreditStats = computed(() => {
  return thesisDetail.data.value?.data.creditsStats
})

const userThesisStatsData = computed(() => {
  return processThesisCreditStats(userThesisCreditStats.value)
})

const noncesToRevoke = computed(() => {
  const result: Partial<Record<SupportedChain, bigint[]>> = {}

  const stats = Object.values(userThesisStatsData.value)

  stats.forEach((v) => {
    const chainId = v.creditAssetMetadata.chainId
    if (!result[chainId]) {
      result[chainId] = []
    }

    if (v.proposalNonces) {
      result[chainId]!.push(...v.proposalNonces.map(BigInt))
    }
  })

  return result
})

const handleOpenLendingModal = () => {
  lendingModalIsVisible.value = true
}

const { getProposalsToCreate, populateThesisMutation } = usePopulateThesis(
  parsedCollateralAssets, parsedCreditAssets, thesis,
  () => {
    lendingModalIsVisible.value = false
  },
)

const handleContinueWithSelected = (values: AssetWithAmount[], thesis: ThesisSchemaWorkaroundBackendSchema) => {
  const proposals = getProposalsToCreate(values, thesis)
  populateThesisMutation.mutate(
    {
      values: proposals,
      previousNonces: noncesToRevoke.value,
    },
    {
      onSuccess: () => {
        thesisDetail.refetch()
      },
    },
  )
}

</script>

<style scoped>
.pwn-matcher-page {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;

  padding-top: 3rem;

  &__box {
    max-width: 33.25rem;
    width: 100%;
  }
}
</style>
