<template>
  <Line
    v-if="hasSaleEvents && !isLoadingPriceHistory"
    :chart-options="chartOptions"
    :chart-data="chartData"
    chart-id="line-chart"
    dataset-id-key="label"
    css-classes="price-chart"
    :width="400"
    :height="250"/>
  <div
    v-if="!hasSaleEvents && !isLoadingPriceHistory"
    class="price-chart__no-data">
    {{ priceHistoryMessageIfNoData }}
  </div>
  <div
    v-if="isLoadingPriceHistory"
    class="price-chart__no-data">
    <div class="price-chart__loader-wrap">
      <BaseSkeletor/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Line } from 'vue-chartjs'
import { onMounted, onUpdated, ref, computed } from 'vue'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  BarController,
  LinearScale,
  LineController,
  PointElement,
  CategoryScale,
  Filler,
} from 'chart.js'
import DateUtils from '@/utils/DateUtils'
import useAssetPriceHistory, { CurrencyType } from '@/modules/pages/asset/nft-page/SectionPriceHistory/useAssetPriceHistory'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import AssetType from '@/modules/common/assets/AssetType'
import useSectionNFTEvents from '@/modules/pages/asset/nft-page/SectionNFTEvents/useSectionNFTEvents'
import type { TChartData, TChartOptions } from 'vue-chartjs/dist/types'

const { asset } = useAssetPage()
const gradient = ref()

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  Filler,
  LineElement,
  LineController,
  BarController,
  BarElement,
  LinearScale,
  PointElement,
  CategoryScale,
)

const { priceHistoryPrimaryCurrency, isLoadingPriceHistory, hasCheckedIncludeBatchSales, priceHistoryMessageIfNoData } = useAssetPriceHistory()
const { saleEvents, hasSaleEvents } = useSectionNFTEvents()

const datesOfSalesXAsis = computed(() => saleEvents.value.reduce((acc, cur) => {
  if (cur.quantity > 1 && !hasCheckedIncludeBatchSales.value) return acc
  // @ts-expect-error FIXME: strictNullChecks
  acc.push(cur.date)
  return acc
}, []))

const batchSales = computed(() => saleEvents.value.reduce((acc, cur) => {
  // @ts-expect-error FIXME: strictNullChecks
  acc.push(cur.quantity)
  return acc
}, []))

const pricesOfSalesYAsis = computed(() => saleEvents.value.reduce((acc, cur) => {
  const price = priceHistoryPrimaryCurrency.value === CurrencyType.USD ? cur.saleUsdPrice : cur.token?.amountFormatted

  if (cur.quantity > 1 && !hasCheckedIncludeBatchSales.value) return acc
  // @ts-expect-error FIXME: strictNullChecks
  acc.push(price)
  return acc
}, []))

const setupBgGradient = () => {
  const ctx = (document.getElementById('line-chart') as HTMLCanvasElement)?.getContext('2d')

  if (!ctx || gradient.value) return

  gradient.value = ctx.createLinearGradient(0, 0, 0, 290)
  gradient.value?.addColorStop(0, 'rgba(0, 255, 224, 0.2)')
  gradient.value?.addColorStop(1, 'transparent')
}

onMounted(() => {
  setupBgGradient()
})

onUpdated(() => {
  setupBgGradient()
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const chartData = computed<TChartData<any, any, any>>(() => ({
  labels: datesOfSalesXAsis.value,
  color: '#00ffe0',
  datasets: [
    {
      type: 'line',
      data: pricesOfSalesYAsis.value,
      borderColor: '#00ffe0',
      borderWidth: 1,
      pointBackgroundColor: 'black',
      fill: true,
      backgroundColor: gradient.value,
      yAxisID: 'y',
    },
    {
      type: 'bar',
      data: hasCheckedIncludeBatchSales.value ? batchSales.value : [],
      borderColor: '#00ffe0',
      backgroundColor: '#00FFE080',
      borderWidth: 1,
      barThickness: 5,
      fill: false,
      yAxisID: 'y1',
      xAxisID: 'x1',
    },
  ],
}))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const chartOptions: TChartOptions<any> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        title: function(tooltipItem) {
          const timeString = tooltipItem[0].chart.data.labels[tooltipItem[0].dataIndex]
          const time = DateUtils.displayDate(new Date(timeString), {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })

          function round(value: number, precision: number) {
            const multiplier = Math.pow(10, precision || 0)
            return Math.round(value * multiplier) / multiplier
          }

          const currencyVolume = tooltipItem[0].chart.data.datasets[0].data[tooltipItem[0].dataIndex]
          const currencySymbol = priceHistoryPrimaryCurrency.value === CurrencyType?.USD ? '$' : priceHistoryPrimaryCurrency.value

          const currency = `${currencySymbol === '$' ? currencySymbol : ''}${currencySymbol === '$' ? round(+currencyVolume, 2) : currencyVolume} ${currencySymbol !== '$' ? currencySymbol + ' ' : ''}`

          const nftsQuantityRaw = tooltipItem[0].chart.data.datasets[1].data[tooltipItem[0].dataIndex]
          const nftsQuantityFormatted = nftsQuantityRaw > 1 ? `for ${nftsQuantityRaw} NFTs` : 'for 1 NFT'
          // @ts-expect-error FIXME: strictNullChecks
          const nftsQuantity = ` ${(hasCheckedIncludeBatchSales.value && asset.value.category === AssetType.ERC1155) ? nftsQuantityFormatted : ''}`

          return `${currency}${nftsQuantity}\n${time}`
        },
        label: function() {
          return ''
        },
      },
      titleFont: { family: '"AutoScape", "sans-serif"', weight: 'normal' },
      titleAlign: 'center',
      backgroundColor: '#111',
      cornerRadius: 1,
      borderColor: 'rgba(0, 255, 224, 1)',
      borderWidth: 1,
      padding: 10,
      caretSize: 0,
      caretPadding: 10,
      titleMarginBottom: 0,
    },

    legend: { display: false },
  },
  layout: {
    padding: {
      right: 37,
      top: 0,
      bottom: 0,
      left: 0,
    },
  },
  scales: {
    x: {
      ticks: {
        color: 'white',
        padding: 7,
        maxTicksLimit: 6,
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0,
        font: { family: '"Oxygen Mono", "monospace"' },
        z: 10,
        callback: function(value) {
          return DateUtils.displayDate(new Date(datesOfSalesXAsis.value[value]), {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        },
      },
      grid: {
        borderColor: 'white',
        color: 'transparent',
        tickColor: 'white',
      },
    },
    x1: {
      display: false,
      offset: false,
      grid: {
        offset: false,
      },
    },
    y: {
      ticks: {
        beginAtZero: false,
        color: 'white',
        padding: 7,
        maxTicksLimit: 8.1,
        font: { family: '"Oxygen Mono", "monospace"' },
      },
      grid: {
        borderColor: 'white',
        tickColor: 'white',
        color: '#222',
      },
      afterBuildTicks: function(chart) {
        // remove tick with label "0"
        chart.ticks.shift()
      },
      position: 'right',
    },
    y1: {
      display: false,
      min: 1,
      suggestedMax: 30,
      maxTicksLimit: 8.1,
    },

  },
}

</script>

<style scoped>
.price-chart {
  border-top: 1px solid white;
  position: relative;
  z-index: 1;

  &__no-data {
    border-top: 1px solid white;
    font-size: 1.2rem;
    height: 250px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  &__loader-wrap {
    width: 90%;
  }

  &__additional-line {
    height: 1px;
    width: 100%;
    background-color: white;
    margin-top: -1px;
  }
}
</style>
