import { computed, ref, watch } from 'vue'
import PwnSafeTokenizeModalAssetDetailStep from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/PwnSafeTokenizeModalAssetDetailStep.vue'
import PwnSafeTokenizeModalSelectTargetSafeStep from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/PwnSafeTokenizeModalSelectTargetSafeStep.vue'
import PwnSafeTokenizeModalOnlyTokenizingStep from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/PwnSafeTokenizeModalOnlyTokenizingStep.vue'
import PwnSafeTokenizeModalTokenizingWithTransfersStep from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/PwnSafeTokenizeModalTokenizingWithTransfersStep.vue'
import PwnSafeTokenizeModalConfirmationStep from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/PwnSafeTokenizeModalConfirmationStep.vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { useSafesList } from '@/modules/queryHooks/safe/useSafesList'
import usePwnSafeTokenizeModalTokenizingWithTransfersStep
  from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModalTokenizingWithTransfersStep'
import usePwnSafeTokenizeModalOnlyTokenizingStep
  from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModalOnlyTokenizingStep'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { storeToRefs } from 'pinia'

const isOpen = ref(false)

export enum PwnSafeTokenizeModalStep {
  AssetDetail,
  SelectTargetSafe,
  TokenizingWithTransfers, // when tokenizing from current wallet
  OnlyTokenizing, // when tokenizing from pwn safe
  Confirmation,
}
const activeStep = ref(PwnSafeTokenizeModalStep.AssetDetail)

const activeStepComponent = computed(() => {
  switch (activeStep.value) {
  // select asset
  case PwnSafeTokenizeModalStep.AssetDetail:
    return PwnSafeTokenizeModalAssetDetailStep
    //
  case PwnSafeTokenizeModalStep.OnlyTokenizing:
    return PwnSafeTokenizeModalOnlyTokenizingStep
  // When minting from current wallet, we need to select the safe to mint to
  case PwnSafeTokenizeModalStep.SelectTargetSafe:
    return PwnSafeTokenizeModalSelectTargetSafeStep
  // Actual step when transfer is executed
  case PwnSafeTokenizeModalStep.TokenizingWithTransfers:
    return PwnSafeTokenizeModalTokenizingWithTransfersStep
  case PwnSafeTokenizeModalStep.Confirmation:
    return PwnSafeTokenizeModalConfirmationStep
  default:
    // todo probably throw some error + logging here?
    throw new Error(`Unknown active step for tokenize modal: ${activeStep.value}`)
  }
})

export default function usePwnSafeTokenizeModal() {
  const { isWaitingOnTxConfirmation } = usePwnSafeTokenizeModalOnlyTokenizingStep()
  const { isCloseDisabled } = usePwnSafeTokenizeModalTokenizingWithTransfersStep()
  const pwnSafeDetailStore = usePwnSafeDetailStore()
  const { selectedPwnSafe, walletAsSafe, isSelectedCurrentWallet } = storeToRefs(pwnSafeDetailStore)

  const { fromWallet, toWallet, assetsToTransfer } = usePwnSafeTransfer()
  const { pwnSafeWhereTokenizing, selectedAssetsToTokenize, successfullyTokenizedAssets } = usePwnSafeTokenize()
  const { address: userAddress } = useCustomAccount()

  // @ts-expect-error TS(2345) FIXME: Argument of type 'SupportedChain | null | undefine... Remove this comment to see the full error message
  const safesList = useSafesList(userAddress, selectedPwnSafe.value?.chainId)

  const hasMoreThanOneSafe = computed(() => safesList.safes.value.length > 1)
  const isModalCloseDisabled = computed(() => isWaitingOnTxConfirmation.value || isCloseDisabled.value)

  const resetModalState = () => {
    activeStep.value = PwnSafeTokenizeModalStep.AssetDetail
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'PwnSafe | u... Remove this comment to see the full error message
    fromWallet.value = null
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'PwnSafe | u... Remove this comment to see the full error message
    toWallet.value = null
    assetsToTransfer.value = []
    selectedAssetsToTokenize.value = []
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'PwnSafe | u... Remove this comment to see the full error message
    pwnSafeWhereTokenizing.value = null
    successfullyTokenizedAssets.value = []
  }

  watch(isOpen, (newIsOpen, oldIsOpen) => {
    if (!newIsOpen) {
      resetModalState()
    }
  })

  const closeModal = () => {
    isOpen.value = false
  }

  const handleConfirmSelectedAssetsToTokenize = (transferTo: PwnSafe) => {
    if (useConnectedAccountTypeStore().isConnectedPwnSafe || !isSelectedCurrentWallet.value) {
      pwnSafeWhereTokenizing.value = selectedPwnSafe.value
      activeStep.value = PwnSafeTokenizeModalStep.OnlyTokenizing
    } else if (hasMoreThanOneSafe.value) {
      activeStep.value = PwnSafeTokenizeModalStep.SelectTargetSafe
    } else {
      fromWallet.value = walletAsSafe.value
      pwnSafeWhereTokenizing.value = transferTo
      toWallet.value = transferTo
      assetsToTransfer.value = selectedAssetsToTokenize.value
      activeStep.value = PwnSafeTokenizeModalStep.TokenizingWithTransfers
    }
  }

  return {
    isOpen,
    closeModal,
    activeStep,
    activeStepComponent,
    handleConfirmSelectedAssetsToTokenize,
    isModalCloseDisabled,
    resetModalState,
  }
}
