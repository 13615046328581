import { AmountInEthAndUsd } from './typings/prices'
import { compareAddresses } from '@/utils/utils'
import NFTPriceStats from './typings/NFTPriceStats'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import CollectionStats from './typings/CollectionStats'
import TokenPriceStats from './typings/TokenPriceStats'
import SEPOLIA_CONSTANTS from '@/constants/chains/sepolia'
import { AssetPrice, NFTAppraisal } from './typings/AssetPriceClasses'
import type AssetType from './AssetType'
import { NFT_CATEGORIES } from './AssetType'
import { getAddress } from 'viem'
import type { Address } from 'viem'
import STARKNET_SEPOLIA_CONSTANTS from '@/constants/chains/starknetSepolia'

export const TESTNET_ETH_USD_PRICE = 2000

// todo: move to utils, for some reason the tests are failing when importing this function from utils
function generateRandomNumber(lowerBound: number | string, upperBound: number | string, returnInteger: boolean = false): number {
  lowerBound = Number(lowerBound)
  upperBound = Number(upperBound)
  // Ensure the bounds are in the correct order
  const min = Math.min(lowerBound, upperBound)
  const max = Math.max(lowerBound, upperBound)
  // Generate a random number between 0 (inclusive) and 1 (exclusive)
  const randomNumber = Math.random()
  // Scale the random number to fit the specified range
  const scaledNumber = min + randomNumber * (max - min)
  if (returnInteger) {
    return Math.round(scaledNumber)
  }
  return scaledNumber
}

const createAmountInEthAndUsdFromEthAmount = (ethAmount: string | number): AmountInEthAndUsd => {
  return new AmountInEthAndUsd(
    String(ethAmount),
    String(Number(ethAmount) * TESTNET_ETH_USD_PRICE),
  )
}

const createAmountInEthAndUsdFromUsdAmount = (usdAmount: string | number): AmountInEthAndUsd => {
  return new AmountInEthAndUsd(
    String(Number(usdAmount) / TESTNET_ETH_USD_PRICE),
    String(usdAmount),
  )
}

export const MIN_ERC20_USD_PRICE = 0.0004
export const MAX_ERC20_USD_PRICE = 2000

export const MIN_NFT_ETH_PRICE = 0.00005
export const MAX_NFT_ETH_PRICE = 2.420

// for having unified price on testing PWN Faucet ERC20 PWND
export const PWN_FAUCET_PWND_SEPOLIA_ADDRESS = SEPOLIA_CONSTANTS.topTokens.pwnd!
export const PWN_FAUCET_PWND_PRICE = createAmountInEthAndUsdFromUsdAmount(generateRandomNumber(MIN_ERC20_USD_PRICE, MAX_ERC20_USD_PRICE))

// for having unified price on testing PWN Faucet ERC20 PWNS ("stablecoin")
export const PWN_FAUCET_PWNS_SEPOLIA_ADDRESS = SEPOLIA_CONSTANTS.topTokens.pwns!
export const PWN_FAUCET_PWNS_PRICE = createAmountInEthAndUsdFromUsdAmount(generateRandomNumber(0.99, 1.01))

// for having unified price on testing WETH
export const WETH_SEPOLIA_ADDRESS = SEPOLIA_CONSTANTS.topTokens.weth!

export const WETH_SEPOLIA_PRICE = createAmountInEthAndUsdFromUsdAmount(generateRandomNumber(3600, 3800))

export const DAI_SEPOLIA_ADDRESS = SEPOLIA_CONSTANTS.topTokens.dai!
export const DAI_SEPOLIA_PRICE = createAmountInEthAndUsdFromUsdAmount(1)

export const USDT_SEPOLIA_ADDRESS = SEPOLIA_CONSTANTS.topTokens.usdt!
export const USDT_SEPOLIA_PRICE = createAmountInEthAndUsdFromUsdAmount(1)

export const USDT_STARKNET_SEPOLIA_ADDRESS = STARKNET_SEPOLIA_CONSTANTS.topTokens.usdt!
export const USDT_STARKNET_SEPOLIA_PRICE = createAmountInEthAndUsdFromUsdAmount(1)

export const USDC_STARKNET_SEPOLIA_ADDRESS = STARKNET_SEPOLIA_CONSTANTS.topTokens.usdc!
export const USDC_STARKNET_SEPOLIA_PRICE = createAmountInEthAndUsdFromUsdAmount(1)

export const ETH_STARKNET_SEPOLIA_ADDRESS = '0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7'
export const ETH_STARKNET_SEPOLIA_PRICE = createAmountInEthAndUsdFromUsdAmount(generateRandomNumber(2660, 2669))

export const STRK_STARKNET_SEPOLIA_ADDRESS = '0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d'
export const STRK_STARKNET_SEPOLIA_PRICE = createAmountInEthAndUsdFromUsdAmount(generateRandomNumber(0.3940, 0.3960))

export const LINK_SEPOLIA_ADDRESS = getAddress('0xf8Fb3713D459D7C1018BD0A49D19b4C44290EBE5')
export const LINK_SEPOLIA_PRICE = createAmountInEthAndUsdFromUsdAmount(generateRandomNumber(15, 19))

// for having unified floor price on testing PWN Faucet ERC721 collection
export const PWN_FAUCET_ERC721_SEPOLIA_ADDRESS = getAddress('0xDdF366d6e74D7A83181335A528b8DeA6002Be288')
const PWN_FAUCET_ERC_721_FLOOR_PRICE_ETH = generateRandomNumber(MIN_NFT_ETH_PRICE, MAX_NFT_ETH_PRICE)
const PWN_FAUCET_ERC_721_FLOOR_PRICE_USD = PWN_FAUCET_ERC_721_FLOOR_PRICE_ETH * TESTNET_ETH_USD_PRICE
export const PWN_FAUCET_ERC721_FLOOR_PRICE = new AmountInEthAndUsd(String(PWN_FAUCET_ERC_721_FLOOR_PRICE_ETH), String(PWN_FAUCET_ERC_721_FLOOR_PRICE_USD))

// for having unified floor price on testing PWN Faucet ERC1155 collection
export const PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS = getAddress('0x31fFF03EC93062C8741E8D62753BFaAE833CF3bC')
const PWN_FAUCET_ERC_1155_FLOOR_PRICE_ETH = generateRandomNumber(MIN_NFT_ETH_PRICE, MAX_NFT_ETH_PRICE)
const PWN_FAUCET_ERC_1155_FLOOR_PRICE_USD = PWN_FAUCET_ERC_1155_FLOOR_PRICE_ETH * TESTNET_ETH_USD_PRICE
export const PWN_FAUCET_ERC1155_FLOOR_PRICE = new AmountInEthAndUsd(String(PWN_FAUCET_ERC_1155_FLOOR_PRICE_ETH), String(PWN_FAUCET_ERC_1155_FLOOR_PRICE_USD))

export const generateFakeNftPrice = (contractAddress: Address, lowerBoundInEth?: number | string | undefined): AmountInEthAndUsd => {
  if (compareAddresses(contractAddress, PWN_FAUCET_ERC721_SEPOLIA_ADDRESS)) {
    lowerBoundInEth = PWN_FAUCET_ERC721_FLOOR_PRICE.ethAmount
  } else if (compareAddresses(contractAddress, PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS)) {
    lowerBoundInEth = PWN_FAUCET_ERC1155_FLOOR_PRICE.ethAmount
  } else if (lowerBoundInEth === undefined) {
    lowerBoundInEth = MIN_NFT_ETH_PRICE
  }

  const appraisalEth = generateRandomNumber(lowerBoundInEth, MAX_NFT_ETH_PRICE)
  return createAmountInEthAndUsdFromEthAmount(appraisalEth)
}

export const generateFakeFloorPrice = (contractAddress: Address): AmountInEthAndUsd => {
  if (compareAddresses(contractAddress, PWN_FAUCET_ERC721_SEPOLIA_ADDRESS)) {
    return PWN_FAUCET_ERC721_FLOOR_PRICE
  } else if (compareAddresses(contractAddress, PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS)) {
    return PWN_FAUCET_ERC1155_FLOOR_PRICE
  } else {
    return generateFakeNftPrice(contractAddress)
  }
}

export const generateFakeNftPriceStats = (contractAddress: Address): NFTPriceStats => {
  const floorPrice = generateFakeFloorPrice(contractAddress)

  return new NFTPriceStats({
    floorPrice,
    floorPriceSource: DataSourceType.UPSHOT,
    appraisal: generateFakeNftPrice(contractAddress, floorPrice.ethAmount),
    appraisalSource: DataSourceType.UPSHOT,
  })
}

export const generateFakeCollectionStats = (contractAddress: Address): CollectionStats => {
  const floorPrice = generateFakeFloorPrice(contractAddress)
  const averageAppraisal = generateFakeNftPrice(contractAddress, floorPrice.ethAmount)
  const marketCapInEth = generateRandomNumber(10, 420)
  const totalVolumeInEth = generateRandomNumber(21, 69)
  const pastDayVolumeInEth = generateRandomNumber(1, 4)
  const pastDayAverageInEth = generateRandomNumber(Number(averageAppraisal.ethAmount) - 0.1, Number(averageAppraisal.ethAmount) + 0.1)
  const pastWeekVolumeInEth = pastDayVolumeInEth * 7
  const pastWeekAverageInEth = generateRandomNumber(Number(averageAppraisal.ethAmount) - 0.2, Number(averageAppraisal.ethAmount) + 0.2)

  return new CollectionStats({
    floorPrice,
    weekFloorPriceChangePercentage: String(generateRandomNumber(-5, 5)),
    averageAppraisal,
    marketCap: createAmountInEthAndUsdFromEthAmount(marketCapInEth),
    weekMarketCapChangePercentage: String(generateRandomNumber(-4, 4)),
    totalVolume: createAmountInEthAndUsdFromEthAmount(totalVolumeInEth),
    pastDayVolume: createAmountInEthAndUsdFromEthAmount(pastDayVolumeInEth),
    pastDayAverage: createAmountInEthAndUsdFromEthAmount(pastDayAverageInEth),
    pastWeekVolume: createAmountInEthAndUsdFromEthAmount(pastWeekVolumeInEth),
    pastWeekAverage: createAmountInEthAndUsdFromEthAmount(pastWeekAverageInEth),
    pastMonthSales: String(generateRandomNumber(100, 1000, true)),
    totalSales: String(generateRandomNumber(14000, 42000, true)),
    lastUpdated: new Date(),
    dataSource: DataSourceType.UPSHOT,
  })
}

export const generateFakeErc20Price = (contractAddress: Address): AssetPrice => {
  let price: AmountInEthAndUsd
  if (compareAddresses(contractAddress, SEPOLIA_CONSTANTS.topTokens.pwnd)) {
    price = PWN_FAUCET_PWND_PRICE
  } else if (compareAddresses(contractAddress, SEPOLIA_CONSTANTS.topTokens.pwns)) {
    price = PWN_FAUCET_PWNS_PRICE
  } else if (compareAddresses(contractAddress, SEPOLIA_CONSTANTS.topTokens.weth)) {
    price = WETH_SEPOLIA_PRICE
  } else if (compareAddresses(contractAddress, SEPOLIA_CONSTANTS.topTokens.dai)) {
    price = DAI_SEPOLIA_PRICE
  } else if (compareAddresses(contractAddress, LINK_SEPOLIA_ADDRESS)) {
    price = LINK_SEPOLIA_PRICE
  } else if (compareAddresses(contractAddress, ETH_STARKNET_SEPOLIA_ADDRESS)) {
    price = ETH_STARKNET_SEPOLIA_PRICE
  } else if (compareAddresses(contractAddress, STRK_STARKNET_SEPOLIA_ADDRESS)) {
    price = STRK_STARKNET_SEPOLIA_PRICE
  } else if (compareAddresses(contractAddress, USDT_SEPOLIA_ADDRESS) || compareAddresses(contractAddress, USDT_STARKNET_SEPOLIA_ADDRESS)) {
    price = USDT_SEPOLIA_PRICE
  } else if (compareAddresses(contractAddress, USDT_SEPOLIA_ADDRESS) || compareAddresses(contractAddress, USDC_STARKNET_SEPOLIA_ADDRESS)) {
    price = USDT_SEPOLIA_PRICE
  } else {
    const priceInUsd = generateRandomNumber(MIN_ERC20_USD_PRICE, MAX_ERC20_USD_PRICE)
    price = createAmountInEthAndUsdFromUsdAmount(priceInUsd)
  }

  return new AssetPrice({
    price,
    appraisalCreatedDate: new Date(),
    priceSource: DataSourceType.COINGECKO,
  })
}

export const generateFakeErc20PriceStats = (contractAddress: Address): TokenPriceStats => {
  const latestPrice = generateFakeErc20Price(contractAddress)
  const volume24hInEth = generateRandomNumber(50, 2500)
  const marketCapInEth = generateRandomNumber(100, 10000)
  return new TokenPriceStats({
    latestPrice,
    volume24h: createAmountInEthAndUsdFromEthAmount(volume24hInEth),
    marketCap: createAmountInEthAndUsdFromEthAmount(marketCapInEth),
  })
}

export const generateFakeNftAppraisal = (contractAddress: Address): NFTAppraisal => {
  const price = generateFakeNftPrice(contractAddress)
  const pricePercentageDeviation = generateRandomNumber(0, 0.1)
  const priceRangeLowInEth = Number(price.ethAmount) - (Number(price.ethAmount) * pricePercentageDeviation)
  const priceRangeHighInEth = Number(price.ethAmount) + (Number(price.ethAmount) * pricePercentageDeviation)

  return new NFTAppraisal({
    price,
    appraisalCreatedDate: new Date(),
    priceSource: DataSourceType.UPSHOT,
    pricePercentageDeviation,
    priceRangeLow: createAmountInEthAndUsdFromEthAmount(priceRangeLowInEth),
    priceRangeHigh: createAmountInEthAndUsdFromEthAmount(priceRangeHighInEth),
  })
}

export const generateFakeAssetPrice = (contractAddress: Address, category: AssetType): AssetPrice | NFTAppraisal => {
  if (NFT_CATEGORIES.includes(category)) {
    return generateFakeNftAppraisal(contractAddress)
  } else {
    return generateFakeErc20Price(contractAddress)
  }
}
