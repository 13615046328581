<template>
  <div class="the-wallet-links">
    <BaseLink
      v-for="(link, index) in links"
      :key="index"
      :to="link.link"
      class="the-wallet-links__link"
      target="_blank"
      :title="link">
      <Component
        :is="link.icon"
        width="13"
        class="the-wallet-links__icon"
        :alt="link.title"/>
      <span class="the-wallet-links__link-name">
        {{ link.title }}
      </span>
    </BaseLink>
  </div>
</template>

<script setup lang="ts">
import linkTo from '@/constants/links'
import BaseLink from '@/general-components/BaseLink.vue'
import DocsIconSvg from '@/assets/icons/docs.svg'
import DiscordIconSvg from '@/assets/icons/discord.svg'
import MirrorIconSvg from '@/assets/icons/mirror.svg'
import TwitterIconSvg from '@/assets/icons/twitter.svg'
import FarcasterIconSvg from '@/assets/icons/farcaster.svg'

const links = [
  {
    title: 'Docs',
    icon: DocsIconSvg,
    link: linkTo.docs,
  },
  {
    title: 'Discord',
    icon: DiscordIconSvg,
    link: linkTo.discord,
  },
  {
    title: 'Blog',
    icon: MirrorIconSvg,
    link: linkTo.blog,
  },
  {
    title: 'X.COM',
    icon: TwitterIconSvg,
    link: linkTo.twitter,
  },
  {
    title: 'Farcaster',
    icon: FarcasterIconSvg,
    link: linkTo.warpcaster,
  },
]
</script>

<style scoped>
.the-wallet-links {
  --primary-color-1: var(--text-color);
  margin-top: auto;
  display: flex;
  justify-content: space-evenly;
  padding-top: 0.88rem;
  border-top: 1px solid #434343;
  min-width: calc(100% + 2rem);
  margin-left: -1rem;
  z-index: 1;

  &__link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.75rem;
    font-family: var(--font-family-screener);
  }

  &__link-name {
    @media only screen and (--mobile-viewport) {
      font-size: 0.6rem;
    }
  }
}
</style>
