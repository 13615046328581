import type { MultiToken } from '@/contracts/structs'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import AssetType from '@/modules/common/assets/AssetType'

const formatToMultiTokenTuple = (assets: AssetWithAmount[]): MultiToken[] => {
  return assets.map(({ category, address, tokenId, amountInputRaw }) => {
    return {
      category,
      assetAddress: address,
      id: tokenId || 0n,
      amount: category === AssetType.ERC721 ? 0n : amountInputRaw,
    } as const
  })
}

export default formatToMultiTokenTuple
