<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    :heading="modalHeading"
    :heading-icon="safeIcon.default"
    heading-align="left">
    <template #body>
      <CreateNewPwnSafeModalMissingSafeStep v-if="activeStep === CreateNewPwnSafeModalStep.MissingSafe"/>
      <CreateNewPwnSafeModalInputStep v-else-if="activeStep === CreateNewPwnSafeModalStep.Input"/>
      <CreateNewPwnSafeModalConfirmationStep
        v-else-if="activeStep === CreateNewPwnSafeModalStep.Confirmation"
        :final-button-text="finalButtonText"
        @on-final-button-click="emit('on-final-button-click')"/>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import { toRefs } from 'vue'
import useCreateNewPwnSafeModal, { CreateNewPwnSafeModalStep } from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/useCreateNewPwnSafeModal'
import CreateNewPwnSafeModalMissingSafeStep from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/CreateNewPwnSafeModalMissingSafeStep.vue'
import * as safeIcon from '@/assets/icons/safe.svg'
import CreateNewPwnSafeModalInputStep from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/CreateNewPwnSafeModalInputStep.vue'
import CreateNewPwnSafeModalConfirmationStep from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/CreateNewPwnSafeModalConfirmationStep.vue'
import ModalSize from '@/general-components/ModalSize'

interface Props {
    finalButtonText: string
}
const props = defineProps<Props>()
const { finalButtonText } = toRefs(props)

const { isOpen, modalHeading, activeStep } = useCreateNewPwnSafeModal()

const emit = defineEmits<{(e: 'on-final-button-click'): void}>()
</script>
