import to from '@/utils/await-to-js'
import { web3authUserProfileCreate, web3authUserProfileRetrieve } from '@/modules/common/backend/generated'
import type { UserProfileResponseBackendSchema } from '@/modules/common/backend/generated'
import UserProfile from '@/modules/common/pwn/user/UserProfile'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'
import { ref } from 'vue'
import useAuth from '@/modules/common/backend/useAuth'
import type { Address } from 'viem'
import type { AxiosResponse } from 'axios'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const userProfile = ref<UserProfile>()
const isFetchingUserProfile = ref(false)

export default function useUserProfile() {
  const updateUserProfile = async () => {
    const { address: userAddress } = useCustomAccount()
    const { fetchRestrictedEndpoint } = useAuth()

    isFetchingUserProfile.value = true
    const [, response] = await to(fetchRestrictedEndpoint(() => web3authUserProfileCreate(userAddress.value!, UserProfile.createRequestBody(userProfile.value!))))
    if (response?.data) {
      userProfile.value = UserProfile.createFromBackendModel(response.data)
    }
    isFetchingUserProfile.value = false
  }

  /**
   * @param {boolean} requireAuth - if true, BE login sign is prompt and response includes sensitive user info
   */
  const loadUserProfile = async (address: Address, requireAuth: boolean) => {
    const { isFetchingNotificationSettings } = useNotificationCenterSettings()

    isFetchingUserProfile.value = true
    isFetchingNotificationSettings.value = true
    let error: Error | null, response: AxiosResponse<UserProfileResponseBackendSchema, any> | undefined
    if (requireAuth) {
      [error, response] = await to(useAuth().fetchRestrictedEndpoint(() => web3authUserProfileRetrieve(address)))
    } else {
      [error, response] = await to(web3authUserProfileRetrieve(address))
    }

    if (!error && response?.data) {
      userProfile.value = UserProfile.createFromBackendModel(response.data)
    }
    isFetchingUserProfile.value = false
  }

  return {
    userProfile,
    isFetchingUserProfile,
    updateUserProfile,
    loadUserProfile,
  }
}
