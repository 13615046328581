import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import {
  NFTS_SORT_OPTIONS_LOOKUP, NFTsSortOption,
  TOKENS_SORT_OPTIONS_LOOKUP, TokensSortOption,
} from '@/modules/pages/pwn-explorer/tabs/TableDefinitions'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import type {
  NFTSearchResultWithStats,
  TokenSearchResultWithStats,
} from '@/modules/pages/pwn-explorer/tabs/SortFunctions'
import type { ERC20AssetSearchResult } from '@/modules/common/pwn/explorer/models/ERC20AssetSearchResult'
import { useSearchPwnExplorer } from '@/modules/common/pwn/explorer/useSearchStore'
import { useNFTSearchPriceStatsStore } from '@/modules/common/pwn/explorer/stats/useNFTSearchPriceStatsStore'
import { useERC20SearchStatsStore } from '@/modules/common/pwn/explorer/stats/useERC20SearchStatsStore'
import type { NFTAssetSearchResult } from '@/modules/common/pwn/explorer/models/NFTAssetSearchResult'
import { ExplorerStores } from '@/modules/common/pwn/explorer/constants'
import type NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'

export const usePwnExplorerAssetsTabStore = defineStore(ExplorerStores.AssetsTabStore, () => {
  const explorerStore = useSearchPwnExplorer()
  const nftStatsStore = useNFTSearchPriceStatsStore()
  const erc20StatsStore = useERC20SearchStatsStore()
  const { results } = storeToRefs(explorerStore)
  const { isFetched: nftStatsAreFetched } = storeToRefs(nftStatsStore)
  const { isFetched: erc20StatsAreFetched } = storeToRefs(erc20StatsStore)

  const tokenSelectedSortOption = ref<ActiveSortOption>(loadSortOption(
    'sort-option-search-asset-tokens',
    {
      id: TokensSortOption.TradingVolume,
      direction: SortDirection.Descending,
      viewName: 'sort-option-search-asset-tokens',
    },
    Object.keys(TOKENS_SORT_OPTIONS_LOOKUP),
  ))

  const nftSelectedSortOption = ref<ActiveSortOption>(loadSortOption(
    'sort-option-search-asset-nfts',
    {
      id: NFTsSortOption.Appraisal,
      direction: SortDirection.Descending,
      viewName: 'sort-option-search-asset-nfts',
    },
    Object.keys(NFTS_SORT_OPTIONS_LOOKUP),
  ))

  const getSortedTokens = (tokens: TokenSearchResultWithStats[]) => {
    return TOKENS_SORT_OPTIONS_LOOKUP[tokenSelectedSortOption.value.id](tokens, tokenSelectedSortOption.value.direction) as TokenSearchResultWithStats[]
  }

  const getSortedNFTs = (nfts: NFTSearchResultWithStats[] | ((NFTAssetSearchResult & { stats: NFTPriceStats; })[])) => {
    return NFTS_SORT_OPTIONS_LOOKUP[nftSelectedSortOption.value.id](nfts, nftSelectedSortOption.value.direction) as NFTSearchResultWithStats[] | ((NFTAssetSearchResult & { stats: NFTPriceStats; })[])
  }

  const sortedTokens = computed(() => {
    const tokens = results.value?.tokens
    if (erc20StatsAreFetched.value && tokens) {
      return getSortedTokens(
        tokens.map(
          (tokenResult: ERC20AssetSearchResult) => {
            return Object.assign(tokenResult, {
              stats: erc20StatsStore.actions.getTokenPriceStats(tokenResult),
            })
          },
        ),
      )
    }

    return tokens ?? []
  })

  const sortedNFTs = computed(() => {
    const nfts = results.value?.nfts
    if (nftStatsAreFetched.value && nfts) {
      return getSortedNFTs(
        // @ts-expect-error TS(2345) FIXME: Argument of type '(NFTAssetSearchResult & { stats:... Remove this comment to see the full error message
        nfts.map(
          (nftResult: NFTAssetSearchResult) => {
            return Object.assign(nftResult, {
              stats: nftStatsStore.actions.getNftPriceStats(nftResult),
            })
          },
        ),
      )
    }

    return nfts ?? []
  })

  const tokenStatsAreFetching = (asset: ERC20AssetSearchResult) => {
    const stats = erc20StatsStore.actions.getTokenPriceStats(asset)
    if (stats !== null) {
      return false
    }

    return !erc20StatsAreFetched.value
  }

  const nftStatsAreFetching = (asset: NFTAssetSearchResult) => {
    const stats = nftStatsStore.actions.getNftPriceStats(asset)
    if (stats !== null) {
      return false
    }

    return !nftStatsAreFetched.value
  }

  return {
    tokenSelectedSortOption,
    nftSelectedSortOption,
    sortedTokens,
    sortedNFTs,
    actions: {
      tokenStatsAreFetching,
      nftStatsAreFetching,
    },
  }
})
