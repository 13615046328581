import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import type { ValuationSource } from '@/general-components/data-source/DataSourceType'
import { parseSourceFromBackend } from '@/general-components/data-source/DataSourceType'
import type NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'
import type { AssetPriceSchemaBackendSchema } from '@/modules/common/backend/generated'

export class AssetPrice {
  public price: AmountInEthAndUsd
  public date: Date
  public appraisalCreatedDate: Date
  public priceSource: ValuationSource
  public priceFullAmount?: AmountInEthAndUsd

  constructor(price: Partial<AssetPrice>) {
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.price = price?.price
    // @ts-expect-error TS(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
    this.appraisalCreatedDate = price?.appraisalCreatedDate
    // @ts-expect-error TS(2322) FIXME: Type 'ValuationSource | undefined' is not assignab... Remove this comment to see the full error message
    this.priceSource = price?.priceSource
    this.priceFullAmount = price?.priceFullAmount
    this.date = new Date()
  }

  public static createFromBackendModel(price: AssetPriceSchemaBackendSchema): AssetPrice {
    return new AssetPrice({
      price: price?.price ? AmountInEthAndUsd.createFromBackendModel(price.price) : undefined,
      appraisalCreatedDate: price.created_at ? new Date(price.created_at) : undefined,
      priceSource: price.price_source ? parseSourceFromBackend(price.price_source) as ValuationSource : undefined, // TODO is this correct?
    })
  }

  static fromFullPriceToBasePrice(fullPrice: AmountInEthAndUsd, amount: string): AmountInEthAndUsd {
    return new AmountInEthAndUsd(
      (parseFloat(fullPrice.ethAmount) / parseFloat(amount)).toString(),
      (parseFloat(fullPrice.usdAmount) / parseFloat(amount)).toString(),
    )
  }

  // used for loan.collateral_price. The price in loan.collateral_price is in full amount(amount of tokens * price per one token), unlike BE prices elsewhere(price per one token)
  public static createFromBackendModelForFullAmount(price: AssetPriceSchemaBackendSchema): AssetPrice {
    return new AssetPrice({
      priceFullAmount: price?.price ? AmountInEthAndUsd.createFromBackendModel(price.price) : undefined,
      appraisalCreatedDate: price.created_at ? new Date(price.created_at) : undefined,
      priceSource: price.price_source ? parseSourceFromBackend(price.price_source) : undefined,
    })
  }
}

export class NFTAppraisal extends AssetPrice {
  public pricePercentageDeviation: number
  public priceRangeLow: AmountInEthAndUsd // calculated as: this.price - (this.price * this.pricePercentageDeviation)
  public priceRangeHigh: AmountInEthAndUsd // calculated as: this.price + (this.price * this.pricePercentageDeviation)

  constructor(appraisal: Partial<NFTAppraisal>) {
    super(appraisal)
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.pricePercentageDeviation = appraisal?.pricePercentageDeviation
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.priceRangeLow = appraisal?.priceRangeLow
    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.priceRangeHigh = appraisal?.priceRangeHigh
  }

  public static createFromBackendModel(price: AssetPriceSchemaBackendSchema): NFTAppraisal {
    const assetPrice = super.createFromBackendModel(price)
    return new NFTAppraisal({
      ...assetPrice,
      pricePercentageDeviation: price.price_percentage_deviation ? Number(price.price_percentage_deviation) : undefined,
      priceRangeLow: AmountInEthAndUsd.createFromBackendModel(price.price_range_low),
      priceRangeHigh: AmountInEthAndUsd.createFromBackendModel(price.price_range_high),
    })
  }

  public static createFromNftPriceStats(nftPriceStats: NFTPriceStats): NFTAppraisal {
    return new NFTAppraisal({
      price: nftPriceStats.appraisal ?? undefined,
      priceSource: nftPriceStats.appraisalSource as ValuationSource,
      appraisalCreatedDate: undefined,
      pricePercentageDeviation: undefined,
      priceRangeLow: undefined,
      priceRangeHigh: undefined,
    })
  }
}
