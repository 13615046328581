import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { connect, disconnect } from '@starknet-io/get-starknet'
import type { StarknetWindowObject } from '@starknet-io/get-starknet'
import { handleAccount, starknetAccount, starknetWallet } from '@/modules/common/web3/useCustomAccount'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import { useDisconnect } from '@wagmi/vue'
import { WalletAccount } from 'starknet'
import { getStarknetTransport } from '@/modules/common/starknet/usePwnStarknetConfig'
import { SupportedChain } from '@/constants/chains/types'

export function useCustomConnect() {
  const handleConnect = async () => {
    if (isStarknet) {
      const transport = getStarknetTransport(SupportedChain.StarknetSepolia)
      const walletSWO: StarknetWindowObject | null = await connect({ modalMode: 'canAsk', modalTheme: 'dark' })
      // @ts-expect-error enable is not part of the type
      await walletSWO?.enable?.()
      if (!walletSWO) {
        return
      }
      starknetWallet.value = walletSWO

      starknetAccount.value = new WalletAccount(transport, walletSWO)
      if (starknetWallet.value) {
        starknetWallet.value?.on('accountsChanged', handleAccount)
      }
    } else {
      // https://docs.walletconnect.com/web3modal/vue/composables#useweb3modal
      // TODO do we care about additional parameter `view`?
      useWeb3Modal().open()
    }
  }

  const handleDisconnect = async () => {
    if (isStarknet) {
      await disconnect({ clearLastWallet: true })
      // todo: should we do this here or in the event listener for address change?
      starknetWallet.value = undefined
      starknetAccount.value = undefined
    } else {
      const { disconnect } = useDisconnect()
      disconnect()
    }
  }
  return {
    handleConnect,
    handleDisconnect,
  }
}
