import type { Address } from 'viem'
import { readPwnV1_2RevokedNonceCurrentNonceSpace, writePwnV1_2RevokedNonceRevokeNonce, readPwnV1_2RevokedNonceIsNonceUsable, writePwnV1_2RevokedNonceRevokeNonces } from '@/contracts/generated'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { SupportedChain } from '@/constants/chains/types'
export default class V1_2RevokedNonceContract {
  public async currentNonceSpace(nonceContractAddress: Address, chainId: SupportedChain, owner: Address): Promise<bigint> {
    return await readPwnV1_2RevokedNonceCurrentNonceSpace(pwnWagmiConfig, {
      address: nonceContractAddress,
      chainId,
      args: [owner],
    })
  }

  public async revokeNonce(nonceContractAddress: Address, chainId: SupportedChain, nonce: bigint): Promise<Address> {
    return await writePwnV1_2RevokedNonceRevokeNonce(pwnWagmiConfig, {
      address: nonceContractAddress,
      chainId,
      args: [nonce],
    })
  }

  public static async revokeNonces(nonceContractAddress, chainId: SupportedChain, nonces: bigint[]): Promise<Address> {
    return await writePwnV1_2RevokedNonceRevokeNonces(pwnWagmiConfig, {
      address: nonceContractAddress,
      chainId,
      args: [nonces],
    })
  }

  public async isNonceUsable({
    nonceContractAddress,
    chainId,
    owner,
    nonceSpace,
    nonce,
  } : {
    nonceContractAddress: Address,
    chainId: SupportedChain,
    owner: Address,
    nonceSpace: bigint,
    nonce: bigint
  }): Promise<boolean> {
    return await readPwnV1_2RevokedNonceIsNonceUsable(pwnWagmiConfig, {
      address: nonceContractAddress,
      chainId,
      args: [owner, nonceSpace, nonce],
    })
  }
}
