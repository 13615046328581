import { V1_2SimpleLoanFungibleProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import type { Address } from 'viem'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import useNFTFetch from '@/modules/common/assets/fetchers/useNFTFetch'
import useApprove from '@/modules/common/assets/useApprove'
import to from '@/utils/await-to-js'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { compoundCometBalanceOf, compoundCometIsAllowed, getPoolAdapter, getReserveData } from '@/contracts/abis'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { AssetReserveData } from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import { calculateMinCreditAmount } from '@/modules/common/pwn/utils'
import { readAnyERC20Allowance } from '@/modules/common/adapters/erc20-utils'

export const checkCollateral = async ({
  proposalClass,
  walletAddress,
}: { proposalClass: BaseProposal, walletAddress: Address }) => {
  const collateralAmountToCheck = proposalClass.isFungibleProposal && proposalClass instanceof V1_2SimpleLoanFungibleProposal
    ? proposalClass.minCollateralAmount
    : proposalClass.collateral.isFungible ? proposalClass.collateralAmount : 1n

  let userBalancePromise: Promise<bigint> | Promise<bigint | undefined>
  const userAllowancePromise = useApprove().isApproved(
    proposalClass.chainId,
    proposalClass.collateral?.category,
    proposalClass.collateral.address,
    walletAddress,
    proposalClass.loanContractAddress,
    collateralAmountToCheck,
  )

  if (proposalClass.collateral.isErc20) {
    userBalancePromise = useERC20Fetch().fetchUserERC20Balance(
      proposalClass.chainId,
      proposalClass.collateral.address,
      walletAddress,
    )
  } else {
    // TODO how to fetch balance of collection?
    userBalancePromise = useNFTFetch().fetchUserNftBalance(
      proposalClass.chainId,
      proposalClass.collateral.address,
      proposalClass.collateral.tokenId ?? 0n,
      proposalClass.collateral.category,
      walletAddress,
    )
  }
  const [, response] = await to(Promise.all([userAllowancePromise, userBalancePromise]))
  const userBalance = response?.[1] ?? 0n

  return {
    hasCollateralApproval: response ? response[0] : false,
    hasCollateral: userBalance >= collateralAmountToCheck,
  }
}

export const checkCreditAsset = async ({
  proposalClass,
  walletAddress,
}: { proposalClass: BaseProposal, walletAddress: Address }) => {
  const creditAmountToCheck = proposalClass instanceof V1_2SimpleLoanFungibleProposal
    ? calculateMinCreditAmount(proposalClass.minCollateralAmount, proposalClass.creditPerCollateralUnit)
    : proposalClass.creditAmount

  // relevant only for compound/aave/morpho proposals
  let hasSofBalance = false
  let hasSofApproval = false
  if (proposalClass.isOffer && proposalClass.sourceOfFunds) {
    if (CHAINS_CONSTANTS[String(proposalClass.chainId)].compound.pools.includes(proposalClass.sourceOfFunds)) {
      const poolAdapter = await getPoolAdapter(pwnWagmiConfig, {
        address: CHAINS_CONSTANTS[String(proposalClass.chainId)].pwnV1_2Contracts.pwnConfig,
        chainId: proposalClass.chainId,
        args: [proposalClass.sourceOfFunds],
      })

      const balanceOfPromise = compoundCometBalanceOf(pwnWagmiConfig, {
        address: proposalClass.sourceOfFunds,
        chainId: proposalClass.chainId,
        args: [proposalClass.proposer],
      }) as Promise<bigint>

      const isAllowedPromise = compoundCometIsAllowed(pwnWagmiConfig, {
        address: proposalClass.sourceOfFunds,
        chainId: proposalClass.chainId,
        args: [proposalClass.proposer, poolAdapter],
      }) as Promise<boolean>

      const [isAllowed, balanceOf] = await Promise.all([isAllowedPromise, balanceOfPromise])

      const hasCreditAsset = balanceOf >= creditAmountToCheck

      hasSofBalance = hasCreditAsset
      hasSofApproval = isAllowed
    } else if (proposalClass.sourceOfFunds === CHAINS_CONSTANTS[String(proposalClass.chainId)].aave.pool) {
      const reserveData = await getReserveData(pwnWagmiConfig, {
        address: CHAINS_CONSTANTS[String(proposalClass.chainId)].aave.pool,
        chainId: proposalClass.chainId,
        args: [proposalClass.creditAsset.address],
      }) as AssetReserveData

      const poolAdapter = await getPoolAdapter(pwnWagmiConfig, {
        address: CHAINS_CONSTANTS[String(proposalClass.chainId)].pwnV1_2Contracts.pwnConfig,
        chainId: proposalClass.chainId,
        args: [proposalClass.sourceOfFunds],
      })

      const userSofAllowancePromise = useApprove().isApproved(
        proposalClass.chainId,
        proposalClass.creditAsset.category,
        reserveData.aTokenAddress,
        walletAddress,
        poolAdapter,
        creditAmountToCheck,
      )

      const userSofBalancePromise = useERC20Fetch().fetchUserERC20Balance(
        proposalClass.chainId,
        reserveData.aTokenAddress,
        walletAddress,
      )
      const [isAllowed, balanceOf] = await Promise.all([userSofAllowancePromise, userSofBalancePromise])
      hasSofApproval = isAllowed
      hasSofBalance = balanceOf >= creditAmountToCheck
    }
  }

  const creditAssetAllowancePromise = readAnyERC20Allowance(proposalClass.creditAsset.address, proposalClass.creditAsset.chainId, walletAddress, proposalClass.loanContractAddress)
  const userBalancePromise = useERC20Fetch().fetchUserERC20Balance(
    proposalClass.chainId,
    proposalClass.creditAsset.address,
    walletAddress,
  )
  const [creditAssetAllowance, userBalance] = await Promise.all([creditAssetAllowancePromise, userBalancePromise])

  const hasCreditAsset = userBalance >= creditAmountToCheck
  const hasCreditApproval = creditAssetAllowance >= creditAmountToCheck
  const hasFullAmountApprovalForFungibleProposal = proposalClass instanceof V1_2SimpleLoanFungibleProposal && proposalClass.fullRemainingCreditAmount
    ? creditAssetAllowance >= proposalClass.fullRemainingCreditAmount
    : hasCreditApproval

  if (proposalClass.sourceOfFunds) {
    return {
      // we don't care about underlying asset balance in case of SoF, because it will be withdrawn from the pool
      hasCreditApproval: hasCreditApproval && hasSofApproval,
      hasCreditAsset: hasSofBalance,
      hasFullAmountApproval: hasFullAmountApprovalForFungibleProposal,
    }
  }

  return {
    hasCreditApproval,
    hasCreditAsset,
    hasFullAmountApproval: hasFullAmountApprovalForFungibleProposal,
  }
}

export const checkAcceptorApprovalAndBalance = async ({
  proposalClass,
  acceptorAddress,
}: { proposalClass: BaseProposal, acceptorAddress: Address }) => {
  if (proposalClass.isOffer) {
    // acceptor == borrower, so we are checking connected wallet collateral balance
    const {
      hasCollateralApproval,
      hasCollateral,
    } = await checkCollateral({
      proposalClass,
      walletAddress: acceptorAddress,
    })
    return {
      hasAcceptorApproval: hasCollateralApproval,
      hasAcceptorAsset: hasCollateral,
    }
  } else {
    // acceptor == lender, so we are checking connected wallet credit balance
    const {
      hasCreditApproval,
      hasCreditAsset,
    } = await checkCreditAsset({
      proposalClass,
      walletAddress: acceptorAddress,
    })
    return {
      hasAcceptorApproval: hasCreditApproval,
      hasAcceptorAsset: hasCreditAsset,
    }
  }
}

export const checkProposerApprovalAndBalance = async ({
  proposalClass,
  proposerAddress,
}: { proposalClass: BaseProposal, proposerAddress: Address }) => {
  if (proposalClass.isOffer) {
    // proposer == lender, so we are checking if proposer still have credit asset
    const {
      hasCreditApproval,
      hasCreditAsset,
      hasFullAmountApproval,
    } = await checkCreditAsset({
      proposalClass,
      walletAddress: proposerAddress,
    })
    return {
      hasProposerApproval: hasCreditApproval,
      hasProposerAsset: hasCreditAsset,
      hasProposerFullAmountApproval: !!hasFullAmountApproval,
    }
  } else {
    // proposer == borrower, so we are checking if proposer still have collateral asset
    const {
      hasCollateralApproval,
      hasCollateral,
    } = await checkCollateral({
      proposalClass,
      walletAddress: proposerAddress,
    })
    return {
      hasProposerApproval: hasCollateralApproval,
      hasProposerAsset: hasCollateral,
      // TODO return also the full amount approval check from checkCollateral?
      hasProposerFullAmountApproval: true,
    }
  }
}

export const checkApprovalsAndBalances = async ({
  proposalClass,
  userAddress,
}: { proposalClass?: BaseProposal, userAddress?: Address}) => {
  if (!proposalClass || !userAddress) {
    return
  }

  const proposerPromise = checkProposerApprovalAndBalance({
    proposalClass,
    proposerAddress: proposalClass.proposer,
  })

  const acceptorPromise = checkAcceptorApprovalAndBalance({
    proposalClass,
    acceptorAddress: userAddress,
  })
  const
    [
      {
        hasProposerApproval,
        hasProposerAsset,
        hasProposerFullAmountApproval,
      }, {
        hasAcceptorApproval,
        hasAcceptorAsset,
      },
    ] = await Promise.all([proposerPromise, acceptorPromise])

  return {
    hasAcceptorApproval,
    hasAcceptorAsset,
    hasProposerApproval,
    hasProposerAsset,
    hasProposerFullAmountApproval,
  }
}
