import { ref } from 'vue'
import UserNotificationSettings from '@/modules/common/notifications/UserNotificationSettings'
import to from '@/utils/await-to-js'
import { web3authNotificationSettingsCreate, web3authNotificationSettingsRetrieve } from '@/modules/common/backend/generated'
import useAuth from '@/modules/common/backend/useAuth'
import useNotifications from '@/modules/common/notifications/useNotifications'
import usePushNotificationModal from '@/modules/pages/notification-center/settings/modals/usePushNotificationModal'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const userNotificationSettings = ref<UserNotificationSettings>()
const isFetchingNotificationSettings = ref(false)

export default function useNotificationCenterSettings() {
  const updateNotificationSettings = async () => {
    const { address: userAddress, chainId: connectedChainId } = useCustomAccount()
    const { userNotificationSettings } = useNotificationCenterSettings()

    const { fetchRestrictedEndpoint } = useAuth()
    const [error] = await to(fetchRestrictedEndpoint(() => web3authNotificationSettingsCreate(
      userAddress.value!,
      UserNotificationSettings.createRequestBody(userNotificationSettings.value!),
    )))
    if (error) {
      useNotifications().displayErrorNotification({
        action: NotificationFrontendOnlyActionEnum.UPDATE_NOTIFICATION_SETTINGS,
        errorMessage: error.message,
        chainId: connectedChainId.value!,
      })
    }
  }

  const loadNotificationSettings = async () => {
    const { address: userAddress } = useCustomAccount()
    const { fetchIsSubscribedToPush } = usePushNotificationModal()

    isFetchingNotificationSettings.value = true
    const [, resp] = await to(web3authNotificationSettingsRetrieve(userAddress.value!))
    if (resp?.data) {
      userNotificationSettings.value = UserNotificationSettings.createFromBackendModel(resp.data)
    }
    await fetchIsSubscribedToPush()
    isFetchingNotificationSettings.value = false
  }

  const toggleNewLoanRequests = async () => {
  // @ts-expect-error FIXME: strictNullChecks
    userNotificationSettings.value.newLoanRequest = !userNotificationSettings.value.newLoanRequest
    const [error] = await to(updateNotificationSettings())
    if (error) {
    // set the toggle back
    // @ts-expect-error FIXME: strictNullChecks
      userNotificationSettings.value.newLoanRequest = !userNotificationSettings.value.newLoanRequest
    }
  }
  const toggleFollowUsers = async () => {
  // @ts-expect-error FIXME: strictNullChecks
    userNotificationSettings.value.followUsers = !userNotificationSettings.value.followUsers
    const [error] = await to(updateNotificationSettings())
    if (error) {
    // set the toggle back
    // @ts-expect-error FIXME: strictNullChecks
      userNotificationSettings.value.followUsers = !userNotificationSettings.value.followUsers
    }
  }

  const toggleLoanDefaultWarning = async () => {
  // @ts-expect-error FIXME: strictNullChecks
    userNotificationSettings.value.loanDefaultWarning = !userNotificationSettings.value.loanDefaultWarning
    const [error] = await to(updateNotificationSettings())
    if (error) {
    // set the toggle back
    // @ts-expect-error FIXME: strictNullChecks
      userNotificationSettings.value.loanDefaultWarning = !userNotificationSettings.value.loanDefaultWarning
    }
  }

  const setDefaultSettings = () => {
    userNotificationSettings.value = {
      offersOnUserLoanRequestFilterSettings: {
        onlyVerifiedTokens: true,
        onlyRelevantTokens: false,
        filterApy: false,
        minApy: 0,
        maxApy: 100,
        filterLtv: false,
        minLtv: 0,
        maxLtv: 100,
      },
      newLoanRequestFilterSettings: [],
      followedUsers: [],
      offersOnUserLoanRequest: true,
      loanDefaultWarning: true,
      firstAlertDaysBeforeLoanDefault: 1,
      secondAlertDaysBeforeLoanDefault: 7,
      offerAccepted: true,
      offerNotSelected: false,
      newLoanRequest: false,
      followUsers: false,
    }
    updateNotificationSettings()
  }

  return {
    userNotificationSettings,
    isFetchingNotificationSettings,
    updateNotificationSettings,
    loadNotificationSettings,
    toggleNewLoanRequests,
    toggleFollowUsers,
    toggleLoanDefaultWarning,
    setDefaultSettings,
  }
}
