<template>
  <div class="address-info-select-option__option">
    <div class="address-info-select-option__option-icon">
      <Icon
        class="address-info-select-option__option-icon--img"
        alt="transfer"/>
    </div>
    <div class="address-info-select-option__option-text">
      <div class="address-info-select-option__option-name">
        {{ addressInfo?.name }}
      </div>
      <div class="address-info-select-option__option-address">
        ({{ addressInfo?.safeAddressShort }})
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SafeIconSvg from '@/assets/icons/safe.svg'
import WalletIconSvg from '@/assets/icons/wallet.svg'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { compareAddresses } from '@/utils/utils'
import { toRefs, computed } from 'vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import type { Address } from 'viem'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { storeToRefs } from 'pinia'
import { useConnectedSafeWalletStore } from '@/modules/common/web3/useSafeWalletSdk'

interface Props {
  addressInfo: PwnSafe
}
const props = defineProps<Props>()
const { addressInfo } = toRefs(props)

const connectedSafeWalletStore = useConnectedSafeWalletStore()

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { pwnSafes } = storeToRefs(pwnSafeDetailStore)

const { address: userAddress } = useCustomAccount()

const Icon = computed(() => {
  const isOwner = connectedSafeWalletStore.connectedSafeInfo?.owners.some((owner: string) => compareAddresses(owner as Address, addressInfo.value.safeAddress))
  const isWalletIcon = isOwner || compareAddresses(addressInfo.value.safeAddress, userAddress.value) || !(addressInfo.value.safeAddress in pwnSafes)
  return isWalletIcon ? WalletIconSvg : SafeIconSvg
})
</script>

<style scoped>
.address-info-select-option {
  &__option {
    display: flex;
    font-family: var(--font-family-oxygen-mono);
  }

  &__option-icon {
    filter: grayscale(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0.5rem;

    &--img {
      height: 1rem;
    }
  }

  &__option-text {
    margin-top: 0.125rem;
    display: flex;
  }

  &__option-name {
    margin-right: 0.5rem;
  }

  &__option-address {
    color: var(--gray-2);
  }
}
</style>
