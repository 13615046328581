import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, unref } from 'vue'
import type { AxiosResponse } from 'axios'
import type { MaybeRef } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'
import { TESTNET_CHAINS } from '@/constants/chains/types'
import { getFetchAssetPriceQueryOptions } from '@/modules/common/backend/generated'
import type { AmountInEthAndUsdBackendSchema, AssetPriceAndTaskStatusSchemaBackendSchema } from '@/modules/common/backend/generated'
import { useQueries } from '@tanstack/vue-query'
import { generateFakeAssetPrice } from '@/modules/common/assets/fakeAppraisals'
import type { Address } from 'viem'
import { parseAddress } from '@/utils/address-utils'

export const useAssetListPrices = (assets: MaybeRef<AssetWithAmount[]>) => {
  const queriesToFetch = computed(() => {
    return unref(assets).map(v => {
      const tokenId = v.tokenId ? String(v.tokenId) : 'null'
      return getFetchAssetPriceQueryOptions(String(v.chainId), v.address, tokenId)
    })
  })

  const hackToInjectSepoliaPrices = (
    response: AxiosResponse<AssetPriceAndTaskStatusSchemaBackendSchema, unknown> | undefined,
    asset: AssetWithAmount,
  ) => {
    if (!TESTNET_CHAINS.includes(asset.chainId as SupportedChain)) {
      return false
    }
    const address = asset.address
    const priceObj = generateFakeAssetPrice(address, asset.category)
    return {
      eth_amount: priceObj?.price.ethAmount,
      usd_amount: priceObj?.price.usdAmount,
    }
  }

  const assetPrices = useQueries({
    queries: queriesToFetch,
    combine: (results) => {
      const res: Partial<Record<Address, AmountInEthAndUsdBackendSchema>> = {}
      const _assets = unref(assets)

      results.forEach((v, i) => {
        const asset = _assets[i]
        const injectedPrice = hackToInjectSepoliaPrices(v.data, asset)
        const assetKey = `${asset.chainId}-${parseAddress(asset.address)}`

        if (injectedPrice && !(v.data?.data.best_price?.unit_price || v.data?.data.best_price?.price)) {
          res[assetKey] = injectedPrice
        } else {
          res[assetKey] = v.data?.data.best_price?.unit_price || v.data?.data.best_price?.price
        }
        return injectedPrice
      })

      return res
    },
  })

  return {
    assetPrices,
  }
}
