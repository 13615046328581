<template>
  <div class="multiple-assets-amount">
    <div class="multiple-assets-amount__link">
      <div class="multiple-assets-amount__image-wrap">
        <TokenMedia
          v-for="(asset, index) in props.creditAssets"
          :key="asset.id"
          :token="asset"
          :style="{
            marginLeft: index > 0 ? '-15px' : '0',
            zIndex: index,
          }"
          height="20"
          width="20"/>
      </div>

      <div class="multiple-assets-amount__details">
        <div class="multiple-assets-amount__info">
          <span class="multiple-assets-amount__name">
            <SafeText
              v-if="props.assetAmount"
              class="multiple-assets-amount__name-wrap"
              :text="assetName"
              :tooltip-text="assetName"/>
            <VerifiedIcon
              v-if="asset.isVerified && !hideVerifiedIcon"
              class="proposal-collateral__verified-icon"/>
          </span>
        </div>
      </div>
    </div>

    <span :class="['multiple-assets-amount__appraisal', {'multiple-assets-amount__appraisal--right' : isAppraisalAlignedRight}]">

      <AssetAppraisal
        :asset-amount="assetAmountFormatted"
        :asset-metadata="{
          address: asset.address,
          chainId: asset.chainId,
          tokenId: asset.tokenId ? asset.tokenId.toString() : undefined,
        }"/>
    </span>
  </div>
</template>

<script setup lang="ts">
import { formatAmountWithDecimals } from '@/utils/utils'
import SafeText from '@/general-components/SafeText.vue'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
import { computed } from 'vue'
import AssetType from '@/modules/common/assets/AssetType'
import { parseUnits } from 'viem'
import type { Address } from 'viem'
import TokenMedia from '@/general-components/TokenMedia.vue'
import VerifiedIcon from '@/general-components/icons/VerifiedIcon.vue'
import { asyncComputed } from '@vueuse/core'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

type Props = {
  creditAssets: AssetWithAmount[]
  assetAmount: string
  assetAmountBigInt?: bigint
  isDisplaySymbol?: boolean
  isAppraisalAlignedRight?: boolean
  isCheckForFungibleProposalCreditAmount?: boolean
  proposer?: string
  loanContract?: string

  hideIcon?: boolean
  hideVerifiedIcon?: boolean
}

const props = defineProps<Props>()

const asset = computed(() => {
  return props.creditAssets[0]
})

const assetAmountRaw = asyncComputed(async() => {
  if (props.isCheckForFungibleProposalCreditAmount && props.proposer && props.loanContract) {
    return await ProposalFactory.maxAcceptableCreditOfFungibleProposal({
      creditAssetAddress: asset.value?.address,
      chainId: asset.value?.chainId,
      proposer: props.proposer as Address,
      loanContract: props.loanContract as Address,
      remainingCreditFungibleProposal: BigInt(props.assetAmount ?? ''),
      returnBigInt: false,
    })
  } else {
    return parseUnits(props.assetAmount, asset.value.decimals)
  }
})

const assetAmountFormatted = computed(() => {
  const fallbackDecimals = asset?.value.category === AssetType.ERC20 ? 18 : 0
  const v = formatAmountWithDecimals(BigInt((assetAmountRaw.value && assetAmountRaw.value !== '0') ? assetAmountRaw.value : 1), asset.value.decimals ?? fallbackDecimals)
  return v
})

const assetName = computed(() => {
  return 'Stables'
})

</script>

<style scoped>
.multiple-assets-amount {
  display: flex;
  flex-flow: column;

  &__link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__image-wrap {
    position: relative;
    display: flex;
  }

  &__image {
    width: 1rem;
    height: 1rem;

    margin-right: 0.5rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 0.875rem;
    display: inline-flex;

    align-items: center;
  }

  &__name-wrap {
    max-width: clamp(100px, 10vw, 100%);

    margin-right: 0.125rem;
  }

  &__appraisal {
    font-size: 0.75rem;
    color: var(--gray-2);
    display: inline-flex;

    margin-top: 0.25rem;
    margin-right: auto;

    &--right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
</style>
