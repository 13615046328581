<template>
  <BaseSection
    class="section-your-borrowing"
    with-gray-background
    heading="">
    <SectionYourBorrowingStats
      v-if="!searchTerm"
      class="section-your-borrowing__stats"/>
    <SectionYourFundedBorrowing
      :is-dashboard="props.isDashboard"
      class="section-your-borrowing__borrowing"/>
    <SectionYourUnfundedBorrowing
      class="section-your-borrowing__unfunded-borrowing"/>
  </BaseSection>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import BaseSection from '@/general-components/BaseSection.vue'
import SectionYourFundedBorrowing from '@/modules/sections/your-funded-borrowing/SectionYourFundedBorrowing.vue'
import SectionYourUnfundedBorrowing from '@/modules/sections/your-unfunded-borrowing/SectionYourUnfundedBorrowing.vue'
import SectionYourBorrowingStats from '@/modules/sections/your-stats/SectionYourBorrowingStats.vue'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { address: userAddress } = useCustomAccount()
const { searchTerm } = useDashboard()
const { userAddressToDisplay } = useYourNFTs()

const props = defineProps<{ isDashboard: boolean }>()

watch(userAddress, () => {
  if (!props.isDashboard) {
    userAddressToDisplay.value = userAddress.value
  }
})
</script>

<style scoped>
.section-your-borrowing {
  &__card {
    width: 521px;
    height: 242px;
    margin: 0 auto;
  }

  &__borrowing,
  &__unfunded-borrowing,
  &__stats,
  &__card {
    margin-bottom: 3rem;
  }

  @media only screen and (--small-viewport) {
    &__borrowing,
    &__unfunded-borrowing {
      row-gap: 1rem;
      display: flex;
      flex-flow: column;
      margin-bottom: 4rem;
    }

    &__card {
      width: 100%;
      height: auto;
    }
  }
}
</style>
