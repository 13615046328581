<template>
  <RewardsTooltip
    v-if="creditAddress && _chainId"
    :assets="rewardsAssets"
    :apr="apr"
    :proposal-rewards-amount="rewardsToBeEarned > 0 ? formatDecimalPoint(rewardsToBeEarned) : undefined">
    <template #trigger>
      <div
        class="rewards-by-loan-id">
        <ReputationScoreSvg
          class="rewards-by-loan-id__icon"
          alt="reputation score"/>

        <span>
          {{ formatDecimalPoint(rewardsToBeEarned) }}
        </span>
      </div>
    </template>
  </RewardsTooltip>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import ReputationScoreSvg from '@/assets/icons/rep-score.svg'
import { formatDecimalPoint } from '@/utils/utils'
import RewardsTooltip from '@/general-components/promo/RewardsTooltip.vue'
// import { useUserPotentialReputationPerLoanRepayRetrive } from '@/modules/common/backend/generated'
// import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { getAddress } from 'viem'
import type { Address } from 'viem'
import { useRewardsForCreditToBeEarned } from './useRewardsForCreditToBeEarned'

type Props = {
  creditAddress: Address
  collateralAddress: Address
  loanId: string
  loanContractAddress: string
  chainId: number | string | null
  creditAmount: string
  apr?: string
}

const props = defineProps<Props>()

const { chainId, creditAddress, collateralAddress, creditAmount } = toRefs(props)

// const { address: userAddress } = useCustomAccount()

const _chainId = computed(() => {
  if (!chainId.value) return null
  return Number(chainId.value)
})

// const loanParam = computed(() => {
//   return `${_chainId.value},${props.loanContractAddress},${props.loanId}`
// })

// const repPointsLoanParams = computed(() => {
//   return { loan_ids: [loanParam.value] }
// })

// const loansRequestIsEnabled = computed(() => Boolean(props.loanId && props.loanContractAddress && props.chainId && userAddress.value))

// // @es-lint-disable-next-line no-undef
// const repPointsLoanQuery = useUserPotentialReputationPerLoanRepayRetrive(userAddress, repPointsLoanParams, {
//   query: {
//     enabled: loansRequestIsEnabled,
//   },
// })

const { rewardsToBeEarned: clientSideRewardsToBeEarned } = useRewardsForCreditToBeEarned(creditAddress, _chainId, creditAmount)

const rewardsToBeEarned = computed(() => {
  return clientSideRewardsToBeEarned.value
  // const data = repPointsLoanQuery.data.value?.data
  // return data?.potentialRepsPerLoanRepay[loanParam.value || ''] ?? 0
})

const rewardsAssets = computed(() => {
  if (!creditAddress.value || !collateralAddress.value || !_chainId.value) return []
  return [{ chainId: _chainId.value, address: getAddress(creditAddress.value) }, { chainId: _chainId.value, address: getAddress(collateralAddress.value) }]
})

</script>

<style scoped>
.rewards-by-loan-id {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--primary-color-1);

  display: flex;
  align-items: center;

  &__icon {
    margin-right: 0.25rem;
    width: 12px;
    height: 12px;
    fill: var(--primary-color-1);
  }
}
</style>
