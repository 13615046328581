/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CookieTokenRefreshBackendSchema,
  CuratorGroupDetailBackendSchema,
  NotificationSettingsBackendSchema,
  NotificationSettingsRequestBackendSchema,
  PaginatedCuratorGroupDetailListBackendSchema,
  PaginatedWalletRoleListBackendSchema,
  UserProfileRequestRequestBackendSchema,
  UserProfileResponseBackendSchema,
  WalletRoleBackendSchema,
  WalletStatsBackendSchema,
  Web3TokenObtainPairBackendSchema,
  Web3TokenObtainPairRequestBackendSchema,
  Web3authCuratorGroupsListParams,
  Web3authUserProfileStatsRetrieveParams,
  Web3authWalletsListParams
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const web3authCuratorGroupsList = (
    params?: MaybeRef<Web3authCuratorGroupsListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedCuratorGroupDetailListBackendSchema>(
      {url: `/api/v1/web3auth/curator_groups/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getWeb3authCuratorGroupsListQueryKey = (params?: MaybeRef<Web3authCuratorGroupsListParams>,) => {
    return ['api','v1','web3auth','curator_groups', ...(params ? [params]: [])] as const;
    }

    
export const getWeb3authCuratorGroupsListQueryOptions = <TData = Awaited<ReturnType<typeof web3authCuratorGroupsList>>, TError = unknown>(params?: MaybeRef<Web3authCuratorGroupsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authCuratorGroupsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getWeb3authCuratorGroupsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof web3authCuratorGroupsList>>> = ({ signal }) => web3authCuratorGroupsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof web3authCuratorGroupsList>>, TError, TData> 
}

export type Web3authCuratorGroupsListQueryResult = NonNullable<Awaited<ReturnType<typeof web3authCuratorGroupsList>>>
export type Web3authCuratorGroupsListQueryError = unknown

export const useWeb3authCuratorGroupsList = <TData = Awaited<ReturnType<typeof web3authCuratorGroupsList>>, TError = unknown>(
 params?: MaybeRef<Web3authCuratorGroupsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authCuratorGroupsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeb3authCuratorGroupsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const web3authCuratorGroupsRetrieve = (
    name: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      name = unref(name);
      
      return customInstance<CuratorGroupDetailBackendSchema>(
      {url: `/api/v1/web3auth/curator_groups/${encodeURIComponent(String(name))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getWeb3authCuratorGroupsRetrieveQueryKey = (name: MaybeRef<string>,) => {
    return ['api','v1','web3auth','curator_groups',name] as const;
    }

    
export const getWeb3authCuratorGroupsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof web3authCuratorGroupsRetrieve>>, TError = unknown>(name: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authCuratorGroupsRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getWeb3authCuratorGroupsRetrieveQueryKey(name);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof web3authCuratorGroupsRetrieve>>> = ({ signal }) => web3authCuratorGroupsRetrieve(name, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(name))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof web3authCuratorGroupsRetrieve>>, TError, TData> 
}

export type Web3authCuratorGroupsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof web3authCuratorGroupsRetrieve>>>
export type Web3authCuratorGroupsRetrieveQueryError = unknown

export const useWeb3authCuratorGroupsRetrieve = <TData = Awaited<ReturnType<typeof web3authCuratorGroupsRetrieve>>, TError = unknown>(
 name: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authCuratorGroupsRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeb3authCuratorGroupsRetrieveQueryOptions(name,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Returns full message to sign in response body.
 */
export const web3authMessageToSignRetrieve = (
    walletAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
      
      return customInstance<string>(
      {url: `/api/v1/web3auth/message_to_sign/${encodeURIComponent(String(walletAddress))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getWeb3authMessageToSignRetrieveQueryKey = (walletAddress: MaybeRef<string>,) => {
    return ['api','v1','web3auth','message_to_sign',walletAddress] as const;
    }

    
export const getWeb3authMessageToSignRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof web3authMessageToSignRetrieve>>, TError = unknown>(walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authMessageToSignRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getWeb3authMessageToSignRetrieveQueryKey(walletAddress);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof web3authMessageToSignRetrieve>>> = ({ signal }) => web3authMessageToSignRetrieve(walletAddress, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof web3authMessageToSignRetrieve>>, TError, TData> 
}

export type Web3authMessageToSignRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof web3authMessageToSignRetrieve>>>
export type Web3authMessageToSignRetrieveQueryError = unknown

export const useWeb3authMessageToSignRetrieve = <TData = Awaited<ReturnType<typeof web3authMessageToSignRetrieve>>, TError = unknown>(
 walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authMessageToSignRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeb3authMessageToSignRetrieveQueryOptions(walletAddress,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const web3authNotificationSettingsRetrieve = (
    walletAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
      
      return customInstance<NotificationSettingsBackendSchema>(
      {url: `/api/v1/web3auth/notification_settings/${encodeURIComponent(String(walletAddress))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getWeb3authNotificationSettingsRetrieveQueryKey = (walletAddress: MaybeRef<string>,) => {
    return ['api','v1','web3auth','notification_settings',walletAddress] as const;
    }

    
export const getWeb3authNotificationSettingsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof web3authNotificationSettingsRetrieve>>, TError = unknown>(walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authNotificationSettingsRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getWeb3authNotificationSettingsRetrieveQueryKey(walletAddress);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof web3authNotificationSettingsRetrieve>>> = ({ signal }) => web3authNotificationSettingsRetrieve(walletAddress, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof web3authNotificationSettingsRetrieve>>, TError, TData> 
}

export type Web3authNotificationSettingsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof web3authNotificationSettingsRetrieve>>>
export type Web3authNotificationSettingsRetrieveQueryError = unknown

export const useWeb3authNotificationSettingsRetrieve = <TData = Awaited<ReturnType<typeof web3authNotificationSettingsRetrieve>>, TError = unknown>(
 walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authNotificationSettingsRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeb3authNotificationSettingsRetrieveQueryOptions(walletAddress,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const web3authNotificationSettingsCreate = (
    walletAddress: MaybeRef<string>,
    notificationSettingsRequestBackendSchema: MaybeRef<NotificationSettingsRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      walletAddress = unref(walletAddress);
notificationSettingsRequestBackendSchema = unref(notificationSettingsRequestBackendSchema);
      
      return customInstance<NotificationSettingsBackendSchema>(
      {url: `/api/v1/web3auth/notification_settings/${encodeURIComponent(String(walletAddress))}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: notificationSettingsRequestBackendSchema
    },
      options);
    }
  


export const getWeb3authNotificationSettingsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof web3authNotificationSettingsCreate>>, TError,{walletAddress: string;data: NotificationSettingsRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof web3authNotificationSettingsCreate>>, TError,{walletAddress: string;data: NotificationSettingsRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof web3authNotificationSettingsCreate>>, {walletAddress: string;data: NotificationSettingsRequestBackendSchema}> = (props) => {
          const {walletAddress,data} = props ?? {};

          return  web3authNotificationSettingsCreate(walletAddress,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type Web3authNotificationSettingsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof web3authNotificationSettingsCreate>>>
    export type Web3authNotificationSettingsCreateMutationBody = NotificationSettingsRequestBackendSchema
    export type Web3authNotificationSettingsCreateMutationError = unknown

    export const useWeb3authNotificationSettingsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof web3authNotificationSettingsCreate>>, TError,{walletAddress: string;data: NotificationSettingsRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof web3authNotificationSettingsCreate>>,
        TError,
        {walletAddress: string;data: NotificationSettingsRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getWeb3authNotificationSettingsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Takes a set of user credentials and returns an access and refresh JSON web
token pair to prove the authentication of those credentials.
 */
export const web3authTokenCreate = (
    walletAddress: MaybeRef<string>,
    web3TokenObtainPairRequestBackendSchema: MaybeRef<Web3TokenObtainPairRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      walletAddress = unref(walletAddress);
web3TokenObtainPairRequestBackendSchema = unref(web3TokenObtainPairRequestBackendSchema);
      
      return customInstance<Web3TokenObtainPairBackendSchema>(
      {url: `/api/v1/web3auth/token/${encodeURIComponent(String(walletAddress))}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: web3TokenObtainPairRequestBackendSchema
    },
      options);
    }
  


export const getWeb3authTokenCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof web3authTokenCreate>>, TError,{walletAddress: string;data: Web3TokenObtainPairRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof web3authTokenCreate>>, TError,{walletAddress: string;data: Web3TokenObtainPairRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof web3authTokenCreate>>, {walletAddress: string;data: Web3TokenObtainPairRequestBackendSchema}> = (props) => {
          const {walletAddress,data} = props ?? {};

          return  web3authTokenCreate(walletAddress,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type Web3authTokenCreateMutationResult = NonNullable<Awaited<ReturnType<typeof web3authTokenCreate>>>
    export type Web3authTokenCreateMutationBody = Web3TokenObtainPairRequestBackendSchema
    export type Web3authTokenCreateMutationError = unknown

    export const useWeb3authTokenCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof web3authTokenCreate>>, TError,{walletAddress: string;data: Web3TokenObtainPairRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof web3authTokenCreate>>,
        TError,
        {walletAddress: string;data: Web3TokenObtainPairRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getWeb3authTokenCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const web3authTokenRefreshCreate = (
    walletAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,) => {
      walletAddress = unref(walletAddress);
      
      return customInstance<CookieTokenRefreshBackendSchema>(
      {url: `/api/v1/web3auth/token/refresh/${encodeURIComponent(String(walletAddress))}/`, method: 'POST'
    },
      options);
    }
  


export const getWeb3authTokenRefreshCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof web3authTokenRefreshCreate>>, TError,{walletAddress: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof web3authTokenRefreshCreate>>, TError,{walletAddress: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof web3authTokenRefreshCreate>>, {walletAddress: string}> = (props) => {
          const {walletAddress} = props ?? {};

          return  web3authTokenRefreshCreate(walletAddress,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type Web3authTokenRefreshCreateMutationResult = NonNullable<Awaited<ReturnType<typeof web3authTokenRefreshCreate>>>
    
    export type Web3authTokenRefreshCreateMutationError = unknown

    export const useWeb3authTokenRefreshCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof web3authTokenRefreshCreate>>, TError,{walletAddress: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof web3authTokenRefreshCreate>>,
        TError,
        {walletAddress: string},
        TContext
      > => {

      const mutationOptions = getWeb3authTokenRefreshCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const web3authUserProfileRetrieve = (
    walletAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
      
      return customInstance<UserProfileResponseBackendSchema>(
      {url: `/api/v1/web3auth/user_profile/${encodeURIComponent(String(walletAddress))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getWeb3authUserProfileRetrieveQueryKey = (walletAddress: MaybeRef<string>,) => {
    return ['api','v1','web3auth','user_profile',walletAddress] as const;
    }

    
export const getWeb3authUserProfileRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof web3authUserProfileRetrieve>>, TError = unknown>(walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authUserProfileRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getWeb3authUserProfileRetrieveQueryKey(walletAddress);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof web3authUserProfileRetrieve>>> = ({ signal }) => web3authUserProfileRetrieve(walletAddress, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof web3authUserProfileRetrieve>>, TError, TData> 
}

export type Web3authUserProfileRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof web3authUserProfileRetrieve>>>
export type Web3authUserProfileRetrieveQueryError = unknown

export const useWeb3authUserProfileRetrieve = <TData = Awaited<ReturnType<typeof web3authUserProfileRetrieve>>, TError = unknown>(
 walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authUserProfileRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeb3authUserProfileRetrieveQueryOptions(walletAddress,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const web3authUserProfileCreate = (
    walletAddress: MaybeRef<string>,
    userProfileRequestRequestBackendSchema: MaybeRef<UserProfileRequestRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      walletAddress = unref(walletAddress);
userProfileRequestRequestBackendSchema = unref(userProfileRequestRequestBackendSchema);
      
      return customInstance<UserProfileResponseBackendSchema>(
      {url: `/api/v1/web3auth/user_profile/${encodeURIComponent(String(walletAddress))}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userProfileRequestRequestBackendSchema
    },
      options);
    }
  


export const getWeb3authUserProfileCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof web3authUserProfileCreate>>, TError,{walletAddress: string;data: UserProfileRequestRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof web3authUserProfileCreate>>, TError,{walletAddress: string;data: UserProfileRequestRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof web3authUserProfileCreate>>, {walletAddress: string;data: UserProfileRequestRequestBackendSchema}> = (props) => {
          const {walletAddress,data} = props ?? {};

          return  web3authUserProfileCreate(walletAddress,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type Web3authUserProfileCreateMutationResult = NonNullable<Awaited<ReturnType<typeof web3authUserProfileCreate>>>
    export type Web3authUserProfileCreateMutationBody = UserProfileRequestRequestBackendSchema
    export type Web3authUserProfileCreateMutationError = unknown

    export const useWeb3authUserProfileCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof web3authUserProfileCreate>>, TError,{walletAddress: string;data: UserProfileRequestRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof web3authUserProfileCreate>>,
        TError,
        {walletAddress: string;data: UserProfileRequestRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getWeb3authUserProfileCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const web3authUserProfileStatsRetrieve = (
    walletAddress: MaybeRef<string>,
    params?: MaybeRef<Web3authUserProfileStatsRetrieveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
params = unref(params);
      
      return customInstance<WalletStatsBackendSchema>(
      {url: `/api/v1/web3auth/user_profile/stats/${encodeURIComponent(String(walletAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getWeb3authUserProfileStatsRetrieveQueryKey = (walletAddress: MaybeRef<string>,
    params?: MaybeRef<Web3authUserProfileStatsRetrieveParams>,) => {
    return ['api','v1','web3auth','user_profile','stats',walletAddress, ...(params ? [params]: [])] as const;
    }

    
export const getWeb3authUserProfileStatsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof web3authUserProfileStatsRetrieve>>, TError = unknown>(walletAddress: MaybeRef<string>,
    params?: MaybeRef<Web3authUserProfileStatsRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authUserProfileStatsRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getWeb3authUserProfileStatsRetrieveQueryKey(walletAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof web3authUserProfileStatsRetrieve>>> = ({ signal }) => web3authUserProfileStatsRetrieve(walletAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof web3authUserProfileStatsRetrieve>>, TError, TData> 
}

export type Web3authUserProfileStatsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof web3authUserProfileStatsRetrieve>>>
export type Web3authUserProfileStatsRetrieveQueryError = unknown

export const useWeb3authUserProfileStatsRetrieve = <TData = Awaited<ReturnType<typeof web3authUserProfileStatsRetrieve>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    params?: MaybeRef<Web3authUserProfileStatsRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authUserProfileStatsRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeb3authUserProfileStatsRetrieveQueryOptions(walletAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const web3authWalletsList = (
    params?: MaybeRef<Web3authWalletsListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedWalletRoleListBackendSchema>(
      {url: `/api/v1/web3auth/wallets/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getWeb3authWalletsListQueryKey = (params?: MaybeRef<Web3authWalletsListParams>,) => {
    return ['api','v1','web3auth','wallets', ...(params ? [params]: [])] as const;
    }

    
export const getWeb3authWalletsListQueryOptions = <TData = Awaited<ReturnType<typeof web3authWalletsList>>, TError = unknown>(params?: MaybeRef<Web3authWalletsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authWalletsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getWeb3authWalletsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof web3authWalletsList>>> = ({ signal }) => web3authWalletsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof web3authWalletsList>>, TError, TData> 
}

export type Web3authWalletsListQueryResult = NonNullable<Awaited<ReturnType<typeof web3authWalletsList>>>
export type Web3authWalletsListQueryError = unknown

export const useWeb3authWalletsList = <TData = Awaited<ReturnType<typeof web3authWalletsList>>, TError = unknown>(
 params?: MaybeRef<Web3authWalletsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authWalletsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeb3authWalletsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const web3authWalletsRetrieve = (
    walletAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
      
      return customInstance<WalletRoleBackendSchema>(
      {url: `/api/v1/web3auth/wallets/${encodeURIComponent(String(walletAddress))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getWeb3authWalletsRetrieveQueryKey = (walletAddress: MaybeRef<string>,) => {
    return ['api','v1','web3auth','wallets',walletAddress] as const;
    }

    
export const getWeb3authWalletsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof web3authWalletsRetrieve>>, TError = unknown>(walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authWalletsRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getWeb3authWalletsRetrieveQueryKey(walletAddress);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof web3authWalletsRetrieve>>> = ({ signal }) => web3authWalletsRetrieve(walletAddress, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof web3authWalletsRetrieve>>, TError, TData> 
}

export type Web3authWalletsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof web3authWalletsRetrieve>>>
export type Web3authWalletsRetrieveQueryError = unknown

export const useWeb3authWalletsRetrieve = <TData = Awaited<ReturnType<typeof web3authWalletsRetrieve>>, TError = unknown>(
 walletAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof web3authWalletsRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getWeb3authWalletsRetrieveQueryOptions(walletAddress,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



