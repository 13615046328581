import { computed, ref } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'
import { isEns } from '@/utils/utils'
import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import type { BaseOffer } from '@/modules/common/pwn/offers/OfferClasses'
import { isAddress } from 'viem'
import type { Address } from 'viem'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import { useThrottle } from '@vueuse/core'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { getAddressProfile, resolveAddressName } from '@/modules/common/web3/useAddressProfile'
import type { AddressProfile } from '@/modules/common/web3/useAddressProfile'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { isStarknetAddress } from '@/modules/common/web3/useCustomAccount'
import { parseAddress } from '@/utils/address-utils'

export enum DashboardComponent {
  Assets = 'assets',
  Lending = 'lending',
  Borrowing = 'borrowing',
}

const dashboardComponentToDisplay = ref<DashboardComponent>(isStarknet ? DashboardComponent.Lending : DashboardComponent.Assets)
const searchTerm = ref('')

const debouncedSearchTerm = useThrottle(searchTerm, 500)

const dashboardUserAddress = ref<Address>()
const ensName = ref<AddressProfile>()

const isValidUser = ref(false)
const loadedForUserAddress = ref<string | null>(null)
const isCheckingValidUser = ref(false)

const unfundedBorrowingToDisplay = ref<BaseLoan[]>([])
const fundedBorrowingToDisplay = ref<BaseLoan[]>([])
const lendingToDisplay = ref<BaseLoan[]>([])
const offersToDisplay = ref<BaseOffer[]>([])

export default function useDashboard() {
  const resetLoans = () => {
    unfundedBorrowingToDisplay.value = []
    fundedBorrowingToDisplay.value = []
    lendingToDisplay.value = []
  }

  const resetOffers = () => {
    offersToDisplay.value = []
  }

  const reset = () => {
    resetLoans()
    resetOffers()
  }

  const selectedChainsStore = useSelectedChainsStore()
  const { selectedChains } = storeToRefs(selectedChainsStore)

  const checkAndParseUser = async (user: string) => {
    isCheckingValidUser.value = true
    try {
      if (isStarknet && isStarknetAddress(user)) {
        // @ts-expect-error TS2322: Type `0x${string}` | undefined is not assignable to type `0x${string}
        dashboardUserAddress.value = user
        // @ts-expect-error TS2322: Type `0x${string}` | undefined is not assignable to type `0x${string}
        ensName.value = { address: dashboardUserAddress.value, name: user, avatar: null }
        isValidUser.value = true
      }
      if (isEns(user)) {
        const resolvedAddress = await resolveAddressName(user)
        if (resolvedAddress && isAddress(resolvedAddress)) {
          dashboardUserAddress.value = parseAddress(resolvedAddress)
          const addressProfile = await getAddressProfile(resolvedAddress)
          ensName.value = addressProfile || { address: dashboardUserAddress.value, name: user, avatar: null }
          isValidUser.value = true
        } else {
          isValidUser.value = false
        }
      } else if (parseAddress(user)) {
        dashboardUserAddress.value = parseAddress(user)
        const addressProfile = await getAddressProfile(dashboardUserAddress.value)
        ensName.value = addressProfile || { address: dashboardUserAddress.value, name: null, avatar: null }
        isValidUser.value = true
      } else {
        isValidUser.value = false
      }
    } catch (error) {
      isValidUser.value = false
    } finally {
      isCheckingValidUser.value = false
    }
    return dashboardUserAddress.value
  }

  const loadAnotherUser = async (userAddress: Address) => {
    loadedForUserAddress.value = userAddress
  }

  const initPage = async (route: RouteLocationNormalized) => {
    const parsedUserAddress = await checkAndParseUser(route.params.user as string)
    if (!isValidUser.value || !parsedUserAddress) {
      return
    }

    const { address: userAddress } = getAccount(pwnWagmiConfig)
    if (parsedUserAddress !== userAddress) {
      await loadAnotherUser(parsedUserAddress)
    } else {
      await loadAnotherUser(userAddress)
    }
  }

  return {
    initPage,
    dashboardComponentToDisplay,
    searchTerm,
    debouncedSearchTerm,
    dashboardUserAddress,
    ensName,
    isValidUser,
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    unfundedBorrowingToDisplay: computed(() => unfundedBorrowingToDisplay.value.filter(loan => selectedChains.value !== 'all' ? selectedChains.value.includes(loan.chainId) : true)),
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    fundedBorrowingToDisplay: computed(() => fundedBorrowingToDisplay.value.filter(loan => selectedChains.value !== 'all' ? selectedChains.value.includes(loan.chainId) : true)),
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    lendingToDisplay: computed(() => lendingToDisplay.value.filter(loan => selectedChains.value !== 'all' ? selectedChains.value.includes(loan.chainId) : true)),
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    offersToDisplay: computed(() => offersToDisplay.value.filter(offer => selectedChains.value !== 'all' ? selectedChains.value.includes(offer.chainId) : true)),
    isCheckingValidUser,
    reset,
    checkAndParseUser,
  }
}
