<template>
  <BaseModal
    v-model:is-open="isOpen"
    heading="Boost & Score Overview"
    heading-align="left"
    :size="ModalSize.Large">
    <template #trigger>
      <slot
        name="trigger-modal"
        :open-modal="openModal">
        <BaseButton
          is-full-width
          :variant="ButtonVariant.Outline"
          :color="ButtonColor.Primary"
          :font="ButtonFont.Screener"
          :is-disabled="isLoading"
          button-text="Boost Your Rank"
          @click="isOpen = true">
          <template #iconBeforeText>
            <LightningSvg
              width="19"
              height="19"
              class="achievements-modal__lightning-icon achievements-modal__lightning-icon--trigger"
              alt="boost"/>
          </template>
        </BaseButton>
      </slot>
    </template>

    <template #body>
      <div class="achievements-modal__body">
        <AchievementsModal/>
        <BoostModal/>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import ModalSize from '@/general-components/ModalSize'
import BaseModal from '@/revamp/components/RevampBaseModal.vue'
import BaseButton, { ButtonColor, ButtonFont, ButtonVariant } from '@/general-components/BaseButton.vue'
import { computed, ref } from 'vue'
import LightningSvg from '@/assets/icons/lightning.svg'
import BoostModal from '@/revamp/components/modals/achievements-and-boost/BoostModal.vue'
import AchievementsModal from '@/revamp/components/modals/achievements-and-boost/AchievementsModal.vue'
import { useUserReputationScoreWithParametersRetrive } from '@/modules/common/backend/generated'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const isOpen = ref(false)

const { address: userAddress } = useCustomAccount()
const connectedUserRepQuery = useUserReputationScoreWithParametersRetrive(
  userAddress.value || '', { refresh: true },
)
const isLoading = computed(() => connectedUserRepQuery.isFetching.value)

const openModal = () => {
  isOpen.value = true
}

</script>

<style scoped>
.achievements-modal {
  &__body {
    width: 62rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__lightning-icon {
    fill: var(--primary-color-1);
  }

  &__lightning-icon--trigger {
    margin: 0 0.5rem 0 0;
  }
}
</style>
