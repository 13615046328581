<template>
  <div class="pwn-safe-transfer-tokens">
    <PwnSafeAssetTable
      v-model:active-sort-option="selectedNftsSortOption"
      class="pwn-safe-transfer-tokens__assets"
      :sorted-assets="sortedNftsToDisplay"
      heading="NFTs "
      is-row-selectable
      :is-asset-selected="handleIsAssetSelected"
      :is-row-disabled="canAssetBeSelected"
      :is-fetching-assets=" props.isFetchingNfts"
      :table-definition="TRANSFER_OR_TOKENIZE_ASSET_TABLE_DEFINITION"
      @on-asset-select="handleOnAssetSelect"/>
    <PwnSafeAssetTable
      v-model:active-sort-option="selectedCoinsSortOption"
      class="pwn-safe-transfer-tokens__assets"
      :sorted-assets="sortedTokensToDisplay"
      heading="Tokens "
      is-row-selectable
      :is-asset-selected="handleIsAssetSelected"
      :is-row-disabled="canAssetBeSelected"
      :is-fetching-assets=" props.isFetchingTokens"
      :table-definition="TRANSFER_OR_TOKENIZE_ASSET_TABLE_DEFINITION"
      @on-asset-select="handleOnAssetSelect"/>
    <PwnSafeAtrTable
      v-model:active-sort-option="selectedAtrTokensSortOption"
      class="pwn-safe-transfer-tokens__atr-tokens"
      :sorted-atr-tokens="sortedAtrsToDisplay"
      heading="ATRs "
      is-row-selectable
      :is-asset-selected="handleIsAssetSelected"
      :table-definition="TRANSFER_ATR_TABLE_DEFINITION"
      :display-coins="displayCoins"
      :is-fetching-assets=" props.isFetchingNfts"
      :display-nfts="displayNfts"
      :is-row-disabled="canAssetBeSelected"
      @on-switch-display-nfts="handleOnSwitchDisplayNfts"
      @on-switch-display-coins="handleOnSwitchDisplayCoins"
      @on-atr-select="handleOnAssetSelect"/>
  </div>
</template>

<script lang="ts" setup>
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import PwnSafeAssetTable from '@/modules/pages/pwn-safe/tables/PwnSafeAssetTable.vue'
import PwnSafeAtrTable from '@/modules/pages/pwn-safe/tables/PwnSafeAtrTable.vue'
import { TRANSFER_ATR_TABLE_DEFINITION, TRANSFER_OR_TOKENIZE_ASSET_TABLE_DEFINITION } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useYourCoins from '@/modules/sections/your-assets/your-coins/useYourCoins'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import usePwnSafeAssetsDashboard from '@/modules/pages/pwn-safe/usePwnSafeAssetsDashboard'
import { computed } from 'vue'

type Props = {
  nonAtrNfts: AssetWithAmount[];
  atrNfts: AssetWithAmount[];
  safeTokens: AssetWithAmount[];
  isFetchingTokens: boolean;
  isFetchingNfts: boolean;
}
const props = defineProps<Props>()

const { searchTerm, assetsToTransfer, displayCoins, displayNfts, handleOnAssetSelect } = usePwnSafeTransfer()

const { selectedSortOption: selectedNftsSortOption, getSortedAndFilteredNFTs } = useYourNFTs()
const { selectedSortOption: selectedCoinsSortOption, getSortedAndFilteredCoins } = useYourCoins()
const { selectedSortOption: selectedAtrTokensSortOption, getSortedAndFilteredATRs } = usePwnSafeAssetsDashboard()
const { isAssetSelected } = usePwnSafeTransfer()

const sortedNftsToDisplay = computed(() => {
  return getSortedAndFilteredNFTs(props.nonAtrNfts, searchTerm.value, selectedNftsSortOption.value)
})

const sortedAtrsToDisplay = computed(() => {
  return getSortedAndFilteredATRs(props.atrNfts, searchTerm.value, selectedAtrTokensSortOption.value)
})

const sortedTokensToDisplay = computed(() => {
  return getSortedAndFilteredCoins(props.safeTokens, searchTerm.value, selectedCoinsSortOption.value)
})

const handleIsAssetSelected = (asset: AssetWithAmount) => {
  if (asset.isFungible) asset.amountInput = asset.maxAvailableAmount
  return isAssetSelected({ asset, selectedAssets: assetsToTransfer })
}

const handleOnSwitchDisplayNfts = (val: boolean) => {
  displayNfts.value = val
}

const handleOnSwitchDisplayCoins = (val: boolean) => {
  displayCoins.value = val
}
const { sortedAndFilteredAssetsToTransfer } = usePwnSafeTransfer()

const canAssetBeSelected = (asset: AssetWithAmount) => {
  if (sortedAndFilteredAssetsToTransfer.value.length >= 1) {
    const selectedChain = sortedAndFilteredAssetsToTransfer.value[0]?.chainId
    return asset.chainId !== selectedChain
  }
  return false
}

</script>

<style scoped>
.pwn-safe-transfer-tokens {
  width: 100%;
  display: flex;
  gap: calc(2.25rem - 1.5rem);
  margin-bottom: 3rem;

  &__assets {
    max-width: 21.625rem;
  }

  &__atr-tokens {
    min-width: 40%;
  }

  @media only screen and (--mobile-viewport) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
</style>
