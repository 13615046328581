/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import {
  V1_2ProposalType,
  V1_2SimpleLoanDutchAuctionProposal,
  V1_2SimpleLoanFungibleProposal,
  V1_2SimpleLoanSimpleListProposal,
  V1_2SimpleLoanSimpleProposal,
} from './ProposalClasses'
import { formatAmountWithDecimals } from '@/utils/utils'
import DateUtils from '@/utils/DateUtils'
import AssetType from '@/modules/common/assets/AssetType'
import { transformKeys, toCamelCase } from '@/revamp/modules/proposals/types'
import { calculateCollateralAmountFungibleProposal } from '@/modules/common/pwn/utils'
import { readErc20Allowance } from '@/contracts/generated'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import type { Address } from 'viem'
import type { SupportedChain } from '@/constants/chains/types'
import { getAtokenAddress } from '@/constants/depositedAssets'
import { getPoolAdapter } from '@/contracts/abis'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { readAnyERC20Allowance } from '@/modules/common/adapters/erc20-utils'

export default class ProposalFactory {
  public static async maxAcceptableCreditOfFungibleProposal ({
    creditAssetAddress,
    chainId,
    proposer,
    loanContract,
    remainingCreditFungibleProposal,
    returnBigInt,
    isCreatingProposal,
    sourceOfFunds,
  }: {
    creditAssetAddress: Address
    chainId: SupportedChain
    proposer: Address
    loanContract: Address
    remainingCreditFungibleProposal: bigint
    returnBigInt?: boolean
    isCreatingProposal?: boolean
    sourceOfFunds?: Address
  }): Promise<string | bigint> {
    let aTokenAddress : Address | undefined
    let poolAdapter: Address | undefined
    if (sourceOfFunds) {
      aTokenAddress = getAtokenAddress(creditAssetAddress, chainId, sourceOfFunds)
      poolAdapter = await getPoolAdapter(pwnWagmiConfig, {
        address: CHAINS_CONSTANTS[String(chainId)].pwnV1_2Contracts.pwnConfig,
        chainId,
        args: [sourceOfFunds],
      })
      if (!aTokenAddress || !poolAdapter) {
        // If this error is thrown, it means that we are missing definition for aToken address for the given source of funds/We haven't registered the pool adapter
        throw new Error(`aToken address or Pool Adapter address not found for ${creditAssetAddress} on chain ${chainId} and source of funds ${sourceOfFunds}`)
      }
    }

    const proposerAllowancePromise = await readAnyERC20Allowance(creditAssetAddress, chainId, proposer, loanContract)

    // If it's LLL proposal, we also need to check allowance for poolAdapter, instead of loanContract for the aToken
    const proposerAtokenAllowancePromise = aTokenAddress && poolAdapter
      ? readErc20Allowance(pwnWagmiConfig, {
        address: aTokenAddress,
        chainId,
        args: [proposer, poolAdapter],
      })
      : undefined

    // If it's LLL proposal, we do not care about the balance of underlying asset, because it's withdrawn from pool once the proposal is created
    const proposerBalancePromise = useERC20Fetch().fetchUserERC20Balance(
      chainId,
      aTokenAddress || creditAssetAddress,
      proposer,
    )

    const [proposerAllowance, proposerBalance, proposerAtokenAllowance] = await Promise.all([proposerAllowancePromise, proposerBalancePromise, proposerAtokenAllowancePromise])

    // If it's LLL proposal, we assign minimum of either the aToken allowance or the underlying asset allowance since both are needed
    const minAllowance = proposerAtokenAllowance && proposerAllowance > proposerAtokenAllowance ? proposerAtokenAllowance : proposerAllowance
    const maxAcceptableAmountOfProposer = (minAllowance > proposerBalance) || isCreatingProposal ? proposerBalance : minAllowance

    const maxAcceptableCredit = BigInt(remainingCreditFungibleProposal) < maxAcceptableAmountOfProposer ? BigInt(remainingCreditFungibleProposal) : maxAcceptableAmountOfProposer
    if (returnBigInt) {
      return maxAcceptableCredit
    } else {
      return maxAcceptableCredit.toString()
    }
  }

  public static async createProposalFromBackendModel(proposalMaybeSnakeCase, collateralProp?: AssetWithAmount, creditAssetProp?: AssetWithAmount, isCreatingProposal?: boolean): Promise<
    V1_2SimpleLoanDutchAuctionProposal |
    V1_2SimpleLoanSimpleProposal |
    V1_2SimpleLoanFungibleProposal |
    V1_2SimpleLoanSimpleListProposal> {
    const proposal: any = transformKeys(proposalMaybeSnakeCase, toCamelCase)

    // TODO rather remove and resolve missing decimals in the BE?
    const fallbackErc20DecimalsCollateral = proposal?.collateral?.decimals ?? 18
    const decimalsFallbackCollateral = proposal?.collateral?.category === AssetType.ERC20 ? fallbackErc20DecimalsCollateral : null
    let parsedProposal: V1_2SimpleLoanDutchAuctionProposal | V1_2SimpleLoanSimpleProposal | V1_2SimpleLoanFungibleProposal | V1_2SimpleLoanSimpleListProposal

    const remainingCreditFungibleProposal = proposal?.creditData?.amount ?? proposal.availableCreditLimit

    const maxAcceptableCreditOfFungibleProposalInCreateProposal = async (returnBigInt?: boolean) => {
      if (!proposal.isOffer) {
        if (returnBigInt) {
          return BigInt(remainingCreditFungibleProposal)
        } else {
          return remainingCreditFungibleProposal.toString()
        }
      }
      return await ProposalFactory.maxAcceptableCreditOfFungibleProposal({
        creditAssetAddress: proposal.creditAsset?.address || proposal.creditAddress,
        chainId: proposal.chainId,
        proposer: proposal.proposer,
        loanContract: proposal.loanContract,
        remainingCreditFungibleProposal,
        returnBigInt,
        isCreatingProposal,
        sourceOfFunds: proposal.sourceOfFunds,
      })
    }

    const getCollateralAmountForFungibleProposal = async (returnBigInt: boolean) => {
      const creditPerCollateralUnit = proposal?.creditData?.creditPerCollateralUnit ?? proposal.creditPerCollateralUnit
      return calculateCollateralAmountFungibleProposal({
        creditPerCollateralUnit,
        collateralDecimals: collateralProp?.decimals ?? proposal.collateral.decimals,
        availableCreditLimit: await maxAcceptableCreditOfFungibleProposalInCreateProposal(false),
        returnBigInt,
      })
    }

    const collateralAmountInAsset = async () => {
      if (proposal.category === AssetType.ERC721) {
        return '1'
      }
      if (proposal.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
        return await getCollateralAmountForFungibleProposal(false) as string
      }
      return formatAmountWithDecimals(BigInt(proposal.collateralAmount), proposal.collateral?.decimals ?? 0)
    }

    const collateral = new AssetWithAmount({
      id: proposal.collateral?.id || 12345,
      address: proposal.collateral?.address,
      // TODO check if fallback 0 is without issues
      amount: await collateralAmountInAsset(),
      chainId: proposal.collateral?.chainId,
      tokenId: proposal.collateral?.tokenId,
      category: proposal.collateral?.category,
      decimals: decimalsFallbackCollateral,
      isVerified: proposal.collateral?.isVerified,
      name: proposal.collateral?.name,
      image: proposal.collateral?.thumbnailUrl,
      isKycRequired: proposal?.collateral?.isKycRequired,
      symbol: proposal?.collateral?.symbol,
    })

    // TODO check if fallback 18 is without issues
    const creditAmountInAsset = proposal.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL
      ? formatAmountWithDecimals(await maxAcceptableCreditOfFungibleProposalInCreateProposal(true) as bigint, proposal.creditAsset?.decimals ?? 18)
      : formatAmountWithDecimals(BigInt(proposal.creditAmount ?? proposal?.creditData?.amount), proposal.creditAsset?.decimals ?? 18)

    const creditAsset = new AssetWithAmount({
      id: proposal.collateral?.id || 12345,
      address: proposal.creditAsset?.address,
      amount: creditAmountInAsset,
      category: proposal.creditAsset?.category,
      chainId: proposal.creditAsset?.chainId,
      decimals: proposal.creditAsset?.decimals,
      image: proposal.creditAsset?.thumbnailUrl,
      isKycRequired: proposal.creditAsset?.isKycRequired,
      isVerified: proposal.creditAsset?.isVerified,
      name: proposal.creditAsset?.name,
      tokenId: proposal.creditAsset?.tokenId,
      symbol: proposal.creditAsset?.symbol,
    })

    const collateralAmount = () => {
      if (proposal.category === AssetType.ERC721) {
        return 0n
      }
      if (proposal.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
        return getCollateralAmountForFungibleProposal(true)
      }
      return BigInt(proposal.collateralAmount)
    }

    const creditAmount = async () => {
      if (proposal.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
        return await maxAcceptableCreditOfFungibleProposalInCreateProposal(true) ?? 0n
      }
      return proposal.creditAmount ? BigInt(proposal.creditAmount) : proposal.creditData?.amount ? BigInt(proposal.creditData.amount) : 0n
    }

    const commonBase = {
      accruingInterestAPR: proposal.creditData?.accruingInterestApr ?? proposal.accruingInterestApr,
      allowedAcceptor: proposal.allowedAcceptor,
      availableCreditLimit: proposal.availableCreditLimit ? BigInt(proposal.availableCreditLimit) : 0n,
      chainId: proposal.chainId,
      checkCollateralStateFingerprint: proposal.checkCollateralStateFingerprint,
      collateral: collateralProp || collateral || new AssetWithAmount({
        id: 12345,
        address: proposal.collateralAddress,
        chainId: proposal.chainId,
        category: proposal.collateralCategory,
        tokenId: proposal.collateralId,
        symbol: proposal?.collateral?.symbol,
      }),
      collateralAmount: await collateralAmount(),
      collateralStateFingerprint: proposal.collateralStateFingerprint,
      createdAt: new Date(proposal.createdAt),
      creditAsset: creditAssetProp || creditAsset || new AssetWithAmount({
        id: 12345,
        address: proposal.creditAddress ?? proposal.creditAsset.address,
        chainId: proposal.chainId,
        category: AssetType.ERC20,
      }),
      creditAmount: await creditAmount(),
      fixedInterestAmount: proposal.creditData?.fixedInterestAmount ? BigInt(proposal.creditData.fixedInterestAmount) : 0n,
      id: proposal.id,
      isOffer: proposal.isOffer,
      isOnChain: proposal.isOnChain,
      loanContractAddress: proposal.loanContract,
      loanDurationDays: DateUtils.convertSecondsToDays(proposal.duration), // TODO change also in Class?
      multiproposalMerleRoot: proposal.multiproposalMerkleRoot,
      nonce: proposal.nonce ? BigInt(proposal.nonce) : 0n,
      nonceSpace: proposal.nonceSpace ? BigInt(proposal.nonceSpace) : 0n,
      proposalContractAddress: proposal.proposalContractAddress,
      proposalHash: proposal.hash,
      proposalSignature: proposal.signature,
      proposer: proposal.proposer,
      proposerSpecHash: proposal.proposerSpecHash,
      refinancingLoanId: proposal.refinancingLoanId ? BigInt(proposal.refinancingLoanId) : 0n,
      revokedAt: proposal.revokedAt,
      repaymentAmount: proposal.creditData?.totalRepaymentAmount,
      status: proposal.status, // is BigInt ?
      type: proposal.type,
      sourceOfFunds: proposal.sourceOfFunds,
    }

    switch (proposal.type) {
    case V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL:
      // @ts-expect-error TS(2322) FIXME: Type 'V1_2SimpleLoanSimpleProposal' is not
      parsedProposal = new V1_2SimpleLoanSimpleProposal({
        ...commonBase,
        expiration: proposal.expiration,
      })
      break
    case V1_2ProposalType.SIMPLE_LOAN_DUTCH_AUCTION_PROPOSAL:
      // @ts-expect-error TS(2322) FIXME: Type 'V1_2SimpleLoanDutchAuctionProposal' is not
      parsedProposal = new V1_2SimpleLoanDutchAuctionProposal({
        ...commonBase,
        minCreditAmount: proposal.minCreditAmount ? BigInt(proposal.minCreditAmount) : 0n,
        maxCreditAmount: proposal.maxCreditAmount ? BigInt(proposal.maxCreditAmount) : 0n,
        auctionDuration: proposal.expiration, // TODO resolve the real response from BE
        auctionStart: proposal.auctionStart,
      })
      break

    case V1_2ProposalType.SIMPLE_LOAN_LIST_PROPOSAL:
      // @ts-expect-error TS(2322) FIXME: Type 'V1_2SimpleLoanSimpleProposal' is not
      parsedProposal = new V1_2SimpleLoanSimpleListProposal({
        ...commonBase,
        expiration: proposal.expiration,
      })
      break

    case V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL:
      // @ts-expect-error TS(2322) FIXME: Type 'V1_2SimpleLoanSimpleProposal' is not
      parsedProposal = new V1_2SimpleLoanFungibleProposal({
        ...commonBase,
        expiration: proposal.expiration,
        minCollateralAmount: BigInt(proposal.minCollateralAmount),
        creditPerCollateralUnit: proposal.creditPerCollateralUnit ?? proposal.creditData.creditPerCollateralUnit,
        fullRemainingCreditAmount: remainingCreditFungibleProposal,
      })
      break

    default:
      // @ts-expect-error TS(2322) FIXME: Type 'V1_2SimpleLoanSimpleProposal' is not
      parsedProposal = new V1_2SimpleLoanSimpleProposal({
        ...commonBase,
        expiration: proposal.expiration,
      })
      break
    }

    return parsedProposal
  }
}
