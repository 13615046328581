<template>
  <div class="thesis-page">
    <div class="thesis-page__top-row">
      <BaseBackButton
        has-emit
        @go-back="handleGoBack"/>

      <h1 class="thesis-page__title">
        Strategy {{ formattedThesisId }}
      </h1>
    </div>

    <div class="thesis-page__description-container">
      <ThesisDetailDescription
        class="thesis-page__description"
        :thesis-date="thesis.createdAt ? `Updated: ${formatDate(thesis.modifiedAt)}` : ''"
        :thesis-name="thesis?.title"
        :thesis-identifier="thesis?.id"
        :thesis-description="thesis?.description">
        <slot>
          <ul class="thesis-page__description-stats">
            <li
              v-for="param in thesisParams"
              :key="param.id"
              class="thesis-page__description-stats-item">
              <span class="thesis-page__description-stats-item-title">{{ param.title }}</span>
              <BaseSkeletor v-if="isPending"/>
              <ThesisCurator
                v-else-if="param.id === 'curator'"
                :curator="thesis.curator?.name || 'Community'"
                :curator-description="thesis.curator?.description || ''"
                :curator-icon="thesis.curator?.avatar || ''"/>
              <Networks
                v-else-if="param.id === 'chains'"
                :chains="thesisChains"/>
              <span
                v-else
                class="thesis-page__description-stats-item-value">{{ param.value }}</span>
            </li>
          </ul>
        </slot>
      </ThesisDetailDescription>

      <ThesisCommit
        class="thesis-page__committed"
        :on-update="handleUpdateUserThesisStats"
        :hide-revoke="!userHasCommitedFunds"
        :thesis="thesis as ThesisSchemaWorkaroundBackendSchema"
        :thesis-stats-data="userThesisStatsData"/>
    </div>

    <div class="thesis-page__content">
      <div class="thesis-page__tabs">
        <button
          v-if="userHasCommitedFunds"
          class="thesis-page__tab"
          :class="{
            'thesis-page__tab--active': activeTab === Tabs.Commitments
          }"
          @click="() => activeTab = Tabs.Commitments">
          Your Committment ({{ Object.keys(nonEmptyUserStats).length }})
        </button>

        <button
          class="thesis-page__tab"
          :class="{
            'thesis-page__tab--active': activeTab === Tabs.Collateral
          }"
          @click="() => activeTab = Tabs.Collateral">
          Accepted Collateral ({{ thesisCollateralAssets?.length }})
        </button>

        <button
          class="thesis-page__tab"
          :class="{
            'thesis-page__tab--active': activeTab === Tabs.Credit
          }"
          @click="() => activeTab = Tabs.Credit">
          Available Credit ({{ thesisCreditAssets?.length }})
        </button>
      </div>

      <div
        v-if="activeTab === Tabs.Commitments"
        class="thesis-page__commitments">
        <ThesisMyCommitments
          :thesis-stats-data="userThesisStatsData"
          :thesis="thesis"
          :on-update="handleUpdateUserThesisStats"
          :is-loading="isPending"/>
      </div>

      <div
        v-if="activeTab === Tabs.Collateral"
        class="thesis-page__collateral-assets">
        <ThesisCollateralAssetsTable
          :is-loading="false"
          :thesis="thesis"
          :data="thesisCollateralAssets as AssetMetadata[]"/>
      </div>

      <div
        v-if="activeTab === Tabs.Credit"
        class="thesis-page__credit-assets">
        <ThesisCreditAssetsTable
          :is-loading="false"
          :thesis-stats-data="thesisStatsData"
          :data="assetsWithTotalCommited as AssetWithAmount[]"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ThesisDetailDescription from '@/revamp/pages/thesis/ThesisDetailDescription.vue'
import ThesisCollateralAssetsTable from '@/revamp/pages/thesis/ThesisCollateralAssetsTable.vue'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import ThesisCreditAssetsTable from '@/revamp/pages/thesis/ThesisCreditAssetsTable.vue'
import { useGetThesis } from '@/revamp/hooks/thesis/useGetThesis'
import { useRoute } from 'vue-router'
import DateUtils from '@/utils/DateUtils'
import { computed, ref } from 'vue'
import router from '@/router'
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import ThesisCommit from '@/revamp/pages/thesis/ThesisCommit.vue'
import {
  getThesisListQueryOptions,
  useThesisDetail,
} from '@/modules/common/backend/generated'
import type { ThesisCreditStatsSchemaWorkaroundBackendSchema, ThesisListParams, ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { formatUnits } from 'viem'
import ThesisMyCommitments from './ThesisMyCommitments.vue'
import { useAssetListPrice } from '@/revamp/hooks/asset-prices/useAssetListPrice'
import { getThesisCollateralAssetsApr, getThesisCollateralAssetsLtv, parseThesisAsset } from '@/revamp/hooks/thesis/utils'
import type { ThesisCreditStatsData } from '@/revamp/hooks/thesis/utils'
import { useQueryClient } from '@tanstack/vue-query'
import { watchOnce } from '@vueuse/core'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import ThesisCurator from '@/revamp/components/tables/cells/thesis/ThesisCurator.vue'
import Networks from '@/revamp/components/Networks.vue'
import type { SupportedChain } from '@/constants/chains/types'
import { getDepositedAssetByAToken } from '@/constants/depositedAssets'
import { parseAddress } from '@/utils/address-utils'

enum Tabs {
  Collateral = 'collateral',
  Credit = 'credit',
  Commitments = 'commitments'
}
const { address } = useCustomAccount()

const route = useRoute()
const thesisId = route.params.id as string
const activeTab = ref(Tabs.Collateral)

const { thesis, thesisCollateralAssets, isFetched, isPending } = useGetThesis(thesisId)

const reactiveThesisId = computed(() => thesis.value.id)
const thesisChains = computed(() => [...new Set(thesis.value?.creditsStats?.map(v => v.creditAssetMetadata.chainId as SupportedChain))])

const totalThesisCreditStats = computed(() => {
  return thesis.value?.creditsStats
})

const userThesisQueryIsEnabled = computed(() => {
  if (!address.value) return false

  return parseAddress(address.value) && !!reactiveThesisId.value && isFetched.value
})

const userStatsParams = computed<ThesisListParams>(() => {
  return {
    user_address: address.value,
  }
})

const userThesisData = useThesisDetail(
  reactiveThesisId,
  userStatsParams,
  {
    query: {
      enabled: userThesisQueryIsEnabled,
    },
  },
)

const userThesisCreditStats = computed(() => {
  if (!userThesisQueryIsEnabled.value) {
    return []
  }
  return userThesisData.data.value?.data.creditsStats ?? []
})

const client = useQueryClient()

const handleUpdateThesisStats = (thesisId: string) => {
  const params = getThesisListQueryOptions(userStatsParams.value)

  client.invalidateQueries(params)
}

const handleUpdateUserThesisStats = (thesisId: string) => {
  handleUpdateThesisStats(thesisId)
  const thesisListParams = getThesisListQueryOptions(userStatsParams.value)
  const thesisListQuery = getThesisListQueryOptions()

  setTimeout(() => {
    client.invalidateQueries(thesisListParams)
    client.invalidateQueries(thesisListQuery)
  }, 300)
}

const filterOurUtilizedAmount = (v: ThesisCreditStatsSchemaWorkaroundBackendSchema) => {
  return v.amountsStats.totalCommittedAmount !== '0' && v.amountsStats.totalUtilizedAmount !== v.amountsStats.totalCommittedAmount
}

const processThesisCreditStats = (statsData: ThesisCreditStatsSchemaWorkaroundBackendSchema[] | undefined): ThesisCreditStatsData => {
  const result: ThesisCreditStatsData = {}

  if (statsData) {
    for (const data of statsData) {
      const uniqueKey = `${data.creditAssetMetadata.chainId}-${parseAddress(data.creditAssetMetadata.address)}`

      result[uniqueKey] = data
    }
  }

  return result
}

const thesisStatsData = computed(() => {
  return processThesisCreditStats(totalThesisCreditStats.value)
})

const thesisCreditAssets = computed(() => {
  return Object.values(thesisStatsData.value).map((v) => {
    const parsedAsset = parseThesisAsset(v.creditAssetMetadata)
    return parsedAsset.updateAssetAmounts({
      amount: v.amountsStats.totalCommittedAmount,
    })
  })
})

const userThesisStatsData = computed(() => {
  const filteredStats = userThesisCreditStats.value
  return processThesisCreditStats(filteredStats.filter(v => filterOurUtilizedAmount(v)))
})

const userHasCommitedFunds = computed(() => {
  return Object.values(userThesisStatsData.value).length > 0
})

watchOnce(userHasCommitedFunds, async (v) => {
  if (v) {
    activeTab.value = Tabs.Commitments
  }
})

const formattedThesisId = computed(() => {
  if (!thesis.value || thesis.value.id === undefined) {
    return ''
  }

  return `#${thesis.value?.id.padStart(4, '0')}`
})

const assetsWithTotalCommited = computed(() => {
  return Object.values(thesisStatsData.value).map((v) => {
    const parsedAsset = parseThesisAsset(v.creditAssetMetadata)

    const depositedAsset = getDepositedAssetByAToken(parsedAsset.address, parsedAsset.chainId)
    if (depositedAsset) {
      const creditData = thesisStatsData.value[`${parsedAsset.chainId}-${parseAddress(depositedAsset.underlyingToken)}`]
      if (!creditData) {
        throw new Error(`Underlying asset not found for ${depositedAsset.underlyingToken}`)
      }
      const underlyingAsset = parseThesisAsset(creditData.creditAssetMetadata)
      if (!underlyingAsset) {
        throw new Error(`Underlying asset not found for ${depositedAsset.underlyingToken}`)
      }

      const assetToAdd = new AssetWithAmount({
        ...underlyingAsset,
        sourceOfFunds: depositedAsset.pool,
        protocol: depositedAsset.protocol,
        aTokenAddress: depositedAsset.aToken,
      })
      return assetToAdd
    }

    return parsedAsset.updateAssetAmounts({
      amount: formatUnits(BigInt(v.amountsStats.totalCommittedAmount), v.creditAssetMetadata.decimals ?? 18),
    })
  })
})

const { summarizedAmount: totalCommitedAmount } = useAssetListPrice(assetsWithTotalCommited)

const assetsWithTotalUtilized = computed(() => {
  return Object.values(thesisStatsData.value).map((v) => {
    const parsedAsset = parseThesisAsset(v.creditAssetMetadata)
    return parsedAsset.updateAssetAmounts({
      amount: formatUnits(BigInt(v.amountsStats.totalUtilizedAmount), v.creditAssetMetadata.decimals ?? 18),
    })
  })
})

const { summarizedAmount: totalUtilizedAmount } = useAssetListPrice(assetsWithTotalUtilized)

const minMaxLtv = computed(() => {
  return getThesisCollateralAssetsLtv(thesis.value)
})

const minMaxApr = computed(() => {
  return getThesisCollateralAssetsApr(thesis.value)
})

const thesisParams = computed(() => {
  const params = [
    {
      title: 'LTV',
      value: minMaxLtv.value,
      id: 'ltv',
    },
    {
      title: 'APR',
      // value: `${thesis.value?.aprMin}% - ${thesis.value?.aprMax}%`,
      value: minMaxApr.value,
      id: 'apr',
    },
    {
      title: 'Duration',
      value: `${thesis.value?.loanDurationDays} days`,
      id: 'duration',
    },
    {
      title: 'Curator',
      value: 'Community',
      id: 'curator',
    },
  ]

  if (totalCommitedAmount.value.raw > 0) {
    params.splice(2, 0, {
      title: 'Total Committed',
      value: totalCommitedAmount.value.raw > 0 ? totalCommitedAmount.value.formatted : '---',
      id: 'committed',
    })
  }

  if (totalUtilizedAmount.value.raw > 0) {
    params.splice(3, 0, {
      title: 'Total Utilized to date',
      value: totalUtilizedAmount.value.raw > 0 ? totalUtilizedAmount.value.formatted : '---',
      id: 'utilization',
    })
  }

  params.splice(0, 0, {
    title: 'Chains',
    value: thesisChains.value.join(', '),
    id: 'chains',
  })

  return params
})

const handleGoBack = () => {
  router.go(-1)
}

const nonEmptyUserStats = computed(() => Object.values(userThesisStatsData.value).filter(v => v.amountsStats.totalCommittedAmount !== '0'))

const formatDate = (date: number) => {
  return DateUtils.displayDate(new Date(date * 1000), { dateStyle: 'long' })
}

</script>

<style lang="postcss" scoped>
.thesis-page {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  margin-top: 2.5rem;

  &__top-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__title {
    font-family: var(--font-family-screener);
    font-size: 1.875rem;
    margin-left: 2rem;

    @media only screen and (--small-viewport) {
      font-size: 1.25rem;
    }
  }

  &__title-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
  }

  &__description-container {
    margin-top: 0.5rem;

    display: flex;
    flex-flow: row nowrap;
    gap: 1.5rem;

    @media only screen and (max-width: 952px) {
      flex-flow: column nowrap;
    }

    @media only screen and (--mobile-viewport) {
      flex-flow: column nowrap;
    }
  }

  &__description {
    width: 100%;
  }

  &__committted {
    width: 100%;
  }

  &__content {
    padding: 1rem;
    border: 1px solid #434343;
  }

  &__tabs {
    display: flex;
    gap: 24px;
    margin-bottom: 2rem;

    width: 100%;
    justify-content: space-between;

    @media only screen and (--mobile-viewport) {
      flex-flow: row wrap;
      gap: 1rem;
    }
  }

  &__tab {
    text-align: center;
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--text-color);
    padding: 0.675rem 2rem;
    width: 100%;
    cursor: pointer;

    font-family: var(--font-family-screener);
    font-weight: 400;
    user-select: none;

    will-change: border-color, color;
    transition: border-color 0.3s linear,
      color 0.3s linear;

    &:hover {
      border-color: var(--primary-color-1);
    }

    &--active {
      border-color: var(--primary-color-1);
      color: var(--primary-color-1);
    }
  }

  &__description-stats {
    padding: 0;
    margin: 0;
    display: inline-flex;
    flex-flow: row nowrap;
    text-decoration: none;

    width: 100%;
    justify-content: space-between;

    flex: 0 1 100%;

    @media only screen and (--small-viewport) {
      flex-flow: column wrap;
      height: 150px;
    }

    @media only screen and (--mobile-viewport) {
      flex-flow: row wrap;
      gap: 1rem;
      height: 100%;
    }
  }

  &__description-stats-item {
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.25rem;

    @media only screen and (--small-viewport) {
      flex-flow: column nowrap;
      width: 100px;
      height: 60px;
    }

    @media only screen and (--mobile-viewport) {
      flex-flow: row wrap;
      gap: 0.25rem;
      align-items: center;
      width: 40%;
    }
  }

  &__description-stats-item-title {
    font-family: var(--font-family-supreme);
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #a4a4a4;
  }

  &__description-stats-item-value {
    font-family: var(--font-family-supreme);
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--text-color);
  }

  &__button {
    margin: auto;
    margin-right: 0;
  }

  &__collateral-assets {
    margin-top: 2rem;
  }
}
</style>
