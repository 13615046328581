<template>
  <div>
    <BaseModal
      v-model:is-open="isOpen"
      heading="Share Dashboard"
      :size="ModalSize.Small">
      <template #trigger>
        <BaseButton
          :color="ButtonColor.Gray"
          :variant="ButtonVariant.Outline"
          @click="isOpen = true">
          <template #icon>
            <ShareIconSvg
              width="20"
              height="20"
              alt="share icon"/>
          </template>
        </BaseButton>
      </template>
      <template #subheading>
        <h3 class="modal-share-dashboard__subheading">
          Share link via
        </h3>
      </template>
      <template #body>
        <div class="modal-share-dashboard__icons-container">
          <TwitterIconSvg
            class="modal-share-dashboard__icon modal-share-dashboard__twitter-icon"
            alt="twitter"
            height="40"
            width="40"
            @click="openTwitterIntent"/>
          <TelegramIconSvg
            class="modal-share-dashboard__icon"
            alt="telegram"
            height="40"
            width="40"
            @click="openTelegram"/>
        </div>
        <div class="modal-share-dashboard__link-heading">
          Or copy link
        </div>
        <div class="modal-share-dashboard__link-container">
          <div :class="['modal-share-dashboard__link', {'modal-share-dashboard__link--copied': isCopied}]">
            {{ linkToThisPage }}
          </div>
          <BaseButton
            is-full-width
            :font="ButtonFont.Screener"
            :size="ButtonSize.M"
            :button-text="buttonText"
            @on-button-click="copy">
            <template #icon>
              <CopyIconSvg
                class="modal-share-dashboard__icon"
                alt="copy"/>
            </template>
          </BaseButton>
        </div>
        <div class="modal-share-dashboard__button-container">
          <BaseButton
            button-text="Done"
            :size="ButtonSize.M"
            @click="isOpen = false"/>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script lang="ts" setup>
import BaseModal from '@/general-components/BaseModal.vue'
import BaseButton, { ButtonFont, ButtonSize, ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import { computed, ref } from 'vue'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { shortenAddress } from '@/utils/utils'
import ModalSize from '@/general-components/ModalSize'
import CopyIconSvg from '@/assets/icons/copy.svg'
import ShareIconSvg from '@/assets/icons/share.svg'
import TwitterIconSvg from '@/assets/icons/twitter.svg'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import TelegramIconSvg from '@/assets/icons/telegram.svg'

const props = defineProps<{
  user: string
}>()

const { address: userAddress } = useCustomAccount()
const { dashboardUserAddress } = useDashboard()
const isOpen = ref(false)
const isCopied = ref(false)
const linkToThisPage = computed(() => `${window?.location.host}/#/dashboard/${props.user || userAddress.value}`)
const buttonText = computed(() => isCopied.value ? 'Copied!' : 'Copy')

const copy = async () => {
  await navigator.clipboard.writeText(linkToThisPage.value)
  isCopied.value = true
  setTimeout(() => {
    isCopied.value = false
  }, 1500)
}

// @ts-expect-error FIXME: strictNullChecks
const getAlternateUserInfo = computed(() => props.user.includes('.eth') ? props.user : shortenAddress(props.user))

const openTwitterIntent = () => {
  if (userAddress.value === dashboardUserAddress.value) {
    window.open(`https://twitter.com/intent/tweet?text=Here%20are%20all%20of%20the%20assets%20that%20I%E2%80%99m%20borrowing%20and%2For%20lending%20on%20%40pwndao%20%F0%9F%91%80%20Check%20out%20my%20dashboard%3A%20app.pwn.xyz%2F%23%2Fdashboard%2F${props.user || userAddress.value}&hashtags=p2p,defi`, '_blank')
  } else {
    window.open(`https://twitter.com/intent/tweet?text=Hats%20off%20to%20${getAlternateUserInfo.value}%20for%20their%20awesome%20collection%20of%20assets%20on%20%40pwndao!%20%F0%9F%91%8F%20Check%20out%20their%20dashboard%3A%20app.pwn.xyz%2F%23%2Fdashboard%2F${props.user}&hashtags=p2p,defi`, '_blank')
  }
}

const openTelegram = () => {
  if (userAddress.value === dashboardUserAddress.value) {
    window.open(`https://t.me/share/url?url=app.pwn.xyz%2F%23%2Fdashboard%2F${props.user || userAddress.value}&text=Here%20are%20all%20of%20the%20assets%20that%20I%E2%80%99m%20borrowing%20and%2For%20lending%20on%20%40pwndao%20%F0%9F%91%80%20Check%20out%20my%20dashboard!`, '_blank')
  } else {
    window.open(`https://t.me/share/url?url=app.pwn.xyz%2F%23%2Fdashboard%2F${props.user}&text=Hats%20off%20to%20${getAlternateUserInfo.value}%20for%20their%20awesome%20collection%20of%20assets%20on%20%40pwndao!%20%F0%9F%91%8F%20Check%20out%20their%20dashboard!`, '_blank')
  }
}
</script>

<style scoped>
.modal-share-dashboard {
  &__subheading {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 1.25rem;
  }

  &__icons-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  &__icon {
    cursor: pointer;

    margin-left: 0.5rem;
  }

  &__twitter-icon {
    margin-top: 0.125rem;
    width: 2.5rem;
    margin-right: 2rem;
  }

  &__link-container {
    display: flex;
    margin-top: 0.5rem;
  }

  &__link-heading {
    font-size: 1.25rem;
    margin-top: 2rem;
  }

  &__link {
    --content-height: 32px;
    height: var(--content-height);
    line-height: var(--content-height);
    padding: 0 1.25rem;
    margin-right: 0.75rem;
    background-color: var(--dark-separator-color);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--copied {
      color: var(--primary-color-1);
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}
</style>
