import { ref } from 'vue'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { ATR_SORT_OPTIONS_LOOKUP, AtrSortOption } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { filterAssetsInSearchResults } from '@/utils/search'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

export enum Tab {
  Assets = 'Assets',
  AtrTokens = 'ATR Tokens'
}

const selectedTab = ref(Tab.Assets)
const isTableView = ref(true)

const selectedSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-your-atr',
  { id: AtrSortOption.Name, direction: SortDirection.Descending, viewName: 'sort-option-your-atr' },
  Object.keys(ATR_SORT_OPTIONS_LOOKUP),
))

export default function usePwnSafeAssetsDashboard() {
  const getSortedAndFilteredATRs = (nfts: AssetWithAmount[], searchTerm: string, selectedSorting: ActiveSortOption) => {
    let resultNfts = filterAssetsInSearchResults(nfts, searchTerm)
    resultNfts = useGlobalFiltersStore().applyShowUnverifiedAssetsFilter(resultNfts)
    return ATR_SORT_OPTIONS_LOOKUP[selectedSorting.id](resultNfts, selectedSorting.direction)
  }
  return {
    selectedTab,
    isTableView,
    selectedSortOption,
    getSortedAndFilteredATRs,
  }
}
