<template>
  <div
    class="achievements-page__background">
    <div
      class="achievements-page__connect-wallet">
      <div
        class="achievements-page__countdown">
        <h2 class="achievements-page__heading">
          {{ heading }}
        </h2>
        <h2 class="achievements-page__heading">
          Time to claim your PWN Rep.
        </h2>
      </div>
      <BaseButton
        class="achievements-page__connect-wallet-button"
        :font="ButtonFont.Screener"
        :color="ButtonColor.PrimaryDark"
        :size="ButtonSize.XXL"
        button-text="Connect Wallet"
        @on-button-click="web3Modal.open">
        <template #iconBeforeText>
          <ReputationScoreSvg
            class="achievements-page__reputation-score-icon"
            width="20"
            height="20"
            alt="reputation score"/>
        </template>
        <template #icon-after-text>
          <ReputationScoreSvg
            class="achievements-page__reputation-score-icon"
            width="20"
            height="20"
            alt="reputation score"/>
        </template>
      </BaseButton>
      <LearnMore/>
      <slot name="eligibility-criteria"/>
    </div>
    <img
      src="@/assets/animations/points-spin.gif"
      class="points-spin"
      alt="Points Spin">
  </div>
</template>

<script lang="ts" setup>
import BaseButton, { ButtonFont, ButtonColor, ButtonSize } from '@/general-components/BaseButton.vue'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import ReputationScoreSvg from '@/assets/icons/rep-score.svg'
import LearnMore from './LearnMore.vue'
import { toRefs } from 'vue'

type Props = {
  heading: string
}

const props = defineProps<Props>()
const { heading } = toRefs(props)

const web3Modal = useWeb3Modal()
</script>

  <style scoped>
.achievements-page {
  margin-top: 1rem;

  &__info {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #434343;
    width: 35rem;
  }

  &__background {
    background-color: #171717;
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
  }

  &__connect-wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__connect-wallet-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35rem;
    height: 5rem;
    margin-bottom: 2rem;
    background-color: #0f2926;

    &:hover {
      background-color: var(--primary-color-3);
    }
  }

  &__countdown {
    margin-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
    padding: 2rem;
  }

  &__reputation-score-icon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    fill: var(--primary-color-1);
  }
}

@media (max-width: 768px) {
  .achievements-page__connect-wallet-button {
    width: 90%;
  }
}

.points-spin {
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  mix-blend-mode: lighten;
}
  </style>
