<template>
  <div class="price-history">
    <BaseAssetPageSection
      icon="chart"
      label="Price history"
      question-mark-tooltip="Chart visualization and stats of the historical price action of this asset"
      :is-fetching="isFetchingSaleEvents"
      :has-data="hasData">
      <template #content>
        <div class="price-history__content">
          <div
            v-if="!isLoadingPriceHistory"
            class="price-history__prices-summary">
            <AllTimePrice
              label="All-time High"
              tooltip-text="All-time high, or ATH, is a term that refers to the highest historical sale price an asset has reached since its initial launch."
              :usd-amount="allTimeHigh?.token?.appraisal?.price?.usdAmount"
              :eth-amount="allTimeHigh?.token?.amount"/>

            <AllTimePrice
              label="All-time Low"
              tooltip-text="All-time low, or ATL, is a term that refers to the lowest historical sale price an asset has reached since its initial launch."
              :usd-amount="allTimeLow?.token?.appraisal?.price?.usdAmount"
              :eth-amount="allTimeLow?.token?.amount"/>

            <AllTimePrice
              label="All-time Average"
              tooltip-text="All-time average, or ATA, is a term that refers to the average historical sale price an asset has reached since its initial launch."
              :usd-amount="allTimeAverage"
              :eth-amount="null"/>
            <CurrencySwitcher/>
          </div>
          <div
            v-else
            class="price-history__prices-summary">
            <div class="price-history__loader-wrap">
              <BaseSkeletor/>
            </div>
            <CurrencySwitcher/>
          </div>
        </div>
        <PriceChart/>
        <BatchSalesSwitcher v-if="asset?.category === AssetType.ERC1155"/>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import AllTimePrice from '@/modules/pages/asset/nft-page/SectionPriceHistory/AllTimePrice.vue'
import CurrencySwitcher from '@/modules/pages/asset/nft-page/SectionPriceHistory/CurrencySwitcher.vue'
import BatchSalesSwitcher from '@/modules/pages/asset/nft-page/SectionPriceHistory/BatchSalesSwitcher.vue'
import PriceChart from '@/modules/pages/asset/nft-page/SectionPriceHistory/PriceChart.vue'
import useAssetPriceHistory from '@/modules/pages/asset/nft-page/SectionPriceHistory/useAssetPriceHistory'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import AssetType from '@/modules/common/assets/AssetType'
import useSectionNFTEvents from '@/modules/pages/asset/nft-page/SectionNFTEvents/useSectionNFTEvents'
import { computed } from 'vue'

const { asset } = useAssetPage()
const { isLoadingPriceHistory, allTimeHigh, allTimeLow, allTimeAverage } = useAssetPriceHistory()
const { isFetchingSaleEvents, saleEvents } = useSectionNFTEvents()

const hasData = computed(() => saleEvents.value?.length > 0)
</script>

<style scoped>
.price-history {
  &__content {
    padding: 0.7rem;
  }

  &__prices-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__loader-wrap {
    width: 83%;
  }
}
</style>
