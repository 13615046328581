<template>
  <div :class="['bundle-assets-submit__hint', { 'bundle-assets-submit__hide': !isHintMessage }]">
    <span class="bundle-assets-submit__hint-warning">Please make sure the assets you chose are correct.</span>
    <BaseLink
      target="_blank"
      :to="linkTo.tokenBundlerArticle"
      :is-underlined="false"
      class="link--primary">
      [Guide]
    </BaseLink>
  </div>

  <div
    v-if="!isSuccessfullyBundled && isBundling"
    class="bundle-assets-submit__bundling-text">
    Creating a bundle, this may take a while
  </div>
  <div class="bundle-assets-submit__selected-bundle">
    <div
      v-if="!isSuccessfullyBundled && !isBundling"
      class="bundle-assets-submit__bundle-assets">
      <AssetCardBundle
        v-for="asset in selectedAssetsToBundle"
        :key="asset.id"
        amount-prop-name="amountInputFormatted"
        :asset="asset"/>
    </div>
    <div
      v-if="isSuccessfullyBundled || isBundling"
      class="bundle-assets-submit__success">
      <div
        v-if="isSuccessfullyBundled"
        class="bundle-assets-submit__success-text">
        Success!<br>
        Assets have been bundled<br>
        The bundle can be found in <BaseLink
          :to="{ name: RouteName.Dashboard, params: { user: userAddress } }"
          class="link--primary">
          <span>your dashboard</span>
        </BaseLink> as
        <BaseLink
          v-if="createdBundle"
          class="link link--primary"
          :to="getAssetPageRoute(createdBundle)">
          {{ createdBundle?.displayedName }}
        </BaseLink>
        <br>
      </div>

      <img
        v-if="isSuccessfullyBundled && createdBundle"
        :src="createdBundle.image"
        alt="bundle"
        class="bundle-assets-submit__bundle-image ">
      <BundlingAnimation
        v-if="isBundling"
        :is-successfully-bundled="isSuccessfullyBundled!"
        :bundle-assets="selectedAssetsToBundle"/>
    </div>
  </div>

  <div class="bundle-assets-submit__continue">
    <BaseButton
      :button-text="prevStepButtonText"
      :color="ButtonColor.White"
      :variant="ButtonVariant.Underline"
      @on-button-click="goToPreviousStep"/>

    <BaseButton
      v-if="!isSuccessfullyBundled"
      :button-text="BundleButtonText"
      :is-disabled="!isBundleButtonEnabled"
      @on-button-click="handleButtonClick"/>
    <BaseLink
      v-else
      :is-underlined="false"
      :to="{ name: RouteName.Dashboard, params: { user: userAddress } }">
      <BaseButton button-text="Go to Dashboard"/>
    </BaseLink>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import useBundleAssets from '@/modules/pages/token-bundler/bundle-assets/useBundleAssets'
import AssetCardBundle from '@/general-components/AssetCardBundle.vue'
import { computed, ref } from 'vue'
import type { Ref } from 'vue'
import useBundleApprove from '@/modules/common/assets/useBundleApprove'
import BaseLink from '@/general-components/BaseLink.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import BundlingAnimation from '@/modules/pages/token-bundler/bundle-assets/BundlingAnimation.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import linkTo from '@/constants/links'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { queryMutations } from '@/modules/mutations'
import { useMutation } from '@tanstack/vue-query'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import useActionFlow from '@/modules/common/notifications/useActionFlow'

const isSuccessfullyBundled = ref<boolean>()
const createdBundle = ref<AssetWithAmount>()

const { getAssetPageRoute } = useAssetPage()
const { address: userAddress } = useCustomAccount()
const { selectedAssetsToBundle } = useBundleAssets()
const router = useRouter()
const { isBundleApproved } = useBundleApprove()
const { approveAssetsForBundlerIfNeeded } = useBundleApprove()

const mutations = queryMutations()

const { mutateAsync: bundleAssetsMutateAsync, isPending: isBundling } = useMutation({
  ...mutations.tokenBundler.bundle,
  onSuccess(_createdBundle, variables, context) {
    createdBundle.value = _createdBundle
    selectedAssetsToBundle.value = []
    isSuccessfullyBundled.value = true
    mutations.tokenBundler.bundle.onSuccess?.(_createdBundle, variables, context)
  },
})

const { mutateAsync: approveAssetsForBundleMutateAsync, isPending: isApproving } = useMutation({
  mutationKey: ['approveForBundle'],
  mutationFn: async (variables) => {
    isBundleApproved.value = await approveAssetsForBundlerIfNeeded(selectedAssetsToBundle.value)
    return isBundleApproved.value
  },
})

const toastSteps = computed(() => {
  const steps: ToastStep[] = []

  if (!isBundleApproved.value) {
    steps.push(new ToastStep({
      text: 'Approving...',
      async fn(step) {
        return await approveAssetsForBundleMutateAsync()
      },
    }))
  }

  if (!isSuccessfullyBundled.value) {
    steps.push(new ToastStep({
      text: 'Bundling...',
      async fn(step) {
        return !!(await bundleAssetsMutateAsync({ assets: selectedAssetsToBundle.value, step }))
      },
    }))
  }

  return steps
})

let continueFlow
const toast = ref<Toast>()

const handleButtonClick = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_CREATE_BUNDLE](selectedAssetsToBundle.value)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      steps: toastSteps.value,
      chainId: selectedAssetsToBundle.value?.[0]?.chainId,
      title: 'Creating bundle',
    }, NotificationFrontendOnlyActionEnum.TX_CREATE_BUNDLE, selectedAssetsToBundle.value);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}

const BundleButtonText = computed(() => {
  if (isBundling.value) return 'Bundling...'
  if (isBundleApproved.value) return 'Bundle'
  return 'Approve and Bundle'
})
const isHintMessage = computed(() => !isBundling.value && !isSuccessfullyBundled.value)
const prevStepButtonText = computed(() => isSuccessfullyBundled.value ? 'Keep bundling' : 'previous step')
const isBundleButtonEnabled = computed(() => selectedAssetsToBundle.value && !isBundling.value && !isApproving.value)

const goToPreviousStep = (): void => { router.push({ name: RouteName.BundleAssetsChooseAssets }) }
</script>

<style scoped>
.bundle-assets-submit {
  &__selected-bundle {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30rem;
  }

  &__continue {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 1rem;
    padding-top: 1rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 1px;
      width: 100%;
      background-image: var(--border-gray-dashed);
      background-size: auto 2px;
      /* small hack how to hide buggy double border (top + bottom), when height is 1px */
    }
  }

  &__hint-warning {
    color: var(--negative-1);
    margin-right: 0.5rem;
  }

  &__hint {
    margin-top: 1rem;
    display: flex;
  }

  &__bundle-image {
    height: 16.25rem;
    width: 16.25rem;
    border-radius: 1.625rem;
    margin: 2rem auto 1rem;
    display: block;
  }

  &__hide {
    visibility: hidden;
  }

  &__success-text,
  &__bundling-text {
    font-family: var(--font-family-screener);
    color: var(--primary-color-1);
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: center;
  }

  &__bundle-assets {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 620px;
    overflow-y: auto;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin: 1.5rem 0 2rem;
  }

  &__link-to-asset-page {
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary-color-1);

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 400px) {
    &__continue {
      flex-direction: column-reverse;
      align-items: center;
      gap: 1rem;
    }
  }
}
</style>
