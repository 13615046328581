import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import { useStorage } from '@vueuse/core'
import { CollateralTypeFilter } from '@/modules/common/filters/collateral-type/useCollateralTypeFilter'
import { computed, ref } from 'vue'
import type { ProposalAndLoanListParams } from '@/modules/common/backend/generated'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'

export type ProposalCollateralAssetFilter = {
  assets: (AssetWithAmount | AssetMetadata)[];
};

export type ProposalFiltersStoreModel = {
  ltv: {
    from: number;
    to: number;
  };
  apr: {
    from: number;
    to: number;
  };
  duration: {
    from: number;
    to: number;
  };
  collateralAsset: ProposalCollateralAssetFilter;
  creditAsset: ProposalCollateralAssetFilter;
  collateralType: CollateralTypeFilter;
  showHistory: boolean;
};

export const defaultProposalFilters: ProposalFiltersStoreModel = {
  ltv: {
    from: 0,
    to: 100,
  },
  apr: {
    from: 1,
    to: 150,
  },
  duration: {
    from: 1,
    to: 365,
  },
  collateralAsset: {
    assets: [],
  },
  creditAsset: {
    assets: [],
  },
  collateralType: CollateralTypeFilter.All,
  showHistory: false,
}

export const useProposalFilters = defineStore(StoreIds.proposalFilters, () => {
  const filters = useStorage('proposalFilters', defaultProposalFilters)
  const proposalsTableRequestParams = ref<ProposalAndLoanListParams>()
  const searchQuery = ref<string>('')
  const matchingProposals = ref<number>(0)

  const collateralAssetToFilter = ref<AssetMetadata | null>()

  const selectedCollateralAsset = computed(() => {
    return filters.value.collateralAsset?.assets
  })

  const selectedCreditAsset = computed(() => {
    return filters.value.creditAsset?.assets
  })

  const isDifferentFromDefault = computed(() => {
    const dataToCompareFilters = {
      ltv: filters.value.ltv,
      apr: filters.value.apr,
      duration: filters.value.duration,
    }

    const dataToCompareDefaultFilters = {
      ltv: defaultProposalFilters.ltv,
      apr: defaultProposalFilters.apr,
      duration: defaultProposalFilters.duration,
    }

    return JSON.stringify(dataToCompareFilters) !== JSON.stringify(dataToCompareDefaultFilters)
  })

  return {
    searchQuery,
    matchingProposals,
    collateralAsset: selectedCollateralAsset,
    creditAsset: selectedCreditAsset,
    filters,
    collateralAssetToFilter,
    isDifferentFromDefault,
    $reset: () => {
      filters.value = defaultProposalFilters
    },
    actions: {
      updateLtvFilter(from: number, to: number) {
        filters.value.ltv = { from, to }
      },
      updateAprFilter(from: number, to: number) {
        filters.value.apr = { from, to }
      },
      updateDurationFilter(from: number, to: number) {
        filters.value.duration = { from, to }
      },
      updateCollateralTypeFilter(collateralTypeFilter: CollateralTypeFilter) {
        filters.value.collateralType = collateralTypeFilter
      },
      updateCollateralAssetsFilter(assets: (AssetWithAmount | AssetMetadata)[]) {
        filters.value.collateralAsset = {
          assets,
        }
      },
      updateCreditAssetsFilter(assets: (AssetWithAmount | AssetMetadata)[]) {
        filters.value.creditAsset = {
          assets,
        }
      },
      updateShowHistory() {
        filters.value.showHistory = !filters.value.showHistory
      },
      resetToDefault(key: keyof ProposalFiltersStoreModel) {
        if (key === 'creditAsset') {
          filters.value = {
            ...filters.value,
            creditAsset: {
              assets: [],
            },
          }
        } else if (key === 'collateralAsset') {
          filters.value = {
            ...filters.value,
            collateralAsset: {
              assets: [],
            },
          }
        } else {
          filters.value = {
            ...filters.value,
            [key]: defaultProposalFilters[key],
          }
        }
      },
    },
    proposalsTableRequestParams,
  }
})
