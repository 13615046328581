<template>
  <BaseModal
    v-model:is-open="isOpen"
    heading-align="left"
    heading="Loan Default Warning">
    <template #trigger>
      <NotificationCenterSettingsCustomizeButton
        :is-customizeable="userNotificationSettings?.loanDefaultWarning"
        @click="handleClick"/>
    </template>

    <template #body>
      <div class="loan-default-warning-modal__body">
        <div class="loan-default-warning-modal__container loan-default-warning-modal__container--top">
          <div class="loan-default-warning-modal__text">
            Alert me
          </div>
          <div class="loan-default-warning-modal__input">
            <BaseInput
              v-if="userNotificationSettings"
              v-model.number="userNotificationSettings.firstAlertDaysBeforeLoanDefault"
              input-text-align="center"
              placeholder="1"
              append-text="Days"/>
          </div>
          <div class="loan-default-warning-modal__text">
            before my loan defaults
          </div>
          <div class="loan-default-warning-modal__tooltip">
            <QuestionMarkTooltip
              tooltip-text="The amount of days you will get an alert before your final repayment date on a loan"/>
          </div>
        </div>
        <div class="loan-default-warning-modal__container">
          <div class="loan-default-warning-modal__text">
            Alert me
          </div>
          <div class="loan-default-warning-modal__input">
            <BaseInput
              v-if="userNotificationSettings"
              v-model.number="userNotificationSettings.secondAlertDaysBeforeLoanDefault"
              input-text-align="center"
              placeholder="7"
              append-text="Days"/>
          </div>
          <div class="loan-default-warning-modal__text">
            before my loan defaults
          </div>
          <div class="loan-default-warning-modal__tooltip">
            <QuestionMarkTooltip :tooltip-text="secondTooltipText">
              <slot name="tooltip-extra-content"/>
            </QuestionMarkTooltip>
          </div>
        </div>
      </div>
      <div class="loan-default-warning-modal__button-container">
        <BaseButton
          button-text="Discard"
          :color="ButtonColor.White"
          :variant="ButtonVariant.Underline"
          @on-button-click="isOpen = false"/>
        <BaseButton
          button-text="Save Settings"
          @on-button-click="handleClose"/>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import NotificationCenterSettingsCustomizeButton from '@/modules/pages/notification-center/settings/NotificationCenterSettingsCustomizeButton.vue'
import { ref } from 'vue'
import BaseInput from '@/general-components/BaseInput.vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'

const isOpen = ref(false)
const { userNotificationSettings, updateNotificationSettings } = useNotificationCenterSettings()

const handleClick = () => {
  if (!userNotificationSettings.value?.loanDefaultWarning) return
  isOpen.value = true
}

const handleClose = () => {
  updateNotificationSettings()
  isOpen.value = false
}

const secondTooltipText = 'A loan default means you will lose your collateral since you have not paid your lender the repayment amount, this alert is to help you remember to pay before you run out of time'
</script>

<style scoped>
.loan-default-warning-modal {
  &__body {
    margin: 2rem 0;
  }

  &__container {
    display: flex;

    &--top {
      margin-bottom: 2rem;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__tooltip {
    margin-left: 0.5rem;
  }

  &__input {
    margin: 0 2rem;
    min-width: 10rem;

    ::v-deep(.input__container) {
      height: 1.5rem;
    }

    ::v-deep(.input__input) {
      text-align: center;
      padding: 0;
      padding-top: 0.1rem;
      height: 1.3rem;
      width: 7.25rem;
    }
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
