<template>
  <BaseTooltip
    :has-tooltip="hasTooltip"
    :border-color="TooltipBorderColor.TealLight"
    append-to-body
    is-interactive>
    <template #trigger>
      <slot/>
    </template>

    <template #default>
      <div v-if="!asset.isWhitelistedForPwnSafe">
        <div>Asset is not whitelisted</div>
        <BaseLink
          class="tokenize-disabled-tooltip__request-whitelist link--primary link--font-oxygen-mono"
          :to="linkTo.pwnSafeSubmitRequest"
          target="_blank">
          Request Whitelisting
        </BaseLink>
      </div>
      <div
        v-else-if="isUsedInLoan"
        class="tokenize-disabled-tooltip__used-in-loan-warning">
        Token used as repayment in active or open loan
      </div>
      <div v-else-if="asset.hasAtrToken">
        <div>
          Asset already has an existing
          <template
            v-for="(atrToken, index) in asset.atrTokens"
            :key="atrToken.tokenId">
            <span v-if="index !== 0">, </span>
            <BaseLink
              class="link--primary link--font-oxygen-mono"
              :to="getAtrTokenRoute(atrToken)"
              target="_blank">
              {{ getAtrTokenIdFormatted(atrToken) }}
            </BaseLink>
          </template>
        </div>
        <div
          v-if="hasAnyAtrTokenInDifferentSafe"
          class="tokenize-disabled-tooltip__different-safe-warning">
          <br>
          <span
            v-for="(atrToken, index) in atrTokensInDifferentSafe"
            :key="String(atrToken.tokenId)">
            <span v-if="index !== 0">, </span>
            {{ getAtrTokenIdFormatted(atrToken) }}
          </span>
          {{ atrTokensInDifferentSafe.length === 1 ? 'is' : 'are' }} not in this safe
        </div>
      </div>
    </template>
  </BaseTooltip>
</template>

<script setup lang="ts">
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import type { AssetWithAmount, AtrTokenInfo } from '@/modules/common/assets/AssetClasses'
import { computed } from 'vue'
import linkTo from '@/constants/links'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import RouteName from '@/router/RouteName'
import { compareAssets } from '@/utils/utils'
import AssetType from '@/modules/common/assets/AssetType'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import type { RouteLocationRaw } from 'vue-router'

interface Props {
  asset: AssetWithAmount
  selectedPwnSafe: PwnSafe
  hasTooltip: boolean
  isUsedInLoan: boolean
}
const props = defineProps<Props>()

const getAtrTokenRoute = (atrToken: AtrTokenInfo): RouteLocationRaw => {
  return {
    name: RouteName.NftPage,
    params: {
      chainName: CHAINS_CONSTANTS[props.asset.chainId].general.chainName.toLowerCase(),
      contractAddress: atrToken.contractAddress,
      tokenId: String(atrToken.tokenId),
    },
  }
}

const getAtrTokenIdFormatted = (atrToken: AtrTokenInfo) => `ATR #${atrToken.tokenId}`
const atrTokensInDifferentSafe = computed(() => {
  return props.asset.atrTokens.filter(atrToken => {
    return props.selectedPwnSafe.nfts.every(nft => !compareAssets({
      assetA: { chainId: atrToken.chainId, address: atrToken.contractAddress, tokenId: atrToken.tokenId, category: AssetType.ERC721 }, // ATR token is ERC721
      assetB: nft,
    }))
  })
})
const hasAnyAtrTokenInDifferentSafe = computed(() => atrTokensInDifferentSafe.value?.length)
</script>

<style scoped>
.tokenize-disabled-tooltip {
  &__request-whitelist {
    display: flex;
    justify-content: center;
  }

  &__different-safe-warning,
  &__used-in-loan-warning {
    color: var(--orange-1);
  }
}
</style>
