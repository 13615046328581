import type { CollectionOrAppraisalRow } from '@/modules/pages/asset/nft-page/types/CollectionOrAppraisalRow'
import { StatValueTypeEnum } from '@/modules/pages/asset/nft-page/types/CollectionOrAppraisalRow'
import { computed, readonly, ref } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type { NFTAppraisal } from '@/modules/common/assets/typings/AssetPriceClasses'
import type { Address } from 'viem'

const nftAppraisal = ref<NFTAppraisal | null>(null)
const isFetchingNFTAppraisal = ref(false)

// @ts-expect-error FIXME: strictNullChecks/
const nftAppraisalWithLabels = computed<CollectionOrAppraisalRow[]>(() => [
  {
    description: {
      label: 'Estimated Price',
      questionMarkTooltip: 'The singular appraisal of an asset',
    },
    value: {
      type: StatValueTypeEnum.Price,
      value: nftAppraisal.value?.price,
    },
  },
  {
    description: {
      label: 'Predicted Range',
      questionMarkTooltip: 'An approximation of the highest and lowest value appraisal you could get for this particular NFT using the Estimated Price and relative error',
    },
    value: {
      type: StatValueTypeEnum.PredictedRange,
      value: [nftAppraisal.value?.priceRangeLow, nftAppraisal.value?.priceRangeHigh],
    },
  }],
)

export default function useSectionNFTAppraisal() {
  const clearNFTAppraisal = (): void => {
    nftAppraisal.value = null
  }

  const loadNFTAppraisal = async (chainId: SupportedChain, contractAddress: Address, tokenId: string, refresh?: boolean | undefined): Promise<void> => {
    isFetchingNFTAppraisal.value = true
    nftAppraisal.value = await useMetadataFetch().fetchNFTAppraisal(chainId, contractAddress, tokenId, undefined, refresh)
    isFetchingNFTAppraisal.value = false
  }

  const hasNFTAppraisal = computed(() => !!nftAppraisal.value)

  const calculatedNftAppraisal = computed(() => nftAppraisal.value)

  return {
    nftAppraisalWithLabels,
    isFetchingNFTAppraisal: readonly(isFetchingNFTAppraisal),
    loadNFTAppraisal,
    clearNFTAppraisal,
    hasNFTAppraisal,
    nftAppraisal: calculatedNftAppraisal,
  }
}
