<template>
  <div class="ltv-colored">
    <span
      :class="[`ltv-colored__value`, `ltv-colored__value--${style}`]">
      {{ displayedValue }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
interface Props {
  ltv: number
  isMissingLtvDash?: boolean
}

enum LtvType {
  Lowest = 'lowest',
  Low = 'low',
  Average = 'average',
  High = 'high',
  DangerHigh = 'danger-high',
  Unavailable = 'unavailable'
}

const props = defineProps<Props>()
const { ltv } = toRefs(props)

const style = computed<LtvType>(() => {
  if (!ltv.value || isNaN(ltv.value)) {
    return LtvType.Unavailable
  } else if (ltv.value <= 20) {
    return LtvType.Lowest
  } else if (ltv.value <= 60) {
    return LtvType.Low
  } else if (ltv.value <= 80) {
    return LtvType.Average
  } else if (ltv.value <= 100) {
    return LtvType.High
  } else {
    return LtvType.DangerHigh
  }
})

const displayedValue = computed(() => {
  if (!ltv.value && props.isMissingLtvDash) return '-'
  if (!ltv.value) return 'N/A'
  return `${ltv.value}%`
})
</script>

<style scoped>
.ltv-colored {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &__value {
    font-size: 0.875rem;
    font-weight: 500;

    /* &--lowest {
      color: var(--primary-color-1);
    }

    &--low {
      color: var(--positive-bright);
    }

    &--average {
      color: var(--warning-1);
    }

    &--high {
      color: var(--negative-1);
    }

    &--danger-high {
      color: var(--danger-1);
    } */

    &--unavailable {
      color: var(--gray-2);
    }
  }
}
</style>
