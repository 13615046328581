<template>
  <BaseTooltip
    :has-tooltip="asset.isTransferDisabled"
    :border-color="TooltipBorderColor.TealLight"
    :max-width="320"
    is-interactive>
    <template #trigger>
      <slot/>
    </template>
    <template v-if="asset.hasAtrToken">
      <div v-if="asset.isTotalAmountDivisible">
        <div>
          All asset amount is locked in
          <template
            v-for="(atrToken, index) in asset.atrTokens"
            :key="atrToken.tokenId">
            <span v-if="index !== 0">, </span>
            <BaseLink
              class="link--primary link--font-oxygen-mono"
              :to="getAtrTokenRoute(atrToken)"
              target="_blank">
              {{ getAtrTokenIdFormatted(atrToken) }}
            </BaseLink>
          </template>
          .
        </div>
        <br>
        <div>
          If you wish to transfer this asset, you must
          <component
            :is="burnLinkComponent"
            :class="{ 'link--primary link--font-oxygen-mono': hasAnyAtrTokenInCurrentSafe }"
            :to="{ name: RouteName.PwnSafeClaimAndBurn }">
            burn the ATR token
          </component>
          that will unlock the locked amount of corresponding ATR token.
        </div>
        <div
          v-if="hasAnyAtrTokenInDifferentSafe"
          class="transfer-disabled-tooltip__different-safe-warning">
          <br>
          <span
            v-for="(atrToken, index) in atrTokensInDifferentSafe"
            :key="String(atrToken.tokenId)">
            <span v-if="index !== 0">, </span>
            {{ getAtrTokenIdFormatted(atrToken) }}
          </span>
          {{ atrTokensInDifferentSafe?.length === 1 ? "is" : "are" }} not in this safe
        </div>
      </div>
      <div v-else-if="!asset.isTotalAmountDivisible && asset?.atrTokens?.[0]">
        <div>Asset cannot be transferred while it has an existing ATR Token.</div>
        <br>
        <div>
          If you wish to transfer this asset
          <BaseLink
            class="link link--primary link--font-oxygen-mono"
            :to="getAtrTokenRoute(asset.atrTokens[0])"
            target="_blank">
            {{ getAtrTokenIdFormatted(asset.atrTokens[0]) }}
          </BaseLink>
          must be
          <component
            :is="burnLinkComponent"
            :class="{ 'link--primary link--font-oxygen-mono': hasAnyAtrTokenInCurrentSafe }"
            :to="{ name: RouteName.PwnSafeClaimAndBurn }">
            burnt
          </component>
        </div>
        <div
          v-if="hasAnyAtrTokenInDifferentSafe"
          class="transfer-disabled-tooltip__different-safe-warning">
          <br>
          <span>{{ getAtrTokenIdFormatted(asset.atrTokens[0]) }} is not in this safe</span>
        </div>
      </div>
    </template>
  </BaseTooltip>
</template>

<script setup lang="ts">
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import type { AssetWithAmount, AtrTokenInfo } from '@/modules/common/assets/AssetClasses'
import { computed } from 'vue'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { compareAssets } from '@/utils/utils'
import AssetType from '@/modules/common/assets/AssetType'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import type { RouteLocationRaw } from 'vue-router'

interface Props {
  asset: AssetWithAmount;
  selectedPwnSafe: PwnSafe;
}

const props = defineProps<Props>()

const getAtrTokenRoute = (atrToken: AtrTokenInfo): RouteLocationRaw => {
  return {
    name: RouteName.NftPage,
    params: {
      chainName: CHAINS_CONSTANTS[props.asset.chainId].general.chainName.toLowerCase(),
      contractAddress: atrToken.contractAddress,
      tokenId: String(atrToken.tokenId),
    },
  }
}

const getAtrTokenIdFormatted = (atrToken: AtrTokenInfo) => `ATR #${atrToken.tokenId}`

const atrTokensInDifferentSafe = computed(() => {
  return props.asset.atrTokens.filter((atrToken) => {
    return props.selectedPwnSafe.nfts.every(
      (nft) =>
        !compareAssets({
          assetA: { chainId: atrToken.chainId, address: atrToken.contractAddress, tokenId: atrToken.tokenId, category: AssetType.ERC721 }, // ATR token is ERC721
          assetB: nft,
        }),
    )
  })
})
const hasAnyAtrTokenInDifferentSafe = computed(() => atrTokensInDifferentSafe.value?.length)
const hasAnyAtrTokenInCurrentSafe = computed(
  () => atrTokensInDifferentSafe.value?.length !== props.asset.atrTokens?.length,
)

const burnLinkComponent = computed(() => (hasAnyAtrTokenInCurrentSafe.value ? BaseLink : 'span'))
</script>

<style scoped>
.transfer-disabled-tooltip {
  &__different-safe-warning {
    color: var(--orange-1);
  }
}
</style>
