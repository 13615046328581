import { typeSafeObjectValues } from '@/utils/typescriptWrappers'
import { ref } from 'vue'

export function useCancelableRequests<Key extends string>() {
  type StoreType = Record<Key, AbortController>

  const requests = ref<StoreType | object>({})

  const setCancellableRequest = (key: Key, controller: AbortController) => {
    const currentRequests = requests.value as StoreType
    requests.value = {
      ...currentRequests,
      [key]: controller,
    }
  }

  const cancelRequests = () => {
    // @ts-expect-error not sure what is wrong here
    typeSafeObjectValues(requests.value).forEach(controller => {
      if (controller instanceof AbortController) {
        controller.abort()
      }
    })
    requests.value = {}
  }

  return {
    requests,
    setCancellableRequest,
    cancelRequests,
  }
}
