<template>
  <ThesesTable
    :for-user="true"
    :on-update="handleUpdateUserThesisStats"/>
</template>

<script setup lang="ts">
import { getThesisDetailQueryOptions, getThesisListQueryOptions } from '@/modules/common/backend/generated'
import ThesesTable from '@/revamp/components/tables/thesis/ThesesTable.vue'
import { useQueryClient } from '@tanstack/vue-query'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const client = useQueryClient()
const { address } = useCustomAccount()

const handleUpdateThesisStats = (thesisId: string) => {
  const params = getThesisDetailQueryOptions(thesisId)

  client.invalidateQueries(params)
}

const handleUpdateUserThesisStats = (thesisId: string) => {
  handleUpdateThesisStats(thesisId)
  const params = getThesisDetailQueryOptions(thesisId, { user_address: address.value })
  const thesisListParams = getThesisListQueryOptions({ user_address: address.value })

  client.invalidateQueries(params)
  client.invalidateQueries(thesisListParams)
}

</script>
