import type { Hex } from 'viem'
import { signEip712 } from '@/modules/common/web3/useSignatures'
import type { V1_2SimpleLoanFungibleProposalStruct } from '@/contracts/structs'
import BaseProposalContract from '@/modules/common/pwn/contracts/BaseProposalContract'
import { hashTypedData, zeroAddress } from 'viem'
import type { V1_2SimpleLoanFungibleProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import { freeUserNonceRetrieve } from '@/modules/common/backend/generated'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { getLenderSpecHash, readPWNFungibleProposalEncodeData } from '@/modules/common/adapters/proposal-utils'
import { readStarknetFungibleProposalGetHash } from '@/revamp/hooks/thesis/starknet/proposals'

const EMPTY_32_BYTES = '0x0000000000000000000000000000000000000000000000000000000000000000'

export default class V1_2SimpleLoanFungibleProposalContract extends BaseProposalContract<V1_2SimpleLoanFungibleProposal> {
  public async createStarknetProposalHash(
    proposal: V1_2SimpleLoanFungibleProposal,
  ): Promise<{ proposalHash: Hex }> {
    const hash = await readStarknetFungibleProposalGetHash(proposal, await this.createProposalStruct(proposal))

    return {
      proposalHash: hash as Hex,
    }
  }

  public async createProposalHash(
    proposal: V1_2SimpleLoanFungibleProposal,
  ): Promise<{ proposalHash: Hex }> {
    if (isStarknet) {
      return this.createStarknetProposalHash(proposal)
    }

    const domain = {
      name: 'PWNSimpleLoanFungibleProposal',
      version: '1.0',
      chainId: proposal.chainId,
      verifyingContract: proposal.proposalContractAddress,
    }

    const types = {
      Proposal: [
        { name: 'collateralCategory', type: 'uint8' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralId', type: 'uint256' },
        { name: 'minCollateralAmount', type: 'uint256' },
        { name: 'checkCollateralStateFingerprint', type: 'bool' },
        { name: 'collateralStateFingerprint', type: 'bytes32' },
        { name: 'creditAddress', type: 'address' },
        { name: 'creditPerCollateralUnit', type: 'uint256' },
        { name: 'availableCreditLimit', type: 'uint256' },
        { name: 'fixedInterestAmount', type: 'uint256' },
        { name: 'accruingInterestAPR', type: 'uint40' },
        { name: 'duration', type: 'uint32' },
        { name: 'expiration', type: 'uint40' },
        { name: 'allowedAcceptor', type: 'address' },
        { name: 'proposer', type: 'address' },
        { name: 'proposerSpecHash', type: 'bytes32' },
        { name: 'isOffer', type: 'bool' },
        { name: 'refinancingLoanId', type: 'uint256' },
        { name: 'nonceSpace', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'loanContract', type: 'address' },
      ],
    }

    const hash = hashTypedData({
      domain,
      message: await this.createProposalStruct(proposal),
      primaryType: 'Proposal',
      types,
    })

    return {
      proposalHash: hash,
    }
  }

  public async createProposalHashAndSignature(
    proposal: V1_2SimpleLoanFungibleProposal,
  ): Promise<{proposalHash: Hex, signature: Hex }> {
    const freeUserNonceData = await freeUserNonceRetrieve(
      String(proposal.chainId),
      CHAINS_CONSTANTS[proposal.chainId].pwnV1_2Contracts.pwnRevokedNonce,
      proposal.proposer,
      {
        nonce_count_to_reserve: 1,
      },
    )
    proposal.nonceSpace = BigInt(freeUserNonceData.data.freeUserNonceSpace)
    proposal.nonce = BigInt(freeUserNonceData.data.freeUserNonces[0])

    const domain = {
      name: 'PWNSimpleLoanFungibleProposal',
      version: '1.0',
      chainId: proposal.chainId,
      verifyingContract: proposal.proposalContractAddress,
    }

    const types = {
      Proposal: [
        { name: 'collateralCategory', type: 'uint8' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralId', type: 'uint256' },
        { name: 'minCollateralAmount', type: 'uint256' },
        { name: 'checkCollateralStateFingerprint', type: 'bool' },
        { name: 'collateralStateFingerprint', type: 'bytes32' },
        { name: 'creditAddress', type: 'address' },
        { name: 'creditPerCollateralUnit', type: 'uint256' },
        { name: 'availableCreditLimit', type: 'uint256' },
        { name: 'fixedInterestAmount', type: 'uint256' },
        { name: 'accruingInterestAPR', type: 'uint40' },
        { name: 'duration', type: 'uint32' },
        { name: 'expiration', type: 'uint40' },
        { name: 'allowedAcceptor', type: 'address' },
        { name: 'proposer', type: 'address' },
        { name: 'proposerSpecHash', type: 'bytes32' },
        { name: 'isOffer', type: 'bool' },
        { name: 'refinancingLoanId', type: 'uint256' },
        { name: 'nonceSpace', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'loanContract', type: 'address' },
      ],
    }

    const proposalStruct = await this.createProposalStruct(proposal)

    const { hash: proposalHash, signature } = await signEip712({
      domain,
      types,
      primaryType: 'Proposal',
      message: proposalStruct,
    })

    return {
      proposalHash,
      signature,
    }
  }

  public async createProposalStruct(proposal: V1_2SimpleLoanFungibleProposal): Promise<V1_2SimpleLoanFungibleProposalStruct> {
    if (!proposal.collateral) {
      throw new Error('Collateral is not defined')
    }

    const proposerSpecHash = await getLenderSpecHash(proposal)

    return {
      collateralCategory: proposal.collateral.category,
      collateralAddress: proposal.collateral.address,
      collateralId: proposal.collateral.tokenId ?? 0n,
      minCollateralAmount: BigInt(proposal.minCollateralAmount),
      checkCollateralStateFingerprint: false,
      collateralStateFingerprint: EMPTY_32_BYTES,
      creditAddress: proposal.creditAsset.address,
      creditPerCollateralUnit: BigInt(proposal.creditPerCollateralUnit),
      availableCreditLimit: proposal.availableCreditLimit,
      fixedInterestAmount: proposal.fixedInterestAmount,
      accruingInterestAPR: proposal.accruingInterestAPR || 0,
      duration: proposal.loanDurationSeconds,
      expiration: proposal.expiration,
      allowedAcceptor: zeroAddress,
      proposer: proposal.proposer,
      proposerSpecHash: proposal.isOffer ? proposerSpecHash : EMPTY_32_BYTES,
      isOffer: proposal.isOffer ?? false,
      refinancingLoanId: 0n,
      nonceSpace: proposal.nonceSpace,
      nonce: proposal.nonce,
      loanContract: proposal.loanContractAddress,
    }
  }

  public async encodeProposalData(
    proposal: V1_2SimpleLoanFungibleProposal,
  ): Promise<Hex> {
    const proposalStruct = await this.createProposalStruct(proposal)
    if (!proposal.collateralAmountToAccept) {
      throw Error('missing proposal.collateralAmountToAccept')
    }
    const proposalValues = {
      collateralAmount: proposal.collateralAmountToAccept,
    }

    return await readPWNFungibleProposalEncodeData(proposal, proposalStruct, proposalValues) as unknown as Hex
  }
}
