<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large">
    <template #trigger>
      <div
        class="boost-modal__trigger"
        @click="isOpen = true">
        <div class="boost-modal__trigger-left-side">
          <BoostSvg
            width="64"
            height="64"
            alt="boost category"/>
          <label class="boost-modal__trigger-label">
            Boosts
          </label>
        </div>
        <div class="boost-modal__trigger-right-side">
          <BaseSkeletor
            v-if="isLoading"
            class="boost-modal__trigger-loader"/>
          <div
            v-else
            class="boost-modal__trigger-boost">
            <LightningSvg
              class="boost-modal__trigger-lightning-icon"
              width="24"
              height="24"
              alt="boost"/>
            x{{ connectedUserData?.totalMultiplier.toFixed(3) }}
          </div>
        </div>
      </div>
    </template>

    <template #custom-header>
      <header
        class="boost-modal__header">
        <BaseBackButton
          has-emit
          @go-back="isOpen = false"/>
        <h2 class="boost-modal__title">
          Boost Tasks
        </h2>

        <CloseIconSvg
          alt="close"
          class="boost-modal__close-icon"
          @click.stop="isOpen = false"/>
      </header>
    </template>

    <template #body>
      <div class="boost-modal__body">
        <div class="boost-modal__note">
          Note: Boosts will only apply to the reputation score earned after task completion
        </div>
        <div class="boost-modal__current-boost-multiplier">
          <div>Current Boost Multiplier</div>
          <div
            class="boost-modal__boost-info">
            <LightningSvg
              class="boost-modal__lightning-icon"
              width="24"
              height="24"
              alt="boost"/>
            x{{ connectedUserData?.totalMultiplier.toFixed(3) }}
          </div>
        </div>

        <RepScoreList :rep-score-items="repScoreBoostItems"/>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import ModalSize from '@/general-components/ModalSize'
import BaseModal from '@/revamp/components/RevampBaseModal.vue'
import { computed, ref } from 'vue'
import LightningSvg from '@/assets/icons/lightning.svg'
import { useUserReputationScoreWithParametersRetrive } from '@/modules/common/backend/generated'
import type { UserReputationWithParametersResponse } from '@/revamp/types'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import BoostSvg from '@/assets/icons/boost.svg'
import CloseIconSvg from '@/assets/icons/close.svg'
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import { repScoreBoostItems } from '@/revamp/components/modals/achievements-and-boost/repScoreItems'
import RepScoreList from '@/revamp/components/modals/achievements-and-boost/RepScoreList.vue'

const isOpen = ref(false)

const { address: userAddress } = useCustomAccount()
const connectedUserRepQuery = useUserReputationScoreWithParametersRetrive(userAddress.value || '', { refresh: true })

const isLoading = computed(() => connectedUserRepQuery.isFetching.value)

const connectedUserData = computed(() => {
  if (connectedUserRepQuery.data.value?.data) {
    return connectedUserRepQuery.data.value.data as UserReputationWithParametersResponse
  }
  return undefined
})

</script>

<style scoped>
.boost-modal {
  &__body {
    width: 62rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__header {
    display: flex;
    position: relative;
  }

  &__title {
    font-size: 1.5rem;
    font-family: var(--font-family-screener);
    width: 100%;
    font-weight: 400;
    margin-left: 0.5rem;
  }

  &__close-icon {
    width: 30px;
    height: 30px;
    margin-left: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__note {
    border: 1px solid #828282;
    padding: 1rem;
    box-shadow: 0 0 8px 0 #000;
    width: fit-content;
  }

  &__trigger {
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    border: 1px solid #434343;
    box-shadow: 0 0 1rem 0 #000;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__trigger-label {
    color: var(--text-color);
    font-family: var(--font-family-supreme);
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  &__trigger-left-side {
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  &__lightning-icon,
  &__trigger-lightning-icon {
    fill: var(--primary-color-1);
    margin: 0 0.5rem 0 0;
  }

  &__boost-info,
  &__trigger-boost {
    font-family: var(--font-family-supreme-bold);
    font-size: 1.5rem;
    color: var(--primary-color-1);
    display: flex;
    align-items: center;
  }

  &__current-boost-multiplier {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 1rem;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-image: var(--border-gray-dashed);
      background-size: auto 2px;
      /* small hack how to hide buggy double border (top + bottom), when height is 1px */
    }
  }
}
</style>
