import { readonly, ref } from 'vue'
import AssetType from '@/modules/common/assets/AssetType'
import type { NftAssetType } from '@/modules/common/assets/AssetType'
import to from '@/utils/await-to-js'
import { fetchUserNfts } from '@/modules/common/backend/generated'
import type { FetchUserNftsParams } from '@/modules/common/backend/generated'
import { SupportedChain } from '@/constants/chains/types'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import * as Sentry from '@sentry/vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { getContract, isAddressEqual } from 'viem'
import type { Address } from 'viem'
import { getAccount, getPublicClient } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { cryptoPunksMarketAbi, readErc1155BalanceOf, readErc721OwnerOf } from '@/contracts/generated'

const isFetchingUserNfts = ref(false)
const setIsFetchingUserNfts = (isFetching: boolean): void => {
  isFetchingUserNfts.value = isFetching
}

export type FetchUserNftsPageParams = FetchUserNftsParams & {
  chainId: SupportedChain;
  userAddress: Address;
}

export default function useNFTFetch() {
  const fetchOwnerOfERC721 = async (chainId: SupportedChain, contractAddress: Address, tokenId: bigint): Promise<Address | undefined> => {
    if (!tokenId) return undefined
    const [error, owner] = await to(readErc721OwnerOf(pwnWagmiConfig, {
      chainId,
      address: contractAddress,
      args: [tokenId],
    }))

    return error ? undefined : owner
  }

  const fetchUserERC721Balance = async (chainId: SupportedChain, contractAddress: Address, tokenId: bigint, userAddress: Address): Promise<boolean> => {
    const nftOwnerAddress = await fetchOwnerOfERC721(chainId, contractAddress, tokenId)
    return nftOwnerAddress ? isAddressEqual(userAddress, nftOwnerAddress) : false
  }

  const fetchUserERC1155Balance = async (chainId: SupportedChain, contractAddress: Address, tokenId: bigint, userAddress: Address): Promise<bigint> => {
    return await readErc1155BalanceOf(pwnWagmiConfig, {
      chainId,
      address: contractAddress,
      args: [userAddress, tokenId],
    })
  }

  const fetchUserNftBalance = async (
    chainId: SupportedChain,
    contractAddress: Address,
    tokenId: bigint,
    nftType: NftAssetType,
    accountAddress: Address,
  ): Promise<bigint | undefined> => {
    if (nftType === AssetType.ERC721) {
      const isOwner = await fetchUserERC721Balance(chainId, contractAddress, tokenId, accountAddress)
      return isOwner ? 1n : undefined
    } else if (nftType === AssetType.ERC1155) {
      const userBalance = await fetchUserERC1155Balance(chainId, contractAddress, tokenId, accountAddress)
      return userBalance > 0n ? userBalance : undefined
    } else {
      // e.g. CRYPTOPUNKS and other contracts that do not follow neither ERC721 nor ERC1155 standard
      return 1n
    }
  }

  /**
   * Since cryptopunks are not ERC721/ERC1155 compliant, we have our own cryptopunk wrapper for cases when the user
   * would like to use cryptopunk as collateral. For testing this wrapper, we have deployed our own testing cryptopunks
   * contract where we can mint ourselves cryptopunks and try wrapping. However since cryptopunks are not compliant
   * to the ERC721/ERC1155 standards, the APIs that we use for fetching user assets are not returning them
   * in the results. Because of this, we are fetching our own testing cryptopunks directly from the contract events.
   */
  const loadUserCryptoPunks = async (chainId: SupportedChain) => {
    const cryptoPunksAddress = CHAINS_CONSTANTS[chainId]?.nftContractsAddresses?.cryptoPunks
    const { address: userAddress } = getAccount(pwnWagmiConfig)
    if (!cryptoPunksAddress || !userAddress) {
      return undefined
    }

    const cryptoPunksContract = getContract({
      address: cryptoPunksAddress,
      abi: cryptoPunksMarketAbi,
      client: {
        public: getPublicClient(pwnWagmiConfig, { chainId })!,
      },
    })

    const mintEventsRaw = await cryptoPunksContract.getEvents.Assign({ to: userAddress })
    const transferOutEventsRaw = await cryptoPunksContract.getEvents.PunkTransfer({ from: userAddress })
    const transferInEventsRaw = await cryptoPunksContract.getEvents.PunkTransfer({ to: userAddress })

    const mintEvents = mintEventsRaw.map((mintEvent) => ({
      punkId: mintEvent.args.punkIndex as bigint,
      blockNumber: mintEvent.blockNumber,
    }))

    const transferOutEvents = transferOutEventsRaw.map((transferOut) => ({
      punkId: transferOut.args.punkIndex as bigint,
      blockNumber: transferOut.blockNumber,
    }))

    const transferInEvents = transferInEventsRaw.map((transferIn) => ({
      punkId: transferIn.args.punkIndex as bigint,
      blockNumber: transferIn.blockNumber,
    }))

    const userPunkIds: bigint[] = []
    for (const mintEvent of mintEvents) {
      const hasBeenTransferredOut = transferOutEvents.find(
        (transferOutEvent) => transferOutEvent.punkId === mintEvent.punkId,
      )
      if (!hasBeenTransferredOut) {
        userPunkIds.push(mintEvent.punkId)
      }
    }

    for (const transferInEvent of transferInEvents) {
      if (userPunkIds.includes(transferInEvent.punkId)) continue
      const hasBeenTransferredOut = transferOutEvents.find(
        (transferOutEvent) =>
          transferOutEvent.punkId === transferInEvent.punkId &&
          transferOutEvent.blockNumber >= transferInEvent.blockNumber,
      )
      if (!hasBeenTransferredOut) {
        userPunkIds.push(transferInEvent.punkId)
      }
    }

    const punkAssets = userPunkIds.map(punkId => new AssetWithAmount({
      amount: '1',
      address: cryptoPunksAddress,
      category: AssetType.CRYPTOPUNKS,
      name: `CryptoPunk ${punkId}`,
      symbol: 'C',
      tokenId: punkId,
      collectionName: 'CryptoPunks',
      isVerified: false,
      decimals: 0,
      chainId: SupportedChain.Sepolia, // we are manually fetching testing cryptopunk only on sepolia
    }))
    return punkAssets
  }

  const fetchUserNFTsPage = async (
    { chainId, userAddress, page, metadata_source, subdomain }: FetchUserNftsPageParams,
    { signal }: { signal?: AbortSignal } = {},
  ) => {
    const response = await fetchUserNfts(
      String(chainId),
      userAddress,
      {
        ...(page && { page: String(page) }),
        ...(metadata_source && { metadata_source }),
        ...(subdomain && { subdomain }),
      },
      { signal },
    )

    if (!response?.data) {
      Sentry.captureMessage(
        `Received not error, but response === ${response} at the same time while fetching user nfts from backend.`,
      )
    }

    return {
      next: response.data.next ?? null,
      results: (response.data.assets ?? []).map((asset) => AssetWithAmount.createFromBackendModel(asset)),
      metadata_source: response.data.metadata_source ?? null,
    }
  }

  return {
    fetchUserNftBalance,
    fetchUserERC721Balance,
    fetchUserERC1155Balance,
    isFetchingUserNfts: readonly(isFetchingUserNfts),
    setIsFetchingUserNfts,
    loadUserCryptoPunks,
    fetchOwnerOfERC721,
    fetchUserNFTsPage,
  }
}
