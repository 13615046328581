import { getProtocolKit } from './useSafeWalletSdk'
import { encodeFunctionData } from 'viem'
import type { Abi, Address, ContractFunctionArgs, ContractFunctionName, Hex, Log, RpcLog, TransactionReceipt } from 'viem'
import type { WriteContractVariables } from '@wagmi/vue/query'
import { pwnWagmiConfig } from './usePwnWagmiConfig'
import type { PwnWagmiConfig } from './usePwnWagmiConfig'
import { getAccount } from '@wagmi/vue/actions'
import SafeApiKit from '@safe-global/api-kit'
import type { SendTransactionOptions } from './useTransactions'
import to from '@/utils/await-to-js'

/* eslint-disable no-console */
// TODO change type of transaction parameter to always have chainId filled, right now it's marked as optional
//  and also allows undefined value, which we should not allow
export async function sendTransactionOnBehalfOfSafeWallet<
  const TAbi extends Abi,
  TFunctionName extends ContractFunctionName<TAbi, 'nonpayable' | 'payable'>,
  TArgs extends ContractFunctionArgs<TAbi, 'nonpayable' | 'payable', TFunctionName>
>(
  safeAddress: Address,
  transaction: WriteContractVariables<TAbi, TFunctionName, TArgs, PwnWagmiConfig, PwnWagmiConfig['chains'][number]['id']>,
  { step, hooks }: Exclude<SendTransactionOptions, 'safeAddress'> = {},
): Promise<TransactionReceipt> {
  const chainId = transaction.chainId!
  const { address: userAddress } = getAccount(pwnWagmiConfig)

  console.log('Calling sendTransactionOnBehalfOfSafeWallet instead of normal send transaction with.')
  console.log(`Acting as an EOA ${userAddress} on behalf of SafeWallet with address ${safeAddress} on chain ID ${chainId}`)
  const protocolKit = await getProtocolKit(chainId, safeAddress)
  const apiKit = new SafeApiKit({ chainId: BigInt(chainId) })
  const infoAboutSafe = await apiKit.getSafeInfo(safeAddress)

  console.log('Info about Safe{Wallet}:')
  console.log(infoAboutSafe)

  const safeTransaction = await protocolKit.createTransaction({
    transactions: [
      {
        to: transaction.address,
        value: String(transaction.value || 0n),
        // @ts-expect-error not sure why typings are off here
        data: encodeFunctionData({
          abi: transaction.abi,
          functionName: transaction.functionName,
          ...(transaction.args && { args: transaction.args }),
        }),
      },
    ],
  })
  console.log('Created safeTransaction:')
  console.log(safeTransaction)

  if (infoAboutSafe.threshold === 1) {
    console.log(`Detected Safe{Wallet}=${safeAddress} on chainId=${chainId} with threshold 1. Currently connected account ${userAddress} is the owner of the Safe{Wallet} so we proceed with executing the transaction on behalf of this safe.`)
    const [executeTxError, executeTxResponse] = await to(protocolKit.executeTransaction(safeTransaction))
    console.log('executeTxResponse:')
    console.log(executeTxResponse)
    console.log('executeTxError:')
    console.log(executeTxError)

    if (executeTxError || !executeTxResponse?.transactionResponse) {
      if (hooks?.onWriteContractError) {
        hooks.onWriteContractError()
      }
      throw executeTxError
    }

    if (hooks?.onWriteContractSuccess) {
      hooks.onWriteContractSuccess()
    }
    if (step) {
      step.txHash = executeTxResponse.hash as Hex
    }
    const [confirmTxError, txReceipt] = await to(executeTxResponse.transactionResponse.wait())
    if (confirmTxError || !txReceipt) {
      if (hooks?.onTxConfirmError) {
        hooks.onTxConfirmError()
      }
      throw confirmTxError
    }

    if (hooks?.onTxConfirmSuccess) {
      hooks.onTxConfirmSuccess()
    }
    const viemTxReceipt = {
      ...txReceipt,
      transactionHash: txReceipt?.hash as Hex,
      transactionIndex: Number(txReceipt.index),
      effectiveGasPrice: txReceipt?.gasPrice,
      logs: (txReceipt?.logs ?? []).map(log => {
        const newLog = log as unknown as Log | RpcLog
        newLog.logIndex = log.index
        return newLog
      }),
      blockNumber: BigInt(txReceipt.blockNumber),
      status: txReceipt.status === 1 ? 'success' : 'reverted',
      type: 'eip1559', // TODO how to resolve the type
    } as TransactionReceipt
    console.log('viemTxReceipt:')
    console.log(viemTxReceipt)
    return viemTxReceipt
  } else {
    console.log(`Detected Safe{Wallet}=${safeAddress} on chainId=${chainId} with threshold > 1. Currently connected account ${userAddress} is one of owners of the Safe{Wallet} so the owner will propose a transaction and adds his first signature to this proposal. However he will must wait for others to confirm and reach the threshold in order for this TX to be valid.`)
    const safeTxHash = await protocolKit.getTransactionHash(safeTransaction)
    console.log(`safeTxHash=${safeTxHash}`)
    const senderSignature = await protocolKit.signHash(safeTxHash)
    console.log('senderSignature:')
    console.log(senderSignature)
    const proposedTransaction = await apiKit.proposeTransaction({
      safeAddress,
      safeTxHash,
      safeTransactionData: safeTransaction.data,
      senderAddress: getAccount(pwnWagmiConfig).address!,
      senderSignature: senderSignature.data,
    })
    console.log('Proposed transaction:')
    console.log(proposedTransaction)
    // TODO what to do here?
    throw new Error('You need to wait for other signers in multisig to confirm.')
  }
}
/* eslint-enable no-console */
