<template>
  <div>
    <div class="all-time-price__label-and-tooltip">
      <span class="all-time-price__label"> {{ label }}</span>
      <QuestionMarkTooltip
        v-if="tooltipText"
        :tooltip-text="tooltipText"/>
    </div>

    <div>
      <div class="all-time-price__currency">
        <BasePriceLabel
          v-if="currency.primary.price"
          class="all-time-price__primary-currency"
          :symbol="currency.primary.symbol"
          :amount="currency.primary.price"
          :is-symbol-before-amount="currency.primary.symbol === '$'"/>

        <div class="all-time-price__secondary-currency">
          <template v-if="currency.secondary.price">
            (<BasePriceLabel
              class="all-time-price__secondary-currency"
              :symbol="currency.secondary.symbol"
              :amount="currency.secondary.price"
              :is-symbol-before-amount="currency.secondary.symbol === '$'"/>
            )
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import BasePriceLabel from '@/general-components/BasePriceLabel.vue'
import useAssetPriceHistory, { CurrencyType } from '@/modules/pages/asset/nft-page/SectionPriceHistory/useAssetPriceHistory'
import type { Nullable } from '@/modules/common/typings/customTypes'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'

interface Props {
  label: string;
  tooltipText: string;
  usdAmount?: Nullable<string>
  ethAmount?: Nullable<string>
}
const props = withDefaults(defineProps<Props>(), {
  usdAmount: '-',
  ethAmount: '-',
})
const { usdAmount, ethAmount } = toRefs(props)

const { priceHistoryPrimaryCurrency } = useAssetPriceHistory()

interface PrimaryAndSecondaryCurrency {
  primary: {
    symbol: 'ETH' | '$'
    price: string
  }
  secondary: {
    symbol: 'ETH' | '$'
    price: string
  }
}
// @ts-expect-error FIXME: strictNullChecks
const currency = computed<PrimaryAndSecondaryCurrency>(() => {
  const ethPriceDetails = {
    symbol: 'ETH',
    price: ethAmount.value,
  } as const
  const usdPriceDetails = {
    symbol: '$',
    price: usdAmount.value,
  } as const

  if (priceHistoryPrimaryCurrency.value === CurrencyType.ETH && ethPriceDetails.price) {
    return {
      primary: ethPriceDetails,
      secondary: usdPriceDetails,
    }
  } else {
    return {
      primary: usdPriceDetails,
      secondary: ethPriceDetails,
    }
  }
})
</script>

<style scoped>
.all-time-price {
  width: 6.25rem;

  &__currency {
    display: flex;
    align-items: baseline;
  }

  &__primary-currency,
  &__secondary-currency {
    display: flex;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
  }

  &__primary-currency {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  &__secondary-currency {
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    align-items: flex-end;
    color: var(--gray);
  }

  &__label {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  &__label-and-tooltip {
    display: flex;
    gap: 0.35rem;
    align-items: center;
  }

  &__question-mark {
    cursor: help;
    line-height: 1.375rem;
    font-size: 0.875rem;
    color: var(--gray);
  }
}
</style>
