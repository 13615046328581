import type { Address } from 'viem'
import {
  createPublicClient,
  encodePacked,
  http,
  isAddress,
  keccak256,
  namehash,
} from 'viem'
import { base, mainnet } from 'viem/chains'
import L2ResolverAbi from './basename/L2ResolverAbi'
import { SupportedChain } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { normalize } from 'viem/ens'

// inspired by this tutorial:
//  - https://docs.base.org/docs/basenames-tutorial-using-wagmi/
// and this related gist
//  - https://gist.github.com/hughescoin/95b680619d602782396fa954e981adae

export type Basename = `${string}.base.eth`;
const BASENAME_L2_RESOLVER_ADDRESS = '0xC6d566A56A1aFf6508b41f6c90ff131615583BCD'

const publicClient = createPublicClient({
  chain: base,
  transport: http(CHAINS_CONSTANTS[SupportedChain.Base].nodeProvider.httpNodeUrl, {
    ...(CHAINS_CONSTANTS[SupportedChain.Base].nodeProvider.bearerAuthToken && {
      fetchOptions: {
        headers: {
          Authorization: `Bearer ${CHAINS_CONSTANTS[SupportedChain.Base].nodeProvider.bearerAuthToken}`,
        },
      },
    }),
  }),
})

/**
 * Convert an chainId to a coinType hex for reverse chain resolution
 */
export const convertChainIdToCoinType = (chainId: number): string => {
  // L1 resolvers to addr
  if (chainId === mainnet.id) {
    return 'addr'
  }

  const cointype = (0x80000000 | chainId) >>> 0
  return cointype.toString(16).toLocaleUpperCase()
}

/**
 * Convert an address to a reverse node for ENS resolution
 */
const convertReverseNodeToBytes = (
  address: Address,
  chainId: number,
) => {
  const addressFormatted = address.toLocaleLowerCase() as Address
  const addressNode = keccak256(addressFormatted.substring(2) as Address)
  const chainCoinType = convertChainIdToCoinType(chainId)
  const baseReverseNode = namehash(
    `${chainCoinType.toLocaleUpperCase()}.reverse`,
  )
  const addressReverseNode = keccak256(
    encodePacked(['bytes32', 'bytes32'], [baseReverseNode, addressNode]),
  )
  return addressReverseNode
}

export async function getBasename(address: Address): Promise<Basename | undefined> {
  try {
    const addressReverseNode = convertReverseNodeToBytes(address, base.id)
    const basename = await publicClient.readContract({
      abi: L2ResolverAbi,
      address: BASENAME_L2_RESOLVER_ADDRESS,
      functionName: 'name',
      args: [addressReverseNode],
    })
    if (!basename) {
      return undefined
    }
    return basename as Basename
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error resolving Basename:', error)
    return undefined
  }
}

export async function getBasenameAvatar(basename: Basename) {
  try {
    return await publicClient.getEnsAvatar({
      name: basename,
      universalResolverAddress: BASENAME_L2_RESOLVER_ADDRESS,
    })
  } catch (error) {
    return undefined
  }
}

export async function resolveAddressForBasename(address: Address) {
  if (!address || !isAddress(address)) {
    return undefined
  }

  const basename = await getBasename(address) ?? null
  let avatar: string | null = null
  if (basename) {
    avatar = await getBasenameAvatar(basename) ?? null
  }

  return {
    address,
    name: basename,
    avatar,
  }
}

export async function resolveBasename(name: string) {
  // TODO also implement caching here?
  try {
    return await publicClient.getEnsAddress({
      name: normalize(name),
      universalResolverAddress: BASENAME_L2_RESOLVER_ADDRESS,
    })
  } catch (error) {
    return undefined
  }
}
