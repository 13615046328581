import type { SupportedChain } from '@/constants/chains/types'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { getStarknetTransport } from '@/modules/common/starknet/usePwnStarknetConfig'
import { useQueries } from '@tanstack/vue-query'
import { Contract } from 'starknet'
import type { Abi } from 'starknet'
import { formatUnits } from 'viem'
import type { Address } from 'viem'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import STARKNET_ERC20_ABI from '@/contracts/starknet/misc/complied/ERC20ABI.json'

export const readStarknetERC20Allowance = async (address: Address, chainId: SupportedChain, owner: Address, spender: Address): Promise<bigint> => {
  const transport = getStarknetTransport(chainId)
  const contract = new Contract(STARKNET_ERC20_ABI.abi as Abi, address, transport)
  const response = await contract.allowance(owner, spender)
  const { remaining: { low } } = response
  return low
}

export const readStarknetERC20Balance = async (address: Address, chainId: SupportedChain, owner: Address): Promise<bigint> => {
  const transport = getStarknetTransport(chainId)
  const contract = new Contract(STARKNET_ERC20_ABI.abi as Abi, address, transport)
  const response = await contract.balanceOf(owner)
  const { balance: { low } } = response
  return low
}

export const useStarknetAssetBalances = (
  tokens: MaybeRef<AssetWithAmount[]>,
  chainId: SupportedChain,
  owner: MaybeRef<Address>,
) => {
  const getERC20Balance = async (address: Address, owner: Address, token: AssetWithAmount) => {
    const balance = await readStarknetERC20Balance(address, chainId, owner)

    const formattedAmount = formatUnits(balance, token.decimals)
    const assetWithUpdatedBalance = token.updateAssetAmounts({
      amount: formattedAmount,
    })

    return {
      [address]: assetWithUpdatedBalance,
    }
  }

  const queries = computed(() => {
    return unref(tokens).map((token) => ({
      queryKey: ['starknet', 'asset', 'balance', token.address, owner],
      queryFn: (ctx) => getERC20Balance(ctx.queryKey[3], ctx.queryKey[4], token),
    }))
  })

  const tokenBalances = useQueries({
    queries,
    combine: (data) => {
      return data.reduce((acc, curr) => {
        return Object.assign(acc, curr.data)
      }, {} as Record<Address, bigint>)
    },
  })

  return tokenBalances
}
