import { ref } from 'vue'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import { SORT_OPTIONS_LOOKUP, SortOption } from '@/modules/sections/your-assets/your-coins/YourCoinsDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { filterAssetsInSearchResults } from '@/utils/search'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

const searchTerm = ref('')

const userAddressToDisplay = ref<string>()
const userCoinsToDisplay = ref<AssetWithAmount[]>([]) // when viewing other assets than the currently logged in user

const selectedSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-your-coins',
  { id: SortOption.Symbol, direction: SortDirection.Descending, viewName: 'sort-option-your-coins' },
  Object.keys(SORT_OPTIONS_LOOKUP),
))

export default function useYourCoins() {
  const getSortedAndFilteredCoins = (coins: AssetWithAmount[], searchTerm: string, sorting: ActiveSortOption) => {
    let filteredCoins = filterAssetsInSearchResults(coins, searchTerm)
    filteredCoins = useGlobalFiltersStore().applyShowUnverifiedAssetsFilter(filteredCoins)
    return SORT_OPTIONS_LOOKUP[sorting.id](filteredCoins, sorting.direction)
  }

  return {
    selectedSortOption,
    searchTerm,
    userCoinsToDisplay,
    userAddressToDisplay,
    getSortedAndFilteredCoins,
  }
}
