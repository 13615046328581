export enum ExplorerStores {
    // Additional data stores
    CollectionSearchStats = 'explorer:collection-search-stats',
    TokenSearchStats = 'explorer:token-search-stats',
    LoanSearchData = 'explorer:loan-search-data',
    NftSearchStats = 'explorer:nft-search-stats',
    WalletSearchStats = 'explorer:wallet-search-stats',

    // Page stores
    AssetsTabStore = 'explorer:assets-tab',
    CollectionsTabStore = 'explorer:collections-tab',
    LoansTabStore = 'explorer:loans-tab',

    // Searches
    NavbarSearch = 'explorer:navbar-search',
    HomepageSearch = 'explorer:homepage-search',
    ExplorerPageSearch = 'explorer:explorer-page-search',

    // Utils
    SearchPagination = 'explorer:search-pagination',
}
