<template>
  <div>
    <PwnSafeTokenizeModal/>
    <PwnSafeTransferModal/>
    <PwnSafeClaimAndBurnModal/>
    <CreateNewPwnSafeModal
      final-button-text="Continue to Mint ATR"
      @on-final-button-click="handleNewPwnSafeModalFinish"/>
    <PwnSafePageHeader
      :label="headerLabel"
      :icon="headerIcon"
      :has-wallet-connect="hasWalletConnect"
      :selected-pwn-safe="selectedPwnSafe"
      :address="headerAddress"
      @go-back="goBack"/>
    <div class="pwn-safe-assets-dashboard__filters">
      <BaseSearch
        v-model="searchTermComputed"
        :class="['pwn-safe-assets-dashboard__search', { 'pwn-safe-assets-dashboard__search--selected': searchTermComputed }]"
        search-placeholder="Search assets"
        has-clear-button/>

      <RefreshButton
        :is-loading="isFetchingNfts || isFetchingCoins"
        :handle-refresh="handleRefresh"/>

      <ChainGroupFilter
        class="pwn-safe-assets-dashboard__chain-switcher"
        :set-chain="handleChainSelect"
        :selected-chains="(selectedPWNSafeChains as SupportedChain[])"
        :disabled-chains="disabledChains"
        :show-all-networks="isSelectedCurrentWallet"/>
    </div>
    <BigTabButtons
      class="pwn-safe-assets-dashboard__big-tab-buttons"
      :tabs="tabs"
      :selected-tab-default="selectedTab"
      :has-all-tabs-displayed-as-active="Boolean(searchTermComputed)"
      @on-tab-select="handleTabSelect"/>

    <BaseEmptyStateText
      v-if="hasNoSearchResults"
      :text="getEmptyStateText"/>

    <div
      v-if="isTabContentDisplayed(Tab.Assets) && !hasNoSearchResults"
      class="pwn-safe-assets-dashboard__section-container">
      <PwnSafeAssetTable
        v-model:active-sort-option="selectedNftsSortOption"
        :is-fetching-assets="isFetchingNfts"
        :sorted-assets="filteredNfts"
        heading="NFTs "
        :is-row-selectable="false"
        :table-definition="ASSET_TABLE_DEFINITION"
        @handle-transfer="handleTransfer"
        @handle-tokenize="handleTokenize"/>

      <PwnSafeAssetTable
        v-model:active-sort-option="selectedCoinsSortOption"
        :is-fetching-assets="isFetchingCoins"
        :sorted-assets="filteredCoins"
        heading="Tokens "
        :is-row-selectable="false"
        :table-definition="ASSET_TABLE_DEFINITION"
        @handle-transfer="handleTransfer"
        @handle-tokenize="handleTokenize"/>
    </div>

    <div
      v-if="isTabContentDisplayed(Tab.AtrTokens) && !hasNoSearchResults"
      :class="{ 'pwn-safe-assets-dashboard__section-atr-table--search-results': searchTermComputed }">
      <PwnSafeAtrTable
        v-model:active-sort-option="selectedAtrTokensSortOption"
        :is-fetching-atr-tokens="isFetchingNfts"
        :sorted-atr-tokens="sortedAndMaybeFilteredAtrTokens"
        :table-definition="ATR_TABLE_DEFINITION"
        heading="ATR Tokens "
        :is-row-selectable="false"
        @handle-transfer="handleTransfer"
        @handle-claim-and-burn="handleClaimAndBurn"
        @on-switch-display-coins="handleOnSwitchDisplayCoins"
        @on-switch-display-nfts="handleOnSwitchDisplayNfts"/>
    </div>
  </div>
  <PwnSafeWhitelistBanner
    v-if="isConnected"
    class="pwn-safe-assets-dashboard__whitelist-banner"/>
</template>

<script setup lang="ts">
import PwnSafePageHeader from '@/modules/pages/pwn-safe/PwnSafePageHeader.vue'
import BigTabButtons from '@/general-components/BigTabButtons.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import BaseSearch from '@/general-components/BaseSearch.vue'
import { computed, ref, toRefs } from 'vue'
import type { Component } from 'vue'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import AssetType from '@/modules/common/assets/AssetType'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import usePwnSafeAssetsDashboard, { Tab } from '@/modules/pages/pwn-safe/usePwnSafeAssetsDashboard'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import useYourCoins from '@/modules/sections/your-assets/your-coins/useYourCoins'
import PwnSafeAssetTable from '@/modules/pages/pwn-safe/tables/PwnSafeAssetTable.vue'
import PwnSafeAtrTable from '@/modules/pages/pwn-safe/tables/PwnSafeAtrTable.vue'
import useAssetsSelection from '@/modules/common/assets/useAssetsSelection'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import usePwnSafeTransferModal from '@/modules/pages/pwn-safe/transfer/modal/usePwnSafeTransferModal'
import usePwnSafeTokenizeModal from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModal'
import PwnSafeTokenizeModal from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/PwnSafeTokenizeModal.vue'
import PwnSafeTransferModal from '@/modules/pages/pwn-safe/transfer/modal/PwnSafeTransferModal.vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import useCreateNewPwnSafeModal from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/useCreateNewPwnSafeModal'
import CreateNewPwnSafeModal from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/CreateNewPwnSafeModal.vue'
import { ATR_TABLE_DEFINITION, ASSET_TABLE_DEFINITION } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import usePwnSafeClaimAndBurnModal from '@/modules/pages/pwn-safe/claim-and-burn/modal/usePwnSafeClaimAndBurnModal'
import PwnSafeClaimAndBurnModal from '@/modules/pages/pwn-safe/claim-and-burn/modal/PwnSafeClaimAndBurnModal.vue'
import usePwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import PwnSafeWhitelistBanner from '@/modules/pages/pwn-safe/PwnSafeWhitelistBanner.vue'
import { storeToRefs } from 'pinia'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import type { Address } from 'viem'
import RefreshButton from '@/modules/common/assets/components/RefreshButton.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import type { SupportedChain } from '@/constants/chains/types'

interface Props {
  headerLabel: string
  headerIcon: Component
  headerAddress: Address
  searchTerm: string
  sortedNfts: AssetWithAmount[]
  sortedCoins: AssetWithAmount[]
  sortedAtrTokens: AssetWithAmount[]
  selectedPwnSafe?: PwnSafe
  isFetchingNfts?: boolean
  isFetchingCoins?: boolean
  hasWalletConnect?: boolean
  handleReload?: () => void
  availableSafes?: PwnSafe[]
}

const props = defineProps<Props>()

const selectedChainsStore = useSelectedChainsStore()
const { selectedChains } = storeToRefs(selectedChainsStore)

const { isConnected } = useCustomAccount()

const { headerLabel, searchTerm, sortedNfts, sortedCoins, isFetchingNfts, isFetchingCoins, sortedAtrTokens, selectedPwnSafe, hasWalletConnect } = toRefs(props)
const { selectedSortOption: selectedNftsSortOption } = useYourNFTs()
const { selectedSortOption: selectedCoinsSortOption } = useYourCoins()
const { selectedSortOption: selectedAtrTokensSortOption, selectedTab } = usePwnSafeAssetsDashboard()
const { selectedAssetsToTokenize } = usePwnSafeTokenize()
const { assetsToTransfer, fromWallet } = usePwnSafeTransfer()

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { isSelectedCurrentWallet, walletAsSafe, disabledChains } = storeToRefs(pwnSafeDetailStore)

const { isOpen: transferModalIsOpen } = usePwnSafeTransferModal()
const { addToSelectedAssets } = useAssetsSelection()
const { isOpen: pwnSafeTokenizeIsOpen } = usePwnSafeTokenizeModal()
const { isOpen: pwnSafeClaimAndBurnIsOpen } = usePwnSafeClaimAndBurnModal()
const { atrTokensToClaimAndBurn } = usePwnSafeClaimAndBurn()

const isDisplayAtrTokenHoldingNfts = ref(false)
const isDisplayAtrTokenHoldingCoins = ref(false)
const router = useRouter()

// eslint-disable-next-line
const emit = defineEmits<{
  (e: 'on-set-search-term', searchTermValue: string): void;
  (e: 'handle-transfer', asset: AssetWithAmount): void;
  (e: 'handle-tokenize', asset: AssetWithAmount): void;
  (e: 'handle-claim-and-burn', asset: AssetWithAmount): void;
  (e: 'on-switch-display-nfts', value: boolean): void;
  (e: 'on-switch-display-coins', value: boolean): void;
}>()

const handleOnSwitchDisplayNfts = (val: boolean) => {
  isDisplayAtrTokenHoldingNfts.value = val
}

const handleOnSwitchDisplayCoins = (val: boolean) => {
  isDisplayAtrTokenHoldingCoins.value = val
}

const searchTermComputed = computed({
  get: (): string => searchTerm.value,
  set: (searchTermValue: string) => emit('on-set-search-term', searchTermValue),
})

const tabs = Object.values(Tab)
const isTabContentDisplayed = (tab: Tab) => selectedTab.value === tab || searchTermComputed.value

const handleTabSelect = (tab: string) => {
  selectedTab.value = tab as Tab
}

const hasNoSearchResults = computed(() => {
  return searchTerm.value &&
    sortedNfts.value.length === 0 &&
    sortedCoins.value.length === 0 &&
    sortedAtrTokens.value.length === 0
})

const getEmptyStateText = computed(() => `No results for your search "${searchTerm.value}"`)

const sortedAndMaybeFilteredAtrTokens = computed(() => {
  return sortedAtrTokens.value.filter(({ assetOfAtrToken }) => {
    if (!assetOfAtrToken) {
      // some weird case when it's null
      return true
    }
    if (!isDisplayAtrTokenHoldingNfts.value && !isDisplayAtrTokenHoldingCoins.value) return true

    if (assetOfAtrToken.category === AssetType.ERC721 || assetOfAtrToken.category === AssetType.ERC1155) {
      return isDisplayAtrTokenHoldingNfts.value
    }
    if (assetOfAtrToken.category === AssetType.ERC20) {
      return isDisplayAtrTokenHoldingCoins.value
    }
    return false
  })
})

const goBack = async () => {
  await router.push({ name: RouteName.PwnSafe })
}

const handleTokenize = (asset: AssetWithAmount) => {
  selectedAssetsToTokenize.value = []
  addToSelectedAssets({ asset, selectedAssets: selectedAssetsToTokenize })

  const hasPwnSafe = props.availableSafes?.length && props.availableSafes?.map(v => v.chainId).includes(asset.chainId)

  if (hasPwnSafe || useConnectedAccountTypeStore().isConnectedPwnSafe) {
    pwnSafeTokenizeIsOpen.value = true
  } else {
    useCreateNewPwnSafeModal().isOpen.value = true
  }
}

const handleNewPwnSafeModalFinish = () => {
  useCreateNewPwnSafeModal().isOpen.value = false
  pwnSafeTokenizeIsOpen.value = true
}

const handleTransfer = (asset: AssetWithAmount) => {
  if (asset.isFungible) asset.amountInput = asset.maxAvailableAmount

  fromWallet.value = isSelectedCurrentWallet.value ? walletAsSafe.value : selectedPwnSafe.value
  assetsToTransfer.value = []
  addToSelectedAssets({ asset, selectedAssets: assetsToTransfer })
  transferModalIsOpen.value = true
}

const handleClaimAndBurn = (asset: AssetWithAmount) => {
  atrTokensToClaimAndBurn.value = []

  addToSelectedAssets({ asset, selectedAssets: atrTokensToClaimAndBurn })
  pwnSafeClaimAndBurnIsOpen.value = true
}

const handleChainSelect = (selectedChain: SetChainFilterOption) => {
  if (isSelectedCurrentWallet.value) {
    selectedChainsStore.actions.setSelectedChain(selectedChain)
  }
}

const handleRefresh = async () => {
  if (props.handleReload) {
    props.handleReload()
  }
}

const selectedPWNSafeChains = computed(() => {
  if (isSelectedCurrentWallet.value || !selectedPwnSafe.value) {
    return selectedChains.value
  }
  return [selectedPwnSafe.value.chainId]
})

const filteredNfts = computed(() => {
  return sortedNfts.value.filter((nft) => !disabledChains.value.includes(nft.chainId))
})

const filteredCoins = computed(() => {
  return sortedCoins.value.filter((coin) => !disabledChains.value.includes(coin.chainId))
})

</script>

<style scoped>
.pwn-safe-assets-dashboard {
  &__big-tab-buttons {
    margin-bottom: 2.5rem;
  }

  &__section-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  &__section {
    margin-bottom: 4rem;
  }

  &__filters {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: space-between;
  }

  &__chain-switcher {
    flex: 1 0 auto;
  }

  &__search {
    flex: 0 1 100%;

    border: 1px solid var(--gray);
    padding: 0.3rem;
    font-family: var(--font-family-oxygen-mono);

    &--selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__amount {
    display: flex;
    text-align: right;
    margin-right: 1.25rem;
  }

  &__asset-action-container {
    display: flex;
    gap: 1rem;
  }

  &__asset-action {
    display: flex;
    font-family: var(--font-family-autoscape);
    color: var(--primary-color-1);
  }

  &__asset-action-icon {
    margin-right: 0.5rem;
  }

  &__whitelist-banner {
    padding: 2.5rem 0;
  }

  &__section-atr-table--search-results {
    margin-top: 3rem;
  }

  @media only screen and (--mobile-viewport) {
    &__section-container {
      flex-direction: column;
      gap: 3rem;
    }

    &__filters {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    &__chain-switcher {
      grid-column: 1/3;
      flex: 1 0 auto;
    }

    &__big-tab-buttons {
      display: flex;
      flex-direction: column;
    }

    &__section {
      margin-bottom: 2rem;
    }

    &__search {
      flex: 1 0 auto;
    }

    &__amount {
      margin-right: 0.5rem;
    }

    &__asset-action {
      font-size: 1rem;
    }

    &__asset-action-icon {
      margin-right: 0.25rem;
    }

    &__whitelist-banner {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
