<template>
  <div :class="['bundling-animation', {'bundling-animation--success': isSuccessfullyBundled}]">
    <img
      v-for="bundleAsset in bundleAssets.slice(0,maxBundleAssetsDisplayed)"
      :key="bundleAsset.id"
      ref="bundleAssetsRefs"
      alt="bundle asset"
      class="bundling-animation__asset"
      :src="bundleAsset.image">
    <div
      v-if="bundleAssets.length > (maxBundleAssetsDisplayed)"
      :class="['bundling-animation__assets-plus-amount', {'bundling-animation__assets-plus-amount--small': isSmallerPlusAmount}]">
      +{{ assetsPlusAmount }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, toRefs } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

const TRESHOLD_FOR_SMALLER_PLUS_AMOUNT = 10

const props = defineProps<{
  bundleAssets: AssetWithAmount[]
  isSuccessfullyBundled: boolean
}>()

const { bundleAssets } = toRefs(props)
const bundleAssetsRefs = ref<HTMLImageElement[]>()
const maxBundleAssetsDisplayed = computed(() => bundleAssets.value.length === 7 ? 7 : 6)
const assetsPlusAmount = computed(() => bundleAssets.value.length - maxBundleAssetsDisplayed.value)
const isSmallerPlusAmount = computed(() => assetsPlusAmount.value >= TRESHOLD_FOR_SMALLER_PLUS_AMOUNT)

onMounted(async () => {
  if (!bundleAssetsRefs.value) return
  switch (bundleAssetsRefs.value.length) {
  case 1:
    bundleAssetsRefs.value[0].classList.add('bundling-animation__asset-top-left')
    bundleAssetsRefs.value[0].style.animationName = 'asset-top-left-anim'
    break
  case 2:
    bundleAssetsRefs.value[0].classList.add('bundling-animation__asset-top-left')
    bundleAssetsRefs.value[0].style.animationName = 'asset-top-left-anim'
    bundleAssetsRefs.value[1].classList.add('bundling-animation__asset-down-right')
    bundleAssetsRefs.value[1].style.animationName = 'asset-down-right-anim'
    break
  case 3:
    bundleAssetsRefs.value[0].classList.add('bundling-animation__asset-top-left')
    bundleAssetsRefs.value[0].style.animationName = 'asset-top-left-anim'
    bundleAssetsRefs.value[1].classList.add('bundling-animation__asset-top-right')
    bundleAssetsRefs.value[1].style.animationName = 'asset-top-right-anim'
    bundleAssetsRefs.value[2].classList.add('bundling-animation__asset-down-left')
    bundleAssetsRefs.value[2].style.animationName = 'asset-down-left-anim'
    break
  case 4:
    bundleAssetsRefs.value[0].classList.add('bundling-animation__asset-top-left')
    bundleAssetsRefs.value[0].style.animationName = 'asset-top-left-anim'
    bundleAssetsRefs.value[1].classList.add('bundling-animation__asset-top-right')
    bundleAssetsRefs.value[1].style.animationName = 'asset-top-right-anim'
    bundleAssetsRefs.value[2].classList.add('bundling-animation__asset-down-left')
    bundleAssetsRefs.value[2].style.animationName = 'asset-down-left-anim'
    bundleAssetsRefs.value[3].classList.add('bundling-animation__asset-down-right')
    bundleAssetsRefs.value[3].style.animationName = 'asset-down-right-anim'
    break
  default:
    bundleAssetsRefs.value[0].classList.add('bundling-animation__asset-top-left')
    bundleAssetsRefs.value[0].style.animationName = 'asset-top-left-anim'
    bundleAssetsRefs.value[1].classList.add('bundling-animation__asset-top-right')
    bundleAssetsRefs.value[1].style.animationName = 'asset-top-right-anim'
    bundleAssetsRefs.value[2].classList.add('bundling-animation__asset-down-left')
    bundleAssetsRefs.value[2].style.animationName = 'asset-down-left-anim'
    bundleAssetsRefs.value[3].classList.add('bundling-animation__asset-down-right-small-top-left', 'bundling-animation__asset-small')
    bundleAssetsRefs.value[3].classList.remove('bundling-animation__asset')
    bundleAssetsRefs.value[3].style.animationName = 'asset-down-right-anim'
    bundleAssetsRefs.value[4].classList.add('bundling-animation__asset-down-right-small-top-right', 'bundling-animation__asset-small')
    bundleAssetsRefs.value[4].classList.remove('bundling-animation__asset')
    bundleAssetsRefs.value[4].style.animationName = 'asset-down-right-anim'
    if (bundleAssetsRefs.value.length >= 6) {
      bundleAssetsRefs.value[5].classList.add('bundling-animation__asset-down-right-small-down-left', 'bundling-animation__asset-small')
      bundleAssetsRefs.value[5].classList.remove('bundling-animation__asset')
      bundleAssetsRefs.value[5].style.animationName = 'asset-down-right-anim'

      if (bundleAssetsRefs.value.length === 7) {
        bundleAssetsRefs.value[6].classList.add('bundling-animation__asset-down-right-small-down-right', 'bundling-animation__asset-small')
        bundleAssetsRefs.value[6].classList.remove('bundling-animation__asset')
        bundleAssetsRefs.value[6].style.animationName = 'asset-down-right-anim'
      }
    }
  }
})

</script>

<style>
.bundling-animation {
  --base-move-size: 7rem;
  --base-move-size-minus: -7rem;
  --top-left-x: var(--base-move-size-minus);
  --top-left-y: var(--base-move-size-minus);
  --top-right-x: var(--base-move-size);
  --top-right-y: var(--base-move-size-minus);
  --down-right-x: var(--base-move-size);
  --down-right-y: var(--base-move-size);
  --down-left-x: var(--base-move-size-minus);
  --down-left-y: var(--base-move-size);
  --animation-duration: 2s;
  position: relative;
  height: 16.25rem;
  width: 16.25rem;
  border-radius: 1.625rem;
  margin: 2rem auto 1rem;
  display: block;

  background-color: var(--gray-3);
  border: 3px solid var(--primary-color-1);

  &--success {
    box-shadow: 0 0 1.875rem 0.3125rem rgb(0 255 224 / 17%);
  }

  &__asset {
    position: absolute;
    width: 7.15rem;
    height: 7.15rem;
    object-fit: contain;
    border-radius: 1.2rem;
    animation-duration: var(--animation-duration);
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &__asset-top-left {
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
  }

  &__asset-top-right {
    position: absolute;
    top: 0.3rem;
    left: 8.3rem;
  }

  &__asset-down-left {
    position: absolute;
    top: 8.3rem;
    left: 0.3rem;
  }

  &__asset-down-right {
    position: absolute;
    top: 8.3rem;
    left: 8.3rem;
  }

  &__asset-small {
    position: absolute;
    width: 3.5rem;
    height: 3.5rem;
    object-fit: contain;
    border-radius: 0.6rem;
    animation-duration: var(--animation-duration);
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &__asset-down-right-small-top-left {
    position: absolute;
    top: 8.3rem;
    left: 8.45rem;
  }

  &__asset-down-right-small-top-right {
    position: absolute;
    top: 8.3rem;
    left: 12.1rem;
  }

  &__asset-down-right-small-down-left {
    position: absolute;
    top: 12.05rem;
    left: 8.45rem;
  }

  &__asset-down-right-small-down-right {
    position: absolute;
    top: 12.05rem;
    left: 12.1rem;
  }

  &__assets-plus-amount {
    position: absolute;
    left: 12.5rem;
    top: -4.5rem;
    font-size: 2rem;
    animation: asset-down-right-anim var(--animation-duration) ease-in-out infinite;

    &--small {
      font-size: 1.5rem;
      top: -4.2rem;
      left: 12.2rem;
    }
  }

  @keyframes asset-top-left-anim {
    0% {
      opacity: 0;
      transform: translate(var(--top-left-x), var(--top-left-y));
    }

    20% {
      opacity: 1;
    }

    35% {
      opacity: 1;
      transform: translate(0, 0);
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes asset-top-right-anim {
    0% {
      opacity: 0;
      transform: translate(var(--top-right-x), var(--top-right-y));
    }

    20% {
      opacity: 1;
    }

    35% {
      opacity: 1;
      transform: translate(0, 0);
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes asset-down-right-anim {
    0% {
      opacity: 0;
      transform: translate(var(--down-right-x), var(--down-right-y));
    }

    20% {
      opacity: 1;
    }

    35% {
      opacity: 1;
      transform: translate(0, 0);
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes asset-down-left-anim {
    0% {
      opacity: 0;
      transform: translate(var(--down-left-x), var(--down-left-y));
    }

    20% {
      opacity: 1;
    }

    35% {
      opacity: 1;
      transform: translate(0, 0);
    }

    100% {
      opacity: 0;
    }
  }
}

</style>
