<template>
  <div class="pwn-safe-page-header">
    <div class="pwn-safe-page-header__left">
      <BaseBackButton
        has-emit
        @go-back="emit('go-back')"/>
      <div class="pwn-safe-page-header__safe-container">
        <component
          :is="icon"
          v-if="icon"
          class="pwn-safe-page-header__icon"
          alt="icon"/>
        <div class="pwn-safe-page-header__label">
          {{ label }}
        </div>
        <div class="pwn-safe-page-header__address">
          ({{ shortAddress }})
        </div>
      </div>

      <BaseButton
        v-if="selectedPwnSafe && !isMobile"
        class="pwn-safe-page-header__link-to-settings"
        :color="buttonColor"
        :variant="ButtonVariant.Underline"
        button-text="Manage Safe"
        is-icon-before-text
        :is-disabled="isSettingsView"
        @on-button-click="navigateToSettings">
        <template #icon>
          <SettingsIconSvg
            :class="{'pwn-safe-page-header__link-to-settings--gray': !isSettingsView}"
            width="20"
            height="20"
            alt="settings icon"/>
        </template>
      </BaseButton>
    </div>

    <div class="pwn-safe-page-header__right">
      <BaseButton
        v-if="selectedPwnSafe && isMobile"
        class="pwn-safe-page-header__link-to-settings"
        :color="buttonColor"
        :variant="ButtonVariant.Underline"
        button-text="Manage Safe"
        is-icon-before-text
        :is-disabled="isSettingsView"
        @on-button-click="navigateToSettings">
        <template #icon>
          <SettingsIconSvg
            :class="{'pwn-safe-page-header__link-to-settings--gray': !isSettingsView}"
            width="20"
            height="20"
            alt="settings icon"/>
        </template>
      </BaseButton>
      <WcModalStep1 v-if="hasWalletConnect"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import RouteName from '@/router/RouteName'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { toRefs, computed } from 'vue'
import type { Component } from 'vue'
import { shortenAddress } from '@/utils/utils'
import WcModalStep1 from '@/modules/pages/pwn-safe/pwn-safe-dashboard/WcModalStep1.vue'
import SettingsIconSvg from '@/assets/icons/settings.svg'
import type { Address } from 'viem'
import { isMobile } from '@/utils/mediaQueries'
interface Props {
  label: string
  address: Address
  icon?: Component
  selectedPwnSafe?: PwnSafe
  isSettingsView?: boolean
  hasWalletConnect?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSettingsView: false,
})

const { label, address, icon, isSettingsView, hasWalletConnect } = toRefs(props)
const router = useRouter()

const shortAddress = computed(() => shortenAddress(address.value))
const buttonColor = computed(() => isSettingsView.value ? ButtonColor.Transparent : ButtonColor.White)

const navigateToSettings = () => {
  router.push({ name: RouteName.PwnSafeDetailSettings })
}

const emit = defineEmits<{(e: 'go-back'): void}>()
</script>

<style scoped>
.pwn-safe-page-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 1.5rem;
  padding-bottom: 1rem;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px; /* small hack how to hide buggy double border (top + bottom), when height is 1px */
  }

  &__safe-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    gap: 0.7rem;
  }

  &__left {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 1rem;
    font-family: var(--font-family-autoscape);
  }

  &__icon {
    width: 1.875rem;
    height: 1.875rem;
    margin-right: 0.5rem; /* added to help center icon */
  }

  &__label {
    font-size: 1.25rem;
  }

  &__address {
    font-size: 1rem;
    color: var(--gray-2);
  }

  &__link-to-settings {
    height: 1.5625rem;

    &--gray {
      filter: grayscale(1);
    }
  }

  &__wallet-connect-icon {
    width: 1.6875rem;
    height: 1rem;
  }

  @media only screen and (--mobile-viewport) {
    flex-direction: column;
    gap: 1rem;

    &__safe-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__address {
      font-size: 0.8rem;
    }
  }
}
</style>
