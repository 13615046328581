<template>
  <RevampBaseModal
    v-model:is-open="isModalShown"
    :custom-z-index="420"
    heading="Sign in to PWN"
    :is-closable="false">
    <template #body>
      <div class="accept-service-terms-modal">
        <p>
          By continuing, you are confirming the following:
        </p>
        <ul>
          <li>
            You are not a resident of USA, Canada, Belarus, Russia, Cuba, Democratic People's Republic of Korea (DPRK), Democratic Republic of Congo, Iran, Iraq, Lebanon, Libya, Mali, Myanmar, Nicaragua, Somalia, South Sudan, Sudan, or Syria.
          </li>

          <li>
            You will not use any software or networking techniques, including use of a Virtual Private Network (VPN) to modify your internet protocol address or otherwise circumvent or attempt to circumvent this prohibition.
          </li>

          <li>
            You accept the <BaseLink
              :to="links.termsAndConditions"
              class="accept-service-terms-modal__link">
              Terms of Service
            </BaseLink> and <BaseLink
              :to="links.privacyPolicy"
              class="accept-service-terms-modal__link">
              Privacy Policy
            </BaseLink>
          </li>
        </ul>

        <BaseButton
          :is-full-width="true"
          button-text="Continue"
          :variant="ButtonVariant.Default"
          @click="acceptTerms"/>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script lang="ts" setup>
import { useServiceTermsModal } from '@/revamp/hooks/useServiceTermsModal'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import links from '@/constants/links'
import BaseLink from '@/general-components/BaseLink.vue'
import BaseButton, { ButtonVariant } from '@/general-components/BaseButton.vue'

const { acceptTerms, isModalShown } = useServiceTermsModal()
</script>

<script setup lang="ts">
</script>

<style lang="postcss" scoped>
  .accept-service-terms-modal {
    display: flex;
    flex-flow: column nowrap;

    margin: auto;

    max-width: 620px;

    @media only screen and (--mobile-viewport) {
      max-width: 100%;
    }

    &__link {
      color: var(--primary-color-1);
    }

    p {
      margin: 0;
    }

    ul {
      margin: 1rem 0 2rem;
      padding-left: 1.5rem;
    }
  }
</style>
