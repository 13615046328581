import { computed, watch } from 'vue'
import { TESTNET_CHAINS, SupportedChain } from '@/constants/chains/types'
import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { createPublicClient, http, isAddress } from 'viem'
import type { Address } from 'viem'
import { normalize } from 'viem/ens'
import { useChainId } from '@wagmi/vue'
import { mainnet } from '@wagmi/core/chains'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { AddressProfile } from './useAddressProfile'

interface EnsCache {
  [address: Address]: AddressProfile;
}

const publicClient = createPublicClient({
  chain: mainnet,
  transport: http(CHAINS_CONSTANTS[SupportedChain.Ethereum].nodeProvider.httpNodeUrl, {
    ...(CHAINS_CONSTANTS[SupportedChain.Ethereum].nodeProvider.bearerAuthToken && {
      fetchOptions: {
        headers: {
          Authorization: `Bearer ${CHAINS_CONSTANTS[SupportedChain.Ethereum].nodeProvider.bearerAuthToken}`,
        },
      },
    }),
  }),
})

export const useEnsStore = defineStore('ens', () => {
  const wagmiChainId = useChainId()

  const chainIdToUse = computed(() => {
    if (TESTNET_CHAINS.includes(wagmiChainId.value)) {
      return SupportedChain.Sepolia
    }

    return SupportedChain.Ethereum
  })

  const ensCacheKey = computed(() => `ens_cache_${chainIdToUse.value}`)

  // TODO shall we implement some TTL to this ens cache?
  let cache = useLocalStorage<EnsCache>(ensCacheKey.value, {})

  watch(wagmiChainId, (chainId, prevChainId) => {
    if (TESTNET_CHAINS.includes(chainId) !== TESTNET_CHAINS.includes(prevChainId)) {
      // TODO is this correct? do we even need this watcher
      cache = useLocalStorage<EnsCache>(ensCacheKey.value, {})
    }
  })

  const getFromCache = (address: Address): AddressProfile | null => {
    return cache.value[address]
  }

  const resolveAddress = async (address: Address): Promise<AddressProfile | undefined> => {
    if (!isAddress(address)) {
      return undefined
    }

    const valueInCache = getFromCache(address)
    if (valueInCache) {
      return valueInCache
    }

    const name = await publicClient.getEnsName({
      address,
    })

    let avatar: string | null = null
    if (name) {
      avatar = await publicClient.getEnsAvatar({
        name: normalize(name),
      })
    }

    const ensName = { address, name, avatar }
    cache.value[address] = ensName

    return ensName
  }

  const resolveEnsName = async (name: string) => {
    // TODO also implement caching here?
    return await publicClient.getEnsAddress({
      name: normalize(name),
    })
  }

  return {
    resolveAddress,
    resolveEnsName,
  }
})
