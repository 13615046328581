import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { AmountInEthAndUsdBackendSchema } from '@/modules/common/backend/generated'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import { formatDecimalPoint } from '@/utils/utils'
import { useCurrencySwitcherStore } from '@/layout/header/useCurrencySwitcherStore'
import { storeToRefs } from 'pinia'
import { useAssetListPrices } from '@/revamp/hooks/thesis/useAssetListPrices'
import { parseAddress } from '@/utils/address-utils'

export const useAssetListPrice = (
  commitedAssets: MaybeRef<AssetWithAmount[]>,
  postCalcMutate?: (v: number) => number,
  isThesis: boolean = true,
) => {
  const store = useCurrencySwitcherStore()
  const { selectedCurrency } = storeToRefs(store)

  const { assetPrices } = useAssetListPrices(commitedAssets)

  const summarizedAmount = computed(() => {
    let result = 0

    const currency = selectedCurrency.value.label
    const displayInEth = currency === 'ETH'

    unref(commitedAssets).forEach((v, i) => {
      const unitPrice: AmountInEthAndUsdBackendSchema | undefined = unref(assetPrices)?.[`${v.chainId}-${parseAddress(v.address)}`]
      const asset = v
      if (!asset) return

      let assetAmount = asset.amountInput === '' && isThesis ? asset.amount : asset.amountInput

      if (asset.isNft) {
        assetAmount = '1'
      }

      if (assetAmount === '-' || isNaN(parseFloat(assetAmount))) {
        return
      }

      const unitPriceInCurrency = displayInEth ? unitPrice?.eth_amount : unitPrice?.usd_amount

      if (!unitPriceInCurrency) return

      result += parseFloat(unitPriceInCurrency) * parseFloat(assetAmount)
    })

    if (postCalcMutate) {
      result = postCalcMutate(result)
    }

    const formattedAmount = displayInEth
      ? `${formatDecimalPoint(result)} ETH`
      : `$${
        parseFloat(result.toFixed(2)).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }`

    return {
      formatted: formattedAmount,
      raw: result,
    }
  })

  return {
    summarizedAmount,
  }
}
