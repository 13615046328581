<template>
  <div class="section-details">
    <BaseAssetPageSection
      icon="details"
      label="Details"
      :is-fetching="isLoadingBasicAssetDetails"
      :has-data="hasBasicAssetDetails">
      <template #content>
        <div
          v-if="assetToDisplay"
          class="section-details__content">
          <div class="section-details__top-row">
            <img
              :src="assetToDisplay.collectionImage"
              class="section-details__collection-image"
              alt="collection"
              height="75">
            <div>
              <SafeText
                :text="assetToDisplay.collectionName"
                :allow-click-through="false"
                :style="{textTransform: 'uppercase'}"
                :tooltip-text="assetToDisplay?.collectionName"/>
              <VueMarkdown
                v-if="assetToDisplay.collection && !assetToDisplay.collection?.isDescriptionMissing"
                class="section-details__collection-description section-details__collection-description--markdown"
                :source="assetToDisplay.collectionDescription"/>
              <div
                v-else-if="assetToDisplay.collection?.isDescriptionMissing"
                class="section-details__collection-description section-details__collection-description--missing">
                {{ assetToDisplay.collectionDescription }}
              </div>
            </div>
          </div>
          <div class="section-details__row">
            <span>Contract Address</span>
            <span class="section-details__accent-text">
              <CryptoAddress
                :chain-id="assetToDisplay.chainId"
                :address="assetToDisplay.address"/>
            </span>
          </div>
          <div class="section-details__row">
            <span>Token ID</span>
            <span class="section-details__accent-text">
              <BaseLink
                class="section-details__token-id-link"
                :is-underlined="false"
                :to="getAssetDetailsExplorerLink(assetToDisplay)"
                target="_blank">
                <LooksrareIconSvg
                  v-if="isChickenBond"
                  alt="LooksRare link"
                  class="section-details__marketplace-icon"/>
                <ExplorerIcon
                  v-else
                  alt="Explorer link"
                  class="section-details__marketplace-icon"/>
                <SafeText
                  style="color: var(--primary-color-1); line-height: 1;"
                  :text="assetToDisplay.tokenId"
                  :allow-click-through="false"
                  :tooltip-text="String(assetToDisplay.tokenId)"/>
              </BaseLink>
            </span>
          </div>
          <div class="section-details__row">
            <span>Token Standard</span>
            <span>{{ assetToDisplay.categoryVerbose }}</span>
          </div>
          <div class="section-details__row">
            <span>Blockchain</span>
            <span>{{ getChainName(assetToDisplay.chainId) }}</span>
          </div>
          <div class="section-details__links">
            <div
              v-for="link in validLinks"
              :key="link.name">
              <BaseLink
                class="section-details__link"
                :to="link.to!"
                target="_blank"
                :title="`Collection on ${link.name}`">
                <component
                  :is="{...link.icon}"
                  width="20"
                  class="section-details__link-icon"
                  :alt="`${link.name} icon`"/>
              </BaseLink>
            </div>
          </div>
        </div>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import VueMarkdown from 'vue-markdown-render'
import SafeText from '@/general-components/SafeText.vue'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import { getAssetDetailsExplorerLink, getChainName } from '@/utils/chain'
import WebsiteIconSvg from '@/assets/icons/external-website.svg'
import TwitterIconSvg from '@/assets/icons/twitter.svg'
import DiscordIconSvg from '@/assets/icons/discord.svg'
import InstagramIconSvg from '@/assets/icons/instagram.svg'
import type { Nullable } from '@/modules/common/typings/customTypes'
import { computed, ref } from 'vue'
import type { Component } from 'vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { compareAddresses } from '@/utils/utils'
import LooksrareIconSvg from '@/assets/icons/looksrare.svg'

const { asset, isLoadingBasicAssetDetails } = useAssetPage()

const hasBasicAssetDetails = computed(() => !!asset.value)

// @ts-expect-error FIXME: strictNullChecks
const assetToDisplay = computed(() => asset.value.isBundleWithSingleAsset ? asset.value.bundledAsset : asset.value)

interface ExternalLinkData {
  name: string
  icon: Component
  to?: Nullable<string>
}

const links = ref<ExternalLinkData[]>([{
  name: 'website',
  to: asset.value?.websiteUrl,
  icon: WebsiteIconSvg,
}, {
  name: 'Twitter',
  to: asset.value?.twitterUrl, // todo check if the twitterUrl property contains full url links
  icon: TwitterIconSvg,
}, {
  name: 'Discord',
  to: asset.value?.discordUrl, // todo check if the discordUrl property contains full url links
  icon: DiscordIconSvg,
}, {
  name: 'Instagram',
  to: asset.value?.instagramUrl, // todo check if the instagramUrl property contains full url links
  icon: InstagramIconSvg,
}])
const validLinks = computed(() => links.value.filter(link => link.to))
// @ts-expect-error FIXME: strictNullChecks
const isChickenBond = computed(() => compareAddresses(asset.value.address, CHAINS_CONSTANTS[asset.value.chainId].nftContractsAddresses?.chickenBonds))
// @ts-expect-error FIXME: strictNullChecks
const ExplorerIcon = computed(() => CHAINS_CONSTANTS[asset.value.chainId].explorer.nftExplorerIcon)
</script>

<style scoped>
.section-details {
  &__content {
    padding: 1.1rem 1.5rem;
  }

  &__collection-description {
    margin: 0.7rem 0 2rem;
    line-height: 1.5rem;
    word-break: break-word;

    &--markdown {
      ::v-deep(&) {
        /* stylelint-disable-next-line max-nesting-depth */
        a {
          text-decoration: underline;
          color: var(--primary-color-1);
          transition: all var(--primary-transition-time);
          cursor: pointer;

          /* stylelint-disable-next-line max-nesting-depth */
          &:hover {
            filter: brightness(60%);
          }
        }
      }
    }

    &--missing {
      color: var(--negative-1);
    }
  }

  &__collection-image {
    float: left;
    margin: 0 0.5rem 0.4rem 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 1.2rem;
  }

  &__marketplace-icon {
    width: 1.5rem;
  }

  &__accent-text {
    max-width: 50%;
    color: var(--primary-color-1);
  }

  &__token-id-link {
    display: flex;
    font-family: var(--font-family-oxygen-mono);
    text-align: end;
  }

  &__links {
    display: flex;
    margin-top: 1.5625rem;
  }

  &__top-row {
    min-height: 5rem;
  }

  &__link {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color-2);
    height: 2.1875rem;
    width: 2.875rem;
  }
}

</style>
