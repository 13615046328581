<template>
  <div
    ref="searchContainer"
    class="search-bar">
    <BaseSearch
      :is-on-home-page="variant === 'homepage'"
      :model-value="searchTerm || ''"
      :set-focus="setFocus"
      has-clear-button
      :auto-focus="autoFocus"
      :class="['search-bar__search', { 'search-bar__search--selected': searchTerm || isFocused }, {'search-bar__search--on-homepage': props.variant === 'homepage'}]"
      :search-placeholder="searchPlaceholder"
      @update:model-value="handleUpdateSearch">
      <template #actions="{ clearSearch }">
        <div class="search-bar__actions">
          <img
            src="@/assets/icons/close-gray.svg?url"
            alt="Search icon"
            :class="['search-bar__clear', {
              'search-bar__clear--visible': searchTerm
            }]"
            @click="() => handleClearSearch(clearSearch)">
        </div>
      </template>
    </BaseSearch>

    <SearchBarResults
      v-if="resultsAreVisible && showResults"
      :variant="variant"
      :search-query="searchTerm"
      :style="{ top: variant === 'homepage' ? '4rem' : '3rem' }"
      :handle-close="handleClose"
      :is-fetched="isFetched"
      :loading-state="loadingState"
      :results="results"
      :is-empty="isEmptySearch"/>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import SearchBarResults from '@/modules/sections/nav-searchbar/SearchBarResults.vue'
import { onClickOutside } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import {
  useSearchPwnExplorer,
  useSearchStore,
  useSearchStoreHome,
} from '@/modules/common/pwn/explorer/useSearchStore'

interface Props {
  variant?: 'homepage' | 'default' | 'explorer' | 'dashboard'
  autoFocus?: boolean
  placeholder?: string
  showResults?: boolean
  defaultValue?: string
}
const props = withDefaults(defineProps<Props>(), {
  showResults: true,
  variant: 'default',
})
function getStoreByVariant(variant: Props['variant']) {
  switch (variant) {
  case 'homepage':
    return useSearchStoreHome()
  case 'dashboard':
    return useSearchStore()
  case 'explorer':
    return useSearchPwnExplorer()
  default:
    return useSearchStore()
  }
}
const store = getStoreByVariant(props.variant)
const { searchTerm, results, isFetched, loadingState } = storeToRefs(store)
const searchPlaceholder = computed(() => props.placeholder || 'Search assets / collections')
const isFocused = ref(false)
const searchContainer = ref<HTMLElement | null>(null)
const forceClose = ref(false)
const setFocus = (v: boolean) => {
  forceClose.value = false
  isFocused.value = v
}

const handleClose = () => {
  forceClose.value = true
  isFocused.value = false
}

const emit = defineEmits<{(e: 'on-search-update', searchTerm: string)}>()

const handleUpdateSearch = async (searchTerm: string) => {
  await store.actions.setSearchTerm(searchTerm)
  emit('on-search-update', searchTerm)
}

onClickOutside(searchContainer, () => {
  handleClose()
})

const isEmptySearch = computed(() => searchTerm.value?.length === 0)

function handleClearSearch(clearFn) {
  // since BaseSearch re-declares state we must reset if we want to clear the search
  clearFn()
  store.actions.clearSearchTerm()
}

const resultsAreVisible = computed(() => {
  return forceClose.value ? false : isFocused.value || !isEmptySearch.value
})

</script>

<style scoped>
.search-bar {
  flex: 1;
  position: relative;
  z-index: var(--z-index-search-bar);

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }

  &__clear {
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &--visible {
      opacity: 1;
      display: block;
    }
  }

  &__search {
    border: 1px solid var(--gray);
    padding: 0.25rem;
    font-family: var(--font-family-screener);
    align-items: center;

    &--selected {
      border: 1px solid var(--text-color);
    }

    &--on-homepage {
      height: 3.5rem;
      font-size: 1.125rem;
    }
  }
}
</style>
