<template>
  <div
    v-if="toast"
    :class="['base-toast__container', {'base-toast__container--fading-out': startFadeOut}]"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false">
    <div class="base-toast__header">
      <div class="base-toast__title">
        <div
          v-if="toast.firstAsset?.image || toast.secondAsset?.image || toast.customImage"
          class="base-toast__image-wrapper">
          <component
            :is="toast.customImage"
            v-if="toast.customImage"
            class="base-toast__first-image"/>
          <TokenMedia
            v-if="toast.firstAsset?.image"
            :token="toast.firstAsset"
            height="40"
            width="40"
            class="base-toast__first-image"/>
          <TokenMedia
            v-if="toast?.secondAsset?.image"
            :token="toast.secondAsset"
            height="40"
            width="40"
            class="base-toast__second-image"/>
          <ChainIcon
            v-if="!toast.secondAsset?.image"
            class="base-toast__second-image"
            height="35"
            width="35"/>
        </div>
        <div>
          {{ toast.title }}
        </div>
      </div>
      <div
        v-if="isCloseable"
        class="base-toast__close-button"
        @click="handleCloseToast">
        <CloseIcon
          width="20"
          height="20"/>
      </div>
    </div>
    <div class="base-toast__body">
      <div
        v-for="(step, index) in toast.steps"
        :key="step.text">
        <BaseToastStep
          :toast="toast"
          :step="step"/>
        <SeparatorIcon
          v-if="index !== toast.steps.length - 1"
          class="base-toast__separator"/>
      </div>
      <div
        v-if="isRunningLong"
        class="base-toast__timeout">
        The action you're trying to perform is taking longer than expected. You can safely close this message.
      </div>
    </div>
    <div class="base-toast__custom-bottom-component">
      <component
        :is="toastsStore.customBottomComponent"
        v-if="toastsStore.customBottomComponent"/>
    </div>
    <div class="base-toast__footer">
      <div class="base-toast__created-time">
        {{ toast.created }}
      </div>
      <ChainInfo
        v-if="toast.chainId"
        class="base-toast__chain-info"
        :chain-id="toast.chainId"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watchEffect } from 'vue'
import ChainInfo from '@/general-components/ChainInfo.vue'
import BaseToastStep from '@/general-components/notification/BaseToastStep.vue'
import CloseIcon from '@/assets/icons/close.svg'
import TokenMedia from '@/general-components/TokenMedia.vue'
import SeparatorIcon from '@/assets/icons/line-separator.svg'
import { getChainIcon } from '@/utils/chain'
import { useToastsStore, ToastStepStatus } from '@/modules/common/notifications/useToastsStore'
import type { ToastActionId } from '@/modules/common/notifications/useToastsStore'
import type { IntervalId } from '@/modules/common/typings/customTypes'

interface Props {
  toastId: ToastActionId
}
const props = defineProps<Props>()
const { toastId } = toRefs(props)

// TODO check if this really works
const emit = defineEmits<{(e: 'close-toast'): void}>()

const toastsStore = useToastsStore()
const toast = computed(() => toastsStore.displayedToasts.find(toast => toast.id === toastId.value))

const startFadeOutInterval = ref<IntervalId>()
const dismissInterval = ref<IntervalId>()

const isHovering = ref(false)

const startFadeOut = ref()

const handleCloseToast = () => {
  emit('close-toast')
  toastsStore.customBottomComponent = undefined
}

watchEffect(() => {
  if (isHovering.value) {
    startFadeOut.value = false
    clearInterval(startFadeOutInterval.value)
    startFadeOutInterval.value = undefined
    clearInterval(dismissInterval.value)
    dismissInterval.value = undefined
    return
  }

  if (toast.value?.steps.every(step => step.status === ToastStepStatus.SUCCESS) && startFadeOutInterval.value === undefined) {
    startFadeOutInterval.value = setInterval(() => {
      startFadeOut.value = true
      dismissInterval.value = setInterval(() => {
        handleCloseToast()
      }, 1400)
    }, 5200)
  }
})

// @ts-expect-error FIXME: strictNullChecks
const isError = computed(() => toast.value.steps.some(step => step.status === ToastStepStatus.ERROR))
// @ts-expect-error FIXME: strictNullChecks
const isAllStepsCompleted = computed(() => toast.value.steps.every(step => step.status === ToastStepStatus.SUCCESS))

const isRunningLong = computed(() => {
  // @ts-expect-error FIXME: strictNullChecks
  const pendingStep = toast.value.steps.find(step => step.status === ToastStepStatus.PENDING)
  return pendingStep?.isRunningLong
})

// TODO how to handle closable? do we need to set the toast._toastOptions?
const isCloseable = computed(() => isError.value || isRunningLong.value || isAllStepsCompleted.value)
// @ts-expect-error FIXME: strictNullChecks
const ChainIcon = computed(() => getChainIcon(toast.value.chainId))
</script>

<style>
/* reset component wrap styles */
.Vue-Toastification__container.bottom-right {
  bottom: 50px !important;
  right: 50px !important;

  @media only screen and (--mobile-viewport) {
    bottom: 80px !important;
    right: 0 !important;
    margin: auto;
  }
}

.Vue-Toastification__container {
  padding: 0 !important;
  width: 457px !important;

  @media only screen and (--mobile-viewport) {
    width: var(--mobile-app-min-width) !important;
    bottom: 0 !important;
    right: 0 !important;
  }
}

.Vue-Toastification__toast {
  max-width: 100% !important;
  min-height: 126px !important;
  margin: 60px 0 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-color: white;
  overflow: visible !important;

  &:not(:first-child) {
    /* to prevent double borders with multiple notifications stacking on top of each other */
    box-shadow: 0 2px var(--background-color);
  }

  @media only screen and (--mobile-viewport) {
    margin-top: 1rem !important;
  }
}

.Vue-Toastification__toast-component-body {
  width: 100%;
  border: 1px solid #434343;
}

.Vue-Toastification__close-button {
  position: absolute;
  right: 14px;
  top: 14px;
  opacity: 1 !important;
  box-sizing: content-box;

  &:hover {
    opacity: 0.3 !important;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-enter-active {
  animation-name: fade-in;
  animation-duration: 750ms;
  animation-fill-mode: both;
}

.fade-leave-active {
  animation-name: fade-out;
  animation-duration: 750ms;
  animation-fill-mode: both;
}

.fade-move {
  transition-timing-function: ease-in-out;
  transition-property: all;
  transition-duration: 400ms;
}

</style>

<style scoped>
.base-toast {
  &__container {
    transition: opacity 1.4s;

    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0.5rem;
    background: var(--background-color);
    font-family: var(--font-family-oxygen-mono);
    opacity: 1;

    &--fading-out {
      opacity: 0;
    }
  }

  &__separator {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
  }

  &__header {
    height: 4rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    align-items: center;
    border-bottom: 1px solid #434343;
  }

  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__second-image {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__image-wrapper {
    position: relative;
    margin-right: 0.5rem;
  }

  &__body {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
  }

  &__timeout {
    font-size: 0.75rem;
    width: 75%;
    color: var(--warning);
    font-family: var(--font-family-oxygen-mono);
  }

  &__created-time {
    font-size: 0.625rem;
  }

  &__chain-info {
    font-size: 0.75rem;
  }

  &__close-button {
    cursor: pointer;
    margin-right: 0.5rem;
  }

  &__custom-bottom-component {
    margin-bottom: 0.5rem;
  }
}
</style>
