<template>
  <div
    ref="tableContainerRef"
    class="tokens-table"
    @scroll="fetchMoreOnBottomReached">
    <table
      class="tokens-table__table">
      <thead class="tokens-table__head">
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
          class="tokens-table__head-row">
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            class="tokens-table__head-cell"
            :class="[`tokens-table__head-cell--${header.id}`]"
            :style="{
              width: `${header.column.getSize()}px`,
            }"
            :colSpan="header.colSpan">
            <FlexRender
              v-if="!header.isPlaceholder"
              :render="header.column.columnDef.header"
              :props="header.getContext()"/>
          </th>
        </tr>
        <tr>
          <BaseSkeletor
            v-if="isLoading || !isFetched"
            class="tokens-table__loader"/>
        </tr>
      </thead>
      <tbody
        class="tokens-table__body"
        :class="[
          {
            'tokens-table__body--empty': props.q || props.isThirdParty,
          }
        ]"
        style="display: grid; height: 100%; position: relative;"
        :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
        <TransitionGroup name="tokens-table--fade">
          <tr
            v-for="row in virtualRows"
            :key="rows[row.index]?.id"
            :ref="customRefHandler"
            class="tokens-table__row"
            :class="[isDivider(rows[row.index].original) ? 'tokens-table__divider' : '']"
            :data-index="row.index"
            :style="{
              display: 'flex',
              position: 'absolute',
              transform: `translateY(${row.start}px)`,
              width: '100%',
              justifyContent: 'space-between',
            }"
            @click="!isDivider(rows[row.index].original) && emit('select-asset', rows[row.index].original!)">
            <td
              v-if="isDivider(rows[row.index].original)"
              class="tokens-table__divider-content">
              <span class="tokens-table__divider-text"> Others </span>
            </td>
            <td
              v-else-if="rows[row.index].original?.symbol === '$PWN-ALL-YOUR-STABLES'"
              colspan="3"
              class="tokens-table__all-stables-row">
              <TokenMedia
                v-for="(asset, index) in props.stables"
                :key="asset.id"
                :token="asset"
                :style="{
                  marginLeft: index > 0 ? '-15px' : '0',
                  zIndex: index,
                }"
                height="20"
                width="20"/>
              <span class="tokens-table__all-stables-row-text">Use All Your Stables</span>

              <div class="tokens-table__all-stables-row-amount">
                <AssetsListAppraisal :assets="existingUserStables"/>
              </div>
            </td>
            <td
              v-for="cell in rows[row.index]?.getVisibleCells()"
              v-else
              :key="cell.id"
              :style="{
                width: `${cell.column.getSize()}px`,
              }">
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"/>
            </td>
          </tr>
        </TransitionGroup>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import {
  getFetchAssetMetadataQueryKey,
  useAssetTokensInTokenListListInfinite,
  useAssetTopTokensListInfinite,
} from '@/modules/common/backend/generated'
import type { AssetMetadataBackendSchema } from '@/modules/common/backend/generated'
import { computed, h, onMounted, ref, toRefs } from 'vue'
import { FlexRender, getCoreRowModel, useVueTable } from '@tanstack/vue-table'
import { useVirtualizer } from '@tanstack/vue-virtual'
import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import type { SupportedChain } from '@/constants/chains/types'
import AssetInfoCell from '@/revamp/components/tables/cells/assets/AssetInfoCell.vue'
import { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import AssetPrice from '@/revamp/components/prices/AssetPrice.vue'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import { storeToRefs } from 'pinia'
import AssetAmountWithAppraisal from '@/revamp/components/tables/cells/assets/AssetAmountWithAppraisal.vue'
import { useDivider } from '@/revamp/components/modals/utils/use-divider'
import { filterAssetsInSearchResults } from '@/utils/search'
import { compareAddresses, getPlatformIcon } from '@/utils/utils'
import { isAddress } from 'viem'
import type { Address } from 'viem'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import to from '@/utils/await-to-js'
import { isPwnSpace, isStarknet, pwnSpaceConfig } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import AssetType from '@/modules/common/assets/AssetType'
import { isMobile } from '@/utils/mediaQueries'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import AssetsListAppraisal from '@/revamp/components/tables/cells/AssetsListAppraisal.vue'
import type { AssetPriceLog } from '@/revamp/modules/asset-prices/types'
import { useAssetsWithSmallBalancesStore } from '@/revamp/hooks/useAssetsWithSmallBalancesStore'

type Props = {
  chainId: SupportedChain;
  q?: string;
  allExistingTokens?: AssetWithAmount[]
  existingTokens?: AssetWithAmount[]
  isThirdParty?: boolean
  isCollateralSelection?: boolean
  onlyExisting?: boolean
  isLoading?: boolean
  withAllStablesOption?: boolean
  stables?: AssetWithAmount[]
  includesNativeToken?: boolean
};

const props = defineProps<Props>()
const { chainId } = toRefs(props)

const tableContainerRef = ref(null)
const fetchSize = 15

const reqParams = computed(() => {
  return {
    limit: fetchSize,
    q: props.q,
  }
})

const existingUserStables = computed(() => {
  return props.allExistingTokens?.filter(token => props.stables?.some(stable => compareAddresses(stable.address, token.address))) || []
})

const { fetchErc20Metadata } = useMetadataFetch()
const localTopTokensStore = useTopTokensStore()
const smallBalancesStore = useAssetsWithSmallBalancesStore()
const { topTokens: localTopTokens } = storeToRefs(localTopTokensStore)
const emit = defineEmits<{(e: 'select-asset', asset: AssetMetadata) }>()
const topTokensByChain = computed(() => {
  return props.chainId ? localTopTokens.value[props.chainId] : []
})

const topTokensQuery = useAssetTopTokensListInfinite(reqParams, {
  query: {
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      return allPages.length * 100
    },
    placeholderData: keepPreviousData,
  },
})

const reactiveChainId = computed(() => {
  return String(props.chainId)
})

const tokensInTokenListQuery = useAssetTokensInTokenListListInfinite(reactiveChainId, reqParams, {
  query: {
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      return allPages.length * fetchSize
    },
    placeholderData: keepPreviousData,
  },
})

const totalCount = computed(() => tokensInTokenListQuery.data.value?.pages[0]?.data?.count || 100)

const topTokensFlatData = computed(() => {
  return topTokensQuery.data?.value?.pages
    ? topTokensQuery.data.value.pages?.flatMap(
      (page) =>
        page.data.results?.map((v) => {
          return AssetMetadata.createFromBackendModel(v)
        }) ?? [],
    )
    : []
})

const tokensInTokenListFlatData = computed(() => {
  return tokensInTokenListQuery.data?.value?.pages
    ? tokensInTokenListQuery.data.value.pages?.flatMap(
      (page) =>
        page.data.results?.map((v) => {
          return AssetMetadata.createFromTokenInTokenListBackendSchema(v as unknown as AssetMetadataBackendSchema)
        }) ?? [],
    )
    : []
})

const { isDivider, getDivider } = useDivider()

const searchTokenAddress = computed(() => {
  if (props.q && isAddress(props.q)) {
    return props.q
  }
  return ''
})

const assetMetadataQuery = useQuery({
  // @ts-expect-error It's just dumb, we need to unify the chainId type in the api components
  queryKey: getFetchAssetMetadataQueryKey(searchTokenAddress, chainId, ''),
  queryFn: async (context) => {
    const [,,,, address, chainId] = context.queryKey
    const [err, res] = await to(fetchErc20Metadata({
      contractAddress: address as Address,
      chainId: Number(chainId),
      refresh: false,
    }))

    if (!err) {
      return res
    }
    throw new Error(`Failed to fetch metadata, ${err}`)
  },
})

const mergedQueriesData = computed<AssetWithAmount[]>(() => {
  const topTokens =
    topTokensFlatData.value.filter((asset) => (props.chainId ? asset?.chainId === props.chainId : true))?.slice(0, 4) ||
    []
  const tokensInTokenList = tokensInTokenListFlatData.value || []

  const others = props.isCollateralSelection
    ? [
      ...topTokens,
      ...(props.existingTokens || []),
      ...tokensInTokenList.flat(),
      ...topTokensByChain.value,
    ]
    : [
      ...topTokens,
      ...tokensInTokenList.flat(),
      ...(props.existingTokens || []),
    ]

  const divider = getDivider(props.chainId)
  const promoted = [...topTokensByChain.value, divider]

  if (isPwnSpace && !assetMetadataQuery.data.value && !isStarknet) {
    const allowedAssets: AssetWithAmount[] = []
    for (const asset of pwnSpaceConfig?.allowedContracts.filter(v => v.category === AssetType.ERC20) || []) {
      allowedAssets.push(
        new AssetWithAmount({
          name: asset.name,
          address: asset.address,
          chainId: asset.chainId,
          category: asset.category,
          symbol: asset.name,
          image: asset.image,
          id: Math.random() * -100,
        }),
      )
    }

    return [...topTokensByChain.value, ...allowedAssets] as AssetWithAmount[]
  }

  const result = props.isCollateralSelection ? [...others, ...topTokensByChain.value] : [...promoted, ...others]

  if (assetMetadataQuery.data.value) {
    result.push(assetMetadataQuery.data.value)
  }
  // removing duplicates
  const filteredResults = result.flat().filter((v, i, a) => a.findIndex((t) => compareAddresses(t?.address, v?.address)) === i).filter(Boolean) as AssetWithAmount[]
  if (props.onlyExisting) {
    return filteredResults.filter((v) => props.existingTokens?.find((t) => compareAddresses(t.address, v.address)))
  }
  return filteredResults
})

const mappedUserAssets = computed(() => {
  const assets = new Map<string, AssetWithAmount>()

  for (const token of props.existingTokens || []) {
    if (!token) continue
    assets.set(token.address, token)
  }

  return assets
})

const allStablesOption = computed(() => {
  return new AssetWithAmount({
    name: 'Multi-Asset Credit (stablecoins)',
    address: '0x0',
    chainId: props.chainId,
    category: AssetType.ERC20,
    symbol: '$PWN-ALL-YOUR-STABLES',
    amount: '1', // dirty hack to make this asset selectable
    image: 'https://placehold.it/100x100',
    id: Math.random() * -100,
  })
})

const tableEntities = computed(() => {
  const response = mergedQueriesData.value
  let assets = response
  if (props.existingTokens?.length && props.isThirdParty) {
    return props.existingTokens
  }
  if (props.existingTokens?.length) {
    assets = response.map(asset => {
      if (isDivider(asset) || !asset) return asset
      return mappedUserAssets.value.get(asset.address) || asset
    }).filter(Boolean)

    const userAssets = Array.from(mappedUserAssets.value.values())
    const missingUserAssets = userAssets.filter(userAsset => !assets.some(asset => compareAddresses(asset.address, userAsset.address)),
    )

    assets.push(...missingUserAssets)
  }

  if (props.withAllStablesOption) {
    const divider = getDivider(props.chainId) as AssetWithAmount
    assets = [allStablesOption.value, divider, ...assets]
  }

  const assetsToReturn = props.q?.length ? filterAssetsInSearchResults(assets, props.q) : assets
  if (!props.includesNativeToken) {
    return assetsToReturn.filter((v) => !v?.isNativeToken)
  }
  return assetsToReturn
})

const tableColumns = computed(() => {
  const baseColumns = [
    {
      id: 'name',
      header: 'Token Name',
      accessorFn: (row) => row?.name,
      cell: (ctx) => {
        if (!ctx.row.original) return null
        return h(AssetInfoCell, {
          asset: ctx.row.original,
        })
      },
      size: isMobile.value ? 140 : 450,
    },
  ]
  if (props.isThirdParty) {
    baseColumns.push(
      {
        id: 'platform',
        header: 'Platform',
        accessorFn: (row) => row?.id,
        cell: (ctx) => {
          const Icon = getPlatformIcon(ctx.row.original)
          return h(Icon)
        },
        size: 100,
      },
    )
  }

  baseColumns.push({
    id: 'price',
    header: 'Price',
    accessorFn: (row) => row?.id,
    cell: (ctx) => {
      return h(AssetPrice, {
        contractAddress: ctx.row.original?.address || '0x',
        tokenId: String(ctx.row.original?.tokenId || null),
        chainId: String(props.chainId),
      })
    },
    size: 130,
  })
  if (props.existingTokens) {
    return [
      ...baseColumns,
      {
        id: 'owned',
        header: 'You Own',
        accessorFn: (row) => row?.id,
        cell: (ctx) => {
          return h(AssetAmountWithAppraisal, {
            asset: ctx.row.original,
            amount: ctx.row.original.amount,
            onAssetAppraisalUpdated: (log: AssetPriceLog) => {
              if (props.existingTokens?.some(token => token.id === ctx.row.original.id)) {
                smallBalancesStore.addAsset(ctx.row.original, log)
              }
            },
          })
        },
        size: 100,
      },
    ]
  }

  return [...baseColumns]
})

const table = useVueTable({
  get data() {
    return tableEntities.value
  },
  columns: tableColumns.value,
  getCoreRowModel: getCoreRowModel(),
  getRowId: (row) => String(row?.id),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return tableEntities.value?.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 42,
    paddingStart: props.q || props.isThirdParty ? 0 : 42,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const fetchMoreOnBottomReached = () => {
  const container = tableContainerRef.value
  if (container) {
    const { scrollHeight, scrollTop, clientHeight } = container
    if (
      scrollHeight - scrollTop - clientHeight < 500 &&
      !tokensInTokenListQuery.isFetching.value &&
      tokensInTokenListQuery.hasNextPage.value &&
      rows.value.length < totalCount.value
    ) {
      tokensInTokenListQuery.fetchNextPage()
    }
  }
}

onMounted(() => {
  fetchMoreOnBottomReached()
})

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

const isFetched = computed(() => tokensInTokenListQuery.isFetched && topTokensQuery.isFetched)
</script>

<style scoped>
.tokens-table {
  overflow: auto;
  position: relative;
  height: 400px;

  padding-right: 1rem;

  &__all-stables-row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__all-stables-row-text {
    margin-left: 0.5rem;
  }

  &__all-stables-row-amount {
    margin-left: auto;
  }

  &__table {
    display: grid;
  }

  &__loader {
    position: absolute;
    top: 29px;
    right: 0;
    width: 100%;
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 5;

    background: var(--gray-3);
    padding: 0 0.5rem;

    display: flex;
    flex-flow: column nowrap;
  }

  &__head-row {
    width: 100%;
    padding: 0.25rem;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }

  &__head-cell {
    text-align: left;
    font-family: var(--font-family-screener);
    font-weight: 400;

    &--price {
      text-align: right;
    }

    &:last-child {
      text-align: right;
    }
  }

  &__row {
    width: 100%;
    justify-content: space-between;
    display: inline-flex;

    padding: 0.5rem 1rem;
    height: 42px;

    will-change: background-color;
    transition: background-color 0.3s linear;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-3);
    }
  }

  &__body {
    position: relative;

    &::before {
      content: "Featured";
      position: absolute;
      top: 0.875rem;
      left: 1rem;
    }

    &--empty {
      &::before {
        display: none;
      }
    }
  }

  &__divider {
    padding: 0;
    margin-top: 0.5rem;
    width: 100%;
    border-top: 1px solid var(--gray-2);
    height: 42px;
    display: flex;

    &:last-child {
      display: none !important;
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  &__divider-content {
    display: flex;
    width: 100%;
  }

  &__divider-text {
    color: var(--text-color);
    padding-left: 1rem;
    padding-top: 0.5rem;
    font-size: 1rem;
  }
}

.tokens-table--fade,
.tokens-table--fade-move {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave-active {
    position: absolute;
  }
}

</style>

<style>
.tokens-table__table {
  .asset-info-cell__symbol {
    max-width: 100% !important;
  }

  .safe-display-decimals {
    margin-left: auto;
  }
}
</style>
