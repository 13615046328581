import type { ColumnProperties } from '@/general-components/ColumnProperties'
import { SortOption as AssetSortOption } from '@/modules/sections/your-assets/your-nfts/YourNFTsDefinitions'
import {
  sortAssetsByAmount,
  sortAssetsByName,
  sortAssetsByVerification,
  sortAtrTokensById,
} from '@/general-components/sorting/SortFunctions'

export enum AtrSortOption {
  Name = 'name',
  HoldingRights = 'holding-rights'
}

export enum TransferSortOption {
  Name = 'name',
  Verification = 'verification',
  Amount = 'amount'
}

export enum TokenizeAssetSortOption {
  Name = 'name',
  Amount = 'amount'
}

export const ATR_SORT_OPTIONS_LOOKUP = {
  [AtrSortOption.Name]: sortAtrTokensById,
  [AtrSortOption.HoldingRights]: sortAssetsByName,
}

export const TRANSFER_ASSET_SORT_OPTIONS_LOOKUP = {
  [TransferSortOption.Name]: sortAssetsByName,
  [TransferSortOption.Verification]: sortAssetsByVerification,
  [TransferSortOption.Amount]: sortAssetsByAmount,
}

export const TOKENIZE_ASSET_SORT_OPTIONS_LOOKUP = {
  [TokenizeAssetSortOption.Name]: sortAssetsByName,
  [TokenizeAssetSortOption.Amount]: sortAssetsByAmount,
}

export const ASSET_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Asset (a-z)',
    width: '35%',
    slotName: 'asset-info',
    sortOptionName: AssetSortOption.Name,
  },
  {
    title: 'Amount',
    width: '25%',
    slotName: 'amount',
    sortOptionName: AssetSortOption.Amount,
  },
  {
    title: 'Action',
    width: '40%',
    slotName: 'action-asset',
  },
]

export const ATR_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Asset (a-z)',
    width: '30%',
    slotName: 'asset-info',
    sortOptionName: AtrSortOption.Name,
  },
  {
    title: 'Holding Rights (a-z)',
    width: '40%',
    slotName: 'holding-rights',
    sortOptionName: AtrSortOption.HoldingRights,
  },
  {
    title: 'Action',
    width: '30%',
    slotName: 'action-atr',
  },
]

export const TRANSFER_OR_TOKENIZE_ASSET_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '10%',
    slotName: 'checkbox',
  },
  {
    title: 'Asset (a-z)',
    width: '65%',
    slotName: 'asset-info',
    sortOptionName: AssetSortOption.Name,
  },
  {
    title: 'Amount',
    width: '25%',
    slotName: 'amount',
    sortOptionName: AssetSortOption.Amount,
  },
]

export const TRANSFER_ATR_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '8%',
    slotName: 'checkbox',
  },
  {
    title: 'Asset (a-z)',
    width: '45%',
    slotName: 'asset-info',
    sortOptionName: AtrSortOption.Name,
  },
  {
    title: 'Holding Rights (a-z)',
    width: '47%',
    slotName: 'holding-rights',
    sortOptionName: AtrSortOption.HoldingRights,
  },
]

export const TRANSFER_SELECTION_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '5%',
    slotName: 'checkbox',
  },
  {
    title: 'Asset (a-z)',
    width: '25%',
    slotName: 'asset-info',
    sortOptionName: AssetSortOption.Name,
  },
  {
    title: 'Holding Rights',
    width: '25%',
    slotName: 'holding-rights',
    sortOptionName: AtrSortOption.HoldingRights,
  },
  {
    title: 'Amount',
    width: '20%',
    slotName: 'amount',
    sortOptionName: AssetSortOption.Amount,
  },
  {
    title: 'Explorer',
    width: '15%',
    slotName: 'explorer',
  },
  {
    title: 'Action',
    width: '10%',
    slotName: 'remove',
  },
]

export const TOKENIZE_SELECTION_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '5%',
    slotName: 'checkbox',
  },
  {
    title: 'Asset (a-z)',
    width: '43%',
    slotName: 'asset-info',
    sortOptionName: AssetSortOption.Name,
  },
  {
    title: 'Amount',
    width: '23%',
    slotName: 'amount',
    sortOptionName: AssetSortOption.Amount,
  },
  {
    title: 'Explorer',
    width: '17%',
    slotName: 'explorer',
  },
  {
    title: 'Action',
    width: '12%',
    slotName: 'remove',
  },
]

export const CLAIM_AND_BURN_ASSET_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '10%',
    slotName: 'checkbox',
  },
  {
    title: 'Asset (a-z)',
    width: '45%',
    slotName: 'asset-info',
    sortOptionName: AtrSortOption.Name,
  },
  {
    title: 'Holding Rights (a-z)',
    width: '45%',
    slotName: 'holding-rights',
    sortOptionName: AtrSortOption.HoldingRights,
  },
]

export const CLAIM_AND_BURN_SELECTION_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '10%',
    slotName: 'checkbox',
  },
  {
    title: 'Asset (a-z)',
    width: '70%',
    slotName: 'asset-info',
    sortOptionName: AtrSortOption.Name,
  },
  {
    title: 'Action',
    width: '20%',
    slotName: 'remove',
  },
]
