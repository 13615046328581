<template>
  <div class="create-new-pwn-safe-modal-missing-safe-step__container">
    <p>
      We have detected that you have not deployed a PWN Safe yet, let’s do that first.
    </p>
    <div class="create-new-pwn-safe-modal-missing-safe-step__icons-container">
      <div>
        <SafeIconSvg
          width="185"
          height="185"
          alt="PWN Safe"/>
      </div>
      <div class="create-new-pwn-safe-modal-missing-safe-step__think-questionmark-icon-container">
        <ThinkIconSvg
          width="115"
          height="115"
          alt="think emoji"/>
        <QuestionMarkInCircleIconSvg
          width="50"
          height="50"
          class="create-new-pwn-safe-modal-missing-safe-step__questionmark-icon"
          alt="question mark"/>
      </div>
    </div>
    <div class="create-new-pwn-safe-modal-missing-safe-step__buttons-container">
      <BaseButton
        button-text="+ Create New Safe"
        @on-button-click="handleCreateNewSafe"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/general-components/BaseButton.vue'
import useCreateNewPwnSafeModal, { CreateNewPwnSafeModalStep } from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/useCreateNewPwnSafeModal'
import QuestionMarkInCircleIconSvg from '@/assets/icons/questionmark-in-circle.svg'
import SafeIconSvg from '@/assets/icons/safe.svg'
import ThinkIconSvg from '@/assets/icons/think.svg'

const { activeStep } = useCreateNewPwnSafeModal()
const handleCreateNewSafe = () => {
  activeStep.value = CreateNewPwnSafeModalStep.Input
}
</script>

  <style scoped>
  p {
    margin: 0;
  }

  .create-new-pwn-safe-modal-missing-safe-step {
    &__container {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      margin-top: 2rem;
    }

    &__icons-container {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 2rem;
    }

    &__think-questionmark-icon-container {
      position: relative;
    }

    &__questionmark_icon {
      position: relative;
      bottom: 5.6875rem;
      right: 1.3125rem;
    }

    &__buttons-container {
      display: flex;
      justify-content: end;
    }
  }
  </style>
