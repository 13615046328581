/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

export type ProposalAndLoanListStatusesItem = typeof ProposalAndLoanListStatusesItem[keyof typeof ProposalAndLoanListStatusesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProposalAndLoanListStatusesItem = {
  NUMBER_MINUS_1: -1,
  NUMBER_MINUS_2: -2,
  NUMBER_MINUS_3: -3,
  NUMBER_MINUS_4: -4,
  NUMBER_MINUS_5: -5,
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;
