<template>
  <button
    v-if="isTenderly"
    class="testing-helpers"
    type="button"
    @click="handleFillEtherMutateAsync">
    {{ drippingEth ? "Dripping" : "Drip 100 ETH" }}
  </button>
</template>

<script setup lang="ts">
import { fillEther } from '@/modules/common/web3/tenderlyTestnetFaucet'
import { useMutation } from '@tanstack/vue-query'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { address: userAddress } = useCustomAccount()

const { isPending: drippingEth, mutateAsync: fillEtherMutateAsync } = useMutation({
  mutationFn: async (variables) => {
    if (!userAddress.value) {
      throw new Error('No user address')
    }
    await fillEther(userAddress.value)
  },
})

const isTenderly = import.meta.env.VITE_IS_TENDERLY_TESTING_RPC as string === 'true'

const handleFillEtherMutateAsync = async () => {
  await fillEtherMutateAsync()
}

</script>

<style scoped>
.testing-helpers {
  position: fixed;
  bottom: 0.5rem;
  left: 1rem;
  padding: 0.5rem;
  color: var(--color-white);
  border: var(--primary-color-1) 1px solid;
  border-radius: 0.5rem;
  z-index: 1000;
  background-color: var(--background-color);
  cursor: pointer;
}
</style>
