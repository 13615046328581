import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import { parseSourceFromBackend } from '@/general-components/data-source/DataSourceType'
import type { ValuationSource } from '@/general-components/data-source/DataSourceType'
import useUuid from '@/utils/useUuid'
import type { NFTOrderBackendSchema } from '@/modules/common/backend/generated'
import { NFTOrderTypeEnumBackendSchema } from '@/modules/common/backend/generated'
import { formatAmountWithDecimals } from '@/utils/utils'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { AssetPrice } from '@/modules/common/assets/typings/AssetPriceClasses'
import { DateTime } from 'luxon'
import type { Address } from 'viem'
import { parseAddress } from '@/utils/address-utils'

export enum NFTOrderType {
  LISTING = 'listing',
  OFFER = 'offer'
}

export class NFTOrder {
  id: number
  orderType: NFTOrderType
  orderAsset: AssetWithAmount
  quantity: string
  expiration: Date
  creatorAddress: Address
  marketplace: ValuationSource
  floorPriceDifferencePercentage: number // filled only when order type is offer
  asset?: AssetWithAmount // filled only when the Order is for an asset from a bundle

  constructor(listing: Partial<NFTOrder>) {
    this.id = useUuid().getUuid()
    // @ts-expect-error TS(2322) FIXME: Type 'NFTOrderType | undefined' is not assignable ... Remove this comment to see the full error message
    this.orderType = listing?.orderType
    // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount | undefined' is not assignab... Remove this comment to see the full error message
    this.orderAsset = listing?.orderAsset
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.quantity = listing?.quantity
    // @ts-expect-error TS(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
    this.expiration = listing?.expiration
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
    this.creatorAddress = listing?.creatorAddress
    // @ts-expect-error TS(2322) FIXME: Type 'DataSourceType | undefined' is not assignabl... Remove this comment to see the full error message
    this.marketplace = listing?.marketplace
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.floorPriceDifferencePercentage = listing?.floorPriceDifferencePercentage
  }

  get orderUsdPrice(): string | undefined {
    return this.orderAsset?.appraisal?.price?.usdAmount
  }

  static createFromBackendResponse(nftOrder: NFTOrderBackendSchema): NFTOrder | null {
    if (!nftOrder) {
      return null
    }

    // BE endpoint does not return order expiration in proper format (it does not include timezone)
    // so we need to handle this on FE till the BE changes
    const utcExpiration = nftOrder?.expiration ? DateTime.fromISO(nftOrder.expiration, { zone: 'utc' }).toJSDate() : null

    return new NFTOrder({
      orderType: nftOrder.order_type === NFTOrderTypeEnumBackendSchema.listing ? NFTOrderType.LISTING : NFTOrderType.OFFER,
      orderAsset: new AssetWithAmount({
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        amount: formatAmountWithDecimals(nftOrder?.unit_price?.token_amount, nftOrder?.token_metadata?.decimals),
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '`0x${stri... Remove this comment to see the full error message
        address: nftOrder?.token_metadata?.contract_address,
        name: nftOrder?.token_metadata?.name ?? undefined,
        decimals: nftOrder?.token_metadata?.decimals ?? undefined,
        symbol: nftOrder?.token_metadata?.symbol ?? undefined,
        image: nftOrder?.token_metadata?.thumbnail_url ?? undefined,
        appraisal: new AssetPrice({
          // @ts-expect-error TS(2322) FIXME: Type 'Nullable<AmountInEthAndUsd>' is not assignab... Remove this comment to see the full error message
          price: nftOrder?.unit_price ? AmountInEthAndUsd.createFromBackendModel(nftOrder.unit_price) : null,
        }),
      }),
      quantity: String(nftOrder?.quantity ?? 1),
      // @ts-expect-error TS(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
      expiration: utcExpiration,
      creatorAddress: parseAddress(nftOrder?.creator_address),
      marketplace: parseSourceFromBackend(nftOrder?.marketplace),
      floorPriceDifferencePercentage: nftOrder?.floor_price_difference_percentage ?? undefined,
    })
  }
}
