<template>
  <PwnSafeAssetsDashboard
    v-if="!pwnSafeQuery.isLoading.value && loadedSafe"
    :header-label="loadedSafe.name"
    :header-icon="safeIcon.default"
    :header-address="loadedSafe.safeAddress"
    :search-term="searchTerm"
    :sorted-nfts="sortedNonAtrNfts"
    :sorted-coins="sortedSafeTokens"
    :sorted-atr-tokens="sortedAtrNfts"
    :selected-pwn-safe="loadedSafe"
    :is-fetching-nfts="nftsAreLoading"
    :is-fetching-coins="tokensAreLoading"
    :has-wallet-connect="!isConnectedPwnSafe"
    :handle-reload="handleReloadCurrentSafe"
    :available-safes="allDeployedSafes"
    @on-set-search-term="handleSetSearchTerms"/>
  <BaseSkeletor v-else/>
</template>

<script setup lang="ts">
import * as safeIcon from '@/assets/icons/safe.svg'
import usePwnSafeCoins from '@/modules/pages/pwn-safe/pwn-safe-detail/safe-assets/usePwnSafeCoins'
import { computed, watchEffect } from 'vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import usePwnSafeNfts from '@/modules/pages/pwn-safe/pwn-safe-detail/safe-assets/usePwnSafeNfts'
import PwnSafeAssetsDashboard from '@/modules/pages/pwn-safe/PwnSafeAssetsDashboard.vue'
import { useRoute } from 'vue-router'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { queries } from '@/modules/queries'
import { isAddress } from 'viem'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import useYourCoins from '@/modules/sections/your-assets/your-coins/useYourCoins'
import usePwnSafeAssetsDashboard from '@/modules/pages/pwn-safe/usePwnSafeAssetsDashboard'
import { useSafeAssetsDetail } from '@/modules/queryHooks/safe/useSafeAssetsDetail'
import { queryMutations } from '@/modules/mutations'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { storeToRefs } from 'pinia'

const route = useRoute()

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { searchTerm } = storeToRefs(pwnSafeDetailStore)

const connectedAccountTypeStore = useConnectedAccountTypeStore()
const { isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)

const { address: userAddress } = useCustomAccount()

const handleSetSearchTerms = (searchTermValue: string) => {
  searchTerm.value = searchTermValue
  usePwnSafeNfts().searchTerm.value = searchTermValue
  usePwnSafeCoins().searchTerm.value = searchTermValue
}

const { getSortedAndFilteredNFTs, selectedSortOption: selectedNftsSortOption } = useYourNFTs()
const { selectedSortOption: selectedCoinsSortOption, getSortedAndFilteredCoins } = useYourCoins()
const { selectedSortOption: selectedAtrSortOption, getSortedAndFilteredATRs: getSortedAndFilteredATRNFTs } =
  usePwnSafeAssetsDashboard()

const routerParams = computed(() => route.params as { safeAddress: string; chainId: string })

const pwnSafeQuery = useQuery({
  // @ts-expect-error FIXME: strictNullChecks
  ...queries.safe.detail(routerParams.value.safeAddress, Number(routerParams.value.chainId)),
  enabled: !!routerParams.value.safeAddress,
})

const loadedSafe = computed(() => {
  return pwnSafeQuery.data.value || null
})

const availableSafes = useQuery({
  // @ts-expect-error FIXME: strictNullChecks
  ...queries.safe.list(userAddress, Number(routerParams.value.chainId)),
})

const allDeployedSafes = computed(() => {
  return availableSafes.data.value || []
})

const { nonAtrNfts, atrNfts, safeTokens, nftsAreLoading, tokensAreLoading } = useSafeAssetsDetail(loadedSafe)

const sortedSafeTokens = computed(() => {
  return getSortedAndFilteredCoins([...safeTokens.value], searchTerm.value, selectedCoinsSortOption.value)
})

const sortedNonAtrNfts = computed(() => {
  return getSortedAndFilteredNFTs([...nonAtrNfts.value], searchTerm.value, selectedNftsSortOption.value)
})

const sortedAtrNfts = computed(() => {
  return getSortedAndFilteredATRNFTs([...atrNfts.value], searchTerm.value, selectedAtrSortOption.value)
})

const pwnSafeWithAssets = computed(() => {
  if (loadedSafe.value) {
    // @ts-expect-error FIXME: strictNullChecks
    return new PwnSafe({
      ...loadedSafe.value,
      nfts: [...nonAtrNfts.value, ...atrNfts.value],
      erc20s: safeTokens.value,
    })
  }
  return null
})

watchEffect(() => {
  // @ts-expect-error FIXME: strictNullChecks
  if (pwnSafeWithAssets.value && pwnSafeQuery.isFetched && isAddress(userAddress.value)) {
    pwnSafeDetailStore.setSelectedPwnSafe(pwnSafeWithAssets.value)
  }
})

const mutations = queryMutations()

const refreshMutation = useMutation(
  mutations.safe.batchRefreshAssets,
)

const handleReloadCurrentSafe = () => {
  refreshMutation.mutate({
    // @ts-expect-error FIXME: strictNullChecks
    safesToRefetch: [loadedSafe.value],
  })
}
</script>
