import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import { ref } from 'vue'

export const useNoProposalsStore = defineStore(StoreIds.useNoProposals, () => {
  const isHighlightForm = ref(false)

  const handleHighlightForm = () => {
    isHighlightForm.value = true
    setTimeout(() => {
      isHighlightForm.value = false
    }, 3500)
  }

  return {
    isHighlightForm,
    handleHighlightForm,
  }
})
