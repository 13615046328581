<template>
  <div class="confirmation-step__container">
    <p class="confirmation-step__success-message">
      {{ bottomParagraph }}
    </p>
    <div class="confirmation-step__button-container">
      <BaseButton
        :button-text="finishButtonText"
        @on-button-click="closeModal"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/general-components/BaseButton.vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import usePwnSafeClaimAndBurnModal from '@/modules/pages/pwn-safe/claim-and-burn/modal/usePwnSafeClaimAndBurnModal'
import { computed } from 'vue'
import usePwnSafeClaimAndBurn, { ClaimAndBurnActionType } from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import { capitalizeFirstLetter } from '@/utils/utils'
import { storeToRefs } from 'pinia'

const { closeModal, assetsToBeClaimedInfo, atrTokensInfo } = usePwnSafeClaimAndBurnModal()
const { claimAndBurnActionType } = usePwnSafeClaimAndBurn()
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const finishButtonText = computed(() => `Go to ${selectedPwnSafe.value?.name}`)

const bottomParagraph = computed(() => {
  switch (claimAndBurnActionType.value) {
  case ClaimAndBurnActionType.ClaimAndBurn:
    return `Success! ${assetsToBeClaimedInfo.value} has been transferred to your ${selectedPwnSafe.value?.name} and ${atrTokensInfo.value} has been burnt.`
  case ClaimAndBurnActionType.Burn:
    return `Success! ${capitalizeFirstLetter(atrTokensInfo.value)} has been burnt.`
  case ClaimAndBurnActionType.Claim:
    return `Success! ${assetsToBeClaimedInfo.value} has been transferred to your ${selectedPwnSafe.value?.name}.`
  default:
    // todo any log here?
    return null
  }
})
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.confirmation-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__success-message {
    color: var(--positive-bright);
  }

  &__button-container {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
