import { assetTraitsList } from '@/modules/common/backend/generated'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import AssetTraits from '@/modules/pages/asset/nft-page/types/AssetTraits'

export default function useSectionProperties() {
  const loadAssetTraits = async (): Promise<void> => {
    const { asset } = useAssetPage()

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const assetToLoadTraitsFor = asset.value.isBundleWithSingleAsset ? asset.value.bundledAsset : asset.value
    if (!assetToLoadTraitsFor) {
      return
    }

    const response = await assetTraitsList(
      assetToLoadTraitsFor.address,
      String(assetToLoadTraitsFor.chainId),
      String(assetToLoadTraitsFor.tokenId),
    )

    if (asset.value) {
      asset.value.traits = AssetTraits.createFromBackendResponse(response.data)
    }
  }

  return {
    loadAssetTraits,
  }
}
