/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useQuery
} from '@tanstack/vue-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AnnouncementNewFeaturesListListParams,
  PaginatedNewFeatureListBackendSchema
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const announcementNewFeaturesListList = (
    params?: MaybeRef<AnnouncementNewFeaturesListListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedNewFeatureListBackendSchema>(
      {url: `/api/v1/announcement/new_features_list/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getAnnouncementNewFeaturesListListQueryKey = (params?: MaybeRef<AnnouncementNewFeaturesListListParams>,) => {
    return ['api','v1','announcement','new_features_list', ...(params ? [params]: [])] as const;
    }

    
export const getAnnouncementNewFeaturesListListQueryOptions = <TData = Awaited<ReturnType<typeof announcementNewFeaturesListList>>, TError = unknown>(params?: MaybeRef<AnnouncementNewFeaturesListListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof announcementNewFeaturesListList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAnnouncementNewFeaturesListListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof announcementNewFeaturesListList>>> = ({ signal }) => announcementNewFeaturesListList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof announcementNewFeaturesListList>>, TError, TData> 
}

export type AnnouncementNewFeaturesListListQueryResult = NonNullable<Awaited<ReturnType<typeof announcementNewFeaturesListList>>>
export type AnnouncementNewFeaturesListListQueryError = unknown

export const useAnnouncementNewFeaturesListList = <TData = Awaited<ReturnType<typeof announcementNewFeaturesListList>>, TError = unknown>(
 params?: MaybeRef<AnnouncementNewFeaturesListListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof announcementNewFeaturesListList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAnnouncementNewFeaturesListListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



