import {
  useThesisList as useThesisListApi,
} from '@/modules/common/backend/generated'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'

export const useThesisList = (
  userAddress?: MaybeRef<string | undefined>,
) => {
  const params = computed(() => {
    const userAddressToSend = unref(userAddress)

    const result = {
      user_address: userAddressToSend,
    }

    return result
  })
  const thesisQuery = useThesisListApi(params, {
    query: {
      refetchOnMount: true,
    },
  })

  const parsedTheses = computed(() => {
    return thesisQuery.data.value?.data.results || []
  })

  return {
    theses: parsedTheses,
    isLoading: thesisQuery.isLoading,
    refetch: thesisQuery.refetch,
  }
}
