import { defineStore, storeToRefs } from 'pinia'
import { isPwnSpace } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import StoreIds from '@/constants/storeIds'
import { useLocalStorage } from '@vueuse/core'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { computed } from 'vue'
import { useAssetsWithSmallBalancesStore } from '@/revamp/hooks/useAssetsWithSmallBalancesStore'

export const useGlobalFiltersStore = defineStore(StoreIds.globalFilters, () => {
  const SHOW_UNVERIFIED_ASSETS_KEY = 'SHOW_UNVERIFIED_ASSETS_KEY'
  const showUnverifiedAssets = useLocalStorage(SHOW_UNVERIFIED_ASSETS_KEY, isPwnSpace)

  const SHOW_ASSETS_WITHOUT_PRICING_KEY = 'SHOW_ASSETS_WITHOUT_PRICING'
  const showAssetsWithoutPricing = useLocalStorage(SHOW_ASSETS_WITHOUT_PRICING_KEY, isPwnSpace)

  const SHOW_ASSETS_WITH_LESSER_AMOUNT_KEY = 'SHOW_ASSETS_WITH_LESSER_AMOUNT'
  const showAssetsWithLesserAmount = useLocalStorage(SHOW_ASSETS_WITH_LESSER_AMOUNT_KEY, isPwnSpace)

  const smallBalancesStore = useAssetsWithSmallBalancesStore()
  const { assetsWithSmallBalances } = storeToRefs(smallBalancesStore)

  const applyShowUnverifiedAssetsFilter = <T extends AssetMetadata>(assets: T[]): T[] => {
    if (!assets?.length) {
      assets = []
    }

    if (showUnverifiedAssets.value) {
      return assets
    }

    return assets.filter(asset => {
      // temporary solution to make sure bundle/atr/loan tokens are displayed in the user assets
      //  as well as e.g. choose bundle to unbundle section
      // TODO implement proper verification logic for token bundles, atr tokens and loan tokens
      if (asset.isTokenBundle || asset.isAtrToken || asset.isLoanToken) {
        return true
      }
      return asset.isVerified
    })
  }

  const applyShowAssetsWithLesserAmountFilter = <T extends AssetMetadata>(assets: T[]): T[] => {
    if (!assets?.length) {
      assets = []
    }

    if (!showAssetsWithLesserAmount.value) {
      return assets.filter(asset => {
        const assetIdIsInSmallBalances = assetsWithSmallBalances.value[String(asset.id)]
        if (assetIdIsInSmallBalances) {
          return false
        }
        return true
      })
    }

    return assets
  }

  const isAnyGlobalFilterEnabled = computed(() => {
    return showUnverifiedAssets.value === false || showAssetsWithoutPricing.value === false
  })

  return {
    showUnverifiedAssets,
    showVerifiedAssetsOnly: computed(() => !showUnverifiedAssets.value),

    showAssetsWithoutPricing,
    showAssetsWithPricingOnly: computed(() => !showAssetsWithoutPricing.value),

    showAssetsWithLesserAmount,
    showAssetsWithLesserAmountOnly: computed(() => !showAssetsWithLesserAmount.value),

    applyShowUnverifiedAssetsFilter,
    applyShowAssetsWithLesserAmountFilter,
    isAnyGlobalFilterEnabled,
  }
})
