import { getAssetCacheState, purgeUserERC20sCache, purgeUserNFTsCache } from '@/modules/common/backend/generated'
import to from '@/utils/await-to-js'
import { ref } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'
import { isPwnSpace, pwnSpaceName } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import DateUtils from '@/utils/DateUtils'
import type { Address } from 'viem'

const INVALIDATE_CACHE_AFTER_MINUTES = 2

type RequestParams = {
  userAddress: Address;
  chainId: SupportedChain;
  isOnlyPurgeCache?: boolean;
};

type PurgeCacheRequestParams = RequestParams & {
  target?: 'userErc20s' | 'userNfts' | 'both'
}

const cacheCreatedAt = ref<Date | null>(null)
const cacheIsResetting = ref<boolean>(false)
const cacheIsLoading = ref<boolean>(false)

function shouldAutomaticallyInvalidate(cacheDate: Date): boolean {
  const now = new Date()
  const timePassedInMinutes = DateUtils.getMinutesDifference(now, cacheDate)
  return timePassedInMinutes >= INVALIDATE_CACHE_AFTER_MINUTES
}

async function loadCacheState({ userAddress, chainId }: RequestParams): Promise<Date> {
  cacheIsLoading.value = true

  const [error, response] = await to(
    getAssetCacheState({
      user_address: userAddress,
      chain_id: chainId,
      ...(isPwnSpace && { subdomain: pwnSpaceName! }),
    }),
    {
      enableLogging: false,
    },
  )
  cacheIsLoading.value = false
  cacheIsResetting.value = false

  if (error) {
    /*
      we assume that if server returned 404 or other error for cache state
      then the cache is going to be created on the assets fetching call
    */
    cacheCreatedAt.value = new Date()
    return cacheCreatedAt.value
  }
  const refreshedAt = new Date(response.data.created_at)
  cacheCreatedAt.value = refreshedAt
  return refreshedAt
}

async function resetCacheState({ userAddress, chainId, isOnlyPurgeCache = false, target = 'both' }: PurgeCacheRequestParams): Promise<void> {
  if (!isOnlyPurgeCache) {
    cacheIsResetting.value = true
    cacheCreatedAt.value = null
  }

  const promises: Promise<unknown>[] = []
  if (target === 'userErc20s' || target === 'both') {
    promises.push(purgeUserERC20sCache({
      user_address: userAddress,
      chain_id: chainId,
      ...(isPwnSpace && { subdomain: pwnSpaceName! }),
    }))
  }

  if (target === 'userNfts' || target === 'both') {
    promises.push(purgeUserNFTsCache({
      user_address: userAddress,
      chain_id: chainId,
      ...(isPwnSpace && { subdomain: pwnSpaceName! }),
    }))
  }

  await to(Promise.allSettled(promises))

  await to(
    loadCacheState({
      userAddress,
      chainId,
    }),
  )
}

export default function useDashboardCache() {
  return {
    cacheCreatedAt,
    cacheIsLoading,
    cacheIsResetting,
    shouldAutomaticallyInvalidate,
    loadCacheState,
    resetCacheState,
  }
}
