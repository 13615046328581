<template>
  <BaseModal
    v-model:is-open="isOpen"
    heading-align="left"
    heading="Type Your Discord User ID">
    <template #trigger>
      <BaseButton
        :style="{ width: props.buttonWidth }"
        :color="ButtonColor.PrimaryDark"
        button-text="Discord"
        :variant="ButtonVariant.Outline"
        is-full-width
        class="discord-notification-modal__button"
        @on-button-click="openModal">
        <template #iconBeforeText>
          <DiscordLogoSvg/>
        </template>
      </BaseButton>
    </template>

    <template #body>
      <div class="discord-notification-modal__body">
        <div class="discord-notification-modal__input-container">
          <div class="discord-notification-modal__input">
            <BaseInput
              v-if="userProfile"
              v-model="userProfile.discordUserId"
              :is-valid-input="isValidInput"
              placeholder="Example: 957314868443246693"
              warning-text="Please enter a valid Discord User ID"/>
          </div>
          <!-- PENDING RESOLUTION FROM SLACK CONVO https://pwn-xyz.slack.com/archives/C049GV6J12L/p1687347749515249 -->
          <!-- <div class="discord-notification-modal__icon">
            <img
              src="@/assets/icons/paste.svg?url"
              alt="paste">
          </div> -->
        </div>
      </div>
      <div>
        <div class="discord-notification-modal__currently-sending-subheading">
          Currently Sending Notifications To:
        </div>
        <BaseButton
          v-if="isValidInput && userProfile?.discordUserId"
          :variant="ButtonVariant.Outline"
          :button-text="userProfile.discordUserId"
          @on-button-click="userProfile.discordUserId = ''">
          <template #icon>
            <CloseIconSvg alt="close"/>
          </template>
        </BaseButton>
      </div>
      <div class="discord-notification-modal__button-container">
        <BaseLink @click="isOpen = false">
          Go back
        </BaseLink>
        <BaseButton
          button-text="Confirm"
          @on-button-click="handleClose"/>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import { computed, ref } from 'vue'
import BaseButton, { ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import BaseInput from '@/general-components/BaseInput.vue'
import useUserProfile from '@/modules/common/assets/fetchers/useUserProfile'
import CloseIconSvg from '@/assets/icons/close-primary-color.svg'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import DiscordLogoSvg from '@/assets/icons/discord.svg'
import BaseLink from '@/general-components/BaseLink.vue'

const isOpen = ref(false)
const { address: userAddress } = useCustomAccount()
const { userProfile, updateUserProfile, loadUserProfile } = useUserProfile()
const isValidInput = computed(() => {
  if (userProfile.value?.discordUserId) {
    return userProfile.value.discordUserId.length >= 17 && userProfile.value.discordUserId.length <= 19
  }
  return false
})

const props = defineProps<{
  buttonWidth?: string
}>()

const handleClose = () => {
  // TODO turn into mutation + add toast notification for this action
  updateUserProfile()
  isOpen.value = false
}

const openModal = () => {
  loadUserProfile(userAddress.value!, false)
  isOpen.value = true
}
</script>

<style scoped>
.discord-notification-modal {
  &__body {
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__input-container {
    display: flex;
    width: 70%;
    min-width: 20rem;
  }

  &__input {
    width: 100%;
  }

  &__icon {
    display: flex;
    margin-left: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__currently-sending-subheading {
    margin-bottom: 0.75rem;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (--mobile-viewport) {
    &__button {
      width: 50%;
    }
  }
}
</style>
