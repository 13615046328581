export const ABI = [
  {
    type: 'impl',
    name: 'SimpleLoanDutchAuctionProposalImpl',
    interface_name: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::ISimpleLoanFungibleProposal',
  },
  {
    type: 'enum',
    name: 'pwn::multitoken::library::MultiToken::Category',
    variants: [
      {
        name: 'ERC20',
        type: '()',
      },
      {
        name: 'ERC721',
        type: '()',
      },
      {
        name: 'ERC1155',
        type: '()',
      },
    ],
  },
  {
    type: 'struct',
    name: 'core::integer::u256',
    members: [
      {
        name: 'low',
        type: 'core::integer::u128',
      },
      {
        name: 'high',
        type: 'core::integer::u128',
      },
    ],
  },
  {
    type: 'enum',
    name: 'core::bool',
    variants: [
      {
        name: 'False',
        type: '()',
      },
      {
        name: 'True',
        type: '()',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::Proposal',
    members: [
      {
        name: 'collateral_category',
        type: 'pwn::multitoken::library::MultiToken::Category',
      },
      {
        name: 'collateral_address',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'collateral_id',
        type: 'core::felt252',
      },
      {
        name: 'min_collateral_amount',
        type: 'core::integer::u256',
      },
      {
        name: 'check_collateral_state_fingerprint',
        type: 'core::bool',
      },
      {
        name: 'collateral_state_fingerprint',
        type: 'core::felt252',
      },
      {
        name: 'credit_address',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'credit_per_collateral_unit',
        type: 'core::integer::u256',
      },
      {
        name: 'available_credit_limit',
        type: 'core::integer::u256',
      },
      {
        name: 'fixed_interest_amount',
        type: 'core::integer::u256',
      },
      {
        name: 'accruing_interest_APR',
        type: 'core::integer::u32',
      },
      {
        name: 'duration',
        type: 'core::integer::u64',
      },
      {
        name: 'expiration',
        type: 'core::integer::u64',
      },
      {
        name: 'allowed_acceptor',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'proposer',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'proposer_spec_hash',
        type: 'core::felt252',
      },
      {
        name: 'is_offer',
        type: 'core::bool',
      },
      {
        name: 'refinancing_loan_id',
        type: 'core::felt252',
      },
      {
        name: 'nonce_space',
        type: 'core::felt252',
      },
      {
        name: 'nonce',
        type: 'core::felt252',
      },
      {
        name: 'loan_contract',
        type: 'core::starknet::contract_address::ContractAddress',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::lib::signature_checker::Signature',
    members: [
      {
        name: 'r',
        type: 'core::felt252',
      },
      {
        name: 's',
        type: 'core::felt252',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::multitoken::library::MultiToken::Asset',
    members: [
      {
        name: 'category',
        type: 'pwn::multitoken::library::MultiToken::Category',
      },
      {
        name: 'asset_address',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'id',
        type: 'core::felt252',
      },
      {
        name: 'amount',
        type: 'core::integer::u256',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::loan::types::Terms',
    members: [
      {
        name: 'lender',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'borrower',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'duration',
        type: 'core::integer::u64',
      },
      {
        name: 'collateral',
        type: 'pwn::multitoken::library::MultiToken::Asset',
      },
      {
        name: 'credit',
        type: 'pwn::multitoken::library::MultiToken::Asset',
      },
      {
        name: 'fixed_interest_amount',
        type: 'core::integer::u256',
      },
      {
        name: 'accruing_interest_APR',
        type: 'core::integer::u32',
      },
      {
        name: 'lender_spec_hash',
        type: 'core::felt252',
      },
      {
        name: 'borrower_spec_hash',
        type: 'core::felt252',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::ProposalValues',
    members: [
      {
        name: 'collateral_amount',
        type: 'core::integer::u256',
      },
    ],
  },
  {
    type: 'interface',
    name: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::ISimpleLoanFungibleProposal',
    items: [
      {
        type: 'function',
        name: 'make_proposal',
        inputs: [
          {
            name: 'proposal',
            type: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::Proposal',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'accept_proposal',
        inputs: [
          {
            name: 'acceptor',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'refinancing_loan_id',
            type: 'core::felt252',
          },
          {
            name: 'proposal_data',
            type: 'core::array::Array::<core::felt252>',
          },
          {
            name: 'proposal_inclusion_proof',
            type: 'core::array::Array::<core::integer::u256>',
          },
          {
            name: 'signature',
            type: 'pwn::loan::lib::signature_checker::Signature',
          },
        ],
        outputs: [
          {
            type: '(core::felt252, pwn::loan::terms::simple::loan::types::Terms)',
          },
        ],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'get_proposal_hash',
        inputs: [
          {
            name: 'proposal',
            type: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::Proposal',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'encode_proposal_data',
        inputs: [
          {
            name: 'proposal',
            type: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::Proposal',
          },
          {
            name: 'proposal_values',
            type: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::ProposalValues',
          },
        ],
        outputs: [
          {
            type: 'core::array::Array::<core::felt252>',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'decode_proposal_data',
        inputs: [
          {
            name: 'encoded_data',
            type: 'core::array::Array::<core::felt252>',
          },
        ],
        outputs: [
          {
            type: '(pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::Proposal, pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::ProposalValues)',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_credit_amount',
        inputs: [
          {
            name: 'collateral_amount',
            type: 'core::integer::u256',
          },
          {
            name: 'credit_per_collateral_unit',
            type: 'core::integer::u256',
          },
        ],
        outputs: [
          {
            type: 'core::integer::u256',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'PROPOSAL_TYPEHASH',
        inputs: [],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
    ],
  },
  {
    type: 'impl',
    name: 'SimpleLoanProposalImpl',
    interface_name: 'pwn::loan::terms::simple::proposal::simple_loan_proposal::ISimpleLoanProposal',
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::proposal::simple_loan_proposal::SimpleLoanProposalComponent::Multiproposal',
    members: [
      {
        name: 'merkle_root',
        type: 'core::integer::u256',
      },
    ],
  },
  {
    type: 'interface',
    name: 'pwn::loan::terms::simple::proposal::simple_loan_proposal::ISimpleLoanProposal',
    items: [
      {
        type: 'function',
        name: 'revoke_nonce',
        inputs: [
          {
            name: 'nonce_space',
            type: 'core::felt252',
          },
          {
            name: 'nonce',
            type: 'core::felt252',
          },
        ],
        outputs: [],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'get_multiproposal_hash',
        inputs: [
          {
            name: 'multiproposal',
            type: 'pwn::loan::terms::simple::proposal::simple_loan_proposal::SimpleLoanProposalComponent::Multiproposal',
          },
        ],
        outputs: [
          {
            type: 'core::integer::u256',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_proposal_made',
        inputs: [
          {
            name: 'proposal_hash',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'core::bool',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_credit_used',
        inputs: [
          {
            name: 'proposal_hash',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'core::integer::u256',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'DOMAIN_SEPARATOR',
        inputs: [],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'MULTIPROPOSAL_DOMAIN_SEPARATOR',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u256',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'MULTIPROPOSAL_TYPEHASH',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u256',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'VERSION',
        inputs: [],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
    ],
  },
  {
    type: 'constructor',
    name: 'constructor',
    inputs: [
      {
        name: 'hub',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'revoke_nonce',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'config',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'name',
        type: 'core::felt252',
      },
      {
        name: 'version',
        type: 'core::felt252',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::ProposalMade',
    kind: 'struct',
    members: [
      {
        name: 'proposal_hash',
        type: 'core::felt252',
        kind: 'data',
      },
      {
        name: 'proposer',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'proposal',
        type: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::Proposal',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::proposal::simple_loan_proposal::SimpleLoanProposalComponent::Event',
    kind: 'enum',
    variants: [],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::Event',
    kind: 'enum',
    variants: [
      {
        name: 'ProposalMade',
        type: 'pwn::loan::terms::simple::proposal::simple_loan_fungible_proposal::SimpleLoanFungibleProposal::ProposalMade',
        kind: 'nested',
      },
      {
        name: 'SimpleLoanProposalEvent',
        type: 'pwn::loan::terms::simple::proposal::simple_loan_proposal::SimpleLoanProposalComponent::Event',
        kind: 'flat',
      },
    ],
  },
] as const
