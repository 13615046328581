<template>
  <FeaturedCollateralTable
    v-if="isPwnSpace && store.isSearchEmpty"
    :is-nft="false"/>
  <BaseTable
    v-else
    v-model:active-sort-option="tokenSelectedSortOption"
    class="pwn-explorer-assets-tab-tokens"
    :columns="PWN_EXPLORER_TOKENS"
    :variant="TableVariant.ExplorerCollections"
    :is-fetching="loadingState.tokens!"
    :items="sortByPromotion"
    @on-row-click="navigate">
    <template #name="{ item }">
      <div class="pwn-explorer-assets-tab-tokens__name">
        <AssetImageWithChain
          :image="item.image"
          is-erc20
          :chain-id="item.chainId"/>

        <div class="pwn-explorer-assets-tab-tokens__name-row">
          <SafeText
            :class="[
              'pwn-explorer-assets-tab-tokens__name-text',
              { 'pwn-explorer-assets-tab-tokens__name-text--missing-name': !item.title },
            ]"
            :allow-click-through="true"
            :text="item.title || 'Missing name'"
            :tooltip-text="item.title"/>

          <VerifiedIcon
            v-if="item.isVerified"
            class="pwn-explorer-assets-tab-tokens__is-verified"/>
          <DangerAssetIcon
            v-if="isAssetFakeComputed(item)"
            style="margin-left: 0.5rem;"
            tooltip-text="This asset is likely fake."/>
        </div>

        <RewardsTooltip
          class="pwn-explorer-assets-tab-tokens__points-icon"
          :chain-id="item.chainId"
          :assets="[{ chainId: item.chainId, address: item.contractAddress }]"/>
      </div>
    </template>

    <template #price="{ item: erc20Asset }">
      <BaseSkeletor
        v-if="assetsTabStore.actions.tokenStatsAreFetching(erc20Asset)"
        class="pwn-explorer-assets-tab-tokens__inline-loading"/>
      <EthAndUsdPriceLabel
        v-else-if="getERC20AssetStats(erc20Asset)?.latestPrice?.price"
        :decimals="2"
        :amount="getERC20AssetStats(erc20Asset)?.latestPrice?.price"
        :price-source="getERC20AssetStats(erc20Asset)?.latestPrice?.priceSource"/>
      <div v-else>
        ---
      </div>
    </template>

    <template #tradingVolume="{ item: erc20Asset }">
      <BaseSkeletor
        v-if="assetsTabStore.actions.tokenStatsAreFetching(erc20Asset)"
        class="pwn-explorer-assets-tab-tokens__inline-loading"/>
      <EthAndUsdPriceLabel
        v-else-if="getERC20AssetStats(erc20Asset)?.volume24h && getERC20AssetStats(erc20Asset)?.volume24h?.usdAmount !== '0'"
        :decimals="2"
        :amount="getERC20AssetStats(erc20Asset)?.volume24h"/>
      <div v-else>
        ---
      </div>
    </template>

    <template #action="{ item: token }">
      <BaseButton
        class="pwn-explorer-assets-tab-tokens__actions"
        :variant="ButtonVariant.Outline"
        button-text="Make Proposal"
        @click.stop="(event: MouseEvent) => navigate(token, event, true)"/>
    </template>
  </BaseTable>
  <BaseEmptyStateText
    v-if="hasNoResults"
    text="No tokens matching search criteria"/>
  <SearchCategoryPaginationController :category="SearchCategory.TOKENS"/>
</template>

<script lang="ts" setup>
import BaseTable from '@/general-components/BaseTable.vue'
import BaseButton, { ButtonVariant } from '@/general-components/BaseButton.vue'
import { PWN_EXPLORER_TOKENS } from '@/modules/pages/pwn-explorer/tabs/TableDefinitions'
import { useSearchPwnExplorer } from '@/modules/common/pwn/explorer/useSearchStore'
import { storeToRefs } from 'pinia'
import AssetImageWithChain from '@/modules/pages/pwn-explorer/tabs/AssetImageWithChain.vue'
import router from '@/router'
import type { NFTAssetSearchResult } from '@/modules/common/pwn/explorer/models/NFTAssetSearchResult'
import { computed, onMounted, onUnmounted } from 'vue'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import { useERC20SearchStatsStore } from '@/modules/common/pwn/explorer/stats/useERC20SearchStatsStore'
import type { ERC20AssetSearchResult } from '@/modules/common/pwn/explorer/models/ERC20AssetSearchResult'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { usePwnExplorerAssetsTabStore } from '@/modules/common/pwn/explorer/tab-stores/usePwnExplorerAssetsTabStore'
import SafeText from '@/general-components/SafeText.vue'
import VerifiedIcon from '@/general-components/icons/VerifiedIcon.vue'
import { SearchCategory } from '@/modules/common/pwn/explorer/models'
import SearchCategoryPaginationController from '@/modules/pages/pwn-explorer/tabs/SearchCategoryPaginationController.vue'
import FeaturedCollateralTable from '@/modules/pages/pwn-explorer/FeaturedCollateralTable.vue'
import RouteName from '@/router/RouteName'
import { isPwnSpace, pwnSpaceConfig } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import type { ERC20AssetDocumentBackendSchema } from '@/modules/common/backend/generated'
import TableVariant from '@/general-components/TableVariant'
import RewardsTooltip from '@/general-components/promo/RewardsTooltip.vue'
import { getEligibleRewards } from '@/general-components/promo/rewardsRegistry'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import DangerAssetIcon from '@/general-components/DangerAssetIcon.vue'

const store = useSearchPwnExplorer()
const erc20StatsStore = useERC20SearchStatsStore()
const assetsTabStore = usePwnExplorerAssetsTabStore()
const { results, loadingState, searchTerm } = storeToRefs(store)
const { tokenSelectedSortOption, sortedTokens } = storeToRefs(assetsTabStore)
const isSearchEmpty = computed(() => !searchTerm.value)
const hasNoResults = computed(
  () => !loadingState.value.tokens && !results.value.tokens?.length && !isSearchEmpty.value,
)
const getERC20AssetStats = (asset: ERC20AssetSearchResult) => {
  return erc20StatsStore.actions.getTokenPriceStats(asset)
}

const sortByPromotion = computed(() => {
  return sortedTokens.value.slice(0).sort((a, b) => {
    const aPointsAssetType = !!getEligibleRewards([{ chainId: a.chainId, address: a.contractAddress }])?.length
    const bPointsAssetType = !!getEligibleRewards([{ chainId: b.chainId, address: b.contractAddress }])?.length
    if (aPointsAssetType && !bPointsAssetType) {
      return -1
    }
    if (!aPointsAssetType && bPointsAssetType) {
      return 1
    }
    return 0
  })
})

const navigate = (item: NFTAssetSearchResult | ERC20AssetDocumentBackendSchema, event: MouseEvent, isHighlight? : boolean) => {
  if ('pageRoute' in item) {
    if (event && (event.ctrlKey || event.metaKey)) {
      const resolvedRoute = router.resolve(item.pageRoute())
      window.open(resolvedRoute.href, '_blank')
      return
    }
    const pageRoute = item.pageRoute()
    // @ts-expect-error FIXME: Property 'params' does not exist on type 'RouteLocationRaw'.
    if (isHighlight) router.push({ name: pageRoute.name, params: pageRoute.params, query: { highlight: 'true' } })
    else router.push(pageRoute)
  }
}
onMounted(() => {
  if (pwnSpaceConfig?.isNftOnly) {
    router.push({ name: RouteName.PwnExplorerPageCollections })
  }
  store.actions.setCategoriesToFetch([SearchCategory.TOKENS])
  erc20StatsStore.actions.shouldFetch(true)
})

onUnmounted(() => {
  erc20StatsStore.actions.shouldFetch(false)
})

const isAssetFakeComputed = (asset: ERC20AssetSearchResult) => {
  return useTopTokensStore().isAssetFake(asset)
}
</script>

<style scoped>
.title {
  font-family: var(--font-family-screener);
  font-size: 1.875rem;
  font-weight: 550;
  line-height: 2.025rem;
  margin-bottom: 2rem;
}

.pwn-explorer-assets-tab-tokens {
  &__name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__name-row {
    margin-left: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    overflow: hidden;
    padding-right: 1rem;
  }

  &__name-text--missing-name {
    color: var(--negative-1);
  }

  &__is-verified {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }

  &__actions {
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5rem;
  }

  &__points-icon {
    margin-left: auto;
    margin-right: 0.25rem;
  }

  &__inline-loading {
    max-width: 4rem;
  }
}

@media screen and (--mobile-viewport) {
  .pwn-explorer-assets-tab-tokens {
    overflow: auto hidden;
  }
}
</style>
