<template>
  <header
    class="hero">
    <div
      class="hero__homepage">
      <div class="hero__subtitle">
        Search for any asset and post lending proposals on your terms
      </div>
    </div>
  </header>
</template>

<style scoped>
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__note {
    font-size: 1.125rem;
    font-family: var(--font-family-supreme);
    margin-top: 1rem;
    text-align: center;
  }

  &__subtitle {
    font-family: var(--font-family-screener);
    font-size: 1.875rem;
    text-align: center;
    text-wrap: balance;
  }

  &__link {
    color: var(--primary-color-1);
  }

  @media only screen and (--small-viewport) {
    &__content {
      width: 100%;
    }

    &__logo {
      height: 1.5rem;
    }

    &__title {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    &__subtitle {
      font-size: 1.5rem;
      line-height: 2rem;
      width: 100%;
    }

    &__note {
      width: 100%;
      font-size: 0.8rem;
      display: inline-block;
      text-align: center;
    }
  }
}
</style>
