<template>
  <div
    v-if="alwaysShowControls || arrayOfPages.length > 1"
    class="pagination">
    <div class="pagination__content">
      <button
        v-if="currentPage !== 1"
        class="pagination__button pagination__arrow-button"
        @click="() => setPage(currentPage - 1)">
        <img
          src="@/assets/icons/arrow-left-white.svg?url"
          alt="Back">
      </button>

      <button
        v-for="(page, index) in arrayOfPages"
        :key="page"
        :class="['pagination__button', {
          'pagination__button-active': page === currentPage,
        }]"
        @click="() => setPage(page, index)">
        {{ page }}
      </button>

      <button
        v-if="hasNextButton"
        class="pagination__button pagination__arrow-button"
        @click="() => setPage(currentPage + 1)">
        <img
          src="@/assets/icons/arrow-right-white.svg?url"
          alt="Next">
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { generatePaginationArray, getPageElementOffset } from '@/modules/common/pagination/utils'

type Props = {
  limit: number
  totalResults: number
  currentOffset: number
  alwaysShowControls?: boolean
}
const props = defineProps<Props>()

const emit = defineEmits<{(e: 'on-offset-change', newOffset: number)}>()

const currentPage = computed(() => {
  if (props.currentOffset === 0) {
    return 1
  }
  return (props.currentOffset / props.limit) + 1
})

const totalPages = computed(() => Math.ceil(props.totalResults / props.limit))

const arrayOfPages = computed(() => {
  return generatePaginationArray(totalPages.value, currentPage.value)
})

const hasNextButton = computed(() => {
  if (arrayOfPages.value.length) {
    return currentPage.value < Number(arrayOfPages.value?.[arrayOfPages.value.length - 1])
  }
  return false
})

const setPage = (page: number | string, index?: number) => {
  const offset = getPageElementOffset(
    arrayOfPages.value,
    page,
    props.limit,
    index,
  )
  if (offset === undefined && typeof page === 'number') {
    emit('on-offset-change', (page - 1) * props.limit)
    return
  }
  // @ts-expect-error FIXME: strictNullChecks
  emit('on-offset-change', offset)
}

</script>

<style scoped>
.pagination {
  display: flex;
  width: 100%;
  padding-bottom: 2rem;

  &__content {
    display: flex;
    cursor: pointer;
    margin-left: auto;
    margin-top: 2rem;
    gap: 1rem;
  }

  &__button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    color: var(--text-color);
    font-size: 1rem;
    font-family: var(--font-family-oxygen-mono);

    &-active {
      color: var(--primary-color-1);
    }
  }
}
</style>
