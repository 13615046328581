import { useQuery } from '@tanstack/vue-query'
import { queries } from '@/modules/queries'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import { isAddress } from 'viem'
import type { Address } from 'viem'

export default function useWalletStats(userAddress: MaybeRef<Address>) {
  const parsedAddress = computed(() => isAddress(unref(userAddress)))

  const statsData = useQuery({
    ...queries.user.stats(userAddress),
    enabled: parsedAddress,
  })

  const isFetchingWalletStats = computed(() => statsData.isLoading.value)
  const walletStats = computed(() => statsData.data.value)

  return {
    walletStats,
    isFetchingWalletStats,
  }
}
