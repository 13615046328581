<template>
  <div class="space-explorer__hero">
    <span class="space-explorer__title">
      Find a space you would like to explore
    </span>
  </div>
  <div class="space-explorer__search-bar">
    <BaseSearch
      v-model="searchTerm"
      has-clear-button
      :class="['space-explorer__search', { 'space-explorer__search--selected': searchTerm }]"
      search-placeholder="Search for Spaces"/>
  </div>
  <BaseSkeletor v-if="spacesQuery.isFetching.value"/>
  <BaseEmptyStateText
    v-else-if="!spaces.length"
    text="No Spaces found"/>
  <div
    v-else
    class="space-explorer__card-grid">
    <div
      v-for="space in spaces"
      :key="space.pwnSpaceName"
      class="space-explorer__card">
      <SpaceCard :space-data="space"/>
    </div>
    <div class="space-explorer__card">
      <SuggestSpaceCard/>
    </div>
  </div>
</template>

<script setup lang="ts">
import SpaceCard from '@/modules/pages/spaces/SpaceCard.vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import { queries } from '@/modules/queries'
import { useQuery } from '@tanstack/vue-query'
import { computed, ref } from 'vue'
import SuggestSpaceCard from '@/modules/pages/spaces/SuggestSpaceCard.vue'

const searchTerm = ref('')
const spacesQuery = useQuery({
  ...queries.spaces.list(),
  staleTime: 1000 * 60 * 5,
})

const spaces = computed(
  () => {
    if (spacesQuery.data.value instanceof Error) {
      return []
    }
    if (!searchTerm.value) {
      return spacesQuery.data?.value || []
    }
    return spacesQuery.data?.value?.filter(space => space.title?.toLowerCase().includes(searchTerm.value.toLowerCase())) || []
  })
</script>

<style scoped>
.space-explorer {
  &__hero {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  &__title {
    font-family: var(--font-family-screener);
    font-size: 1.875rem;
    text-align: center;
  }

  &__card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-bottom: 5rem;
  }

  &__card {
    display: flex;
    justify-content: center;
  }

  &__search-bar {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
  }

  &__search {
    flex: 1;
    max-width: 50rem;
    position: relative;
    border: 1px solid var(--gray);
    padding: 0.25rem;
    font-family: var(--font-family-oxygen-mono);
    align-items: center;
    height: 3.5rem;
    font-size: 1.125rem;

    &--selected {
      border: 1px solid var(--text-color);
    }
  }

  &__footer {
    margin-top: 15rem;
  }

  @media only screen and (--small-viewport) {
    &__card-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: 865px) {
    &__card-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }

    &__footer {
      margin-top: 0;
    }

    &__title {
      font-size: 1.5rem;
    }
  }
}
</style>
