import { useQuery } from '@tanstack/vue-query'
import type { Address } from 'viem'
import { useEnsStore } from './useEnsStore'
import { resolveAddressForBasename, resolveBasename } from './useBasename'
import { computed } from 'vue'
import type { Ref } from 'vue'

export interface AddressProfile {
    address: Address;
    name: string | null;
    avatar: string | null;
  }

export async function getAddressProfile(address: Address): Promise<AddressProfile | undefined> {
  // basenames displaying has priority over ENS for now

  const basenameAddressProfile = await resolveAddressForBasename(address)
  if (basenameAddressProfile?.name) {
    return basenameAddressProfile
  }

  return await useEnsStore().resolveAddress(address)
}

export function useAddressProfile(address: Ref<Address | null>) {
  return useQuery({
    enabled: computed(() => !!address.value),
    queryKey: ['addressName', address],
    queryFn: async () => await getAddressProfile(address.value!),
    // TODO make this persist?
    staleTime: Infinity,
  })
}

export async function resolveAddressName(addressName: string): Promise<Address | undefined> {
  const basenameAddress = await resolveBasename(addressName)
  if (basenameAddress) {
    return basenameAddress
  }

  return await useEnsStore().resolveEnsName(addressName) ?? undefined
}
