import { defineStore } from 'pinia'
import { ExplorerStores } from '@/modules/common/pwn/explorer/constants'
import { SearchCategory } from '@/modules/common/pwn/explorer/models'
import { ref } from 'vue'

type CategoriesPagination = Record<SearchCategory, PaginationConfigs | null>

const defaultCategoriesPagination: CategoriesPagination = {
  [SearchCategory.TOKENS]: null,
  [SearchCategory.COLLECTIONS]: null,
  [SearchCategory.NFTS]: null,
  [SearchCategory.WALLETS]: null,
}

type PaginationConfigs = {
    count: number
    currentOffset: number
    limit: number
}

export const useExplorerSearchPaginationStore = defineStore(ExplorerStores.SearchPagination, () => {
  const categoriesPagination = ref(defaultCategoriesPagination)

  const setCategoryPagination = (category: SearchCategory, pagination: PaginationConfigs) => {
    categoriesPagination.value = {
      ...categoriesPagination.value,
      [category]: pagination,
    }
  }

  const setCurrentOffset = (category: SearchCategory, offset: number) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ currentOffset: number; count?:... Remove this comment to see the full error message
    setCategoryPagination(category, {
      ...categoriesPagination.value[category],
      currentOffset: offset,
    })
  }

  const resetPagination = () => {
    categoriesPagination.value = defaultCategoriesPagination
  }

  return {
    actions: {
      setCurrentOffset,
      setCategoryPagination,
      resetPagination,
    },
    categoriesPagination,
  }
})
