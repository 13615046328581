<template>
  <RevampBaseModal
    v-model:is-open="fungibleProposalModalStore.isOpen"
    :size="ModalSize.Medium">
    <template #custom-header>
      <header
        class="accept-fungible-proposal-modal__header">
        <h2 class="accept-fungible-proposal-modal__title">
          Select How Much You Want To {{ isLendingProposal ? "Borrow" : "Lend" }}
        </h2>
        <div class="accept-fungible-proposal-modal__breadcrumbs-and-close">
          <CloseIconSvg
            alt="close"
            class="accept-fungible-proposal-modal__close"
            @click.stop="fungibleProposalModalStore.close"/>
        </div>
      </header>
    </template>
    <template #body>
      <div class="accept-fungible-proposal-modal__body">
        <form
          class="accept-fungible-proposal-modal__form"
          @submit="
            async (e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }
          ">
          <div
            v-if="isLendingProposal"
            class="accept-fungible-proposal-modal__inputs-container">
            <!--  Collateral -->
            <SelectAssetInput
              :label="'Your Collateral'"
              :form="form"
              is-check-user-balance
              asset-field-name="collateralAsset"
              amount-field-name="collateralAmount"
              :amount-schema="collateralAmountSchema"
              modal-type="your-assets"
              appraisal-field-name="collateralAppraisal"
              is-asset-locked
              :max-amount="String(maxCollateralAmount)"
              :selected-asset="collateral"
              max-button-as-text-max
              @blur="isFocusedCollateralInput = false"
              @focus="isFocusedCollateralInput = true"
              @on-amount-change="(amount, isMaxAmountChange) => handleCollateralAmountChange(amount, isMaxAmountChange)"/>

            <!--  Credit -->
            <SelectAssetInput
              :label="'You will get'"
              :form="form"
              asset-field-name="creditAsset"
              amount-field-name="creditAmount"
              :amount-schema="creditAmountSchema"
              hide-max-button
              modal-type="your-assets"
              appraisal-field-name="creditAppraisal"
              is-disabled-input
              is-asset-locked
              no-wallet-icon
              is-fungible-proposal
              :selected-asset="fungibleProposalModalStore.proposalClass?.creditAsset"
              @blur="isFocusedCreditInput = false"
              @focus="isFocusedCreditInput = true"
              @on-amount-change="handleCreditAmountChange"/>
          </div>
          <div
            v-else
            class="accept-fungible-proposal-modal__inputs-container">
            <!--  Credit -->
            <SelectAssetInput
              :label="'You will lend'"
              :form="form"
              asset-field-name="creditAsset"
              amount-field-name="creditAmount"
              :amount-schema="creditAmountSchema"
              modal-type="your-assets"
              appraisal-field-name="creditAppraisal"
              is-check-user-balance
              is-asset-locked
              is-fungible-proposal
              max-button-as-text-max
              :max-amount="String(maxAvailableCreditAmountFormatted)"
              :selected-asset="creditAsset"
              @blur="isFocusedCreditInput = false"
              @focus="isFocusedCreditInput = true"
              @on-amount-change="(amount, isMaxAmountChange) => handleCreditAmountChange(amount, isMaxAmountChange)"/>

            <!--  Collateral -->
            <SelectAssetInput
              :label="'Collateral'"
              :form="form"
              :is-check-user-balance="isLendingProposal"
              :is-disabled-input="!isLendingProposal"
              asset-field-name="collateralAsset"
              amount-field-name="collateralAmount"
              :amount-schema="collateralAmountSchema"
              modal-type="your-assets"
              appraisal-field-name="collateralAppraisal"
              is-asset-locked
              hide-max-button
              :max-amount="String(maxCollateralAmount)"
              :selected-asset="collateral"
              @blur="isFocusedCollateralInput = false"
              @focus="isFocusedCollateralInput = true"
              @on-amount-change="handleCollateralAmountChange"/>
          </div>

          <form.Subscribe>
            <template #default="{ canSubmit }">
              <BaseButton
                class="accept-fungible-proposal-modal__button"
                is-type-submit
                is-full-width
                button-text="Continue"
                :is-disabled="!canSubmit"/>
            </template>
          </form.Subscribe>
        </form>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import ModalSize from '@/general-components/ModalSize'
import CloseIconSvg from '@/assets/icons/close.svg'
import { useAcceptFungibleProposalModalStore } from '@/revamp/components/modals/accept-fungible-proposal/useAcceptFungibleProposalModalStore'
import BaseButton from '@/general-components/BaseButton.vue'
import { useForm } from '@tanstack/vue-form'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { z } from 'zod'
import SelectAssetInput from '@/revamp/components/proposal-form/SelectAssetInput.vue'
import { formatAmountWithDecimals, getRawAmount } from '@/utils/utils'
import { computed, ref, watch } from 'vue'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useStartLoanModalStore } from '@/revamp/components/actions/useStartLoanModalStore'
import { storeToRefs } from 'pinia'
import { parseUnits } from 'viem'
import { calculateMinCreditAmount } from '@/modules/common/pwn/utils'
import Decimal from 'decimal.js'

const { address: userAddress } = useCustomAccount()
const fungibleProposalModalStore = useAcceptFungibleProposalModalStore()
const { proposalClass } = storeToRefs(fungibleProposalModalStore)
const startLoanModalStore = useStartLoanModalStore()

const walletCollateralBalance = ref<string>()
const walletCreditAssetBalance = ref<string>()
const isFocusedCollateralInput = ref(false)
const isFocusedCreditInput = ref(false)

const minCollateralAmount = computed(() => formatAmountWithDecimals(BigInt(proposalClass.value?.minCollateralAmount ?? 0n), proposalClass.value?.collateral.decimals ?? 0))

const maxCollateralAmount = computed(() => formatAmountWithDecimals(BigInt(proposalClass.value?.collateralAmount ?? 0n), proposalClass.value?.collateral.decimals ?? 0))

const minCreditAmount = computed(() => {
  if (!proposalClass.value) {
    return undefined
  }
  return calculateMinCreditAmount(proposalClass.value?.minCollateralAmount, proposalClass.value?.creditPerCollateralUnit)
})
const minCreditAmountFormatted = computed(() => {
  if (!minCreditAmount.value) {
    return '0'
  }

  return formatAmountWithDecimals(minCreditAmount.value, proposalClass.value?.creditAsset.decimals ?? 0)
})
const maxCreditAmount = computed(() => formatAmountWithDecimals(BigInt(proposalClass.value?.creditAmount ?? 0n), proposalClass.value?.creditAsset.decimals ?? 0))

const maxAvailableCreditAmount = computed(() => {
  const creditAssetDecimals = proposalClass.value?.creditAsset.decimals ?? 18
  const maxCreditAmountBigInt = BigInt(proposalClass.value?.creditAmount ?? 0n)
  const userCreditAssetBalanceBigInt = parseUnits(walletCreditAssetBalance.value ?? '0', creditAssetDecimals)
  return userCreditAssetBalanceBigInt <= maxCreditAmountBigInt ? userCreditAssetBalanceBigInt : maxCreditAmountBigInt
})

const maxAvailableCreditAmountFormatted = computed(() => {
  return formatAmountWithDecimals(maxAvailableCreditAmount.value, proposalClass.value?.creditAsset.decimals ?? 18)
})

const collateralAmountSchema = computed(() => {
  if (!proposalClass.value?.isOffer) {
    // not necessary to perform a validation since the field is disabled
    return z.coerce.number()
  }

  return z.coerce
    .string()
    .regex(/^\d+(\.\d+)?$/, { message: 'Collateral amount must be a number' })
    .transform((value) => Number(value))
    .refine((value) => Number.isFinite(value), { message: 'Collateral amount must be finite' }) // Ensure value is finite
    .refine((value) => value > 0, { message: 'Collateral value must exceed $0' }) // Ensure value is positive
    .refine((value) => value >= +minCollateralAmount.value, { message: `Minimum Collateral amount is ${minCollateralAmount.value}` })
    .refine((value) => value <= +maxCollateralAmount.value, { message: `Maximum Collateral amount is ${maxCollateralAmount.value}` })
})

const creditAmountSchema = computed(() => {
  if (proposalClass.value?.isOffer) {
    // not necessary to perform a validation since the field is disabled
    return z.coerce.number()
  }

  let schema = z.coerce
    .number({
      message: 'Lent amount must be a number',
    })
    .positive({ message: 'Lent amount must exceed $0' })

  if (!proposalClass.value?.isOffer) {
    // display those errors only when acceptor is inputting credit amount
    // @ts-expect-error not sure why it errors but it works alright
    schema = schema
      .refine((value) => value >= +minCreditAmountFormatted.value, { message: `Minimum Lent amount is ${minCreditAmountFormatted.value}` })
      .refine((value) => value <= +maxCreditAmount.value, { message: `Maximum possible lent amount is ${maxCreditAmount.value}` })
  }
  return schema
})

const fullZodSchema = computed(() => {
  if (proposalClass.value?.isOffer) {
    return z.object({
      collateralAmount: collateralAmountSchema.value,
    })
  } else {
    return z.object({
      creditAmount: creditAmountSchema.value,
    })
  }
})

const form = useForm({
  defaultValues: {
    collateralAmount: '',
    creditAmount: '',
  },
  onSubmit: ({ value }: { value }) => {
    if (!fungibleProposalModalStore.proposalClass) return
    fungibleProposalModalStore.proposalClass.collateralAmountToAccept = getRawAmount(value.collateralAmount, fungibleProposalModalStore.proposalClass.collateral.decimals)
    fungibleProposalModalStore.proposalClass.creditAmountToAccept = getRawAmount(value.creditAmount, fungibleProposalModalStore.proposalClass.creditAsset.decimals)
    fungibleProposalModalStore.collateralAmount = value.collateralAmount
    fungibleProposalModalStore.close()
    startLoanModalStore.open()
  },
  validatorAdapter: zodValidator(),
  validators: {
    onChange: fullZodSchema.value,
  },
})

const isLendingProposal = computed(() => proposalClass.value?.isOffer)

const collateral = computed(() => {
  if (!fungibleProposalModalStore.proposalClass) return null
  return new AssetWithAmount({
    ...fungibleProposalModalStore.proposalClass.collateral,
    amount: walletCollateralBalance.value,
  })
})

const creditAsset = computed(() => {
  if (!fungibleProposalModalStore.proposalClass) return null
  return new AssetWithAmount({
    ...fungibleProposalModalStore.proposalClass.creditAsset,
    amount: walletCreditAssetBalance.value,
  })
})

watch([() => fungibleProposalModalStore.proposalClass?.collateral.address, () => userAddress.value, () => isLendingProposal.value], async () => {
  if (!fungibleProposalModalStore.proposalClass || !userAddress.value || !isLendingProposal.value) return
  const collateralBalance = await useERC20Fetch().fetchUserERC20Balance(
    fungibleProposalModalStore.proposalClass.chainId,
    fungibleProposalModalStore.proposalClass.collateral.address,
    userAddress.value,
  )

  walletCollateralBalance.value = formatAmountWithDecimals(collateralBalance ?? 0n, fungibleProposalModalStore.proposalClass.collateral.decimals)
}, { immediate: true })

watch([() => fungibleProposalModalStore.proposalClass?.creditAsset.address, () => userAddress.value, () => isLendingProposal.value], async () => {
  if (!fungibleProposalModalStore.proposalClass || !userAddress.value || isLendingProposal.value) return
  const creditAssetBalance = await useERC20Fetch().fetchUserERC20Balance(
    fungibleProposalModalStore.proposalClass.chainId,
    fungibleProposalModalStore.proposalClass.creditAsset.address,
    userAddress.value,
  )

  walletCreditAssetBalance.value = formatAmountWithDecimals(creditAssetBalance ?? 0n, fungibleProposalModalStore.proposalClass.creditAsset.decimals)
}, { immediate: true })

const handleCollateralAmountChange = (amount: string, skipValidation?: boolean) => {
  if (!fungibleProposalModalStore.proposalClass) return
  amount = amount === '' ? '0' : amount
  const maxCreditAmount = new Decimal(fungibleProposalModalStore.proposalClass.creditAsset.amount)
  const maxCollateralAmount = new Decimal(fungibleProposalModalStore.proposalClass.collateral.amount)
  const amountDecimal = new Decimal(amount)
  const creditAmount = amountDecimal.times(maxCreditAmount).div(maxCollateralAmount)
  if (isFocusedCollateralInput.value || skipValidation) {
    form.setFieldValue('creditAmount', creditAmount.toString())
  }
  form.validateField('creditAmount', 'change')
}

const handleCreditAmountChange = (amount: string, skipValidation?: boolean) => {
  if (!fungibleProposalModalStore.proposalClass) return
  amount = amount === '' ? '0' : amount
  const maxCreditAmount = new Decimal(fungibleProposalModalStore.proposalClass.creditAsset.amount)
  const maxCollateralAmount = new Decimal(fungibleProposalModalStore.proposalClass.collateral.amount)
  const amountDecimal = new Decimal(amount)
  const collateralAmount = amountDecimal.times(maxCollateralAmount).div(maxCreditAmount)
  if (isFocusedCreditInput.value || skipValidation) {
    form.setFieldValue('collateralAmount', collateralAmount.toString())
  }
  form.validateField('collateralAmount', 'change')
}

watch([() => fungibleProposalModalStore.isOpen, walletCollateralBalance, maxCollateralAmount], (values) => {
  const [newIsOpen, collateralBalance, maxCollAmount] = values
  if (collateralBalance === '-') return
  if (!newIsOpen || !fungibleProposalModalStore.proposalClass || !collateralBalance || !maxCollAmount || !isLendingProposal.value) return

  if (+maxCollAmount < +collateralBalance) {
    form.setFieldValue('collateralAmount', String(maxCollAmount))
    handleCollateralAmountChange(String(maxCollAmount), true)
  } else {
    form.setFieldValue('collateralAmount', String(collateralBalance))
    handleCollateralAmountChange(String(collateralBalance), true)
  }
}, { immediate: true })

watch([() => fungibleProposalModalStore.isOpen, walletCreditAssetBalance, maxCreditAmount], (values) => {
  const [newIsOpen, creditAssetBalance, maxCreditAmount] = values
  if (creditAssetBalance === '-') return
  if (!newIsOpen || !fungibleProposalModalStore.proposalClass || !creditAssetBalance || !maxCreditAmount || isLendingProposal.value) return

  if (+maxCreditAmount < +creditAssetBalance) {
    form.setFieldValue('creditAmount', String(maxCreditAmount))
    handleCreditAmountChange(String(maxCreditAmount), true)
  } else {
    form.setFieldValue('creditAmount', String(creditAssetBalance))
    handleCreditAmountChange(String(creditAssetBalance), true)
  }
})
</script>

<style scoped>
.accept-fungible-proposal-modal {
  &__title {
    font-size: 24px;
    font-family: var(--font-family-screener);
    width: 100%;
    font-weight: 400;
  }

  &__body {
    width: 520px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__close {
    width: 30px;
    height: 30px;
    margin-left: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__no-matching-assets {
    font-family: var(--font-family-screener);
    font-size: 24px;
    text-align: center;
    margin: 4rem 0 5rem;
  }

  &__breadcrumbs-and-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.2rem;
  }

  &__breadcrumb-select-collateral {
    color: var(--primary-color-1);
    font-family: var(--font-family-supreme);
  }

  &__breadcrumb-loan-preview {
    color: var(--separation-grey, #828282);
    font-family: var(--font-family-supreme);
  }

  &__content {
    margin-top: 1.5rem;
  }

  &__header {
    display: flex;
    position: relative;
  }

  &__search {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  &__button {
    margin-left: auto;
    width: 206px;
  }
}

.search-bar {
  width: 100%;
}
</style>
