<template>
  <ChainIcon
    class="chain-icon"
    :class="`chain-icon__network--${getChainName(chainId).toLowerCase()}`"/>
</template>

<script lang="ts" setup>
import type { SupportedChain } from '@/constants/chains/types'
import { computed } from 'vue'
import { getChainName, getMonoChromeChainIcon } from '@/utils/chain'

type Props = {
  chainId: SupportedChain
}

const props = defineProps<Props>()

const ChainIcon = computed(() => getMonoChromeChainIcon(props.chainId))

</script>

<style scoped>
.chain-icon {
  width: 1.5rem;
  height: 1.5rem;
  object-position: center;
  padding: 0.1rem;

  background: rgb(var(--background-color-base) / 80%);
  border: 0.09rem solid;
  border-radius: 50%;

  &__network {
    &--polygon {
      border-color: #a281ff;
    }

    &--arbitrum {
      border-color: #28a0f0;
    }

    &--optimism {
      border-color: #fe3033;
    }

    &--base {
      border-color: #1a54f4;
    }

    &--cronos {
      border-color: #1199fa;
    }

    &--bsc {
      border-color: #f0b90b;
    }

    &--ethereum {
      border-color: var(--primary-color-1);
    }
  }
}
</style>
