// @see https://stackoverflow.com/a/76176570
export const typeSafeObjectFromEntries = <
  const T extends ReadonlyArray<readonly [PropertyKey, unknown]>
>(
    entries: T,
  ): { [K in T[number] as K[0]]: K[1] } => {
  return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] }
}

export const typeSafeObjectKeys = <const T extends object>(obj: T) => {
  return Object.keys(obj) as Array<keyof T>
}

export const typeSafeObjectValues = <const T extends object>(obj: T) => {
  return Object.values(obj) as Array<T>
}
