<template>
  <BaseTable
    class="featured-collateral-table"
    :columns="TABLE_DEFINITION"
    :items="assets ?? []"
    :has-first-column-padding="false"
    td-padding="0.625rem 0.5rem"
    @on-row-click="goToCollectionDetail">
    <template #name="{ item: contract }">
      <div class="featured-collateral-table__contract-detail">
        <div class="featured-collateral-table__image-wrapper">
          <TokenMedia
            width="40"
            height="40"
            class="featured-collateral-table__image"
            :token="contract"/>
          <component
            :is="getMonoChromeChainIcon(contract.chainId)"
            class="featured-collateral-table__chain-icon"
            alt="chain icon"/>
        </div>

        <span class="featured-collateral-table__contract-name">{{ contract.name }}</span>
        <VerificationBadge
          :token="contract"
          :display-text="false"/>
      </div>
    </template>

    <template #action="{ item: contract }">
      <BaseButton
        class="featured-collateral-table__lend-button"
        button-text="Lend"
        @on-button-click="goToCollectionMakeOffer(contract)"/>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import BaseButton from '@/general-components/BaseButton.vue'
import BaseTable from '@/general-components/BaseTable.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import VerificationBadge from '@/general-components/VerificationBadge.vue'
import type { AssetContract } from '@/modules/common/assets/AssetClasses'
import { pwnSpaceConfig } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import TABLE_DEFINITION from '@/modules/pages/pwn-explorer/FeaturedCollateralTableDefinition'
import RouteName from '@/router/RouteName'
import { useRouter } from 'vue-router'
import type { RouteLocationRaw } from 'vue-router'
import { getMonoChromeChainIcon } from '@/utils/chain'
import { computed, toRefs } from 'vue'

const router = useRouter()

interface Props {
  isNft?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isNft: true,
})
const { isNft } = toRefs(props)

const assets = computed(() => isNft.value
  ? pwnSpaceConfig?.allowedContracts.filter((contract) => contract.isNft)
  : pwnSpaceConfig?.allowedContracts.filter((contract) => !contract.isNft))

const goToCollectionMakeOffer = async (contract: AssetContract) => {
  const route: RouteLocationRaw = {
    name: contract.isNft ? RouteName.CollectionMakeOfferByContractAddress : RouteName.TokenPage,
    params: {
      chainName: CHAINS_CONSTANTS[contract.chainId].general.chainName,
      contractAddress: contract.address,
    },
  }
  await router.push(route)
}

const goToCollectionDetail = async (contract: AssetContract) => {
  const route: RouteLocationRaw = {
    name: contract.isNft ? RouteName.CollectionByContractAddress : RouteName.TokenPage,
    params: {
      chainName: CHAINS_CONSTANTS[contract.chainId].general.chainName,
      contractAddress: contract.address,
    },
  }
  await router.push(route)
}
</script>

<style scoped>
.featured-collateral-table {
  &__contract-detail {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }

  &__image-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    display: inline-block;
  }

  &__image {
    width: 2.5rem;
    height: 2.5rem;
  }

  &__chain-icon {
    position: absolute;
    right: -0.5rem;
    bottom: -0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    box-shadow: inset 0 1px 16px 0 var(--background-color);
    padding: 0.15rem;
    box-sizing: content-box;
    border-radius: 50%;
    border: 1px solid var(--text-color);
  }

  &__contract-name {
    font-family: var(--font-family-autoscape);
    font-size: 0.875rem;
  }

  &__lend-button {
    display: flex;
    justify-content: end;
  }

  @media only screen and (--small-viewport) {
    max-width: 100%;
    overflow: auto hidden;
    padding-bottom: 0.5rem;
  }
}
</style>
