import type { Hex } from 'viem'

const CONTRACT_WALLET_SIGNATURE_STORED_ON_BE = '0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
// fixing different signature format from Ledger hardware wallet
// for more in depth explanation https://medium.com/mycrypto/the-magic-of-digital-signatures-on-ethereum-98fe184dc9c7
// solution is based on https://github.com/poap-xyz/poap-fun/pull/62
export default function fixSignatureIfInvalid(signature: Hex): Hex {
  let v = parseInt(signature.substring(130, 132), 16)

  if (CONTRACT_WALLET_SIGNATURE_STORED_ON_BE === signature) return '0x'
  if (v === 0 || v === 1) {
    v = v + 27
    const fixedV = Number(v).toString(16)
    const fixedSignature = signature.slice(0, -2) + fixedV as Hex
    return fixedSignature
  } else {
    return signature
  }
}
