import type { ColumnProperties } from '@/general-components/ColumnProperties'
import {
  sortAssetsByAmount,
  sortAssetsByPrice,
  sortAssetsBySymbol,
} from '@/general-components/sorting/SortFunctions'

export enum SortOption {
  Symbol = 'symbol',
  Amount = 'amount',
  Price = 'price',
  Name = 'name'
}

export const SORT_OPTIONS_LOOKUP = {
  [SortOption.Symbol]: sortAssetsBySymbol,
  [SortOption.Amount]: sortAssetsByAmount,
  [SortOption.Price]: sortAssetsByPrice,
  [SortOption.Name]: sortAssetsBySymbol,
}

export const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Asset',
    width: '20%',
    slotName: 'asset-info',
    sortOptionName: SortOption.Symbol,
  },
  {
    title: 'Platform',
    width: '15%',
    slotName: 'platform',
    sortOptionName: SortOption.Price,
  },
  {
    title: 'Price',
    width: '20%',
    slotName: 'price',
    sortOptionName: SortOption.Price,
  },
  {
    title: 'Supplied',
    width: '15%',
    slotName: 'amount',
    sortOptionName: SortOption.Amount,
  },
  {
    title: 'Current APY',
    width: '10%',
    slotName: 'current-apy',
  },
  {
    title: 'Health Factor',
    width: '10%',
    slotName: 'health-factor',
  },
  {
    title: '',
    width: '10%',
    slotName: 'action',
    align: 'right',
  },
]
