<template>
  <BaseTooltip
    v-if="isInNotification"
    class="repay-loan-button__only-text-tooltip"
    :has-tooltip="!!tooltipText"
    @mouseenter="handleMouseEnter">
    <template #trigger>
      <button
        :disabled="isButtonDisabled"
        :class="['repay-loan-button__only-text', { 'repay-loan-button__only-text--disabled': isButtonDisabled }]"
        @click="() => handleRepayLoan()">
        Repay Loan
      </button>
    </template>
    <div>
      {{ tooltipText }}
    </div>
  </BaseTooltip>
  <BaseTooltip
    v-else-if="!isInTable"
    class="repay-loan-button__tooltip"
    :has-tooltip="!!tooltipText"
    @mouseenter="handleMouseEnter">
    <template #trigger>
      <button
        :disabled="isButtonDisabled"
        class="repay-loan-button__button"
        @click="handleRepayLoan">
        <div class="repay-loan-button__button-text-and-rep-score">
          <span>
            Repay Loan
          </span>
          <RewardsByLoanId
            v-if="loanData?.onChainId && formattedCreditAmount && !isStarknet"
            :loan-id="loanData.onChainId"
            :loan-contract-address="loanTokenContractAddress"
            :credit-address="loanData.creditAsset.address as Address"
            :collateral-address="loanData.collateral.address as Address"
            :credit-amount="formattedCreditAmount"
            :apr="apr"
            :chain-id="loanData.chainId"/>
        </div>
        <AssetAmount
          v-if="props.loanData && userChecksAndAmountToRepay"
          class="repay-loan-button__token-and-appraisal"
          :asset="props.loanData.creditAsset"
          is-display-symbol
          :asset-amount="userChecksAndAmountToRepay?.assetToRepayAmountBigInt?.toString()"/>
      </button>
    </template>
    <div>
      {{ tooltipText }}
    </div>
  </BaseTooltip>
  <BaseTooltip
    v-else
    :has-tooltip="!!tooltipText"
    @mouseenter="handleMouseEnter">
    <template #trigger>
      <BaseButton
        :variant="ButtonVariant.Outline"
        is-no-enlarge-effect
        is-full-width
        class="repay-loan-button__button-in-table"
        :is-disabled="isButtonDisabled"
        :size="ButtonSize.M"
        button-text="Repay"
        @click.stop
        @on-button-click="() => handleRepayLoan()"/>
    </template>
    <div>
      {{ tooltipText }}
    </div>
  </BaseTooltip>
</template>

<script setup lang="ts">
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import { computed, ref } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { formatUnits, maxUint256 } from 'viem'
import type { Address } from 'viem'
import useApprove from '@/modules/common/assets/useApprove'
import AssetType from '@/modules/common/assets/AssetType'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import V1_2SimpleLoanContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanContract'
import { Toast, TOAST_ACTION_ID_TO_UNIQUE_ID_FN, ToastStep } from '@/modules/common/notifications/useToastsStore'
import {
  getLoanDetailQueryKey,
  getUserReputationScoreWithParametersRetriveQueryOptions,
  NotificationActionEnumBackendSchema,
} from '@/modules/common/backend/generated'
import type { LoanDetailSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import type { Ref } from 'vue'
import { pwnV1SimpleLoanAbi } from '@/contracts/generated'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import BaseButton, { ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import AssetAmount from '@/revamp/components/AssetAmount.vue'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import { compareAddresses } from '@/utils/utils'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import RewardsByLoanId from '@/revamp/features/points/RewardsByLoanId.vue'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { useApproveThesisAssets } from '@/revamp/hooks/thesis/useApproveThesisAssets'
import { getAnyLoanRepayment } from '@/modules/common/adapters/loan-utils'

type Props = {
  loanId: string | number
  chainIdWhereIsLoan: SupportedChain
  assetToRepayAddress: Address
  loanContractAddress: Address
  isInTable?: boolean
  isInNotification?: boolean
  loanData?: LoanDetailSchemaWorkaroundBackendSchema
}
const queryClient = useQueryClient()
const props = defineProps<Props>()
const loanId = computed(() => props.loanId)
const chainIdWhereIsLoan = computed(() => props.chainIdWhereIsLoan)
const assetToRepayAddress = computed(() => props.assetToRepayAddress)
const loanContractAddress = computed(() => props.loanContractAddress)
const loanTokenContractAddress = computed(() => CHAINS_CONSTANTS[chainIdWhereIsLoan.value]?.pwnV1_2Contracts?.pwnLoan || '0x missing contract address')

const { chainId: connectedChainId, address: userAddress } = useCustomAccount()
const { fetchUserERC20Balance } = useERC20Fetch()
const { isNeedRemovePreviousApproval, approve, getPreviousApprovalAmount } = useApprove()

const queryKeyLoanDetail = getLoanDetailQueryKey(String(chainIdWhereIsLoan.value), loanTokenContractAddress.value, String(loanId.value))
const isV1_1Loan = computed(() => compareAddresses(loanContractAddress.value, CHAINS_CONSTANTS[chainIdWhereIsLoan.value]?.pwnV1Contracts?.pwnSimpleLoan) && !isStarknet)

const updateRepScore = () => {
  if (!userAddress.value) return
  const keyToInvalidate = getUserReputationScoreWithParametersRetriveQueryOptions(userAddress.value, { refresh: true })

  queryClient.invalidateQueries(keyToInvalidate)
}

const { isPending: isPendingRepayAction, mutateAsync: repayLoanMutationAsync } = useMutation({
  mutationKey: ['repayLoanMutationAsync', loanId],
  mutationFn: async (step: ToastStep) => {
    // TODO test
    if (isV1_1Loan.value) {
      return await sendTransaction({
        abi: pwnV1SimpleLoanAbi,
        functionName: 'repayLOAN',
        args: [BigInt(loanId.value), '0x'],
        address: loanContractAddress.value,
        chainId: chainIdWhereIsLoan.value,
      }, { step })
    }

    const loanContract = new V1_2SimpleLoanContract()
    await loanContract.payBack({ onChainId: String(loanId.value), chainId: chainIdWhereIsLoan.value, step })
    await queryClient.refetchQueries({ queryKey: queryKeyLoanDetail })
  },
  onSuccess: () => {
    updateRepScore()
  },
})

const apr = computed(() => {
  if (!props.loanData?.creditData.accruingInterestApr) return 'N/A'
  return (props.loanData.creditData.accruingInterestApr * 10 ** -APR_DECIMAL_POINT_PADDING).toFixed(0) + '%'
})

// handle errors
const checksQuery = useQuery({
  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  queryKey: ['repayLoanApprovalAndBalanceCheck', loanId, userAddress, chainIdWhereIsLoan, loanContractAddress],
  queryFn: async () => {
    if (!userAddress.value || !chainIdWhereIsLoan.value || !loanContractAddress.value) return null

    const assetToRepayAmountBigInt = isV1_1Loan.value
      ? BigInt(props.loanData?.creditData?.totalRepaymentAmount ?? 0)
      : await getAnyLoanRepayment(BigInt(loanId.value), loanContractAddress.value, chainIdWhereIsLoan.value)

    const userBalancePromise = await fetchUserERC20Balance(
      chainIdWhereIsLoan.value,
      assetToRepayAddress.value,
      userAddress.value,
    )

    const isApprovedPromise = await useApprove().isApproved(
      chainIdWhereIsLoan.value,
      AssetType.ERC20,
      assetToRepayAddress.value,
      userAddress.value,
      loanContractAddress.value,
      assetToRepayAmountBigInt,
    )
    const [userBalance, isApproved] = await Promise.all([userBalancePromise, isApprovedPromise])
    const hasEnoughBalance = userBalance >= assetToRepayAmountBigInt

    return {
      hasEnoughBalance,
      isApproved,
      assetToRepayAmountBigInt,
    }
  },
  staleTime: 1000 * 60, // 1 minute
  refetchOnWindowFocus: true,
})

const { data: userChecksAndAmountToRepay, isPending } = checksQuery

const isButtonDisabled = computed(() => {
  return !userAddress.value || !userChecksAndAmountToRepay.value?.hasEnoughBalance || isPendingRepayAction.value
})

const tooltipText = computed(() => {
  if (!userAddress.value) return 'Connect wallet'
  if (!userChecksAndAmountToRepay.value?.hasEnoughBalance && !isPending.value) return 'Not enough balance'
  return ''
})

const handleMouseEnter = async () => {
  await checksQuery.refetch()
}

const handleRepayLoan = async () => {
  let continueFlow: () => Promise<void>
  const toast = ref<Toast>()
  await checksQuery.refetch()

  const getCreateLoanToastSteps = () => {
    const toastSteps: ToastStep[] = []

    if (connectedChainId.value !== chainIdWhereIsLoan.value && !isStarknet) {
      toastSteps.push(ToastStep.createSwitchChainStep(chainIdWhereIsLoan.value))
    }

    if (!userChecksAndAmountToRepay.value?.isApproved) {
      const assetToCheck = new AssetMetadata({
        address: assetToRepayAddress.value,
        chainId: chainIdWhereIsLoan.value,
        category: AssetType.ERC20,
      })

      if (!assetToCheck || !loanContractAddress.value) {
        throw new Error('Asset to check or loan contract address is not defined')
      }

      toastSteps.push(new ToastStep({
        text: 'Checking approval...',
        async fn(step) {
          if (!userAddress.value) {
            return false
          }

          const amountToRepay = userChecksAndAmountToRepay.value?.assetToRepayAmountBigInt
          if (amountToRepay === undefined) {
            return false
          }
          const existingAllowance = await getPreviousApprovalAmount(
            chainIdWhereIsLoan.value,
            assetToCheck.address,
            assetToCheck.category,
            loanContractAddress.value,
            userAddress.value,
          )

          if (existingAllowance !== null) {
            if (existingAllowance >= amountToRepay) {
              return true
            }

            if (existingAllowance > 0n && isNeedRemovePreviousApproval(
              chainIdWhereIsLoan.value,
              assetToCheck.address,
              assetToCheck.category,
            )) {
              step.text = 'Removing previous approval...'

              const isApproved = await approve({
                asset: assetToCheck,
                amount: 0n,
                spender: loanContractAddress.value,
              }, step)
              return Boolean(isApproved)
            }
          }
          return true
        },
      }))

      if (isStarknet) {
        toastSteps.push(...useApproveThesisAssets().approveThesisAssets([assetToCheck], loanContractAddress.value, chainIdWhereIsLoan.value))
      } else {
        toastSteps.push(new ToastStep({
          text: 'Approve Asset',
          async fn(step) {
            // TODO test this
            return await useApprove().approve({
              asset: assetToCheck,
              spender: loanContractAddress.value,
              amount: maxUint256,
              minAllowanceAmountToCheck: userChecksAndAmountToRepay.value?.assetToRepayAmountBigInt,
            })
          },
        }))
      }
    }

    toastSteps.push(new ToastStep({
      text: 'Repay Loan',
      async fn(step) {
        await repayLoanMutationAsync(step)
        return true
      },
    }))

    return toastSteps
  }

  const handleRepayLoan = async () => {
    const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationActionEnumBackendSchema.LOAN_PAID_BACK](String(loanId.value))
    if (toast.value?.id !== actionId) {
      toast.value = new Toast({
        title: `Repay Loan #${loanId.value}`,
        chainId: chainIdWhereIsLoan.value,
        steps: getCreateLoanToastSteps(),
        // firstAsset: proposal.collateral,
      }, NotificationActionEnumBackendSchema.LOAN_PAID_BACK, String(loanId.value));
      ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
    }

    await continueFlow()
  }

  await handleRepayLoan()
  // invalidate and re-fetch queries are handled by sseListener
}

const formattedCreditAmount = computed(() => {
  if (!props.loanData?.creditData.totalRepaymentAmount) return undefined
  return formatUnits(BigInt(props.loanData.creditData.totalRepaymentAmount), props.loanData.creditAsset.decimals ?? 18)
})

</script>

<style scoped>
.repay-loan-button {
  &__button {
    cursor: pointer;
    height: 64px;
    width: 381px;
    border: 1px solid var(--teal-1, #00ffe0);
    background: var(--primary-color-5, #0f2926);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    color: var(--primary-color-1);

    &:disabled {
      opacity: 0.5;
    }

    &:hover:not(:disabled) {
      background: var(--primary-color-3, #0f2926);
    }
  }

  &__button-in-table {
    margin-right: 0.3rem;
  }

  &__token-and-appraisal {
    text-align: end;
  }

  &__token {
    color: var(--text-color);
    gap: 0.5rem;
    font-size: 14px;
    font-weight: 400;
  }

  &__tooltip {
    height: 4rem;
  }

  &__appraisal {
    color: var(--gray);
    font-size: 12px;
    font-weight: 400;
    text-align: end;
  }

  &__button-text-and-rep-score {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.2rem;
  }

  &__only-text {
    all: unset;
    color: var(--primary-color-1);
    font-family: var(--font-family-supreme);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
    margin: 0.2rem 0 0.5rem;
    cursor: pointer;
    width: fit-content;

    &:hover:not(:disabled) {
      opacity: 0.7;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__only-text-tooltip {
    width: fit-content;
  }
}

</style>
