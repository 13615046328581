<template>
  <div
    ref="globalFiltersWrapperRef"
    class="global-filters__wrapper">
    <div
      :class="['global-filters__icon-wrapper', {'global-filters__icon-wrapper--opened': isDropdownOpened, 'global-filters__icon-wrapper--active': isAnyGlobalFilterEnabled}]"
      @click.stop="toggleDropdown">
      <FilterIcon/>
      <slot name="appendOnRight"/>
    </div>

    <Transition name="global-filters__dropdown">
      <div
        v-show="isDropdownOpened"
        :class="['global-filters__body-wrapper', `global-filters__body-wrapper--${props.xDirection}`, `global-filters__body-wrapper--${props.yDirection}`]"
        @click.stop>
        <div class="global-filters__checkbox-with-label-wrapper">
          <BaseCheckbox
            v-model="showUnverifiedAssets"
            input-name="showUnverifiedAssetsCheckbox"
            label="Show Unverified Assets"
            :wrap-label-text="false"
            :variant="CheckboxVariant.White"/>
          <QuestionMarkTooltip tooltip-text="PWN uses third-party sources and an internal process to verify asset addresses, ensuring you interact with legitimate assets. If you can't find your asset, you can show unverified ones."/>
        </div>
        <div
          v-if="props.displayPricingFilter"
          class="global-filters__checkbox-with-label-wrapper">
          <BaseCheckbox
            v-model="showAssetsWithoutPricing"
            input-name="showAssetsWithoutPricingCheckbox"
            label="Show Assets Without Pricing"
            :wrap-label-text="false"
            :variant="CheckboxVariant.White"/>
          <QuestionMarkTooltip tooltip-text="PWN uses third-party sources for asset pricing, but some assets may not have pricing. If you can't find your asset, you can choose to see these unpriced assets."/>
        </div>
        <div
          v-if="props.displayAmountFilter"
          class="global-filters__checkbox-with-label-wrapper">
          <BaseCheckbox
            v-model="showAssetsWithLesserAmount"
            input-name="showAssetsWithoutPricingCheckbox"
            label="Show assets with a value below $5"
            :wrap-label-text="false"
            :variant="CheckboxVariant.White"/>
          <QuestionMarkTooltip tooltip-text="Only assets with a total value above $5 are displayed to ensure higher-value loans."/>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import FilterIcon from '@/assets/icons/filter.svg'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import { useGlobalFiltersStore } from './useGlobalFiltersStore'
import { storeToRefs } from 'pinia'
import CheckboxVariant from '@/general-components/CheckboxVariant'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

interface Props {
  xDirection: 'left' | 'right'
  yDirection: 'top' | 'bottom'
  displayPricingFilter?: boolean
  displayAmountFilter?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  displayPricingFilter: true,
  displayAmountFilter: true,
})

const globalFiltersStore = useGlobalFiltersStore()
const { showAssetsWithoutPricing, showUnverifiedAssets, isAnyGlobalFilterEnabled, showAssetsWithLesserAmount } = storeToRefs(globalFiltersStore)

const isDropdownOpened = ref(false)
const globalFiltersWrapperRef = ref(null)

const hideDropdown = () => {
  isDropdownOpened.value = false
}

onClickOutside(globalFiltersWrapperRef, hideDropdown)

const toggleDropdown = () => {
  isDropdownOpened.value = !isDropdownOpened.value
}
</script>

<style scoped lang="postcss">
.global-filters {
  &__wrapper {
    position: relative;
  }

  &__icon-wrapper {
    position: relative;
    border: 1px solid var(--gray-2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    min-width: 2rem;
    padding: 0 0.25rem;

    &:hover {
      border-color: var(--primary-color-1);
      cursor: pointer;
    }

    &--opened {
      border-color: var(--primary-color-1);
    }

    &--active {
      border-color: var(--primary-color-1);
    }
  }

  &__dropdown-enter-active,
  &__dropdown-leave-active {
    transition: opacity 0.5s ease;
  }

  &__dropdown-enter-from,
  &__dropdown-leave-to {
    opacity: 0;
  }

  &__body-wrapper {
    position: absolute;
    padding: 0.25rem;
    z-index: var(--z-index-tooltip);

    background-color: var(--background-color);
    border: 1px solid var(--separator-color);

    &--top {
      bottom: 3rem;
      top: auto;
    }

    &--bottom {
      top: 2.625rem;
      right: 0;
    }

    &--left {
      right: 0;
    }

    &--right {
      left: 0;
    }
  }

  &__checkbox-with-label-wrapper {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    padding: 1rem 0.75rem;
  }
}
</style>

<style lang="postcss">
.global-filters {
  &__icon-wrapper {
    &--active {
      svg {
        /* stylelint-disable-next-line max-nesting-depth */
        path {
          fill: var(--primary-color-1);
        }
      }
    }
  }
}
</style>
