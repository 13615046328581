<template>
  <div class="asset-section">
    <div
      :class="['asset-section__header', {
        'asset-section__header--fixed': isFixedExpanded,
        'asset-section__header--no-data': !hasData
      }]"
      @click="toggleExpand()">
      <!-- TODO is this v-if="iconComponent" okay? -->
      <component
        :is="iconComponent"
        v-if="iconComponent"
        :alt="icon"
        height="16"/>
      <div class="asset-section__header-label">
        {{ props.label }}
      </div>
      <span class="asset-section__question-mark-tooltip">
        <QuestionMarkTooltip
          v-if="questionMarkTooltip"
          :tooltip-text="questionMarkTooltip"/>
      </span>
      <ExpandIcon
        v-if="!shouldRemainFolded"
        :class="['asset-section__expand-icon', { 'asset-section__expand-icon--expanded': isExpanded }]"
        alt="toggle expand"/>
      <div
        v-else
        class="asset-section__header-no-data">
        No data
      </div>
    </div>

    <div v-show="isExpanded">
      <div class="asset-section__content-wrap">
        <BaseSkeletor v-if="props.isFetching"/>
        <slot
          v-else-if="!shouldRemainFolded"
          name="content"/>
        <div
          v-else-if="!hasData"
          class="asset-section__no-data">
          No data
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, toRefs, watch } from 'vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import ExpandIcon from '@/assets/icons/icon_expand.svg'

type IconTypes =
  | 'details'
  | 'collection'
  | 'listings'
  | 'stats'
  | 'activity'
  | 'collateral'
  | 'offers'
  | 'chart'
  | 'properties'
  | 'bundler'
  | 'cart';

interface Props {
  icon: IconTypes;
  label: string;
  isFetching?: boolean;
  questionMarkTooltip?: string;
  hasData?: boolean;
  isFixedExpanded?: boolean;
  isAlwaysOpen?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hasData: false,
  isFixedExpanded: false,
  isAlwaysOpen: false,
})
const { hasData, isFetching, isFixedExpanded, icon, isAlwaysOpen } = toRefs(props)

// dynamic svg icon component import is taken from here: https://github.com/jpkleemans/vite-svg-loader/issues/85#issuecomment-1706000543
const iconMapping = computed(() => import.meta.glob('/src/assets/icons/*.svg'))
const iconComponent = computed(() => {
  const iconPath = `/src/assets/icons/${props.icon}.svg`
  if (!iconMapping.value[iconPath]) {
    return null
  }
  // @ts-expect-error FIXME: strictNullChecks
  return defineAsyncComponent(() => iconMapping.value[iconPath]())
})

const isExpanded = ref(isFixedExpanded.value || hasData.value || isAlwaysOpen.value)
const wasAutomaticallyFolded = ref(false)

const shouldRemainFolded = computed(() => Boolean((isFixedExpanded.value || !hasData.value) && !isAlwaysOpen.value))

const toggleExpand = () => {
  if ((isFixedExpanded.value || !hasData.value) && !isAlwaysOpen.value) return
  isExpanded.value = !isExpanded.value
}

watch([hasData, isFetching, wasAutomaticallyFolded, isAlwaysOpen], ([_hasData, _isFetching, wasFolded, _alwaysOpen]) => {
  if (!_isFetching && !wasFolded) {
    if (_hasData || _alwaysOpen) {
      isExpanded.value = true
      wasAutomaticallyFolded.value = true
    }
  }
})
</script>

<style scoped>
.asset-section {
  border: 1px solid var(--gray);
  display: flex;
  flex-direction: column;

  &__content-wrap {
    position: relative;
  }

  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1.2rem 0.7rem;
    border-bottom: 1px solid var(--gray);
    font-family: var(--font-family-autoscape);
    font-size: 1.2rem;

    &--fixed {
      cursor: default;
    }

    &--no-data {
      cursor: default;
      border-bottom: 0;
    }
  }

  &__header-label {
    margin: 0 0 -0.15rem 0.7rem;

    font-family: var(--font-family-screener);
  }

  &__header-no-data {
    font-family: var(--font-family-screener);
    margin-left: auto;
    font-size: 1rem;
    color: var(--dark-separator-color);
    cursor: default;
  }

  &__expand-icon {
    transition: all var(--primary-transition-time);
    margin-left: auto;

    &--expanded {
      transform: rotate(180deg);
    }
  }

  &__question-mark-tooltip {
    margin-left: 0.5rem;
    line-height: 0;
  }

  &__no-data {
    display: grid;
    place-content: center;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media only screen and (--mobile-viewport) {
    &__content-wrap {
      overflow: auto hidden;
    }
  }
}
</style>
