import type { SupportedChain } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useApprove from '@/modules/common/assets/useApprove'
import type { Address } from 'viem'
import { readAssetTransferRightsGuardOperatorsFor } from '@/contracts/generated'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'

export default function useAtrGuardContract() {
  const { approve } = useApprove()

  const checkApprovals = async({ pwnSafeAddress, assetAddress, chainId }: {pwnSafeAddress: Address, assetAddress: Address, chainId: SupportedChain}) => {
    // is used proxy contract address "assetTransferRightsGuardProxy" with logic contract ABI "AssetTransferRightsGuard" info https://eips.ethereum.org/EIPS/eip-1967
    return await readAssetTransferRightsGuardOperatorsFor(pwnWagmiConfig, {
      address: CHAINS_CONSTANTS[chainId].pwnSafeContracts.assetTransferRightsGuardProxy,
      chainId,
      args: [pwnSafeAddress, assetAddress],
    })
  }

  const removeApproval = async ({ asset, spender, pwnSafeAddress }: {asset: AssetWithAmount, spender: Address, pwnSafeAddress: Address}): Promise<boolean> => {
    return await approve({
      asset,
      spender,
      amount: 0n,
      safeAddress: pwnSafeAddress,
    })
  }

  return {
    checkApprovals,
    removeApproval,
  }
}
