import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import { COLLECTION_SORT_OPTIONS_LOOKUP, CollectionSortOption } from '@/modules/pages/pwn-explorer/tabs/TableDefinitions'
import type { CollectionSearchResultWithStats } from '@/modules/pages/pwn-explorer/tabs/SortFunctions'
import type { CollectionSearchResult } from '@/modules/common/pwn/explorer/models/CollectionSearchResult'
import { useCollectionSearchStatsStore } from '@/modules/common/pwn/explorer/stats/useCollectionSearchStatsStore'
import { useSearchPwnExplorer } from '@/modules/common/pwn/explorer/useSearchStore'
import { ExplorerStores } from '@/modules/common/pwn/explorer/constants'

export const usePwnExplorerCollectionsTabStore = defineStore(ExplorerStores.CollectionsTabStore, () => {
  const explorerStore = useSearchPwnExplorer()
  const { results } = storeToRefs(explorerStore)
  const statsStore = useCollectionSearchStatsStore()
  const { isFetched } = storeToRefs(statsStore)

  const selectedSortOption = ref<ActiveSortOption>(loadSortOption(
    'sort-option-search-collections',
    {
      id: CollectionSortOption.TradingVolume,
      direction: SortDirection.Descending,
      viewName: 'sort-option-search-collections',
    },
    Object.keys(COLLECTION_SORT_OPTIONS_LOOKUP),
  ))

  const getSortedResults = (
    collections: CollectionSearchResultWithStats[],
  ) => {
    return COLLECTION_SORT_OPTIONS_LOOKUP[selectedSortOption.value.id](collections, selectedSortOption.value.direction)
  }

  const sortedResults = computed(() => {
    if ((isFetched.value && results?.value.collections) || !useSearchPwnExplorer().searchTerm) {
      return getSortedResults(
        // @ts-expect-error FIXME: strictNullChecks
        (results.value.collections ?? []).map(
          (collection: CollectionSearchResult) => {
            return Object.assign(collection, {
              stats: statsStore.actions.getCollectionStats(collection),
            })
          },
        ))
    }
    return results?.value?.collections || []
  })

  const isFetching = (collection: CollectionSearchResult) => {
    const collectionStats = statsStore.actions.getCollectionStats(collection)
    if (collectionStats !== null) {
      return false
    }

    return !isFetched.value
  }

  return {
    selectedSortOption,
    sortedResults,
    actions: {
      isFetching,
    },
  }
})
