import type { ColumnProperties } from '@/general-components/ColumnProperties'

export const ASSETS_TABLE_DEFINITIONS: ColumnProperties[] = [
  {
    title: 'Name',
    width: '45%',
    slotName: 'name',
  },
  {
    title: 'Appraisal',
    width: '35%',
    slotName: 'appraisal',
  },
  {
    title: '',
    width: '15%',
    slotName: 'purchaseOffer',
  },
  {
    title: '',
    width: '5%',
    slotName: 'actions',
  },
]
