<template>
  <BaseTable
    class="your-positions-table"
    :columns="tableDefinition"
    :items="assets ? assets : []"
    :variant="TableVariant.Assets"
    :is-fetching="isLoading"
    :has-first-column-padding="hasFirstColumnPadding"
    is-virtual
    :is-hoverable="false"
    :item-height="63"
    :virtual-table-overscan="3"
    :table-min-width="tableMinWidth"
    table-max-height="330px"
    display-loading-bottom>
    <template #asset-info="{ item: token }">
      <AssetInfo
        class="your-positions-table__asset-info"
        :asset="token"
        badge-size="small"
        :show-verification-badge="true"
        show-link
        :hide-invalid-asset-tooltip="hideInvalidAssetTooltip"/>
    </template>
    <template #price="{ item: token }">
      <AssetPrice
        :contract-address="token.address"
        :chain-id="token.chainId"
        :token-id="null"/>
    </template>
    <template #platform="{ item: token }">
      <component :is="getPlatformIcon(token)"/>
    </template>
    <template #amount="{ item: token }">
      <BasePriceLabel :amount="token.amount"/>
    </template>
    <template #current-apy="{ item: token }">
      <div>{{ token?.apy }}%</div>
    </template>
    <template #health-factor="{ item: token }">
      <div :class="['your-positions-table__health-factor', getHealthFactorClasses(token)]">
        {{ formatHealthFactor(token) }}
      </div>
    </template>
    <template #action="{ item: token }">
      <span
        class="link link--primary link--sm link--font-oxygen-mono your-positions-table__actions"
        @click="lendToken(token)">Lend</span>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">
import BaseTable from '@/general-components/BaseTable.vue'
import BasePriceLabel from '@/general-components/BasePriceLabel.vue'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import AssetInfo from '@/general-components/AssetInfo.vue'
import TableVariant from '@/general-components/TableVariant'
import { toRefs } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import AssetPrice from '@/revamp/components/prices/AssetPrice.vue'
import { useSelectedAssetsStore } from '@/revamp/hooks/useSelectedAssetsStore'
import { getPlatformIcon } from '@/utils/utils'

interface Props {
  tableDefinition: ColumnProperties[];
  hasFirstColumnPadding: boolean;
  isDashboard: boolean;
  tableMinWidth?: string;
  assets: AssetWithAmount[] | undefined;
  isLoading?: boolean;
  hideInvalidAssetTooltip?: boolean;
}

const props = defineProps<Props>()
const { tableDefinition, hasFirstColumnPadding, isLoading, hideInvalidAssetTooltip } = toRefs(props)
const selectedAssetsStore = useSelectedAssetsStore()

const lendToken = (asset: AssetWithAmount) => {
  selectedAssetsStore.actions.navigateWithSelectedCredit(asset)
}
const formatHealthFactor = (asset: AssetWithAmount) => {
  if (!asset.healthFactor || !Number(asset.healthFactor)) {
    return '---'
  }
  if (Number(asset.healthFactor) > 100) {
    return '>100'
  } else return asset.healthFactor
}

const getHealthFactorClasses = (asset: AssetWithAmount) => {
  if (!asset.healthFactor || !Number(asset.healthFactor)) {
    return ''
  }
  if (Number(asset.healthFactor) > 1.5) {
    return 'your-positions-table__health-factor--healthy'
  } else return 'your-positions-table__health-factor--unhealthy'
}
</script>

<style scoped>
.your-positions-table {
  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__health-factor {
    font-family: var(--font-family-supreme);

    &--healthy {
      color: #5aff3f;
    }

    &--unhealthy {
      color: var(--negative-1);
    }
  }

  @media only screen and (--small-viewport) {
    overflow: auto auto;
    padding-bottom: 2rem;
  }
}
</style>
