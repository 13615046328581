<template>
  <div class="proposal-interest">
    <span :class="['proposal-interest__value', {'proposal-interest__value--unavailable': !props.interest}]">{{ valueToDisplay }}</span>
    <BaseTooltip
      is-interactive>
      <template #trigger>
        <span
          v-if="isRepaymentShown"
          class="proposal-interest__total-repayment"> {{ formatDecimalPoint(repayment) }} {{ symbolToDisplay }} {{ isPrevVersion ? '' : `by day ${duration}` }}</span>
      </template>
      <template #default>
        <span class="proposal-interest__dimmed-text">net interest </span>
        <span>{{ getNetResultAmount }} </span>
        <span class="proposal-interest__dimmed-text"> by day {{ props.duration }}</span>
      </template>
    </BaseTooltip>
    <div
      v-if="creditAsset"
      class="proposal-interest__rep-points">
      <RewardsByAmount
        v-if="creditAmount"
        :amount-input="creditAmount"
        :credit-address="creditAsset.address as Address"
        :collateral-address="collateralAsset.address as Address"
        :apr="aprWithoutText"
        :chain-id="creditAsset.chainId"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { AssetDetail } from '@/revamp/modules/proposals/types'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import { formatDecimalPoint } from '@/utils/utils'
import { getProposalOrLoanType, ProposalOrLoanEnum } from '@/modules/common/pwn/utils'
import type { ProposalAndLoanTypes } from '@/modules/common/pwn/utils'
import type { Address } from 'viem'
import RewardsByAmount from '@/revamp/features/points/RewardsByAmount.vue'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import { useAssetPrice } from '@/revamp/hooks/asset-prices/useAssetPrice'

type Props = {
  interest: number | null;
  duration?: number;
  creditAsset: AssetDetail
  collateralAsset: AssetDetail
  totalRepayment?: string | null;
  totalRepaymentRaw?: string | null;
  type?: ProposalAndLoanTypes | null;
  proposalId?: string | number
  creditAmount?: string
};

const props = withDefaults(defineProps<Props>(), {
  interest: null,
})

const isPrevVersion = computed(() => {
  return getProposalOrLoanType(props.type) === ProposalOrLoanEnum.V1_1 ||
    getProposalOrLoanType(props.type) === ProposalOrLoanEnum.BETA
})

const repayment = computed(() => {
  if (props.totalRepayment) return props.totalRepayment
  if (props.totalRepaymentRaw) return Number(props.totalRepaymentRaw) / (10 ** (props.creditAsset?.decimals ?? 0))
  return 'N/A'
})

const aprDecimalPointPadding = computed(() => APR_DECIMAL_POINT_PADDING)

const symbolToDisplay = computed(() => {
  if (props.creditAsset.symbol === '$PWN-ALL-YOUR-STABLES') {
    return ''
  }

  return props.creditAsset?.symbol || ''
})

const valueToDisplay = computed(() => {
  if (!props.interest) return 'N/A'
  // moving decimal point x places to the left
  return (props.interest * 10 ** -aprDecimalPointPadding.value).toFixed(APR_DECIMAL_POINT_PADDING) + '% APR'
})

const aprWithoutText = computed(() => {
  if (!props.interest) return 'N/A'
  // moving decimal point x places to the left
  return (props.interest * 10 ** -aprDecimalPointPadding.value).toFixed(APR_DECIMAL_POINT_PADDING) + '%'
})

const isRepaymentShown = computed(() => props.duration && (props.totalRepayment || props.totalRepaymentRaw) && props.creditAsset)

const assetPrice = useAssetPrice(
  String(props.creditAsset?.chainId),
  props.creditAsset.address,
  String(props.creditAsset?.tokenId),
  props.creditAsset?.category,
)

const getNetResultAmount = computed(() => {
  const usdUnitPrice = assetPrice.priceData.value?.price?.usdAmount
  const pureInterestNum = Number(repayment.value) - Number(props.creditAmount)

  if (usdUnitPrice) {
    const usdProfit = pureInterestNum * +usdUnitPrice
    if (usdProfit > 0.01) {
      return `$${formatDecimalPoint(pureInterestNum * +usdUnitPrice, 2)}`
    } else {
      return `$${formatDecimalPoint(pureInterestNum * +usdUnitPrice)}`
    }
  }

  return `${formatDecimalPoint(pureInterestNum)} ${props.creditAsset?.symbol}`
})

</script>

<style lang="postcss" scoped>
.proposal-interest {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 0.1rem;

  &__value {
    font-size: 0.875rem;
    color: var(--text-color);

    &--unavailable {
      color: var(--gray-2);
    }
  }

  &__total-repayment {
    font-size: 0.75rem;
    color: var(--gray-2);
    cursor: help;
  }

  &__rep-points {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: help;
  }

  &__rep-points-icon {
    fill: var(--primary-color-1);
  }

  &__dimmed-text {
    color: var(--gray-2);
  }
}
</style>
