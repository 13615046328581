import { computed, readonly, ref } from 'vue'
import useSectionNFTEvents from '@/modules/pages/asset/nft-page/SectionNFTEvents/useSectionNFTEvents'
import type NFTEvent from '@/modules/common/assets/typings/NFTEvent'

enum PriceHistoryNoData {
  NO_HISTORY = 'No historical data',
  TOO_MANY_SALES = 'Large amount of sales',
  UNAVAILABLE = 'Data unavailable at this time'
}

export enum CurrencyType {
  ETH = 'WETH',
  USD = '$ USD'
}

const LOCAL_STORAGE_KEYS = {
  SHOW_BATCH_SALES: 'asset-page-batch-sales-in-chart',
  SELECTED_CURRENCY_IN_CHART: 'asset-page-selected-currency-in-chart',
}

const isLoadingPriceHistory = ref(false)
const priceHistoryMessageIfNoData = ref(PriceHistoryNoData.NO_HISTORY) // todo make sure that we set this value correctly
const hasCheckedIncludeBatchSales = ref((window.localStorage.getItem(LOCAL_STORAGE_KEYS.SHOW_BATCH_SALES) === 'true') || false)
const priceHistoryPrimaryCurrency = ref<CurrencyType>(window.localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_CURRENCY_IN_CHART) as CurrencyType || CurrencyType.ETH)

const allTimeHigh = computed<NFTEvent | null>(() => {
  const { saleEvents } = useSectionNFTEvents()
  if (!saleEvents.value?.length) {
    return null
  }

  const highestSaleEvent = saleEvents.value.reduce((previousValue, currentValue) => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    return (Number(previousValue.token.appraisal?.price?.usdAmount) > Number(currentValue.token.appraisal?.price?.usdAmount) ? previousValue : currentValue)
  })
  return highestSaleEvent
})

const allTimeLow = computed<NFTEvent | null>(() => {
  const { saleEvents } = useSectionNFTEvents()
  if (!saleEvents.value?.length) {
    return null
  }

  const lowestSaleEvent = saleEvents.value.reduce((previousValue, currentValue) => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    return (Number(previousValue.token.appraisal?.price?.usdAmount) > Number(currentValue.token.appraisal?.price?.usdAmount) ? currentValue : previousValue)
  })
  return lowestSaleEvent
})

const allTimeAverage = computed<string | null>(() => {
  const { saleEvents } = useSectionNFTEvents()
  if (!saleEvents.value?.length) {
    return null
  }

  // todo USD
  const sumOfSalesInUsd = saleEvents.value.map(saleEvent => Number(saleEvent.token?.appraisal?.price?.usdAmount)).reduce((accumulator, currentValue) => accumulator + currentValue)
  return String(sumOfSalesInUsd / saleEvents.value.length)
})

export default function useAssetPriceHistory() {
  const setPriceHistoryPrimaryCurrency = (currency: CurrencyType): void => {
    priceHistoryPrimaryCurrency.value = currency
  }

  const setHasCheckedIncludeBatchSales = (showBatchSales: boolean): void => {
    hasCheckedIncludeBatchSales.value = showBatchSales
  }

  return {
    isLoadingPriceHistory,
    hasCheckedIncludeBatchSales: readonly(hasCheckedIncludeBatchSales),
    setHasCheckedIncludeBatchSales,
    priceHistoryPrimaryCurrency: readonly(priceHistoryPrimaryCurrency),
    setPriceHistoryPrimaryCurrency,
    allTimeAverage,
    allTimeLow,
    allTimeHigh,
    priceHistoryMessageIfNoData,
    LOCAL_STORAGE_KEYS,
  }
}
