import type { Address } from 'viem'
import { SupportedChain } from '@/constants/chains/types'
import { computed, ref, unref, watchEffect } from 'vue'
import type { MaybeRef } from 'vue'
import { useElementVisibility, useTimeout } from '@vueuse/core'
import { useFetchAssetPrice } from '@/modules/common/backend/generated'
import { useCurrencySwitcherStore } from '@/layout/header/useCurrencySwitcherStore'
import { storeToRefs } from 'pinia'
import { generateFakeAssetPrice } from '@/modules/common/assets/fakeAppraisals'
import { keepPreviousData, useIsFetching } from '@tanstack/vue-query'
import { formatDecimalPoint } from '@/utils/utils'
import { AssetPrice } from '@/modules/common/assets/typings/AssetPriceClasses'
import type AssetType from '@/modules/common/assets/AssetType'
import { parseAddress } from '@/utils/address-utils'

export function useAssetPrice(
  chainId: MaybeRef<string | null>,
  contractAddress: MaybeRef<string | Address | null>,
  tokenId: MaybeRef<string | null>,
  assetCategory?: MaybeRef<AssetType | undefined>,
) {
  const queriesAlreadyRunning = useIsFetching({
    queryKey: ['api', 'v1', 'asset', 'price'],
    exact: false,
    fetchStatus: 'fetching',
  })

  const store = useCurrencySwitcherStore()
  const { selectedCurrency } = storeToRefs(store)

  const target = ref(null)
  const targetIsVisible = useElementVisibility(target)
  const ready = useTimeout(700)

  const queryIsEnabled = computed(() => {
    if (!unref(chainId) || !unref(contractAddress)) return false
    if (target.value === null) {
      return ready.value && queriesAlreadyRunning.value < 3
    }
    return targetIsVisible.value && ready.value && queriesAlreadyRunning.value < 3
  })

  const priceData = useFetchAssetPrice(
    chainId as MaybeRef<string>,
    contractAddress as MaybeRef<string | Address>,
    // @ts-expect-error null should be okay to pass
    tokenId,
    {
      // timestamp: new Date().getTime()
    },
    {
      query: {
        enabled: queryIsEnabled,
        staleTime: 1000 * 60 * 5,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 60 * 5,
        gcTime: 1000 * 60 * 30,
        retry: 1,
        placeholderData: keepPreviousData,
      },
    },
  )
  const hasRefetched = ref(true)
  const { data: assetPriceData } = priceData

  watchEffect(() => {
    if (priceData.data.value?.data.is_task_scheduled && hasRefetched.value) {
      setTimeout(() => {
        priceData.refetch({
          cancelRefetch: true,
        })
      }, 3000)
      hasRefetched.value = false
    }
  })

  const priceDataParsed = computed(() => {
    const v = assetPriceData?.value?.data?.best_price
      ? AssetPrice.createFromBackendModel(assetPriceData.value.data.best_price)
      : null

    if (
      [SupportedChain.Sepolia, SupportedChain.StarknetSepolia].includes(Number(unref(chainId))) &&
      unref(contractAddress)?.length &&
      !v
    ) {
      if (unref(assetCategory) !== null || unref(assetCategory) !== undefined) {
        const price = generateFakeAssetPrice(parseAddress(unref(contractAddress)!), unref(assetCategory)!)
        return price
      }
    }

    return v
  })

  const currentUnitPrice = computed(() => {
    const price = priceDataParsed.value
    const currency = selectedCurrency.value.label
    const displayInEth = currency === 'ETH'

    if (price?.price) {
      if (displayInEth) {
        return price.price.ethAmount
      }
      return price.price.usdAmount
    }

    return null
  })

  const priceDataToDisplay = computed(() => {
    const unitPrice = currentUnitPrice.value
    const currency = selectedCurrency.value.label
    const displayInEth = currency === 'ETH'

    if (!unitPrice && priceData.isFetched.value) return '---'
    if (!unitPrice) return

    if (!displayInEth) {
      return '$' + formatDecimalPoint(unitPrice, 2)
    }

    return formatDecimalPoint(unitPrice) + ' ETH'
  })

  return {
    target,
    currentUnitPrice,
    priceDataToDisplay,
    priceData: priceDataParsed,
    isLoading: priceData.isLoading.value,
  }
}
