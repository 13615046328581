<template>
  <div
    class="collateral-filter"
    :class="[
      {
        'collateral-filter--disabled': isDisabled
      }
    ]">
    <div
      :class="['collateral-filter__button', { 'collateral-filter__button--selected': collateralType === CollateralTypeFilter.All }]"
      @click="handleUpdateCollateral(CollateralTypeFilter.All)">
      All
    </div>
    <div
      :class="['collateral-filter__button', { 'collateral-filter__button--selected': collateralType === CollateralTypeFilter.NFTs }]"
      @click="handleUpdateCollateral(CollateralTypeFilter.NFTs)">
      <NFTIcon
        height="14"
        width="14"/>
      NFTs
    </div>
    <div
      :class="['collateral-filter__button', { 'collateral-filter__button--selected': collateralType === CollateralTypeFilter.Tokens }]"
      @click="handleUpdateCollateral(CollateralTypeFilter.Tokens)">
      <TokenIcon
        height="14"
        width="14"/>
      Tokens
    </div>
  </div>
</template>

<script setup lang="ts">
import NFTIcon from '@/assets/icons/asset-offer.svg'
import TokenIcon from '@/assets/icons/token.svg'
import {
  CollateralTypeFilter,
  useCollateralTypeFilter,
} from '@/modules/common/filters/collateral-type/useCollateralTypeFilter'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'

type Props = {
  isDisabled?: boolean
}

const props = defineProps<Props>()

const { collateralType, actions } = useCollateralTypeFilter()
const proposalFiltersStore = useProposalFilters()

const handleUpdateCollateral = (collateralType: CollateralTypeFilter) => {
  if (props.isDisabled) {
    return
  }
  actions.handleUpdateCollateralType(collateralType)
  proposalFiltersStore.actions.updateCollateralTypeFilter(collateralType)
}

</script>

<style scoped>
.collateral-filter {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 0.25rem;
  border: 1px solid var(--primary-color-3);
  padding: 3px;
  width: fit-content;

  &--disabled {
    opacity: 0.5;
  }

  &__button {
    display: inline-flex;
    padding: 4px;
    cursor: pointer;
    box-sizing: content-box;
    align-items: center;

    gap: 0.25rem;
    border: 1px solid transparent;

    font-size: 0.875rem;
    line-height: 1rem;
    font-family: var(--font-family-oxygen-mono);
    font-weight: 400;

    user-select: none;

    transition: 0.3s ease-in-out background-color,
      0.3s ease-in-out border-color;

    &--selected {
      background-color: var(--primary-color-3);
      border: 1px solid var(--primary-color-1);
    }
  }

  &__icon {
    width: 0.875rem;
    height: 0.875rem;
  }

  @media only screen and (--mobile-viewport) {
    &__button {
      font-size: 0.675rem;
    }
  }
}
</style>
