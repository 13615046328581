<template>
  <div>
    <div
      :class="['input__container', { 'input__container--bare': isBareStyle }]"
      v-bind="$attrs">
      <slot name="input-left"/>
      <input
        ref="inputElement"
        v-focus:[isAutofocused]
        v-select-text
        :value="modelValue"
        :disabled="isDisabled || isFixedValue"
        :placeholder="placeholder"
        :class="['input__input', { 'input__input--bare': isBareStyle }]"
        :style="{ textAlign: inputTextAlign }"
        @blur="emit('blur')"
        @focus="emit('focus')"
        @input="emitUpdateModelValue">
      <span
        v-if="appendText"
        :class="['input__append-text', {'input__append-text--disabled': isDisabled}]">{{ appendText }}
      </span>
      <slot name="input-right">
        <BaseButton
          v-if="buttonText"
          class="input__button"
          :button-text="buttonText"
          :is-disabled="isButtonDisabled"
          :variant="ButtonVariant.InsideInput"
          @on-button-click="emitButtonClick"/>
      </slot>
      <slot
        v-if="isDisabled"
        name="disabled-input"/>
    </div>
    <div :class="['input__label-warning-wrapper', {'input__label-warning-wrapper--small-and-long-warning': isSmallAndLongWarning }]">
      <label
        v-if="!isValidInput"
        :class="['input__label-warning', { 'input__label-warning--double-input': isDoubleInput, 'input__label-warning--small-and-long-warning': isSmallAndLongWarning }]">
        {{ warningText }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonVariant } from '@/general-components/BaseButton.vue'
import { ref, toRefs } from 'vue'
import { replaceCommaByDecimalPoint } from '@/utils/utils'

interface Props {
  modelValue: string | number | undefined;
  placeholder?: string;
  inputTextAlign?: 'left' | 'center';
  isAutofocused?: boolean;
  isDisabled?: boolean;
  buttonText?: string;
  isButtonDisabled?: boolean;
  appendText?: string;
  isValidInput?: boolean;
  warningText?: string;
  isDoubleInput?: boolean;
  isSmallAndLongWarning?: boolean;
  isBareStyle?: boolean;
  isFixedValue?: boolean;
  inputType?: 'text' | 'number' | 'decimal';
}
const props = withDefaults(defineProps<Props>(), {
  inputTextAlign: 'left',
  inputType: 'text',
  isSmallAndLongWarning: false,
})

const {
  modelValue,
  placeholder,
  inputTextAlign,
  isAutofocused,
  isDisabled,
  buttonText,
  isButtonDisabled,
  appendText,
  isValidInput,
  warningText,
  isDoubleInput,
  isSmallAndLongWarning,
} = toRefs(props)

const emit = defineEmits<{(e: 'update:modelValue', value: string): void;
  (e: 'button-clicked'): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>()

const emitUpdateModelValue = (event: Event) => {
  emit('update:modelValue', replaceCommaByDecimalPoint((event.target as HTMLInputElement).value))
}

const emitButtonClick = () => emit('button-clicked')

const inputElement = ref<HTMLInputElement>()

defineExpose({
  inputElement,
})
</script>

<style scoped>
.input {
  &__container {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgb(var(--text-color-base) / 50%);
    height: var(--input-height);
    background-color: var(--input-color);

    &:disabled {
      background-color: var(--separator-color);
    }

    &:hover {
      border-color: var(--text-color);
    }

    &:focus-within {
      border-color: var(--primary-color-1);
    }

    &--bare {
      border: none;
      background: transparent;
    }
  }

  &__input {
    width: 100%;
    border: none;
    font-size: 0.875rem;
    color: var(--text-color);
    background-color: var(--input-color);
    padding: 1rem 0.5rem;
    caret-color: rgb(var(--text-color-base) / 70%);
    font-family: var(--font-family-oxygen-mono);

    &:focus {
      outline: none;
    }

    &:disabled:not(.input__input--bare) {
      background: var(--separator-color);
      cursor: not-allowed;
    }

    &--bare {
      background: transparent;
    }
  }

  &__button {
    font-size: 0.8rem;
    white-space: nowrap;
  }

  &__append-text {
    display: flex;
    align-items: center;
    padding-right: 0.625rem;
    background-color: var(--input-color);
    font-size: 0.75rem;
    white-space: pre;

    &:hover {
      cursor: default;
    }

    &--disabled {
      background: var(--separator-color);
      cursor: not-allowed;
    }
  }

  &__label-warning {
    color: var(--negative-1);
    font-size: 0.875rem;
    left: 0;

    --base-warning-label-margin: 5px;
    margin-top: var(--base-warning-label-margin);

    &--double-input {
      margin-top: calc(var(--base-warning-label-margin) + var(--input-height));
    }

    &--small-and-long-warning {
      font-size: 0.75rem;
    }
  }

  &__label-warning-wrapper {
    position: absolute;
    height: 0.4rem;
    margin-top: 0.2rem;

    &--small-and-long-warning {
      right: 1rem;
    }
  }
}
</style>
