<template>
  <input
    v-model="modelValueLocal"
    :value="props.inputValue"
    class="input-radio"
    type="radio">
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  modelValue: string
  inputValue: string
}
const props = defineProps<Props>()

const emit = defineEmits<{(e: 'update:modelValue', value: typeof props.modelValue)}>()

const modelValueLocal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
</script>

<style scoped>
.input-radio {
  --input-radio-size: 20px;
  appearance: none;
  background-color: var(--background-color);
  margin: 0;
  border-radius: 50%;
  border: 1px solid var(--primary-color-3);
  width: var(--input-radio-size);
  height: var(--input-radio-size);

  display: grid;
  place-content: center;

  &:hover {
    border-color: var(--primary-color-2);
    cursor: pointer;
  }

  &::before {
    content: "";
    width: calc(var(--input-radio-size) / 2);
    height: calc(var(--input-radio-size) / 2);
    border-radius: 50%;
    background-color: var(--primary-color-1);
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  &:checked {
    border-color: var(--primary-color-1);

    &::before {
      transform: scale(1);
    }
  }
}
</style>
