import { computed } from 'vue'
import { globalConstants } from '@/constants/globals'
import type { Address } from 'viem'

/**
 * Endpoint URLs for these endpoints, that we can not use via generated backend SDK (need to perform retry).
 * This object will most probably be obsolete after this will be resolved:
 * https://github.com/ferdikoomen/openapi-typescript-codegen/issues/984 .
 */
const endpointUrls = computed(() => {
  return {
    SSE: {
      Listener: (userAddress: Address): string => `${globalConstants.backendUrl}/sse/events/${userAddress}/`,
      BroadcastListener: `${globalConstants.backendUrl}/sse/events/`,
    },
  }
})
export default endpointUrls
