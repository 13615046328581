export const ABI = [
  {
    type: 'impl',
    name: 'RevokedNonceImpl',
    interface_name: 'pwn::nonce::revoked_nonce::IRevokedNonce',
  },
  {
    type: 'enum',
    name: 'core::option::Option::<core::starknet::contract_address::ContractAddress>',
    variants: [
      {
        name: 'Some',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'None',
        type: '()',
      },
    ],
  },
  {
    type: 'enum',
    name: 'core::option::Option::<core::felt252>',
    variants: [
      {
        name: 'Some',
        type: 'core::felt252',
      },
      {
        name: 'None',
        type: '()',
      },
    ],
  },
  {
    type: 'enum',
    name: 'core::bool',
    variants: [
      {
        name: 'False',
        type: '()',
      },
      {
        name: 'True',
        type: '()',
      },
    ],
  },
  {
    type: 'interface',
    name: 'pwn::nonce::revoked_nonce::IRevokedNonce',
    items: [
      {
        type: 'function',
        name: 'revoke_nonce',
        inputs: [
          {
            name: 'owner',
            type: 'core::option::Option::<core::starknet::contract_address::ContractAddress>',
          },
          {
            name: 'nonce_space',
            type: 'core::option::Option::<core::felt252>',
          },
          {
            name: 'nonce',
            type: 'core::felt252',
          },
        ],
        outputs: [],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'revoke_nonces',
        inputs: [
          {
            name: 'nonces',
            type: 'core::array::Array::<core::felt252>',
          },
        ],
        outputs: [],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'revoke_nonce_space',
        inputs: [],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'is_nonce_revoked',
        inputs: [
          {
            name: 'owner',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'nonce_space',
            type: 'core::felt252',
          },
          {
            name: 'nonce',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'core::bool',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'is_nonce_usable',
        inputs: [
          {
            name: 'owner',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'nonce_space',
            type: 'core::felt252',
          },
          {
            name: 'nonce',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'core::bool',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'current_nonce_space',
        inputs: [
          {
            name: 'owner',
            type: 'core::starknet::contract_address::ContractAddress',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'hub',
        inputs: [],
        outputs: [
          {
            type: 'core::starknet::contract_address::ContractAddress',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'access_tag',
        inputs: [],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
    ],
  },
  {
    type: 'constructor',
    name: 'constructor',
    inputs: [
      {
        name: 'hub',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'access_tag',
        type: 'core::felt252',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::nonce::revoked_nonce::RevokedNonce::NonceRevoked',
    kind: 'struct',
    members: [
      {
        name: 'owner',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'nonce_space',
        type: 'core::felt252',
        kind: 'data',
      },
      {
        name: 'nonce',
        type: 'core::felt252',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::nonce::revoked_nonce::RevokedNonce::NonceSpaceRevoked',
    kind: 'struct',
    members: [
      {
        name: 'owner',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'nonce_space',
        type: 'core::felt252',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::nonce::revoked_nonce::RevokedNonce::Event',
    kind: 'enum',
    variants: [
      {
        name: 'NonceRevoked',
        type: 'pwn::nonce::revoked_nonce::RevokedNonce::NonceRevoked',
        kind: 'nested',
      },
      {
        name: 'NonceSpaceRevoked',
        type: 'pwn::nonce::revoked_nonce::RevokedNonce::NonceSpaceRevoked',
        kind: 'nested',
      },
    ],
  },
] as const
