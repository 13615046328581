<template>
  <div class="create-new-pwn-safe-footer">
    <div
      :class="['create-new-pwn-safe-footer__button-container', {'create-new-pwn-safe-footer__button-container--right': !isFinalStep}]">
      <div
        v-if="isFinalStep"
        class="create-new-pwn-safe-footer__previous-step"
        @click="router.back()">
        Previous Step
      </div>
      <div class="create-new-pwn-safe-footer__button-container">
        <SafeText
          v-if="isFinalStep"
          class="create-new-pwn-safe-footer__previous-step-button"
          :text="`Approximated Cost: ${gasCost} ${nativeTokenSymbol}`"/>
        <BaseButton
          class="create-new-pwn-safe-footer__button"
          :button-text="buttonText"
          :is-disabled="isDisabled"
          @on-button-click="emitButtonClick"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/general-components/BaseButton.vue'
import { computed, toRefs, watch } from 'vue'
import { useRouter } from 'vue-router'
import SafeText from '@/general-components/SafeText.vue'
import useCreateNewPwnSafe from '@/modules/pages/pwn-safe/create-new-pwn-safe/useCreateNewPwnSafe'
import { ALL_SUPPORTED_CHAINS_WAGMI } from '@/constants/chains/types'

const props = defineProps<{
  buttonText?: string
  isDisabled?: boolean
}>()
const { buttonText, isDisabled } = toRefs(props)

const { estimatePwnSafeDeployCost, gasCost, activeStep, chainId } = useCreateNewPwnSafe()
const emit = defineEmits<{(e: 'onButtonClick'): void }>()
const emitButtonClick = () => emit('onButtonClick')
const router = useRouter()

const nativeTokenSymbol = computed(() => ALL_SUPPORTED_CHAINS_WAGMI.find(chainWagmi => chainWagmi.id === chainId.value)?.nativeCurrency?.symbol)

const isFinalStep = computed(() => activeStep.value.stepNumber === 2)

watch(chainId, async (newChainId) => {
  if (!newChainId) {
    return
  }

  await estimatePwnSafeDeployCost()
}, { immediate: true })
</script>

<style scoped>
.create-new-pwn-safe-footer {
  position: relative;
  margin-top: 2rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 1px;
    width: 100%;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px; /* small hack how to hide buggy double border (top + bottom), when height is 1px */
  }

  &__previous-step {
    margin-top: 1.5rem;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    &--right {
      justify-content: right;
    }
  }

  &__previous-step-button {
    margin-top: 0.75rem;
  }

  &__button {
    margin-left: 1rem;
  }

  @media only screen and (--mobile-viewport) {
    margin-bottom: 1rem;

    &__button-container {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
