<template>
  <BaseSection
    class="pwn-safe-selection-table__section"
    :is-expandable="false"
    :heading="heading"
    :secondary-heading="secondaryHeadingAssets">
    <BaseTable
      v-model:active-sort-option="activeSortOptionLocal!"
      table-max-height="450px"
      table-min-width="900px"
      :variant="TableVariant.AssetPage"
      :items="sortedAssets"
      :columns="tableDefinition"
      :is-fetching="isFetchingAssets"
      @on-row-click="emitOnAssetSelect">
      <template #checkbox="{ item: token }">
        <BaseCheckbox
          v-if="isAssetSelected !== undefined"
          :is-disabled="!token.isValid"
          :model-value="isAssetSelected(token)"
          @click.prevent/>
      </template>

      <template #asset-info="{ item: token }">
        <AssetInfo
          class="pwn-safe-selection-table__asset"
          :asset="token"
          :show-link="false"/>
      </template>

      <template #holding-rights="{ item: token }">
        <AssetInfo
          v-if="token.isAtrToken"
          class="pwn-safe-selection-table__asset"
          :asset="token"
          :show-link="false"
          :show-unpack-tooltip="false"/>
      </template>

      <template #amount="{ item: token }">
        <div class="pwn-safe-selection-table__amount">
          <div
            v-if="token.isFungible"
            class="pwn-safe-selection-table__amount-input">
            <BaseInput
              v-model="token.amountInput"
              button-text="max"
              :placeholder="token.maxAvailableAmount"
              :is-valid-input="false"
              @button-clicked="token.amountInput = token.maxAvailableAmount"
              @click.stop/>
          </div>
          <SafeText
            v-else
            :allow-click-through="false"
            :text="token.amountFormatted"/>
        </div>
      </template>

      <template #explorer="{ item: token }">
        <div class="pwn-safe-selection-table__asset-action-container">
          <BaseLink
            class="pwn-safe-selection-table__action-link"
            target="_blank"
            :to="getAssetPageRoute(token)"
            @click.stop>
            <PwnLogoIconSvg
              class="pwn-safe-selection-table__asset-action-icon"
              alt="transfer"/>
            <SafeText
              text="Asset Page"/>
          </BaseLink>
        </div>
      </template>

      <template #remove>
        <div class="pwn-safe-selection-table__action">
          <SafeText
            :allow-click-through="false"
            text="- Remove"/>
        </div>
      </template>
    </BaseTable>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import BaseTable from '@/general-components/BaseTable.vue'
import AssetInfo from '@/general-components/AssetInfo.vue'
import SafeText from '@/general-components/SafeText.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, toRefs } from 'vue'
import TableVariant from '@/general-components/TableVariant'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import BaseInput from '@/general-components/BaseInput.vue'
import PwnLogoIconSvg from '@/assets/icons/pwn.svg'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'

interface Props {
  heading: string
  sortedAssets: AssetWithAmount[]
  activeSortOption?: ActiveSortOption
  isFetchingAssets?: boolean
  tableDefinition: ColumnProperties[]
  isAssetSelected?: (asset: AssetWithAmount) => boolean
}

const props = defineProps<Props>()
const { heading, activeSortOption, sortedAssets, isFetchingAssets, tableDefinition } = toRefs(props)

const { getAssetPageRoute } = useAssetPage()

// eslint-disable-next-line
const emit = defineEmits<{
  (e: 'handle-remove', asset: AssetWithAmount): void;
  (e: 'on-asset-select', asset: AssetWithAmount): void;
  (e: 'update:activeSortOption', newActiveSortOption: ActiveSortOption | undefined): void;
}>()

const secondaryHeadingAssets = computed(() => `(${sortedAssets.value.length})`)

const emitOnAssetSelect = (asset: AssetWithAmount) => {
  emit('on-asset-select', asset)
}

const activeSortOptionLocal = computed({
  get: () => activeSortOption.value,
  set: (value: ActiveSortOption | undefined) => {
    emit('update:activeSortOption', value)
  },
})
</script>

<style scoped>
.pwn-safe-selection-table {
  &__section {
    margin-bottom: 4rem;
  }

  &__asset {
    margin-right: 0.5rem;
  }

  &__asset-action-container {
    display: flex;
  }

  &__action-link,
  &__amount,
  &__action {
    display: flex;
  }

  &__amount-input {
    margin-right: 5rem;
  }

  &__asset-action {
    display: flex;
    flex: 1 1 0;
    font-family: var(--font-family-autoscape);
    color: var(--primary-color-1);

    &:hover {
      text-decoration: underline;
    }
  }

  &__asset-action-icon {
    margin-right: 0.5rem;
    width: 0.875rem;
  }

  &__action {
    color: var(--orange-1);
    font-family: var(--font-family-autoscape);
  }

  @media only screen and (--mobile-viewport) {
    &__section {
      overflow: auto auto;
    }
  }
}
</style>
