<template>
  <div
    class="achievements-page">
    <Claim
      v-if="address"
      :address="address"
      :parameter-name="`HasBasename`"
      :eligible="eligible"
      share-message="Hey, I just claimed my reputation on @pwndao for having a Basename on @base!"
      class="achievements-page__background">
      <template #not-eligible>
        <h2 class=" achievements-page__heading">
          Looks like you don’t own a basename yet.
        </h2>
        <p class="achievements-page__info">
          Get yours on
          <BaseLink
            to="https://www.base.org/names"
            target="_blank">
            <a>https://www.base.org/names</a>
          </BaseLink>
          and report back to claim your PWN rep.
        </p>
      </template>
      <template #already-claimed>
        <h2 class=" achievements-page__heading">
          You've already claimed reputation for Basenames.
        </h2>
        <GetMoreRep/>
        <LearnMore/>
      </template>
    </Claim>
    <Connect
      v-if="!address"
      :heading="`Attention Basename holder!`"
      class="achievements-page__background"/>
  </div>
</template>

<script lang="ts" setup>
import { useAccount } from '@wagmi/vue'
import Claim from '@/revamp/pages/claim-rep/Claim.vue'
import Connect from '@/revamp/pages/claim-rep/Connect.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import { getBasename } from '@/modules/common/web3/useBasename'
import { asyncComputed } from '@vueuse/core'
import LearnMore from '@/revamp/pages/claim-rep/LearnMore.vue'
import GetMoreRep from '@/revamp/pages/claim-rep/GetMoreRep.vue'

const { address } = useAccount()

const eligible = asyncComputed(async () => {
  if (!address.value) return false
  const basename = await getBasename(address.value)
  if (!basename) return false
  return true
})
</script>

<style scoped>
.achievements-page {
  margin-top: 1rem;

  &__background {
    background-color: #171717;
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    padding: 2rem;
  }

  &__info {
    font-size: 1.2rem;
    text-align: center;
  }
}
</style>
