<template>
  <BaseSection
    :is-expandable="true"
    with-gray-background
    heading="Third-party positions"
    :is-loading="isLoading"
    :secondary-heading="assets?.length ? '(' + assets.length + ')' : '(0)'">
    <template #title-icon>
      <ThirdPartyIcon/>
    </template>
    <BaseEmptyStateText
      v-if="error || (!assets && !isLoading)"
      text="No third-party positions available"/>
    <YourDepositedAssetsTable
      v-else
      is-dashboard
      :assets="assets"
      :is-loading="isLoading"
      :table-definition="props.tableDefinition"
      has-first-column-padding/>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import ThirdPartyIcon from '@/assets/icons/third-party.svg'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import YourDepositedAssetsTable from '@/modules/sections/your-assets/your-deposited-assets/YourDepositedAssetsTable.vue'
import { toRefs } from 'vue'

interface Props {
  tableDefinition: ColumnProperties[]
  assets: AssetWithAmount[] | undefined
  isLoading: boolean
  error: Error | null
}
const props = defineProps<Props>()

const { assets } = toRefs(props)
</script>
