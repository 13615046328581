<template>
  <div class="section-bundle-assets-table">
    <BaseAssetPageSection
      icon="bundler"
      question-mark-tooltip="Overview of all assets in this bundle, individual assets can be obtained by unpacking this bundle"
      :has-data="hasBundleAssetsOrLoading"
      :label="title">
      <template #content>
        <div class="section-bundle-assets-table__content">
          <BaseTable
            :columns="TABLE_DEFINITION"
            :variant="TableVariant.AssetPage"
            :items="asset?.bundleAssets ?? []"
            :is-fetching="isFetchingAppraisals"
            table-max-height="254px"
            table-min-width="700px"
            :is-hoverable="false">
            <template #asset="{ item: bundleAsset, index }">
              <AssetInfo
                class="section-bundle-assets-table__asset"
                :asset="bundleAsset"
                :is-lazy-loaded="handleIsLazyLoadedInTable(index)"
                show-link/>
            </template>

            <template #appraisal="{ item: bundleAsset }">
              <EthAndUsdPriceLabel
                v-if="bundleAsset?.appraisalFullAmount"
                :amount="bundleAsset.appraisalFullAmount"/>
              <div v-else>
                No data
              </div>
            </template>

            <template #amount="{ item: bundleAsset }">
              <div
                v-if="bundleAsset.isNft"
                style="margin-right: 1rem;">
                <SafeText
                  :text="bundleAsset.amount"
                  :tooltip-text="bundleAsset.amount"/>
              </div>
              <BasePriceLabel
                v-else
                :amount="bundleAsset.amount"
                :symbol="bundleAsset.symbol"/>
            </template>
          </BaseTable>
        </div>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import BaseTable from '@/general-components/BaseTable.vue'
import { TABLE_DEFINITION } from '@/modules/pages/asset/nft-page/SectionAssetTable/SectionBundleAssetsTableDefinitions'
import TableVariant from '@/general-components/TableVariant'
import { computed } from 'vue'
import BasePriceLabel from '@/general-components/BasePriceLabel.vue'
import AssetInfo from '@/general-components/AssetInfo.vue'
import useSectionBundleAssetsTable from '@/modules/pages/asset/nft-page/SectionAssetTable/useSectionBundleAssetsTable'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import useLazyLoading from '@/modules/common/useLazyLoading'
import SafeText from '@/general-components/SafeText.vue'

const { handleIsLazyLoadedInTable } = useLazyLoading()
const { isFetchingAppraisals } = useSectionBundleAssetsTable()
const { asset } = useAssetPage()
// @ts-expect-error FIXME: strictNullChecks
const hasBundleAssetsOrLoading = computed(() => isFetchingAppraisals.value || asset.value?.bundleAssets.length > 0)

const title = computed(() => {
  if (asset.value?.isBundleWithSingleAsset) {
    return 'Wrapped Asset'
  }
  return 'Assets in bundle'
})
</script>

<style scoped>
.section-bundle-assets-table {
  &__worth {
    display: flex;
    padding: 1.5rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
  }

  &__worth-in-token,
  &__worth-in-usd {
    line-height: 2.375rem;
    width: fit-content;
  }

  &__worth-in-token {
    padding-left: 0.1rem;
    font-size: 1rem;
  }

  &__worth-in-usd {
    color: var(--gray);
    font-size: 0.8rem;
  }

  &__from {
    display: inline;
  }

  &__from-icon {
    display: inline;
    width: 0.875rem;
    height: 0.875rem;
    min-width: 0.875rem;
    margin-right: 0.5rem;
  }

  &__asset {
    padding-right: 1.5rem;
  }
}

</style>
