<template>
  <div
    ref="tableContainerRef"
    class="nfts-table"
    @scroll="fetchMoreOnBottomReached">
    <table
      v-if="topCollectionsQuery.isFetched"
      class="nfts-table__table">
      <thead class="nfts-table__head">
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
          class="nfts-table__head-row">
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            :colSpan="header.colSpan">
            <FlexRender
              v-if="!header.isPlaceholder"
              :render="header.column.columnDef.header"
              :props="header.getContext()"/>
          </th>
        </tr>
      </thead>
      <tbody
        class="nfts-table__body"
        style="display: grid; height: 100%; position: relative;"
        :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
        <TransitionGroup name="nfts-table--fade">
          <tr
            v-for="row in virtualRows"
            :key="rows[row.index].original.id"
            :ref="customRefHandler"
            class="nfts-table__row"
            :class="[isDivider(rows[row.index].original) ? 'nfts-table__divider' : '']"
            :data-index="row.index"
            :style="{
              display: 'flex',
              position: 'absolute',
              transform: `translateY(${row.start}px)`,
              width: '100%',
              justifyContent: 'space-between',
            }"
            @click="() => handleSelectAsset(collectionToAssetAdapter(rows[row.index].original)!)">
            <td
              v-if="isDivider(rows[row.index].original)"
              class="nfts-table__divider-content">
              <span class="nfts-table__divider-text"> Others </span>
            </td>
            <td
              v-for="cell in rows[row.index].getVisibleCells()"
              v-else
              :key="cell.id"
              :style="{
                width: `${cell.column.getSize()}px`,
              }">
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"/>
            </td>
          </tr>
        </TransitionGroup>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import {
  getFetchAssetMetadataQueryKey,
  useAssetTopCollectionsList,
  useAssetTopCollectionsListExternalInfinite,
} from '@/modules/common/backend/generated'
import type { NFTAssetCollectionDetailWithStatsSchemaBackendSchema } from '@/modules/common/backend/generated'
import { computed, h, onMounted, ref } from 'vue'
import { FlexRender, getCoreRowModel, useVueTable } from '@tanstack/vue-table'
import { useVirtualizer } from '@tanstack/vue-virtual'
import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import type { SupportedChain } from '@/constants/chains/types'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import { useDivider } from '@/revamp/components/modals/utils/use-divider'
import AssetInfoCell from '@/revamp/components/tables/cells/assets/AssetInfoCell.vue'
import AssetPrice from '@/revamp/components/prices/AssetPrice.vue'
import { isAddress } from 'viem'
import to from '@/utils/await-to-js'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import { filterAssetsInSearchResults } from '@/utils/search'
import { compareAddresses } from '@/utils/utils'
import { isPwnSpace, pwnSpaceConfig } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import AssetType from '@/modules/common/assets/AssetType'
import { isMobile } from '@/utils/mediaQueries'

type Props = {
  chainId: SupportedChain;
  q?: string;
};

const props = defineProps<Props>()

const { isDivider, getDivider } = useDivider()

const tableContainerRef = ref(null)
const fetchSize = 15
const emit = defineEmits<{(e: 'select-asset', asset: AssetMetadata) }>()

const topCollectionParams = computed(() => {
  return {
    chain_list: [props.chainId],
  }
})

const { fetchNftCollection } = useMetadataFetch()
const topExternalCollectionsQuery = useAssetTopCollectionsListExternalInfinite(topCollectionParams, {
  query: {
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 15,
    // placeholderData: keepPreviousData,
    initialPageParam: undefined,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.data.cursor
    },
  },
})

const topExternalCollections = computed(() => {
  const results: NFTAssetCollection[] = []

  for (const page in topExternalCollectionsQuery.data?.value?.pages) {
    const parsed: NFTAssetCollection[] = topExternalCollectionsQuery.data?.value?.pages[page]?.data?.collections.map(
      NFTAssetCollection.createFromBackendModel,
    )
    results.push(...parsed)
  }

  return results
})

const reqParams = computed(() => {
  return {
    limit: fetchSize,
    chain_id: props.chainId,
  }
})

const topCollectionsQuery = useAssetTopCollectionsList(reqParams, {
  query: {
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 15,
    placeholderData: keepPreviousData,
  },
})

const flatData = computed(() => {
  if (topCollectionsQuery.isFetched && topCollectionsQuery.data.value?.data.results) {
    return topCollectionsQuery.data.value?.data.results.map(
      // @ts-expect-error todo: bug in the orval schema generation
      (result: NFTAssetCollectionDetailWithStatsSchemaBackendSchema) => {
        return NFTAssetCollection.createFromBackendModel(result)
      },
    )
  }

  return []
})

const totalCount = computed(() => {
  const topTokensCount = topCollectionsQuery.data.value?.data.count || 10
  const tokensInTokenListCount = topCollectionsQuery.data.value?.data.count || 10

  return Math.max(topTokensCount, tokensInTokenListCount)
})
const collectionToAssetAdapter = (collection: NFTAssetCollection | AssetMetadata) => {
  if (isDivider(collection) || !(collection instanceof NFTAssetCollection)) return null

  return new AssetMetadata({
    chainId: collection.chainId,
    address: collection.contractAddress,
    name: collection.name,
    image: collection.image,
    symbol: collection.symbol,
    collection,
    id: parseInt(collection.id) + Math.random() * -10000,
    category: collection.category,
  })
}

const searchTokenAddress = computed(() => {
  if (props.q && isAddress(props.q)) {
    return props.q
  }
  return ''
})

const chainId = computed(() => props.chainId)

const assetMetadataQuery = useQuery({
  // @ts-expect-error It's just dumb, we need to unify the chainId type in the api components
  queryKey: getFetchAssetMetadataQueryKey(searchTokenAddress, chainId, ''),
  queryFn: async (context) => {
    const [, , , , address, chainId] = context.queryKey
    const [err, res] = await to(fetchNftCollection(address, Number(chainId)))

    if (!err) {
      return res
    }
    throw new Error(`Failed to fetch metadata, ${err}`)
  },
})

const rowsToDisplay = computed(() => {
  const divider = getDivider(props.chainId)

  const pwnSpaceContracts = pwnSpaceConfig?.allowedContracts.filter(v => v.category !== AssetType.ERC20).map(v => new NFTAssetCollection({
    symbol: v.name,
    name: v.name,
    image: v.image,
    contractAddress: v.address,
    chainId: v.chainId,
    category: v.category,
  })) || []

  const result: any[] = isPwnSpace ? pwnSpaceContracts : [...flatData.value.slice(0, 4), divider, ...topExternalCollections.value]

  if (assetMetadataQuery.data.value) {
    result.push(
      new NFTAssetCollection({
        symbol: assetMetadataQuery.data.value.symbol,
        name: assetMetadataQuery.data.value.name,
        image: assetMetadataQuery.data.value.image,
        contractAddress: assetMetadataQuery.data.value.contractAddress,
        chainId: assetMetadataQuery.data.value.chainId,
        id: assetMetadataQuery.data.value.id,
        isVerified: assetMetadataQuery.data.value.isVerified,
      }),
    )
  }

  if (props.q) {
    const parsedAssets = result.map((asset) => collectionToAssetAdapter(asset)).filter(Boolean) as AssetMetadata[]
    const filteredAssets = filterAssetsInSearchResults(parsedAssets, props.q)

    return result
      .map((asset) => {
        if (
          filteredAssets.find((filteredAsset) => {
            if (asset instanceof NFTAssetCollection) {
              return compareAddresses(filteredAsset.address, asset.contractAddress)
            } else {
              return compareAddresses(filteredAsset.address, asset?.address)
            }
          })
        ) {
          return asset
        }
        return null
      })
      .filter(Boolean)
      .filter((v, i, a) => a.findIndex((t) => t?.id === v?.id) === i)
      .filter(Boolean) as NFTAssetCollection[]
  }

  // remove duplicates
  return result.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i).filter(Boolean) as NFTAssetCollection[]
})

const table = useVueTable({
  get data() {
    const res = rowsToDisplay?.value.filter(Boolean)
    return res || []
  },
  columns: [
    {
      id: 'name',
      header: 'NFT Name',
      accessorFn: (row) => {
        return row?.name
      },
      cell: (ctx) => {
        const asset = collectionToAssetAdapter(ctx.row.original)
        if (!asset) return null
        return h(AssetInfoCell, {
          asset,
          class: 'nfts-table__info-cell',
        })
      },
      size: isMobile ? 210 : 640,
    },
    {
      id: 'price',
      header: 'Average Appraisal',
      accessorFn: (row) => row?.id,
      cell: (ctx) => {
        const asset = collectionToAssetAdapter(ctx.row.original)
        if (!asset) return null
        return h(AssetPrice, {
          contractAddress: asset.address,
          tokenId: asset.tokenId ? String(asset.tokenId) : null,
          chainId: String(asset.chainId),
        })
      },
      size: 75,
    },
  ],
  getCoreRowModel: getCoreRowModel(),
  getRowId: (row) => String(row.id),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rowsToDisplay.value?.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 42,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 10,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const fetchMoreOnBottomReached = () => {
  const container = tableContainerRef.value
  if (container) {
    const { scrollHeight, scrollTop, clientHeight } = container
    if (
      scrollHeight - scrollTop - clientHeight < 500 &&
      !topExternalCollectionsQuery.isFetching.value &&
      topExternalCollectionsQuery.hasNextPage.value &&
      rows.value.length < totalCount.value
    ) {
      topExternalCollectionsQuery.fetchNextPage()
    }
  }
}

onMounted(() => {
  fetchMoreOnBottomReached()
})

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

const handleSelectAsset = (asset: AssetMetadata) => {
  emit('select-asset', asset)
}
</script>

<style scoped>
.nfts-table {
  overflow: auto;
  position: relative;
  height: 400px;

  padding-right: 1rem;

  &__table {
    display: grid;
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 5;

    display: flex;

    background: var(--gray-3);
    padding: 0 0.5rem;
  }

  &__head-row {
    width: 100%;
    padding: 0.25rem;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family-screener);
  }

  &__row {
    width: 100%;
    justify-content: space-between;
    display: inline-flex;

    padding: 0.5rem 1rem;

    will-change: background-color;
    transition: background-color 0.3s linear;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-3);
    }
  }

  &__info-cell {
    width: 16rem;

    @media only screen and (--small-viewport) {
      width: 8rem;
    }
  }

  &__divider {
    padding: 0;
    margin-top: 0.5rem;
    width: 100%;
    border-top: 1px solid var(--gray-2);
    height: 40px;
    display: flex;

    &:last-child {
      display: none !important;
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  &__divider-content {
    display: flex;
    width: 100%;
  }

  &__divider-text {
    color: var(--text-color);
    padding-left: 1rem;
    padding-top: 0.5rem;
    font-size: 1rem;
  }
}

.nfts-table--fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>

<style>
.nfts-table {
  .asset-info-cell__name {
    max-width: 100% !important;

    @media only screen and (--mobile-viewport) {
      max-width: 12rem !important;
    }
  }
}
</style>
