<template>
  {{ totalCommitedAmount.formatted }}
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useAssetListPrice } from '@/revamp/hooks/asset-prices/useAssetListPrice'
import { toRefs } from 'vue'

type Props = {
    assets: AssetWithAmount[]
}

const props = defineProps<Props>()
const { assets } = toRefs(props)

const { summarizedAmount: totalCommitedAmount } = useAssetListPrice(assets, undefined, true)
</script>
