import { computed, readonly, ref } from 'vue'
import type { CollectionOrAppraisalRow } from '@/modules/pages/asset/nft-page/types/CollectionOrAppraisalRow'
import { StatValueTypeEnum } from '@/modules/pages/asset/nft-page/types/CollectionOrAppraisalRow'
import type CollectionStats from '@/modules/common/assets/typings/CollectionStats'
import type { SupportedChain } from '@/constants/chains/types'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type { Address } from 'viem'

const collectionStats = ref<CollectionStats>()
const isFetchingCollectionStats = ref(false)

const collectionStatsWithLabels = computed<CollectionOrAppraisalRow[]>(() => {
  return [
    {
      description: {
        label: 'Floor',
        questionMarkTooltip: 'Lowest-priced active listing within the collection',
      },
      value: {
        type: StatValueTypeEnum.Price,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.floorPrice,
      },
    },
    {
      description: {
        label: 'Week Floor Change',
        questionMarkTooltip: 'The percentage change in the floor price of the collection over the preceding week',
      },
      value: {
        type: StatValueTypeEnum.PercentChange,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.weekFloorPriceChangePercentage,
      },
    },
    {
      description: {
        label: 'Average appraisal',
        questionMarkTooltip: 'Average appraisal of all NFTs in this collection',
      },
      value: {
        type: StatValueTypeEnum.Price,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.averageAppraisal,
      },
    },
    {
      description: {
        label: 'Market Cap',
        questionMarkTooltip: 'The approximated total market value of the collection',
      },
      value: {
        type: StatValueTypeEnum.Price,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.marketCap,
      },
    },
    {
      description: {
        label: 'Week Mcap Change',
        questionMarkTooltip: 'The percentage change in the market capitalization of the collection over the preceding week',
      },
      value: {
        type: StatValueTypeEnum.PercentChange,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.weekMarketCapChangePercentage,
      },
    },
    {
      description: {
        label: 'Total Volume',
        questionMarkTooltip: 'Total volume (all-time) in the collection',
      },
      value: {
        type: StatValueTypeEnum.Price,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.totalVolume,
      },
    },
    {
      description: {
        label: 'Past Day Volume',
        questionMarkTooltip: 'Volume in the collection over the preceding day',
      },
      value: {
        type: StatValueTypeEnum.Price,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.pastDayVolume,
      },
    },
    {
      description: {
        label: 'Past Day Average',
        questionMarkTooltip: 'Average sale price of assets in the collection over the preceding day',
      },
      value: {
        type: StatValueTypeEnum.Price,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.pastDayAverage,
      },
    },
    {
      description: {
        label: 'Past Week Volume',
        questionMarkTooltip: 'Volume in the collection over the preceding week',
      },
      value: {
        type: StatValueTypeEnum.Price,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.pastWeekVolume,
      },
    },
    {
      description: {
        label: 'Past Week Average',
        questionMarkTooltip: 'Average sale price of assets in the collection over the preceding week',
      },
      value: {
        type: StatValueTypeEnum.Price,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.pastWeekAverage,
      },
    },
    {
      description: {
        label: 'Past Month Sales',
        questionMarkTooltip: 'The number of sales related to assets in the collection over the preceding month',
      },
      value: {
        type: StatValueTypeEnum.Text,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.pastMonthSales,
      },
    },
    {
      description: {
        label: 'Total Sales',
        questionMarkTooltip: 'The total number of sales related to assets in the collection',
      },
      value: {
        type: StatValueTypeEnum.Text,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        value: collectionStats.value.totalSales,
      },
    },
  ]
})

export default function useSectionCollectionStats() {
  const clearCollectionStats = (): void => {
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'CollectionS... Remove this comment to see the full error message
    collectionStats.value = null
  }

  const loadCollectionStats = async (chainId: SupportedChain, contractAddress: Address, refresh?: boolean | undefined): Promise<void> => {
    isFetchingCollectionStats.value = true
    collectionStats.value = await useMetadataFetch().fetchCollectionStats(chainId, contractAddress, refresh)
    isFetchingCollectionStats.value = false
  }

  const hasCollectionStats = computed(() => !!collectionStats.value)

  return {
    hasCollectionStats,
    collectionStatsWithLabels,
    isFetchingCollectionStats: readonly(isFetchingCollectionStats),
    loadCollectionStats,
    clearCollectionStats,
    collectionStats: computed(() => collectionStats.value),
  }
}
