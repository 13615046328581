<template>
  <RewardsTooltip
    :assets="assetsForRewards"
    :apr="apr"
    :multiplier="multiplier"
    :is-with-rewards="true"
    :is-with-text="isWithText"
    :proposal-rewards-amount="rewardsToBeEarned > 0 ? formatDecimalPoint(rewardsToBeEarned) : undefined"
    :always-show-tooltip="alwaysShowTooltip">
    <template #trigger>
      <div
        v-if="rewardsToBeEarned || alwaysShowTooltip"
        class="rewards-by-amount">
        <ReputationScoreSvg
          class="rewards-by-amount__icon"
          alt="reputation score"/>

        <span v-if="creditAmount && !alwaysShowTooltip">
          {{ formatDecimalPoint(rewardsToBeEarned) }}
        </span>
        <span v-else>
          earn rewards
        </span>
      </div>
    </template>
  </RewardsTooltip>
</template>

<script setup lang="ts">
import { useUserReputationScoreWithParametersRetrive } from '@/modules/common/backend/generated'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { computed, toRefs } from 'vue'
import { formatDecimalPoint } from '@/utils/utils'
import RewardsTooltip from '@/general-components/promo/RewardsTooltip.vue'
import type { EligibleRewardsInput } from '@/general-components/promo/rewardsRegistry'
import ReputationScoreSvg from '@/assets/icons/rep-score.svg'

type Props = {
  assetsForRewards: EligibleRewardsInput[]
  apr?: string
  creditAmount?: string
  alwaysShowTooltip?: boolean
  isWithText?: boolean
}

const { address: userAddress } = useCustomAccount()
const userAddressRef = computed(() => userAddress.value || '')
const props = defineProps<Props>()
const { creditAmount } = toRefs(props)

const connectedUserRepQuery = useUserReputationScoreWithParametersRetrive(userAddressRef, { refresh: false })

const multiplier = computed<number>(() => {
  const userRep = connectedUserRepQuery.data.value?.data.totalMultiplier

  return (userRep || 1) as number
})

const rewardsToBeEarned = computed(() => {
  if (!creditAmount.value) {
    return 0
  }

  return (Number(creditAmount.value) / 1000) * multiplier.value
})

</script>

<style scoped>
.rewards-by-amount {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--primary-color-1);

  display: flex;
  align-items: center;

  &__icon {
    margin-right: 0.25rem;
    width: 12px;
    height: 12px;
    fill: var(--primary-color-1);
  }
}
</style>
