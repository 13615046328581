<template>
  <div class="thesis-commited-actions">
    <RevokeThesisProposals
      v-if="noncesToRevoke.length"
      :title-text="titleText"
      :nonces="nonces"
      :thesis-id="thesis.id"
      :asset-name="asset.name"
      :on-update="props.onUpdate"/>
  </div>
</template>

<script lang="ts" setup>
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { ThesisCreditStatsSchemaWorkaroundBackendSchema, ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { computed } from 'vue'
import RevokeThesisProposals from '@/revamp/components/modals/thesis/RevokeThesisProposals.vue'
import type { SupportedChain } from '@/constants/chains/types'

type Props = {
    asset: AssetWithAmount
    creditStatsData: ThesisCreditStatsSchemaWorkaroundBackendSchema
    thesis: ThesisSchemaWorkaroundBackendSchema
    onUpdate: (thesisId: string) => void
}
const props = defineProps<Props>()
const noncesToRevoke = computed(() => props.creditStatsData.proposalNonces!.map(BigInt))
const chainId = computed(() => props.creditStatsData.creditAssetMetadata.chainId)

const titleText = computed(() => {
  return `Cancel ${props.asset.symbol} ${props.thesis.title} ${'#' + props.thesis.id.padStart(4, '0')} Proposals`
})

const nonces = computed(() => {
  const res: Partial<Record<SupportedChain, bigint[]>> = {}
  if (!chainId.value) return {}
  res[chainId.value] = noncesToRevoke.value
  return res
})
</script>

<style scoped>
.thesis-commited-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 1rem;
}
</style>
