<template>
  <header class="pwn-safe-dashboard-header">
    <div class="pwn-safe-dashboard-header__heading">
      <div class="pwn-safe-dashboard-header__title">
        <SafeIconSvg
          alt="safe icon"
          class="pwn-safe-dashboard-header__safe-icon"/>
        <h1 class="pwn-safe-dashboard-header__heading-secondary">
          PWN Safe Dashboard <span v-if="pwnSafesCount">({{ pwnSafesCount }})</span>
        </h1>
      </div>
      <ChainGroupFilter
        :set-chain="selectedChainStore.actions.setSelectedChain"
        :selected-chains="selectedChains!"
        :disabled-chains="pwnSafeUnsupportedChains"/>

      <RefreshButton
        :handle-refresh="handleRefresh"
        :is-loading="isLoading"/>
      <span class="pwn-safe-dashboard-header__powered-by">
        Powered By
        <img
          class="pwn-safe-dashboard-header__safe-logo"
          src="@/assets/images/safe-logo.svg?url"
          alt="safe logo">
      </span>
    </div>

    <BaseButton
      v-if="linkTo.pwnSafeVideoTutorial"
      :to="linkTo.pwnSafeVideoTutorial"
      :color="ButtonColor.PrimaryDark"
      :variant="ButtonVariant.Outline"
      is-icon-before-text
      :is-darkening-effect="false"
      link-target="_blank"
      button-text="Watch Tutorial">
      <template #icon>
        <VideoIconSvg
          class="pwn-safe-dashboard-header__video-icon"
          alt="video icon"/>
      </template>
    </BaseButton>
  </header>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { computed } from 'vue'
import linkTo from '@/constants/links'
import SafeIconSvg from '@/assets/icons/safe.svg'
import VideoIconSvg from '@/assets/icons/video.svg'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import RefreshButton from '@/modules/common/assets/components/RefreshButton.vue'

type Props = {
  handleRefresh: () => void
  isLoading: boolean
}

defineProps<Props>()

const selectedChainStore = useSelectedChainsStore()
const { selectedChains } = storeToRefs(selectedChainStore)

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { pwnSafes } = storeToRefs(pwnSafeDetailStore)

const pwnSafesCount = computed(() => Object.keys(pwnSafes).length)

const pwnSafeUnsupportedChains = computed(() => {
  const results = []
  for (const config of Object.values(CHAINS_CONSTANTS)) {
    if (!config?.pwnSafeContracts) {
      // @ts-expect-error FIXME: strictNullChecks
      results.push(config.general.chainId)
    }
  }
  return results
})

</script>

<style scoped>
.pwn-safe-dashboard-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 3rem;
  padding-bottom: 2rem;

  &__title {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family-autoscape);
    font-weight: 550;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  &__heading-primary {
    font-size: 1.875rem;
    font-weight: 550;
  }

  &__heading-secondary {
    font-size: 1.5rem;
    font-weight: 550;
  }

  &__safe-icon {
    align-self: flex-start;
    width: 2rem;
    height: 2rem;
  }

  &__video-icon {
    margin-right: 0.5rem;
    margin-top: -0.1rem;
    width: 1.3125rem;
    height: 1.3125rem;
  }

  &__powered-by {
    color: var(--gray-2);
    font-family: var(--font-family-oxygen-mono);
    margin-left: auto;
  }

  &__safe-logo {
    vertical-align: middle;
  }

  @media only screen and (--small-viewport) {
    padding-bottom: 0;

    &__heading {
      flex-direction: column;
      align-items: flex-start;
    }

    &__heading-secondary {
      font-size: 1.2rem;
    }
  }
}
</style>
