import { ref } from 'vue'
import useNotifications from '@/modules/common/notifications/useNotifications'
import { getAccount, getWalletClient } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'

const isSubscribedToPush = ref(false)
const isOpen = ref(false)
const PREFIX = 'eip155:'

export default function usePushNotificationModal() {
  const { displayErrorNotification } = useNotifications()

  const fetchIsSubscribedToPush = async () => {
    const PushAPI = await import('@pushprotocol/restapi')
    const { address: userAddress } = getAccount(pwnWagmiConfig)
    const subscriptions: Array<unknown> = await PushAPI.user.getSubscriptions({
      // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
      user: PREFIX.concat(import.meta.env.VITE_PUSH_CHANNEL_ID, ':', userAddress),
      env: import.meta.env.VITE_PUSH_ENV,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isSubscribedToPush.value = subscriptions.some((sub: any) => sub.channel === import.meta.env.VITE_PUSH_CHANNEL)
  }

  const handleSubscribe = async () => {
    const PushAPI = await import('@pushprotocol/restapi')

    // TODO some simple composable for retrieving wallet client???
    const walletClient = await getWalletClient(pwnWagmiConfig)
    const { address: userAddress, chainId: connectedChainId } = getAccount(pwnWagmiConfig)
    await PushAPI.channels.subscribe({
      signer: walletClient,
      channelAddress: PREFIX.concat(import.meta.env.VITE_PUSH_CHANNEL_ID, ':', import.meta.env.VITE_PUSH_CHANNEL),
      // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
      userAddress: PREFIX.concat(import.meta.env.VITE_PUSH_CHANNEL_ID, ':', userAddress),
      env: import.meta.env.VITE_PUSH_ENV,
      onSuccess: () => {
        isOpen.value = false
        isSubscribedToPush.value = true
        fetchIsSubscribedToPush()
      },
      onError: () => {
        displayErrorNotification({
          errorMessage: 'Error Subscribing to Push',
          // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          chainId: connectedChainId,
        })
      },
    })
  }

  const handleUnsubscribe = async () => {
    const PushAPI = await import('@pushprotocol/restapi')

    const { address: userAddress, chainId: connectedChainId } = getAccount(pwnWagmiConfig)
    const walletClient = await getWalletClient(pwnWagmiConfig)
    await PushAPI.channels.unsubscribe({
      signer: walletClient,
      channelAddress: PREFIX.concat(import.meta.env.VITE_PUSH_CHANNEL_ID, ':', import.meta.env.VITE_PUSH_CHANNEL),
      // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
      userAddress: PREFIX.concat(import.meta.env.VITE_PUSH_CHANNEL_ID, ':', userAddress),
      env: import.meta.env.VITE_PUSH_ENV,
      onSuccess: () => {
        isOpen.value = false
        isSubscribedToPush.value = true
        fetchIsSubscribedToPush()
      },
      onError: () => {
        displayErrorNotification({
          errorMessage: 'Error Unsubscribing to Push',
          // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          chainId: connectedChainId,
        })
      },
    })
  }
  return {
    isSubscribedToPush,
    isOpen,
    fetchIsSubscribedToPush,
    handleSubscribe,
    handleUnsubscribe,
  }
}
