<template>
  <div class="active-filter">
    <TransitionGroup name="active-filters--fade">
      <div
        v-for="filter in transformedFilters"
        :key="filter.id"
        class="active-filter__container">
        <TokenMedia
          v-if="!filter.icon"
          width="16"
          height="16"
          :token="filter?.asset!"/>
        <span class="active-filter__text">
          <component
            :is="filter.icon"
            v-if="filter.icon"
            :alt="filter.text"/>
          {{ filter.text }}
        </span>
        <CloseIconSvg
          v-if="!isReadOnly && filter.onClick"
          alt="close"
          class="active-filter__close-icon"
          @click.stop="filter?.onClick"/>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import TokenMedia from '@/general-components/TokenMedia.vue'
import CloseIconSvg from '@/assets/icons/close-primary-color.svg'
import DollarIconSvg from '@/assets/icons/dollar.svg'
import type { ProposalFiltersStoreModel } from '@/revamp/modules/proposals/useProposalFilters'
import { computed, toRefs } from 'vue'

type Props = {
  selectedCollateral?: AssetMetadata | null;
  selectedCredit?: AssetMetadata | null;

  selectedStableCoins?: AssetMetadata[];
  hasStablesFilter?: boolean;

  clearStablesFilter?: VoidFunction;
  clearCollateralSelection?: VoidFunction;
  clearCreditSelection?: VoidFunction;

  isReadOnly?: boolean;
};

const props = defineProps<Props>()
const { selectedCollateral, selectedCredit, isReadOnly } = toRefs(props)

export type ProposalFilterUI = {
  id: keyof ProposalFiltersStoreModel;
  text: string;
  icon?: string | typeof DollarIconSvg;
  asset?: AssetMetadata;
  onClick?: VoidFunction;
};

const MAX_ASSET_NAME_LENGTH = 13

const transformedFilters = computed(() => {
  const proposalFilters: ProposalFilterUI[] = []

  if (props.hasStablesFilter && Array.isArray(props.selectedStableCoins)) {
    proposalFilters.push({
      id: 'collateralAsset',
      text: 'Stables/ETH',
      asset: new AssetMetadata({
        address: '0x',
        name: 'Stables/ETH',
        symbol: 'Stables/eth',
        decimals: 18,
        image: 'url',
      }),
      icon: DollarIconSvg,
      onClick: props.clearStablesFilter,
    })
  }

  // extensive but necessary check for types
  if (selectedCollateral.value && !Array.isArray(selectedCollateral.value)) {
    let collateralName = selectedCollateral.value?.collectionName || selectedCollateral.value.primaryInfoWithoutAmount || ''
    if (collateralName.length > MAX_ASSET_NAME_LENGTH) {
      collateralName = `${collateralName.substring(0, MAX_ASSET_NAME_LENGTH - 3)}...`
    }

    proposalFilters.push({
      id: 'collateralAsset',
      text: collateralName,
      asset: selectedCollateral.value,
      onClick: props.clearCollateralSelection,
    })
  }

  // extensive but necessary check for types
  if (selectedCredit.value && !Array.isArray(selectedCredit.value)) {
    if (selectedCredit.value.bundleAssets.length) {
      proposalFilters.push({
        id: 'creditAsset',
        text: 'Stables',
        asset: new AssetMetadata({
          address: '0x',
          name: 'Stables/ETH',
          symbol: 'Stables/eth',
          decimals: 18,
          image: 'url',
        }),
        icon: DollarIconSvg,
        onClick: props.clearCreditSelection,
      })
    } else {
      let creditName = selectedCredit.value.primaryInfoWithoutAmount || ''
      if (creditName.length > MAX_ASSET_NAME_LENGTH) {
        creditName = `${creditName.substring(0, MAX_ASSET_NAME_LENGTH - 3)}...`
      }

      proposalFilters.push({
        id: 'creditAsset',
        text: creditName,
        asset: selectedCredit.value,
        onClick: props.clearCreditSelection,
      })
    }
  }

  return proposalFilters
})
</script>

<style scoped>
.active-filter {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;

  &__container {
    display: flex;
    align-items: center;
    height: 2rem;
    color: var(--primary-color-1);
    border: var(--gray-2) 1px solid;
    background-color: var(--background-color);
    padding: 0.375rem 0.75rem;
    gap: 0.5rem;
  }

  &__text {
    font-family: var(--font-family-supreme);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;

    color: var(--text-color);

    font-size: 1rem;
  }

  &__close-icon {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
  }
}

.active-filters--fade,
.active-filter--fade-move {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }

  &-leave-active {
    position: absolute;
  }
}
</style>

<style>
.active-filter {
  &__close-icon {
    path {
      fill: var(--gray-2);
    }

    &:hover {
      path {
        fill: var(--white);
      }
    }
  }
}
</style>
