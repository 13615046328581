<template>
  <div
    class="achievements-page">
    <Claim
      v-if="address"
      :address="address"
      :parameter-name="`IsAaveUser`"
      :eligible="isAaveUser"
      share-message="Hey, I just claimed my reputation on @pwndao for using @aave!"
      class="achievements-page__background">
      <template #not-eligible>
        <h2 class=" achievements-page__heading">
          It seems you're currently not using Aave. Check out other ways to earn rep.
        </h2>
        <GetMoreRep/>
        <LearnMore/>
      </template>
      <template #already-claimed>
        <h2 class=" achievements-page__heading">
          You've already claimed reputation for being an Aave user.
        </h2>
        <GetMoreRep/>
        <LearnMore/>
      </template>
    </Claim>
    <Connect
      v-if="!address"
      :heading="`Attention Aave users!`"
      class="achievements-page__background">
      <template #eligibility-criteria>
        <div class="achievements-page__info">
          To be eligible, you must have at least one deposit in Aave.
        </div>
      </template>
    </Connect>
  </div>
</template>

<script lang="ts" setup>
import { useAccount } from '@wagmi/vue'
import Claim from '@/revamp/pages/claim-rep/Claim.vue'
import Connect from '@/revamp/pages/claim-rep/Connect.vue'
import { asyncComputed } from '@vueuse/core'
import LearnMore from '@/revamp/pages/claim-rep/LearnMore.vue'
import GetMoreRep from '@/revamp/pages/claim-rep/GetMoreRep.vue'
import { getUserAccountData } from '@/contracts/abis'
import { SupportedChain } from '@/constants/chains/types'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'

const { address } = useAccount()

const SUPPORTED_CHAINS = [SupportedChain.Arbitrum, SupportedChain.Polygon, SupportedChain.Base, SupportedChain.Bsc, SupportedChain.Ethereum, SupportedChain.Optimism]

const isAaveUser = asyncComputed(async () => {
  if (!address) return false
  for (const chainId of SUPPORTED_CHAINS) {
    const userAccountData = await getUserAccountData(pwnWagmiConfig, {
      address: CHAINS_CONSTANTS[String(chainId)].aave.pool,
      chainId,
      args: [address.value],
    }) as Array<bigint>
    // userAccountData[0] is users total deposit
    if (userAccountData[0] !== BigInt(0)) return true
  }
  return false
})

</script>

  <style scoped>
  .achievements-page {
    margin-top: 1rem;

    &__background {
      background-color: #171717;
    }

    &__heading {
      font-family: var(--font-family-screener);
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: center;
      padding: 2rem;
      max-width: 50rem;
    }

    &__claim-more-rep {
      width: 35rem;
    }

    &__info {
      display: flex;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
      font-size: 1rem;
      border: 1px solid #434343;
      width: 35rem;
      margin-top: 1rem;
    }
  }

  @media (max-width: 768px) {
    .achievements-page__info {
      width: 100%;
    }
  }
  </style>
