import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import type Notification from '@/modules/common/notifications/Notification'
import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import { compareAddresses, includesCaseInsensitive } from '@/utils/utils'
import { isAddress } from 'viem'

export const compareAssetWithSearchTerm = <AssetType extends AssetMetadata>(asset: AssetType, searchTerm: string): boolean => {
  if (!asset) {
    return false
  }

  if (isAddress(searchTerm) && compareAddresses(asset.address, searchTerm)) {
    return true
  }

  return includesCaseInsensitive(asset.name || '', searchTerm) ||
            includesCaseInsensitive(asset.symbol || '', searchTerm) ||
            includesCaseInsensitive(asset.collectionName || '', searchTerm) ||
            includesCaseInsensitive(asset.address || '', searchTerm)
}

export const isAssetIncludedInSearchResults = <AssetType extends AssetMetadata>(asset: AssetType, searchTerm: string): boolean => {
  const includesNameOrSymbol = compareAssetWithSearchTerm(asset, searchTerm)

  if (!asset) return false
  if (includesNameOrSymbol || (!asset.isTokenBundle && !asset.isAtrToken)) {
    return includesNameOrSymbol
  } else if (asset.isTokenBundle) {
    return asset.bundleAssets.some(bundleAsset => compareAssetWithSearchTerm(bundleAsset, searchTerm))
  } else if (asset.isAtrToken) {
    return compareAssetWithSearchTerm(asset.assetOfAtrToken, searchTerm)
  }

  return false
}

export const filterAssetsInSearchResults = <AssetType extends AssetMetadata>(assets: AssetType[], searchTerm: string): AssetType[] => {
  if (!searchTerm) {
    return assets
  }

  return assets.filter(asset => isAssetIncludedInSearchResults(asset, searchTerm))
}

export const filterLoansByCollateralAssetInSearchResults = <SpecificLoan extends BaseLoan>(loans: SpecificLoan[], searchTerm: string): SpecificLoan[] => {
  if (!searchTerm) {
    return loans
  }

  return loans.filter(loan => isAssetIncludedInSearchResults(loan.collateral, searchTerm))
}

export const filterNotificationsInSearchResults = (notifications: Notification[], searchTerm: string): Notification[] => {
  if (!searchTerm) {
    return notifications
  }

  return notifications.filter(notification =>
    includesCaseInsensitive(notification.message, searchTerm) ||
        includesCaseInsensitive(notification.loanOnChainId, searchTerm) ||
        includesCaseInsensitive(notification.proposalId, searchTerm) ||
        includesCaseInsensitive(new Date(notification.created).toLocaleDateString(), searchTerm))
}
