<template>
  <PwnSafeSelectionTable
    v-model:active-sort-option="selectedSelectionSortOption"
    :table-definition="TRANSFER_SELECTION_TABLE_DEFINITION"
    :sorted-assets="sortedAndFilteredAssetsToTransfer"
    :is-asset-selected="handleIsAssetSelected"
    heading="Selection "
    @on-asset-select="handleOnAssetSelect"/>
  <div class="pwn-safe-transfer-selection__divider"/>
</template>

<script lang="ts" setup>
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import PwnSafeSelectionTable from '@/modules/pages/pwn-safe/tables/PwnSafeSelectionTable.vue'
import { TRANSFER_SELECTION_TABLE_DEFINITION } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

const { sortedAndFilteredAssetsToTransfer, selectedSelectionSortOption, handleOnAssetSelect, isAssetSelected } = usePwnSafeTransfer()
const handleIsAssetSelected = (asset: AssetWithAmount) => {
  return isAssetSelected({ asset, selectedAssets: sortedAndFilteredAssetsToTransfer })
}
</script>

<style scoped>
.pwn-safe-transfer-selection {
  &__divider {
    margin: 2rem 0;
    height: 1px;
    width: 100%;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px;
  }
}
</style>
