<template>
  <div class="select-your-collateral__tabs">
    <BaseButton
      is-full-width
      :class="[
        {
          'select-your-collateral__tab--active': isTabSelected(Tabs.ASSETS),
        },
      ]"
      :color="isTabSelected(Tabs.ASSETS) ? ButtonColor.Primary : ButtonColor.White"
      button-text="Tokens"
      style="width: 100%;"
      :variant="ButtonVariant.Outline"
      @on-button-click="() => setSelectedTab(Tabs.ASSETS)">
      <template #iconBeforeText>
        <TokenIcon class="select-your-collateral__tab-icon"/>
      </template>
    </BaseButton>
    <BaseButton
      is-full-width
      :class="[
        {
          'select-your-collateral__tab--active': isTabSelected(Tabs.NFTS),
        },
      ]"
      :color="isTabSelected(Tabs.NFTS) ? ButtonColor.Primary : ButtonColor.White"
      button-text="NFTs"
      style="width: 100%;"
      :variant="ButtonVariant.Outline"
      @on-button-click="() => setSelectedTab(Tabs.NFTS)">
      <template #iconBeforeText>
        <NFTIcon class="select-your-collateral__tab-icon"/>
      </template>
    </BaseButton>
  </div>

  <div
    class="select-your-collateral__tables">
    <div class="select-your-collateral__content">
      <component
        :is="activeComponent"
        :data="tableData"
        :search="props.q"
        :select-multiple="multipleAssetsSelected"
        :selected-rows="selectedRows"
        :is-loading="tableDataIsLoading"
        :selected-chain="selectedChain"
        @add-selected-row="
          (value) => {
            const asset = tableData.find((nft) => nft.id === Number(value))
            if (!multipleAssetsSelected) {
              emit('select-asset', asset as AssetMetadata);
            } else {
              emit('handle-bundle', asset!, true)
            }

            selectedRows = multipleAssetsSelected
              ? {
                ...selectedRows,
                [value]: true,
              }
              : {
                [value]: true,
              };
          }
        "
        @remove-selected-row="
          (value) => {

            const asset = tableData.find((nft) => nft.id === Number(value))
            if (asset) {
              emit('handle-bundle', asset, false)
            }

            selectedRows = multipleAssetsSelected
              ? {
                ...selectedRows,
                [value]: false,
              }
              : {
                [value]: false,
              };
          }
        "/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs } from 'vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import UserTokens from '@/revamp/components/modals/select-your-collateral/UserTokens.vue'
import UserNFTs from '@/revamp/components/modals/select-your-collateral/UserNFTs.vue'
import type { AssetWithAmount, AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { useUserNfts } from '@/revamp/hooks/useUserNfts'
import { useUserTokens } from '@/revamp/hooks/useUserTokens'
import type { SupportedChain } from '@/constants/chains/types'
import useSelectAssetModal from '@/revamp/components/modals/select-your-collateral/useSelectAssetModal'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import NFTIcon from '@/assets/icons/asset-offer.svg'
import TokenIcon from '@/assets/icons/token.svg'
import { filterOutNonPwnSpaceAssets } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'
import { useIsFetching } from '@tanstack/vue-query'
import { compareAddresses } from '@/utils/utils'
import { useDebounce } from '@vueuse/core'

type Props = {
  chainId?: SupportedChain
  q?: string
  selectedChain?: SupportedChain
}

const props = defineProps<Props>()
const { chainId } = toRefs(props)

const globalFiltersStore = useGlobalFiltersStore()

const { multipleAssetsSelected, selectedRows } = useSelectAssetModal()
const { address } = useCustomAccount()

const emit = defineEmits<{(e: 'select-asset', asset: AssetMetadata); (e: 'handle-bundle', asset: AssetMetadata, isAdding: boolean); (e: 'total-removed-by-filter', value: number)}>()

const applyGlobalFilters = (assets: AssetWithAmount[]) => {
  const withAppliedSmallBalanceFilter = globalFiltersStore.applyShowAssetsWithLesserAmountFilter(assets)
  const withAppliedFilter = globalFiltersStore.applyShowUnverifiedAssetsFilter(withAppliedSmallBalanceFilter)

  const totalRemoved = assets.length - withAppliedFilter.length
  emit('total-removed-by-filter', totalRemoved)
  return withAppliedFilter
}

const { nfts, isLoading: isLoadingNfts } = useUserNfts(address, chainId)
const { tokens, isLoading: isLoadingTokens } = useUserTokens(address, chainId)

enum Tabs {
  ASSETS = 'Assets',
  NFTS = 'NFTs',
}

const selectedTab = ref<Tabs>(Tabs.ASSETS)
const setSelectedTab = (tab: Tabs) => {
  selectedTab.value = tab
}
const isTabSelected = (tab: Tabs): boolean => selectedTab.value === tab

const componentsByTab = {
  [Tabs.ASSETS]: UserTokens,
  [Tabs.NFTS]: UserNFTs,
}

const activeComponent = computed(() => componentsByTab[selectedTab.value])

const tableData = computed(() => {
  if (isTabSelected(Tabs.ASSETS)) {
    return applyGlobalFilters(filterOutNonPwnSpaceAssets(tokens.value))
  }
  return applyGlobalFilters(filterOutNonPwnSpaceAssets(nfts.value))
})

const numOfPricesFetching = useIsFetching({
  queryKey: ['api', 'v1', 'asset', 'price'],
  fetchStatus: 'fetching',
  exact: false,
  predicate: (query) => {
    const chainId = query.queryKey[4]
    const address = query.queryKey[5] as `0x${string}`
    const tokenId = query.queryKey[6]

    const data = isTabSelected(Tabs.ASSETS) ? tokens.value : nfts.value

    const token = data.find(v => {
      return compareAddresses(v.address, address) && v.chainId.toString() === chainId && v.tokenId ? v.tokenId?.toString() === tokenId : true
    })

    return !!token
  },
})

const filterAppliedLoading = computed(() => {
  return !globalFiltersStore.showAssetsWithLesserAmount && numOfPricesFetching.value > 0
})

const debouncedFilterAppliedLoading = useDebounce(filterAppliedLoading, 300)

const tableDataIsLoading = computed(() => {
  if (isTabSelected(Tabs.ASSETS)) {
    return isLoadingTokens.value || debouncedFilterAppliedLoading.value
  }
  return isLoadingNfts.value || debouncedFilterAppliedLoading.value
})

</script>

<style lang="postcss" scoped>
.select-your-collateral {
  &__filters-row {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    gap: 1rem;
    align-items: center;
  }

  &__tabs {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
  }

  &__tab {
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--text-color);
    padding: 1rem 2rem;
    width: 100%;

    font-weight: 700;
    font-size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: border-color 0.3s,
      color 0.3s;

    &--active {
      border-color: var(--primary-color-1);
      color: var(--primary-color-1);

      path {
        transition: fill 0.3s;
      }
    }
  }

  &__tab-icon {
    width: 1rem;
    margin-right: 0.5rem;
  }

  &__select-multiple {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
  }

  &__wallet-not-connected {
    padding: 4rem;
    width: 100%;

    border: 1px solid var(--gray-2);

    display: flex;
    justify-content: center;
  }

  &__search {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  &__search-bar {
    width: 100%;
  }

  &__filter {
    height: 2.5rem;
  }

  &__tables {
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 1rem;

    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
  }

  &__footer {
    display: flex;
    flex-flow: row nowrap;
  }

  &__token-select {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--background-color);
    height: 28px;
    width: 136px;
    font-size: 1rem;
    border: 1px solid var(--text-color);

    user-select: none;

    &:hover {
      border: 1px solid var(--text-color);
    }
  }

  &__selected-asset {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 10.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__selected-asset-container {
    display: contents;
  }
}
</style>

<style>
.select-your-collateral__tab--active path {
  fill: var(--primary-color-1);
}

.select-your-collateral__currency-switcher .multiselect__tags {
  padding: 0.5rem;
}

.select-your-collateral__currency-switcher .multiselect {
  height: auto;
  min-height: auto;
}

.select-your-collateral__currency-switcher .multiselect__single {
  margin-bottom: 0;
  display: flex;
}

.user-tokens-table {
  .asset-info-cell__symbol {
    max-width: 100% !important;

    @media only screen and (--small-viewport) {
      max-width: 100% !important;
    }

    @media only screen and (--mobile-viewport) {
      max-width: 8rem !important;
    }
  }
}
</style>
