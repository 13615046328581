<template>
  <BaseTooltip class="thesis-curator">
    <template #trigger>
      <img
        :src="_curatorIcon"
        alt="Community Curator"
        class="thesis-curator__icon">
      <span class="thesis-curator__text">
        {{ curator }}
      </span>
    </template>

    <div>
      {{ curatorDescription }}
    </div>
  </BaseTooltip>
</template>

<script lang="ts" setup>
import { globalConstants } from '@/constants/globals'
import { computed } from 'vue'
import BaseTooltip from '@/general-components/BaseTooltip.vue'

type Props = {
  curator: string
  curatorIcon: string
  curatorDescription: string
}

const props = defineProps<Props>()

const _curatorIcon = computed(() => {
  return `${globalConstants.backendUrl}${props.curatorIcon}`
})
</script>

<style scoped>
.thesis-curator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  height: 100%;
  cursor: pointer;

  &__text {
    font-family: var(--font-family-screener);
    color: var(--primary-color-1);
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
}

</style>
