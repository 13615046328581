import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import { ref } from 'vue'

export const useStartLoanModalStore = defineStore(StoreIds.startLoanModal, () => {
  const isOpen = ref(false)
  const proposalId = ref<number>()
  const handleAcceptProposalMutateAsync = ref()
  const proposalMutation = ref()

  return {
    isOpen,
    open() {
      isOpen.value = true
    },
    close() {
      isOpen.value = false
    },
    proposalId,
    handleAcceptProposalMutateAsync,
    proposalMutation,
  }
})
