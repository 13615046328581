import { ethers } from 'ethers'
import { toHex, parseEther } from 'viem'

const tenderlyForkProvider = new ethers.JsonRpcProvider(import.meta.env.VITE_TENDERLY_SEPOLIA_TESTING_RPC as string)

// const defaultProvider = ethers.getDefaultProvider()

export const fillEther = async (walletAddress: string): Promise<boolean | undefined> => {
  const params = [
    [walletAddress],
    toHex(parseEther('100')),
  ]

  // for more methods check https://docs.tenderly.co/devnets/advanced/custom-rpc-methods
  const res = await tenderlyForkProvider.send('tenderly_addBalance', params)
  if (res) return true
}
