<template>
  <BaseTooltip
    :append-to-body="appendToBody"
    :offset="offset"
    is-interactive
    :placement="tooltipPlacement"
    :border-color="TooltipBorderColor.TealLight"
    remove-padding
    :shadow="TooltipShadow.TealLight">
    <template #trigger>
      <div class="asset-tooltip__content">
        <slot/>
        <AssetChainIcon
          class="asset-tooltip__chain-icon"
          :chain-id="asset.chainId"/>
      </div>
    </template>
    <div class="asset-tooltip__wrapper">
      <div class="asset-tooltip__image-wrapper">
        <TokenMedia
          class="asset-tooltip__image"
          width="116"
          height="116"
          :token="asset"/>
        <AssetChainIcon
          class="asset-tooltip__chain-icon-tooltip"
          :chain-id="asset.chainId"/>
      </div>
      <div class="asset-tooltip__description">
        <div class="asset-tooltip__secondary-info-wrapper">
          <BaseLink
            v-if="asset.isNft"
            class="link link--primary asset-tooltip__secondary-info-text"
            :to="asset.collection.routeToCollection">
            <p class="asset-tooltip__link-to-collection">
              {{ asset.secondaryInfo }}
            </p>
          </BaseLink>
          <BaseLink
            v-else-if="asset.isErc20"
            class="link link--primary asset-tooltip__secondary-info-text"
            :to="asset.routeToErc20AssetPage">
            <p class="asset-tooltip__link-to-collection">
              {{ asset.secondaryInfo }}
            </p>
          </BaseLink>
          <VerificationBadge
            :style="[asset.isVerified ? { marginTop: '-0.1875rem' } : {}]"
            :token="asset"/>
        </div>
        <p class="asset-tooltip__primary-info-text">
          {{ asset.primaryInfoWithoutAmount }}
        </p>
        <BaseLink
          v-if="asset && !asset.isNativeToken"
          class="asset-tooltip__asset-page-link link link--primary"
          :to="getAssetPageRoute(asset)">
          View Asset
        </BaseLink>
      </div>
    </div>
  </BaseTooltip>
</template>

<script setup lang="ts">
import type { TooltipPlacement } from '@/general-components/BaseTooltip.vue'
import BaseTooltip, { TooltipBorderColor, TooltipShadow } from '@/general-components/BaseTooltip.vue'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { toRefs } from 'vue'
import VerificationBadge from '@/general-components/VerificationBadge.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import TokenMedia from '@/general-components/TokenMedia.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'

interface Props {
  asset: AssetMetadata
  tooltipPlacement: TooltipPlacement
  appendToBody?: boolean
  offset?: [number, number]
}
const props = withDefaults(defineProps<Props>(), {
  appendToBody: false,
})
const { asset, tooltipPlacement } = toRefs(props)

const { getAssetPageRoute } = useAssetPage()

</script>

<style scoped>
p {
  margin: 0;
}

.asset-tooltip {
  &__wrapper {
    --tooltip-max-width: 37.5rem;
    --tooltip-padding: 1.5rem;
    --image-size: 7.25rem;
    --margin-between-image-and-description: 1rem;
    display: flex;
    width: 100%;
    max-width: var(--tooltip-max-width);
    height: 10.25rem;
    max-height: 10.25rem;
    padding: var(--tooltip-padding);

    font-family: var(--font-family-autoscape);
  }

  &__image {
    margin-right: var(--margin-between-image-and-description);
  }

  &__description {
    display: flex;
    flex-direction: column;
    /* stylelint-disable-next-line max-line-length */
    max-width: calc(var(--tooltip-max-width) - var(--image-size) - var(--margin-between-image-and-description) - (2 * var(--tooltip-padding)));
  }

  &__link-to-collection {
    display: contents;
  }

  &__primary-info-text,
  &__secondary-info-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__secondary-info {
    &-wrapper {
      display: flex;
      margin-bottom: 0.5rem;
    }

    &-text {
      margin: 0 0.625rem 0 0;
      font-size: 1.125rem;
    }
  }

  &__primary-info-text {
    font-size: 1.5rem;
    text-align: left;
  }

  &__asset-page-link {
    margin-top: auto; /* push asset page link to bottom of the container */
    margin-left: auto;
    font-size: 1.25rem;

    &--missing {
      color: var(--negative-1);
    }
  }

  &__content {
    position: relative;
  }

  &__image-wrapper {
    position: relative;
  }

  &__chain-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    bottom: 0;
    right: 0.5rem;

    &-tooltip {
      width: 2rem;
      height: 2rem;
      position: absolute;
      bottom: 0;
      right: 0.5rem;
      padding: 0.2rem;
    }
  }
}
</style>
