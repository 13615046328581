import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as etherscanImage from '@/assets/icons/etherscan.svg'
import * as openSeaIcon from '@/assets/icons/opensea.svg'
import type { Address } from 'viem'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://sepolia.etherscan.io'

const SEPOLIA_CONSTANTS: ChainConstants = {
  general: {
    chainId: SupportedChain.Sepolia,
    chainName: 'Sepolia',
  },
  nodeProvider: {
    httpNodeUrl: 'https://eth-sepolia.g.alchemy.com/v2/',
    wsNodeUrl: `wss://eth-sepolia.g.alchemy.com/v2/${import.meta.env.VITE_SEPOLIA_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_SEPOLIA_ALCHEMY_NODE_KEY as string,
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'etherscan',
    blockExplorerIcon: etherscanImage.default,
    nftDetailsLink: (tokenAddress, tokenId) => `https://testnets.opensea.io/assets/sepolia/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: openSeaIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://testnets.opensea.io/collection/${slug}`,
  },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x4188C513fd94B0458715287570c832d9560bc08a',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x448E3D0a4BAa00FE511a03E7B27177AeDE6d9636',
  pwnSafeContracts: {
    assetTransferRightsGuardProxy: '0x7Fef14F22fAC06336097b6C35faCA6359A77eb14',
    assetTransferRights: '0x2Af429Ab631Cdd2e9de396F8C838d7ad231E73EB',
    pwnSafeFactory: '0xF475aB5843d6688ffFfDAA38e7DEFeAFAc9d9284',
  },
  topTokens: new TopTokens({
    pwnd: '0x78A341cB77e56b71A88a79e50A567e6C29B5cef5',
    pwns: '0xf6d0348A6a27c159d1aCD3AB98B63B14853E4D8E',
    usdt: '0x7169D38820dfd117C3FA1f22a697dBA58d90BA06',
    weth: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9',
    dai: '0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357',
  }),
  nftContractsAddresses: {
    cryptoPunks: '0x00cbf862cc5a7bd9dbff15f4b6df49b06cee73ef',
  },
  gnosisSafe: {
    transactionServiceUrl: 'https://safe-transaction-sepolia.safe.global',
    chainName: 'sep',
  },
  aave: {
    uiPoolDataProvider: '0x69529987FA4A075D0C00B0128fa848dc9ebbE9CE',
    poolAddressesProvider: '0x012bAC54348C0E635dCAc9D5FB99f06F24136C9A',
    pool: '0x6Ae43d3271ff6888e7Fc43Fd7321a503ff738951',
  },
  compound: {
    pools: ['0xAec1F48e02Cfb822Be958B68C7957156EB3F0b6e', '0x2943ac1216979aD8dB76D9147F64E61adc126e96'],
  },
}
export default SEPOLIA_CONSTANTS
