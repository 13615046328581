<template>
  <BaseTable
    :columns="tableDefinition"
    :items="selectedAssets"
    :variant="TableVariant.SelectedAssets"
    :has-first-column-padding="false"
    :is-hoverable="false"
    :is-disabled="handleIsDisable"
    has-tooltips
    table-max-height="530px"
    class="create-loan-selected-assets-table">
    <template #checkbox="{ item: asset }">
      <BaseInputRadio
        v-if="isRadioButtonTable"
        class="bundle-assets-table__radio"
        :data-testid="TestIds.selectedAssetsTableRadioButton"
        input-value="isSelected"
        model-value="isSelected"
        :data-asset-id="asset.tokenId"
        @click="emitOnAssetSelect(asset)"/>
      <BaseCheckbox
        v-else
        :model-value="true"
        @click="emitOnAssetSelect(asset)"/>
    </template>

    <template #collateral-info="{ item: asset }">
      <div class="create-loan-selected-assets-table--info">
        <CollateralInfo
          :has-amount-in-primary-info="false"
          :collateral="asset"/>
        <RewardsTooltip :assets="[{ chainId: asset.chainId, address: asset.address }]"/>
      </div>
    </template>

    <template #appraisal="{ item: asset }">
      <div>
        <LazyAssetPrice
          :asset="asset"
          align="right"
          :show-source="true"
          :type="asset.isNft ? 'appraisal' : 'token-appraisal'"/>
      </div>
    </template>

    <template #floor-price="{ item: asset }">
      <LazyAssetPrice
        v-if="asset.isNft || asset.isErc20"
        align="right"
        :asset="asset"
        :type="asset.isNft ? 'floorPrice' : 'token-price'"/>
    </template>

    <template #amount="{ item: asset }">
      <template v-if="asset.isMaxAvailableAmountDivisible">
        <BaseInput
          :model-value="asset.amountInput"
          button-text="max"
          :placeholder="handlePlaceholder(asset)"
          :is-valid-input="asset.isAssetAmountInputValidOrEmpty"
          :warning-text="warningTextForInput(asset)"
          :is-small-and-long-warning="asset.isInsufficientAmountOfNativeToken"
          @update:model-value="handleAssetAmountChange(asset, $event)"
          @button-clicked="handleInputButtonClick(asset)"/>
      </template>
      <template v-else>
        {{ asset.amount }}
      </template>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">
import BaseTable from '@/general-components/BaseTable.vue'
import { TABLE_DEFINITION } from '@/modules/sections/selected-assets/SelectedAssetsTableDefinitions'
import BaseInput from '@/general-components/BaseInput.vue'
import TableVariant from '@/general-components/TableVariant'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import { computed, toRefs } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import BaseInputRadio from '@/general-components/BaseInputRadio.vue'
import CollateralInfo from '@/general-components/CollateralInfo.vue'
import TestIds from '@/constants/testIds'
import LazyAssetPrice from '@/modules/sections/your-assets/your-nfts/LazyAssetPrice.vue'
import RewardsTooltip from '@/general-components/promo/RewardsTooltip.vue'

interface Props {
  selectedAssets: AssetWithAmount[]
  isRadioButtonTable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isRadioButtonTable: false,
})

const { selectedAssets } = toRefs(props)

interface Emits {
  (e: 'on-asset-select', asset: AssetWithAmount): void;
  (e: 'on-asset-amount-change', asset: AssetWithAmount): void;
}
const emit = defineEmits<Emits>()

const emitOnAssetSelect = (asset: AssetWithAmount) => {
  emit('on-asset-select', asset)
}

const handleAssetAmountChange = (asset: AssetWithAmount, newAmount: string) => {
  asset.amountInput = newAmount
  emit('on-asset-amount-change', asset)
}

const handleInputButtonClick = (asset: AssetWithAmount) => {
  asset.amountInput = asset.maxAvailableAmount
  emit('on-asset-amount-change', asset)
}

const tableDefinition = computed(() => {
  const TABLE_DEFINITION_CLONE = [...TABLE_DEFINITION]
  const INDEX_OF_FLOOR_PRICE_COLUMN = 3
  const hasNfts = selectedAssets.value.some(asset => asset.isNft)
  const hasTokens = selectedAssets.value.some(asset => asset.isErc20)
  if (hasNfts && hasTokens) {
    TABLE_DEFINITION_CLONE[INDEX_OF_FLOOR_PRICE_COLUMN].title = 'Floor / Price'
    return TABLE_DEFINITION_CLONE
  }
  if (hasNfts) {
    TABLE_DEFINITION_CLONE[INDEX_OF_FLOOR_PRICE_COLUMN].title = 'Floor Price'
    return TABLE_DEFINITION_CLONE
  }
  if (hasTokens) {
    TABLE_DEFINITION_CLONE[INDEX_OF_FLOOR_PRICE_COLUMN].title = 'Price'
    return TABLE_DEFINITION_CLONE
  }
  return TABLE_DEFINITION_CLONE
})

const warningTextForInput = (token: AssetWithAmount) => {
  return token.isInsufficientAmountOfNativeToken
    ? `Insufficient ${token.symbol} amount to cover gas costs (choose a lower amount)`
    : 'Invalid Amount'
}

const handlePlaceholder = (token: AssetWithAmount) => (token.isNativeToken ? '0' : token.maxAvailableAmount)

const handleIsDisable = (token) => {
  return false
}
</script>

<style scoped>
.create-loan-selected-assets-table {
  min-height: 120px;

  @media only screen and (--small-viewport) {
    overflow: auto auto;
    max-height: 400px;
  }
}
</style>

<style>
.create-loan-selected-assets-table {
  &--info {
    display: flex;
    align-items: center;
  }

  .table {
    min-width: 900px;
  }

  .table__td--variant-selected-assets {
    padding: 1rem 0;
  }
}
</style>
