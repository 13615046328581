import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import { parseSourceFromBackend } from '@/general-components/data-source/DataSourceType'
import type { ValuationSource } from '@/general-components/data-source/DataSourceType'
import type { NFTPriceStatsSchemaBackendSchema } from '@/modules/common/backend/generated'

export default class NFTPriceStats {
  floorPrice: AmountInEthAndUsd | null
  floorPriceSource: ValuationSource | null

  appraisal: AmountInEthAndUsd | null
  appraisalSource: ValuationSource | null

  constructor(nftPriceStats: Partial<NFTPriceStats>) {
    this.floorPrice = nftPriceStats?.floorPrice ?? null
    this.floorPriceSource = nftPriceStats?.floorPriceSource ?? null
    this.appraisal = nftPriceStats?.appraisal ?? null
    this.appraisalSource = nftPriceStats?.appraisalSource ?? null
  }

  public static createFromBackendModel(stats: NFTPriceStatsSchemaBackendSchema): NFTPriceStats {
    return new NFTPriceStats({
      floorPrice: stats.floor_price ? AmountInEthAndUsd.createFromBackendModel(stats.floor_price) : null,
      floorPriceSource: stats.floor_price_source ? parseSourceFromBackend(stats.floor_price_source) : null,
      appraisal: stats.appraisal ? AmountInEthAndUsd.createFromBackendModel(stats.appraisal) : null,
      appraisalSource: stats.appraisal_source ? parseSourceFromBackend(stats.appraisal_source) : null,
    })
  }
}
