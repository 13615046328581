<template>
  <div
    ref="menuContainer"
    class="mobile-nav-menu">
    <PWNLogo
      is-link
      height="1.5rem"
      :to-link="RouteName.RevampHomepage"
      :class="['mobile-nav-menu__logo', {
        'mobile-nav-menu__logo--hidden': searchIsOpen
      }]"/>

    <MobileNavSearch
      v-if="!isStarknet"
      :search-is-open="searchIsOpen"
      :handle-open-search="handleOpenSearch"
      :handle-close-search="handleCloseSearch"/>

    <ChainFilterDropdown
      v-if="enabledChains.length > 1"
      :selected-chains="selectedChains!"
      :set-chain="selectedChainStore.actions.setSelectedChain"/>

    <div
      v-if="!searchIsOpen"
      :class="['mobile-nav-menu__button-open']">
      <ExpandMenuButton
        :menu-is-open="menuIsOpen"
        :handle-open-menu="handleOpenMenu"/>
    </div>
  </div>

  <ul
    :class="['mobile-nav-menu__content', {
      'mobile-nav-menu__content--active': menuIsOpen
    }]">
    <router-link
      v-for="(navigationItem, index) in navigationItems"
      :key="index"
      v-slot="{ navigate }"
      class="mobile-nav-menu__element"
      :to="{ name: navigationItem.pathname, params: navigationItem.params }"
      @click="closeMenu">
      <BaseLink
        :to="getToRoute(navigationItem)"
        class="mobile-nav-menu__element"
        @click="() => navigateAndClose(navigate)">
        <li>
          {{ navigationItem.label }}
        </li>
      </BaseLink>
    </router-link>
  </ul>
</template>

<script setup lang="ts">

import { ref } from 'vue'
import BaseLink from '@/general-components/BaseLink.vue'
import ExpandMenuButton from '@/layout/header/ExpandMenuButton.vue'
import MobileNavSearch from '@/layout/header/MobileNavSearch.vue'
import PWNLogo from '@/general-components/PWNLogo.vue'
import { onClickOutside } from '@vueuse/core'
import ChainFilterDropdown from '@/revamp/components/ChainFilterDropdown.vue'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import RouteName from '@/router/RouteName'

const selectedChainStore = useSelectedChainsStore()
const { selectedChains } = storeToRefs(selectedChainStore)

type NavigationItem = {
  pathname: string
  label: string
  params?: Record<string, string | undefined>
}

type Props = {
  navigationItems: NavigationItem[]
}

defineProps<Props>()

const searchIsOpen = ref(false)
const menuIsOpen = ref(false)
const menuContainer = ref(null)

const handleOpenMenu = () => {
  menuIsOpen.value = !menuIsOpen.value
  searchIsOpen.value = false
}

const handleOpenSearch = () => {
  searchIsOpen.value = true
  menuIsOpen.value = false
}

const handleCloseSearch = () => {
  searchIsOpen.value = false
}

const getToRoute = (navigationItem: NavigationItem) => {
  return navigationItem.params ? { name: navigationItem.pathname, params: navigationItem.params } : { name: navigationItem.pathname }
}

const closeMenu = () => {
  menuIsOpen.value = false
}

const navigateAndClose = (navigate: () => void) => {
  navigate()
  closeMenu()
}

onClickOutside(menuContainer, (refValue) => {
  closeMenu()
})

</script>

<style scoped>
.mobile-nav-menu {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  position: relative;

  &__logo {
    display: flex;
    opacity: 1;
    align-items: center;

    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;

    &--hidden {
      transform: translateX(80px);
      opacity: 0;
      margin: 0;
    }
  }

  &__button-open {
    position: relative;
  }

  &__content {
    position: absolute;
    width: 100%;
    left: 0;
    top: 60px;
    opacity: 0;
    background-color: var(--background-color);
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    transform: translateY(-130%);

    transition: opacity 0.3s ease-in-out, transform 0.4s ease-out;

    &--active {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__element {
    padding: 1rem 0;
    text-decoration: none;
  }
}

</style>
