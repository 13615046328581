<template>
  <div class="pwn-safe-settings-network">
    <div class="pwn-safe-settings-network__title">
      Network
    </div>
    <ChainInfo
      v-if="selectedPwnSafe"
      class="pwn-safe-settings-network__network-info"
      :chain-id="selectedPwnSafe.chainId!"/>
  </div>
</template>

<script lang="ts" setup>
import ChainInfo from '@/general-components/ChainInfo.vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { storeToRefs } from 'pinia'

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)
</script>

<style scoped>
.pwn-safe-settings-network {
  border: 1px solid var(--text-color);
  padding: 1rem;
  max-height: 7rem;

  &__title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: var(--font-family-autoscape);
    margin-bottom: 1rem;
  }

  &__network-info {
    font-size: 1.5rem;
  }

  @media only screen and (--mobile-viewport) {
    &__title {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    &__network-info {
      font-size: 1.25rem;
    }
  }
}
</style>
