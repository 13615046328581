<template>
  <div class="section-listings">
    <BaseAssetPageSection
      icon="listings"
      question-mark-tooltip="Active listings of this asset by it’s owner/s from different NFT marketplaces"
      :has-data="hasData"
      :is-fetching="isFetchingListings"
      label="Listings">
      <template #content>
        <div class="section-listings__content">
          <BaseTable
            :columns="TABLE_DEFINITION"
            :variant="TableVariant.AssetPage"
            :is-hoverable="false"
            :is-fetching="isFetchingListings"
            table-max-height="254px"
            table-min-width="700px"
            :items="sortedListings">
            <template #price="{ item: listing }">
              <TokenAndEthAndUsdPriceLabel
                class="section-listings__price"
                :token="listing.orderAsset"/>
            </template>

            <template #quantity="{ item: listing }">
              <div
                v-if="listing.quantity"
                class="section-listings__floor-difference">
                {{ listing.quantity }}
              </div>
            </template>

            <template #expiration="{ item: listing }">
              <div
                v-if="listing.expiration"
                class="section-listings__expiration">
                {{ DateUtils.displayDate(listing.expiration) }}
              </div>
            </template>

            <template #from="{ item: listing }">
              <div class="section-listings__ens">
                <CryptoAddress
                  :address="listing.creatorAddress"
                  :chain-id="asset?.chainId"/>
              </div>
            </template>

            <template #link="{ item: listing }">
              <BaseLink
                target="_blank"
                :to="getMarketplaceLink(listing)">
                <img
                  v-if="listing.marketplace === DataSourceType.OPENSEA"
                  class="section-listings__marketplace-icon"
                  src="@/assets/icons/opensea.svg?url"
                  alt="opensea">
                <img
                  v-else-if="listing.marketplace === DataSourceType.LOOKSRARE"
                  class="section-listings__marketplace-icon"
                  src="@/assets/icons/looksrare.svg?url"
                  alt="opensea">
                <OpenIconSvg
                  class="section-listings__link-icon"
                  alt="open"/>
              </BaseLink>
            </template>
          </BaseTable>
        </div>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import BaseTable from '@/general-components/BaseTable.vue'
import { TABLE_DEFINITION } from '@/modules/pages/asset/nft-page/SectionListings/SectionListingsTableDefinitions'
import DateUtils from '@/utils/DateUtils'
import TableVariant from '@/general-components/TableVariant'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import useSectionListings from '@/modules/pages/asset/nft-page/SectionListings/useSectionListings'
import TokenAndEthAndUsdPriceLabel from '@/general-components/TokenAndEthAndUsdPriceLabel.vue'
import { getNftDetailLinkOnRelatedMarketplace } from '@/utils/chain'
import type { NFTOrder } from '@/modules/common/assets/typings/NFTOrder'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import { computed } from 'vue'
import OpenIconSvg from '@/assets/icons/open.svg'

const { asset } = useAssetPage()
const { isFetchingListings, sortedListings } = useSectionListings()

const hasData = computed(() => sortedListings.value.length > 0)

const getMarketplaceLink = (listing: NFTOrder): string => {
  // @ts-expect-error FIXME: strictNullChecks
  return getNftDetailLinkOnRelatedMarketplace(listing.marketplace, asset.value.chainId, asset.value.address, asset.value.tokenId)
}
</script>

<style scoped>
.section-listings {
  &__content {
    height: var(--default-asset-section-height);
  }

  &__ens {
    padding: 0 0.2rem;
  }

  &__link-icon {
    margin-left: 0.5rem;
    fill: var(--primary-color-1);
  }

  &__marketplace-icon {
    width: 1.2rem;
    height: 1rem;
  }
}

</style>
