<template>
  <div
    :class="['notification', `notification--${notification.variant}`, { 'notification--new': !notification.hasSeen && markAsSeenOnHover, 'notification--hoverable': markAsSeenOnHover }]"
    @mouseover="hoverAction">
    <div class="notification__tokens">
      <component
        :is="notification.customImage"
        v-if="notification.customImage"/>

      <div v-else>
        <TokenMedia
          v-if="!!notification.firstAsset"
          :token="notification.firstAsset"
          :width="imageSize"
          :height="imageSize"
          :class="['notification__collateral', { 'notification__collateral--composite': hasCompositeImage }]"/>
        <TokenMedia
          v-if="!!notification.secondAsset"
          :token="notification.secondAsset"
          :width="imageSize"
          :height="imageSize"
          :class="['notification__asset', { 'notification__asset--composite': hasCompositeImage }]"/>
      </div>
    </div>
    <div class="notification__body">
      <div class="notification__content">
        <NotificationTypeIcon
          class="notification__type-icon"
          :variant="notification.variant"/>
        <span :class="['notification__message', `notification__message--${notification.variant}`]">
          {{ notification.message }}
        </span>
      </div>
      <div class="notification__loan-id-tx-details-container">
        <div v-if="notification.loanOnChainId">
          Loan:
          <BaseLink
            :to="notification.loanDetailRoute"
            class="link--font-oxygen-mono notification__loan-id"
            @click="emit('loan-id-click')">
            {{ '#' + notification.loanOnChainId }}
          </BaseLink>
        </div>
        <div v-else-if="notification.proposalId">
          Proposal:
          <BaseLink
            :to="notification.proposalDetailRoute"
            class="link--font-oxygen-mono notification__loan-id"
            @click="emit('loan-id-click')">
            {{ '#' + notification.proposalId }}
          </BaseLink>
        </div>
        <div
          v-if="notification.txDetails"
          class="notification__loan-id-tx-details-divider"/>
        <div
          v-if="notification.txDetails"
          class="notification__transaction-container notification__transaction-container--tx-details">
          <BaseLink
            class="link--font-oxygen-mono  notification__transaction"
            target="_blank"
            :to="notification.txDetails">
            {{ 'TX Details' }}
            <img
              class="notification__link-icon"
              src="@/assets/icons/open.svg?url"
              alt="open">
          </BaseLink>
        </div>
      </div>
      <div v-if="notification.customFunctionAction">
        <span
          class="link link--font-oxygen-mono"
          @click="notification.customFunctionAction">{{
            notification.customFunctionActionName }}</span>
      </div>
      <div
        v-if="notification.to"
        class="notification__transaction-container">
        <span v-if="notification.actionPrompt">
          {{ notification.actionPrompt }}&nbsp;
        </span>
        <BaseLink
          class="link--font-oxygen-mono notification__transaction"
          :target="notification.actionTarget"
          :to="notification.to">
          <span :class="{ 'notification__transaction--teal': notification.actionPrompt }">
            {{ notification.actionName }}
          </span>
          <img
            v-if="!notification.actionPrompt"
            class="notification__link-icon"
            src="@/assets/icons/open.svg?url"
            alt="open">
        </BaseLink>
      </div>
      <div class="notification__chaininfo-date-wrap">
        <ChainInfo
          v-if="notification.chainId"
          class="notification__chain-info"
          :chain-id="notification.chainId"/>
        <div class="notification__date">
          <span>{{ notification.displayCreateDate.split('. ').join('/') + ' ' + notification.displayCreateTime }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import NotificationTypeIcon from '@/general-components/NotificationTypeIcon.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import type Notification from '@/modules/common/notifications/Notification'
import ChainInfo from '@/general-components/ChainInfo.vue'
import useNotifications from '@/modules/common/notifications/useNotifications'

interface Props {
  notification: Notification
  markAsSeenOnHover: boolean
}
const props = defineProps<Props>()
const { markAsSeenOnHover, notification } = toRefs(props)

const emit = defineEmits<{(e: 'loan-id-click'): void}>()

const markNotificationAsSeenOnHover = async (): Promise<void> => {
  return await useNotifications().markNotificationsAsSeen([notification.value.id!])
}

const hoverAction = computed(() => {
  return markAsSeenOnHover.value && !notification.value.hasSeen ? markNotificationAsSeenOnHover : undefined
})

const hasCompositeImage = computed(() => notification.value.firstAsset?.image && notification.value.secondAsset?.image)

const imageSize = computed(() => hasCompositeImage.value ? '58' : '87')
</script>

<style>
/* reset component wrap styles */
.Vue-Toastification__container.bottom-right {
  bottom: 50px !important;
  right: 50px !important;

  @media only screen and (--mobile-viewport) {
    bottom: 80px !important;
    right: 0 !important;
    margin: auto;
  }
}

.Vue-Toastification__container {
  padding: 0 !important;
  width: 457px !important;

  @media only screen and (--mobile-viewport) {
    width: var(--mobile-app-min-width) !important;
    bottom: 0 !important;
    right: 0 !important;
  }
}

.Vue-Toastification__toast {
  max-width: 100% !important;
  min-height: 126px !important;
  margin: 60px 0 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  overflow: visible !important;

  &:not(:first-child) {
    /* to prevent double borders with multiple notifications stacking on top of each other */
    box-shadow: 0 2px var(--background-color);
  }

  &--info {
    color: var(--text-color) !important;
  }

  @media only screen and (--mobile-viewport) {
    margin-top: 1rem !important;
  }
}

.Vue-Toastification__toast-component-body {
  width: 100%;
}

.Vue-Toastification__close-button {
  position: absolute;
  right: 14px;
  top: 14px;
  opacity: 1 !important;
  box-sizing: content-box;

  &:hover {
    opacity: 0.3 !important;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-enter-active {
  animation-name: fade-in;
  animation-duration: 750ms;
  animation-fill-mode: both;
}

.fade-leave-active {
  animation-name: fade-out;
  animation-duration: 750ms;
  animation-fill-mode: both;
}

.fade-move {
  transition-timing-function: ease-in-out;
  transition-property: all;
  transition-duration: 400ms;
}
</style>

<style scoped>
.notification {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0.5rem;

  font-family: var(--font-family-oxygen-mono);
  font-size: 1rem;
  line-height: 24px;
  background: var(--background-color);
  background-image: var(--border-white-dashed);

  &--hoverable {
    &:hover {
      background-color: var(--gray-3);
    }
  }

  &--new {
    background-color: var(--teal-4);
  }

  &--success {
    background-image: var(--border-success-dashed);
    box-shadow: 0 0 20px rgb(0 255 224 / 10%);
  }

  &--pending {
    background-image: var(--border-pending-dashed);
    box-shadow: 0 0 20px rgb(243 248 5 / 10%);
  }

  &--error {
    background-image: var(--border-error-dashed);
    box-shadow: 0 0 20px rgb(249 118 0 / 10%);
  }

  &--danger {
    background-image: var(--border-danger-dashed);
    box-shadow: 0 0 20px rgb(232 0 3 / 10%);
  }

  &__tokens {
    min-width: 87px;
    height: 87px;
    margin-right: 1rem;
    position: relative;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__collateral,
  &__asset {
    width: 87px;
    height: 87px;

    position: absolute;
    object-fit: contain;
    object-position: center;
  }

  &__collateral--composite,
  &__asset--composite {
    width: 58px;
    height: 58px;
  }

  &__asset {
    &--composite {
      left: 29px;
      top: 29px;
    }
  }

  &__content {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__message {
    padding-right: 1.25rem;
    /* do not overflow to absolutely positioned close icon */
    overflow: hidden;
    display: box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    color: var(--text-color);

    &--success {
      color: var(--primary-color-1);
    }

    &--pending {
      color: var(--warning);
    }

    &--error {
      color: var(--negative-1);
    }

    &--danger {
      color: var(--danger-1);
    }
  }

  &__type-icon {
    margin-right: 1rem;
  }

  &__transaction-container {
    display: flex;

    &--tx-details {
      margin-top: 0.5rem;
    }
  }

  &__transaction {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;
    margin-top: 0.2rem;

    &--teal {
      color: var(--primary-color-1);
      text-decoration: underline;
      text-decoration-color: var(--primary-color-1);
    }
  }

  &__loan-id {
    color: var(--primary-color-1);
  }

  &__loan-id-tx-details-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__loan-id-tx-details-divider {
    width: 1px;
    height: 60%;
    background-color: #434343;
    margin-left: 16px;
    margin-right: 16px;
  }

  &__link-icon {
    margin-left: 0.625rem;
  }

  &__chain-info {
    justify-content: flex-end;
    margin-top: auto;
  }

  &__date {
    font-family: var(--font-family-oxygen-mono);
    font-size: 12px;
  }

  &__chaininfo-date-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    color: var(--text-color);
  }

  @media only screen and (--mobile-viewport) {
    &__tokens,
    &__collateral,
    &__asset {
      width: 32px;
      height: 32px;
      margin-right: 1rem;
      min-width: 0;
    }

    &__collateral--composite,
    &__asset--composite {
      width: 21px;
      height: 21px;
    }

    &__asset {
      &--composite {
        left: 10px;
        top: 10px;
      }
    }

    &__type-icon {
      display: none;
    }
  }
}
</style>
