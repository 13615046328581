<template>
  <div class="button_wrapper">
    <template v-if="isLink">
      <BaseLink
        :to="to!"
        :is-darkening-effect="isDarkeningEffect"
        :target="linkTarget"
        :is-underlined="false">
        <button
          class="button"
          :class="buttonClasses"
          :disabled="isDisabled"
          :type="isTypeSubmit ? 'submit' : 'button'"
          @click="onButtonClick">
          <slot
            name="iconBeforeText"/>
          <span
            v-if="buttonText"
            class="button__text"
            :class="[{
              'button__text--wrap-on-mobile': forceSmallScreenTextWrap,
            }]">{{ buttonText }}
          </span>
          <slot
            name="icon-after-text"/>
        </button>
      </BaseLink>
    </template>
    <template v-else>
      <button
        class="button"
        :class="buttonClasses"
        :disabled="isDisabled"
        :type="isTypeSubmit ? 'submit' : 'button'"
        @click="onButtonClick">
        <div
          v-if="$slots.iconBeforeText"
          style="margin-right: 0.5rem;">
          <slot
            name="iconBeforeText"/>
        </div>
        <span
          v-if="buttonText"
          class="button__text"
          :class="[{
            'button__text--wrap-on-mobile': forceSmallScreenTextWrap,
          }]">{{ buttonText }}
        </span>
        <slot
          name="icon-after-text"/>
        <slot
          name="icon"/>
      </button>
    </template>
    <NewIcon
      v-if="isNew"
      class="button_wrapper__navigation-item-new-icon"/>
  </div>
</template>

<script lang="ts">
export enum ButtonColor {
  Primary = 'primary',
  PrimaryDark = 'primary-dark',
  Transparent = 'transparent',
  Orange = 'orange',
  Warning = 'warning',
  White = 'white',
  Gray = 'gray',
}

export enum ButtonVariant {
  Default = 'default',
  Outline = 'outline',
  InsideInput = 'inside-input',
  Underline = 'underline',
}

export enum ButtonFont {
  OxygenMono = 'oxygen-mono',
  AutoScape = 'autoscape',
  Supreme = 'supreme',
  Screener = 'screener'
}

export enum ButtonSize {
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}

export enum ButtonTextColorOverride {
  Primary = 'primary',
  Negative = 'negative'
}
</script>

<script setup lang="ts">
/* eslint-disable import/first */
import BaseLink from '@/general-components/BaseLink.vue'
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import NewIcon from '@/assets/icons/new-icon.svg'

interface Props {
  buttonText?: string // not passed in case of e.g. using only #icon slot
  to?: string | RouteLocationRaw // ''
  linkTarget?: '_blank' | '_self' | '_parent' | '_top'
  isDisabled?: boolean
  isFullWidth?: boolean
  isPulsing?: boolean
  isNoEnlargeEffect?: boolean
  isDarkeningEffect?: boolean
  color?: ButtonColor
  variant?: ButtonVariant
  font?: ButtonFont
  size?: ButtonSize
  textColorOverride?: ButtonTextColorOverride
  forceSmallScreenTextWrap?: boolean
  hasTransparentBackground?: boolean
  isTypeSubmit?: boolean
  hasWhiteHover?: boolean
  isNew?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  linkTarget: '_self',
  isDisabled: false,
  isFullWidth: false,
  isPulsing: false,
  isNoEnlargeEffect: true,
  isDarkeningEffect: false,
  color: ButtonColor.Primary,
  variant: ButtonVariant.Default,
  font: ButtonFont.Screener,
  size: ButtonSize.L,
  forceSmallScreenTextWrap: false,
  hasWhiteHover: false,
})

const emit = defineEmits<
  {(e: 'onButtonClick'): void}
>()

defineSlots<{
  // slots return "any" atm as described in official docs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon(props: unknown): any
  iconBeforeText(props: unknown): any
}>()

// @ts-expect-error FIXME: strictNullChecks
const isLink = computed(() => ![null, undefined].includes(props.to))
const buttonClasses = computed(() => {
  return [
    { 'button--full-width': props.isFullWidth },
    `button--${props.color}`,
    `button--variant-${props.variant}`,
    `button--font-${props.font}`,
    `button--size-${props.size}`,
    props.isNoEnlargeEffect && 'button--no-enlargement-effect',
    props.isPulsing && 'button--pulsing',
    props.textColorOverride && `button--text-color-override-${props.textColorOverride}`,
    props.hasTransparentBackground && 'button--transparent-background',
    props.hasWhiteHover && 'button--gray__white-hover',
  ]
})

const onButtonClick = (): void => emit('onButtonClick')
</script>

<style scoped>
.button {
  &__text {
    white-space: nowrap;
  }
}

.button_wrapper {
  position: relative;

  &__navigation-item-new-icon {
    position: absolute;
    top: -0.2rem;
    right: -0.2rem;
    /* Adjust as needed */
    transform: translate(50%, -50%);
  }

  .button {
    display: inline-flex;
    flex-direction: row;
    max-height: 100%;
    height: 100%;
    transition: all var(--primary-transition-time);
    color: var(--primary-color-1);
    border: 0 solid transparent;
    align-items: center;
    line-height: 100%;

    &__text {
      white-space: nowrap;

      &--wrap-on-mobile {
        white-space: normal !important;
      }
    }

    &--transparent-background {
      background: transparent !important;
    }

    &:hover {
      &:disabled {
        cursor: not-allowed;
        transform: scale(1);
      }

      &:not(:disabled) {
        cursor: pointer;

        /* stylelint-disable-next-line selector-not-notation */
        &:not(.button--variant-inside-input):not(.button--no-enlargement-effect) {
          transform: scale(1.035);
        }
      }
    }

    &--no-enlargement-effect:hover {
      transform: scale(1) !important;
    }

    &--size {
      &-s {
        height: 1.75rem;
        padding: 0.375rem 0.625rem;
      }

      &-m {
        height: 2rem;
        padding: 0 1rem;
      }

      &-l {
        justify-content: center;
        height: 2.5rem;
        padding: 0 1rem;
      }

      &-xl {
        font-size: 1rem;
        padding: 1.2rem 1.4rem;
      }

      &-xxl {
        font-size: 1.5rem;
        padding: 1rem 4rem;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;

        max-height: 3rem;
      }
    }

    &--full-width {
      width: 100%;
      justify-content: center;
    }

    &--primary {
      color: var(--primary-color-1);
      background: var(--primary-color-3);
      border-color: var(--primary-color-1);

      &:hover {
        background: var(--primary-color-2);
      }

      &:disabled {
        opacity: 0.5;

        &:is(.button--variant-outline) {
          background: var(--background-color);
        }
      }
    }

    &--primary-dark {
      color: var(--primary-color-1);
      background-color: transparent;
      border-color: var(--primary-color-3);

      &:hover {
        border-color: var(--primary-color-1);
        opacity: 1;
        filter: none;
        transform: none !important;
        /* TODO think about better BaseButton options (colors, variants, etc.)s */
      }
    }

    &--orange {
      background: var(--orange-3);
      color: var(--orange-1);
      border-color: var(--orange-1);

      &:hover {
        background: var(--orange-2) !important;
      }

      &:disabled {
        color: var(--orange-3);
        background: var(--orange-4);
      }
    }

    &--warning {
      background: var(--warning-3);
      color: var(--warning-1);
      border-color: var(--warning-3);

      &:hover {
        background: var(--warning-2);
      }

      &:disabled {
        color: var(--warning-2);
        background: var(--warning-4);
      }
    }

    &--gray {
      border-color: var(--gray);
      background-color: var(--gray-3);
      color: var(--gray-2);
    }

    &--gray__white-hover {
      border-color: var(--gray);
      background-color: var(--text-color);
      color: var(--text-color);
      transition: border-color 0.3s, color 0.3s;

      &:hover {
        border-color: var(--text-color);
        color: var(--text-color);
      }
    }

    &--white {
      color: var(--text-color);
      background: transparent;
      border-color: var(--text-color);

      &:disabled {
        opacity: 0.5;

        border-color: var(--gray);
        color: var(--gray-2);
      }
    }

    &--variant {
      &-outline {
        border-width: 1px;
        background: transparent;
      }

      &-outline .button--orange {
        border-width: 1px;

        background: var(--primary-color-5);
      }

      &-underline {
        padding-left: 0;
        padding-right: 0;
        background: transparent;

        &:hover {
          background-color: transparent;
        }
      }

      &-underline .button__text {
        border-bottom: 1px solid currentcolor;
      }

      &-inside-input {
        height: 100%;
        background: transparent;
        padding: 0 0.3rem 0 0;

        &:disabled {
          background: transparent;
        }

        &:hover {
          background: transparent;
        }

        /* stylelint-disable max-nesting-depth */
        .button__text {
          white-space: nowrap;
          background: var(--primary-color-3);
          padding: 0.1rem 0.25rem;
          transition: all var(--primary-transition-time);

          &:hover {
            background: var(--primary-color-2);
          }
        }
      }
    }

    &--font {
      &-oxygen-mono {
        font-family: var(--font-family-oxygen-mono);
      }

      &-autoscape {
        font-family: var(--font-family-autoscape);
      }

      &-supreme {
        font-family: var(--font-family-supreme);
      }

      &-screener {
        font-family: var(--font-family-screener);
      }
    }

    @keyframes pulse {
      0% {
        box-shadow: var(--primary-color-4);
      }

      50% {
        box-shadow: 0 0 1.875rem 0.3125rem rgb(var(--primary-color-base) / 17%);
      }

      100% {
        box-shadow: var(--primary-color-4);
      }
    }

    &--pulsing {
      animation: pulse 3s infinite ease-in-out;
    }

    &--is-icon-before-text {
      gap: 0.5rem;
    }

    &--text-color-override {
      &-primary {
        color: var(--primary-color-1);
      }

      &-negative {
        color: var(--negative-1);
      }
    }
  }
}
</style>
