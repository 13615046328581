import type { WalletStatsBackendSchema } from '@/modules/common/backend/generated'

export default class WalletStats {
  totalLent: number
  totalLentVolumeUsd: string
  totalLentVolumeEth: string
  totalBorrowed: number
  totalBorrowedVolumeUsd: string
  totalBorrowedVolumeEth: string
  totalEarned: number
  totalEarnedVolumeUsd: string
  totalEarnedVolumeEth: string
  activeLentLoans: number
  activeLentLoansVolumeUsd: string
  activeLentLoansVolumeEth: string
  activeBorrowedLoans: number
  activeBorrowedLoansVolumeUsd: string
  activeBorrowedLoansVolumeEth: string
  defaultedLentLoans: number
  defaultedLentLoansVolumeUsd: string
  defaultedLentLoansVolumeEth: string
  defaultedBorrowedLoans: number
  defaultedBorrowedLoansVolumeUsd: string
  defaultedBorrowedLoansVolumeEth: string
  paidBackLentLoans: number
  paidBackLentLoansVolumeUsd: string
  paidBackLentLoansVolumeEth: string
  paidBackBorrowedLoans: number
  paidBackBorrowedLoansVolumeUsd: string
  paidBackBorrowedLoansVolumeEth: string
  nextLentLoanDefault?: string
  nextBorrowedLoanDefault?: string
  totalRequests: number
  totalRequestsVolumeUsd: string
  totalRequestsVolumeEth: string

  constructor(walletStats: Partial<WalletStats>) {
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalLent = walletStats?.totalLent
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalLentVolumeUsd = walletStats?.totalLentVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalLentVolumeEth = walletStats?.totalLentVolumeEth
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalBorrowed = walletStats?.totalBorrowed
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalBorrowedVolumeUsd = walletStats?.totalBorrowedVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalBorrowedVolumeEth = walletStats?.totalBorrowedVolumeEth
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalEarned = walletStats?.totalEarned
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalEarnedVolumeUsd = walletStats?.totalEarnedVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalEarnedVolumeEth = walletStats?.totalEarnedVolumeEth
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.activeLentLoans = walletStats?.activeLentLoans
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.activeLentLoansVolumeUsd = walletStats?.activeLentLoansVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.activeLentLoansVolumeEth = walletStats?.activeLentLoansVolumeEth
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.activeBorrowedLoans = walletStats?.activeBorrowedLoans
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.activeBorrowedLoansVolumeUsd = walletStats?.activeBorrowedLoansVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.activeBorrowedLoansVolumeEth = walletStats?.activeBorrowedLoansVolumeEth
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.defaultedLentLoans = walletStats?.defaultedLentLoans
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.defaultedLentLoansVolumeUsd = walletStats?.defaultedLentLoansVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.defaultedLentLoansVolumeEth = walletStats?.defaultedLentLoansVolumeEth
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.defaultedBorrowedLoans = walletStats?.defaultedBorrowedLoans
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.defaultedBorrowedLoansVolumeUsd = walletStats?.defaultedBorrowedLoansVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.defaultedBorrowedLoansVolumeEth = walletStats?.defaultedBorrowedLoansVolumeEth
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.paidBackLentLoans = walletStats?.paidBackLentLoans
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.paidBackLentLoansVolumeUsd = walletStats?.paidBackLentLoansVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.paidBackLentLoansVolumeEth = walletStats?.paidBackLentLoansVolumeEth
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.paidBackBorrowedLoans = walletStats?.paidBackBorrowedLoans
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.paidBackBorrowedLoansVolumeUsd = walletStats?.paidBackBorrowedLoansVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.paidBackBorrowedLoansVolumeEth = walletStats?.paidBackBorrowedLoansVolumeEth
    this.nextLentLoanDefault = walletStats?.nextLentLoanDefault
    this.nextBorrowedLoanDefault = walletStats?.nextBorrowedLoanDefault
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalRequests = walletStats?.totalRequests
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalRequestsVolumeUsd = walletStats?.totalRequestsVolumeUsd
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.totalRequestsVolumeEth = walletStats?.totalRequestsVolumeEth
  }

  public static createFromBackendModel(walletStats: WalletStatsBackendSchema): WalletStats {
    return new WalletStats({
      totalLent: walletStats?.total_lent,
      totalLentVolumeUsd: walletStats?.total_lent_value_usd,
      totalLentVolumeEth: walletStats?.total_lent_value_eth,
      totalBorrowed: walletStats?.total_borrowed,
      totalBorrowedVolumeUsd: walletStats?.total_borrowed_value_usd,
      totalBorrowedVolumeEth: walletStats?.total_borrowed_value_eth,
      totalEarned: walletStats?.total_earned,
      totalEarnedVolumeUsd: walletStats?.total_earned_value_usd,
      totalEarnedVolumeEth: walletStats?.total_earned_value_eth,
      activeLentLoans: walletStats?.active_lent_loans,
      activeLentLoansVolumeUsd: walletStats?.active_lent_loans_value_usd,
      activeLentLoansVolumeEth: walletStats?.active_lent_loans_value_eth,
      activeBorrowedLoans: walletStats?.active_borrowed_loans,
      activeBorrowedLoansVolumeUsd: walletStats?.active_borrowed_loans_value_usd,
      activeBorrowedLoansVolumeEth: walletStats?.active_borrowed_loans_value_eth,
      defaultedLentLoans: walletStats?.defaulted_lent_loans,
      defaultedLentLoansVolumeUsd: walletStats?.defaulted_lent_loans_value_usd,
      defaultedLentLoansVolumeEth: walletStats?.defaulted_lent_loans_value_eth,
      defaultedBorrowedLoans: walletStats?.defaulted_borrowed_loans,
      defaultedBorrowedLoansVolumeUsd: walletStats?.defaulted_borrowed_loans_value_usd,
      defaultedBorrowedLoansVolumeEth: walletStats?.defaulted_borrowed_loans_value_eth,
      paidBackLentLoans: walletStats?.paid_back_lent_loans,
      paidBackLentLoansVolumeUsd: walletStats?.paid_back_lent_loans_value_usd,
      paidBackLentLoansVolumeEth: walletStats?.paid_back_lent_loans_value_eth,
      paidBackBorrowedLoans: walletStats?.paid_back_borrowed_loans,
      paidBackBorrowedLoansVolumeUsd: walletStats?.paid_back_borrowed_loans_value_usd,
      paidBackBorrowedLoansVolumeEth: walletStats?.paid_back_borrowed_loans_value_eth,
      nextLentLoanDefault: walletStats?.next_lent_loan_default ?? undefined,
      nextBorrowedLoanDefault: walletStats?.next_borrowed_loan_default ?? undefined,
      totalRequests: walletStats?.total_requests,
      totalRequestsVolumeUsd: walletStats?.total_requests_value_usd,
      totalRequestsVolumeEth: walletStats?.total_requests_value_eth,
    })
  }
}
