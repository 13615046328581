import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import AssetType from '@/modules/common/assets/AssetType'
import { compareAddresses, compareAssets, isEns, shortenAddress } from '@/utils/utils'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import deepcopy from '@/utils/deepcopyinstance'
import { useEnsStore } from '@/modules/common/web3/useEnsStore'
import { TRANSFER_ASSET_SORT_OPTIONS_LOOKUP, TransferSortOption } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import { isAddress } from 'viem'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import type { SupportedChain } from '@/constants/chains/types'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { storeToRefs } from 'pinia'

const assetsToTransfer = ref<(AssetWithAmount)[]>([])
const fromWallet = ref<PwnSafe>()
const toWallet = ref<PwnSafe>()
const addressTo = ref('')

const searchTerm = ref('')
const displayNfts = ref(false)
const displayCoins = ref(false)

const selectedSelectionSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-your-tokens',
  { id: TransferSortOption.Name, direction: SortDirection.Descending, viewName: 'sort-option-your-tokens' },
  Object.keys(TRANSFER_ASSET_SORT_OPTIONS_LOOKUP),
))

export default function usePwnSafeTransfer() {
  const pwnSafeDetailStore = usePwnSafeDetailStore()
  const { pwnSafes } = storeToRefs(pwnSafeDetailStore)

  const { address: userAddress } = useCustomAccount()
  const connectedAccountTypeStore = useConnectedAccountTypeStore()
  const { isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)

  const isTransferFromPwnSafe = computed(() => {
    return !compareAddresses(fromWallet.value?.safeAddress, userAddress.value) || isConnectedPwnSafe.value
  })

  const isTransferToPwnSafe = computed(() => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    return toWallet.value.safeAddress in pwnSafes
  })

  const isTransferToCustomAddress = computed(() => {
    return !isTransferToPwnSafe.value && !compareAddresses(toWallet.value?.safeAddress, userAddress.value)
  })

  const isUserTransfer = computed(() => !isTransferToPwnSafe.value || !isTransferFromPwnSafe.value)

  const handleCheckboxFilter = (asset: AssetWithAmount) => {
    if (!displayNfts.value && !displayCoins.value) return true

    if (asset.category === AssetType.ERC721 || asset.category === AssetType.ERC1155) {
      return displayNfts.value
    }
    if (asset.category === AssetType.ERC20) {
      return displayCoins.value
    }
    return false
  }

  const filteredAssetsToTransferByName = computed(() => {
    const filteredAssetsToTransfer = assetsToTransfer.value.filter(handleCheckboxFilter)
    return filteredAssetsToTransfer

    // todo: this search filter doesn't make sense here. The assets remain selected and it's visually confusing
    // return filterAssetsInSearchResults(filteredAssetsToTransfer, searchTerm.value)
  })

  const sortedAndFilteredAssetsToTransfer = computed(() => {
    return TRANSFER_ASSET_SORT_OPTIONS_LOOKUP[selectedSelectionSortOption.value.id]?.(filteredAssetsToTransferByName.value, selectedSelectionSortOption.value.direction)
  })

  const handleOnAssetSelect = (asset: AssetWithAmount) => {
    const index = assetsToTransfer.value.findIndex(selectedAsset => asset.id === selectedAsset.id)

    if (index === -1) {
      const assetCopy = deepcopy(asset)
      assetsToTransfer.value.push(assetCopy)
    } else {
      assetsToTransfer.value.splice(index, 1)
    }
  }

  const isAssetSelected = ({ asset, selectedAssets }: {asset: AssetWithAmount, selectedAssets: Ref<AssetWithAmount[]>}): boolean => {
    if (asset.isNativeToken && selectedAssets.value.some(selectedAsset => selectedAsset.isNativeToken)) {
      return true
    }
    return selectedAssets.value.some(selectedAsset => compareAssets({ assetA: selectedAsset, assetB: asset }))
  }

  // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
  const isValidAddress = computed(() => isAddress(toWallet.value?.safeAddress) || !addressTo.value)

  const handleAddressInputChange = async (addressInput: string, chainId: SupportedChain) => {
    addressTo.value = addressInput
    if (isEns(addressTo.value)) {
      const address = await useEnsStore().resolveEnsName(addressTo.value)
      if (address) {
        // Need to create a mocked PWN Safe around wallet adress to integrate with current logic
        // future refactor around this https://www.notion.so/pwndao/0f177f2e103c4ba0a1fad0c4d2717c79?v=9a8f838972a44acea77fae62931c9d0d&p=aa326c1f394b459ab19a6dc8bb0ca2a1&pm=c
        toWallet.value = new PwnSafe({ name: addressTo.value, safeAddress: address, chainId })
      } else {
        // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'PwnSafe | u... Remove this comment to see the full error message
        toWallet.value = null
      }
    } else if (isAddress(addressInput)) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      toWallet.value = new PwnSafe({ name: shortenAddress(addressTo.value), safeAddress: addressTo.value, chainId })
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'PwnSafe | u... Remove this comment to see the full error message
      toWallet.value = null
    }
  }

  const handleWalletSelectChange = (newWallet: PwnSafe) => {
    addressTo.value = newWallet?.safeAddress
    toWallet.value = newWallet
  }

  return {
    searchTerm,
    selectedSelectionSortOption,
    assetsToTransfer,
    chainTarget: computed(() => assetsToTransfer.value[0]?.chainId),
    displayNfts,
    displayCoins,
    isTransferFromPwnSafe,
    isTransferToPwnSafe,
    sortedAndFilteredAssetsToTransfer,
    toWallet,
    isUserTransfer,
    handleOnAssetSelect,
    isAssetSelected,
    fromWallet,
    addressTo,
    isValidAddress,
    handleAddressInputChange,
    handleWalletSelectChange,
    isTransferToCustomAddress,
  }
}
