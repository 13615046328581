<template>
  <PwnSafePageHeader
    :label="headerLabel!"
    :icon="headerIcon"
    :address="fromWallet?.safeAddress!"
    @go-back="goBack"/>
  <PwnSafeActionHeader title="Transfer">
    <template #icons>
      <TransferIconSvg
        alt="transfer"
        class="pwn-safe-transfer-header__icon"/>
    </template>
    <template #subheading>
      <div class="pwn-safe-transfer-header__subheading">
        Please select assets to transfer:
      </div>
    </template>
  </PwnSafeActionHeader>
  <div class="pwn-safe-transfer-header__sorting-and-filtering">
    <BaseSearch
      v-model="searchTerm"
      :has-clear-button="hasSearchValue"
      :class="['pwn-safe-transfer-header__search', {'pwn-safe-transfer-header__search--selected': hasSearchValue}]"
      search-placeholder="Search asset name/ATR name/address"/>
    <ChainGroupFilter
      :set-chain="selectedCurrentWallet ? props.setSelectedChain : undefined"
      :show-all-networks="false"
      :disabled-chains="disabledChainsDependingOnSelectedAssetsChain"
      :selected-chains="[props.selectedChain]"/>
  </div>
</template>

<script setup lang="ts">
import PwnSafeActionHeader from '@/modules/pages/pwn-safe/PwnSafeActionHeader.vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import PwnSafePageHeader from '@/modules/pages/pwn-safe/PwnSafePageHeader.vue'
import { computed } from 'vue'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import * as safeIcon from '@/assets/icons/safe.svg'
import * as walletIcon from '@/assets/icons/wallet.svg'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import TransferIconSvg from '@/assets/icons/transfer.svg'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import { compareAddresses } from '@/utils/utils'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import type { SupportedChain } from '@/constants/chains/types'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { storeToRefs } from 'pinia'

type Props = {
  selectedChain: SupportedChain;
  setSelectedChain: (chain: SetChainFilterOption) => void;
}
const props = defineProps<Props>()

const { address: userAddress } = useCustomAccount()

const { searchTerm, fromWallet, isTransferFromPwnSafe, chainTarget } = usePwnSafeTransfer()
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe, disabledChains } = storeToRefs(pwnSafeDetailStore)
const router = useRouter()

const hasSearchValue = computed(() => searchTerm.value.length > 0)
const headerLabel = computed(() => isTransferFromPwnSafe.value ? selectedPwnSafe.value?.name : 'Current Wallet')
const headerIcon = computed(() => isTransferFromPwnSafe.value ? safeIcon.default : walletIcon.default)

const selectedCurrentWallet = computed(() => {
  return compareAddresses(selectedPwnSafe.value?.safeAddress, userAddress.value)
})

const disabledChainsDependingOnSelectedAssetsChain = computed(() => {
  if (chainTarget.value) {
    return enabledChains.filter(chain => chain !== chainTarget.value)
  }
  return disabledChains.value.filter(chain => chain !== props.selectedChain)
})

const goBack = () => {
  if (isTransferFromPwnSafe.value) {
    pwnSafeDetailStore.goBack(router)
  } else {
    router.push({ name: RouteName.PwnSafeCurrentWalletAssets })
  }
}
</script>

<style scoped>
.pwn-safe-transfer-header {
  &__icon {
    width: 2.375rem;
  }

  &__sorting-and-filtering {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__search {
    min-width: 24rem;
    font-size: 0.75rem;
    border: 1px solid var(--gray);
    padding: 0.25rem;
    height: 2.5rem;

    &--selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__buttons-container {
    display: flex;
  }

  &__sort {
    margin-left: 1rem;
  }

  @media only screen and (--mobile-viewport) {
    &__search {
      min-width: 100%;
    }

    &__sorting-and-filtering {
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>
