<template>
  <div
    ref="target"
    class="asset-price">
    <AssetPriceSourceIcon
      v-if="showSource && !!priceData?.priceSource"
      class="eth-and-usd-price-label__source-icon"
      data-source-icon-width-px="14"
      :link="coingeckoUrl"
      :data-source="priceData.priceSource"/>

    <slot
      :unit-price="currentUnitPrice"
      :price-data="priceData">
      <SafeText :text="priceDataToDisplay || '---'">
        <template #text>
          <SafeDisplayDecimals :value="priceDataToDisplay || '---'"/>
        </template>
      </SafeText>
    </slot>
  </div>
</template>

<script setup lang="ts">
import SafeText from '@/general-components/SafeText.vue'
import { useAssetPrice } from '@/revamp/hooks/asset-prices/useAssetPrice'
import type { Address } from 'viem'
import type AssetType from '@/modules/common/assets/AssetType'
import { computed, toRefs } from 'vue'
import AssetPriceSourceIcon from '@/general-components/data-source/AssetPriceSourceIcon.vue'
import { useGetCoingeckoUrl } from '@/modules/common/backend/generated'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import SafeDisplayDecimals from '@/general-components/safe-display-decimals/SafeDisplayDecimals.vue'

type Props = {
  contractAddress: string | Address;
  chainId: string;
  tokenId: string | null;
  category?: AssetType;
  showSource?: boolean
};

const props = defineProps<Props>()
const { chainId, contractAddress, tokenId, category, showSource } = toRefs(props)

const assetPriceModel = useAssetPrice(chainId, contractAddress, tokenId.value ? tokenId.value : null, category)
const { target, currentUnitPrice, priceDataToDisplay, priceData } = assetPriceModel

const assetChainId = computed(() => Number(chainId.value))
const queryIsEnabled = computed(() => !!assetChainId.value && !!contractAddress.value && showSource.value && priceData.value?.priceSource === DataSourceType.COINGECKO)

const coingeckoUrlQuery = useGetCoingeckoUrl(
  assetChainId,
  contractAddress,
  {
    query: {
      enabled: queryIsEnabled,
      staleTime: 1000 * 60 * 120,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: 2,
    },
  },
)

const coingeckoUrl = computed(() => coingeckoUrlQuery ? coingeckoUrlQuery.data?.value?.data : undefined)

</script>

<style scoped>
.asset-price {
  display: flex;
  text-align: right;
  gap: 0.25rem;

  max-width: 8rem;

  span {
    font-size: 14px;
    white-space: nowrap;
  }
}
</style>
