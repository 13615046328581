export const MIN_LOAN_DURATION_IN_DAYS = 1
export const DEFAULT_LOAN_DURATION_IN_DAYS = 30
export const NON_EXTENDED_MAX_LOAN_DURATION_IN_DAYS = 90
export const EXTENDED_MAX_LOAN_DURATION_IN_DAYS = 36500
export const DEFAULT_OFFER_EXPIRATION_IN_DAYS = 7

export const MIN_LOAN_REQUEST_EXPIRATION_IN_DAYS = 1
export const DEFAULT_LOAN_REQUEST_EXPIRATION_IN_DAYS = 7
export const MAX_LOAN_REQUEST_EXPIRATION_IN_DAYS = 30
export const APR_DECIMAL_POINT_PADDING = 2
export const CREDIT_PER_COLLATERAL_UNIT_DENOMINATOR = 38
