<template>
  <div class="single-asset-detail-step__container">
    <p>
      {{ topParagraph }}
    </p>
    <div class="single-asset-detail-step__asset-cards-container">
      <SelectedAtrTokenCard :atr-token="singleAtrTokenToClaim"/>
      <div
        v-if="hasMultipleAtrTokensToClaimAndBurn"
        class="single-asset-detail-step__add-more-assets"
        @click="handleClickClaimMultipleAssets">
        Add More Assets +
      </div>
    </div>
    <p>
      {{ actionDescription }}
    </p>
    <div class="single-asset-detail-step__buttons-container">
      <BaseButton
        button-text="cancel"
        :color="ButtonColor.White"
        :variant="ButtonVariant.Underline"
        @on-button-click="closeModal"/>
      <div class="single-asset-detail-step__checkbox-and-button-container">
        <BaseCheckbox
          v-if="showBurnAtrTokenCheckbox"
          v-model="isBurnAtrTokens"
          label="Burn ATR token after claim"/>
        <BaseButton
          :button-text="actionName"
          @on-button-click="handleClaimButtonClick"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import { computed, nextTick } from 'vue'
import usePwnSafeClaimAndBurnModal, { PwnSafeClaimAndBurnModalStep } from '@/modules/pages/pwn-safe/claim-and-burn/modal/usePwnSafeClaimAndBurnModal'
import SelectedAtrTokenCard from '@/general-components/SelectedAtrTokenCard.vue'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import usePwnSafeClaimAndBurn, { ClaimAndBurnActionType } from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import usePwnSafeAtrTokens from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeAtrTokens'
import { storeToRefs } from 'pinia'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'

const router = useRouter()

const { closeModal, activeStep } = usePwnSafeClaimAndBurnModal()
const { atrTokensToClaimAndBurn, isBurnAtrTokens, isBurnOnly, claimAndBurnActionType, actionDescription, actionName } = usePwnSafeClaimAndBurn()
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { isSelectedCurrentWallet } = storeToRefs(pwnSafeDetailStore)

const { sortedPwnSafeAtrTokens } = usePwnSafeAtrTokens()

const connectedAccountTypeStore = useConnectedAccountTypeStore()
const { isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)

const showBurnAtrTokenCheckbox = computed(() => !isBurnOnly.value && (isConnectedPwnSafe.value || !isSelectedCurrentWallet.value))

const singleAtrTokenToClaim = computed(() => {
  return atrTokensToClaimAndBurn.value?.[0]
})

const hasMultipleAtrTokensToClaimAndBurn = computed(() => sortedPwnSafeAtrTokens.value?.length > 1)

const handleClaimButtonClick = async () => {
  activeStep.value = PwnSafeClaimAndBurnModalStep.Waiting
}

const handleClickClaimMultipleAssets = async () => {
  const atrTokenToClaimAndBurnCopy = singleAtrTokenToClaim.value
  const isBurnAtrTokensCopy = isBurnAtrTokens.value
  router.push({ name: RouteName.PwnSafeClaimAndBurn })
  closeModal()
  await nextTick()
  // we are closing the modal when rerouting to claim/burn/claim and burn multiple page
  // due to the watcher in PwnSafeClaimAndBurnModal that resets the selected assets
  // to claim/burn/claim and burn when the modal is closed, we need to manually reassign here after closing
  atrTokensToClaimAndBurn.value = [atrTokenToClaimAndBurnCopy]
  isBurnAtrTokens.value = isBurnAtrTokensCopy
}

const topParagraph = computed(() => {
  switch (claimAndBurnActionType.value) {
  case ClaimAndBurnActionType.ClaimAndBurn:
    return 'You are about to claim the asset and burn the following ATR token:'
  case ClaimAndBurnActionType.Burn:
    return 'You are about to burn the following ATR token:'
  case ClaimAndBurnActionType.Claim:
    return 'You are about to claim the tokenized asset using your ATR token:'
  default:
    // todo any log here?
    return null
  }
})
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.single-asset-detail-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__asset-cards-container {
    display: flex;
    column-gap: 1rem;
  }

  &__add-more-assets {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    border: 1px solid var(--primary-color-1);
    color: var(--primary-color-1);

    transition: all var(--primary-transition-time);

    &:hover {
      background-color: var(--primary-color-3);
      cursor: pointer;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__checkbox-and-button-container {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
}
</style>
