import type { Hex } from 'viem'
import { signEip712 } from '@/modules/common/web3/useSignatures'
import type { V1_2SimpleLoanListProposalStruct } from '@/contracts/structs'
import BaseProposalContract from '@/modules/common/pwn/contracts/BaseProposalContract'
import { readPwnV1_2SimpleLoanListProposalEncodeProposalData, readPwnV1_2SimpleLoanGetLenderSpecHash } from '@/contracts/generated'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { zeroAddress } from 'viem'
import type { V1_2SimpleLoanSimpleListProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { freeUserNonceRetrieve } from '@/modules/common/backend/generated'

const EMPTY_32_BYTES = '0x0000000000000000000000000000000000000000000000000000000000000000'

export default class V1_2SimpleLoanListProposalContract extends BaseProposalContract<V1_2SimpleLoanSimpleListProposal> {
  public async createProposalHash(proposalClass: V1_2SimpleLoanSimpleListProposal): Promise<{ proposalHash: `0x${string}`; }> {
    throw new Error('Method not implemented.')
  }

  public async createProposalHashAndSignature(proposal: V1_2SimpleLoanSimpleListProposal) {
    const freeUserNonceData = await freeUserNonceRetrieve(
      String(proposal.chainId),
      CHAINS_CONSTANTS[proposal.chainId].pwnV1_2Contracts.pwnRevokedNonce,
      proposal.proposer,
      {
        nonce_count_to_reserve: 1,
      },
    )
    proposal.nonceSpace = BigInt(freeUserNonceData.data.freeUserNonceSpace)
    proposal.nonce = BigInt(freeUserNonceData.data.freeUserNonces[0])

    const domain = {
      name: 'PWNSimpleLoanListProposal',
      version: '1.2',
      chainId: proposal.chainId,
      verifyingContract: proposal.proposalContractAddress,
    }

    const types = {
      Proposal: [
        { name: 'collateralCategory', type: 'uint8' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralIdsWhitelistMerkleRoot', type: 'bytes32' },
        { name: 'collateralAmount', type: 'uint256' },
        { name: 'checkCollateralStateFingerprint', type: 'bool' },
        { name: 'collateralStateFingerprint', type: 'bytes32' },
        { name: 'creditAddress', type: 'address' },
        { name: 'creditAmount', type: 'uint256' },
        { name: 'availableCreditLimit', type: 'uint256' },
        { name: 'fixedInterestAmount', type: 'uint256' },
        { name: 'accruingInterestAPR', type: 'uint40' },
        { name: 'duration', type: 'uint32' },
        { name: 'expiration', type: 'uint40' },
        { name: 'allowedAcceptor', type: 'address' },
        { name: 'proposer', type: 'address' },
        { name: 'proposerSpecHash', type: 'bytes32' },
        { name: 'isOffer', type: 'bool' },
        { name: 'refinancingLoanId', type: 'uint256' },
        { name: 'nonceSpace', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'loanContract', type: 'address' },
      ],
    }

    const { hash: proposalHash, signature } = await signEip712({
      domain,
      types,
      primaryType: 'Proposal',
      message: await this.createProposalStruct(proposal),
    })

    return {
      proposalHash,
      signature,
    } as const
  }

  public async createProposalStruct(proposal: V1_2SimpleLoanSimpleListProposal): Promise<V1_2SimpleLoanListProposalStruct> {
    if (!proposal.collateral) {
      throw new Error('Collateral is not defined')
    }

    const proposerSpecHash = await readPwnV1_2SimpleLoanGetLenderSpecHash(pwnWagmiConfig, {
      address: proposal.loanContractAddress,
      chainId: proposal.chainId,
      args: [{ sourceOfFunds: proposal.sourceOfFunds ? proposal.sourceOfFunds : proposal.proposer }],
    })
    return {
      collateralCategory: proposal.collateral.category,
      collateralAddress: proposal.collateral.address,
      collateralIdsWhitelistMerkleRoot: EMPTY_32_BYTES,
      collateralAmount: proposal.collateralAmount,
      checkCollateralStateFingerprint: false,
      collateralStateFingerprint: EMPTY_32_BYTES,
      creditAddress: proposal.creditAsset.address,
      creditAmount: proposal.creditAmount,
      availableCreditLimit: 0n,
      fixedInterestAmount: proposal.fixedInterestAmount,
      accruingInterestAPR: proposal.accruingInterestAPR || 0,
      duration: proposal.loanDurationSeconds,
      expiration: proposal.expiration,
      allowedAcceptor: zeroAddress,
      proposer: proposal.proposer,
      proposerSpecHash: proposal.isOffer ? proposerSpecHash : EMPTY_32_BYTES,
      isOffer: proposal.isOffer ?? false,
      refinancingLoanId: 0n,
      nonceSpace: proposal.nonceSpace,
      nonce: proposal.nonce,
      loanContract: proposal.loanContractAddress,
    }
  }

  public async encodeProposalData(
    proposal: V1_2SimpleLoanSimpleListProposal,
  ): Promise<Hex> {
    const proposalStruct = await this.createProposalStruct(proposal)
    const proposalValues = {
      collateralId: BigInt(proposal.collateral.tokenId),
      merkleInclusionProof: [],
    }

    return await readPwnV1_2SimpleLoanListProposalEncodeProposalData(pwnWagmiConfig, {
      address: proposal.proposalContractAddress,
      chainId: proposal.chainId,
      args: [proposalStruct, proposalValues],
    })
  }
}
