<template>
  <header class="token-bundler-header">
    <h1 class="token-bundler-header__heading">
      Choose Action
    </h1>
  </header>
</template>

<script setup lang="ts">

</script>

<style scoped>
.token-bundler-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  margin-top: 3rem;
  padding-bottom: 2rem;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px; /* small hack how to hide buggy double border (top + bottom), when height is 1px */
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 2rem;
    font-weight: 400;
  }

  &__hint {
    margin: 1.875rem 0 1.25rem;
  }

  &__hint-highlighted {
    margin-bottom: 4rem;
    color: var(--negative-1);
    white-space: pre-line;
  }
}
</style>
