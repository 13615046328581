<template>
  <div class="refresh-assets">
    <span
      v-if="showText"
      class="refresh-assets__text">
      {{ text }}
    </span>

    <RefreshButton
      :is-loading="cacheIsResetting"
      :handle-refresh="handleResetCache"/>
  </div>
</template>

<script lang="ts" setup>
import useDashboardCache from '@/modules/pages/dashboard/hooks/useDashboardCache'
import { computed } from 'vue'
import DateUtils from '@/utils/DateUtils'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import to from '@/utils/await-to-js'
import type { Address } from 'viem'
import RefreshButton from '@/modules/common/assets/components/RefreshButton.vue'
import { storeToRefs } from 'pinia'

interface Props {
  showText?: boolean
  afterRefresh?: (address: Address) => Promise<void>
  address?: Address
}

const props = withDefaults(defineProps<Props>(), {
  showText: true,
})

const userAssetsStore = useUserAssetsStore()
const { dashboardUserAddress } = storeToRefs(userAssetsStore)

const addressToRefresh = computed(() => props.address || dashboardUserAddress.value)

const { cacheCreatedAt, cacheIsLoading } = useDashboardCache()

const cacheIsResetting = computed(() => cacheIsLoading.value)

const formatDate = computed(() => {
  if (!cacheIsLoading.value && cacheCreatedAt.value) return DateUtils.getFormattedTimeAgo(cacheCreatedAt.value)
  return ''
})

const text = computed(() => {
  if (!cacheIsLoading.value && cacheCreatedAt.value) return `Last updated: ${formatDate.value}`
  if (cacheIsResetting.value) return 'Refreshing...'
  return ''
})

const handleResetCache = async () => {
  cacheIsLoading.value = true
  // @ts-expect-error FIXME: strictNullChecks
  await to(userAssetsStore.invalidateAndRefetch(addressToRefresh.value))
  // @ts-expect-error FIXME: strictNullChecks
  if (props.afterRefresh) await to(props?.afterRefresh(addressToRefresh.value))
  cacheIsLoading.value = false
}
</script>

<style>
  .refresh-assets {
    display: flex;
    align-items: center;

    &__text {
      font-size: 0.8rem;
      font-family: var(--font-family-oxygen-mono);
      color: var(--gray-2);
      font-style: normal;
      font-weight: 400;
      margin-right: 1rem;
    }
  }
</style>
