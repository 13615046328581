<template>
  <BaseSection
    :heading="heading"
    with-gray-background
    :is-expandable="false"
    is-subsection>
    <template #controls>
      <div class="section-your-unfunded-borrowing__checkbox-container">
        <BaseCheckbox
          :model-value="showHistory"
          label="Show History"
          @update:model-value="v => showHistory = v"/>
      </div>
    </template>
    <UserProposals
      type="borrow"
      :show-history="showHistory"
      include-proposals/>

    <EmptyTableCTA
      v-if="hasEmptyTableCTA"
      :link-to-other-page="RouteName.RevampBorrow"
      button-text="Post Borrowing Proposal"
      heading="No outgoing borrowing proposals"/>
    <BaseEmptyStateText
      v-else-if="hasEmptyState"
      text="This user has not any pending proposals"/>
  </BaseSection>
</template>

<script lang="ts" setup>
import BaseSection from '@/general-components/BaseSection.vue'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import { computed, provide, ref } from 'vue'
import EmptyTableCTA from '@/general-components/EmptyTableCTA.vue'
import RouteName from '@/router/RouteName'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { compareAddresses } from '@/utils/utils'
import UserProposals from '@/revamp/components/tables/dashboard/UserProposals.vue'
import { injectedProposalsCountKeyName, useInjectedProposalsCount } from '@/revamp/hooks/useInjectedProposalsCount'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import { useLocalStorage } from '@vueuse/core'

const { dashboardUserAddress } = useDashboard()
const { address: userAddress } = useCustomAccount()
const { injectedProposalsCount, setInjectedProposalsCount } = useInjectedProposalsCount()

const showHistory = useLocalStorage('show-borrowing-proposals-history-new', true)
const hasEmptyState = ref(false)

const isDashboardOfConnectedUser = computed(() => {
  return compareAddresses(dashboardUserAddress.value, userAddress.value)
})

provide(injectedProposalsCountKeyName, {
  injectedProposalsCount,
  setInjectedProposalsCount,
  setHasEmptyState: (v: boolean) => {
    hasEmptyState.value = v
  },
})

const heading = computed(() => {
  if (isDashboardOfConnectedUser.value) {
    return `Your Borrowing Proposals (${injectedProposalsCount.value})`
  }
  return `Borrowing Proposals (${injectedProposalsCount.value})`
})

const hasEmptyTableCTA = computed(() =>
  hasEmptyState.value &&
  isDashboardOfConnectedUser.value,
)
</script>
