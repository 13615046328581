import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as openSeaIcon from '@/assets/icons/opensea.svg'
import * as baseWhite from '@/assets/icons/base-white.svg'
import type { Address } from 'viem'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://basescan.org'

const BASE_CONSTANTS: ChainConstants = {
  gnosisSafe: { chainName: 'base', transactionServiceUrl: 'https://safe-transaction-base.safe.global' },
  pwnSafeContracts: {
    assetTransferRightsGuardProxy: '0x7Fef14F22fAC06336097b6C35faCA6359A77eb14',
    assetTransferRights: '0x2Af429Ab631Cdd2e9de396F8C838d7ad231E73EB',
    pwnSafeFactory: '0xF475aB5843d6688ffFfDAA38e7DEFeAFAc9d9284',
  },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x4188C513fd94B0458715287570c832d9560bc08a',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x6fD3f5439aB1C103599385929d5f4c19acdBd264',
  topTokens: new TopTokens({
    dai: '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
    usdc: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
    weth: '0x4200000000000000000000000000000000000006',
  }),
  general: {
    chainId: SupportedChain.Base,
    chainName: 'Base',
  },
  nodeProvider: {
    httpNodeUrl: 'https://base-mainnet.g.alchemy.com/v2/',
    wsNodeUrl: `wss://base-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_BASE_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_BASE_ALCHEMY_NODE_KEY as string,
  },
  nftContractsAddresses: {
    kyberSwapElastic: '0xe222fBE074A436145b255442D919E4E3A6c6a480',
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'basescan',
    blockExplorerIcon: baseWhite.default,
    nftDetailsLink: (tokenAddress, tokenId) => `https://opensea.io/assets/base/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: openSeaIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://opensea.io/collection/${slug}`,
  },
  aave: {
    uiPoolDataProvider: '0x5d4D4007A4c6336550DdAa2a7c0d5e7972eebd16',
    poolAddressesProvider: '0xe20fCBdBfFC4Dd138cE8b2E6FBb6CB49777ad64D',
    pool: '0xA238Dd80C259a72e81d7e4664a9801593F98d1c5',
  },
  compound: {
    pools: ['0xb125E6687d4313864e53df431d5425969c15Eb2F', '0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf', '0x46e6b214b524310239732D51387075E0e70970bf'],
  },
}

export default BASE_CONSTANTS
