import type IPwnSafe from '@/modules/common/pwn/safe/IPwnSafe'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import type IPwnSafeGetters from '@/modules/common/pwn/safe/IPwnSafeGetters'
import type { SupportedChain } from '@/constants/chains/types'
import type { SafeBackendSchema } from '@/modules/common/backend/generated'
import { parseChainIdFromResponse } from '@/modules/common/backend/backendUtils'
import { shortenAddress } from '@/utils/utils'
import type { Address } from 'viem'
import { parseAddress } from '@/utils/address-utils'

export default class PwnSafe implements IPwnSafe, IPwnSafeGetters {
  public id: string
  public name: string
  // @ts-expect-error TS(2416) FIXME: Property 'chainId' in type 'PwnSafe' is not assign... Remove this comment to see the full error message
  public chainId: SupportedChain | null // null in case of current walelt
  public nfts: AssetWithAmount[]
  public erc20s: AssetWithAmount[]
  public safeAddress: Address
  public isFetchingErc20s: boolean
  public isFetchingNfts: boolean
  public owners: Address[]

  constructor(pwnSafe?: Partial<IPwnSafe>) {
    this.id = pwnSafe?.id || ''
    this.name = pwnSafe?.name || ''
    // @ts-expect-error TS(2322) FIXME: Type 'SupportedChain | undefined' is not assignabl... Remove this comment to see the full error message
    this.chainId = pwnSafe.chainId
    this.nfts = pwnSafe?.nfts || []
    this.erc20s = pwnSafe?.erc20s || []
    // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
    this.safeAddress = parseAddress(pwnSafe?.safeAddress)
    // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.isFetchingErc20s = pwnSafe.isFetchingErc20s
    // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.isFetchingNfts = pwnSafe.isFetchingNfts
    this.owners = pwnSafe?.owners || []
  }

  static parseResponseFromBackendModel = (pwnSafeDataFromBE: SafeBackendSchema): PwnSafe => {
    return new PwnSafe({
      safeAddress: parseAddress(pwnSafeDataFromBE.contract_address),
      name: pwnSafeDataFromBE.name,
      chainId: parseChainIdFromResponse(pwnSafeDataFromBE.chain_id),
      owners: pwnSafeDataFromBE.owners.map(parseAddress),
    })
  }

  get safeAddressShort(): string {
    return shortenAddress(this.safeAddress)
  }

  get amountOfErc20s(): number {
    return this.erc20s?.length ?? 0
  }

  get atrTokens(): AssetWithAmount[] {
    return this.nfts.filter(AssetMetadata.isAtrToken)
  }

  get amountOfNftsWithoutAtrTokens(): number {
    const arrayOfNftsWithoutAtrTokens = this.nfts.filter(nft => !AssetMetadata.isAtrToken(nft))
    return arrayOfNftsWithoutAtrTokens?.length ?? 0
  }

  get amountOfAtrTokens(): number {
    return this.atrTokens?.length ?? 0
  }

  get key(): string {
    return `${this.safeAddress}-${this.chainId}`
  }
}
