<template>
  <TheTopBanner class="app__top-banner"/>
  <StarknetBanner class="app__starknet-banner"/>
  <div class="app__header-container">
    <TheHeader
      class="app__header"/>
  </div>
  <div
    class="app__container">
    <main class="app__main">
      <router-view/>
    </main>
  </div>
  <ConsentAnalytics/>
  <MobileNavBar/>
  <Modals/>
  <VueQueryDevtools
    button-position="bottom-right"
    position="right"/>
  <TestingHelpers/>
</template>

<script setup lang="ts">
import TheHeader from '@/layout/header/TheHeader.vue'
import TheTopBanner from '@/general-components/TheTopBanner.vue'
import Modals from '@/general-components/Modals.vue'
import MobileNavBar from '@/layout/mobile-nav-bar/MobileNavBar.vue'
import ConsentAnalytics from '@/modules/common/analytics/ConsentAnalytics.vue'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import useSseListener from '@/modules/common/backend/sseListener/useSseListener'
import { onMounted, watchEffect } from 'vue'
import { watchAccount, getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from './modules/common/web3/usePwnWagmiConfig'
import { compareAddresses } from './utils/utils'
import useGlobalDataLoader from './modules/common/useGlobalDataLoader'
import TestingHelpers from '@/general-components/TestingHelpers.vue'
import { useQueryClient } from '@tanstack/vue-query'
import { isStarknet } from './modules/common/pwnSpace/pwnSpaceDetail'
import StarknetBanner from './general-components/StarknetBanner.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const queryClient = useQueryClient()
const { setupSseListener } = useSseListener(queryClient)

if (!isStarknet) {
  // TODO can we move this to usePwnWagmiConfig?
  watchAccount(pwnWagmiConfig, {
    onChange({ address }, { address: previousAddress }) {
      if (!compareAddresses(address, previousAddress)) {
        setupSseListener(address)
        useGlobalDataLoader().loadDataOnAppInit(address)
      }
    },
  })
}
if (isStarknet) {
  watchEffect(() => {
    const { address: connectedUserAddress } = useCustomAccount()
    if (!connectedUserAddress.value) return
    setupSseListener(connectedUserAddress.value)
    useGlobalDataLoader().loadDataOnAppInit(connectedUserAddress.value)
  })
}

onMounted(() => {
  setupSseListener()
  if (!isStarknet) {
    // TODO previously moved from useAppInit.beforeAppMount
    const { address: connectedUserAddress } = getAccount(pwnWagmiConfig)
    useGlobalDataLoader().loadDataOnAppInit(connectedUserAddress)
  } else {
    const { address: connectedUserAddress } = useCustomAccount()
    useGlobalDataLoader().loadDataOnAppInit(connectedUserAddress.value)
  }
})
</script>

<style scoped>
.app {
  &__top-banner {
    min-width: var(--app-min-width);
  }

  &__header-container {
    width: 100%;
    height: var(--menu-height);
    position: sticky;
    top: 0;
    z-index: var(--z-index-app-top-menu);
    background: var(--background-color);
    display: flex;
    justify-content: center;
  }

  &__header,
  &__container {
    height: 90%;
    width: 97%; /* to overcome horizontal scroll in some browsers */
    display: flex;
    justify-content: center;

    margin: auto;

    @media only screen and (--large-viewport) {
      height: 95%;
    }
  }

  &__header {
    padding: 0 1rem;
    max-width: var(--app-header-max-width);
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 1rem;

    max-width: var(--app-max-width);
  }
}
</style>
