<!-- eslint-disable import/first -->
<template>
  <div
    ref="parentRef"
    class="price-label">
    <BaseTooltip
      :has-tooltip="hasTooltip"
      is-interactive
      :tooltip-text="amountAndSymbolInTooltip"
      placement="center-start">
      <template #trigger>
        <span
          ref="amountRef"
          :class="textAlign">
          {{ amountAndSymbol }}
        </span>
      </template>
      <slot/>
    </BaseTooltip>
  </div>
</template>

<script lang="ts">
export enum TextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}
</script>

<script lang="ts" setup>
/* eslint-disable import/first */
import { formatToken, shortenNumber } from '@/utils/utils'
import { computed, ref, watch } from 'vue'
import BaseTooltip from '@/general-components/BaseTooltip.vue'

interface Props {
  symbol?: string;
  amount?: string; // amount could be undefined in some cases, e.g. missing loan request
  maxLength?: number;
  shortenThreshold?: number;
  isSymbolBeforeAmount?: boolean;
  textAlign?: TextAlign;
  isEnabledTooltip?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  symbol: '',
  maxLength: 12,
  shortenThreshold: 24,
  isSymbolBeforeAmount: false,
  isEnabledTooltip: true,
  textAlign: TextAlign.Left,
})

// todo rewrite computeds to watchEffect?
// todo further refactor/cleanup needed

const delimiter = '…'
const amountRef = ref<HTMLSpanElement>()
const parentRef = ref<HTMLDivElement>()

const textAlign = computed(() => `price-label__text-align--${props.textAlign}`)

// todo maybe just a method?
const long = computed(() => props.amount ? formatToken(props.amount) : '---')
// todo maybe just a method?
const shorten = computed(() => props.amount ? shortenNumber(props.amount) : '---')

const isRounded = computed(() => {
  if (long.value === '---' || !props.amount) return false
  const formattedLong = long.value.replaceAll(',', '')
  const formattedAmount = props.amount.endsWith('.0') ? props.amount.slice(0, props.amount.length - 2) : props.amount
  return formattedLong !== formattedAmount
})

const isShorten = computed<boolean>(() => {
  if (!parentRef.value || !amountRef.value) {
    // template is not yet rendered
    return false
  }

  const parentWidth = parentRef.value.offsetWidth ?? 0
  const amountWidth = amountRef.value.offsetWidth ?? 0
  const remainingSpace = parentWidth - amountWidth
  return remainingSpace < props.shortenThreshold
})

const formattedAmount = computed(() => {
  if (isShorten.value) {
    return shorten.value
  }

  if (long.value.length <= props.maxLength) {
    return long.value
  }

  const maxDigits = props.maxLength - (props.symbol?.length ?? 0) - delimiter.length - 1
  const tail = long.value.substring(long.value.length - 2, long.value.length)
  const regex = new RegExp('(.{' + maxDigits + '}).+')
  return long.value.replace(regex, '$1' + `${delimiter}${tail}`)
})

const hasTooltip = ref()
const tooltipAmount = ref()

watch(formattedAmount, (newFormattedAmount, oldFormattedAmount) => {
  hasTooltip.value = (
    newFormattedAmount.includes(delimiter) ||
    newFormattedAmount.includes('<') ||
    isShorten.value ||
    isRounded.value // || !hasAmountSameLength.value
  ) &&
    props.isEnabledTooltip

  tooltipAmount.value = newFormattedAmount.includes('<') || isRounded.value ? props.amount : long.value // || !hasAmountSameLength.value
}, { immediate: true })

const amountAndRoundedSymbol = computed(() => isRounded.value && !formattedAmount.value.includes('<') ? `≈${formattedAmount.value}` : formattedAmount.value)
const amountAndSymbol = computed(() => props.isSymbolBeforeAmount ? `${props.symbol}${amountAndRoundedSymbol.value}` : amountAndRoundedSymbol.value + (props.symbol ? ` ${props.symbol}` : ''))
const amountAndSymbolInTooltip = computed(() => props.isSymbolBeforeAmount ? `${props.symbol}${tooltipAmount.value}` : tooltipAmount.value + (props.symbol ? ` ${props.symbol}` : ''))
</script>

<style scoped>
.price-label {
  display: inline-flex;
  width: 80%;

  :first-child {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    vertical-align: middle;
  }

  &__text-align {
    &--right {
      text-align: right;
    }

    &--center {
      text-align: center;
    }

    &--left {
      text-align: left;
    }
  }
}
</style>
