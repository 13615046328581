<template>
  <BaseAssetPageSection
    v-if="asset && offers"
    class="section-offers"
    icon="cart"
    question-mark-tooltip="Offers on individual assets that make up this bundle, from different NFT marketplaces"
    :is-fetching="isFetchingOffers"
    :has-data="hasDataOrLoading"
    label="Purchase offers on assets">
    <template #content>
      <div class="section-offers__content">
        <BaseTable
          :columns="TABLE_DEFINITION"
          :variant="TableVariant.AssetPage"
          :items="offers"
          table-max-height="254px"
          table-min-width="700px"
          is-virtual
          :item-height="56"
          :virtual-table-overscan="10"
          :is-fetching="isFetchingOffers"
          :is-hoverable="false">
          <template #asset="{ item: offer }">
            <AssetInfo
              :asset="offer.asset"
              show-link/>
          </template>

          <template #price="{ item: offer }">
            <TokenAndEthAndUsdPriceLabel
              class="section-offers__price"
              :token="offer.orderAsset"/>
          </template>

          <template #expiration="{ item: offer }">
            <div
              v-if="offer.expiration"
              class="section-offers__expiration">
              {{ DateUtils.displayDate(offer.expiration) }}
            </div>
          </template>

          <template #from="{ item: offer }">
            <div class="section-offers__ens">
              <DataSource
                :data-source="offer.marketplace">
                <template #textAfter>
                  <CryptoAddress
                    :address="offer.creatorAddress"
                    :chain-id="offer.asset.chainId"/>
                </template>
              </DataSource>
            </div>
          </template>
        </BaseTable>
      </div>
    </template>
  </BaseAssetPageSection>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import BaseTable from '@/general-components/BaseTable.vue'
import { TABLE_DEFINITION } from '@/modules/pages/asset/nft-page/SectionOffersOnAssets/SectionOffersOnAssetsTableDefinitions'
import TableVariant from '@/general-components/TableVariant'
import useSectionOffers from '@/modules/pages/asset/nft-page/SectionOffers/useSectionOffers'
import { computed } from 'vue'
import DataSource from '@/general-components/data-source/DataSource.vue'
import DateUtils from '@/utils/DateUtils'
import TokenAndEthAndUsdPriceLabel from '@/general-components/TokenAndEthAndUsdPriceLabel.vue'
import AssetInfo from '@/general-components/AssetInfo.vue'
import CryptoAddress from '@/general-components/CryptoAddress.vue'

const { asset } = useAssetPage()
const { offers, isFetchingOffers } = useSectionOffers()

const hasDataOrLoading = computed(() => offers.value.length > 0)

</script>

<style scoped>
.section-offers {
  &__content {
    height: var(--default-asset-section-height);
    position: relative;
  }

  &__question-mark {
    cursor: help;
    line-height: 1.375rem;
    font-size: 0.75rem;
    color: var(--gray);
  }

  &__floor-difference {
    &--negative {
      color: var(--danger-1);
    }

    &--positive {
      color: var(--positive-bright);
    }
  }

  &__ens {
    display: flex;
    align-items: center;
  }

  &__from-icon {
    width: 0.875rem;
    height: 0.875rem;
    min-width: 0.875rem;
    margin-right: 0.5rem;
  }

  &__floor-difference-head {
    margin-left: 0.3rem;
    line-height: 1;
  }
}
</style>
