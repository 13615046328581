<template>
  <PleaseConnectWallet
    message="Token Bundler"/>
  <div
    v-if="isConnected"
    class="bundle-assets">
    <BreadcrumbsHeader
      :active-step="activeStep!"
      :set-active-step="setActiveStep"
      :steps="BUNDLE_ASSETS_STEPS_DEFINITION"/>
    <router-view/>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useBundleAssets from '@/modules/pages/token-bundler/bundle-assets/useBundleAssets'
import BreadcrumbsHeader from '@/general-components/BreadcrumbsHeader.vue'
import BUNDLE_ASSETS_STEPS_DEFINITION from '@/modules/pages/token-bundler/bundle-assets/bundleAssetsStepsDefinition'
import PleaseConnectWallet from '@/general-components/PleaseConnectWallet.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { initPage, setActiveStep, activeStep } = useBundleAssets()
const { isConnected } = useCustomAccount()

const route = useRoute()
const router = useRouter()
initPage(route, router)

</script>

<style scoped>
.bundle-assets {
  padding-bottom: 5rem;
}
</style>
