<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    :heading="headingText">
    <template #trigger>
      <span
        :class="['link link--primary link--sm link--font-oxygen-mono']"
        @click="handleOpenModal">Unwrap</span>
    </template>
    <template #body>
      <p class="modal-unwrap-native-token__text">
        You are about to unwrap your {{ wrappedNativeToken.symbol }} back into native {{ nativeTokenSymbol }}.
      </p>

      <div class="modal-unwrap-native-token__input-container">
        <label class="modal-wrap-native-token__label-choose-amount">
          Select the amount to unwrap:
        </label>

        <BaseInput
          button-text="max"
          class="modal-unwrap-native-token__input"
          :model-value="wrappedNativeToken.amountInput"
          placeholder="0"
          :is-valid-input="wrappedNativeToken.isAssetAmountInputValidOrEmpty"
          warning-text="Invalid amount"
          @button-clicked="handleInputMaxButtonClick(wrappedNativeToken)"
          @update:model-value="handleAssetAmountChange(wrappedNativeToken, $event)"/>
      </div>
      <BaseButton
        class="modal-unwrap-native-token__button"
        is-pulsing
        is-full-width
        :is-disabled="isDisabledUnwrapButton"
        button-text="Unwrap"
        @on-button-click="handleUnwrapNativeToken"/>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import ModalSize from '@/general-components/ModalSize'
import BaseButton from '@/general-components/BaseButton.vue'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, reactive, ref } from 'vue'
import type { Ref } from 'vue'
import BaseInput from './BaseInput.vue'
import deepcopy from '@/utils/deepcopyinstance'
import { getChainName } from '@/utils/chain'
import { ALL_SUPPORTED_CHAINS_WAGMI } from '@/constants/chains/types'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import { useMutation } from '@tanstack/vue-query'
import { queryMutations } from '@/modules/mutations'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import { compareAssets } from '@/utils/utils'

interface Props {
  wrappedNativeToken: AssetWithAmount
}
const props = defineProps<Props>()

const isOpen = ref(false)

const wrappedNativeToken = reactive(deepcopy(props.wrappedNativeToken))

const headingText = computed(() => `Unwrapping ${wrappedNativeToken.symbol} on ${getChainName(wrappedNativeToken.chainId)}`)

const handleOpenModal = () => {
  wrappedNativeToken.amountInput = ''
  isOpen.value = true
}

const handleAssetAmountChange = (asset: AssetWithAmount, newAmount: string) => {
  asset.amountInput = newAmount
}

const handleInputMaxButtonClick = (asset: AssetWithAmount) => {
  asset.amountInput = asset.maxAvailableAmount
}

const mutations = queryMutations()
const assetHasNonNativeWrapping = computed(() => compareAssets({
  assetA: wrappedNativeToken,
  assetB: AssetWithAmount.createEtherFiWETHAssetMetadata(),
}))
const fnToWrap = () => {
  if (assetHasNonNativeWrapping.value) {
    return mutations.etherFi.unwrap
  }
  return mutations.unwrap
}

const { isPending: isUnwrapping, mutateAsync: unwrapMutateAsync } = useMutation({
  ...fnToWrap(),
  onSuccess(data, variables, context) {
    fnToWrap().onSuccess?.(data, variables, context)
    isOpen.value = false
  },
})

const toast = ref<Toast>()
let continueFlow

const handleUnwrapNativeToken = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_UNWRAP_NATIVE_TOKEN](wrappedNativeToken.chainId, wrappedNativeToken.amountInputRaw)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      steps: [new ToastStep({
        text: `Unwrapping ${wrappedNativeToken.amountInput} ${wrappedNativeToken.symbol}...`,
        async fn(step) {
          await unwrapMutateAsync({ wrappedNativeToken, amount: wrappedNativeToken.amountInputRaw, step })
          return true
        },
      })],
      firstAsset: wrappedNativeToken,
      chainId: wrappedNativeToken.chainId,
      title: 'Unwrapping',
    }, NotificationFrontendOnlyActionEnum.TX_UNWRAP_NATIVE_TOKEN, wrappedNativeToken.chainId, wrappedNativeToken.amountInputRaw);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}

const isDisabledUnwrapButton = computed(() =>
  isUnwrapping.value ||
  !wrappedNativeToken.isAssetAmountInputValid,
)

const nativeTokenSymbol = computed(() => {
  return ALL_SUPPORTED_CHAINS_WAGMI.find(chainWagmi => chainWagmi.id === wrappedNativeToken.chainId)?.nativeCurrency?.symbol
})
</script>

<style scoped>
.modal-unwrap-native-token {
  &__text {
    line-height: 1.5rem;
    max-width: 46.875rem;
    margin: 1.5rem auto 4rem;
    text-align: center;
  }

  &__label-choose-amount {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &__button {
    margin: 4rem auto 1rem;
    max-width: 20rem;
  }

  &__input-container {
    width: fit-content;
    margin: auto;
  }
}
</style>

<style>
.modal-unwrap-native-token__input {
  width: 14.5rem;
  margin-top: 1rem;
}
</style>
