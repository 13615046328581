import { AssetContract } from '@/modules/common/assets/AssetClasses'
import type { HexColor } from '@/modules/common/typings/customTypes'
import type { SupportedChain } from '@/constants/chains/types'
import type { SubdomainConfigBackendSchema } from '@/modules/common/backend/generated'
import { parseChainIdFromResponse } from '@/modules/common/backend/backendUtils'

export default class PwnSpaceConfig {
  allowedContracts: AssetContract[]
  allowedAssetCategory: string
  supportedChains: SupportedChain[]
  pwnSpaceName: string
  bannerUrl: string
  backgroundColor: HexColor
  textColor: HexColor
  primaryColor: HexColor
  separatorColor: HexColor
  darkSeparatorColor: HexColor
  inputColor: HexColor
  isErc20Only: boolean
  isNftOnly: boolean
  showInSpacePage: boolean

  lendTableHeading: string | undefined
  title: string | undefined
  avatar: string | undefined

  constructor(pwnSpaceConfig: Omit<PwnSpaceConfig, 'isErc20Only' | 'isNftOnly'>) {
    this.allowedContracts = (pwnSpaceConfig.allowedContracts ?? []).map(allowedContract => new AssetContract(allowedContract))
    this.supportedChains = pwnSpaceConfig.supportedChains
    this.pwnSpaceName = pwnSpaceConfig.pwnSpaceName
    this.bannerUrl = pwnSpaceConfig.bannerUrl
    this.lendTableHeading = pwnSpaceConfig.lendTableHeading
    this.backgroundColor = pwnSpaceConfig.backgroundColor
    this.textColor = pwnSpaceConfig.textColor
    this.primaryColor = pwnSpaceConfig.primaryColor
    this.separatorColor = pwnSpaceConfig.separatorColor
    this.darkSeparatorColor = pwnSpaceConfig.darkSeparatorColor
    this.inputColor = pwnSpaceConfig.inputColor

    this.isErc20Only = true
    this.isNftOnly = true
    for (const contract of this.allowedContracts) {
      if (contract.isNft) {
        this.isErc20Only = false
      } else {
        this.isNftOnly = false
      }
    }
    this.showInSpacePage = pwnSpaceConfig.showInSpacePage
    this.title = pwnSpaceConfig.title
    this.avatar = pwnSpaceConfig.avatar
    this.allowedAssetCategory = pwnSpaceConfig.allowedAssetCategory
  }

  public static createFromBackendModel(pwnSpaceConfig: SubdomainConfigBackendSchema): PwnSpaceConfig {
    return new PwnSpaceConfig({
      allowedContracts: (pwnSpaceConfig.allowed_contracts ?? []).map(allowedContract => AssetContract.createFromBackendModel(allowedContract)),
      allowedAssetCategory: pwnSpaceConfig.allowed_category,
      supportedChains: (pwnSpaceConfig.supported_chains ?? []).map(supportedChain => parseChainIdFromResponse(supportedChain)),
      pwnSpaceName: pwnSpaceConfig.subdomain_name,
      bannerUrl: pwnSpaceConfig.banner_url,
      lendTableHeading: pwnSpaceConfig.lend_table_heading,
      backgroundColor: pwnSpaceConfig.background_color as HexColor,
      textColor: pwnSpaceConfig.text_color as HexColor,
      primaryColor: pwnSpaceConfig.primary_color as HexColor,
      separatorColor: pwnSpaceConfig.separator_color as HexColor,
      darkSeparatorColor: pwnSpaceConfig.dark_separator_color as HexColor,
      inputColor: pwnSpaceConfig.input_color as HexColor,
      showInSpacePage: pwnSpaceConfig.show_in_spaces_page ?? false,
      title: pwnSpaceConfig.title ?? undefined,
      avatar: pwnSpaceConfig.avatar ?? undefined,
    })
  }
}
