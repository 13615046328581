<template>
  <RevampBaseModal
    v-model:is-open="isOpen"
    :heading="heading"
    :size="ModalSize.Small"
    heading-align="left">
    <template #trigger>
      <BaseButton
        :color="ButtonColor.Gray"
        has-white-hover
        title="Set Notifications"
        :variant="ButtonVariant.Outline"
        :font="ButtonFont.Screener"
        @click="isOpen = true">
        <template #icon>
          <div class="set-notifications-modal__icon-wrapper">
            <BellIconSvg
              width="20"
              height="20"
              class="set-notifications-modal__bell-icon"
              alt="calendar notification"/>
          </div>
          <span class="set-notifications-modal__button-text">Set Notifications</span>
        </template>
      </BaseButton>
    </template>
    <template #body>
      <div class="set-notifications-modal__footer">
        <div class="set-notifications-modal__buttons">
          <EmailNotificationModal button-width="12rem"/>
          <PushNotificationModal button-width="12rem"/>
          <DiscordNotificationModal button-width="12rem"/>
        </div>
        <div
          v-if="expiration && chainName && loanContractAddress && loanId"
          class="set-notifications-modal__calendar">
          <div class="set-notifications-modal__calendar-title">
            Add to Calendar
          </div>
          <div class="set-notifications-modal__calendar-buttons">
            <CalendarNotification
              class="loan-detail-page__notification"
              :expiration="expiration"
              :chain-name="chainName"
              :loan-contract-address="loanContractAddress"
              :loan-id="loanId"/>
          </div>
        </div>
        <BaseCheckbox
          style="margin-bottom: 1rem;"
          :model-value="dontShowAgain"
          label="Don't show the modal again"
          @update:model-value="dontShowAgain = !dontShowAgain"/>
        <BaseButton
          button-text="Done"
          is-full-width
          :color="ButtonColor.Primary"
          :variant="ButtonVariant.Default"
          :font="ButtonFont.Screener"
          @click="isOpen = false"/>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonFont, ButtonVariant } from '@/general-components/BaseButton.vue'
import BellIconSvg from '@/assets/icons/bell.svg'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import ModalSize from '@/general-components/ModalSize'
import { ref, watchEffect } from 'vue'
import PushNotificationModal from '@/modules/pages/notification-center/settings/modals/PushNotificationModal.vue'
import DiscordNotificationModal from '@/modules/pages/notification-center/settings/modals/DiscordNotificationModal.vue'
import EmailNotificationModal from '@/modules/pages/notification-center/settings/modals/EmailNotificationModal.vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import { useLocalStorage } from '@vueuse/core'
import type { RemovableRef } from '@vueuse/core'
import CalendarNotification from '@/revamp/components/CalendarNotification.vue'

interface Props {
  isModalOpen?: boolean
  expiration?: number
  loanId?: number | string
  proposalId?: number | string
  chainName?: string
  loanContractAddress?: string
}

const props = withDefaults(defineProps<Props>(), {
  isModalOpen: false,
})

const isOpen = ref(props.isModalOpen)
const heading = 'Set Up Notifications'
const dontShowAgain = useLocalStorage('dont-show-notification-modal', false)
const alreadySeenProposals: RemovableRef<Array<string | number>> = useLocalStorage('already-seen-proposals', [])
const alreadySeenLoans: RemovableRef<Array<string | number>> = useLocalStorage('already-seen-loans', [])
watchEffect(() => {
  if (isOpen.value) {
    if (props.loanId && !alreadySeenLoans.value.includes(props.loanId)) {
      alreadySeenLoans.value.push(props.loanId)
    } else if (props.proposalId && !alreadySeenProposals.value.includes(props.proposalId)) {
      alreadySeenProposals.value.push(props.proposalId)
    }
  }
})
</script>

<style scoped>
.set-notifications-modal {
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  &__calendar-title {
    font-family: var(--font-family-screener);
    display: flex;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  &__calendar {
    margin-bottom: 2rem;
  }

  &__button-text {
    font-size: 14px;
    text-align: center;
    color: var(--text-color);
  }

  &__bell-icon {
    fill: var(--primary-color-1);
  }

  &__footer {
    width: 100%;
  }

  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 38rem;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  @media only screen and (--mobile-viewport) {
    &__buttons {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      width: 100%;
      align-items: center;
    }
  }

  &__tab {
    flex: 1;
    white-space: nowrap;
    width: 100%;
  }
}
</style>
