<template>
  <BaseLink
    :is-underlined="false"
    :to="props.navigateTo">
    <div :class="['search-result-item', `search-result-item--variant-${props.variant}`, `${props.isFocused ? 'search-result-item__focused' : ''}`]">
      <div class="search-result-item__image-wrap">
        <slot name="icon"/>
        <FallBackResultImage
          show-fallback
          class="search-result-item__image"
          :image="props.image"/>
        <ChainIcon
          v-if="chainId"
          class="search-result-item__chain-icon"
          alt="chain icon"/>
      </div>
      <div class="search-result-item__title-wrap">
        <SafeText
          :text="props.title ?? undefined"
          class="search-result-item__title"/>
        <VerificationIconSvg
          v-if="props.isVerified"
          class="search-result-item__verified-icon"
          alt="Verified Icon"/>
      </div>
      <div class="search-result-item__value">
        <slot name="value"/>
      </div>
    </div>
  </BaseLink>
</template>

<script setup lang="ts">
import BaseLink from '@/general-components/BaseLink.vue'
import type { RouteLocationRaw } from 'vue-router'
import { computed } from 'vue'
import type { Component } from 'vue'
import { getMonoChromeChainIcon } from '@/utils/chain'
import FallBackResultImage from '@/modules/sections/nav-searchbar/FallBackResultImage.vue'
import SafeText from '@/general-components/SafeText.vue'
import type { SupportedChain } from '@/constants/chains/types'
import VerificationIconSvg from '@/assets/icons/verified.svg'
import type { SearchCategory } from '@/modules/common/pwn/explorer/models'

type Props = {
  variant: 'homepage' | 'default' | 'explorer' | 'dashboard'
  title: string | null
  image: string | null
  chainId: SupportedChain | null
  sectionId: SearchCategory
  navigateTo: Record<string, string> | string | RouteLocationRaw
  showPrice?: boolean
  isVerified: boolean | null
  isFocused: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showPrice: false,
})
// @ts-expect-error FIXME: strictNullChecks
const ChainIcon = computed<Component>(() => getMonoChromeChainIcon(props.chainId))
</script>

<style scoped>
.search-result-item {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-family: var(--font-family-oxygen-mono);

  &:hover {
    background: var(--gray-3);
    cursor: pointer;
  }

  &__focused {
    background: var(--gray-3);
  }

  &__image-wrap {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__chain-icon {
    position: absolute;
    right: -0.5rem;
    bottom: -0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    box-shadow: inset 0 1px 16px 0 var(--background-color);
    padding: 0.15rem;
    box-sizing: content-box;
    border-radius: 50%;
  }

  &__image {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    margin-left: 0.5rem;
  }

  &__title {
    text-decoration: none;
    flex: 1;
    max-width: 271px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  &__title-wrap {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__value {
    margin-left: auto;
  }

  &__verified-icon {
    width: 1.15rem;
    height: 1.15rem;
    margin-left: 0.5rem;
  }
}
</style>

<style>
.search-result-item--variant-default {
  @media screen and (max-width: 1360px) {
    .search-result-item__title-wrap {
      max-width: 175px;
    }
  }
}

</style>
