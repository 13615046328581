<template>
  <div class="create-new-pwn-safe">
    <BreadcrumbsHeader
      :active-step="activeStep"
      :set-active-step="setActiveStep"
      :steps="CREATE_PWN_SAFE_STEPS_DEFINITION"
      :image="safeImage"
      has-back-button/>
    <router-view/>
  </div>
</template>

<script setup lang="ts">
import BreadcrumbsHeader from '@/general-components/BreadcrumbsHeader.vue'
import CREATE_PWN_SAFE_STEPS_DEFINITION from '@/modules/pages/pwn-safe/create-new-pwn-safe/createNewPwnSafeStepsDefinition'
import useCreateNewPwnSafe from '@/modules/pages/pwn-safe/create-new-pwn-safe/useCreateNewPwnSafe'
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'

const { activeStep, setActiveStep, initPage } = useCreateNewPwnSafe()
const route = useRoute()
const router = useRouter()
const safeImage = 'safe.svg'

onMounted(() => {
  initPage(route, router)
})
</script>

<style scoped>
.create-new-pwn-safe {
  margin-top: 5rem;
  padding-bottom: 3rem;
}
</style>
