<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    :heading-icon="tokenizeIcon.default"
    :is-closable="!isModalCloseDisabled"
    heading-align="left"
    heading="Mint Asset Transfer Rights Token">
    <template #body>
      <component :is="activeStepComponent"/>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import * as tokenizeIcon from '@/assets/icons/tokenize.svg'
import ModalSize from '@/general-components/ModalSize'
import usePwnSafeTokenizeModal from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModal'

const { isOpen, activeStepComponent, isModalCloseDisabled } = usePwnSafeTokenizeModal()
</script>
