import StoreIds from '@/constants/storeIds'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useRouterStackStore = defineStore(StoreIds.routerStack, () => {
  const routerStack = ref([])

  return {
    routerStack,
  }
})
