<template>
  <div
    v-if="shouldBeDisplayed"
    class="rep-score">
    (<ReputationScoreSvg
      :width="iconSize"
      :height="iconSize"
      alt="reputation score"/>

    <BaseSkeletor
      v-if="isLoading"
      class="rep-score__skeleton"/>
    <span v-else>
      {{ repScorePoints }}
    </span>
    )
  </div>
</template>

<script setup lang="ts">

import type { Address } from 'viem'

import { useUserReputationScoreWithParametersRetrive } from '@/modules/common/backend/generated'
import { computed, toRefs } from 'vue'
import ReputationScoreSvg from '@/assets/icons/rep-score.svg'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'

type Props = {
  address: Address
  iconSize?: number
}
const props = withDefaults(defineProps<Props>(), {
  iconSize: 16,
})
const { address } = toRefs(props)

const repScoreData = useUserReputationScoreWithParametersRetrive(address, { refresh: true })

const isLoading = computed(() => repScoreData.isLoading.value || repScoreData.isFetching.value)

const repScorePoints = computed(() => {
  // @ts-expect-error FIXME: schema is not correct
  return repScoreData.data.value?.data.reputationScore as string
})

const shouldBeDisplayed = computed(() => {
  return repScorePoints.value !== '0'
})
</script>

<style scoped>
.rep-score {
  display: inline-flex;
  align-items: center;

  min-width: 1;

  &__skeleton {
    width: 1.5rem;
    margin: 0 0.5rem;
  }

  &__points {
    display: inline-flex;
    align-items: center;
  }
}
</style>
