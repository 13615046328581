import type { Hex } from 'viem'
import { signEip712 } from '@/modules/common/web3/useSignatures'
import type { V1_2SimpleLoanSimpleProposalStruct } from '@/contracts/structs'
import BaseProposalContract from '@/modules/common/pwn/contracts/BaseProposalContract'
import {
  readPwnV1_2SimpleLoanSimpleProposalEncodeProposalData,
  readPwnV1_2SimpleLoanGetLenderSpecHash,
} from '@/contracts/generated'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { hashTypedData, zeroAddress } from 'viem'
import type { V1_2SimpleLoanSimpleProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { freeUserNonceRetrieve } from '@/modules/common/backend/generated'
import type { SupportedChain } from '@/constants/chains/types'
import type { TypedData } from 'starknet'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { starknetAccount } from '@/modules/common/web3/useCustomAccount'

const EMPTY_32_BYTES = '0x0000000000000000000000000000000000000000000000000000000000000000'

export default class V1_2SimpleLoanSimpleProposalContract extends BaseProposalContract<V1_2SimpleLoanSimpleProposal> {
  public async createProposalHash(proposal: V1_2SimpleLoanSimpleProposal): Promise<{ proposalHash: Hex }> {
    const domain = {
      name: 'PWNSimpleLoanSimpleProposal',
      version: '1.2',
      chainId: proposal.chainId,
      verifyingContract: proposal.proposalContractAddress,
    }

    const types = {
      Proposal: [
        { name: 'collateralCategory', type: 'uint8' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralId', type: 'uint256' },
        { name: 'collateralAmount', type: 'uint256' },
        { name: 'checkCollateralStateFingerprint', type: 'bool' },
        { name: 'collateralStateFingerprint', type: 'bytes32' },
        { name: 'creditAddress', type: 'address' },
        { name: 'creditAmount', type: 'uint256' },
        { name: 'availableCreditLimit', type: 'uint256' },
        { name: 'fixedInterestAmount', type: 'uint256' },
        { name: 'accruingInterestAPR', type: 'uint40' },
        { name: 'duration', type: 'uint32' },
        { name: 'expiration', type: 'uint40' },
        { name: 'allowedAcceptor', type: 'address' },
        { name: 'proposer', type: 'address' },
        { name: 'proposerSpecHash', type: 'bytes32' },
        { name: 'isOffer', type: 'bool' },
        { name: 'refinancingLoanId', type: 'uint256' },
        { name: 'nonceSpace', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'loanContract', type: 'address' },
      ],
    }

    const hash = hashTypedData({
      domain,
      message: await this.createProposalStruct(proposal),
      primaryType: 'Proposal',
      types,
    })

    return {
      proposalHash: hash,
    }
  }

  public async createProposalHashAndSignature(proposal: V1_2SimpleLoanSimpleProposal) {
    const freeUserNonceData = await freeUserNonceRetrieve(
      String(proposal.chainId),
      CHAINS_CONSTANTS[proposal.chainId].pwnV1_2Contracts.pwnRevokedNonce,
      proposal.proposer,
      {
        nonce_count_to_reserve: 1,
      },
    )
    proposal.nonceSpace = BigInt(freeUserNonceData.data.freeUserNonceSpace)
    proposal.nonce = BigInt(freeUserNonceData.data.freeUserNonces[0])

    const domain = {
      name: 'PWNSimpleLoanSimpleProposal',
      version: '1.2',
      chainId: proposal.chainId,
      verifyingContract: proposal.proposalContractAddress,
    }

    const types = {
      Proposal: [
        { name: 'collateralCategory', type: 'uint8' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralId', type: 'uint256' },
        { name: 'collateralAmount', type: 'uint256' },
        { name: 'checkCollateralStateFingerprint', type: 'bool' },
        { name: 'collateralStateFingerprint', type: 'bytes32' },
        { name: 'creditAddress', type: 'address' },
        { name: 'creditAmount', type: 'uint256' },
        { name: 'availableCreditLimit', type: 'uint256' },
        { name: 'fixedInterestAmount', type: 'uint256' },
        { name: 'accruingInterestAPR', type: 'uint40' },
        { name: 'duration', type: 'uint32' },
        { name: 'expiration', type: 'uint40' },
        { name: 'allowedAcceptor', type: 'address' },
        { name: 'proposer', type: 'address' },
        { name: 'proposerSpecHash', type: 'bytes32' },
        { name: 'isOffer', type: 'bool' },
        { name: 'refinancingLoanId', type: 'uint256' },
        { name: 'nonceSpace', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'loanContract', type: 'address' },
      ],
    }

    const { hash: proposalHash, signature } = await signEip712({
      domain,
      types,
      primaryType: 'Proposal',
      message: await this.createProposalStruct(proposal),
    })

    return {
      proposalHash,
      signature,
    } as const
  }

  public static async createStarknetMultiProposalHashAndSignature(merkleRoot: string, chainId: SupportedChain) {
    const multiproposalDomain = {
      name: 'PWNMultiproposal',
    }

    const types = {
      StarkNetDomain: [
        { name: 'name', type: 'string' },
      ],
      Multiproposal: [{ name: 'multiproposalMerkleRoot', type: 'felt' }],
    }

    const typedDataValidate: TypedData = {
      types,
      primaryType: 'Multiproposal',
      domain: multiproposalDomain,
      message: {
        multiproposalMerkleRoot: merkleRoot,
      },
    }

    // const signature = starknetKeccak(typedDataValidate)

    const signature = await starknetAccount.value?.signMessage(typedDataValidate)
    if (!signature) {
      throw new Error('User rejected signature')
    }
    const r = signature[3]
    const s = signature[4]

    const combinedSignature = [r, s]
      .map(n => BigInt(n).toString(16).padStart(64, '0'))
      .join('')

    return {
      multiProposalHash: merkleRoot,
      signature: `0x${combinedSignature}`,
    } as const
  }

  public static async createMultiProposalHashAndSignature(merkleRoot: string, chainId: SupportedChain) {
    if (isStarknet) {
      return await this.createStarknetMultiProposalHashAndSignature(merkleRoot, chainId)
    }

    const multiproposalDomain = {
      name: 'PWNMultiproposal',
    }

    const types = {
      Multiproposal: [{ name: 'multiproposalMerkleRoot', type: 'bytes32' }],
    }

    const { hash: multiProposalHash, signature } = await signEip712(
      {
        domain: multiproposalDomain,
        types,
        primaryType: 'Multiproposal',
        message: {
          multiproposalMerkleRoot: merkleRoot,
        },
      },
      chainId,
    )

    return {
      multiProposalHash,
      signature,
    } as const
  }

  public async createProposalStruct(
    proposal: V1_2SimpleLoanSimpleProposal,
  ): Promise<V1_2SimpleLoanSimpleProposalStruct> {
    const proposerSpecHash = proposal.isOffer
      ? await readPwnV1_2SimpleLoanGetLenderSpecHash(pwnWagmiConfig, {
        address: proposal.loanContractAddress,
        chainId: proposal.chainId,
        args: [{ sourceOfFunds: proposal.sourceOfFunds ? proposal.sourceOfFunds : proposal.proposer }],
      })
      : EMPTY_32_BYTES

    return {
      collateralCategory: proposal.collateral.category,
      collateralAddress: proposal.collateral.address,
      collateralId: proposal.collateral?.tokenId ?? 0n,
      collateralAmount: proposal.collateralAmount,
      checkCollateralStateFingerprint: proposal.checkCollateralStateFingerprint,
      collateralStateFingerprint: EMPTY_32_BYTES,
      creditAddress: proposal.creditAsset?.address,
      creditAmount: proposal.creditAmount,
      availableCreditLimit: proposal.availableCreditLimit,
      fixedInterestAmount: proposal.fixedInterestAmount,
      accruingInterestAPR: proposal.accruingInterestAPR,
      duration: proposal.loanDurationSeconds,
      expiration: proposal.expiration,
      allowedAcceptor: zeroAddress,
      proposer: proposal.proposer,
      proposerSpecHash,
      isOffer: proposal.isOffer,
      refinancingLoanId: proposal.refinancingLoanId,
      nonceSpace: proposal.nonceSpace,
      nonce: proposal.nonce,
      loanContract: proposal.loanContractAddress,
    }
  }

  public async encodeProposalData(proposal: V1_2SimpleLoanSimpleProposal): Promise<Hex> {
    if (!proposal.proposalContractAddress) {
      throw new Error('proposal.proposalContractAddress is not defined')
    }

    const proposalStruct = await this.createProposalStruct(proposal)
    return await readPwnV1_2SimpleLoanSimpleProposalEncodeProposalData(pwnWagmiConfig, {
      address: proposal.proposalContractAddress,
      chainId: proposal.chainId,
      args: [proposalStruct],
    })
  }
}
