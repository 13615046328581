<template>
  <div
    v-if="!isPageNotFound"
    class="collection-offers-page">
    <CollectionPageHeader
      :open-offers-tab="() => void 0"
      class="collection-offers-page__header"/>
    <BaseSkeletor v-if="isFetchingCollection"/>
    <CollectionPageDescription v-else-if="collection"/>

    <div class="collection-offers-page__table-and-footer">
      <div class="collection-offers-page__content">
        <div class="collection-offers-page__content-left">
          <div class="collection-offers-page__tabs">
            <BaseButton
              class="collection-offers-page__tab"
              is-full-width
              :variant="ButtonVariant.Outline"
              :color="activeTab === Tabs.Lend ? ButtonColor.Primary : ButtonColor.White"
              :font="ButtonFont.Screener"
              button-text="LEND"
              @click="() => setActiveTab(Tabs.Lend)"/>
            <BaseButton
              class="collection-offers-page__tab"
              is-full-width
              :font="ButtonFont.Screener"
              :color="activeTab === Tabs.Borrow ? ButtonColor.Primary : ButtonColor.White"
              :variant="ButtonVariant.Outline"
              button-text="BORROW"
              @click="() => setActiveTab(Tabs.Borrow)"/>
            <BaseButton
              class="collection-offers-page__tab"
              is-full-width
              :variant="ButtonVariant.Outline"
              :color="activeTab === Tabs.Assets ? ButtonColor.Primary : ButtonColor.White"
              :font="ButtonFont.Screener"
              button-text="Assets"
              @click="() => setActiveTab(Tabs.Assets)"/>
          </div>

          <div class="collection-offers-page__filters">
            <div class="collection-offers-page__filters-left">
              <div class="collection-offers-page__filters-row">
                <ProposalFilters
                  v-model:show-history="showHistory"
                  is-read-only
                  :selected-collateral="collectionAsset"/>
              </div>
            </div>
          </div>

          <RouterView v-slot="{ Component, route }">
            <Transition name="move-to-left">
              <div :key="route.path">
                <KeepAlive>
                  <component
                    :is="Component"
                    v-if="collectionAsset?.address && collectionAsset?.chainId"
                    :selected-collateral-filter-assets="collectionAsset ? [collectionAsset] : undefined"
                    include-collateral-without-price
                    :include-credit-without-price="globalFiltersStore.showAssetsWithoutPricing"
                    include-unverified-collateral
                    :include-unverified-credit="globalFiltersStore.showUnverifiedAssets"
                    :show-history="showHistory"
                    :type="activeTab === Tabs.Borrow ? 'borrow' : 'lend'"/>
                </KeepAlive>
              </div>
            </Transition>
          </RouterView>
        </div>

        <Transition name="move-to-right">
          <ProposalForm
            :has-proposal-type-tabs="false"
            :is-collateral-locked="!isActiveTabBorrow"
            :selected-collateral="selectedCollateral"
            :selected-credit="selectedCredit"
            :select-collateral="selectCollateral"
            :is-select-collection-asset="isActiveTabBorrow"
            :select-credit="selectCredit"
            borrowing-is-enabled
            :is-active-tab-borrow="isActiveTabBorrow"/>
        </Transition>
      </div>
    </div>
  </div>
  <PageNotFound v-else/>
</template>

<script setup lang="ts">
import CollectionPageHeader from '@/revamp/pages/collection/CollectionPageHeader.vue'
import CollectionPageDescription from '@/revamp/pages/collection/CollectionPageDescription.vue'
import { useCollectionPageStore } from '@/revamp/pages/collection/useCollectionPageStore'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import PageNotFound from '@/modules/pages/not-found/PageNotFound.vue'
import BaseButton, { ButtonColor, ButtonFont, ButtonVariant } from '@/general-components/BaseButton.vue'
import router from '@/router'
import RouteName from '@/router/RouteName'
import ProposalForm from '@/revamp/components/proposal-form/ProposalForm.vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import {
  useSelectCollectionAssetModalStore,
} from '@/revamp/components/modals/select-collection-asset/useSelectCollectionAssetModalStore'
import { useLocalStorage } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import { useNoProposalsStore } from '@/revamp/hooks/useNoProposalsStore'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'
import ProposalFilters from '@/revamp/components/proposal-filters/ProposalFilters.vue'

const storeOffersPage = useCollectionPageStore()
const proposalFiltersStore = useProposalFilters()
const storeSelectCollectionAssetModal = useSelectCollectionAssetModalStore()

const globalFiltersStore = useGlobalFiltersStore()

const isPageNotFound = ref(false)
const route = useRoute()
const { isFetchingCollection, collection } = storeToRefs(storeOffersPage)
const showHistory = useLocalStorage('show-history-on-collection-detail', false)

const selectedCredit = ref<AssetWithAmount | null>(null)
const selectedCollateral = ref<AssetWithAmount | null>(null)
const collectionAsset = ref<AssetWithAmount | null>(null)
const selectCredit = (asset?: AssetWithAmount | AssetMetadata) => {
  selectedCredit.value = asset ? new AssetWithAmount(asset) : null
}

const selectCollateral = (asset: AssetWithAmount | AssetMetadata | Array<AssetWithAmount | AssetMetadata> | undefined) => {
  if (Array.isArray(asset)) {
    Sentry.captureMessage('Unexpectedly received asset arg in CollectionPage.selectCollateral as array.')
  } else {
    selectedCollateral.value = asset ? new AssetWithAmount(asset) : null
  }
}

onUnmounted(() => {
  proposalFiltersStore.collateralAssetToFilter = null
})

onMounted(() => {
  const isLendHighlighted = useRoute().query.highlight === 'true'
  if (isLendHighlighted) {
    useNoProposalsStore().handleHighlightForm()
  }
})

const { address } = useCustomAccount()

// TODO do we have to disable borrow tab if user does not hove the asset?
// const collectionChainId = computed(() => collection.value?.chainId || null)
// const { nfts, isLoading } = useUserNfts(address, collectionChainId)

// const userHasBorrowingAsset = computed(() => {
//   if (isLoading.value && !collection.value) {
//     return false
//   }
//
//   const v = nfts.value.some(token => compareAddresses(token.address, collection.value?.contractAddress) && token.chainId === collection.value?.chainId)
//   return v
// })

enum Tabs {
  Borrow = 'borrow',
  Lend = 'lend',
  Assets = 'assets',
}

const activeTab = useLocalStorage<Tabs>('collectionPageLastActiveTab', Tabs.Assets)

const setActiveTab = (tab: Tabs) => {
  activeTab.value = tab

  switch (tab) {
  case Tabs.Borrow:
    router.replace({
      name: RouteName.RevampCollectionProposalsBorrowing,
    })
    break
  case Tabs.Lend:
    router.replace({
      name: RouteName.RevampCollectionProposalsLending,
    })
    break
  case Tabs.Assets:
    router.replace({
      name: RouteName.CollectionAssetsTab,
    })
    break
  }
}
const setBorrowTab = () => {
  setActiveTab(Tabs.Borrow)
  storeSelectCollectionAssetModal.selectAsset = async (asset) => {
    selectedCollateral.value = asset
  }
  storeSelectCollectionAssetModal.isOnlySelectAsset = true
  storeSelectCollectionAssetModal.chainId = collectionAsset.value?.chainId
  storeSelectCollectionAssetModal.collectionAddress = collectionAsset.value?.address
  selectedCollateral.value = null
}

const setLendTab = () => {
  if (collectionAsset.value) {
    selectedCollateral.value = collectionAsset.value
    selectedCredit.value = null
  }
  setActiveTab(Tabs.Lend)
}
const isActiveTabBorrow = computed(() => activeTab.value === Tabs.Borrow)

watch(collection, (freshCollection) => {
  if (freshCollection) {
    const newCollectionAsset = new AssetWithAmount({
      name: freshCollection.name,
      address: freshCollection.contractAddress,
      image: freshCollection.image,
      chainId: freshCollection.chainId,
      category: freshCollection.category,
      amount: '1',
      symbol: freshCollection.symbol,
      collection: freshCollection,
    })
    collectionAsset.value = newCollectionAsset
    proposalFiltersStore.collateralAssetToFilter = newCollectionAsset
    storeSelectCollectionAssetModal.collectionAddress = collectionAsset.value?.address
    storeSelectCollectionAssetModal.chainId = collectionAsset.value?.chainId
    if (!isActiveTabBorrow.value) {
      selectCollateral(newCollectionAsset)
    }
  }
}, { immediate: true })

watch(
  [address, () => route.params],
  async () => {
    isPageNotFound.value = false
    if (!storeOffersPage.isValidRouteParams(route.params)) {
      return
    }

    if (route.name === RouteName.RevampCollectionProposalsLending) {
      setLendTab()
    }

    if (route.name === RouteName.RevampCollectionProposalsBorrowing) {
      setBorrowTab()
    }

    const isCollectionFound = await storeOffersPage.fetchCollectionDataIfNotAlreadyFetched(
      storeOffersPage.parseRouteParams(route.params),
    )
    if (!isCollectionFound) {
      isPageNotFound.value = true
    }
  },
  { immediate: true },
)

watch(() => route.params, () => {
  if (route.name === RouteName.CollectionByContractAddress) {
    setActiveTab(activeTab.value)
  }
}, { immediate: true })

onMounted(() => {
  const isLendHighlighted = useRoute().query.highlight === 'true'
  if (isLendHighlighted) {
    useNoProposalsStore().handleHighlightForm()
  }
})
</script>

<style scoped>
.collection-offers-page {
  padding: 3.5rem 0 5rem;
  font-family: var(--font-family-supreme);

  &__header {
    width: 100%;
  }

  &__create-offer-button {
    display: flex;
    justify-content: flex-end;
  }

  &__tabs {
    display: flex;
    gap: 2rem;

    margin-bottom: 1.5rem;
  }

  &__tab {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 2rem;
  }

  &__content-left {
    flex: 1;

    padding: 1rem;
    border: 1px solid #434343;
  }

  &__content-right {
    flex: 1;

    padding: 1rem;
    border: 1px solid #434343;

    max-width: 360px;
  }

  &__table-and-footer {
    position: relative;
  }

  &__table {
    margin: 1rem 0;
    padding: 0;

    &--bottom-dashed-line {
      padding: 0;

      &::after {
        content: "";
        display: block;
        height: 1px;
        background-image: var(--border-gray-dashed);
        background-size: auto 2px;
        /* small hack how to hide buggy double border (top + bottom), when height is 1px */
      }
    }
  }

  @media only screen and (--small-viewport) {
    padding-top: 1rem;

    &__table {
      overflow: auto auto;
    }

    &__tabs {
      display: flex;
      flex-flow: column;
      gap: 0.5rem;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }

  &__proposals-title {
    font-size: 1.125rem;
  }
}
</style>
