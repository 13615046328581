<template>
  <div
    ref="tableContainerRef"
    class="container"
    style="overflow: auto; position: relative; height: 400px;">
    <table class="user-nfts-collection-assets">
      <thead class="user-nfts-collection-assets__head">
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
          class="user-nfts-collection-assets__head-row">
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            :colSpan="header.colSpan">
            <FlexRender
              v-if="!header.isPlaceholder"
              :render="header.column.columnDef.header"
              :props="header.getContext()"/>
          </th>
        </tr>
      </thead>
      <tbody
        class="user-nfts-collection-assets__body"
        style="display: grid; height: 100%; position: relative;"
        :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
        <tr
          v-for="row in virtualRows"
          :key="rows[row.index].id"
          :ref="customRefHandler"
          class="user-nfts-collection-assets__row"
          :data-index="row.index"
          :style="{
            display: 'flex',
            position: 'absolute',
            transform: `translateY(${row.start}px)`,
            width: '100%',
            justifyContent: 'space-between',
          }"
          @click="() => handleRowClick(rows[row.index])">
          <td
            v-for="cell in rows[row.index].getVisibleCells()"
            :key="cell.id"
            :style="{
              width: `${cell.column.getSize()}px`,
            }">
            <FlexRender
              :render="cell.column.columnDef.cell"
              :props="cell.getContext()"/>
          </td>
        </tr>
        <BaseSkeletor v-if="props.isLoading"/>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { FlexRender, getCoreRowModel, getFilteredRowModel, useVueTable } from '@tanstack/vue-table'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, h, ref } from 'vue'
import AssetInfoCell from '@/revamp/components/tables/cells/assets/AssetInfoCell.vue'
import AssetAmountWithAppraisal from '@/revamp/components/tables/cells/assets/AssetAmountWithAppraisal.vue'
import { useVirtualizer } from '@tanstack/vue-virtual'
import { isAssetIncludedInSearchResults } from '@/utils/search'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'

type Props = {
  data: AssetWithAmount[];
  search?: string
  isLoading: boolean
};

const props = defineProps<Props>()
const tableContainerRef = ref(null)

const emit = defineEmits<{(e: 'select-asset', value: AssetWithAmount) }>()

const columnFilters = computed(() => {
  if (props.search) {
    return [{
      id: 'name',
      value: props.search,
    }]
  }
  return []
})

const table = useVueTable<AssetWithAmount>({
  get data() {
    return props.data
  },
  state: {
    get columnFilters() {
      return columnFilters.value
    },
  },
  getRowId: (row) => String(row.id),
  columns: [
    {
      header: 'Collateral',
      id: 'name',
      accessorFn: (row) => row.name + ' ' + row.symbol,
      filterFn: (row, filterValue, v) => {
        return isAssetIncludedInSearchResults(row.original, v.toLowerCase())
      },
      size: 300,
      cell: (ctx) => {
        return h(AssetInfoCell, {
          asset: ctx.row.original,
          nameWidth: '15rem',
        })
      },
    },
    {
      header: 'You Own',
      accessorFn: (row) => {
        return row.amount
      },
      cell: (ctx) => {
        return h(AssetAmountWithAppraisal, {
          asset: ctx.row.original,
          amount: ctx.row.original.amount,
        })
      },
      size: 75,
    },
  ],
  getCoreRowModel: getCoreRowModel(),
  enableRowSelection(row) {
    return row.original.isValid // todo: check if it's correct
  },
  getFilteredRowModel: getFilteredRowModel(),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rows.value.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 42,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

const handleRowClick = (row: any) => {
  emit('select-asset', row.original)
}

</script>

<style scoped>
.user-nfts-collection-assets {
  display: grid;

  padding-right: 1rem;

  &__head {
    position: sticky;
    top: 0;
    z-index: 5;

    display: flex;

    background: var(--gray-3);
    padding: 0 0.5rem;
  }

  &__head-row {
    width: 100%;
    padding: 0.25rem;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family-screener);
  }

  &__row {
    width: 100%;
    justify-content: space-between;
    display: inline-flex;

    padding: 0.5rem 1rem;

    &:hover {
      background-color: var(--gray-3);
      cursor: pointer;
    }
  }
}
</style>
