import type { ColumnProperties } from '@/general-components/ColumnProperties'

const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Name',
    width: '85%',
    slotName: 'name',
  },
  {
    title: '',
    width: '15%',
    slotName: 'action',
  },
]
export default TABLE_DEFINITION
