<template>
  <AssetPrice
    :show-source="showSource"
    :chain-id="chainId"
    :contract-address="address"
    :category="category"
    :token-id="tokenId">
    <template #default="{ unitPrice, priceData }">
      <div class="asset-appraisal">
        <slot
          :price="unitPrice"
          :price-data="priceData">
          <SafeDisplayDecimals :value="getPriceToDisplay(unitPrice)"/>
        </slot>
      </div>
    </template>
  </AssetPrice>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { useCurrencySwitcherStore } from '@/layout/header/useCurrencySwitcherStore'
import { storeToRefs } from 'pinia'
import AssetPrice from '@/revamp/components/prices/AssetPrice.vue'
import SafeDisplayDecimals from '@/general-components/safe-display-decimals/SafeDisplayDecimals.vue'
import { formatDecimalPoint } from '@/utils/utils'
import type AssetType from '@/modules/common/assets/AssetType'
import type { AssetPriceLog } from '@/revamp/modules/asset-prices/types'
import type { SupportedChain } from '@/constants/chains/types'
import type { Address } from 'viem'

type Props = {
  assetAmount: string;
  assetMetadata: { chainId: number; address: string; tokenId?: string | number | null };
  category?: AssetType;
  showSource?: boolean;
};

const emit = defineEmits<{(e: 'asset-appraisal-updated', log: AssetPriceLog): void }>()

const props = defineProps<Props>()
const { assetAmount, assetMetadata, showSource } = toRefs(props)

const store = useCurrencySwitcherStore()
const { selectedCurrency } = storeToRefs(store)

const chainId = computed(() => String(assetMetadata.value.chainId))
const address = computed(() => assetMetadata.value.address)
const tokenId = computed(() => (assetMetadata.value.tokenId ? String(assetMetadata.value.tokenId) : null))

const getPriceToDisplay = (unitPrice: string | null | undefined) => {
  const currency = selectedCurrency.value.label
  const displayInEth = currency === 'ETH'

  if (!unitPrice) return '---'
  const parsedAmount = assetAmount.value === '' ? 1 : parseFloat(assetAmount.value)

  const totalAmount = unitPrice && parsedAmount ? +parsedAmount * parseFloat(unitPrice) : 0
  const formattedTotalAmount = parseFloat(String(totalAmount))
  emit('asset-appraisal-updated', {
    assetContractAddress: address.value as Address,
    assetChainId: Number(chainId.value) as SupportedChain,
    assetTokenId: tokenId.value,
    assetAmountInCurrency: totalAmount,
    currency: currency as 'USD' | 'ETH',
  })

  if (displayInEth) {
    return formatDecimalPoint(formattedTotalAmount) + ' ETH'
  } else {
    return '$' + formatDecimalPoint(formattedTotalAmount, 2)
  }
}
</script>

<style>
.asset-appraisal {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
