/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  MarkAsSeenBodyRequestBackendSchema,
  NotificationBackendSchema,
  NotificationNotificationListParams,
  NotificationRequestBackendSchema,
  NotificationUserUnseenNotificationsCountParams,
  PaginatedNotificationListBackendSchema,
  UnseenNotificationsCountBackendSchema
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const notificationsMarkAsSeenAll = (
    recipientAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,) => {
      recipientAddress = unref(recipientAddress);
      
      return customInstance<void>(
      {url: `/api/v1/notification/mark-as-seen-all/${encodeURIComponent(String(recipientAddress))}/`, method: 'POST'
    },
      options);
    }
  


export const getNotificationsMarkAsSeenAllMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notificationsMarkAsSeenAll>>, TError,{recipientAddress: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof notificationsMarkAsSeenAll>>, TError,{recipientAddress: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notificationsMarkAsSeenAll>>, {recipientAddress: string}> = (props) => {
          const {recipientAddress} = props ?? {};

          return  notificationsMarkAsSeenAll(recipientAddress,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type NotificationsMarkAsSeenAllMutationResult = NonNullable<Awaited<ReturnType<typeof notificationsMarkAsSeenAll>>>
    
    export type NotificationsMarkAsSeenAllMutationError = unknown

    export const useNotificationsMarkAsSeenAll = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notificationsMarkAsSeenAll>>, TError,{recipientAddress: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof notificationsMarkAsSeenAll>>,
        TError,
        {recipientAddress: string},
        TContext
      > => {

      const mutationOptions = getNotificationsMarkAsSeenAllMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const notificationsMarkAsSeen = (
    recipientAddress: MaybeRef<string>,
    markAsSeenBodyRequestBackendSchema: MaybeRef<MarkAsSeenBodyRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      recipientAddress = unref(recipientAddress);
markAsSeenBodyRequestBackendSchema = unref(markAsSeenBodyRequestBackendSchema);
      
      return customInstance<void>(
      {url: `/api/v1/notification/mark-as-seen/${encodeURIComponent(String(recipientAddress))}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: markAsSeenBodyRequestBackendSchema
    },
      options);
    }
  


export const getNotificationsMarkAsSeenMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notificationsMarkAsSeen>>, TError,{recipientAddress: string;data: MarkAsSeenBodyRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof notificationsMarkAsSeen>>, TError,{recipientAddress: string;data: MarkAsSeenBodyRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notificationsMarkAsSeen>>, {recipientAddress: string;data: MarkAsSeenBodyRequestBackendSchema}> = (props) => {
          const {recipientAddress,data} = props ?? {};

          return  notificationsMarkAsSeen(recipientAddress,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type NotificationsMarkAsSeenMutationResult = NonNullable<Awaited<ReturnType<typeof notificationsMarkAsSeen>>>
    export type NotificationsMarkAsSeenMutationBody = MarkAsSeenBodyRequestBackendSchema
    export type NotificationsMarkAsSeenMutationError = unknown

    export const useNotificationsMarkAsSeen = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notificationsMarkAsSeen>>, TError,{recipientAddress: string;data: MarkAsSeenBodyRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof notificationsMarkAsSeen>>,
        TError,
        {recipientAddress: string;data: MarkAsSeenBodyRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getNotificationsMarkAsSeenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const notificationNotificationList = (
    recipientAddress: MaybeRef<string>,
    params?: MaybeRef<NotificationNotificationListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      recipientAddress = unref(recipientAddress);
params = unref(params);
      
      return customInstance<PaginatedNotificationListBackendSchema>(
      {url: `/api/v1/notification/notification/${encodeURIComponent(String(recipientAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getNotificationNotificationListQueryKey = (recipientAddress: MaybeRef<string>,
    params?: MaybeRef<NotificationNotificationListParams>,) => {
    return ['api','v1','notification','notification',recipientAddress, ...(params ? [params]: [])] as const;
    }

    
export const getNotificationNotificationListQueryOptions = <TData = Awaited<ReturnType<typeof notificationNotificationList>>, TError = unknown>(recipientAddress: MaybeRef<string>,
    params?: MaybeRef<NotificationNotificationListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationNotificationList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getNotificationNotificationListQueryKey(recipientAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationNotificationList>>> = ({ signal }) => notificationNotificationList(recipientAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(recipientAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof notificationNotificationList>>, TError, TData> 
}

export type NotificationNotificationListQueryResult = NonNullable<Awaited<ReturnType<typeof notificationNotificationList>>>
export type NotificationNotificationListQueryError = unknown

export const useNotificationNotificationList = <TData = Awaited<ReturnType<typeof notificationNotificationList>>, TError = unknown>(
 recipientAddress: MaybeRef<string>,
    params?: MaybeRef<NotificationNotificationListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationNotificationList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getNotificationNotificationListQueryOptions(recipientAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const notificationNotificationCreate = (
    recipientAddress: MaybeRef<string>,
    notificationRequestBackendSchema: MaybeRef<NotificationRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      recipientAddress = unref(recipientAddress);
notificationRequestBackendSchema = unref(notificationRequestBackendSchema);
      
      return customInstance<NotificationBackendSchema>(
      {url: `/api/v1/notification/notification/${encodeURIComponent(String(recipientAddress))}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: notificationRequestBackendSchema
    },
      options);
    }
  


export const getNotificationNotificationCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notificationNotificationCreate>>, TError,{recipientAddress: string;data: NotificationRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof notificationNotificationCreate>>, TError,{recipientAddress: string;data: NotificationRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notificationNotificationCreate>>, {recipientAddress: string;data: NotificationRequestBackendSchema}> = (props) => {
          const {recipientAddress,data} = props ?? {};

          return  notificationNotificationCreate(recipientAddress,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type NotificationNotificationCreateMutationResult = NonNullable<Awaited<ReturnType<typeof notificationNotificationCreate>>>
    export type NotificationNotificationCreateMutationBody = NotificationRequestBackendSchema
    export type NotificationNotificationCreateMutationError = unknown

    export const useNotificationNotificationCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notificationNotificationCreate>>, TError,{recipientAddress: string;data: NotificationRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof notificationNotificationCreate>>,
        TError,
        {recipientAddress: string;data: NotificationRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getNotificationNotificationCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const notificationUserUnseenNotificationsCount = (
    recipientAddress: MaybeRef<string>,
    params?: MaybeRef<NotificationUserUnseenNotificationsCountParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      recipientAddress = unref(recipientAddress);
params = unref(params);
      
      return customInstance<UnseenNotificationsCountBackendSchema>(
      {url: `/api/v1/notification/user-unseen-notifications-count/${encodeURIComponent(String(recipientAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getNotificationUserUnseenNotificationsCountQueryKey = (recipientAddress: MaybeRef<string>,
    params?: MaybeRef<NotificationUserUnseenNotificationsCountParams>,) => {
    return ['api','v1','notification','user-unseen-notifications-count',recipientAddress, ...(params ? [params]: [])] as const;
    }

    
export const getNotificationUserUnseenNotificationsCountQueryOptions = <TData = Awaited<ReturnType<typeof notificationUserUnseenNotificationsCount>>, TError = unknown>(recipientAddress: MaybeRef<string>,
    params?: MaybeRef<NotificationUserUnseenNotificationsCountParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationUserUnseenNotificationsCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getNotificationUserUnseenNotificationsCountQueryKey(recipientAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationUserUnseenNotificationsCount>>> = ({ signal }) => notificationUserUnseenNotificationsCount(recipientAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(recipientAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof notificationUserUnseenNotificationsCount>>, TError, TData> 
}

export type NotificationUserUnseenNotificationsCountQueryResult = NonNullable<Awaited<ReturnType<typeof notificationUserUnseenNotificationsCount>>>
export type NotificationUserUnseenNotificationsCountQueryError = unknown

export const useNotificationUserUnseenNotificationsCount = <TData = Awaited<ReturnType<typeof notificationUserUnseenNotificationsCount>>, TError = unknown>(
 recipientAddress: MaybeRef<string>,
    params?: MaybeRef<NotificationUserUnseenNotificationsCountParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof notificationUserUnseenNotificationsCount>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getNotificationUserUnseenNotificationsCountQueryOptions(recipientAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



