<script lang="ts" setup>

import { watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { apiV2PwnContractsOldLoanUrlMappingRetrieve } from '@/modules/common/backend/generated'
import to from '@/utils/await-to-js'
import router from '@/router'
import RouteName from '@/router/RouteName'

const route = useRoute()

watchEffect(async () => {
  const id: string = route.params.id as string

  // @ts-expect-error it is what it is
  const [err, res] = await to<{data: string}>(apiV2PwnContractsOldLoanUrlMappingRetrieve(id) as Promise<{data: string}>)
  if (err || !res?.data) {
    await router.push({
      name: RouteName.RevampHomepage,
    })
    return
  }

  await router.push(res?.data)
})

</script>
