import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { computed, ref, watch } from 'vue'

export const useServiceTermsModal = () => {
  const { address: userAddress } = useCustomAccount()
  const localStorageHasAcceptedTermsKey = computed(() => {
    const LOCAL_STORAGE_LEGAL_SIGN = 'accepted-terms'
    return `${userAddress.value}__${LOCAL_STORAGE_LEGAL_SIGN}`
  })

  // we are not use useLocalStorage from @vueuse because the local storage key is changing depending
  //  on the connected wallet address and it's not possible to pass a reactive key to useLocalStorage
  //  because of this we need to create a ref helper for reactivity to work fine and UI reacted to changes
  const _isAcceptedTerms = ref(window.localStorage.getItem(localStorageHasAcceptedTermsKey.value) === 'true')
  watch(localStorageHasAcceptedTermsKey, newKey => {
    _isAcceptedTerms.value = window.localStorage.getItem(newKey) === 'true'
  })

  const isAcceptedTerms = computed({
    get() {
      return _isAcceptedTerms.value
    },
    set(value) {
      _isAcceptedTerms.value = value
      window.localStorage.setItem(localStorageHasAcceptedTermsKey.value, String(value))
    },
  })

  const acceptTerms = () => {
    isAcceptedTerms.value = true
  }

  const isModalShown = computed(() => false)

  return {
    isModalShown,
    acceptTerms,
  }
}
