<template>
  <div class="section-collection-stats">
    <BaseAssetPageSection
      icon="collection"
      question-mark-tooltip="Statistics for the entire collection this particular NFT belongs to"
      label="Collection Stats"
      :has-data="hasCollectionStats"
      :is-fetching="isFetchingCollectionStats">
      <template #content>
        <div
          v-for="stat in collectionStatsWithLabels"
          :key="stat.description.label"
          class="section-collection-stats__content-row">
          <div class="section-collection-stats__left-cell">
            <span>{{ stat.description.label }}</span>
            <span class="section-collection-stats__question-mark-tooltip">
              <QuestionMarkTooltip
                v-if="stat.description.questionMarkTooltip"
                :tooltip-text="stat.description.questionMarkTooltip"/>
            </span>
          </div>

          <div class="section-collection-stats__right-cell">
            <span v-if="!stat.value?.value">No data</span>
            <span v-else-if="stat.value.type === StatValueTypeEnum.Text">
              {{ stat.value.value }}
            </span>
            <span
              v-else-if="isValuePriceChange(stat.value.value, stat.value.type)"
              :class="getClassesForPercentageItem(stat.value.value)">
              {{ stat.value.value }}%
            </span>
            <EthAndUsdPriceLabel
              v-else-if="isValuePrice(stat.value.value, stat.value.type)"
              class="section-collection-stats__price"
              :amount="stat.value.value"/>
            <div
              v-else-if="isValueDate(stat.value.value, stat.value.type)"
              class="section-collection-stats__expiration">
              {{ DateUtils.displayDate(stat.value.value) }}
            </div>
          </div>
        </div>
        <div class="section-collection-stats__footer">
          <div>
            Last updated: {{ DateUtils.displayDate(collectionStats!.lastUpdated) }}
          </div>
          <DataSource
            :data-source="collectionStats!.dataSource">
            <template #textBefore>
              Data source
            </template>
          </DataSource>
        </div>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import DateUtils from '@/utils/DateUtils'
import type { AllowedStatValueTypes } from '@/modules/pages/asset/nft-page/types/CollectionOrAppraisalRow'
import { StatValueTypeEnum } from '@/modules/pages/asset/nft-page/types/CollectionOrAppraisalRow'
import useSectionCollectionStats from '@/modules/pages/asset/nft-page/SectionCollectionStats/useSectionCollectionStats'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import { functionExists } from '@/utils/utils'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import type { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import DataSource from '@/general-components/data-source/DataSource.vue'

const getClassesForPercentageItem = (percentChange: string): string => {
  if (!functionExists(percentChange?.startsWith)) {
    return ''
  }
  return `section-collection-stats__percent-change--${percentChange.startsWith('-') ? 'negative' : 'positive'}`
}

const { collectionStatsWithLabels, isFetchingCollectionStats, hasCollectionStats, collectionStats } = useSectionCollectionStats()

const isValueDate = (value: AllowedStatValueTypes, type: StatValueTypeEnum): value is Date | number => {
  return type === StatValueTypeEnum.Date
}

const isValuePrice = (value: AllowedStatValueTypes, type: StatValueTypeEnum): value is AmountInEthAndUsd => {
  return type === StatValueTypeEnum.Price
}

const isValuePriceChange = (value: AllowedStatValueTypes, type: StatValueTypeEnum): value is string => {
  return type === StatValueTypeEnum.PercentChange
}
</script>

<style scoped>
.section-collection-stats {
  &__content-row {
    display: grid;
    grid-template-columns: repeat(1, 2.7fr 3.5fr);
    font-size: 0.875rem;
  }

  &__content-row:nth-child(odd) {
    background-color: var(--gray-3);
  }

  &__left-cell,
  &__right-cell {
    padding: 0.5rem 0.75rem 0.4rem;
    position: relative;
  }

  &__left-cell {
    text-align: left;
    border-right: 1px solid white;
    display: flex;
    align-items: center;
  }

  &__right-cell {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__question-mark-tooltip {
    margin-left: 0.2rem;
    line-height: 1;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    text-align: right;
    padding: 0.75rem;
    font-size: 0.75rem;
    border-top: 1px solid white;
  }

  &__percent-change {
    &--negative {
      color: var(--danger-1);
    }

    &--positive {
      color: var(--positive-bright);
    }
  }
}

</style>
