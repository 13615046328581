<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    heading-align="left"
    heading="New Loan Requests on PWN">
    <template #trigger>
      <NotificationCenterSettingsCustomizeButton
        :is-customizeable="userNotificationSettings?.newLoanRequest"
        @click="handleClick"/>
    </template>

    <template #body>
      <div class="new-loan-requests__options-container">
        <!-- WILL BE ADDED BACK WITH UPDATED SCHEMA -->
        <!-- <NotificationCenterSettingsOption
          text="All loan requests"
          tooltip-text="Alerts new loan requests posted on the PWN Platform"
          :is-recommended="false"
          :toggle-value="null">
          <template #action>
            <div class="new-loan-requests__not-recommended">
              *not recommended
            </div>
          </template>
        </NotificationCenterSettingsOption> -->
        <NotificationCenterSettingsOption
          v-if="userNotificationSettings?.newLoanRequestFilterSettings?.[0]"
          text="Only verified collateral"
          tooltip-text="Filter loan requests for only verified collateral"
          :is-recommended="false"
          :toggle-value="userNotificationSettings.newLoanRequestFilterSettings[0].onlyVerifiedCollateral"
          @toggle="userNotificationSettings.newLoanRequestFilterSettings[0].onlyVerifiedCollateral = !userNotificationSettings.newLoanRequestFilterSettings[0].onlyVerifiedCollateral"/>
        <NotificationCenterSettingsOption
          v-if="userNotificationSettings?.newLoanRequestFilterSettings?.[0]"
          text="Loans with a duration of:"
          tooltip-text="Only alert for offers which provide an LTV within a certain range"
          is-modal
          :is-recommended="false"
          :toggle-value="userNotificationSettings.newLoanRequestFilterSettings[0].filterDuration"
          @toggle="userNotificationSettings.newLoanRequestFilterSettings[0].filterDuration = !userNotificationSettings.newLoanRequestFilterSettings[0].filterDuration">
          <template #action>
            <div class="new-loan-requests__custom-range-inputs-container">
              <div class="new-loan-requests__limit-input-container">
                <BaseInput
                  v-model.number="userNotificationSettings.newLoanRequestFilterSettings[0].minDurationDays"
                  input-text-align="center"
                  placeholder="0"
                  append-text="days"/>
              </div>
              <div class="new-loan-requests__custom-range-divider">
                -
              </div>
              <div class="new-loan-requests__limit-input-container">
                <BaseInput
                  v-model.number="userNotificationSettings.newLoanRequestFilterSettings[0].maxDurationDays"
                  input-text-align="center"
                  append-text="days"
                  placeholder="10"/>
              </div>
            </div>
          </template>
        </NotificationCenterSettingsOption>
        <NotificationCenterSettingsOption
          v-if="userNotificationSettings?.newLoanRequestFilterSettings?.[0]"
          text="Loans with an LTV of:"
          tooltip-text="Only alert for offers which provide an LTV within a certain range"
          is-modal
          :is-recommended="false"
          :toggle-value="userNotificationSettings.newLoanRequestFilterSettings[0].filterLtv"
          @toggle="userNotificationSettings.newLoanRequestFilterSettings[0].filterLtv = !userNotificationSettings.newLoanRequestFilterSettings[0].filterLtv">
          <template #action>
            <div class="new-loan-requests__custom-range-inputs-container">
              <div class="new-loan-requests__limit-input-container">
                <BaseInput
                  v-model.number="userNotificationSettings.newLoanRequestFilterSettings[0].minLtv"
                  input-text-align="center"
                  placeholder="0"
                  append-text="%"/>
              </div>
              <div class="new-loan-requests__custom-range-divider">
                -
              </div>
              <div class="new-loan-requests__limit-input-container">
                <BaseInput
                  v-model.number="userNotificationSettings.newLoanRequestFilterSettings[0].maxLtv"
                  input-text-align="center"
                  append-text="%"
                  placeholder="10"/>
              </div>
            </div>
          </template>
        </NotificationCenterSettingsOption>
      </div>
      <div class="new-loan-requests__button-container">
        <BaseButton
          button-text="Discard"
          :color="ButtonColor.White"
          :variant="ButtonVariant.Underline"
          @on-button-click="isOpen = false"/>
        <BaseButton
          button-text="Save Settings"
          @on-button-click="handleClose"/>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import NotificationCenterSettingsCustomizeButton from '@/modules/pages/notification-center/settings/NotificationCenterSettingsCustomizeButton.vue'
import { ref } from 'vue'
import NotificationCenterSettingsOption from '@/modules/pages/notification-center/settings/NotificationCenterSettingsOption.vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'
import BaseInput from '@/general-components/BaseInput.vue'
import ModalSize from '@/general-components/ModalSize'
import UserLoanRequestFilterSettings from '@/modules/common/notifications/UserLoanRequestFilterSettings'

const isOpen = ref(false)
const { userNotificationSettings, updateNotificationSettings } = useNotificationCenterSettings()

const handleClick = () => {
  if (!userNotificationSettings.value?.newLoanRequest) return
  // @ts-expect-error FIXME: strictNullCheks
  if (userNotificationSettings.value.newLoanRequestFilterSettings.length < 1) {
    // @ts-expect-error FIXME: strictNullCheks
    userNotificationSettings.value.newLoanRequestFilterSettings.push(new UserLoanRequestFilterSettings({
      onlyVerifiedCollateral: true,
      filterDuration: false,
      // @ts-expect-error FIXME: strictNullCheks
      minDurationDays: null,
      // @ts-expect-error FIXME: strictNullCheks
      maxDurationDays: null,
      filterLtv: false,
      // @ts-expect-error FIXME: strictNullCheks
      minLtv: null,
      // @ts-expect-error FIXME: strictNullCheks
      maxLtv: null,
    }))
  }
  isOpen.value = true
}

const handleClose = () => {
  updateNotificationSettings()
  isOpen.value = false
}
</script>

<style scoped>
.new-loan-requests {
  &__options-container {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
  }

  &__text {
    display: flex;
  }

  &__input {
    margin: 0 2rem;
    min-width: 10rem;
  }

  &__custom-range-inputs-container {
    display: flex;
    height: 1.5rem;
    margin-left: auto;
    margin-right: 1rem;
  }

  &__limit-input-container {
    ::v-deep(.input__container) {
      height: 1.5rem;
    }

    ::v-deep(.input__input) {
      text-align: center;
      padding: 0;
      padding-top: 0.1rem;
      height: 1.3rem;
      width: 7.25rem;
    }
  }

  &__custom-range-divider {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-top: 1px solid var(--gray-2);
    border-bottom: 1px solid var(--gray-2);
  }

  &__tooltip {
    margin-left: 0.5rem;
  }

  &__not-recommended {
    margin: auto 0.5rem auto auto;
    color: var(--danger-1);
  }

  &__filters {
    margin-bottom: 0.75rem;
  }

  &__filter-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 2.5rem;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
