<template>
  <div class="selected-asset-card">
    <AssetBaseInfo
      :asset="asset"
      :display-amount="displayAmount"/>
    <BaseInput
      v-if="showInput"
      v-model="asset.amountInput"
      class="selected-asset-card__amount-input"
      is-autofocused
      button-text="max"
      :placeholder="asset.maxAvailableAmount"
      @button-clicked="asset.amountInput = asset.maxAvailableAmount"/>
  </div>
</template>

<script setup lang="ts">
import AssetBaseInfo from '@/general-components/AssetBaseInfo.vue'
import BaseInput from '@/general-components/BaseInput.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, toRefs } from 'vue'

interface Props {
  asset: AssetWithAmount
  displayInput?: boolean
  displayAmount?: boolean
}
const props = defineProps<Props>()
const { asset, displayInput, displayAmount } = toRefs(props)
const showInput = computed(() => asset.value.isMaxAvailableAmountDivisible && !displayInput?.value)
</script>

<style>
.selected-asset-card {
  max-width: 18rem;
  border: 1px solid var(--gray);
  padding: 1rem;
  background-color: var(--background-color);

  &__amount-input {
    margin-top: 1rem;
  }
}
</style>
