<template>
  <WcModalStep2/>
  <MultisigWalletSignModal/>
  <ModalCryptoPunkWrap/>
  <ModalMobileAlpha/>
  <WhatIsNewModal v-if="whatIsNewModalStore.isOpen"/>
  <NotificationReminderModal/>

  <ModalKYC/>

  <!-- REVAMP -->
  <ModalProposalPreview/>
  <StartLoanModal/>
  <SelectCollectionAssetModal/>

  <AcceptServiceTermsModal/>
  <AcceptFungibleProposalModal/>
</template>

<script setup lang="ts">
import WhatIsNewModal from '@/general-components/what-is-new-modal/WhatIsNewModal.vue'
import ModalCryptoPunkWrap from '@/general-components/ModalCryptoPunkWrap.vue'
import MultisigWalletSignModal from '@/general-components/multisig-wallet/MultisigWalletSignModal.vue'
import WcModalStep2 from '@/modules/pages/pwn-safe/pwn-safe-dashboard/WcModalStep2.vue'
import ModalMobileAlpha from '@/general-components/ModalMobileAlpha.vue'
import NotificationReminderModal from '@/general-components/notification-reminder-modal/NotificationReminderModal.vue'
import { useWhatIsNewModalStore } from '@/general-components/what-is-new-modal/useWhatIsNewModalStore'
import ModalKYC from '@/general-components/kyc-modal/ModalKYC.vue'
import ModalProposalPreview from '@/revamp/components/modals/proposal-preview-modal/ModalProposalPreview.vue'
import StartLoanModal from '@/revamp/components/actions/StartLoanModal.vue'
import SelectCollectionAssetModal from '@/revamp/components/modals/select-collection-asset/SelectCollectionAssetModal.vue'
import AcceptServiceTermsModal from '@/revamp/components/modals/accept-service-terms-modal/AcceptServiceTermsModal.vue'
import AcceptFungibleProposalModal from '@/revamp/components/modals/accept-fungible-proposal/AcceptFungibleProposalModal.vue'

const whatIsNewModalStore = useWhatIsNewModalStore()
</script>
