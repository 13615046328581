<template>
  <div
    ref="triggerRef"
    class="select-chain-explorer">
    <BaseLink
      v-if="!isMobile"
      target="_blank"
      :is-underlined="false"
      :to="address ? selectedChain.addressDetailsLink(address) : ''"
      :is-darkening-effect="false"
      :class="['select-chain-explorer__trigger', {'select-chain-explorer__trigger--hovered': isHovered}]"
      @touch="handleToggleDropdown">
      <component
        :is="selectedChain.icon"
        width="14"
        height="14"/>
      {{ selectedChain.name }}
      <ExternalIconSvg class="select-chain-explorer__external-icon"/>
      <ArrowDownIconSvg class="select-chain-explorer__trigger-icon"/>
    </BaseLink>
    <div
      v-else
      :class="['select-chain-explorer__trigger', {'select-chain-explorer__trigger--hovered': isHovered}]"
      @touch="handleToggleDropdown">
      <component
        :is="selectedChain.icon"
        width="14"
        height="14"/>
      {{ selectedChain.name }}
      <ExternalIconSvg class="select-chain-explorer__external-icon"/>
      <ArrowDownIconSvg class="select-chain-explorer__trigger-icon"/>
    </div>

    <div
      v-show="isHovered"
      class="select-chain-explorer__spacer"/>
    <div
      v-show="isHovered"
      class="select-chain-explorer__opened">
      <BaseLink
        v-for="(explorer, index) in explorers"
        :key="index"
        target="_blank"
        :is-underlined="false"
        :is-darkening-effect="false"
        :to="address ? explorer.addressDetailsLink(address) : ''"
        :class="['select-chain-explorer__item', {'select-chain-explorer__item--hide': !isMobile && explorer.id === selectedChain.id}]">
        <component
          :is="explorer.icon"
          width="14"
          height="14"/>
        {{ explorer.name }}
        <ExternalIconSvg class="select-chain-explorer__external-icon"/>
      </BaseLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, toRefs, markRaw } from 'vue'
import { onClickOutside, useElementHover } from '@vueuse/core'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { Address } from 'viem'
import type { Component } from 'vue'
import ArrowDownIconSvg from '@/assets/icons/arrow-down-white.svg'
import BaseLink from '@/general-components/BaseLink.vue'
import ExternalIconSvg from '@/assets/icons/external-text-color.svg'
import type { SupportedChain } from '@/constants/chains/types'
import { isMobile } from '@/utils/mediaQueries'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import starknet from '@/constants/chains/starknet'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

const isOpen = ref(false)
const triggerRef = ref(null)

const isHovered = useElementHover(triggerRef)

const account = useCustomAccount()
const connectedChainId = computed(() => account.chainId.value as SupportedChain)
const handleToggleDropdown = () => {
  isOpen.value = true
}

type Props = {
  address: Address | null
}
const props = defineProps<Props>()
const { address } = toRefs(props)

const explorers = computed(() => {
  const chains: { name: string; icon: Component, id: number, addressDetailsLink: (address: Address) => string }[] = []
  Object.values(CHAINS_CONSTANTS).forEach((chain) => {
    if (chains.some((c) => c?.name === chain.explorer.blockExplorerName)) {
      return
    }
    chains.push({
      name: chain.explorer.blockExplorerName,
      addressDetailsLink: chain.explorer.addressDetailsLink,
      icon: markRaw(chain.explorer.blockExplorerIcon),
      id: chain.general.chainId,
    })
  })
  if (isStarknet) {
    return chains.filter((chain) => chain.id === starknet.general.chainId)
  }
  return chains.filter((chain) => chain.id !== starknet.general.chainId)
})

const selectedChain = ref(explorers.value?.find((explorer) => explorer.id === connectedChainId.value) || explorers.value?.[0])

onClickOutside(triggerRef, () => {
  isOpen.value = false
})

</script>

<style scoped lang="postcss">
.select-chain-explorer {
  font-family: var(--font-family-supreme);
  position: relative;
  font-size: 0.875rem;
  width: fit-content;

  &__spacer {
    position: absolute;
    left: 0;
    height: 1rem;
    width: 10rem;
  }

  &__trigger-icon {
    padding-left: 0.5rem;
    width: 22px;
    height: 14px;
    border-left: 1px solid gray;
    margin-left: auto;
  }

  &__trigger,
  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    width: 10rem;
    gap: 0.5rem;
    line-height: 20px;
  }

  &__trigger {
    border: 1px solid var(--gray-2);
    width: 10.1rem;
    height: 1.75rem;

    &--hovered {
      border-color: var(--primary-color-1);

      .select-chain-explorer__trigger-icon {
        fill: var(--primary-color-1);
      }
    }
  }

  &__item {
    &:hover {
      background: var(--gray-3);
    }
  }

  &__item--hide {
    display: none;
  }

  &__opened {
    position: absolute;
    margin-top: 0.5rem;
    z-index: 300;
    background: var(--background-color);
    border: 1px solid var(--gray);
    box-shadow: 0 5px 16px 0 var(--background-color);
  }

  &__external-icon {
    width: 8px;
    height: 8px;
    opacity: 0.7;
  }
}
</style>
