<template>
  <BaseSkeletor v-if="isFetchingNotificationSettings"/>
  <NotificationCenterSettingsSection title="Your Proposals and Loans">
    <template #options>
      <NotificationCenterSettingsOption
        text="Loan default warning"
        tooltip-text="The amount of days you will get an alert before &#10; your final repayment date on a loan"
        :is-recommended="true"
        :toggle-value="userNotificationSettings?.loanDefaultWarning"
        @toggle="toggleLoanDefaultWarning">
        <template #modal>
          <LoanDefaultWarningModal/>
        </template>
      </NotificationCenterSettingsOption>
    </template>
  </NotificationCenterSettingsSection>
</template>

<script setup lang="ts">
import NotificationCenterSettingsOption from '@/modules/pages/notification-center/settings/NotificationCenterSettingsOption.vue'
import NotificationCenterSettingsSection from '@/modules/pages/notification-center/settings/NotificationCenterSettingsSection.vue'
import LoanDefaultWarningModal from '@/modules/pages/notification-center/settings/modals/LoanDefaultWarningModal.vue'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'

const { userNotificationSettings, isFetchingNotificationSettings, toggleLoanDefaultWarning } = useNotificationCenterSettings()

</script>
