<template>
  <div :class="['empty-table-cta', {'empty-table-cta--smaller-high': props.linkToOtherPage, 'empty-table-cta--bigger-high': !props.linkToOtherPage}]">
    <div
      v-if="props.heading"
      class="empty-table-cta__heading">
      {{ props.heading }}
    </div>
    <BaseButton
      v-if="props.linkToOtherPage"
      :variant="ButtonVariant.Outline"
      :color="ButtonColor.White"
      :size="ButtonSize.XXL"
      :button-text="props.buttonText"
      :to="{name: props.linkToOtherPage, ...props.linkParams}"/>
    <ButtonScrollToBottom
      v-else
      :font="ButtonFont.Screener"
      :button-size="ButtonSize.XXL"
      :button-text="buttonText"
      @click="handleClick"
      @on-scroll="handleScroll"/>
  </div>
</template>

<script lang="ts" setup>
import BaseButton, { ButtonColor, ButtonFont, ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import ButtonScrollToBottom from '@/general-components/ButtonScrollToBottom.vue'

interface Props {
  heading?: string
  buttonText?: string
  linkToOtherPage?: string
  linkParams?: object
}

const props = withDefaults(defineProps<Props>(), {
  heading: 'No offers on this asset, be the first!',
  buttonText: 'Make Loan Offer',
})

const emit = defineEmits<{(e: 'on-scroll', isScrolling: boolean): void, (e: 'click'): void }>()
const handleScroll = (isScrolling: boolean) => {
  emit('on-scroll', isScrolling)
}

const handleClick = () => {
  emit('click')
}

</script>

<style scoped>
.empty-table-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  width: 100%;

  &--bigger-high {
    height: 15rem;
  }

  &--smaller-high {
    height: 220px;
    padding-top: 2rem;
  }

  &__heading {
    font-family: var(--font-family-supreme);
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  @media only screen and (--small-viewport) {
    &__heading {
      line-height: 100%;
      text-align: center;
    }
  }
}
</style>
