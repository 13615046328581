<template>
  <div
    class="asset-card-bundle">
    <div
      class="asset-card-bundle__image">
      <TokenMedia
        :token="asset"
        class="asset-card-bundle__collateral-image"
        :width="140"
        :height="140"/>
    </div>
    <div class="asset-card-bundle__body">
      <div :class="['asset-card-bundle__primary-info', { 'asset-card-bundle__primary-info--missing': asset.isPrimaryInfoMissing }]">
        <SafeText
          :text="asset.primaryInfoWithoutAmount"
          :allow-click-through="false"/>
      </div>
      <div :class="['asset-card-bundle__secondary-info', { 'asset-card-bundle__secondary-info--missing': asset.isSecondaryInfoMissing}]">
        <SafeText
          :text="asset.secondaryInfo"
          :allow-click-through="false"/>
      </div>
    </div>
    <div
      class="asset-card-bundle__amount-info">
      <SafeText
        v-if="asset?.isTokenBundle"
        :text="`Amount of assets: ${asset.bundleAssets.length}`"
        :tooltip-text="`${asset.bundleAssets.length}`"
        :allow-click-through="false"/>
      <SafeText
        v-else
        :text="`Amount: ${asset[amountPropName]}`"
        :tooltip-text="asset[amountPropName]"
        :allow-click-through="false"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TokenMedia from '@/general-components/TokenMedia.vue'
import SafeText from '@/general-components/SafeText.vue'
import { toRefs } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

const props = defineProps<{
  asset: AssetWithAmount,
  amountPropName: 'amountFormatted' | 'amountInputFormatted'
}>()
const { asset, amountPropName } = toRefs(props)
</script>

<style scoped>
.asset-card-bundle {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--gray-3);
  background-image: var(--border-teal-dashed);
  width: 212px;
  height: 292px;

  padding: 0 1rem 2rem;

  &__image {
    margin: 2rem 0 1rem;
    display: flex;
    justify-content: center;
  }

  &__collateral-image {
    object-fit: contain;
  }

  &__primary-info {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  &__primary-info--missing,
  &__secondary-info--missing {
    color: var(--negative-1);
  }

  &__amount-info,
  &__secondary-info {
    font-size: 0.875rem;
    line-height: 1.15rem;
  }
}
</style>
