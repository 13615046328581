import { computed, ref } from 'vue'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import { SORT_OPTIONS_LOOKUP, SortOption } from '@/modules/pages/notification-center/NotificationCenterDefinitions'
import useNotifications from '@/modules/common/notifications/useNotifications'
import { filterNotificationsInSearchResults } from '@/utils/search'
import type Notification from '@/modules/common/notifications/Notification'

const selectedSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-notification-center',
  { id: SortOption.Time, direction: SortDirection.Ascending, viewName: 'sort-option-notification-center' },
  Object.keys(SORT_OPTIONS_LOOKUP),
))

const searchTerm = ref('')

const { notifications } = useNotifications()
const sortedNotifications = computed<Notification[]>(() => {
  const filteredNotifications = filterNotificationsInSearchResults(notifications.value, searchTerm.value)

  return SORT_OPTIONS_LOOKUP[selectedSortOption.value.id](filteredNotifications, selectedSortOption.value.direction)
})

export default function useNotificationCenter() {
  return {
    selectedSortOption,
    sortedNotifications,
    searchTerm,
  }
}
