<template>
  <div class="proposal-detail-info">
    <div class="proposal-detail-info__title">
      <span>Proposal Details</span>
    </div>

    <div class="proposal-detail-info__fieldset">
      <div
        v-if="!isProposalPreview && proposer"
        class="proposal-detail-info__row">
        <div class="proposal-detail-info__label">
          <span>{{ proposerValue }}</span>
        </div>
        <div class="proposal-detail-info__value proposal-detail-info__proposer-address">
          <CryptoAddress
            is-link-to-dashboard
            class="loan-description-borrower__ens"
            :address="proposer as Address"
            :chain-id="chainId"/>

          <RepScore
            v-if="proposer"
            :address="proposer as Address"/>
        </div>
      </div>

      <div class="proposal-detail-info__row">
        <div class="proposal-detail-info__label">
          <span>{{ creditAmountLabel }}</span>
        </div>
        <div class="proposal-detail-info__value proposal-detail-info__credit-info">
          <slot name="credit">
            <AssetAmount
              is-appraisal-aligned-right
              is-display-symbol
              :link-is-internal="hasInternalLinks"
              :asset-amount="creditAmount"
              :asset="creditAsset"/>
          </slot>
        </div>
      </div>

      <div class="proposal-detail-info__row">
        <div class="proposal-detail-info__label">
          <span>LTV</span>
        </div>
        <div class="proposal-detail-info__value">
          <LtvColored :ltv="Number(ltv.toFixed(2))"/>
        </div>
      </div>

      <div class="proposal-detail-info__row">
        <div class="proposal-detail-info__label">
          <span>Interest</span>
        </div>
        <div class="proposal-detail-info__value">
          <InterestInfo
            :credit-asset="creditAsset"
            :collateral-asset="collateralAsset"
            :interest="apr ?? 0"
            :duration="duration"
            :type="type"
            :total-repayment="totalRepayment"
            :total-repayment-raw="totalRepaymentRaw"
            :credit-amount="formattedCreditAmount"
            :proposal-id="proposalId"/>
        </div>
      </div>
      <div class="proposal-detail-info__row">
        <div class="proposal-detail-info__label">
          <span>Max Loan Duration</span>
        </div>
        <div class="proposal-detail-info__value">
          <span>{{ duration }} {{ singularOrPlural(duration ?? 0, 'Day') }}</span>
        </div>
      </div>
      <slot name="expiration"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AssetDetail } from '@/revamp/modules/proposals/types'
import { computed } from 'vue'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import { formatUnits } from 'viem'
import type { Address } from 'viem'
import LtvColored from '@/revamp/components/LtvColored.vue'
import AssetAmount from '@/revamp/components/AssetAmount.vue'
import InterestInfo from '@/revamp/components/InterestInfo.vue'
import type { ProposalAndLoanTypes } from '@/modules/common/pwn/utils'
import RepScore from '@/modules/pages/dashboard/RepScore.vue'
import { singularOrPlural } from '@/utils/utils'

interface Props {
  isProposalPreview?: boolean
  creditAmountLabel: string
  creditAmount: string
  creditAsset: AssetDetail
  collateralAsset: AssetDetail
  apr: number | null
  ltv: number
  totalRepaymentRaw?: string
  totalRepayment?: string
  duration?: number
  isOffer: boolean
  proposer?: string
  chainId: number
  type?: ProposalAndLoanTypes | null
  hasInternalLinks?: boolean
  proposalId?: string | number
}
const props = defineProps<Props>()

const duration = computed(() => {
  if (!props?.duration) return
  return props.duration / 60 / 60 / 24
})

const proposerValue = computed(() => props.isOffer ? 'Lender' : 'Borrower')

const formattedCreditAmount = computed(() => {
  return formatUnits(BigInt(props.creditAmount), props.creditAsset.decimals ?? 18)
})

</script>

<style scoped>
.proposal-detail-info {
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;

  width: 100%;

  &__proposer-address {
    display: flex;
    gap: 0.25rem;
  }

  &__title {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }

  &__fieldset {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
  }

  &__row {
    display: flex;
    padding: 0.5rem 0;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      border-bottom: 1px solid #828282;
      padding-bottom: 1rem;
    }
  }

  &__label {
    font-size: 0.875rem;
    font-family: var(--font-family-supreme);
    color: var(--text-color-secondary);
  }

  &__value {
    font-size: 0.875rem;
    font-family: var(--font-family-supreme);
  }

  &__credit-info {
    display: flex;
    justify-content: end;
    gap: 0.5rem;
    flex-direction: column;
  }

  &__appraisal {
    display: flex;
    justify-content: end;
    color: var(--gray);
  }
}
</style>

<style>
.proposal-detail-info {
  .ens-name__text {
    font-family: var(--font-family-supreme);
    text-decoration: underline;
  }
}
</style>
