<template>
  <div
    v-if="!hasNoSearchResults"
    class="pwn-safe-coins-and-nfts-section">
    <PwnSafeAssetTable
      v-model:active-sort-option="selectedSortOption"
      :sorted-assets="props.nfts"
      heading="NFTs "
      is-row-selectable
      :is-asset-selected="handleIsAssetSelected"
      :table-definition="TRANSFER_OR_TOKENIZE_ASSET_TABLE_DEFINITION"
      @on-asset-select="handleSelectAsset"/>
    <PwnSafeAssetTable
      v-model:active-sort-option="selectedSortOption"
      :sorted-assets="props.tokens"
      heading="Tokens "
      :is-asset-selected="handleIsAssetSelected"
      is-row-selectable
      :table-definition="TRANSFER_OR_TOKENIZE_ASSET_TABLE_DEFINITION"
      @on-asset-select="handleSelectAsset"/>
  </div>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useAssetsSelection from '@/modules/common/assets/useAssetsSelection'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import PwnSafeAssetTable from '@/modules/pages/pwn-safe/tables/PwnSafeAssetTable.vue'
import { TRANSFER_OR_TOKENIZE_ASSET_TABLE_DEFINITION } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import usePwnSafeTokenizeCoinsAndNfts from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenizeCoinsAndNfts'

const { selectedAssetsToTokenize } = usePwnSafeTokenize()
const { addOrRemoveAssetSelection, isAssetSelected } = useAssetsSelection()
const { selectedSortOption } = usePwnSafeTokenizeCoinsAndNfts()

type Props = {
  nfts: AssetWithAmount[];
  tokens: AssetWithAmount[];
  hasNoSearchResults: boolean
}

const props = defineProps<Props>()

const handleSelectAsset = (asset: AssetWithAmount) => {
  return addOrRemoveAssetSelection({ asset, selectedAssets: selectedAssetsToTokenize })
}

const handleIsAssetSelected = (asset: AssetWithAmount) => {
  return isAssetSelected({ asset, selectedAssets: selectedAssetsToTokenize })
}
</script>

<style scoped>
.pwn-safe-coins-and-nfts-section {
  width: 100%;
  display: flex;
  gap: calc(2.25rem - 1.5rem);
  margin-bottom: 3rem;
}
</style>
