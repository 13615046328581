<template>
  <div
    v-if="!isHidden"
    class="pwn-safe-box">
    <div>
      <div class="pwn-safe-box__label">
        <SafeIconSvg
          alt="safe icon"
          class="pwn-safe-box__wallet-icon"/>
        <span>{{ pwnSafe.name }}</span>
      </div>

      <div class="pwn-safe-box__address">
        ({{ pwnSafe?.safeAddress }})
      </div>
    </div>

    <div class="pwn-safe-box__assets-in-wallet">
      <AmountOfAssetType
        :is-loading="pwnSafe.isFetchingErc20s"
        label="Tokens"
        :amount="pwnSafe?.amountOfErc20s"/>

      <AmountOfAssetType
        :is-loading="pwnSafe.isFetchingNfts"
        label="NFTs"
        :amount="pwnSafe?.amountOfNftsWithoutAtrTokens"/>

      <AmountOfAssetType
        :is-loading="pwnSafe.isFetchingNfts"
        label="ATR Tokens"
        :amount="pwnSafe?.amountOfAtrTokens"/>

      <AmountOfAssetType
        :is-loading="false"
        label="Network"
        :amount="pwnSafe?.chainId!">
        <ChainInfo :chain-id="pwnSafe.chainId!"/>
      </AmountOfAssetType>
    </div>

    <div class="pwn-safe-box__row-with-buttons">
      <BaseButton
        :color="ButtonColor.Primary"
        :variant="ButtonVariant.Outline"
        is-full-width
        is-smaller-enlarge-effect
        class="pwn-safe-box__open-safe"
        button-text="Go to Safe"
        @click="handleGoToPwnSafeDetail">
        <template #icon>
          <OutInIconSvg
            class="pwn-safe-box__out-in-icon"
            alt="out-in icon"/>
        </template>
      </BaseButton>

      <WcModalStep1/>

      <BaseButton
        :color="ButtonColor.White"
        :variant="ButtonVariant.Outline"
        is-full-width
        class="pwn-safe-box__manage-safe"
        button-text="Manage"
        @click="handleGoToPwnSafeDetailSettings"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import router from '@/router'
import RouteName from '@/router/RouteName'
import { toRefs } from 'vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import AmountOfAssetType from '@/modules/pages/pwn-safe/pwn-safe-dashboard/AmountOfAssetType.vue'
import WcModalStep1 from '@/modules/pages/pwn-safe/pwn-safe-dashboard/WcModalStep1.vue'
import OutInIconSvg from '@/assets/icons/out-in.svg'
import SafeIconSvg from '@/assets/icons/safe.svg'
import ChainInfo from '@/general-components/ChainInfo.vue'

const props = withDefaults(defineProps<{
  pwnSafe: PwnSafe
  isHidden?: boolean
}>(), {
  isHidden: false,
})
const { pwnSafe } = toRefs(props)

const pwnSafeDetailStore = usePwnSafeDetailStore()

const handleGoToPwnSafeDetail = () => {
  pwnSafeDetailStore.setSelectedPwnSafe(pwnSafe.value)
  router.push({
    name: RouteName.PwnSafeDetailAssets,
    params: {
      safeAddress: pwnSafe.value.safeAddress,
      chainId: pwnSafe.value.chainId,
    },
  })
}

const handleGoToPwnSafeDetailSettings = () => {
  pwnSafeDetailStore.setSelectedPwnSafe(pwnSafe.value)
  router.push({ name: RouteName.PwnSafeDetailSettings })
}
</script>

<style scoped>
.pwn-safe-box {
  display: flex;
  flex: 1 1 auto;
  padding: 2rem;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background-image: var(--border-teal-dashed);

  &:last-child {
    flex: 0 1 calc(50% - 0.5rem);
  }

  &__wallet-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  &__label {
    font-size: 1.25rem;
    justify-content: center;
    margin-bottom: 0.25rem;
    display: flex;
    gap: 0.5rem;
    color: var(--primary-color-1);
  }

  &__address {
    color: var(--gray-2);
  }

  &__assets-in-wallet {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 25rem;
  }

  &__asset-type-label {
    color: var(--gray-2);
    margin-bottom: 0.3rem;
  }

  &__asset-type-amount {
    font-size: 1.25rem;
  }

  &__out-in-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }

  &__row-with-buttons {
    display: flex;
    gap: 1rem;
    width: 100%;
  }

  &__open-safe {
    width: 100%;
  }

  @media only screen and (--mobile-viewport) {
    padding: 0.5rem;

    &__address {
      font-size: 0.75rem;
    }

    &__assets-in-wallet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 15rem;
      gap: 1rem;
    }

    &__row-with-buttons {
      width: auto;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
