import { globalConstants } from '@/constants/globals'

const { hostname } = window.location

export const PUBLIC_TESTNET_HOSTNAME = 'testnet.pwn.xyz'

function getIncludeTestnetsInMultichainResults(): boolean {
  // helper function for setting `include_testnets` backend parameter
  // we use `include_testnets: true` on multichain endpoint in following cases:
  // 1) development build (e.g. local dev, staging)
  // 2) production build deployed on testnet.pwn.xyz url
  // 3) override VITE_IS_PUBLIC_TESTNET_OVERRIDE .env variable (can be in both development or production build)

  if (import.meta.env.VITE_IS_PUBLIC_TESTNET_OVERRIDE) {
    // VITE_IS_PUBLIC_TESTNET_OVERRIDE is overriden in .env settings to allow previewing the app
    // in production mode with testnets included (by default testnets are excluded in production mode)
    return true
  }

  if (hostname && hostname.toLowerCase() === PUBLIC_TESTNET_HOSTNAME) {
    return true
  }

  if (globalConstants.environment === 'development') {
    return true
  }

  return false
}
export const includeTestnetsInMultichainResults = getIncludeTestnetsInMultichainResults()
