<template>
  <div class="apr-chart__header">
    Repayment Over Time
  </div>
  <Line
    :chart-options="chartOptions"
    :chart-data="chartDataLocal"
    chart-id="line-chart"
    dataset-id-key="label"
    css-classes="apr-chart"
    :width="680"
    :height="250"/>
</template>

<script setup lang="ts">
import { Line } from 'vue-chartjs'
import { onMounted, onUpdated, ref, computed, toRefs } from 'vue'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  BarController,
  LinearScale,
  LineController,
  PointElement,
  CategoryScale,
  Filler,
} from 'chart.js'
import type { TChartData, TChartOptions } from 'vue-chartjs/dist/types'
import DateUtils from '@/utils/DateUtils'
import { formatDecimalPoint } from '@/utils/utils'

interface Props {
  loanDurationDays: number,
  amountToRepayStart: number,
  amountToRepayEnd: number,
  tokenSymbol: string
}

const props = defineProps<Props>()
const { loanDurationDays, amountToRepayStart, amountToRepayEnd } = toRefs(props)

const chartData = computed(() => {
  const _chartData: {
    labels: string[],
    data: number[],
  } = {
    labels: [],
    data: [],
  }

  const increaseAmountPerDay = (+amountToRepayEnd.value - +amountToRepayStart.value) / +loanDurationDays.value
  if (+loanDurationDays.value === 1) {
    for (let i = 0; i <= 24; ++i) {
      const increaseAmountPerHour = increaseAmountPerDay / 24
      const tokenAmountOfHour = i === 0
        ? +amountToRepayStart.value
        : +amountToRepayStart.value + (increaseAmountPerHour * i)

      if (i !== 24) {
        _chartData.labels.push(`Hour ${i + 1}`)
      } else {
        _chartData.labels.push('')
      }
      _chartData.data.push(tokenAmountOfHour)
    }
    return _chartData
  }

  for (let i = 0; i <= +loanDurationDays.value; ++i) {
    const tokenAmountOfDay = i === 0
      ? +amountToRepayStart.value
      : +amountToRepayStart.value + (increaseAmountPerDay * i)

    if (+loanDurationDays.value !== i) {
      _chartData.labels.push(`Day ${i + 1}`)
    }
    _chartData.data.push(+tokenAmountOfDay)
  }
  return _chartData
})

const gradient = ref<CanvasGradient>()

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  Filler,
  LineElement,
  LineController,
  BarController,
  BarElement,
  LinearScale,
  PointElement,
  CategoryScale,
)

const getComputedStyleVar = (varName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(varName).trim()
}

const getComputedStyleVarWithAlpha = (varName: string, alpha: number) => {
  const rgb = getComputedStyle(document.documentElement).getPropertyValue(varName).trim()

  // Check if the value is in rgb() format and extract the numeric values
  const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
  if (match) {
    const [, r, g, b] = match
    return `rgba(${r}, ${g}, ${b}, ${alpha})` // Convert to rgba() with the given alpha
  }

  return rgb
}

const setupBgGradient = () => {
  const ctx = (document.getElementById('line-chart') as HTMLCanvasElement)?.getContext('2d')

  if (!ctx || gradient.value) return

  gradient.value = ctx.createLinearGradient(0, 0, 0, 290)
  gradient.value?.addColorStop(0, getComputedStyleVarWithAlpha('--primary-color-4', 0.2))
  gradient.value?.addColorStop(1, 'transparent')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const chartDataLocal = computed<TChartData<any, any, any>>(() => ({
  labels: chartData.value?.labels || [],
  color: getComputedStyleVar('--primary-color-1'),
  datasets: [
    {
      type: 'line',
      data: chartData.value?.data || [],
      borderColor: getComputedStyleVar('--primary-color-1'),
      borderWidth: 1,
      pointBackgroundColor: 'rgba(0, 0, 0, 0)', // This sets the point background to transparent
      pointBorderColor: 'rgba(0, 0, 0, 0)', // This sets the point border to transparent
      fill: true,
      backgroundColor: gradient.value,
      pointHoverBackgroundColor: getComputedStyleVar('--background-color'),
      pointHoverBorderColor: getComputedStyleVar('--primary-color-1'),
      pointRadius: 0, // Hide points by default
      pointHoverRadius: 5, // Show points on hover
      yAxisID: 'y',
    },
  ],
}))

onMounted(() => {
  setupBgGradient()
})

onUpdated(() => {
  setupBgGradient()
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const chartOptions: TChartOptions<any> = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0, // Hide points by default
      hoverRadius: 5, // Show points on hover
    },
    line: {
      tension: 0.4, // Makes the line slightly curved
    },
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    title: {
      display: true,
      text: props.tokenSymbol,
      position: 'left',
      align: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      font: {
        size: 14,
        family: '"Supreme-Regular", "monospace"',
        weight: 'normal',
      },
      color: '#A4A4A4',
      rotation: -90,
    },
    legend: { display: false },
    tooltip: {
      backgroundColor: getComputedStyleVarWithAlpha('--background-color', 0.8),
      titleColor: getComputedStyleVar('--text-color'),
      bodyColor: getComputedStyleVar('--text-color'),
      borderColor: getComputedStyleVar('--primary-color-1'),
      borderWidth: 1,
      cornerRadius: 0, // Remove rounding
      displayColors: false,
      caretSize: 0,
      caretPadding: 15,
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
      titleFont: {
        weight: 'normal',
      },
      callbacks: {
        title: function(tooltipItems) {
          const date = new Date()
          date.setDate(date.getDate() + tooltipItems[0].dataIndex)
          return DateUtils.displayShortDate(date)
        },
        label: function(context) {
          const value = context.raw as number
          return `${formatDecimalPoint(value, 8)} ${props.tokenSymbol}`
        },
      },
    },
  },
  layout: {
    padding: {
      right: 0,
      top: 0,
      bottom: 0,
      left: 10,
    },
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        color: getComputedStyleVar('--text-color'),
        padding: 7,
        maxTicksLimit: 6,
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0,
        font: { family: '"Supreme-Regular", "monospace"' },
        z: 10,
      },
      grid: {
        borderColor: getComputedStyleVar('--text-color'),
        color: 'transparent',
        tickColor: getComputedStyleVar('--text-color'),
      },
    },
    x1: {
      display: false,
      offset: false,
      grid: {
        offset: false,
      },
    },
    y: {
      ticks: {
        beginAtZero: true,
        color: getComputedStyleVar('--text-color'),
        padding: 7,
        maxTicksLimit: 7,
        font: { family: '"Supreme-Regular", "monospace"' },
      },
      grid: {
        borderColor: getComputedStyleVar('--text-color'),
        tickColor: getComputedStyleVar('--text-color'),
        color: getComputedStyleVar('--background-color'),
      },
      afterBuildTicks: function(chart) {
        // remove tick with label "0"
        chart.ticks.shift()
      },
      position: 'left',
    },
    y1: {
      display: false,
      min: 1,
      suggestedMax: 30,
      maxTicksLimit: 8.1,
    },

  },
}

</script>

<style scoped>
.apr-chart {
  border: 1px solid var(--text-color);
  position: relative;
  z-index: 1;
  margin: 0 0 1rem;

  &__header {
    color: var(--text-color);
    font-family: var(--font-family-supreme);
    font-size: 1.125rem;
  }
}
</style>
