import { ref } from 'vue'
import type { RowSelectionState } from '@tanstack/vue-table'
const isOpen = ref(false)
const multipleAssetsSelected = ref(false)
const selectedRows = ref<RowSelectionState>({})
const isSecondStep = ref(false)
const resetMultiselect = () => {
  multipleAssetsSelected.value = false
  selectedRows.value = {}
  isSecondStep.value = false
}

const resetRows = () => {
  selectedRows.value = {}
}
export default function useSelectAssetModal() {
  return {
    isOpen,
    multipleAssetsSelected,
    selectedRows,
    isSecondStep,
    resetMultiselect,
    resetRows,
  }
}
