import type { V1SimpleLoanListOffer } from '@/modules/common/pwn/offers/OfferClasses'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import DateUtils from '@/utils/DateUtils'
import BaseV1SimpleLoanOfferContract from '@/modules/common/pwn/contracts/v1/BaseV1SimpleLoanOfferContract'
import type { V1SimpleLoan } from '@/modules/common/pwn/loans/LoanClasses'
import fixSignatureIfInvalid from '@/utils/fixSignatureIfInvalid'
import AssetType from '@/modules/common/assets/AssetType'
// import { useNotificationReminderModalStore } from '@/general-components/notification-reminder-modal/useNotificationReminderModalStore'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { zeroAddress, zeroHash } from 'viem'
import type { TransactionReceipt } from 'viem'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { pwnV1SimpleLoanAbi, pwnV1SimpleLoanListOfferAbi, readPwnV1SimpleLoanListOfferEncodeLoanTermsFactoryData } from '@/contracts/generated'
import type { V1ListOfferStruct } from '@/contracts/structs'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export default class V1SimpleLoanListOfferContract extends BaseV1SimpleLoanOfferContract<V1SimpleLoanListOffer> {
  public async acceptOffer(offer: V1SimpleLoanListOffer, loan: V1SimpleLoan | undefined, step: ToastStep): Promise<TransactionReceipt> {
    const validatedOfferSignature = fixSignatureIfInvalid(offer.offerSignature)
    const collateralAmountRaw = loan?.collateral?.amount ? loan.collateral.amountRaw : offer?.collateral?.amountRaw
    if (!collateralAmountRaw) {
      throw new Error('Collateral amount is required')
    }

    const offerEncodedData = await offer.encodeOfferData(offer.loanAmountRaw, collateralAmountRaw)

    const receipt = await sendTransaction({
      abi: pwnV1SimpleLoanAbi,
      functionName: 'createLOAN',
      args: [
        CHAINS_CONSTANTS[offer.chainId].pwnV1Contracts.pwnSimpleLoanListOffer,
        offerEncodedData,
        validatedOfferSignature,
        '0x',
        '0x',
      ],
      address: CHAINS_CONSTANTS[offer.chainId].pwnV1Contracts.pwnSimpleLoan,
      chainId: offer.chainId,
    }, { step })

    // if (receipt) {
    //   useNotificationReminderModalStore().checkIfShowNotificationReminderModal(loan)
    // }
    return receipt
  }

  public async revokeOffer({ loan, loanAsset, nonce }: V1SimpleLoanListOffer, step? :ToastStep): Promise<TransactionReceipt> {
    return await sendTransaction({
      abi: pwnV1SimpleLoanListOfferAbi,
      functionName: 'revokeOfferNonce',
      args: [nonce],
      address: CHAINS_CONSTANTS[loanAsset.chainId].pwnV1Contracts.pwnSimpleLoanListOffer,
      chainId: loanAsset.chainId,
    }, { step })
  }

  public createOfferStruct(offer: V1SimpleLoanListOffer, loanAmountRaw: bigint, collateralAmountRaw: bigint): V1ListOfferStruct {
    if (offer?.collateral?.category === undefined || offer?.collateral?.category === null) throw new Error('Collateral category is required')
    if (offer.collateral.category === AssetType.ERC721) {
      collateralAmountRaw = 0n
    }

    return {
      collateralCategory: offer.collateral.category,
      collateralAddress: offer.collateral.address,
      collateralIdsWhitelistMerkleRoot: zeroHash, // change this once we add whitelist ids
      collateralAmount: collateralAmountRaw,
      loanAssetAddress: offer.loanAssetAddress,
      loanAmount: loanAmountRaw,
      loanYield: offer.fixedInterestAmount ?? 0n,
      duration: DateUtils.convertDaysToSeconds(offer.loanDurationDays),
      expiration: offer.expiration,
      borrower: zeroAddress, // always zero for the collection offer to match the signature
      lender: offer.lender,
      isPersistent: false, // change this once we add persistent offer option
      nonce: offer.nonce,
    } as const
  }

  public async encodeOfferData(offer: V1SimpleLoanListOffer, loanAmountRaw: bigint, collateralAmountRaw: bigint): Promise<string> {
    const offerStruct = this.createOfferStruct(offer, loanAmountRaw, collateralAmountRaw)
    if (offer?.collateral?.category === undefined || offer?.collateral?.category === null) throw new Error('Collateral category and tokenId is required')
    const offerValues = {
      collateralId: offer.collateral.category === AssetType.ERC20 ? 0n : offer.collateral.tokenId,
      merkleInclusionProof: [],
    }

    return await readPwnV1SimpleLoanListOfferEncodeLoanTermsFactoryData(pwnWagmiConfig, {
      address: offer.contractAddress,
      chainId: offer.chainId,
      args: [offerStruct, offerValues],
    })
  }
}
