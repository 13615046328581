<template>
  <BaseModal
    v-model:is-open="store.isOpen"
    :size="ModalSize.Large"
    has-blur-backdrop
    :variant="ModalVariant.Default"
    :is-closable="true">
    <template #custom-header>
      <div class="what-is-new-modal__header what-is-new-modal__unselectable">
        <StarEyesIcon
          width="24"
          height="24"
          alt="what is new icon"/>
        <div class="what-is-new-modal__header-text-container">
          <h2 class="what-is-new-modal__header-title">
            What’s New?
          </h2>
          <span class="what-is-new-modal__header-version">
            (PWN v{{ store?.activeSlide?.version }})
          </span>
        </div>

        <img
          class="what-is-new-modal__close"
          src="@/assets/icons/close-gray.svg?url"
          alt="Search icon"
          @click="handleClose">
      </div>
    </template>
    <template #body>
      <div class="what-is-new-modal__carousel what-is-new-modal__unselectable">
        <div
          v-if="!isLastSlideOnTheLeft"
          class="what-is-new-modal__carousel-arrow-wrapper"
          @click="handleChangeSlide('left')">
          <img
            src="@/assets/icons/arrow-right-triangle.svg?url"
            class="what-is-new-modal__carousel-arrow-left"
            width="24"
            alt="carousel-arrow-left">
        </div>
        <div
          v-else
          class="what-is-new-modal__carousel-arrow-wrapper what-is-new-modal__carousel-arrow-wrapper--last-slide"/>
        <img
          v-if="store?.activeSlide?.image && (!store?.activeSlide?.image.endsWith('.mp4') && !store?.activeSlide?.image.endsWith('.webm'))"
          :src="store?.activeSlide?.image"
          class="what-is-new-modal__slide-image"
          alt="what is new image">
        <video
          v-else-if="store?.activeSlide?.image"
          :src="store.activeSlide?.image"
          class="what-is-new-modal__slide-image"
          autoplay
          loop
          muted>
          Your browser does not support the video tag.
        </video>
        <div
          v-if="!isLastSlideOnTheRight"
          class="what-is-new-modal__carousel-arrow-wrapper"
          @click="handleChangeSlide('right')">
          <img
            src="@/assets/icons/arrow-right-triangle.svg?url"
            class="what-is-new-modal__carousel-arrow-right"
            width="24"
            alt="carousel-arrow-right">
        </div>
        <div
          v-else
          class="what-is-new-modal__carousel-arrow-wrapper what-is-new-modal__carousel-arrow-wrapper--last-slide"/>
      </div>

      <div class="what-is-new-modal__slide-indicators what-is-new-modal__unselectable">
        <div
          v-for="(slide, index) in store?.whatIsNewSlides"
          :key="slide.id"
          :class="['what-is-new-modal__slide-indicator-box', { 'what-is-new-modal__slide-indicator-box--active': index === store?.indexOfActiveSlide }]"
          @click="handleClickOnIndicator(index)"/>
      </div>

      <div class="what-is-new-modal__slide-description">
        <div class="what-is-new-modal__slide-label">
          {{ store?.activeSlide?.title }}
        </div>
        <div class="what-is-new-modal__slide-text">
          {{ store?.activeSlide?.description }}
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import ModalVariant from '@/general-components/ModalVariant'
import ModalSize from '@/general-components/ModalSize'
import { useWhatIsNewModalStore } from '@/general-components/what-is-new-modal/useWhatIsNewModalStore'
import StarEyesIcon from '@/assets/icons/star-eyes.svg'
import { computed, onUnmounted } from 'vue'
import { onKeyStroke } from '@vueuse/core'

const store = useWhatIsNewModalStore()

const handleChangeSlide = (direction: 'left' | 'right') => {
  if (store.indexOfActiveSlide === -1) return
  if (store.indexOfActiveSlide === 0 && direction === 'left') return
  // @ts-expect-error FIXME: strict null checks
  if (store.indexOfActiveSlide === store.whatIsNewSlides.length - 1 && direction === 'right') return

  if (direction === 'left') {
  // @ts-expect-error FIXME: strict null checks
    store.activeSlide = store.whatIsNewSlides[store.indexOfActiveSlide - 1]
  } else {
  // @ts-expect-error FIXME: strict null checks
    store.activeSlide = store.whatIsNewSlides[store.indexOfActiveSlide + 1]
  }
}

const handleClickOnIndicator = (index: number) => {
  // @ts-expect-error FIXME: strict null checks
  store.activeSlide = store.whatIsNewSlides[index]
}

const isLastSlideOnTheLeft = computed(() => store.indexOfActiveSlide === 0)
// @ts-expect-error FIXME: strict null checks
const isLastSlideOnTheRight = computed(() => store.indexOfActiveSlide === store.whatIsNewSlides.length - 1)

const handleClose = () => {
  store.isOpen = false
}

const unwatchArrowLeftListener = onKeyStroke('ArrowLeft', (e) => {
  e.preventDefault()
  handleChangeSlide('left')
})
const unwatchArrowRightListener = onKeyStroke('ArrowRight', (e) => {
  e.preventDefault()
  handleChangeSlide('right')
})

onUnmounted(() => {
  unwatchArrowLeftListener()
  unwatchArrowRightListener()
})
</script>

<style scoped>
.what-is-new-modal {
  backdrop-filter: blur(2px);

  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;

    &-text-container {
      display: flex;
      align-items: baseline;
      align-self: end;
      gap: 1rem;
    }

    &-title {
      color: var(--text-color);
      font-family: var(--font-family-screener);
      font-size: 1.25rem;
      height: 1rem;
    }

    &-version {
      color: var(--separation-grey);
      font-family: var(--font-family-screener);
      font-size: 0.75rem;
    }
  }

  &__unselectable {
    -webkit-touch-callout: none;
    user-select: none;
  }

  &__carousel-arrow-wrapper {
    display: flex;
    align-items: center;
    height: 21rem;
    cursor: pointer;
    width: 5rem;
    justify-content: center;

    &--last-slide {
      cursor: default;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__carousel-arrow-left {
    transform: rotate(180deg);
  }

  &__carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 1.5rem 1.5rem 1rem;
  }

  &__slide-description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    padding-top: 1rem;
  }

  &__slide-text {
    color: var(--text-color);
    font-size: 0.875rem;
    min-height: 3rem;
    line-height: 1.5rem;
  }

  &__slide-image {
    width: 100%;
    object-fit: contain;
    height: 21rem;
    max-width: 33rem;
  }

  &__slide-label {
    font-size: 1rem;
    color: var(--primary-color-1);
    text-decoration-line: underline;
    font-family: var(--font-family-screener);
  }

  &__slide-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
  }

  &__slide-indicator-box {
    width: 1.5rem;
    height: 0.25rem;
    background-color: var(--separation-grey-brighter);
    cursor: pointer;

    &--active {
      background-color: var(--primary-color-1);
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 2rem;
  }
}
</style>
