<template>
  <div class="asset-amount__wrapper">
    <div class="asset-amount">
      <component
        :is="componentToRender"
        class="asset-amount__link"
        :target="linkIsInternal ? 'self' :'_blank'"
        :to="{
          name: RouteName.TokenPage,
          params: {
            chainName: CHAINS_CONSTANTS[props.asset.chainId].general.chainName,
            contractAddress: props.asset.address,
          }
        }">
        <div class="asset-amount__image-wrap">
          <TokenMedia
            v-if="assetAdapter && !hideIcon"
            :token="assetAdapter"
            class="asset-amount__image"
            height="32"
            width="32"/>
        </div>

        <div class="asset-amount__details">
          <div class="asset-amount__info">
            <span class="asset-amount__name">
              <SafeText
                v-if="props.assetAmount"
                class="asset-amount__name-wrap"
                :text="assetName"
                :tooltip-text="assetName">
                <template #text>
                  <SafeDisplayDecimals :value="formatDecimalPoint(assetAmountFormatted)"/>
                </template>
              </SafeText>
              <VerifiedIcon
                v-if="props.asset.isVerified && !hideVerifiedIcon"
                class="proposal-collateral__verified-icon"/>
            </span>
          </div>
        </div>
      </component>

      <span :class="['asset-amount__appraisal', {'asset-amount__appraisal--right' : isAppraisalAlignedRight}]">

        <AssetAppraisal
          :asset-amount="assetAmountFormatted"
          :asset-metadata="props.asset"/>
      </span>
    </div>
    <DangerAssetIcon v-if="isFake"/>
  </div>
</template>

<script setup lang="ts">
import { formatAmountWithDecimals, formatDecimalPoint } from '@/utils/utils'
import SafeText from '@/general-components/SafeText.vue'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
import { computed, toRefs } from 'vue'
import AssetType from '@/modules/common/assets/AssetType'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import type { Address } from 'viem'
import TokenMedia from '@/general-components/TokenMedia.vue'
import RouteName from '@/router/RouteName'
import BaseLink from '@/general-components/BaseLink.vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import VerifiedIcon from '@/general-components/icons/VerifiedIcon.vue'
import { asyncComputed } from '@vueuse/core'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import DangerAssetIcon from '@/general-components/DangerAssetIcon.vue'
import SafeDisplayDecimals from '@/general-components/safe-display-decimals/SafeDisplayDecimals.vue'
import { parseAddress } from '@/utils/address-utils'

type Props = {
  asset: {
    address: string,
    chainId: number,
    category?: number | null,
    decimals?: number | null,
    thumbnailUrl?: string | null,
    symbol?: string | null,
    name?: string | null
    isVerified?: boolean | null
  }
  assetAmount: string
  assetAmountBigInt?: bigint
  isDisplaySymbol?: boolean
  isAppraisalAlignedRight?: boolean
  isCheckForFungibleProposalCreditAmount?: boolean
  sourceOfFunds?: string
  proposer?: string
  loanContract?: string

  withLink?: boolean
  linkIsInternal?: boolean
  hideIcon?: boolean
  hideVerifiedIcon?: boolean
  isFake? : boolean
}

const props = withDefaults(defineProps<Props>(), {
  withLink: true,
})

const { assetAmount } = toRefs(props)

const assetAmountRaw = asyncComputed(async () => {
  if (props.isCheckForFungibleProposalCreditAmount && props.proposer && props.loanContract) {
    return await ProposalFactory.maxAcceptableCreditOfFungibleProposal({
      creditAssetAddress: props.asset?.address as Address,
      chainId: props.asset.chainId,
      proposer: props.proposer as Address,
      loanContract: props.loanContract as Address,
      remainingCreditFungibleProposal: BigInt(assetAmount.value ?? ''),
      returnBigInt: false,
      sourceOfFunds: props.sourceOfFunds as Address,
    })
  } else {
    if (props.asset.category === AssetType.ERC721) {
      return 1
    }
    return assetAmount.value
  }
})

const assetAmountFormatted = computed(() => {
  const fallbackDecimals = props.asset?.category === AssetType.ERC20 ? 18 : 0
  if (assetAmountRaw.value === '0') {
    return '0'
  }
  return formatAmountWithDecimals(BigInt((assetAmountRaw.value && assetAmountRaw.value !== '0') ? assetAmountRaw.value : 1), props.asset.decimals ?? fallbackDecimals)
})

const assetName = computed(() => {
  if (props.isDisplaySymbol) {
    return `${formatDecimalPoint(assetAmountFormatted.value)} ${props.asset?.symbol ?? 'Missing symbol'}`
  }
  return assetAmountFormatted.value + '  ' + (props.asset?.name ?? 'default name')
})

const assetAdapter = computed(() => {
  if (!props.asset) return null

  return new AssetMetadata(
    // @ts-expect-error not good stuff
    {
      ...props.asset,
      image: props.asset.thumbnailUrl || undefined,
      decimals: props.asset.decimals ?? 18,
      id: parseInt(props.asset.address + props.asset.chainId),
      address: parseAddress(props.asset.address),
      category: props.asset.category!,
    },
  )
})

const componentToRender = computed(() => {
  if (props.withLink) {
    return BaseLink
  }

  return 'div'
})

</script>

<style scoped>
.asset-amount {
  display: flex;
  flex-flow: column;

  &__wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__image-wrap {
    position: relative;
    display: flex;
  }

  &__image {
    width: 1rem;
    height: 1rem;

    margin-right: 0.5rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 0.875rem;
    display: inline-flex;

    align-items: center;
  }

  &__name-wrap {
    max-width: clamp(100px, 10vw, 100%);

    margin-right: 0.125rem;
  }

  &__appraisal {
    font-size: 0.75rem;
    color: var(--gray-2);
    display: inline-flex;

    margin-top: 0.25rem;
    margin-right: auto;

    &--right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
</style>
