import { computed, ref, watch } from 'vue'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import PwnSafeTransferModalInputStep from '@/modules/pages/pwn-safe/transfer/modal/PwnSafeTransferModalInputStep.vue'
import PwnSafeTransferModalTransferringStep from '@/modules/pages/pwn-safe/transfer/modal/PwnSafeTransferModalTransferringStep.vue'
import PwnSafeTransferModalConfirmationStep from '@/modules/pages/pwn-safe/transfer/modal/PwnSafeTransferModalConfirmationStep.vue'

const isOpen = ref(false)

export enum TransferModalStep {
  Input,
  Transferring,
  Confirmation,
}
const activeStep = ref(TransferModalStep.Input)
const activeStepComponent = computed(() => {
  switch (activeStep.value) {
  case TransferModalStep.Input:
    return PwnSafeTransferModalInputStep
  case TransferModalStep.Transferring:
    return PwnSafeTransferModalTransferringStep
  case TransferModalStep.Confirmation:
    return PwnSafeTransferModalConfirmationStep
  default:
    // todo any log here?
    throw new Error(`Unknown active step ${activeStep.value} in transfer modal.`)
  }
})

const isWaitingOnTxConfirmation = ref(false)

export default function usePwnSafeTransferModal() {
  const { assetsToTransfer, fromWallet, toWallet, addressTo } = usePwnSafeTransfer()

  const resetModalState = () => {
    activeStep.value = TransferModalStep.Input
    assetsToTransfer.value = []
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'PwnSafe | u... Remove this comment to see the full error message
    fromWallet.value = null
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'PwnSafe | u... Remove this comment to see the full error message
    toWallet.value = null
    addressTo.value = ''
  }

  watch(isOpen, (newIsOpen, oldIsOpen) => {
    if (!newIsOpen) {
      resetModalState()
    }
  })

  const transferringAssetsInfo = computed<string>(() => {
    return assetsToTransfer.value.map(asset => asset.primaryInfoWithAmountInput).join(', ')
  })

  const closeModal = () => {
    isOpen.value = false
  }

  return {
    isOpen,
    closeModal,
    activeStep,
    activeStepComponent,
    transferringAssetsInfo,
    isWaitingOnTxConfirmation,
    resetModalState,
  }
}
