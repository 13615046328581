import { formatDecimalPoint } from '@/utils/utils'

export const getCollateralOrCreditOrLtv = ({
  whatToReturn,
  collateralAmount,
  collateralUnitPrice,
  creditAmount,
  creditUnitPrice,
  ltv,
}: {
  whatToReturn: 'collateralAmount' | 'creditAmount' | 'ltv';
  collateralAmount: number | string;
  collateralUnitPrice: number | string;
  creditAmount: number | string;
  creditUnitPrice: number | string;
  ltv: number | string;
}) => {
  const collateralUnitPriceFormatted = String(collateralUnitPrice).replace(',', '')
  const collateralAmountFormatted = String(collateralAmount).replace(',', '')
  const collateralAppraisal = (+collateralUnitPriceFormatted ?? 0) * (+collateralAmountFormatted ?? 0)

  const creditUnitPriceFormatted = String(creditUnitPrice).replace(',', '')
  const creditAmountFormatted = String(creditAmount).replace(',', '')
  const creditAppraisal = (+creditUnitPriceFormatted ?? 0) * (+creditAmountFormatted ?? 0)
  const _ltv = ltv ?? 0

  if (whatToReturn === 'ltv') {
    return creditAppraisal / collateralAppraisal * 100
  }

  if (whatToReturn === 'collateralAmount') {
    const newCollateralAppraisal = creditAppraisal / (+_ltv / 100)
    return newCollateralAppraisal / +collateralUnitPriceFormatted
  }

  if (whatToReturn === 'creditAmount') {
    const newCreditAppraisal = collateralAppraisal * (+_ltv / 100)
    return newCreditAppraisal / +creditUnitPriceFormatted
  }
}

type BasePropsForCalculationType = {
  collateralAmount: number | string;
  collateralUnitPrice: number | string;
  creditAmount: number | string;
  creditUnitPrice: number | string;
  ltv: number | string;
}

const updateCreditAmount = (basePropsForCalculation: BasePropsForCalculationType, form) => {
  const newCreditAmount = getCollateralOrCreditOrLtv({
    whatToReturn: 'creditAmount',
    ...basePropsForCalculation,
  })
  if (newCreditAmount && newCreditAmount !== Infinity && !isNaN(newCreditAmount)) {
    const creditToSet = Number.isInteger(newCreditAmount) ? newCreditAmount : formatDecimalPoint(newCreditAmount, 6)
    form.setFieldValue('creditAmount', String(creditToSet).replace(',', ''))
  }
}

const updateCollateralAmount = (basePropsForCalculation: BasePropsForCalculationType, form) => {
  const newCollateralAmount = getCollateralOrCreditOrLtv({
    whatToReturn: 'collateralAmount',
    ...basePropsForCalculation,
  })
  if (newCollateralAmount && newCollateralAmount !== Infinity && !isNaN(newCollateralAmount)) {
    const collateralToSet = Number.isInteger(newCollateralAmount) ? newCollateralAmount : formatDecimalPoint(newCollateralAmount, 6)
    form.setFieldValue('collateralAmount', String(collateralToSet).replace(',', ''))
  }
}

const updateLtv = (basePropsForCalculation: BasePropsForCalculationType, form) => {
  const newLtv = getCollateralOrCreditOrLtv({
    whatToReturn: 'ltv',
    ...basePropsForCalculation,
  })
  if (newLtv) {
    if (+newLtv > 0.01) {
      const ltvToSet = Number.isInteger(newLtv) ? newLtv : newLtv?.toFixed(2)
      form.setFieldValue('ltv', String(ltvToSet))
    }
    form.setFieldValue('ltv', String(newLtv))
  }
}

// run this only if ltv input is focused or changed by button
// owned asset has priority to keep the previous value, except if is not yet set
// isActiveTabBorrow = collateral is owned asset
// !isActiveTabBorrow = credit is owned asset
export const recalculateOnLtvChange = ({
  ltv,
  form,
  isActiveTabBorrow,
  collateralUnitPrice,
  creditUnitPrice,
}: {
  ltv: number;
  form;
  isActiveTabBorrow?: boolean;
  collateralUnitPrice: number | string;
  creditUnitPrice: number | string;
}) => {
  if (form.state.isSubmitting || !ltv) return

  const existingCollateralAmount = form.getFieldValue('collateralAmount')
  const existingCreditAmount = form.getFieldValue('creditAmount')
  const collateralAsset = form.getFieldValue('collateralAsset')

  const basePropsForCalculation: BasePropsForCalculationType = {
    collateralAmount: existingCollateralAmount,
    collateralUnitPrice,
    creditAmount: existingCreditAmount,
    creditUnitPrice,
    ltv,
  }

  if (
    (isActiveTabBorrow && existingCollateralAmount) ||
    (!isActiveTabBorrow && !existingCreditAmount) ||
    !collateralAsset?.isErc20
  ) {
    updateCreditAmount(basePropsForCalculation, form)
    return false
  }

  if (
    (collateralAsset?.isErc20 && ( // TODO how with ERC1155?
      (isActiveTabBorrow && !existingCollateralAmount) ||
      (!isActiveTabBorrow && existingCreditAmount)
    ))
  ) {
    updateCollateralAmount(basePropsForCalculation, form)
  }
  return false
}

export const recalculateOnCollateralOrCreditChange = ({
  focusedInput,
  form,
  isActiveTabBorrow,
  collateralUnitPrice,
  creditUnitPrice,
}: {
  focusedInput: 'collateral' | 'credit';
  form;
  isActiveTabBorrow?: boolean;
  collateralUnitPrice: number | string;
  creditUnitPrice: number | string;
}) => {
  const existingCollateralAmount = form.getFieldValue('collateralAmount')
  const existingCreditAmount = form.getFieldValue('creditAmount')
  const collateralAsset = form.getFieldValue('collateralAsset')
  const ltv = form.getFieldValue('ltv')

  const basePropsForCalculation = {
    collateralAmount: existingCollateralAmount,
    collateralUnitPrice,
    creditAmount: existingCreditAmount,
    creditUnitPrice,
    ltv,
  }

  if (isActiveTabBorrow && ltv && focusedInput === 'collateral') {
    updateCreditAmount(basePropsForCalculation, form)
    return
  }

  if (!isActiveTabBorrow && ltv && focusedInput === 'credit' && collateralAsset?.isErc20) {
    updateCollateralAmount(basePropsForCalculation, form)
    return
  }

  updateLtv(basePropsForCalculation, form)
}
