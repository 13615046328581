<template>
  <ProposalsTable
    :type="proposalsTypeToShow"
    :user-address="dashboardUserAddress"

    :include-proposals="onlyProposals"
    :include-loans="includeLoans"

    :show-history="showHistory"

    ignore-chains

    :q="searchTerm"
    :include-collateral-without-price="true"
    :include-credit-without-price="true"
    :include-unverified-collateral="true"
    :include-unverified-credit="true"
    is-hide-hand-for-own-proposal

    max-height="344px"/>
</template>

<script setup lang="ts">
import ProposalsTable from '@/revamp/components/tables/ProposalsTable.vue'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { computed } from 'vue'

type Props = {
  type: 'borrow' | 'lend'

  includeLoans?: boolean
  onlyProposals?: boolean

  showHistory?: boolean
}

const props = defineProps<Props>()

const { dashboardUserAddress, searchTerm } = useDashboard()

/**
 * @author Skas
 * if this function looks weird to you, it's because it is.
 * if type is borrow we show borrow requests not in the context of `I want to lend/borrow`
 */
const proposalsTypeToShow = computed(() => {
  return props.type === 'borrow' ? 'lend' : 'borrow'
})

</script>
