import type { App } from 'vue'
import useSentry from '@/modules/common/useSentry'
import vFocus from '@/directives/vFocus'
import vSelectText from '@/directives/vSelectText'
import useAnalytics from '@/modules/common/analytics/useAnalytics'
import HotJarTracker from '@/modules/common/analytics/HotJar'
import { isPwnSpace, isStarknet, loadPwnSpaceConfig, pwnSpaceName } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import to from '@/utils/await-to-js'
import { initEnabledChains } from '@/modules/common/web3/useEnabledChains'
import { initPwnWagmiConfig, setupWagmiAccountWatcher } from '@/modules/common/web3/usePwnWagmiConfig'
import initWeb3Modal from '@/modules/common/web3/useWeb3Modal'
import { setupStarknetAccountWatcher } from './starknet/usePwnStarknetConfig'

export default function useAppInit() {
  const initBeforeAppCreated = async () => {
    if (isPwnSpace) {
      const [error, pwnSpaceConfig] = await to(loadPwnSpaceConfig())
      if (error || !pwnSpaceConfig) {
        // eslint-disable-next-line no-console
        console.error(`Could not get pwn space config from backend for pwn space=${pwnSpaceName}.`)
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    initEnabledChains()
    if (!isStarknet) {
      initPwnWagmiConfig()
      initWeb3Modal()
    }
  }

  const initBeforeAppMount = async (app: App<Element>) => {
    app.directive('focus', vFocus)
    app.directive('select-text', vSelectText)

    if (!isStarknet) {
      setupWagmiAccountWatcher()
    } else {
      setupStarknetAccountWatcher()
    }
    useSentry().initSentry(app)
    useAnalytics().initAnalytics(new HotJarTracker())

    return app
  }

  return {
    initBeforeAppMount,
    initBeforeAppCreated,
  }
}
