<template>
  <div class="proposal-collateral">
    <BaseTooltip
      v-if="compareAddresses(proposer, userAddress) && !isHideHandForOwnProposal"
      has-tooltip
      append-to-body
      :max-width="400"
      :border-color="TooltipBorderColor.White">
      <template #trigger>
        <OwnLoanIconSvg
          class="proposal-collateral__own-proposal"
          width="25"
          height="12"
          alt="own loan"/>
      </template>
      This is your {{ isLoan ? 'loan' : 'proposal' }}
    </BaseTooltip>
    <div class="proposal-collateral__image-wrap">
      <div class="proposal-collateral__image">
        <TokenMedia
          v-if="assetAdapter"
          :token="assetAdapter"
          height="32"
          width="32"/>
      </div>

      <AssetChainIcon
        :chain-id="chainId"
        class="proposal-collateral__chain-icon"/>
    </div>

    <div class="proposal-collateral__details">
      <div class="proposal-collateral__info">
        <SafeText
          :text="collateralAmountFormatted + ' ' + name"
          :tooltip-text="collateralAmountFormatted + ' ' + name"
          class="proposal-collateral__name">
          <template #text>
            <SafeDisplayDecimals
              v-if="category === AssetType.ERC20"
              :value="`${formatDecimalPoint(collateralAmountParsed)} ${name}`"/>
            <span v-else>{{ name }}</span>
          </template>
        </SafeText>
        <VerifiedIcon
          v-if="isVerified"
          class="proposal-collateral__verified-icon"/>
      </div>

      <span class="proposal-collateral__appraisal">
        <AssetAppraisal
          :asset-amount="collateralAmountParsed"
          :asset-metadata="collateralMetadata"/>
      </span>
    </div>
    <BaseTooltip
      v-if="type === V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL"
      class="proposal-collateral__simple-proposal-icon"
      has-tooltip
      append-to-body
      :max-width="470"
      :border-color="TooltipBorderColor.White">
      <template #trigger>
        <SimpleProposalSvg
          width="28"
          height="28"
          alt="simple proposal"/>
      </template>
      <div class="proposal-collateral__simple-proposal-tooltip">
        This proposal requires the borrower to take the full offered amount. Partial borrowing isn't allowed by the lender.
      </div>
    </BaseTooltip>
    <DangerAssetIcon v-if="isFake"/>
  </div>
</template>

<script setup lang="ts">
import TokenMedia from '@/general-components/TokenMedia.vue'
import VerifiedIcon from '@/general-components/icons/VerifiedIcon.vue'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'
import SafeText from '@/general-components/SafeText.vue'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
import type { Proposal } from '@/revamp/modules/proposals/types'
import { computed } from 'vue'
import { formatUnits } from 'viem'
import type { Address, Hex } from 'viem'
import OwnLoanIconSvg from '@/assets/icons/own-loan.svg'
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import { compareAddresses, formatDecimalPoint } from '@/utils/utils'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import AssetType from '@/modules/common/assets/AssetType'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { calculateCollateralAmountFungibleProposal } from '@/modules/common/pwn/utils'
import type { ProposalAndLoanTypes } from '@/modules/common/pwn/utils'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import { asyncComputed } from '@vueuse/core'
import DangerAssetIcon from '@/general-components/DangerAssetIcon.vue'
import { parseAddress } from '@/utils/address-utils'
import SimpleProposalSvg from '@/assets/icons/simple-proposal.svg'
import SafeDisplayDecimals from '@/general-components/safe-display-decimals/SafeDisplayDecimals.vue'

const { address: userAddress } = useCustomAccount()

type Props = {
  address: string;
  thumbnail: string;
  name: string
  isVerified?: boolean
  chainId: number
  proposer: Hex
  category: AssetType
  type?: ProposalAndLoanTypes
  proposal: Proposal
  collateralMetadata: Proposal['collateral']
  isLoan?: boolean
  isOffer?: boolean
  isFake?: boolean
  isHideHandForOwnProposal?: boolean
};

const props = defineProps<Props>()

const collateralAmount = asyncComputed(async() => {
  if (
    props.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL &&
    props.proposal?.creditData?.maxAmount &&
    props.proposal?.creditData?.creditPerCollateralUnit) {
    const maxAcceptableCredit = await ProposalFactory.maxAcceptableCreditOfFungibleProposal({
      creditAssetAddress: props.proposal.creditAsset?.address as Address,
      chainId: props.chainId,
      proposer: props.proposal.proposer as Address,
      loanContract: props.proposal.loanContract as Address,
      remainingCreditFungibleProposal: BigInt(props.proposal.creditData.amount ?? ''),
      returnBigInt: false,
      sourceOfFunds: props.proposal.sourceOfFunds as Address,
    })
    return calculateCollateralAmountFungibleProposal({
      creditPerCollateralUnit: props.proposal.creditData.creditPerCollateralUnit,
      collateralDecimals: props.collateralMetadata.decimals ?? 0,
      availableCreditLimit: props.proposal.isOffer ? maxAcceptableCredit as string : props.proposal.creditData.amount as string,
      returnBigInt: true,
    }).toString()
  } else {
    return props.proposal.collateralAmount
  }
}, props.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL
  ? calculateCollateralAmountFungibleProposal({
    creditPerCollateralUnit: props.proposal.creditData.creditPerCollateralUnit!,
    collateralDecimals: props.collateralMetadata.decimals ?? 0,
    returnBigInt: true,
    availableCreditLimit: props.proposal.creditData.amount!,
  }).toString()
  : props.proposal.collateralAmount)

const collateralAmountParsed = computed(() => {
  if (collateralAmount.value === '1' && props.collateralMetadata?.decimals === null) return ''
  // TODO remove decimals fallback?
  const fallbackErc20Decimals = props.collateralMetadata.decimals ?? 18
  const decimalsFallback = props.category === AssetType.ERC20 ? fallbackErc20Decimals : undefined
  const v = props.collateralMetadata.category === AssetType.ERC20 ? formatUnits(BigInt(collateralAmount.value ?? '0'), decimalsFallback!) : collateralAmount.value || '1'
  if (props.category === AssetType.ERC721) {
    return '1'
  }

  if (Number.isNaN(v) || Number.isNaN(Number(v))) {
    return '1'
  }

  return v
})

const collateralAmountFormatted = computed(() => {
  if (collateralAmountParsed.value === '' || (collateralAmountParsed.value === '1' && props.collateralMetadata.category !== AssetType.ERC20)) return ''
  return parseFloat(collateralAmountParsed.value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
})

const assetAdapter = computed(() => {
  if (!props.collateralMetadata) return null

  return new AssetMetadata(
    // @ts-expect-error not good stuff
    {
      ...props.collateralMetadata,
      image: props.thumbnail,
      decimals: props.collateralMetadata.decimals ?? 18,
      id: parseInt(props.collateralMetadata.id),
      address: parseAddress(props.collateralMetadata.address),
    },
  )
})
/*
const formattedAmount = computed(() => {
  if (!collateralAmountParsed.value) return ''
  return `${formatDecimalPoint(parseFloat(collateralAmountParsed.value))} ${assetAdapter.value?.isErc20 ? assetAdapter.value.symbol : ''}`
})
*/
</script>

<style scoped>
.proposal-collateral {
  display: flex;
  align-items: center;

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  &__image-wrap {
    position: relative;
  }

  &__image {
    width: 2rem;
    height: 2rem;

    margin-right: 1rem;
  }

  &__chain-icon {
    position: absolute;
    bottom: 0;
    right: 0.5rem;

    width: 14px;
    height: 14px;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-text-primary);
    margin-right: 0.125rem;

    max-width: clamp(140px, 10vw, 100%);
  }

  &__verified-icon {
    width: 0.875rem;
    height: 0.875rem;
  }

  &__appraisal {
    font-size: 0.75rem;
    color: var(--gray-2);

    margin-top: 0.25rem;
    display: inline-flex;
  }

  &__own-proposal {
    margin-right: 0.5rem;
  }

  &__simple-proposal-icon {
    margin-left: auto;
    margin-right: 0.375rem;
  }

  &__simple-proposal-tooltip {
    text-align: center;
  }
}
</style>
