<template>
  <div class="asset-info-cell">
    <div class="asset-info-cell__image-wrap">
      <TokenMedia
        :token="asset"
        class="asset-info-cell__logo"
        :style="{
          width: props.iconWidth,
          height: props.iconWidth,
        }"/>

      <AssetChainIcon
        :chain-id="asset.chainId"
        class="asset-info-cell__chain-icon"/>
    </div>

    <div class="asset-info-cell__name-wrap">
      <div
        class="asset-info-cell__name"
        :style="{ maxWidth: props.nameWidth }">
        <SafeText :text="assetName">
          <template #text>
            <span class="asset-info-cell__name">
              {{ assetName }}
            </span>
          </template>
        </SafeText>
      </div>

      <div class="asset-info-cell__symbol">
        <SafeText :text="assetSymbol">
          <template #text>
            <span class="asset-info-cell__symbol">
              {{ assetSymbol }}
            </span>
          </template>
        </SafeText>
      </div>

      <div class="asset-info-cell__icons">
        <VerifiedIcon v-if="asset.isVerified"/>

        <BaseLink
          :href="explorerLink"
          target="_blank"
          class="asset-info-cell__explorer-link"
          @click="handleOpenExplorer">
          <ChainIcon class="asset-info-cell__explorer"/>
        </BaseLink>

        <BaseLink
          :to="asset?.routeToAssetPage?.()"
          target="_blank"
          @click.stop>
          <ExternalIconSvg class="asset-info-cell__link-to-page"/>
        </BaseLink>
      </div>

      <DangerAssetIcon
        v-if="isAssetFakeComputed"
        tooltip-text="This asset is likely fake."/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TokenMedia from '@/general-components/TokenMedia.vue'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'
import { computed } from 'vue'
import type { AssetWithAmount, AssetMetadata } from '@/modules/common/assets/AssetClasses'
import SafeText from '@/general-components/SafeText.vue'
import VerifiedIcon from '@/general-components/icons/VerifiedIcon.vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import BaseLink from '@/general-components/BaseLink.vue'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import DangerAssetIcon from '@/general-components/DangerAssetIcon.vue'
import ExternalIconSvg from '@/assets/icons/external.svg'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

type Props = {
  asset: AssetWithAmount | AssetMetadata
  iconWidth?: string
  nameWidth?: string
}
const props = withDefaults(defineProps<Props>(), {
  iconWidth: '1.5rem',
  nameWidth: '8rem',
})

const assetName = computed(() => {
  return props.asset.isNft ? props.asset.name : props.asset.symbol
})

const assetSymbol = computed(() => {
  return props.asset.isNft
    ? (
      props.asset.symbol === 'Missing symbol' ? '' : `(${props.asset.symbol})`
    )
    : `(${props.asset.name})`
})

const ChainIcon = computed(() => CHAINS_CONSTANTS[props.asset.chainId].explorer.blockExplorerIcon)
const explorerLink = computed(() => {
  return CHAINS_CONSTANTS[props.asset.chainId].explorer.contractDetailsLink(props.asset.address)
})

const handleOpenExplorer = (e: MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
  window.open(explorerLink.value, '_blank')
}

const isAssetFakeComputed = computed(() => {
  if (isStarknet) return false
  return useTopTokensStore().isAssetFake(props.asset)
})

</script>

<style lang="postcss" scoped>
  .asset-info-cell {
    display: flex;
    row-gap: 0.5rem;

    &__image-wrap {
      display: flex;
      position: relative;

      margin-right: 0.5rem;
    }

    &__logo {
      width: 1.5rem;
      height: 1.5rem;
    }

    &__chain-icon {
      position: absolute;
      bottom: -4px;
      right: -4px;
      width: 0.75rem;
      height: 0.75rem;
    }

    &__name-wrap {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.5rem;
      white-space: nowrap;
      align-items: center;
    }

    &__name {
      max-width: 8rem;
    }

    &__symbol {
      color: var(--separation-grey);
      max-width: 8rem;

      @media only screen and (--mobile-viewport) {
        display: none;
      }
    }

    &__icons {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    &__explorer-link {
      width: 1rem;
      height: 1rem;
      display: inline-flex;
    }

    &__explorer {
      width: 1rem;
      height: 1rem;

      path {
        fill: var(--separation-grey);
      }
    }

    &__link-to-page {
      cursor: pointer;
      pointer-events: all;
      opacity: 0.7;

      &:hover {
        opacity: 0.5;
      }
    }
  }
</style>

<style>
.asset-info-cell__explorer path {
  fill: var(--separation-grey);
}
</style>
