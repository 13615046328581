import { computed } from 'vue'
import type { Ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { queries } from '@/modules/queries'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { isAddress } from 'viem'

export const useSafeAssetsDetail = (selectedSafe: Ref<PwnSafe | null>) => {
  const safeAddress = computed(() => selectedSafe.value?.safeAddress)
  const safeChain = computed(() => selectedSafe.value?.chainId)

  // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
  const enabled = computed(() => Boolean(isAddress(safeAddress.value) && safeChain.value))

  const safeTokensQuery = useQuery({
    // @ts-expect-error TS(2345) FIXME: Argument of type 'ComputedRef<`0x${string}` | unde... Remove this comment to see the full error message
    ...queries.safe.detail(safeAddress, safeChain)._ctx.tokens,
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const safeNftsQuery = useQuery({
    // @ts-expect-error TS(2345) FIXME: Argument of type 'ComputedRef<`0x${string}` | unde... Remove this comment to see the full error message
    ...queries.safe.detail(safeAddress, safeChain)._ctx.nfts,
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const nonAtrNfts = computed(() => {
    return safeNftsQuery.data.value?.filter((nft) => !nft.isAtrToken) || []
  })

  const atrNfts = computed(() => {
    return safeNftsQuery.data.value?.filter((nft) => nft.isAtrToken) || []
  })

  const safeTokens = computed(() => {
    return [...safeTokensQuery.data.value || []]
  })

  const nftsAreLoading = computed(() => {
    return Boolean(safeNftsQuery.isPending.value || safeNftsQuery.isRefetching.value)
  })

  const tokensAreLoading = computed(() => {
    return Boolean(safeTokensQuery.isPending.value || safeTokensQuery.isRefetching.value)
  })

  return {
    safeTokens,
    nonAtrNfts,
    atrNfts,
    nftsAreLoading,
    tokensAreLoading,
  }
}
