import { getAddress, isAddress } from 'viem'
import type { Address, Chain } from 'viem'
import type { Component } from 'vue'
import { polygon, cronos, base, optimism, sepolia, mainnet, arbitrum, bsc, unichainSepolia, gnosis } from '@wagmi/core/chains'

export enum SupportedChain {
  Ethereum = 1,
  Optimism = 10,
  Polygon = 137,
  Cronos = 25,
  Base = 8453,
  Arbitrum = 42161,
  Bsc = 56,
  Sepolia = 11155111,
  UnichainSepolia = 1301,
  Gnosis = 100,
  StarknetSepolia = 11155112,
  StarknetMainnet = 112211,
}

const disabledChains = [SupportedChain.StarknetSepolia, SupportedChain.StarknetMainnet]

export const ALL_SUPPORTED_CHAINS = Object.values(SupportedChain).filter(v => typeof v === 'number' && !disabledChains.includes(v)) as SupportedChain[]
export const TESTNET_CHAINS = [SupportedChain.Sepolia, SupportedChain.UnichainSepolia, SupportedChain.StarknetSepolia]

export const ALL_SUPPORTED_CHAINS_WAGMI = [arbitrum, base, bsc, cronos, sepolia, mainnet, optimism, polygon, unichainSepolia, gnosis] satisfies [Chain, ...Chain[]]
export type SupportedChainWagmi = typeof ALL_SUPPORTED_CHAINS_WAGMI

export type ChainWithBetaContracts = SupportedChain.Ethereum | SupportedChain.Polygon
export type ChainWithDeprecatedV1Contracts = SupportedChain.Ethereum | SupportedChain.Polygon

export enum DisabledChain {
}

export class TopTokens {
  usdc?: Address
  weth?: Address
  usdt?: Address
  dai?: Address
  wmatic?: Address
  wcro?: Address
  wbnb?: Address
  pwns?: Address // PWN "stablecoin" erc20 from faucet, should be set only on testnet
  pwnd?: Address // PWN erc20 from faucet, should be set only on testnet
  wxdai?: Address
  'usdc.e'?: Address

  constructor(topTokens: Partial<TopTokens>, isStarknet?: boolean) {
    const tokenNames: (keyof TopTokens)[] = ['usdc', 'usdt', 'weth', 'dai', 'wmatic', 'wcro', 'wbnb', 'pwns', 'pwnd', 'usdc.e', 'wxdai']

    for (const tokenName of tokenNames) {
      const tokenValue = topTokens?.[tokenName]

      // the bullshit with imports prevents us from using parseAddress here
      if (isStarknet) {
        this[tokenName] = tokenValue as Address
      } else if (!tokenValue || !isAddress(tokenValue)) {
        // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type '`0x${string... Remove this comment to see the full error message
        this[tokenName] = null
      } else {
        this[tokenName] = getAddress(tokenValue)
      }
    }
  }
}

// chain name that we display in UI
export type ChainName = 'Ethereum' | 'Polygon' | 'Cronos' | 'Base' | 'Optimism' | 'Arbitrum' | 'BSC' | 'Sepolia' | 'Unichain Sepolia' | 'Gnosis' | 'Starknet Sepolia' | 'Starknet Mainnet'
export type ChainNameLowercase = Lowercase<ChainName>

export interface BaseChainExplorers {
  blockExplorerName: string;
  blockExplorerIcon: Component;
  addressDetailsLink: (address: Address) => string;
  contractDetailsLink: (tokenAddress: Address) => string;
  nftExplorerName: string;
  nftExplorerIcon: Component;
  nftDetailsLink: (tokenAddress: Address, tokenId: string | bigint) => string;
  transactionDetailsLink: (transactionHash: string) => string;
  nftOpenseaCollectionLink?: (slug: string) => string;
}

export interface ChainWithLooksRareExplorer extends BaseChainExplorers {
  looksRareNftDetailsLink: (tokenAddress: Address, tokenId: string | bigint) => string
}

// TODO revise what values can we remove from here, as they are present in viem chain info already
// TODO also add a link to viem chain object from here?
export interface ChainConstants<IExplorers extends BaseChainExplorers = BaseChainExplorers> {
  general: {
    chainId: SupportedChain;
  chainName: ChainName;
  }
  nodeProvider: {
    httpNodeUrl: string
    // TODO remove wsNodeUrl completely?
    wsNodeUrl: string
    bearerAuthToken?: string
  }
  pwnNode?: {
    httpNodeUrl: string
    wsNodeUrl: string
    priority: number
    timeout: number
  }
  explorer: IExplorers
  pwnBetaContracts?: {
    pwn: Address
    pwnLoan: Address
    pwnVault: Address
  },
  deprecatedPwnV1Contracts?: {
    pwnSimpleLoan: Address
    pwnSimpleLoanSimpleOffer: Address
  },
  pwnV1Contracts: {
    pwnSimpleLoan: Address
    pwnSimpleLoanSimpleOffer: Address
    pwnSimpleLoanSimpleRequest: Address
    pwnSimpleLoanListOffer: Address
    pwnLoanToken: Address
  },
  pwnV1_2Contracts: {
    pwnSimpleLoan: Address
    multiTokenCategoryRegistry: Address
    pwnRevokedNonce: Address
    pwnLoan: Address
    pwnSimpleLoanSimpleProposal: Address
    pwnSimpleLoanListProposal: Address
    pwnSimpleLoanDutchAuctionProposal: Address
    pwnSimpleLoanFungibleProposal: Address
    pwnConfig: Address,
  },
  tokenBundlerContract?: Address,
  pwnSafeContracts?: {
    assetTransferRightsGuardProxy?: Address,
    assetTransferRights?: Address,
    pwnSafeFactory?: Address,
  },
  topTokens: TopTokens
  nftContractsAddresses?: {
    cryptoPunks?: Address;
    chickenBonds?: Address;
    aavegotchis?: Address;
    aavegotchiVault?: Address;
    meanFinanceDca?: Address;
    kyberSwapElastic?: Address;
  },
  gnosisSafe: {
    transactionServiceUrl: string;
    chainName: string;
  }
  aave?: {
    uiPoolDataProvider: Address,
    poolAddressesProvider: Address,
    pool: Address,
  }
  compound?: {
    pools: Array<Address>
  }
}

export const isChainWithLooksRareExplorer = (chainConstants: ChainConstants): chainConstants is ChainConstants<ChainWithLooksRareExplorer> => {
  return 'looksRareNftDetailsLink' in chainConstants.explorer
}

export const SHIT_FILTER_EXCLUDED_CHAINS = [
  SupportedChain.Sepolia,
  SupportedChain.Cronos,
  SupportedChain.Base,
]
