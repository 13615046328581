<template>
  <a
    class="page-not-found__back link"
    @click="back()">
    <img
      src="@/assets/icons/icon_arrow_left.svg?url"
      alt="Back">
    <p class="page-not-found__back-label">Back</p>
  </a>

  <div class="page-not-found__invalid-user-image">
    <img
      alt="Invalid user"
      src="@/assets/images/invalid-user.png">
  </div>
  <div :class="[{'page-not-found__img-container': props.imageSize}]">
    <img
      src="@/assets/images/404.svg?url"
      alt="Page not found"
      :class="['page-not-found__img', `page-not-found__img--${props.imageSize}`]">
  </div>

  <h1 class="page-not-found__heading">
    {{ props.text }}
  </h1>
</template>

<script lang="ts" setup>
import useAnalytics, { EventNames } from '@/modules/common/analytics/useAnalytics'

interface Props {
  imageSize?: string
  text?: string
}

const back = () => window.history.back()

const props = withDefaults(defineProps<Props>(), {
  imageSize: '',
  text: 'Looks like you’ve treaded off the beaten path',
})

const { trackEvent } = useAnalytics()
trackEvent({ name: EventNames.PageNotFound })
</script>

<style scoped>
.page-not-found {
  &__img-container {
    display: flex;
    justify-content: center;
  }

  &__img {
    margin-top: 4.2rem;

    &--s {
      width: 50%;
    }
  }

  &__invalid-user-image {
    display: flex;
    justify-content: center;
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 1.9rem;
    margin-top: 5.8rem;
    text-align: center;
    line-height: 2.35rem;
    color: var(--orange-1);
  }

  &__back {
    display: inline-flex;
    gap: 1.25rem;
    margin-top: 2rem;
    padding-bottom: 1rem;
    text-decoration: none;
    font-family: var(--font-family-screener);
    font-size: 1rem;

    &-label {
      margin: 0;
      font-size: 1.9rem;
    }
  }
}
</style>
