<template>
  <div>
    <BaseSkeletor
      v-if="isLoadingMetadata"
      width="466px"
      height="216px"/>
    <div v-else>
      <div :class="['token-page-basic-info__basic-info', {'token-page-basic-info__basic-info--fake-asset' : isTokenFake}]">
        <div class="token-page-basic-info__image-wrap">
          <TokenMedia
            class="token-page-basic-info__image"
            :token="parsedErc20!"/>
          <ChainIcon
            class="token-page-basic-info__chain-icon"
            alt="chain icon"/>
        </div>
        <div class="token-page-basic-info__name-symbol">
          <div class="token-page-basic-info__symbol">
            <SafeText
              :text="parsedErc20?.symbol!"
              :tooltip-text="parsedErc20?.symbol"/>
            <DangerAssetIcon
              v-if="isTokenFake"
              tooltip-text="This asset is likely fake."/>
          </div>
          <div class="token-page-basic-info__full-name">
            <SafeText
              :text="parsedErc20?.name!"
              :tooltip-text="parsedErc20?.name"/>
            <VerifiedIcon
              v-if="parsedErc20?.isVerified"
              :has-tooltip="true"/>
          </div>
        </div>
      </div>
      <BaseSkeletor
        v-if="isLoadingMetadata"
        height="54"
        class="token-page-basic-info__price-loading"/>
      <div
        v-else
        :class="['token-page-basic-info__price', { 'token-page-basic-info__price-not-fetched': !price}]">
        <AssetPriceSourceIcon
          v-if="!!assetPrice.priceData.value?.priceSource"
          data-source-icon-width-px="36"
          class="eth-and-usd-price-label__source-icon"
          :link="coingeckoUrl"
          :data-source="assetPrice.priceData.value?.priceSource"/>

        <SafeText
          :text="price"
          :tooltip-text="price">
          <template #text>
            <SafeDisplayDecimals
              v-if="price"
              :value="price"/>
          </template>
        </SafeText>
      </div>
      <div
        class="token-page-basic-info__market-data">
        <div
          v-if="stats?.marketCap"
          class="token-page-basic-info__data-row">
          <span class="token-page-basic-info__data-label">Market Cap</span>
          <div class="token-page-basic-info__data-value">
            <EthAndUsdPriceLabel
              :amount="stats.marketCap"
              :shorten-usd-amount="false"/>
          </div>
        </div>
        <div
          v-if="stats?.volume24h"
          class="token-page-basic-info__data-row">
          <span class="token-page-basic-info__data-label">24 Hour Trading Volume</span>
          <EthAndUsdPriceLabel
            :amount="stats?.volume24h"
            :asset="parsedErc20"
            :price-source="stats?.latestPrice?.priceSource"
            :shorten-usd-amount="false"/>
        </div>
        <div
          v-if="stats?.circulatingSupply"
          class="token-page-basic-info__data-row">
          <span class="token-page-basic-info__data-label">Circulating Supply</span>
          <span class="token-page-basic-info__data-value">{{ stats.circulatingSupply }}</span>
        </div>
        <div class="token-page-basic-info__data-row">
          <span class="token-page-basic-info__data-label">Contract Address</span>
          <div class="token-page-basic-info__data-value">
            <CryptoAddress
              v-if="parsedErc20?.chainId && parsedErc20?.address!"
              class="token-page-details__explorer-link"
              :chain-id="parsedErc20.chainId"
              is-copy-button
              tooltip-position="left"
              :address="parsedErc20.address!"/>
            <img
              v-if="isTokenAddableToMetamask"
              class="token-page-details__add-to-metamask"
              src="../../../assets/icons/metamask.svg?url"
              title="add token to Metamask"
              alt="add token to Metamask"
              @click="addTokenToWallet(parsedErc20!)">
          </div>
        </div>
      </div>

      <div class="token-page-basic-info__links">
        <BaseButton
          v-for="link in validLinks"
          :key="link.name"
          :color="ButtonColor.Gray"
          :variant="ButtonVariant.Outline"
          :has-white-hover="true"
          :title="`Token on ${link.name}`"
          is-icon-before-text="link.name === 'website'"
          style="margin-right: 0.5rem;"
          :is-full-width="link.name === 'website'"
          :size="ButtonSize.S"
          :font="ButtonFont.Supreme"
          :button-text="link.name === 'website' ? getWebsiteText(link.to!) : ''"
          :class="['token-page-basic-info__link-button', link.name === 'website' ? 'token-page-basic-info__website-btn' : '']"
          @on-button-click="handleButtonClick(link.to!)">
          <template #icon>
            <component
              :is="link.icon"
              v-if="link.name !== 'website'"
              class="token-page-basic-info__link-icon"
              width="16"
              height="16"
              :alt="`${link.name} icon`"/>
          </template>
          <template
            v-if="link.name === 'website'"
            #iconBeforeText>
            <component
              :is="link.icon"
              class="token-page-basic-info__link-icon"
              width="16"
              height="16"
              :alt="`${link.name} icon`"/>
          </template>
        </BaseButton>
        <RefreshButton
          :size="ButtonSize.S"
          :is-loading="isRefetching"
          is-transparent
          :handle-refresh="handleRefresh"
          class="token-page-basic-info__refresh-button"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SafeText from '@/general-components/SafeText.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { computed, ref } from 'vue'
import { getChainIdFromChainName, getMonoChromeChainIcon } from '@/utils/chain'
import TokenMedia from '@/general-components/TokenMedia.vue'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import type { Component } from 'vue'
import type { Nullable } from '@/modules/common/typings/customTypes'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import WebsiteIconSvg from '@/assets/icons/external-website.svg'
import TwitterIconSvg from '@/assets/icons/twitter.svg'
import DiscordIconSvg from '@/assets/icons/discord.svg'
import InstagramIconSvg from '@/assets/icons/instagram.svg'
import CoingeckoIconSvg from '@/assets/icons/coingecko.svg'
import VerifiedIcon from '@/general-components/icons/VerifiedIcon.vue'
import BaseButton, { ButtonColor, ButtonVariant, ButtonSize, ButtonFont } from '@/general-components/BaseButton.vue'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import { addTokenToWallet } from '@/modules/common/web3/walletActions'
import { useFetchAssetMetadata, useGetCoingeckoUrl } from '@/modules/common/backend/generated'
import { useRoute } from 'vue-router'
import type { ChainName, ChainNameLowercase } from '@/constants/chains/types'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type { Address } from 'viem'
import { formatDecimalPoint } from '@/utils/utils'
import { useAssetPrice } from '@/revamp/hooks/asset-prices/useAssetPrice'
import AssetPriceSourceIcon from '@/general-components/data-source/AssetPriceSourceIcon.vue'
import RefreshButton from '@/modules/common/assets/components/RefreshButton.vue'
import DangerAssetIcon from '@/general-components/DangerAssetIcon.vue'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import SafeDisplayDecimals from '@/general-components/safe-display-decimals/SafeDisplayDecimals.vue'

const stats = ref()
const { isAssetFake } = useTopTokensStore()

interface Props {
  isRefetching: boolean
}
const props = defineProps<Props>()

const emit = defineEmits(['refresh'])

const handleRefresh = () => {
  emit('refresh')
}

const route = useRoute()

const chainId = computed(() => getChainIdFromChainName(route.params.chainName as ChainName | ChainNameLowercase))
const contractAddress = computed(() => route.params.contractAddress as Address)

const erc20MetadataQuery = useFetchAssetMetadata(
  chainId as unknown as string,
  contractAddress,
  '',
  { refresh: false },
  {
    query: {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 2,
    },
  },
)

const { fetchERC20PriceStats } = useMetadataFetch()

fetchERC20PriceStats(chainId.value, contractAddress.value).then((priceStats) => {
  stats.value = priceStats
}).catch(() => {
  stats.value = null
})

const parsedErc20 = computed(() => {
  if (!erc20MetadataQuery.data?.value?.data) {
    return null
  }
  return AssetMetadata.createFromBackendModel(erc20MetadataQuery.data?.value?.data)
})

const isLoadingMetadata = computed(() => erc20MetadataQuery.isLoading.value || props.isRefetching)

const ChainIcon = computed(() => parsedErc20.value?.chainId && getMonoChromeChainIcon(parsedErc20.value.chainId))
const isTokenAddableToMetamask = computed(() => parsedErc20.value?.address && parsedErc20.value?.symbol && parsedErc20.value?.decimals)

const _chainId = computed(() => String(getChainIdFromChainName(route.params.chainName as ChainName | ChainNameLowercase)))
const address = computed(() => route.params.contractAddress as Address)
const tokenId = computed(() => (route.params.tokenId || 'null') as string)

const coingeckoUrlQuery = useGetCoingeckoUrl(
  chainId,
  address,
  {
    query: {
      enabled: true,
      staleTime: 1000 * 60 * 120,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
)

const coingeckoUrl = computed(() => coingeckoUrlQuery.data?.value?.data)

const assetPrice = useAssetPrice(
  _chainId,
  address,
  tokenId,
  parsedErc20.value?.category,
)

const price = computed(() => {
  if (assetPrice.priceData.value && assetPrice.priceData.value?.price.usdAmount) {
    return `$${formatDecimalPoint(assetPrice.priceData.value?.price.usdAmount, 2)}`
  }

  return 'Failed to fetch price'
})

interface ExternalLinkData {
  name: string
  icon?: Component
  to?: Nullable<string>
}

const links = computed<ExternalLinkData[]>(() => [{
  name: 'website',
  to: parsedErc20.value?.websiteUrl,
  icon: WebsiteIconSvg,
}, {
  name: chainId.value ? CHAINS_CONSTANTS[chainId.value].explorer.blockExplorerName : '',
  to: chainId.value ? CHAINS_CONSTANTS[chainId.value].explorer.contractDetailsLink(contractAddress.value) : '',
  icon: chainId.value ? CHAINS_CONSTANTS[chainId.value].explorer.blockExplorerIcon : undefined,
}, {
  name: 'Twitter',
  to: parsedErc20.value?.twitterUrl,
  icon: TwitterIconSvg,
}, {
  name: 'Discord',
  to: parsedErc20.value?.discordUrl,
  icon: DiscordIconSvg,
}, {
  name: 'Instagram',
  to: parsedErc20.value?.instagramUrl,
  icon: InstagramIconSvg,
}, {
  name: 'Coingecko',
  to: coingeckoUrl.value,
  icon: CoingeckoIconSvg,
}])

const validLinks = computed(() => links.value.filter(link => link.to))

const handleButtonClick = (link: string) => {
  window.open(link, '_blank')
}
const getWebsiteText = (link: string) => {
  const withoutProtocol = link.replace(/^https?:\/\/www./, '').replace(/\//, '')
  return withoutProtocol
}

const isTokenFake = computed(() => parsedErc20.value ? isAssetFake(parsedErc20.value) : false)
</script>

<style scoped>
.token-page-basic-info {
  &__market-data {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }

  &__data-label {
    white-space: nowrap;

    color: var(--text-color-secondary);
  }

  &__data-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 30rem;
    min-width: 20rem;
  }

  &__data-value {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;

    font-family: var(--font-faimily-supreme);
    font-weight: 700;
  }

  &__links {
    display: flex;
    gap: 0.5rem;
    height: 1.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__website-btn {
    font-size: 0.875rem;
    width: 17rem;
    height: 1.75rem;
    flex: 1 0 0;
  }

  &__name {
    display: flex;
    gap: 1rem;
    margin-top: 1.2rem;
    font-family: var(--font-family-autoscape);
    font-size: 1.25rem;
    font-weight: 550;
    line-height: 1.75rem;
  }

  &__image-wrap {
    position: relative;
    display: block;
    margin-right: 0.3rem;
  }

  &__basic-info {
    display: flex;
    gap: 1rem;
    position: relative;
    align-items: center;
    font-family: var(--font-family-oxygen-mono);

    &--fake-asset::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--fake-asset-overlay);
      z-index: var(--z-index-fake-asset-overlay);
    }
  }

  &__chain-icon {
    position: absolute;
    right: -0.5rem;
    bottom: -0.15rem;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0.15rem;
    box-sizing: content-box;
    border-radius: 50%;
    background-color: var(--background-color);
    border: 1px solid var(--text-color);
  }

  &__image {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
  }

  &__price {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    font-family: var(--font-family-supreme);
    font-size: 1.875rem;
    font-weight: 400;

    display: flex;
    gap: 0.5rem;
  }

  &__price-not-fetched {
    color: var(--text-color-secondary);
    font-size: 1.2rem !important;
  }

  &__price-loading {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    width: 15rem;
    line-height: 3.375rem;
  }

  &__name-symbol {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 30rem;
  }

  &__full-name {
    display: flex;
    width: fit-content;
    max-width: 30rem;
    gap: 0.25rem;
    flex-direction: row;
    font-size: 0.875rem;
    align-items: center;
    color: var(--text-color-secondary);
  }

  &__symbol {
    display: flex;
    gap: 0.5rem;
    font-size: 1.25rem;
    color: var(--text-color);
  }

  @media only screen and (max-width: 640px) {
    &__name-symbol {
      max-width: 90%;
      font-size: 1.2rem;
    }

    &__name {
      max-width: 90%;
    }

    &__price {
      font-size: 2rem;
    }

    &__links {
      flex-flow: row wrap;
    }
  }
}
</style>
