<template>
  <div class="pwn-safe-claim-and-burn">
    <PwnSafeClaimAndBurnModal/>
    <PwnSafeClaimAndBurnHeader/>
    <div class="pwn-safe-claim-and-burn__sections">
      <PwnSafeClaimAndBurnAtrTokens/>
      <PwnSafeClaimAndBurnSelection/>
    </div>
    <div class="pwn-safe-claim-and-burn__button-container">
      <BaseButton
        button-text="Claim and Burn"
        :is-disabled="!hasAssetsSelected"
        @on-button-click="handleClaimAndBurnAtrTokens"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import PwnSafeClaimAndBurnHeader from '@/modules/pages/pwn-safe/claim-and-burn/PwnSafeClaimAndBurnHeader.vue'
import PwnSafeClaimAndBurnAtrTokens from '@/modules/pages/pwn-safe/claim-and-burn/PwnSafeClaimAndBurnAtrTokens.vue'
import PwnSafeClaimAndBurnSelection from '@/modules/pages/pwn-safe/claim-and-burn/PwnSafeClaimAndBurnSelection.vue'
import { computed, onMounted } from 'vue'
import usePwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import BaseButton from '@/general-components/BaseButton.vue'
import PwnSafeClaimAndBurnModal from '@/modules/pages/pwn-safe/claim-and-burn/modal/PwnSafeClaimAndBurnModal.vue'
import usePwnSafeClaimAndBurnModal, { PwnSafeClaimAndBurnModalStep } from '@/modules/pages/pwn-safe/claim-and-burn/modal/usePwnSafeClaimAndBurnModal'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'

const { atrTokensToClaimAndBurn, displayNfts, displayCoins } = usePwnSafeClaimAndBurn()
const { activeStep, isOpen } = usePwnSafeClaimAndBurnModal()
const hasAssetsSelected = computed(() => atrTokensToClaimAndBurn.value.length > 0)
const { userAddressToDisplay } = useYourNFTs()

const handleClaimAndBurnAtrTokens = async () => {
  activeStep.value = PwnSafeClaimAndBurnModalStep.Waiting
  isOpen.value = true
}
// Needed in case of refresh on page
onMounted(() => {
  displayNfts.value = false
  displayCoins.value = false
  if (!userAddressToDisplay.value) {
    userAddressToDisplay.value = getAccount(pwnWagmiConfig).address
  }
})
</script>

<style>
.pwn-safe-claim-and-burn {
  padding-bottom: 5rem;

  &__sections {
    display: flex;
  }

  &__button-container {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
</style>
