<template>
  <div :class="['search-results', `search-results__variant-${variant}`]">
    <div class="search-results__content">
      <span
        v-if="props.isEmpty"
        class="search-results__empty-text">
        <div v-if="variant === 'dashboard'">
          Try searching for an address or ENS name
        </div>
        <div v-else>
          Try searching for an asset name <br>
          collection name or any address
        </div>
      </span>

      <SearchResultSection
        v-for="section in notEmptySections"
        :key="section.headerTitle"
        :header-title="section.headerTitle"
        :header-value-name="section.headerValue">
        <BaseSkeletor
          v-if="loadingState[section.id]"/>
        <span
          v-else-if="section.isEmpty"
          class="search-results__empty-text">
          No Results
        </span>
        <div v-else>
          <SearchResultItem
            v-for="item in section.items"
            :id="getSearchResultId(item.key)"
            :key="item.key"
            :variant="props.variant"
            :title="item.title ?? null"
            :navigate-to="item.pageRoute()"
            :chain-id="item.chainId ?? null"
            :image="item.image ?? null"
            :is-verified="item.isVerified"
            :section-id="section.id"
            :is-focused="isFocused(item.key)"
            @click="props.handleClose">
            <template #value>
              <!-- even if type is correctly resolved for vue-tsc IDE still throws error here -->
              <!--suppress JSValidateTypes -->
              <CollectionResultValue
                v-show="false"
                v-if="section.id === SearchCategory.COLLECTIONS && isCollection(item)"
                :item="item"/>
            </template>
          </SearchResultItem>
        </div>
      </SearchResultSection>
    </div>
    <div
      v-if="hasAnyResult && variant !== 'dashboard'"
      class="search-results__footer"
      @click="openExplorerWithQuery">
      <span class="search-results__show-more">
        Press Enter to show more
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchResultItem from '@/modules/sections/nav-searchbar/SearchResultItem.vue'
import SearchResultSection from '@/modules/sections/nav-searchbar/SearchResultSection.vue'
import { computed, onUpdated, ref } from 'vue'
import type { Ref } from 'vue'
import router from '@/router'
import RouteName from '@/router/RouteName'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import type { LoadingState, SearchResults } from '@/modules/common/pwn/explorer/useSearchStore'
import CollectionResultValue from '@/modules/sections/nav-searchbar/CollectionResultValue.vue'
import { onKeyDown } from '@vueuse/core'
import type { SearchResultItemType, SearchSection, FocusedSearchResult } from '@/modules/sections/nav-searchbar/types'
import { CollectionSearchResult } from '@/modules/common/pwn/explorer/models/CollectionSearchResult'
import { SearchCategory } from '@/modules/common/pwn/explorer/models'

type Props = {
  searchQuery: string
  variant: 'homepage' | 'default' | 'explorer' | 'dashboard'
  isEmpty?: boolean
  isFetched?: boolean
  isFetching?: boolean
  results: SearchResults
  loadingState: LoadingState
  handleClose: () => void
}

const props = defineProps<Props>()

type ItemType = SearchResultItemType

// @ts-expect-error FIXME: strictNullChecks
const focusedItem: Ref<FocusedSearchResult> = ref()

const getSectionIsEmpty = (sectionCategory: SearchCategory) => {
  return !props.results?.[sectionCategory]?.length && props.loadingState[sectionCategory] === false
}

const sections = props.variant === 'dashboard'
  ? computed<SearchSection[]>(() => [
    {
      id: SearchCategory.WALLETS,
      headerTitle: 'Wallets',
      headerValue: '',
      items: props.results.wallets,
      isEmpty: getSectionIsEmpty(SearchCategory.WALLETS),
    },
  ])
  : computed<SearchSection[]>(() => [
    {
      id: SearchCategory.COLLECTIONS,
      headerTitle: 'Collections',
      headerValue: '',
      items: props.results.collections,
      isEmpty: getSectionIsEmpty(SearchCategory.COLLECTIONS),
    },
    {
      id: SearchCategory.NFTS,
      headerTitle: 'NFTs',
      headerValue: '',
      items: props.results.nfts,
      isEmpty: getSectionIsEmpty(SearchCategory.NFTS),
    },
    {
      id: SearchCategory.TOKENS,
      headerTitle: 'Tokens',
      headerValue: '',
      items: props.results.tokens,
      isEmpty: getSectionIsEmpty(SearchCategory.TOKENS),
    },
    {
      id: SearchCategory.WALLETS,
      headerTitle: 'Wallets',
      headerValue: '',
      items: props.results.wallets,
      isEmpty: getSectionIsEmpty(SearchCategory.WALLETS),
    },
  ])
const notEmptySections = computed(() => {
  const hasAnyResult = sections.value.some((section) => section.items !== null && section.items.length > 0)

  return sections.value.filter((section) => {
    if (hasAnyResult) {
      return section.items !== null && section.items.length > 0
    }

    if (props.isEmpty) {
      return true
    }

    return true
  })
})
const hasAnyResult = computed(() => notEmptySections.value.some((section) => section.items !== null))

const openExplorerWithQuery = async () => {
  // TODO: remove this check after a new homepage design is up and we add wallet tab back to the explorer
  if (props.variant === 'dashboard') {
    return
  }
  const routeName = RouteName.PwnExplorerPageCollections
  await router.push({
    name: routeName,
    query: {
      q: props.searchQuery,
    },
  })

  props?.handleClose()
}

const getSearchResultId = (itemKey: string) => {
  return `search-result-${itemKey}`
}

const scrollToFocusedItem = () => {
  if (!focusedItem.value) {
    return
  }
  const element = document.getElementById(getSearchResultId(focusedItem.value.itemKey))
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-misused-promises
onKeyDown('Enter', async () => {
  // clear focus from search bar
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur()
  }
  if (!focusedItem.value) {
    await openExplorerWithQuery()
    props.handleClose()
  } else {
    const section = notEmptySections.value.find((section) => section.id === focusedItem.value.sectionIndex)
    if (section) {
      // @ts-expect-error FIXME: strictNullChecks
      const item = section.items[focusedItem.value.itemIndex]
      if (item) {
        await router.push(item.pageRoute())
        props.handleClose()
      }
    }
  }
})

onKeyDown('ArrowUp', (e) => {
  e.preventDefault()
  if (!focusedItem.value) {
    return
  } else {
    // check if this item is first in the section
    if (focusedItem.value.itemIndex === 0) {
      // check if this is the first section
      const sectionIndex = notEmptySections.value.findIndex((section) => section.id === focusedItem.value.sectionIndex)
      if (sectionIndex === 0) {
        // @ts-expect-error FIXME: strictNullChecks
        focusedItem.value = null
      } else {
        // focus last item in previous section
        const lastSection = notEmptySections.value[sectionIndex - 1]
        // @ts-expect-error FIXME: strictNullChecks
        const lastItem = lastSection.items[lastSection.items.length - 1]
        focusedItem.value = {
          // @ts-expect-error FIXME: strictNullChecks
          itemIndex: lastSection.items.length - 1,
          itemKey: lastItem.key,
          sectionIndex: lastSection.id,
        }
      }
    } else {
      // focus previous item in this section
      const section = notEmptySections.value.find((section) => section.id === focusedItem.value.sectionIndex)
      if (section) {
        // @ts-expect-error FIXME: strictNullChecks
        const previousItem = section.items[focusedItem.value.itemIndex - 1]
        focusedItem.value = {
          itemIndex: focusedItem.value.itemIndex - 1,
          itemKey: previousItem.key,
          sectionIndex: section.id,
        }
      }
    }
  }
  scrollToFocusedItem()
})

onKeyDown('ArrowDown', (e) => {
  e.preventDefault()
  if (!focusedItem.value) {
    // focus first item in first section
    const firstSection = notEmptySections.value[0]
    // @ts-expect-error FIXME: strictNullChecks
    const firstItem = firstSection.items[0]
    focusedItem.value = {
      itemIndex: 0,
      itemKey: firstItem.key,
      sectionIndex: firstSection.id,
    }
  } else {
    // check if this item is last in the section
    const section = notEmptySections.value.find((section) => section.id === focusedItem.value.sectionIndex)
    if (section) {
      // @ts-expect-error FIXME: strictNullChecks
      if (focusedItem.value.itemIndex === section.items.length - 1) {
        // check if this is the last section
        const sectionIndex = notEmptySections.value.findIndex((section) => section.id === focusedItem.value.sectionIndex)
        if (sectionIndex === notEmptySections.value.length - 1) {
          return
        } else {
          // focus first item in next section
          const nextSection = notEmptySections.value[sectionIndex + 1]
          // @ts-expect-error FIXME: strictNullChecks
          const nextItem = nextSection.items[0]
          focusedItem.value = {
            itemIndex: 0,
            itemKey: nextItem.key,
            sectionIndex: nextSection.id,
          }
        }
      } else {
        // focus next item in this section
        // @ts-expect-error FIXME: strictNullChecks
        const nextItem = section.items[focusedItem.value.itemIndex + 1]
        focusedItem.value = {
          itemIndex: focusedItem.value.itemIndex + 1,
          itemKey: nextItem.key,
          sectionIndex: section.id,
        }
      }
    }
  }
  scrollToFocusedItem()
})

const isCollection = (item: ItemType): item is CollectionSearchResult => {
  return item instanceof CollectionSearchResult
}

const isFocused = (key: string) => {
  if (!focusedItem.value) {
    return false
  }
  return focusedItem.value.itemKey === key
}

onUpdated(() => {
  // @ts-expect-error FIXME: strictNullChecks
  focusedItem.value = null
})
</script>

<style>
.search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.25rem 2rem 0 var(--background-color);
  border: 1px solid var(--gray-3);
  background: var(--background-color);
  min-height: 250px;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  position: absolute;

  &__content {
    width: 100%;
    max-height: 35rem;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__empty-text {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    padding: 0.25rem 0;
    font-family: var(--font-family-oxygen-mono);
    font-weight: 400;
    line-height: 1.5;
  }

  &__footer {
    cursor: pointer;
    margin-top: 1rem;
    padding: 0.5rem;
    text-align: center;
    border: 1px solid var(--primary-color-1);
    width: 100%;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.035);
    }
  }

  &__show-more {
    transition: color 0.2s ease-in-out;
    color: var(--primary-color-1);
  }

  &__user-icon {
    margin-right: 0.5rem;
    height: 1.75rem;
    width: 1.75rem;
  }

  &__variant-dashboard,
  &__variant-homepage,
  &__variant-default {
    max-height: 31rem;
  }
}
</style>
