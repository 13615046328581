import { sendTransaction } from '@/modules/common/web3/useTransactions'
import DateUtils from '@/utils/DateUtils'
import type { BaseLoan, V1SimpleLoan } from '@/modules/common/pwn/loans/LoanClasses'
import useApprove from '@/modules/common/assets/useApprove'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { ASSET_TYPES_WITHOUT_AMOUNT } from '@/modules/common/assets/AssetType'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import fixSignatureIfInvalid from '@/utils/fixSignatureIfInvalid'
import { getAddress, maxUint256, zeroAddress } from 'viem'
import type { Address, Hex, TransactionReceipt } from 'viem'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { pwnV1SimpleLoanAbi, pwnV1SimpleLoanSimpleRequestAbi, readPwnV1SimpleLoanSimpleRequestEncodeLoanTermsFactoryData, readPwnV1SimpleLoanSimpleRequestGetRequestHash } from '@/contracts/generated'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export default class V1SimpleLoanSimpleRequestContract {
  public createRequestStruct(loan: V1SimpleLoan) {
    const collateral = loan.collateral
    let collateralAmountRaw = loan.collateralAmountRaw

    if (ASSET_TYPES_WITHOUT_AMOUNT.includes(collateral.category)) {
      collateralAmountRaw = 0n
    }

    return {
      collateralCategory: collateral.category,
      collateralAddress: getAddress(collateral.address),
      collateralId: (loan.collateralAssetTokenId ? BigInt(loan.collateralAssetTokenId) : 0n),
      collateralAmount: collateralAmountRaw,
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      loanAssetAddress: loan.desiredLoanTerms.address,
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      loanAmount: loan.desiredLoanTerms.amountRaw,
      loanYield: loan.desiredLoanTerms?.fixedInterestAmount ?? 0n,
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      duration: DateUtils.convertDaysToSeconds(loan.desiredLoanTerms.durationDays),
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      expiration: DateUtils.getTimestampInSeconds(loan.desiredLoanTerms.loanExpirationDate),
      borrower: loan.borrower,
      lender: zeroAddress, // lender Address of a lender. Only this address can accept a request. If the address is zero address, anybody with a loan asset can accept the request. source: https://github.com/PWNDAO/pwn_contracts/blob/d14f2122aa131ff823864730871cf8f4cfc6f936/src/loan/terms/simple/factory/request/PWNSimpleLoanSimpleRequest.sol#L45C7-L45C7 - for now we don't specify lender in loan request
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      nonce: loan.desiredLoanTerms.loanRequestNonce!,
    }
  }

  public async encodeRequestData(loan: V1SimpleLoan): Promise<Hex> {
    const requestStruct = this.createRequestStruct(loan)
    return await readPwnV1SimpleLoanSimpleRequestEncodeLoanTermsFactoryData(pwnWagmiConfig, {
      address: CHAINS_CONSTANTS[loan.collateral.chainId].pwnV1Contracts.pwnSimpleLoanSimpleRequest,
      chainId: loan.chainId,
      args: [requestStruct],
    })
  }

  public async geLoanRequestHash(loan: V1SimpleLoan): Promise<string> {
    const requestStruct = this.createRequestStruct(loan)
    return await readPwnV1SimpleLoanSimpleRequestGetRequestHash(pwnWagmiConfig, {
      address: CHAINS_CONSTANTS[loan.collateral.chainId].pwnV1Contracts.pwnSimpleLoanSimpleRequest,
      chainId: loan.chainId,
      args: [requestStruct],
    })
  }

  public async revokeLoanRequest(loan: V1SimpleLoan, step: ToastStep): Promise<TransactionReceipt> {
    const { desiredLoanTerms } = loan
    if (desiredLoanTerms?.loanRequestNonce === undefined || desiredLoanTerms.loanRequestNonce === null) {
      throw new Error(`Trying to perform onchain revoke loan ID ${loan.id} with loan without loanRequestNonce value.`)
    }

    return await sendTransaction({
      abi: pwnV1SimpleLoanSimpleRequestAbi,
      address: CHAINS_CONSTANTS[loan.chainId].pwnV1Contracts.pwnSimpleLoanSimpleRequest,
      args: [desiredLoanTerms.loanRequestNonce],
      chainId: loan.chainId,
      functionName: 'revokeRequestNonce',
    }, { step })
  }

  public async isBorrowerApprovedForInstantFunding(loan: BaseLoan): Promise<boolean> {
    if (!loan.collateral.chainId) return false

    return await useApprove().isApproved(
      loan.collateral.chainId,
      loan.collateral.category,
      loan.collateral.address,
      loan.borrower,
      CHAINS_CONSTANTS[loan.collateral.chainId].pwnV1Contracts.pwnSimpleLoan,
      loan.collateral.amountRaw,
    )
  }

  public async isLenderApprovedForInstantFunding(loan: BaseLoan, lenderAddress: Address): Promise<boolean> {
    if (!loan.collateral || loan?.desiredLoanTerms?.category === undefined) return false
    return await useApprove().isApproved(
      loan.collateral.chainId,
      loan.desiredLoanTerms.category,
      loan.desiredLoanTerms.address,
      lenderAddress,
      CHAINS_CONSTANTS[loan.collateral.chainId].pwnV1Contracts.pwnSimpleLoan,
      loan.desiredLoanTerms.amountRaw,
    )
  }

  public async approveForInstantFundingBase(loan: BaseLoan, assetToApprove: AssetWithAmount, step: ToastStep): Promise<boolean> {
    const isApproved = await useApprove().approve({
      asset: assetToApprove,
      spender: CHAINS_CONSTANTS[assetToApprove.chainId].pwnV1Contracts.pwnSimpleLoan,
      amount: maxUint256,
      minAllowanceAmountToCheck: assetToApprove.amountRaw,
    }, step)
    return !!isApproved
  }

  public async approveBorrowerForInstantFunding(loan: BaseLoan, step: ToastStep): Promise<boolean> {
    return await loan.loanRequestContract.approveForInstantFundingBase(loan, loan.collateral, step)
  }

  public async approveLenderForInstantFunding(loan: BaseLoan, step: ToastStep): Promise<boolean> {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'DesiredLoanTerms | null' is not ... Remove this comment to see the full error message
    return await loan.loanRequestContract.approveForInstantFundingBase(loan, loan.desiredLoanTerms, step)
  }

  public async fundInstantlyLoan(loan: V1SimpleLoan, step?: ToastStep): Promise<TransactionReceipt> {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const validatedLoanRequestSignature = fixSignatureIfInvalid(loan.desiredLoanTerms.loanRequestSignature)
    const loanTermsFactoryData = await loan.loanRequestContract.encodeRequestData(loan)

    return await sendTransaction({
      abi: pwnV1SimpleLoanAbi,
      functionName: 'createLOAN',
      args: [
        CHAINS_CONSTANTS[loan.collateral.chainId].pwnV1Contracts.pwnSimpleLoanSimpleRequest, // loanTermsFactoryContractAddress
        loanTermsFactoryData,
        validatedLoanRequestSignature,
        '0x',
        '0x',
      ],
      address: CHAINS_CONSTANTS[loan.collateral.chainId].pwnV1Contracts.pwnSimpleLoan,
      chainId: loan.chainId,
    }, { step })
  }
}
