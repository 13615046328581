<template>
  <div class="theses-page">
    <div class="theses-page__wrapper">
      <ThesesTable
        :on-update="handleUpdateUserThesisStats"
        :hide-cancel-button="true"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getThesisDetailQueryOptions, getThesisListQueryOptions } from '@/modules/common/backend/generated'
import ThesesTable from '@/revamp/components/tables/thesis/ThesesTable.vue'
import { useQueryClient } from '@tanstack/vue-query'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const client = useQueryClient()
const { address } = useCustomAccount()

const handleUpdateThesisStats = (thesisId: string) => {
  const params = getThesisDetailQueryOptions(thesisId)

  client.invalidateQueries(params)
}

const handleUpdateUserThesisStats = (thesisId: string) => {
  handleUpdateThesisStats(thesisId)
  const params = getThesisDetailQueryOptions(thesisId, { user_address: address.value })
  const thesisListParams = getThesisListQueryOptions({ user_address: address.value })
  const thesisListQuery = getThesisListQueryOptions()

  setTimeout(() => {
    client.invalidateQueries(params)
    client.invalidateQueries(thesisListParams)
    client.invalidateQueries(thesisListQuery)
  }, 300)
}

</script>

<style scoped>
.theses-page {
  margin-top: 2.5rem;
  margin-bottom: 4rem;

  &__wrapper {
    padding: 1rem;
    border: 1px solid #434343;
  }
}
</style>
