<template>
  <section class="section">
    <header
      v-if="heading?.length"
      :class="{
        'section__header--with-gray-background': withGrayBackground,
        'section__header--with-margin': props.withMargin,
      }"
      class="section__header"
      @click="(e) => isExpandable ? toggleExpand(e) : null">
      <slot name="title-icon"/>
      <h2 :class="headingClasses">
        {{ heading }}
        <span
          v-if="secondaryHeading"
          class="section__title--secondary-heading"> {{ secondaryHeading }}</span>
        <ExpandIcon
          v-if="isExpandable"
          :class="['section__icon', { 'section__icon--expanded': isExpanded }]"
          alt="Toggle expand"/>
      </h2>
      <CircleLoaderIcon
        v-if="isLoading"
        class="section__is-loading"
        :is-pending="isLoading"/>
      <BaseSearch
        v-if="hasSearch"
        v-model="searchTermLocal"
        :search-placeholder="searchPlaceholder"
        :style="searchStyles"/>

      <div
        ref="controlsRef"
        class="section__controls">
        <slot name="controls"/>
      </div>
    </header>

    <div v-show="isExpanded">
      <slot/>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, withDefaults } from 'vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import CircleLoaderIcon from '@/general-components/icons/CircleLoaderIcon.vue'
import ExpandIcon from '@/assets/icons/icon_expand.svg'

const props = withDefaults(defineProps<{
  heading?: string,
  isExpandable?: boolean,
  searchTerm?: string,
  searchStyles?: Partial<CSSStyleDeclaration>,
  isSubsection?: boolean,
  searchPlaceholder?: string,
  secondaryHeading?: string,
  removeHeadingMargin?: boolean,
  withGrayBackground?: boolean,
  withMargin?: boolean
  isLoading?: boolean
}>(), {
  isExpandable: true,
  searchTerm: undefined,
  searchStyles: () => ({}),
  isSubsection: false,
  searchPlaceholder: 'Search',
  secondaryHeading: '',
  removeHeadingMargin: false,
  withMargin: false,
  isLoading: false,
})

const emit = defineEmits(['update:searchTerm'])

const isExpanded = ref(true)
const controlsRef = ref<HTMLElement | null>(null)
const toggleExpand = (e: HTMLElementEventMap['click']) => {
  if (controlsRef.value?.contains(e.target as Node)) {
    return
  }
  isExpanded.value = !isExpanded.value
}

const searchTermLocal = computed({
  get: () => props.searchTerm as string,
  set: (value: string) => {
    emit('update:searchTerm', value)
  },
})

const hasSearch = computed(() => props.searchTerm !== undefined)

const headingClasses = computed(() => [
  'section__title',
  { 'section__title--expandable': props.isExpandable },
  { 'section__title--subsection': props.isSubsection },
  { 'section__title--remove-margin': props.removeHeadingMargin },
])
</script>

<style scoped>
.section {
  color: var(--text-color);
  border-radius: 5px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    max-height: 2.375rem;

    &--with-margin {
      margin-top: 3.25rem;
      margin-bottom: 3.25rem;
    }

    &--with-gray-background {
      background-color: var(--gray-3);
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      align-items: center;
    }
  }

  &__title {
    font-family: var(--font-family-screener);
    font-size: 1.125rem;
    font-weight: 400;
    /* 1px Chrome overflow fix */

    display: flex;
    align-items: center;
    gap: 0.25rem;

    &--subsection {
      font-size: 1.125rem;
    }

    &--expandable {
      cursor: pointer;
    }

    &--secondary-heading {
      font-size: 1.125rem;
    }

    &--remove-margin {
      margin-bottom: 0;
    }
  }

  &__controls {
    display: flex;
    gap: 1rem;
    margin-left: auto;
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;

    &--expanded {
      transform: rotate(180deg);
    }
  }

  &__is-loading {
    margin-left: 0.5rem;
  }

  @media only screen and (--small-viewport) {
    &__header {
      margin-bottom: 0;
      display: flex;
      flex-flow: row;

      &--with-margin {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    &__title {
      font-size: 1.1rem;

      &--secondary-heading {
        font-size: 1.1rem;
      }
    }

    &__icon {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
