import { computed, unref } from 'vue'
import type { MaybeRef, Ref } from 'vue'
import { useThesisDetail } from '@/modules/common/backend/generated'
import type { ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { parseThesisAsset } from './utils'

export const useGetThesis = (
  thesisId: MaybeRef<string>,
) => {
  const reqParams = computed(() => {
    return {
    }
  })

  const thesisDetailQuery = useThesisDetail(
    thesisId,
    reqParams,
    {
      query: {
        enabled: computed(() => !!unref(thesisId)),
      },
    },
  )

  const thesisMock: Ref<ThesisSchemaWorkaroundBackendSchema | undefined> = computed(() => {
    return thesisDetailQuery.data.value?.data
  })

  const parsedThesisCollateralAssets = computed(() => {
    if (!thesisMock.value) {
      return []
    }
    return thesisMock.value?.collateralAssets?.map(parseThesisAsset)
  })

  const composedThesis = computed(() => {
    return {
      ...thesisMock.value!,
      parsedCollateralAssets: parsedThesisCollateralAssets,
    }
    // return {
    //   ...thesisMock.value!,
    //   parsedCollateralAssets: parsedThesisCollateralAssets,
    // }
  })

  return {
    thesisCollateralAssets: parsedThesisCollateralAssets,
    thesis: composedThesis,
    isPending: thesisDetailQuery.isPending,
    isFetched: thesisDetailQuery.isFetched,
  }
}
