import { AssetCategoryBackendSchema } from '@/modules/common/backend/generated'

enum AssetType {
  UNSUPPORTED = -1,
  ERC20 = 0,
  ERC721 = 1,
  ERC1155 = 2,
  CRYPTOKITTIES = 3,
  CRYPTOPUNKS = 420,
  NATIVE_TOKEN = 421,
}
export default AssetType

export const ASSET_TYPES_WITHOUT_AMOUNT = [AssetType.ERC721, AssetType.CRYPTOPUNKS, AssetType.CRYPTOKITTIES]

// adapter for transforming CategoryEnum received from backend to custom frontend enum.
export const parseAssetCategoryToAssetType = (category: AssetCategoryBackendSchema): AssetType => {
  switch (category) {
  case AssetCategoryBackendSchema.NUMBER_MINUS_1:
    return AssetType.UNSUPPORTED
  case AssetCategoryBackendSchema.NUMBER_0:
    return AssetType.ERC20
  case AssetCategoryBackendSchema.NUMBER_1:
    return AssetType.ERC721
  case AssetCategoryBackendSchema.NUMBER_2:
    return AssetType.ERC1155
  case AssetCategoryBackendSchema.NUMBER_3:
    return AssetType.CRYPTOKITTIES
  case AssetCategoryBackendSchema.NUMBER_420:
    return AssetType.CRYPTOPUNKS
  case AssetCategoryBackendSchema.NUMBER_421:
    return AssetType.NATIVE_TOKEN
  default:
    throw new TypeError(`Unknown asset category received from backend. Category: ${category}`)
  }
}

export const parseAssetTypeToAssetCategory = (assetType: AssetType): AssetCategoryBackendSchema => {
  switch (assetType) {
  case AssetType.UNSUPPORTED:
    return AssetCategoryBackendSchema.NUMBER_MINUS_1
  case AssetType.ERC20:
    return AssetCategoryBackendSchema.NUMBER_0
  case AssetType.ERC721:
    return AssetCategoryBackendSchema.NUMBER_1
  case AssetType.ERC1155:
    return AssetCategoryBackendSchema.NUMBER_2
  case AssetType.CRYPTOKITTIES:
    return AssetCategoryBackendSchema.NUMBER_3
  case AssetType.CRYPTOPUNKS:
    return AssetCategoryBackendSchema.NUMBER_420
  case AssetType.NATIVE_TOKEN:
    return AssetCategoryBackendSchema.NUMBER_421
  default:
    throw new TypeError(`Unknown assetType ${assetType}.`)
  }
}

export const NFT_CATEGORIES = [
  AssetType.ERC721,
  AssetType.ERC1155,
  AssetType.CRYPTOPUNKS,
  AssetType.CRYPTOKITTIES,
]

export type NftAssetType = typeof NFT_CATEGORIES[number]
