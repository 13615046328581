<template>
  <BaseSelect
    :model-value="priceHistoryPrimaryCurrency"
    class="currency-switcher"
    :searchable="false"
    :options="currencyTypeOptions"
    @update:model-value="handlePrimaryCurrencyLocalStorage">
    <template #option="{ option }">
      <div class="currency-switcher__option">
        {{ option }}
      </div>
    </template>

    <template #singleLabel="{ option }">
      <div
        class="currency-switcher__selected-option">
        {{ option }}
      </div>
    </template>
  </BaseSelect>
</template>

<script setup lang="ts">
import BaseSelect from '@/general-components/BaseSelect.vue'
import useAssetPriceHistory, { CurrencyType } from '@/modules/pages/asset/nft-page/SectionPriceHistory/useAssetPriceHistory'

const currencyTypeOptions: CurrencyType[] = Object.values(CurrencyType)
const { priceHistoryPrimaryCurrency, LOCAL_STORAGE_KEYS, setPriceHistoryPrimaryCurrency } = useAssetPriceHistory()
const emit = defineEmits<{(e: 'on-currency-select', currency: string): void;}>()

const handlePrimaryCurrencyLocalStorage = (currency: CurrencyType) => {
  setPriceHistoryPrimaryCurrency(currency)
  emit('on-currency-select', currency)
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_CURRENCY_IN_CHART, String(currency))
}
</script>

<style scoped>
.currency-switcher {
  width: 6.25rem;

  &__option,
  &__selected-option {
    font-size: 0.875rem;
    text-align: center;
    transform: translateY(1px);
  }
}
</style>
