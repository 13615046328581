<template>
  <form
    class="min-max-form"
    @submit="
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }
    ">
    <div class="min-max-form__row">
      <span>{{ label }}</span>
      <FormField
        name="min"
        :validators="{
          onChange: ({ value, fieldApi }) => {
            const parsed = props.minSchema.safeParse(value);

            if (!parsed.success) {
              return parsed.error.errors[0].message;
            }

            const maxValue = fieldApi.form.getFieldValue('max');

            if (parsed.data > maxValue) {
              return 'Min value should be less than max value';
            }
            return undefined;
          },
          onChangeAsyncDebounceMs: 300,
          onChangeListenTo: ['max'],
        }">
        <template #default="{ field, state }">
          <div class="min-max-form__field">
            <slot
              name="min"
              :field="{
                ...field,
                handleBlur: (e) => {
                  field.handleBlur();
                  handleBlur('min');
                  form.handleSubmit();
                },
              }"
              :state="state"
              :on-focus="handleFocusEventSelectWholeField(() => onFocus('min'))"
              :value="getMinValueToDisplay(state.value)"/>
            <span class="min-max-form__error">
              {{ getFirstStateError(state.meta.errors) || formStore?.fieldMeta?.max?.errors[0] }}
            </span>
          </div>
        </template>
      </FormField>

      <div class="min-max-form__field">
        <FormField
          name="max"
          :validators="{
            onChange: ({ value, fieldApi, ...rest }) => {
              const parsed = props.maxSchema.safeParse(value);

              if (!parsed.success) {
                return parsed.error.errors[0].message;
              }

              return undefined;
            },
            onChangeAsyncDebounceMs: 300,
          }">
          <template #default="{ field, state }">
            <slot
              name="max"
              :field="{
                ...field,
                handleBlur: (e) => {
                  field.handleBlur();
                  handleBlur('max');
                  form.handleSubmit();
                },
              }"
              :state="state"
              :on-focus="handleFocusEventSelectWholeField(() => onFocus('max'))"
              :value="getMaxValueToDisplay(state.value)"/>
          </template>
        </FormField>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm } from '@tanstack/vue-form'
import type { ZodSchema } from 'zod'
import { nextTick, ref } from 'vue'
import { zodValidator } from '@tanstack/zod-form-adapter'

type Props = {
  setMin: (value: number) => void;
  setMax: (value: number) => void;
  min: number | string;
  max: number | string;
  mask?: string;
  minSchema: ZodSchema;
  maxSchema: ZodSchema;
  resetToDefault: () => void;
  label: string;
};

const props = defineProps<Props>()

const form = useForm({
  defaultValues: {
    min: props.minSchema.parse(String(props.min)),
    max: props.maxSchema.parse(String(props.max)),
  },
  onSubmit({ value }) {
    props.setMin(value.min)
    props.setMax(value.max)
  },
  validatorAdapter: zodValidator(),
})

const FormField = form.Field

const minIsFocused = ref(false)
const maxIsFocused = ref(false)

const onFocus = (field: 'min' | 'max') => {
  if (field === 'min') {
    minIsFocused.value = true
    maxIsFocused.value = false
  } else {
    minIsFocused.value = false
    maxIsFocused.value = true
  }
}

const handleFocusEventSelectWholeField = (fn: VoidFunction) => (e: FocusEvent) => {
  fn()
  nextTick().then(() => {
    (e.target as HTMLInputElement)?.select()
  })
}

const handleBlur = (field: 'min' | 'max') => {
  if (field === 'min') {
    minIsFocused.value = false
  } else {
    maxIsFocused.value = false
  }
}

const getMinValueToDisplay = (value: string | number) => {
  if (minIsFocused.value) {
    return value
  }
  return `${value}${props.mask}`
}

const getMaxValueToDisplay = (value: string | number) => {
  if (maxIsFocused.value) {
    return value
  }
  return `${value}${props.mask}`
}

const getFirstStateError = (errors: any[]) => {
  return errors[0]
}

const formStore = form.useStore((store) => store)
</script>

<style scoped>
.min-max-form {
  display: flex;
  flex-flow: column nowrap;

  gap: 10px;

  &__row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;

    &-header {
      justify-content: space-between;
      align-items: center;
    }
  }

  input {
    all: unset;
    width: 80px;
    border: 1px solid var(--gray);

    padding: 0.5rem;
  }

  &__field {
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;

    position: relative;
  }

  &__error {
    color: var(--warning);
    font-size: 0.75rem;
    position: absolute;
    bottom: -1rem;
    width: 250px;
  }
}
</style>
