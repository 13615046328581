<template>
  <div class="wallet-safe-wallet-transfer-graphic__icons-container">
    <figure>
      <WalletIconSvg
        width="122"
        height="122"
        alt="from current wallet"/>
      <figcaption class="wallet-safe-wallet-transfer-graphic__figcaption">
        Current Wallet
      </figcaption>
    </figure>

    <div class="wallet-safe-wallet-transfer-graphic__horizontal-line-wrapper">
      <LineHorizontalIconSvg
        alt="transfer"
        width="190"/>
      <template v-if="isTransferringFromUser">
        <component
          :is="BatchImageComponent"
          v-if="isMultipleAssets"
          class="wallet-safe-wallet-transfer-graphic__asset-in-transfer"
          width="80"
          height="80"/>
        <TokenMedia
          v-else-if="singleAsset"
          class="wallet-safe-wallet-transfer-graphic__asset-in-transfer"
          width="80"
          height="80"
          :token="singleAsset"/>
      </template>
    </div>

    <figure>
      <div class="wallet-safe-wallet-transfer-graphic__safe-icon-wrapper">
        <SafeIconSvg
          width="122"
          height="122"
          alt="to your pwn safe"/>
        <template v-if="isTokenizing">
          <component
            :is="BatchImageComponent"
            v-if="isMultipleAssets"
            class="wallet-safe-wallet-transfer-graphic__asset-in-safe"
            width="80"
            height="80"/>
          <TokenMedia
            v-else
            class="wallet-safe-wallet-transfer-graphic__asset-in-safe"
            width="80"
            height="80"
            :token="singleAsset!"/>
        </template>
      </div>
      <figcaption
        v-if="pwnSafeName"
        class="wallet-safe-wallet-transfer-graphic__figcaption wallet-safe-wallet-transfer-graphic__figcaption--safe-name">
        <SafeText :text="pwnSafeName"/>
      </figcaption>
    </figure>

    <div class="wallet-safe-wallet-transfer-graphic__horizontal-line-wrapper">
      <LineHorizontalIconSvg
        alt="transfer"
        width="190"/>
      <template v-if="isTransferringFromPwnSafe">
        <component
          :is="BatchImageComponent"
          v-if="isMultipleAssets"
          class="wallet-safe-wallet-transfer-graphic__asset-in-transfer"
          width="80"
          height="80"/>
        <TokenMedia
          v-else-if="singleAsset"
          class="wallet-safe-wallet-transfer-graphic__asset-in-transfer"
          width="80"
          height="80"
          :token="singleAsset"/>
      </template>
    </div>

    <figure>
      <WalletIconSvg
        width="122"
        height="122"
        alt="back to current wallet"/>
      <figcaption class="wallet-safe-wallet-transfer-graphic__figcaption">
        Current Wallet
      </figcaption>
    </figure>
  </div>
</template>

<script setup lang="ts">
import TokenMedia from '@/general-components/TokenMedia.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, toRefs } from 'vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import SafeText from '@/general-components/SafeText.vue'
import LineHorizontalIconSvg from '@/assets/icons/line-horizontal.svg'
import SafeIconSvg from '@/assets/icons/safe.svg'
import WalletIconSvg from '@/assets/icons/wallet.svg'

interface Props {
    assets?: AssetWithAmount[]
    pwnSafeName?: string
    isTransferringFromUser?: boolean
    isTokenizing?: boolean
    isTransferringFromPwnSafe?: boolean
}
const props = defineProps<Props>()
const { assets, isTransferringFromPwnSafe, isTransferringFromUser, isTokenizing, pwnSafeName } = toRefs(props)

const pwnSafeDetailStore = usePwnSafeDetailStore()

// @ts-expect-error FIXME: strictNullChecks
const isMultipleAssets = computed(() => assets.value?.length > 1)

const BatchImageComponent = computed(() => {
  if (!isMultipleAssets.value) {
    return
  }

  // @ts-expect-error FIXME: strictNullChecks
  return pwnSafeDetailStore.createBatchImage(assets.value)
})

const singleAsset = computed(() => {
  if (isMultipleAssets.value || !assets.value) {
    return null
  }
  return assets.value[0]
})
</script>

<style scoped>
figure {
  margin: 0;
}

.wallet-safe-wallet-transfer-graphic {
  &__icons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__horizontal-line-wrapper,
  &__safe-icon-wrapper {
    position: relative;
    display: flex;
  }

  &__asset-in-transfer,
  &__asset-in-safe {
    width: 5.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__figcaption {
    font-size: 0.75rem;
    color: var(--primary-color-1);
    text-align: center;
    position: absolute;
    width: 7.625rem;

    &--safe-name {
      max-width: 7.625rem;
    }
  }
}
</style>
