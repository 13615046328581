import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain } from '@/constants/chains/types'
import ETHEREUM_CONSTANTS from '@/constants/chains/ethereum'
import POLYGON_CONSTANTS from '@/constants/chains/polygon'
import CRONOS_CONSTANTS from '@/constants/chains/cronos'
import BASE_CONSTANTS from '@/constants/chains/base'
import OPTIMISM_CONSTANTS from '@/constants/chains/optimism'
import ARBITRUM_ONE_CONSTANTS from '@/constants/chains/arbitrum-one'
import BSC_CONSTANTS from '@/constants/chains/bsc'
import SEPOLIA_CONSTANTS from './sepolia'
import UNICHAIN_SEPOLIA_CONSTANTS from './unichainSepolia'
import GNOSIS_CONSTANTS from './gnosis'
import STARKNET_SEPOLIA_CONSTANTS from './starknetSepolia'
import STARKNET_MAINNET_CONSTANTS from './starknet'

export const CHAINS_CONSTANTS: Record<Exclude<SupportedChain, SupportedChain.StarknetMainnet | SupportedChain.StarknetSepolia>, ChainConstants> = {
  [SupportedChain.Ethereum]: ETHEREUM_CONSTANTS,
  [SupportedChain.Polygon]: POLYGON_CONSTANTS,
  [SupportedChain.Cronos]: CRONOS_CONSTANTS,
  [SupportedChain.Base]: BASE_CONSTANTS,
  [SupportedChain.Optimism]: OPTIMISM_CONSTANTS,
  [SupportedChain.Arbitrum]: ARBITRUM_ONE_CONSTANTS,
  [SupportedChain.Bsc]: BSC_CONSTANTS,
  [SupportedChain.Sepolia]: SEPOLIA_CONSTANTS,
  [SupportedChain.UnichainSepolia]: UNICHAIN_SEPOLIA_CONSTANTS,
  [SupportedChain.Gnosis]: GNOSIS_CONSTANTS,

  // @ts-expect-error TS(2322)
  [SupportedChain.StarknetSepolia]: STARKNET_SEPOLIA_CONSTANTS,
  [SupportedChain.StarknetMainnet]: STARKNET_MAINNET_CONSTANTS,
}
