<template>
  <div class="token-type-filter">
    <div class="token-type-filter__text">
      <div>Filter</div>
    </div>
    <div class="token-type-filter__option">
      <BaseCheckbox
        v-model="displayNfts"
        :variant="CheckboxVariant.SmallGray"
        label="NFTs"
        input-name="nfts"
        @update:model-value="handleOnSwitchDisplayNfts"/>
    </div>
    <div class="token-type-filter__option">
      <BaseCheckbox
        v-model="displayCoins"
        :variant="CheckboxVariant.SmallGray"
        label="Tokens"
        input-name="coins"
        @update:model-value="handleOnSwitchDisplayCoins"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import CheckboxVariant from '@/general-components/CheckboxVariant'

const displayNfts = ref(false)
const displayCoins = ref(false)

const emit = defineEmits<{(e: 'onSwitchDisplayNfts', value: boolean): void; (e: 'onSwitchDisplayCoins', value: boolean): void;}>()

const handleOnSwitchDisplayNfts = (val: boolean) => {
  if (val && displayCoins.value) {
    displayCoins.value = false
    emit('onSwitchDisplayCoins', false)
  }
  emit('onSwitchDisplayNfts', val)
}

const handleOnSwitchDisplayCoins = (val: boolean) => {
  if (val && displayNfts.value) {
    displayNfts.value = false
    emit('onSwitchDisplayNfts', false)
  }
  emit('onSwitchDisplayCoins', val)
}

</script>

<style scoped>
.token-type-filter {
  height: 2.5rem;
  width: 15rem;
  border: 1px solid var(--primary-color-3);
  display: flex;
  justify-content: space-around;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color: var(--primary-color-1);
  }

  &__option {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
