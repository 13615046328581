<template>
  <div class="dashboard-big-tab-buttons">
    <BaseButton
      :size="ButtonSize.XXL"
      :font="ButtonFont.Screener"
      :color="getButtonColor('assets')"
      :variant="ButtonVariant.Outline"
      :button-text="getButtonText('Assets')"
      @click="handleSelect('assets')"/>
    <BaseButton
      :size="ButtonSize.XXL"
      :font="ButtonFont.Screener"
      :color="getButtonColor('lending')"
      :variant="ButtonVariant.Outline"
      :button-text="getButtonText('Lending')"
      @click="handleSelect('lending')"/>
    <BaseButton
      :size="ButtonSize.XXL"
      :font="ButtonFont.Screener"
      :color="getButtonColor('borrowing')"
      :variant="ButtonVariant.Outline"
      :button-text="getButtonText('Borrowing')"
      @click="handleSelect('borrowing')"/>
  </div>
</template>

<script setup lang="ts">
import useDashboard, { DashboardComponent } from '@/modules/pages/dashboard/hooks/useDashboard'
import BaseButton, { ButtonVariant, ButtonColor, ButtonSize, ButtonFont } from '@/general-components/BaseButton.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { dashboardComponentToDisplay, searchTerm, dashboardUserAddress } = useDashboard()
const { address: userAddress } = useCustomAccount()

const getButtonColor = (name: string): ButtonColor => {
  return dashboardComponentToDisplay.value === name || searchTerm.value ? ButtonColor.Primary : ButtonColor.White
}

const getButtonText = (buttonName: string): string => {
  return dashboardUserAddress.value === userAddress.value ? `Your ${buttonName}` : buttonName
}

const handleSelect = (option: string) => {
  if (option === 'lending') {
    dashboardComponentToDisplay.value = DashboardComponent.Lending
  } else if (option === 'borrowing') {
    dashboardComponentToDisplay.value = DashboardComponent.Borrowing
  } else if (option === 'assets') {
    dashboardComponentToDisplay.value = DashboardComponent.Assets
  }
}

</script>

<style scoped>
.dashboard-big-tab-buttons {
  display: flex;
  justify-content: space-between;

  .button_wrapper {
    width: 32%;
  }

  @media only screen and (max-width: 840px) {
    flex-flow: row wrap;
    gap: 0.5rem 0.5rem;

    .button_wrapper {
      width: auto;
      flex: 1;

      &:first-child {
        flex: 1 0 100%;
      }
    }
  }
}

</style>

<style>
.dashboard-big-tab-buttons {
  @media only screen and (--mobile-viewport) {
    button {
      padding: 0.5rem 1rem !important;
    }

    .button__text {
      font-size: 0.88rem;
    }
  }
}

</style>
