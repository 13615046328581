import RouteName from '@/router/RouteName'
import type { TableItem } from '@/general-components/BaseTable.vue'

export default function useEditLoanTooltip() {
  const isRowDisabled = (item: TableItem) => {
    return false
  }

  const getEditLoanRoute = (loanId: string) => {
    return {
      name: RouteName.EditLoan,
      params: { id: loanId },
    }
  }

  return {
    getEditLoanRoute,
    isRowDisabled,
  }
}
