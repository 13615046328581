<template>
  <div class="dashboard-header__content">
    <UserInfo
      :address="dashboardUserAddress!"
      :has-avatar="true"/>
    <div class="dashboard-header__options-button-container">
      <RefreshAssets
        v-if="dashboardComponentToDisplay === 'assets'"
        class="dashboard-header__refresh"/>
      <BaseButton
        class="dashboard-header__options-btn"
        :color="ButtonColor.Gray"
        :variant="ButtonVariant.Outline"
        @click="openOpenSeaProfile">
        <template #icon>
          <ExternalIconSvg
            width="20"
            height="20"
            alt="external link icon"/>
        </template>
      </BaseButton>
      <ModalShareDashboard
        class="dashboard-header__options-btn"
        :user="ensName?.name ? ensName.name : dashboardUserAddress!"/>
      <BaseButton
        v-if="isPwnSpace"
        class="dashboard-header__options-btn"
        link-target="_blank"
        :to="linkToFullDashboard"
        :variant="ButtonVariant.Outline"
        :color="ButtonColor.PrimaryDark"
        button-text="Go to full dashboard >"/>
    </div>
  </div>

  <div class="dashboard-header">
    <div
      v-if="!isStarknet"
      class="dashboard-header__search-container">
      <BaseSearch
        v-model="searchTerm"
        :class="['dashboard-header__search', { 'dashboard-header__search--selected': searchTerm }]"
        :search-placeholder="searchPlaceholder"
        has-clear-button
        @update:model-value="setSearchTerms"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BaseButton, { ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import BaseSearch from '@/general-components/BaseSearch.vue'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import useYourCoins from '@/modules/sections/your-assets/your-coins/useYourCoins'
import ModalShareDashboard from '@/modules/pages/dashboard/ModalShareDashboard.vue'
import { computed } from 'vue'
import RefreshAssets from '@/modules/pages/dashboard/RefreshAssets.vue'
import { useRoute } from 'vue-router'
import linkTo from '@/constants/links'
import ExternalIconSvg from '@/assets/icons/external.svg'
import UserInfo from '@/modules/pages/dashboard/UserInfo.vue'
import { isPwnSpace, isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

const route = useRoute()

const { dashboardComponentToDisplay, searchTerm, ensName, dashboardUserAddress } = useDashboard()

const openOpenSeaProfile = () => {
  window.open(`https://opensea.io/${dashboardUserAddress.value}`, '_blank')
}

const searchPlaceholder = computed(() => 'Search Assets, Proposals and Loans')

const setSearchTerms = () => {
  useYourNFTs().searchTerm.value = searchTerm.value
  useYourCoins().searchTerm.value = searchTerm.value
}

const linkToFullDashboard = computed(() => `${linkTo.pwnAppProduction}/#${route.fullPath}`)
</script>

<style scoped>
.dashboard-header {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 1.5rem;
  font-family: var(--font-family-autoscape);

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__ens {
    font-size: 1.875rem;
    margin-bottom: 1.5rem;
  }

  &__description-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: var(--gray-2);
  }

  &__description {
    margin-bottom: 3rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-family: var(--font-family-oxygen-mono);
  }

  &__description-text {
    max-width: 80%;
  }

  &__search-container {
    display: flex;
    align-items: stretch;
    column-gap: 0.5rem;
  }

  &__search {
    flex: 1 0;
    border: 1px solid var(--gray);
    padding: 0.5rem;
    font-family: var(--font-family-supreme);
    font-size: 1rem;
    font-weight: 400;
    align-items: center;

    &--selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__options-button-container {
    display: flex;
    height: fit-content;
    align-items: center;
  }

  &__options-btn {
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: 840px) {
    &__options-btn {
      display: none;
    }

    &__search-container {
      display: flex;
      flex-flow: column nowrap;
    }

    &__content {
      flex-flow: column-reverse nowrap;
      row-gap: 1rem;
    }

    &__refresh {
      margin-left: auto;
    }

    &__description {
      margin-bottom: 2rem;

      &-title {
        font-size: 1.2rem;
        color: var(--text-color);
      }

      &-text {
        font-size: 1rem;
        max-width: 100%;
      }
    }

    &__ens {
      font-size: 1.5rem;
    }

    &__search {
      margin-bottom: 2rem;
    }
  }
}
</style>

<style>
.dashboard-header {
  @media only screen and (--mobile-viewport) {
    &__header {
      padding-top: 0;
    }

    &__search {
      input {
        font-size: 0.9rem;
      }
    }

    &__ens {
      .user-avatar {
        width: 3rem !important;
        height: 3rem !important;
        margin-right: 1rem;
      }
    }
  }
}
</style>
