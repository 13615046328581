import { computed, ref } from 'vue'
import type { ParsedTransferViaAtrEvent } from '@/modules/common/pwn/contracts/useAtrContract'
import useAtrContract from '@/modules/common/pwn/contracts/useAtrContract'
import type { AssetMetadata, AtrTokenInfo } from '@/modules/common/assets/AssetClasses'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import useAtrGuardContract from '@/modules/common/pwn/contracts/useAtrGuardContract'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import { compareAddresses, compareAssets } from '@/utils/utils'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { TokenizeAssetSortOption, TOKENIZE_ASSET_SORT_OPTIONS_LOOKUP } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { storeToRefs } from 'pinia'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

const selectedAssetsToTokenize = ref<AssetWithAmount[]>([])
const successfullyTokenizedAssets = ref<AssetWithAmount[]>([])
const pwnSafeWhereTokenizing = ref<PwnSafe>()

const selectedSelectionSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-your-tokens',
  { id: TokenizeAssetSortOption.Name, direction: SortDirection.Descending, viewName: 'sort-option-your-tokens' },
  Object.keys(TOKENIZE_ASSET_SORT_OPTIONS_LOOKUP),
))

export default function usePwnSafeTokenize() {
  const pwnSafeDetailStore = usePwnSafeDetailStore()
  const { isSelectedCurrentWallet } = storeToRefs(pwnSafeDetailStore)

  const connectedAccountTypeStore = useConnectedAccountTypeStore()
  const { isConnectedContractWallet, isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)

  const isTokenizingMultipleAssets = computed(() => selectedAssetsToTokenize.value?.length > 1)

  const removeApprovalsIfExist = async (pwnSafe: PwnSafe, asset: AssetWithAmount): Promise<void> => {
    const existingApprovals = await useAtrGuardContract().checkApprovals({
      pwnSafeAddress: pwnSafe.safeAddress,
      assetAddress: asset.address,
      // @ts-expect-error TS(2322) FIXME: Type 'SupportedChain | null' is not assignable to ... Remove this comment to see the full error message
      chainId: pwnSafe.chainId,
    })

    if (existingApprovals?.length > 0) {
    // TODO try send as batch transaction. Gnosis safe accept one transaction request in time. The next request is ignored if pending modal with submit transaction.
      if (isConnectedContractWallet.value) {
        for (const spender of existingApprovals) {
          await useAtrGuardContract().removeApproval({ asset, spender, pwnSafeAddress: pwnSafe.safeAddress })
        }
      } else {
        await Promise.all(existingApprovals.map((spender) => (
          useAtrGuardContract().removeApproval({ asset, spender, pwnSafeAddress: pwnSafe.safeAddress })
        )))
      }
    }
  }

  const createTokenizedAsset = async (pwnSafe: PwnSafe, atrTokenAndTokenizedAsset: ParsedTransferViaAtrEvent): Promise<AssetWithAmount> => {
    // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
    const atrTokenContractAddress = CHAINS_CONSTANTS[pwnSafe.chainId].pwnSafeContracts?.assetTransferRights
    const mintedAtrToken = await useMetadataFetch().fetchNftMetadata({
      contractAddress: atrTokenContractAddress,
      tokenId: atrTokenAndTokenizedAsset.atrTokenId,
      // @ts-expect-error TS(2322) FIXME: Type 'SupportedChain | null' is not assignable to ... Remove this comment to see the full error message
      chainId: pwnSafe.chainId,
      userAddress: pwnSafe.safeAddress,
    })
    const mintedAtrTokenWithAmount = new AssetWithAmount({ ...mintedAtrToken, amount: '1' })
    mintedAtrTokenWithAmount.ownerAddress = pwnSafe.safeAddress
    return mintedAtrTokenWithAmount
  }

  const mintAtrToken = async (pwnSafe: PwnSafe, asset: AssetWithAmount, step: ToastStep): Promise<AssetWithAmount> => {
    // TODO divide this into separate action with toast
    await removeApprovalsIfExist(pwnSafe, asset)

    if (asset.amountInput === '') {
      asset.amountInput = asset.maxAvailableAmount
    }

    const parsedTxReturn = await useAtrContract().mintAtrToken(asset, pwnSafe.safeAddress, step)

    // @ts-expect-error FIXME: strictNullChecks
    const mintedAtrToken = await createTokenizedAsset(pwnSafe, parsedTxReturn)

    return mintedAtrToken
  }

  const mintAtrTokenBatchAndRefreshAssets = async (pwnSafe: PwnSafe, assets: AssetWithAmount[], step: ToastStep): Promise<AssetWithAmount[]> => {
    const uniqueAssetsByAddress = [
      ...new Map(assets.map((asset) => [asset.address.toLowerCase(), asset])).values(),
    ]
    // TODO divide this into separate action with toast
    await Promise.all(uniqueAssetsByAddress.map(asset => removeApprovalsIfExist(pwnSafe, asset)))

    const parsedTxReturn = await useAtrContract().mintAtrTokenBatch(assets, pwnSafe.safeAddress, step)
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'AssetWithAm... Remove this comment to see the full error message
    if (!parsedTxReturn) return null

    const mintedAtrTokens = await Promise.all(parsedTxReturn.map(mintedAtrToken => createTokenizedAsset(
      pwnSafe,
      mintedAtrToken,
    )))

    return mintedAtrTokens
  }

  const hasSelectedAsset = computed(() => selectedAssetsToTokenize.value.length > 0)

  const hasValidFungibleAssets = computed(() => {
    const fungibleAssets = selectedAssetsToTokenize.value.filter(asset => asset.isFungible)
    if (!fungibleAssets) return true
    // todo is this okay?
    return fungibleAssets.every(asset => asset.isAssetAmountInputValidOrEmpty)
  })

  const checkIsTokenUsedInActiveLoan = (asset: AssetWithAmount | AssetMetadata) => {
    /**
     *  TODO: FIX BEFORE RELEASE
     *  Notion task: https://www.notion.so/pwndao/Add-check-for-existence-of-the-loan-using-certain-asset-for-pwn-safe-a808e3977cae4d0f9f08a3a942dc6417?pvs=4
     */
    // const { sortedFundedBorrowing } = useYourFundedBorrowing()
    // return sortedFundedBorrowing.value.some(loan => loan.status === LoanStatus.Accepted && compareAssets({ assetA: loan.acceptedOffer.loanAsset, assetB: asset }))
    return false
  }

  const isViewingFromPwnSafe = computed(() => !isSelectedCurrentWallet.value || isConnectedPwnSafe.value)

  const checkIsTokenizeDisabled = (asset: AssetWithAmount) => {
    return asset.isTokenizeDisabled || (checkIsTokenUsedInActiveLoan(asset) && (isViewingFromPwnSafe.value))
  }

  const getLoanAssetAtrTokens = (asset: AssetMetadata, erc20s: AssetWithAmount[]): AtrTokenInfo[] => {
    const atrTokens = erc20s.find(erc20 => compareAddresses(erc20.address, asset.address))?.atrTokens
    if (!atrTokens) return []
    return atrTokens
  }

  const checkForExistingAtrTokens = (asset: AssetWithAmount | AssetMetadata, erc20s: AssetWithAmount[], nfts: AssetWithAmount[]): boolean => {
    let token: AssetWithAmount
    if (asset.isErc20) {
      // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount | undefined' is not assignab... Remove this comment to see the full error message
      token = erc20s.find(erc20 => compareAssets({ assetA: asset, assetB: erc20 }))
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount | undefined' is not assignab... Remove this comment to see the full error message
      token = nfts.find(nft => compareAssets({ assetA: asset, assetB: nft }))
    }
    return token ? token.hasAtrToken : false
  }

  const isTokenizationDisabled = computed(() => !hasSelectedAsset.value || !hasValidFungibleAssets.value)

  const sortedAndFilteredAssetsToTransfer = computed(() => {
    return TOKENIZE_ASSET_SORT_OPTIONS_LOOKUP[selectedSelectionSortOption.value.id](selectedAssetsToTokenize.value, selectedSelectionSortOption.value.direction)
  })

  return {
    selectedAssetsToTokenize,
    sortedAndFilteredAssetsToTransfer,
    selectedSelectionSortOption,
    successfullyTokenizedAssets,
    mintAtrTokenAndRefreshAssets: mintAtrToken,
    mintAtrTokenBatchAndRefreshAssets,
    hasSelectedAsset,
    hasValidFungibleAssets,
    isTokenizationDisabled,
    chainTarget: computed(() => selectedAssetsToTokenize.value[0]?.chainId),
    isTokenizingMultipleAssets,
    pwnSafeWhereTokenizing,
    checkIsTokenUsedInActiveLoan,
    checkIsTokenizeDisabled,
    getLoanAssetAtrTokens,
    checkForExistingAtrTokens,
  }
}
