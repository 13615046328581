<template>
  <PwnSafeTransferModal/>
  <div class="pwn-safe-transfer">
    <PwnSafeTransferHeader
      :selected-chain="currentSafeChain!"
      :set-selected-chain="setSelectedChain"/>
    <PwnSafeTransferAssetTables
      :atr-nfts="filteredAtrNfts"
      :non-atr-nfts="filteredNfts"
      :safe-tokens="filteredTokens"
      :is-fetching-tokens="tokensAreLoading"
      :is-fetching-nfts="nftsAreLoading"/>
    <PwnSafeTransferSelection/>
    <div class="pwn-safe-transfer__select-input-button-wrapper">
      <PwnSafeTransferSelect
        class="pwn-safe-transfer__select-to-wallet"
        :selected-chain="currentSafeChain!"/>
      <div>or</div>
      <div class="pwn-safe-transfer__input-address-wrapper">
        <BaseInput
          :model-value="addressTo"
          warning-text="Please input valid address or ENS"
          :is-valid-input="isValidAddress"
          placeholder="ENS or Wallet Address"
          @update:model-value="(address) => handleAddressInputChange(address, currentSafeChain!)"/>
      </div>
      <BaseButton
        button-text="Transfer"
        :is-disabled="isTransferDisabled"
        @on-button-click="onTransferAssetsClick"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import { computed, onMounted, ref, watch } from 'vue'
import PwnSafeTransferAssetTables from '@/modules/pages/pwn-safe/transfer/PwnSafeTransferAssetTables.vue'
import PwnSafeTransferSelection from '@/modules/pages/pwn-safe/transfer/PwnSafeTransferSelection.vue'
import PwnSafeTransferSelect from '@/modules/pages/pwn-safe/transfer/PwnSafeTransferSelect.vue'
import PwnSafeTransferHeader from '@/modules/pages/pwn-safe/transfer/PwnSafeTransferHeader.vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import usePwnSafeTransferAssetsTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransferAssetsTransfer'
import BaseButton from '@/general-components/BaseButton.vue'
import BaseInput from '@/general-components/BaseInput.vue'
import usePwnSafeTransferModal, { TransferModalStep } from '@/modules/pages/pwn-safe/transfer/modal/usePwnSafeTransferModal'
import PwnSafeTransferModal from '@/modules/pages/pwn-safe/transfer/modal/PwnSafeTransferModal.vue'
import type { SupportedChain } from '@/constants/chains/types'
import { useSafeAssetsDetail } from '@/modules/queryHooks/safe/useSafeAssetsDetail'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import { compareAddresses } from '@/utils/utils'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { storeToRefs } from 'pinia'

const router = useRouter()

const { fromWallet, isTransferFromPwnSafe, toWallet, displayCoins, displayNfts, addressTo, isValidAddress, handleAddressInputChange, chainTarget } = usePwnSafeTransfer()
const { isTransferDisabled } = usePwnSafeTransferAssetsTransfer()
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { pwnSafes, walletAsSafe, selectedPwnSafe, ownerAddressAsSafe } = storeToRefs(pwnSafeDetailStore)

const { address: userAddress } = useCustomAccount()
const { isOpen, activeStep } = usePwnSafeTransferModal()
const connectedAccountTypeStore = useConnectedAccountTypeStore()
const { isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)

// @ts-expect-error FIXME: strictNullChecks
const selectedChain = ref<SupportedChain>(chainTarget.value || selectedPwnSafe.value.chainId)

const pwnSafe = computed(() => {
  // @ts-expect-error FIXME: strictNullChecks
  if (compareAddresses(selectedPwnSafe.value.safeAddress, userAddress.value)) {
    return new PwnSafe({
      ...selectedPwnSafe.value,
      chainId: selectedChain.value,
    })
  } else {
    return selectedPwnSafe.value
  }
})

const currentSafeChain = computed(() => {
  if (chainTarget.value) return chainTarget.value
  // @ts-expect-error FIXME: strictNullChecks
  if (!chainTarget.value && compareAddresses(selectedPwnSafe.value.safeAddress, userAddress.value)) {
    return selectedChain.value
  }

  // @ts-expect-error FIXME: strictNullChecks
  return pwnSafe.value.chainId
})

// @ts-expect-error FIXME: strictNullChecks
const { nonAtrNfts, atrNfts, safeTokens, nftsAreLoading, tokensAreLoading } = useSafeAssetsDetail(pwnSafe)

const filteredNfts = computed(() => {
  if (!displayCoins.value && !displayNfts.value) return nonAtrNfts.value
  return displayNfts.value ? nonAtrNfts.value : []
})

const filteredAtrNfts = computed(() => {
  if (!displayCoins.value && !displayNfts.value) return atrNfts.value
  return displayNfts.value ? atrNfts.value : []
})

const filteredTokens = computed(() => {
  if (!displayCoins.value && !displayNfts.value) return safeTokens.value
  return displayCoins.value ? safeTokens.value : []
})

const setSelectedChain = (chain: SetChainFilterOption) => {
  if (chain === 'all' || chainTarget.value) return
  selectedChain.value = chain
}

const onTransferAssetsClick = () => {
  isOpen.value = true
  activeStep.value = TransferModalStep.Transferring
}

// Needed in case of refresh (same as PwnSafeDetail)
onMounted(async () => {
  fromWallet.value = selectedPwnSafe.value

  const selectedTransferOptionIfConnectedEoa = isTransferFromPwnSafe.value ? walletAsSafe.value : Object.values(pwnSafes)[0]
  toWallet.value = isConnectedPwnSafe.value ? ownerAddressAsSafe.value : selectedTransferOptionIfConnectedEoa

  displayCoins.value = false
  displayNfts.value = false
})

watch(userAddress, () => {
  router.push({ name: RouteName.PwnSafe })
})
</script>

<style scoped>
.pwn-safe-transfer {
  padding-bottom: 5rem;

  &__select-input-button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2rem;
  }

  &__select-to-wallet,
  &__input-address-wrapper {
    flex: 1 1 0;
  }

  @media only screen and (--mobile-viewport) {
    &__select-input-button-wrapper {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
</style>
