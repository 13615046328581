import StoreIds from '@/constants/storeIds'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { AssetPriceLog } from '@/revamp/modules/asset-prices/types'

export const useAssetsWithSmallBalancesStore = defineStore(StoreIds.assetsWithSmallBalances, () => {
  const assetsWithSmallBalances = ref<{ [key: string]: true }>({})

  const addAsset = (asset: AssetWithAmount, assetPriceLog: AssetPriceLog) => {
    if (assetPriceLog.currency === 'ETH' && assetPriceLog.assetAmountInCurrency < 0.002 && asset.id > 0) {
      assetsWithSmallBalances.value[String(asset.id)] = true
    }
    if (assetPriceLog.currency === 'USD' && assetPriceLog.assetAmountInCurrency < 5 && asset.id > 0) {
      assetsWithSmallBalances.value[String(asset.id)] = true
    }
  }

  const cleanAssets = () => {
    assetsWithSmallBalances.value = {}
  }

  return {
    assetsWithSmallBalances: computed(() => assetsWithSmallBalances.value),
    addAsset,
    cleanAssets,
  }
})
