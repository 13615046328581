import { ref } from 'vue'
import ReconnectingEventSource from 'reconnecting-eventsource'
import EndpointUrls from '@/modules/common/backend/endpointUrls'
import useNotifications from '@/modules/common/notifications/useNotifications'
import type { Stringified } from '@/modules/common/typings/customTypes'
import type {
  ReceivedNotificationSseEvent,
  ReceivedUpdateSseEvent,
  ReceivedSseEventDataPropTypes,
} from '@/modules/common/backend/sseListener/sseListenerTypes'
import {
  ReceivedSseEvent,
} from '@/modules/common/backend/sseListener/sseListenerTypes'
import Notification from '@/modules/common/notifications/Notification'
import { globalConstants } from '@/constants/globals'
import type { Address } from 'viem'
import { TOAST_ACTION_IDS } from '@/modules/common/notifications/useToastsStore'
import type { ProposalAndLoanListSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import {
  getLoanDetailQueryKey,
  getProposalAndLoanListQueryKey,
  getProposalDetailQueryKey,
} from '@/modules/common/backend/generated'
import type { QueryClient } from '@tanstack/vue-query'
import { getLoanTokenAddressForProposalOrLoan, isLoan } from '@/modules/common/pwn/utils'
const { amountOfUnseenNotifications } = useNotifications()

const eventListener = ref<ReconnectingEventSource>()

export default function useSseListener(queryClient: QueryClient) {
  const parseEventData = <T extends ReceivedSseEventDataPropTypes>(eventData: Stringified<T>): T => JSON.parse(eventData)

  const handleNotification = (event: ReceivedNotificationSseEvent) => {
    const notificationJson = parseEventData(event.data)
    const parsedNotification = Notification.createFromBackendResponse(notificationJson)

    if (globalConstants.environment === 'development') {
      /* eslint-disable no-console */
      console.groupCollapsed('Received SSE Notification Event:')
      console.log('Raw event:')
      console.log(event)
      console.log('Parsed event data:')
      console.log(notificationJson)
      console.log('Parsed notification:')
      console.log(parsedNotification)
      console.groupEnd()
      /* eslint-enable no-console */
    }

    if (parsedNotification) {
      const { pushNotificationToStart } = useNotifications()
      amountOfUnseenNotifications.value += 1
      pushNotificationToStart(parsedNotification)

      if (!TOAST_ACTION_IDS.some(toastAction => toastAction === parsedNotification.action)) {
        // notify(parsedNotification)
      }
    }

    if (parsedNotification?.proposalId) {
      queryClient.invalidateQueries({
        queryKey: getProposalDetailQueryKey(+parsedNotification.proposalId),
        refetchType: 'all',
      })
    }
  }

  const handleUpdateProposalTable = async (eventData: ProposalAndLoanListSchemaWorkaroundBackendSchema) => {
    const promises: Promise<any>[] = []

    const isLoanEvent = isLoan(eventData.type)

    if (isLoanEvent && eventData.onChainId) {
      const loanTokenContractAddress = getLoanTokenAddressForProposalOrLoan(eventData.chainId, eventData.type) || '0x missing loan token address'
      const queryKeyLoanDetail = getLoanDetailQueryKey(String(eventData.chainId), loanTokenContractAddress, String(eventData.onChainId))
      promises.push(queryClient.invalidateQueries({
        queryKey: queryKeyLoanDetail,
        refetchType: 'all',
      }))
    }

    await Promise.all([
      ...promises,
      queryClient.invalidateQueries({
        queryKey: getProposalAndLoanListQueryKey(),
        refetchType: 'all',
      }),
    ])
  }

  const handleUpdate = (event: ReceivedUpdateSseEvent) => {
    const eventData: any = parseEventData(event.data)

    if (globalConstants.environment === 'development') {
      /* eslint-disable no-console */
      console.groupCollapsed('Received SSE Update Event:')
      console.log('Raw event:')
      console.log(event)
      console.log('Parsed event data:')
      console.log(eventData)
      console.groupEnd()
      /* eslint-enable no-console */
    }

    // TODO is ok call this for all events?
    handleUpdateProposalTable(eventData)
  }

  const setupSseListener = (userAddress?: Address) => {
    if (eventListener.value instanceof ReconnectingEventSource) {
      // eslint-disable-next-line no-console
      console.debug('Closed previous SSE connection.')
      eventListener.value.close() // clear previous connection
    }

    const listenerEndpoint = userAddress ? EndpointUrls.value.SSE.Listener(userAddress) : EndpointUrls.value.SSE.BroadcastListener
    eventListener.value = new ReconnectingEventSource(listenerEndpoint, { max_retry_time: 30000 })

    eventListener.value.addEventListener(ReceivedSseEvent.Notification, (event) => {
      handleNotification(event as ReceivedNotificationSseEvent)
    })

    eventListener.value.addEventListener(
      ReceivedSseEvent.Update,
      (event) => handleUpdate(event as ReceivedUpdateSseEvent),
    )

    if (userAddress) {
      // eslint-disable-next-line no-console
      console.debug(`Set up new SSE listener on broadcast channel + channel for user address ${userAddress}.`)
    } else {
      // eslint-disable-next-line no-console
      console.debug('Set up new SSE listener on broadcast channel.')
    }
  }

  return {
    setupSseListener,
  }
}
