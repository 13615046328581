/* eslint-disable camelcase */
import type { V1SimpleLoanSimpleOffer } from '@/modules/common/pwn/offers/OfferClasses'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import DateUtils from '@/utils/DateUtils'
import BaseV1SimpleLoanOfferContract from '@/modules/common/pwn/contracts/v1/BaseV1SimpleLoanOfferContract'
import type { V1SimpleLoan } from '@/modules/common/pwn/loans/LoanClasses'
import fixSignatureIfInvalid from '@/utils/fixSignatureIfInvalid'
import { ASSET_TYPES_WITHOUT_AMOUNT } from '@/modules/common/assets/AssetType'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
// import { useNotificationReminderModalStore } from '@/general-components/notification-reminder-modal/useNotificationReminderModalStore'
import { getAddress, zeroAddress } from 'viem'
import type { TransactionReceipt, Hex } from 'viem'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { pwnV1SimpleLoanAbi, pwnV1SimpleLoanSimpleOfferAbi, readPwnV1SimpleLoanSimpleOfferEncodeLoanTermsFactoryData } from '@/contracts/generated'
import type { V1SimpleOfferStruct } from '@/contracts/structs'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export default class V1SimpleLoanSimpleOfferContract extends BaseV1SimpleLoanOfferContract<V1SimpleLoanSimpleOffer> {
  public async acceptOffer(offer: V1SimpleLoanSimpleOffer, loan?: V1SimpleLoan, step?: ToastStep): Promise<TransactionReceipt> {
    const validatedOfferSignature = fixSignatureIfInvalid(offer.offerSignature)

    const collateralAmountRaw = loan?.collateral?.amount ? loan.collateral.amountRaw : offer?.collateral?.amountRaw
    if (!collateralAmountRaw) {
      throw new Error('Collateral amount is required')
    }
    const offerEncodedData = await offer.encodeOfferData(offer.loanAmountRaw, collateralAmountRaw)
    const receipt = await sendTransaction({
      abi: pwnV1SimpleLoanAbi,
      functionName: 'createLOAN',
      address: CHAINS_CONSTANTS[offer.chainId].pwnV1Contracts.pwnSimpleLoan,
      args: [
        offer.contractAddress,
        offerEncodedData,
        validatedOfferSignature,
        '0x',
        '0x',
      ],
      chainId: offer.chainId,
    }, { step })

    // if (receipt) {
    //   useNotificationReminderModalStore().checkIfShowNotificationReminderModal(loan)
    // }
    return receipt
  }

  public async revokeOffer({ loanAsset, nonce, contractAddress: offerContractAddress }: V1SimpleLoanSimpleOffer, step: ToastStep): Promise<TransactionReceipt> {
    return await sendTransaction({
      abi: pwnV1SimpleLoanSimpleOfferAbi,
      address: offerContractAddress,
      args: [nonce],
      chainId: loanAsset.chainId,
      functionName: 'revokeOfferNonce',
    }, { step })
  }

  public createOfferStruct(offer: V1SimpleLoanSimpleOffer, loanAmountRaw: bigint, collateralAmountRaw: bigint): V1SimpleOfferStruct {
    const collateral = offer.loan ? offer.loan.collateral : offer.collateral!
    // TODO remove?
    if (ASSET_TYPES_WITHOUT_AMOUNT.includes(collateral.category)) {
      collateralAmountRaw = 0n
    }

    return {
      collateralCategory: collateral.category,
      collateralAddress: getAddress(collateral.address),
      collateralId: offer.collateral?.tokenId ? BigInt(offer.collateral.tokenId) : 0n,
      collateralAmount: collateralAmountRaw,
      loanAssetAddress: offer.loanAssetAddress,
      loanAmount: loanAmountRaw,
      loanYield: offer.fixedInterestAmount ?? 0n,
      duration: DateUtils.convertDaysToSeconds(offer.loanDurationDays),
      expiration: offer.expiration,
      borrower: zeroAddress,
      lender: offer.lender,
      isPersistent: false, // change this once we have persistent offer option in UI
      nonce: offer.nonce,
    } as const
  }

  public async encodeOfferData(offer: V1SimpleLoanSimpleOffer, loanAmountRaw: bigint, collateralAmountRaw: bigint): Promise<Hex> {
    const offerStruct = this.createOfferStruct(offer, loanAmountRaw, collateralAmountRaw)
    return await readPwnV1SimpleLoanSimpleOfferEncodeLoanTermsFactoryData(pwnWagmiConfig, {
      address: offer.contractAddress,
      chainId: offer.chainId,
      args: [offerStruct],
    })
  }
}
