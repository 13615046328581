<template>
  <BaseModal
    v-if="isOpen"
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    :heading-icon="claimIcon.default"
    :is-closable="!isWaitingForTxConfirmation"
    heading-align="left"
    :heading="actionName">
    <template #body>
      <component :is="activeStepComponent"/>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import ModalSize from '@/general-components/ModalSize'
import usePwnSafeClaimAndBurnModal from '@/modules/pages/pwn-safe/claim-and-burn/modal/usePwnSafeClaimAndBurnModal'
import * as claimIcon from '@/assets/icons/claim.svg'
import usePwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'

const { isOpen, activeStepComponent, isWaitingForTxConfirmation } = usePwnSafeClaimAndBurnModal()
const { actionName } = usePwnSafeClaimAndBurn()
</script>
