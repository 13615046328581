<template>
  <div :class="`asset-stat-info asset-stat-info--${size}`">
    <div class="asset-stat-info__top-row">
      <component
        :is="iconBeforeLabel"
        v-if="iconBeforeLabel"
        width="20"
        height="20"
        :alt="label"/>
      <span :class="`asset-stat-info__label asset-stat-info__label--${size}`"> {{ label }} </span>
      <slot name="base-tooltip">
        <QuestionMarkTooltip
          v-if="tooltipText"
          is-interactive
          :max-width="tooltipMaxWidth">
          <slot name="tooltip">
            <div class="asset-stat-info__tooltip">
              {{ tooltipText }}
            </div>
          </slot>
        </QuestionMarkTooltip>
      </slot>
    </div>
    <div :class="`asset-stat-info__bottom-row asset-stat-info__bottom-row--${size}`">
      <slot>
        <BaseSkeletor
          v-if="isLoading"
          height="30px"
          width="200px"/>
        <div v-else-if="bottomRowText">
          {{ bottomRowText }}
        </div>
        <TokenAndEthAndUsdPriceLabel
          v-else-if="coin"
          :token="coin"/>
        <EthAndUsdPriceLabel
          v-else-if="amountInEthAndUsd"
          :amount="amountInEthAndUsd"
          :asset="coin"
          :eth-icon-width-px="ASSET_STAT_INFO_ETH_ICON_WIDTH_PX"
          :data-source-icon-width-px="ASSET_STAT_INFO_DATA_SOURCE_ICON_WIDTH_PX"
          :price-source="priceSource"/>
        <div v-else>
          No Data
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import type { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import TokenAndEthAndUsdPriceLabel from '@/general-components/TokenAndEthAndUsdPriceLabel.vue'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import type { Component } from 'vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import type { ValuationSource } from '@/general-components/data-source/DataSourceType'

interface Props {
  label: string
  tooltipText?: string
  iconBeforeLabel?: Component
  tooltipMaxWidth?: number
  coin?: AssetWithAmount
  amountInEthAndUsd?: AmountInEthAndUsd | null
  priceSource?: ValuationSource
  isLoading?: boolean
  bottomRowText?: string | null
  size?: 'small' | 'medium' | 'large'
}
withDefaults(defineProps<Props>(), {
  tooltipMaxWidth: 320,
  size: 'medium',
})
</script>

<script lang="ts">
export const ASSET_STAT_INFO_DATA_SOURCE_ICON_WIDTH_PX = '25px'
export const ASSET_STAT_INFO_ETH_ICON_WIDTH_PX = '15px'
</script>

<style scoped>
.asset-stat-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--small {
    height: 3.625rem;
  }

  &--medium {
    height: 4.375rem;
  }

  &__top-row,
  &__bottom-row {
    display: flex;
    gap: 0.5rem;
  }

  &__top-row {
    align-items: center;
  }

  &__bottom-row {
    align-items: baseline;
    font-family: var(--font-family-oxygen-mono);

    &--small {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }

    &--medium {
      font-size: 1.5rem;
      line-height: 2.375rem;
    }
  }

  &__label {
    font-family: var(--font-family-screener);
    font-weight: 400;

    &--small {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &--medium {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }

  &__tooltip {
    text-align: center;
  }

  @media only screen and (--mobile-viewport) {
    &__bottom-row {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    &__label {
      &--small {
        font-size: 0.675rem;
      }
    }
  }
}
</style>
