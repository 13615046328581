import { computed, readonly, ref } from 'vue'
import { NFTOrder } from '@/modules/common/assets/typings/NFTOrder'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import { fetchCollectionOffers, fetchNftOffers } from '@/modules/common/backend/generated'
import to from '@/utils/await-to-js'
import { sortNftOrdersByUsdValue } from '@/general-components/sorting/SortFunctions'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { isChainWithLooksRareExplorer } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'

const offers = ref<NFTOrder[]>([])
const isFetchingOffers = ref(false)

const sortedOffers = computed(() => {
  if (!offers.value?.length) {
    return []
  }

  return sortNftOrdersByUsdValue(offers.value, SortDirection.Descending)
})

export default function useSectionOffers() {
  const clearOffers = (): void => {
    offers.value = []
  }

  const loadAllCollectionOffersFromMarketplace = async (marketplace: DataSourceType.LOOKSRARE | DataSourceType.OPENSEA, asset: AssetWithAmount | AssetMetadata): Promise<void> => {
    let next: string | undefined
    do {
      const [error, response] = await to(fetchCollectionOffers(
        String(asset.chainId),
        asset.address,
        {
          marketplace,
          ...(next && { page: next }),
        }),
      )
      if (error || !response?.data?.orders?.length) {
        return
      }
      next = response.data.next ?? undefined
      for (const order of response.data.orders) {
        const parsedOrder = NFTOrder.createFromBackendResponse(order)
        if (parsedOrder) {
          if (asset instanceof AssetWithAmount) {
            parsedOrder.asset = asset
          }
          offers.value.push(parsedOrder)
        }
      }
    } while (next)
  }

  const loadAllNftOffersFromMarketplace = async (marketplace: DataSourceType.LOOKSRARE | DataSourceType.OPENSEA, asset: AssetWithAmount | AssetMetadata): Promise<void> => {
    let next: string | undefined
    do {
      const [error, response] = await to(fetchNftOffers(
        String(asset.chainId),
        asset.address,
        String(asset.tokenId),
        {
          marketplace,
          ...(next && { page: next }),
        }))
      if (error || !response?.data?.orders?.length) {
        return
      }
      next = response?.data?.next ?? undefined
      for (const order of response.data.orders) {
        const parsedOrder = NFTOrder.createFromBackendResponse(order)
        if (parsedOrder) {
          if (asset instanceof AssetWithAmount) {
            parsedOrder.asset = asset
          }
          offers.value.push(parsedOrder)
        }
      }
    } while (next)
  }

  const loadAllOffers = async (asset: AssetMetadata | AssetWithAmount | null): Promise<void> => {
    if (!asset) return
    isFetchingOffers.value = true

    if (asset.isTokenBundle && asset.bundleAssets?.length) {
      for (const bundleAsset of asset.bundleAssets) {
        // doing this in sync way to avoid rate limits from marketplace APIs when fetching offers for bundle with a lot of assets
        // TODO is this okay? shall we do it in sync or async?
        await loadAllOffers(bundleAsset)
      }
      return
    }

    const assetToFetchOffersFor = asset.isAtrToken ? asset.assetOfAtrToken : asset

    let marketplacesToFetch: [DataSourceType.OPENSEA] | [DataSourceType.OPENSEA, DataSourceType.LOOKSRARE]
    if (isChainWithLooksRareExplorer(CHAINS_CONSTANTS[asset.chainId])) {
      marketplacesToFetch = [DataSourceType.OPENSEA, DataSourceType.LOOKSRARE]
    } else {
      marketplacesToFetch = [DataSourceType.OPENSEA]
    }

    const fetchAllNftOffersPromises = marketplacesToFetch.map(marketplace => loadAllNftOffersFromMarketplace(
      marketplace,
      assetToFetchOffersFor,
    ))
    const fetchAllCollectionOffersPromises = marketplacesToFetch.map(marketplace => loadAllCollectionOffersFromMarketplace(
      marketplace,
      assetToFetchOffersFor,
    ))
    await to(
      Promise.allSettled([...fetchAllNftOffersPromises, ...fetchAllCollectionOffersPromises]),
      { additionalErrorInfo: { chainIdToFetch: assetToFetchOffersFor.chainId, contractAddressToFetch: assetToFetchOffersFor.address, tokenIdToFetch: assetToFetchOffersFor.tokenId } },
    )
    isFetchingOffers.value = false
  }

  const highestPurchaseOffer = computed(() => {
    const firstOffer = sortedOffers.value?.at(0)
    if (firstOffer) {
      const ethAmount = firstOffer.orderAsset.appraisal.price.ethAmount
      const usdAmount = firstOffer.orderAsset.appraisal.price.usdAmount
      return new AmountInEthAndUsd(ethAmount, usdAmount)
    }
    return null
  })

  const highestPurchaseOfferMarket = computed(() => {
    const firstOffer = sortedOffers.value?.at(0)
    if (firstOffer) {
      return firstOffer.marketplace
    }
    return null
  })

  return {
    offers: computed(() => offers.value),
    sortedOffers,
    highestPurchaseOffer,
    highestPurchaseOfferMarket,
    loadAllOffers,
    isFetchingOffers: readonly(isFetchingOffers),
    clearOffers,
  }
}
