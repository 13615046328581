<template>
  <div class="pwn-safe-transfer-modal-input-step__container">
    <p>
      You are about to transfer the following asset to a different PWN Safe or wallet:
    </p>
    <div class="pwn-safe-transfer-modal-input-step__asset-cards-container">
      <SelectedAssetCard
        :asset="assetToTransfer"
        :display-amount="false"/>
      <div
        class="pwn-safe-transfer-modal-input-step__add-more-assets"
        @click="handleClickTransferMoreAssets">
        Add More Assets +
      </div>
    </div>
    <p>
      Please specify where you want to move the asset to:
    </p>
    <AddressInfo
      class="pwn-safe-transfer-modal-input-step__address-info"
      :name="fromAddressName!"
      :address="fromWallet!.safeAddress"
      :icon="fromAddressIcon"/>
    <div class="pwn-safe-transfer-modal-input-step__from-to-arrows">
      <svg
        width="2"
        height="29"
        viewBox="0 0 2 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 0L0.999999 29"
          stroke="#00FFE0"
          stroke-width="2"
          stroke-dasharray="5 5"/>
      </svg>
      <div>To</div>
      <svg
        width="16"
        height="30"
        viewBox="0 0 16 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.29289 29.7071C7.68342 30.0976 8.31658 30.0976 8.70711 29.7071L15.0711 23.3431C15.4616 22.9526 15.4616 22.3195 15.0711 21.9289C14.6805 21.5384 14.0474 21.5384 13.6569 21.9289L8 27.5858L2.34314 21.9289C1.95262 21.5384 1.31946 21.5384 0.928931 21.9289C0.538407 22.3195 0.538407 22.9526 0.928931 23.3431L7.29289 29.7071ZM7 -4.37114e-08L7 2.41667L9 2.41667L9 4.37114e-08L7 -4.37114e-08ZM7 7.25L7 12.0833L9 12.0833L9 7.25L7 7.25ZM7 16.9167L7 21.75L9 21.75L9 16.9167L7 16.9167ZM7 26.5833L7 29L9 29L9 26.5833L7 26.5833Z"
          fill="#00FFE0"/>
      </svg>
    </div>
    <div class="pwn-safe-transfer-modal-input-step__inputs">
      <PwnSafeTransferSelect
        :selected-chain="assetsToTransfer?.[0]?.chainId"
        class="pwn-safe-transfer-modal-input-step__address-select"/>
      <div>or</div>
      <div class="pwn-safe-transfer-modal-input-step__address-input">
        <BaseInput
          :model-value="addressTo"
          :is-valid-input="isValidAddress"
          warning-text="Please input valid address or ENS"
          placeholder="ENS or Wallet Address"
          @update:model-value="(address) => handleAddressInputChange(address, assetsToTransfer?.[0]?.chainId)"/>
      </div>
    </div>
    <div class="pwn-safe-transfer-modal-input-step__buttons-container">
      <BaseButton
        button-text="cancel"
        :color="ButtonColor.White"
        :variant="ButtonVariant.Underline"
        @on-button-click="closeModal"/>
      <BaseButton
        button-text="Transfer"
        :is-disabled="isTransferDisabled"
        @on-button-click="handleTransferClick"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import usePwnSafeTransferModal, { TransferModalStep } from '@/modules/pages/pwn-safe/transfer/modal/usePwnSafeTransferModal'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import AddressInfo from '@/general-components/AddressInfo.vue'
import { computed, nextTick } from 'vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import * as walletIcon from '@/assets/icons/wallet.svg'
import * as safeIcon from '@/assets/icons/safe.svg'
import PwnSafeTransferSelect from '@/modules/pages/pwn-safe/transfer/PwnSafeTransferSelect.vue'
import BaseInput from '@/general-components/BaseInput.vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import SelectedAssetCard from '@/general-components/SelectedAssetCard.vue'
import usePwnSafeTransferAssetsTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransferAssetsTransfer'
import RouteName from '@/router/RouteName'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

const router = useRouter()
const { closeModal, activeStep } = usePwnSafeTransferModal()
const { isTransferDisabled } = usePwnSafeTransferAssetsTransfer()
const { isTransferFromPwnSafe, fromWallet, addressTo, isValidAddress, handleAddressInputChange, assetsToTransfer, toWallet } = usePwnSafeTransfer()

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const fromAddressName = computed(() => {
  return isTransferFromPwnSafe.value ? selectedPwnSafe.value?.name : 'Connected Wallet'
})

const fromAddressIcon = computed(() => {
  return isTransferFromPwnSafe.value ? safeIcon.default : walletIcon.default
})

const assetToTransfer = computed(() => assetsToTransfer.value?.[0])

const handleTransferClick = () => {
  activeStep.value = TransferModalStep.Transferring
}

const handleClickTransferMoreAssets = async () => {
  const assetToTransferCopy = assetToTransfer.value
  const fromWalletCopy = fromWallet.value
  const toWalletCopy = toWallet.value
  const addressToCopy = addressTo.value

  await router.push({ name: RouteName.PwnSafeTransfer })
  closeModal()
  // so changes are reflected in the nextTick
  await nextTick()
  // we are closing the modal when rerouting to transfer multiple page
  // due to the watcher in PwnSafeTransferModal that resets the selected assets
  // to transfer when the modal is closed, we need to manually reassign here after closing
  assetsToTransfer.value = [assetToTransferCopy]
  fromWallet.value = fromWalletCopy
  toWallet.value = toWalletCopy
  addressTo.value = addressToCopy
}
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.pwn-safe-transfer-modal-input-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__from-to-arrows {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
  }

  &__inputs {
    display: flex;
    column-gap: 2rem;
    align-items: center;
  }

  &__address-input,
  &__address-select {
    flex: 1 1 0;
  }

  &__asset-cards-container {
    display: flex;
    column-gap: 1rem;
  }

  &__add-more-assets {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    border: 1px solid var(--primary-color-1);
    color: var(--primary-color-1);

    transition: all var(--primary-transition-time);

    &:hover {
      background-color: var(--primary-color-3);
      cursor: pointer;
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (--mobile-viewport) {
    &__container {
      row-gap: 1rem;
    }

    &__inputs {
      flex-direction: column;
      row-gap: 1rem;
    }

    &__address-input,
    &__address-select {
      width: 80%;
    }

    &__asset-cards-container {
      flex-direction: column;
      gap: 1rem;
    }

    &__address-info {
      font-size: 1.2rem;
    }
  }
}
</style>
