import type { BaseSearchResult } from '@/modules/common/pwn/explorer/models/index'
import type { SupportedChain } from '@/constants/chains/types'
import { parseChainIdFromResponse } from '@/modules/common/backend/backendUtils'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { RouteLocationRaw } from 'vue-router'
import { getAddress } from 'viem'
import type { Address } from 'viem'
import TokenPriceStats from '@/modules/common/assets/typings/TokenPriceStats'
import { AssetPrice } from '@/modules/common/assets/typings/AssetPriceClasses'
import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import type { ChainIdEnumBackendSchema, ERC20AssetDocumentBackendSchema, TopTokenModelBackendSchema } from '@/modules/common/backend/generated'
import type { PartialWithRequired } from '@/modules/common/typings/customTypes'

export class ERC20AssetSearchResult implements BaseSearchResult {
  chainId: SupportedChain
  contractAddress: Address
  name: string | undefined
  image: string | undefined
  isVerified: boolean
  stats?: TokenPriceStats

  constructor(erc20Asset: PartialWithRequired<ERC20AssetSearchResult, 'chainId' | 'contractAddress'>) {
    this.chainId = erc20Asset?.chainId
    this.image = erc20Asset?.image
    this.isVerified = erc20Asset?.isVerified ?? false
    this.contractAddress = getAddress(erc20Asset?.contractAddress)
    this.name = erc20Asset?.name
    this.isVerified = erc20Asset?.isVerified ?? false
    this.stats = erc20Asset?.stats
  }

  public static createFromBackendModel(erc20AssetDocument: ERC20AssetDocumentBackendSchema): ERC20AssetSearchResult {
    return new ERC20AssetSearchResult({
      ...erc20AssetDocument,
      chainId: parseChainIdFromResponse(erc20AssetDocument?.contract?.chain_id),
      contractAddress: erc20AssetDocument.contract.address as Address,
      name: erc20AssetDocument?.name,
      image: erc20AssetDocument?.thumbnail_url,
      isVerified: erc20AssetDocument?.is_verified ?? false,
    })
  }

  public static createFromBackendModelDexGuru(customTopTokenModel: TopTokenModelBackendSchema): ERC20AssetSearchResult {
    const stats = new TokenPriceStats({
      latestPrice: customTopTokenModel.asset_price
        ? new AssetPrice({
          price: AmountInEthAndUsd.createFromBackendModel({
            eth_amount: String(customTopTokenModel.asset_price.eth_amount),
            usd_amount: String(customTopTokenModel.asset_price.usd_amount),
          }),
        })
        : undefined,
      volume24h: AmountInEthAndUsd.createFromBackendModel({
        eth_amount: String(customTopTokenModel.volume_24h),
        usd_amount: String(customTopTokenModel.volume_24h_usd),
      }),
      marketCap: customTopTokenModel.market_cap && String(customTopTokenModel.market_cap?.usd_amount) !== '0'
        ? AmountInEthAndUsd.createFromBackendModel({
          eth_amount: String(customTopTokenModel.market_cap?.eth_amount),
          usd_amount: String(customTopTokenModel.market_cap?.usd_amount),
        })
        : undefined,
    },
    )
    return new ERC20AssetSearchResult({
      chainId: parseChainIdFromResponse(customTopTokenModel.chain_id as ChainIdEnumBackendSchema),
      contractAddress: customTopTokenModel.address as Address,
      name: customTopTokenModel.name ?? undefined,
      image: customTopTokenModel.thumbnail_url ?? undefined,
      isVerified: true,
      stats,
    })
  }

  pageRoute(): RouteLocationRaw {
    return {
      name: RouteName.TokenPage,
      params: {
        contractAddress: this.contractAddress,
        chainName: CHAINS_CONSTANTS[this.chainId].general.chainName,
      },
    }
  }

  get key(): string {
    return `${this.chainId}-${this.contractAddress}`
  }

  get title() {
    return this.name
  }
}
