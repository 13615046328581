import type { NotificationBackendSchema } from '@/modules/common/backend/generated'
import { NotificationActionEnumBackendSchema, NotificationTypeEnumBackendSchema } from '@/modules/common/backend/generated'
import { TYPE } from 'vue-toastification'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import type { RouteLocationRaw } from 'vue-router'
import DateUtils from '@/utils/DateUtils'
import RouteName from '@/router/RouteName'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import type { SupportedChain } from '@/constants/chains/types'
import type { VNode } from 'vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import { getChainName, isChainSupported } from '@/utils/chain'
import type { PartialWithRequired } from '@/modules/common/typings/customTypes'
import { getAddress, isAddress } from 'viem'
import type { Address } from 'viem'

export const ACTION_NOTIFICATION_TO_TEXT_MAPPING = {
  [NotificationTypeEnumBackendSchema.success]: {
    [NotificationActionEnumBackendSchema.PROPOSAL_CREATED]: 'Proposal Created!',
    [NotificationActionEnumBackendSchema.PROPOSAL_REVOKED]: 'Proposal Revoked!',
    [NotificationActionEnumBackendSchema.LOAN_CREATED]: 'Loan Funded!',
    [NotificationActionEnumBackendSchema.LOAN_PAID_BACK]: 'Loan Repaid!',
    [NotificationActionEnumBackendSchema.LOAN_PAYMENT_CLAIMED]: 'Payment Claimed!',
    [NotificationActionEnumBackendSchema.LOAN_COLLATERAL_CLAIMED]: 'Collateral Claimed!',
    [NotificationActionEnumBackendSchema.LOAN_EXTENDED]: 'Loan expiration has been extended!',
    [NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUEST_CANCELLED]: 'Cancelled Loan Extension Proposal',
    [NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUEST_IGNORED]: 'Lender rejected a loan extension request!',
    [NotificationActionEnumBackendSchema.NEW_COUNTER_PROPOSAL]: 'New Counter Proposal',
    [NotificationFrontendOnlyActionEnum.TOKEN_ADDED_TO_METAMASK]: 'Token added to Metamask',
    [NotificationFrontendOnlyActionEnum.TX_CREATE_PWN_SAFE]: 'PWN Safe has been created!',
    [NotificationFrontendOnlyActionEnum.TX_UPDATE_PWN_SAFE]: 'PWN Safe name updated!',
    [NotificationFrontendOnlyActionEnum.TX_CREATE_BUNDLE]: 'Bundle Created!',
    [NotificationFrontendOnlyActionEnum.TX_UNWRAP_BUNDLE]: 'Bundle Unpacked!',
    [NotificationFrontendOnlyActionEnum.TX_MINT_ATR_TOKEN]: 'ATR Token Created!',
    [NotificationFrontendOnlyActionEnum.TX_BURN_ATR_TOKEN]: 'ATR Token Burnt!',
    [NotificationFrontendOnlyActionEnum.TX_CLAIM_ATR_TOKEN]: 'ATR Token Claimed',
    [NotificationFrontendOnlyActionEnum.TX_TRANSFER_ASSET]: 'Asset Transferred!',
    [NotificationFrontendOnlyActionEnum.TX_REMOVE_APPROVAL]: 'Existing Approval Removed!',
    [NotificationFrontendOnlyActionEnum.TX_WRAP_NATIVE_TOKEN]: 'Wrapped native token!',
    [NotificationFrontendOnlyActionEnum.TX_UNWRAP_NATIVE_TOKEN]: 'Unwrapped to native token!',
    [NotificationFrontendOnlyActionEnum.TX_APPROVE_ASSETS]: 'Assets approved!',
    [NotificationFrontendOnlyActionEnum.UPDATE_NOTIFICATION_SETTINGS]: 'Notification Settings Updated!',
    [NotificationActionEnumBackendSchema.PROPOSAL_BACK_AVAILABLE]: 'Your proposal is acceptable again!',
  },
  [NotificationTypeEnumBackendSchema.info]: {
    [NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUESTED]: 'Loan Extension Requested',
    [NotificationActionEnumBackendSchema.NEW_COUNTER_PROPOSAL]: 'New Counter Proposal',
    [NotificationActionEnumBackendSchema.LOAN_DEFAULTED]: 'Loan Defaulted, Collateral Ready To Be Claimed',
    [NotificationActionEnumBackendSchema.LOAN_PAID_BACK]: 'Loan Was Paid Back',
  },
  [NotificationTypeEnumBackendSchema.error]: {
    [NotificationFrontendOnlyActionEnum.UPDATE_NOTIFICATION_SETTINGS]: 'Failed To Update Notification Settings',
  },
  [NotificationTypeEnumBackendSchema.danger]: {
    [NotificationActionEnumBackendSchema.LOAN_DEFAULTED]: 'Loan Defaulted!',
    [NotificationActionEnumBackendSchema.FIRST_BORROWER_CLOSE_TO_DEFAULT]: 'Loan Close To Default!',
    [NotificationActionEnumBackendSchema.SECOND_BORROWER_CLOSE_TO_DEFAULT]: 'Loan Very Close To Default!',
    [NotificationActionEnumBackendSchema.PROPOSAL_UNAVAILABLE]: 'Your proposal has become unacceptable!',
    [NotificationActionEnumBackendSchema.THESIS_TERMS_CHANGED]: 'Recommit funds after thesis change!',
  },
} as const

export type NotificationAction = NotificationActionEnumBackendSchema | NotificationFrontendOnlyActionEnum

export default class Notification {
  public id?: number
  public variant: NotificationTypeEnumBackendSchema

  public action: NotificationAction
  public errorMessage?: string

  public hasSeen: boolean
  public created: Date

  public firstAsset?: AssetMetadata | NFTAssetCollection
  public secondAsset?: AssetMetadata | NFTAssetCollection
  public customImage?: VNode

  public proposalId?: string
  public loanOnChainId?: string | undefined
  public loanTokenContractAddress?: Address | undefined

  public to?: string | RouteLocationRaw
  public actionName?: string
  public actionPrompt?: string
  public actionTarget?: '_blank' | '_self'

  public chainId: SupportedChain
  public txDetails?: string
  public desiredDuration?: string
  public desiredAmount?: string

  public customFunctionAction?: () => void
  public customFunctionActionName?: string

  constructor(notification: PartialWithRequired<Notification, 'action' | 'variant' | 'chainId'>) {
    this.id = notification.id
    this.variant = notification.variant
    this.action = notification.action
    this.errorMessage = notification.errorMessage
    this.hasSeen = notification.hasSeen || false
    this.created = notification.created || new Date()
    this.firstAsset = notification.firstAsset
    this.secondAsset = notification.secondAsset
    this.customImage = notification.customImage
    this.loanOnChainId = notification.loanOnChainId
    this.loanTokenContractAddress = notification.loanTokenContractAddress
    this.proposalId = notification.proposalId
    this.to = notification.to
    this.actionName = notification.actionName
    this.actionPrompt = notification.actionPrompt
    this.actionTarget = notification.actionTarget || '_blank'
    this.chainId = notification.chainId
    this.txDetails = notification.txDetails
    this.desiredDuration = notification.desiredDuration
    this.desiredAmount = notification.desiredAmount
    this.customFunctionAction = notification.customFunctionAction
    this.customFunctionActionName = notification.customFunctionActionName
  }

  public static createFromBackendResponse(notificationModel: NotificationBackendSchema): Notification | null {
    const chainId = Number(notificationModel.chain_id)
    if (!isChainSupported(chainId)) {
      return null // notification was created for action on chain that we no longer support, so we exclude it
    }

    return new Notification({
      id: notificationModel.id,
      variant: notificationModel.type,
      action: notificationModel.action,
      hasSeen: notificationModel.has_seen,
      created: notificationModel.created_at ? new Date(notificationModel.created_at) : new Date(),
      loanOnChainId: notificationModel.loan_on_chain_id?.toString() ?? undefined,
      loanTokenContractAddress: notificationModel.loan_token_contract_address && isAddress(notificationModel.loan_token_contract_address) ? getAddress(notificationModel.loan_token_contract_address) : undefined,
      proposalId: notificationModel.proposal_id?.toString() ?? undefined,
      chainId,
      ...(notificationModel.first_image && { firstAsset: new AssetMetadata({ image: notificationModel.first_image }) }),
      ...(notificationModel.second_image && { secondAsset: new AssetMetadata({ image: notificationModel.second_image }) }),
      ...(notificationModel.transaction_hash && {
        to: CHAINS_CONSTANTS[chainId].explorer.transactionDetailsLink(notificationModel.transaction_hash),
        actionName: 'TX Details',
      }),
    })
  }

  get loanDetailRoute(): RouteLocationRaw | undefined {
    if (!this.chainId || !this.loanTokenContractAddress || this.loanOnChainId === undefined) {
      return undefined
    }

    return {
      name: RouteName.RevampLoanDetail,
      params: {
        chainName: getChainName(this.chainId).toLowerCase(),
        loanTokenContractAddress: this.loanTokenContractAddress,
        id: this.loanOnChainId,
      },
    }
  }

  get proposalDetailRoute(): RouteLocationRaw | undefined {
    if (this.proposalId === undefined) {
      return undefined
    }

    return {
      name: RouteName.ProposalDetail,
      params: {
        id: this.proposalId,
      },
    }
  }

  get displayCreateDate(): string {
    return DateUtils.displayDate(this.created, { year: 'numeric', month: 'numeric', day: 'numeric' })
  }

  get displayCreateTime(): string {
    return DateUtils.displayDate(this.created, { hour: '2-digit', minute: '2-digit' })
  }

  get isHighlighted(): boolean {
    return !this.hasSeen
  }

  get message(): string | undefined {
    if (this.errorMessage) {
      return this.errorMessage
    }

    return ACTION_NOTIFICATION_TO_TEXT_MAPPING[this.variant]?.[this.action]
  }

  get toastificationType(): TYPE {
    const NOTIFICATION_TYPE_TO_TOASTIFICATION_TYPE_MAPPING: Record<NotificationTypeEnumBackendSchema, TYPE> = {
      [NotificationTypeEnumBackendSchema.info]: TYPE.INFO,
      [NotificationTypeEnumBackendSchema.pending]: TYPE.WARNING,
      [NotificationTypeEnumBackendSchema.success]: TYPE.SUCCESS,
      [NotificationTypeEnumBackendSchema.error]: TYPE.ERROR,
      [NotificationTypeEnumBackendSchema.danger]: TYPE.ERROR,
    }
    return NOTIFICATION_TYPE_TO_TOASTIFICATION_TYPE_MAPPING[this.variant]
  }
}
