<template>
  <div
    class="space-card"
    @click="goToForm">
    <div class="space-card__content">
      <div class="space-card__heading">
        Suggest a New PWN Space!
      </div>
      <BaseButton
        button-text="Submit Your Idea"
        @on-button-click="goToForm"/>
    </div>
  </div>
</template>

<script setup lang="ts">

import BaseButton from '@/general-components/BaseButton.vue'
import links from '@/constants/links'

const goToForm = () => {
  window.open(links.spaceSubmitForm, '_blank', 'noopener,noreferrer')
}
</script>

<style scoped>
.space-card {
  position: relative;
  border: 1px solid var(--gray);
  background: var(--primary-color-4);
  width: 22.5rem;
  height: 11rem;
  flex: 0 0 auto;
  overflow: visible;
  display: flex;
  justify-content: center;
  transition: transform var(--primary-transition-time) ease, box-shadow var(--primary-transition-time) ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px var(--primary-color-1);
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
}
</style>
