enum RouteName {
  Dashboard = 'dashboard',
  Borrow = 'borrow',
  CreateLoan = 'create-loan',
  CreateDutchAuction = 'create-dutch-auction',
  CreateClassicLoanRequest = 'create-classic-loan-request',
  LoanDetail = 'loan-detail',
  NotFound = 'not-found',
  // NftPage = 'nft-page',
  TokenPage = 'token-page',
  NotificationCenter = 'notification-center',
  NotificationCenterSettings = 'notification-center-settings',
  EditLoan = 'edit-loan',
  TokenBundler = 'token-bundler',
  Spaces = 'spaces',

  BundleAssets = 'bundle-assets',
  BundleAssetsChooseAssets = 'bundle-assets-choose-assets',
  BundleAssetsSubmit = 'bundle-assets-submit',

  UnpackBundle = 'unpack-bundle',
  UnpackBundleChooseBundle = 'unpack-bundle-choose-bundle',
  UnpackBundleSubmit = 'unpack-bundle-submit',

  PwnExplorerPage = 'pwn-explorer',
  PwnExplorerPageCollections = 'pwn-explorer-collections',
  PwnExplorerPageAssets = 'pwn-explorer-assets',
  PwnExplorerPageLoans = 'pwn-explorer-loans',
  PwnExplorerPageWallets = 'pwn-explorer-wallets',

  Lend = 'lend',
  LendExplorerPageCollections = 'lend-explorer-collections',
  LendExplorerPageAssets = 'lend-explorer-assets',
  LendExplorerPageLoans = 'lend-explorer-loans',

  PwnSafe = 'pwn-safe',

  PwnSafeCurrentWalletAssets = 'pwn-safe-current-wallet-assets',
  PwnSafeTransfer = 'pwn-safe-transfer',
  PwnSafeClaimAndBurn = 'pwn-safe-claim-and-burn',

  PwnSafeDetailAssets = 'pwn-safe-detail-assets',
  PwnSafeTokenize = 'pwn-safe-tokenize',
  PwnSafeDetailSettings = 'pwn-safe-detail-settings',

  PwnSafeCreateConfirmation = 'pwn-safe-confirmation',
  PwnSafeCreateNewPwnSafe = 'pwn-safe-create-new-pwn-safe',

  Collection = 'collection',
  CollectionByContractAddress = 'collection-by-contract-address',
  CollectionBySlug = 'collection-by-slug',
  CollectionMakeOfferByContractAddress = 'collection-make-offer-by-contract-address',
  CollectionMakeOfferBySlug = 'collection-make-offer-by-slug',
  CollectionOffersTab = 'collection-offers-tab',
  CollectionAssetsTab = 'collection-assets-tab',

  // REVAMP //
  RevampHomepage = 'revamp-homepage',
  RevampLend = 'revamp-lend',
  RevampBorrow = 'revamp-borrow',
  RevampExplorer = 'revamp-explorer',
  RevampExplorerDetailNfts = 'revamp-explorer-detail-nfts',
  RevampExplorerDetailTokens = 'revamp-explorer-detail-tokens',
  ProposalDetail = 'proposal-detail',
  RevampLoanDetail = 'revamp-loan-detail',

  RevampCollectionProposalsBorrowing = 'revamp-collection-proposals-borrowing',
  RevampCollectionProposalsLending = 'revamp-collection-proposals-lending',

  RevampTokenLending = 'revamp-token-lending',
  RevampTokenBorrowing = 'revamp-token-borrowing',

  NftPage = 'revamp-nft-page',
  RevampNftPageDetails = 'revamp-nft-page-details',
  RevampNftPageBorrowing = 'revamp-nft-page-borrowing',
  RevampNftPageLending = 'revamp-nft-page-lending',

  Thesis = 'thesis',
  ThesisList = 'thesis-list',

  Leaderboard = 'leaderboard',

  PwnMatcher = 'pwn-matcher',
  ClaimRep = 'claim-rep',
  ClaimRepBasenames = 'claim-rep-basenames',
  ClaimRepPush = 'claim-rep-push',
  ClaimRepAave = 'claim-rep-aave',
}

export default RouteName
