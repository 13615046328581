import type { MetadataSourceBackendSchema, NFTAssetCollectionDetailSchemaBackendSchema, NFTAssetCollectionDetailWithStatsSchemaBackendSchema } from '@/modules/common/backend/generated'
import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import type AssetType from '@/modules/common/assets/AssetType'
import { parseAssetCategoryToAssetType } from '@/modules/common/assets/AssetType'
import type { SupportedChain } from '@/constants/chains/types'
import { parseChainIdFromResponse } from '@/modules/common/backend/backendUtils'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { RouteLocationRaw } from 'vue-router'
import CollectionStats from '@/modules/common/assets/typings/CollectionStats'
import { isAddress, getAddress } from 'viem'
import type { Address } from 'viem'
import { isTokenBundle } from '@/utils/contractAddresses'

export default class NFTAssetCollection {
  public static MISSING_COLLECTION_NAME_PLACEHOLDER = 'Missing collection name'
  public static MISSING_COLLECTION_DESCRIPTION_PLACEHOLDER = 'Missing collection description'

  public name?: string
  public description?: string
  public symbol?: string
  public image?: string
  public isVerified: boolean
  public isVerifiedSource?: MetadataSourceBackendSchema
  public tlvValue?: AmountInEthAndUsd

  public discordUrl?: string
  public websiteUrl?: string
  public githubUrl?: string
  public bannerImageUrl?: string
  public twitterUrl?: string
  public instagramUrl?: string
  public openseaSlug?: string

  public category: AssetType
  public chainId: SupportedChain
  public isDynamicCollection: boolean
  public contractAddress: Address
  public collectionStats?: CollectionStats

  public totalNFTs?: string
  public totalOwners?: string

  // kyc required == contract that if the loan is defaulted collateral will require kyc for claim
  public isKycRequired?: boolean
  public kycTitle?: string
  public kycDescription?: string
  public kycLogo?: string
  public kycUrl?: string

  constructor(collection: Partial<NFTAssetCollection>) {
    this.name = collection?.name || NFTAssetCollection.MISSING_COLLECTION_NAME_PLACEHOLDER
    this.description = collection?.description || NFTAssetCollection.MISSING_COLLECTION_DESCRIPTION_PLACEHOLDER
    this.symbol = collection?.symbol
    this.image = collection?.image
    this.isVerified = collection?.isVerified ?? false
    this.isVerifiedSource = collection?.isVerifiedSource
    this.tlvValue = collection?.tlvValue

    this.discordUrl = collection?.discordUrl
    this.websiteUrl = collection?.websiteUrl
    this.githubUrl = collection?.githubUrl
    this.bannerImageUrl = collection?.bannerImageUrl
    this.twitterUrl = collection?.twitterUrl
    this.instagramUrl = collection?.instagramUrl
    this.openseaSlug = collection?.openseaSlug

    this.category = collection.category!
    this.chainId = collection.chainId!
    this.isDynamicCollection = collection?.isDynamicCollection ?? false
    this.contractAddress = collection.contractAddress!
    this.collectionStats = collection?.collectionStats

    this.totalNFTs = collection?.totalNFTs
    this.totalOwners = collection?.totalOwners

    this.isKycRequired = collection?.isKycRequired
    this.kycLogo = collection?.kycLogo
    this.kycTitle = collection?.kycTitle
    this.kycDescription = collection?.kycDescription
    this.kycUrl = collection?.kycUrl
  }

  public static createFromBackendModel(collection: NFTAssetCollectionDetailSchemaBackendSchema | NFTAssetCollectionDetailWithStatsSchemaBackendSchema): NFTAssetCollection {
    const nftAssetCollection = new NFTAssetCollection({
      name: collection?.name ?? undefined,
      description: collection?.description ?? undefined,
      symbol: collection?.symbol ?? undefined,
      image: collection?.thumbnail_url ?? undefined,
      isVerified: collection?.is_verified ?? undefined,
      isVerifiedSource: collection?.is_verified_source ?? undefined,
      tlvValue: new AmountInEthAndUsd(String(collection?.tlv_value_eth), String(collection?.tlv_value_usd)),
      discordUrl: collection?.discord_url ?? undefined,
      websiteUrl: collection?.website_url ?? undefined,
      githubUrl: collection?.github_url ?? undefined,
      bannerImageUrl: collection?.banner_image_url ?? undefined,
      twitterUrl: collection?.twitter_url ?? undefined,
      instagramUrl: collection?.instagram_url ?? undefined,
      openseaSlug: collection?.opensea_slug ?? undefined,
      category: parseAssetCategoryToAssetType(collection?.contract?.category) ?? undefined,
      chainId: parseChainIdFromResponse(collection?.contract?.chain_id) ?? undefined,
      contractAddress: collection?.contract?.address && isAddress(collection.contract.address) ? getAddress(collection.contract.address) : undefined,
      isDynamicCollection: collection?.contract.is_dynamic_contract ?? undefined,
      totalNFTs: String(collection?.total_nfts ?? ''),
      totalOwners: String(collection?.total_owners ?? ''),
      isKycRequired: collection?.contract.is_kyc_required,
      kycTitle: collection?.contract.kyc_details?.title ?? undefined,
      kycDescription: collection?.contract.kyc_details?.description ?? undefined,
      kycLogo: collection?.contract.kyc_details?.logo ?? undefined,
      kycUrl: collection?.contract.kyc_details?.website_url ?? undefined,
    })

    if ('collection_stats' in collection && collection.collection_stats) {
      nftAssetCollection.collectionStats = CollectionStats.createFromBackendModel(collection.collection_stats)
    }
    return nftAssetCollection
  }

  get isNameMissing(): boolean {
    return this.name === NFTAssetCollection.MISSING_COLLECTION_NAME_PLACEHOLDER
  }

  get isDescriptionMissing(): boolean {
    return this.description === NFTAssetCollection.MISSING_COLLECTION_DESCRIPTION_PLACEHOLDER
  }

  get openseaUrl(): string {
    if (!this.openseaSlug) {
      return ''
    }

    return CHAINS_CONSTANTS[this.chainId]?.explorer?.nftOpenseaCollectionLink?.(this.openseaSlug) || ''
  }

  get routeToCollection(): RouteLocationRaw {
    return {
      name: RouteName.CollectionByContractAddress,
      params: {
        chainName: CHAINS_CONSTANTS[this.chainId].general.chainName.toLowerCase(),
        contractAddress: this.contractAddress,
      },
    }
  }

  get id(): string {
    return `${this.chainId}-${this.contractAddress}`
  }

  get etherscanUrl(): string | undefined {
    return CHAINS_CONSTANTS[this.chainId]?.explorer?.contractDetailsLink?.(this.contractAddress)
  }

  get isTokenBundle(): boolean {
    if (!this.contractAddress || !this.chainId) {
      return false
    }

    return isTokenBundle(this.chainId, this.contractAddress)
  }
}
