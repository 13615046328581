import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import formatToMultiTokenTuple from '@/utils/formatToMultiTokenTuple'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import { tokenBundlerAbi } from '@/contracts/generated'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { parseEventLogs } from 'viem'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export default function useTokenBundlerContract() {
  const createBundle = async (assetsToBundle: AssetWithAmount[], step: ToastStep): Promise<AssetWithAmount | null> => {
    const formattedAssetsToBundle = formatToMultiTokenTuple(assetsToBundle)
    const bundledAssetsChainId = assetsToBundle[0].chainId

    if (!CHAINS_CONSTANTS[bundledAssetsChainId].tokenBundlerContract) {
      throw new Error(`Bundler contract not found for chain ${bundledAssetsChainId}`)
    }

    // TODO do we need to override confirmations required here? in previous version we did overwrite to 2 confirmations required
    const receipt = await sendTransaction({
      abi: tokenBundlerAbi,
      functionName: 'create',
      // @ts-expect-error FIXME: strictNullChecks
      args: [formattedAssetsToBundle],
      address: CHAINS_CONSTANTS[bundledAssetsChainId].tokenBundlerContract!,
      chainId: bundledAssetsChainId,
    }, { step })

    const { address: userAddress, chainId: connectedChainid } = getAccount(pwnWagmiConfig)

    const bundleCreatedEvent = parseEventLogs({
      abi: tokenBundlerAbi,
      logs: receipt.logs,
      eventName: 'BundleCreated',
    })
    const bundleId = bundleCreatedEvent[0].args.id

    if (!connectedChainid) return null

    const metadata = await useMetadataFetch().fetchNftMetadata({
      chainId: connectedChainid,
      contractAddress: CHAINS_CONSTANTS[connectedChainid].tokenBundlerContract,
      tokenId: bundleId,
      userAddress,
    })
    return new AssetWithAmount({ ...metadata, amount: '1', isValid: true })
  }

  const unbundle = async (bundle: AssetWithAmount, step: ToastStep) => {
    if (!CHAINS_CONSTANTS[bundle.chainId].tokenBundlerContract) {
      throw new Error(`Bundler contract not found for chain ${bundle.chainId}`)
    }

    // TODO do we need to override confirmations required here? in previous version we did overwrite to 2 confirmations required
    await sendTransaction({
      abi: tokenBundlerAbi,
      functionName: 'unwrap',
      args: [bundle.tokenId],
      address: CHAINS_CONSTANTS[bundle.chainId].tokenBundlerContract!,
      chainId: bundle.chainId,
    }, { step })
    return bundle.bundleAssets
  }

  return {
    createBundle,
    unbundle,
  }
}
