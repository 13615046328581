<template>
  <PleaseConnectWallet
    message="PWN Safe"
    :background-image="PleaseConnectWalletBgImage.Safe"/>
  <div>
    <PwnSafeDashboardHeader
      :handle-refresh="handleRefresh"
      :is-loading="refreshMutation.isPending.value"/>
    <PwnSafeCurrentWallet/>
    <BaseSkeletor
      v-if="isLoadingPwnSafes && isConnected"
      class="pwn-safe-dashboard__loader"/>
    <div
      v-if="!isConnectedContractWallet"
      class="pwn-safe-dashboard__safes">
      <PwnSafeBox
        v-for="safe in safesWithLoadedAssets"
        :key="safe.id"
        :is-hidden="!selectedValues?.includes?.(safe.chainId!)"
        :pwn-safe="safe"/>
    </div>

    <BaseButton
      class="pwn-safe-dashboard__create-new-safe"
      :is-disabled="isConnectedPwnSafe"
      button-text="+ Create New Safe"
      @on-button-click="handleCreateSafeButtonClick"/>
  </div>
  <PwnSafeWhitelistBanner
    v-if="isConnected"
    class="pwn-safe-dashboard__whitelist-banner"/>
</template>

<script setup lang="ts">
import PwnSafeDashboardHeader from '@/modules/pages/pwn-safe/pwn-safe-dashboard/PwnSafeDashboardHeader.vue'
import PwnSafeCurrentWallet from '@/modules/pages/pwn-safe/pwn-safe-dashboard/PwnSafeCurrentWallet.vue'
import PleaseConnectWallet from '@/general-components/PleaseConnectWallet.vue'
import PwnSafeBox from '@/modules/pages/pwn-safe/pwn-safe-dashboard/PwnSafeBox.vue'
import BaseButton from '@/general-components/BaseButton.vue'
import RouteName from '@/router/RouteName'
import { PleaseConnectWalletBgImage } from '@/general-components/PleaseConnectWalletProperties'
import { useRouter } from 'vue-router'
import PwnSafeWhitelistBanner from '@/modules/pages/pwn-safe/PwnSafeWhitelistBanner.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { computed, watch, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { useMutation, useQueries, useQueryClient } from '@tanstack/vue-query'
import { queries } from '@/modules/queries'
import { isAddress } from 'viem'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { queryMutations } from '@/modules/mutations'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'

const selectedChainsStore = useSelectedChainsStore()
const { selectedValues } = storeToRefs(selectedChainsStore)
const userAssetsStore = useUserAssetsStore()
const router = useRouter()

const { address: userAddress, isConnected } = useCustomAccount()

const connectedAccountTypeStore = useConnectedAccountTypeStore()
const { isConnectedContractWallet, isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)

const queryClient = useQueryClient()

watch(userAddress, (v) => {
  // @ts-expect-error FIXME: strictNullChecks
  if (isAddress(userAddress.value)) {
    userAssetsStore.loadUserAssets(userAddress.value)
  }
})

const handleCreateSafeButtonClick = () => {
  router.push({ name: RouteName.PwnSafeCreateNewPwnSafe })
}

const pwnSafeFetchQueries = computed(() => {
  if (!isConnected.value && !userAddress.value) {
    return []
  }
  // @ts-expect-error FIXME: strictNullChecks
  return selectedValues.value.map((chainId) => {
    return {
      // @ts-expect-error FIXME: strictNullChecks
      ...queries.safe.list(userAddress, chainId),
      staleTime: 1000 * 60 * 5,
      isRefetching: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  })
})

const pwnSafeQueries = useQueries({
  queries: pwnSafeFetchQueries,
  combine: (results) => {
    return {
      data: results.map((result) => result.data).flat(),
      isLoading: results.some((result) => result.isLoading),
      refetch: [...results.map((result) => result.refetch)],
    }
  },
})

// @ts-expect-error FIXME: strictNullChecks
const safes = computed<PwnSafe[]>(() => {
  const results = pwnSafeQueries.value.data
  return results.filter(Boolean)
})

watchEffect(() => {
  safes.value.forEach((safe) => {
    // @ts-expect-error FIXME: strictNullChecks
    queryClient.setQueryData(queries.safe.detail(safe.safeAddress, safe.chainId).queryKey, safe)
  })
})

const isLoadingPwnSafes = computed(() => {
  return pwnSafeQueries.value.isLoading
})

const safeNftsQueries = computed(() => {
  return safes.value.map((safe) => {
    return {
      // @ts-expect-error FIXME: strictNullChecks
      ...queries.safe.detail(safe.safeAddress, safe.chainId)._ctx.nfts,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  })
})

const safeNftsQueriesResult = useQueries({
  queries: safeNftsQueries,
  combine: (data) => {
    const res = {}

    for (let i = 0; i < data.length; i++) {
      const safe = safes.value[i]
      res[safe.key] = {
        data: data[i].data,
        isLoading: data[i].isLoading,
        isRefetching: data[i].isRefetching,
        refetch: data[i].refetch,
      }
    }

    return res
  },
})

const safeTokenQueries = computed(() => {
  return safes.value.map((safe) => {
    return {
      // @ts-expect-error FIXME: strictNullChecks
      ...queries.safe.detail(safe.safeAddress, safe.chainId)._ctx.tokens,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  })
})

const safeTokensQueriesResult = useQueries({
  queries: safeTokenQueries,
  combine: (data) => {
    const res = {}

    for (let i = 0; i < data.length; i++) {
      const safe = safes.value[i]
      res[safe.key] = {
        data: data[i].data,
        isLoading: data[i].isLoading,
        isRefetching: data[i].isRefetching,
        refetch: data[i].refetch,
      }
    }

    return res
  },
})

const safesWithLoadedAssets = computed(() => {
  return safes.value.map((safe) => {
    const tokenResults = safeTokensQueriesResult.value[safe.key]
    const nftResults = safeNftsQueriesResult.value[safe.key]

    // @ts-expect-error FIXME: strictNullChecks
    return new PwnSafe({
      ...safe,
      erc20s: tokenResults?.data || [],
      nfts: nftResults?.data || [],
      isFetchingNfts: nftResults?.isLoading || nftResults?.isRefetching,
      isFetchingErc20s: tokenResults?.isLoading || tokenResults?.isRefetching,
    })
  })
})
const mutations = queryMutations()

const refreshMutation = useMutation(mutations.safe.batchRefreshAssets)

const handleRefresh = () => {
  refreshMutation.mutate({
    safesToRefetch: safesWithLoadedAssets.value,
    selectedChains: selectedValues.value,
    userAddress: userAddress.value,
  },
  )
}
</script>

<style scoped>
.pwn-safe-dashboard {
  &__safes {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
  }

  &__loader {
    margin-top: 1rem;
  }

  &__create-new-safe {
    display: flex;
    justify-content: end;
    margin: 1rem 0 0;
  }

  &__whitelist-banner {
    padding: 2.5rem 0;
  }

  @media only screen and (--mobile-viewport) {
    &__whitelist-banner {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
