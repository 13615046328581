<template>
  <div class="create-new-pwn-safe-confirmation">
    <div class="create-new-pwn-safe-confirmation__safe-details">
      <div class="create-new-pwn-safe-confirmation__safe-container">
        <div class="create-new-pwn-safe-confirmation__label-container">
          <label class="create-new-pwn-safe-confirmation__label"> PWN Safe Name </label>
        </div>
        <div class="create-new-pwn-safe-confirmation__safe-name">
          {{ safeName }}
        </div>
      </div>
      <div class="create-new-pwn-safe-confirmation__network-container">
        <div class="create-new-pwn-safe-confirmation__label-container">
          <label class="create-new-pwn-safe-confirmation__label"> Network </label>
        </div>
        <div class="create-new-pwn-safe-confirmation__network">
          <ChainImageSvg
            class="create-new-pwn-safe-confirmation__network-icon"
            alt="network icon"/>
          <div
            v-if="chainId"
            class="create-new-pwn-safe-confirmation__network-name">
            {{ getChainName(chainId) }}
          </div>
        </div>
      </div>
    </div>
    <div class="create-new-pwn-safe-confirmation__owner-details">
      <div class="create-new-pwn-safe-confirmation__label-container">
        <label class="create-new-pwn-safe-confirmation__label"> Owner </label>
      </div>
      <div class="create-new-pwn-safe-confirmation__ens-name">
        <CryptoAddress
          :has-tooltip="false"
          :address="userAddress!"
          :chain-id="chainId!"/>
        <div class="create-new-pwn-safe-confirmation__address-container">
          <div
            :class="[
              'create-new-pwn-safe-confirmation__address',
              { 'create-new-pwn-safe-confirmation__address--copied': isCopied },
            ]">
            ({{ shortenAddress(userAddress!) }})
          </div>
          <div class="create-new-pwn-safe-confirmation__copy-container">
            <CopyIconSvg
              :class="[
                'create-new-pwn-safe-confirmation__copy-icon',
                { 'create-new-pwn-safe-confirmation__copy-icon--copied': isCopied },
              ]"
              alt="copy"
              @click="copy"/>
            <CopyTopMiddleIconSvg
              v-if="isCopied"
              class="create-new-pwn-safe-confirmation__copy-text"
              alt="copied"/>
          </div>
          <router-link
            :to="dashboardRoute"
            target="_blank">
            <UserIconSvg
              alt="user icon"
              class="create-new-pwn-safe-confirmation__user-icon"/>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <CreateNewPwnSafeFooter
    button-text="Confirm"
    :is-disabled="isPending"
    @on-button-click="handleDeployPwnSafe"/>
</template>

<script setup lang="ts">
import useCreateNewPwnSafe from '@/modules/pages/pwn-safe/create-new-pwn-safe/useCreateNewPwnSafe'
import CreateNewPwnSafeFooter from '@/modules/pages/pwn-safe/create-new-pwn-safe/CreateNewPwnSafeFooter.vue'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import { computed, markRaw, ref } from 'vue'
import type { Ref } from 'vue'
import { shortenAddress } from '@/utils/utils'
import router from '@/router'
import RouteName from '@/router/RouteName'
import type { RouteLocationRaw } from 'vue-router'
import usePwnSafeFactory from '@/modules/common/pwn/safe/usePwnSafeFactory'
import { getChainIcon, getChainName } from '@/utils/chain'
import CopyTopMiddleIconSvg from '@/assets/icons/copy-top-middle.svg'
import CopyIconSvg from '@/assets/icons/copy.svg'
import UserIconSvg from '@/assets/icons/user.svg'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { queries } from '@/modules/queries'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import SafeIconSvg from '@/assets/icons/safe.svg'

const { safeName, chainId } = useCreateNewPwnSafe()
const { deployPwnSafe } = usePwnSafeFactory()
const isCopied = ref(false)

const { address: userAddress } = useCustomAccount()

const queryClient = useQueryClient()

const copy = async () => {
  // @ts-expect-error FIXME: strictNullChecks
  await navigator.clipboard.writeText(userAddress.value)
  isCopied.value = true
  setTimeout(() => {
    isCopied.value = false
  }, 1500)
}

const dashboardRoute: RouteLocationRaw = {
  name: RouteName.Dashboard,
  params: {
    user: userAddress.value,
  },
}

const submitName = async (step: ToastStep) => {
  // @ts-expect-error FIXME: strictNullChecks
  const deployedSafe = await deployPwnSafe(safeName.value, chainId.value, [userAddress.value], 1, step)

  if (deployedSafe) {
    safeName.value = ''
    return deployedSafe
  }
}

// @ts-expect-error FIXME: strictNullChecks
const ChainImageSvg = computed(() => getChainIcon(chainId.value))

const { mutateAsync, isPending } = useMutation({
  mutationFn: async(step: ToastStep) => { await submitName(step) },
  onSuccess(data, variables, context) {
    queryClient.setQueriesData(
      {
        // @ts-expect-error FIXME: strictNullChecks
        queryKey: queries.safe.list(userAddress.value, chainId).queryKey,
      },
      // @ts-expect-error FIXME: strictNullChecks
      (existingData: PwnSafe[]) => {
        return [...existingData, data]
      },
    )
    router.push({ name: RouteName.PwnSafe })
  },
})

let continueFlow
const toast = ref<Toast>()

const handleDeployPwnSafe = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_CREATE_PWN_SAFE](chainId.value!)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      title: 'Deploying PWN Safe',
      customImage: markRaw(SafeIconSvg),
      // @ts-expect-error FIXME: strictNullChecks
      chainId: chainId.value,
      steps: [new ToastStep({
        text: 'Deploying...',
        async fn(step) {
          await mutateAsync(step)
          return true
        },
      })],
    }, NotificationFrontendOnlyActionEnum.TX_CREATE_PWN_SAFE, chainId.value);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}
</script>

<style scoped>
.create-new-pwn-safe-confirmation {
  display: flex;
  margin: 3rem 0;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    width: 1px;
    height: 10.25rem;
    background-image: var(--border-gray-dashed);
    background-size: 2px auto; /* small hack how to hide buggy double border (top + bottom), when width is 1px */
  }

  &__safe-details,
  &__owner-details {
    width: 50%;
  }

  &__owner-details {
    padding-left: 4rem;
  }

  &__safe-container {
    margin-bottom: 2rem;
  }

  &__label-container {
    margin-bottom: 0.5rem;
  }

  &__label {
    font-size: 1.125rem;
    font-family: var(--font-family-autoscape);
    line-height: 1.625rem;
  }

  &__safe-name,
  &__network-name {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  &__network {
    display: flex;
  }

  &__network-icon {
    width: 1.5rem;
    margin-right: 0.5rem;
    height: auto;
  }

  &__ens-name {
    font-size: 1.5rem;
  }

  &__address-container {
    display: flex;
  }

  &__address {
    margin-top: 0.5rem;
    font-size: 1.125rem;
    font-family: var(--font-family-oxygen-mono);
    line-height: 1.625rem;

    &--copied {
      color: var(--primary-color-1);
    }
  }

  &__copy-container {
    position: relative;
    display: flex;
  }

  &__copy-icon {
    width: 1rem;
  }

  &__user-icon {
    margin-top: 0.5rem;
  }

  &__copy-icon,
  &__user-icon {
    cursor: pointer;
    margin-left: 0.5rem;
    filter: grayscale(1);

    &--copied,
    &:hover {
      filter: grayscale(0);
    }
  }

  &__copy-text {
    width: 6.5rem;
    position: absolute;
    margin-top: 2.25rem;
    right: -3rem;
    transform-origin: top;
    animation: scale 2s ease-in both;
  }

  @keyframes scale {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    10% {
      transform: scale(1);
      opacity: 1;
    }

    90% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

  @media only screen and (--mobile-viewport) {
    &__owner-details {
      padding-left: 1rem;
    }
  }
}
</style>
