import type { SupportedChain } from '@/constants/chains/types'
import { readErc20Allowance, readErc20BalanceOf } from '@/contracts/generated'
import { erc20Abi, maxUint256 } from 'viem'
import type { Address, WriteContractParameters } from 'viem'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { readStarknetERC20Allowance, readStarknetERC20Balance } from '@/revamp/hooks/thesis/starknet/useStarknetAssetBalances'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { getPublicClient } from '@wagmi/core'
import { usdtMainnetAbi } from '@/contracts/abis'
import { compareAssets } from '@/utils/utils'
import { CallData, uint256 } from 'starknet'
import type { Call } from 'starknet'

export const readAnyERC20Allowance = async (address: Address, chainId: SupportedChain, owner: Address, spender: Address) => {
  if (isStarknet) {
    return readStarknetERC20Allowance(address, chainId, owner, spender)
  }

  return readErc20Allowance(pwnWagmiConfig, {
    address,
    chainId,
    args: [owner, spender],
  })
}

export const readAnyERC20Balance = async (address: Address, chainId: SupportedChain, owner: Address) => {
  if (isStarknet) {
    return readStarknetERC20Balance(address, chainId, owner)
  }

  return readErc20BalanceOf(pwnWagmiConfig, {
    address,
    chainId,
    args: [owner],
  })
}

export const readAnyMultipleERC20Allowances = async (assets: AssetWithAmount[], chainId: SupportedChain, owner: Address, spender: Address) => {
  if (isStarknet) {
    const allowancePromises = assets.map((asset) => readStarknetERC20Allowance(asset.address, chainId, owner, spender))

    const results = await Promise.all(allowancePromises)

    return results.map((result, index) => ({
      asset: assets[index],
      isApproved: result >= assets[index].amountRaw,
      spenderAddress: spender,
    }))
  }

  const client = getPublicClient(pwnWagmiConfig, {
    chainId,
  })

  if (!owner || !client) {
    throw new Error('Account not connected')
  }

  const callsToExecute = assets.map((asset) => {
    return {
      abi: erc20Abi,
      address: asset.address,
      functionName: 'allowance',
      args: [owner, spender],
    }
  })

  const results = await client.multicall({
    contracts: [...callsToExecute],
    allowFailure: false,
  })

  return results.map((result, index) => ({
    asset: assets[index],
    isApproved: BigInt(result) >= assets[index].amountRaw,
    spenderAddress: spender,
  }))
}

type PrepareApproveMultiERC20sReturnType = WriteContractParameters[] | Call[]

export const prepareApproveMultiERC20s = (assets: (AssetMetadata | AssetWithAmount)[], chainId: SupportedChain, owner: Address, spender: Address): PrepareApproveMultiERC20sReturnType => {
  if (isStarknet) {
    const amount = uint256.UINT_256_MAX
    const formattedAmount = uint256.bnToUint256(amount)
    const complied = CallData.compile({
      spender,
      amount: formattedAmount,
    })

    return assets.map((asset) => ({
      contractAddress: asset.address,
      entrypoint: 'approve',
      calldata: complied,
      from: owner,
    })) as unknown as Call[]
  }

  const approvalCalls = assets.map((asset) => {
    const assetIsUSDT = compareAssets({
      assetA: asset,
      assetB: AssetWithAmount.createMainnetUsdtAssetMetadata(),
    }) || compareAssets({
      assetA: asset,
      assetB: AssetWithAmount.createSepoliaUsdtAssetMetadata(),
    })

    const abi = !assetIsUSDT ? erc20Abi : usdtMainnetAbi

    return {
      abi,
      address: asset.address,
      functionName: 'approve',
      args: [spender, maxUint256],
    } as const
  })

  return approvalCalls as unknown as WriteContractParameters[]
}
