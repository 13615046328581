import usePwnSafeFactory from '@/modules/common/pwn/safe/usePwnSafeFactory'
import to from '@/utils/await-to-js'
import { computed, ref } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

const isOpen = ref(false)

export enum CreateNewPwnSafeModalStep {
    MissingSafe,
    Input,
    Confirmation,
}
const activeStep = ref(CreateNewPwnSafeModalStep.MissingSafe)
const safeName = ref('')
const isWaitingForTxConfirmation = ref(false)
const chainId = ref<SupportedChain | null>(null)

export default function useCreateNewPwnSafeModal() {
  const modalHeading = computed(() => {
    switch (activeStep.value) {
    case CreateNewPwnSafeModalStep.MissingSafe:
      return 'Missing Safe'
    case CreateNewPwnSafeModalStep.Input:
      return 'Create new PWN Safe'
    case CreateNewPwnSafeModalStep.Confirmation:
      return 'Safe Deployed'
    default:
      return ''
    }
  })

  const createSafe = async (step: ToastStep) => {
    isWaitingForTxConfirmation.value = true
    const { address: userAddress } = getAccount(pwnWagmiConfig)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'SupportedChain | null' is not as... Remove this comment to see the full error message
    const [error, deployedSafe] = await to(usePwnSafeFactory().deployPwnSafe(safeName.value, chainId.value, [userAddress], 1, step))
    isWaitingForTxConfirmation.value = false
    if (!error && deployedSafe) {
      activeStep.value = CreateNewPwnSafeModalStep.Confirmation
      return deployedSafe
    } else {
      throw new Error(error.message)
    }
  }

  return {
    isOpen,
    activeStep,
    safeName,
    modalHeading,
    isWaitingForTxConfirmation,
    chainId,
    createSafe,
  }
}
