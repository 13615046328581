import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useSearchPwnExplorer } from '@/modules/common/pwn/explorer/useSearchStore'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'
import type { NFTAssetSearchResult } from '@/modules/common/pwn/explorer/models/NFTAssetSearchResult'
import { useCancelableRequests } from '@/modules/common/useCancellableRequest'
import { watchArray } from '@vueuse/core'
import { ExplorerStores } from '@/modules/common/pwn/explorer/constants'
import type { NftUniqueKey } from '@/utils/unique-keys'

type NFTPriceStatsResult = Record<NftUniqueKey, NFTPriceStats>

export const useNFTSearchPriceStatsStore = defineStore(ExplorerStores.NftSearchStats, () => {
  const statsResult = ref<NFTPriceStatsResult | null>(null)
  const isFetched = ref(false)
  const shouldBeFetched = ref(false)

  const nftSearchResults = computed(() => {
    if (!shouldBeFetched.value) return []
    return useSearchPwnExplorer().results.nfts || []
  })

  const { setCancellableRequest, cancelRequests } = useCancelableRequests<NftUniqueKey>()

  watchArray<NFTAssetSearchResult>(nftSearchResults, async (currentSearchResults, _, added) => {
    cancelRequests()
    if (!currentSearchResults?.length || !added?.length) {
      return
    }
    isFetched.value = false

    const nftStats: NFTPriceStatsResult = {}
    const { fetchNFTPriceStats } = useMetadataFetch()

    const toFetch = added.length ? added : currentSearchResults

    await Promise.allSettled(
      toFetch.map(async (nft) => {
        const key = nft.key
        if (statsResult.value?.[key]) {
          nftStats[key] = statsResult.value[key]
          return
        }
        const controller = new AbortController()
        setCancellableRequest(key, controller)
        const priceResults = await fetchNFTPriceStats(
          nft.contractAddress,
          nft.tokenId,
          nft.chainId,
          { signal: controller.signal },
        )
        if (!priceResults) {
          return
        }

        nftStats[key] = priceResults
      }),
    )

    statsResult.value = nftStats
    isFetched.value = true
  })

  const getNftPriceStats = (nft: NFTAssetSearchResult): NFTPriceStats | null => {
    return statsResult.value?.[nft.key] || null
  }

  const shouldFetch = (v: boolean) => {
    shouldBeFetched.value = v
  }

  return {
    isFetched,
    actions: {
      getNftPriceStats,
      shouldFetch,
    },
  }
})
