/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

export type ChainIdEnumBackendSchema = typeof ChainIdEnumBackendSchema[keyof typeof ChainIdEnumBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChainIdEnumBackendSchema = {
  NUMBER_1: 1,
  NUMBER_137: 137,
  NUMBER_100: 100,
  NUMBER_25: 25,
  NUMBER_8453: 8453,
  NUMBER_10: 10,
  NUMBER_42161: 42161,
  NUMBER_56: 56,
  NUMBER_11155111: 11155111,
  NUMBER_1301: 1301,
  NUMBER_112211: 112211,
  NUMBER_11155112: 11155112,
} as const;
