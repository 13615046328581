<template>
  <div>
    <p>Complete these tasks, boost your reputation and earn more rep</p>
    <div
      v-for="(repScoreItem, index) in repScoreItems"
      :key="index"
      class="rep-score-list__row-item">
      <div class="rep-score-list__row-item-left-side">
        <component
          :is="repScoreItem.icon"
          width="48"
          height="48"
          :alt="repScoreItem.label"/>
        <label>
          {{ repScoreItem.label }}
        </label>
      </div>
      <div class="rep-score-list__row-item-right-side">
        <BaseLink
          v-if="repScoreItem.link"
          :to="repScoreItem.link"
          target="_blank">
          <BaseButton
            :size="ButtonSize.S"
            :button-text="repScoreItem.linkText">
            <template #icon-before-text>
              <component
                :is="repScoreItem.linkIcon"
                class="rep-score-list__link-icon"
                width="20"
                height="20"
                :alt="repScoreItem.label"/>
            </template>
          </BaseButton>
        </BaseLink>
        <div class="">
          <span>
            {{ repScoreItem.scoreText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FarcasterSvg from '@/assets/icons/rep-score/farcaster-follow.svg'
import OnlineEventPOAPsSvg from '@/assets/icons/rep-score/online-event-poaps.svg'
import MakeSimpleProposalSvg from '@/assets/icons/rep-score/make-simple-proposal.svg'
import WarpcastIconSvg from '@/assets/icons/warpcaster.svg'
import links from '@/constants/links'
import BaseButton, { ButtonSize } from '@/general-components/BaseButton.vue'
import BaseLink from '@/general-components/BaseLink.vue'

const repScoreItems = [
  {
    icon: OnlineEventPOAPsSvg,
    link: links.discord,
    label: 'Attend Community Calls',
    linkText: 'Join Discord',
    scoreText: '+ 1 rep per POAP',
  },
  {
    icon: FarcasterSvg,
    link: links.warpcaster,
    linkIcon: WarpcastIconSvg,
    label: 'Follow PWN on Farcaster',
    linkText: 'Follow PWN',
    scoreText: '+ 1 rep',
  },
  {
    icon: MakeSimpleProposalSvg,
    link: 'https://app.pwn.xyz',
    label: 'Borrow & Lend',
    linkText: 'Go to App',
    scoreText: '+ 1 rep per $1k',
  },
]
</script>

<style scoped>
.rep-score-list {
  &__row-item {
    display: flex;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    border: 1px solid #434343;
    height: 5rem;
    width: 35rem;
  }

  &__row-item-left-side {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__row-item-right-side {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: end;
  }

  &__link-icon {
    margin-right: 0.5rem;
  }
}

p {
  font-size: 1.2rem;
  text-align: center;
}

@media (max-width: 768px) {
  .rep-score-list__row-item {
    width: 100%;
  }
}
</style>
