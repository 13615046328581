<template>
  <div class="create-new-pwn-safe-modal-confirmation-step__container">
    <p>
      Awesome! You’re now the proud owner of your first PWN Safe {{ safeName }}!
      <template v-if="linkTo.pwnSafeVideoTutorial">
        If you want to learn more about PWN Safes, we recommend watching our
        <BaseLink
          class="link--primary"
          target="_blank"
          :to="linkTo.pwnSafeVideoTutorial">
          video tutorial
        </BaseLink>.
      </template>
    </p>
    <div class="create-new-pwn-safe-modal-confirmation-step__icons-container">
      <SafeIconSvg
        width="185"
        height="185"
        alt="PWN Safe"/>
      <img
        width="164"
        height="164"
        src="@/assets/icons/doge_pwn.png"
        alt="doge PWN">
    </div>
    <div class="create-new-pwn-safe-modal-confirmation-step__buttons-container">
      <BaseButton
        :button-text="finalButtonText"
        @on-button-click="emit('on-final-button-click')"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/general-components/BaseButton.vue'
import { toRefs } from 'vue'
import linkTo from '@/constants/links'
import useCreateNewPwnSafeModal from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/useCreateNewPwnSafeModal'
import SafeIconSvg from '@/assets/icons/safe.svg'

interface Props {
    finalButtonText: string
}
const props = defineProps<Props>()
const { finalButtonText } = toRefs(props)

const { safeName } = useCreateNewPwnSafeModal()

const emit = defineEmits<{(e: 'on-final-button-click'): void}>()
</script>

<style scoped>
.create-new-pwn-safe-modal-confirmation-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
    line-height: 1.5;
  }

  &__icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
  }

  &__buttons-container {
    display: flex;
    justify-content: end;
  }
}
</style>
