<template>
  <div
    class="create-new-pwn-safe-modal-input-step__container">
    <div>
      You are about to create a PWN Safe, please choose a name you would like to give to your safe.
      The name you choose will be displayed in the PWN Safe Dashboard and while
      interacting with your Safe (This name can be changed later).
    </div>
    <div>
      <h4>PWN Safe Name</h4>
      <div class="create-new-pwn-safe-modal-input-step__fields">
        <BaseInput
          v-model="safeName"
          :append-text="`${safeName.length}/${MAX_SAFE_NAME_LENGTH}`"
          :is-valid-input="!isInputTooLong"
          warning-text="Name too long"
          placeholder="Type PWN Safe name"/>
        <div>
          Network

          <ChainGroupFilter
            :set-chain="handleSelectSingleChain"
            :selected-chains="[chainId!]"
            :disabled-chains="pwnSafeUnsupportedChains"
            :show-all-networks="false"/>
        </div>
      </div>
      <div/>
    </div>
    <div class="create-new-pwn-safe-modal-input-step__buttons-container">
      <BaseButton
        :color="ButtonColor.White"
        button-text="cancel"/>
      <BaseButton
        :is-disabled="isCreateButtonDisabled || isPending"
        :button-text="createSafeButtonText"
        @on-button-click="handleDeployPwnSafe"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor } from '@/general-components/BaseButton.vue'
import BaseInput from '@/general-components/BaseInput.vue'
import useCreateNewPwnSafeModal from '@/modules/pages/pwn-safe/create-new-pwn-safe/modal/useCreateNewPwnSafeModal'
import { computed, markRaw, ref } from 'vue'
import type { Ref } from 'vue'
import useCreateNewPwnSafe from '@/modules/pages/pwn-safe/create-new-pwn-safe/useCreateNewPwnSafe'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { queries } from '@/modules/queries'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import type { SupportedChain } from '@/constants/chains/types'
import type { Address } from 'viem'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import SafeIconSvg from '@/assets/icons/safe.svg'

const { MAX_SAFE_NAME_LENGTH } = useCreateNewPwnSafe()
const { createSafe, isWaitingForTxConfirmation, safeName, chainId } = useCreateNewPwnSafeModal()
const queryClient = useQueryClient()

const { address: userAddress } = useCustomAccount()

const isInputTooLong = computed(() => safeName.value?.length > MAX_SAFE_NAME_LENGTH)

const isCreateButtonDisabled = computed(() => safeName.value?.length === 0 || isInputTooLong.value || isWaitingForTxConfirmation.value)
const createSafeButtonText = computed(() => isWaitingForTxConfirmation.value ? 'Creating Safe' : 'Create Safe')

const pwnSafeUnsupportedChains = computed(() => {
  const results: SupportedChain[] = []
  for (const config of Object.values(CHAINS_CONSTANTS)) {
    if (!config?.pwnSafeContracts) {
      results.push(config.general.chainId)
    }
  }
  return results
})

const handleSelectSingleChain = (selectedChainId: SetChainFilterOption) => {
  if (selectedChainId === 'all') return
  chainId.value = selectedChainId
}

const { mutateAsync, isPending } = useMutation({
  mutationFn: async(step: ToastStep) => { return await createSafe(step) },
  onSuccess(data, variables, context) {
    queryClient.setQueriesData({
      queryKey: queries.safe.list(userAddress as Ref<Address>, chainId as Ref<SupportedChain>).queryKey,
    },
    (existingData: PwnSafe[] | undefined) => [...existingData ?? [], data],
    )
  },
})

let continueFlow
const toast = ref<Toast>()

const handleDeployPwnSafe = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_CREATE_PWN_SAFE](chainId.value!)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      title: 'Deploying PWN Safe',
      customImage: markRaw(SafeIconSvg),
      chainId: chainId.value!,
      steps: [new ToastStep({
        text: 'Deploying...',
        async fn(step) {
          await mutateAsync(step)
          return true
        },
      })],
    }, NotificationFrontendOnlyActionEnum.TX_CREATE_PWN_SAFE, chainId.value!);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}
</script>

<style scoped>
.create-new-pwn-safe-modal-input-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
    line-height: 1.5;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
