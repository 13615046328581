<template>
  <div class="collateral-info__wrapper">
    <BaseLink
      :target="internalLink ? '_self' : '_blank'"
      :to="
        collateral.isErc20
          ? {
            name: RouteName.TokenPage,
            params: {
              chainName: CHAINS_CONSTANTS[collateral.chainId].general.chainName.toLowerCase(),
              contractAddress: collateral.address,
            },
          }
          : getAssetPageRoute(collateral)
      "
      :class="['collateral-info__border', { [`collateral-info__border--${borderColor}`]: !!props.borderColor }]">
      <AssetTooltip
        v-if="hasTooltip"
        :asset="collateral"
        tooltip-placement="top">
        <TokenMedia
          :is-lazy-loaded="isLazyLoaded"
          class="collateral-info__logo"
          width="60"
          height="60"
          :token="collateral"/>

        <AssetChainIcon
          class="collateral-info__chain-icon"
          :chain-id="collateral.chainId"/>
      </AssetTooltip>
      <div
        v-else
        class="collateral-info__token-media-wrapper">
        <TokenMedia
          :is-lazy-loaded="isLazyLoaded"
          width="60"
          height="60"
          class="collateral-info__logo"
          :token="collateral"/>

        <AssetChainIcon
          class="collateral-info__chain-icon-tooltip"
          :chain-id="collateral.chainId"/>
      </div>
      <div class="collateral-info__description">
        <div class="collateral-info__primary-info-wrapper">
          <SafeText
            :class="[
              'collateral-info__primary-info',
              {
                'collateral-info__primary-info--missing': collateral?.isPrimaryInfoMissing,
                'collateral-info__primary-info--no-amount-info': !collateral?.isFungible,
              },
            ]"
            :has-tooltip="hasExplanationTooltips"
            :tooltip-text="displayedPrimaryInfo"
            :text="displayedPrimaryInfo"/>
          <DangerAssetIcon v-if="isAssetFake(collateral)"/>
          <div
            class="collateral-info__elastic-proposal">
            <span v-if="proposalType === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL">(max)</span>
            <BaseTooltip
              v-if="proposalType === V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL"
              class="collateral-info__elastic-proposal-icon"
              has-tooltip
              append-to-body
              :max-width="470"
              :border-color="TooltipBorderColor.White">
              <template #trigger>
                <SimpleProposalSvg
                  width="30"
                  height="30"
                  alt="simple proposal"/>
              </template>
              <div class="collateral-info__elastic-proposal-tooltip">
                {{ proposalTooltipText }}
              </div>
            </BaseTooltip>
          </div>
        </div>
        <BaseLink
          class="collateral-info__secondary-info-wrapper"
          :target="internalLink ? '_self' : '_blank'"
          :to="collectionOrTokenLink">
          <SafeText
            :has-tooltip="hasExplanationTooltips"
            :class="[
              'collateral-info__secondary-info',
              { 'collateral-info__secondary-info--missing': collateral?.isSecondaryInfoMissing },
            ]"
            :text="collateral.secondaryInfo"/>
          <VerificationBadge
            :token="collateral"
            :has-tooltip="hasExplanationTooltips"
            is-generic-verification
            :display-text="false"
            class="collateral-info__verification-badge"/>
        </BaseLink>
      </div>
    </BaseLink>
  </div>
</template>

<script setup lang="ts">
import SafeText from '@/general-components/SafeText.vue'
import { computed, toRefs } from 'vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import AssetTooltip from '@/general-components/AssetTooltip.vue'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import VerificationBadge from '@/general-components/VerificationBadge.vue'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import AssetType from '@/modules/common/assets/AssetType'
import { formatDecimalPoint } from '@/utils/utils'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import SimpleProposalSvg from '@/assets/icons/simple-proposal.svg'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import DangerAssetIcon from '@/general-components/DangerAssetIcon.vue'

interface Props {
  collateral: AssetMetadata;
  borderColor?: 'teal' | 'gray';
  hasTooltip?: boolean;
  isLazyLoaded?: boolean;
  hasExplanationTooltips?: boolean;
  assetAmount?: string | number;
  internalLink?: boolean;
  isOnlyCollection?: boolean;
  proposalType?: string;
  isLendingProposal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hasTooltip: true,
  hasExplanationTooltips: true,
})

const { isAssetFake } = useTopTokensStore()

const displayedPrimaryInfo = computed(() => {
  if (props.assetAmount && !props.collateral.isBundleWithSingleAsset) {
    return `${props.collateral.category === AssetType.ERC721 ? '' : formatDecimalPoint(props.assetAmount)} ${props.collateral.primaryInfoWithoutAmount}`
  }
  return props.collateral.primaryInfoWithoutAmount
})

const { collateral, borderColor, hasTooltip, isLazyLoaded } = toRefs(props)

const collectionOrTokenLink = computed(() => {
  if (props.collateral.isErc20) {
    return props.collateral.routeToErc20AssetPage
  } else {
    return {
      name: RouteName.CollectionByContractAddress,
      params: {
        chainName: CHAINS_CONSTANTS[props.collateral.chainId].general.chainName,
        contractAddress: props.collateral.address,
      },
    }
  }
})

const proposalTooltipText = computed(() => {
  if (props.proposalType === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
    return `Elastic Proposals allow you to ${props.isLendingProposal ? 'borrow' : 'lend'} any amount you desire up to the limit decided upon by the ${props.isLendingProposal ? 'lender' : 'borrower'}.`
  } else {
    // todo: fix this copy based on if it's lending/borrowing proposal?
    return `This proposal requires the ${props.isLendingProposal ? 'borrower' : 'lender'} to take the full offered amount. Partial ${props.isLendingProposal ? 'borrowing' : 'lending'} isn't allowed by the ${props.isLendingProposal ? 'lender' : 'borrower'}.`
  }
})

const { getAssetPageRoute } = useAssetPage()
</script>

<style scoped>
.collateral-info {
  &__wrapper {
    max-width: 100%;
    display: flex;
    align-items: center;
  }

  &__secondary-info-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1rem;
    padding-right: 2rem;
    color: var(--gray-2);
  }

  &__border {
    max-width: 100%;
    display: inherit;
    align-items: inherit;

    text-decoration: none;
  }

  &__border--teal {
    border: solid 1px var(--primary-color-1);
    padding: 0.8rem 4rem 0.8rem 0.8rem;
  }

  &__border--gray {
    border: solid 1px var(--gray);
    padding: 0.8rem 4rem 0.8rem 0.8rem;
  }

  &__logo {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    object-fit: contain;
    margin-right: 0.75rem;
  }

  &__description {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.125rem;
  }

  &__primary-info {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: inherit;

    &--missing {
      color: var(--negative-1);
    }

    &--no-amount-info {
      padding-right: 2rem;
    }
  }

  &__primary-info-amount-wrapper {
    display: inline-flex;
    align-items: center;
    margin-left: 0.75rem;
    padding-right: 2rem;
  }

  &__secondary-info {
    display: block;
    font-size: 1rem;
    max-width: 240px;
    color: inherit;
    width: auto;
    text-decoration: underline;

    &--missing {
      color: var(--negative-1);
    }
  }

  &__primary-info-wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;

    max-width: 18rem;
  }

  &__token-media-wrapper {
    position: relative;
  }

  &__chain-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    bottom: 0;
    right: 0.5rem;

    &-tooltip {
      position: absolute;
      bottom: 0;
      right: 0.5rem;
      padding: 0.2rem;
    }
  }

  &__elastic-proposal {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__elastic-proposal-tooltip {
    text-align: center;
  }
}
</style>
