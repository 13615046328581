import { SupportedChain } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { Address } from 'viem'
import { compareAddresses } from '@/utils/utils'
import { DepositProtocol } from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

interface DepositedAsset {
  underlyingToken: Address;
  aToken: Address;
  pool: Address;
  protocol?: DepositProtocol;
}

interface DepositedAssets {
  [key: string]: {
    [chain in SupportedChain]?: DepositedAsset[]
  }
}

// Always add new supported assets by the protocols here
// Aave has pool per chain, compound has pool per asset
export const DEPOSITED_ASSETS: DepositedAssets = {
  aave: {
    [SupportedChain.Sepolia]: [
      { underlyingToken: '0xff34b3d4aee8ddcd6f9afffb6fe49bd371b8a357', aToken: '0x29598b72eb5CeBd806C5dCD549490FdA35B13cD8', pool: CHAINS_CONSTANTS[SupportedChain.Sepolia].aave!.pool }, // DAI
      { underlyingToken: '0x94a9d9ac8a22534e3faca9f4e7f2e2cf85d5e4c8', aToken: '0x16dA4541aD1807f4443d92D26044C1147406EB80', pool: CHAINS_CONSTANTS[SupportedChain.Sepolia].aave!.pool }, // USDC
      { underlyingToken: '0x1804Bf30507dc2EB3bDEbbbdd859991EAeF6EefF', aToken: '0x29f2d40b0605204364af54ec677bd022da425d03', pool: CHAINS_CONSTANTS[SupportedChain.Sepolia].aave!.pool }, // WBTC
      { underlyingToken: '0x88541670e55cc00beefd87eb59edd1b7c511ac9a', aToken: '0x6b8558764d3b7572136F17174Cb9aB1DDc7E1259', pool: CHAINS_CONSTANTS[SupportedChain.Sepolia].aave!.pool }, // AAVE
      { underlyingToken: '0xaa8e23fb1079ea71e0a56f48a2aa51851d8433d0', aToken: '0xAF0F6e8b0Dc5c913bbF4d14c22B4E78Dd14310B6', pool: CHAINS_CONSTANTS[SupportedChain.Sepolia].aave!.pool }, // USDT
      { underlyingToken: '0xc558dbdd856501fcd9aaf1e62eae57a9f0629a3c', aToken: '0x5b071b590a59395fE4025A0Ccc1FcC931AAc1830', pool: CHAINS_CONSTANTS[SupportedChain.Sepolia].aave!.pool }, // WETH
    ],
    [SupportedChain.Ethereum]: [
      { underlyingToken: '0x6b175474e89094c44da98b954eedeac495271d0f', aToken: '0x018008bfb33d285247A21d44E50697654f754e63', pool: CHAINS_CONSTANTS[SupportedChain.Ethereum].aave!.pool }, // DAI
      { underlyingToken: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', aToken: '0x4d5F47FA6A74757f35C14fD3a6Ef8E3C9BC514E8', pool: CHAINS_CONSTANTS[SupportedChain.Ethereum].aave!.pool }, // WETH
      { underlyingToken: '0xdac17f958d2ee523a2206206994597c13d831ec7', aToken: '0x23878914EFE38d27C4D67Ab83ed1b93A74D4086a', pool: CHAINS_CONSTANTS[SupportedChain.Ethereum].aave!.pool }, // USDT
      { underlyingToken: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', aToken: '0x98C23E9d8f34FEFb1B7BD6a91B7FF122F4e16F5c', pool: CHAINS_CONSTANTS[SupportedChain.Ethereum].aave!.pool }, // USDC
      { underlyingToken: '0x5f98805a4e8be255a32880fdec7f6728c6568ba0', aToken: '0x3Fe6a295459FAe07DF8A0ceCC36F37160FE86AA9', pool: CHAINS_CONSTANTS[SupportedChain.Ethereum].aave!.pool }, // LUSD
      // todo: add other assets?
    ],
    [SupportedChain.Polygon]: [
      { underlyingToken: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063', aToken: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE', pool: CHAINS_CONSTANTS[SupportedChain.Polygon].aave!.pool }, // DAI
      { underlyingToken: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', aToken: '0x625E7708f30cA75bfd92586e17077590C60eb4cD', pool: CHAINS_CONSTANTS[SupportedChain.Polygon].aave!.pool }, // USDC.e
      { underlyingToken: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', aToken: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620', pool: CHAINS_CONSTANTS[SupportedChain.Polygon].aave!.pool }, // USDT
      { underlyingToken: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359', aToken: '0xA4D94019934D8333Ef880ABFFbF2FDd611C762BD', pool: CHAINS_CONSTANTS[SupportedChain.Polygon].aave!.pool }, // USDC
      // todo: add other assets?
    ],
    [SupportedChain.Arbitrum]: [
      { underlyingToken: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', aToken: '0x625E7708f30cA75bfd92586e17077590C60eb4cD', pool: CHAINS_CONSTANTS[SupportedChain.Arbitrum].aave!.pool }, // USDC.e
      { underlyingToken: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1', aToken: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE', pool: CHAINS_CONSTANTS[SupportedChain.Arbitrum].aave!.pool }, // DAI
      { underlyingToken: '0xaf88d065e77c8cc2239327c5edb3a432268e5831', aToken: '0x724dc807b04555b71ed48a6896b6F41593b8C637', pool: CHAINS_CONSTANTS[SupportedChain.Arbitrum].aave!.pool }, // USDC
      { underlyingToken: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9', aToken: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620', pool: CHAINS_CONSTANTS[SupportedChain.Arbitrum].aave!.pool }, // USDT
      // todo: add other assets?
    ],
    [SupportedChain.Base]: [
      { underlyingToken: '0x4200000000000000000000000000000000000006', aToken: '0xD4a0e0b9149BCee3C920d2E00b5dE09138fd8bb7', pool: CHAINS_CONSTANTS[SupportedChain.Base].aave!.pool }, // WETH
      { underlyingToken: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', aToken: '0x4e65fE4DbA92790696d040ac24Aa414708F5c0AB', pool: CHAINS_CONSTANTS[SupportedChain.Base].aave!.pool }, // USDC
      { underlyingToken: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca', aToken: '0x0a1d576f3eFeF75b330424287a95A366e8281D54', pool: CHAINS_CONSTANTS[SupportedChain.Base].aave!.pool }, // USDbC
      // todo: add other assets?
    ],
    [SupportedChain.Optimism]: [
      { underlyingToken: '0x4200000000000000000000000000000000000006', aToken: '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8', pool: CHAINS_CONSTANTS[SupportedChain.Optimism].aave!.pool }, // WETH
      { underlyingToken: '0x0b2c639c533813f4aa9d7837caf62653d097ff85', aToken: '0x38d693cE1dF5AaDF7bC62595A37D667aD57922e5', pool: CHAINS_CONSTANTS[SupportedChain.Optimism].aave!.pool }, // USDC
      { underlyingToken: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58', aToken: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620', pool: CHAINS_CONSTANTS[SupportedChain.Optimism].aave!.pool }, // USDT
      { underlyingToken: '0x7f5c764cbc14f9669b88837ca1490cca17c31607', aToken: '0x625E7708f30cA75bfd92586e17077590C60eb4cD', pool: CHAINS_CONSTANTS[SupportedChain.Optimism].aave!.pool }, // USDC.e
      { underlyingToken: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1', aToken: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE', pool: CHAINS_CONSTANTS[SupportedChain.Optimism].aave!.pool }, // DAI
      { underlyingToken: '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9', aToken: '0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97', pool: CHAINS_CONSTANTS[SupportedChain.Optimism].aave!.pool }, // sUSD
      // todo: add other assets?
    ],
    [SupportedChain.Bsc]: [
      { underlyingToken: '0x55d398326f99059ff775485246999027b3197955', aToken: '0xa9251ca9DE909CB71783723713B21E4233fbf1B1', pool: CHAINS_CONSTANTS[SupportedChain.Bsc].aave!.pool }, // USDT
      { underlyingToken: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', aToken: '0x00901a076785e0906d1028c7d6372d247bec7d61', pool: CHAINS_CONSTANTS[SupportedChain.Bsc].aave!.pool }, // USDC
      { underlyingToken: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', aToken: '0x9B00a09492a626678E5A3009982191586C444Df9', pool: CHAINS_CONSTANTS[SupportedChain.Bsc].aave!.pool }, // BNB
      { underlyingToken: '0xc5f0f7b66764f6ec8c8dff7ba683102295e16409', aToken: '0x75bd1A659bdC62e4C313950d44A2416faB43E785', pool: CHAINS_CONSTANTS[SupportedChain.Bsc].aave!.pool }, // FDUSD
      // todo: add other assets?
    ],
  },
  compound: {
    [SupportedChain.Sepolia]: [
      { underlyingToken: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238', aToken: '0xAec1F48e02Cfb822Be958B68C7957156EB3F0b6e', pool: '0xAec1F48e02Cfb822Be958B68C7957156EB3F0b6e' }, // USDC
      { underlyingToken: '0x2D5ee574e710219a521449679A4A7f2B43f046ad', aToken: '0x2943ac1216979aD8dB76D9147F64E61adc126e96', pool: '0x2943ac1216979aD8dB76D9147F64E61adc126e96' }, // WETH

    ],
    [SupportedChain.Ethereum]: [
      { underlyingToken: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', aToken: '0xc3d688B66703497DAA19211EEdff47f25384cdc3', pool: '0xc3d688B66703497DAA19211EEdff47f25384cdc3' }, // USDC
      { underlyingToken: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', aToken: '0xA17581A9E3356d9A858b789D68B4d866e593aE94', pool: '0xA17581A9E3356d9A858b789D68B4d866e593aE94' }, // WETH
      { underlyingToken: '0xdAC17F958D2ee523a2206206994597C13D831ec7', aToken: '0x3Afdc9BCA9213A35503b077a6072F3D0d5AB0840', pool: '0x3Afdc9BCA9213A35503b077a6072F3D0d5AB0840' }, // USDT
      { underlyingToken: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0', aToken: '0x3D0bb1ccaB520A66e607822fC55BC921738fAFE3', pool: '0x3D0bb1ccaB520A66e607822fC55BC921738fAFE3' }, // wstETH
    ],
    [SupportedChain.Polygon]: [
      { underlyingToken: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', aToken: '0xF25212E676D1F7F89Cd72fFEe66158f541246445', pool: '0xF25212E676D1F7F89Cd72fFEe66158f541246445' }, // USDC
      { underlyingToken: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', aToken: '0xaeB318360f27748Acb200CE616E389A6C9409a07', pool: '0xaeB318360f27748Acb200CE616E389A6C9409a07' }, // USDT
    ],
    [SupportedChain.Arbitrum]: [
      { underlyingToken: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', aToken: '0xA5EDBDD9646f8dFF606d7448e414884C7d905dCA', pool: '0xA5EDBDD9646f8dFF606d7448e414884C7d905dCA' }, // USDC.e
      { underlyingToken: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', aToken: '0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf', pool: '0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf' }, // USDC
      { underlyingToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', aToken: '0x6f7D514bbD4aFf3BcD1140B7344b32f063dEe486', pool: '0x6f7D514bbD4aFf3BcD1140B7344b32f063dEe486' }, // WETH
      { underlyingToken: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', aToken: '0xd98Be00b5D27fc98112BdE293e487f8D4cA57d07', pool: '0xd98Be00b5D27fc98112BdE293e487f8D4cA57d07' }, // USDT

    ],
    [SupportedChain.Base]: [
      { underlyingToken: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', aToken: '0xb125E6687d4313864e53df431d5425969c15Eb2F', pool: '0xb125E6687d4313864e53df431d5425969c15Eb2F' }, // USDC
      { underlyingToken: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA', aToken: '0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf', pool: '0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf' }, // USDCbC
      { underlyingToken: '0x4200000000000000000000000000000000000006', aToken: '0x46e6b214b524310239732D51387075E0e70970bf', pool: '0x46e6b214b524310239732D51387075E0e70970bf' }, // WETH
    ],
    [SupportedChain.Optimism]: [
      { underlyingToken: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85', aToken: '0x2e44e174f7D53F0212823acC11C01A11d58c5bCB', pool: '0x2e44e174f7D53F0212823acC11C01A11d58c5bCB' }, // USDC
      { underlyingToken: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58', aToken: '0x995E394b8B2437aC8Ce61Ee0bC610D617962B214', pool: '0x995E394b8B2437aC8Ce61Ee0bC610D617962B214' }, // USDT
      { underlyingToken: '0x4200000000000000000000000000000000000006', aToken: '0xE36A30D249f7761327fd973001A32010b521b6Fd', pool: '0xE36A30D249f7761327fd973001A32010b521b6Fd' }, // WETH

    ],
  },
}

export const getDepositedAssetByAToken = (aToken: Address, chainId: SupportedChain) => {
  const aaveAssets = DEPOSITED_ASSETS.aave[chainId]
  const compoundAssets = DEPOSITED_ASSETS.compound[chainId]
  if (!aaveAssets && !compoundAssets) return undefined

  const aaveAsset = aaveAssets?.find((asset) => compareAddresses(asset.aToken, aToken))
  const compoundAsset = compoundAssets?.find((asset) => compareAddresses(asset.aToken, aToken))
  if (aaveAsset) {
    return { ...aaveAsset, protocol: DepositProtocol.AAVE } as DepositedAsset
  } else if (compoundAsset) {
    return { ...compoundAsset, protocol: DepositProtocol.COMPOUND } as DepositedAsset
  }
  return undefined
}

export const getAtokenAddress = (underlyingToken: Address, chainId: SupportedChain, sourceOfFunds: Address) => {
  const aaveAssets = DEPOSITED_ASSETS.aave[chainId]
  const compoundAssets = DEPOSITED_ASSETS.compound[chainId]
  if (!aaveAssets && !compoundAssets) return undefined

  const aaveAsset = aaveAssets?.find((asset) => compareAddresses(asset.underlyingToken, underlyingToken) && compareAddresses(asset.pool, sourceOfFunds))
  const compoundAsset = compoundAssets?.find((asset) => compareAddresses(asset.underlyingToken, underlyingToken) && compareAddresses(asset.pool, sourceOfFunds))
  if (aaveAsset) {
    return aaveAsset.aToken
  } else if (compoundAsset) {
    return compoundAsset.aToken
  }
  return undefined
}

export const createDepositedAssets = (parsedThesisAssets: AssetWithAmount[], assetsToFilterFrom: AssetWithAmount[]) => {
  const transformedDepositedAssets: AssetWithAmount[] = []
  const assetsToFilter: AssetWithAmount[] = []

  for (const asset of parsedThesisAssets) {
    const depositedAssetsAave = DEPOSITED_ASSETS.aave[asset.chainId]
    const depositedAssetsCompound = DEPOSITED_ASSETS.compound[asset.chainId]
    const depositedAssets = [...(depositedAssetsAave || []), ...(depositedAssetsCompound || [])]
    if (!depositedAssets) continue

    const depositedAsset = getDepositedAssetByAToken(asset.address, asset.chainId)
    if (depositedAsset) {
      assetsToFilter.push(asset)
      const underlyingAsset = assetsToFilterFrom.find((v) => compareAddresses(v.address, depositedAsset.underlyingToken))
      if (!underlyingAsset) {
        throw new Error(`Underlying asset not found for ${asset.address}`)
      }
      const assetToAdd = new AssetWithAmount({
        ...underlyingAsset,
        sourceOfFunds: depositedAsset.pool,
        protocol: depositedAsset.protocol,
        aTokenAddress: depositedAsset.aToken,
      })
      transformedDepositedAssets.push(assetToAdd)
    }
  }
  return { transformedDepositedAssets, assetsToFilter }
}
