import { sortAssetsByAmount, sortAssetsByPrimaryInfo, sortAssetsByVerification } from '@/general-components/sorting/SortFunctions'

export enum SortOption {
  Name = 'name',
  Verification = 'verification',
  Amount = 'amount'
}

export const SORT_OPTIONS_LOOKUP = {
  [SortOption.Name]: sortAssetsByPrimaryInfo,
  [SortOption.Verification]: sortAssetsByVerification,
  [SortOption.Amount]: sortAssetsByAmount,
}
