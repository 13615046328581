/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

export type AssetCategoryBackendSchema = typeof AssetCategoryBackendSchema[keyof typeof AssetCategoryBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetCategoryBackendSchema = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_420: 420,
  NUMBER_421: 421,
} as const;
