import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Dashboard from '@/modules/pages/dashboard/Dashboard.vue'
import NftPage from '@/revamp/pages/nft/NftPage.vue'
import RouteName from '@/router/RouteName'
import PageNotFound from '@/modules/pages/not-found/PageNotFound.vue'
import TokenPage from '@/revamp/pages/token/TokenPage.vue'
import NotificationCenter from '@/modules/pages/notification-center/NotificationCenter.vue'
import NotificationCenterSettings from '@/modules/pages/notification-center/settings/NotificationCenterSettings.vue'
import TokenBundler from '@/modules/pages/token-bundler/TokenBundler.vue'
import UnpackBundle from '@/modules/pages/token-bundler/unpack-bundle/UnpackBundle.vue'
import UnpackBundleChooseBundle from '@/modules/pages/token-bundler/unpack-bundle/UnpackBundleChooseBundle.vue'
import UnpackBundleSubmit from '@/modules/pages/token-bundler/unpack-bundle/UnpackBundleSubmit.vue'
import BundleAssets from '@/modules/pages/token-bundler/bundle-assets/BundleAssets.vue'
import BundleAssetsChooseAssets from '@/modules/pages/token-bundler/bundle-assets/BundleAssetsChooseAssets.vue'
import BundleAssetsSubmit from '@/modules/pages/token-bundler/bundle-assets/BundleAssetsSubmit.vue'
import PwnSafeDashboard from '@/modules/pages/pwn-safe/pwn-safe-dashboard/PwnSafeDashboard.vue'
import PwnSafeCurrentWalletAssets from '@/modules/pages/pwn-safe/current-wallet/PwnSafeCurrentWalletAssets.vue'
import PwnSafeDetailAssets from '@/modules/pages/pwn-safe/pwn-safe-detail/safe-assets/PwnSafeDetailAssets.vue'
import PwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/PwnSafeTokenize.vue'
import PwnSafeDetailSettings from '@/modules/pages/pwn-safe/pwn-safe-detail/settings/PwnSafeDetailSettings.vue'
import PwnSafeCreateNewPwnSafe from '@/modules/pages/pwn-safe/create-new-pwn-safe/CreateNewPwnSafe.vue'
import PwnSafeCreateConfirmation from '@/modules/pages/pwn-safe/create-new-pwn-safe/CreateNewPwnSafeConfirmation.vue'
import CreateNewPwnSafeInput from '@/modules/pages/pwn-safe/create-new-pwn-safe/CreateNewPwnSafeInput.vue'
import PwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/PwnSafeTransfer.vue'
import PwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/PwnSafeClaimAndBurn.vue'
import CollectionPage from '@/revamp/pages/collection/CollectionPage.vue'
import PwnExplorerPage from '@/modules/pages/pwn-explorer/PwnExplorerPage.vue'
import PwnExplorerNftTab from '@/modules/pages/pwn-explorer/tabs/PwnExplorerNftTab.vue'
import PwnExplorerErc20Tab from '@/modules/pages/pwn-explorer/tabs/PwnExplorerErc20Tab.vue'
import CollectionAssetsTab from '@/revamp/pages/collection/tabs/CollectionAssetsTab.vue'
import { useRouterStackStore } from './useRouterStack'
import SpaceExplorer from '@/modules/pages/spaces/SpaceExplorer.vue'
import RevampHomepage from '@/revamp/pages/Homepage.vue'
import ProposalDetailPage from '@/revamp/pages/ProposalDetailPage.vue'
import ResolveOldLoanRedirect from '@/revamp/pages/ResolveOldLoanRedirect.vue'
import LoanDetailPage from '@/revamp/pages/LoanDetailPage.vue'
import ProposalsTable from '@/revamp/components/tables/ProposalsTable.vue'
import ThesisPage from '@/revamp/pages/thesis/ThesisPage.vue'
import ThesesPage from '@/revamp/pages/thesis/ThesesPage.vue'
import PwnMatcher from '@/modules/pages/PwnMatcher.vue'
import ClaimBasenamesPage from '@/revamp/pages/claim-rep/basenames/ClaimBasenames.vue'
import ClaimPushPage from '@/revamp/pages/claim-rep/push/ClaimPush.vue'
import ClaimAavePage from '@/revamp/pages/claim-rep/aave/ClaimAave.vue'
// coming soon
// import LeaderboardPage from '@/revamp/pages/leaderboard/LeaderboardPage.vue'

const routes: RouteRecordRaw[] = [
  {
    name: RouteName.Dashboard,
    path: '/dashboard/:user?',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    },
  },
  {
    name: RouteName.Thesis,
    path: '/strategy/:id',
    component: ThesisPage,
    meta: {
      title: 'Strategy',
    },
  },
  {
    name: RouteName.ThesisList,
    path: '/strategies',
    component: ThesesPage,
    meta: {
      title: 'Strategies',
    },
  },
  {
    name: RouteName.NotFound,
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
    meta: {
      title: 'Page not found',
    },
  },
  {
    name: RouteName.PwnMatcher,
    path: '/pwn-matcher/:id',
    component: PwnMatcher,
    meta: {
      title: 'Pwn Matcher',
    },
  },
  {
    name: RouteName.NftPage,
    path: '/asset/:chainName/:contractAddress/:tokenId',
    alias: '/asset/:chainName/:contractAddress/:tokenId/loan-:loanId?', // todo try regex for this
    component: NftPage,
    children: [
      {
        name: RouteName.RevampNftPageDetails,
        path: 'details',
        component: NftPage,
        props: { type: 'details' },
      },
      {
        name: RouteName.RevampNftPageBorrowing,
        path: 'borrow',
        component: ProposalsTable,
        props: { type: 'borrow' },
      },
      {
        name: RouteName.RevampNftPageLending,
        path: 'lend',
        component: ProposalsTable,
        props: { type: 'lend' },
      },
    ],
    meta: {
      title: 'Asset overview',
    },
  },
  {
    name: RouteName.TokenPage,
    path: '/asset/:chainName/:contractAddress',
    alias: '/asset/:chainName/:contractAddress/loan-:loanId?', // todo try regex for this
    component: TokenPage,
    props: true,
    children: [
      {
        name: RouteName.RevampTokenBorrowing,
        path: 'borrow',
        component: ProposalsTable,
        props: { type: 'borrow' },
      },
      {
        name: RouteName.RevampTokenLending,
        path: 'lend',
        component: ProposalsTable,
        props: { type: 'lend' },
      },
    ],
    meta: {
      title: 'Asset overview',
    },
  },
  {
    name: RouteName.NotificationCenter,
    path: '/notification-center',
    component: NotificationCenter,
    meta: {
      title: 'Notification center',
    },
  },
  {
    name: RouteName.NotificationCenterSettings,
    path: '/notification-center-settings',
    component: NotificationCenterSettings,
    meta: {
      title: 'Notification Settings',
    },
  },
  {
    name: RouteName.TokenBundler,
    path: '/token-bundler',
    component: TokenBundler,
    meta: {
      title: 'Token Bundler',
    },
  },
  {
    name: RouteName.BundleAssets,
    path: '/token-bundler/bundle-assets',
    component: BundleAssets,
    meta: {
      title: 'Bundle Assets',
    },
    children: [
      {
        name: RouteName.BundleAssetsChooseAssets,
        path: 'choose-assets',
        component: BundleAssetsChooseAssets,
      },
      {
        name: RouteName.BundleAssetsSubmit,
        path: 'submit',
        component: BundleAssetsSubmit,
      },
    ],
  },
  {
    path: '/token-bundler/unpack-bundle',
    component: UnpackBundle,
    meta: {
      title: 'Unpack Bundle',
    },
    children: [
      {
        name: RouteName.UnpackBundleChooseBundle,
        path: 'choose-bundle',
        component: UnpackBundleChooseBundle,
      },
      {
        name: RouteName.UnpackBundleSubmit,
        path: 'submit',
        component: UnpackBundleSubmit,
      },
    ],
  },
  {
    name: RouteName.PwnSafe,
    path: '/pwn-safe',
    component: PwnSafeDashboard,
    meta: {
      title: 'PWN Safe',
    },
  },
  {
    path: '/pwn-safe/create-new',
    component: PwnSafeCreateNewPwnSafe,
    meta: {
      title: 'Pwn Safe - Create new',
    },
    children: [
      {
        name: RouteName.PwnSafeCreateNewPwnSafe,
        path: '',
        component: CreateNewPwnSafeInput,
      },
      {
        name: RouteName.PwnSafeCreateConfirmation,
        path: 'confirmation',
        component: PwnSafeCreateConfirmation,
      },
    ],
  },
  {
    name: RouteName.PwnSafeCurrentWalletAssets,
    path: '/pwn-safe/current-wallet-assets',
    component: PwnSafeCurrentWalletAssets,
    meta: {
      title: 'Pwn Safe - Current wallet assets',
    },
  },
  {
    name: RouteName.PwnSafeTransfer,
    path: '/pwn-safe/transfer',
    component: PwnSafeTransfer,
    meta: {
      title: 'Pwn Safe - Transfer',
    },
  },
  {
    name: RouteName.PwnSafeClaimAndBurn,
    path: '/pwn-safe/claim-and-burn',
    component: PwnSafeClaimAndBurn,
    meta: {
      title: 'Pwn Safe - Claim & Burn',
    },
  },
  {
    name: RouteName.PwnSafeDetailAssets,
    path: '/pwn-safe/:chainId/:safeAddress',
    component: PwnSafeDetailAssets,
    meta: {
      title: 'Pwn Safe - Safe assets',
    },
  },
  {
    name: RouteName.PwnSafeTokenize,
    path: '/pwn-safe/tokenize',
    component: PwnSafeTokenize,
    meta: {
      title: 'Pwn Safe - Tokenize',
    },
  },
  {
    name: RouteName.PwnSafeDetailSettings,
    path: '/pwn-safe/safe-settings',
    component: PwnSafeDetailSettings,
    meta: {
      title: 'Pwn Safe - Safe settings',
    },
  },
  {
    name: RouteName.PwnExplorerPage,
    path: '/explorer',
    component: PwnExplorerPage,
    meta: {
      title: 'Explorer',
    },
    children: [
      {
        name: RouteName.PwnExplorerPage,
        path: '',
        redirect: {
          name: RouteName.PwnExplorerPageCollections,
        },
      },
      {
        name: RouteName.PwnExplorerPageCollections,
        path: '',
        component: PwnExplorerNftTab,
      },
      {
        name: RouteName.PwnExplorerPageAssets,
        path: 'tokens',
        component: PwnExplorerErc20Tab,
      },
      {
        name: RouteName.PwnExplorerPageLoans,
        path: 'loans',
        redirect(to) {
          return {
            name: RouteName.RevampHomepage,
          }
        },
      },
    ],
  },
  {
    path: '/collection',
    meta: {
      title: 'Collection',
    },
    children: [
      {
        name: RouteName.CollectionByContractAddress,
        path: ':chainName/:contractAddress/',
        component: CollectionPage,
        children: [
          {
            name: RouteName.CollectionAssetsTab,
            path: 'assets',
            component: CollectionAssetsTab,
          },
          {
            name: RouteName.RevampCollectionProposalsBorrowing,
            path: 'borrow',
            component: ProposalsTable,
            props: { type: 'borrow' },
          },
          {
            name: RouteName.RevampCollectionProposalsLending,
            path: 'lend',
            component: ProposalsTable,
            props: { type: 'lend' },
          },
        ],
      },
      {
        name: RouteName.CollectionBySlug,
        path: ':slug/',
        component: CollectionPage,
      },
    ],
  },
  {
    name: RouteName.Spaces,
    path: '/spaces',
    component: SpaceExplorer,
    meta: {
      title: 'Spaces',
    },
  },
  // REVAMP //
  {
    name: 'old-lend',
    path: '/lend',
    redirect: { name: RouteName.RevampHomepage },
  },
  {
    name: RouteName.RevampHomepage,
    path: '/',
    component: RevampHomepage,
    meta: {
      title: 'Homepage',
    },
    redirect: { name: RouteName.RevampLend },
    children: [
      {
        name: RouteName.RevampLend,
        path: '',
        component: ProposalsTable,
        props: { type: 'lend' },
      },
      {
        name: RouteName.RevampBorrow,
        path: 'borrow',
        component: ProposalsTable,
        props: { type: 'borrow' },
        alias: 'revamp-borrow',
      },
    ],
  },
  {
    name: RouteName.ProposalDetail,
    path: '/proposal/:id',
    component: ProposalDetailPage,
    meta: {
      title: 'Proposal',
    },
  },
  {
    name: 'fallback-loan-redirect',
    path: '/loan/:id',
    component: ResolveOldLoanRedirect,
  },
  {
    path: '/loan/:chainName/:loanTokenContractAddress/:id',
    name: RouteName.RevampLoanDetail,
    component: LoanDetailPage,
    props: true,
    meta: {
      title: 'Loan detail',
    },
  },
  {
    name: RouteName.ClaimRep,
    path: '/claim-rep',
    component: ClaimBasenamesPage,
    meta: {
      title: 'Claim',
    },
  },
  {
    name: RouteName.ClaimRepBasenames,
    path: '/claim-rep/basenames',
    component: ClaimBasenamesPage,
    meta: {
      title: 'Claim',
    },
  },
  {
    name: RouteName.ClaimRepPush,
    path: '/claim-rep/push',
    component: ClaimPushPage,
    meta: {
      title: 'Claim',
    },
  },
  {
    name: RouteName.ClaimRepAave,
    path: '/claim-rep/aave',
    component: ClaimAavePage,
    meta: {
      title: 'Claim',
    },
  },
  // coming soon //
  // {
  //   name: RouteName.Leaderboard,
  //   path: '/leaderboard',
  //   component: LeaderboardPage,
  //   meta: {
  //     title: 'Leaderboard',
  //   },
  // },
]

const router = createRouter({
  history: import.meta.env.VITE_PWN_USE_HASH_ROUTER === 'true' ? createWebHashHistory() : createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    const routesExcludedFromScrollToTop = [
      RouteName.PwnExplorerPageCollections,
      RouteName.PwnExplorerPageLoans,
      RouteName.PwnExplorerPageAssets,
      RouteName.Lend,
      RouteName.LendExplorerPageCollections,
      RouteName.LendExplorerPageAssets,
      RouteName.LendExplorerPageLoans,
      RouteName.RevampBorrow,
      RouteName.RevampLend,
      RouteName.RevampTokenBorrowing,
      RouteName.RevampTokenLending,
      RouteName.RevampNftPageDetails,
      RouteName.RevampNftPageBorrowing,
      RouteName.RevampNftPageLending,
      RouteName.RevampCollectionProposalsBorrowing,
      RouteName.RevampCollectionProposalsLending,
      RouteName.CollectionAssetsTab,
    ]
    if (routesExcludedFromScrollToTop.includes(to.name as RouteName)) {
      return
    }
    if (savedPosition) return savedPosition
    return to.hash ? { el: to.hash, behavior: 'smooth' } : { top: 0 }
  },
})

router.beforeEach((to) => {
  const routerStackStore = useRouterStackStore()
  // @ts-expect-error TS(2345) FIXME: Argument of type 'RouteLocationNormalized' is not ... Remove this comment to see the full error message
  routerStackStore.routerStack.unshift(to)
  document.title = `${to.meta.title} | PWN` || 'PWN'
})

export default router
