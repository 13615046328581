import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import formatToMultiTokenTuple from '@/utils/formatToMultiTokenTuple'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { parseEventLogs } from 'viem'
import type { Address, TransactionReceipt } from 'viem'
import type { MultiToken } from '@/contracts/structs'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import { assetTransferRightsAbi } from '@/contracts/generated'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export interface ParsedTransferViaAtrEvent {
  from: Address
  to: Address
  atrTokenId: bigint
  transferredAsset: MultiToken
}

export default function useAtrContract() {
  const extractTransferViaAtrEventDataFromReceipt = (receipt: TransactionReceipt): ParsedTransferViaAtrEvent[] | undefined => {
    let parsedLogs = parseEventLogs({
      abi: assetTransferRightsAbi,
      eventName: 'TransferViaATR',
      logs: receipt.logs,
    })

    // for some weird reason the parsedLogs also included the 'Transfer' event
    parsedLogs = parsedLogs.filter(parsedLog => parsedLog.eventName === 'TransferViaATR')

    const mappedLogsTransferViaAtr: ParsedTransferViaAtrEvent[] = parsedLogs.map(parsedLog => {
      return {
        from: parsedLog.args.from,
        to: parsedLog.args.to,
        atrTokenId: parsedLog.args.atrTokenId,
        transferredAsset: {
          category: parsedLog.args.asset.category,
          assetAddress: parsedLog.args.asset.assetAddress,
          amount: parsedLog.args.asset.category === 1 ? 1n : parsedLog.args.asset.amount,
          id: parsedLog.args.asset.id,
        },
      }
    })
    return mappedLogsTransferViaAtr
  }

  const mintAtrToken = async (asset: AssetWithAmount, safeAddress: Address, step: ToastStep): Promise<ParsedTransferViaAtrEvent | undefined> => {
    const [formattedAsset] = formatToMultiTokenTuple([asset])

    // TODO resolve contract wallet txs... also probably safe tx service
    const receipt = await sendTransaction({
      abi: assetTransferRightsAbi,
      address: CHAINS_CONSTANTS[asset.chainId].pwnSafeContracts.assetTransferRights,
      functionName: 'mintAssetTransferRightsToken',
      // @ts-expect-error FIXME: strictNullChecks
      args: [formattedAsset],
      chainId: asset.chainId,
    }, { step, safeAddress })

    // returning [0] as just one ATR token has been minted
    return extractTransferViaAtrEventDataFromReceipt(receipt)?.[0]
  }

  const mintAtrTokenBatch = async (assets: AssetWithAmount[], safeAddress: Address, step: ToastStep): Promise<ParsedTransferViaAtrEvent[]> => {
    const formattedAssets = formatToMultiTokenTuple(assets)
    const assetChainId = assets[0].chainId

    const receipt = await sendTransaction({
      abi: assetTransferRightsAbi,
      functionName: 'mintAssetTransferRightsTokenBatch',
      chainId: assetChainId,
      // @ts-expect-error FIXME: strictNullChecks
      args: [formattedAssets],
      address: CHAINS_CONSTANTS[assetChainId].pwnSafeContracts.assetTransferRights,
    }, { step, safeAddress })

    // @ts-expect-error TS(2322) FIXME: Type 'ParsedTransferViaAtrEvent[] | undefined' is ... Remove this comment to see the full error message
    return extractTransferViaAtrEventDataFromReceipt(receipt)
  }

  const burnAtrToken = async (atrToken: AssetWithAmount, safeAddress: Address, step?: ToastStep): Promise<TransactionReceipt> => {
    const receipt = await sendTransaction({
      abi: assetTransferRightsAbi,
      functionName: 'burnAssetTransferRightsToken',
      args: [atrToken.tokenId],
      chainId: atrToken.chainId,
      address: CHAINS_CONSTANTS[atrToken.chainId].pwnSafeContracts.assetTransferRights,
    }, { step, safeAddress })

    return receipt
  }

  const burnAtrTokenBatch = async (assets: AssetWithAmount[], safeAddress: Address, step?: ToastStep): Promise<TransactionReceipt> => {
    const atrTokenIds = assets.map(({ tokenId }) => tokenId)
    const atrTokensChainId = assets[0].chainId

    const receipt = await sendTransaction({
      abi: assetTransferRightsAbi,
      address: CHAINS_CONSTANTS[atrTokensChainId].pwnSafeContracts.assetTransferRights,
      args: [atrTokenIds],
      chainId: atrTokensChainId,
      functionName: 'burnAssetTransferRightsTokenBatch',
    }, { step, safeAddress })
    return receipt
  }

  type ClaimAtrTokenParams = { isContractWalletTx: boolean, atrToken: AssetWithAmount, safeAddress: Address, isBurn: boolean, step?: ToastStep }

  const claimAtrToken = async ({ atrToken, safeAddress, isBurn, step }: ClaimAtrTokenParams): Promise<TransactionReceipt> => {
    return await sendTransaction({
      abi: assetTransferRightsAbi,
      functionName: 'claimAssetFrom',
      args: [atrToken.assetOfAtrToken.ownerAddress, atrToken.tokenId, isBurn],
      address: CHAINS_CONSTANTS[atrToken.chainId].pwnSafeContracts.assetTransferRights,
      chainId: atrToken.chainId,
    }, { step, safeAddress })
  }

  return {
    mintAtrToken,
    mintAtrTokenBatch,
    burnAtrToken,
    burnAtrTokenBatch,
    claimAtrToken,
  }
}
