<template>
  <div
    ref="tableContainerRef"
    class="container"
    style="overflow: auto; position: relative; height: 400px;">
    <table class="thesis-credit-assets-table">
      <thead class="thesis-credit-assets-table__head">
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
          class="thesis-credit-assets-table__head-row">
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            :style="{
              width: `${header.getSize()}px`,
            }"
            :colSpan="header.colSpan">
            <FlexRender
              v-if="!header.isPlaceholder"
              :render="header.column.columnDef.header"
              :props="header.getContext()"/>
          </th>
        </tr>
      </thead>
      <tbody
        class="thesis-credit-assets-table__body"
        style="display: grid; height: 100%; position: relative;"
        :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
        <TransitionGroup name="thesis-credit-assets-table--fade">
          <tr
            v-for="row in virtualRows"
            :key="rows[row.index].id"
            :ref="customRefHandler"
            :class="['thesis-credit-assets-table__row', { 'thesis-credit-assets-table__row--disabled': !rows[row.index].getCanSelect() }]"
            :data-index="row.index"
            :style="{
              display: 'flex',
              position: 'absolute',
              transform: `translateY(${row.start}px)`,
              width: '100%',
              height: `64px`,
              alignItems: 'center',
              justifyContent: 'space-between',
            }">
            <td
              v-for="cell in rows[row.index].getVisibleCells()"
              :key="cell.id"
              :style="{
                width: `${cell.column.getSize()}px`,
              }">
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"/>
            </td>
          </tr>
        </TransitionGroup>
        <BaseSkeletor v-if="props.isLoading"/>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { FlexRender, getCoreRowModel, getFilteredRowModel, useVueTable } from '@tanstack/vue-table'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, h, ref } from 'vue'
import AssetInfoCellWithProtocol from '@/revamp/components/tables/cells/assets/AssetInfoCellWithProtocol.vue'
import { useVirtualizer } from '@tanstack/vue-virtual'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { isMobile } from '@/utils/mediaQueries'
import AssetPrice from '@/revamp/components/prices/AssetPrice.vue'
import AssetAmount from '@/revamp/components/AssetAmount.vue'
import type { ThesisCreditStatsSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import type { Address } from 'viem'
import { formatDecimalPoint } from '@/utils/utils'
import { parseAddress } from '@/utils/address-utils'

type Props = {
  data: AssetWithAmount[];
  thesisStatsData: Record<Address, ThesisCreditStatsSchemaWorkaroundBackendSchema>
  isLoading: boolean;
};

const props = defineProps<Props>()
const tableContainerRef = ref(null)

const table = useVueTable({
  get data() {
    return props.data
  },
  getRowId: (row) => String(row.id),
  columns: [
    {
      header: 'Asset',
      id: 'name',
      accessorFn: (row) => row.name,
      cell: (ctx) => {
        return h(AssetInfoCellWithProtocol, {
          asset: ctx.row.original,
        })
      },
      size: isMobile.value ? 250 : 420,
    },
    {
      header: 'Price',
      id: 'unitPrice',
      cell: (ctx) => {
        const asset = ctx.row.original
        if (!asset) return null
        return h(AssetPrice, {
          contractAddress: asset.address,
          tokenId: asset.tokenId ? String(asset.tokenId) : null,
          chainId: String(asset.chainId),
          class: 'thesis-credit-assets-table__price',
        })
      },
    },
    {
      header: 'Total Committed',
      id: 'totalComitted',
      cell: (ctx) => {
        const uniqueKey = ctx.row.original.aTokenAddress ? `${ctx.row.original.chainId}-${parseAddress(ctx.row.original.aTokenAddress)}` : `${ctx.row.original.chainId}-${parseAddress(ctx.row.original.address)}`
        const thesisStats: ThesisCreditStatsSchemaWorkaroundBackendSchema | undefined = props.thesisStatsData[uniqueKey]
        const { totalCommittedAmount } = thesisStats?.amountsStats || {}

        let amount = totalCommittedAmount
        if (!Number(amount)) {
          amount = '0'

          return '---'
        }

        return h(AssetAmount, {
          assetAmount: amount!,
          asset: ctx.row.original,
          isDisplaySymbol: true,
          withLink: false,
          hideIcon: true,
          hideVerifiedIcon: true,
        })
      },
    },
    {
      header: 'Total Utilized',
      id: 'totalComitted',
      cell: (ctx) => {
        const uniqueKey = ctx.row.original.aTokenAddress ? `${ctx.row.original.chainId}-${parseAddress(ctx.row.original.aTokenAddress)}` : `${ctx.row.original.chainId}-${parseAddress(ctx.row.original.address)}`
        const thesisStats: ThesisCreditStatsSchemaWorkaroundBackendSchema | undefined = props.thesisStatsData[uniqueKey]
        const { totalUtilizedAmount } = thesisStats?.amountsStats || {}

        let amount = totalUtilizedAmount
        if (!Number(amount)) {
          amount = '0'

          return '---'
        }

        return h(AssetAmount, {
          assetAmount: amount!,
          asset: ctx.row.original,
          isDisplaySymbol: true,
          withLink: false,
          hideIcon: true,
          hideVerifiedIcon: true,
        })
      },
    },
    {
      header: () => '% Total Utilized',
      id: 'totalComitted',
      cell: (ctx) => {
        const uniqueKey = ctx.row.original.aTokenAddress ? `${ctx.row.original.chainId}-${parseAddress(ctx.row.original.aTokenAddress)}` : `${ctx.row.original.chainId}-${parseAddress(ctx.row.original.address)}`
        const thesisStats: ThesisCreditStatsSchemaWorkaroundBackendSchema | undefined = props.thesisStatsData[uniqueKey]
        let { totalUtilizedPercentage } = thesisStats?.amountsStats || {}

        if (!Number(totalUtilizedPercentage)) {
          totalUtilizedPercentage = '0'

          return '---'
        }

        totalUtilizedPercentage = Number(totalUtilizedPercentage) * 100

        return h('span', `${formatDecimalPoint(totalUtilizedPercentage)}%`)
      },
    },
  ],
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rows.value.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 64,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

</script>

<style scoped>
.thesis-credit-assets-table {
  display: grid;

  padding-right: 1rem;

  th {
    font-weight: 400;
    text-align: left;
    font-family: var(--font-family-screener);
    font-size: 0.875rem;
    color: #a4a4a4;
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 5;
    background: var(--background-color);

    display: flex;

    padding: 0 0.5rem;

    border-bottom: 1px dashed #828282;
  }

  &__head-row {
    width: 100%;
    padding: 0.25rem;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family-screener);
  }

  &__row {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0.5rem 1rem;
    will-change: background-color;
    transition: background-color 0.3s linear;
    cursor: pointer;

    &--disabled {
      opacity: 0.5;
    }

    &:hover {
      background-color: var(--gray-3);
    }
  }

  &__price {
    text-align: left;
  }
}

.thesis-credit-assets-table--fade,
.thesis-credit-assets-table--fade-move {
  transition: opacity 0.5s;

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave-active {
    position: absolute;
  }
}
</style>
