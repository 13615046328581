import type { SupportedChain } from '@/constants/chains/types'
import type { Address } from 'viem'

export type NftUniqueKey = `${SupportedChain}-${Address}-${string}`

export interface UniqueAssetProperties {
    chainId: SupportedChain
    contractAddress: Address
    tokenId: string
}

export const getNftUniqueKey = (asset: UniqueAssetProperties): NftUniqueKey => {
  return `${asset.chainId}-${asset.contractAddress}-${asset.tokenId}`
}
