import useNotifications from '@/modules/common/notifications/useNotifications'
import useNotificationsFetch from '@/modules/common/notifications/useNotificationsFetch'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'
import { useWhatIsNewModalStore } from '@/general-components/what-is-new-modal/useWhatIsNewModalStore'
import { ref } from 'vue'
import type { Address } from 'viem'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import type { CustomAddress } from '@/modules/common/web3/useCustomAccount'

const loadedForUserAddress = ref<CustomAddress | undefined>(undefined)

export default function useGlobalDataLoader() {
  const { clearAllUserNotifications } = useNotifications()
  const { loadUserNotificationsForWalletMenu } = useNotificationsFetch()
  const { loadNotificationSettings } = useNotificationCenterSettings()

  const loadDataOnAppInit = async (userAddress?: CustomAddress) => {
    // load common data for both connected & non-connected user
    Promise.allSettled([
      useWhatIsNewModalStore().fetchAndCheckWhatIsNewAnnouncement(),
    ])

    if (userAddress) {
      // if has wallet connected, load user data as well
      Promise.allSettled([
        loadUserNotificationsForWalletMenu(),
        loadNotificationSettings(),
      ])
    }
  }

  const reloadDataOnUserAddressChange = (userAddress: Address) => {
    useUserAssetsStore().invalidateAndRefetch(userAddress)
    useUserAssetsStore().loadUserAssets(userAddress)
    loadUserNotificationsForWalletMenu()
    loadNotificationSettings()
  }

  const clearDataOnUserAddressChange = (): void => {
    clearAllUserNotifications()
  }

  const reloadDataOnChainOrUserAddressChange = (userAddress: Address) => {
    loadedForUserAddress.value = userAddress
  }

  return {
    loadDataOnAppInit,
    reloadDataOnUserAddressChange,
    clearDataOnUserAddressChange,
    reloadDataOnChainOrUserAddressChange,
  }
}
