import { ref } from 'vue'

export const injectedProposalsCountKeyName = 'injectedProposalsCount'

export const useInjectedProposalsCount = (

) => {
  const injectedProposalsCount = ref<number | null>(null)
  const setInjectedProposalsCount = (count: number) => {
    injectedProposalsCount.value = count
  }

  return {
    injectedProposalsCount,
    setInjectedProposalsCount,
  }
}
