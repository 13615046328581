import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { fetchCollectionNFTs } from '@/modules/common/backend/generated'
import type { BaseNFTAssetSchemaBackendSchema } from '@/modules/common/backend/generated'
import { useCollectionPageStore } from '@/revamp/pages/collection/useCollectionPageStore'
import to from '@/utils/await-to-js'
import type NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'
import type { NftUniqueKey } from '@/utils/unique-keys'
import { getNftUniqueKey } from '@/utils/unique-keys'
import StoreIds from '@/constants/storeIds'
const DISPLAY_ASSETS_LIMIT = 20

export const useCollectionAssetsStore = defineStore(StoreIds.collectionPageAssetStore, () => {
  const store = useCollectionPageStore()

  const nextCursor = ref<string | undefined>(undefined)
  const limit = DISPLAY_ASSETS_LIMIT
  const isFetchingCollectionAssets = ref(false)

  const collectionAssets = ref<BaseNFTAssetSchemaBackendSchema[]>([])
  const nftPriceStats = ref({})

  const setNftPriceStats = (key: NftUniqueKey, value: NFTPriceStats) => {
    nftPriceStats.value[key] = value
  }

  /**
   * This function is used to retrieve information about the asset price stats and loading state
   * @returns {(NFTPriceStats | null | undefined)} undefined if asset is loading, null if loaded and no result. Result if successufly loaded
   */
  const getAssetPriceStats = (asset: BaseNFTAssetSchemaBackendSchema): NFTPriceStats | null | undefined => {
    const key = getNftUniqueKey({
      chainId: store.collection.chainId,
      contractAddress: store.collection.contractAddress,
      tokenId: String(asset.token_id),
    })

    return nftPriceStats.value[key]
  }

  const fetchNftStats = async (assets: BaseNFTAssetSchemaBackendSchema[]) => {
    const tasks = assets.map(async (asset) => {
      const priceStats = await useMetadataFetch().fetchNFTPriceStats(
        store.collection.contractAddress,
        String(asset.token_id),
        store.collection.chainId,
      )
      const uniqueKey = getNftUniqueKey({
        chainId: store.collection.chainId,
        contractAddress: store.collection.contractAddress,
        tokenId: String(asset.token_id),
      })

      // @ts-expect-error TS(2345) FIXME: Argument of type 'NFTPriceStats | null' is not ass... Remove this comment to see the full error message
      setNftPriceStats(uniqueKey, priceStats)
    })

    await Promise.allSettled(tasks)
  }

  const fetchCollectionNfts = async (collection: NFTAssetCollection, cursor?: string) => {
    isFetchingCollectionAssets.value = true
    const [error, response] = await to(fetchCollectionNFTs(
      collection.chainId,
      collection.contractAddress,
      {
        limit,
        next: cursor,
      }))
    isFetchingCollectionAssets.value = false

    if (response?.data && !error) {
      collectionAssets.value = [...collectionAssets.value, ...response.data.assets]
      nextCursor.value = response.data.cursor ?? undefined
      await fetchNftStats(response.data.assets)
    }
  }

  const fetchNext = async () => {
    if (nextCursor.value && !isFetchingCollectionAssets.value) {
      await fetchCollectionNfts(store.collection, nextCursor.value)
    }
  }

  watch(
    () => store.collection,
    (collection) => {
      nftPriceStats.value = {}
      collectionAssets.value = []
      nextCursor.value = undefined
      if (collection) {
        return fetchCollectionNfts(collection)
      }
    },
    {
      immediate: true,
    },
  )

  return {
    isFetchingCollectionAssets,
    limit,
    collectionAssets,
    nftPriceStats,
    actions: {
      fetchNext,
      getAssetPriceStats,
    },
  }
})
