import { SupportedChain } from '@/constants/chains/types'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { getChainId } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'

export const useAnyChainId = () => {
  if (isStarknet) {
    return SupportedChain.StarknetSepolia
  }

  return getChainId(pwnWagmiConfig)
}
