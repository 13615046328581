<template>
  <div class="pwn-safe-current-wallet">
    <img
      src="@/assets/images/metamask-shadow.svg?url"
      alt="metamask shadow image"
      class="pwn-safe-current-wallet__metamask-image">

    <img
      src="@/assets/images/smoking-punk.svg?url"
      alt="smoking punk image"
      class="pwn-safe-current-wallet__smoking-punk-image">

    <div>
      <div class="pwn-safe-current-wallet__label">
        <WalletIconSvg
          alt="wallet icon"
          class="pwn-safe-current-wallet__wallet-icon"/>
        <span>Current Wallet</span>
        <span
          v-if="isConnectedPwnSafe"
          class="pwn-safe-current-wallet__connected-pwn-safe"> PWN Safe</span>
      </div>

      <div class="pwn-safe-current-wallet__address">
        ({{ userAddress }})
      </div>
    </div>

    <div class="pwn-safe-current-wallet__assets-in-wallet">
      <AmountOfAssetType
        :is-loading="showTokensAmountLoader"
        label="Tokens"
        :amount="amountOfSupportedTokens"/>

      <AmountOfAssetType
        :is-loading="showNftsAmountLoader"
        label="NFTs"
        :amount="amountOfNftsWithoutAtrTokens"/>

      <AmountOfAssetType
        :is-loading="showNftsAmountLoader"
        label="ATR Tokens"
        :amount="amountOfAtrTokens"/>
    </div>

    <BaseButton
      :color="ButtonColor.Primary"
      :variant="ButtonVariant.Outline"
      is-full-width
      is-smaller-enlarge-effect
      class="pwn-safe-current-wallet__open-current-wallet"
      :button-text="goToCurrentWalletText"
      @click="handleGoToCurrentWallet">
      <template #icon>
        <OutInIconSvg
          class="pwn-safe-current-wallet__out-in-icon"
          alt="out-in icon"/>
      </template>
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import RouteName from '@/router/RouteName'
import router from '@/router'
import { computed } from 'vue'
import AmountOfAssetType from '@/modules/pages/pwn-safe/pwn-safe-dashboard/AmountOfAssetType.vue'
import OutInIconSvg from '@/assets/icons/out-in.svg'
import WalletIconSvg from '@/assets/icons/wallet.svg'
import { useCurrentWalletAssetsDetail } from '@/modules/queryHooks/safe/useCurrentWalletAssetsDetail'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { storeToRefs } from 'pinia'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'

const { address: userAddress, chainId: connectedChainId } = useCustomAccount()
// @ts-expect-error FIXME: strictNullChecks
const { selectedChainsTokens, disabledChains, nonAtrTokens, atrTokens, tokensAreLoading, nftsAreLoading } = useCurrentWalletAssetsDetail(userAddress)

const connectedAccountTypeStore = useConnectedAccountTypeStore()
const { isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)

const amountOfSupportedTokens = computed(
  () => selectedChainsTokens.value.filter((erc20) => !disabledChains.value.includes(erc20.chainId)).length,
)
const amountOfNftsWithoutAtrTokens = computed(
  () => nonAtrTokens.value.filter((nft) => !disabledChains.value.includes(nft.chainId)).length,
)
const amountOfAtrTokens = computed(
  () => atrTokens.value.filter((nft) => !disabledChains.value.includes(nft.chainId)).length,
)
const goToCurrentWalletText = computed(() => (isConnectedPwnSafe.value ? 'Go to PWN Safe' : 'Go to current wallet'))

const showTokensAmountLoader = computed(() => {
  return Boolean(tokensAreLoading.value)
})

const showNftsAmountLoader = computed(() => {
  return Boolean(nftsAreLoading.value)
})

const handleGoToCurrentWallet = () => {
  if (isConnectedPwnSafe.value) {
    router.push({
      name: RouteName.PwnSafeDetailAssets,
      params: {
        safeAddress: userAddress.value,
        // TODO does this make sense?
        chainId: connectedChainId.value,
      },
    })
  } else {
    router.push({ name: RouteName.PwnSafeCurrentWalletAssets })
  }
}
</script>

<style scoped>
.pwn-safe-current-wallet {
  display: flex;
  margin-top: 2rem;
  padding: 2rem;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background-image: var(--border-white-dashed);

  &__metamask-image {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 2rem;
    top: 1rem;
  }

  &__smoking-punk-image {
    position: absolute;
    width: 240px;
    height: 200px;
    right: 2rem;
    top: 0;
  }

  &__wallet-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  &__label {
    font-size: 1.25rem;
    justify-content: center;
    margin-bottom: 0.25rem;
    display: flex;
    gap: 0.5rem;
  }

  &__address {
    color: var(--gray-2);
  }

  &__assets-in-wallet {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 25rem;
  }

  &__asset-type-label {
    color: var(--gray-2);
    margin-bottom: 0.3rem;
  }

  &__asset-type-amount {
    font-size: 1.25rem;
  }

  &__out-in-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }

  &__open-current-wallet {
    width: 100%;
    transform: none;
  }

  &__connected-pwn-safe {
    color: var(--gray-2);
  }

  @media only screen and (--mobile-viewport) {
    &__address {
      font-size: 0.75rem;
    }

    &__assets-in-wallet {
      width: 19rem;
    }
  }
}
</style>
