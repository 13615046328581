import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useSearchPwnExplorer } from '@/modules/common/pwn/explorer/useSearchStore'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import to from '@/utils/await-to-js'
import type { CollectionSearchResult } from '@/modules/common/pwn/explorer/models/CollectionSearchResult'
import type { SupportedChain } from '@/constants/chains/types'
import type CollectionStats from '@/modules/common/assets/typings/CollectionStats'
import { watchArray } from '@vueuse/core'
import { useCancelableRequests } from '@/modules/common/useCancellableRequest'
import { ExplorerStores } from '@/modules/common/pwn/explorer/constants'
import type { Address } from 'viem'

type ChainIdAndAddress = `${SupportedChain}-${Address}`

type CollectionSearchStats = Record<ChainIdAndAddress, CollectionStats>

export const useCollectionSearchStatsStore = defineStore(ExplorerStores.CollectionSearchStats, () => {
  const statsResult = ref<CollectionSearchStats | null>(null)
  const isFetched = ref(false)
  const shouldBeFetched = ref(false)

  const collectionSearchResults = computed(() => {
    if (!shouldBeFetched.value) return []
    return useSearchPwnExplorer().results.collections ?? []
  })

  const { setCancellableRequest, cancelRequests } = useCancelableRequests<ChainIdAndAddress>()

  watchArray(collectionSearchResults,
    async (
      collections, _, added,
    ) => {
      cancelRequests()
      if (!collections?.length || !added?.length) {
        return
      }
      if (!useSearchPwnExplorer().searchTerm) {
        const { fetchCollectionStats } = useMetadataFetch()
        const toFetch = collections
        const collectionStats: CollectionSearchStats = {}
        await Promise.allSettled(toFetch.map(async (collection) => {
          const key: ChainIdAndAddress = collection.key as ChainIdAndAddress
          const controller = new AbortController()
          setCancellableRequest(key, controller)
          const [err, stats] = await to(fetchCollectionStats(
            collection.chainId,
            collection.contractAddress,
            false,
            { signal: controller.signal },
          ))
          if (err) {
            return
          }
          // @ts-expect-error FIXME: strictNullCheks
          collectionStats[key] = stats
        }))
        statsResult.value = collectionStats
        isFetched.value = true
        return
      }
      isFetched.value = false

      const collectionStats: CollectionSearchStats = {}
      const { fetchCollectionStats } = useMetadataFetch()

      const toFetch = added.length ? added : collections

      await Promise.allSettled(toFetch.map(async (collection) => {
        const key: ChainIdAndAddress = collection.key as ChainIdAndAddress
        // if key is already fetched, then use it
        if (statsResult.value && statsResult.value[key]) {
          collectionStats[key] = statsResult.value[key]
          return
        }

        const controller = new AbortController()
        setCancellableRequest(key, controller)
        const [err, stats] = await to(fetchCollectionStats(
          collection.chainId,
          collection.contractAddress,
          false,
          { signal: controller.signal },
        ))
        if (err) {
          return
        }
        // @ts-expect-error TS(2322) FIXME: Type 'CollectionStats | null' is not assignable to... Remove this comment to see the full error message
        collectionStats[key] = stats
      }))

      statsResult.value = collectionStats
      isFetched.value = true
    },
  )

  const getCollectionStats = (collectionResult: CollectionSearchResult): CollectionStats | null => {
    return statsResult.value?.[collectionResult.key] || null
  }
  const shouldFetch = (v: boolean) => {
    shouldBeFetched.value = v
  }

  return {
    isFetched,
    actions: {
      getCollectionStats,
      shouldFetch,
    },
  }
})
