<template>
  <RevampBaseModal
    v-model:is-open="isOpen"
    :heading="heading"
    :size="ModalSize.Small"
    heading-align="left">
    <template #trigger>
      <BaseButton
        class="modal-share__options-btn"
        :color="ButtonColor.Gray"
        title="Share"
        has-white-hover
        :variant="ButtonVariant.Outline"
        :font="ButtonFont.Screener"
        @click="isOpen = true">
        <template #icon>
          <div class="modal-share__icon-wrapper">
            <ShareIconPrimarySvg
              class="modal-share__icon-share"
              width="20"
              height="20"
              alt="share icon"/>
          </div>
          <span class="modal-share__button-text--white">Share This Proposal</span>
        </template>
      </BaseButton>
    </template>
    <template #subheading>
      <div class="modal-share__copy-label">
        Share via
      </div>
    </template>
    <template #body>
      <div class="modal-share__body">
        <div class="modal-share__social-buttons">
          <BaseLink
            v-for="(button, index) in socialButtons"
            :key="index"
            class="modal-share__button"
            :to="button.link.value"
            target="_blank">
            <div class="modal-share__icon-container">
              <component
                :is="button.icon"
                class="modal-share__icon-social"/>
              <span class="modal-share__button-text">{{ button.name }}</span>
            </div>
          </BaseLink>
        </div>
        <div class="modal-share__section">
          <p class="modal-share__copy-label">
            Copy link
          </p>

          <div class="modal-share__copy-button-and-content">
            <div :class="['modal-share__copy-content', { 'modal-share__copy-content--copied': isCopied }]">
              {{ linkToThisPage }}
            </div>
            <BaseButton
              class="modal-share__copy-button"
              :font="ButtonFont.Screener"
              is-full-width
              :button-text="buttonText"
              @on-button-click="copy">
              <template #icon>
                <CopyIconSvg
                  class="modal-share__icon-copy"
                  alt="copy"/>
              </template>
            </BaseButton>
          </div>
        </div>
        <BaseButton
          class="modal-share__button-done"
          is-full-width
          button-text="Done"
          @on-button-click="isOpen = false"/>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import BaseButton, { ButtonFont, ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import ModalSize from '@/general-components/ModalSize'
import { ref, computed, toRefs } from 'vue'
import BaseLink from '@/general-components/BaseLink.vue'
import CopyIconSvg from '@/assets/icons/copy.svg'
import ShareIconPrimarySvg from '@/assets/icons/sharePrimary.svg'
import TwitterIconSvg from '@/assets/icons/twitter.svg'
import WarpcastIconSvg from '@/assets/icons/warpcaster.svg'
import TelegramIconSvg from '@/assets/icons/telegramRevamp.svg'
import DiscordIconSvg from '@/assets/icons/discordVector.svg'
import { useClipboard } from '@vueuse/core'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import { globalConstants } from '@/constants/globals'

  interface Props {
    heading: string
    shareMessage: string
    proposalId: number | string
  }

const props = defineProps<Props>()
const { heading, shareMessage, proposalId } = toRefs(props)
const isOpen = ref(false)
const isCopied = ref(false)
const encodedLinkToThisPage = encodeURIComponent(window?.location.href)

const twitterLink = computed(() => `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage.value)}&url=${encodedLinkToThisPage}`)
const discordLink = computed(() => `https://discord.com/channels/@me?message=${encodeURIComponent(shareMessage.value)}%20${encodedLinkToThisPage}`)
const telegramLink = computed(() => `https://t.me/share/url?url=${encodedLinkToThisPage}&text=${shareMessage.value}`)
const warpcastLink = computed(() => `https://warpcast.com/~/compose?text=${encodeURIComponent(`${globalConstants.farcasterFramesUrl}/proposal/${proposalId.value}`)}`)

const linkToThisPage = computed(() => window?.location.href)
const { copy, copied } = useClipboard({ source: linkToThisPage })
const buttonText = computed(() => (copied.value ? 'Copied!' : 'Copy'))
const socialButtons = [
  { name: 'Twitter', icon: TwitterIconSvg, link: twitterLink },
  { name: 'Telegram', icon: TelegramIconSvg, link: telegramLink },
  { name: 'Discord', icon: DiscordIconSvg, link: discordLink },
  { name: 'Farcaster', icon: WarpcastIconSvg, link: warpcastLink },
]

</script>

<style scoped>
.modal-share {
  &__heading {
    font-family: var(--font-family-screener);
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--text-color);
    width: 100%;
    box-sizing: border-box;
    gap: 2rem;
    opacity: 0;
  }

  &__options-btn {
    display: flex;
    justify-content: end;
    margin-bottom: 2rem;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 10px;
    gap: 8px;
    border: 1px solid var(--primary-color-1);
    opacity: 1;
    color: var(--primary-color-1);

    @media only screen and (--small-viewport) {
      width: 100%;
    }
  }

  &__button-text {
    font-family: var(--font-family-supreme);
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  &__button {
    flex: 1 1 calc(50% - 0.25rem);
    min-width: 0;
    text-decoration: none;
    color: inherit;

    @media only screen and (--small-viewport) {
      width: 100%;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
  }

  &__copy-label {
    font-family: var(--font-family-supreme);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  &__social-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
  }

  &__icon-copy {
    margin-left: 0.6rem;
  }

  &__copy-button {
    height: 40px;
    margin-left: 0.75rem;

    width: 6rem;

    @media only screen and (--small-viewport) {
      width: auto;
      max-width: 5rem;
    }

    @media only screen and (--mobile-viewport) {
      max-width: 11rem;
    }
  }

  &__copy-button-and-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media only screen and (--small-viewport) {
      flex-flow: row nowrap;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__copy-content {
    padding: 8px 16px;
    background-color: transparent;
    border: 1px solid var(--border-color, #ccc);
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-family-autoscape);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @media only screen and (--small-viewport) {
      width: 100%;
    }

    @media only screen and (--mobile-viewport) {
      max-width: 11rem;
    }

    &--copied {
      color: var(--primary-color-1);
    }
  }

  &__button-done {
    margin: 1rem auto 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__button-text--white {
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    color: var(--text-color);
  }
}
</style>
