<template>
  <div :class="['notification-message-and-status__content', {'notification-message-and-status__content--has-action-button': hasRepayButton || hasClaimButton}]">
    <div class="notification-message-and-status__tokens-and-message">
      <div class="notification-message-and-status__tokens">
        <component
          :is="notification.customImage"
          v-if="notification.customImage"/>
        <div v-else>
          <TokenMedia
            v-if="!!notification.firstAsset"
            :token="notification.firstAsset"
            :width="imageSize"
            :height="imageSize"
            :class="['notification-message-and-status__collateral', { 'notification-message-and-status__collateral--composite': hasCompositeImage }]"/>
          <TokenMedia
            v-if="!!notification.secondAsset"
            :token="notification.secondAsset"
            :width="imageSize"
            :height="imageSize"
            :class="['notification-message-and-status__asset', { 'notification-message-and-status__asset--composite': hasCompositeImage }]"/>
        </div>
      </div>
      <span :class="['notification-message-and-status__message', `notification-message-and-status__message--${notification.variant}`]">
        {{ notification.message }}
        <span v-if="notification.loanOnChainId">
          <BaseLink
            :to="notification.loanDetailRoute"
            class="link--font-oxygen-mono notification-message-and-status__loan-id"
            @click="emit('loan-id-click')">
            {{ '#' + notification.loanOnChainId }}
          </BaseLink>
        </span>
        <span v-else-if="notification.proposalId">
          <BaseLink
            :to="notification.proposalDetailRoute"
            class="link--font-oxygen-mono notification-message-and-status__loan-id"
            @click="emit('loan-id-click')">
            {{ '#' + notification.proposalId }}
          </BaseLink>
        </span>
      </span>
    </div>

    <!-- TODO fill in LoanStatus.New in LoanStatusInfo? -->
    <LoanStatusInfo
      v-if="(loanStatus || loanStatus === 0) && loanStatus !== 1"
      is-without-text
      class="notification-message-and-status__status"
      :loan="loanData as LoanDetailSchemaWorkaroundBackendSchema"
      :loan-status="loanStatus as (LoanStatus | LoanStatus.PaidBack)"/>
    <NotificationTypeIcon
      v-else
      class="notification-message-and-status__type-icon"
      :variant="notification.variant"/>
  </div>
</template>

<script setup lang="ts">
import NotificationTypeIcon from '@/general-components/NotificationTypeIcon.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import type { LoanDetailSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import LoanStatusInfo from '@/general-components/LoanStatusInfo.vue'
import { computed, toRefs } from 'vue'
import type Notification from '@/modules/common/notifications/Notification'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import BaseLink from '@/general-components/BaseLink.vue'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'

interface Props {
  notification: Notification
  hasRepayButton?: boolean
  hasClaimButton?: boolean
  loanData?: LoanDetailSchemaWorkaroundBackendSchema | null
  proposalClass?: BaseProposal | null
}

const props = defineProps<Props>()
const { notification, loanData, proposalClass } = toRefs(props)

const emit = defineEmits<{(e: 'loan-id-click'): void}>()

const hasCompositeImage = computed(() => notification.value?.firstAsset?.image && notification.value?.secondAsset?.image)
const imageSize = computed(() => hasCompositeImage.value ? '16' : '24')

const loanStatus = computed(() => {
  if (loanData.value?.status === 0 && loanData.value?.paidBackAt) {
    return LoanStatus.PaidBack
  }
  if (loanData.value?.status || loanData.value?.status === 0) {
    return loanData.value.status
  }
  if (proposalClass.value) {
    return proposalClass.value.status
  }
  return null
})

</script>

<style scoped>
.notification-message-and-status {
  &__content {
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: space-between;
    align-items: flex-start;

    &--has-action-button {
      margin-bottom: 0;
    }
  }

  &__message {
    padding-right: 1.25rem;
    line-height: 1;
    /* do not overflow to absolutely positioned close icon */
    overflow: hidden;
    font-size: 0.875rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--text-color);

    &--danger {
      color: var(--danger-1);
    }
  }

  &__type-icon {
    margin-right: 0;
    scale: 0.8;
  }

  &__loan-id {
    color: var(--primary-color-1);
  }

  &__tokens-and-message {
    display: flex;
    align-items: center;
  }

  &__status {
    scale: 0.8;
    translate: 7px;
  }

  &__tokens {
    min-width: 24px;
    height: 24px;
    margin-right: 0.25rem;
    position: relative;
  }

  &__collateral,
  &__asset {
    width: 24px;
    height: 24px;

    position: absolute;
    object-fit: contain;
    object-position: center;
  }

  &__collateral--composite,
  &__asset--composite {
    width: 16px;
    height: 16px;
  }

  &__asset {
    &--composite {
      left: 7px;
      top: 7px;
    }
  }
}

</style>
