<template>
  <BaseTooltip
    v-if="props.hasTooltip"
    is-interactive
    has-tooltip
    append-to-body
    :max-width="400"
    :border-color="TooltipBorderColor.White">
    <template #trigger>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none">
        <path
          d="M9.55244 4.65244L6.29993 7.90495L4.44742 6.05244L3.95244 6.54742L6.29993 8.8949L10.0474 5.14742L9.55244 4.65244Z"
          fill="var(--primary-color-1)"/>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5999 6.9999C12.5999 10.0927 10.0927 12.5999 6.9999 12.5999C3.90711 12.5999 1.3999 10.0927 1.3999 6.9999C1.3999 3.90711 3.90711 1.3999 6.9999 1.3999C10.0927 1.3999 12.5999 3.90711 12.5999 6.9999ZM11.8999 6.9999C11.8999 9.7061 9.7061 11.8999 6.9999 11.8999C4.29371 11.8999 2.0999 9.7061 2.0999 6.9999C2.0999 4.29371 4.29371 2.0999 6.9999 2.0999C9.7061 2.0999 11.8999 4.29371 11.8999 6.9999Z"
          fill="var(--primary-color-1)"/>
      </svg>
    </template>
    <p class="verification-badge-and-tooltip__tooltip-text-verification--upper verification-badge-and-tooltip__tooltip-text-verification">
      Verification shows if the asset you are interacting with is the real asset
    </p>
    <p class="verification-badge-and-tooltip__tooltip-text-verification--lower verification-badge-and-tooltip__tooltip-text-verification">
      <BaseLink
        target="_blank"
        class="link link--primary link--font-oxygen-mono"
        :to="linkTo.verificationGuide">
        Learn more about asset verification
      </BaseLink>
    </p>
  </BaseTooltip>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none">
    <path
      d="M9.55244 4.65244L6.29993 7.90495L4.44742 6.05244L3.95244 6.54742L6.29993 8.8949L10.0474 5.14742L9.55244 4.65244Z"
      fill="var(--primary-color-1)"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5999 6.9999C12.5999 10.0927 10.0927 12.5999 6.9999 12.5999C3.90711 12.5999 1.3999 10.0927 1.3999 6.9999C1.3999 3.90711 3.90711 1.3999 6.9999 1.3999C10.0927 1.3999 12.5999 3.90711 12.5999 6.9999ZM11.8999 6.9999C11.8999 9.7061 9.7061 11.8999 6.9999 11.8999C4.29371 11.8999 2.0999 9.7061 2.0999 6.9999C2.0999 4.29371 4.29371 2.0999 6.9999 2.0999C9.7061 2.0999 11.8999 4.29371 11.8999 6.9999Z"
      fill="var(--primary-color-1)"/>
  </svg>
</template>

<script setup lang="ts">

import linkTo from '@/constants/links'
import BaseLink from '@/general-components/BaseLink.vue'
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'

interface Props {
  hasTooltip?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  hasTooltip: false,
})
</script>

<style scoped>
.verification-badge-and-tooltip {
  &__tooltip {
    margin-bottom: 0.875rem;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: var(--font-family-autoscape);
  }

  &__question-mark {
    cursor: help;
  }

  &__tooltip-text-verification {
    text-align: center;

    &--upper {
      margin-top: 0;
    }

    &--lower {
      margin-bottom: 0;
    }
  }
}

</style>
