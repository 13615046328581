<template>
  <PwnSafeSelectionTable
    v-model:active-sort-option="selectedSelectionSortOption"
    :table-definition="CLAIM_AND_BURN_SELECTION_TABLE_DEFINITION"
    :sorted-assets="sortedAtrTokensToClaimAndBurn"
    :is-asset-selected="handleIsAssetSelected"
    heading="Selection "
    @on-asset-select="handleAssetSelect"/>
</template>

<script lang="ts" setup>
import usePwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import PwnSafeSelectionTable from '@/modules/pages/pwn-safe/tables/PwnSafeSelectionTable.vue'
import { CLAIM_AND_BURN_SELECTION_TABLE_DEFINITION } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useAssetsSelection from '@/modules/common/assets/useAssetsSelection'

const { sortedAtrTokensToClaimAndBurn, selectedSelectionSortOption } = usePwnSafeClaimAndBurn()
const { isAssetSelected, addOrRemoveAssetSelection } = useAssetsSelection()

const handleIsAssetSelected = (atrToken: AssetWithAmount) => {
  return isAssetSelected({ asset: atrToken, selectedAssets: sortedAtrTokensToClaimAndBurn })
}

const handleAssetSelect = (atrToken: AssetWithAmount) => {
  addOrRemoveAssetSelection({ asset: atrToken, selectedAssets: sortedAtrTokensToClaimAndBurn })
}
</script>
