<template>
  <div class="collection-offers-page-header__wrapper">
    <header class="collection-offers-page-header__container">
      <BaseBackButton
        class="collection-offers-page-header__back-button"/>
      <h1 class="collection-offers-page-header__title">
        Collection Overview
      </h1>
      <div class="collection-offers-page-header__right-section">
        <div
          v-if="storeCollectionOffersPage.collection"
          class="collection-offers-page-header__chain-name">
          <ChainIcon
            width="22"
            height="22"
            :alt="getChainName(storeCollectionOffersPage.collection.chainId)"/>
          <span class="collection-offers-page-header__chain-text">
            {{ getChainName(storeCollectionOffersPage.collection.chainId) }}
          </span>
        </div>
        <BaseButton
          :color="ButtonColor.Gray"
          :variant="ButtonVariant.Outline"
          title="Refresh metadata"
          @click="handleRefresh">
          <template #icon>
            <RefreshIconSvg
              width="20"
              height="20"
              alt="refresh icon"/>
          </template>
        </BaseButton>
        <ModalShare
          heading="Share Collection"
          share-message="Hey, check out this NFT collection I've found on PWN.xyz"/>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
import ModalShare from '@/general-components/ModalShare.vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import type { FetchCollectionDataParams } from '@/revamp/pages/collection/useCollectionPageStore'
import { useCollectionPageStore } from '@/revamp/pages/collection/useCollectionPageStore'
import { computed } from 'vue'
import { getChainIcon, getChainName } from '@/utils/chain'
import RefreshIconSvg from '@/assets/icons/refresh.svg'

const storeCollectionOffersPage = useCollectionPageStore()
const ChainIcon = computed(() => getChainIcon(storeCollectionOffersPage.collection.chainId))

const handleRefresh = async () => {
  let fetchCollectionDataParams: FetchCollectionDataParams | null
  if (storeCollectionOffersPage.collection?.openseaSlug) {
    fetchCollectionDataParams = {
      slug: storeCollectionOffersPage.collection.openseaSlug,
    }
  } else {
    fetchCollectionDataParams = {
      contractAddress: storeCollectionOffersPage.collection.contractAddress,
      chainId: storeCollectionOffersPage.collection.chainId,
    }
  }

  await storeCollectionOffersPage.fetchCollectionDataIfNotAlreadyFetched({
    ...fetchCollectionDataParams,
    refresh: true,
  })
}

</script>

<style scoped>
.collection-offers-page-header {
  &__wrapper {
    position: sticky;
    top: var(--menu-height);
    z-index: var(--z-index-collection-offers-page-header);

    margin-bottom: var(--space-40);

    &--borderless {
      margin-bottom: 2rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: var(--background-color);
    padding-bottom: 1rem;
    /* for fixing the content under this sticky header to be visible (1px stripe on the left) */

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      width: 2px;
      height: 100%;
      background: inherit;
    }

    &:not(&--borderless) {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-image: var(--border-gray-dashed);
        background-size: auto 2px;
        /* small hack how to hide buggy double border (top + bottom), when height is 1px */
      }
    }
  }

  &__title {
    font-size: 1.5rem;
    font-family: var(--font-family-screener);
    text-transform: capitalize;
  }

  &__chain-name {
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  &__chain-text {
    font-size: 1rem;
    font-family: var(--font-family-supreme);
    text-transform: capitalize;
  }

  &__back-button {
    margin-right: 1rem;
  }

  &__edit-button {
    margin-right: 1rem;
  }

  &__status {
    margin: 0 0 0 var(--space-20);
  }

  &__right-section {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 0.85rem;
  }

  &__chain-info {
    margin-right: 1rem;
    font-family: var(--font-family-oxygen-mono);
  }

  &__create-icon {
    width: 1.25rem;
  }

  @media only screen and (--small-viewport) {
    &__container {
      flex-flow: column nowrap;
      row-gap: 1rem;
      align-items: start;
    }

    &__right-section {
      flex-flow: row-reverse nowrap;
      margin-left: 0;
    }

    &__title {
      font-size: 1.25rem;
      line-height: 1.5rem;
      align-self: flex-start;
    }

    &__back-button {
      align-self: flex-start;
    }

    &__chain-info {
      position: absolute;
      top: 0;
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 465px) {
    &__right-section {
      font-size: small;
    }
  }
}
</style>
