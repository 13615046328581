<template>
  <BaseSection
    :is-expandable="props.isExpandable"
    with-gray-background
    heading="Tokens "
    :is-loading="userErc20sIsPending"
    :secondary-heading="secondaryHeading">
    <template #title-icon>
      <TokenIcon class="section-your-coins"/>
    </template>
    <YourCoinsTable
      :is-dashboard="props.isDashboard"
      :has-first-column-padding="props.hasFirstColumnPadding"
      :table-definition="props.tableDefinition"
      :table-min-width="tableMinWidth"
      :is-asset-selected="isAssetSelected"
      :is-asset-disabled="isAssetDisabled"
      :is-loading="userErc20sIsPending"
      :assets="props.assets"
      :hide-invalid-asset-tooltip="props.hideInvalidAssetTooltip"
      @on-asset-select="emitOnAssetSelect"/>
    <BaseEmptyStateText
      v-if="hasEmptyState"
      text="No coins available"/>

    <template #controls>
      <span
        v-if="props.hiddenAssets > 0"
        class="section-your-coins__hidden-assets-text"
        @click="handleClickShowAll">
        {{ checkboxLabelText }}
      </span>
    </template>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import YourCoinsTable from '@/modules/sections/your-assets/your-coins/YourCoinsTable.vue'
import { computed, onMounted, provide } from 'vue'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import useYourCoins from '@/modules/sections/your-assets/your-coins/useYourCoins'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { storeToRefs } from 'pinia'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { appliedFiltersKey } from '@/modules/common/injection-keys'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import TokenIcon from '@/assets/icons/token.svg'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

interface Props {
  tableDefinition: ColumnProperties[]
  hasFirstColumnPadding: boolean
  isExpandable?: boolean
  isDashboard: boolean
  isAssetSelected?: (asset: AssetWithAmount) => boolean
  isAssetDisabled?: (asset: AssetWithAmount) => boolean
  amountOfCoins?: number
  isFetchingCoins?: boolean
  tableMinWidth?: string
  assets: AssetWithAmount[]
  hideInvalidAssetTooltip?: boolean;
  hiddenAssets?: number
}
const props = withDefaults(defineProps<Props>(), {
  isExpandable: true,
  isDashboard: false,
  hiddenAssets: 0,
})

const { searchTerm } = useDashboard()

const selectedChainStore = useSelectedChainsStore()
const globalFiltersStore = useGlobalFiltersStore()
const { showUnverifiedAssets, showAssetsWithLesserAmount } = storeToRefs(globalFiltersStore)

const handleClickShowAll = () => {
  showUnverifiedAssets.value = true
  showAssetsWithLesserAmount.value = true
}

const { selectedChains } = storeToRefs(selectedChainStore)

const checkboxLabelText = computed(() => {
  return `Show Hidden Assets (${props.hiddenAssets})`
})

const emit = defineEmits<{(e: 'on-asset-select', asset: AssetWithAmount): void}>()
const emitOnAssetSelect = (asset: AssetWithAmount) => emit('on-asset-select', asset)

const { userAddressToDisplay } = useYourCoins()
const userAssetsStore = useUserAssetsStore()
const { userErc20s, userErc20sIsPending, selectedCoinsSortOption } = storeToRefs(userAssetsStore)

const appliedFiltersOrSorting = computed(() =>
  // we create snapshot of all applied filters to pass it down to virtual table
  // component and trigger scroll event once value is changed
  JSON.stringify({
    searchTerm: searchTerm.value,
    selectedChains: selectedChains.value,
    selectedCoinsSortOption: selectedCoinsSortOption.value,
  }))

provide(appliedFiltersKey, appliedFiltersOrSorting)

const amountOfCoins = computed(() => props.amountOfCoins !== null && props.amountOfCoins !== undefined ? props.amountOfCoins : userErc20s.value.length)
const hasEmptyState = computed(() => !userErc20sIsPending.value && amountOfCoins.value === 0)

const secondaryHeading = computed(() => `(${amountOfCoins.value}) `)

onMounted(() => {
  if (!props.isDashboard) {
    userAddressToDisplay.value = getAccount(pwnWagmiConfig).address
  }
})
</script>

<style>
.section-your-coins {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;

  &__hidden-assets-text {
    font-family: var(--font-family-supreme);
    font-size: 0.875rem;
    font-weight: 400;

    cursor: pointer;
    transition: color 0.3s linear;

    &:hover {
      color: var(--gray);
    }
  }
}
</style>
