<template>
  <BaseButton
    :variant="ButtonVariant.Outline"
    :color="buttonColor"
    :is-disabled="!isCustomizeable"
    is-full-width
    button-text="Customize"
    class="customize-button"/>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  isCustomizeable: boolean | undefined,
}>()
const { isCustomizeable } = toRefs(props)
const buttonColor = computed(() => isCustomizeable.value ? ButtonColor.White : ButtonColor.Gray)
</script>

<style scoped>
.customize-button {
  height: 1.5rem;
  margin-right: 0.5rem;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }

  .button {
    padding: 0 0.75rem;
  }
}
</style>
