<template>
  <div class="batch-sales-switcher">
    <BaseCheckbox
      :model-value="hasCheckedIncludeBatchSales"
      is-checkbox-after-label
      label="Include batch sales"
      class="batch-sales-switcher__checkbox"
      @update:model-value="handleIncludeBatchSales"/>
  </div>
</template>

<script setup lang="ts">
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import useAssetPriceHistory from '@/modules/pages/asset/nft-page/SectionPriceHistory/useAssetPriceHistory'

const { hasCheckedIncludeBatchSales, LOCAL_STORAGE_KEYS, setHasCheckedIncludeBatchSales } = useAssetPriceHistory()

const handleIncludeBatchSales = (showBatchSales: boolean) => {
  setHasCheckedIncludeBatchSales(showBatchSales)
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.SHOW_BATCH_SALES, String(showBatchSales))
}

</script>

<style scoped>
.batch-sales-switcher {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid;
  padding: 0.5rem;

  &__checkbox {
    font-size: 12px;
  }
}
</style>
