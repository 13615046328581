<template>
  <div
    v-if="enabledChains.length > 1"
    class="chain-group-filter">
    <button
      v-if="props.showAllNetworks"
      :class="['chain-group-filter__button', {
        'chain-group-filter__button--selected': selectedChains === 'all' ,
      }]"
      @click="() => props.setChain?.('all')">
      All Networks
    </button>

    <div
      v-for="chain in enabledChains"
      :key="chain"
      :class="[
        'chain-group-filter__button',
        {
          'chain-group-filter__button--selected': selectedChains !== 'all' && selectedChains?.includes(chain) ,
          'chain-group-filter__button--disabled': disabledChains && disabledChains?.includes(chain)
        },
      ]"
      @click="() => handleSetChain(chain)">
      <component
        :is="getMonoChromeChainIcon(chain)"
        class="chain-group-filter__icon"
        :alt="getChainName(chain)"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getChainName, getMonoChromeChainIcon } from '@/utils/chain'
import type { ChainFilterOptions, SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import type { SupportedChain } from '@/constants/chains/types'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'

const proposalFiltersStore = useProposalFilters()

type Props = {
  selectedChains: ChainFilterOptions;
  setChain?: (chain: SetChainFilterOption) => void;
  disabledChains?: SupportedChain[]
  showAllNetworks?: boolean
};
const props = withDefaults(defineProps<Props>(), {
  showAllNetworks: true,
})

const handleSetChain = (chain: Exclude<SetChainFilterOption, 'all'>) => {
  proposalFiltersStore.actions.updateCollateralAssetsFilter([])
  if (props.selectedChains !== 'all' && props.selectedChains?.includes(chain) && props.selectedChains?.length === 1) {
    props.setChain?.('all')
    return
  }

  if (props.disabledChains && props.disabledChains?.includes(chain)) return
  props.setChain?.(chain)
}
</script>

<style scoped>
.chain-group-filter {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 0.25rem;
  border: 1px solid var(--primary-color-3);
  padding: 3px;
  box-sizing: content-box;

  &__button {
    display: inline-flex;
    padding: 6px;
    cursor: pointer;
    box-sizing: content-box;
    align-items: center;
    border: 1px solid transparent;

    background-color: transparent;
    color: var(--text-color);

    font-size: 0.875rem;
    line-height: 1rem;
    font-family: var(--font-family-oxygen-mono);
    font-weight: 400;

    user-select: none;

    transition: 0.3s ease-in-out background-color,
      0.3s ease-in-out border-color;

    &--selected {
      background-color: var(--primary-color-3);
      border: 1px solid var(--primary-color-1);
    }

    &--disabled {
      opacity: 0.3;
    }
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  @media only screen and (--mobile-viewport) {
    &__button {
      font-size: 0.675rem;
    }
  }
}
</style>
