<template>
  <div
    class="token-page-chart"
    :class="[
      {
        'token-page-chart--hidden': chartRef === null && isFetched,
      },
    ]">
    <div
      id="tokenChart"
      class="token-page-chart__container"/>
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from '@tanstack/vue-query'
import { fetchErc20OHLC } from '@/modules/common/backend/generated'
import { computed, nextTick, ref, watch, watchEffect } from 'vue'
import { createChart } from 'lightweight-charts'
import type { VertAlign, IChartApi, ISeriesApi } from 'lightweight-charts'
import type { SupportedChain } from '@/constants/chains/types'

const props = defineProps<{
  address: string
  chainId: SupportedChain
}>()

const addressToFetch = computed(() => {
  return props.address
})

const chainIdToFetch = computed(() => {
  return props.chainId
})

const loadTokenChart = async ({ address, chainId }) => {
  return (await fetchErc20OHLC(chainId, address)) || []
}

const { data, isFetched } = useQuery({
  queryKey: ['tokenChart', addressToFetch, chainIdToFetch],
  retry: 1,
  queryFn: async (context) => {
    return await loadTokenChart({
      address: context.queryKey[1],
      chainId: context.queryKey[2],
    })
  },
})

const chartRef = ref<IChartApi | null>(null)
const seriesRef = ref<ISeriesApi<'Candlestick'> | null>(null)

const watermark = `TradingView Lightweight Charts™\n
Copyright (с) 2023 TradingView, Inc. https://www.tradingview.com/`

const createExternalChart = () => {
  const chartOptions = {
    autoSizeActive: true,
    layout: {
      textColor: 'white',
      background: {
        color: '#141721',
        visible: true,
      },
    },
    grid: {
      vertLines: {
        color: '#363c4e',
      },
      horzLines: {
        color: '#363c4e',
      },
    },
    watermark: {
      color: 'rgba(255, 255, 255, 0.5)',
      visible: true,
      text: watermark,
      vertAlign: 'top' as VertAlign,
      fontSize: 10,
    },
  }
  const container = document.getElementById('tokenChart')

  if (!container) {
    return
  }

  const chart = createChart(container, chartOptions)
  const candlestickSeries = chart.addCandlestickSeries({
    upColor: '#26a69a',
    downColor: '#ef5350',
    borderVisible: false,
    wickUpColor: '#26a69a',
    wickDownColor: '#ef5350',
  })

  chartRef.value = chart
  seriesRef.value = candlestickSeries

  return {
    chart,
    candlestickSeries,
  }
}

watch(addressToFetch, (newAddress, oldAddress) => {
  if (newAddress !== oldAddress && !oldAddress && !!chartRef.value) {
    chartRef.value?.remove()
    chartRef.value = null
  }
})

watchEffect(async () => {
  if (data.value && isFetched.value) {
    if (!chartRef.value) {
      createExternalChart()
      await nextTick()
    }
    seriesRef.value?.setData(data.value.data)
    chartRef.value?.timeScale().fitContent()
    await nextTick()
  } else {
    if (chartRef.value) {
      chartRef.value.remove()
      chartRef.value = null
      seriesRef.value = null
    }
  }
})
</script>

<style scoped>
.token-page-details {
  &__widget {
    height: 100%;
    width: 100%;
  }
}

.token-page-chart {
  height: 16rem;
  width: 100%;

  &__container {
    height: 100%;
    width: 100%;
  }

  &--hidden {
    display: none;
  }
}
</style>
