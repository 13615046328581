<template>
  <div class="search-bar">
    <SearchIcon class="search-bar__search-icon"/>

    <input
      v-model="searchTerm"
      class="search-bar__input"
      type="text"
      :placeholder="placeholder">

    <CrossIcon
      class="search-bar__clear"
      @click="handleCleanSearch"/>
  </div>
</template>

<script lang="ts" setup>
import SearchIcon from '@/assets/icons/search_icon.svg'
import CrossIcon from '@/assets/icons/close-gray.svg'
import { computed } from 'vue'

type Props = {
  modelValue: string
  placeholder: string
}
const props = defineProps<Props>()

const emit = defineEmits<{(e: 'update:modelValue', value: string): void }>()

const searchTerm = computed({
  get: (): string => props.modelValue,
  set: (value: string) => emit('update:modelValue', value),
})

const handleCleanSearch = () => {
  searchTerm.value = ''
}

</script>

<style lang="postcss" scoped>
.search-bar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  max-height: 48px;

  padding: 0.625rem 1rem;
  border: 1px solid var(--gray);
  background-color: var(--input-color);

  width: 40%;

  &__input {
    border: none;
    width: 100%;
    background-color: transparent;
    outline: none;
    color: var(--text-color);

    padding: 0;
    min-width: 23rem;

    @media only screen and (--mobile-viewport) {
      min-width: 100%;
    }

    &::placeholder {
      color: var(--gray);
    }
  }

  &__clear {
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>

<style>
.search-bar .search-bar__search-icon {
  min-width: 1rem;

  path {
    fill: var(--text-color);
  }
}
</style>
