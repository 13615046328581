<template>
  <BaseStatusInfo
    v-if="loanStatus !== null && loanStatus !== undefined && statusVariant"
    :text="statusText"
    :variant="statusVariant"
    :is-without-text="props.isWithoutText"
    :size="props.size"/>
</template>

<script setup lang="ts">
import BaseStatusInfo, { StatusSize, StatusVariant } from '@/general-components/BaseStatusInfo.vue'
import { computed } from 'vue'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import * as Sentry from '@sentry/vue'
import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import type { LoanDetailSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'

interface Props {
  loanStatus?: LoanStatus
  size?: StatusSize
  loan?: BaseLoan | undefined | LoanDetailSchemaWorkaroundBackendSchema
  isWithoutText?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  size: StatusSize.M,
})
function isLoanDetailSchemaWorkaroundBackendSchema(
  loan: BaseLoan | undefined | LoanDetailSchemaWorkaroundBackendSchema,
): loan is LoanDetailSchemaWorkaroundBackendSchema {
  return (loan as LoanDetailSchemaWorkaroundBackendSchema)?.paidBackAt !== undefined
}

const offerCount = computed(() => {
  if (props.loanStatus !== LoanStatus.New) {
    return ''
  } else {
    return '' // TODO loan status
    // return `(${props.loan.validPendingOffersCount})`
  }
})
const isDefaultedAndClaimed = computed(() => {
  return props.loanStatus === 0 && isLoanDetailSchemaWorkaroundBackendSchema(props.loan) && !props.loan?.paidBackAt
})

const getStatusVariant = (loanStatus: LoanStatus): StatusVariant => {
  if (isDefaultedAndClaimed.value) {
    return StatusVariant.Defaulted
  }
  switch (loanStatus) {
  case LoanStatus.InactiveRevoked:
    return StatusVariant.InactiveRevoked
  case LoanStatus.InactiveDefaulted:
    return StatusVariant.InactiveDefaulted
  case LoanStatus.InactivePaidBack:
    return StatusVariant.InactivePaid
  case LoanStatus.New:
    return StatusVariant.Pending // TODO
    // return loan.validPendingOffersCount ? StatusVariant.AvailableOffers : StatusVariant.Pending
  case LoanStatus.Accepted:
    return StatusVariant.Active
  case LoanStatus.AcceptedTransferred:
    return StatusVariant.ActiveTransferred
  case LoanStatus.PaidBack:
    return StatusVariant.Paid
  case LoanStatus.PaidBackTransferred:
    return StatusVariant.PaidBackTransferred
  case LoanStatus.DefaultedTransferred:
    return StatusVariant.DefaultedTransferred
  case LoanStatus.Defaulted:
    return StatusVariant.Defaulted
  case LoanStatus.InactiveExpired:
    return StatusVariant.InactiveExpired
  case LoanStatus.NonExistent:
    return StatusVariant.NonExistent
  default:
    Sentry.captureException(`Unknown loan status = ${loanStatus} passed to LoanStatusInfo component.`)
    // @ts-expect-error FIXME: strictNullChecks
    return null
  }
}

const getStatusName = computed(() => {
  if (isDefaultedAndClaimed.value) {
    return 'Defaulted'
  }
  switch (props.loanStatus) {
  case LoanStatus.New:
    return '' // TODO
    // return this.validPendingOffersCount > 0 ? 'Available offers' : 'Waiting for offers'
  case LoanStatus.Accepted:
  case LoanStatus.AcceptedTransferred:
    return 'Active'
  case LoanStatus.PaidBack:
  case LoanStatus.PaidBackTransferred:
    return 'Paid Back'
  case LoanStatus.Defaulted:
  case LoanStatus.DefaultedTransferred:
    return 'Defaulted'
  case LoanStatus.InactiveRevoked:
    return 'Inactive (Revoked)'
  case LoanStatus.InactiveDefaulted:
    return 'Inactive (Defaulted)'
  case LoanStatus.InactivePaidBack:
    return 'Inactive (Paid back)'
  case LoanStatus.InactiveExpired:
    return 'Inactive (Expired)'
  default:
    return ''
  }
})

const statusText = computed(() => `${getStatusName.value} ${offerCount.value}`)

const statusVariant = computed<StatusVariant | undefined>(() => props.loanStatus !== undefined ? getStatusVariant(props.loanStatus) : undefined)

</script>
