import type { ColumnProperties } from '@/general-components/ColumnProperties'
import {
  sortCollectionResultsByMarketCap,
  sortCollectionResultsByName, sortCollectionResultsByPastWeekVolume,
  sortNFTResultsByAppraisal,
  sortNFTResultsByName,
  sortTokenResultsByName, sortTokenResultsByPrice,
  sortTokenResultsByTradingVolume,
} from '@/modules/pages/pwn-explorer/tabs/SortFunctions'

export enum CollectionSortOption {
  TradingVolume = 'tradingVolume',
  Name = 'name',
  MarketCap = 'marketCap',
}

export const COLLECTION_SORT_OPTIONS_LOOKUP = {
  [CollectionSortOption.TradingVolume]: sortCollectionResultsByPastWeekVolume,
  [CollectionSortOption.Name]: sortCollectionResultsByName,
  [CollectionSortOption.MarketCap]: sortCollectionResultsByMarketCap,
}

export const PWN_EXPLORER_COLLECTIONS: ColumnProperties[] = [
  {
    title: 'Name',
    width: '40%',
    slotName: 'name',
    sortOptionName: CollectionSortOption.Name,
  },
  {
    title: '7D Trading Volume',
    width: '20%',
    slotName: 'tradingVolume',
    sortOptionName: CollectionSortOption.TradingVolume,
  },
  {
    title: 'Mkt Cap',
    width: '15%',
    slotName: 'marketCap',
    sortOptionName: CollectionSortOption.MarketCap,
  },
  {
    title: '',
    width: '5%',
    slotName: 'action',
  },
]

export enum NFTsSortOption {
  Appraisal = 'appraisal',
  Name = 'name',
}

export const NFTS_SORT_OPTIONS_LOOKUP = {
  [NFTsSortOption.Appraisal]: sortNFTResultsByAppraisal,
  [NFTsSortOption.Name]: sortNFTResultsByName,
}

export const PWN_EXPLORER_NFTS: ColumnProperties[] = [
  {
    title: 'Name',
    width: '40%',
    slotName: 'name',
    sortOptionName: NFTsSortOption.Name,
  },
  {
    title: 'Appraisal',
    width: '27%',
    slotName: 'appraisal',
    sortOptionName: NFTsSortOption.Appraisal,
  },
  {
    title: '',
    width: '5%',
    slotName: 'action',
  },
]

export enum TokensSortOption {
  TradingVolume = 'tradingVolume',
  Name = 'name',
  Price = 'price',
}

export const TOKENS_SORT_OPTIONS_LOOKUP = {
  [TokensSortOption.Name]: sortTokenResultsByName,
  [TokensSortOption.Price]: sortTokenResultsByPrice,
  [TokensSortOption.TradingVolume]: sortTokenResultsByTradingVolume,
}

export const PWN_EXPLORER_TOKENS: ColumnProperties[] = [
  {
    title: 'Symbol',
    width: '50%',
    slotName: 'name',
    sortOptionName: TokensSortOption.Name,
  },
  {
    title: 'Price',
    width: '20%',
    slotName: 'price',
    sortOptionName: TokensSortOption.Price,
  },
  {
    title: '1D Trading Volume',
    width: '23%',
    slotName: 'tradingVolume',
    sortOptionName: TokensSortOption.TradingVolume,
  },
  {
    title: '',
    width: '7%',
    slotName: 'action',
  },
]

export const PWN_EXPLORER_WALLETS: ColumnProperties[] = [
  {
    title: 'Name (a-z)',
    width: '45%',
    slotName: 'name',
  },
  {
    title: '',
    width: '45%',
    slotName: '',
  },
  {
    title: 'Loans',
    width: '10%',
    slotName: 'loans',
  },
]
