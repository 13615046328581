<template>
  <slot name="trigger"/>
  <teleport to="body">
    <aside
      v-if="isOpenLocal"
      :class="['modal', {'modal--blur-backdrop': hasBlurBackdrop}]"
      :style="wrapperDynamicStyles"
      @mousedown="close">
      <div
        :class="['modal__content', `modal__content--variant-${props.variant}`, `modal__content--size-${props.size}`]"
        :style="contentCustomStyles"
        @mousedown.stop
        @click.stop>
        <slot name="custom-header">
          <header>
            <div class="modal__heading-container">
              <h2
                :class="['modal__heading', `modal__heading--variant-${props.variant}`, `modal__heading--align-${props.headingAlign}`]"
                :style="props.customHeadingStyles">
                <div>
                  <component
                    :is="props.headingIcon"
                    v-if="props.headingIcon"
                    :class="['modal__heading-icon', `modal__heading-icon--${props.headingIconSize}`]"
                    alt="modal heading icon"/>
                </div>
                {{ props.heading }}
              </h2>
              <svg
                v-if="props.isClosable"
                :class="['modal__close', `modal__close--variant-${props.variant}`]"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                @click.stop="close">
                <line
                  x1="2.06066"
                  y1="1.93934"
                  x2="23.2739"
                  y2="23.1525"
                  stroke="currentColor"
                  stroke-width="3"
                  @click.stop="close"/>
                <line
                  x1="1.93934"
                  y1="23.1542"
                  x2="23.1525"
                  y2="1.94098"
                  stroke="currentColor"
                  stroke-width="3"
                  @click.stop="close"/>
              </svg>
            </div>
            <div class="modal__subheading-container">
              <slot name="subheading"/>
            </div>
          </header>
        </slot>
        <section class="modal__body">
          <slot name="body"/>
        </section>
      </div>
    </aside>
  </teleport>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { Component, StyleValue } from 'vue'
import ModalVariant from '@/general-components/ModalVariant'
import ModalSize from '@/general-components/ModalSize'
import type ModalHeadingIconSize from '@/general-components/ModalHeadingIconSize'

interface Props {
  isOpen: boolean;
  heading?: string;
  size?: ModalSize;
  variant?: ModalVariant;
  isClosable?: boolean;
  headingIcon?: Component
  headingAlign?: 'center' | 'left'
  headingIconSize?: ModalHeadingIconSize;
  customZIndex?: number;
  customMaxWidth?: string;
  customPadding?: string;
  customHeadingStyles?: StyleValue
  hasBlurBackdrop?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  variant: ModalVariant.Default,
  size: ModalSize.Medium,
  isClosable: true,
  headingAlign: 'center',
  customHeadingStyles: () => ({}),
})

const emit = defineEmits<{(e: 'update:isOpen', isOpen: boolean) }>()

defineSlots<{
  // slots return "any" atm as described in official docs
  trigger(): unknown;
  customHeader(): unknown;
  subheading(): unknown;
  body(): unknown;
}>()

const wrapperDynamicStyles = computed(() => {
  return {
    ...(props.customZIndex && { zIndex: props.customZIndex }),
  }
})

const contentCustomStyles = computed(() => {
  return {
    ...(props.customMaxWidth && { maxWidth: props.customMaxWidth }),
    ...(props.customPadding && { padding: props.customPadding }),
  }
})

const isOpenLocal = computed({
  get: (): boolean => props.isOpen,
  set: (value: boolean): void => emit('update:isOpen', value),
})

const isClickedOutside = (event): boolean => event.target !== event.currentTarget

const close = (event: MouseEvent): void => {
  if (!props.isClosable) return
  if (isClickedOutside(event)) return
  isOpenLocal.value = false
}
</script>

<style scoped>
.modal {
  position: fixed;
  inset: 0;

  isolation: isolate;
  z-index: var(--z-index-modal);

  display: grid;
  place-items: center;

  background: var(--background-color-half-transparent);

  &:hover {
    cursor: pointer;
  }

  &--blur-backdrop {
    backdrop-filter: blur(2px);
  }

  &__content {
    max-height: 100%;
    overflow: auto;
    padding: 24px;
    position: relative;

    background: var(--background-color);
    border: 1px solid var(--gray-2, #828282);

    &:hover {
      cursor: default;
    }

    &--size {
      &-x-small {
        width: calc(100% * var(--modal-size-x-small));
        max-width: calc(var(--app-max-width) * var(--modal-size-x-small));
      }

      &-small {
        width: calc(100% * var(--modal-size-small));
        max-width: calc(var(--app-max-width) * var(--modal-size-small));
      }

      &-medium {
        width: calc(100% * var(--modal-size-medium));
        max-width: calc(var(--app-max-width) * var(--modal-size-medium));
      }

      &-large {
        width: calc(100% * var(--modal-size-large));
        max-width: calc(var(--app-max-width) * var(--modal-size-large));
      }

      &-x-large {
        width: calc(100% * var(--modal-size-x-large));
        max-width: var(--app-max-width);
      }
    }

    &--variant {
      &-error {
        background-image: var(--border-error-dashed);
      }
    }
  }

  &__heading-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: center;
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 24px;
    line-height: 2.375rem;
    font-weight: 550;
    display: flex;

    &--align {
      &-center {
        grid-column-start: 2;
      }

      &-left {
        /* allow the heading text to take full width */
        grid-column: 1 / 4;
        justify-self: start;
        width: 100%;
      }
    }

    &--variant {
      &-error {
        color: var(--negative-1);
      }
    }
  }

  &__heading-icon {
    margin-right: 0.75rem;
    width: 2rem;

    &--large {
      width: 6rem;
    }

    &--medium {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  &__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    color: var(--gray);

    &:hover {
      cursor: pointer;
    }

    &--variant {
      &-error {
        color: var(--negative-1);
      }
    }
  }

  @media only screen and (--small-viewport) {
    &__content {
      padding: 1rem 1rem 2.5rem;

      &--size {
        &-medium {
          width: calc(100% * var(--modal-size-medium-mobile));
          max-width: calc(var(--app-max-width) * var(--modal-size-medium-mobile));
        }

        &-small {
          width: calc(100% * var(--modal-size-small-mobile));
          max-width: calc(var(--app-max-width) * var(--modal-size-small-mobile));
        }

        &-large {
          width: calc(100% * var(--modal-size-small-mobile));
          max-width: calc(var(--app-max-width) * var(--modal-size-large-mobile));
        }
      }
    }

    &__heading {
      font-size: 0.875rem;
      line-height: 1rem;
      align-items: center;
    }

    &__close {
      width: 1rem;
      height: 1rem;
    }
  }
}

</style>

<style>
.nouns-subdomain .modal__content {
  background-blend-mode: exclusion;
}
</style>
