// NOTE: direct import from @safe-global/protocol-kit resulted in some TS errors in the compiler, so direct import from folders is workaround
import EthersAdapter from '@safe-global/protocol-kit/dist/src/adapters/ethers/EthersAdapter'
import Safe from '@safe-global/protocol-kit/dist/src/Safe'
import { ref } from 'vue'
import type { Address } from 'viem'
import SafeApiKit from '@safe-global/api-kit'
import type { SafeInfoResponse } from '@safe-global/api-kit'
import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import { getConnectorClient, watchAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from './usePwnWagmiConfig'
import type { SupportedChain } from '@/constants/chains/types'
import { clientToSigner } from './useEthersAdapter'
import { ethers } from 'ethers'
import to from '@/utils/await-to-js'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

export const getProtocolKit = async (chainId: SupportedChain, safeAddress: Address) => {
  const client = await getConnectorClient(pwnWagmiConfig, { chainId })
  if (!client) {
    throw new Error(`Could not get wagmi client for chainId=${chainId}.`)
  }

  const ethAdapter = new EthersAdapter({
    ethers,
    signerOrProvider: clientToSigner(client),
  })
  return await Safe.create({
    ethAdapter,
    safeAddress,
  })
}

export const getInfoAboutSafe = async (chainId: SupportedChain, safeAddress: Address): Promise<SafeInfoResponse> => {
  const apiKit = new SafeApiKit({ chainId: BigInt(chainId) })
  return await apiKit.getSafeInfo(safeAddress)
}

export const useConnectedSafeWalletStore = defineStore(StoreIds.safeWallet, () => {
  const connectedSafeInfo = ref<SafeInfoResponse>()

  // note: if needed, we can also persist here the protocolKit and apiKit instance
  // for the connected safe wallet, however so far we did not require it anywhere
  // so i did not include it

  if (!isStarknet) {
    watchAccount(pwnWagmiConfig, {
      async onChange(account, prevAccount) {
        const { address: userAddress, chainId: connectedChainId, isConnected } = account
        if (!userAddress || !connectedChainId || !isConnected) {
          return
        }

        // TODO we can also potentially restrict this to use only when a contract wallet is created, but we would need
        // to do some reshuffling of the existing logic so it's kept like this so far
        const [error, safeInfo] = await to(
          getInfoAboutSafe(connectedChainId, userAddress),
          { enableLogging: false },
        )
        if (!error && safeInfo) {
          connectedSafeInfo.value = safeInfo
        }
      },
    })
  }

  return {
    connectedSafeInfo,
  }
})
