import { globalConstants } from '@/constants/globals'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import Axios from 'axios'
import qs from 'qs'
import { useAccessTokenStore } from '@/modules/common/backend/useAccessTokenStore'

export const AXIOS_INSTANCE = Axios.create({
  baseURL: globalConstants.backendUrl,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
})
AXIOS_INSTANCE.interceptors.request.use(config => {
  const { accessToken } = useAccessTokenStore()

  if (accessToken.value?.isValid) {
    config.headers.Authorization = `Bearer ${accessToken.value.token}`
  }
  return config
})

export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<AxiosResponse<T, unknown>> => {
  return AXIOS_INSTANCE({ ...options, ...config })
}
