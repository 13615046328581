import type { ColumnProperties } from '@/general-components/ColumnProperties'
import {
  sortAssetsByAmount,
  sortAssetsByAppraisal,
  sortAssetsByName,
  sortAssetsByVerification,
} from '@/general-components/sorting/SortFunctions'

export enum SortOption {
  Name = 'name',
  Verification = 'verification',
  Amount = 'amount',
  Appraisal = 'appraisal',
}

export const SORT_OPTIONS_LOOKUP = {
  [SortOption.Name]: sortAssetsByName,
  [SortOption.Verification]: sortAssetsByVerification,
  [SortOption.Amount]: sortAssetsByAmount,
  [SortOption.Appraisal]: sortAssetsByAppraisal,
}

export const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Asset',
    width: '37%',
    slotName: 'asset-info',
    sortOptionName: SortOption.Name,
  },
  {
    title: 'Amount',
    width: '17%',
    slotName: 'amount',
    sortOptionName: SortOption.Amount,
  },
  {
    title: 'Appraisal',
    width: '13%',
    slotName: 'appraisal',
  },
  {
    title: '',
    width: '20%',
    slotName: 'action',
  },
]

export const SELECT_NFTS_TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '6%',
    slotName: 'checkbox',
  },
  {
    title: 'Asset',
    width: '52%',
    slotName: 'asset-info',
    sortOptionName: SortOption.Name,
  },
  {
    title: '',
    width: '26%',
    slotName: 'verification',
  },
  {
    title: 'Amount',
    width: '16%',
    slotName: 'amount',
    sortOptionName: SortOption.Amount,
  },
]
