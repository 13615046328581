<template>
  <div
    :class="[
      'select-asset-input',
      {
        'select-asset-input--is-not-selected-asset': assetIsNotSelected
      },
    ]"
    @click="handleOpenSelectAssetModal">
    <slot name="header">
      <label class="select-asset-input__label">{{ label }}</label>
    </slot>
    <div
      v-if="form"
      :class="formClasses">
      <div class="select-asset-input__amount-and-appraisal">
        <FormField
          :name="`${amountFieldName}`"
          :validators="{
            onChange: ({ value, fieldApi, form }) => {
              let parsed;

              if (formStore?.values?.[props.assetFieldName]?.category === AssetType.ERC1155) {
                parsed = zodSchemaForERC1155.safeParse(value);
              } else {
                parsed = amountSchema.safeParse(value);
              }

              if (!props.isCollateral && formStore?.values?.[props.assetFieldName]?.symbol === '$PWN-ALL-YOUR-STABLES') {
                const selectedCreditMultiStables = formStore?.values?.[props.assetFieldName]
                const assetsWithMatchingAmounts = selectedCreditMultiStables?.bundleAssets?.filter(
                  (asset) => asset.amountRaw >= parseUnits(value, asset.decimals)
                )
                if (assetsWithMatchingAmounts && assetsWithMatchingAmounts.length === 0) {
                  return 'No assets with matching amount'
                }

                return undefined
              }

              if (!parsed.success) {
                return parsed.error.errors?.[0].message;
              }

              if (props.isCheckUserBalance) {
                const hasBalance = checkUserBalance(value);
                if (!hasBalance) {
                  return 'Not enough funds';
                }
              }
              return undefined;
            },
            onChangeListenTo: [props.amountFieldName, props.amountFieldOnChangeListenTo],
          }">
          <template #default="{ field }">
            <input
              :id="field.name"
              ref="inputRef"
              v-maska="maskTokenAmount(props.selectedAsset?.decimals)"
              autocomplete="off"
              type="text"
              tabindex="0"
              inputmode="decimal"
              :class="amountInputClasses"
              :name="field.name"
              :placeholder="inputPlaceholder"
              :value="String(field.state.value)"
              @blur="emit('blur')"
              @focus="emit('focus')"
              @wheel.stop.prevent
              @input="(e) => handleAmountChange(e, field)">
          </template>
        </FormField>

        <!-- todo: resolve how to show appraisal for multiple assets -->
        <div>
          <AssetAppraisal
            v-if="assetMetadata"
            class="select-asset-input__appraisal"
            :asset-amount="formStore.values[props.amountFieldName]"
            :asset-metadata="assetMetadata"/>
        </div>
      </div>

      <FormField :name="assetFieldName">
        <template #default="{ field }">
          <div
            class="select-asset-input__field-select-wrap">
            <div
              :class="[
                { 'select-asset-input__select-modal-container--selectable': !isAssetLocked },
                {'select-asset-input__select-modal-container--locked': isAssetLocked},
                'select-asset-input__select-modal-container',
              ]"
              @click.stop>
              <div
                v-if="isAssetLocked && selectedAsset"
                class="select-asset-input__selected-locked-asset">
                <TokenMedia
                  :token="selectedAsset"
                  height="20"
                  width="20"/>
                <SafeText
                  has-tooltip
                  :text="selectedAsset.isSymbolMissing ? selectedAsset.name : selectedAsset.symbol"/>
              </div>

              <div
                v-else-if="props.isSelectCollectionAsset"
                @click="storeSelectCollectionAssetModal.isOpen = true">
                <div
                  v-if="selectedAsset"
                  class="select-asset-input__select-collection-asset">
                  <TokenMedia
                    :token="selectedAsset"
                    height="20"
                    width="20"/>
                  <SafeText
                    has-tooltip
                    :text="selectedAsset.isSymbolMissing || props.isSelectCollectionAsset ? selectedAsset.name : selectedAsset.symbol"/>

                  <ArrowSelectSvg/>
                </div>
                <div
                  v-else
                  class="select-asset-input__select-collection-asset">
                  <span> Select Asset </span>
                  <ArrowSelectSvg/>
                </div>
              </div>

              <div v-else>
                <component
                  :is="toRender"
                  v-if="toRender"
                  :selected-token="selectedAsset || undefined"
                  :is-modal-open="isModalOpen"
                  :chain-id="props.chainId === null ? undefined : props.chainId"
                  :header-text="selectCreditAssetModalExtraProps?.header"
                  :bundle-image="bundleImage"
                  :hide-you-own-column="isHideYouOwnColumn"
                  :is-edit="isEdit"
                  :is-fungible-proposal="props.isFungibleProposal"
                  @select-asset="(asset) => handleAssetChange(asset as AssetWithAmount | AssetWithAmount[], field)"/>

                <div
                  v-if="selectedAsset?.bundleAssets.length && !props.isCollateral"
                  class="select-asset-input__matching-multi-credits">
                  {{ matchingAssetAmounts }}
                </div>
              </div>
            </div>
            <div
              v-if="hasMaxButton && selectedAsset?.amount !== '-' && selectedAsset?.amount && selectedAsset?.symbol !== '$PWN-ALL-YOUR-STABLES'"
              class="select-asset-input__max-button"
              @click="() => handleSetMaxAmount(field)">
              <WalletIconSvg
                v-if="!noWalletIcon"
                class="select-asset-input__wallet-icon"/>
              <span v-if="maxButtonAsTextMax">
                Max
              </span>
              <span v-else>
                {{ selectedAsset?.amount ? formatDecimalPoint(selectedAsset?.amount) : '-' }}
              </span>
            </div>
          </div>
        </template>
      </FormField>
    </div>
    <span class="select-asset-input__error-message">
      {{ formStore?.fieldMeta[props.amountFieldName]?.errors?.[0] }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, toRefs, watch } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { z } from 'zod'
import type { ZodSchema } from 'zod'
import { formatDecimalPoint, getRawAmount } from '@/utils/utils'
import SelectYourCollateral from '@/revamp/components/modals/select-your-collateral/SelectYourCollateral.vue'
import type { SupportedChain } from '@/constants/chains/types'
import AssetType from '@/modules/common/assets/AssetType'
import SelectTheirCollateralModal from '@/revamp/components/modals/select-their-collateral/SelectTheirCollateralModal.vue'
import SelectCreditAssetModal from '@/revamp/components/modals/select-credit-asset/SelectCreditAssetModal.vue'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import SelectLentAssetModal from '@/revamp/components/modals/select-lent-asset/SelectLentAssetModal.vue'
import WalletIconSvg from '@/assets/icons/wallet.svg'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
import deepcopy from '@/utils/deepcopyinstance'
import type { VNode } from 'vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import SafeText from '@/general-components/SafeText.vue'
import {
  useSelectCollectionAssetModalStore,
} from '@/revamp/components/modals/select-collection-asset/useSelectCollectionAssetModalStore'
import { parseUnits } from 'viem'
import ArrowSelectSvg from '@/assets/icons/arrow-select.svg'
import { maskTokenAmount } from '@/revamp/utils/inputs'

interface Props {
  amountFieldName: string;
  amountFieldOnChangeListenTo?: string;
  amountSchema: ZodSchema;
  appraisalFieldName: string;
  assetFieldName: string;
  bundleImage?: VNode | undefined;
  chainId?: SupportedChain | null;
  fixedAmount?: string;
  placeholder?: string | number;
  form;
  formerCollateral?: AssetWithAmount | null;
  getLtvForUpdate?: (creditAmount: number) => number | null;
  hideMaxButton?: boolean;
  isAmountAlwaysOne?: boolean;
  isAssetLocked?: boolean;
  isCheckUserBalance?: boolean;
  isCollateral?: boolean;
  isDisabledInput?: boolean;
  isEdit?: boolean;
  isFocusInput?: boolean;
  isFungibleProposal?: boolean;
  isOpenModal?: boolean;
  isSelectCollectionAsset?: boolean;
  label: string;
  maxAmount?: string;
  maxButtonAsTextMax?: boolean;
  modalType?: 'your-assets' | 'their-assets' | 'credit-asset' | 'lent-asset';
  noWalletIcon?: boolean;
  onlyErc20?: boolean;
  selectedAsset?: AssetWithAmount | null;
  showOnlyOwned?: boolean;
  isBorrowing?: boolean
}

const props = defineProps<Props>()
const { address: userAddress } = useCustomAccount()
const storeSelectCollectionAssetModal = useSelectCollectionAssetModalStore()
const { selectedAsset, isAssetLocked, isAmountAlwaysOne, isFungibleProposal } = toRefs(props)
const isInputDisabled = computed(() => {
  return Boolean(
    (selectedAsset.value && !selectedAsset?.value?.isFungible) ||
        (selectedAsset.value && isAmountAlwaysOne.value) ||
        (selectedAsset.value && selectedAsset.value?.isTokenBundle) ||
        props.isDisabledInput,
  )
})

const FormField = props.form.Field
const formStore = props.form.useStore((store) => store)
const proposalFiltersStore = useProposalFilters()

const isModalOpen = ref(false)
const inputRef = ref()

const hasMaxButton = computed(() => {
  if (props.hideMaxButton) return false
  if (!['your-assets', 'lent-asset'].includes(props.modalType || '')) {
    return false
  }

  if (selectedAsset.value?.category === AssetType.ERC20) {
    return true
  }

  if (selectedAsset.value?.category === AssetType.ERC1155 && selectedAsset.value?.amountRaw !== 1n && selectedAsset.value?.amountRaw !== 0n) {
    return true
  }

  if (selectedAsset.value?.category === AssetType.NATIVE_TOKEN) {
    return true
  }
  return false
})

const emit = defineEmits<{(e: 'select-asset', asset: AssetWithAmount | AssetWithAmount[]): void;
  (e: 'on-amount-change', amount: string, isMaxAmountChange?: boolean): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>()

const handleAmountChange = (e: Event, fieldApi) => {
  const newVal = (e.target as HTMLInputElement).value
  emit('on-amount-change', newVal)
  fieldApi.handleChange(newVal)
}

const handleSetMaxAmount = (fieldApi) => {
  if (!selectedAsset.value) return
  const valueToBeSet = selectedAsset.value.category === AssetType.ERC721 ? '1' : selectedAsset?.value?.maxAvailableAmount
  if (props.maxAmount && +props.maxAmount < +valueToBeSet) {
    fieldApi.form.setFieldValue(props.amountFieldName, props.maxAmount, { touch: true })
    fieldApi.form.validateField(props.amountFieldName)
    emit('on-amount-change', props.maxAmount, true)
  } else {
    fieldApi.form.setFieldValue(props.amountFieldName, valueToBeSet, { touch: true })
    fieldApi.form.validateField(props.amountFieldName)
    emit('on-amount-change', valueToBeSet, true)
  }
}

const assetsToBundle = ref<AssetWithAmount[] | null>(null)
const multiProposalCredits = ref<AssetWithAmount[] | null>(null)

const handleAssetChange = (asset: AssetWithAmount | AssetWithAmount[], fieldApi) => {
  assetsToBundle.value = null
  multiProposalCredits.value = null

  if (Array.isArray(asset)) {
    if (props.isCollateral) {
      assetsToBundle.value = asset
      asset = asset[0]
    } else {
      multiProposalCredits.value = asset
      asset = asset[0]
    }
  }
  const assetIsAllStables = asset.category === AssetType.ERC20 && asset.symbol === '$PWN-ALL-YOUR-STABLES'

  if (assetIsAllStables) {
    multiProposalCredits.value = asset.bundleAssets
  }

  if (assetsToBundle.value && props.isCollateral) {
    emit('select-asset', deepcopy(assetsToBundle.value))
  } else if (assetIsAllStables) {
    emit('select-asset', deepcopy(asset))
  } else {
    emit('select-asset', deepcopy(asset))
  }

  fieldApi.handleChange(asset)

  if (asset.category === AssetType.ERC721 || assetsToBundle.value || isAmountAlwaysOne.value) {
    fieldApi.form.setFieldValue(props.amountFieldName, '1')
  } else {
    fieldApi.form.setFieldValue(props.amountFieldName, '')
  }
}

const checkUserBalance = (newVal: string) => {
  if (!userAddress.value || !selectedAsset.value?.amount) return false
  const a = getRawAmount(selectedAsset.value.amount, selectedAsset.value.decimals)
  const b = getRawAmount(String(newVal), selectedAsset.value.decimals)

  return a >= b
}

const handleOpenSelectAssetModal = () => {
  if (selectedAsset.value) return
  if (props.isSelectCollectionAsset) {
    if (!selectedAsset.value) {
      storeSelectCollectionAssetModal.isOpen = true
    }
    return
  }

  isModalOpen.value = false
  if (isAssetLocked.value) return
  if (formStore.value.values?.[props.assetFieldName]) return

  setTimeout(() => {
    isModalOpen.value = true
  }, 100)
}

const components = {
  'their-assets': SelectTheirCollateralModal,
  'your-assets': SelectYourCollateral,
  'credit-asset': SelectCreditAssetModal,
  'lent-asset': SelectLentAssetModal,
}

const toRender = computed(() => {
  if (props.modalType) {
    return components[props.modalType]
  }
  return null
})

watch(selectedAsset, (newSelectedCollateral) => {
  if (newSelectedCollateral) {
    props.form.setFieldValue(props.assetFieldName, newSelectedCollateral)
    if (newSelectedCollateral.category === AssetType.ERC721 || newSelectedCollateral.isTokenBundle) {
      props.form.setFieldValue(props.amountFieldName, '1')
    }
  } else {
    props.form.setFieldValue(props.assetFieldName, undefined)
    props.form.setFieldValue(props.amountFieldName, '')
  }
}, { immediate: true })

watch(() => props.isOpenModal, (newVal) => {
  if (newVal) {
    isModalOpen.value = false
    setTimeout(() => {
      isModalOpen.value = true
    }, 100)
  }
}, { immediate: true })

watch(() => props.isFocusInput, (newVal) => {
  if (newVal && !isAmountAlwaysOne.value) {
    inputRef.value.focus()
  }
}, { immediate: true })

onUnmounted(() => {
  if (props.isCollateral) {
    proposalFiltersStore.actions.updateCollateralAssetsFilter([])
  }
})

const isHideYouOwnColumn = computed(() => {
  return props.modalType === 'credit-asset' && isFungibleProposal.value
})

const selectCreditAssetModalExtraProps = computed(() => {
  if (props.modalType === 'credit-asset') {
    if (isFungibleProposal.value) {
      return {
        header: 'Select Their Collateral Asset',
      }
    }
    return {
      header: 'Select Borrowed Asset',
    }
  } else if (props.modalType === 'lent-asset') {
    return {
      header: 'Select Your Lent Asset',
    }
  }
  return {}
})

const zodSchemaForERC1155 = z.coerce
  .number()
  .positive({ message: 'Amount should be more than 0' })
  .refine((value) => Number.isInteger(value), { message: 'Amount should be a whole number' })

const assetMetadata = computed(() => {
  if (!selectedAsset.value) return null

  if (selectedAsset.value.bundleAssets.length && !props.isCollateral) {
    const bundleAsset = selectedAsset.value.bundleAssets[0]
    return {
      chainId: bundleAsset.chainId,
      address: bundleAsset.address,
      tokenId: bundleAsset.tokenId ? String(bundleAsset.tokenId) : null,
    }
  }

  return {
    chainId: selectedAsset.value.chainId,
    address: selectedAsset.value.address,
    tokenId: selectedAsset.value.tokenId ? String(selectedAsset.value?.tokenId) : null,
  }
})

const assetIsNotSelected = computed(() => {
  return !props.selectedAsset && !isAssetLocked.value
})

const formClasses = computed(() => {
  return [
    'select-asset-input__container',
    ((
      (isAssetLocked.value || isInputDisabled.value) && (!selectedAsset.value?.isFungible || selectedAsset.value?.isTokenBundle)) ||
      (isAmountAlwaysOne.value && selectedAsset.value)) && 'select-asset-input__locked-asset',
    isInputDisabled.value && isAssetLocked.value && 'select-asset-input--is-only-view',
    hasMaxButton.value && 'select-asset-input__container--has-max-button',
  ]
})

const amountInputClasses = computed(() => {
  return [
    'select-asset-input__amount',
    isAssetLocked.value && !selectedAsset.value?.isFungible && 'select-asset-input__locked-asset',
    isInputDisabled.value && 'select-asset-input--is-only-view',
    (!selectedAsset.value?.isFungible || assetsToBundle.value || (isAmountAlwaysOne.value && selectedAsset.value)) && 'select-asset-input__amount-locked-asset pointer-events-none',
  ]
})
watch(() => props.formerCollateral, (newVal) => {
  if (newVal && selectedAsset.value?.isFungible) {
    props.form.setFieldValue(props.amountFieldName, newVal.amount)
  }
}, { immediate: true })

const currentCreditAmount = props.form.useStore((store) => store.values?.[props.amountFieldName])

const matchingAssetAmounts = computed(() => {
  if (!selectedAsset.value?.bundleAssets.length) return []

  const assetsWithDesiredAmount = selectedAsset.value.bundleAssets.filter(v => v.amountRaw > 0n && v.amountRaw >= parseUnits(currentCreditAmount.value, v.decimals))

  if (assetsWithDesiredAmount.length === 1) {
    return `${assetsWithDesiredAmount.length} matching amount`
  }

  return `${assetsWithDesiredAmount.length} matching amounts`
})

const inputPlaceholder = computed(() => {
  return props.placeholder ? String(props.placeholder) : (props.selectedAsset ? '0' : '')
})

</script>

<style scoped>
.select-asset-input {
  position: relative;

  &__label {
    font-size: 0.875rem;
    font-family: var(--font-family-screener);
    font-weight: 400;
    color: #a4a4a4;
  }

  &--has-max-button {
    height: 96px;
  }

  &__select-modal-container {
    position: relative;
    cursor: pointer;
    gap: 0.5rem;
    display: flex;
    height: 28px;

    &--selectable {
      &::after {
        pointer-events: none;
        content: "";
        position: absolute;
        inset: 0;
      }

      &:hover&::after {
        border: 1px solid var(--primary-color-1);
      }
    }
  }

  &--is-not-selected-asset {
    cursor: pointer;

    .select-asset-input__select-modal-container--selectable {
      transition: border 0.3s linear;
    }

    &:hover {
      .select-asset-input__select-modal-container--selectable::after {
        border: 1px solid var(--primary-color-1);
      }
    }
  }

  &__matching-multi-credits {
    color: var(--separation-grey, #828282);
    font-size: 0.75rem;
    font-family: var(--font-family-supreme);
    text-align: right;
    margin-top: 0.25rem;
  }

  &__container {
    margin: 8px 0 0;
    border: 1px solid var(--gray, #696678);
    background: var(--input-color, #080808);
    width: 100%;
    height: 5.0625rem;
    display: flex;
    padding: 1.5rem;
    box-sizing: border-box;
    gap: 0.25rem;

    &--has-max-button {
      height: 96px;
    }
  }

  &__appraisal {
    color: var(--separation-grey, #828282);
    font-size: 0.75rem;
    right: 1rem;
    top: 1rem;

    text-align: left;
    overflow: hidden;
  }

  &__amount-and-appraisal {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.35rem;
  }

  &__amount {
    all: unset;
    width: 100%;
    flex: 1;
    color: var(--text-color);
    background: var(--input-color, #080808);
    cursor: text;
  }

  &__field-select-wrap {
    display: flex;
    gap: 0.5rem;
    flex-flow: column;
    align-items: flex-end;
    justify-content: center;
  }

  &__error-message {
    color: var(--negative-1);
    font-size: 0.9rem;
    position: absolute;
    margin-top: 4px;
    right: 0;
    bottom: -22px;
  }

  &--is-only-view {
    pointer-events: none;
    background: var(--gray-3);
    display: flex;
    justify-content: flex-end;
  }

  &__max-button {
    font-size: 0.75rem;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      text-decoration: none;
    }
  }

  &__selected-locked-asset {
    display: flex;
    align-items: center;
    max-width: 8rem;
    gap: 0.5rem;
  }

  &__selected-collection-asset,
  &__select-collection-asset {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--background-color);
    height: 28px;
    width: 136px;
    font-size: 1rem;
    border: 1px solid var(--text-color);
    color: var(--text-color);

    user-select: none;

    &:hover {
      border: 1px solid var(--text-color);
    }
  }

  &__amount-locked-asset {
    width: 6rem;
    color: var(--text-color);
  }

  &__locked-asset {
    background: var(--gray-3);
  }
}

.pointer-events-none {
  pointer-events: none;
}
</style>

<style>
.select-asset-input__max-button svg path {
  fill: var(--text-color);
}
</style>
