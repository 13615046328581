<template>
  <RevampBaseModal
    v-model:is-open="isOpen"
    heading="Bundled Assets"
    class-name="bundle-detail-modal"
    heading-align="left">
    <template #trigger>
      <slot
        name="trigger"
        :handle-open="() => isOpen = true">
        <BaseButton
          class="collateral-detail__row"
          :color="ButtonColor.Gray"
          button-text="See all assets"
          has-transparent-background
          has-white-hover
          :text-color-override="ButtonTextColorOverride.Primary"
          :variant="ButtonVariant.Outline"
          is-full-width
          @click="isOpen = true"/>
      </slot>
    </template>
    <template #body>
      <div class="bundle-detail-modal__search">
        <SearchBar
          v-model="searchBar"
          class="bundle-detail-modal__search-bar"
          placeholder="Search asset name or address"/>
      </div>
      <BundleDetailTable
        :search="searchBar"
        :assets="props.bundledAssets"/>
    </template>
  </RevampBaseModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import SearchBar from '@/revamp/components/SearchBar.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import BaseButton, { ButtonColor, ButtonTextColorOverride, ButtonVariant } from '@/general-components/BaseButton.vue'
import BundleDetailTable from '@/revamp/components/tables/BundleDetailTable.vue'

interface Props {
  bundledAssets: AssetWithAmount[];
}
const props = defineProps<Props>()
const isOpen = ref(false)

const searchBar = ref('')
</script>

<style lang="postcss" scoped>
.bundle-detail-modal {
  &__search {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  &__search-bar {
    width: 100%;
  }
}
</style>

<style>
.bundle-detail-modal {
  .modal__body {
    min-width: 944px;
  }
}
</style>
