import { computed, ref } from 'vue'
import { SORT_OPTIONS_LOOKUP } from '@/modules/sections/your-assets/your-nfts/YourNFTsDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { filterAssetsInSearchResults } from '@/utils/search'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { storeToRefs } from 'pinia'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

const searchTerm = ref('')

export default function usePwnSafeNfts() {
  const { selectedSortOption } = useYourNFTs()
  const pwnSafeDetailStore = usePwnSafeDetailStore()
  const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

  const getFilteredAndSortedNfts = (nfts: AssetWithAmount[], searchTerm: string, sorting: ActiveSortOption) => {
    let filteredNfts = filterAssetsInSearchResults(nfts, searchTerm)
    filteredNfts = useGlobalFiltersStore().applyShowUnverifiedAssetsFilter(filteredNfts)
    return SORT_OPTIONS_LOOKUP[sorting.id](filteredNfts, sorting.direction)
  }

  const sortedNFTs = computed<AssetWithAmount[]>(() => {
    if (!selectedPwnSafe.value) {
      return []
    }
    return getFilteredAndSortedNfts(selectedPwnSafe.value.nfts, searchTerm.value, selectedSortOption.value)
  })

  const sortedPwnSafeNFTsWithoutATRTokens = computed(() => sortedNFTs.value.filter(nft => !nft.isAtrToken))

  return {
    selectedSortOption,
    getFilteredAndSortedNfts,
    sortedPwnSafeNFTsWithoutATRTokens,
    sortedNFTs,
    searchTerm,
  }
}
