<template>
  <div class="data-source">
    <slot name="textBefore"/>
    <BaseTooltip
      :has-tooltip="props.hasTooltip"
      is-interactive
      :max-width="maxTooltipWidth"
      append-to-body
      :border-color="TooltipBorderColor.White">
      <template #trigger>
        <component
          :is="usedDataSource.logoComponent"
          v-if="usedDataSource"
          :class="['data-source__logo', { 'data-source__is-link': isLink }]"
          :style="{ width: logoWidthPx }"
          :title="usedDataSource.name"
          :alt="usedDataSource.name"
          @click="handleLink"/>
      </template>
      <div style="text-align: center;">
        <span>
          <slot name="tooltipText"/>
        </span>
      </div>
    </BaseTooltip>
    <slot name="textAfter"/>
  </div>
</template>

<script setup lang="ts">
import DataSourceType from '@/general-components/data-source/DataSourceType'
import PwnLogoIconSvg from '@/assets/icons/pwn.svg'
import UpshotLogoIconSvg from '@/assets/icons/upshot.svg'
import NftbankLogoIconSvg from '@/assets/icons/nft-bank.svg'
import EbisusBayLogoIconSvg from '@/assets/icons/ebisusbay.svg'
import UnmarshalLogoIconSvg from '@/assets/icons/unmarshal.svg'
import OpenseaLogoIconSvg from '@/assets/icons/opensea.svg'
import LooksrareLogoIconSvg from '@/assets/icons/looksrare.svg'
import EtherscanLogoIconSvg from '@/assets/icons/etherscan.svg'
import AlchemyLogoIconSvg from '@/assets/icons/alchemy.svg'
import CmcLogoIconSvg from '@/assets/icons/cmc.svg'
import CoingeckoLogoIconSvg from '@/assets/icons/coingecko.svg'
import DexguruLogoIconSvg from '@/assets/icons/dexguru.svg'
import MoralisLogoIconSvg from '@/assets/icons/moralis.svg'
import VenoFinanceLogoIconSvg from '@/assets/icons/veno.svg'
import PendleLogoSvg from '@/assets/icons/pendle.svg'
import SwaapLogoSvg from '@/assets/icons/swaap.svg'
import RevestLogoSvg from '@/assets/icons/revest.svg'
import KyberSwapLogoSvg from '@/assets/icons/kyberswap.svg'
import SommelierLogoSvg from '@/assets/icons/sommelier.svg'
import InstadappLogoSvg from '@/assets/icons/instadapp.svg'
import YearnLogoSvg from '@/assets/icons/yearn.svg'
import BackedLogoSvg from '@/assets/icons/backed.svg'
import BeefyLogoSvg from '@/assets/icons/beefy.svg'
import SushiLogoSvg from '@/assets/icons/sushi.svg'
import MorphoLogoSvg from '@/assets/icons/morpho.svg'
import SuperformLogoSvg from '@/assets/icons/superform.svg'
import KernelLogoPng from '@/assets/icons/kernel.svg'
import MellowLogoSvg from '@/assets/icons/mellow.svg'
import ReserveLogoSvg from '@/assets/icons/reserve.svg'
import SpectraLogoSvg from '@/assets/icons/spectra.svg'
import ResolvLogoSvg from '@/assets/icons/resolv.svg'
import type { Component } from 'vue'
import { computed } from 'vue'
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import { EBISUSBAY_NAME } from '@/constants/chains/cronos'
import links from '@/constants/links'

interface Props {
  dataSource: DataSourceType;
  hasTooltip?: boolean;
  maxTooltipWidth?: number;
  logoWidthPx?: string;
  link?: string | undefined;
}
const props = withDefaults(defineProps<Props>(), {
  hasTooltip: false,
})
interface DataSourceDetail {
  name: string;
  logoComponent: Component;
}

const allDataSources: Record<DataSourceType, DataSourceDetail> = {
  [DataSourceType.LOOKSRARE]: {
    name: 'LooksRare',
    logoComponent: LooksrareLogoIconSvg,
  },
  [DataSourceType.UPSHOT]: {
    name: 'Upshot',
    logoComponent: UpshotLogoIconSvg,
  },
  [DataSourceType.NFTBANK]: {
    name: 'NFTBank',
    logoComponent: NftbankLogoIconSvg,
  },
  [DataSourceType.OPENSEA]: {
    name: 'Opensea',
    logoComponent: OpenseaLogoIconSvg,
  },
  [DataSourceType.ETHERSCAN]: {
    name: 'Etherscan',
    logoComponent: EtherscanLogoIconSvg,
  },
  [DataSourceType.COINMARKETCAP]: {
    name: 'CoinMarketCap',
    logoComponent: CmcLogoIconSvg,
  },
  [DataSourceType.ALCHEMY]: {
    name: 'Alchemy',
    logoComponent: AlchemyLogoIconSvg,
  },
  [DataSourceType.COINGECKO]: {
    name: 'CoinGecko',
    logoComponent: CoingeckoLogoIconSvg,
  },
  [DataSourceType.DEXGURU]: {
    name: 'DexGuru',
    logoComponent: DexguruLogoIconSvg,
  },
  [DataSourceType.MORALIS]: {
    name: 'Moralis',
    logoComponent: MoralisLogoIconSvg,
  },
  [DataSourceType.EBISUSBAY]: {
    name: EBISUSBAY_NAME,
    logoComponent: EbisusBayLogoIconSvg,
  },
  [DataSourceType.UNMARSHAL]: {
    name: 'Unmarshal',
    logoComponent: UnmarshalLogoIconSvg,
  },
  [DataSourceType.VENO_FINANCE]: {
    name: 'Veno Finance',
    logoComponent: VenoFinanceLogoIconSvg,
  },
  [DataSourceType.PENDLE]: {
    name: 'Pendle',
    logoComponent: PendleLogoSvg,
  },
  [DataSourceType.SWAAP]: {
    name: 'Swaap',
    logoComponent: SwaapLogoSvg,
  },
  [DataSourceType.REVEST]: {
    name: 'Revest',
    logoComponent: RevestLogoSvg,
  },
  [DataSourceType.SUPERFORM]: {
    name: 'Superform',
    logoComponent: SuperformLogoSvg,
  },
  [DataSourceType.KYBER_SWAP]: {
    name: 'KyberSwap',
    logoComponent: KyberSwapLogoSvg,
  },
  [DataSourceType.SOMMELIER]: {
    name: 'Sommelier',
    logoComponent: SommelierLogoSvg,
  },
  [DataSourceType.INSTADAPP]: {
    name: 'Instadapp',
    logoComponent: InstadappLogoSvg,
  },
  [DataSourceType.YEARN]: {
    name: 'Yearn',
    logoComponent: YearnLogoSvg,
  },
  [DataSourceType.BACKED]: {
    name: 'Backed',
    logoComponent: BackedLogoSvg,
  },
  [DataSourceType.BEEFY]: {
    name: 'Beefy',
    logoComponent: BeefyLogoSvg,
  },
  [DataSourceType.SUSHI]: {
    name: 'Sushi',
    logoComponent: SushiLogoSvg,
  },
  [DataSourceType.MORPHO]: {
    name: 'Morpho',
    logoComponent: MorphoLogoSvg,
  },
  [DataSourceType.KERNEL]: {
    name: 'Kernel',
    logoComponent: KernelLogoPng,
  },
  [DataSourceType.MELLOW]: {
    name: 'Mellow Finance',
    logoComponent: MellowLogoSvg,
  },
  [DataSourceType.RESERVE]: {
    name: 'Reserve',
    logoComponent: ReserveLogoSvg,
  },
  [DataSourceType.SPECTRA]: {
    name: 'Spectra Finance',
    logoComponent: SpectraLogoSvg,
  },
  [DataSourceType.SEPOLIA]: {
    name: 'PWN Sepolia',
    logoComponent: PwnLogoIconSvg,
  },
  [DataSourceType.RESOLV]: {
    name: 'Resolv',
    logoComponent: ResolvLogoSvg,
  },
  // TODO remove or keep?
  [DataSourceType.PWN_OVERWRITE]: {
    name: 'PWN',
    logoComponent: PwnLogoIconSvg,
  },
}

const usedDataSource = computed<DataSourceDetail | undefined>(() => {
  if (!props.dataSource) {
    return undefined
  }

  return allDataSources[props.dataSource]
})

const isLink = computed(() => {
  return props.link || props.dataSource === DataSourceType.PENDLE
})
const handleLink = () => {
  if (props.link) window.open(props.link, '_blank', 'noopener,noreferrer')
  else if (props.dataSource === DataSourceType.PENDLE) window.open(links.pendleMarket, '_blank', 'noopener,noreferrer')
}
</script>

<style scoped>
.data-source {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1;

  &__logo {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    cursor: help;
  }

  &__is-link {
    cursor: pointer;
  }
}
</style>
