<template>
  <BaseSkeletor v-if="isLoading && !parsedCollateral"/>
  <div
    v-else
    class="collateral-detail">
    <div class="collateral-detail__header">
      <span class="collateral-detail__title">Collateral Info</span>
      <div class="collateral-detail__chain">
        <AssetChainIcon :chain-id="collateral.chainId"/>
        {{ getChainName(parsedCollateral?.chainId!) }}
      </div>
    </div>
    <div style="position: relative;">
      <CollateralInfo
        v-if="parsedCollateral"
        :class="{ 'collateral-detail__fake-asset': isAssetFake(parsedCollateral) }"
        is-only-collection
        :asset-amount="formatUnits(amountComputed, parsedCollateral?.decimals ?? 0)"
        :has-tooltip="false"
        :internal-link="hasInternalLink"
        :proposal-type="proposalType"
        :is-lending-proposal="isLendingProposal"
        :collateral="parsedCollateral"/>
    </div>
    <div class="collateral-detail__stats">
      <div
        class="collateral-detail__row collateral-detail__collateral-value">
        <div class="collateral-detail__label">
          <span>Collateral Value</span>
        </div>
        <div class="collateral-detail__value">
          <AssetAppraisal
            show-source
            :asset-amount="formatUnits(amountComputed, parsedCollateral?.decimals ?? 0)"
            :asset-metadata="collateral"/>
        </div>
      </div>
      <div
        v-if="parsedCollateral?.isErc20 && !isProposalPreview"
        class="collateral-detail__row">
        <div class="collateral-detail__label">
          <span>Price</span>
        </div>
        <div class="collateral-detail__value">
          <AssetPrice
            show-source
            :token-id="String(parsedCollateral?.tokenId || null)"
            :chain-id="String(parsedCollateral?.chainId)"
            :contract-address="parsedCollateral?.address"/>
        </div>
      </div>
      <!--      <div-->
      <!--        v-if="parsedCollateral?.isNft && !isProposalPreview && !parsedCollateral.isTokenBundle"-->
      <!--        class="collateral-detail__row">-->
      <!--        <div class="collateral-detail__label">-->
      <!--          <span>Highest Purchase Offer</span>-->
      <!--        </div>-->
      <!--        <div class="collateral-detail__value">-->
      <!--            <HighestPurchaseOffer :asset="parsedCollateral"/> -->
      <!--          $1312-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        v-if="isAddress(address) && !compareAddresses(zeroAddress, address)"
        class="collateral-detail__row">
        <div class="collateral-detail__label">
          <span>Contract Address</span>
        </div>
        <div class="collateral-detail__value collateral-detail__contract-address">
          <CryptoAddress
            :has-avatar="false"
            is-copy-button
            is-addable
            :chain-id="parsedCollateral?.chainId"
            :address="address as Address"/>
          <img
            v-if="userAddress"
            src="@/assets/icons/wallet+.svg?url"
            alt="Add to wallet"
            width="20"
            class="collateral-detail__add-token-to-wallet"
            @click="addTokenToWallet(parsedCollateral!)">
        </div>
      </div>
      <BundleDetailModal
        v-if="parsedCollateral?.isTokenBundle && parsedCollateral?.bundleAssets"
        :bundled-assets="parsedCollateral?.bundleAssets"/>
      <div
        v-if="!isProposalPreview"
        class="collateral-detail__links">
        <BaseButton
          v-if="websiteText"
          class="collateral-detail__website-btn"
          :color="ButtonColor.Gray"
          :button-text="websiteText"
          is-icon-before-text
          :has-white-hover="true"
          is-full-width
          :font="ButtonFont.Supreme"
          has-transparent-background
          :variant="ButtonVariant.Outline"
          :to="parsedCollateral?.websiteUrl"
          link-target="_blank">
          <template #iconBeforeText>
            <WebsiteIconSvg
              style="margin-right: 0.5rem;"
              height="16"
              width="16"/>
          </template>
        </BaseButton>
        <BaseButton
          :color="ButtonColor.Gray"
          has-transparent-background
          :variant="ButtonVariant.Outline"
          :has-white-hover="true"
          :to="getExplorerAddressLink!"
          :size="ButtonSize.S"
          link-target="_blank">
          <template #iconBeforeText>
            <component
              :is="ExplorerIcon"
              width="16"
              height="16"/>
          </template>
        </BaseButton>
        <BaseButton
          v-if="coingeckoUrl"
          :color="ButtonColor.Gray"
          has-transparent-background
          :variant="ButtonVariant.Outline"
          :to="coingeckoUrl"
          :has-white-hover="true"
          :size="ButtonSize.S"
          link-target="_blank">
          <template #iconBeforeText>
            <CoingeckoIconSvg
              width="16"
              height="16"/>
          </template>
        </BaseButton>
        <RefreshButton
          :size="ButtonSize.S"
          :is-loading="isFetching"
          is-transparent
          :handle-refresh="handleRefresh"/>
      </div>
    </div>
    <!-- todo: change the text according to the design when provided -->
    <div
      v-if="isDynamic"
      class="collateral-detail__dynamic-asset-note">
      Note: This asset requires wrapping.
      <QuestionMarkTooltip
        is-interactive>
        <template #default>
          Wrapping is done using our bundler contract. <BaseLink
            :to="linkTo.bundlerDocs"
            target="_blank">
            Read More...
          </BaseLink>
        </template>
      </QuestionMarkTooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import CollateralInfo from '@/revamp/components/CollateralInfo.vue'
import { computed, toRefs } from 'vue'
import { useFetchAssetMetadata, useFetchNFTAssetCollectionMetadata2, useGetCoingeckoUrl } from '@/modules/common/backend/generated'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'
import { getChainName } from '@/utils/chain'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import BaseButton, { ButtonColor, ButtonFont, ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import { formatUnits, isAddress, zeroAddress } from 'viem'
import type { Address } from 'viem'
import RefreshButton from '@/modules/common/assets/components/RefreshButton.vue'
import WebsiteIconSvg from '@/assets/icons/external-website.svg'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
// import HighestPurchaseOffer from '@/revamp/components/prices/HighestPurchaseOffer.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { addTokenToWallet } from '@/modules/common/web3/walletActions'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import AssetPrice from '@/revamp/components/prices/AssetPrice.vue'
import BundleDetailModal from '@/revamp/components/modals/bundle-detail-modal/BundleDetailModal.vue'
import type AssetType from '@/modules/common/assets/AssetType'
import { NFT_CATEGORIES } from '@/modules/common/assets/AssetType'
import NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import linkTo from '@/constants/links'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import { useQuery } from '@tanstack/vue-query'
import { compareAddresses } from '@/utils/utils'
import CoingeckoIconSvg from '@/assets/icons/coingecko.svg'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import { parseAddress } from '@/utils/address-utils'
interface Props {
  collateral: { chainId: number; address: string; category: AssetType | null; tokenId?: string | number};
  amount?: string | number;
  isProposalPreview?: boolean;
  hasInternalLink?: boolean;
  isDynamic?: boolean;
  proposalType?: string;
  isLendingProposal?: boolean
}

const props = defineProps<Props>()
const { collateral, isDynamic, isLendingProposal } = toRefs(props)
const chainId = computed(() => String(collateral.value.chainId))
const address = computed(() => collateral.value.address)
const tokenId = computed(() => (collateral.value.tokenId ? String(collateral.value.tokenId) : ''))
const amountComputed = computed(() => (props.amount ? BigInt(props.amount) : BigInt(0)))
const { isAssetFake } = useTopTokensStore()

const { address: userAddress } = useCustomAccount()
const collateralMetadataResponse = useFetchAssetMetadata(
  chainId,
  address,
  tokenId,
  {},
  {
    query: {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 2,
    },
  },
)

const assetIsNativeToken = computed(() => address.value && compareAddresses(zeroAddress, parseAddress(address.value)))

const { fetchNativeToken } = useERC20Fetch()

const nativeTokenFetchIsEnabled = computed(() => {
  return !!(userAddress.value && assetIsNativeToken.value && parseAddress(userAddress.value))
})
const parsedChainId = computed(() => Number(chainId.value))

const nativeTokenData = useQuery({
  queryKey: ['nativeToken', parsedChainId, userAddress],
  queryFn: async ({ queryKey }) => {
    const [, chainId, address] = queryKey
    return fetchNativeToken(Number(chainId), String(address) as Address)
  },
  enabled: nativeTokenFetchIsEnabled,
})

const isOnlyCollection = computed(() => {
  return NFT_CATEGORIES.some(nftCategory => nftCategory === props.collateral.category) && !tokenId.value
})

const collateralCollectionQuery = useFetchNFTAssetCollectionMetadata2(
  chainId,
  address,
  {}, {
    query: {
      enabled: isOnlyCollection,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 2,
    },
  })

const isLoading = computed(() => collateralMetadataResponse.isLoading.value || collateralCollectionQuery.isLoading.value)
const isFetching = computed(() => collateralMetadataResponse.isFetching.value || collateralCollectionQuery.isFetching.value)
const parsedCollateral = computed(() => {
  if (assetIsNativeToken.value && nativeTokenData.data?.value) {
    return nativeTokenData.data?.value
  }
  if (isOnlyCollection.value) {
    const collection = collateralCollectionQuery.data?.value?.data && NFTAssetCollection.createFromBackendModel(collateralCollectionQuery.data?.value?.data)
    if (collection) {
      return new AssetMetadata({
        chainId: collection.chainId,
        address: collection.contractAddress,
        name: collection.name,
        image: collection.image,
        symbol: collection.symbol,
        collection,
        id: parseInt(collection.id) + Math.random() * -10000,
        category: collection.category,
      })
    }
  }
  if (!collateralMetadataResponse.data?.value?.data) {
    return null
  }
  return AssetMetadata.createFromBackendModel(collateralMetadataResponse.data?.value?.data)
})

const handleRefresh = () => {
  collateralMetadataResponse.refetch()
  collateralCollectionQuery.refetch()
  return true
}

const coingeckoUrlQuery = useGetCoingeckoUrl(
  computed(() => Number(chainId.value)),
  address,
  {
    query: {
      enabled: true,
      staleTime: 1000 * 60 * 120,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
)

const coingeckoUrl = computed(() => coingeckoUrlQuery.data?.value?.data)

const getExplorerAddressLink = computed(() => {
  return CHAINS_CONSTANTS[chainId.value].explorer.addressDetailsLink(address.value)
})
const ExplorerIcon = computed(() => parsedCollateral.value?.chainId && CHAINS_CONSTANTS[parsedCollateral.value.chainId].explorer?.blockExplorerIcon)

const websiteText = computed(() => {
  const link = parsedCollateral.value?.websiteUrl

  if (link) {
    let strippedLink = link.replace(/^https:\/\//, '')
    if (strippedLink.length > 15) {
      strippedLink = strippedLink.substring(0, 15) + '...'
    }
    return strippedLink
  }

  return null
})
</script>

<style scoped>
.collateral-detail {
  display: flex;
  flex-flow: column;
  gap: 2rem;

  &__fake-asset::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--fake-asset-overlay);
    z-index: var(--z-index-fake-asset-overlay);
  }

  &__collateral-value {
    font-weight: 400;
  }

  &__website-btn {
    font-size: 0.875rem;
    width: 13.7rem;
    height: 1.75rem;
    flex: 1 0 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__links {
    display: flex;
    gap: 0.5rem;
    height: 1.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__stats {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
  }

  &__chain {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }

  &__title {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }

  &__row {
    display: flex;
    padding: 0.5rem 0;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    font-size: 0.875rem;
    font-family: var(--font-family-supreme);
    color: var(--text-color-secondary);
  }

  &__value {
    font-size: 0.875rem;
    font-family: var(--font-family-supreme);
  }

  &__dynamic-asset-note {
    font-size: 0.875rem;
    font-family: var(--font-family-supreme);
    color: var(--text-color-secondary);
    border: 1px solid  #828282;
    padding: 0.5rem;
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 0.5rem;
  }

  &__contract-address {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
  }

  &__add-token-to-wallet {
    cursor: pointer;
  }
}
</style>

<style>
.collateral-detail {
  .ens-name__text {
    font-family: var(--font-family-supreme);
    text-decoration: underline;
  }
}
</style>
