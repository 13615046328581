import { AssetPrice } from '@/modules/common/assets/typings/AssetPriceClasses'
import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import type { ERC20AssetStatsSchemaBackendSchema } from '@/modules/common/backend/generated'

export default class TokenPriceStats {
  latestPrice: AssetPrice | undefined
  volume24h: AmountInEthAndUsd | undefined
  marketCap: AmountInEthAndUsd | undefined
  circulatingSupply: string | undefined

  constructor(tokenPriceStats: Partial<TokenPriceStats>) {
    this.latestPrice = tokenPriceStats?.latestPrice
    this.volume24h = tokenPriceStats?.volume24h
    this.marketCap = tokenPriceStats?.marketCap
    this.circulatingSupply = tokenPriceStats?.circulatingSupply
  }

  public static createFromBackendModel(tokenPriceStats: ERC20AssetStatsSchemaBackendSchema): TokenPriceStats {
    return new TokenPriceStats({
      latestPrice: tokenPriceStats?.latest_price ? AssetPrice.createFromBackendModel(tokenPriceStats.latest_price) : undefined,
      volume24h: tokenPriceStats?.volume_24h
        ? AmountInEthAndUsd.createFromBackendModel({
          eth_amount: String(tokenPriceStats.volume_24h.eth_volume),
          usd_amount: String(tokenPriceStats.volume_24h.usd_volume),
        })
        : undefined,
      marketCap: !tokenPriceStats?.market_cap?.usd_amount || Number(tokenPriceStats?.market_cap.usd_amount) === Number(0) ? undefined : AmountInEthAndUsd.createFromBackendModel(tokenPriceStats?.market_cap),
      circulatingSupply: !tokenPriceStats?.circulating_supply || Number(tokenPriceStats?.circulating_supply) === Number(0) ? undefined : tokenPriceStats?.circulating_supply,
    })
  }
}
