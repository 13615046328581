import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { V1_2SimpleLoanSimpleProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import { useCreateProposalPayload } from '@/revamp/components/proposal-form/useCreateProposalPayload'
import type { CreateProposalFormValue } from '@/revamp/components/proposal-form/useCreateProposalPayload'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { DefaultError } from '@tanstack/vue-query'
import { useApproveThesisAssets } from './thesis/useApproveThesisAssets'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import { compareAssets } from '@/utils/utils'
import { ToastStep, Toast } from '@/modules/common/notifications/useToastsStore'
import { ref } from 'vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { freeUserNonceRetrieve, getProposalAndLoanListQueryKey, proposalCreateBatch } from '@/modules/common/backend/generated'
import type { CreateSimpleLoanSimpleProposalRequestSchemaRequestBackendSchema } from '@/modules/common/backend/generated'
import type { Hash } from 'viem'
import { SimpleMerkleTree } from '@openzeppelin/merkle-tree'
import V1_2SimpleLoanSimpleProposalContract
  from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanSimpleProposalContract'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import useActionFlow from '@/modules/common/notifications/useActionFlow'

export const useMultiCreditsProposal = (
  onClose?: VoidFunction,
) => {
  const queryClient = useQueryClient()
  const { createProposalPayload } = useCreateProposalPayload()
  const { checkPreviousApprovals, approveThesisAssets } = useApproveThesisAssets()

  const getProposalsToCreate = (
    creditAssets: AssetWithAmount[],
    terms: CreateProposalFormValue,
  ) => {
    const toCreate: CreateProposalFormValue[] = []

    for (const credit of creditAssets) {
      toCreate.push({
        ...terms,
        creditAsset: credit,
      })
    }

    return toCreate
  }

  const getPayload = async (value: CreateProposalFormValue) => {
    const payload = await createProposalPayload(value, true, V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL)

    const parsedValue = await ProposalFactory.createProposalFromBackendModel(
      payload,
      value.collateralAsset,
      value.creditAsset,
    )

    return {
      payload,
      decoded: parsedValue,
    }
  }

  const issueAndAssignNonces = async (
    proposals: V1_2SimpleLoanSimpleProposal[],
  ) => {
    const chainId = proposals[0].chainId
    const proposerAddress = proposals[0].proposer

    const freeUserNonceData = await freeUserNonceRetrieve(
      String(chainId),
      CHAINS_CONSTANTS[chainId].pwnV1_2Contracts.pwnRevokedNonce,
      proposerAddress,
      {
        nonce_count_to_reserve: 1,
      },
    )

    if (!freeUserNonceData.data) {
      throw new Error(freeUserNonceData.data)
    }

    const res: V1_2SimpleLoanSimpleProposal[] = []

    proposals.forEach((proposal) => {
      const nonce = freeUserNonceData.data.freeUserNonces[0]
      const nonceSpace = freeUserNonceData.data.freeUserNonceSpace

      proposal.nonce = BigInt(nonce)
      proposal.nonceSpace = BigInt(nonceSpace)

      res.push(proposal)
    })

    return res
  }

  const createMultiProposal = useMutation<undefined, DefaultError, {
    values: CreateProposalFormValue[],
  }>({
    mutationKey: ['createMultiProposal'],
    mutationFn: async ({ values }) => {
      const encodedProposals = await Promise.all(values.map(getPayload))
      const assetsForApproval = encodedProposals.map(({ decoded: proposal }) => proposal.creditAsset)

      const uniqueAssetsForApproval: AssetWithAmount[] = []
      const toastSteps: ToastStep[] = []

      const toast = ref<Toast>()

      for (const asset of assetsForApproval) {
        if (!uniqueAssetsForApproval.some(a => compareAssets({
          assetA: a,
          assetB: asset,
        }))) {
          uniqueAssetsForApproval.push(asset)
        }
      }

      const chainId = encodedProposals[0].decoded.chainId
      const spenderAddress = CHAINS_CONSTANTS[chainId].pwnV1_2Contracts?.pwnSimpleLoan
      const approvals = await checkPreviousApprovals(uniqueAssetsForApproval, spenderAddress)

      const assetsRequireApproval = approvals.filter(approval => !approval.isApproved).map(approval => approval.asset)
      if (assetsRequireApproval.length > 0) {
        const steps = approveThesisAssets(assetsRequireApproval, spenderAddress, chainId)
        toastSteps.push(...steps)
      }

      let proposalHashes: Hash[] = []
      let proposals: V1_2SimpleLoanSimpleProposal[] = []

      type MultiProposalResult = Awaited<ReturnType<typeof V1_2SimpleLoanSimpleProposalContract.createMultiProposalHashAndSignature>>
      let signedData: MultiProposalResult | undefined
      let tree: SimpleMerkleTree | undefined

      toastSteps.push(new ToastStep({
        text: 'Constructing the merkle tree',
        async fn(step) {
          proposals = await issueAndAssignNonces(encodedProposals.map(({ decoded }) => decoded as V1_2SimpleLoanSimpleProposal))
          const res = await Promise.all(
            proposals.map((v) => v.proposalContract.createProposalHash(v)),
          )
          proposalHashes = res.map(v => v.proposalHash)
          const leaves = proposalHashes.map((hash) => hash)

          if (leaves.length === 0) {
            throw new Error('No proposals to create')
          }

          tree = SimpleMerkleTree.of(proposalHashes)

          return true
        },
      }))

      toastSteps.push(new ToastStep({
        text: 'Signing the multiproposal',
        async fn(step) {
          if (!tree) {
            throw new Error('Tree not created')
          }

          signedData = await V1_2SimpleLoanSimpleProposalContract.createMultiProposalHashAndSignature(tree.root, chainId)

          return true
        },
      }))

      toastSteps.push(new ToastStep({
        text: 'Creating the proposals',
        async fn(step) {
          if (!signedData || !tree || !proposals) {
            throw new Error('Signed data not created')
          }

          const proposalsWithMerkleProof = encodedProposals.map(({ payload: p }, i) => {
            const proposalParsed = proposals[i]
            const proposalHash = proposalHashes[i]

            const proposalNonce = proposalParsed.nonce
            const proposalNonceSpace = proposalParsed.nonceSpace

            const res = {
              ...p,
              multiproposal_merkle_root: tree!.root,
              nonce: String(proposalNonce),
              nonce_space: String(proposalNonceSpace),
              hash: proposalHash,
              signature: signedData!.signature,
            } as unknown as CreateSimpleLoanSimpleProposalRequestSchemaRequestBackendSchema
            return res
          })
          await proposalCreateBatch(proposalsWithMerkleProof)

          return true
        },
      }))

      toast.value = new Toast({
        title: 'Creating the proposals',
        chainId,
        steps: toastSteps,
        firstAsset: assetsForApproval[0],
      }, NotificationFrontendOnlyActionEnum.MULTI_PROPOSALS_CREATED, assetsForApproval)

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const { continueFlow } = useActionFlow(toast as Ref<Toast>)

      await continueFlow()
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries({
        queryKey: getProposalAndLoanListQueryKey({ isOffer: true }),
      })
      onClose?.()
    },
  })

  return {
    getProposalsToCreate,
    createMultiProposal,
  }
}
