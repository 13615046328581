import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import { getStarknetTransport } from '@/modules/common/starknet/usePwnStarknetConfig'
import { CairoCustomEnum, CairoOption, CairoOptionVariant, CallData, Contract, num, uint256 } from 'starknet'
import type { BigNumberish, Call, Uint256 } from 'starknet'
import { ABI as SimpleLoanABI } from '@/contracts/starknet/pwn/generated/pwn-simple-loan'
import { ABI as RevokedNonceABI } from '@/contracts/starknet/pwn/generated/revoke-starknet'
import { ABI as SimpleLoanFungibleProposalABI } from '@/contracts/starknet/pwn/generated/simple-loan-fungible-proposal'
import { SupportedChain } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { Address, Hex } from 'viem'
import { starknetAccount } from '@/modules/common/web3/useCustomAccount'
import type { V1_2SimpleLoanFungibleProposalStruct } from '@/contracts/structs'
import AssetType from '@/modules/common/assets/AssetType'

export const getStarknetLenderSpecHash = async (proposal: BaseProposal) => {
  const transport = getStarknetTransport(proposal.chainId)

  const contract = new Contract(SimpleLoanABI, proposal.loanContractAddress, transport).typedv2(SimpleLoanABI)
  const response = await contract.get_lender_spec_hash({ source_of_funds: proposal.sourceOfFunds ?? proposal.proposer })
  // if something is not right with the proposer spec the problem is here
  return response as Address
}

export const starknetCreateMultiProposalHashAndSignature = async (merkleRoot: string) => {
  const transport = getStarknetTransport(SupportedChain.StarknetSepolia)

  const contract = new Contract(SimpleLoanFungibleProposalABI, CHAINS_CONSTANTS[SupportedChain.StarknetSepolia]?.pwnV1_2Contracts?.pwnSimpleLoanFungibleProposal, transport).typedv2(SimpleLoanFungibleProposalABI)
  const response = await contract.get_multiproposal_hash({
    merkle_root: merkleRoot,
  })
  return response
}

export const starknetCreateLoan = async (
  proposalClass: BaseProposal,
  encodedProposalData: bigint[],
  proposalInclusionProof: BigNumberish[],
  validatedProposalSignature: Hex,
  nonceToRevoke?: bigint,
) => {
  const loanContractAddress = CHAINS_CONSTANTS[proposalClass.chainId]?.pwnV1_2Contracts.pwnSimpleLoan
  const contract = new Contract(SimpleLoanABI, loanContractAddress, starknetAccount.value).typedv2(SimpleLoanABI)

  const extra = new CairoOption<BigNumberish>(CairoOptionVariant.None, undefined)

  const lenderSpec = {
    source_of_funds: proposalClass.sourceOfFunds ? proposalClass.sourceOfFunds : (proposalClass.isOffer ? proposalClass.proposer : proposalClass.acceptor!),
  }
  const callerSpec = {
    refinancing_loan_id: 0n,
    revoke_nonce: Boolean(nonceToRevoke),
    nonce: nonceToRevoke ?? 0n,
    permit_data: 0n,
  }

  const proposalSpec = {
    proposal_contract: proposalClass.proposalContractAddress,
    proposal_data: encodedProposalData,
    proposal_inclusion_proof: proposalInclusionProof,
    signature: {
      r: 0n,
      s: 0n,
    },
  }
  // eslint-disable-next-line @typescript-eslint/await-thenable
  const response = await contract.create_loan(
    // @ts-expect-error types missmatch here but we don't care
    proposalSpec,
    lenderSpec,
    callerSpec,
    extra,
  )
  const transactionRes = await starknetAccount.value?.waitForTransaction(response.transaction_hash)
  if (transactionRes) {
    const parsedEvents = contract.parseEvents(transactionRes)
    const loanCreatedEvent = parsedEvents[0]['pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanCreated']
    return loanCreatedEvent
  }
  return response
}

export const starknetRepayLoan = async (loanId: bigint) => {
  const loanContractAddress = CHAINS_CONSTANTS[SupportedChain.StarknetSepolia]?.pwnV1_2Contracts?.pwnSimpleLoan
  const contract = new Contract(SimpleLoanABI, loanContractAddress, starknetAccount.value).typedv2(SimpleLoanABI)
  // eslint-disable-next-line @typescript-eslint/await-thenable
  const response = await contract.repay_loan(loanId, 0n)
  await starknetAccount.value?.waitForTransaction(response.transaction_hash)
  return response
}

export const revokeStarknetNonces = async (noncesToRevoke: bigint[]) => {
  if (!starknetAccount.value) {
    throw new Error('No starknet account')
  }
  const contract = new Contract(RevokedNonceABI, CHAINS_CONSTANTS[SupportedChain.StarknetSepolia]?.pwnV1_2Contracts?.pwnRevokedNonce, starknetAccount.value).typedv2(RevokedNonceABI)
  // eslint-disable-next-line @typescript-eslint/await-thenable
  const response = await contract.revoke_nonces(noncesToRevoke)
  await starknetAccount.value?.waitForTransaction(response.transaction_hash)
  return true
}

const categoryToCairoEnum = (category: AssetType) => {
  const myCustomEnum = new CairoCustomEnum({
    ERC20: '()',
  })

  switch (category) {
  case AssetType.ERC20:
    return myCustomEnum
  case AssetType.ERC721:
    return myCustomEnum.variant.ERC721
  case AssetType.ERC1155:
    return myCustomEnum.variant.ERC1155
  default:
    throw new Error('Invalid asset category')
  }
}

export const toStarknetProposalStruct = (proposalStruct: V1_2SimpleLoanFungibleProposalStruct) => {
  return {
    collateral_category: categoryToCairoEnum(proposalStruct.collateralCategory),
    collateral_address: proposalStruct.collateralAddress,
    collateral_id: proposalStruct.collateralId,
    min_collateral_amount: proposalStruct.minCollateralAmount,
    check_collateral_state_fingerprint: proposalStruct.checkCollateralStateFingerprint,
    collateral_state_fingerprint: proposalStruct.collateralStateFingerprint,
    credit_address: proposalStruct.creditAddress,
    accruing_interest_APR: proposalStruct.accruingInterestAPR,
    duration: proposalStruct.duration,
    expiration: proposalStruct.expiration,
    credit_per_collateral_unit: proposalStruct.creditPerCollateralUnit,
    available_credit_limit: proposalStruct.availableCreditLimit,
    fixed_interest_amount: proposalStruct.fixedInterestAmount,
    nonce_space: proposalStruct.nonceSpace,
    nonce: proposalStruct.nonce,
    loan_contract: proposalStruct.loanContract,
    proposer: proposalStruct.proposer,
    proposer_spec_hash: proposalStruct.proposerSpecHash,
    allowed_acceptor: proposalStruct.allowedAcceptor,
    is_offer: proposalStruct.isOffer,
    refinancing_loan_id: proposalStruct.refinancingLoanId,
  }
}

export const readStarknetFungibleProposalGetHash = async (
  proposal: BaseProposal,
  proposalStruct: V1_2SimpleLoanFungibleProposalStruct,
) => {
  const transport = getStarknetTransport(proposal.chainId)
  const proposalAddress = CHAINS_CONSTANTS[proposal.chainId]?.pwnV1_2Contracts?.pwnSimpleLoanFungibleProposal
  const contract = new Contract(SimpleLoanFungibleProposalABI, proposalAddress, transport).typedv2(SimpleLoanFungibleProposalABI)
  const v = toStarknetProposalStruct(proposalStruct)
  const response = await contract.call('get_proposal_hash', [v])
  return num.toHex(response as BigNumberish)
}

export const batchCreateStarknetFungibleProposals = async (proposals: V1_2SimpleLoanFungibleProposalStruct[]) => {
  const transport = getStarknetTransport(SupportedChain.StarknetSepolia)

  const contract = new Contract(SimpleLoanFungibleProposalABI, CHAINS_CONSTANTS[SupportedChain.StarknetSepolia]?.pwnV1_2Contracts?.pwnSimpleLoanFungibleProposal, transport).typedv2(SimpleLoanFungibleProposalABI)
  const calldata = new CallData(contract.abi)

  const calls: Call[] = proposals.map((p) => {
    const parsed = toStarknetProposalStruct(p)
    const compiled = calldata.compile('make_proposal', [parsed])
    return ({
      contractAddress: contract.address,
      entrypoint: 'make_proposal',
      calldata: compiled,
    })
  })

  await starknetAccount.value?.execute(calls)
}

export const readStarknetFungibleProposalEncodeData = async (
  proposal: BaseProposal,
  proposalStruct: V1_2SimpleLoanFungibleProposalStruct,
  proposalValues: {
    collateralAmount: bigint
  },
) => {
  const transport = getStarknetTransport(proposal.chainId)
  const proposalAddress = CHAINS_CONSTANTS[proposal.chainId]?.pwnV1_2Contracts?.pwnSimpleLoanFungibleProposal
  const contract = new Contract(SimpleLoanFungibleProposalABI, proposalAddress, transport).typedv2(SimpleLoanFungibleProposalABI)
  const v = toStarknetProposalStruct(proposalStruct)
  const response = await contract.encode_proposal_data(
    v,
    {
      collateral_amount: proposalValues.collateralAmount,
    },
  )
  // if something is not right with the proposer spec the problem is here
  return response
}

export const starknetGetLoanRepaymentAmount = async (loanId: bigint) => {
  const transport = getStarknetTransport(SupportedChain.StarknetSepolia)
  const loanContractAddress = CHAINS_CONSTANTS[SupportedChain.StarknetSepolia]?.pwnV1_2Contracts?.pwnSimpleLoan
  const contract = new Contract(SimpleLoanABI, loanContractAddress, transport).typedv2(SimpleLoanABI)
  const response = await contract.get_loan_repayment_amount(loanId)
  // @ts-expect-error types missmatch here but we don't care
  if (uint256.isUint256(response)) {
    return uint256.uint256ToBN(response as Uint256)
  }
  return response as bigint
}
