<template>
  <div class="choose-bundle__wrapper-search-and-actions">
    <BaseSearch
      v-model="searchTerm"
      :class="['choose-bundle__search', { 'choose-bundle__search--selected': searchTerm }]"
      search-placeholder="Search bundle"
      has-clear-button
      @update:model-value="setSearchTerms"/>
    <div class="choose-bundle__actions">
      <RefreshAssets :show-text="false"/>
      <ChainGroupFilter
        :selected-chains="selectedChains!"
        :set-chain="selectedChainsStore.actions.setSelectedChain"/>
    </div>
  </div>
  <SectionChooseBundle/>

  <div class="choose-bundle__continue">
    <BaseButton
      :is-disabled="!selectedBundle"
      button-text="Continue"
      @on-button-click="goToSubmitUnpackBundle"/>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/general-components/BaseButton.vue'
import { onMounted, ref } from 'vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import useUnpackBundle from '@/modules/pages/token-bundler/unpack-bundle/useUnpackBundle'
import SectionChooseBundle from '@/modules/pages/token-bundler/unpack-bundle/section-choose-bundle/SectionChooseBundle.vue'
import useChooseBundle from '@/modules/pages/token-bundler/unpack-bundle/section-choose-bundle/useChooseBundle'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import RefreshAssets from '@/modules/pages/dashboard/RefreshAssets.vue'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { goToSubmitUnpackBundle, selectedBundle } = useUnpackBundle()
const selectedChainsStore = useSelectedChainsStore()
const { address: userAddress } = useCustomAccount()
const { selectedChains } = storeToRefs(selectedChainsStore)

const searchTerm = ref('')
const setSearchTerms = () => {
  useChooseBundle().searchTerm.value = searchTerm.value
}

onMounted(() => {
  if (userAddress.value) {
    useUserAssetsStore().loadUserAssets(userAddress.value)
  }
})

</script>

<style scoped>
.choose-bundle {
  &__search {
    flex: 1 0;
    border: 1px solid var(--gray);
    padding: 0.3rem;
    font-family: var(--font-family-oxygen-mono);
    max-height: 42px;

    &--selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__wrapper-search-and-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 3.5rem 0 2.5rem;
    justify-content: space-between;
    column-gap: 0.5rem;
  }

  &__continue {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: 1rem;
    padding-top: 1rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 1px;
      width: 100%;
      background-image: var(--border-gray-dashed);
      background-size: auto 2px;
      /* small hack how to hide buggy double border (top + bottom), when height is 1px */
    }
  }
}
</style>
