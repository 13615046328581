import numeral from 'numeral'
import type { AnyFunction } from '@/modules/common/typings/customTypes'
import { globalConstants } from '@/constants/globals'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import AssetType from '@/modules/common/assets/AssetType'
import { useEnsStore } from '@/modules/common/web3/useEnsStore'
import { formatUnits, isAddress, isAddressEqual, parseUnits } from 'viem'
import type { Address } from 'viem'
import router from '@/router'
import RouteName from '@/router/RouteName'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import type { CustomAddress } from '@/modules/common/web3/useCustomAccount'
import { parseRepeatedDecimal } from '@/general-components/safe-display-decimals/utils'
import { DepositProtocol } from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import AaveIcon from '@/assets/icons/aave.svg'
import CompoundIcon from '@/assets/icons/compound.svg'
import MorphoIcon from '@/assets/icons/morpho-logo.svg'
import { validateAndParseAddress } from 'starknet'
export function * chunks<ArrayItemType>(array: ArrayItemType[], chunkSize: number): Generator<ArrayItemType[], void> {
  for (let i = 0; i < array.length; i += chunkSize) {
    yield array.slice(i, i + chunkSize)
  }
}

export const partition = <T>(array: T[], filterFunction: (item: T, index?: number, iteratedArray?: T[]) => boolean): [T[], T[]] => {
  const pass: T[] = []
  const fail: T[] = []
  array.forEach((e, idx, arr) => (filterFunction(e, idx, arr) ? pass : fail).push(e))
  return [pass, fail]
}

export const isLocalhost = (): boolean => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
}

export const isEmpty = (object: Record<string, unknown>): boolean => {
  return object && Object.keys(object).length === 0 && object.constructor === Object
}

export const isObject = (variable: unknown): boolean => {
  return variable !== null && typeof variable === 'object' && !Array.isArray(variable)
}

export const functionExists = (functionToCheck: AnyFunction): boolean => {
  return typeof functionToCheck === 'function'
}

export const parseToNumber = (number: string | number): number | null => {
  if (typeof number === 'number') {
    return number
  } else if (!number) {
    return null
  }

  try {
    return parseFloat(number)
  } catch (error) {
    return null
  }
}

export const shortenAddress = (address: Address | null, startLettersCount = 5, endLettersCount = 39): string => {
  if (isStarknet) {
    return address ? `${address.slice(0, startLettersCount)}…${address.slice(address.length - 3, address.length)}` : ''
  }
  return address && isAddress(address) ? `${address.slice(0, startLettersCount)}…${address.slice(endLettersCount, 42)}` : ''
}

export const compareCaseInsensitive = (a: string, b: string): boolean => {
  if (!a || !b) return false
  return a.localeCompare(b, 'en-US', { sensitivity: 'accent', usage: 'search', localeMatcher: 'lookup' }) === 0
}

export const includesCaseInsensitive = (haystack: string | undefined, needle: string): boolean => {
  if (!haystack || !needle) return false
  haystack = haystack.toUpperCase()
  needle = needle.toUpperCase()
  return haystack?.includes(needle) ?? false
}

export const indexOfEnd = (string: string, needle: string): number => {
  const index = string.indexOf(needle)
  return index === -1 ? -1 : index + needle.length
}

export const formatAmountWithDecimals = (amount: bigint, decimals: number | undefined | null): string => {
  if (!amount) {
    return '-' // todo does this make sense?
  }
  return formatUnits(amount, decimals ?? globalConstants.defaultErc20Decimals)
}

export const shortenNumber = (amount: string): string => {
  const shorten = numeral(amount).format('0,0.00')
  if (parseFloat(amount) < 0.01) return '<0.01'
  if (parseFloat(amount) < 0.1) return '<0.1'
  return shorten.includes('NaN') ? amount : shorten
}

export const formatToken = (amount: string): string => {
  if (parseFloat(amount) === 0) return '0'
  if (parseFloat(amount) < 0.001) return '<0.001'
  const formatted = numeral(amount).format('0,0.[0000]')
  return formatted === 'NaN' ? amount : formatted
}

export const getRawAmount = (amount: string, decimals: number | undefined): bigint => {
  if (!amount) return 0n

  try {
    return parseUnits(amount, decimals ?? 0)
  } catch (error) {
    if (globalConstants.environment === 'development') {
      // eslint-disable-next-line no-console
      console.debug(`ERROR: getRawAmount => can't parse amount=${amount} with decimals=${decimals}.`)
    }
    return 0n
    // TODO shall we return here 0n or null?
  }
}

export const singularOrPlural = (count: number, singular: string, plural?: string): string => {
  return count === 1 ? singular : (plural || `${singular}s`)
}

export const replaceCommaByDecimalPoint = (inputValue: string): string => inputValue.replace(/,/g, '.')

export const isWholeNumber = (number: number): boolean => number % 1 === 0

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1)

// for more details see - https://github.com/vuejs/core/issues/1081#issuecomment-1033224354
export const resetReactiveObject = (reactiveObject: Record<string, unknown>): void => {
  for (const property in reactiveObject) {
    delete reactiveObject[property]
  }
}

export const compareAddresses = (a: CustomAddress | undefined, b: CustomAddress | undefined): boolean => {
  if (a === undefined && b === undefined) {
    return true
  } else if (!a || !b) {
    return false
  } else {
    if (isStarknet) {
      return validateAndParseAddress(a) === validateAndParseAddress(b)
    }
    if (isAddress(a) && isAddress(b)) {
      return isAddressEqual(a, b)
    }
  }
  return false
}

type AssetIdentifiers = Pick<AssetMetadata, 'address' | 'category' | 'tokenId' | 'chainId'>
interface CompareAssetsParams {
  assetA: AssetIdentifiers
  assetB: AssetIdentifiers
}

export const compareAssets = ({ assetA, assetB }: CompareAssetsParams): boolean => {
  if ((!assetA || !assetB) || Number(assetA.category) !== Number(assetB.category)) {
    return false
  } else if (Number(assetA.category) === AssetType.ERC20 || (Number(assetA.category) === AssetType.UNSUPPORTED && (assetA.tokenId === null || assetA.tokenId === undefined))) {
    return compareAddresses(assetA.address, assetB.address) && assetA.chainId === assetB.chainId
  } else {
    return compareAddresses(assetA.address, assetB.address) && assetA.chainId === assetB.chainId && String(assetA.tokenId) === String(assetB.tokenId)
  }
}

// TODO update this for e.g. other domains?
export const isEns = (input: string): boolean => {
  if (!input) {
    return false
  }
  return input.toLowerCase().endsWith('.eth')
}

export const isBasename = (input: string): boolean => {
  if (!input) {
    return false
  }

  return input.toLowerCase().endsWith('.base.eth')
}

export const formatEthSuffix = (inputString: string): string => {
  if (!inputString.endsWith('.eth')) {
    inputString = inputString.replace(/\.et(?!h)/g, '.eth').replace(/\.e(?!th)/g, '.eth')
    inputString = inputString.replace(/\.{2,}/g, '.') // Replace multiple consecutive '.' with a single '.'
    if (inputString.endsWith('.')) {
      inputString = inputString.slice(0, -1) + '.eth' // Remove the trailing '.' and add '.eth'
    } else if (!inputString.endsWith('.eth')) {
      inputString += '.eth'
    }
  }
  return inputString
}

export const isValidAddressOrEns = async (addressOrEns: Address | string): Promise<boolean> => {
  if (isAddress(addressOrEns)) {
    return true
  }

  const resolvedAddress = await useEnsStore().resolveEnsName(addressOrEns)
  return !!resolvedAddress && isAddress(resolvedAddress)
}

export const isScientificNotation = (num: number): boolean => {
  return num.toString().includes('e')
}

// examples of formatting: 1.1234567890 to 1.1234 or 0.00000000006448 to 0.00000000006
export const formatDecimalPoint = (amount: number | string, numbersBehindDecimalPoint = 4): string => {
  let stringAmount = ''
  if (typeof amount === 'number' && isScientificNotation(amount)) {
    stringAmount = amount.toFixed(20)
  } else {
    stringAmount = typeof amount === 'number' ? amount.toString() : amount.replace(/,/g, '')
  }

  const [integerPart, decimalPart = ''] = stringAmount.split('.')

  // Find the first non-zero digit in the decimal part
  const firstNonZeroIndex = decimalPart.split('').findIndex(char => char !== '0')

  // Determine the number of decimal places to keep
  const decimalPlacesToKeep = Math.max(numbersBehindDecimalPoint, firstNonZeroIndex + 1)

  const repeatedZeroes = parseRepeatedDecimal(`${integerPart}.${decimalPart || 0}`)
  // Pad or truncate the decimal part
  let formattedDecimal = decimalPart.padEnd(decimalPlacesToKeep, '0')

  if (!repeatedZeroes?.repeatedZeroes) {
    formattedDecimal = decimalPart.slice(0, decimalPlacesToKeep)
  } else if (repeatedZeroes?.repeatedZeroes && repeatedZeroes.repeatedZeroes > 0) {
    formattedDecimal = decimalPart.slice(0, repeatedZeroes.repeatedZeroes + decimalPlacesToKeep)
  }

  // Remove trailing zeros
  let cleanedDecimal = formattedDecimal.replace(/0+$/, '')

  if (cleanedDecimal.length > numbersBehindDecimalPoint && firstNonZeroIndex !== -1 && firstNonZeroIndex < numbersBehindDecimalPoint) {
    cleanedDecimal = cleanedDecimal.slice(0, numbersBehindDecimalPoint)
  }

  const formattedIntegerPart = parseFloat(integerPart).toLocaleString('en-US')

  // Combine integer and decimal parts
  let result = formattedIntegerPart + (cleanedDecimal ? '.' + cleanedDecimal.padEnd(2, '0') : '')

  // Add .00 if there is no decimal point
  if (!result.includes('.')) {
    result += '.00'
  }

  return result
}

export const displayAmount = (amount: string): string | null => {
  const parsedAmount = parseFloat(amount)
  if (isNaN(parsedAmount)) {
    return null
  }

  return formatDecimalPoint(parsedAmount)
}

export const displayUsdAmount = (usdAmount: string): string | null => {
  return displayAmount(usdAmount)
}

export const displayTokenAmount = (ethAmount: string): string | null => {
  return displayAmount(ethAmount)
}

export const hexToRgb = (hex: string): [number, number, number] => {
  if (hex[0] === '#') {
    hex = hex.substring(1)
  }
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return [r, g, b]
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function generateRandomBoolean(): boolean {
  return Math.random() < 0.5
}

export function formatCountdown(timestampStr: string | null): string | null {
  if (!timestampStr) {
    return null
  }

  const targetDate = new Date(timestampStr)
  const now = new Date()
  const timeDiff = targetDate.getTime() - now.getTime()

  if (isNaN(timeDiff)) {
    return null
  }

  if (timeDiff > 0) {
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)
    return `${days}d ${hours}h ${minutes}m ${seconds}s`
  } else {
    return 'Expired'
  }
}

export function getRandomUint256(): bigint {
  const array = new Uint32Array(8)
  window.crypto.getRandomValues(array)
  const hex = Array.from(array, x => x.toString(16).padStart(8, '0')).join('')
  return BigInt('0x' + hex)
}

export function goBackOrFallback(): void {
  const history = router.options.history
  const isFirstEntry = history.state.back === null
  if (isFirstEntry) {
    router.push({ name: RouteName.RevampHomepage })
  } else {
    router.back()
  }
}

export const getPlatformIcon = (asset: AssetWithAmount) => {
  switch (asset.protocol) {
  case DepositProtocol.AAVE:
    return AaveIcon
  case DepositProtocol.COMPOUND:
    return CompoundIcon
  case DepositProtocol.MORPHO:
    return MorphoIcon
  default:
    return ''
  }
}
