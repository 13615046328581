<template>
  <div class="asset-image-with-chain">
    <FallBackResultImage
      :image="image"
      :is-erc20="isErc20"
      class="asset-image-with-chain__image"/>
    <AssetChainIcon
      :chain-id="chainId"
      class="asset-image-with-chain__chain-icon"/>
  </div>
</template>

<script setup lang="ts">
import FallBackResultImage from '@/modules/sections/nav-searchbar/FallBackResultImage.vue'
import type { SupportedChain } from '@/constants/chains/types'
import { toRefs } from 'vue'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'

type Props = {
  image: string | null
  chainId: SupportedChain
  isErc20?: boolean
}
const props = defineProps<Props>()
const { image, chainId } = toRefs(props)
</script>

<style>
  .asset-image-with-chain {
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    display: inline-block;

    &__image {
      width: 2.5rem;
      height: 2.5rem;
      object-fit: contain;
    }

    &__chain-icon {
      position: absolute;
      right: -0.5rem;
      bottom: -0.25rem;
      width: 0.75rem !important;
      height: 0.75rem !important;
      box-shadow: inset 0 1px 16px 0 var(--background-color);
      padding: 0.15rem;
      box-sizing: content-box;
      border-radius: 50%;
    }
  }
</style>
