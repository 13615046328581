import StoreIds from '@/constants/storeIds'
import { announcementNewFeaturesListList } from '@/modules/common/backend/generated'
import type { NewFeatureBackendSchema } from '@/modules/common/backend/generated'
import to from '@/utils/await-to-js'
import { isMobile } from '@/utils/mediaQueries'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useStorage } from '@vueuse/core'

export const useWhatIsNewModalStore = defineStore(StoreIds.whatIsNewModal, () => {
  const LOCAL_STORAGE_WHAT_IS_NEW = 'what-is-new-modal-pwn-version'
  const localStorageLatestSlideShown = useStorage(LOCAL_STORAGE_WHAT_IS_NEW, '0')
  const isOpen = ref(false)
  const whatIsNewSlides = ref<NewFeatureBackendSchema[]>()
  const activeSlide = ref<NewFeatureBackendSchema>()

  const checkIfShowModalWhatIsNew = (latestSlideId: number) => {
    if (isMobile.value) return
    const localStorageAlreadyShownVersionNumber = parseFloat(localStorageLatestSlideShown.value)
    if (latestSlideId > localStorageAlreadyShownVersionNumber) {
      isOpen.value = true

      whatIsNewSlides.value = whatIsNewSlides.value?.filter(slide => slide.id > localStorageAlreadyShownVersionNumber)
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      activeSlide.value = whatIsNewSlides.value[0]
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      localStorageLatestSlideShown.value = latestSlideId
    }
  }

  const indexOfActiveSlide = computed(() => whatIsNewSlides.value?.findIndex((slide, index) => slide.id === activeSlide.value?.id))

  const fetchAndCheckWhatIsNewAnnouncement = async () => {
    const [error, whatIsNewBackendResponse] = await to(announcementNewFeaturesListList())
    if (error || !whatIsNewBackendResponse?.data?.results?.length) return

    if (whatIsNewBackendResponse?.data.results?.length > 0) {
      whatIsNewSlides.value = whatIsNewBackendResponse?.data?.results
      activeSlide.value = whatIsNewSlides.value[0]
      // @ts-expect-error FIXME: Property 'latest' does not exist on type 'PaginatedNewFeatureListBackendSchema'.
      const latestSlide = Number(whatIsNewBackendResponse?.data?.latest)
      checkIfShowModalWhatIsNew(latestSlide)
    }
  }

  return {
    isOpen,
    fetchAndCheckWhatIsNewAnnouncement,
    whatIsNewSlides,
    activeSlide,
    indexOfActiveSlide,
  }
})
