<template>
  <BaseSelect
    :options="toolsOptions"
    :searchable="false"
    :border-type="SelectBorderType.None"
    class="tool-select"
    :is-dropdown-caret="false"
    :dropdown-caret-type="DropdownCaretType.None"
    @on-select-close="handleIsSelectOpen(false)"
    @on-select-open="handleIsSelectOpen(true)"
    @update:model-value="handleToolSelect">
    <template #option="{ option }">
      <div class="tool-select__option">
        <component
          :is="option.icon"
          width="16"
          height="16"
          :alt="option.label"/>
        <span class="tool-select__option-text">
          {{ option.label }}
        </span>
        <ExternalIconSvg
          v-if="isOpenPwnSafeInNewTab(option)"
          class="tool-select__option-icon"
          width="14"
          height="14"
          alt="open in new tab"/>
      </div>
    </template>

    <template #placeholder>
      <div
        :class="['tool-select__single-label', {'tool-select__open-select': isSelectOpen}]">
        <span
          class="tool-select__single-label-text">
          Tools
        </span>
        <CaretFilledIconSvg
          :class="['tool-select__caret-icon', {'tool-select__caret-icon--open': isSelectOpen}]"
          alt="caret"/>
      </div>
    </template>
  </BaseSelect>
</template>

<script setup lang="ts">
import BaseSelect from '@/general-components/BaseSelect.vue'
import type { OptionItem } from '@/general-components/BaseSelectProperties'
import { SelectBorderType, DropdownCaretType } from '@/general-components/BaseSelectProperties'
import BundlerIconSvg from '@/assets/icons/bundler.svg'
import PwnSafeIconSvg from '@/assets/icons/safe.svg'
import { computed, ref } from 'vue'
import type { Component } from 'vue'
import RouteName from '@/router/RouteName'
import { useRouter } from 'vue-router'
import linkTo from '@/constants/links'
import ExternalIconSvg from '@/assets/icons/external.svg'
import CaretFilledIconSvg from '@/assets/icons/caret-filled.svg'
import { isPwnSpace, isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import SpacesSvg from '@/assets/icons/spaces.svg'

const router = useRouter()

enum Tools {
  Bundler = 'bundler',
  PwnSafe = 'pwn-safe',
  Spaces = 'spaces'
}

interface ToolOption extends OptionItem<RouteName, Tools> {
  icon: Component
}

const isSelectOpen = ref()

const toolsOptions = computed(() => {
  const options: ToolOption[] = [
    {
      trackBy: Tools.Bundler,
      label: 'Bundler',
      value: RouteName.TokenBundler,
      icon: BundlerIconSvg,
    },
    {
      trackBy: Tools.PwnSafe,
      label: 'PWN Safe',
      value: RouteName.PwnSafe,
      icon: PwnSafeIconSvg,
    },
    {
      trackBy: Tools.Spaces,
      label: 'Spaces',
      value: RouteName.Spaces,
      icon: SpacesSvg,
    },
  ]

  return isStarknet
    ? options.filter(option => option.label === 'Spaces')
    : options
})

const handleIsSelectOpen = (isOpen: boolean) => {
  isSelectOpen.value = isOpen
}

const isOpenPwnSafeInNewTab = (option: ToolOption) => {
  return option.trackBy === Tools.PwnSafe && isPwnSpace
}

const handleToolSelect = async (selectedToolRoute: ToolOption) => {
  if (isOpenPwnSafeInNewTab(selectedToolRoute)) {
    // no PWN Safe on pwn spaces, reroute to normal app version
    window.open(`${linkTo.pwnAppProduction}/#/pwn-safe`)
    return
  }
  await router.push({ name: selectedToolRoute.value })
}
</script>

<style scoped>
.tool-select {
  width: 6rem;
  margin: 0 -0.5rem;

  &__option {
    font-size: 0.875rem;
    width: 11.25rem;

    &-text {
      padding-top: 2px;
      margin-left: 0.75rem;
    }

    &-icon {
      margin-left: 0.75rem;
    }
  }

  &__single-label {
    font-size: 1rem;
    margin-bottom: -0.625rem;
    padding-right: 1.5rem;
  }

  &__caret-icon {
    margin-left: 0.5rem;
    transition: transform var(--primary-transition-time);

    fill: var(--text-color);

    &--open {
      transform: rotate(180deg);
    }
  }

  &__open-select {
    color: var(--primary-color-1);

    .tool-select__caret-icon {
      fill: var(--primary-color-1);
    }
  }

  &__option,
  &__single-label {
    display: flex;
    align-items: center;

    font-family: var(--font-family-screener);
    font-weight: 400;
    font-size: 1rem;

    &:hover {
      color: var(--primary-color-1);

      .tool-select__caret-icon {
        fill: var(--primary-color-1);
      }
    }
  }
}
</style>
