<template>
  <div
    v-if="loanData?.isRunningLoan || loanData?.paidBackAt || loanData?.defaultDate"
    class="loan-date-info">
    <div class="loan-date-info__status-dot-container">
      <span
        v-if="loanData?.isRunningLoan"
        class="loan-date-info__status-dot loan-date-info__status-dot--active"/>
      <span
        v-if="loanData?.defaultDate && isDefaulted"
        class="loan-date-info__status-dot loan-date-info__status-dot--defaulted"/>
      <span
        v-if="isPaidBack"
        class="loan-date-info__status-dot loan-date-info__status-dot--paid-back"/>
      <span>{{ statusLabel }} </span>
    </div>
    <span class="loan-date-info__date">
      <BaseTooltip
        v-if="paidBackAtDate && loanData?.paidBackAt"
        :tooltip-text="paidBackAtDate">
        <template #trigger>
          <span>{{ repaymentDateTime }}</span>
        </template>
      </BaseTooltip>
      <BaseTooltip
        v-else-if="loanData?.defaultDate && isDefaulted"
        :tooltip-text="defaultedTimeAgo">
        <template #trigger>
          <span>{{ defaultedDateTime }}</span>
        </template>
      </BaseTooltip>
      <Countdown
        v-else-if="loanData?.defaultDate"
        smallest-units="seconds"
        :expiration-date="new Date(loanData?.defaultDate * 1000)"/>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import Countdown from '@/general-components/Countdown.vue'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import DateUtils from '@/utils/DateUtils'
import type { LoanDetailSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'

interface Props {
  loanData: null | LoanDetailSchemaWorkaroundBackendSchema & { isRunningLoan: boolean }
}
const props = defineProps<Props>()
const { loanData } = toRefs(props)

const paidBackAtDate = computed(() => {
  if (!loanData.value?.paidBackAt) return
  return DateUtils.getFormattedTimeAgo(new Date(loanData.value.paidBackAt * 1000))
})

const isPaidBack = computed(() => {
  return !!loanData.value?.paidBackAt
})

const isDefaulted = computed(() => {
  if (!loanData.value) return false
  if (loanData.value.status === 4) return true
  if (loanData.value.defaultDate && !loanData.value.paidBackAt) {
    return Date.now() >= loanData.value.defaultDate * 1000
  }
  return false
})

const repaymentDateTime = computed(() => {
  if (!loanData.value?.paidBackAt) return ''
  return DateUtils.displayLongDate(loanData.value.paidBackAt * 1000)
})

const defaultedDateTime = computed(() => {
  if (!loanData.value?.defaultDate) return ''
  return DateUtils.displayLongDate(loanData.value.defaultDate * 1000)
})

const defaultedTimeAgo = computed(() => {
  if (!loanData.value?.defaultDate) return ''
  return DateUtils.getFormattedTimeAgo(new Date(loanData.value.defaultDate * 1000))
})

const statusLabel = computed(() => {
  if (!loanData.value) return
  if (loanData.value.defaultDate && isDefaulted.value) return 'Defaulted'
  return loanData.value.paidBackAt ? 'Paid Back' : 'Defaults in'
  // TODO status for defaulted at
})
</script>

<style scoped>
.loan-date-info {
  display: flex;
  justify-content: space-between;

  &__date {
    cursor: help;
  }

  &__status-dot-container {
    display: flex;
    align-items: baseline;
  }

  &__status-dot {
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;

    &--defaulted {
      background: var(--orange-1);
    }

    &--active {
      background: #24ff00;
    }

    &--paid-back {
      background: var(--info);
    }
  }
}
</style>
