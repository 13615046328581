import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import useTransfer from '@/modules/common/assets/useTransfer'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed } from 'vue'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'

export default function usePwnSafeTransferAssetsTransfer() {
  const { assetsToTransfer, toWallet, fromWallet, isValidAddress } = usePwnSafeTransfer()
  const { transfer } = useTransfer()

  const transferIndividualAsset = async (asset: AssetWithAmount) => {
    return await transfer({
      assetERCType: asset.category,
      contractAddress: asset.address,
      chainId: asset.chainId,
      // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
      owner: fromWallet.value?.safeAddress,
      // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
      receiver: toWallet.value?.safeAddress,
      amount: asset.amountInputRaw,
      tokenId: asset.tokenId,
    })
  }

  // TODO what about error handling here?
  const transferAssets = async (assets: AssetWithAmount[]): Promise<AssetWithAmount[]> => {
    let transferResults: unknown[] = []
    if (useConnectedAccountTypeStore().isConnectedContractWallet) {
      for (const asset of assets) {
        // TODO rather perform multi send here, this is unoptimal
        const isSuccess = await transferIndividualAsset(asset)
        transferResults.push(isSuccess)
      }
    } else {
      transferResults = await Promise.all(assets.map(transferIndividualAsset))
    }

    return assets
  }

  const hasValidFungibleAssets = computed(() => {
    const fungibleAssets = assetsToTransfer.value.filter(asset => asset.isFungible)
    if (!fungibleAssets) return true
    return fungibleAssets.every(asset => asset.isAssetAmountInputValidOrEmpty)
  })

  const isTransferDisabled = computed(() => {
    return !assetsToTransfer.value?.length || !toWallet.value || !isValidAddress.value || !hasValidFungibleAssets.value
  })

  return {
    transferAssets,
    isTransferDisabled,
    hasValidFungibleAssets,
  }
}
