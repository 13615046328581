import type { Component } from 'vue'
import FarcasterSvg from '@/assets/icons/rep-score/farcaster-follow.svg'
import links from '@/constants/links'
import WarpcastIconSvg from '@/assets/icons/warpcaster.svg'
import RunningLoanSvg from '@/assets/icons/rep-score/running-loan.svg'
import UseBundleAsCollateral from '@/assets/icons/rep-score/use-bundle-as-collateral.svg'
import AlphaPoap from '@/assets/icons/rep-score/alpha-poap.svg'
import HasEns from '@/assets/icons/rep-score/has-ens.svg'
import HasBasename from '@/assets/icons/rep-score/has-basename.svg'
import PwnMirrorPoap from '@/assets/icons/rep-score/pwn-mirror-poap.svg'
import LendingProposalAccepted from '@/assets/icons/rep-score/lending-proposal-accepted.svg'
import IykykPoaps from '@/assets/icons/rep-score/iykyk-poap.svg'
import BorrowingProposalAccepted from '@/assets/icons/rep-score/borrowing-proposal-accepted.svg'
import AcceptLendingProposal from '@/assets/icons/rep-score/accept-lending-proposal.svg'
import AcceptBorrowingProposal from '@/assets/icons/rep-score/accept-borrowing-proposal.svg'
import ATRTokenAsCollateral from '@/assets/icons/rep-score/atr-token-as-collateral.svg'
import InPersonEventPOAPs from '@/assets/icons/rep-score/in-person-poaps.svg'
import MakeListProposal from '@/assets/icons/rep-score/make-list-proposal.svg'
import MakeSimpleProposal from '@/assets/icons/rep-score/make-simple-proposal.svg'
import OnlineEventPOAPs from '@/assets/icons/rep-score/online-event-poaps.svg'
// import CreateBorrowingProposal from '@/assets/icons/rep-score/create-borrowing-proposal.svg'
import RepayLoan from '@/assets/icons/rep-score/repay-loan.svg'
import RevokeProposal from '@/assets/icons/rep-score/revoke-proposal.svg'
import DeployPWNSafe from '@/assets/icons/rep-score/deploy-pwn-safe.svg'
import MintATRToken from '@/assets/icons/rep-score/mint-atr-token.svg'
import BurnATRToken from '@/assets/icons/rep-score/burn-atr-token.svg'
import MakeElasticProposal from '@/assets/icons/rep-score/make-elastic-proposal.svg'
import SetupDiscordNotifications from '@/assets/icons/rep-score/setup-discord-notifications.svg'
import SetupPushNotifications from '@/assets/icons/rep-score/setup-push-notifications.svg'
import SetupEmailNotifications from '@/assets/icons/rep-score/setup-email-notifications.svg'
import CreateBundle from '@/assets/icons/rep-score/create-bundle.svg'
import UnwrapBundle from '@/assets/icons/rep-score/unwrap-bundle.svg'
import ClaimLoan from '@/assets/icons/rep-score/claim-loan.svg'
import IsAaveUser from '@/assets/icons/rep-score/is-aave-user.svg'

export type RepScoreItem = {
  parameterName: string
  icon: Component
  link?: string
  linkIcon?: Component
  label: string
  multiplier?: string
  base?: string
  points?: string
  itemType: RepScoreItemType
}

export enum RepScoreItemType {
  Boost = 'boost',
  Achievement = 'Achievement',
}

export const repScoreItems: RepScoreItem[] = [
  // Boosts
  {
    parameterName: 'HasRunningLoan',
    icon: RunningLoanSvg,
    label: 'Have a running loan',
    multiplier: '0.100',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'PWNMirrorPOAP',
    icon: PwnMirrorPoap,
    label: 'Mirror.xyz POAP',
    multiplier: '0.100',
    base: '10',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'PWNIYKYKPOAPs',
    icon: IykykPoaps,
    label: 'IYKYK POAP',
    multiplier: '0.100',
    base: '15',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'PWNAlphaPOAPs',
    icon: AlphaPoap,
    label: 'Alpha Access POAP',
    multiplier: '0.100',
    base: '10',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'GetLendingProposalAccepted',
    icon: LendingProposalAccepted,
    label: 'Have one of your lending proposals accepted',
    multiplier: '0.050',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'UseBundleAsCollateral',
    icon: UseBundleAsCollateral,
    label: 'Use Bundle as collateral',
    multiplier: '0.050',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'GetBorrowingProposalAccepted',
    icon: BorrowingProposalAccepted,
    label: 'Have one of your borrowing proposals accepted',
    multiplier: '0.050',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'AcceptLendingProposal',
    icon: AcceptLendingProposal,
    label: 'Fund a proposal',
    multiplier: '0.050',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'AcceptBorrowingProposal',
    icon: AcceptBorrowingProposal,
    label: 'Accept a borrowing proposal',
    multiplier: '0.050',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'UseATRTokenAsCollateral',
    icon: ATRTokenAsCollateral,
    label: 'Use ATR as collateral',
    multiplier: '0.050',
    itemType: RepScoreItemType.Boost,
  },
  {
    parameterName: 'HasENS',
    icon: HasEns,
    label: 'Have an ENS name',
    multiplier: '0.025',
    itemType: RepScoreItemType.Boost,
  },

  // Achievements
  {
    parameterName: 'HasBasename',
    icon: HasBasename,
    label: 'Have a Basename name',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'FollowsPWNOnFarcaster',
    icon: FarcasterSvg,
    link: links.warpcaster,
    linkIcon: WarpcastIconSvg,
    label: 'Follow PWN on Farcaster',
    multiplier: '0',
    base: '1',
    itemType: RepScoreItemType.Achievement,
  },
  // TODO remove?
  // {
  //   parameterName: 'FollowsPWNOnLens',
  //   icon: LensSvg,
  //   link: '',
  //   linkIcon: '',
  //   label: 'Follow PWN on Lens',
  //   multiplier: '0.01',
  //   isBoostItem: RepScoreItemType.Achievement,
  // },
  {
    parameterName: 'PWNInPersonEventPOAPs',
    icon: InPersonEventPOAPs,
    label: 'In-person event POAPs',
    points: '2',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'MakeListProposal',
    icon: MakeListProposal,
    label: 'Make lending proposal on collection',
    points: '2',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'MakeSimpleProposal',
    icon: MakeSimpleProposal,
    label: 'Make proposal on specific asset',
    points: '2',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'MakeFungibleProposal',
    icon: MakeElasticProposal,
    label: 'Create elastic proposal',
    points: '2',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'PWNOnlineEventPOAPs',
    icon: OnlineEventPOAPs,
    label: 'Online event POAPs',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  // TODO remove?
  // {
  //   parameterName: 'CreateBorrowingProposal',
  //   icon: CreateBorrowingProposal,
  //   label: 'Create borrowing proposal',
  //   points: '1',
  //   itemType: RepScoreItemType.Achievement,
  // },
  {
    parameterName: 'RepayLoan',
    icon: RepayLoan,
    label: 'Repay loan',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'RevokeProposal',
    icon: RevokeProposal,
    label: 'Cancel proposal',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'DeployPWNSafe',
    icon: DeployPWNSafe,
    label: 'Deploy PWN Safe',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'MintATRToken',
    icon: MintATRToken,
    label: 'Mint ATR Token',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'BurnATRToken',
    icon: BurnATRToken,
    label: 'Burn ATR Token',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'SetupDiscordNotifications',
    icon: SetupDiscordNotifications,
    label: 'Set up Discord notifications',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'SetupPUSHNotifications',
    icon: SetupPushNotifications,
    label: 'Set up Push notifications',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'SetupEmailNotifications',
    icon: SetupEmailNotifications,
    label: 'Set up Email notifications',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'CreateBundle',
    icon: CreateBundle,
    label: 'Create bundle',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'UnwrapBundle',
    icon: UnwrapBundle,
    label: 'Unpack bundle',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'ClaimLoan',
    icon: ClaimLoan,
    label: 'Claim loan',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
  {
    parameterName: 'IsAaveUser',
    icon: IsAaveUser,
    label: 'Be an Aave user',
    points: '1',
    itemType: RepScoreItemType.Achievement,
  },
]

export const repScoreBoostItems = repScoreItems.filter(item => item.itemType === RepScoreItemType.Boost)
export const repScoreAchievementsItems = repScoreItems.filter(item => item.itemType === RepScoreItemType.Achievement)
