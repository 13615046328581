<template>
  <div class="empty-state-text">
    {{ props.text }}
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  text: string
}>()
</script>

<style scoped>
.empty-state-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  width: 100%;
  height: 150px;
  padding-top: 2rem;
  font-family: var(--font-family-screener);
  font-size: 1.5rem;
  line-height: 1.25rem;
}
</style>
