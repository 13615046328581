/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryReturnType,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateProposalRequestSchemaRequestBackendSchema,
  CreateStrategy201,
  CreateStrategy400,
  CreateStrategy403,
  FreeUserNonceRetrieveParams,
  FreeUserNonceSchemaWorkaroundBackendSchema,
  LendingProposalTermSuggestionSchemaBackendSchema,
  LoanDetailSchemaWorkaroundBackendSchema,
  LoanExtensionCreateSchemaRequestBackendSchema,
  LoanExtensionSchemaWorkaroundBackendSchema,
  LoanExtensionUpdateSchemaRequestBackendSchema,
  LoanStatsSchemaBackendSchema,
  PaginatedProposalAndLoanListSchemaWorkaroundListBackendSchema,
  PaginatedThesisSchemaWorkaroundListBackendSchema,
  ProposalAndLoanListParams,
  ProposalDetailSchemaBackendSchema,
  ProposalHashesListSchemaBackendSchema,
  SuggestLendingTerms404,
  SuggestLendingTermsParams,
  ThesisCreateUpdateSchemaRequestBackendSchema,
  ThesisDetail2Params,
  ThesisDetailParams,
  ThesisListParams,
  ThesisSchemaWorkaroundBackendSchema,
  UpdateStrategy200,
  UpdateStrategy400,
  UpdateStrategy403,
  UpdateStrategy404
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const allProposalHashesForRoot = (
    multiproposalMerkleRoot: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      multiproposalMerkleRoot = unref(multiproposalMerkleRoot);
      
      return customInstance<ProposalHashesListSchemaBackendSchema>(
      {url: `/api/v2/pwn_contracts/all-proposal-hashes-for-root/${encodeURIComponent(String(multiproposalMerkleRoot))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAllProposalHashesForRootQueryKey = (multiproposalMerkleRoot: MaybeRef<string>,) => {
    return ['api','v2','pwn_contracts','all-proposal-hashes-for-root',multiproposalMerkleRoot] as const;
    }

    
export const getAllProposalHashesForRootQueryOptions = <TData = Awaited<ReturnType<typeof allProposalHashesForRoot>>, TError = unknown>(multiproposalMerkleRoot: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof allProposalHashesForRoot>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAllProposalHashesForRootQueryKey(multiproposalMerkleRoot);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof allProposalHashesForRoot>>> = ({ signal }) => allProposalHashesForRoot(multiproposalMerkleRoot, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(multiproposalMerkleRoot))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof allProposalHashesForRoot>>, TError, TData> 
}

export type AllProposalHashesForRootQueryResult = NonNullable<Awaited<ReturnType<typeof allProposalHashesForRoot>>>
export type AllProposalHashesForRootQueryError = unknown

export const useAllProposalHashesForRoot = <TData = Awaited<ReturnType<typeof allProposalHashesForRoot>>, TError = unknown>(
 multiproposalMerkleRoot: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof allProposalHashesForRoot>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAllProposalHashesForRootQueryOptions(multiproposalMerkleRoot,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const proposalCreateBatch = (
    createProposalRequestSchemaRequestBackendSchema: MaybeRef<CreateProposalRequestSchemaRequestBackendSchema[]>,
 options?: SecondParameter<typeof customInstance>,) => {
      createProposalRequestSchemaRequestBackendSchema = unref(createProposalRequestSchemaRequestBackendSchema);
      
      return customInstance<ProposalDetailSchemaBackendSchema[]>(
      {url: `/api/v2/pwn_contracts/create-proposal-batch`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createProposalRequestSchemaRequestBackendSchema
    },
      options);
    }
  


export const getProposalCreateBatchMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof proposalCreateBatch>>, TError,{data: CreateProposalRequestSchemaRequestBackendSchema[]}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof proposalCreateBatch>>, TError,{data: CreateProposalRequestSchemaRequestBackendSchema[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof proposalCreateBatch>>, {data: CreateProposalRequestSchemaRequestBackendSchema[]}> = (props) => {
          const {data} = props ?? {};

          return  proposalCreateBatch(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ProposalCreateBatchMutationResult = NonNullable<Awaited<ReturnType<typeof proposalCreateBatch>>>
    export type ProposalCreateBatchMutationBody = CreateProposalRequestSchemaRequestBackendSchema[]
    export type ProposalCreateBatchMutationError = unknown

    export const useProposalCreateBatch = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof proposalCreateBatch>>, TError,{data: CreateProposalRequestSchemaRequestBackendSchema[]}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof proposalCreateBatch>>,
        TError,
        {data: CreateProposalRequestSchemaRequestBackendSchema[]},
        TContext
      > => {

      const mutationOptions = getProposalCreateBatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const freeUserNonceRetrieve = (
    chainId: MaybeRef<string>,
    revokedNonceContractAddress: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FreeUserNonceRetrieveParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
revokedNonceContractAddress = unref(revokedNonceContractAddress);
userAddress = unref(userAddress);
params = unref(params);
      
      return customInstance<FreeUserNonceSchemaWorkaroundBackendSchema>(
      {url: `/api/v2/pwn_contracts/free-user-nonce/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(revokedNonceContractAddress))}/${encodeURIComponent(String(userAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFreeUserNonceRetrieveQueryKey = (chainId: MaybeRef<string>,
    revokedNonceContractAddress: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FreeUserNonceRetrieveParams>,) => {
    return ['api','v2','pwn_contracts','free-user-nonce',chainId,revokedNonceContractAddress,userAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFreeUserNonceRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof freeUserNonceRetrieve>>, TError = unknown>(chainId: MaybeRef<string>,
    revokedNonceContractAddress: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FreeUserNonceRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof freeUserNonceRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFreeUserNonceRetrieveQueryKey(chainId,revokedNonceContractAddress,userAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof freeUserNonceRetrieve>>> = ({ signal }) => freeUserNonceRetrieve(chainId,revokedNonceContractAddress,userAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(revokedNonceContractAddress) && unref(userAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof freeUserNonceRetrieve>>, TError, TData> 
}

export type FreeUserNonceRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof freeUserNonceRetrieve>>>
export type FreeUserNonceRetrieveQueryError = unknown

export const useFreeUserNonceRetrieve = <TData = Awaited<ReturnType<typeof freeUserNonceRetrieve>>, TError = unknown>(
 chainId: MaybeRef<string>,
    revokedNonceContractAddress: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FreeUserNonceRetrieveParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof freeUserNonceRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFreeUserNonceRetrieveQueryOptions(chainId,revokedNonceContractAddress,userAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const loanExtensionCreate = (
    chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>,
    loanExtensionCreateSchemaRequestBackendSchema: MaybeRef<LoanExtensionCreateSchemaRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      chainId = unref(chainId);
loanTokenContractAddress = unref(loanTokenContractAddress);
onChainId = unref(onChainId);
loanExtensionCreateSchemaRequestBackendSchema = unref(loanExtensionCreateSchemaRequestBackendSchema);
      
      return customInstance<LoanExtensionSchemaWorkaroundBackendSchema>(
      {url: `/api/v2/pwn_contracts/loan-extension/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(loanTokenContractAddress))}/${encodeURIComponent(String(onChainId))}/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loanExtensionCreateSchemaRequestBackendSchema
    },
      options);
    }
  


export const getLoanExtensionCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loanExtensionCreate>>, TError,{chainId: string;loanTokenContractAddress: string;onChainId: string;data: LoanExtensionCreateSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof loanExtensionCreate>>, TError,{chainId: string;loanTokenContractAddress: string;onChainId: string;data: LoanExtensionCreateSchemaRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loanExtensionCreate>>, {chainId: string;loanTokenContractAddress: string;onChainId: string;data: LoanExtensionCreateSchemaRequestBackendSchema}> = (props) => {
          const {chainId,loanTokenContractAddress,onChainId,data} = props ?? {};

          return  loanExtensionCreate(chainId,loanTokenContractAddress,onChainId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoanExtensionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof loanExtensionCreate>>>
    export type LoanExtensionCreateMutationBody = LoanExtensionCreateSchemaRequestBackendSchema
    export type LoanExtensionCreateMutationError = unknown

    export const useLoanExtensionCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loanExtensionCreate>>, TError,{chainId: string;loanTokenContractAddress: string;onChainId: string;data: LoanExtensionCreateSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof loanExtensionCreate>>,
        TError,
        {chainId: string;loanTokenContractAddress: string;onChainId: string;data: LoanExtensionCreateSchemaRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getLoanExtensionCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const loanExtensionUpdate = (
    loanExtensionProposalId: MaybeRef<string>,
    loanExtensionUpdateSchemaRequestBackendSchema: MaybeRef<LoanExtensionUpdateSchemaRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      loanExtensionProposalId = unref(loanExtensionProposalId);
loanExtensionUpdateSchemaRequestBackendSchema = unref(loanExtensionUpdateSchemaRequestBackendSchema);
      
      return customInstance<LoanExtensionSchemaWorkaroundBackendSchema>(
      {url: `/api/v2/pwn_contracts/loan-extension/${encodeURIComponent(String(loanExtensionProposalId))}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: loanExtensionUpdateSchemaRequestBackendSchema
    },
      options);
    }
  


export const getLoanExtensionUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loanExtensionUpdate>>, TError,{loanExtensionProposalId: string;data: LoanExtensionUpdateSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof loanExtensionUpdate>>, TError,{loanExtensionProposalId: string;data: LoanExtensionUpdateSchemaRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loanExtensionUpdate>>, {loanExtensionProposalId: string;data: LoanExtensionUpdateSchemaRequestBackendSchema}> = (props) => {
          const {loanExtensionProposalId,data} = props ?? {};

          return  loanExtensionUpdate(loanExtensionProposalId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoanExtensionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof loanExtensionUpdate>>>
    export type LoanExtensionUpdateMutationBody = LoanExtensionUpdateSchemaRequestBackendSchema
    export type LoanExtensionUpdateMutationError = unknown

    export const useLoanExtensionUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loanExtensionUpdate>>, TError,{loanExtensionProposalId: string;data: LoanExtensionUpdateSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof loanExtensionUpdate>>,
        TError,
        {loanExtensionProposalId: string;data: LoanExtensionUpdateSchemaRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getLoanExtensionUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const loanExtensionRetrieve = (
    chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
loanTokenContractAddress = unref(loanTokenContractAddress);
onChainId = unref(onChainId);
      
      return customInstance<LoanExtensionSchemaWorkaroundBackendSchema>(
      {url: `/api/v2/pwn_contracts/loan-extension/latest/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(loanTokenContractAddress))}/${encodeURIComponent(String(onChainId))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getLoanExtensionRetrieveQueryKey = (chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>,) => {
    return ['api','v2','pwn_contracts','loan-extension','latest',chainId,loanTokenContractAddress,onChainId] as const;
    }

    
export const getLoanExtensionRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof loanExtensionRetrieve>>, TError = unknown>(chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof loanExtensionRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getLoanExtensionRetrieveQueryKey(chainId,loanTokenContractAddress,onChainId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof loanExtensionRetrieve>>> = ({ signal }) => loanExtensionRetrieve(chainId,loanTokenContractAddress,onChainId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(loanTokenContractAddress) && unref(onChainId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof loanExtensionRetrieve>>, TError, TData> 
}

export type LoanExtensionRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof loanExtensionRetrieve>>>
export type LoanExtensionRetrieveQueryError = unknown

export const useLoanExtensionRetrieve = <TData = Awaited<ReturnType<typeof loanExtensionRetrieve>>, TError = unknown>(
 chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof loanExtensionRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLoanExtensionRetrieveQueryOptions(chainId,loanTokenContractAddress,onChainId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const loanStats = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<LoanStatsSchemaBackendSchema>(
      {url: `/api/v2/pwn_contracts/loan-stats/`, method: 'GET', signal
    },
      options);
    }
  

export const getLoanStatsQueryKey = () => {
    return ['api','v2','pwn_contracts','loan-stats'] as const;
    }

    
export const getLoanStatsQueryOptions = <TData = Awaited<ReturnType<typeof loanStats>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof loanStats>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getLoanStatsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof loanStats>>> = ({ signal }) => loanStats(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof loanStats>>, TError, TData> 
}

export type LoanStatsQueryResult = NonNullable<Awaited<ReturnType<typeof loanStats>>>
export type LoanStatsQueryError = unknown

export const useLoanStats = <TData = Awaited<ReturnType<typeof loanStats>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof loanStats>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLoanStatsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const loanDetail = (
    chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
loanTokenContractAddress = unref(loanTokenContractAddress);
onChainId = unref(onChainId);
      
      return customInstance<LoanDetailSchemaWorkaroundBackendSchema>(
      {url: `/api/v2/pwn_contracts/loan/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(loanTokenContractAddress))}/${encodeURIComponent(String(onChainId))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getLoanDetailQueryKey = (chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>,) => {
    return ['api','v2','pwn_contracts','loan',chainId,loanTokenContractAddress,onChainId] as const;
    }

    
export const getLoanDetailQueryOptions = <TData = Awaited<ReturnType<typeof loanDetail>>, TError = unknown>(chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof loanDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getLoanDetailQueryKey(chainId,loanTokenContractAddress,onChainId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof loanDetail>>> = ({ signal }) => loanDetail(chainId,loanTokenContractAddress,onChainId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(loanTokenContractAddress) && unref(onChainId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof loanDetail>>, TError, TData> 
}

export type LoanDetailQueryResult = NonNullable<Awaited<ReturnType<typeof loanDetail>>>
export type LoanDetailQueryError = unknown

export const useLoanDetail = <TData = Awaited<ReturnType<typeof loanDetail>>, TError = unknown>(
 chainId: MaybeRef<string>,
    loanTokenContractAddress: MaybeRef<string>,
    onChainId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof loanDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLoanDetailQueryOptions(chainId,loanTokenContractAddress,onChainId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const suggestLendingTerms = (
    chainId: MaybeRef<string>,
    symbol: MaybeRef<string>,
    params?: MaybeRef<SuggestLendingTermsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
symbol = unref(symbol);
params = unref(params);
      
      return customInstance<LendingProposalTermSuggestionSchemaBackendSchema>(
      {url: `/api/v2/pwn_contracts/market-lending-terms/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(symbol))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getSuggestLendingTermsQueryKey = (chainId: MaybeRef<string>,
    symbol: MaybeRef<string>,
    params?: MaybeRef<SuggestLendingTermsParams>,) => {
    return ['api','v2','pwn_contracts','market-lending-terms',chainId,symbol, ...(params ? [params]: [])] as const;
    }

    
export const getSuggestLendingTermsQueryOptions = <TData = Awaited<ReturnType<typeof suggestLendingTerms>>, TError = SuggestLendingTerms404>(chainId: MaybeRef<string>,
    symbol: MaybeRef<string>,
    params?: MaybeRef<SuggestLendingTermsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof suggestLendingTerms>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getSuggestLendingTermsQueryKey(chainId,symbol,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof suggestLendingTerms>>> = ({ signal }) => suggestLendingTerms(chainId,symbol,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(symbol))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof suggestLendingTerms>>, TError, TData> 
}

export type SuggestLendingTermsQueryResult = NonNullable<Awaited<ReturnType<typeof suggestLendingTerms>>>
export type SuggestLendingTermsQueryError = SuggestLendingTerms404

export const useSuggestLendingTerms = <TData = Awaited<ReturnType<typeof suggestLendingTerms>>, TError = SuggestLendingTerms404>(
 chainId: MaybeRef<string>,
    symbol: MaybeRef<string>,
    params?: MaybeRef<SuggestLendingTermsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof suggestLendingTerms>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSuggestLendingTermsQueryOptions(chainId,symbol,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiV2PwnContractsOldLoanUrlMappingRetrieve = (
    oldId: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      oldId = unref(oldId);
      
      return customInstance<void>(
      {url: `/api/v2/pwn_contracts/old-loan-url-mapping/${encodeURIComponent(String(oldId))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getApiV2PwnContractsOldLoanUrlMappingRetrieveQueryKey = (oldId: MaybeRef<string>,) => {
    return ['api','v2','pwn_contracts','old-loan-url-mapping',oldId] as const;
    }

    
export const getApiV2PwnContractsOldLoanUrlMappingRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof apiV2PwnContractsOldLoanUrlMappingRetrieve>>, TError = unknown>(oldId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof apiV2PwnContractsOldLoanUrlMappingRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiV2PwnContractsOldLoanUrlMappingRetrieveQueryKey(oldId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiV2PwnContractsOldLoanUrlMappingRetrieve>>> = ({ signal }) => apiV2PwnContractsOldLoanUrlMappingRetrieve(oldId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(oldId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiV2PwnContractsOldLoanUrlMappingRetrieve>>, TError, TData> 
}

export type ApiV2PwnContractsOldLoanUrlMappingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof apiV2PwnContractsOldLoanUrlMappingRetrieve>>>
export type ApiV2PwnContractsOldLoanUrlMappingRetrieveQueryError = unknown

export const useApiV2PwnContractsOldLoanUrlMappingRetrieve = <TData = Awaited<ReturnType<typeof apiV2PwnContractsOldLoanUrlMappingRetrieve>>, TError = unknown>(
 oldId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof apiV2PwnContractsOldLoanUrlMappingRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiV2PwnContractsOldLoanUrlMappingRetrieveQueryOptions(oldId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const proposalCreate = (
    createProposalRequestSchemaRequestBackendSchema: MaybeRef<CreateProposalRequestSchemaRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      createProposalRequestSchemaRequestBackendSchema = unref(createProposalRequestSchemaRequestBackendSchema);
      
      return customInstance<ProposalDetailSchemaBackendSchema>(
      {url: `/api/v2/pwn_contracts/proposal/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createProposalRequestSchemaRequestBackendSchema
    },
      options);
    }
  


export const getProposalCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof proposalCreate>>, TError,{data: CreateProposalRequestSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof proposalCreate>>, TError,{data: CreateProposalRequestSchemaRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof proposalCreate>>, {data: CreateProposalRequestSchemaRequestBackendSchema}> = (props) => {
          const {data} = props ?? {};

          return  proposalCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ProposalCreateMutationResult = NonNullable<Awaited<ReturnType<typeof proposalCreate>>>
    export type ProposalCreateMutationBody = CreateProposalRequestSchemaRequestBackendSchema
    export type ProposalCreateMutationError = unknown

    export const useProposalCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof proposalCreate>>, TError,{data: CreateProposalRequestSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof proposalCreate>>,
        TError,
        {data: CreateProposalRequestSchemaRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getProposalCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const proposalAndLoanList = (
    params?: MaybeRef<ProposalAndLoanListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedProposalAndLoanListSchemaWorkaroundListBackendSchema>(
      {url: `/api/v2/pwn_contracts/proposal-and-loan/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getProposalAndLoanListQueryKey = (params?: MaybeRef<ProposalAndLoanListParams>,) => {
    return ['api','v2','pwn_contracts','proposal-and-loan', ...(params ? [params]: [])] as const;
    }

    
export const getProposalAndLoanListInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof proposalAndLoanList>>, ProposalAndLoanListParams['offset']>, TError = unknown>(params?: MaybeRef<ProposalAndLoanListParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof proposalAndLoanList>>, TError, TData, Awaited<ReturnType<typeof proposalAndLoanList>>, QueryKey, ProposalAndLoanListParams['offset']>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getProposalAndLoanListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof proposalAndLoanList>>, QueryKey, ProposalAndLoanListParams['offset']> = ({ signal, pageParam }) => proposalAndLoanList({...unref(params), offset: pageParam || unref(params)?.['offset']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof proposalAndLoanList>>, TError, TData, Awaited<ReturnType<typeof proposalAndLoanList>>, QueryKey, ProposalAndLoanListParams['offset']> 
}

export type ProposalAndLoanListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof proposalAndLoanList>>>
export type ProposalAndLoanListInfiniteQueryError = unknown

export const useProposalAndLoanListInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof proposalAndLoanList>>, ProposalAndLoanListParams['offset']>, TError = unknown>(
 params?: MaybeRef<ProposalAndLoanListParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof proposalAndLoanList>>, TError, TData, Awaited<ReturnType<typeof proposalAndLoanList>>, QueryKey, ProposalAndLoanListParams['offset']>>, request?: SecondParameter<typeof customInstance>}

  ): UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getProposalAndLoanListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getProposalAndLoanListQueryOptions = <TData = Awaited<ReturnType<typeof proposalAndLoanList>>, TError = unknown>(params?: MaybeRef<ProposalAndLoanListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof proposalAndLoanList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getProposalAndLoanListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof proposalAndLoanList>>> = ({ signal }) => proposalAndLoanList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof proposalAndLoanList>>, TError, TData> 
}

export type ProposalAndLoanListQueryResult = NonNullable<Awaited<ReturnType<typeof proposalAndLoanList>>>
export type ProposalAndLoanListQueryError = unknown

export const useProposalAndLoanList = <TData = Awaited<ReturnType<typeof proposalAndLoanList>>, TError = unknown>(
 params?: MaybeRef<ProposalAndLoanListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof proposalAndLoanList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getProposalAndLoanListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const proposalDetail = (
    id: MaybeRef<number>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customInstance<ProposalDetailSchemaBackendSchema>(
      {url: `/api/v2/pwn_contracts/proposal/${encodeURIComponent(String(id))}`, method: 'GET', signal
    },
      options);
    }
  

export const getProposalDetailQueryKey = (id: MaybeRef<number>,) => {
    return ['api','v2','pwn_contracts','proposal',id] as const;
    }

    
export const getProposalDetailQueryOptions = <TData = Awaited<ReturnType<typeof proposalDetail>>, TError = unknown>(id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof proposalDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getProposalDetailQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof proposalDetail>>> = ({ signal }) => proposalDetail(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof proposalDetail>>, TError, TData> 
}

export type ProposalDetailQueryResult = NonNullable<Awaited<ReturnType<typeof proposalDetail>>>
export type ProposalDetailQueryError = unknown

export const useProposalDetail = <TData = Awaited<ReturnType<typeof proposalDetail>>, TError = unknown>(
 id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof proposalDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getProposalDetailQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const thesisList = (
    params?: MaybeRef<ThesisListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedThesisSchemaWorkaroundListBackendSchema>(
      {url: `/api/v2/pwn_contracts/thesis/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getThesisListQueryKey = (params?: MaybeRef<ThesisListParams>,) => {
    return ['api','v2','pwn_contracts','thesis', ...(params ? [params]: [])] as const;
    }

    
export const getThesisListQueryOptions = <TData = Awaited<ReturnType<typeof thesisList>>, TError = unknown>(params?: MaybeRef<ThesisListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof thesisList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getThesisListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof thesisList>>> = ({ signal }) => thesisList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof thesisList>>, TError, TData> 
}

export type ThesisListQueryResult = NonNullable<Awaited<ReturnType<typeof thesisList>>>
export type ThesisListQueryError = unknown

export const useThesisList = <TData = Awaited<ReturnType<typeof thesisList>>, TError = unknown>(
 params?: MaybeRef<ThesisListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof thesisList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getThesisListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const thesisDetail = (
    id: MaybeRef<string>,
    params?: MaybeRef<ThesisDetailParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      id = unref(id);
params = unref(params);
      
      return customInstance<ThesisSchemaWorkaroundBackendSchema>(
      {url: `/api/v2/pwn_contracts/thesis/${encodeURIComponent(String(id))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getThesisDetailQueryKey = (id: MaybeRef<string>,
    params?: MaybeRef<ThesisDetailParams>,) => {
    return ['api','v2','pwn_contracts','thesis',id, ...(params ? [params]: [])] as const;
    }

    
export const getThesisDetailQueryOptions = <TData = Awaited<ReturnType<typeof thesisDetail>>, TError = unknown>(id: MaybeRef<string>,
    params?: MaybeRef<ThesisDetailParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof thesisDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getThesisDetailQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof thesisDetail>>> = ({ signal }) => thesisDetail(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof thesisDetail>>, TError, TData> 
}

export type ThesisDetailQueryResult = NonNullable<Awaited<ReturnType<typeof thesisDetail>>>
export type ThesisDetailQueryError = unknown

export const useThesisDetail = <TData = Awaited<ReturnType<typeof thesisDetail>>, TError = unknown>(
 id: MaybeRef<string>,
    params?: MaybeRef<ThesisDetailParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof thesisDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getThesisDetailQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const thesisDetail2 = (
    slug: MaybeRef<string>,
    params?: MaybeRef<ThesisDetail2Params>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      slug = unref(slug);
params = unref(params);
      
      return customInstance<ThesisSchemaWorkaroundBackendSchema>(
      {url: `/api/v2/pwn_contracts/thesis/${encodeURIComponent(String(slug))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getThesisDetail2QueryKey = (slug: MaybeRef<string>,
    params?: MaybeRef<ThesisDetail2Params>,) => {
    return ['api','v2','pwn_contracts','thesis',slug, ...(params ? [params]: [])] as const;
    }

    
export const getThesisDetail2QueryOptions = <TData = Awaited<ReturnType<typeof thesisDetail2>>, TError = unknown>(slug: MaybeRef<string>,
    params?: MaybeRef<ThesisDetail2Params>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof thesisDetail2>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getThesisDetail2QueryKey(slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof thesisDetail2>>> = ({ signal }) => thesisDetail2(slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof thesisDetail2>>, TError, TData> 
}

export type ThesisDetail2QueryResult = NonNullable<Awaited<ReturnType<typeof thesisDetail2>>>
export type ThesisDetail2QueryError = unknown

export const useThesisDetail2 = <TData = Awaited<ReturnType<typeof thesisDetail2>>, TError = unknown>(
 slug: MaybeRef<string>,
    params?: MaybeRef<ThesisDetail2Params>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof thesisDetail2>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getThesisDetail2QueryOptions(slug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Create a new Strategy
 */
export const createStrategy = (
    thesisCreateUpdateSchemaRequestBackendSchema: MaybeRef<ThesisCreateUpdateSchemaRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      thesisCreateUpdateSchemaRequestBackendSchema = unref(thesisCreateUpdateSchemaRequestBackendSchema);
      
      return customInstance<CreateStrategy201>(
      {url: `/api/v2/pwn_contracts/thesis/update-or-create//`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: thesisCreateUpdateSchemaRequestBackendSchema
    },
      options);
    }
  


export const getCreateStrategyMutationOptions = <TError = CreateStrategy400 | CreateStrategy403,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStrategy>>, TError,{data: ThesisCreateUpdateSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createStrategy>>, TError,{data: ThesisCreateUpdateSchemaRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStrategy>>, {data: ThesisCreateUpdateSchemaRequestBackendSchema}> = (props) => {
          const {data} = props ?? {};

          return  createStrategy(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateStrategyMutationResult = NonNullable<Awaited<ReturnType<typeof createStrategy>>>
    export type CreateStrategyMutationBody = ThesisCreateUpdateSchemaRequestBackendSchema
    export type CreateStrategyMutationError = CreateStrategy400 | CreateStrategy403

    export const useCreateStrategy = <TError = CreateStrategy400 | CreateStrategy403,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStrategy>>, TError,{data: ThesisCreateUpdateSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createStrategy>>,
        TError,
        {data: ThesisCreateUpdateSchemaRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getCreateStrategyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update an existing Strategy
 */
export const updateStrategy = (
    thesisCreateUpdateSchemaRequestBackendSchema: MaybeRef<ThesisCreateUpdateSchemaRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      thesisCreateUpdateSchemaRequestBackendSchema = unref(thesisCreateUpdateSchemaRequestBackendSchema);
      
      return customInstance<UpdateStrategy200>(
      {url: `/api/v2/pwn_contracts/thesis/update-or-create//`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: thesisCreateUpdateSchemaRequestBackendSchema
    },
      options);
    }
  


export const getUpdateStrategyMutationOptions = <TError = UpdateStrategy400 | UpdateStrategy403 | UpdateStrategy404,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStrategy>>, TError,{data: ThesisCreateUpdateSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStrategy>>, TError,{data: ThesisCreateUpdateSchemaRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStrategy>>, {data: ThesisCreateUpdateSchemaRequestBackendSchema}> = (props) => {
          const {data} = props ?? {};

          return  updateStrategy(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateStrategyMutationResult = NonNullable<Awaited<ReturnType<typeof updateStrategy>>>
    export type UpdateStrategyMutationBody = ThesisCreateUpdateSchemaRequestBackendSchema
    export type UpdateStrategyMutationError = UpdateStrategy400 | UpdateStrategy403 | UpdateStrategy404

    export const useUpdateStrategy = <TError = UpdateStrategy400 | UpdateStrategy403 | UpdateStrategy404,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStrategy>>, TError,{data: ThesisCreateUpdateSchemaRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateStrategy>>,
        TError,
        {data: ThesisCreateUpdateSchemaRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getUpdateStrategyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    