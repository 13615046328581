import AssetType from '@/modules/common/assets/AssetType'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import { SupportedChain } from '@/constants/chains/types'
import { compareAddresses, compareAssets } from '@/utils/utils'
import SEPOLIA_CONSTANTS from '@/constants/chains/sepolia'
import ETHEREUM_CONSTANTS from '@/constants/chains/ethereum'
import { erc20Abi, erc721Abi, parseEventLogs } from 'viem'
import type { Address, TransactionReceipt } from 'viem'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { erc1155Abi, usdtMainnetAbi } from '@/contracts/abis'
import type { AssetMetadata } from './AssetClasses'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'
import { readErc1155IsApprovedForAll, readErc721IsApprovedForAll } from '@/contracts/generated'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { getInfoAboutSafe } from '@/modules/common/web3/useSafeWalletSdk'
import { AssetWithAmount } from './AssetClasses'
import { readAnyERC20Allowance } from '@/modules/common/adapters/erc20-utils'

export default function useApprove() {
  const CONTRACTS_WHICH_NEED_REMOVE_PREVIOUS_APPROVAL: Partial<{ [chain in SupportedChain]: Address[] }> = {
    [SupportedChain.Sepolia]: [SEPOLIA_CONSTANTS.topTokens.usdt!],
    [SupportedChain.Ethereum]: [ETHEREUM_CONSTANTS.topTokens.usdt!],
    [SupportedChain.Arbitrum]: [
      '0x0c0ADd0f4D8858516075ebAF8Cb1B98d1b33741a', // Swaap ARB-USDC
    ],
  }

  const getPreviousApprovalAmount = async (chainId: SupportedChain, contractAddress: Address, category: AssetType, spender: Address, owner: Address) => {
    if (category !== AssetType.ERC20) {
      return null
    }

    return readAnyERC20Allowance(contractAddress, chainId, owner, spender)
  }

  const isNeedRemovePreviousApproval = (chainId: SupportedChain, contractAddress: Address, category: AssetType, spender?: Address, owner?: Address, amount?: bigint) => {
    if (category !== AssetType.ERC20) {
      return false
    }

    return CONTRACTS_WHICH_NEED_REMOVE_PREVIOUS_APPROVAL[chainId]?.some(address => compareAddresses(address, contractAddress))
  }

  // TODO do we need something like `safeAddress`
  type ApproveTxParams = {
    asset: AssetMetadata
    spender: Address
    amount: bigint
    safeAddress?: Address
    // user can overwrite in his wallet the set approval `amount`, so this additional parameter check if the user set
    // at least min required approval
    minAllowanceAmountToCheck?: bigint
  }
  const approve = async (
    { asset, spender, amount, safeAddress = undefined, minAllowanceAmountToCheck = undefined }: ApproveTxParams,
    step?: ToastStep,
  ): Promise<boolean> => {
    let receipt: TransactionReceipt | undefined

    const assetIsUSDT = compareAssets({
      assetA: asset,
      assetB: AssetWithAmount.createMainnetUsdtAssetMetadata(),
    }) || compareAssets({
      assetA: asset,
      assetB: AssetWithAmount.createSepoliaUsdtAssetMetadata(),
    })

    const abi = !assetIsUSDT ? erc20Abi : usdtMainnetAbi

    if (asset.category === AssetType.ERC20) {
      receipt = await sendTransaction(
        {
          address: asset.address,
          abi,
          functionName: 'approve',
          args: [spender, amount],
          chainId: asset.chainId,
        }, { step, safeAddress })
      if (amount === 0n) {
        if (useConnectedAccountTypeStore()?.isConnectedContractWallet && safeAddress) {
          const infoAboutSafe = await getInfoAboutSafe(asset.chainId, safeAddress)
          return infoAboutSafe.threshold === 1
        }
        return true
      }

      const logs = parseEventLogs({
        abi,
        eventName: 'Approval',
        logs: receipt.logs,
      })
      if (minAllowanceAmountToCheck !== undefined) {
        return logs[0].args.value >= minAllowanceAmountToCheck
      } else {
        return logs[0].args.value >= amount
      }
    } else if (asset.category === AssetType.ERC721) {
      receipt = await sendTransaction({
        address: asset.address,
        abi: erc721Abi,
        functionName: 'setApprovalForAll',
        args: [spender, Boolean(amount)],
        chainId: asset.chainId,
      }, { step, safeAddress })
      if (!amount) {
        if (useConnectedAccountTypeStore().isConnectedContractWallet && safeAddress) {
          const infoAboutSafe = await getInfoAboutSafe(asset.chainId, safeAddress)
          return infoAboutSafe.threshold === 1
        }
        return true
      }

      const logs = parseEventLogs({
        abi: erc721Abi,
        eventName: 'ApprovalForAll',
        logs: receipt.logs,
      })
      return logs[0].args.approved
    } else if (asset.category === AssetType.ERC1155) {
      receipt = await sendTransaction({
        address: asset.address,
        abi: erc1155Abi,
        functionName: 'setApprovalForAll',
        args: [spender, Boolean(amount)],
        chainId: asset.chainId,
      }, { step, safeAddress })
      if (!amount) {
        if (useConnectedAccountTypeStore().isConnectedContractWallet && safeAddress) {
          const infoAboutSafe = await getInfoAboutSafe(asset.chainId, safeAddress)
          return infoAboutSafe.threshold === 1
        }
        return true
      }

      const logs = parseEventLogs({
        abi: erc1155Abi,
        eventName: 'ApprovalForAll',
        logs: receipt.logs,
      })
      return logs[0].args.approved
    } else {
      throw new Error(`Unknown asset category == ${asset.category} spotted while calling useApprove().approve.`)
    }
  }

  const isERC20Approved = async (chainId: SupportedChain, contractAddress: Address, owner: Address, spender: Address, amount: bigint): Promise<boolean> => {
    const allowance = await readAnyERC20Allowance(contractAddress, chainId, owner, spender)
    return allowance >= amount
  }

  const isERC721Approved = async (chainId: SupportedChain, contractAddress: Address, owner: Address, spender: Address): Promise<boolean> => {
    return await readErc721IsApprovedForAll(pwnWagmiConfig, {
      address: contractAddress,
      chainId,
      args: [owner, spender],
    })
  }

  const isERC1155Approved = async (chainId: SupportedChain, contractAddress: Address, owner: Address, spender: Address): Promise<boolean> => {
    return await readErc1155IsApprovedForAll(pwnWagmiConfig, {
      address: contractAddress,
      chainId,
      args: [owner, spender],
    })
  }

  // @ts-expect-error TS(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  const isApproved = async (chainId: SupportedChain, assetERCType: AssetType, contractAddress: Address, owner: Address, spender: Address, amount?: bigint): Promise<boolean> => {
    switch (assetERCType) {
    case AssetType.ERC20:
      return await isERC20Approved(chainId, contractAddress, owner, spender, amount!)
    case AssetType.ERC721:
      return await isERC721Approved(chainId, contractAddress, owner, spender)
    case AssetType.ERC1155:
      return await isERC1155Approved(chainId, contractAddress, owner, spender)
    }
  }

  return {
    isERC20Approved,
    isApproved,
    approve,
    isNeedRemovePreviousApproval,
    getPreviousApprovalAmount,
  }
}
