/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useQuery
} from '@tanstack/vue-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  PaginatedSubdomainConfigListBackendSchema,
  SpacesListParams
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const spacesList = (
    params?: MaybeRef<SpacesListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedSubdomainConfigListBackendSchema>(
      {url: `/spaces/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getSpacesListQueryKey = (params?: MaybeRef<SpacesListParams>,) => {
    return ['spaces', ...(params ? [params]: [])] as const;
    }

    
export const getSpacesListQueryOptions = <TData = Awaited<ReturnType<typeof spacesList>>, TError = unknown>(params?: MaybeRef<SpacesListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof spacesList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getSpacesListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof spacesList>>> = ({ signal }) => spacesList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof spacesList>>, TError, TData> 
}

export type SpacesListQueryResult = NonNullable<Awaited<ReturnType<typeof spacesList>>>
export type SpacesListQueryError = unknown

export const useSpacesList = <TData = Awaited<ReturnType<typeof spacesList>>, TError = unknown>(
 params?: MaybeRef<SpacesListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof spacesList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSpacesListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



