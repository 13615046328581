<template>
  <div class="pwn-safe-tokenize-modal-select-target-safe-step__container">
    <p>
      The following approvals and 3 transactions will transfer the asset into a PWN Safe, mint an ATR token of
      the asset and transfer the ATR Token back into your wallet.
    </p>
    <div class="pwn-safe-tokenize-modal-select-target-safe-step__select-wrapper">
      <BaseSelect
        :model-value="toWallet"
        label="name"
        :options="targetPwnSafeOptions"
        @update:model-value="handleUpdateToWallet">
        <template #option="{ option }">
          <AddressInfoSelectOption :address-info="option"/>
        </template>

        <template #singleLabel>
          <AddressInfoSelectOption :address-info="toWallet!"/>
        </template>
      </BaseSelect>
    </div>
    <WalletSafeWalletTransferGraphic :pwn-safe-name="toWallet?.name"/>
    <p>
      Please select a Safe and accept the following transactions if you wish to mint an ATR token of your asset.
    </p>
    <div class="pwn-safe-tokenize-modal-select-target-safe-step__buttons-container">
      <BaseButton
        button-text="previous step"
        :color="ButtonColor.White"
        :variant="ButtonVariant.Underline"
        @on-button-click="handlePreviousStepClick"/>
      <BaseButton
        button-text="Transfer"
        :is-disabled="isTransferButtonDisabled"
        @on-button-click="handleTransferClick"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseSelect from '@/general-components/BaseSelect.vue'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import AddressInfoSelectOption from '@/general-components/AddressInfoSelectOption.vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import usePwnSafeTokenizeModal, { PwnSafeTokenizeModalStep } from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModal'
import WalletSafeWalletTransferGraphic from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/WalletSafeWalletTransferGraphic.vue'
import { computed } from 'vue'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import { compareAddresses } from '@/utils/utils'
import { useSafesList } from '@/modules/queryHooks/safe/useSafesList'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { activeStep } = usePwnSafeTokenizeModal()
const { toWallet } = usePwnSafeTransfer()
const { pwnSafeWhereTokenizing, chainTarget } = usePwnSafeTokenize()
const { address: userAddress } = useCustomAccount()

// @ts-expect-error FIXME: strictNullChecks
const safesList = useSafesList(userAddress, chainTarget)

const targetPwnSafeOptions = computed(() => {
  return safesList.safes.value.filter(pwnSafe => !compareAddresses(pwnSafe?.safeAddress, userAddress.value))
})

const handlePreviousStepClick = () => {
  activeStep.value = PwnSafeTokenizeModalStep.AssetDetail
}

const handleTransferClick = () => {
  activeStep.value = PwnSafeTokenizeModalStep.TokenizingWithTransfers
}

const isTransferButtonDisabled = computed(() => !toWallet.value)

const handleUpdateToWallet = (newWallet: PwnSafe) => {
  toWallet.value = newWallet
  pwnSafeWhereTokenizing.value = newWallet
}
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.pwn-safe-tokenize-modal-select-target-safe-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__select-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21.75rem;
    align-self: center;
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
