<template>
  <div
    v-if="!isProposalOfConnectedWallet && !isProposalPage && !isCancelledOrExpired"
    ref="buttonRef"
    class="proposal-actions">
    <BaseTooltip
      :has-tooltip="!!tooltipText"
      append-to-body
      is-interactive
      :max-width="450"
      class="proposal-actions-proposal-page__tooltip-in-list"
      @click="handleUnconnectedWallet">
      <template #trigger>
        <div
          v-if="!userAddress"
          class="proposal-actions-proposal-page__connect-wallet-overlay"
          @click.stop="handleUnconnectedWallet"/>
        <BaseButton
          :variant="ButtonVariant.Outline"
          is-no-enlarge-effect
          is-full-width
          class="proposal-actions__button"
          :is-disabled="isButtonDisabled || isButtonClicked"
          :size="ButtonSize.M"
          :button-text="buttonText"
          @click.stop
          @on-button-click="handleOpenStartLoanModal"/>
      </template>
      <div>
        {{ tooltipText }}
      </div>
    </BaseTooltip>
  </div>
  <CancelProposalModal
    v-if="hasCancelButton && proposalClass"
    :proposal-class="proposalClass"
    is-proposal-page
    is-smaller-trigger
    label="Cancel"/>

  <BaseTooltip
    v-if="isProposalOfConnectedWallet && (!checks?.hasProposerApproval || !checks?.hasProposerFullAmountApproval)"
    class="proposal-actions-proposal-page__tooltip"
    append-to-body
    is-interactive
    :max-width="450"
    :has-tooltip="!!tooltipText">
    <template #trigger>
      <button
        v-if="isProposalPage"
        :disabled="isButtonDisabled"
        class="proposal-actions-proposal-page__button"
        @click="() => handleProposerApproveMutateAsync()">
        <CircleLoaderIcon
          v-if="isLoading"
          :is-pending="isLoading"/>
        <span v-if="!isLoading"> Re-approve </span>
        <!--  Asset Amount with appraisal  -->
        <AssetAmount
          v-if="assetInButton && proposalClass && !isLoading"
          class="proposal-actions-proposal-page__token-and-appraisal"
          :asset="{ ...assetInButton }"
          is-display-symbol
          :asset-amount="proposalClass.creditAmount.toString()"/>
      </button>
    </template>
    <div>
      {{ tooltipText }}
    </div>
  </BaseTooltip>
  <BaseTooltip
    v-else
    append-to-body
    is-interactive
    :max-width="450"
    class="proposal-actions-proposal-page__tooltip"
    :has-tooltip="!!tooltipText">
    <template #trigger>
      <div
        v-if="!userAddress"
        class="proposal-actions-proposal-page__connect-wallet-overlay"
        @click="handleUnconnectedWallet"/>
      <button
        v-if="isProposalPage"
        :disabled="isButtonDisabled"
        :class="['proposal-actions-proposal-page__button', {'proposal-actions-proposal-page__button--fake-asset': isAssetInProposalFake }]"
        @click="handleAcceptProposal">
        <div class="proposal-actions-proposal-page__button-text-and-rep-score">
          <CircleLoaderIcon
            v-if="isLoading"
            :is-pending="isLoading"/>
          <span
            v-if="!isLoading"
            class="proposal-actions-proposal-page__button-text-and-rep-score-loaded">
            {{ buttonText }} {{ isAssetInProposalFake ? '(Likely Fake!)' : '' }}
            <DangerAssetIcon
              v-if="isAssetInProposalFake"
              style="vertical-align: center;"/>
            <RewardsTooltip
              v-if="proposalClass && rewardsAssets.length > 0"
              :assets="rewardsAssets"
              is-with-text
              :proposal-id="proposalId"
              :proposal-rewards-amount="rewardsToBeEarnedFormatted"
              :apr="aprWithoutText">
              <template #trigger>
                <span class="proposal-actions-proposal-page__earn_rewards">
                  + earn rewards
                </span>
              </template>
            </RewardsTooltip>
          </span>
        </div>
        <!--  Asset Amount with appraisal  -->
        <AssetAmount
          v-if="assetInButton && proposalClass && !isLoading"
          class="proposal-actions-proposal-page__token-and-appraisal"
          :asset="{ ...assetInButton }"
          is-display-symbol
          :asset-amount="proposalClass.creditAmount.toString()"/>
      </button>
    </template>
    <div>
      {{ tooltipText }}
    </div>
  </BaseTooltip>
  <ModalKYC
    :heading="kycModalHeading"/>
</template>

<script setup lang="ts">
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import { useAcceptProposal } from '@/revamp/modules/proposals/useAcceptProposal'
import { computed, ref, unref, watch } from 'vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import { getProposalDetailQueryKey, getUserReputationScoreWithParametersRetriveQueryOptions, useProposalDetail } from '@/modules/common/backend/generated'
import AssetAmount from '@/revamp/components/AssetAmount.vue'
import type { AssetDetail, Proposal } from '@/revamp/modules/proposals/types'
import CancelProposalModal from '@/revamp/components/proposal-form/CancelProposalModal.vue'
import { compareAddresses, formatDecimalPoint, getRawAmount } from '@/utils/utils'
import { checkApprovalsAndBalances } from '@/revamp/modules/proposals/useProposalApprovals'
import { asyncComputed, useElementHover } from '@vueuse/core'
import BaseButton, { ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import { useStartLoanModalStore } from '@/revamp/components/actions/useStartLoanModalStore'
import useApprove from '@/modules/common/assets/useApprove'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { formatUnits, maxUint256 } from 'viem'
import { useSelectCollectionAssetModalStore } from '@/revamp/components/modals/select-collection-asset/useSelectCollectionAssetModalStore'
import { useModalKycStore } from '@/general-components/kyc-modal/useModalKycStore'
import ModalKYC from '@/general-components/kyc-modal/ModalKYC.vue'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import AssetType from '@/modules/common/assets/AssetType'
import { V1_2SimpleLoanFungibleProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import { useAcceptFungibleProposalModalStore } from '@/revamp/components/modals/accept-fungible-proposal/useAcceptFungibleProposalModalStore'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { compoundCometAllow, getPoolAdapter, getReserveData } from '@/contracts/abis'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { AssetReserveData } from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import CircleLoaderIcon from '@/general-components/icons/CircleLoaderIcon.vue'
import RouteName from '@/router/RouteName'
import useAnalytics, { EventNames } from '@/modules/common/analytics/useAnalytics'
import { useRoute } from 'vue-router'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import DangerAssetIcon from '@/general-components/DangerAssetIcon.vue'
import RewardsTooltip from '@/general-components/promo/RewardsTooltip.vue'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import { useRewardsForCreditToBeEarned } from '@/revamp/features/points/useRewardsForCreditToBeEarned'
import { useCustomConnect } from '@/modules/common/web3/useCustomConnect'
import { calculateMinCreditAmount } from '@/modules/common/pwn/utils'

type Props = {
  buttonText: string;
  isProposalPage?: boolean;
  proposalId?: number | string;
  proposal?: Proposal;
  isLoading?: boolean;
};
const props = defineProps<Props>()

const { address: userAddress } = useCustomAccount()
const storeSelectCollectionAssetModal = useSelectCollectionAssetModalStore()
const storeAcceptFungibleProposalModal = useAcceptFungibleProposalModalStore()
const { trackEvent } = useAnalytics()

const { acceptProposal } = useAcceptProposal()
const startLoanModalStore = useStartLoanModalStore()
const queryClient = useQueryClient()

const kycStore = useModalKycStore()
const { fetchErc20Metadata, fetchNftMetadata } = useMetadataFetch()
const { isAssetFake } = useTopTokensStore()

const buttonRef = ref()
const isStartLoanModal = ref()
const isHovered = useElementHover(buttonRef)
const route = useRoute()

const proposalId = computed(() => Number(props.proposal?.id || props.proposalId))
const { data: proposalData } = useProposalDetail(proposalId, {
  query: {
    enabled: !!props.proposal?.id || !!props.proposalId,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData,
  },
})

const proposalClass = asyncComputed(async () => {
  return proposalData.value?.data ? await ProposalFactory.createProposalFromBackendModel(proposalData.value.data) : null
}, null)

const aprWithoutText = computed(() => {
  if (!proposalClass.value?.accruingInterestAPR) return 'N/A'
  return (proposalClass.value?.accruingInterestAPR * 10 ** -APR_DECIMAL_POINT_PADDING).toFixed(0) + '%'
})

const _chainId = computed(() => unref(proposalClass)?.chainId ?? null)

const _amount = computed(() => {
  const _proposalClass = proposalClass.value
  if (!_proposalClass?.creditAmount || !_proposalClass?.creditAsset?.decimals) return null

  const amount = formatUnits(_proposalClass?.creditAmount, _proposalClass?.creditAsset?.decimals)
  return amount ?? null
})

const proposalAddress = computed(() => {
  return proposalClass.value?.creditAsset?.address ?? null
})

const { rewardsToBeEarned } = useRewardsForCreditToBeEarned(proposalAddress, _chainId, _amount)

const rewardsToBeEarnedFormatted = computed(() => {
  return formatDecimalPoint(rewardsToBeEarned.value)
})

const proposalClassId = computed(() => Number(proposalClass.value?.id))
const checkApprovalsAndBalancesQueryKey = computed(() => ['checkApprovalsAndBalances', userAddress, proposalClassId])

const { data: checks } = useQuery({
  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  queryKey: checkApprovalsAndBalancesQueryKey.value,
  queryFn: async () => {
    if (!userAddress.value || !proposalClass.value) {
      return null
    }
    return await checkApprovalsAndBalances({ proposalClass: proposalClass.value, userAddress: userAddress.value })
  },
})

const isMissingApprovalOfConnectedProposer = computed(() => {
  if (!checks.value) return false
  return compareAddresses(userAddress.value, proposalClass.value?.proposer) && (!checks.value?.hasProposerApproval || !checks.value?.hasProposerFullAmountApproval)
})

const isListProposal = computed(() => proposalClass.value?.type === 'pwn_contracts.v1_2simpleloanlistproposal' || proposalClass.value?.type === 'pwn_contracts.v1_1simpleloanlistproposal')

const getErrorMessageForAcceptorHasNoAsset = () => {
  const baseMessage = 'You don’t have the necessary funds to accept this proposal. '
  if (proposalClass.value instanceof V1_2SimpleLoanFungibleProposal) {
    if (proposalClass.value.isOffer) {
      const minCollateralAmountFormatted = formatDecimalPoint(formatUnits(proposalClass.value.minCollateralAmount, proposalClass.value.collateral.decimals))
      return `${baseMessage} ${minCollateralAmountFormatted} ${proposalClass.value.collateral.symbol} is the minimum set by the lender. `
    } else {
      const minCreditAmount = calculateMinCreditAmount(proposalClass.value?.minCollateralAmount, proposalClass.value?.creditPerCollateralUnit)
      const minCreditAmountFormatted = formatDecimalPoint(formatUnits(minCreditAmount, proposalClass.value.creditAsset.decimals))
      return `${baseMessage} ${minCreditAmountFormatted} ${proposalClass.value?.creditAsset?.symbol} is the minimum set by the borrower. `
    }
  }
  return baseMessage
}

const tooltipText = computed(() => {
  let message = ''
  if (!userAddress.value) {
    return 'Connect your wallet to accept this proposal.'
  }
  if (!proposalClass.value?.proposalSignature) {
    return 'Missing proposal signature. '
  }
  if (!checks.value || isMissingApprovalOfConnectedProposer.value) return
  if (!checks.value.hasProposerApproval) {
    message = 'Proposer doesn’t have the necessary approvals. '
  }
  if (!checks.value.hasProposerAsset) {
    message += 'Proposer doesn’t have the asset. '
  }
  if (!checks.value.hasAcceptorAsset && !isListProposal.value) {
    message += getErrorMessageForAcceptorHasNoAsset()
  }
  if (compareAddresses(userAddress.value, proposalClass.value?.proposer)) {
    message = 'You cannot accept your own proposal. '
  }
  return message
})

const sendProposalAcceptedEvent = (isProposalPage: boolean, isBorrowing: boolean) => {
  let eventName: EventNames | null = null

  const routeName = route.name as RouteName
  const routeIsHomepage = routeName === RouteName.RevampBorrow || routeName === RouteName.RevampLend
  const routeIsAssetPage = routeName === RouteName.RevampTokenBorrowing || routeName === RouteName.RevampTokenLending || routeName === RouteName.RevampNftPageBorrowing || routeName === RouteName.RevampNftPageLending

  if (routeIsHomepage) {
    eventName = isBorrowing ? EventNames.BorrowingProposalAcceptedHomepage : EventNames.LendingProposalAcceptedHomepage
  } else if (routeIsAssetPage) {
    eventName = isBorrowing ? EventNames.BorrowingProposalAcceptedAssetPage : EventNames.LendingProposalAcceptedAssetPage
  } else if (isProposalPage) {
    eventName = isBorrowing ? EventNames.BorrowingProposalAcceptedProposalPage : EventNames.LendingProposalAcceptedProposalPage
  }

  if (eventName) {
    trackEvent({ name: eventName })
  }
}

const updateRepScore = () => {
  if (!userAddress.value) return
  const keyToInvalidate = getUserReputationScoreWithParametersRetriveQueryOptions(userAddress.value, { refresh: true })

  queryClient.invalidateQueries(keyToInvalidate)
}

const proposalMutation = useMutation({
  mutationKey: ['acceptProposal', proposalId, userAddress],
  mutationFn: async () => {
    if (!userAddress.value || !proposalClass?.value) return
    if (isListProposal.value) {
      proposalClass.value.collateral.tokenId = storeSelectCollectionAssetModal?.selectedCollateral?.tokenId ?? 0n
    }

    const collateralBigIntAmountToCheck = proposalClass.value?.isFungibleProposal ? getRawAmount(storeAcceptFungibleProposalModal.collateralAmount, proposalClass.value.collateral.decimals) : undefined
    const isAcceptorApproved = async () => {
      if (proposalClass.value?.isFungibleProposal && userAddress.value && proposalClass.value?.isOffer) {
        return await useApprove().isApproved(
          proposalClass.value.chainId,
          proposalClass.value.collateral?.category,
          proposalClass.value.collateral.address,
          userAddress.value,
          proposalClass.value.loanContractAddress,
          collateralBigIntAmountToCheck,
        )
      }
      return checks.value?.hasAcceptorApproval ?? false
    }

    if (proposalClass.value?.isFungibleProposal) {
      (proposalClass.value as V1_2SimpleLoanFungibleProposal).collateralAmountToAccept = collateralBigIntAmountToCheck
    }
    await acceptProposal({
      acceptorAddress: userAddress.value,
      proposalClass: proposalClass.value,
      isAcceptorApproved: await isAcceptorApproved(),
      isProposalPage: props.isProposalPage,
      collateralBigIntAmountToCheck,
    })

    storeSelectCollectionAssetModal.reset()
    startLoanModalStore.close()
  },
  onSuccess: () => {
    sendProposalAcceptedEvent(props.isProposalPage, !proposalClass.value?.isOffer)
    updateRepScore()
  },
})

const { mutateAsync: handleAcceptProposalMutateAsync, isPending: isAcceptingProposal } = proposalMutation

const { mutateAsync: handleProposerApproveMutateAsync, isPending: isProposerApprovePending } = useMutation({
  mutationKey: ['handleProposerApprove', proposalId, userAddress],
  mutationFn: async (variables) => {
    if (!proposalClass.value) return
    const assetToApprove = proposalClass.value.isOffer
      ? proposalClass.value.creditAsset
      : proposalClass.value.collateral
    let aTokenAddress
    let isSofApproved = false
    const isCompoundProposal = CHAINS_CONSTANTS[String(proposalClass.value.chainId)]?.compound?.pools.includes(proposalClass.value?.sourceOfFunds)
    if (proposalClass.value.sourceOfFunds) {
      const poolAdapter = await getPoolAdapter(pwnWagmiConfig, {
        address: CHAINS_CONSTANTS[String(proposalClass.value?.chainId)].pwnV1_2Contracts.pwnConfig,
        chainId: proposalClass.value?.chainId,
        args: [proposalClass.value.sourceOfFunds],
      })

      if (proposalClass.value.sourceOfFunds === CHAINS_CONSTANTS[String(proposalClass.value.chainId)].aave.pool) {
        const reserveData = await getReserveData(pwnWagmiConfig, {
          address: CHAINS_CONSTANTS[String(proposalClass.value.chainId)].aave.pool,
          chainId: proposalClass.value.chainId,
          args: [proposalClass.value.creditAsset.address],
        }) as AssetReserveData
        aTokenAddress = reserveData.aTokenAddress
      }
      isSofApproved = isCompoundProposal
        ? !!await compoundCometAllow(pwnWagmiConfig, {
          address: proposalClass.value.sourceOfFunds,
          chainId: proposalClass.value.chainId,
          args: [poolAdapter, true],
        })
        : await useApprove().approve({
          asset: new AssetMetadata({
            address: aTokenAddress,
            chainId: assetToApprove.chainId,
            category: assetToApprove.category,
          }),
          spender: poolAdapter,
          amount: maxUint256,
          minAllowanceAmountToCheck: assetToApprove.amountRaw,
        })
    }
    const isApproved = await useApprove().approve({
      asset: new AssetMetadata({
        address: assetToApprove.address,
        chainId: assetToApprove.chainId,
        category: assetToApprove.category,
      }),
      spender: proposalClass.value.loanContractAddress || '0x',
      amount: maxUint256,
      minAllowanceAmountToCheck: assetToApprove.amountRaw,
    })
    if (isApproved && (!proposalClass.value?.sourceOfFunds || (isSofApproved && proposalClass.value?.sourceOfFunds))) {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: checkApprovalsAndBalancesQueryKey.value,
        }),
        queryClient.invalidateQueries({
          queryKey: getProposalDetailQueryKey(+proposalClass.value.id),
          refetchType: 'all',
        }),
      ])
    }
    return (isApproved && (!proposalClass.value?.sourceOfFunds || (isSofApproved && proposalClass.value?.sourceOfFunds)))
  },
})

const isButtonDisabled = computed(() => {
  if (!checks.value || isProposerApprovePending.value || isAcceptingProposal.value) {
    return true
  }

  if (isMissingApprovalOfConnectedProposer.value) return false

  return (
    !checks.value?.hasProposerApproval ||
    !checks.value?.hasProposerAsset ||
    (!checks.value?.hasAcceptorAsset && !isListProposal.value) ||
    !userAddress.value ||
    !proposalClass.value?.proposalSignature ||
    compareAddresses(userAddress.value, proposalClass.value?.proposer)
  )
})

const isProposalOfConnectedWallet = computed(() => {
  if (!userAddress.value || !proposalClass.value) return false
  return userAddress.value === proposalClass.value?.proposer
})

watch(isHovered, async (hovered) => {
  if (!hovered || !userAddress.value || !proposalClass.value) return
  await queryClient.refetchQueries({
    queryKey: ['checkApprovalsAndBalances', userAddress, proposalClassId],
    exact: false,
  })
})

const hasCancelButton = computed(() => {
  return isProposalOfConnectedWallet.value && proposalClass.value?.status === 1 && !props.isProposalPage
})

const isCancelledOrExpired = computed(() => {
  if (props.proposal) {
    return props.proposal?.status === -1 || props.proposal?.status === -2
  } else {
    return proposalClass.value?.status === -1 || proposalClass.value?.status === -2
  }
})

const assetInButton = computed(() => {
  if (!proposalClass.value?.creditAsset) return undefined
  const creditAsset: AssetDetail = {
    address: proposalClass.value?.creditAsset.address,
    category: proposalClass.value?.creditAsset.category,
    chainId: proposalClass.value?.creditAsset.chainId,
    decimals: proposalClass.value?.creditAsset.decimals,
    isKycRequired: proposalClass.value?.creditAsset.isKycRequired ?? null,
    tokenId: proposalClass.value?.creditAsset.tokenId?.toString() ?? null,
    thumbnailUrl: proposalClass.value?.creditAsset.image,
    symbol: proposalData.value?.data?.creditAsset?.symbol,
  }
  return creditAsset
})

const fetchAssetMetadata = async (asset: any) => {
  if (!asset || !proposalClass.value?.chainId) return null

  try {
    let assetInfo
    if (asset.category === AssetType.ERC20) {
      assetInfo = await fetchErc20Metadata({
        chainId: proposalClass.value.chainId,
        contractAddress: asset.address,
        isInBundle: false,
        userAddress: userAddress.value,
        refresh: false,
      })
    } else if (asset.category === AssetType.ERC721 || asset.category === AssetType.ERC1155) {
      assetInfo = await fetchNftMetadata({
        chainId: proposalClass.value.chainId,
        contractAddress: asset.address,
        tokenId: asset.tokenId || 0n,
        refresh: false,
        useCachedMetadata: true,
        isInBundle: false,
        userAddress: userAddress.value,
      })
    }
    return assetInfo
  } catch (error) {
    return null
  }
}

const checkKyc = async (submitAction) => {
  const collateralAsset = await fetchAssetMetadata(proposalClass.value?.collateral)
  const creditAsset = await fetchAssetMetadata(proposalClass.value?.creditAsset)
  const isCollateralKycRequired = collateralAsset?.isKycRequired || collateralAsset?.collection?.isKycRequired
  const isCreditAssetKycRequired = creditAsset?.isKycRequired
  const isKycRequired = isCollateralKycRequired || isCreditAssetKycRequired
  const assetForKyc = isCollateralKycRequired ? collateralAsset : creditAsset

  if (isKycRequired && assetForKyc && !kycStore.shouldSkipModal(assetForKyc)) {
    kycStore.setModalAsset(assetForKyc)
    kycStore.isOpen = true
    kycStore.submitAction = submitAction
    return true
  }
  return false
}

const handleAcceptProposalFlow = async () => {
  startLoanModalStore.proposalId = proposalId.value
  startLoanModalStore.handleAcceptProposalMutateAsync = handleAcceptProposalMutateAsync
  startLoanModalStore.proposalMutation = proposalMutation

  if (proposalClass.value?.isFungibleProposal) {
    storeAcceptFungibleProposalModal.proposalClass = proposalClass.value as V1_2SimpleLoanFungibleProposal
    storeAcceptFungibleProposalModal.open()
    return
  }

  if (isListProposal.value) {
    if (!proposalClass.value?.collateral?.chainId || !proposalClass.value.collateral.address) {
      // eslint-disable-next-line no-console
      console.error('Collateral chainId or address is missing')
      return
    }
    storeSelectCollectionAssetModal.collectionAddress = proposalClass.value.collateral.address
    storeSelectCollectionAssetModal.chainId = proposalClass.value.collateral.chainId
    storeSelectCollectionAssetModal.isOpen = true
    return
  }

  if (isStartLoanModal.value) {
    startLoanModalStore.open()
  } else {
    await handleAcceptProposalMutateAsync()
  }
}

const isButtonClicked = ref(false)

const handleOpenStartLoanModal = async () => {
  isButtonClicked.value = true
  setTimeout(() => {
    isButtonClicked.value = false
  }, 200)

  isStartLoanModal.value = true
  const isKyc = await checkKyc(handleAcceptProposalFlow)
  if (isKyc) return
  await handleAcceptProposalFlow()
}

const handleAcceptProposal = async () => {
  isStartLoanModal.value = false
  const isKyc = await checkKyc(handleAcceptProposalFlow)
  if (isKyc) return
  await handleAcceptProposalFlow()
}

const kycModalHeading = computed(() => {
  const asset = kycStore.asset
  if (asset?.collection?.kycTitle) {
    return asset.collection.kycTitle
  }
  if (asset?.kycTitle) {
    return asset.kycTitle
  }
  if (asset?.collection?.name) {
    return `KYC for ${asset.collection.name}`
  }
  return 'KYC Verification'
})

const { handleConnect } = useCustomConnect()
const handleUnconnectedWallet = () => {
  if (!userAddress.value) {
    handleConnect()
  }
}
const isAssetInProposalFake = computed(() => {
  return isAssetFake(proposalClass.value?.collateral) || isAssetFake(proposalClass.value?.creditAsset)
})

const rewardsAssets = computed(() => {
  if (!proposalClass.value?.chainId || !proposalClass.value?.creditAsset?.address || !proposalClass.value?.collateral?.address) return []
  return [{ chainId: proposalClass.value?.chainId, address: proposalClass.value?.creditAsset?.address }, { chainId: proposalClass.value?.chainId, address: proposalClass.value?.collateral?.address }]
})
</script>

<style scoped>
.proposal-actions {
  max-width: 100px;
  overflow: hidden;

  &__button {
    margin-right: 0.3rem;
  }
}

.proposal-actions-proposal-page {
  &__button {
    cursor: pointer;
    height: 64px;
    width: 381px;
    border: 1px solid var(--primary-color-1, #00ffe0);
    background: var(--primary-color-5, #0f2926);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    color: var(--primary-color-1);

    &--fake-asset {
      color: var(--danger-1);
      background: #260708;
      border: 1px solid var(--danger-1);
    }

    &:disabled {
      opacity: 0.5;
    }

    &:hover:not(:disabled) {
      background: var(--primary-color-3, #0f2926);
    }

    @media only screen and (--mobile-viewport) {
      width: 100%;
    }
  }

  &__token-and-appraisal {
    text-align: end;
  }

  &__token {
    color: var(--text-color);
    gap: 0.5rem;
    font-size: 14px;
    font-weight: 400;
  }

  &__tooltip {
    height: 4rem;
    position: relative;
  }

  &__appraisal {
    color: var(--gray);
    font-size: 12px;
    font-weight: 400;
    text-align: end;
  }

  &__tooltip-in-list {
    position: relative;
  }

  &__connect-wallet-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  &__button-text-and-rep-score-loaded,
  &__button-text-and-rep-score {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.2rem;
  }

  &__earn_rewards {
    color: var(--primary-color-1);
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>
