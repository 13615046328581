<template>
  <div class="chain-info__container">
    <AssetChainIcon
      v-if="iconWithCircle"
      :class="['chain-info__icon', {'chain-info__icon--small': isSmall}]"
      :chain-id="chainId"/>
    <ChainIcon
      v-else
      class="chain-info__icon"
      :class="['chain-info__icon', {'chain-info__icon--small': isSmall}]"
      :alt="chainNameUppercase"/>
    <span :class="['chain-info__text', {'chain-info__text--small': isSmall}]">
      {{ chainNameUppercase }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'
import { getChainIcon, getChainName } from '@/utils/chain'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'

const props = defineProps<{
  chainId: SupportedChain,
  isSmall?: boolean,
  iconWithCircle?: boolean,
}>()

const chainNameUppercase = computed(() => getChainName(props.chainId))
const ChainIcon = computed(() => getChainIcon(props.chainId))
</script>

<style scoped>
.chain-info {
  &__container {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
  }

  &__icon {
    margin-right: 0.5rem;
    width: 1.375rem;
    height: 1.375rem;

    &--small {
      width: 0.75rem !important;
      height: 0.75rem !important;
    }
  }

  &__text {
    font-family: var(--font-family-supreme);
    text-transform: capitalize;
    font-size: 1rem;

    &--small {
      font-size: 0.75rem;
      line-height: 1;
    }
  }
}
</style>
