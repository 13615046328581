import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { unref } from 'vue'
import type { MaybeRef } from 'vue'
import { useStarknetAssetBalances } from './starknet/useStarknetAssetBalances'
import type { Address } from 'viem'
import { SupportedChain } from '@/constants/chains/types'

export const useAssetBalances = (
  tokens: MaybeRef<AssetWithAmount[]>,
  owner: MaybeRef<Address | undefined>,
) => {
  if (isStarknet && unref(owner)) {
    // todo: make this dynamic
    return useStarknetAssetBalances(tokens, SupportedChain.StarknetSepolia, unref(owner) as Address)
  }

  return {}
}
