import type { SupportedChain } from '@/constants/chains/types'
import type { Address } from 'viem'
import { KERNEL_ASSETS } from './kernel/utils'
import KarakIcon from '@/assets/icons/karak.svg'
import type { FunctionalComponent } from 'vue'
import KernelIconUrl from '@/assets/icons/kernel.png'
import { parseAddress } from '@/utils/address-utils'
import ResolvIconUrl from '@/assets/icons/resolv.svg?url'
import { RESOLV_ADDRESS_MULTIPLIER, RESOLV_ASSETS } from './resolv/utils'

export interface RewardForAsset {
    icon: FunctionalComponent | string // either SVG component or static asset path (png, etc.)
    name: string
    value: string
}

export interface EligibleRewardsInput {
  chainId: SupportedChain;
  address: Address;
  type?: string
}

const gerResolvRewardText = (asset: EligibleRewardsInput) => {
  if (asset.type === 'credit') {
    return 'Resolv pts (lend)'
  }
  if (asset.type === 'collateral') {
    return 'Resolv pts (borrow)'
  }
  return 'Resolv points'
}

export const getEligibleRewards = (assets: EligibleRewardsInput[]): RewardForAsset[] => {
  const eligibleRewards: RewardForAsset[] = []

  for (const asset of assets) {
    const kernelAddresses = KERNEL_ASSETS[asset.chainId]
    if (kernelAddresses) {
      if (kernelAddresses.some(kernelAddress => parseAddress(kernelAddress) === parseAddress(asset.address))) {
        eligibleRewards.push({
          icon: KernelIconUrl as string,
          name: 'Kernel Points',
          value: '3x',
        })
        eligibleRewards.push({
          icon: KarakIcon,
          name: 'Karak XP',
          value: '3x',
        })
        break // Break outer loop once a match is found for this address
      }
    }
    const resolvAddresses = RESOLV_ASSETS[asset.chainId]
    if (resolvAddresses) {
      if (resolvAddresses.some(resolvAddress => parseAddress(resolvAddress) === parseAddress(asset.address))) {
        eligibleRewards.push({
          icon: ResolvIconUrl as unknown as string,
          name: gerResolvRewardText(asset),
          value: `${RESOLV_ADDRESS_MULTIPLIER[asset.chainId][parseAddress(asset.address)]}x`,
        })
      }
    }
  }

  const rewardsForResolv = eligibleRewards.filter(reward => reward.name.includes('Resolv'))
  const shouldAddResolvNonBustedRewards = eligibleRewards.length >= 1 && assets.length === 2 && rewardsForResolv.length === 1
  if (shouldAddResolvNonBustedRewards) {
    const resolvAsset = assets.find(asset => {
      const resolvAddresses = RESOLV_ASSETS[asset.chainId]
      return resolvAddresses && resolvAddresses.some(resolvAddress => parseAddress(resolvAddress) === parseAddress(asset.address))
    })

    const rewardReversedText = resolvAsset?.type === 'credit' ? 'borrow' : 'lend'
    eligibleRewards.push({
      icon: ResolvIconUrl as unknown as string,
      name: `Resolv pts (${rewardReversedText})`,
      value: '5x',
    })
  }

  return eligibleRewards
}
