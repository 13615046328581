enum StoreIds {
  modalWrapNativeToken = 'modalWrapNativeToken',
  modalMultisigWalletSign = 'modalMultisigWalletSign',

  openLoans = 'openLoans',

  routerStack = 'routerStack',
  revokeLoanRequest = 'revokeLoanRequest',

  whatIsNewModal = 'whatIsNewModal',

  notificationReminderModal = 'notificationReminderModal',
  selectedChains = 'selectedChains',

  topTokens = 'topTokens',
  hiddenAssets = 'hiddenAssets',

  currencySwitcher = 'currencySwitcher',

  connectedAccountType = 'connectedAccountType',

  userAssets = 'userAssets',

  pwnSafeDetail = 'pwnSafeDetail',
  safeWallet = 'safeWallet',

  modalKyc = 'modalKyc',

  proposalFilters = 'proposalFilters',

  startLoanModal = 'startLoanModal',

  selectCollectionAssetModal = 'selectCollectionAssetModal',
  useSelectedCollateral = 'useSelectedCollateral',

  useNoProposals = 'useNoProposals',
  acceptFungibleProposalModalStore = 'acceptFungibleProposalModalStore',

  globalFilters = 'globalFilters',
  collectionPage = 'collection-page',
  collectionPageAssetStore = 'collection-page-asset-store',
  collectionPageNavigateByTokenId = 'collection-page-navigate-by-token-id',
  assetsWithSmallBalances = 'assets-with-small-balances',
}
export default StoreIds
