<template>
  <div :class="['status', `status--size-${props.size}`, `status--${props.variant}`]">
    <div :class="['status__dot', `status__dot--${props.variant}`]"/>
    <slot v-if="!isWithoutText">
      {{ props.text }}
    </slot>
  </div>
</template>

<script lang="ts">
export enum StatusSize {
  S = 's',
  M = 'm',
  L = 'l',
}

export enum StatusVariant {
  InactiveRevoked = 'inactive-revoked',
  InactivePaid = 'inactive-paid',
  InactiveDefaulted = 'inactive-defaulted',
  Pending = 'pending',
  AvailableOffers = 'available-offers',
  Active = 'active',
  Paid = 'paid',
  Defaulted = 'defaulted',

  // client-side only
  ActiveTransferred = 'active-transferred',
  PaidBackTransferred = 'paid-back-transferred',
  DefaultedTransferred = 'defaulted-transferred',
  InactiveExpired = 'inactive-expired',
  NonExistent = 'non-existent'
}
</script>

<script setup lang="ts">
/* eslint-disable import/first */

interface Props {
  variant: StatusVariant | null
  text?: string
  size?: StatusSize
  isWithoutText?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: StatusSize.M,
})
</script>

<style scoped>
.status {
  font-family: var(--font-family-oxygen-mono);
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-color);

  &--active-transferred,
  &--paid-back-transferred {
    text-decoration: line-through;
  }

  &__dot {
    min-width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;

    --status-waiting-for-offers: var(--warning);

    &--pending {
      background: var(--warning);
    }

    &--available-offers {
      @keyframes available-offers-status-color-change {
        0% {
          background-color: var(--status-waiting-for-offers);
        }

        100% {
          background-color: var(--status-active);
        }
      }
      animation: 1500ms cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite available-offers-status-color-change;
      animation-direction: alternate;
    }

    &--active,
    &--active-transferred {
      background: var(--status-active);
    }

    &--inactive-expired,
    &--inactive-revoked {
      background: var(--gray);
    }

    &--inactive-defaulted {
      background: linear-gradient(180deg, var(--gray-2) 37.33%, var(--negative-1) 100%);
    }

    &--inactive-paid {
      background: linear-gradient(180deg, var(--gray-2) 37.33%, var(--info) 100%);
    }

    &--paid,
    &--paid-back-transferred {
      background: var(--info);
    }

    &--defaulted,
    &--defaulted-transferred {
      background: var(--orange-1);
    }
  }

  &--size {
    &-s {
      font-size: 0.75rem;
    }

    &-m {
      font-size: 1rem;
    }

    &-l {
      font-size: 1.2rem;
    }
  }
}
</style>
