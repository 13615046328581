import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import OpenseaIconSvg from '@/assets/icons/opensea.svg'
import type { Address } from 'viem'
import ArbitrumIconSvg from '@/assets/icons/arbitrum-white.svg'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://arbiscan.io/'

const ARBITRUM_ONE_CONSTANTS: ChainConstants = {
  gnosisSafe: { chainName: 'arb1', transactionServiceUrl: 'https://safe-transaction-arbitrum.safe.global/' },
  pwnSafeContracts: {
    assetTransferRightsGuardProxy: '0xd7903fD606C3FfF8d776723Af7CCFB6d6E85B126',
    assetTransferRights: '0x2Af429Ab631Cdd2e9de396F8C838d7ad231E73EB',
    pwnSafeFactory: '0x72dc3870FEb5d80f3feC46e3BFDa673170695395',
  },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x57c88D78f6D08b5c88b4A3b7BbB0C1AA34c3280A',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x448E3D0a4BAa00FE511a03E7B27177AeDE6d9636',
  topTokens: new TopTokens({
    dai: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    usdc: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
    usdt: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    weth: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
  }),
  general: {
    chainId: SupportedChain.Arbitrum,
    chainName: 'Arbitrum',
  },
  nodeProvider: {
    httpNodeUrl: 'https://arb-mainnet.g.alchemy.com/v2/',
    wsNodeUrl: `wss://arb-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_OPTIMISM_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_OPTIMISM_ALCHEMY_NODE_KEY as string,
  },
  nftContractsAddresses: {
    kyberSwapElastic: '0xe222fBE074A436145b255442D919E4E3A6c6a480',
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'arbiscan',
    blockExplorerIcon: ArbitrumIconSvg,
    nftDetailsLink: (tokenAddress, tokenId) => `https://opensea.io/assets/arbitrum/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: OpenseaIconSvg,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://opensea.io/collection/${slug}`,
  },
  aave: {
    uiPoolDataProvider: '0x5d4D4007A4c6336550DdAa2a7c0d5e7972eebd16',
    poolAddressesProvider: '0xa97684ead0e402dC232d5A977953DF7ECBaB3CDb',
    pool: '0x794a61358D6845594F94dc1DB02A252b5b4814aD',
  },
  compound: {
    pools: ['0xA5EDBDD9646f8dFF606d7448e414884C7d905dCA', '0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf', '0x6f7D514bbD4aFf3BcD1140B7344b32f063dEe486', '0xd98Be00b5D27fc98112BdE293e487f8D4cA57d07'],
  },
}

export default ARBITRUM_ONE_CONSTANTS
