<template>
  <div class="proposal-duration">
    <span class="proposal-duration__value">
      {{ formattedDuration }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
type Props = {
  duration: number;
};

const props = defineProps<Props>()

const formattedDuration = computed(() => {
  if (props.duration < 1) {
    return '<1d'
  }
  return `${Math.round(props.duration)}d`
})
</script>

<style scoped>
.proposal-duration {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &__value {
    font-size: 0.875rem;
    color: var(--text-color);
  }
}
</style>
