import { displayTokenAmount, displayUsdAmount, formatToken, getRawAmount } from '@/utils/utils'
import type { AmountInEthAndUsdBackendSchema } from '@/modules/common/backend/generated'

export class AmountInEthAndUsd {
  constructor(
    public ethAmount: string, // always formatted in ether unit
    public usdAmount: string,
  ) {}

  static createFromBackendModel(amountInEthAndUsd: AmountInEthAndUsdBackendSchema | undefined | null): AmountInEthAndUsd | undefined {
    if (!amountInEthAndUsd) {
      return undefined
    }

    return new AmountInEthAndUsd(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      amountInEthAndUsd.eth_amount,
      amountInEthAndUsd.usd_amount,
    )
  }

  get formattedUsdAmount(): string {
    // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    return displayUsdAmount(this.usdAmount)
  }

  get formattedEthAmount(): string {
    // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    return displayTokenAmount(this.ethAmount)
  }

  get shortenedEthAmount(): string {
    return formatToken(this.ethAmount)
  }

  get ethAmountRaw(): bigint {
    const ETH_DECIMALS = 18
    return getRawAmount(this.ethAmount, ETH_DECIMALS)
  }

  static multiply(amount: AmountInEthAndUsd, multiplier: number): AmountInEthAndUsd {
    const ethAmount = (parseFloat(amount.ethAmount) * multiplier).toString()
    const usdAmount = (parseFloat(amount.usdAmount) * multiplier).toString()
    return new AmountInEthAndUsd(ethAmount, usdAmount)
  }
}

export type PriceRange = [AmountInEthAndUsd, AmountInEthAndUsd] // [low, high]
