import type { ChainConstants } from '@/constants/chains/types'

export const pwnV1_2Contracts: ChainConstants['pwnV1_2Contracts'] = {
  pwnSimpleLoan: '0x9A93AE395F09C6F350E3306aec592763c517072e',
  multiTokenCategoryRegistry: '0xbB2168d5546A94AE2DA9254e63D88F7f137B2534',
  pwnRevokedNonce: '0x972204fF33348ee6889B2d0A3967dB67d7b08e4c',
  pwnLoan: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  pwnSimpleLoanSimpleProposal: '0xEb3e6B9B51911175F3a121b5Efb46Fa354520f41',
  pwnSimpleLoanListProposal: '0x0E6cE603d328de0D357D624F10f3f448855fFBDC',
  pwnSimpleLoanDutchAuctionProposal: '0x807eb2A61B2d0193b0696436BeFFcFE4d6D520CB',
  pwnSimpleLoanFungibleProposal: '0x0618504Fa17888ec36AC5D46A4A0Ed59436Fb77E',
  pwnConfig: '0xd52a2898d61636bB3eEF0d145f05352FF543bdCC',
}

export const pwnV1_2ContractsStarknet: ChainConstants['pwnV1_2Contracts'] = {
  pwnSimpleLoan: '0x0679eae6cdd4c2e7266f9c20452bf02a8d26f52a6b9bd930339ccde97a8d9579',
  multiTokenCategoryRegistry: '0x042772be7ae4fd8699c07683b062a8f6e4623145a4630e7f1cbad54a811d7afe',
  pwnRevokedNonce: '0x00da94e7963078f0ad46b431e6d518c0bdb7415c54732d812bcf62c3a36dacf2',
  pwnLoan: '0x06d3fe7506cd04cd8788fa99c580e02c11abe8f65a9e861512d5716bbe20c742',
  pwnSimpleLoanSimpleProposal: '0x0399f68e50fce201a11c11575ef45edc5449dcc295fd131f47f0367dc05604ae',
  pwnSimpleLoanListProposal: '0x06405b987104906b4adcf3188ddcb9205cae778d3600cc47766cf0451ed7c7db',
  pwnSimpleLoanDutchAuctionProposal: '0x0369500a7d2c0ef339e2d575290cc14308e0ce27dd1be93d2da872697127a082',
  pwnSimpleLoanFungibleProposal: '0x050c97a3d6ead0f7998ef29e1bbd85d3662abd152016406ee1cb2d37d156fdb5',
  pwnConfig: '0x03e5d8f5233e819857a1563780bcbeb4c7eee82bcb88a6c1df1ca4f5af3e8b28',
}
