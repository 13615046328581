<template>
  <BaseModal
    v-model:is-open="isOpen"
    heading-align="left"
    heading="Enter your email"
    @update:is-open="handleOnClose">
    <template #trigger>
      <BaseButton
        :style="{ width: props.buttonWidth }"
        is-full-width
        :color="ButtonColor.PrimaryDark"
        :variant="ButtonVariant.Outline"
        button-text="Email"
        @on-button-click="openModal">
        <template #iconBeforeText>
          <LetterIconSvg
            class="email-notification-modal__email-icon"
            alt="email logo"/>
        </template>
      </BaseButton>
    </template>

    <template #body>
      <div class="email-notification-modal__body">
        <div class="email-notification-modal__input-container">
          <div class="email-notification-modal__input">
            <BaseInput
              v-model="email"
              :is-valid-input="isValidInput"
              placeholder="example@gmail.com"
              warning-text="Please enter a valid email"
              @blur="() => handleFocus(false)"
              @focus="() => handleFocus(true)"/>
          </div>
        </div>
      </div>
      <BaseSkeletor
        v-if="isFetchingUserProfile"
        class="email-notification-modal__loading"/>
      <div v-else>
        <div class="email-notification-modal__currently-sending-subheading">
          Sending notifications to:
        </div>
        <BaseButton
          v-if="userEmail"
          :variant="ButtonVariant.Outline"
          :button-text="userProfile?.email"
          @on-button-click="handleRemoveEmail">
          <template #icon>
            <CloseIconSvg
              alt="close"
              class="email-notification-modal__close-icon"/>
          </template>
        </BaseButton>
        <div
          v-else-if="isEmailNotSet"
          class="email-notification-modal__email-not-set">
          not set
        </div>
        <div
          v-else
          class="email-notification-modal__no-email"/>
        <div v-if="userProfile?.email && !isRemoveEmail">
          <div
            v-if="userProfile?.emailVerified"
            class="email-notification-modal__email-verified">
            Email Verified
          </div>
          <div
            v-else
            class="email-notification-modal__email-unverified">
            Please confirm your email in your inbox and refresh page
          </div>
        </div>
        <div
          v-else
          class="email-notification-modal__no-email"/>
        <div class="email-notification-modal__button-container">
          <BaseLink @click="isOpen = false">
            Go back
          </BaseLink>
          <BaseButton
            button-text="Save Settings"
            :is-disabled="isDisabledSaveButton"
            @on-button-click="saveSettings"/>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import { computed, ref } from 'vue'
import BaseButton, { ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import BaseInput from '@/general-components/BaseInput.vue'
import useUserProfile from '@/modules/common/assets/fetchers/useUserProfile'
import CloseIconSvg from '@/assets/icons/close-primary-color.svg'
import LetterIconSvg from '@/assets/icons/letter.svg'
import { emailRegex } from '@/utils/utils'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import BaseLink from '@/general-components/BaseLink.vue'

const isOpen = ref(false)
const email = ref('')
const isFocused = ref(false)
const isRemoveEmail = ref(false)
const { userProfile, updateUserProfile, isFetchingUserProfile, loadUserProfile } = useUserProfile()
const { address: userAddress } = useCustomAccount()

const props = defineProps<{
  buttonWidth?: string
}>()

const openModal = async () => {
  loadUserProfile(userAddress.value!, true)
  isOpen.value = true
}

const isValidEmailAddress = computed(() => emailRegex.test(email.value))

const isDisabledSaveButton = computed(() =>
  !isRemoveEmail.value &&
  (!isValidEmailAddress.value || email.value === userProfile.value?.email),
)

const isEmailNotSet = computed(() => !userProfile.value?.email && !isRemoveEmail.value)

const isValidInput = computed(() =>
  isValidEmailAddress.value ||
  isFocused.value ||
  email.value === '',
)

const userEmail = computed(() => userProfile.value?.email && isValidInput.value && !isRemoveEmail.value)

const saveSettings = () => {
  if (isRemoveEmail.value) {
    email.value = ''
    // @ts-expect-error FIXME: strictNullChecks
    userProfile.value.email = ''
    isRemoveEmail.value = false
  }
  // @ts-expect-error FIXME: strictNullChecks
  userProfile.value.email = email.value
  // TODO turn into mutation + add toast notification for this action
  updateUserProfile()
}

const handleRemoveEmail = () => {
  isRemoveEmail.value = true
}

const handleFocus = (isInputFocused: boolean) => {
  isFocused.value = isInputFocused
}

const handleOnClose = (isOpen: boolean) => {
  if (!isOpen && isRemoveEmail.value) {
    isRemoveEmail.value = false
  }
  // @ts-expect-error FIXME: strictNullChecks
  email.value = userProfile.value.email || ''
}
</script>

<style scoped>
.email-notification-modal {
  height: 12rem;

  &__body {
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__input-container {
    display: flex;
    width: 70%;
    min-width: 20rem;
  }

  &__input {
    width: 100%;
  }

  &__icon {
    display: flex;
    margin-left: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__no-email {
    height: 2.5rem;
  }

  &__close-icon {
    margin-left: 0.5rem;
  }

  &__currently-sending-subheading {
    margin-bottom: 0.75rem;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__email-not-set {
    color: var(--gray-2);
    height: 2.5rem;
  }

  &__email-unverified,
  &__email-verified {
    display: flex;
    align-items: center;
    height: 2.5rem;
  }

  &__email-verified {
    color: var(--success);
  }

  &__email-unverified {
    color: var(--warning);
  }

  &__loading {
    margin-bottom: 8.125rem;
  }

  &__email-icon {
    margin-left: 0.5rem;
  }
}
</style>
