import type { OfferFilterSettingsBackendSchema, OfferFilterSettingsRequestBackendSchema } from '@/modules/common/backend/generated'

export default class UserOfferFilterSettings {
  public onlyVerifiedTokens?: boolean
  public onlyRelevantTokens?: boolean
  public filterApy?: boolean
  public minApy?: number
  public maxApy?: number
  public filterLtv?: boolean
  public minLtv?: number
  public maxLtv?: number

  constructor(offerFilterSettings: UserOfferFilterSettings) {
    this.onlyVerifiedTokens = offerFilterSettings.onlyVerifiedTokens
    this.onlyRelevantTokens = offerFilterSettings.onlyRelevantTokens
    this.filterApy = offerFilterSettings.filterApy
    this.minApy = offerFilterSettings.minApy
    this.maxApy = offerFilterSettings.maxApy
    this.filterLtv = offerFilterSettings.filterLtv
    this.minLtv = offerFilterSettings.minLtv
    this.maxLtv = offerFilterSettings.maxLtv
  }

  public static createFromBackendModel(offerFilterSettings: OfferFilterSettingsBackendSchema): UserOfferFilterSettings {
    return new UserOfferFilterSettings({
      onlyVerifiedTokens: offerFilterSettings.only_verified_tokens,
      onlyRelevantTokens: offerFilterSettings.only_relevant_offers,
      filterApy: offerFilterSettings.filter_apy,
      minApy: offerFilterSettings.min_apy ?? undefined,
      maxApy: offerFilterSettings.max_apy ?? undefined,
      filterLtv: offerFilterSettings.filter_ltv,
      minLtv: offerFilterSettings.min_ltv ?? undefined,
      maxLtv: offerFilterSettings.max_ltv ?? undefined,
    })
  }

  public static createRequestBody(offerFilterSettings: UserOfferFilterSettings): OfferFilterSettingsRequestBackendSchema {
    return {
      only_verified_tokens: offerFilterSettings.onlyVerifiedTokens,
      only_relevant_offers: offerFilterSettings.onlyRelevantTokens,
      filter_apy: offerFilterSettings.filterApy,
      min_apy: offerFilterSettings.minApy,
      max_apy: offerFilterSettings.maxApy,
      filter_ltv: offerFilterSettings.filterLtv,
      min_ltv: offerFilterSettings.minLtv,
      max_ltv: offerFilterSettings.maxLtv,
    }
  }
}
