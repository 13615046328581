import { getAccount, getConnectorClient, switchChain } from '@wagmi/vue/actions'
import { watchAsset } from 'viem/actions'
import { pwnWagmiConfig } from './usePwnWagmiConfig'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import useNotifications from '@/modules/common/notifications/useNotifications'
import to from '@/utils/await-to-js'

export const addTokenToWallet = async (tokenToAdd: AssetWithAmount | AssetMetadata) => {
  const connectorClient = await getConnectorClient(pwnWagmiConfig, { chainId: tokenToAdd.chainId })

  const currentChainId = getAccount(pwnWagmiConfig).chainId

  // TODO do we need this check? how it behaves when called with same chain as the connected one?
  if (currentChainId !== tokenToAdd.chainId) {
    // TODO do we need to switch the chains here?
    const [error, result] = await to(switchChain(pwnWagmiConfig, { chainId: tokenToAdd.chainId }))
    if (error || result.id !== tokenToAdd.chainId) {
      // TODO any notification to display to user here?
      return
    }
  }

  const success = await watchAsset(connectorClient, {
    type: 'ERC20', // Initially only supports ERC20, but eventually more!
    options: {
      address: tokenToAdd.address, // The address that the token is at.
      symbol: tokenToAdd.symbol, // A ticker symbol or shorthand, up to 5 chars.
      decimals: tokenToAdd.decimals, // The number of decimals in the token
      image: tokenToAdd?.image, // A string url of the token logo
    },
  })

  const { displayErrorNotification, displaySuccessNotification } = useNotifications()
  if (!success) {
    displayErrorNotification({
      errorMessage: 'Error: Add token to Metamask failed',
      firstAsset: tokenToAdd,
      chainId: tokenToAdd.chainId,
      action: NotificationFrontendOnlyActionEnum.TOKEN_ADDED_TO_METAMASK,
    })
  } else {
    displaySuccessNotification({
      action: NotificationFrontendOnlyActionEnum.TOKEN_ADDED_TO_METAMASK,
      chainId: tokenToAdd.chainId,
      firstAsset: tokenToAdd,
      actionName: NotificationFrontendOnlyActionEnum.TOKEN_ADDED_TO_METAMASK,
    })
  }
}
