<template>
  <div class="proposal-detail">
    <div class="proposal-detail__proposals-title">
      <HeaderProposalOrLoan
        v-if="parsedProposal"
        :title="title"
        :type="parsedProposal.type as ProposalAndLoanTypes"/>
      <div class="proposal-detail__proposal-actions">
        <SetNotificationsButton
          v-if="isConnectedProposer"
          :proposal-id="proposalId"
          :is-modal-open="isNotificationModalShown"/>
        <ModalShareProposals
          v-if="parsedProposal"
          heading="Share Proposal"
          share-message="Hey, check out this proposal I've found on PWN.xyz"
          :proposal-id="parsedProposal.id"/>
      </div>
    </div>
    <BaseSkeletor v-if="isFetching"/>
    <div class="proposal-detail__content">
      <section class="proposal-detail__info">
        <CollateralDetail
          v-if="parsedProposal && parsedProposal.collateral"
          class="proposal-detail__collateral-detail"
          has-internal-link
          :is-lending-proposal="parsedProposal?.isOffer"
          :proposal-type="parsedProposal.type as string"
          :collateral="{
            ...parsedProposal.collateral,
            category: parsedProposal.collateral?.category ?? null,
            tokenId: parsedProposal?.collateral?.tokenId ?? ''
          }"
          :amount="collateralAmount"/>
        <ProposalDetail
          v-if="parsedProposal"
          class="proposal-detail__proposal-detail"
          :apr="isPrevVersion ? parsedProposal.creditData.apr ?? null : parsedProposal.creditData.apr ?? null"
          :total-repayment="String(totalRepayment)"
          :credit-amount="creditAmount"
          :credit-asset="parsedProposal.creditAsset as AssetDetail"
          :collateral-asset="parsedProposal.collateral as AssetDetail"
          :ltv="Number(parsedProposal?.creditData?.ltv)"
          :duration="parsedProposal.duration"
          :type="parsedProposal.type"
          has-internal-links
          :is-offer="parsedProposal.isOffer"
          :proposer="parsedProposal.proposer"
          :chain-id="parsedProposal.chainId"
          :proposal-id="parsedProposal.id"
          :credit-amount-label="creditAmountLabel"/>
      </section>

      <!--    Form    -->
      <section class="proposal-detail__form">
        <Expiration
          v-if="proposalClass && parsedProposal"
          :expiration="proposalClass.expiration"
          :is-accepted="parsedProposal.status === -3 || (isFungibleProposalInvalid && parsedProposal.status !== -1)"
          :is-unacceptable="parsedProposal.status === -4"
          :revoked-at="revokedAt"/>
        <div
          v-else
          class="proposal-detail__expiration-empty"/>
        <ProposalActions
          v-if="isProposalAcceptable"
          :button-text="proposalActionButtonText"
          :proposal-id="proposalId"
          :is-loading="!proposalClass"
          is-proposal-page/>
        <div
          v-if="isConnectedProposer && proposalClass && !revokedAt && !isExpired && !isFungibleProposalInvalid"
          class="proposal-detail__cancel-and-edit">
          <ProposalForm
            v-if="isConnectedProposer && proposalClass && !isStarknet"
            class="proposal-detail__form-post-proposal"
            :has-tabs="false"
            :has-border="false"
            is-edit
            :former-collateral="proposalClass.isFungibleProposal ? selectedCollateralEdit : proposalClass.collateral"
            is-collateral-locked
            :is-fungible-proposal="proposalClass.isFungibleProposal"
            :selected-collateral="selectedCollateral"
            :selected-credit="selectedCredit"
            :select-collateral="selectCollateral"
            :select-credit="selectCredit"
            :is-active-tab-borrow="!proposalClass.isOffer"
            :nonce-to-revoke="proposalClass.nonce"
            :is-offer="proposalClass.isOffer"
            :is-open="isFormOpen">
            <template #trigger>
              <div class="proposal-detail__form-trigger">
                <BaseButton
                  button-text="Edit Proposal"
                  is-full-width
                  :color="ButtonColor.White"
                  has-transparent-background
                  :variant="ButtonVariant.Outline"
                  @on-button-click="handleToggleForm">
                  <template #iconBeforeText>
                    <PencilOnPaperIconSvg class="proposal-detail__edit-icon"/>
                  </template>
                  <template #icon-after-text>
                    <ArrowToggle
                      :class="['proposal-detail__toggle-arrow', { 'proposal-detail__toggle-arrow--open': isFormOpen }]"
                      alt="arrow"/>
                  </template>
                </BaseButton>
              </div>
            </template>
          </ProposalForm>
          <div class="proposal-detail__cancel-proposal">
            <CancelProposalModal
              v-if="isConnectedProposer && proposalClass"
              :proposal-class="proposalClass"
              has-trash-icon
              @on-cancel-proposal="isFormOpen = false"/>
          </div>
        </div>
        <div
          v-if="!isConnectedProposer && isProposalAcceptable"
          class="proposal-detail__for-or">
          Or
        </div>

        <!-- :is-offer="!proposalClass.isOffer" on Borrowing proposal Lender(not owner) want to make Lend proposal and vice versa-->
        <ProposalForm
          v-if="!isConnectedProposer && proposalClass && isProposalAcceptable && !isStarknet"
          class="proposal-detail__form-post-proposal"
          :has-tabs="false"
          :has-border="false"
          :is-redirect-to-proposal-page="false"
          :is-edit="isConnectedProposer"
          :former-collateral="proposalClass.collateral"
          is-collateral-locked
          :is-offer="!proposalClass.isOffer"
          :is-active-tab-borrow="proposalClass.isOffer"
          :selected-collateral="selectedCollateral"
          :selected-credit="selectedCredit"
          :select-collateral="selectCollateral"
          :select-credit="selectCredit"
          :is-open="isFormOpen">
          <template #trigger>
            <BaseButton
              button-text="Post a Counter Proposal"
              is-full-width
              :color="ButtonColor.White"
              has-transparent-background
              :variant="ButtonVariant.Outline"
              @on-button-click="handleToggleForm">
              <template #icon-after-text>
                <img
                  :class="['proposal-detail__toggle-arrow', { 'proposal-detail__toggle-arrow--open': isFormOpen }]"
                  src="@/assets/icons/arrow-select.svg?url"
                  alt="arrow">
              </template>
            </BaseButton>
          </template>
        </ProposalForm>
      </section>
    </div>

    <div :class="['proposal-detail__table', {'proposal-detail__table--edit-proposal': isFormOpen}]">
      <div class="proposal-detail__filters">
        <div class="proposal-detail__filters-left">
          <div class="proposal-detail__filters-row proposal-detail__proposals-title">
            <!-- note: - 1 is little hack to exclude the proposal that corresponds to the proposal detail itself -->
            Similar Proposals ({{ (injectedProposalsCount === null || injectedProposalsCount === 0 ? 1 : injectedProposalsCount) - 1 }})
          </div>
          <div class="proposal-detail__filters-row">
            <ProposalFilters
              v-model:show-history="showHistory"
              is-read-only
              :selected-collateral="parsedCollateral"/>
          </div>
        </div>
      </div>
      <ProposalsTable
        v-if="parsedProposal && parsedProposal?.collateral?.address && parsedProposal?.collateral?.chainId"
        type="all"
        :proposal-id-to-filter-out="String(proposalId)"
        hide-no-proposals-banner
        :selected-collateral-filter-assets="currentCollateral ? [currentCollateral] : undefined"
        :include-collateral-without-price="true"
        :include-credit-without-price="globalFiltersStore.showAssetsWithoutPricing"
        :include-unverified-collateral="true"
        :include-unverified-credit="globalFiltersStore.showUnverifiedAssets"
        :show-history="showHistory"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, provide, ref, watch } from 'vue'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import { compareAddresses } from '@/utils/utils'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import ProposalForm from '@/revamp/components/proposal-form/ProposalForm.vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import ProposalActions from '@/revamp/components/actions/ProposalActions.vue'
import CancelProposalModal from '@/revamp/components/proposal-form/CancelProposalModal.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import Expiration from '@/revamp/components/proposal-form/Expiration.vue'
import CollateralDetail from '@/revamp/components/CollateralDetail.vue'
import { useFetchAssetMetadata, useFetchNFTAssetCollectionMetadata2, useProposalAndLoanList, useProposalDetail } from '@/modules/common/backend/generated'
import { parseAndTransform, zProposalDetail } from '@/revamp/modules/proposals/types'
import type { AssetDetail } from '@/revamp/modules/proposals/types'
import ProposalDetail from '@/revamp/components/ProposalDetail.vue'
import ProposalsTable from '@/revamp/components/tables/ProposalsTable.vue'
import HeaderProposalOrLoan from '@/revamp/pages/proposal-or-loan/HeaderProposalOrLoan.vue'
import AssetType, { NFT_CATEGORIES } from '@/modules/common/assets/AssetType'
import { DateTime } from 'luxon'
import { formatUnits } from 'viem'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import DateUtils from '@/utils/DateUtils'
import ProposalFilters from '@/revamp/components/proposal-filters/ProposalFilters.vue'
import PencilOnPaperIconSvg from '@/assets/icons/pencil-on-paper.svg'
import { injectedProposalsCountKeyName, useInjectedProposalsCount } from '@/revamp/hooks/useInjectedProposalsCount'
import { AssetWithAmount, AssetMetadata } from '@/modules/common/assets/AssetClasses'
import * as Sentry from '@sentry/vue'
import {
  calculateCollateralAmountFungibleProposal,
  getLoanTokenAddressForProposalOrLoan,
  getProposalOrLoanType,
  ProposalOrLoanEnum,
} from '@/modules/common/pwn/utils'
import type { ProposalAndLoanTypes } from '@/modules/common/pwn/utils'
import router from '@/router'
import RouteName from '@/router/RouteName'
import { getChainName } from '@/utils/chain'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import type { ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import { useUserNfts } from '@/revamp/hooks/useUserNfts'
import { useUserTokens } from '@/revamp/hooks/useUserTokens'
import ModalShareProposals from '@/general-components/ModalShareProposals.vue'
import NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'
import { asyncComputed, useLocalStorage } from '@vueuse/core'
import ArrowToggle from '@/assets/icons/arrow-select.svg'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import SetNotificationsButton from '@/revamp/components/SetNotificationsButton.vue'

const route = useRoute()
const { address: userAddress } = useCustomAccount()
const isFormOpen = ref(false)
const showHistory = useLocalStorage('show-history-on-proposal-detail', false)
const showHistoryManuallySetUp = ref(false)
const alreadySeenProposals = useLocalStorage('already-seen-proposals', [])
const dontShowAgain = useLocalStorage('dont-show-notification-modal', false)

const globalFiltersStore = useGlobalFiltersStore()

const proposalId = computed(() => Number(route.params.id))
const proposalDetailQuery = useProposalDetail(proposalId, {
  query: {
    staleTime: 1000 * 60 * 5,
  },
})

const { injectedProposalsCount, setInjectedProposalsCount } = useInjectedProposalsCount()
provide(injectedProposalsCountKeyName, {
  injectedProposalsCount,
  setInjectedProposalsCount,
  setHasEmptyState: (v: boolean) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
})

watch(injectedProposalsCount, v => {
  if (!showHistoryManuallySetUp.value) {
    if (v === 0) {
      showHistory.value = true
      showHistoryManuallySetUp.value = true
    }
  }
})

const isFetching = computed(() => proposalDetailQuery.isFetching.value)

const proposalClass = asyncComputed(async () => {
  const v = proposalDetailQuery.data?.value?.data
  if (!v) {
    return null
  }
  return await ProposalFactory.createProposalFromBackendModel(v)
})

const selectedCollateral = ref<AssetWithAmount | null>(proposalClass.value?.collateral ?? null)
const selectedCredit = ref<AssetWithAmount | null>(null)
const selectCollateral = (asset: AssetWithAmount | AssetMetadata | Array<AssetWithAmount | AssetMetadata> | undefined) => {
  if (Array.isArray(asset)) {
    Sentry.captureMessage('Unexpectedly received asset arg in ProposalDetailPage.selectCollateral as array.')
  } else {
    selectedCollateral.value = asset ? new AssetWithAmount(asset) : null
  }
}

const selectCredit = (asset?: AssetWithAmount | AssetMetadata | undefined) => {
  selectedCredit.value = asset ? new AssetWithAmount(asset) : null
}

const title = computed(() =>
  proposalClass.value?.isOffer ? `Lending Proposal #${route.params.id}` : `Borrowing Proposal #${route.params.id}`,
)

const proposalActionButtonText = computed(() => {
  if (!proposalClass.value) return 'loading...'
  if (proposalClass.value?.isFungibleProposal) {
    return proposalClass.value?.isOffer ? 'Borrow up to' : 'Lend up to'
  }
  return proposalClass.value?.isOffer ? 'Borrow' : 'Lend'
})

const chainId = computed(() => String(proposalDetailQuery.data?.value?.data?.chainId))
const collateralAddress = computed(() => proposalDetailQuery.data?.value?.data?.collateral?.address as string)
const collateralTokenId = computed(() => proposalDetailQuery.data?.value?.data?.collateral?.tokenId ?? '')
const collateralCategory = computed(() => proposalDetailQuery.data?.value?.data?.collateral?.category)
const isOnlyCollection = computed(() => NFT_CATEGORIES.some(nftCategory => nftCategory === collateralCategory.value) && !collateralTokenId.value)

const isCollateralMetadataQueryEnabled = computed(() => {
  return Boolean(chainId.value && collateralAddress.value)
})

// needed for additional fetching of metadata for list proposals or v1.1 erc20
//  proposals (which are also list proposals)
const collateralMetadataResponse = useFetchAssetMetadata(
  chainId,
  collateralAddress,
  collateralTokenId,
  {},
  {
    query: {
      enabled: isCollateralMetadataQueryEnabled,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 2,
    },
  },
)

const isCollateralCollectionQueryEnabled = computed(() => isOnlyCollection.value && isCollateralMetadataQueryEnabled.value)

const collateralCollectionQuery = useFetchNFTAssetCollectionMetadata2(
  chainId,
  collateralAddress,
  {}, {
    query: {
      enabled: isCollateralCollectionQueryEnabled,
    },
  })

const parsedCollateral = computed(() => {
  if (isOnlyCollection.value) {
    const collection = collateralCollectionQuery.data?.value?.data && NFTAssetCollection.createFromBackendModel(collateralCollectionQuery.data.value.data)
    if (!collection) {
      return null
    }

    return new AssetMetadata({
      chainId: collection.chainId,
      address: collection.contractAddress,
      name: collection.name,
      image: collection.image,
      symbol: collection.symbol,
      collection,
      id: parseInt(collection.id) + Math.random() * -10000,
      category: collection.category,
    })
  } else {
    if (!collateralMetadataResponse.data?.value?.data) {
      return null
    }
    return AssetMetadata.createFromBackendModel(collateralMetadataResponse.data?.value?.data)
  }
})

const parsedProposal = computed(() => {
  if (!proposalDetailQuery.data.value?.data) {
    return null
  }
  const parsedPro = parseAndTransform(zProposalDetail, proposalDetailQuery.data.value?.data)
  // TODO rather remove and resolve missing decimals in the BE?
  const fallbackErc20DecimalsCollateral = parsedPro?.collateral?.decimals ?? parsedCollateral.value?.decimals ?? 18 // TODO do we need this 18?
  if (parsedPro.collateral) {
    parsedPro.collateral.decimals = parsedPro.collateral.category === AssetType.ERC20 ? fallbackErc20DecimalsCollateral : null
  }
  if (parsedPro.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
    parsedPro.collateralAmount = calculateCollateralAmountFungibleProposal({
      creditPerCollateralUnit: parsedPro.creditData?.creditPerCollateralUnit ?? '',
      collateralDecimals: parsedPro.collateral?.decimals ?? 0,
      availableCreditLimit: parsedPro.creditData?.amount ?? '',
      returnBigInt: true,
    }).toString()
  }
  return parsedPro
})

const isConnectedProposer = computed(() => {
  if (!proposalClass.value?.proposer || !userAddress.value) return false
  return compareAddresses(proposalClass.value?.proposer, userAddress.value)
})

const isNotificationModalShown = computed(() => {
  return isConnectedProposer.value && !alreadySeenProposals.value.find((id: number) => id === proposalId.value) && !dontShowAgain.value
})

const isFungibleProposalInvalid = computed(() => {
  if (proposalClass.value?.isFungibleProposal) {
    return proposalClass.value?.collateralAmount === 0n
  }
  return false
})

const creditAmountLabel = computed(() => {
  if (parsedProposal.value?.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
    return parsedProposal.value?.isOffer ? 'Wants to Lend up to' : 'Wants to Borrow up to'
  }
  return parsedProposal.value?.isOffer ? 'Wants to Lend' : 'Wants to Borrow'
})

const creditAmount = computed(() => {
  if (isFungibleProposalInvalid.value) {
    return proposalClass.value?.availableCreditLimit?.toString() ?? ''
  }
  return proposalClass.value?.creditAmount?.toString() ?? ''
})

const collateralAmount = computed(() => {
  if (isFungibleProposalInvalid.value) {
    return calculateCollateralAmountFungibleProposal({
      creditPerCollateralUnit: parsedProposal.value?.creditData?.creditPerCollateralUnit ?? '',
      collateralDecimals: parsedProposal.value?.collateral?.decimals ?? 0,
      availableCreditLimit: parsedProposal.value?.availableCreditLimit ?? '',
      returnBigInt: true,
    }).toString()
  }
  if (parsedProposal.value?.collateral?.category === AssetType.ERC721) {
    return 1
  }
  return proposalClass.value?.collateralAmount.toString() ?? ''
})

const handleToggleForm = () => {
  isFormOpen.value = !isFormOpen.value
}

const revokedAt = computed(() => {
  if (!parsedProposal.value?.revokedAt) return null
  return new Date(parsedProposal.value.revokedAt * 1000)
})

const isExpired = computed(() => {
  if (!parsedProposal.value?.expiration) return null

  const expirationTime = DateTime.fromJSDate(new Date(parsedProposal.value.expiration * 1000))
  return expirationTime.diffNow().as('milliseconds') < 0
})

const isPrevVersion = computed(() => {
  if (!parsedProposal.value?.type) return false
  return getProposalOrLoanType(parsedProposal.value.type) === ProposalOrLoanEnum.V1_1 ||
    getProposalOrLoanType(parsedProposal.value.type) === ProposalOrLoanEnum.BETA
})

const totalRepayment = computed(() => {
  if (isPrevVersion.value) {
    return formatUnits(BigInt(parsedProposal.value?.creditData.totalRepaymentAmount ?? 0), parsedProposal.value?.creditAsset?.decimals ?? 18)
  }

  const aprPaddingWith100Percent = 10 ** APR_DECIMAL_POINT_PADDING * 100
  if (!parsedProposal.value?.creditData?.accruingInterestApr) return null
  const dailyInterest = parsedProposal.value?.creditData?.accruingInterestApr / aprPaddingWith100Percent / 365
  const creditAmount = parsedProposal.value?.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL ? parsedProposal.value?.availableCreditLimit : (parsedProposal.value?.creditData?.amount ?? '0')
  const creditAmountFormated = formatUnits(BigInt(creditAmount), parsedProposal.value?.creditAsset?.decimals ?? 18)
  const durationInDays = DateUtils.convertSecondsToDays(parsedProposal.value?.duration)
  return (+creditAmountFormated * dailyInterest * durationInDays) + +creditAmountFormated
})

const collateralId = computed(() => {
  // negative sign is used to represent that it's client side generated
  // @ts-expect-error: remove when proposal credit asset will have numeric asset id
  return parsedProposal.value?.collateral?.id || (Math.random() ** -10 * 3)
})

const currentCollateral = computed(() => {
  if (!parsedProposal.value?.collateral) return null
  const v = parsedProposal.value?.collateral as AssetDetail
  // @ts-expect-error: types missmatch
  return new AssetWithAmount({
    ...v,
    id: collateralId.value,
    image: v.thumbnailUrl || undefined,
  })
})

const loanData = useProposalAndLoanList({
  statuses: [0, 1, 2, 3, 4],
  createdFromProposalId: proposalId.value.toString(),
  includeLoans: true,
}, {
  query: {
    enabled: true,
  },
})

const loanResponse = computed(() => {
  return loanData?.data?.value?.data
})
watch(() => loanResponse.value, async () => {
  if (proposalClass.value?.proposalType === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) return
  const createdLoan = loanResponse.value?.results && loanResponse.value?.results?.length > 0 ? loanResponse.value?.results[0] : null
  if (!proposalClass.value) return

  // TODO how with fungible proposal from which can be created multiple loans?
  if (createdLoan) {
    await router.push({
      name: RouteName.RevampLoanDetail,
      params: {
        chainName: getChainName(proposalClass.value.chainId).toLowerCase(),
        loanTokenContractAddress: getLoanTokenAddressForProposalOrLoan(proposalClass.value.chainId, proposalClass.value.type as ProposalType),
        id: createdLoan.onChainId,
      },
    })
  }
}, { immediate: true })

const { nfts } = useUserNfts(userAddress, proposalClass.value?.chainId)
const { tokens } = useUserTokens(userAddress, proposalClass.value?.chainId)

watch([proposalClass, tokens], ([newProposalClass, newTokens]) => {
  if ((newProposalClass && !newProposalClass.isOffer && isConnectedProposer.value) ||
      (newProposalClass && newProposalClass.isOffer && !isConnectedProposer.value)) {
    let userAsset = tokens.value.find(token => token.address === newProposalClass.collateral.address)
    if (!userAsset) {
      userAsset = nfts.value.find(nft => nft.address === newProposalClass.collateral.address)
    }
    selectedCollateral.value = userAsset || null
  } else if ((newProposalClass && newProposalClass.isOffer && isConnectedProposer.value) ||
    (newProposalClass && !newProposalClass.isOffer && !isConnectedProposer.value)) {
    // todo: symbol is missing in the proposal class
    selectedCollateral.value = newProposalClass.collateral
  }
}, { immediate: true })

const selectedCollateralEdit = computed(() => {
  if (proposalClass.value?.isFungibleProposal) {
    return new AssetWithAmount({
      ...selectedCollateral.value,
      amount: '1',
    })
  }
  return selectedCollateral.value
})

const isProposalAcceptable = computed(() => {
  if (!revokedAt.value && !isExpired.value && !isFungibleProposalInvalid.value) {
    return true
  }
  return false
})

</script>

<style scoped>
.proposal-detail {
  margin-top: 2rem;
  position: relative;

  @media only screen and (--small-viewport) {
    margin-top: 2rem;
  }

  @media only screen and (--mobile-viewport) {
    margin-top: 0;
  }

  &__proposal-actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__filters {
    display: flex;
    justify-content: space-between;

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;

    border: 1px solid #434343;
    padding: 1.5rem;

    @media only screen and (--small-viewport) {
      flex-flow: row nowrap;
      margin-bottom: 0;
      gap: 0.5rem;
    }

    @media only screen and (--mobile-viewport) {
      flex-flow: column nowrap;
      gap: 0.5rem;
    }
  }

  &__collateral-detail {
    padding: 0 2.5rem 0 0;
    width: 100%;

    &:first-child {
      border-right: 1px solid #434343;

      @media only screen and (--mobile-viewport) {
        flex: 1 0;

        border-right: none;
        border-bottom: 1px solid #434343;
      }
    }

    @media only screen and (--mobile-viewport) {
      padding: 0;
    }
  }

  &__proposal-detail {
    padding: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    align-items: flex-start;

    @media only screen and (--small-viewport) {
      flex-flow: column nowrap;
    }
  }

  &__filters-left {
    width: 100%;
  }

  &__form {
    width: 413px;
    border: 1px solid #434343;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 1rem;
    margin-bottom: 3rem;
    height: fit-content;
    margin-left: 1.5rem;

    @media only screen and (--small-viewport) {
      margin-left: 0;

      max-width: 413px;
      margin-top: 1rem;
    }

    @media only screen and (--mobile-viewport) {
      max-width: 100%;
    }
  }

  &__form-post-proposal {
    min-width: fit-content;
  }

  &__for-or {
    text-align: center;
  }

  &__toggle-arrow {
    margin-left: 0.5rem;
  }

  &__toggle-arrow--open {
    transform: rotate(180deg);
  }

  &__json-info {
    max-width: 49rem;
    overflow: auto;
    word-break: break-all;
  }

  &__table {
    position: absolute;
    top: 30rem;
    width: 100%;
    padding: 1rem;
    border: 1px solid #434343;

    @media only screen and (--small-viewport) {
      position: static;
    }

    &--edit-proposal {
      width: calc(100% - 27.3rem);
    }
  }

  &__cancel-and-edit {
    display: flex;
    position: relative;

    @media only screen and (--mobile-viewport) {
      margin: auto;
    }
  }

  &__cancel-proposal {
    position: absolute;
    width: 11.5rem;
    right: 0;
  }

  &__form-trigger {
    width: 11.5rem;
  }

  &__edit-icon {
    width: 1.5rem;
    height: 1.5rem;

    margin-right: 0.25rem;
  }

  &__proposals-title {
    font-size: 1.125rem;
    display: flex;
    justify-content: space-between;

    @media only screen and (--small-viewport) {
      flex-flow: column nowrap;
      gap: 0.5rem;
    }
  }

  &__expiration-empty {
    height: 1.1875rem;
  }
}
</style>

<style>
.proposal-detail {
  button {
    font-family: var(--font-family-supreme);
  }

  .button__text {
    font-family: var(--font-family-supreme);
  }

  &__edit-icon {
    path {
      fill: var(--text-color);
    }
  }
}
</style>
