<template>
  <BaseTable
    v-model:active-sort-option="selectedSortOption"
    :columns="TABLE_DEFINITION"
    :items="sortedBundles"
    :has-first-column-padding="false"
    :variant="TableVariant.Assets"
    :is-fetching="userNftsIsPending"
    table-max-height="420px"
    class="bundle-assets-table"
    @on-row-click="selectBundle">
    <template #radio="{ item: token }">
      <BaseInputRadio
        class="bundle-assets-table__radio"
        :input-value="`${token?.address}-${token?.tokenId}`"
        :model-value="`${selectedBundle?.address}-${selectedBundle?.tokenId}`"/>
    </template>

    <template #asset-info="{ item: token }">
      <AssetInfo
        :asset="token"
        :show-verification-badge="true"
        badge-size="small"
        :show-link="false"/>
    </template>

    <template #verification="{ item: token }">
      <VerificationBadge
        :token="token"
        is-in-table-column/>
    </template>

    <template #explorer="{ item: token }">
      <BaseLink
        class="link link--primary link--sm bundle-assets-table__link-to-asset-page "
        :to="getAssetPageRoute(token)">
        Asset page
      </BaseLink>
    </template>

    <template #amount="{ item: token }">
      <div class="bundle-assets-table__tooltip-asset-trigger">
        <BaseTooltip
          is-interactive
          append-to-body
          placement="top">
          <template #trigger>
            <span>
              {{ token.bundleAssets.length }}
            </span>
          </template>
          <div class="bundle-assets-table__tooltip-assets-container">
            <BaseLink
              v-for="asset in token.bundleAssets"
              :key="asset.id"
              :class="['bundle-assets-table__tooltip-asset', {'bundle-assets-table__tooltip-asset--link-to-asset-page': asset.isNft}]"
              :to="getAssetPageRoute(asset)">
              <TokenMedia
                width="32"
                height="32"
                :token="asset"/>
              <SafeText
                :text=" asset.name"
                :allow-click-through="false"/>
            </BaseLink>
          </div>
        </BaseTooltip>
      </div>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">
import BaseTable from '@/general-components/BaseTable.vue'
import { TABLE_DEFINITION } from '@/modules/pages/token-bundler/unpack-bundle/section-choose-bundle/ChooseBundleTableDefinitions'
import VerificationBadge from '@/general-components/VerificationBadge.vue'
import AssetInfo from '@/general-components/AssetInfo.vue'
import TableVariant from '@/general-components/TableVariant'
import useChooseBundle from '@/modules/pages/token-bundler/unpack-bundle/section-choose-bundle/useChooseBundle'
import BaseInputRadio from '@/general-components/BaseInputRadio.vue'
import useUnpackBundle from '@/modules/pages/token-bundler/unpack-bundle/useUnpackBundle'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import SafeText from '@/general-components/SafeText.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import BaseLink from '@/general-components/BaseLink.vue'
import { storeToRefs } from 'pinia'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'

const { selectedSortOption, sortedBundles } = useChooseBundle()
const { selectedBundle, selectBundle } = useUnpackBundle()
const { getAssetPageRoute } = useAssetPage()
const userAssetsStore = useUserAssetsStore()
const { userNftsIsPending } = storeToRefs(userAssetsStore)
</script>

<style scoped>
.bundle-assets-table {
  min-height: 200px;
  overflow-x: auto;

  &__radio {
    margin: 0 auto;
  }

  &__collection-name {
    font-size: 0.75rem;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--missing {
      color: var(--negative-1);
    }
  }

  &__logo {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
  }

  &__tooltip-assets-container {
    max-height: 18rem;
    overflow-y: auto;
    margin: -0.75rem;
    padding: 0.75rem;
  }

  &__tooltip-asset {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
    cursor: default;

    &--link-to-asset-page {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__tooltip-asset-trigger {
    position: relative;
    text-decoration: underline;
    display: flex;
  }

  &__tooltip-asset-image {
    min-width: 2rem;
    object-fit: contain;
  }

  &__link-to-asset-page {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
