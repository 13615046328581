<template>
  <RevampBaseModal
    v-model:is-open="isOpen"
    :heading="headerText || 'Select Borrowed Asset'"
    class-name="select-credit-asset"
    heading-align="left">
    <template #trigger>
      <button
        class="select-credit-asset__token-select"
        type="button"
        @click="isOpen = true">
        <span v-if="!isAssetSelected"> Select Asset </span>
        <div
          v-else-if="selectedToken"
          class="select-credit-asset__selected-asset-container">
          <div class="select-credit-asset__selected-asset">
            <TokenMedia
              :token="selectedToken"
              height="20"
              width="20"/>
            <SafeText :text="selectedToken.symbol"/>
          </div>
        </div>
        <ArrowSelectSvg/>
      </button>
    </template>
    <template #body>
      <div class="select-credit-asset__search">
        <SearchBar
          v-model="searchBar"
          class="select-credit-asset__search-bar"
          placeholder="Select asset name or address"/>
      </div>

      <div class="select-credit-asset__filters-row">
        <ChainGroupFilter
          :selected-chains="[Number(selectedChain)]"
          :disabled-chains="disabledChains"
          :set-chain="handleSetSelectedChain"/>
        <div class="select-credit-asset__currency-switcher">
          <CurrencySwitcher/>
        </div>
      </div>

      <div class="select-credit-asset__content">
        <TokensTable
          :q="throttledSearchBar"
          :chain-id="props.chainId || selectedChain"
          :existing-tokens="hideYouOwnColumn ? undefined : tableData"
          @select-asset="handleSelectedAsset"/>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import SafeText from '@/general-components/SafeText.vue'
import SearchBar from '@/revamp/components/SearchBar.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import CurrencySwitcher from '@/layout/header/CurrencySwitcher.vue'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import { computed, ref, watch, watchEffect } from 'vue'
import type { VNode } from 'vue'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useThrottle } from '@vueuse/core'
import { SupportedChain } from '@/constants/chains/types'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import TokensTable from '@/revamp/components/modals/select-their-collateral/TokensTable.vue'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useUserTokens } from '@/revamp/hooks/useUserTokens'
import ArrowSelectSvg from '@/assets/icons/arrow-select.svg'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

interface Props {
  selectedToken?: AssetMetadata;
  chainId?: SupportedChain;
  isEdit?: boolean;
  headerText: string;
  isModalOpen?: boolean;
  bundleImage?: VNode;
  hideYouOwnColumn?: boolean;
  isFungibleProposal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{(e: 'select-asset', asset: AssetMetadata) }>()
const { chainId: connectedChainId, address } = useCustomAccount()
const globalFiltersStore = useGlobalFiltersStore()

const isOpen = ref(false)
const searchBar = ref('')
const selectedChain = ref<SupportedChain>(connectedChainId.value || SupportedChain.Ethereum)

const throttledSearchBar = useThrottle(searchBar, 300)
const { tokens } = useUserTokens(address, selectedChain)

const applyGlobalFilters = (assets: AssetWithAmount[]) => {
  const withAppliedSmallBalanceFilter = globalFiltersStore.applyShowAssetsWithLesserAmountFilter(assets)
  const withAppliedFilter = globalFiltersStore.applyShowUnverifiedAssetsFilter(withAppliedSmallBalanceFilter)

  // const totalRemoved = assets.length - withAppliedFilter.length
  // emit('total-removed-by-filter', totalRemoved)
  return withAppliedFilter
}
watch(
  () => props.isModalOpen,
  (newVal, oldVal) => {
    isOpen.value = newVal
  },
)

watchEffect(() => {
  if (!props.chainId && connectedChainId.value) {
    selectedChain.value = connectedChainId.value
    return
  }

  if (props.chainId) {
    selectedChain.value = props.chainId
  }
})

const handleSelectedAsset = (asset?: AssetMetadata) => {
  if (!asset) return
  emit('select-asset', asset)
  isOpen.value = false
}

const handleSetSelectedChain = (chain: SetChainFilterOption) => {
  if (!props.chainId) {
    if (chain !== 'all') {
      selectedChain.value = chain
    }
  }
}

const tableData = computed(() => {
  return applyGlobalFilters(tokens.value)
})

const isAssetSelected = computed(() => !!props.selectedToken?.address)
const disabledChains = computed(() =>
  enabledChains.filter((chain) => (!props.chainId ? false : chain !== props.chainId)),
)
</script>

<style scoped>
.select-credit-asset {
  &__tabs {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: space-between;

    width: 100%;
  }

  &__tab {
    border: 1px solid var(--text-color);
  }

  &__content {
    width: 100%;
  }

  &__search {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  &__search-bar {
    width: 100%;
  }

  &__token-select {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--background-color);
    height: 28px;
    width: 136px;
    font-size: 1rem;
    border: 1px solid var(--text-color);
    color: var(--text-color);

    user-select: none;

    &:hover {
      border: 1px solid var(--text-color);
    }
  }

  &__selected-asset {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 10.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__selected-asset-container {
    display: contents;
  }

  &__filters-row {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
</style>

<style>
.select-credit-asset__currency-switcher .multiselect__tags {
  padding: 0.5rem;
}

.select-credit-asset__currency-switcher .multiselect {
  height: auto;
  min-height: auto;
}

.select-credit-asset__currency-switcher .multiselect__single {
  margin-bottom: 0;
  display: flex;
}

.select-credit-asset {
  .modal__body {
    min-width: 944px;

    @media only screen and (--small-viewport) {
      min-width: 100%;
    }
  }
}
</style>

<style>
.select-credit-asset {
  .multiselect__tags {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .multiselect__single {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
