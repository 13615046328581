import type { Address } from 'viem'
import { formatUnits } from 'viem'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import { SupportedChain } from '@/constants/chains/types'
import to from '@/utils/await-to-js'
import {
  compoundCometABI, compoundCometGetSupplyRate, compoundCometGetUtilization,
  erc20BalanceOf,
  getReserveData,
  getUserAccountData,
  getUserReservesData,
} from '@/contracts/abis'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { multicall } from 'viem/actions'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { globalConstants } from '@/constants/globals'

const RAY_DECIMALS = 25
const HEALTH_FACTOR_DECIMALS = 18
const SUPPORTED_CHAINS = [SupportedChain.Arbitrum, SupportedChain.Polygon, SupportedChain.Base, SupportedChain.Bsc, SupportedChain.Ethereum, SupportedChain.Optimism]
const SUPPORTED_CHAINS_COMPOUND = [SupportedChain.Arbitrum, SupportedChain.Polygon, SupportedChain.Base, SupportedChain.Optimism, SupportedChain.Ethereum]
if (globalConstants.environment === 'development') {
  SUPPORTED_CHAINS.push(SupportedChain.Sepolia)
  SUPPORTED_CHAINS_COMPOUND.push(SupportedChain.Sepolia)
}
type UserAccountDataResponse = [
  bigint,
  bigint,
  bigint,
  bigint,
  bigint,
  bigint, // health factor
];

type UserReserveData = {
  principalStableDebt: bigint
  scaledATokenBalance: bigint
  scaledVariableDebt: bigint
  stableBorrowLastUpdateTimestamp: bigint
  stableBorrowRate: bigint
  underlyingAsset: Address
  usageAsCollateralEnabledOnUser: boolean
};
type HealthFactor = Record<SupportedChain, string>

export type AssetReserveData = {
  currentLiquidityRate: bigint
  aTokenAddress: Address
}

type AssetToCreate = {
  underlyingAsset: Address
  aTokenAddress: Address
  protocol: DepositProtocol
  sourceOfFunds: Address
  chainId: SupportedChain
  amount?: bigint
  apy?: string
  healthFactor?: string
}

type ContractCall = {
  address: Address,
  abi: never,
  functionName: string,
  args?: never,
  chainId?: SupportedChain
}

export enum DepositProtocol {
  AAVE = 'aave',
  COMPOUND = 'compound',
  MORPHO = 'morpho',
}

export default function useDepositedAssets() {
  const fetchAssetsMetadata = async (assetsToCreate: AssetToCreate[]): Promise<AssetMetadata[]> => {
    const fetchMetadata = useMetadataFetch()
    const metadataPromises = assetsToCreate.map(async (asset) => {
      if (!asset.underlyingAsset) {
        return null
      }
      return fetchMetadata.fetchErc20Metadata({
        chainId: asset.chainId,
        contractAddress: asset.underlyingAsset,
      })
    })

    const metadataResults = await Promise.all(metadataPromises)
    return metadataResults.filter((asset): asset is AssetMetadata => asset !== null)
  }

  // todo: refactor using setQueryData
  const fetchCompoundPositions = async (userAddress: Address | undefined) => {
    if (!userAddress) return []

    const compoundPositions: AssetWithAmount[] = []
    const baseTokenContractsToCall: Array<ContractCall> = []
    const balanceContractsToCall: Array<ContractCall> = []
    for (const chainId of SUPPORTED_CHAINS_COMPOUND) {
      for (const poolAddress of CHAINS_CONSTANTS[String(chainId)].compound.pools) {
        const baseTokenContract = {
          address: poolAddress as Address,
          abi: compoundCometABI,
          chainId,
          functionName: 'baseToken',
        } as ContractCall

        const balanceContract = {
          address: poolAddress as Address,
          abi: compoundCometABI,
          chainId,
          functionName: 'balanceOf',
          args: [userAddress],
        } as unknown as ContractCall
        balanceContractsToCall.push(balanceContract)
        baseTokenContractsToCall.push(baseTokenContract)
      }

      const [, baseTokenRes] = await to(multicall(pwnWagmiConfig.getClient({ chainId }), {
        contracts: baseTokenContractsToCall,
      }))

      const [, balanceRes] = await to(multicall(pwnWagmiConfig.getClient({ chainId }), {
        contracts: balanceContractsToCall,
      }))

      const assetsToCreate = baseTokenRes?.map(async (val, i) => {
        if (balanceRes && balanceRes[i] && balanceRes[i].result !== 0n) {
          const secondsPerYear = 60 * 60 * 24 * 365
          const utilization = await compoundCometGetUtilization(pwnWagmiConfig, {
            address: baseTokenContractsToCall[i].address,
            chainId: baseTokenContractsToCall[i].chainId,
          })
          const supplyRate = await compoundCometGetSupplyRate(pwnWagmiConfig, {
            address: baseTokenContractsToCall[i].address,
            chainId: baseTokenContractsToCall[i].chainId,
            args: [utilization],
          }) as number
          // calculation from: https://github.com/compound-developers/compound-3-developer-faq/blob/master/examples/get-apr-example.js
          const supplyApr = +(supplyRate).toString() / 1e18 * secondsPerYear * 100

          return {
            underlyingAsset: val.result as unknown as Address,
            amount: balanceRes[i].result as unknown as bigint,
            protocol: DepositProtocol.COMPOUND,
            sourceOfFunds: baseTokenContractsToCall[i].address,
            aTokenAddress: baseTokenContractsToCall[i].address,
            apy: supplyApr.toFixed(2),
            chainId,
          }
        } else return undefined
      })
      // @ts-expect-error FIXME: No overload matches this call.
      const resolvedAssetsToCreate = await Promise.all(assetsToCreate)

      const filteredAssets = resolvedAssetsToCreate?.filter((asset) => asset !== undefined) as AssetToCreate[] || []

      const [, assetsMetadata] = await to(fetchAssetsMetadata(filteredAssets))
      if (!assetsMetadata) continue
      const assetsWithAmount = assetsMetadata.map((asset) => {
        const depositTokenInfo = filteredAssets.find((assetToCreate) => assetToCreate.underlyingAsset === asset?.address)
        return new AssetWithAmount({
          ...asset,
          amount: String(formatUnits(depositTokenInfo?.amount as bigint, asset?.decimals)),
          apy: depositTokenInfo?.apy,
          protocol: depositTokenInfo?.protocol,
          sourceOfFunds: depositTokenInfo?.sourceOfFunds,
          aTokenAddress: depositTokenInfo?.aTokenAddress,
        })
      })
      compoundPositions.push(...assetsWithAmount)
    }
    return compoundPositions
  }

  const fetchAavePositions = async (userAddress: Address | undefined) => {
    if (!userAddress) {
      return []
    }
    const assetsToCreate: AssetToCreate[] = []
    for (const chainId of SUPPORTED_CHAINS) {
      const uiPoolData: UserReserveData[] = await getUserReservesData(pwnWagmiConfig, {
        address: CHAINS_CONSTANTS[String(chainId)].aave.uiPoolDataProvider,
        chainId,
        args: [CHAINS_CONSTANTS[String(chainId)].aave.poolAddressesProvider, userAddress],
      }) as UserReserveData[]
      const filteredData = uiPoolData.filter(item => item.scaledATokenBalance !== 0n)

      // fetching aToken addresses for underlying assets and its current balance
      // todo: in future only assign balance that is ok to spend considering health factor?
      for (const underlyingAsset of filteredData) {
        const reserveData = await getReserveData(pwnWagmiConfig, {
          address: CHAINS_CONSTANTS[String(chainId)].aave.pool,
          chainId,
          args: [underlyingAsset.underlyingAsset],
        }) as AssetReserveData

        const tokenBalanceOf = await erc20BalanceOf(pwnWagmiConfig, {
          address: reserveData.aTokenAddress,
          chainId,
          args: [userAddress],
        })

        assetsToCreate.push({
          underlyingAsset: underlyingAsset.underlyingAsset,
          aTokenAddress: reserveData.aTokenAddress,
          protocol: DepositProtocol.AAVE,
          sourceOfFunds: CHAINS_CONSTANTS[String(chainId)].aave.pool,
          chainId: chainId as SupportedChain,
          amount: tokenBalanceOf,
          apy: Number(formatUnits(reserveData.currentLiquidityRate, RAY_DECIMALS)).toFixed(2), // todo: check how correct is this (it differes from Aave UI by like 0.05%)
        })
      }
    }

    const healthFactors: HealthFactor = {} as HealthFactor
    for (const chainId of SUPPORTED_CHAINS) {
      const chainUserData = await getUserAccountData(pwnWagmiConfig, {
        address: CHAINS_CONSTANTS[String(chainId)].aave.pool,
        chainId,
        args: [userAddress],
      }) as UserAccountDataResponse
      healthFactors[chainId] = Number(formatUnits(chainUserData[5], HEALTH_FACTOR_DECIMALS)).toFixed(2)
    }

    if (assetsToCreate.length === 0) {
      return []
    }
    const [, assetsMetadata] = await to(fetchAssetsMetadata(assetsToCreate))
    if (!assetsMetadata) return []

    const assetsWithAmount: AssetWithAmount[] = assetsMetadata.map((asset) => {
      const depositTokenInfo = assetsToCreate.find((assetToCreate) => assetToCreate.underlyingAsset === asset?.address)
      return new AssetWithAmount({
        ...asset,
        amount: String(formatUnits(depositTokenInfo?.amount as bigint, asset?.decimals)),
        apy: depositTokenInfo?.apy,
        protocol: depositTokenInfo?.protocol,
        sourceOfFunds: depositTokenInfo?.sourceOfFunds,
        aTokenAddress: depositTokenInfo?.aTokenAddress,
        healthFactor: depositTokenInfo?.chainId ? healthFactors[depositTokenInfo.chainId] : undefined,
      })
    })

    return assetsWithAmount
  }
  const fetchAllPositions = async (userAddress: Address | undefined) => {
    const [aavePositions, compoundPositions] = await Promise.all([fetchAavePositions(userAddress), fetchCompoundPositions(userAddress)])
    return [...aavePositions, ...compoundPositions]
  }
  return {
    fetchAllPositions,
  }
}
