<template>
  <BaseSection
    class="section-your-assets"
    heading="">
    <BaseCard
      v-if="hasEmptyState && !searchTerm && (showVerifiedAssetsOnly || userAddress === dashboardUserAddress)"
      class="section-your-assets__card"
      heading="No assets"
      :subheading="getEmptyStateSubheading()">
      <BaseButton
        v-if="showVerifiedAssetsOnly"
        button-text="Show unverified"
        @on-button-click="showUnverifiedAssets = !showUnverifiedAssets"/>
    </BaseCard>
    <template v-else-if="!hasEmptyState || (userAddress !== dashboardUserAddress)">
      <SectionYourCoins
        v-if="(searchTerm && !hasEmptyCoins) || !searchTerm"
        class="section-your-assets__coins"
        is-dashboard
        :is-expandable="false"
        :assets="tokens"
        :is-asset-disabled="isErc20Disabled"
        :amount-of-coins="tokens.length"
        :has-first-column-padding="true"
        :hidden-assets="filteredTokens"
        :table-definition="COINS_TABLE_DEFINITION"
        @on-asset-select="createLoanWithSelectedAsset"/>
      <SectionYourNFTs
        v-if="(searchTerm && !hasEmptyNFTs) || !searchTerm"
        class="section-your-assets__nfts"
        is-dashboard
        :is-expandable="false"
        :amount-of-nfts="nfts.length"
        :assets="nfts"
        :has-first-column-padding="true"
        :hidden-assets="filteredNfts"
        :table-definition="NFTS_TABLE_DEFINITION"
        @on-asset-select="createLoanWithSelectedAsset"/>
      <SectionDepositedAssets
        class="section-your-assets__coins"
        is-dashboard
        :assets="filteredPositions"
        :is-loading="props.isLoadingPositions"
        :error="props.errorPositions"
        has-first-column-padding
        :table-definition="DEPOSITED_ASSETS_TABLE_DEFINITION"/>
    </template>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import SectionYourCoins from '@/modules/sections/your-assets/your-coins/SectionYourCoins.vue'
import SectionYourNFTs from '@/modules/sections/your-assets/your-nfts/SectionYourNFTs.vue'
import { TABLE_DEFINITION as NFTS_TABLE_DEFINITION } from '@/modules/sections/your-assets/your-nfts/YourNFTsDefinitions'
import { TABLE_DEFINITION as COINS_TABLE_DEFINITION } from '@/modules/sections/your-assets/your-coins/YourCoinsDefinitions'
import { TABLE_DEFINITION as DEPOSITED_ASSETS_TABLE_DEFINITION } from '@/modules/sections/your-assets/your-deposited-assets/DepositedAssetsDefinitions'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { computed, toRefs } from 'vue'
import BaseCard from '@/general-components/BaseCard.vue'
import BaseButton from '@/general-components/BaseButton.vue'
import { storeToRefs } from 'pinia'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useSelectedAssetsStore } from '@/revamp/hooks/useSelectedAssetsStore'
import SectionDepositedAssets from '@/modules/sections/your-assets/your-deposited-assets/SectionDepositedAssets.vue'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

type Props = {
  nfts: AssetWithAmount[]
  tokens: AssetWithAmount[]
  positions: AssetWithAmount[] | undefined
  isLoadingPositions: boolean
  errorPositions: Error | null

  filteredNfts: number
  filteredTokens: number
}

const props = defineProps<Props>()
const { nfts, tokens, positions, filteredTokens, filteredNfts } = toRefs(props)

const userAssetsStore = useUserAssetsStore()
const { userNftsIsPending, userErc20sIsPending } = storeToRefs(userAssetsStore)

const selectedChainStore = useSelectedChainsStore()
const { selectedValues } = storeToRefs(selectedChainStore)
const filteredPositions = computed(() => {
  if (!positions.value || !positions.value?.length) { return undefined }
  return positions.value?.filter((asset) => selectedValues.value?.includes(asset.chainId))
})

const { dashboardUserAddress, searchTerm } = useDashboard()
const { address: userAddress } = useCustomAccount()

const globalFiltersStore = useGlobalFiltersStore()
const { showVerifiedAssetsOnly, showUnverifiedAssets } = storeToRefs(globalFiltersStore)

const hasEmptyNFTs = computed(() => nfts.value.length === 0 && !userNftsIsPending.value)
const hasEmptyCoins = computed(() => tokens.value.length === 0 && !userErc20sIsPending.value)
const hasEmptyState = computed(() => hasEmptyNFTs.value && hasEmptyCoins.value)

const selectedCollateralStore = useSelectedAssetsStore()

const createLoanWithSelectedAsset = (asset: AssetWithAmount) => {
  selectedCollateralStore.actions.navigateWithSelected(asset)
}

const getEmptyStateSubheading = (): string => {
  if (showVerifiedAssetsOnly.value) {
    return 'this wallet has no verified assets'
  } else {
    return 'this wallet contains no assets'
  }
}

const isErc20Disabled = (asset: AssetWithAmount) => {
  return !asset.isValid
}

</script>

<style scoped>
.section-your-assets {
  margin-bottom: 3rem;

  &__card {
    width: 521px;
    height: 242px;
    margin: 0 auto;
  }

  &__nfts,
  &__coins,
  &__card {
    margin-bottom: 2rem;
  }

  @media only screen and (--small-viewport) {
    &__coins {
      margin-bottom: 4rem;
    }

    &__card {
      width: 100%;
      height: 100%;

      &__subheading {
        color: red;
      }
    }
  }
}
</style>
