<template>
  <DataSource
    :data-source="props.dataSource"
    :max-tooltip-width="380"
    :link="props.link"
    :logo-width-px="dataSourceIconWidthPx"
    :has-tooltip="true">
    <template
      v-if="usedDataSource"
      #tooltipText>
      {{ usedDataSource.text }}
      <BaseLink
        v-if="linkToSource"
        :to="linkToSource"
        target="_blank"
        class="link link--primary link--font-oxygen-mono">
        {{ usedDataSource.linkText }}
      </BaseLink>
    </template>
  </DataSource>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import DataSource from '@/general-components/data-source/DataSource.vue'
import { DataSourceType } from '@/general-components/data-source/DataSourceType'
import type { ValuationSource } from '@/general-components/data-source/DataSourceType'
import BaseLink from '@/general-components/BaseLink.vue'
import type { ValuationSourceDetail } from '@/general-components/data-source/AllValuationSources'
import allValuationSources from '@/general-components/data-source/AllValuationSources'

interface Props {
  dataSource: ValuationSource
  dataSourceIconWidthPx?: string
  openseaOverride?: string
  link?: string | undefined
}
const props = defineProps<Props>()

const usedDataSource = computed<ValuationSourceDetail | undefined>(() => {
  if (!props.dataSource) {
    return undefined
  }

  return allValuationSources[props.dataSource]
})

const linkToSource = computed(() => {
  if (props.dataSource === DataSourceType.OPENSEA && props.openseaOverride) {
    return props.openseaOverride
  }
  return usedDataSource.value?.link
})
</script>

<style scoped>
.valuation-icon__link {
  margin: 0;
}
</style>
