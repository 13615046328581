import type { ColumnProperties } from '@/general-components/ColumnProperties'

export const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Event',
    width: '19%',
    slotName: 'eventType',
  },
  {
    title: 'Price',
    width: '20%',
    slotName: 'price',
  },
  {
    title: 'From',
    width: '19%',
    slotName: 'from',
  },
  {
    title: 'To',
    width: '19%',
    slotName: 'to',
  },
  {
    title: 'Date',
    width: '23%',
    slotName: 'date',
  },
]
