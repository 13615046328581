import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import StoreIds from '@/constants/storeIds'
import type { SupportedChain } from '@/constants/chains/types'
import type { Address } from 'viem'

export const useSelectCollectionAssetModalStore = defineStore(StoreIds.selectCollectionAssetModal, () => {
  const isOpen = ref(false)
  const selectedCollateral = ref<AssetMetadata>()
  const chainId = ref<SupportedChain>()
  const collectionAddress = ref<Address>()
  const minCollateralAmount = ref<bigint>(0n)
  const isOnlySelectAsset = ref(false)
  const selectAsset = ref<(asset: AssetWithAmount) => Promise<void>>()
  const reset = () => {
    selectedCollateral.value = undefined
    chainId.value = undefined
    collectionAddress.value = undefined
    minCollateralAmount.value = 0n
    isOnlySelectAsset.value = false
    selectAsset.value = undefined
  }

  return {
    isOpen,
    selectedCollateral,
    chainId,
    collectionAddress,
    minCollateralAmount,
    isOnlySelectAsset,
    selectAsset,
    reset,
  }
})
