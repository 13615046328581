import type { ColumnProperties } from '@/general-components/ColumnProperties'
import { sortBundlesByAssetsAmount, sortAssetsByName, sortAssetsByVerification } from '@/general-components/sorting/SortFunctions'

export enum SortOption {
  Name = 'name',
  Verification = 'verification',
  Amount = 'amount'
}

export const SORT_OPTIONS_LOOKUP = {
  [SortOption.Name]: sortAssetsByName,
  [SortOption.Verification]: sortAssetsByVerification,
  [SortOption.Amount]: sortBundlesByAssetsAmount,
}

export const TABLE_DEFINITION: ColumnProperties[] = [

  {
    title: '',
    width: '3%',
    slotName: 'radio',
  },
  {
    title: 'Collateral',
    width: '42%',
    slotName: 'asset-info',
    sortOptionName: SortOption.Name,
  },
  {
    title: 'Verification',
    width: '23%',
    slotName: 'verification',
    sortOptionName: SortOption.Verification,
  },
  {
    title: 'Explorer',
    width: '16%',
    slotName: 'explorer',
  },
  {
    title: 'Amount',
    width: '16%',
    slotName: 'amount',
    sortOptionName: SortOption.Amount,
  },
]
