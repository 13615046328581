import type { BreadcrumbActiveStep } from '@/general-components/IBreadcrumbsHeader'
import RouteName from '@/router/RouteName'

const CREATE_PWN_SAFE_STEPS_DEFINITION: BreadcrumbActiveStep[] = [
  {
    stepNumber: 1,
    headingText: 'Create new PWN Safe',
    hint: 'You are about to create a PWN Safe, please choose a name you would like to give to your safe. The name you choose will be displayed in the PWN Safe Dashboard and while interacting with your PWN Safe.',
    stepName: '1. Choose PWN Safe name',
    route: RouteName.PwnSafeCreateNewPwnSafe,
  },
  {
    stepNumber: 2,
    headingText: 'Create new PWN Safe',
    hint: 'Please confirm the details of your PWN Safe are correct.',
    stepName: '2. Confirm',
    route: RouteName.PwnSafeCreateConfirmation,
  },
]
export default CREATE_PWN_SAFE_STEPS_DEFINITION
