<template>
  <BaseTable
    v-model:active-sort-option="selectedSortOption"
    :columns="TABLE_DEFINITION"
    :has-first-column-padding="false"
    :items="sortedNotifications"
    :on-hover="onHover"
    :is-fetching="isFetchingNotifications"
    display-loading-bottom
    :is-hoverable="false"
    :td-style="{ padding: '0.5rem 0', height: '5.125rem', fontSize: '1rem'}">
    <template #time="{ item: notification }: { item: Notification }">
      {{ notification.displayCreateDate }}<br>
      {{ notification.displayCreateTime }}
    </template>

    <template #id="{ item: notification }: { item: Notification }">
      <div class="notification-center-table__loan-id">
        <div class="notification-center-table__image">
          <TokenMedia
            v-if="!!notification.firstAsset"
            :token="notification.firstAsset"
            :width="imageSize(notification)"
            :height="imageSize(notification)"
            class="notification-center-table__collateral-image"/>
          <TokenMedia
            v-if="!!notification.secondAsset"
            :token="notification.secondAsset"
            :width="imageSize(notification)"
            :height="imageSize(notification)"
            :class="['notification-center-table__asset-image', {'notification-center-table__asset-image--composite': hasCompositeImage(notification)}]"/>
        </div>
        <div
          v-if="notification?.loanOnChainId"
          :class="['notification-center-table__loan-id-link']">
          Loan #:
          <BaseLink
            :to="notification.loanDetailRoute"
            class="link--font-oxygen-mono link--primary">
            {{ notification.loanOnChainId }}
          </BaseLink>
        </div>
        <div
          v-else-if="notification?.proposalId"
          :class="['notification-center-table__loan-id-link']">
          Proposal #:
          <BaseLink
            :to="notification.proposalDetailRoute"
            class="link--font-oxygen-mono link--primary">
            {{ notification.proposalId }}
          </BaseLink>
        </div>
      </div>
    </template>

    <template #type="{ item: notification }: { item: Notification }">
      <div class="notification-center-table__notification-type">
        <NotificationTypeIcon
          class="notification-center-table__notification-type-icon"
          :variant="notification.variant"/>
        {{ notification.message }}
      </div>
    </template>

    <template #chain="{ item: notification }: { item: Notification }">
      <ChainInfo
        v-if="notification?.chainId"
        :chain-id="notification.chainId"/>
    </template>

    <template #details="{ item: notification }: { item: Notification }">
      <BaseLink
        v-if="notification?.loanOnChainId"
        :to="notification.loanDetailRoute"
        class="link--font-oxygen-mono notification-center-table__tx-detail">
        Loan
      </BaseLink>
      <BaseLink
        v-else-if="notification?.proposalId"
        :to="notification.loanDetailRoute"
        class="link--font-oxygen-mono notification-center-table__tx-detail">
        Proposal
      </BaseLink>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">
import BaseTable from '@/general-components/BaseTable.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import { TABLE_DEFINITION } from '@/modules/pages/notification-center/NotificationCenterDefinitions'
import NotificationTypeIcon from '@/general-components/NotificationTypeIcon.vue'
import useNotificationCenter from '@/modules/pages/notification-center/useNotificationCenter'
import TokenMedia from '@/general-components/TokenMedia.vue'
import type Notification from '@/modules/common/notifications/Notification'
import ChainInfo from '@/general-components/ChainInfo.vue'
import useNotifications from '@/modules/common/notifications/useNotifications'
import useNotificationsFetch from '@/modules/common/notifications/useNotificationsFetch'

const { selectedSortOption, sortedNotifications } = useNotificationCenter()
const { isFetchingNotifications } = useNotificationsFetch()

const hasCompositeImage = (notification: Notification) => notification?.firstAsset?.image && notification?.secondAsset?.image
const imageSize = (notification: Notification) => hasCompositeImage(notification) ? '48' : '72'

const onHover = async (notification: Notification) => {
  return !notification.hasSeen ? await useNotifications().markNotificationsAsSeen([notification.id!]) : null
}

</script>

<style scoped>
.notification-center-table {
  &__loan-id {
    display: flex;
    align-items: center;
  }

  &__loan-id-link {
    margin-left: 1rem;
  }

  &__notification-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: normal;
  }

  &__notification-type-icon {
    margin-right: 0.75rem;
  }

  &__image {
    min-width: 72px;
    height: 72px;
    margin-right: 10px;
    position: relative;
  }

  &__asset-image {
    position: absolute;

    object-fit: contain;
    object-position: center;

    &--composite {
      left: 24px;
      top: 24px;
    }
  }

  &__collateral-image {
    position: absolute;
    object-fit: contain;
    object-position: center;
  }
}
</style>
