<template>
  <div class="asset-header__wrapper">
    <div class="asset-header">
      <div class="asset-header__left">
        <BaseBackButton/>
        <div class="asset-header__label">
          {{ headerText }}
        </div>

        <ChainInfo
          v-if="asset?.chainId"
          class="asset-header__chain-info-mobile"
          :chain-id="asset.chainId"/>
      </div>
      <div
        v-if="asset"
        class="asset-header__buttons">
        <ChainInfo
          v-if="asset.chainId"
          class="asset-header__chain-info"
          :chain-id="asset.chainId"/>
        <BaseButton
          v-if="shouldDisplayUnwrapButton"
          :color="ButtonColor.PrimaryDark"
          :variant="ButtonVariant.Outline"
          title="Unwrap asset"
          button-text="Unwrap Asset"
          @click="handleUnwrapAsset"/>
        <!--        <ButtonScrollToBottom-->
        <!--          button-text="Make Proposal"-->
        <!--          @click="handleCreateOfferClick"-->
        <!--          @on-scroll="handleScroll"/>-->
        <BaseButton
          :color="ButtonColor.Gray"
          :variant="ButtonVariant.Outline"
          title="Refresh metadata"
          @click="handleRefreshMetadata">
          <template #icon>
            <RefreshIconSvg
              width="20"
              height="20"
              alt="refresh icon"/>
          </template>
        </BaseButton>
        <BaseButton
          :color="ButtonColor.Gray"
          :variant="ButtonVariant.Outline"
          title="View NFT on Opensea"
          @click="openOpensea">
          <template #icon>
            <ExternalIconSvg
              width="20"
              height="20"
              alt="opensea icon"/>
          </template>
        </BaseButton>
        <ModalShare
          heading="Share asset"
          share-message="Hey, check out this NFT I've found on PWN.xyz"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import RouteName from '@/router/RouteName'
import { useRoute, useRouter } from 'vue-router'
import type { AssetPageRouteParams } from '@/modules/pages/asset/nft-page/useAssetPage'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import ModalShare from '@/general-components/ModalShare.vue'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import { getAssetDetailsExplorerLink } from '@/utils/chain'
// import ButtonScrollToBottom from '@/general-components/ButtonScrollToBottom.vue'
import RefreshIconSvg from '@/assets/icons/refresh.svg'
import ExternalIconSvg from '@/assets/icons/external.svg'
import { storeToRefs } from 'pinia'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { compareAddresses } from '@/utils/utils'
import ChainInfo from '@/general-components/ChainInfo.vue'

const { asset, loadNFTAssetPage } = useAssetPage()
const route = useRoute()
const router = useRouter()

const headerText = computed(() => {
  if (asset.value?.isErc20) {
    return 'Token Overview'
  }
  return 'NFT Overview'
})
const openseaNftDetailsLink = computed(() => {
  if (!asset.value?.address) {
    return
  }
  return getAssetDetailsExplorerLink(asset.value)
})

const openOpensea = () => {
  window.open(openseaNftDetailsLink.value, '_blank')
}

const handleRefreshMetadata = () => {
  loadNFTAssetPage(route.params as unknown as AssetPageRouteParams, true)
}

const handleUnwrapAsset = () => {
  router.push({ name: RouteName.UnpackBundleChooseBundle })
}

const isScrollToMakeOfferButtonDisabled = ref(false)

const checkScroll = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop
  const scrollHeight = document.documentElement.scrollHeight
  const clientHeight = document.documentElement.clientHeight

  const distanceFromBottom = scrollHeight - (scrollTop + clientHeight)

  if (distanceFromBottom > 200) {
    isScrollToMakeOfferButtonDisabled.value = false
  } else {
    isScrollToMakeOfferButtonDisabled.value = true
  }
}

onMounted(() => {
  window.addEventListener('scroll', checkScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', checkScroll)
})

// const handleScroll = (isScrolling: boolean) => {
//   isScrollingToMakeOffer.value = isScrolling
// }
//
// const handleCreateOfferClick = () => {
//   isProposalsTabSelected.value = true
//
//   const element = document.querySelector('#make-offer-credit-offered-input')
//   element?.querySelector('input')?.focus()
// }

const userAssetsStore = useUserAssetsStore()
const { userNfts } = storeToRefs(userAssetsStore)

const isUserOwnerOfNft = computed(() => {
  return !!userNfts.value.find(userAsset => compareAddresses(userAsset.address, asset.value?.address) && userAsset.tokenId === asset.value?.tokenId)
})

const shouldDisplayUnwrapButton = computed(() => {
  if (!asset.value?.isTokenBundle) {
    return false
  }
  return isUserOwnerOfNft.value
})
</script>

<style scoped>
.asset-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1rem;
  background: var(--background-color);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px;
    /* small hack how to hide buggy double border (top + bottom), when height is 1px */
  }

  &__wrapper {
    position: sticky;
    top: var(--menu-height);
    z-index: var(--z-index-token-page-header);
    margin: 5rem 0 var(--space-40);
  }

  &__triangle-icon {
    margin-left: 0.5rem;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 0.7rem;
    margin-bottom: 0.5rem;
  }

  &__chain-info {
    margin-right: 0.5rem;

    @media only screen and (--small-viewport) {
      display: none;
    }

    &-mobile {
      display: none;

      @media only screen and (--small-viewport) {
        display: block;
        margin-left: 1rem;
      }
    }
  }

  &__left {
    display: flex;
    width: 100%;
  }

  &__label {
    font-family: var(--font-family-screener);
    margin-left: 1rem;
    font-size: 2rem;
  }

  @keyframes rotation {
    from {
      transform: rotate(359deg);
    }

    to {
      transform: rotate(0deg);
    }
  }

  &__rotate {
    animation: rotation 2s infinite linear;
  }

  @media only screen and (--small-viewport) {
    flex-flow: column;
    align-items: flex-start;
    padding-top: 1rem;
    row-gap: 1rem;

    &__wrapper {
      margin: 0;
    }

    &__left {
      align-self: flex-start;
    }

    &__label {
      font-size: 1.25rem;
    }
  }

  @media only screen and (max-width: 465px) {
    &__buttons {
      font-size: small;
    }
  }
}
</style>
