<template>
  <RewardsTooltip
    v-if="creditAddress && collateralAddress && _chainId"
    :assets="rewardsAssets"
    :apr="apr"
    :proposal-rewards-amount="rewardsToBeEarned > 0 ? formatDecimalPoint(rewardsToBeEarned) : undefined">
    <template #trigger>
      <div
        v-if="rewardsToBeEarned"
        class="rewards-by-amount">
        <ReputationScoreSvg
          class="rewards-by-amount__icon"
          alt="reputation score"/>

        <span>
          <SafeDisplayDecimals :value="formatDecimalPoint(rewardsToBeEarned)"/>
        </span>
      </div>
    </template>
  </RewardsTooltip>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { useRewardsForCreditToBeEarned } from './useRewardsForCreditToBeEarned'
import type { Address } from 'viem'
import ReputationScoreSvg from '@/assets/icons/rep-score.svg'
import { formatDecimalPoint } from '@/utils/utils'
import RewardsTooltip from '@/general-components/promo/RewardsTooltip.vue'
import SafeDisplayDecimals from '@/general-components/safe-display-decimals/SafeDisplayDecimals.vue'
import { parseAddress } from '@/utils/address-utils'

type Props = {
  collateralAddress: Address | null
  creditAddress: Address | null
  chainId: number | string | null
  amountInput: string
  apr?: string
}

const props = defineProps<Props>()

const { collateralAddress, creditAddress, chainId, amountInput } = toRefs(props)

const _chainId = computed(() => {
  if (!chainId.value) return null
  return Number(chainId.value)
})

const { rewardsToBeEarned } = useRewardsForCreditToBeEarned(creditAddress, _chainId, amountInput)

const rewardsAssets = computed(() => {
  if (!creditAddress.value || !collateralAddress.value || !_chainId.value) return []
  return [{ chainId: _chainId.value, address: parseAddress(creditAddress.value) }, { chainId: _chainId.value, address: parseAddress(collateralAddress.value) }]
})

</script>

<style scoped>
.rewards-by-amount {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--primary-color-1);

  display: flex;
  align-items: center;

  &__icon {
    margin-right: 0.25rem;
    width: 12px;
    height: 12px;
    fill: var(--primary-color-1);
  }
}
</style>
