<template v-show="false">
  <EthAndUsdPriceLabel
    :has-tooltip="false"
    :amount="collectionAmount"/>
</template>

<script lang="ts" setup>
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import { computed } from 'vue'
import type { CollectionSearchResult } from '@/modules/common/pwn/explorer/models/CollectionSearchResult'

type Props = {
  item: CollectionSearchResult
}

const props = defineProps<Props>()

const collectionAmount = computed(() => {
  return props.item.tlvValue
})

</script>
