import { reactive, ref } from 'vue'
import type { RouteLocationNormalized, Router } from 'vue-router'
import UNPACK_BUNDLE_STEPS_DEFINITION from '@/modules/pages/token-bundler/unpack-bundle/unpackBundleStepsDefinition'
import RouteName from '@/router/RouteName'
import router from '@/router'
import type { BreadcrumbActiveStep } from '@/general-components/IBreadcrumbsHeader'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

const selectedBundle = ref<AssetWithAmount>()

const activeStep = ref<BreadcrumbActiveStep>()

export default function useUnpackBundle() {
  const setActiveStep = (step: BreadcrumbActiveStep): void => {
    activeStep.value = step
    window.scrollTo({ top: 0 })
    // useAnalytics().trackEvent({ name: EventNames.LoanFlow, props: { action: step.stepName } })
  }

  // make sure we start unpack bundle flow on first step, even if the user accessed url of next steps (e.g. /token-bundler/unpack-bundle) directly
  const checkEnterStep = (route: RouteLocationNormalized, router: Router) => {
    if (route.name === RouteName.UnpackBundleChooseBundle) {
      return
    }

    if ([RouteName.UnpackBundleSubmit].includes(route.name as RouteName)) {
      return router.push({ name: RouteName.UnpackBundleChooseBundle })
    }
  }

  const initPage = (route: RouteLocationNormalized, router: Router) => {
    checkEnterStep(route, router)
    activeStep.value = UNPACK_BUNDLE_STEPS_DEFINITION[0]
  }

  const selectBundle = (asset: AssetWithAmount) => {
    selectedBundle.value = asset
  }

  const goToSubmitUnpackBundle = (): void => {
    router.push({ name: RouteName.UnpackBundleSubmit })
  }

  return {
    initPage,
    activeStep: reactive(activeStep),
    setActiveStep,
    selectedBundle,
    goToSubmitUnpackBundle,
    selectBundle,

  }
}
