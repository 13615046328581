import StoreIds from '@/constants/storeIds'
import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNotificationReminderModalStore = defineStore(StoreIds.notificationReminderModal, () => {
  const LOCAL_STORAGE_NOTIFICATION_REMINDER = 'notification-reminder-was-shown'
  const localStorageAlreadyShownReminder = useLocalStorage(LOCAL_STORAGE_NOTIFICATION_REMINDER, false)

  const isOpen = ref(false)
  const createdLoan = ref<BaseLoan | BaseProposal>()

  const checkIfShowNotificationReminderModal = (loan: BaseLoan | BaseProposal) => {
    if (!localStorageAlreadyShownReminder.value) {
      createdLoan.value = loan
      localStorageAlreadyShownReminder.value = true
      isOpen.value = true
    }
  }

  return {
    createdLoan,
    isOpen,
    checkIfShowNotificationReminderModal,
  }
})
