<template>
  <div class="lending-stats">
    <div
      v-if="walletStats"
      class="lending-stats__stats">
      <div class="lending-stats__stat">
        <div class="lending-stats__stat-label">
          Total Lent
        </div>
        <div class="lending-stats__stat-value">
          <span v-if="walletStats.totalLentVolumeUsd && walletStats.totalLentVolumeUsd.toString() !== '0'">
            ${{ shortenNumber(walletStats.totalLentVolumeUsd) }}
          </span>
          ({{ walletStats.totalLent }})
        </div>
        <div class="lending-stats__stat-label">
          Defaulted Loans
        </div>
        <div class="lending-stats__stat-value">
          <span v-if="walletStats.defaultedLentLoansVolumeUsd && walletStats.defaultedLentLoansVolumeUsd.toString() !== '0'">
            ${{ shortenNumber(walletStats.defaultedLentLoansVolumeUsd) }}
          </span>
          ({{ walletStats.defaultedLentLoans }})
        </div>
      </div>
      <div class="lending-stats__stat">
        <div class="lending-stats__stat-label">
          Total Earned
        </div>
        <div class="lending-stats__stat-value">
          <span v-if="walletStats.totalEarnedVolumeUsd && walletStats.totalEarnedVolumeUsd.toString() !== '0'">
            ${{ shortenNumber(walletStats.totalEarnedVolumeUsd) }}
          </span>
          ({{ walletStats.totalEarned }})
        </div>
        <div class="lending-stats__stat-label">
          Paid Back Loans
        </div>
        <div class="lending-stats__stat-value">
          <span v-if="walletStats.paidBackLentLoansVolumeUsd && walletStats.paidBackLentLoansVolumeUsd.toString() !== '0'">
            ${{ shortenNumber(walletStats.paidBackLentLoansVolumeUsd) }}
          </span>
          ({{ walletStats.paidBackLentLoans }})
        </div>
      </div>
      <div class="lending-stats__stat">
        <div class="lending-stats__stat-label">
          Active Loans
        </div>
        <div class="lending-stats__stat-value">
          <span v-if="walletStats.activeLentLoansVolumeUsd && walletStats.activeLentLoansVolumeUsd.toString() !== '0'">
            ${{ shortenNumber(walletStats.activeLentLoansVolumeUsd) }}
          </span>
          ({{ walletStats.activeLentLoans }})
        </div>
        <div
          v-if="countdown"
          class="lending-stats__stat-label">
          Next Loan Default
        </div>
        <div
          v-if="countdown"
          class="lending-stats__stat-value">
          {{ countdown }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, toRefs, watchEffect } from 'vue'
import { shortenNumber, formatCountdown } from '@/utils/utils'
import type WalletStats from '@/modules/common/web3/typings/WalletStats'
import type { IntervalId } from '@/modules/common/typings/customTypes'

type Props = {
  walletStats: WalletStats | undefined
}
const props = defineProps<Props>()
const { walletStats } = toRefs(props)
const countdown = ref<string | null>(null)
let intervalId: IntervalId | null = null

const updateCountdown = () => {
  if (walletStats.value && walletStats.value.nextLentLoanDefault) {
    countdown.value = formatCountdown(walletStats.value.nextLentLoanDefault)
  } else {
    countdown.value = ''
  }
}

onMounted(() => {
  updateCountdown()
  intervalId = setInterval(updateCountdown, 1000)
})

watchEffect(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }
  intervalId = setInterval(updateCountdown, 1000)
})
</script>

<style scoped>
.lending-stats {
  display: flex;
  flex-direction: column;

  &__background {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: -2rem;
    height: 15.5rem;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      height: 15.5rem;
      width: 100%;
      background-image: url("@/assets/icons/stats-background.svg");
      background-size: cover;
      background-repeat: no-repeat;
      mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0 0 0 / 0%)), to(rgb(0 0 0 / 100%))),
        -webkit-gradient(linear, left top, left bottom, from(rgb(255 255 255 / 0%)), to(rgb(0 0 0 / 100%)));
      background-position: center;
      pointer-events: none;
      z-index: -1;
      border-bottom: 1px solid rgb(0 255 224 / 10%);
    }
  }

  &__header {
    color: var(--text-color);
    font-size: 2.4rem;
    font-family: var(--font-family-autoscape);
    margin-bottom: 4rem;
  }

  &__stats {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    gap: 5rem;

    @media only screen and (--small-viewport) {
      flex-direction: column;
    }
  }

  &__stat {
    display: flex;
    flex-direction: column;
    width: 18.5rem;
  }

  &__stat-label {
    color: var(--gray-2);
    font-size: 1rem;
    font-family: var(--font-family-screener);
    margin-bottom: 12px;
    margin-top: 24px;
  }

  &__stat-value {
    color: var(--text-color);
    font-size: 1.25rem;
    font-family: var(--font-family-supreme);
  }

  @media only screen and (--small-viewport) {
    &__wrapper {
      overflow-x: hidden;
    }

    &__header {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }

    &__stats {
      flex-flow: row wrap;
      width: 100%;
      gap: 2rem;
    }

    &__stat {
      width: 200px;

      &-label {
        font-size: 0.9rem;
      }

      &-value {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
