import StoreIds from '@/constants/storeIds'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMultisigWalletModalStore = defineStore(StoreIds.modalMultisigWalletSign, () => {
  const isOpen = ref(false)

  return {
    isOpen,
  }
})
