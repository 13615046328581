import { computed, ref } from 'vue'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { SORT_OPTIONS_LOOKUP, SortOption } from '@/modules/sections/your-assets/your-nfts/YourNFTsDefinitions'
import { compareAddresses, partition } from '@/utils/utils'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import useAtrContract from '@/modules/common/pwn/contracts/useAtrContract'
import usePwnSafeAtrTokens from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeAtrTokens'
import { filterAssetsInSearchResults } from '@/utils/search'
import usePwnSafeAssetsDashboard from '@/modules/pages/pwn-safe/usePwnSafeAssetsDashboard'
import { ATR_SORT_OPTIONS_LOOKUP } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { storeToRefs } from 'pinia'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export enum ClaimAndBurnActionType {
  Claim = 'Claim',
  ClaimAndBurn = 'ClaimAndBurn',
  Burn = 'Burn',
}

const atrTokensToClaimAndBurn = ref<AssetWithAmount[]>([])
const isBurnAtrTokens = ref(false)

const searchTerm = ref('')
const displayNfts = ref(false)
const displayCoins = ref(false)

const selectedSelectionSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-your-tokens',
  { id: SortOption.Name, direction: SortDirection.Descending, viewName: 'sort-option-your-tokens' },
  Object.keys(SORT_OPTIONS_LOOKUP),
))

export default function usePwnSafeClaimAndBurn() {
  const { burnAtrToken, burnAtrTokenBatch, claimAtrToken } = useAtrContract()
  const pwnSafeDetailStore = usePwnSafeDetailStore()
  const { selectedPwnSafe, isSelectedCurrentWallet } = storeToRefs(pwnSafeDetailStore)
  const { selectedSortOption: selectedAtrSortOption } = usePwnSafeAssetsDashboard()
  const { sortedPwnSafeAtrTokens } = usePwnSafeAtrTokens()
  const connectedAccountTypeStore = useConnectedAccountTypeStore()
  const { isConnectedContractWallet, isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)
  const { address: userAddress } = useCustomAccount()

  const sortedAndFilteredAtrTokens = computed(() => {
    let atrTokens = sortedPwnSafeAtrTokens.value

    if (displayCoins.value && !displayNfts.value) {
      atrTokens = atrTokens.filter(atrToken => atrToken.assetOfAtrToken.isErc20)
    } else if (!displayCoins.value && displayNfts.value) {
      atrTokens = atrTokens.filter(atrToken => atrToken.assetOfAtrToken.isNft)
    }

    atrTokens = filterAssetsInSearchResults(atrTokens, searchTerm.value)

    return ATR_SORT_OPTIONS_LOOKUP[selectedAtrSortOption.value.id](atrTokens, selectedAtrSortOption.value.direction)
  })

  const isContractWalletTx = computed(() => isConnectedContractWallet.value || !isSelectedCurrentWallet.value)

  const isBurnOnly = computed(() => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    return atrTokensToClaimAndBurn.value.every(atrToken => compareAddresses(atrToken.assetOfAtrToken.ownerAddress, selectedPwnSafe.value.safeAddress))
  })

  const isClaimBurnInPwnSafe = computed(() => {
    return !compareAddresses(selectedPwnSafe.value?.safeAddress, userAddress.value) || isConnectedPwnSafe.value
  })

  const claimAndBurnActionType = computed<ClaimAndBurnActionType>(() => {
    if ((isBurnAtrTokens.value && !isBurnOnly.value) || (isSelectedCurrentWallet.value && !isConnectedPwnSafe.value)) {
      return ClaimAndBurnActionType.ClaimAndBurn
    } else if (isBurnOnly.value) {
      return ClaimAndBurnActionType.Burn
    } else {
      return ClaimAndBurnActionType.Claim
    }
  })

  const actionDescription = computed(() => {
    switch (claimAndBurnActionType.value) {
    case ClaimAndBurnActionType.ClaimAndBurn:
      return 'Claiming and burning ATR token moves the asset into your current wallet and burns the ATR token, this will give the asset it’s original Transfer Rights back.'
    case ClaimAndBurnActionType.Burn:
      return 'You can burn the ATR token and give the asset it’s original transfer rights back'
    case ClaimAndBurnActionType.Claim:
      return 'Claiming moves the asset into your PWN Safe, you can also burn the ATR token to give the asset it’s Transfer Rights back by toggling the burn button below.'
    default:
      // todo any log here?
      return null
    }
  })
  //
  // const refreshAfterClaimOrClaimWithBurn = (atrToken: AssetWithAmount, burnAtrToken: boolean) => {
  //   const previousTokenizedAssetOwner = atrToken.assetOfAtrToken.ownerAddress
  //   const newTokenizedAssetOwner = selectedPwnSafe.value.safeAddress
  //
  //   if (previousTokenizedAssetOwner in pwnSafes) {
  //     const assetsOfPreviousTokenizedAssetOwner = pwnSafes[previousTokenizedAssetOwner][atrToken.assetOfAtrToken.isNft ? 'nfts' : 'erc20s']
  //     const assetIndexInPreviousOwnerSafe = assetsOfPreviousTokenizedAssetOwner.findIndex(asset => compareAssets({ assetA: asset, assetB: atrToken.assetOfAtrToken }))
  //     if (assetIndexInPreviousOwnerSafe !== -1) {
  //       const assetInPreviousOwnerSafe = assetsOfPreviousTokenizedAssetOwner[assetIndexInPreviousOwnerSafe]
  //       if (assetInPreviousOwnerSafe.amountRaw.sub(atrToken.assetOfAtrToken.amountRaw).lte(0)) {
  //         assetsOfPreviousTokenizedAssetOwner.splice(assetIndexInPreviousOwnerSafe, 1)
  //       } else {
  //         assetInPreviousOwnerSafe.atrTokens = assetInPreviousOwnerSafe.atrTokens.filter(atrTokenInSafe => atrTokenInSafe.tokenId !== atrToken.tokenId)
  //
  //         assetsOfPreviousTokenizedAssetOwner[assetIndexInPreviousOwnerSafe] = assetInPreviousOwnerSafe.updateAssetAmounts({
  //           amount: formatAmountWithDecimals(assetInPreviousOwnerSafe.amountRaw.sub(atrToken.assetOfAtrToken.amountRaw), assetInPreviousOwnerSafe.decimals),
  //           tokenizedAmount: formatAmountWithDecimals(assetInPreviousOwnerSafe.tokenizedAmountRaw.sub(atrToken.assetOfAtrToken.amountRaw), assetInPreviousOwnerSafe.decimals),
  //         })
  //       }
  //     }
  //   }
  //
  //   atrToken.assetOfAtrToken.ownerAddress = newTokenizedAssetOwner
  //
  //   // here we decide which assets to update
  //   let assetsOfNewTokenizedAssetOwner: AssetWithAmount[]
  //   // if (isSelectedCurrentWallet.value) {
  //   //   assetsOfNewTokenizedAssetOwner = atrToken.assetOfAtrToken.isNft ? userNfts.value : userErc20sWithNativeToken.value
  //   // } else {
  //   //   assetsOfNewTokenizedAssetOwner = selectedPwnSafe.value[atrToken.assetOfAtrToken.isNft ? 'nfts' : 'erc20s']
  //   // }
  //
  //   // here we remove the burned asset from user assets or
  //   if (burnAtrToken) {
  //     // toggling this off since updates are handled separately inside related mutations
  //     // const nftsArray = isSelectedCurrentWallet.value ? userNfts.value : selectedPwnSafe.value.nfts
  //     // const burntAtrTokenIndex = nftsArray.findIndex(asset => compareAssets({ assetA: asset, assetB: atrToken }))
  //     // if (burntAtrTokenIndex !== -1) {
  //     //   nftsArray.splice(burntAtrTokenIndex, 1)
  //     // }
  //   }
  //
  //   // here we update the new owner assets
  //   const assetIndexInNewOwnerSafe = assetsOfNewTokenizedAssetOwner.findIndex(asset => compareAssets({ assetA: asset, assetB: atrToken.assetOfAtrToken }))
  //   // if the asset is not in the new owner safe, we add it
  //   if (assetIndexInNewOwnerSafe === -1 && burnAtrToken) {
  //     assetsOfNewTokenizedAssetOwner.push(atrToken.assetOfAtrToken)
  //     // if the asset is not in the new owner safe and we are not burning the ATR token, we add it and update the tokenized amount
  //   } else if (assetIndexInNewOwnerSafe === -1 && !burnAtrToken) {
  //     atrToken.assetOfAtrToken.atrTokens.push(new AtrTokenInfo({
  //       contractAddress: atrToken.address,
  //       tokenId: atrToken.tokenId,
  //       createdBy: '',
  //       tokenizedAssetOwner: selectedPwnSafe.value.safeAddress,
  //       tokenizedAmount: atrToken.assetOfAtrToken.amount,
  //     }))
  //     assetsOfNewTokenizedAssetOwner.push(atrToken.assetOfAtrToken.updateAssetAmounts({
  //       tokenizedAmount: atrToken.assetOfAtrToken.amount,
  //     }))
  //     // if the asset is in the new owner safe, we update the amount and tokenized amount
  //   } else if (assetIndexInNewOwnerSafe !== -1 && burnAtrToken) {
  //     const assetInNewOwnerSafe = assetsOfNewTokenizedAssetOwner[assetIndexInNewOwnerSafe]
  //     assetInNewOwnerSafe.atrTokens = assetInNewOwnerSafe.atrTokens.filter(atrTokenInSafe => atrTokenInSafe.tokenId !== atrToken.tokenId)
  //     assetsOfNewTokenizedAssetOwner[assetIndexInNewOwnerSafe] = assetInNewOwnerSafe.updateAssetAmounts({
  //       amount: formatAmountWithDecimals(assetInNewOwnerSafe.amountRaw.add(atrToken.assetOfAtrToken.amountRaw), assetInNewOwnerSafe.decimals),
  //     })
  //     // if the asset is in the new owner safe and we are not burning the ATR token, we update the amount and tokenized amount
  //   } else if (assetIndexInNewOwnerSafe !== -1 && !burnAtrToken) {
  //     const assetInNewOwnerSafe = assetsOfNewTokenizedAssetOwner[assetIndexInNewOwnerSafe]
  //     assetInNewOwnerSafe.atrTokens.push(new AtrTokenInfo({
  //       contractAddress: atrToken.address,
  //       tokenId: atrToken.tokenId,
  //       createdBy: '',
  //       tokenizedAssetOwner: selectedPwnSafe.value.safeAddress,
  //       tokenizedAmount: atrToken.assetOfAtrToken.amount,
  //     }))
  //     // if the asset is in the new owner safe and we are not burning the ATR token, we update the amount and tokenized amount
  //     assetsOfNewTokenizedAssetOwner[assetIndexInNewOwnerSafe] = assetInNewOwnerSafe.updateAssetAmounts({
  //       amount: formatAmountWithDecimals(assetInNewOwnerSafe.amountRaw.add(atrToken.assetOfAtrToken.amountRaw), assetInNewOwnerSafe.decimals),
  //       tokenizedAmount: formatAmountWithDecimals(assetInNewOwnerSafe.tokenizedAmountRaw.add(atrToken.assetOfAtrToken.amountRaw), assetInNewOwnerSafe.decimals),
  //     })
  //   }
  // }
  //
  const claimWithBurn = async (atrToken: AssetWithAmount): Promise<boolean> => {
    const receipt = await claimAtrToken({
      isContractWalletTx: isContractWalletTx.value,
      atrToken,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      safeAddress: selectedPwnSafe.value.safeAddress,
      isBurn: true,
    })
    return !!receipt
  }

  const claimWithBurnBatch = async (
    atrTokensToClaimAndBurn: AssetWithAmount[],
  ): Promise<boolean> => {
    // TODO try send as batch transaction. Gnosis safe accept one transaction request in time. The next request is ignored if pending modal with submit transaction.
    let results = []
    if (isConnectedContractWallet.value) {
      for (const atrTokenToClaim of atrTokensToClaimAndBurn) {
        const result = await claimWithBurn(atrTokenToClaim)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
        results.push(result)
      }
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'boolean[]' is not assignable to type 'never[... Remove this comment to see the full error message
      results = await Promise.all(atrTokensToClaimAndBurn.map(claimWithBurn))
    }

    if (results?.some(result => result)) {
      return true
    } else {
      return false
    }
  }
  //
  // const refreshAfterBurn = (atrToken: AssetWithAmount) => {
  //   const burntAtrTokenIndex = selectedPwnSafe.value.nfts.findIndex(asset => compareAssets({ assetA: asset, assetB: atrToken }))
  //   if (burntAtrTokenIndex !== -1) {
  //     selectedPwnSafe.value.nfts.splice(burntAtrTokenIndex, 1)
  //   }
  //
  //   const safeAssets = selectedPwnSafe.value[atrToken.assetOfAtrToken.isNft ? 'nfts' : 'erc20s']
  //   const assetInSafeIndex = safeAssets.findIndex(asset => compareAssets({ assetA: asset, assetB: atrToken.assetOfAtrToken }))
  //
  //   if (assetInSafeIndex !== -1) {
  //     const assetInSafe = safeAssets[assetInSafeIndex]
  //     assetInSafe.atrTokens = assetInSafe.atrTokens.filter(atrTokenInSafe => atrTokenInSafe.tokenId !== atrToken.tokenId)
  //     safeAssets[assetInSafeIndex] = assetInSafe.updateAssetAmounts({
  //       tokenizedAmount: formatAmountWithDecimals(assetInSafe.tokenizedAmountRaw.sub(atrToken.assetOfAtrToken.amountRaw), assetInSafe.decimals),
  //     })
  //   } else {
  //     safeAssets.push(atrToken.assetOfAtrToken)
  //   }
  // }
  //
  const burn = async (atrToken: AssetWithAmount, step?: ToastStep): Promise<boolean> => {
    const receipt = await burnAtrToken(atrToken, selectedPwnSafe.value!.safeAddress, step)
    if (!receipt) {
      return false
    }

    return true
  }

  const burnBatch = async (atrTokens: AssetWithAmount[]) => {
    const receipt = await burnAtrTokenBatch(atrTokens, selectedPwnSafe.value!.safeAddress)

    if (!receipt) return false

    return true
  }

  const burnOrClaimWithBurnBatch = async (atrTokensToClaimAndBurn: AssetWithAmount[], step?: ToastStep): Promise<boolean> => {
    const [atrTokensOnlyBurn, atrTokensBurnWithClaim] = partition(
      atrTokensToClaimAndBurn,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      (atrToken) => compareAddresses(atrToken.assetOfAtrToken.ownerAddress, selectedPwnSafe.value.safeAddress),
    )

    const promises: Promise<unknown>[] = []

    if (atrTokensBurnWithClaim?.length) {
      promises.push(...atrTokensBurnWithClaim.map(claimWithBurn))
    }

    if (atrTokensOnlyBurn?.length === 1) {
      promises.push(burn(atrTokensOnlyBurn[0], step))
    } else if (atrTokensOnlyBurn?.length > 1) {
      promises.push(burnBatch(atrTokensOnlyBurn))
    }

    const results = await Promise.all(promises)
    return results?.some(result => result)
  }

  const onlyClaim = async (atrToken: AssetWithAmount, step?: ToastStep): Promise<boolean> => {
    const receipt = await claimAtrToken({
      isContractWalletTx: isContractWalletTx.value,
      atrToken,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      safeAddress: selectedPwnSafe.value.safeAddress,
      isBurn: false,
      step,
    })
    if (!receipt) throw Error('Claim failed')

    return true
  }

  const onlyClaimBatch = async (tokens: AssetWithAmount[]) => {
    // TODO try send as batch transaction. Gnosis safe accept one transaction request in time. The next request is ignored if pending modal with submit transaction.
    let results = []
    if (isConnectedContractWallet.value) {
      for (const atrToken of tokens) {
        const result = await onlyClaim(atrToken)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
        results.push(result)
      }
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'boolean[]' is not assignable to type 'never[... Remove this comment to see the full error message
      results = await Promise.all(tokens.map(onlyClaim))
    }
    return results?.some(result => result)
  }

  const sortedAtrTokensToClaimAndBurn = computed(() => ATR_SORT_OPTIONS_LOOKUP[selectedSelectionSortOption.value.id](atrTokensToClaimAndBurn.value, selectedSelectionSortOption.value.direction))

  const burnOrClaimAtrTokens = async (atrTokens: AssetWithAmount[], step?: ToastStep): Promise<boolean> => {
    switch (claimAndBurnActionType.value) {
    case ClaimAndBurnActionType.ClaimAndBurn:
      return await claimWithBurnBatch(atrTokens)
    case ClaimAndBurnActionType.Burn:
      return await burnOrClaimWithBurnBatch(atrTokens, step)
    case ClaimAndBurnActionType.Claim:
      return await onlyClaimBatch(atrTokens)
    default:
      // todo any log here?
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'boolean'.
      return null
    }
  }

  const actionName = computed(() => {
    switch (claimAndBurnActionType.value) {
    case ClaimAndBurnActionType.ClaimAndBurn: return 'Claim and Burn'
    case ClaimAndBurnActionType.Burn: return 'Burn'
    case ClaimAndBurnActionType.Claim: return 'Claim'
    default: return ''
    }
  })

  return {
    searchTerm,
    displayCoins,
    displayNfts,
    atrTokensToClaimAndBurn,
    sortedAtrTokensToClaimAndBurn,
    selectedSelectionSortOption,
    sortedAndFilteredAtrTokens,
    isBurnAtrTokens,
    burnOrClaimAtrTokens,
    claimAndBurnActionType,
    isBurnOnly,
    actionDescription,
    actionName,
    isClaimBurnInPwnSafe,
  }
}
