import { useCreateProposalPayload } from '@/revamp/components/proposal-form/useCreateProposalPayload'
import type { CreateProposalFormValueCustom } from '@/revamp/components/proposal-form/useCreateProposalPayload'

import type {
  V1_2SimpleLoanFungibleProposal,
} from '@/modules/common/pwn/proposals/ProposalClasses'
import {
  V1_2ProposalType,
} from '@/modules/common/pwn/proposals/ProposalClasses'
import { SimpleMerkleTree } from '@openzeppelin/merkle-tree'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { DefaultError } from '@tanstack/vue-query'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, ref, unref } from 'vue'
import type { MaybeRef } from 'vue'
import {
  proposalCreateBatch,
  freeUserNonceRetrieve, getProposalAndLoanListQueryKey,
} from '@/modules/common/backend/generated'
import type {
  CreateSimpleLoanFungibleProposalRequestSchemaRequestBackendSchema,
  ThesisSchemaWorkaroundBackendSchema,
} from '@/modules/common/backend/generated'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { useApproveThesisAssets } from '@/revamp/hooks/thesis/useApproveThesisAssets'
import V1_2SimpleLoanSimpleProposalContract
  from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanSimpleProposalContract'
import { compareAddresses, compareAssets, getRawAmount } from '@/utils/utils'
import { calculateCollateralAmountFungibleProposal, calculateCreditPerCollateralUnit } from '@/modules/common/pwn/utils'
import { Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import type { SupportedChain } from '@/constants/chains/types'
import { readErc20Allowance, pwnV1_2RevokedNonceAbi } from '@/contracts/generated'
import { maxUint256 } from 'viem'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import type { Address, Hash, Hex } from 'viem'
import { useAssetListPrices } from '@/revamp/hooks/thesis/useAssetListPrices'
import { calculateCollateralAmountByLtv } from './utils'
import type { AssetWithExtraThesisParams } from './types'
import useAnalytics, { EventNames } from '@/modules/common/analytics/useAnalytics'
import { compoundCometAllow, getPoolAdapter } from '@/contracts/abis'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import { DepositProtocol } from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import useApprove from '@/modules/common/assets/useApprove'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import * as starknet from 'starknet'
import { batchCreateStarknetFungibleProposals } from './starknet/proposals'
import { useModalKycStore } from '@/general-components/kyc-modal/useModalKycStore'
import { revokeStarknetNonces } from '@/revamp/hooks/thesis/starknet/proposals'

const DEFAULT_MIN_CREDIT_PERCENT = 1

type Proposals =
  V1_2SimpleLoanFungibleProposal
export const usePopulateThesis = (
  thesisCollateralAssets: MaybeRef<AssetWithExtraThesisParams[]>,
  thesisCreditAssets: MaybeRef<AssetWithAmount[]>,
  thesis: MaybeRef<ThesisSchemaWorkaroundBackendSchema>,
  onClose?: () => void,
) => {
  const { createProposalPayload } = useCreateProposalPayload()
  const queryClient = useQueryClient()
  const { trackEvent } = useAnalytics()

  const checkCollateralKyc = async (thesis: ThesisSchemaWorkaroundBackendSchema, submitAction) => {
    const kycStore = useModalKycStore()
    for (const collateralAsset of unref(thesisCollateralAssets)) {
      if (collateralAsset.isKycRequired) {
        // todo: can there be ERC721 as collateral in thesis?
        const assetMetadata = await useMetadataFetch().fetchErc20Metadata({
          chainId: collateralAsset.chainId,
          contractAddress: collateralAsset.address,
        })
        if (!assetMetadata) {
          throw new Error('Asset metadata for KYC asset not found')
        }
        kycStore.setModalAsset(assetMetadata)
        if (kycStore.shouldSkipModal(assetMetadata)) {
          continue
        }
        kycStore.isOpen = true
        kycStore.submitAction = submitAction
        return true
      }
    }
    return false
  }
  const getProposalsToCreate = (
    creditAssets: AssetWithAmount[], thesis: ThesisSchemaWorkaroundBackendSchema,
  ) => {
    const toCreate: CreateProposalFormValueCustom[] = []

    const preDefinedTerms: Pick<CreateProposalFormValueCustom, 'ltv' | 'apr' | 'expirationDays' | 'loanDurationDays'> = {
      ltv: String(thesis.ltv),
      apr: Number(thesis.aprMin),
      expirationDays: thesis.proposalExpirationDays,
      loanDurationDays: thesis.loanDurationDays,
    }

    for (const collateral of unref(thesisCollateralAssets)) {
      const collateralChainId = collateral.chainId
      const collateralAssetFromThesis = thesis.collateralAssets?.find((asset) => asset.id === String(collateral.id))
      const creditAssetsOnSameChain = unref(thesisCreditAssets).filter(credit => credit.chainId === collateralChainId)

      for (const userCreditAsset of creditAssets) {
        const credit = creditAssetsOnSameChain.find((asset) => compareAssets({
          assetA: asset,
          assetB: userCreditAsset,
        }))

        if (credit && userCreditAsset && userCreditAsset.amountInput && userCreditAsset.amountInput !== '' && userCreditAsset.amountInput !== '0') {
          const updatedCreditAsset = credit.updateAssetAmounts({
            amount: String(Number(userCreditAsset.amountInput) * (collateral.allocationPercentage / 100)),
          })

          updatedCreditAsset.aTokenAddress = userCreditAsset?.aTokenAddress
          updatedCreditAsset.protocol = userCreditAsset?.protocol
          updatedCreditAsset.sourceOfFunds = userCreditAsset?.sourceOfFunds

          toCreate.push({
            ...preDefinedTerms,
            ltv: collateralAssetFromThesis?.ltv ? String(collateralAssetFromThesis?.ltv) : preDefinedTerms.ltv,
            apr: collateralAssetFromThesis?.apr || preDefinedTerms.apr,
            collateralAsset: collateral,
            creditAsset: updatedCreditAsset,
            collateralAmount: '',
            creditAmount: '',
            sourceOfFunds: userCreditAsset.sourceOfFunds,
          })
        }
      }
    }

    return toCreate
  }

  const allAssetsWithoutDuplicates = computed(() => {
    const collateralAssets = unref(thesisCollateralAssets)
    const creditAssets = unref(thesisCreditAssets)

    return [...collateralAssets, ...creditAssets].filter((v, i, self) => self.findIndex(t => compareAssets({
      assetA: t,
      assetB: v,
    })) === i)
  })

  const { assetPrices } = useAssetListPrices(allAssetsWithoutDuplicates)

  const getPayload = async (proposal: CreateProposalFormValueCustom) => {
    const creditBigInt = getRawAmount(String(proposal.creditAsset?.amount), proposal.creditAsset?.decimals)

    if (!proposal.creditAsset) {
      throw new Error('Credit asset is required')
    }

    const _assetPrices = unref(assetPrices)
    const collateralAmount = calculateCollateralAmountByLtv(
      proposal.creditAsset,
      proposal.collateralAsset!,
      Number(proposal.ltv),
      _assetPrices,
    )
    const collateralBigInt = getRawAmount(collateralAmount, proposal.collateralAsset?.decimals)

    const creditPerCollateralUnit = calculateCreditPerCollateralUnit(creditBigInt, collateralBigInt)

    const minCreditAmount = String(+proposal.creditAsset.amount * (DEFAULT_MIN_CREDIT_PERCENT / 100))
    const minCreditAmountRaw = getRawAmount(minCreditAmount, proposal.creditAsset?.decimals ?? 0).toString() ?? '0'

    const payload = await createProposalPayload({
      ...proposal,
      creditAmount: proposal.creditAsset.amount,
      minCollateralAmount: calculateCollateralAmountFungibleProposal({
        creditPerCollateralUnit,
        collateralDecimals: proposal.collateralAsset?.decimals ?? 0,
        availableCreditLimit: minCreditAmountRaw,
        returnBigInt: true,
      }).toString(),
      availableCreditLimit: proposal.creditAsset?.amount,
      creditPerCollateralUnit,
      sourceOfFunds: proposal.sourceOfFunds,
    }, true, V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL)

    const parsedValue = await ProposalFactory.createProposalFromBackendModel(
      payload,
      proposal.collateralAsset,
      proposal.creditAsset,
    )
    return {
      payload,
      decoded: parsedValue,
    }
  }
  const getThirdPartyProposalSteps = (asset: AssetWithAmount) => {
    const steps: ToastStep[] = []
    // approve aToken, spender: PoolAdapter, amount: fixed amount
    steps.push(
      new ToastStep({
        text: `Approving ${asset.protocol?.toUpperCase()} Asset...`,
        async fn(step) {
          const pool = asset.sourceOfFunds
          if (!pool) return false
          const poolAdapter = await getPoolAdapter(pwnWagmiConfig, {
            address: CHAINS_CONSTANTS[String(asset.chainId)].pwnV1_2Contracts.pwnConfig,
            chainId: asset.chainId,
            args: [pool],
          })

          const aToken = await useMetadataFetch().fetchErc20Metadata({
            chainId: asset.chainId as SupportedChain,
            contractAddress: asset.aTokenAddress as Address,
          })

          if (!aToken || !poolAdapter || !asset.amount) return false

          if (asset.protocol === DepositProtocol.COMPOUND) {
            const isAllowed = await compoundCometAllow(pwnWagmiConfig, {
              address: pool,
              chainId: asset.chainId,
              args: [poolAdapter, true],
            })
            return !!isAllowed
          }
          if (asset.protocol === DepositProtocol.AAVE) {
            return await useApprove().approve({
              asset: aToken,
              spender: poolAdapter,
              amount: maxUint256,
            }, step)
          } else return false
        },
      }),
    )
    return steps
  }
  const { checkPreviousApprovals, approveThesisAssets } = useApproveThesisAssets()

  const issueAndAssignNonces = async (
    proposals: Proposals[],
  ) => {
    const chainId = proposals[0].chainId
    const proposerAddress = proposals[0].proposer

    const freeUserNonceData = await freeUserNonceRetrieve(
      String(chainId),
      CHAINS_CONSTANTS[chainId].pwnV1_2Contracts.pwnRevokedNonce,
      proposerAddress,
      {
        nonce_count_to_reserve: proposals.length,
      },
    )

    if (!freeUserNonceData.data) {
      throw new Error(freeUserNonceData.data)
    }

    const res: Proposals[] = []

    proposals.forEach((proposal, index) => {
      const nonce = freeUserNonceData.data.freeUserNonces[index]
      const nonceSpace = freeUserNonceData.data.freeUserNonceSpace

      proposal.nonce = BigInt(nonce)
      proposal.nonceSpace = BigInt(nonceSpace)

      res.push(proposal)
    })

    return res
  }

  const populateThesisMutation = useMutation<undefined, DefaultError, {
    values: CreateProposalFormValueCustom[],
    previousNonces?: Partial<Record<SupportedChain, bigint[]>>
  }>({
    mutationKey: ['createMultiProposal'],
    mutationFn: async ({ values, previousNonces }) => {
      const encodedProposals = await Promise.all(values.map(getPayload))
      const creatingSingleProposal = values.length === 1

      const assetsForApproval = encodedProposals.map(({ decoded: proposal }) => proposal.creditAsset)
      const uniqueAssetsForApproval: AssetWithAmount[] = []
      const toastSteps: ToastStep[] = []

      const assetsSofForApproval: AssetWithAmount[] = []
      const toast = ref<Toast>()

      for (const asset of assetsForApproval) {
        if (!uniqueAssetsForApproval.some(a => compareAssets({
          assetA: a,
          assetB: asset,
        }))) {
          uniqueAssetsForApproval.push(asset)
        }
        if (asset.sourceOfFunds && asset.aTokenAddress && asset.protocol && !assetsSofForApproval.some(a => { return compareAddresses(a.aTokenAddress, asset.aTokenAddress) })) {
          assetsSofForApproval.push(asset)
        }
      }

      // Group proposals by chain
      const proposalsByChain = encodedProposals.reduce((acc, proposal) => {
        const chainId = proposal.decoded.chainId
        if (!acc[chainId]) {
          acc[chainId] = []
        }
        acc[chainId].push(proposal)
        return acc
      }, {} as Record<SupportedChain, typeof encodedProposals>)
      const proposer = encodedProposals[0].decoded.proposer
      const approvals: Awaited<ReturnType<typeof checkPreviousApprovals>> = []

      for (const asset of assetsSofForApproval) {
        if (!isStarknet) {
          const poolAdapter = await getPoolAdapter(pwnWagmiConfig, {
            address: CHAINS_CONSTANTS[String(asset.chainId)].pwnV1_2Contracts.pwnConfig,
            chainId: asset.chainId,
            args: [asset.sourceOfFunds!],
          })
          if (!poolAdapter) {
            throw new Error(`Pool Adapter not found for ${asset.sourceOfFunds}`)
          }
          const proposerAtokenAllowancePromise = await readErc20Allowance(pwnWagmiConfig, {
            address: asset.aTokenAddress!,
            chainId: asset.chainId,
            args: [proposer, poolAdapter],
          })

          if (proposerAtokenAllowancePromise < asset.amountRaw) {
            const steps = getThirdPartyProposalSteps(asset)
            toastSteps.push(...steps)
          }
        }
      }

      for (const [chainId, chainProposals] of Object.entries(proposalsByChain)) {
        const spenderAddress = CHAINS_CONSTANTS[chainId].pwnV1_2Contracts?.pwnSimpleLoan
        const chainAssets = chainProposals.map(({ decoded: proposal }) => proposal.creditAsset)
        const chainUniqueAssets = chainAssets.filter((asset, index, self) =>
          index === self.findIndex(a => compareAssets({ assetA: a, assetB: asset })),
        )
        const chainApprovals = await checkPreviousApprovals(chainUniqueAssets, spenderAddress)
        approvals.push(...chainApprovals)
      }

      const approvalsGroupedByChain = approvals.reduce((acc, approval) => {
        const chainId = approval.asset.chainId
        if (!acc[chainId]) {
          acc[chainId] = []
        }
        acc[chainId].push(approval)
        return acc
      }, {} as Record<SupportedChain, typeof approvals>)

      Object.values(approvalsGroupedByChain).forEach((approvals) => {
        const nonApprovedAssets = approvals.filter(approval => !approval.isApproved).map(approval => approval.asset)
        if (nonApprovedAssets.length === 0) {
          return
        }

        const chainId = nonApprovedAssets[0].chainId
        const spenderAddress = CHAINS_CONSTANTS[chainId].pwnV1_2Contracts?.pwnSimpleLoan

        const steps = approveThesisAssets(nonApprovedAssets, spenderAddress, chainId)
        toastSteps.push(...steps)
      })

      let proposalHashes: Hash[] = []
      let proposals: V1_2SimpleLoanFungibleProposal[] = []

      type MultiProposalResult = Awaited<ReturnType<typeof V1_2SimpleLoanSimpleProposalContract.createMultiProposalHashAndSignature>>

      let signedData: MultiProposalResult | { proposalHash: Hex, signature: Hex } | undefined
      let tree: SimpleMerkleTree | starknet.merkle.MerkleTree |undefined

      if (previousNonces && Object.values(previousNonces)?.length) {
        for (const chainId of Object.keys(previousNonces)) {
          if (previousNonces[chainId].length === 0) {
            continue
          }
          toastSteps.push(new ToastStep({
            text: 'Revoking previous proposals',
            async fn(step) {
              if (isStarknet) {
                return await revokeStarknetNonces(previousNonces[chainId])
              }
              const revokeContractAddress = CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnRevokedNonce
              const receipt = await sendTransaction(
                {
                  address: revokeContractAddress,
                  abi: pwnV1_2RevokedNonceAbi,
                  functionName: 'revokeNonces',
                  args: [previousNonces[chainId]],
                  chainId: Number(chainId),
                },
              )

              return receipt.status === 'success'
            },
          }))
        }
      }

      const chainId = encodedProposals[0].decoded.chainId

      if (creatingSingleProposal) {
        toastSteps.push(new ToastStep({
          text: 'Signing the proposal',
          async fn(step) {
            const proposal = encodedProposals[0].decoded as V1_2SimpleLoanFungibleProposal
            signedData = await proposal.proposalContract.createProposalHashAndSignature(proposal)

            proposalHashes = [signedData.proposalHash]
            proposals = [proposal]

            return true
          },
        }))
      } else {
        toastSteps.push(new ToastStep({
          text: 'Constructing the merkle tree',
          async fn(step) {
            proposals = await issueAndAssignNonces(encodedProposals.map(({ decoded }) => decoded as V1_2SimpleLoanFungibleProposal))
            const res = await Promise.all(
              proposals.map((v) => v.proposalContract.createProposalHash(v)),
            )
            proposalHashes = res.map(v => v.proposalHash)
            const leaves = proposalHashes.map((hash) => hash)

            if (leaves.length === 0) {
              throw new Error('No proposals to create')
            }
            if (isStarknet) {
              // const customHashMethod = (a: starknet.BigNumberish, b: starknet.BigNumberish) => {
              // // Order inputs to ensure consistent hashing (matching contract's if a < b logic)
              //   const [min, max] = BigInt(a) < BigInt(b) ? [a, b] : [b, a]

              //   // Use starknetKeccak directly on the concatenated string representation
              //   // This matches the contract's implementation more closely
              //   const combined = `${min}${max}`
              //   const hashValue = starknet.hash.starknetKeccak(combined)

              //   return hashValue.toString()
              // }

              tree = new starknet.merkle.MerkleTree(proposalHashes)
              return true
            }
            tree = SimpleMerkleTree.of(proposalHashes)
            return true
          },
        }))

        toastSteps.push(new ToastStep({
          text: 'Signing the multiproposal',
          async fn(step) {
            if (!tree) {
              throw new Error('Tree not created')
            }

            signedData = await V1_2SimpleLoanSimpleProposalContract.createMultiProposalHashAndSignature(tree.root, chainId)

            return true
          },
        }))
      }

      if (!isStarknet) {
        toastSteps.push(new ToastStep({
          text: 'Signing the multiproposal',
          async fn(step) {
            if (!tree) {
              throw new Error('Tree not created')
            }

            signedData = await V1_2SimpleLoanSimpleProposalContract.createMultiProposalHashAndSignature(tree.root, chainId)

            return true
          },
        }))
      }

      toastSteps.push(new ToastStep({
        text: 'Creating the proposals',
        async fn(step) {
          if (isStarknet) {
            const proposalStructs = await Promise.all(proposals.map(p => p.proposalContract.createProposalStruct(p)))
            await batchCreateStarknetFungibleProposals(proposalStructs)
          }
          if ((!signedData && !isStarknet) || !tree || !proposals) {
            throw new Error('Signed data not created')
          }
          const mustIncludeMerkleRoot = !creatingSingleProposal
          const proposalsWithMerkleProof = encodedProposals.map(({ payload: p }, i) => {
            const proposalParsed = proposals[i]
            const proposalHash = proposalHashes[i]

            const proposalNonce = proposalParsed.nonce
            const proposalNonceSpace = proposalParsed.nonceSpace

            const signature = isStarknet ? '0x0' : signedData!.signature

            const res = {
              ...p,
              available_credit_limit: String(p?.available_credit_limit),
              credit_per_collateral_unit: proposalParsed.creditPerCollateralUnit,
              min_collateral_amount: String(proposalParsed.minCollateralAmount),
              multiproposal_merkle_root: mustIncludeMerkleRoot ? tree!.root : '0x0000000000000000000000000000000000000000000000000000000000000000', // TODO: make sure this is ok for starknet
              nonce: String(proposalNonce),
              nonce_space: String(proposalNonceSpace),
              hash: proposalHash,
              signature,
              related_thesis_id: String(unref(thesis).id),
            } as unknown as CreateSimpleLoanFungibleProposalRequestSchemaRequestBackendSchema
            return res
          })
          await proposalCreateBatch(proposalsWithMerkleProof)

          trackEvent({ name: EventNames.ThesisCreated })

          return true
        },
      }))

      toast.value = new Toast({
        title: 'Creating the proposals',
        chainId,
        steps: toastSteps,
        firstAsset: thesisCollateralAssets[0],
      }, NotificationFrontendOnlyActionEnum.THESES_CREATED, unref(thesis))

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const { continueFlow } = useActionFlow(toast as Ref<Toast>)

      await continueFlow()
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries({
        queryKey: getProposalAndLoanListQueryKey({ isOffer: true }),
      })
      onClose?.()
    },
  })

  return {
    populateThesisMutation,
    thesis,
    getProposalsToCreate,
    checkCollateralKyc,
  }
}
