<template>
  <div class="create-new-pwn-safe-input">
    <div class="create-new-pwn-safe-input__input-label-container">
      <label class="create-new-pwn-safe-input__input-label">
        PWN Safe Name
      </label>
    </div>
    <div class="create-new-pwn-safe-input__pwn-safe-input">
      <BaseInput
        v-model="safeName"
        placeholder="Type PWN Safe name"
        :is-valid-input="isValidInput"
        :append-text="`${safeName.length}/${MAX_SAFE_NAME_LENGTH}`"
        warning-text="Name too long"/>
    </div>
  </div>
  <div class="create-new-pwn-safe-input">
    <div class="create-new-pwn-safe-input__input-label-container">
      <label class="create-new-pwn-safe-input__input-label">
        Network
      </label>
    </div>
    <div class="create-new-pwn-safe-input__pwn-safe-input">
      <ChainGroupFilter
        :set-chain="handleSelectSingleChain"
        :selected-chains="[chainId!]"
        :disabled-chains="pwnSafeUnsupportedChains"
        :show-all-networks="false"/>
    </div>
  </div>
  <CreateNewPwnSafeFooter
    button-text="Continue"
    :is-disabled="isSubmitDisabled"
    @on-button-click="submitName(router)"/>
</template>

<script setup lang="ts">
import BaseInput from '@/general-components/BaseInput.vue'
import CreateNewPwnSafeFooter from '@/modules/pages/pwn-safe/create-new-pwn-safe/CreateNewPwnSafeFooter.vue'
import useCreateNewPwnSafe from '@/modules/pages/pwn-safe/create-new-pwn-safe/useCreateNewPwnSafe'
import router from '@/router'
import { computed } from 'vue'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'

const { safeName, MAX_SAFE_NAME_LENGTH, submitName, chainId } = useCreateNewPwnSafe()
const isValidInput = computed(() => safeName.value.length <= MAX_SAFE_NAME_LENGTH)
const isSubmitDisabled = computed(() => safeName.value.length > MAX_SAFE_NAME_LENGTH || safeName.value.length < 1 || !chainId.value)

const pwnSafeUnsupportedChains = computed(() => {
  const results = []
  for (const config of Object.values(CHAINS_CONSTANTS)) {
    if (!config?.pwnSafeContracts?.pwnSafeFactory) {
      // @ts-expect-error FIXME: strictNullChecks
      results.push(config.general.chainId)
    }
  }
  return results
})

const handleSelectSingleChain = (selectedChainId: SetChainFilterOption) => {
  if (selectedChainId === 'all') return
  chainId.value = selectedChainId
}

</script>

<style scoped>
.create-new-pwn-safe-input {
  &__input-label-container {
    margin-top: 3rem;
    margin-bottom: 0.5rem;
  }

  &__input-label {
    font-size: 1.2rem;
    font-family: var(--font-family-autoscape);
  }

  &__pwn-safe-input {
    width: 50%;
  }

  @media only screen and (--mobile-viewport) {
    &__pwn-safe-input {
      width: 100%;
    }
  }
}
</style>
