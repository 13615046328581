import { ref } from 'vue'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import { SORT_OPTIONS_LOOKUP, SortOption } from '@/modules/sections/your-assets/your-nfts/YourNFTsDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { filterAssetsInSearchResults } from '@/utils/search'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

const selectedSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-your-nfts',
  { id: SortOption.Name, direction: SortDirection.Descending, viewName: 'sort-option-your-nfts' },
  Object.keys(SORT_OPTIONS_LOOKUP),
))
const searchTerm = ref('')
const userAddressToDisplay = ref<string>()

export default function useYourNFTs() {
  const getSortedAndFilteredNFTs = (nfts: AssetWithAmount[], searchTerm: string, selectedSorting: ActiveSortOption) => {
    let resultNfts = filterAssetsInSearchResults(nfts, searchTerm)
    resultNfts = useGlobalFiltersStore().applyShowUnverifiedAssetsFilter(resultNfts)
    return SORT_OPTIONS_LOOKUP[selectedSorting.id](resultNfts, selectedSorting.direction)
  }

  return {
    selectedSortOption,
    searchTerm,
    userAddressToDisplay,
    getSortedAndFilteredNFTs,
  }
}
