import { SupportedChain } from '@/constants/chains/types'
import { PWN_FAUCET_PWNS_SEPOLIA_ADDRESS } from '@/modules/common/assets/fakeAppraisals'
import { getAddress } from 'viem'

export const RESOLV_ASSETS = {
  [SupportedChain.Ethereum]: [
    '0x1202F5C7b4B9E47a1A484E8B270be34dbbC75055', // wstUSR
    '0x4956b52aE2fF65D74CA2d61207523288e4528f96', // RLP
    '0x6c8984bc7DBBeDAf4F6b2FD766f16eBB7d10AAb4', // stUSR
    '0x66a1e37c9b0eaddca17d3662d6c05f4decf3e110', // USR
  ],
  [SupportedChain.Sepolia]: [ // TODO remove this, so far only for testing!
    PWN_FAUCET_PWNS_SEPOLIA_ADDRESS,
  ],
} as const

export const RESOLV_ADDRESS_MULTIPLIER = {
  [SupportedChain.Ethereum]: {
    [getAddress('0x66a1e37c9b0eaddca17d3662d6c05f4decf3e110')]: 22.5,
    [getAddress('0x1202F5C7b4B9E47a1A484E8B270be34dbbC75055')]: 7.5,
    [getAddress('0x6c8984bc7DBBeDAf4F6b2FD766f16eBB7d10AAb4')]: 7.5,
    [getAddress('0x4956b52aE2fF65D74CA2d61207523288e4528f96')]: 15,
  },
  [SupportedChain.Sepolia]: 22.5,
}
