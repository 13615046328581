<template>
  <button
    v-if="isInNotification"
    class="claim-button__only-text"
    @click="handleClaimLoan">
    {{ `Claim ${isDefaulted ? 'Collateral' : ''}` }}
  </button>

  <button
    v-else-if="!isInTable"
    :disabled="isButtonDisabled"
    :class="['claim-button', { 'claim-button__button': hasClaimButton, 'claim-button__as_accruing-interest': !hasClaimButton}]"
    @click="handleClaimLoan">
    <span>
      {{ hasClaimButton ? `Claim ${isDefaulted ? 'Collateral' : ''}` : props.loanData?.creditData.accruingInterestApr !== 0 ? 'Accruing Interest' : 'Interest' }}
    </span>
    <AssetAmount
      v-if="props.loanData && amountToClaim && !isDefaulted"
      class="claim-button__token-and-appraisal"
      :asset="props.loanData?.creditAsset"
      is-display-symbol
      :asset-amount="amountToClaim.toString()"/>
  </button>

  <div v-else-if="isInTable">
    <BaseButton
      :variant="ButtonVariant.Outline"
      is-no-enlarge-effect
      is-full-width
      class="claim-button__button-in-table"
      :is-disabled="isButtonDisabled"
      :size="ButtonSize.M"
      button-text="Claim"
      @click.stop
      @on-button-click="handleClaimLoan"/>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import V1_2SimpleLoanContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanContract'
import { Toast, TOAST_ACTION_ID_TO_UNIQUE_ID_FN, ToastStep } from '@/modules/common/notifications/useToastsStore'
import {
  NotificationActionEnumBackendSchema,
} from '@/modules/common/backend/generated'
import type { LoanDetailSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import type { Ref } from 'vue'
import BaseButton, { ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import AssetAmount from '@/revamp/components/AssetAmount.vue'
import { pwnV1SimpleLoanAbi, readPwnV1_2SimpleLoanLoanRepaymentAmount } from '@/contracts/generated'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { Address } from 'viem'
import { compareAddresses } from '@/utils/utils'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

type Props = {
  loanId: string | number
  chainIdWhereIsLoan: SupportedChain
  isInTable?: boolean
  hasClaimButton?: boolean
  loanData?: LoanDetailSchemaWorkaroundBackendSchema
  isInNotification?: boolean
}

const props = defineProps<Props>()
const loanId = computed(() => props.loanId)
const chainIdWhereIsLoan = computed(() => props.chainIdWhereIsLoan)
const loanContractAddress = computed(() => props.loanData?.contractAddress as Address ?? '0x missing contract address' as Address)
const isV1_1Loan = computed(() => compareAddresses(loanContractAddress.value, CHAINS_CONSTANTS[chainIdWhereIsLoan.value]?.pwnV1Contracts?.pwnSimpleLoan))

const { chainId: connectedChainId, address: userAddress } = useCustomAccount()

const isDefaulted = computed(() => props.loanData?.status === LoanStatus.Defaulted)

const { isPending: isPendingClaimAction, mutateAsync: claimLoanMutationAsync } = useMutation({
  mutationKey: ['claimLoanMutationAsync', loanId],
  mutationFn: async (step: ToastStep) => {
    // TODO test
    if (isV1_1Loan.value) {
      return await sendTransaction({
        abi: pwnV1SimpleLoanAbi,
        functionName: 'claimLOAN',
        args: [BigInt(loanId.value)],
        address: loanContractAddress.value,
        chainId: chainIdWhereIsLoan.value,
      }, { step })
    }
    const loanContract = new V1_2SimpleLoanContract()
    await loanContract.claimLoan({ onChainId: String(loanId.value), chainId: chainIdWhereIsLoan.value })
  },
})

const isButtonDisabled = computed(() => {
  if (!userAddress.value || isPendingClaimAction.value || !props.hasClaimButton) return true
  return false
})

const { data: amountToClaim } = useQuery({
  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  queryKey: ['repayLoanApprovalAndBalanceCheck', loanId, userAddress, chainIdWhereIsLoan, loanContractAddress],
  queryFn: async () => {
    if (!chainIdWhereIsLoan.value || !loanContractAddress.value) return null
    // TODO test this
    if (isV1_1Loan.value) return BigInt(props.loanData?.creditData?.totalRepaymentAmount ?? 0)
    return await readPwnV1_2SimpleLoanLoanRepaymentAmount(pwnWagmiConfig, {
      address: loanContractAddress.value,
      chainId: Number(chainIdWhereIsLoan.value) as SupportedChain,
      args: [BigInt(loanId.value)],
    })
  },
})

const handleClaimLoan = async () => {
  let continueFlow: () => Promise<void>
  const toast = ref<Toast>()
  const getCreateLoanToastSteps = () => {
    const toastSteps: ToastStep[] = []

    if (connectedChainId.value !== chainIdWhereIsLoan.value) {
      toastSteps.push(ToastStep.createSwitchChainStep(chainIdWhereIsLoan.value))
    }

    toastSteps.push(new ToastStep({
      text: 'Claim',
      async fn(step) {
        await claimLoanMutationAsync(step)
        return true
      },
    }))

    return toastSteps
  }
  const handleClaimLoan = async () => {
    const notificationType = props.loanData?.status === LoanStatus.Defaulted ? NotificationActionEnumBackendSchema.LOAN_COLLATERAL_CLAIMED : NotificationActionEnumBackendSchema.LOAN_PAYMENT_CLAIMED
    const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[notificationType](String(loanId.value))
    if (toast.value?.id !== actionId) {
      toast.value = new Toast({
        title: `Claim ${props.loanData?.status === LoanStatus.Defaulted ? 'Collateral' : 'Payment'} of Loan #${loanId.value}`,
        chainId: chainIdWhereIsLoan.value,
        steps: getCreateLoanToastSteps(),
        firstAsset: isDefaulted.value ? new AssetWithAmount({ image: props.loanData?.collateral.thumbnailUrl }) : new AssetWithAmount({ image: props.loanData?.creditAsset.thumbnailUrl }),
      }, notificationType, String(loanId.value));
      ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
    }

    await continueFlow()
  }
  await handleClaimLoan()
  // invalidate and re-fetch queries are handled by sseListener
}

</script>

<style scoped>
.claim-button {
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  &__button {
    cursor: pointer;
    border: 1px solid var(--teal-1, #00ffe0);
    background: var(--primary-color-5, #0f2926);
    color: var(--primary-color-1);

    &:disabled {
      opacity: 0.5;
    }

    &:hover:not(:disabled) {
      background: var(--primary-color-3, #0f2926);
    }
  }

  &__as_accruing-interest {
    border: 1px solid var(--gray, #696678);
    background: var(--gray-3, #222);
    color: var(--text-color);
    font-weight: 400;
    font-family: var(--font-family-screener);
  }

  &__button-in-table {
    margin-right: 0.3rem;
  }

  &__token-and-appraisal {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: flex-end;

    .asset-amount__appraisal {
      margin-right: 0;
    }
  }

  &__token {
    color: var(--text-color);
    gap: 0.5rem;
    font-size: 14px;
    font-weight: 400;
  }

  &__tooltip {
    height: 4rem;
  }

  &__appraisal {
    color: var(--gray);
    font-size: 12px;
    font-weight: 400;
    text-align: end;
  }

  &__only-text {
    all: unset;
    color: var(--primary-color-1);
    font-family: var(--font-family-supreme);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
    margin: 0.2rem 0 0.5rem;
    cursor: pointer;
    width: fit-content;

    &:hover {
      opacity: 0.7;
    }
  }
}

</style>

<style lang="postcss">
.claim-button {
  &__token-and-appraisal {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: flex-end;

    .asset-amount__appraisal {
      margin-right: 0;
    }
  }
}
</style>
