<template>
  <div class="the-wallet-header">
    <UserInfo
      :address="userAddress!"
      is-small
      :has-avatar="true"/>
    <div class="the-wallet-header__actions">
      <SettingsIconSvg
        :class="['the-wallet-header__action-icon the-wallet-header__action-icon--settings', { 'the-wallet-header__action-icon--active': isOpenTabNotificationsSettings }]"
        alt="notification settings"
        @click="handleToggleNotificationSettings"/>
      <MegaphoneSvg
        class="the-wallet-header__action-icon the-wallet-header__action-icon--announcements"
        alt="announcements"
        @click="handleOpenAnnouncement"/>
      <DisconnectIconSvg
        class="the-wallet-header__action-icon the-wallet-header__action-icon--disconnect"
        alt="disconnect"
        @click="() => handleDisconnect()"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import UserInfo from '@/modules/pages/dashboard/UserInfo.vue'
import MegaphoneSvg from '@/assets/icons/megaphone.svg'
import DisconnectIconSvg from '@/assets/icons/disconnect.svg'
import SettingsIconSvg from '@/assets/icons/settings.svg'
import { useWhatIsNewModalStore } from '@/general-components/what-is-new-modal/useWhatIsNewModalStore'
import { useCustomConnect } from '@/modules/common/web3/useCustomConnect'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { handleDisconnect } = useCustomConnect()
const storeWhatIsNew = useWhatIsNewModalStore()
const { address: userAddress } = useCustomAccount()

type Props = {
  isOpenTabNotificationsSettings?: boolean
}
defineProps<Props>()

const emit = defineEmits<{(e: 'toggle-notifications-setting'): void }>()

const handleOpenAnnouncement = () => {
  storeWhatIsNew.isOpen = true
}

const handleToggleNotificationSettings = () => {
  emit('toggle-notifications-setting')
}

</script>

<style scoped>
.the-wallet-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;

  &__actions {
    display: flex;
    gap: 0.5rem;

    @media only screen and (max-width: 391px) {
      flex-direction: column-reverse;
    }
  }

  &__action-icon {
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.5rem;
    border: 1px solid #434343;
    cursor: pointer;

    --primary-color-1: var(--text-color);

    &--active {
      --primary-color-1: var(--primary-color);
      border-color: var(--primary-color-1);
    }

    &--settings,
    &--announcements {
      padding: 0.38rem;

      &:hover {
        border-color: var(--primary-color-1);

        --primary-color-1: var(--primary-color);
      }
    }

    &:hover:is(.the-wallet-header__action-icon--disconnect) {
      border-color: var(--danger-1);
    }
  }
}

</style>
