<template>
  <div class="tokenize-header-search-and-sort">
    <PwnSafePageHeader
      v-if="selectedPwnSafe"
      :label="selectedPwnSafe.name"
      :icon="safeIcon.default"
      :selected-pwn-safe="selectedPwnSafe"
      :address="selectedPwnSafe.safeAddress"
      @go-back="goBack"/>
    <PwnSafeActionHeader title="Mint ATR">
      <template #icons>
        <TokenizeIconSvg
          alt="mint_atr"
          class="tokenize-header-search-and-sort__icon"/>
      </template>
      <template #subheading>
        <div class="tokenize-header-search-and-sort__subheading">
          This allows you to mint an ATR Token and keep the asset in your PWN Safe while also keeping the benefits this asset provides.
          <span v-if="linkTo.pwnSafeVideoTutorial">
            To better understand Asset Transfer Rights please watch our
            <BaseLink
              class="link--primary"
              target="_blank"
              :to="linkTo.pwnSafeVideoTutorial">
              video tutorial
            </BaseLink>
          </span>
        </div>
      </template>
    </PwnSafeActionHeader>

    <div class="tokenize-header-search-and-sort__searching-and-sorting">
      <BaseSearch
        v-model="searchTerm"
        :has-clear-button="hasSearchValue"
        :class="['tokenize-header-search-and-sort__search', {'tokenize-header-search-and-sort__search--selected': hasSearchValue}]"
        search-placeholder="Search asset name/ATR name/address"/>

      <div class="tokenize-header-search-and-sort__actions">
        <RefreshAssets
          :show-text="false"
          :address="userAddress"/>

        <ChainGroupFilter
          :selected-chains="[props.selectedChain]"
          :disabled-chains="disabledChainsDependingOnSelectedAssetsChain"
          :show-all-networks="false"
          :set-chain="selectedCurrentWallet ? props.setSelectedChain : undefined"/>
      </div>
    </div>

    <BaseEmptyStateText
      v-if="hasNoSearchResults"
      class="tokenize-header-search-and-sort__no-search-results"
      :text="getEmptyStateText"/>
  </div>
</template>

<script setup lang="ts">
import PwnSafePageHeader from '@/modules/pages/pwn-safe/PwnSafePageHeader.vue'
import { computed } from 'vue'
import * as safeIcon from '@/assets/icons/safe.svg'
import TokenizeIconSvg from '@/assets/icons/tokenize.svg'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import PwnSafeActionHeader from '@/modules/pages/pwn-safe/PwnSafeActionHeader.vue'
import BaseSearch from '@/general-components/BaseSearch.vue'
import usePwnSafeTokenizeCoinsAndNfts from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenizeCoinsAndNfts'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import BaseLink from '@/general-components/BaseLink.vue'
import linkTo from '@/constants/links'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import RefreshAssets from '@/modules/pages/dashboard/RefreshAssets.vue'
import type { SupportedChain } from '@/constants/chains/types'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import { compareAddresses } from '@/utils/utils'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'
import { storeToRefs } from 'pinia'

type Props = {
  selectedChain: SupportedChain;
  setSelectedChain: (chain: SetChainFilterOption) => void;
  hasNoSearchResults: boolean;
}

const props = defineProps<Props>()

const { address: userAddress } = useCustomAccount()
const connectedAccountTypeStore = useConnectedAccountTypeStore()
const { isConnectedPwnSafe } = storeToRefs(connectedAccountTypeStore)

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe, disabledChains } = storeToRefs(pwnSafeDetailStore)

const { searchTerm } = usePwnSafeTokenizeCoinsAndNfts()
const router = useRouter()
const { chainTarget } = usePwnSafeTokenize()

const selectedCurrentWallet = computed(() => {
  return compareAddresses(selectedPwnSafe.value?.safeAddress, userAddress.value)
})

const getEmptyStateText = computed(() => `No results for your search "${searchTerm.value}"`)
const hasSearchValue = computed(() => searchTerm.value.length > 0)

const disabledChainsDependingOnSelectedAssetsChain = computed(() => {
  if (chainTarget.value) {
    return enabledChains.filter(chain => chain !== chainTarget.value)
  }
  return disabledChains.value.filter(chain => chain !== props.selectedChain)
})

const goBack = () => {
  // @ts-expect-error FIXME: strictNullChecks
  const pwnSafeRoute = { name: RouteName.PwnSafeDetailAssets, params: { safeAddress: selectedPwnSafe.value.safeAddress, chainId: selectedPwnSafe.value.chainId } }
  const currentWalletRoute = { name: RouteName.PwnSafeCurrentWalletAssets }

  // @ts-expect-error FIXME: strictNullChecks
  const goToCurrentWalletRoute = selectedPwnSafe.value.safeAddress === userAddress.value && !isConnectedPwnSafe.value

  const route = goToCurrentWalletRoute ? currentWalletRoute : pwnSafeRoute
  router.push(route)
}
</script>

<style scoped>
.tokenize-header-search-and-sort {
  &__searching-and-sorting {
    margin: 1rem 0 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
  }

  &__search {
    width: 30%;
    border: 1px solid var(--gray);
    height: 2.5rem;
    font-size: 0.75rem;
    padding: 0.25rem;

    &--selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__no-search-results {
    min-height: 23.1875rem;
  }

  &__icon {
    width: 2.375rem;
  }
}
</style>
