/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useInfiniteQuery,
  useQuery
} from '@tanstack/vue-query'
import type {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AssetDetailBackendSchema,
  AssetPriceAndTaskStatusSchemaBackendSchema,
  AssetPriceHistoryBackendSchema,
  AssetPriceSchemaWithAssetMetadataBackendSchema,
  AssetTokensInTokenListListParams,
  AssetTopCollectionsListExternalParams,
  AssetTopCollectionsListParams,
  AssetTopTokensListExternalParams,
  AssetTopTokensListParams,
  CollectionStatsBackendSchema,
  ERC20AssetSchemaWithBalanceBackendSchema,
  ERC20AssetStatsSchemaBackendSchema,
  ERC20OHLCDataBackendSchema,
  FetchAllOffersParams,
  FetchAssetMetadataParams,
  FetchAssetPriceParams,
  FetchCollectionNFTsParams,
  FetchCollectionNFTsResponseBackendSchema,
  FetchCollectionOffersParams,
  FetchCollectionStatsParams,
  FetchErc20OHLCParams,
  FetchErc20PriceParams,
  FetchNFTAssetCollectionMetadata2Params,
  FetchNFTAssetCollectionMetadataParams,
  FetchNFTEventsResponseBackendSchema,
  FetchNFTOrdersBackendSchema,
  FetchNFTPriceStatsParams,
  FetchNftListingsParams,
  FetchNftOffersParams,
  FetchNftPriceParams,
  FetchNftSaleEventsParams,
  FetchNftTransferAndMintEventsParams,
  FetchTopCollectionsResponseBackendSchema,
  FetchTraitOffersParams,
  FetchUserErc20sParams,
  FetchUserNFTsResponseBackendSchema,
  FetchUserNftsParams,
  FetchWalletAssetsListParams,
  GetAssetCacheStateParams,
  GetCoingeckoUrl400,
  GetCoingeckoUrl404,
  GetCoingeckoUrl500,
  NFTAssetCollectionDetailSchemaBackendSchema,
  NFTPriceStatsSchemaBackendSchema,
  PaginatedAssetMetadataListListBackendSchema,
  PaginatedERC20AssetSchemaListBackendSchema,
  PaginatedNFTAssetCollectionSchemaListListBackendSchema,
  PaginatedWalletAssetsResponseWorkaroundListBackendSchema,
  PurgeUserERC20sCacheParams,
  PurgeUserNFTsCacheParams,
  SearchWalletAssetsParams,
  TopTokenModelBackendSchema,
  TraitSchemaBackendSchema,
  UserAssetsCacheStateBackendSchema,
  WalletAssetsResponseWorkaroundBackendSchema
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const fetchAllOffers = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchAllOffersParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
params = unref(params);
      
      return customInstance<FetchNFTOrdersBackendSchema>(
      {url: `/api/v1/asset/all-offers/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchAllOffersQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchAllOffersParams>,) => {
    return ['api','v1','asset','all-offers',chainId,contractAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchAllOffersQueryOptions = <TData = Awaited<ReturnType<typeof fetchAllOffers>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchAllOffersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchAllOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchAllOffersQueryKey(chainId,contractAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchAllOffers>>> = ({ signal }) => fetchAllOffers(chainId,contractAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchAllOffers>>, TError, TData> 
}

export type FetchAllOffersQueryResult = NonNullable<Awaited<ReturnType<typeof fetchAllOffers>>>
export type FetchAllOffersQueryError = unknown

export const useFetchAllOffers = <TData = Awaited<ReturnType<typeof fetchAllOffers>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchAllOffersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchAllOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchAllOffersQueryOptions(chainId,contractAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchAssetMetadata = (
    chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    tokenId?: MaybeRef<string>,
    params?: MaybeRef<FetchAssetMetadataParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
address = unref(address);
tokenId = unref(tokenId);
params = unref(params);
      
      return customInstance<AssetDetailBackendSchema>(
      {url: `/api/v1/asset/asset/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(address))}/${encodeURIComponent(String(tokenId))}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchAssetMetadataQueryKey = (chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    tokenId?: MaybeRef<string>,
    params?: MaybeRef<FetchAssetMetadataParams>,) => {
    return ['api','v1','asset','asset',chainId,address,tokenId, ...(params ? [params]: [])] as const;
    }

    
export const getFetchAssetMetadataQueryOptions = <TData = Awaited<ReturnType<typeof fetchAssetMetadata>>, TError = unknown>(chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    tokenId?: MaybeRef<string>,
    params?: MaybeRef<FetchAssetMetadataParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchAssetMetadata>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchAssetMetadataQueryKey(chainId,address,tokenId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchAssetMetadata>>> = ({ signal }) => fetchAssetMetadata(chainId,address,tokenId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(address) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchAssetMetadata>>, TError, TData> 
}

export type FetchAssetMetadataQueryResult = NonNullable<Awaited<ReturnType<typeof fetchAssetMetadata>>>
export type FetchAssetMetadataQueryError = unknown

export const useFetchAssetMetadata = <TData = Awaited<ReturnType<typeof fetchAssetMetadata>>, TError = unknown>(
 chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    tokenId?: MaybeRef<string>,
    params?: MaybeRef<FetchAssetMetadataParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchAssetMetadata>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchAssetMetadataQueryOptions(chainId,address,tokenId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const purgeTokenStatsCache = (
    chainId: MaybeRef<number>,
    address: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
address = unref(address);
      
      return customInstance<string>(
      {url: `/api/v1/asset/cache/purge/token-stats/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(address))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getPurgeTokenStatsCacheQueryKey = (chainId: MaybeRef<number>,
    address: MaybeRef<string>,) => {
    return ['api','v1','asset','cache','purge','token-stats',chainId,address] as const;
    }

    
export const getPurgeTokenStatsCacheQueryOptions = <TData = Awaited<ReturnType<typeof purgeTokenStatsCache>>, TError = unknown>(chainId: MaybeRef<number>,
    address: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTokenStatsCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPurgeTokenStatsCacheQueryKey(chainId,address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof purgeTokenStatsCache>>> = ({ signal }) => purgeTokenStatsCache(chainId,address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(address))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purgeTokenStatsCache>>, TError, TData> 
}

export type PurgeTokenStatsCacheQueryResult = NonNullable<Awaited<ReturnType<typeof purgeTokenStatsCache>>>
export type PurgeTokenStatsCacheQueryError = unknown

export const usePurgeTokenStatsCache = <TData = Awaited<ReturnType<typeof purgeTokenStatsCache>>, TError = unknown>(
 chainId: MaybeRef<number>,
    address: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTokenStatsCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPurgeTokenStatsCacheQueryOptions(chainId,address,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const assetCachePurgeTokensInTokenListRetrieve = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<string>(
      {url: `/api/v1/asset/cache/purge/tokens-in-token-list/`, method: 'GET', signal
    },
      options);
    }
  

export const getAssetCachePurgeTokensInTokenListRetrieveQueryKey = () => {
    return ['api','v1','asset','cache','purge','tokens-in-token-list'] as const;
    }

    
export const getAssetCachePurgeTokensInTokenListRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof assetCachePurgeTokensInTokenListRetrieve>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetCachePurgeTokensInTokenListRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetCachePurgeTokensInTokenListRetrieveQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetCachePurgeTokensInTokenListRetrieve>>> = ({ signal }) => assetCachePurgeTokensInTokenListRetrieve(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetCachePurgeTokensInTokenListRetrieve>>, TError, TData> 
}

export type AssetCachePurgeTokensInTokenListRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof assetCachePurgeTokensInTokenListRetrieve>>>
export type AssetCachePurgeTokensInTokenListRetrieveQueryError = unknown

export const useAssetCachePurgeTokensInTokenListRetrieve = <TData = Awaited<ReturnType<typeof assetCachePurgeTokensInTokenListRetrieve>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetCachePurgeTokensInTokenListRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetCachePurgeTokensInTokenListRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const purgeTopCollectionsCache = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<string>(
      {url: `/api/v1/asset/cache/purge/top-collections/`, method: 'GET', signal
    },
      options);
    }
  

export const getPurgeTopCollectionsCacheQueryKey = () => {
    return ['api','v1','asset','cache','purge','top-collections'] as const;
    }

    
export const getPurgeTopCollectionsCacheQueryOptions = <TData = Awaited<ReturnType<typeof purgeTopCollectionsCache>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTopCollectionsCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPurgeTopCollectionsCacheQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof purgeTopCollectionsCache>>> = ({ signal }) => purgeTopCollectionsCache(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purgeTopCollectionsCache>>, TError, TData> 
}

export type PurgeTopCollectionsCacheQueryResult = NonNullable<Awaited<ReturnType<typeof purgeTopCollectionsCache>>>
export type PurgeTopCollectionsCacheQueryError = unknown

export const usePurgeTopCollectionsCache = <TData = Awaited<ReturnType<typeof purgeTopCollectionsCache>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTopCollectionsCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPurgeTopCollectionsCacheQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const purgeTopCollectionsCacheExternal = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<string>(
      {url: `/api/v1/asset/cache/purge/top-collections-external/`, method: 'GET', signal
    },
      options);
    }
  

export const getPurgeTopCollectionsCacheExternalQueryKey = () => {
    return ['api','v1','asset','cache','purge','top-collections-external'] as const;
    }

    
export const getPurgeTopCollectionsCacheExternalQueryOptions = <TData = Awaited<ReturnType<typeof purgeTopCollectionsCacheExternal>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTopCollectionsCacheExternal>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPurgeTopCollectionsCacheExternalQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof purgeTopCollectionsCacheExternal>>> = ({ signal }) => purgeTopCollectionsCacheExternal(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purgeTopCollectionsCacheExternal>>, TError, TData> 
}

export type PurgeTopCollectionsCacheExternalQueryResult = NonNullable<Awaited<ReturnType<typeof purgeTopCollectionsCacheExternal>>>
export type PurgeTopCollectionsCacheExternalQueryError = unknown

export const usePurgeTopCollectionsCacheExternal = <TData = Awaited<ReturnType<typeof purgeTopCollectionsCacheExternal>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTopCollectionsCacheExternal>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPurgeTopCollectionsCacheExternalQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const purgeTopTokensCache = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<string>(
      {url: `/api/v1/asset/cache/purge/top-tokens/`, method: 'GET', signal
    },
      options);
    }
  

export const getPurgeTopTokensCacheQueryKey = () => {
    return ['api','v1','asset','cache','purge','top-tokens'] as const;
    }

    
export const getPurgeTopTokensCacheQueryOptions = <TData = Awaited<ReturnType<typeof purgeTopTokensCache>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTopTokensCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPurgeTopTokensCacheQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof purgeTopTokensCache>>> = ({ signal }) => purgeTopTokensCache(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purgeTopTokensCache>>, TError, TData> 
}

export type PurgeTopTokensCacheQueryResult = NonNullable<Awaited<ReturnType<typeof purgeTopTokensCache>>>
export type PurgeTopTokensCacheQueryError = unknown

export const usePurgeTopTokensCache = <TData = Awaited<ReturnType<typeof purgeTopTokensCache>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTopTokensCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPurgeTopTokensCacheQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const purgeTopTokensCacheExternal = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<string>(
      {url: `/api/v1/asset/cache/purge/top-tokens-external/`, method: 'GET', signal
    },
      options);
    }
  

export const getPurgeTopTokensCacheExternalQueryKey = () => {
    return ['api','v1','asset','cache','purge','top-tokens-external'] as const;
    }

    
export const getPurgeTopTokensCacheExternalQueryOptions = <TData = Awaited<ReturnType<typeof purgeTopTokensCacheExternal>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTopTokensCacheExternal>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPurgeTopTokensCacheExternalQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof purgeTopTokensCacheExternal>>> = ({ signal }) => purgeTopTokensCacheExternal(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purgeTopTokensCacheExternal>>, TError, TData> 
}

export type PurgeTopTokensCacheExternalQueryResult = NonNullable<Awaited<ReturnType<typeof purgeTopTokensCacheExternal>>>
export type PurgeTopTokensCacheExternalQueryError = unknown

export const usePurgeTopTokensCacheExternal = <TData = Awaited<ReturnType<typeof purgeTopTokensCacheExternal>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeTopTokensCacheExternal>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPurgeTopTokensCacheExternalQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const purgeUserERC20sCache = (
    params: MaybeRef<PurgeUserERC20sCacheParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<string>(
      {url: `/api/v1/asset/cache/purge/user-erc20s/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPurgeUserERC20sCacheQueryKey = (params: MaybeRef<PurgeUserERC20sCacheParams>,) => {
    return ['api','v1','asset','cache','purge','user-erc20s', ...(params ? [params]: [])] as const;
    }

    
export const getPurgeUserERC20sCacheQueryOptions = <TData = Awaited<ReturnType<typeof purgeUserERC20sCache>>, TError = unknown>(params: MaybeRef<PurgeUserERC20sCacheParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeUserERC20sCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPurgeUserERC20sCacheQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof purgeUserERC20sCache>>> = ({ signal }) => purgeUserERC20sCache(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purgeUserERC20sCache>>, TError, TData> 
}

export type PurgeUserERC20sCacheQueryResult = NonNullable<Awaited<ReturnType<typeof purgeUserERC20sCache>>>
export type PurgeUserERC20sCacheQueryError = unknown

export const usePurgeUserERC20sCache = <TData = Awaited<ReturnType<typeof purgeUserERC20sCache>>, TError = unknown>(
 params: MaybeRef<PurgeUserERC20sCacheParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeUserERC20sCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPurgeUserERC20sCacheQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const purgeUserNFTsCache = (
    params: MaybeRef<PurgeUserNFTsCacheParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<string>(
      {url: `/api/v1/asset/cache/purge/user-nfts/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPurgeUserNFTsCacheQueryKey = (params: MaybeRef<PurgeUserNFTsCacheParams>,) => {
    return ['api','v1','asset','cache','purge','user-nfts', ...(params ? [params]: [])] as const;
    }

    
export const getPurgeUserNFTsCacheQueryOptions = <TData = Awaited<ReturnType<typeof purgeUserNFTsCache>>, TError = unknown>(params: MaybeRef<PurgeUserNFTsCacheParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeUserNFTsCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPurgeUserNFTsCacheQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof purgeUserNFTsCache>>> = ({ signal }) => purgeUserNFTsCache(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof purgeUserNFTsCache>>, TError, TData> 
}

export type PurgeUserNFTsCacheQueryResult = NonNullable<Awaited<ReturnType<typeof purgeUserNFTsCache>>>
export type PurgeUserNFTsCacheQueryError = unknown

export const usePurgeUserNFTsCache = <TData = Awaited<ReturnType<typeof purgeUserNFTsCache>>, TError = unknown>(
 params: MaybeRef<PurgeUserNFTsCacheParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof purgeUserNFTsCache>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPurgeUserNFTsCacheQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getAssetCacheState = (
    params: MaybeRef<GetAssetCacheStateParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<UserAssetsCacheStateBackendSchema>(
      {url: `/api/v1/asset/cache/state/user-assets/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetAssetCacheStateQueryKey = (params: MaybeRef<GetAssetCacheStateParams>,) => {
    return ['api','v1','asset','cache','state','user-assets', ...(params ? [params]: [])] as const;
    }

    
export const getGetAssetCacheStateQueryOptions = <TData = Awaited<ReturnType<typeof getAssetCacheState>>, TError = unknown>(params: MaybeRef<GetAssetCacheStateParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetCacheState>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssetCacheStateQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetCacheState>>> = ({ signal }) => getAssetCacheState(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetCacheState>>, TError, TData> 
}

export type GetAssetCacheStateQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetCacheState>>>
export type GetAssetCacheStateQueryError = unknown

export const useGetAssetCacheState = <TData = Awaited<ReturnType<typeof getAssetCacheState>>, TError = unknown>(
 params: MaybeRef<GetAssetCacheStateParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetCacheState>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetCacheStateQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getCoingeckoUrl = (
    chainId: MaybeRef<number>,
    contractAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
      
      return customInstance<string>(
      {url: `/api/v1/asset/coingecko-url/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCoingeckoUrlQueryKey = (chainId: MaybeRef<number>,
    contractAddress: MaybeRef<string>,) => {
    return ['api','v1','asset','coingecko-url',chainId,contractAddress] as const;
    }

    
export const getGetCoingeckoUrlQueryOptions = <TData = Awaited<ReturnType<typeof getCoingeckoUrl>>, TError = GetCoingeckoUrl400 | GetCoingeckoUrl404 | GetCoingeckoUrl500>(chainId: MaybeRef<number>,
    contractAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoingeckoUrl>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetCoingeckoUrlQueryKey(chainId,contractAddress);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCoingeckoUrl>>> = ({ signal }) => getCoingeckoUrl(chainId,contractAddress, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCoingeckoUrl>>, TError, TData> 
}

export type GetCoingeckoUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getCoingeckoUrl>>>
export type GetCoingeckoUrlQueryError = GetCoingeckoUrl400 | GetCoingeckoUrl404 | GetCoingeckoUrl500

export const useGetCoingeckoUrl = <TData = Awaited<ReturnType<typeof getCoingeckoUrl>>, TError = GetCoingeckoUrl400 | GetCoingeckoUrl404 | GetCoingeckoUrl500>(
 chainId: MaybeRef<number>,
    contractAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoingeckoUrl>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCoingeckoUrlQueryOptions(chainId,contractAddress,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchCollectionOffers = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchCollectionOffersParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
params = unref(params);
      
      return customInstance<FetchNFTOrdersBackendSchema>(
      {url: `/api/v1/asset/collection-offers/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchCollectionOffersQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchCollectionOffersParams>,) => {
    return ['api','v1','asset','collection-offers',chainId,contractAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchCollectionOffersQueryOptions = <TData = Awaited<ReturnType<typeof fetchCollectionOffers>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchCollectionOffersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchCollectionOffersQueryKey(chainId,contractAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchCollectionOffers>>> = ({ signal }) => fetchCollectionOffers(chainId,contractAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionOffers>>, TError, TData> 
}

export type FetchCollectionOffersQueryResult = NonNullable<Awaited<ReturnType<typeof fetchCollectionOffers>>>
export type FetchCollectionOffersQueryError = unknown

export const useFetchCollectionOffers = <TData = Awaited<ReturnType<typeof fetchCollectionOffers>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchCollectionOffersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchCollectionOffersQueryOptions(chainId,contractAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchCollectionStats = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchCollectionStatsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
params = unref(params);
      
      return customInstance<CollectionStatsBackendSchema>(
      {url: `/api/v1/asset/collection-stats/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchCollectionStatsQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchCollectionStatsParams>,) => {
    return ['api','v1','asset','collection-stats',chainId,contractAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchCollectionStatsQueryOptions = <TData = Awaited<ReturnType<typeof fetchCollectionStats>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchCollectionStatsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionStats>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchCollectionStatsQueryKey(chainId,contractAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchCollectionStats>>> = ({ signal }) => fetchCollectionStats(chainId,contractAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionStats>>, TError, TData> 
}

export type FetchCollectionStatsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchCollectionStats>>>
export type FetchCollectionStatsQueryError = unknown

export const useFetchCollectionStats = <TData = Awaited<ReturnType<typeof fetchCollectionStats>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchCollectionStatsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionStats>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchCollectionStatsQueryOptions(chainId,contractAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNFTAssetCollectionMetadata2 = (
    chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    params?: MaybeRef<FetchNFTAssetCollectionMetadata2Params>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
address = unref(address);
params = unref(params);
      
      return customInstance<NFTAssetCollectionDetailSchemaBackendSchema>(
      {url: `/api/v1/asset/collection/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(address))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchNFTAssetCollectionMetadata2QueryKey = (chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    params?: MaybeRef<FetchNFTAssetCollectionMetadata2Params>,) => {
    return ['api','v1','asset','collection',chainId,address, ...(params ? [params]: [])] as const;
    }

    
export const getFetchNFTAssetCollectionMetadata2QueryOptions = <TData = Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata2>>, TError = unknown>(chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    params?: MaybeRef<FetchNFTAssetCollectionMetadata2Params>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata2>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNFTAssetCollectionMetadata2QueryKey(chainId,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata2>>> = ({ signal }) => fetchNFTAssetCollectionMetadata2(chainId,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(address))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata2>>, TError, TData> 
}

export type FetchNFTAssetCollectionMetadata2QueryResult = NonNullable<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata2>>>
export type FetchNFTAssetCollectionMetadata2QueryError = unknown

export const useFetchNFTAssetCollectionMetadata2 = <TData = Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata2>>, TError = unknown>(
 chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    params?: MaybeRef<FetchNFTAssetCollectionMetadata2Params>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata2>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNFTAssetCollectionMetadata2QueryOptions(chainId,address,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchCollectionNFTs = (
    chainId: MaybeRef<number>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchCollectionNFTsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
params = unref(params);
      
      return customInstance<FetchCollectionNFTsResponseBackendSchema>(
      {url: `/api/v1/asset/collection/assets/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchCollectionNFTsQueryKey = (chainId: MaybeRef<number>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchCollectionNFTsParams>,) => {
    return ['api','v1','asset','collection','assets',chainId,contractAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchCollectionNFTsQueryOptions = <TData = Awaited<ReturnType<typeof fetchCollectionNFTs>>, TError = unknown>(chainId: MaybeRef<number>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchCollectionNFTsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionNFTs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchCollectionNFTsQueryKey(chainId,contractAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchCollectionNFTs>>> = ({ signal }) => fetchCollectionNFTs(chainId,contractAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionNFTs>>, TError, TData> 
}

export type FetchCollectionNFTsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchCollectionNFTs>>>
export type FetchCollectionNFTsQueryError = unknown

export const useFetchCollectionNFTs = <TData = Awaited<ReturnType<typeof fetchCollectionNFTs>>, TError = unknown>(
 chainId: MaybeRef<number>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchCollectionNFTsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchCollectionNFTs>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchCollectionNFTsQueryOptions(chainId,contractAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNFTAssetCollectionMetadata = (
    openseaSlug: MaybeRef<string>,
    params?: MaybeRef<FetchNFTAssetCollectionMetadataParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      openseaSlug = unref(openseaSlug);
params = unref(params);
      
      return customInstance<NFTAssetCollectionDetailSchemaBackendSchema>(
      {url: `/api/v1/asset/collection/slug/${encodeURIComponent(String(openseaSlug))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchNFTAssetCollectionMetadataQueryKey = (openseaSlug: MaybeRef<string>,
    params?: MaybeRef<FetchNFTAssetCollectionMetadataParams>,) => {
    return ['api','v1','asset','collection','slug',openseaSlug, ...(params ? [params]: [])] as const;
    }

    
export const getFetchNFTAssetCollectionMetadataQueryOptions = <TData = Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata>>, TError = unknown>(openseaSlug: MaybeRef<string>,
    params?: MaybeRef<FetchNFTAssetCollectionMetadataParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNFTAssetCollectionMetadataQueryKey(openseaSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata>>> = ({ signal }) => fetchNFTAssetCollectionMetadata(openseaSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(openseaSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata>>, TError, TData> 
}

export type FetchNFTAssetCollectionMetadataQueryResult = NonNullable<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata>>>
export type FetchNFTAssetCollectionMetadataQueryError = unknown

export const useFetchNFTAssetCollectionMetadata = <TData = Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata>>, TError = unknown>(
 openseaSlug: MaybeRef<string>,
    params?: MaybeRef<FetchNFTAssetCollectionMetadataParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNFTAssetCollectionMetadata>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNFTAssetCollectionMetadataQueryOptions(openseaSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNftListings = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftListingsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
tokenId = unref(tokenId);
params = unref(params);
      
      return customInstance<FetchNFTOrdersBackendSchema>(
      {url: `/api/v1/asset/nft-listings/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(tokenId))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchNftListingsQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftListingsParams>,) => {
    return ['api','v1','asset','nft-listings',chainId,contractAddress,tokenId, ...(params ? [params]: [])] as const;
    }

    
export const getFetchNftListingsQueryOptions = <TData = Awaited<ReturnType<typeof fetchNftListings>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftListingsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftListings>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNftListingsQueryKey(chainId,contractAddress,tokenId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNftListings>>> = ({ signal }) => fetchNftListings(chainId,contractAddress,tokenId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNftListings>>, TError, TData> 
}

export type FetchNftListingsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchNftListings>>>
export type FetchNftListingsQueryError = unknown

export const useFetchNftListings = <TData = Awaited<ReturnType<typeof fetchNftListings>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftListingsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftListings>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNftListingsQueryOptions(chainId,contractAddress,tokenId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNftOffers = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftOffersParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
tokenId = unref(tokenId);
params = unref(params);
      
      return customInstance<FetchNFTOrdersBackendSchema>(
      {url: `/api/v1/asset/nft-offers/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(tokenId))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchNftOffersQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftOffersParams>,) => {
    return ['api','v1','asset','nft-offers',chainId,contractAddress,tokenId, ...(params ? [params]: [])] as const;
    }

    
export const getFetchNftOffersQueryOptions = <TData = Awaited<ReturnType<typeof fetchNftOffers>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftOffersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNftOffersQueryKey(chainId,contractAddress,tokenId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNftOffers>>> = ({ signal }) => fetchNftOffers(chainId,contractAddress,tokenId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNftOffers>>, TError, TData> 
}

export type FetchNftOffersQueryResult = NonNullable<Awaited<ReturnType<typeof fetchNftOffers>>>
export type FetchNftOffersQueryError = unknown

export const useFetchNftOffers = <TData = Awaited<ReturnType<typeof fetchNftOffers>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftOffersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNftOffersQueryOptions(chainId,contractAddress,tokenId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNftPriceHistory = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
tokenId = unref(tokenId);
      
      return customInstance<AssetPriceHistoryBackendSchema>(
      {url: `/api/v1/asset/nft-price-history/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(tokenId))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getFetchNftPriceHistoryQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,) => {
    return ['api','v1','asset','nft-price-history',chainId,contractAddress,tokenId] as const;
    }

    
export const getFetchNftPriceHistoryQueryOptions = <TData = Awaited<ReturnType<typeof fetchNftPriceHistory>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftPriceHistory>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNftPriceHistoryQueryKey(chainId,contractAddress,tokenId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNftPriceHistory>>> = ({ signal }) => fetchNftPriceHistory(chainId,contractAddress,tokenId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNftPriceHistory>>, TError, TData> 
}

export type FetchNftPriceHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof fetchNftPriceHistory>>>
export type FetchNftPriceHistoryQueryError = unknown

export const useFetchNftPriceHistory = <TData = Awaited<ReturnType<typeof fetchNftPriceHistory>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftPriceHistory>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNftPriceHistoryQueryOptions(chainId,contractAddress,tokenId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNFTPriceStats = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNFTPriceStatsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
tokenId = unref(tokenId);
params = unref(params);
      
      return customInstance<NFTPriceStatsSchemaBackendSchema>(
      {url: `/api/v1/asset/nft-price-stats/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(tokenId))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchNFTPriceStatsQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNFTPriceStatsParams>,) => {
    return ['api','v1','asset','nft-price-stats',chainId,contractAddress,tokenId, ...(params ? [params]: [])] as const;
    }

    
export const getFetchNFTPriceStatsQueryOptions = <TData = Awaited<ReturnType<typeof fetchNFTPriceStats>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNFTPriceStatsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNFTPriceStats>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNFTPriceStatsQueryKey(chainId,contractAddress,tokenId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNFTPriceStats>>> = ({ signal }) => fetchNFTPriceStats(chainId,contractAddress,tokenId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNFTPriceStats>>, TError, TData> 
}

export type FetchNFTPriceStatsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchNFTPriceStats>>>
export type FetchNFTPriceStatsQueryError = unknown

export const useFetchNFTPriceStats = <TData = Awaited<ReturnType<typeof fetchNFTPriceStats>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNFTPriceStatsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNFTPriceStats>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNFTPriceStatsQueryOptions(chainId,contractAddress,tokenId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNftPrice = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNftPriceParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
tokenId = unref(tokenId);
params = unref(params);
      
      return customInstance<AssetPriceSchemaWithAssetMetadataBackendSchema>(
      {url: `/api/v1/asset/nft-price/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(tokenId))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchNftPriceQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNftPriceParams>,) => {
    return ['api','v1','asset','nft-price',chainId,contractAddress,tokenId, ...(params ? [params]: [])] as const;
    }

    
export const getFetchNftPriceQueryOptions = <TData = Awaited<ReturnType<typeof fetchNftPrice>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNftPriceParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftPrice>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNftPriceQueryKey(chainId,contractAddress,tokenId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNftPrice>>> = ({ signal }) => fetchNftPrice(chainId,contractAddress,tokenId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNftPrice>>, TError, TData> 
}

export type FetchNftPriceQueryResult = NonNullable<Awaited<ReturnType<typeof fetchNftPrice>>>
export type FetchNftPriceQueryError = unknown

export const useFetchNftPrice = <TData = Awaited<ReturnType<typeof fetchNftPrice>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNftPriceParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftPrice>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNftPriceQueryOptions(chainId,contractAddress,tokenId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNftSaleEvents = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftSaleEventsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
tokenId = unref(tokenId);
params = unref(params);
      
      return customInstance<FetchNFTEventsResponseBackendSchema>(
      {url: `/api/v1/asset/nft-sale-events/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(tokenId))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchNftSaleEventsQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftSaleEventsParams>,) => {
    return ['api','v1','asset','nft-sale-events',chainId,contractAddress,tokenId, ...(params ? [params]: [])] as const;
    }

    
export const getFetchNftSaleEventsQueryOptions = <TData = Awaited<ReturnType<typeof fetchNftSaleEvents>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftSaleEventsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftSaleEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNftSaleEventsQueryKey(chainId,contractAddress,tokenId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNftSaleEvents>>> = ({ signal }) => fetchNftSaleEvents(chainId,contractAddress,tokenId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNftSaleEvents>>, TError, TData> 
}

export type FetchNftSaleEventsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchNftSaleEvents>>>
export type FetchNftSaleEventsQueryError = unknown

export const useFetchNftSaleEvents = <TData = Awaited<ReturnType<typeof fetchNftSaleEvents>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params: MaybeRef<FetchNftSaleEventsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftSaleEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNftSaleEventsQueryOptions(chainId,contractAddress,tokenId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchNftTransferAndMintEvents = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNftTransferAndMintEventsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
tokenId = unref(tokenId);
params = unref(params);
      
      return customInstance<FetchNFTEventsResponseBackendSchema>(
      {url: `/api/v1/asset/nft-transfer-events/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(tokenId))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchNftTransferAndMintEventsQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNftTransferAndMintEventsParams>,) => {
    return ['api','v1','asset','nft-transfer-events',chainId,contractAddress,tokenId, ...(params ? [params]: [])] as const;
    }

    
export const getFetchNftTransferAndMintEventsQueryOptions = <TData = Awaited<ReturnType<typeof fetchNftTransferAndMintEvents>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNftTransferAndMintEventsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftTransferAndMintEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchNftTransferAndMintEventsQueryKey(chainId,contractAddress,tokenId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchNftTransferAndMintEvents>>> = ({ signal }) => fetchNftTransferAndMintEvents(chainId,contractAddress,tokenId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchNftTransferAndMintEvents>>, TError, TData> 
}

export type FetchNftTransferAndMintEventsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchNftTransferAndMintEvents>>>
export type FetchNftTransferAndMintEventsQueryError = unknown

export const useFetchNftTransferAndMintEvents = <TData = Awaited<ReturnType<typeof fetchNftTransferAndMintEvents>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchNftTransferAndMintEventsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchNftTransferAndMintEvents>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchNftTransferAndMintEventsQueryOptions(chainId,contractAddress,tokenId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchErc20OHLC = (
    chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    params?: MaybeRef<FetchErc20OHLCParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
address = unref(address);
params = unref(params);
      
      return customInstance<ERC20OHLCDataBackendSchema[]>(
      {url: `/api/v1/asset/ohlc/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(address))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchErc20OHLCQueryKey = (chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    params?: MaybeRef<FetchErc20OHLCParams>,) => {
    return ['api','v1','asset','ohlc',chainId,address, ...(params ? [params]: [])] as const;
    }

    
export const getFetchErc20OHLCQueryOptions = <TData = Awaited<ReturnType<typeof fetchErc20OHLC>>, TError = unknown>(chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    params?: MaybeRef<FetchErc20OHLCParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchErc20OHLC>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchErc20OHLCQueryKey(chainId,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchErc20OHLC>>> = ({ signal }) => fetchErc20OHLC(chainId,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(address))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchErc20OHLC>>, TError, TData> 
}

export type FetchErc20OHLCQueryResult = NonNullable<Awaited<ReturnType<typeof fetchErc20OHLC>>>
export type FetchErc20OHLCQueryError = unknown

export const useFetchErc20OHLC = <TData = Awaited<ReturnType<typeof fetchErc20OHLC>>, TError = unknown>(
 chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    params?: MaybeRef<FetchErc20OHLCParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchErc20OHLC>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchErc20OHLCQueryOptions(chainId,address,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Returns price currently available in the DB
First iteration: If asset or price is missing, return 404
heading towards the following response:
{
    "prices": ...
    "task: ...
}
HTTP Status:
    * 200 returning price from DB
    * 404 asset not found, currently also "no price found in DB" until we add task scheduling
    * TODO: 202 when task was scheduled, returned with or without the data
TODO:
    1. check price exclusion list flag on AssetContract
    2. get asset category (once asset_category_refactor is merged)
    3. DONE: schedule price refresh if data is older than a given
    4. DONE: return a dict including task status
    5. DONE: return all sources at once
 */
export const fetchAssetPrice = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchAssetPriceParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
tokenId = unref(tokenId);
params = unref(params);
      
      return customInstance<AssetPriceAndTaskStatusSchemaBackendSchema>(
      {url: `/api/v1/asset/price/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(tokenId))}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchAssetPriceQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchAssetPriceParams>,) => {
    return ['api','v1','asset','price',chainId,contractAddress,tokenId, ...(params ? [params]: [])] as const;
    }

    
export const getFetchAssetPriceQueryOptions = <TData = Awaited<ReturnType<typeof fetchAssetPrice>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchAssetPriceParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchAssetPrice>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchAssetPriceQueryKey(chainId,contractAddress,tokenId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchAssetPrice>>> = ({ signal }) => fetchAssetPrice(chainId,contractAddress,tokenId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchAssetPrice>>, TError, TData> 
}

export type FetchAssetPriceQueryResult = NonNullable<Awaited<ReturnType<typeof fetchAssetPrice>>>
export type FetchAssetPriceQueryError = unknown

export const useFetchAssetPrice = <TData = Awaited<ReturnType<typeof fetchAssetPrice>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    tokenId: MaybeRef<string>,
    params?: MaybeRef<FetchAssetPriceParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchAssetPrice>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchAssetPriceQueryOptions(chainId,contractAddress,tokenId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Searches wallet assets for the given wallet address and query, with optional filters for asset types, chain IDs, and verification status.
 */
export const searchWalletAssets = (
    walletAddress: MaybeRef<string>,
    params: MaybeRef<SearchWalletAssetsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
params = unref(params);
      
      return customInstance<WalletAssetsResponseWorkaroundBackendSchema>(
      {url: `/api/v1/asset/search-wallet-assets/${encodeURIComponent(String(walletAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getSearchWalletAssetsQueryKey = (walletAddress: MaybeRef<string>,
    params: MaybeRef<SearchWalletAssetsParams>,) => {
    return ['api','v1','asset','search-wallet-assets',walletAddress, ...(params ? [params]: [])] as const;
    }

    
export const getSearchWalletAssetsQueryOptions = <TData = Awaited<ReturnType<typeof searchWalletAssets>>, TError = unknown>(walletAddress: MaybeRef<string>,
    params: MaybeRef<SearchWalletAssetsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchWalletAssets>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getSearchWalletAssetsQueryKey(walletAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchWalletAssets>>> = ({ signal }) => searchWalletAssets(walletAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchWalletAssets>>, TError, TData> 
}

export type SearchWalletAssetsQueryResult = NonNullable<Awaited<ReturnType<typeof searchWalletAssets>>>
export type SearchWalletAssetsQueryError = unknown

export const useSearchWalletAssets = <TData = Awaited<ReturnType<typeof searchWalletAssets>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    params: MaybeRef<SearchWalletAssetsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchWalletAssets>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchWalletAssetsQueryOptions(walletAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const assetTokenStatsList = (
    chainId: MaybeRef<number>,
    address: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
address = unref(address);
      
      return customInstance<ERC20AssetStatsSchemaBackendSchema>(
      {url: `/api/v1/asset/token-stats/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(address))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAssetTokenStatsListQueryKey = (chainId: MaybeRef<number>,
    address: MaybeRef<string>,) => {
    return ['api','v1','asset','token-stats',chainId,address] as const;
    }

    
export const getAssetTokenStatsListQueryOptions = <TData = Awaited<ReturnType<typeof assetTokenStatsList>>, TError = unknown>(chainId: MaybeRef<number>,
    address: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTokenStatsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTokenStatsListQueryKey(chainId,address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTokenStatsList>>> = ({ signal }) => assetTokenStatsList(chainId,address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(address))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetTokenStatsList>>, TError, TData> 
}

export type AssetTokenStatsListQueryResult = NonNullable<Awaited<ReturnType<typeof assetTokenStatsList>>>
export type AssetTokenStatsListQueryError = unknown

export const useAssetTokenStatsList = <TData = Awaited<ReturnType<typeof assetTokenStatsList>>, TError = unknown>(
 chainId: MaybeRef<number>,
    address: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTokenStatsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTokenStatsListQueryOptions(chainId,address,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const assetTokensInTokenListList = (
    chainId: MaybeRef<string>,
    params?: MaybeRef<AssetTokensInTokenListListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
params = unref(params);
      
      return customInstance<PaginatedAssetMetadataListListBackendSchema>(
      {url: `/api/v1/asset/tokens-in-token-list/${encodeURIComponent(String(chainId))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getAssetTokensInTokenListListQueryKey = (chainId: MaybeRef<string>,
    params?: MaybeRef<AssetTokensInTokenListListParams>,) => {
    return ['api','v1','asset','tokens-in-token-list',chainId, ...(params ? [params]: [])] as const;
    }

    
export const getAssetTokensInTokenListListInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof assetTokensInTokenListList>>, AssetTokensInTokenListListParams['offset']>, TError = unknown>(chainId: MaybeRef<string>,
    params?: MaybeRef<AssetTokensInTokenListListParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTokensInTokenListList>>, TError, TData, Awaited<ReturnType<typeof assetTokensInTokenListList>>, QueryKey, AssetTokensInTokenListListParams['offset']>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTokensInTokenListListQueryKey(chainId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTokensInTokenListList>>, QueryKey, AssetTokensInTokenListListParams['offset']> = ({ signal, pageParam }) => assetTokensInTokenListList(chainId,{...unref(params), offset: pageParam || unref(params)?.['offset']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId))),  staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTokensInTokenListList>>, TError, TData, Awaited<ReturnType<typeof assetTokensInTokenListList>>, QueryKey, AssetTokensInTokenListListParams['offset']> 
}

export type AssetTokensInTokenListListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof assetTokensInTokenListList>>>
export type AssetTokensInTokenListListInfiniteQueryError = unknown

export const useAssetTokensInTokenListListInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof assetTokensInTokenListList>>, AssetTokensInTokenListListParams['offset']>, TError = unknown>(
 chainId: MaybeRef<string>,
    params?: MaybeRef<AssetTokensInTokenListListParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTokensInTokenListList>>, TError, TData, Awaited<ReturnType<typeof assetTokensInTokenListList>>, QueryKey, AssetTokensInTokenListListParams['offset']>>, request?: SecondParameter<typeof customInstance>}

  ): UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTokensInTokenListListInfiniteQueryOptions(chainId,params,options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getAssetTokensInTokenListListQueryOptions = <TData = Awaited<ReturnType<typeof assetTokensInTokenListList>>, TError = unknown>(chainId: MaybeRef<string>,
    params?: MaybeRef<AssetTokensInTokenListListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTokensInTokenListList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTokensInTokenListListQueryKey(chainId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTokensInTokenListList>>> = ({ signal }) => assetTokensInTokenListList(chainId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId))),  staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetTokensInTokenListList>>, TError, TData> 
}

export type AssetTokensInTokenListListQueryResult = NonNullable<Awaited<ReturnType<typeof assetTokensInTokenListList>>>
export type AssetTokensInTokenListListQueryError = unknown

export const useAssetTokensInTokenListList = <TData = Awaited<ReturnType<typeof assetTokensInTokenListList>>, TError = unknown>(
 chainId: MaybeRef<string>,
    params?: MaybeRef<AssetTokensInTokenListListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTokensInTokenListList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTokensInTokenListListQueryOptions(chainId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const assetTopCollectionsList = (
    params?: MaybeRef<AssetTopCollectionsListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedNFTAssetCollectionSchemaListListBackendSchema>(
      {url: `/api/v1/asset/top-collections/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getAssetTopCollectionsListQueryKey = (params?: MaybeRef<AssetTopCollectionsListParams>,) => {
    return ['api','v1','asset','top-collections', ...(params ? [params]: [])] as const;
    }

    
export const getAssetTopCollectionsListInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof assetTopCollectionsList>>, AssetTopCollectionsListParams['offset']>, TError = unknown>(params?: MaybeRef<AssetTopCollectionsListParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsList>>, TError, TData, Awaited<ReturnType<typeof assetTopCollectionsList>>, QueryKey, AssetTopCollectionsListParams['offset']>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTopCollectionsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTopCollectionsList>>, QueryKey, AssetTopCollectionsListParams['offset']> = ({ signal, pageParam }) => assetTopCollectionsList({...unref(params), offset: pageParam || unref(params)?.['offset']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsList>>, TError, TData, Awaited<ReturnType<typeof assetTopCollectionsList>>, QueryKey, AssetTopCollectionsListParams['offset']> 
}

export type AssetTopCollectionsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof assetTopCollectionsList>>>
export type AssetTopCollectionsListInfiniteQueryError = unknown

export const useAssetTopCollectionsListInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof assetTopCollectionsList>>, AssetTopCollectionsListParams['offset']>, TError = unknown>(
 params?: MaybeRef<AssetTopCollectionsListParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsList>>, TError, TData, Awaited<ReturnType<typeof assetTopCollectionsList>>, QueryKey, AssetTopCollectionsListParams['offset']>>, request?: SecondParameter<typeof customInstance>}

  ): UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTopCollectionsListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getAssetTopCollectionsListQueryOptions = <TData = Awaited<ReturnType<typeof assetTopCollectionsList>>, TError = unknown>(params?: MaybeRef<AssetTopCollectionsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTopCollectionsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTopCollectionsList>>> = ({ signal }) => assetTopCollectionsList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsList>>, TError, TData> 
}

export type AssetTopCollectionsListQueryResult = NonNullable<Awaited<ReturnType<typeof assetTopCollectionsList>>>
export type AssetTopCollectionsListQueryError = unknown

export const useAssetTopCollectionsList = <TData = Awaited<ReturnType<typeof assetTopCollectionsList>>, TError = unknown>(
 params?: MaybeRef<AssetTopCollectionsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTopCollectionsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const assetTopCollectionsListExternal = (
    params: MaybeRef<AssetTopCollectionsListExternalParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<FetchTopCollectionsResponseBackendSchema>(
      {url: `/api/v1/asset/top-collections-external/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getAssetTopCollectionsListExternalQueryKey = (params: MaybeRef<AssetTopCollectionsListExternalParams>,) => {
    return ['api','v1','asset','top-collections-external', ...(params ? [params]: [])] as const;
    }

    
export const getAssetTopCollectionsListExternalInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, AssetTopCollectionsListExternalParams['cursor']>, TError = unknown>(params: MaybeRef<AssetTopCollectionsListExternalParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, TError, TData, Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, QueryKey, AssetTopCollectionsListExternalParams['cursor']>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTopCollectionsListExternalQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, QueryKey, AssetTopCollectionsListExternalParams['cursor']> = ({ signal, pageParam }) => assetTopCollectionsListExternal({...unref(params), cursor: pageParam || unref(params)?.['cursor']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, TError, TData, Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, QueryKey, AssetTopCollectionsListExternalParams['cursor']> 
}

export type AssetTopCollectionsListExternalInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>>
export type AssetTopCollectionsListExternalInfiniteQueryError = unknown

export const useAssetTopCollectionsListExternalInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, AssetTopCollectionsListExternalParams['cursor']>, TError = unknown>(
 params: MaybeRef<AssetTopCollectionsListExternalParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, TError, TData, Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, QueryKey, AssetTopCollectionsListExternalParams['cursor']>>, request?: SecondParameter<typeof customInstance>}

  ): UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTopCollectionsListExternalInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getAssetTopCollectionsListExternalQueryOptions = <TData = Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, TError = unknown>(params: MaybeRef<AssetTopCollectionsListExternalParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTopCollectionsListExternalQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>> = ({ signal }) => assetTopCollectionsListExternal(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, TError, TData> 
}

export type AssetTopCollectionsListExternalQueryResult = NonNullable<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>>
export type AssetTopCollectionsListExternalQueryError = unknown

export const useAssetTopCollectionsListExternal = <TData = Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, TError = unknown>(
 params: MaybeRef<AssetTopCollectionsListExternalParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTopCollectionsListExternal>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTopCollectionsListExternalQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const assetTopTokensList = (
    params?: MaybeRef<AssetTopTokensListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<PaginatedERC20AssetSchemaListBackendSchema>(
      {url: `/api/v1/asset/top-tokens/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getAssetTopTokensListQueryKey = (params?: MaybeRef<AssetTopTokensListParams>,) => {
    return ['api','v1','asset','top-tokens', ...(params ? [params]: [])] as const;
    }

    
export const getAssetTopTokensListInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof assetTopTokensList>>, AssetTopTokensListParams['offset']>, TError = unknown>(params?: MaybeRef<AssetTopTokensListParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopTokensList>>, TError, TData, Awaited<ReturnType<typeof assetTopTokensList>>, QueryKey, AssetTopTokensListParams['offset']>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTopTokensListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTopTokensList>>, QueryKey, AssetTopTokensListParams['offset']> = ({ signal, pageParam }) => assetTopTokensList({...unref(params), offset: pageParam || unref(params)?.['offset']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopTokensList>>, TError, TData, Awaited<ReturnType<typeof assetTopTokensList>>, QueryKey, AssetTopTokensListParams['offset']> 
}

export type AssetTopTokensListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof assetTopTokensList>>>
export type AssetTopTokensListInfiniteQueryError = unknown

export const useAssetTopTokensListInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof assetTopTokensList>>, AssetTopTokensListParams['offset']>, TError = unknown>(
 params?: MaybeRef<AssetTopTokensListParams>, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof assetTopTokensList>>, TError, TData, Awaited<ReturnType<typeof assetTopTokensList>>, QueryKey, AssetTopTokensListParams['offset']>>, request?: SecondParameter<typeof customInstance>}

  ): UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTopTokensListInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const getAssetTopTokensListQueryOptions = <TData = Awaited<ReturnType<typeof assetTopTokensList>>, TError = unknown>(params?: MaybeRef<AssetTopTokensListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTopTokensList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTopTokensListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTopTokensList>>> = ({ signal }) => assetTopTokensList(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000, refetchOnWindowFocus: false,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetTopTokensList>>, TError, TData> 
}

export type AssetTopTokensListQueryResult = NonNullable<Awaited<ReturnType<typeof assetTopTokensList>>>
export type AssetTopTokensListQueryError = unknown

export const useAssetTopTokensList = <TData = Awaited<ReturnType<typeof assetTopTokensList>>, TError = unknown>(
 params?: MaybeRef<AssetTopTokensListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTopTokensList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTopTokensListQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const assetTopTokensListExternal = (
    params?: MaybeRef<AssetTopTokensListExternalParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customInstance<TopTokenModelBackendSchema[]>(
      {url: `/api/v1/asset/top-tokens-external/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getAssetTopTokensListExternalQueryKey = (params?: MaybeRef<AssetTopTokensListExternalParams>,) => {
    return ['api','v1','asset','top-tokens-external', ...(params ? [params]: [])] as const;
    }

    
export const getAssetTopTokensListExternalQueryOptions = <TData = Awaited<ReturnType<typeof assetTopTokensListExternal>>, TError = unknown>(params?: MaybeRef<AssetTopTokensListExternalParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTopTokensListExternal>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTopTokensListExternalQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTopTokensListExternal>>> = ({ signal }) => assetTopTokensListExternal(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetTopTokensListExternal>>, TError, TData> 
}

export type AssetTopTokensListExternalQueryResult = NonNullable<Awaited<ReturnType<typeof assetTopTokensListExternal>>>
export type AssetTopTokensListExternalQueryError = unknown

export const useAssetTopTokensListExternal = <TData = Awaited<ReturnType<typeof assetTopTokensListExternal>>, TError = unknown>(
 params?: MaybeRef<AssetTopTokensListExternalParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTopTokensListExternal>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTopTokensListExternalQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchTraitOffers = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchTraitOffersParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
params = unref(params);
      
      return customInstance<FetchNFTOrdersBackendSchema>(
      {url: `/api/v1/asset/trait-offers/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchTraitOffersQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchTraitOffersParams>,) => {
    return ['api','v1','asset','trait-offers',chainId,contractAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchTraitOffersQueryOptions = <TData = Awaited<ReturnType<typeof fetchTraitOffers>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchTraitOffersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchTraitOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchTraitOffersQueryKey(chainId,contractAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchTraitOffers>>> = ({ signal }) => fetchTraitOffers(chainId,contractAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchTraitOffers>>, TError, TData> 
}

export type FetchTraitOffersQueryResult = NonNullable<Awaited<ReturnType<typeof fetchTraitOffers>>>
export type FetchTraitOffersQueryError = unknown

export const useFetchTraitOffers = <TData = Awaited<ReturnType<typeof fetchTraitOffers>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params: MaybeRef<FetchTraitOffersParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchTraitOffers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchTraitOffersQueryOptions(chainId,contractAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const assetTraitsList = (
    chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    tokenId: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
address = unref(address);
tokenId = unref(tokenId);
      
      return customInstance<TraitSchemaBackendSchema[]>(
      {url: `/api/v1/asset/traits/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(address))}/${encodeURIComponent(String(tokenId))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getAssetTraitsListQueryKey = (chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    tokenId: MaybeRef<string>,) => {
    return ['api','v1','asset','traits',chainId,address,tokenId] as const;
    }

    
export const getAssetTraitsListQueryOptions = <TData = Awaited<ReturnType<typeof assetTraitsList>>, TError = unknown>(chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    tokenId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTraitsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getAssetTraitsListQueryKey(chainId,address,tokenId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assetTraitsList>>> = ({ signal }) => assetTraitsList(chainId,address,tokenId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(address) && unref(tokenId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assetTraitsList>>, TError, TData> 
}

export type AssetTraitsListQueryResult = NonNullable<Awaited<ReturnType<typeof assetTraitsList>>>
export type AssetTraitsListQueryError = unknown

export const useAssetTraitsList = <TData = Awaited<ReturnType<typeof assetTraitsList>>, TError = unknown>(
 chainId: MaybeRef<string>,
    address: MaybeRef<string>,
    tokenId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assetTraitsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAssetTraitsListQueryOptions(chainId,address,tokenId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchUserErc20s = (
    chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FetchUserErc20sParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
userAddress = unref(userAddress);
params = unref(params);
      
      return customInstance<ERC20AssetSchemaWithBalanceBackendSchema[]>(
      {url: `/api/v1/asset/user-erc20s/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(userAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchUserErc20sQueryKey = (chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FetchUserErc20sParams>,) => {
    return ['api','v1','asset','user-erc20s',chainId,userAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchUserErc20sQueryOptions = <TData = Awaited<ReturnType<typeof fetchUserErc20s>>, TError = unknown>(chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FetchUserErc20sParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchUserErc20s>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchUserErc20sQueryKey(chainId,userAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchUserErc20s>>> = ({ signal }) => fetchUserErc20s(chainId,userAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(userAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchUserErc20s>>, TError, TData> 
}

export type FetchUserErc20sQueryResult = NonNullable<Awaited<ReturnType<typeof fetchUserErc20s>>>
export type FetchUserErc20sQueryError = unknown

export const useFetchUserErc20s = <TData = Awaited<ReturnType<typeof fetchUserErc20s>>, TError = unknown>(
 chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FetchUserErc20sParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchUserErc20s>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchUserErc20sQueryOptions(chainId,userAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchUserNfts = (
    chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FetchUserNftsParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
userAddress = unref(userAddress);
params = unref(params);
      
      return customInstance<FetchUserNFTsResponseBackendSchema>(
      {url: `/api/v1/asset/user-nfts/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(userAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchUserNftsQueryKey = (chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FetchUserNftsParams>,) => {
    return ['api','v1','asset','user-nfts',chainId,userAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchUserNftsQueryOptions = <TData = Awaited<ReturnType<typeof fetchUserNfts>>, TError = unknown>(chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FetchUserNftsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchUserNfts>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchUserNftsQueryKey(chainId,userAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchUserNfts>>> = ({ signal }) => fetchUserNfts(chainId,userAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(userAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchUserNfts>>, TError, TData> 
}

export type FetchUserNftsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchUserNfts>>>
export type FetchUserNftsQueryError = unknown

export const useFetchUserNfts = <TData = Awaited<ReturnType<typeof fetchUserNfts>>, TError = unknown>(
 chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<FetchUserNftsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchUserNfts>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchUserNftsQueryOptions(chainId,userAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchEthPrice = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<AssetPriceSchemaWithAssetMetadataBackendSchema>(
      {url: `/api/v1/asset/valuation/eth-price`, method: 'GET', signal
    },
      options);
    }
  

export const getFetchEthPriceQueryKey = () => {
    return ['api','v1','asset','valuation','eth-price'] as const;
    }

    
export const getFetchEthPriceQueryOptions = <TData = Awaited<ReturnType<typeof fetchEthPrice>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchEthPrice>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchEthPriceQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchEthPrice>>> = ({ signal }) => fetchEthPrice(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchEthPrice>>, TError, TData> 
}

export type FetchEthPriceQueryResult = NonNullable<Awaited<ReturnType<typeof fetchEthPrice>>>
export type FetchEthPriceQueryError = unknown

export const useFetchEthPrice = <TData = Awaited<ReturnType<typeof fetchEthPrice>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchEthPrice>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchEthPriceQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const fetchErc20Price = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchErc20PriceParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
params = unref(params);
      
      return customInstance<AssetPriceSchemaWithAssetMetadataBackendSchema>(
      {url: `/api/v1/asset/valuation/token-price/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchErc20PriceQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchErc20PriceParams>,) => {
    return ['api','v1','asset','valuation','token-price',chainId,contractAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchErc20PriceQueryOptions = <TData = Awaited<ReturnType<typeof fetchErc20Price>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchErc20PriceParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchErc20Price>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchErc20PriceQueryKey(chainId,contractAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchErc20Price>>> = ({ signal }) => fetchErc20Price(chainId,contractAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchErc20Price>>, TError, TData> 
}

export type FetchErc20PriceQueryResult = NonNullable<Awaited<ReturnType<typeof fetchErc20Price>>>
export type FetchErc20PriceQueryError = unknown

export const useFetchErc20Price = <TData = Awaited<ReturnType<typeof fetchErc20Price>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    params?: MaybeRef<FetchErc20PriceParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchErc20Price>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchErc20PriceQueryOptions(chainId,contractAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



/**
 * Fetches wallet assets for the given user address and chain IDs, with optional filters for asset types and verification status.
 */
export const fetchWalletAssetsList = (
    walletAddress: MaybeRef<string>,
    params: MaybeRef<FetchWalletAssetsListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      walletAddress = unref(walletAddress);
params = unref(params);
      
      return customInstance<PaginatedWalletAssetsResponseWorkaroundListBackendSchema>(
      {url: `/api/v1/asset/wallet-assets/${encodeURIComponent(String(walletAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getFetchWalletAssetsListQueryKey = (walletAddress: MaybeRef<string>,
    params: MaybeRef<FetchWalletAssetsListParams>,) => {
    return ['api','v1','asset','wallet-assets',walletAddress, ...(params ? [params]: [])] as const;
    }

    
export const getFetchWalletAssetsListQueryOptions = <TData = Awaited<ReturnType<typeof fetchWalletAssetsList>>, TError = unknown>(walletAddress: MaybeRef<string>,
    params: MaybeRef<FetchWalletAssetsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchWalletAssetsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getFetchWalletAssetsListQueryKey(walletAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchWalletAssetsList>>> = ({ signal }) => fetchWalletAssetsList(walletAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fetchWalletAssetsList>>, TError, TData> 
}

export type FetchWalletAssetsListQueryResult = NonNullable<Awaited<ReturnType<typeof fetchWalletAssetsList>>>
export type FetchWalletAssetsListQueryError = unknown

export const useFetchWalletAssetsList = <TData = Awaited<ReturnType<typeof fetchWalletAssetsList>>, TError = unknown>(
 walletAddress: MaybeRef<string>,
    params: MaybeRef<FetchWalletAssetsListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchWalletAssetsList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFetchWalletAssetsListQueryOptions(walletAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



