import { computed, ref } from 'vue'
import RouteName from '@/router/RouteName'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import { getChainIdFromChainName } from '@/utils/chain'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { Nullable } from '@/modules/common/typings/customTypes'
import useSectionCollectionStats from '@/modules/pages/asset/nft-page/SectionCollectionStats/useSectionCollectionStats'
import type { ChainName, ChainNameLowercase, SupportedChain } from '@/constants/chains/types'
import useSectionNFTAppraisal from '@/modules/pages/asset/nft-page/SectionNFTAppraisal/useSectionNFTAppraisal'
import useSectionListings from '@/modules/pages/asset/nft-page/SectionListings/useSectionListings'
import useSectionNFTEvents from '@/modules/pages/asset/nft-page/SectionNFTEvents/useSectionNFTEvents'
import useSectionOffers from '@/modules/pages/asset/nft-page/SectionOffers/useSectionOffers'
import useNFTFetch from '@/modules/common/assets/fetchers/useNFTFetch'
import type { RouteLocationRaw, RouteParamsRaw } from 'vue-router'
import to from '@/utils/await-to-js'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import useSectionProperties from '@/modules/pages/asset/nft-page/SectionProperties/useSectionProperties'
import useSectionBundleAssetsTable from '@/modules/pages/asset/nft-page/SectionAssetTable/useSectionBundleAssetsTable'
import router from '@/router'
import { compareAddresses } from '@/utils/utils'
import type { Address } from 'viem'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'

const asset = ref<AssetMetadata>()
const isLoadingBasicAssetDetails = ref(false)
const isFetchingOwnerOfERC721 = ref(false)
const isScrollingToMakeOffer = ref(false)
const isProposalsTabSelected = ref(false)
const lastParams = ref()
export interface AssetPageRouteParams extends RouteParamsRaw {
  chainName: ChainName | ChainNameLowercase
  contractAddress: Address
  tokenId?: string
  loanId?: string
}

export default function useAssetPage() {
  const { fetchNftMetadata } = useMetadataFetch()

  const setAsset = (newAsset: AssetMetadata) => {
    asset.value = newAsset
  }

  const getAssetPageRoute = (asset: AssetMetadata, loanId?: Nullable<string>): RouteLocationRaw => {
    if (asset.isErc20) {
      return {
        name: RouteName.TokenPage,
        params: {
          chainName: CHAINS_CONSTANTS[asset.chainId].general.chainName.toLowerCase(),
          contractAddress: asset.address,
        },
      }
    }

    if (!asset.tokenId) {
      return {
        name: RouteName.CollectionByContractAddress,
        params: {
          chainName: CHAINS_CONSTANTS[asset.chainId].general.chainName.toLowerCase(),
          contractAddress: asset.address,
        },
      }
    }

    return {
      name: RouteName.NftPage,
      params: {
        chainName: CHAINS_CONSTANTS[asset.chainId].general.chainName.toLowerCase(),
        contractAddress: asset.address,
        tokenId: String(asset.tokenId),
        ...(loanId && { loanId }),
      },
    }
  }

  const loadBasicAssetDetails = async (chainId: SupportedChain, contractAddress: Address, tokenId: bigint, refresh?: boolean | undefined): Promise<void> => {
    isLoadingBasicAssetDetails.value = true

    const { address: userAddress } = getAccount(pwnWagmiConfig)

    const [error, nftMetadata] = await to(
      fetchNftMetadata({
        chainId,
        contractAddress,
        tokenId,
        useCachedMetadata: false,
        userAddress,
        ...(refresh !== undefined && { refresh }),
      }),
      { additionalErrorInfo: { chainIdToFetch: chainId, contractAddressToFetch: contractAddress, tokenIdToFetch: tokenId, isRefresh: refresh } },
    )
    if (error || !nftMetadata) {
      await router.replace({ name: RouteName.NotFound })
    }
    // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetMetadata | null | undefined... Remove this comment to see the full error message
    setAsset(nftMetadata)
    isLoadingBasicAssetDetails.value = false
  }

  const loadOwnerOfERC721 = async (): Promise<void> => {
    isFetchingOwnerOfERC721.value = true
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    asset.value.ownerAddress = await useNFTFetch().fetchOwnerOfERC721(
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      asset.value.chainId,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      asset.value.address,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      asset.value.tokenId,
    )
    isFetchingOwnerOfERC721.value = false
  }

  const clearAssetPageData = (): void => {
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'AssetMetada... Remove this comment to see the full error message
    asset.value = null
    useSectionCollectionStats().clearCollectionStats()
    useSectionNFTAppraisal().clearNFTAppraisal()
    useSectionListings().clearListings()
    useSectionNFTEvents().clearEvents()
    useSectionOffers().clearOffers()
  }

  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
  const loadNFTAssetPage = async ({ chainName, contractAddress, tokenId, loanId = null }: AssetPageRouteParams, refresh?: boolean | undefined) => {
    const chainId = getChainIdFromChainName(chainName)
    // todo: https://www.notion.so/pwndao/refactor-getAssetPageRoute-loadNftAssetPage-logic-1197d6c248f04e7687ae2d1510d42eda
    if (refresh || !asset.value || asset.value.chainId !== chainId || compareAddresses(asset.value.address, contractAddress) || String(asset.value.tokenId) !== String(tokenId)) {
      clearAssetPageData()
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      await loadBasicAssetDetails(chainId, contractAddress, tokenId, refresh)
    }

    const fetchRequestsToPerform: Array<Promise<unknown>> = []
    if (asset.value?.traits?.numericTraits?.length === 0 && asset.value?.traits?.stringTraits?.length === 0) {
      fetchRequestsToPerform.push(useSectionProperties().loadAssetTraits())
    }

    if (!asset.value?.ownerAddress && asset.value?.hasSingleOwner) {
      // We are displaying owner of NFT only for ERC721 (since they all have quantity 1).
      fetchRequestsToPerform.push(loadOwnerOfERC721())
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetMetadata | undefined' is no... Remove this comment to see the full error message
    fetchRequestsToPerform.push(useSectionOffers().loadAllOffers(asset.value))

    if (asset.value?.bundleAssets?.length) {
      fetchRequestsToPerform.push(useSectionBundleAssetsTable().loadAllAppraisals(asset.value, refresh))
    }

    const contractAddressToFetch = asset.value?.isBundleWithSingleAsset ? asset.value.bundledAsset.address : contractAddress

    await Promise.allSettled([
      ...fetchRequestsToPerform,
      useSectionCollectionStats().loadCollectionStats(chainId, contractAddressToFetch, refresh),
      useSectionNFTAppraisal().loadNFTAppraisal(chainId, contractAddressToFetch, tokenId!, refresh),
      useSectionListings().loadListings(chainId, contractAddressToFetch, tokenId!),
      useSectionNFTEvents().loadAllNftEvents(chainId, contractAddressToFetch, tokenId!),
    ])
  }

  return {
    asset: computed(() => asset.value),
    setAsset,
    loadNFTAssetPage,
    isLoadingBasicAssetDetails,
    isFetchingOwnerOfERC721,
    getAssetPageRoute,
    isScrollingToMakeOffer,
    isProposalsTabSelected,
    clearAssetPageData,
    lastParams,
  }
}
