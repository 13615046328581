<template>
  <div style="display: flex; flex-direction: row; align-items: center;">
    <AssetInfoCell
      :asset="props.asset"
      :icon-width="props.iconWidth"
      :name-width="props.nameWidth"/>
    <PlatformIcon
      v-if="hasProtocol"
      style="margin-left: 1rem;"/>
  </div>
</template>

<script setup lang="ts">
import AssetInfoCell from '@/revamp/components/tables/cells/assets/AssetInfoCell.vue'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { getPlatformIcon } from '@/utils/utils'
import { computed } from 'vue'

type Props = {
  asset: AssetWithAmount | AssetMetadata
  iconWidth?: string
  nameWidth?: string
}
const props = withDefaults(defineProps<Props>(), {
  iconWidth: '1.5rem',
  nameWidth: '8rem',
})
const hasProtocol = computed(() => 'protocol' in props.asset && props.asset.protocol)
const PlatformIcon = computed(() => getPlatformIcon(props.asset as AssetWithAmount))

</script>
