<template>
  <div
    class="space-card"
    @click="openPwnSpace(spaceData.pwnSpaceName)">
    <div class="space-card__banner">
      <img
        v-if="spaceData.bannerUrl"
        :src="spaceData.bannerUrl"
        alt="space banner"
        title="space Banner"
        class="space-card__banner-image">
    </div>
    <div class="space-card__description-container">
      <div class="space-card__image">
        <img
          :src="spaceData.avatar!"
          alt="space avatar"
          class="space-card__avatar-image">
      </div>
      <div class="space-card__name">
        <SafeText
          :show-verified-icon="true"
          :text="spaceData.title!"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SafeText from '@/general-components/SafeText.vue'
import type PwnSpaceConfig from '@/modules/common/pwnSpace/PwnSpaceConfig'
interface Props {
  spaceData: PwnSpaceConfig
}
defineProps<Props>()
const openPwnSpace = (pwnSpaceName: string) => {
  window.open(`https://${pwnSpaceName}.pwn.xyz`, '_blank')
}
</script>

<style scoped>
.space-card {
  position: relative;
  border: 1px solid var(--gray);
  background: var(--background-color);
  width: 22.5rem;
  height: 11rem;
  flex: 0 0 auto;
  overflow: visible;
  transition: transform var(--primary-transition-time) ease, box-shadow var(--primary-transition-time) ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px var(--primary-color-1);
    cursor: pointer;
  }

  &__description-container {
    display: flex;
    flex-direction: row;
  }

  &__banner {
    height: 6rem;
    width: 20.5rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1rem;
    overflow: visible;
  }

  &__banner-image {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
  }

  &__image {
    height: 5.5rem;
    width: 5.5rem;
    margin-left: 2rem;
    margin-top: -2rem;
    border: 6px solid var(--background-color);
    background: var(--background-color);
  }

  &__avatar-image {
    width: 100%;
    height: 100%;
  }

  &__name {
    margin: 1rem 0 0 1rem;
    color: var(--white-base);
    font-family: var(--font-family-screener);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
  }

  &__link {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 0.75rem;
    color: var(--primary-color-1);
  }

  &__network-icon {
    border-radius: 0.25rem;
    background: rgb(34 34 34 / 75%);
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
