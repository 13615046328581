<template>
  <div
    :class="['unpack-bundle-submit__hint', {'unpack-bundle-submit__hide': !isHintMessage}]">
    <div class="unpack-bundle-submit__hint-warning">
      Please confirm that the chosen bundle is correct. <br>
      Unpacking the bundle will result in the cancellation of all loan requests that are currently utilizing it as collateral.
    </div>
  </div>

  <div
    v-if="!isSuccessfullyUnbundled && isUnbundling"
    class="unpack-bundle-submit__unpacking-text">
    Unpacking a bundle, this may take a while
  </div>
  <div
    v-if="!isSuccessfullyUnbundled"
    class="unpack-bundle-submit__selected-bundle">
    <AssetCardBundle
      v-if="isAssetCardBundle && selectedBundle"
      amount-prop-name="amountFormatted"
      :asset="selectedBundle"/>
    <div
      v-else
      class="unpack-bundle-submit__unpacking"/>
    <UnpackingAnimation
      v-if="isUnbundling"
      :bundle-assets="bundleAssets"/>
  </div>

  <div
    v-if="isSuccessfullyUnbundled"
    class="unpack-bundle-submit__success">
    <div class="unpack-bundle-submit__success-text">
      Success!<br>
      Bundle has been unpacked<br>
      Individual assets are now available in <BaseLink
        class="link--primary"
        :to="{ name: RouteName.Dashboard, params: { user: userAddress } }">
        <span>your wallet</span>
      </BaseLink><br>
    </div>

    <div class="unpack-bundle-submit__bundle-assets">
      <AssetCardBundle
        v-for="asset in bundleAssets"
        :key="asset.id"
        amount-prop-name="amountFormatted"
        :asset="asset"/>
    </div>
  </div>

  <div class="unpack-bundle-submit__continue">
    <BaseButton
      :button-text="prevStepButtonText"
      :color="ButtonColor.White"
      :variant="ButtonVariant.Underline"
      @on-button-click="goToPreviousStep"/>

    <BaseButton
      v-if="!isSuccessfullyUnbundled"
      :is-disabled="!isUnpackBundleButtonEnabled"
      :button-text="unpackButtonText"
      @on-button-click="handleUnpackBundle"/>
    <BaseLink
      v-else
      :is-underlined="false"
      :to="{ name: RouteName.Dashboard, params: { user: userAddress } }">
      <BaseButton
        button-text="Go to Dashboard"/>
    </BaseLink>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'
import useUnpackBundle from '@/modules/pages/token-bundler/unpack-bundle/useUnpackBundle'
import AssetCardBundle from '@/general-components/AssetCardBundle.vue'
import { computed, ref } from 'vue'
import type { Ref } from 'vue'
import deepcopy from '@/utils/deepcopyinstance'
import BaseLink from '@/general-components/BaseLink.vue'
import UnpackingAnimation from '@/modules/pages/token-bundler/unpack-bundle/UnpackingAnimation.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useMutation } from '@tanstack/vue-query'
import { queryMutations } from '@/modules/mutations'
import type { UnbundleAssetsMutationParams } from '@/modules/mutations'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import useActionFlow from '@/modules/common/notifications/useActionFlow'

const isSuccessfullyUnbundled = ref(false)
const bundleAssets = ref<AssetWithAmount[]>([])

const { address: userAddress } = useCustomAccount()
const { selectedBundle } = useUnpackBundle()
const router = useRouter()

const mutations = queryMutations()
const { mutateAsync: unbundleMutateAsync, isPending: isUnbundling } = useMutation({
  ...mutations.tokenBundler.unbundle,
  onSuccess(data, variables, context) {
    mutations.tokenBundler.unbundle.onSuccess?.(data, variables, context)
    isSuccessfullyUnbundled.value = true
    selectedBundle.value = undefined
  },
  onError(error, variables, context) {
    mutations.tokenBundler.unbundle.onError?.(error, variables, context)
    isSuccessfullyUnbundled.value = false
  },
})

const isHintMessage = computed(() => !isUnbundling.value && !isSuccessfullyUnbundled.value)
const isAssetCardBundle = computed(() => !isUnbundling.value && !isSuccessfullyUnbundled.value)
const prevStepButtonText = computed(() => isSuccessfullyUnbundled.value ? 'Keep unpacking' : 'previous step')
const isUnpackBundleButtonEnabled = computed(() => selectedBundle.value && !isUnbundling.value)
const unpackButtonText = computed(() => isUnbundling.value ? 'Unpacking...' : 'Unpack')

const toast = ref<Toast>()
let continueFlow
const handleUnpackBundle = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_UNWRAP_BUNDLE](selectedBundle.value!)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      steps: [new ToastStep({
        text: 'Unpacking...',
        async fn(step) {
          // @ts-expect-error FIXME: strictNullChecks
          bundleAssets.value = deepcopy(selectedBundle.value.bundleAssets)
          const unwrappedAssets = await unbundleMutateAsync({ asset: selectedBundle.value, step } as UnbundleAssetsMutationParams)
          return unwrappedAssets?.length > 0
        },
      })],
      chainId: selectedBundle.value!.chainId,
      title: 'Unpacking Bundle',
      // TODO first asset?
    }, NotificationFrontendOnlyActionEnum.TX_UNWRAP_BUNDLE, selectedBundle.value!);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}

const goToPreviousStep = (): void => { router.push({ name: RouteName.UnpackBundleChooseBundle }) }
</script>

<style scoped>
.unpack-bundle-submit {
  &__selected-bundle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 30rem;
  }

  &__continue {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 1rem;
    padding-top: 1rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 1px;
      width: 100%;
      background-image: var(--border-gray-dashed);
      background-size: auto 2px; /* small hack how to hide buggy double border (top + bottom), when height is 1px */
    }
  }

  &__hint-warning {
    color: var(--negative-1);
    line-height: 1.5rem;
    max-width: 36.25rem;
  }

  &__hint {
    margin-top: 1rem;
    display: flex;
  }

  &__unpacking {
    height: 10rem;
    width: 10rem;
    border-radius: 1rem;
    margin: 2rem auto 1rem;
    display: block;
    background-color: var(--gray-3);
    border: 2px solid var(--primary-color-1);
  }

  &__hide {
    visibility: hidden;
  }

  &__success-text,
  &__unpacking-text {
    font-family: var(--font-family-autoscape);
    color: var(--primary-color-1);
    font-size: 1.25rem;
    font-weight: 550;
    line-height: 1.75rem;
    text-align: center;
  }

  &__bundle-assets {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin: 1.5rem 0 2rem;
    max-height: 38rem;
    min-height: 23rem;
    overflow-y: auto;
  }
}

</style>
