<template>
  <div
    ref="suggestAprRef"
    class="suggest-apr">
    <div
      v-if="displayMatchTerms && suggestAprData"
      :class="['suggest-apr__button', {'suggest-apr__button--hovered': isHovered}]">
      Match terms
    </div>
    <div class="suggest-apr__spacer"/>
    <div
      v-show="isHovered"
      class="suggest-apr__options">
      <div
        v-for="(val, index) in suggestAprData"
        :key="index"
        class="suggest-apr__option"
        @click="emit('on-click-match-apr-terms', val.apy)">
        <component
          :is="val.icon"
          v-if="val"
          :height="getIconHeight(val.protocol)"/>
        {{ val.apy }}%
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSuggestLendingTerms } from '@/modules/common/backend/generated'
import { computed, ref, watch } from 'vue'
import type { Component } from 'vue'
import AaveIcon from '@/assets/icons/aave.svg'
import CompoundIcon from '@/assets/icons/compound.svg'
import { useElementHover } from '@vueuse/core'

type Props = {
  chainId: string | null;
  creditSymbol: string | null;
};
const props = defineProps<Props>()

const suggestAprRef = ref<HTMLElement | null>(null)

const emit = defineEmits<{(e: 'on-click-match-apr-terms', apy: number): void;}>()

const isHovered = useElementHover(suggestAprRef)

const chainIdComputed = computed(() => props.chainId || '')
const creditSymbolComputed = computed(() => props.creditSymbol || '')

const isEnabled = computed(() => Boolean(!!props.chainId && !!props.creditSymbol))

const suggestApyQuery = useSuggestLendingTerms(chainIdComputed, creditSymbolComputed,
  {},
  {
    query: {
      enabled: isEnabled,
    },
  },
)

const getIconHeight = (protocol: string) => {
  switch (protocol) {
  case 'aave':
    return '10'
  case 'compound':
    return '14'
  default:
    return '12'
  }
}

const suggestAprData = computed(() => {
  const marketTerms = suggestApyQuery.data?.value?.data?.marketTerms
  if (!marketTerms) return null

  const apySources: {
    icon: Component;
    apy: number;
    protocol: string;
  }[] = []

  const aaveTerms = marketTerms.find((term) => term.name.includes(`Aave v3 ${props.creditSymbol || ''}`))
  if (aaveTerms && aaveTerms.apy?.total?.day30) {
    apySources.push({
      icon: AaveIcon,
      apy: aaveTerms.apy.total.day30 / 100,
      protocol: 'aave',
    })
  }
  const compoundTerms = marketTerms.find((term) => term.name.includes(`Compound v3 ${props.creditSymbol || ''}`))
  if (compoundTerms && compoundTerms.apy?.total?.day30) {
    apySources.push({
      icon: CompoundIcon,
      apy: compoundTerms.apy.total.day30 / 100,
      protocol: 'compound',
    })
  }

  return apySources
})

const displayMatchTerms = computed(() => {
  return props.chainId && props.creditSymbol && suggestAprData.value && suggestAprData.value.length > 0
})

watch(() => props.creditSymbol, () => {
  if (props.creditSymbol) {
    suggestApyQuery.refetch()
  }
})

</script>

<style scoped>
.suggest-apr {
  position: relative;

  &__options {
    position: absolute;
    top: 1.7rem;
    right: 0;
    box-shadow: 0 0.25rem 2rem 0 var(--background-color);
    width: 9rem;
  }

  &__option {
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid var(--separation-grey, #828282);
    background: var(--background-color);
    width: 100%;

    &:hover {
      background: var(--separator-color);
    }
  }

  &__spacer {
    position: absolute;
    width: 100%;
    height: 1.5rem;
    top: 1rem;
    right: 0;
  }

  &__button {
    border: 1px solid var(--separation-grey, #828282);
    padding: 0 0.5rem;
    font-size: 1rem;
    line-height: normal;
    display: flex;
    align-items: center;

    &--hovered {
      border: 1px solid var(--primary-color-1);
    }
  }
}

</style>
