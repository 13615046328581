<template>
  <BaseModal
    v-model:is-open="isOpen"
    :heading-icon="pushLogo.default"
    heading="Push"
    heading-align="left">
    <template #trigger>
      <PushNotificationButton
        :button-width="props.buttonWidth"
        :has-earn-rewards-badge="hasEarnRewardsBadge"
        class="push-notification-modal__button"
        @open="isOpen = true"/>
    </template>

    <template #body>
      <div class="push-notification-modal__text">
        Push Protocol (previously EPNS) is a web3 communication network, enabling cross-chain notifications and messaging
        for dapps, wallets, and services.
      </div>
      <br>
      <div>
        You can use the Push Protocol app as an
        <BaseLink
          class="link--primary"
          :to="linkTo.pushExtension"
          target="_blank">
          <span>extension</span>
        </BaseLink>
        on your browser or download the
        <BaseLink
          class="link--primary"
          :to="linkTo.pushAndroid"
          target="_blank">
          <span>Android</span>
        </BaseLink>
        /
        <BaseLink
          class="link--primary"
          :to="linkTo.pushApple"
          target="_blank">
          <span>iOS</span>
        </BaseLink>
        app, alternatively you can use the
        <BaseLink
          class="link--primary"
          :to="linkTo.pushWebApp"
          target="_blank">
          <span>web app</span>
        </BaseLink>.
      </div>

      <div class="push-notification-modal__img-container">
        <PushMobileImageSvg alt="push mobile"/>
      </div>

      <div class="push-notification-modal__button-container">
        <BaseLink @click="isOpen = false">
          Go back
        </BaseLink>
        <div class="push-notification-modal__subscribe">
          <div
            v-if="isSubscribedToPush"
            class="push-notification-modal__unsubscribe-container">
            <div class="push-notification-modal__now-subscribed-text">
              You are now subscribed to Push
            </div>
            <BaseButton
              button-text="Unsubscribe"
              :color="ButtonColor.Orange"
              :variant="ButtonVariant.Outline"
              @on-button-click="handleUnsubscribe"/>
          </div>
          <BaseButton
            v-else
            button-text="Subscribe to Push"
            @on-button-click="handleSubscribe"/>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import PushNotificationButton from '@/modules/pages/notification-center/PushNotificationButton.vue'
import * as pushLogo from '@/assets/icons/push-protocol.svg'
import BaseLink from '@/general-components/BaseLink.vue'
import linkTo from '@/constants/links'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import usePushNotificationModal from '@/modules/pages/notification-center/settings/modals/usePushNotificationModal'
import PushMobileImageSvg from '@/assets/images/push-mobile.svg'

type Props = {
  buttonWidth?: string
  hasEarnRewardsBadge?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasEarnRewardsBadge: true,
})

const { isOpen, isSubscribedToPush, handleSubscribe, handleUnsubscribe } = usePushNotificationModal()
</script>

<style scoped>
.push-notification-modal {
  &__text {
    margin-top: 1rem;
  }

  &__img-container {
    display: flex;
    justify-content: center;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__unsubscribe-container {
    display: flex;
  }

  &__now-subscribed-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
  }
}
</style>
