const linkTo = {
  aboutPwn: 'https://pwn.xyz/#about',
  audit: 'https://linktr.ee/pwn.audits',
  blog: 'https://pwn.mirror.xyz/',
  bundlerDocs: 'https://docs.pwn.xyz/guides/tools/pwn-bundler',
  cryptonativeReport: 'https://cryptonative.pwn.xyz',
  discord: 'https://discord.gg/aWghBQSdHv',
  discordSupportLink: 'https://discord.com/channels/818107187368427550/1196466767900454994',
  docs: 'https://docs.pwn.xyz',
  faq: 'https://faq.pwn.xyz',
  howItWorks: 'https://pwn.xyz/#howItWorks',
  instagram: 'https://www.instagram.com/pwndao/',
  linkedIn: 'https://www.linkedin.com/company/pwndao/',
  push: 'https://app.push.org/#/channels?channel=0x991552E1C2A96D0Ae72E19552b08A1889aebCF53',
  team: 'https://pwn.xyz/#team',
  twitter: 'https://x.com/pwndao',
  warpcaster: 'https://warpcast.com/pwndao',
  pwnSafeVideoTutorial: '',
  whitepaper: 'https://pwn.xyz/PWN-Whitepaper.pdf',
  youtube: 'https://www.youtube.com/@pwndaoxyz',
  safe: 'https://app.safe.global/',
  pwnSafeSubmitRequest: 'https://forms.gle/yLj59RLZuza9dQsK7',
  pwnSafeExistingRequests:
    'https://docs.google.com/spreadsheets/d/1z1thfYhT-x3nYGOLykVFPxhwfk1RfnImzyGNexl6wyI/edit?resourcekey#gid=704302107',
  verificationGuide: 'https://docs.pwn.xyz/guides/other/verification-guide',
  pwnDocsPersistentOffers: 'https://docs.pwn.xyz/guides/presistent-offers',
  pushExtension: 'https://chrome.google.com/webstore/detail/push-protocol-alpha/lbdcbpaldalgiieffakjhiccoeebchmg',
  pushAndroid: 'https://play.google.com/store/apps/details?id=io.epns.epns&pli=1',
  pushApple: 'https://apps.apple.com/app/ethereum-push-service-epns/id1528614910',
  pushWebApp: 'https://app.push.org',
  careers: 'https://jobs.pwn.xyz/',
  tokenBundlerArticle: 'https://pwn.mirror.xyz/NciALcrSBBv_vkIbVkgubQnkATI3UcnTQG6WZ3Bl_YI',
  termsAndConditions: 'https://pwn.xyz/terms-and-conditions',
  privacyPolicy: 'https://pwn.xyz/privacy-policy',
  pwnAppProduction: 'https://app.pwn.xyz',
  pwnLogo: 'https://pwn.xyz/pwn-logo.png',
  tokenListsArticle: 'https://docs.pwn.xyz/guides/other/token-verification-lists-101',
  spaceSubmitForm: 'https://forms.gle/eMEBckwT2WAoKBu29',
  rewardsDocs: 'https://docs.pwn.xyz/guides/other/incentives',

  // data sources
  coinMarketCap: 'https://coinmarketcap.com/converter/',
  upshot: 'https://upshot.xyz/',
  nftbank: 'https://nftbank.ai/',
  venoFinance: 'https://veno.finance/',
  moralis: 'https://moralis.io/',
  coingecko: 'https://www.coingecko.com/',
  opensea: 'https://opensea.io/',
  dexguru: 'https://dex.guru/',
  looksRare: 'https://looksrare.org/',
  ebisusBay: 'https://app.ebisusbay.com/',
  pendle: 'https://www.pendle.finance/',
  swaap: 'https://www.swaap.finance/',
  revest: 'https://revest.finance/',
  superform: 'https://www.superform.xyz/',
  kyberswap: 'https://kyberswap.com/',
  sommelier: 'https://www.sommelier.finance/',
  instadapp: 'https://instadapp.io/',
  yearn: 'https://yearn.fi/',
  backed: 'https://backed.fi/',
  beefy: 'https://beefy.com/',
  sushi: 'https://sushi.com/',
  morpho: 'https://morpho.org/',
  kernel: 'https://kernelprotocol.com/',
  mellow: 'https://mellow.finance/',
  reserve: 'https://reserve.org/',
  pendleMarket: 'https://app.pendle.finance/trade/markets',
  spectra: 'https://spectra.finance/',
  resolv: 'https://resolv.xyz/',
} as const

export default linkTo
