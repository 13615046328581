import { computed, ref } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

const isOpen = ref(false)
const openedCryptoPunk = ref<AssetWithAmount>()

export default function useModalCryptoPunkWrap() {
  const openModal = (cryptoPunk: AssetWithAmount) => {
    openedCryptoPunk.value = cryptoPunk
    isOpen.value = true
  }

  return {
    isOpen,
    openModal,
    openedCryptoPunk: computed(() => openedCryptoPunk.value),
  }
}
