<template>
  <BaseTooltip
    v-if="displayTooltip"
    is-interactive
    append-to-body
    :border-color="TooltipBorderColor.DarkSeparator">
    <template #trigger>
      <slot name="trigger">
        <div class="rewards-tooltip__trigger">
          <RepScoreIcon
            width="14"
            class="rewards-tooltip__trigger-icon"
            height="14"/>
          <span v-if="isWithText">
            Rewards
          </span>
        </div>
      </slot>
    </template>

    <template #default>
      <div class="rewards-tooltip__tooltip-container">
        <div class="rewards-tooltip__header">
          {{ tooltipHeader }}
        </div>
        <div
          v-if="hasAprValue"
          :class="['rewards-tooltip__apr-stats', {'rewards-tooltip__apr-stats--with-bottom-border': rewardsToGet.length > 0}]">
          <span>Net APR</span>
          <span class="rewards-tooltip__value">{{ apr }}</span>
        </div>
        <template v-if="rewardsToGet.length > 0">
          <div class="rewards-tooltip__subsection-header">
            Points
          </div>
          <div
            v-for="reward in rewardsToGet"
            :key="reward.name"
            class="rewards-tooltip__point-container">
            <img
              v-if="typeof reward.icon === 'string'"
              :src="reward.icon"
              :alt="reward.name"
              height="14"
              width="14">
            <component
              :is="reward.icon"
              v-else
              height="14"
              width="14"/>
            <span>{{ reward.name }}</span>
            <span class="rewards-tooltip__value rewards-tooltip__point-value">{{ reward.value }}</span>
          </div>
        </template>
        <BaseLink
          v-if="linkTo.rewardsDocs"
          class="rewards-tooltip__learn-more"
          target="_blank"
          :to="linkTo.rewardsDocs">
          Learn more
        </BaseLink>
      </div>
    </template>
  </BaseTooltip>
  <div
    v-else
    class="rewards-tooltip__fallback">
    <slot name="fallback"/>
  </div>
</template>

<script setup lang="ts">
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import { getEligibleRewards } from './rewardsRegistry'
import type { EligibleRewardsInput } from './rewardsRegistry'
import { computed, toRefs } from 'vue'
import RepScoreIcon from '@/assets/icons/rep-score-primary-color.svg'
import BaseLink from '@/general-components/BaseLink.vue'
import linkTo from '@/constants/links'

interface Props {
    assets: EligibleRewardsInput[]
    apr?: string
    proposalId?: number | string | null
    loanId?: number | string | null
    isWithText?: boolean
    isWithRewards?: boolean
    multiplier?: number
    loanContractAddress?: string
    proposalRewardsAmount?: string
    alwaysShowTooltip?: boolean
}
const props = defineProps<Props>()
const { assets, apr } = toRefs(props)
// // const { address: userAddress } = useCustomAccount()

const hasAprValue = computed(() => apr.value !== undefined && apr.value !== '-')

// // const proposalId = computed(() => {
// //   if (!props.proposalId) return null
// //   return Number(props.proposalId)
// // })

// // const loanId = computed(() => {
//   if (!props.loanId) return null
//   return String(props.loanId)
// })

// const loanId = computed(() => {
//   if (!props.loanId) return null
//   return String(props.loanId)
// })

// const repPointsProposalParams = computed(() => {
// //   return { proposal_ids: [proposalId.value ?? 0] }
// // })

// const userAddressValue = computed(() => userAddress.value || '')

/**
 * Both are disabled by now. The info would be:
 * Boolean(userAddressValue.value && loanId.value)
 */
// const proposalsRequestIsEnabled = computed(() => false)
// const loansRequestIsEnabled = computed(() => false)

// const repPointsProposalQuery = useUserPotentialReputationPerProposalFundRetrive(userAddressValue, repPointsProposalParams, {
//   query: {
//     enabled: proposalsRequestIsEnabled,
//   },
// })

// const loanParam = computed(() => {
//   return `${chainId.value},${props.loanContractAddress},${loanId.value}`
// })
// const userAddressValue = computed(() => userAddress.value || '')

/**
 * Both are disabled by now. The info would be:
 * Boolean(userAddressValue.value && loanId.value)
 */
// const proposalsRequestIsEnabled = computed(() => false)
// const loansRequestIsEnabled = computed(() => false)

// const repPointsProposalQuery = useUserPotentialReputationPerProposalFundRetrive(userAddressValue, repPointsProposalParams, {
//   query: {
//     enabled: proposalsRequestIsEnabled,
//   },
// })

// const loanParam = computed(() => {
//   return `${chainId.value},${props.loanContractAddress},${loanId.value}`
// })

// // const repPointsLoanParams = computed(() => {
// //   return { loan_ids: [loanParam.value] }
// // })

// const repPointsLoanQuery = useUserPotentialReputationPerLoanRepayRetrive(userAddressValue, repPointsLoanParams, {
//   query: {
//     enabled: loansRequestIsEnabled,
//   },
// })
// const repPointsLoanQuery = useUserPotentialReputationPerLoanRepayRetrive(userAddressValue, repPointsLoanParams, {
//   query: {
//     enabled: loansRequestIsEnabled,
//   },
// })

// const repPoints = computed(() => {
//   if (!props.proposalId && !props.loanId) return null
//   if (props.loanId) {
//     const data = repPointsLoanQuery.data.value?.data
//     return data?.potentialRepsPerLoanRepay[loanParam.value || '']
//   }
//   const data = repPointsProposalQuery.data.value?.data
//   return data?.potentialRepsPerProposalFund[proposalId.value || '']
// })

const rewardsToGet = computed(() => {
  const rewards = getEligibleRewards(assets.value)

  // // if (repPoints.value) {
  //   rewards.push({
  //     icon: RepScoreIcon,
  //     name: 'Reputation',
  //     value: String(repPoints.value),
  //   })
  // }
  if (props.proposalRewardsAmount) {
    rewards.push({
      icon: RepScoreIcon,
      name: 'Reputation',
      value: `+${props.proposalRewardsAmount}`,
    })
  }
  if (props.isWithRewards && props.multiplier && !props.proposalRewardsAmount) {
    rewards.push({
      icon: RepScoreIcon,
      name: 'Reputation',
      value: `x${props.multiplier}`,
    })
  }
  return rewards
})

const displayTooltip = computed(() => {
  return rewardsToGet.value.length > 0 || props.alwaysShowTooltip
})

const tooltipHeader = computed(() => {
  let header = ''
  if (hasAprValue.value) {
    header += 'APR'

    if (rewardsToGet.value !== null) {
      header += ' & '
    }
  }

  if (rewardsToGet.value !== null) {
    header += 'Rewards'
  }

  header += ' Breakdown'
  return header
})
</script>

<style scoped>
.rewards-tooltip {
  &__trigger-icon {
    fill: var(--primary-color-1);
  }

  &__trigger {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: help;
  }

  &__tooltip-container {
    font-size: 0.875rem;
  }

  &__header {
    font-family: var(--font-family-screener);
    border-bottom: 1px solid var(--dark-separator-color);
    padding-bottom: 0.5rem;
  }

  &__apr-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-top: 1px solid var(--dark-separator-color);
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;

    &--with-bottom-border {
      border-bottom: 1px solid var(--dark-separator-color);
    }
  }

  &__value {
    font-weight: 700;
  }

  &__subsection-header {
    font-size: 0.75rem;
    color: var(--text-color-secondary);
    text-align: left;
  }

  &__point-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  &__point-value {
    margin-left: auto;
  }

  &__learn-more {
    display: block;
    text-decoration: underline;
    text-align: right;
    color: var(--text-color-secondary);
    padding-top: 0.25rem;
  }

  &__fallback {
    cursor: default;
  }
}
</style>
