<template>
  <AssetStatInfo
    class="kyber-swap-asset-description__stats kyber-swap-asset-description__stats-tokens-row"
    label="LP Position"
    tooltip-text="Shows the tokens in the liquidity pool position">
    <BaseSkeletor
      v-if="isLoadingTokens"
      class="kyber-swap-asset-description__loader"/>
    <div
      v-else-if="tokenOne && tokenTwo"
      class="kyber-swap-asset-description__tokens">
      <TokenAndEthAndUsdPriceLabel :token="tokenOne"/>
      /
      <TokenAndEthAndUsdPriceLabel :token="tokenTwo"/>
    </div>
  </AssetStatInfo>

  <AssetStatInfo
    class="kyber-swap-asset-description__stats"
    label="Estimated Value"
    :amount-in-eth-and-usd="nftAppraisal?.price"
    tooltip-text="Estimated value of this NFT"
    :tooltip-max-width="650"
    :price-source="DataSourceType.COINGECKO">
    <template #base-tooltip>
      <ValuationTooltip/>
    </template>
  </AssetStatInfo>

  <AssetStatInfo
    class="kyber-swap-asset-description__stats kyber-swap-asset-description__stats-tokens-row"
    label="Rewards"
    tooltip-text="Earned rewards which are held by this liquidity pool position">
    <BaseSkeletor
      v-if="isLoadingTokens"
      class="kyber-swap-asset-description__loader"/>
    <div
      v-else-if="tokenOneFeesEarned && tokenTwoFeesEarned"
      class="kyber-swap-asset-description__tokens">
      <TokenAndEthAndUsdPriceLabel :token="tokenOneFeesEarned"/>
      /
      <TokenAndEthAndUsdPriceLabel :token="tokenTwoFeesEarned"/>
    </div>
  </AssetStatInfo>

  <AssetStatInfo
    class="kyber-swap-asset-description__stats"
    label="Position Range"
    tooltip-text="Price range for this liquidity pool.
    In Range = Earning fees
    Out of range = Not earning fees">
    <div class="kyber-swap-asset-description__price-range-wrap">
      <SafeText
        class="kyber-swap-asset-description__price-range"
        :text="String(priceRangeTrait?.value)"
        :tooltip-text="String(priceRangeTrait?.value)"/>

      <div
        :class="['kyber-swap-asset-description__position-in-range', {
          'kyber-swap-asset-description__position-in-range--active': positionInRangeTrait?.value === 'True',
          'kyber-swap-asset-description__position-in-range--waiting': positionInRangeTrait?.value !== 'True',
        }]">
        <span v-if="positionInRangeTrait?.value === 'True'">
          In range
        </span>
        <span v-else>
          Out of range
        </span>
      </div>
    </div>
  </AssetStatInfo>
</template>

<script lang="ts" setup>
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, ref, watch } from 'vue'
import AssetStatInfo from '@/general-components/AssetStatInfo.vue'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import TokenAndEthAndUsdPriceLabel from '@/general-components/TokenAndEthAndUsdPriceLabel.vue'
import ValuationTooltip from '@/general-components/ValuationTooltip.vue'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import SafeText from '@/general-components/SafeText.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import useSectionNFTAppraisal from '@/modules/pages/asset/nft-page/SectionNFTAppraisal/useSectionNFTAppraisal'
import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import { NFTAppraisal } from '@/modules/common/assets/typings/AssetPriceClasses'
import type { NumericTrait, StringTrait } from '@/modules/pages/asset/nft-page/types/AssetTraits'

type Props = {
  asset: AssetMetadata;
};

const props = defineProps<Props>()

const traits = computed<Array<StringTrait | NumericTrait>>(() => props.asset.traits.allTraits)
const { nftAppraisal } = useSectionNFTAppraisal()

const priceRangeTrait = computed(() => traits.value.find((v) => v.title === 'Price Bound'))
const positionInRangeTrait = computed(() => traits.value.find((v) => v.title === 'Is position in range'))
const { fetchErc20Metadata } = useMetadataFetch()

const tokenOne = ref<AssetWithAmount | null>(null)
const tokenTwo = ref<AssetWithAmount | null>(null)
const isLoadingTokens = ref(false)

const tokenOneFeesEarned = ref<AssetWithAmount | null>(null)
const tokenTwoFeesEarned = ref<AssetWithAmount | null>(null)

watch(
  traits,
  async (currentTraits) => {
    if (!currentTraits.length) return
    isLoadingTokens.value = true
    let tokenOneAddress, tokenOneAmount, tokenOneUsdAmount, tokenTwoAddress, tokenTwoAmount, tokenTwoUsdAmount, feesEarnedTokenOne, feesEarnedTokenTwo, feesEarnedTokenOneUsd, feesEarnedTokenTwoUsd

    // doing a single iteration to get all the traits needed for calculations later
    for (const trait of currentTraits) {
      if (trait.title.startsWith('Pool Token')) {
        if (trait.title.endsWith('One')) {
          tokenOneAddress = trait.value
          continue
        }
        if (trait.title.endsWith('Two')) {
          tokenTwoAddress = trait.value
          continue
        }
      }

      if (trait.title === 'Token One Liquidity') {
        tokenOneAmount = trait.value
        continue
      }

      if (trait.title === 'Token Two Liquidity') {
        tokenTwoAmount = trait.value
        continue
      }

      if (trait.title === 'Token One Usd') {
        tokenOneUsdAmount = trait.value
        continue
      }

      if (trait.title === 'Token Two Usd') {
        tokenTwoUsdAmount = trait.value
        continue
      }

      if (trait.title === 'Fees Earned Token One') {
        feesEarnedTokenOne = trait.value
        continue
      }

      if (trait.title === 'Fees Earned Token Two') {
        feesEarnedTokenTwo = trait.value
      }

      if (trait.title === 'Fees Earned Token One Usd') {
        feesEarnedTokenOneUsd = trait.value
        continue
      }

      if (trait.title === 'Fees Earned Token Two Usd') {
        feesEarnedTokenTwoUsd = trait.value
      }
    }

    const [tokenOneMetadata, tokenTwoMetadata] = await Promise.all(
      [
        fetchErc20Metadata({
          chainId: props.asset.chainId,
          contractAddress: tokenOneAddress,
        }),
        fetchErc20Metadata({
          chainId: props.asset.chainId,
          contractAddress: tokenTwoAddress,
        }),
      ],
    )

    tokenOne.value = new AssetWithAmount({
      ...tokenOneMetadata,
      appraisal: new NFTAppraisal({
        price: new AmountInEthAndUsd(
          // @ts-expect-error FIXME: strictNullChecks
          undefined,
          tokenOneUsdAmount,
        ),
      }),
      amount: tokenOneAmount,
    })

    tokenTwo.value = new AssetWithAmount({
      ...tokenTwoMetadata,
      appraisal: new NFTAppraisal({
        price: new AmountInEthAndUsd(
          // @ts-expect-error FIXME: strictNullChecks
          undefined,
          tokenTwoUsdAmount,
        ),
      }),
      amount: tokenTwoAmount,
    })

    tokenOneFeesEarned.value = new AssetWithAmount({
      ...tokenOneMetadata,
      appraisal: new NFTAppraisal({
        price: new AmountInEthAndUsd(
          // @ts-expect-error FIXME: strictNullChecks
          undefined,
          feesEarnedTokenOneUsd,
        ),
      }),
      amount: feesEarnedTokenOne,
    })
    tokenTwoFeesEarned.value = new AssetWithAmount({
      ...tokenTwoMetadata,
      appraisal: new NFTAppraisal({
        price: new AmountInEthAndUsd(
          // @ts-expect-error FIXME: strictNullChecks
          undefined,
          feesEarnedTokenTwoUsd,
        ),
      }),
      amount: feesEarnedTokenTwo,
    })

    isLoadingTokens.value = false
  },
  {
    immediate: true,
  },
)

</script>

<style scoped>
.kyber-swap-asset-description {
  &__loader {
    width: 100%;
  }

  &__stats {
    row-gap: 0.5rem;

    &:nth-child(odd) {
      margin-right: 1rem;
    }
  }

  &__tokens {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__price-range-wrap {
    display: flex;
    flex-flow: nowrap;
  }

  &__price-range {
    max-width: 25rem;
  }

  &__position-in-range {
    font-size: 0.875rem;
    font-family: var(--font-family-oxygen-mono);
    white-space: nowrap;
    margin-left: 1rem;

    &--active {
      &::before {
        content: "";
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: var(--positive-bright);
      }
    }

    &--waiting {
      &::before {
        content: "";
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: var(--dark-separator-color);
      }
    }
  }
}
</style>
