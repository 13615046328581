<template>
  <PwnSafeAtrTable
    v-model:active-sort-option="selectedSortOption"
    class="pwn-safe-claim-and-burn-atr-tokens"
    :sorted-atr-tokens="sortedAndFilteredAtrTokens"
    :table-definition="CLAIM_AND_BURN_ASSET_TABLE_DEFINITION"
    is-row-selectable
    :is-asset-selected="handleIsAssetSelected"
    :display-coins="displayCoins"
    :display-nfts="displayNfts"
    heading="ATR Tokens "
    @on-switch-display-nfts="handleOnSwitchDisplayNfts"
    @on-switch-display-coins="handleOnSwitchDisplayCoins"
    @on-atr-select="handleAtrSelect"/>
</template>

<script lang="ts" setup>
import usePwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import PwnSafeAtrTable from '@/modules/pages/pwn-safe/tables/PwnSafeAtrTable.vue'
import { CLAIM_AND_BURN_ASSET_TABLE_DEFINITION } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useAssetsSelection from '@/modules/common/assets/useAssetsSelection'
import usePwnSafeAssetsDashboard from '@/modules/pages/pwn-safe/usePwnSafeAssetsDashboard'

const { atrTokensToClaimAndBurn, displayCoins, displayNfts, sortedAndFilteredAtrTokens } = usePwnSafeClaimAndBurn()
const { isAssetSelected, addOrRemoveAssetSelection } = useAssetsSelection()
const { selectedSortOption } = usePwnSafeAssetsDashboard()

const handleIsAssetSelected = (atrToken: AssetWithAmount) => {
  return isAssetSelected({ asset: atrToken, selectedAssets: atrTokensToClaimAndBurn })
}

const handleAtrSelect = (atrToken: AssetWithAmount) => {
  addOrRemoveAssetSelection({ asset: atrToken, selectedAssets: atrTokensToClaimAndBurn })
}

const handleOnSwitchDisplayNfts = (val: boolean) => {
  displayNfts.value = val
}

const handleOnSwitchDisplayCoins = (val: boolean) => {
  displayCoins.value = val
}
</script>

<style scoped>
.pwn-safe-claim-and-burn-atr-tokens {
  margin-bottom: 4rem;

  ::v-deep(.section__controls) {
    margin-right: 1.25rem;
  }
}
</style>
