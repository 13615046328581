<template>
  <BaseTooltip
    ref="trigger"
    class="safe-text__text"
    :is-interactive="!allowClickThrough"
    :has-tooltip="isOverflown && hasTooltip"
    placement="center-start">
    <template #trigger>
      <slot name="text">
        {{ text }}
      </slot>
    </template>
    <template #default>
      <div class="safe-text__tooltip-text">
        {{ tooltipTextComputed }}
      </div>
    </template>
  </BaseTooltip>
</template>

<script setup lang="ts">
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import { computed, ref, toRefs, watch } from 'vue'

interface Props {
  text?: string | number | bigint
  hasTooltip?: boolean
  tooltipText?: string
  allowClickThrough?: boolean // todo rename this to something like "isClickable" or "isInteractive", given how new props in BaseTooltip are named?
}
const props = withDefaults(defineProps<Props>(), {
  allowClickThrough: true,
  hasTooltip: true,
})
const { text, tooltipText, allowClickThrough } = toRefs(props)

const emit = defineEmits<{
  onOverflow: [isOverflow: boolean]
}>()

const trigger = ref(null)
const isOverflown = ref<boolean>()

const tooltipTextComputed = computed(() => tooltipText.value || text.value)

watch([trigger, text], (newValue, prevValue) => {
  const [newTrigger] = newValue
  if (!newTrigger) return
  // @ts-expect-error FIXME: strictNullChecks
  const { offsetWidth, scrollWidth } = newTrigger.tooltipRef?.elem || {}
  if (offsetWidth === 0) {
    isOverflown.value = false
    emit('onOverflow', false)
  } else {
    const isOverflownWidth = offsetWidth < scrollWidth
    isOverflown.value = isOverflownWidth
    emit('onOverflow', isOverflownWidth)
  }
}, { immediate: true })

</script>

<style scoped>
.safe-text {
  &__text {
    display: inline-block;
    width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tooltip-text {
    line-height: 2rem;
  }
}
</style>
