<template>
  <RevampBaseModal
    v-model:is-open="storeSelectCollectionAsset.isOpen"
    :size="ModalSize.Large">
    <template #custom-header>
      <header
        v-if="storeSelectCollectionAsset.isOnlySelectAsset"
        class="select-collection-asset-modal__header">
        <h2 class="select-collection-asset-modal__title">
          Select Collateral
        </h2>
        <div class="select-collection-asset-modal__breadcrumbs-and-close">
          <CloseIconSvg
            alt="close"
            class="select-collection-asset-modal__close"
            @click.stop="storeSelectCollectionAsset.isOpen = false"/>
        </div>
      </header>
      <header
        v-else
        class="select-collection-asset-modal__header">
        <h2 class="select-collection-asset-modal__title">
          Start loan
        </h2>
        <div class="select-collection-asset-modal__breadcrumbs-and-close">
          <span class="select-collection-asset-modal__breadcrumb-select-collateral">1. Select Collateral</span>
          <span class="select-collection-asset-modal__breadcrumb-loan-preview"> > 2. Loan Preview </span>
          <CloseIconSvg
            alt="close"
            class="select-collection-asset-modal__close"
            @click.stop="storeSelectCollectionAsset.isOpen = false"/>
        </div>
      </header>
    </template>
    <template #body>
      <div class="select-collection-asset-modal__body">
        <div class="select-collection-asset-modal__search">
          <SearchBar
            v-model="searchBar"
            placeholder="Search asset name"/>
        </div>

        <ConnectWallet v-if="status !== 'connected'"/>

        <div
          v-else
          class="select-collection-asset-modal__content">
          <UserNftsCollectionAssets
            v-if="(!userNftsIsPending && selectedCollectionNfts?.length > 0) || userNftsIsPending"
            :data="selectedCollectionNfts"
            :search="throttledSearchBar"
            :is-loading="userNftsIsPending"
            @select-asset="handleSelectedAsset"/>
          <div
            v-else
            class="select-collection-asset-modal__no-matching-assets">
            No Matching Assets
          </div>
        </div>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import ModalSize from '@/general-components/ModalSize'
import CloseIconSvg from '@/assets/icons/close.svg'
import {
  useSelectCollectionAssetModalStore,
} from '@/revamp/components/modals/select-collection-asset/useSelectCollectionAssetModalStore'
import SearchBar from '@/revamp/components/SearchBar.vue'
import { computed, ref, watch } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useThrottle } from '@vueuse/core'
import UserNftsCollectionAssets from '@/revamp/components/modals/select-your-collateral/UserNftsCollectionAssets.vue'
import { compareAddresses } from '@/utils/utils'
import { useStartLoanModalStore } from '@/revamp/components/actions/useStartLoanModalStore'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { storeToRefs } from 'pinia'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import ConnectWallet from '@/revamp/components/modals/ConnectWallet.vue'

const storeSelectCollectionAsset = useSelectCollectionAssetModalStore()
const startLoanModalStore = useStartLoanModalStore()

const chainId = computed(() => storeSelectCollectionAsset.chainId)

const userAssetsStore = useUserAssetsStore()
const { userNfts, userNftsIsPending } = storeToRefs(userAssetsStore)
const { address, status } = useCustomAccount()
const searchBar = ref('')
const throttledSearchBar = useThrottle(searchBar, 300)

const selectedCollectionNfts = computed(() => {
  return userNfts.value.filter((nft) => {
    return compareAddresses(nft.address, storeSelectCollectionAsset.collectionAddress) &&
      nft.chainId === chainId.value
      // nft.amountRaw >= storeSelectCollectionAsset.minCollateralAmount
  })
})

watch([address, () => storeSelectCollectionAsset.collectionAddress], async ([newAddress]) => {
  if (storeSelectCollectionAsset.collectionAddress && newAddress) {
    userAssetsStore.loadUserAssets(newAddress)
    await userAssetsStore.invalidateAndRefetch(newAddress)
  }
}, { immediate: true })

const handleSelectedAsset = async (asset?: AssetWithAmount) => {
  if (!asset) return
  storeSelectCollectionAsset.selectedCollateral = asset
  storeSelectCollectionAsset.isOpen = false
  if (storeSelectCollectionAsset.selectAsset) {
    await storeSelectCollectionAsset.selectAsset(asset)
  }
  if (storeSelectCollectionAsset.isOnlySelectAsset) {
    return
  }
  startLoanModalStore.open()
}

</script>

<style scoped>
.select-collection-asset-modal {
  &__title {
    font-size: 24px;
    font-family: var(--font-family-screener);
    width: 100%;
  }

  &__body {
    width: 720px;
  }

  &__close {
    width: 30px;
    height: 30px;
    margin-left: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__no-matching-assets {
    font-family: var(--font-family-screener);
    font-size: 24px;
    text-align: center;
    margin: 4rem 0 5rem;
  }

  &__breadcrumbs-and-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.2rem;
    width: 100%;
  }

  &__breadcrumb-select-collateral {
    color: var(--primary-color-1);
    font-family: var(--font-family-supreme);
  }

  &__breadcrumb-loan-preview {
    color: var(--separation-grey, #828282);
    font-family: var(--font-family-supreme);
  }

  &__content {
    margin-top: 1.5rem;
  }

  &__header {
    display: flex;
    position: relative;
  }

  &__search {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
}

.search-bar {
  width: 100%;
}
</style>
