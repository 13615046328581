<template>
  <div
    ref="dropdownRef"
    class="chain-filter-dropdown">
    <div
      :class="['chain-filter-dropdown__trigger', {
        'chain-filter-dropdown__trigger__selected': selectedChains !== 'all'
      }]"
      @click="handleToggleDropdown">
      <span
        v-if="selectedChains === 'all'"
        class="chain-filter-dropdown__trigger-all-chains">All Networks</span>
      <span v-if="selectedChains === 'all'">
        <ArrowDownIcon
          :class="['chain-filter-dropdown__caret-icon', {'chain-filter-dropdown__caret-icon--open': isOpen}]"
          color="var(--text-color)"
          alt="arrow down icon"/>
      </span>
      <div
        v-else
        class="chain-filter-dropdown__selected-chains">
        <div
          v-for="chain in selectedChains"
          :key="chain"
          class="chain-filter-dropdown__selected-chain">
          <component
            :is="getMonoChromeChainIcon(chain)"
            width="20"
            height="20"
            :class="[
              'chain-filter-dropdown__selected-chain-icon',
              {
                'chain-filter-dropdown__selected-chain-icon--selected': selectedChains as ChainFilterOptions !== 'all'
              }
            ]"
            :alt="getChainName(chain)"/>
          <div class="chain-filter-dropdown__selected-chain-divider"/>
        </div>
      </div>
    </div>
    <div
      v-if="isOpen"
      class="chain-filter-dropdown__content">
      <div
        v-if="chainsToDisplay.length > 1"
        class="chain-group-filter">
        <button
          :class="['chain-group-filter__button', {
            'chain-group-filter__button--selected': selectedChains === 'all' ,
            'chain-group-filter__button--disabled': disabledChains
          }]"
          @click="() => props.setChain?.('all')">
          All Networks
        </button>

        <div
          v-for="chain in chainsToDisplay"
          :key="chain"
          :class="[
            'chain-group-filter__button',
            {
              'chain-group-filter__button--selected': selectedChains !== 'all' && selectedChains?.includes(chain) ,
              'chain-group-filter__button--disabled': disabledChains && disabledChains?.includes(chain)
            },
          ]"
          @click="() => handleSetChain(chain)">
          <img
            v-if="isL2Chain(chain)"
            src="@/assets/icons/l2.svg?url"
            width="24"
            height="24"
            alt="layer 2">
          <component
            :is="getMonoChromeChainIcon(chain)"
            class="chain-group-filter__icon"
            :alt="getChainName(chain)"/>
          <span>
            {{ getChainName(chain) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import ArrowDownIcon from '@/general-components/icons/ArrowDownIcon.vue'
import type { ChainFilterOptions, SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { getChainName, getMonoChromeChainIcon } from '@/utils/chain'
import { SupportedChain } from '@/constants/chains/types'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import { onClickOutside } from '@vueuse/core'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

const chainsToDisplay = computed(() => {
  if (isStarknet) {
    return [SupportedChain.StarknetSepolia]
  }
  return enabledChains
})

const proposalFiltersStore = useProposalFilters()

const isOpen = ref(false)
const dropdownRef = ref(null)
const handleToggleDropdown = () => {
  isOpen.value = !isOpen.value
}

type Props = {
  selectedChains: ChainFilterOptions
  setChain?: (chain: SetChainFilterOption) => void
  disabledChains?: SupportedChain[]
}

const props = defineProps<Props>()

const handleSetChain = (chain: Exclude<SetChainFilterOption, 'all'>) => {
  proposalFiltersStore.actions.updateCollateralAssetsFilter([])
  if (props.selectedChains !== 'all' && props.selectedChains?.includes(chain) && props.selectedChains?.length === 1) {
    props.setChain?.('all')
    return
  }

  if (props.disabledChains && props.disabledChains?.includes(chain)) return
  props.setChain?.(chain)
}

const handleClickOutside = () => {
  isOpen.value = false
}
onClickOutside(dropdownRef, handleClickOutside)

const isL2Chain = (chain: SupportedChain) => {
  return [SupportedChain.Arbitrum, SupportedChain.Optimism, SupportedChain.Base, SupportedChain.UnichainSepolia].includes(chain)
}

</script>

<style scoped>
.chain-filter-dropdown {
  &__trigger {
    display: flex;
    height: 2rem;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid var(--separation-grey, #828282);
    cursor: pointer;

    &:hover {
      border: 1px solid var(--primary-color-1);
    }

    &__selected {
      border: 1px solid var(--primary-color-1);
    }
  }

  &__trigger-all-chains {
    padding-left: 0.25rem;
    font-size: 0.875rem;
    white-space: nowrap;
  }

  &__caret-icon {
    margin-left: 0.5rem;
    transition: transform var(--primary-transition-time);

    &--open {
      transform: rotate(180deg);
    }
  }

  &__selected-chains {
    display: flex;
    align-items: center;
  }

  &__selected-chain-divider {
    height: 1rem;
    display: flex;
    align-self: center;
    border-right: 1px solid var(--separation-grey);
    margin: 0 0.25rem;
  }

  &__selected-chain {
    display: flex;

    &:last-child .chain-filter-dropdown__selected-chain-divider {
      display: none;
    }
  }

  &__selected-chain-icon {
    &--selected {
      filter: brightness(0) saturate(100%) invert(88%) sepia(86%) saturate(6818%)
        hue-rotate(89deg) brightness(104%) contrast(105%);
    }
  }
}

.chain-group-filter {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  right: 9.5rem;
  gap: 0.25rem;
  border: 1px solid var(--separation-grey);
  padding: 3px;
  box-sizing: content-box;
  background: var(--background-color);

  @media only screen and (--mobile-viewport) {
    top: 2.5rem;
    right: 0;
  }

  &__button {
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid transparent;
    padding: 0.75rem;
    background-color: transparent;
    color: var(--text-color);
    font-size: 0.875rem;
    line-height: 1rem;
    font-family: var(--font-family-oxygen-mono);
    font-weight: 400;
    width: 15rem;
    display: flex;
    height: 2.75rem;
    gap: 0.5rem;
    align-self: stretch;
    user-select: none;
    transition: 0.3s ease-in-out background-color,
      0.3s ease-in-out border-color;

    @media only screen and (--mobile-viewport) {
      width: 12rem;
    }

    &--selected {
      background-color: var(--primary-color-3);
      border: 1px solid var(--primary-color-1);

      &:hover {
        opacity: 0.9;
      }
    }

    &--disabled {
      opacity: 0.3;
    }

    &:hover:not(.chain-group-filter__button--selected) {
      background: var(--gray-3);
    }
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  @media only screen and (--mobile-viewport) {
    &__button {
      font-size: 0.675rem;
    }
  }
}

</style>
