<template>
  <div class="search-result-section">
    <div class="search-result-section__header">
      <span>
        {{ headerTitle }}
      </span>
      <span class="search-result-section__header-value">
        {{ headerValueName }}
      </span>
    </div>
    <div class="search-result-section__list">
      <slot/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'

type Props = {
  headerTitle: string
  headerValueName: string
}

const props = defineProps<Props>()
const { headerTitle, headerValueName } = toRefs(props)
</script>

<style scoped>
  .search-result-section {
    width: 100%;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    &__header {
      background: var(--gray-3);
      padding: 0.5rem;
      box-sizing: border-box;
      display: flex;
      font-family: var(--font-family-screener);
    }

    &__header-value {
      margin-left: auto;
      text-align: end;
    }
  }
</style>
