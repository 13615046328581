<template>
  <span>
    {{ summarizedAmount.raw > 0 ? summarizedAmount.formatted : '---' }}
  </span>
</template>

<script lang="ts" setup>
import type { ThesisCreditStatsSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { useAssetListPrice } from '@/revamp/hooks/asset-prices/useAssetListPrice'
import { parseThesisAsset } from '@/revamp/hooks/thesis/utils'
import { computed } from 'vue'
import { formatUnits } from 'viem'

type Props = {
   stats: ThesisCreditStatsSchemaWorkaroundBackendSchema[]
}

const props = defineProps<Props>()

const assetsWithTotalCommited = computed(() => {
  const assets = props.stats.map(v => {
    const asset = parseThesisAsset(v.creditAssetMetadata)

    return asset.updateAssetAmounts({
      amount: formatUnits(BigInt(v.amountsStats.totalCommittedAmount), v.creditAssetMetadata.decimals ?? 18),
    })
  })
  return assets
})

const { summarizedAmount } = useAssetListPrice(assetsWithTotalCommited)
</script>
