<template>
  <div ref="modalRef">
    <div
      :class="[
        'reputation-score-modal__trigger',
        { 'reputation-score-modal__trigger--hovered': isHovered },
      ]">
      <ReputationScoreSvg
        class="reputation-score-modal__trigger-icon"
        width="20"
        height="20"
        alt="reputation score"/>
      <div v-if="isLoading">
        <BaseSkeletor :width="32"/>
      </div>
      <span v-else>{{ formatToken(String(userReputation?.reputationScore)) }}</span>
    </div>

    <div
      v-show="isHovered"
      class="reputation-score-modal__spacer"/>
    <div
      v-show="isHovered"
      class="reputation-score-modal__opened-modal">
      <header class="reputation-score-modal__header">
        Current Reputation Score
      </header>

      <div class="reputation-score-modal__body">
        <BaseSkeletor v-if="isLoading"/>
        <div
          v-else
          class="reputation-score-modal__content">
          <div class="reputation-score-modal__boost">
            <LightningSvg
              width="19"
              height="19"
              class="reputation-score-modal__lightning-icon"
              alt="boost"/>
            <BoostAndScoreOverviewModal>
              <template #trigger-modal="{ openModal }">
                <span
                  class="reputation-score-modal__trigger-text"
                  @click="openModal">x{{ userReputation?.totalMultiplier.toFixed(3) }}
                  Boost
                </span>
              </template>
            </BoostAndScoreOverviewModal>
          </div>
          <div class="reputation-score-modal__reputation-score">
            <ReputationScoreSvg
              class="reputation-score-modal__reputation-score-icon"
              width="20"
              height="20"
              alt="reputation score"/>
            <span>{{
              formatToken(String(userReputation?.reputationScore))
            }}</span>
          </div>
          <div class="reputation-score-modal__divider"/>
          <div class="reputation-score-modal__info-line">
            <div class="reputation-score-modal__info-line-label">
              Current Ranking
            </div>
            <div class="reputation-score-modal__current-ranking">
              #{{ userReputation?.position }}
            </div>
          </div>
          <!--   TODO add in next version   -->
          <!--        <div class="reputation-score-modal__info-line">-->
          <!--          <div class="reputation-score-modal__info-line-label">-->
          <!--            Reputation Earned This Month-->
          <!--          </div>-->
          <!--          <div class="reputation-score-modal__reputation-earned-this-month">-->
          <!--            +500-->
          <!--          </div>-->
          <!--        </div>-->
          <!--          <div class="reputation-score-modal__divider"/>-->
        </div>
        <!--  TODO will be added later  -->
        <!--        <div>-->
        <!--          <div class="reputation-score-modal__coming-soon">-->
        <!--            coming soon-->
        <!--          </div>-->
        <!--          <BaseButton-->
        <!--            button-text="View Leaderboard"-->
        <!--            is-full-width-->
        <!--            :is-disabled="false"-->
        <!--            @click="handleViewLeaderboard">-->
        <!--            <template #iconBeforeText>-->
        <!--              <TrophySvg-->
        <!--                class="reputation-score-modal__trophy-icon"-->
        <!--                width="16"-->
        <!--                height="16"-->
        <!--                alt="trophy"/>-->
        <!--            </template>-->
        <!--            <template #icon-after-text>-->
        <!--              <TrophySvg-->
        <!--                class="reputation-score-modal__trophy-icon"-->
        <!--                width="16"-->
        <!--                height="16"-->
        <!--                alt="trophy"/>-->
        <!--            </template>-->
        <!--          </BaseButton>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// import BaseButton from '@/general-components/BaseButton.vue'
// import TrophySvg from '@/assets/icons/trophy.svg'
import ReputationScoreSvg from '@/assets/icons/rep-score.svg'
import type { Address } from 'viem'
import { useUserReputationScoreWithParametersRetrive } from '@/modules/common/backend/generated'
import { computed, toRefs, ref } from 'vue'
import { formatToken } from '@/utils/utils'
// import router from '@/router'
// import RouteName from '@/router/RouteName'
import type { UserReputationWithParametersResponse } from '@/revamp/types'
import LightningSvg from '@/assets/icons/lightning.svg'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { onClickOutside, useElementHover } from '@vueuse/core'
import BoostAndScoreOverviewModal from '@/revamp/components/modals/achievements-and-boost/BoostAndScoreOverviewModal.vue'

const isOpen = ref(false)
const modalRef = ref(null)

const isHovered = useElementHover(modalRef)

type Props = {
  address: Address;
};
const props = defineProps<Props>()
const { address } = toRefs(props)

const userReputationQuery = useUserReputationScoreWithParametersRetrive(
  address,
  { refresh: true },
)

const isLoading = computed(() => userReputationQuery.isFetching.value)

const userReputation = computed(() => {
  if (userReputationQuery.data.value?.data) {
    return userReputationQuery.data.value
      .data as UserReputationWithParametersResponse
  }
  return undefined
})

// const handleViewLeaderboard = async () => {
//   isOpen.value = false
//   await router.push({ name: RouteName.Leaderboard })
// }

onClickOutside(modalRef, () => {
  isOpen.value = false
})
</script>

<style scoped>
.reputation-score-modal {
  &__header {
    color: #a4a4a4; /* --gray-300 */
    text-align: center;
    width: 100%;
    font-family: var(--font-family-screener);
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
  }

  &__body {
    width: 17rem;
    height: 129px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__trigger {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--gray-2);
    width: 100%;
    padding: 0 0.375rem;
    gap: 0.25rem;
    height: 2rem;
    transition: all 0.2s;

    &--hovered {
      border-color: var(--primary-color-1);

      .reputation-score-modal__trigger-icon {
        fill: var(--primary-color-1);
      }
    }
  }

  &__trigger-text {
    text-decoration: underline;
    cursor: pointer;
  }

  &__boost,
  &__reputation-score {
    color: var(--primary-color-1);
    font-family: var(--font-family-supreme);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__boost {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
    gap: 0.1rem;
  }

  &__reputation-score {
    font-family: var(--font-family-supreme-bold);
    font-size: 1.5rem;
    line-height: 1.875rem;
    gap: 0.5rem;
    background: linear-gradient(90deg, #00ffe0 0%, #00d395 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__divider {
    background-color: var(--gray-2);
    height: 1px;
    margin: 1.5rem 0;
    width: 100%;
  }

  &__info-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__info-line-label {
    color: #c8c8c8; /* --gray-200 */
    font-family: var(--font-family-supreme);
    font-size: 0.875rem;
  }

  &__current-ranking,
  &__reputation-earned-this-month {
    font-family: var(--font-family-supreme-bold);
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &__current-ranking {
    color: var(--text-color);
  }

  &__reputation-earned-this-month {
    color: var(--primary-color-1);
    margin-top: 1rem;
  }

  &__reputation-score-icon {
    fill: var(--primary-color-1);
  }

  &__lightning-icon {
    fill: var(--primary-color-1);
  }

  &__trophy-icon {
    margin: 0 0.5rem;
    fill: var(--primary-color-1);
  }

  &__coming-soon {
    color: var(--gray-2);
    font-family: var(--font-family-supreme);
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  &__spacer {
    position: absolute;
    top: 3.5rem;
    right: 5rem;
    height: 1rem;
    width: 17rem;
  }

  &__opened-modal {
    position: absolute;
    top: 4.5rem;
    right: 5rem;
    background: var(--background-color);
    padding: 1.5rem;
    border: 1px solid var(--gray);
    box-shadow: 0 5px 16px 0 var(--background-color);

    @media only screen and (--small-viewport) {
      top: -21rem;
      right: 1rem;
    }
  }
}
</style>
