<template>
  <div
    v-if="!loanData?.hasRepayButton && !loanData?.hasClaimButton"
    class="loan-status">
    <LoanStatusInfo
      class="loan-status__text"
      :loan="loanData as LoanDetailSchemaWorkaroundBackendSchema"
      :loan-status="loanStatus as (LoanStatus | LoanStatus.PaidBack)"/>
  </div>
  <RepayButton
    v-if="loanData?.hasRepayButton"
    is-in-table
    :loan-data="loanData"
    :loan-id="onChainId"
    :chain-id-where-is-loan="Number(chainId)"
    :asset-to-repay-address="proposal?.creditAsset?.address ? proposal.creditAsset.address as Address : '0x'"
    :loan-contract-address="proposal?.loanContract as Address"/>
  <ClaimButton
    v-else-if="loanData?.hasClaimButton"
    is-in-table
    :loan-id="onChainId"
    :loan-data="loanData"
    :has-claim-button="loanData?.hasClaimButton"
    :chain-id-where-is-loan="Number(chainId)"/>
</template>

<script setup lang="ts">
import type { Proposal } from '@/revamp/modules/proposals/types'
import LoanStatusInfo from '@/general-components/LoanStatusInfo.vue'
import type { Address, Hex } from 'viem'
import RepayButton from '@/revamp/components/actions/RepayButton.vue'
import { computed } from 'vue'
import { useLoanDetail } from '@/modules/common/backend/generated'
import type { LoanDetailSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { compareAddresses } from '@/utils/utils'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import ClaimButton from '@/revamp/components/actions/ClaimButton.vue'
const { address: userAddress } = useCustomAccount()

type Props = {
  proposal: Proposal
}
const props = defineProps<Props>()

const chainId = computed(() => String(props.proposal.chainId))
const onChainId = computed(() => String(props.proposal.onChainId))
const loanTokenContractAddress = computed(() => {
  switch (props.proposal.type) {
  case 'pwn_contracts.betaloan':
    return CHAINS_CONSTANTS[chainId.value]?.pwnBetaContracts?.pwnLoan || '0x missing contract address'
  default:
    return CHAINS_CONSTANTS[chainId.value]?.pwnV1_2Contracts?.pwnLoan || '0x missing contract address'
  }
})
const loanDetailQuery = useLoanDetail(chainId, loanTokenContractAddress, onChainId, {
  query: {
    enabled: props.proposal.status === LoanStatus.Accepted || props.proposal.status === LoanStatus.PaidBack || props.proposal.status === LoanStatus.NonExistent,
  },
})

const loanData = computed(() => {
  const data = loanDetailQuery.data?.value?.data
  if (!data) {
    return null
  }

  const isBorrower = compareAddresses(userAddress.value, data?.borrower as Hex)
  const isLoanOwner = compareAddresses(userAddress.value, data.loanOwner as Hex)
  const isRunningLoan = LoanStatus.Accepted === +data.status as LoanStatus
  const isPaidBack = (LoanStatus.PaidBack === +data.status as LoanStatus || data.paidBackAt)
  const isDefaulted = LoanStatus.Defaulted === +data.status as LoanStatus

  const hasRepayButton = isBorrower && isRunningLoan
  const hasClaimButton = isLoanOwner && (isPaidBack || isDefaulted) && !data.claimedAt

  return { ...data, hasRepayButton, hasClaimButton, isPaidBack, isDefaulted }
})

const loanStatus = computed(() => {
  if (props.proposal.status === LoanStatus.Accepted && !loanData.value?.isPaidBack) {
    return props.proposal.status
  }
  if (loanData.value?.isPaidBack) {
    return LoanStatus.PaidBack
  }
  return props.proposal.status
})

</script>

<style scoped>
  .loan-status {
    display: flex;
    align-items: center;
    height: 100%;

    &__text {
      font-size: 0.875rem;
    }
  }
</style>
