import type {
  CreateProposalRequestSchemaRequestBackendSchema,
  CreateSimpleLoanFungibleProposalRequestSchemaRequestBackendSchema,
  CreateSimpleLoanListProposalRequestSchemaRequestBackendSchema,
  CreateSimpleLoanSimpleProposalRequestSchemaRequestBackendSchema,
} from '@/modules/common/backend/generated'
import DateUtils from '@/utils/DateUtils'
import { compareAddresses, getRawAmount } from '@/utils/utils'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import type { Address } from 'viem'
import { calculateCollateralAmountFungibleProposal } from '@/modules/common/pwn/utils'
import * as Sentry from '@sentry/vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { unref } from 'vue'
import { addAddressPadding } from 'starknet'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

export type CreateProposalFormValue = {
  collateralAmount: string
  collateralAsset?: AssetWithAmount
  creditAmount: string
  creditAsset?: AssetWithAmount
  ltv: string
  loanDurationDays: number
  apr: number
  expirationDays: number
  minCollateralAmount?: string
  availableCreditLimit?: string
  creditPerCollateralUnit?: string
  minCreditAmount?: string
}

export type CreateProposalFormValueCustom = CreateProposalFormValue & {
  sourceOfFunds?: string
}

export const useCreateProposalPayload = () => {
  const { address: userAddress } = useCustomAccount()

  return {
    createProposalPayload: async(
      value: CreateProposalFormValue | CreateProposalFormValueCustom,
      isOffer: boolean,
      proposalType: V1_2ProposalType,
    ): Promise<CreateProposalRequestSchemaRequestBackendSchema | undefined> => {
      // TODO add zod validation?
      if (!value.collateralAsset || !value.creditAsset) {
        Sentry.captureMessage(`createProposalPayload has been called with missing collateral asset or credit asset. Collateral asset=${value.collateralAsset}, Credit asset=${value.creditAsset}.`)
        return undefined
      }
      const chainId = value.collateralAsset.chainId
      const timeNowUTCSeconds = await DateUtils.getCurrentBlockTimestamp()
      const aprWithPadding = Number(getRawAmount(String(value.apr), APR_DECIMAL_POINT_PADDING).toString())
      const sourceOfFunds = value.creditAsset?.sourceOfFunds
      const proposer = isStarknet ? addAddressPadding(unref(userAddress) || '0x') : unref(userAddress) || '0x'
      // We don't fill availableCreditLimit at proposalForm, as its equal to creditAmount
      const availableCreditLimit = value.availableCreditLimit || value.creditAmount
      const basePayload = {
        accruing_interest_apr: aprWithPadding,
        allowed_acceptor: '0x0000000000000000000000000000000000000000',
        available_credit_limit: proposalType === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL ? getRawAmount(String(availableCreditLimit), value.creditAsset?.decimals).toString() : '0',
        chain_id: chainId,
        check_collateral_state_fingerprint: false,
        collateral_address: value.collateralAsset.address,
        collateral_category: value.collateralAsset.category,
        collateral_state_fingerprint: '0x0000000000000000000000000000000000000000000000000000000000000000',
        credit_address: value.creditAsset.address,
        duration: DateUtils.convertDaysToSeconds(value.loanDurationDays),
        expiration: timeNowUTCSeconds + value.expirationDays * 24 * 60 * 60,
        fixed_interest_amount: '0',
        hash: '',
        is_offer: isOffer,
        is_on_chain: false,
        loan_contract: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan ?? '0x missing contract address',
        multiproposal_merkle_root: null,
        nonce: '',
        nonce_space: '',
        proposer,
        proposer_spec_hash: '',
        refinancing_loan_id: 0,
        signature: '',
        source_of_funds: isOffer && sourceOfFunds ? sourceOfFunds : undefined,
      }

      if (proposalType === V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL) {
        return {
          ...basePayload,
          collateral_amount: value.collateralAsset.isFungible ? getRawAmount(String(value.collateralAmount), value.collateralAsset.decimals).toString() : '0',
          collateral_id: value.collateralAsset?.tokenId?.toString() ?? 0,
          credit_amount: getRawAmount(String(value.creditAmount), value.creditAsset.decimals).toString(),
          proposal_contract_address: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanSimpleProposal ?? '0x missing contract address',
          type: V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL,
        }
      }

      if (proposalType === V1_2ProposalType.SIMPLE_LOAN_LIST_PROPOSAL) {
        return {
          ...basePayload,
          collateral_amount: value.collateralAsset.isFungible ? getRawAmount(String(value.collateralAmount), value.collateralAsset.decimals).toString() : '0',
          credit_amount: getRawAmount(String(value.creditAmount), value.creditAsset.decimals).toString(),
          proposal_contract_address: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanListProposal ?? '0x missing contract address',
          type: V1_2ProposalType.SIMPLE_LOAN_LIST_PROPOSAL,
          collateral_ids_whitelist_merkle_root: '',
        }
      }

      if (proposalType === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
        const DEFAULT_CREDIT_PERCENT_IN_BIGINT = 15n
        const defaultCredit = getRawAmount(String(value.creditAmount), value.creditAsset.decimals) / 100n * DEFAULT_CREDIT_PERCENT_IN_BIGINT
        const minCollateralAmountDefault = calculateCollateralAmountFungibleProposal({
          creditPerCollateralUnit: value.creditPerCollateralUnit ?? '',
          collateralDecimals: value.collateralAsset.decimals ?? 0,
          availableCreditLimit: basePayload.available_credit_limit && basePayload.available_credit_limit !== '0' ? basePayload.available_credit_limit : defaultCredit.toString(),
          returnBigInt: true,
        }) as bigint
        return {
          ...basePayload,
          collateral_id: value.collateralAsset?.tokenId?.toString() ?? 0,
          credit_per_collateral_unit: value.creditPerCollateralUnit ?? '',
          min_collateral_amount: value.minCollateralAmount ? value.minCollateralAmount : minCollateralAmountDefault.toString(),
          proposal_contract_address: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanFungibleProposal ?? '0x missing contract address',
          type: V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL,
        }
      }
    },
    createProposalPayloadFromExistingProposal: async(
      proposal: CreateProposalRequestSchemaRequestBackendSchema | undefined,
      newCollateral: AssetWithAmount,
    ): Promise<CreateProposalRequestSchemaRequestBackendSchema | undefined> => {
      if (typeof proposal === 'string') return proposal
      if (!proposal) return undefined

      if (!('expiration' in proposal)) {
        // eslint-disable-next-line no-console
        console.warn('Behavior for dutch auction proposals is not yet implemented in createProposalPayloadFromExistingProposal.')
        return undefined
      }

      const { address: userAddress } = getAccount(pwnWagmiConfig)
      const chainId = newCollateral.chainId

      // todo: this has to be calculated again with new collateral i suppose
      const aprWithPadding = proposal.accruing_interest_apr

      const collateralAmount = newCollateral.isFungible ? getRawAmount(String(newCollateral.isNft ? 1 : (proposal as any).collateral_amount), newCollateral.decimals).toString() : '0'

      const basePayload = {
        accruing_interest_apr: aprWithPadding,
        allowed_acceptor: '0x0000000000000000000000000000000000000000',
        available_credit_limit: '0',
        chain_id: chainId,
        check_collateral_state_fingerprint: false,
        collateral_address: newCollateral.address,
        collateral_category: newCollateral.category,
        collateral_state_fingerprint: '0x0000000000000000000000000000000000000000000000000000000000000000',
        credit_address: proposal.credit_address,
        duration: proposal.duration,
        expiration: proposal.expiration,
        fixed_interest_amount: '0',
        hash: '',
        is_offer: proposal.is_offer,
        is_on_chain: false,
        loan_contract: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan ?? '0x missing contract address',
        multiproposal_merkle_root: null,
        nonce: '',
        nonce_space: '',
        proposer: userAddress || '0x',
        proposer_spec_hash: '',
        refinancing_loan_id: 0,
        signature: '',
        source_of_funds: proposal.is_offer && proposal.source_of_funds ? proposal.source_of_funds : undefined,
      }

      if (proposal.proposal_contract_address && compareAddresses(proposal.proposal_contract_address as Address, CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanSimpleProposal)) {
        return {
          ...basePayload,
          credit_amount: (proposal as CreateSimpleLoanSimpleProposalRequestSchemaRequestBackendSchema).credit_amount,
          collateral_id: newCollateral?.tokenId?.toString() ?? 0,
          collateral_amount: collateralAmount,
          proposal_contract_address: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanSimpleProposal ?? '0x missing contract address',
          type: V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL,
        }
      }

      if (proposal.proposal_contract_address && compareAddresses(proposal.proposal_contract_address as Address, CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanListProposal)) {
        return {
          ...basePayload,
          credit_amount: (proposal as CreateSimpleLoanListProposalRequestSchemaRequestBackendSchema).credit_amount,
          collateral_amount: collateralAmount,
          proposal_contract_address: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanListProposal ?? '0x missing contract address',
          type: V1_2ProposalType.SIMPLE_LOAN_LIST_PROPOSAL,
          collateral_ids_whitelist_merkle_root: '',
        }
      }

      if (proposal.proposal_contract_address === CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanFungibleProposal) {
        return {
          ...basePayload,
          collateral_id: (proposal as CreateSimpleLoanFungibleProposalRequestSchemaRequestBackendSchema).collateral_id,
          credit_per_collateral_unit: (proposal as CreateSimpleLoanFungibleProposalRequestSchemaRequestBackendSchema).credit_per_collateral_unit,
          min_collateral_amount: (proposal as CreateSimpleLoanFungibleProposalRequestSchemaRequestBackendSchema).min_collateral_amount,
          proposal_contract_address: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoanFungibleProposal ?? '0x missing contract address',
          type: V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL,
        }
      }
    },
  }
}
