<template>
  <BaseSkeletor
    v-if="isLoadingBasicAssetDetails"
    :width="isSmallScreen ? '260px' : '360px'"
    :height="isSmallScreen ? '260px' : '360px'"/>
  <ModalImage
    v-else-if="asset?.image"
    v-model:is-open="isOpen">
    <template #trigger>
      <TokenMedia
        :token="asset"
        :is-lazy-loaded="false"
        :width="isSmallScreen ? '466' : '360'"
        :height="isSmallScreen ? '466' : '360'"
        class="nft-page-modal-image__image"
        @click.stop.prevent="isOpen = true"/>
    </template>
    <template #body>
      <TokenMedia
        :is-lazy-loaded="false"
        :token="asset"
        class="nft-page-modal-image__image-enlarged"/>
    </template>
  </ModalImage>
  <div
    v-else
    class="nft-page__image-not-loaded"/>
</template>

<script lang="ts" setup>

import ModalImage from '@/general-components/ModalImage.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import { ref } from 'vue'
import { isSmallScreen } from '@/utils/mediaQueries'

const { asset, isLoadingBasicAssetDetails } = useAssetPage()
const isOpen = ref(false)

</script>

<style scoped>
.nft-page-modal-image {
  &__image {
    object-fit: contain;
    object-position: center;
    cursor: pointer;

    &-enlarged {
      width: 75vh;
      height: 75vh;
      object-fit: contain;
      object-position: center;
    }
  }

  &__image-not-loaded {
    width: 29.125rem;
    height: 29.125rem;
  }

  @media only screen and (--small-viewport) {
    &__image {
      width: 20.5rem;
      height: 20.5rem;
      margin: auto;

      &-enlarged {
        width: 20.5rem;
        height: 20.5rem;
      }
    }
  }
}
</style>
