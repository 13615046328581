<template>
  <div class="base-toast-step base-toast-step__container">
    <component
      :is="icon"
      :class="{ 'base-toast-step__icon--pending': isPending }"
      width="20"
      height="20"/>
    <div :class="['base-toast-step__text', `base-toast-step__text--${step.status}`]">
      <div>
        {{ stepText }} <BaseLink
          v-if="txDetailsLink"
          target="_blank"
          :to="txDetailsLink">
          <ExternalIconSvg/>
        </BaseLink>
      </div>
      <div
        v-if="errorText"
        class="base-toast-step__error-text">
        Reason: {{ errorText }}
      </div>
    </div>
    <div
      v-if="showSupportLink"
      class="base-toast-step__support-link">
      <BaseButton
        :to="isAnalyticsAllowed ? undefined : linkTo.discordSupportLink"
        :link-target="isAnalyticsAllowed ? undefined : '_blank'"
        :size="ButtonSize.M"
        :color="ButtonColor.PrimaryDark"
        :variant="ButtonVariant.Outline"
        button-text="Contact support"
        @click="isAnalyticsAllowed ? handleSupportClick : undefined"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import ToastSuccessIcon from '@/assets/icons/toast-success.svg'
import ToastErrorIcon from '@/assets/icons/exclamation-mark-orange.svg'
import ToastPendingIcon from '@/assets/icons/toast-pending.svg'
import ToastInactiveIcon from '@/assets/icons/toast-inactive.svg'
import type { Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import { ToastStepStatus } from '@/modules/common/notifications/useToastsStore'
import ExternalIconSvg from '@/assets/icons/external.svg'
import BaseLink from '@/general-components/BaseLink.vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { extractErrorMessage } from '@/modules/common/utils'
import { UserRejectedRequestError } from 'viem'
import useAnalytics, { EventNames } from '@/modules/common/analytics/useAnalytics'
import BaseButton, { ButtonSize, ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import linkTo from '@/constants/links'

interface Props {
  toast: Toast
  step: ToastStep
}

const props = defineProps<Props>()
const { toast, step } = toRefs(props)
const isPending = computed(() => step.value.status === ToastStepStatus.PENDING)
const isError = computed(() => step.value.status === ToastStepStatus.ERROR)

const stepText = computed(() => {
  if (isError.value) {
    return `ERROR: ${step.value.text}`
  }
  return step.value.text
})

const icon = computed(() => {
  const iconMap: Record<ToastStepStatus, typeof ToastErrorIcon> = {
    [ToastStepStatus.ERROR]: ToastErrorIcon,
    [ToastStepStatus.SUCCESS]: ToastSuccessIcon,
    [ToastStepStatus.INACTIVE]: ToastInactiveIcon,
    [ToastStepStatus.PENDING]: ToastPendingIcon,
  }
  return iconMap[step.value.status]
})

const txDetailsLink = computed(() => {
  if (!step.value.txHash) {
    return null
  }

  return CHAINS_CONSTANTS[toast.value.chainId].explorer.transactionDetailsLink(step.value.txHash)
})

const showSupportLink = computed(() => {
  return isError.value && !(step.value.error?.cause instanceof UserRejectedRequestError) && !(step.value.error instanceof UserRejectedRequestError)
})

const errorText = computed(() => {
  if (!step.value.error || !isError.value) {
    return null
  }

  return extractErrorMessage(step.value.error)
})

const { isAllowed: isAnalyticsAllowed, trackEvent } = useAnalytics()

const handleSupportClick = () => {
  if (isAnalyticsAllowed.value) {
    trackEvent({
      name: EventNames.OpenSupportTicketClickInToast,
    })
  }
}
</script>

<style scoped>
.base-toast-step {
  --grid-gap-width: 0.5rem;
  --icon-column-width: 1.25rem;

  &__text {
    &--SUCCESS,
    &--undefined,
    &--INACTIVE {
      color: var(--gray-2);
    }

    &--ERROR {
      color: var(--negative-1);
    }
  }

  &__support-link {
    display: flex;
    margin-top: 1.25rem;
    color: var(--text-color);
    margin-left: calc(var(--grid-gap-width) + var(--icon-column-width));
  }

  &__error-text {
    margin-top: 1rem;
    white-space: pre-line;
    word-break: break-word;
  }

  &__container {
    display: grid;
    align-items: center;
    gap: 0.5rem;
    grid-template-columns: 1.25rem minmax(0, 1fr);
  }

  &__icon {
    &--pending {
      animation: rotate 1.5s linear infinite;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
