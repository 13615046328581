import type { BaseOffer } from '@/modules/common/pwn/offers/OfferClasses'
import useApprove from '@/modules/common/assets/useApprove'
import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { maxUint256 } from 'viem'
import type { Address, TransactionReceipt } from 'viem'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export default abstract class BaseOfferContract<TOffer extends BaseOffer = BaseOffer> {
    public abstract acceptOffer(offer: TOffer, loan: BaseLoan, toast: ToastStep): Promise<TransactionReceipt>
    public abstract revokeOffer(offer: TOffer, step: ToastStep): Promise<TransactionReceipt>
    public abstract createOfferStruct(offer: TOffer, loanAmountRaw: bigint, collateralAmountRaw: bigint): Record<string, unknown>

    public async isApprovedForMakeOffer({ loanAsset, chainId, lender }: TOffer, loanAmountRaw: bigint): Promise<boolean> {
      return await useApprove().isApproved(
        chainId,
        loanAsset.category,
        loanAsset.address,
        lender,
        CHAINS_CONSTANTS[chainId].pwnV1Contracts.pwnSimpleLoan,
        loanAmountRaw,
      )
    }

    public async approveForMakeOffer({ loanAsset, chainId }: TOffer, loanAmountRaw: bigint): Promise<boolean> {
      return await useApprove().approve({
        asset: loanAsset,
        spender: CHAINS_CONSTANTS[chainId].pwnV1Contracts.pwnSimpleLoan,
        amount: maxUint256,
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ asset: AssetWithAmount; spende... Remove this comment to see the full error message
        allowanceAmountToCheck: loanAmountRaw,
      })
    }

    public async isApprovedForAcceptOffer({ loan, chainId }: TOffer, walletAddress: Address): Promise<boolean> {
      if (!loan) {
        const { asset } = useAssetPage()
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        return await useApprove().isApproved(chainId, asset.value.category, asset.value.address, walletAddress, CHAINS_CONSTANTS[chainId].pwnV1Contracts.pwnSimpleLoan, 1n)
      }
      return await useApprove().isApproved(chainId, loan.collateral.category, loan.collateral.address, walletAddress, CHAINS_CONSTANTS[chainId].pwnV1Contracts.pwnSimpleLoan, loan.collateral.amountRaw)
    }

    public async approveForAcceptOffer(collateral: AssetWithAmount, step? : ToastStep): Promise<boolean> {
      const isApproved = await useApprove().approve({
        asset: collateral,
        spender: CHAINS_CONSTANTS[collateral.chainId].pwnV1Contracts.pwnSimpleLoan,
        amount: maxUint256,
        minAllowanceAmountToCheck: collateral.amountRaw ? collateral.amountRaw : 1n,
      }, step)
      // todo POST request to create new notification on backend?

      return !!isApproved
    }
}
