import type { SupportedChain } from '@/constants/chains/types'
import AssetType from '@/modules/common/assets/AssetType'
import { useUserReputationScoreWithParametersRetrive } from '@/modules/common/backend/generated'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useAssetPrice } from '@/revamp/hooks/asset-prices/useAssetPrice'
import type { Address } from 'viem'
import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

export const useRewardsForCreditToBeEarned = (
  address: MaybeRef<Address | null>,
  chainId: MaybeRef<SupportedChain | null>,
  formattedAmount: MaybeRef<string | null>,
) => {
  if (isStarknet) {
    // dirty hack to remove unnecessary fetches for starknet
    const rewardsToBeEarned = computed(() => 0)
    return {
      rewardsToBeEarned,
    }
  }
  const tokenId = computed(() => null)
  const _chainId = computed(() => String(unref(chainId)))

  const assetPrice = useAssetPrice(_chainId, address, tokenId, AssetType.ERC20)

  const { address: userAddress } = useCustomAccount()
  const userAddressRef = computed(() => userAddress.value || '')

  const connectedUserRepQuery = useUserReputationScoreWithParametersRetrive(userAddressRef, { refresh: false })

  const multiplier = computed<number>(() => {
    const userRep = connectedUserRepQuery.data.value?.data.totalMultiplier

    return (userRep || 1) as number
  })

  const totalAmount = computed(() => {
    if (!assetPrice.priceData.value || !unref(formattedAmount)) {
      return 0
    }

    const price = assetPrice.priceData.value.price.usdAmount

    return +price * Number(unref(formattedAmount))
  })

  const rewardsToBeEarned = computed(() => {
    /**
     * 1 point for each 1000 USD
     */
    if (totalAmount.value === 0) {
      return 0
    }

    return (totalAmount.value / 1000) * multiplier.value
  })

  return {
    rewardsToBeEarned,
  }
}
