import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as starscanImage from '@/assets/icons/starknet-white.svg'
import * as openSeaIcon from '@/assets/icons/opensea.svg'
import type { Address } from 'viem'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://starkscan.co'

const STARKNET_MAINNET_CONSTANTS: ChainConstants = {
  general: {
    chainId: SupportedChain.Sepolia,
    chainName: 'Starknet Sepolia',
  },
  nodeProvider: {
    httpNodeUrl: 'https://starknet-mainnet.g.alchemy.com/starknet/version/rpc/v0_7',
    wsNodeUrl: `wss://starknet-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_STARKNET_MAINNET_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_STARKNET_MAINNET_ALCHEMY_NODE_KEY as string,
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/contract/${tokenAddress}`,
    blockExplorerName: 'starkscan',
    blockExplorerIcon: starscanImage.default,
    nftDetailsLink: (tokenAddress, tokenId) => `https://testnets.opensea.io/assets/sepolia/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: openSeaIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/contract/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://testnets.opensea.io/collection/${slug}`,
  },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x4188C513fd94B0458715287570c832d9560bc08a',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x448E3D0a4BAa00FE511a03E7B27177AeDE6d9636',
  pwnSafeContracts: {
    assetTransferRightsGuardProxy: '0x7Fef14F22fAC06336097b6C35faCA6359A77eb14',
    assetTransferRights: '0x2Af429Ab631Cdd2e9de396F8C838d7ad231E73EB',
    pwnSafeFactory: '0xF475aB5843d6688ffFfDAA38e7DEFeAFAc9d9284',
  },
  topTokens: new TopTokens({
    usdt: '0x02ab8758891e84b968ff11361789070c6b1af2df618d6d2f4a78b0757573c6eb',
    weth: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9',
    dai: '0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357',
  }, true),
  nftContractsAddresses: {
    cryptoPunks: '0x00cbf862cc5a7bd9dbff15f4b6df49b06cee73ef',
  },
  gnosisSafe: {
    transactionServiceUrl: 'https://safe-transaction-sepolia.safe.global',
    chainName: 'sep',
  },
}
export default STARKNET_MAINNET_CONSTANTS
