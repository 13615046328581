import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import links from '@/constants/links'
import { typeSafeObjectFromEntries } from '@/utils/typescriptWrappers'
import { injected, coinbaseWallet, safe, walletConnect } from '@wagmi/connectors'
import { createConfig, webSocket } from '@wagmi/core'
import { watchAccount } from '@wagmi/vue/actions'
import { fallback, http } from 'viem'
import { enabledChainsWagmi } from './useEnabledChains'
import { globalConstants } from '@/constants/globals'
import { compareAddresses } from '@/utils/utils'
import useGlobalDataLoader from '@/modules/common/useGlobalDataLoader'
import { SupportedChain } from '@/constants/chains/types'
import { useConnectedAccountTypeStore } from './useConnnectedAccountTypeStore'
import { useConnectedSafeWalletStore } from './useSafeWalletSdk'
import { mainnet, sepolia } from 'viem/chains'

const tenderlyTestingRpc = {
  [mainnet.id]: import.meta.env.VITE_TENDERLY_MAINNET_TESTING_RPC as string,
  [sepolia.id]: import.meta.env.VITE_TENDERLY_SEPOLIA_TESTING_RPC as string,
}

// TODO could this ts helper library be useful for us? https://github.com/sindresorhus/type-fest
// TODO check if something like https://www.reversemirage.com/ would be useful for us, btw it's built on viem
// TODO maybe change the structure of files to something like useWagmi, useWallet?
// TODO have separate store just for wagmi config?

function getHttpTransports(chainId: SupportedChain) {
  // websockets is not used, as we do not listen to any contract events permanently and there are some
  // issues with websockets reconnection as well htps://github.com/wevm/viem/issues/877

  if (import.meta.env.VITE_IS_TENDERLY_TESTING_RPC === 'true' && tenderlyTestingRpc[chainId]) {
    return [http(tenderlyTestingRpc[chainId], {
      batch: true,
      // TODO also set key?
      name: 'Tenderly testing RPC Node',
    }),
    ]
  }

  const httpTransports = [
    http(CHAINS_CONSTANTS[chainId].nodeProvider.httpNodeUrl, {
      // TODO what batch wait time to set? by default is batch.wait 0 (zero delay)
      batch: true,
      ...(CHAINS_CONSTANTS[chainId].nodeProvider.bearerAuthToken && {
        fetchOptions: {
          headers: {
            Authorization: `Bearer ${CHAINS_CONSTANTS[chainId].nodeProvider.bearerAuthToken}`,
          },
        },
      }),
    }),
  ]

  if (CHAINS_CONSTANTS[chainId].pwnNode?.httpNodeUrl) {
    httpTransports.push(http(CHAINS_CONSTANTS[chainId]?.pwnNode?.httpNodeUrl, {
      // TODO use same config as for above http ?
      batch: true,
      // TODO also set key?
      name: 'PWN Node',
    }))
  }

  return httpTransports
}

const getWebsocketTransports = (chainId: SupportedChain) => {
  const websocketTransports = [
    webSocket(CHAINS_CONSTANTS[chainId].nodeProvider.wsNodeUrl, {
      reconnect: true,
    }),
  ]

  if (chainId === SupportedChain.UnichainSepolia) {
    return []
  }

  return websocketTransports
}

export function createTransports() {
  return typeSafeObjectFromEntries(
    enabledChainsWagmi.map(supportedChain => [
      supportedChain.id,
      fallback([
        ...getWebsocketTransports(supportedChain.id),
        ...getHttpTransports(supportedChain.id),
        // NOTE: In the end I've decided to not use unstable_connector which uses connected wallet RPC, due to the multichain issues.
        //  unstable_connector is able to use only currently connected chain, so a lot of errors are thrown to console
        //  when requesting a data from different chain than the user is currently connected to (e.g. when getting
        //  a user native balance on Arbitrum, while connected to Ethereum).
      ]), // @see https://viem.sh/docs/clients/transports/fallback.html
    ]),
  )
}

function createWagmiConfig() {
  // TODO does this need to be inside this function? or can we move it top level?
  const metadata = {
    name: 'PWN',
    description: 'PWN is a hub for peer-to-peer (P2P) loans backed by digital assets. Use your tokens or NFTs as collateral.',
    url: links.pwnAppProduction,
    icons: [links.pwnLogo],
  }

  return createConfig({
    // TODO should we also we adjust our typings?
    chains: enabledChainsWagmi,
    connectors: [
      // note MetaMask connector is intentionally disabled, for reasoning see here:
      //    https://wagmi.sh/core/api/connectors/metaMask
      // note: there is also `mock` connector that we might want to use for some tests in the future
      //    https://wagmi.sh/core/api/connectors/mock
      coinbaseWallet({
        appName: metadata.name,
        darkMode: true,
        // TODO:
        //    1) do we need to use this chainId parameter?
        //    2) do we need to reinitialize this when a connectedChain changes (i guess before connecting a wallet)
        //    3) any way we can get here the previously connected chain? and do we need to care about this case, or will it be auto handled by reconnect()?
        chainId: enabledChainsWagmi[0].id,
      }),
      // TODO what about the `unstable_shimAsyncInject` injected parameter?
      // TODO is it needed to do anything else to add EIP6963 support? previously there was EIP6963Connector from web3modal
      injected(),
      safe({
        debug: globalConstants.environment === 'development',
        // allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/, /cronos-safe.org$/, /multisig.bnbchain.org/],
        // TODO what about debug parameter
        // TODO what about shimDisconnect parameter that is by default off?
      }),
      walletConnect({
        showQrModal: false,
        projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
        // TODO what is disableProviderPing?
        metadata,
        qrModalOptions: {
          themeMode: 'dark',
        },
      }),
    ],
    multiInjectedProviderDiscovery: true, // EIP6963
    // TODO look at the `batch` parameter
    //  https://wagmi.sh/core/api/createConfig#batch
    // TODO do we need to care about cacheTime & pollingInterval parameters?
    transports: createTransports(),
  })
}

export type PwnWagmiConfig = ReturnType<typeof createConfig>
export let pwnWagmiConfig: PwnWagmiConfig

export function initPwnWagmiConfig() {
  if (!enabledChainsWagmi) {
    throw new Error('You need to call useEnabledChains.initEnabledChains() first before calling useWagmiConfig.initPwnWagmiConfig()!')
  }
  pwnWagmiConfig = createWagmiConfig()
  return pwnWagmiConfig
}

let hasSetupWagmiAccountWatcher = false
export function setupWagmiAccountWatcher() {
  if (hasSetupWagmiAccountWatcher) {
    throw new Error('setupWagmiAccountWatcher should be called only once!')
  }

  // NOTE: init code/watchers inside useConnectedAccountTypeStore
  useConnectedAccountTypeStore()
  useConnectedSafeWalletStore()

  watchAccount(pwnWagmiConfig, {
    async onChange(account, previousAccount) {
      if (globalConstants.environment === 'development') {
        /* eslint-disable no-console */
        console.groupCollapsed('watchAccount triggered')
        console.log('New account:')
        console.log(account)
        console.log('Previous account:')
        console.log(previousAccount)
        console.groupEnd()
        /* eslint-enable no-console */
      }

      // TODO also check here if the chain is supported? can it ever be an unsupported chain?
      if (!account.isConnected) {
        // TODO clear data arrays here?
        return
      }

      const { clearDataOnUserAddressChange, reloadDataOnUserAddressChange, reloadDataOnChainOrUserAddressChange } = useGlobalDataLoader()

      if (!compareAddresses(account.address, previousAccount.address)) {
        clearDataOnUserAddressChange()
        // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
        reloadDataOnUserAddressChange(account.address)
      }

      if (account.chainId !== previousAccount.chainId || !compareAddresses(account.address, previousAccount.address)) {
        // TODO any clear needed here?
        // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | undefined' is no... Remove this comment to see the full error message
        reloadDataOnChainOrUserAddressChange(account.address)
      }
    },
  })
  hasSetupWagmiAccountWatcher = true
}
