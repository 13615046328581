<template>
  <BaseModal
    v-model:is-open="store.isOpen"
    :size="ModalSize.Large"
    custom-max-width="57rem"
    is-closable>
    <template #custom-header>
      <header>
        <div class="notification-reminder__heading-container">
          <BellIconSvg
            class="notification-reminder__bell-icon"/>
          <h2
            class="notification-reminder__heading">
            Notifications Reminder
          </h2>
          <CloseIconSvg
            class="notification-reminder__close-icon"
            @click="handleClose"/>
        </div>
        <div class="notification-reminder__header-divider"/>
      </header>
    </template>
    <template #body>
      <div class="notification-reminder__body">
        <p>
          We highly advise configuring notifications for your loan requests to stay informed.
        </p>
        <p>
          By default, you'll receive alerts 7 days and 24 hours prior to the potential default of your loan.
        </p>

        <div class="notification-reminder__buttons">
          <DiscordNotificationModal/>
          <PushNotificationModal/>
          <EmailNotificationModal/>
          <CalendarHelper
            is-like-button
            :loan="store.createdLoan! as any"/>

          <BaseButton
            is-full-width
            button-text="Go to notification settings"
            @on-button-click="routeToNotificationSettings"/>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import ModalSize from '@/general-components/ModalSize'
import { useNotificationReminderModalStore } from '@/general-components/notification-reminder-modal/useNotificationReminderModalStore'
import BellIconSvg from '@/assets/icons/bell.svg'
import DiscordNotificationModal from '@/modules/pages/notification-center/settings/modals/DiscordNotificationModal.vue'
import PushNotificationModal from '@/modules/pages/notification-center/settings/modals/PushNotificationModal.vue'
import EmailNotificationModal from '@/modules/pages/notification-center/settings/modals/EmailNotificationModal.vue'
import BaseButton from '@/general-components/BaseButton.vue'
import router from '@/router'
import RouteName from '@/router/RouteName'
import CloseIconSvg from '@/assets/icons/close.svg'
import CalendarHelper from '@/general-components/CalendarHelper.vue'

const store = useNotificationReminderModalStore()

const handleClose = () => {
  store.isOpen = false
}

const routeToNotificationSettings = () => {
  store.isOpen = false
  return router.push({ name: RouteName.NotificationCenterSettings })
}

</script>

<style scoped>
.notification-reminder {
  &__heading-container {
    display: flex;
    column-gap: 1rem;
    align-items: center;
  }

  &__bell-icon {
    width: 1.5rem;
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 550;
    font-family: var(--font-family-autoscape);

    @media only screen and (--mobile-viewport) {
      font-size: 1.125rem;
    }
  }

  &__close-icon {
    cursor: pointer;
    width: 1.5rem;
    margin-left: auto;
  }

  &__header-divider {
    height: 1px;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px;
    margin-top: 1rem;
  }

  &__body {
    margin-top: 2rem;
    line-height: 1.5rem;
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-top: 3rem;
    flex-wrap: wrap;

    @media only screen and (--mobile-viewport) {
      flex-direction: column;
    }
  }

  &__calendar {
    border: var(--primary-color-1);
  }
}
</style>
