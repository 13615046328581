import { computed, unref, watch } from 'vue'
import type { MaybeRef } from 'vue'
import { isAddress } from 'viem'
import type { Address } from 'viem'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { getFetchUserErc20sQueryOptions } from '@/modules/common/backend/generated'
import type { ERC20AssetSchemaWithBalanceBackendSchema } from '@/modules/common/backend/generated'
import { queryOptions, useQueries } from '@tanstack/vue-query'
import type { UseQueriesOptions } from '@tanstack/vue-query'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { AxiosResponse } from 'axios'
import type { SupportedChain } from '@/constants/chains/types'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import { compareAddresses } from '@/utils/utils'
import { useAssetsWithSmallBalancesStore } from './useAssetsWithSmallBalancesStore'

export const useUserTokens = (
  address: MaybeRef<Address | undefined> | undefined,
  chainId?: MaybeRef<SupportedChain | undefined | SupportedChain[]> | undefined,
) => {
  const selectedChainsStore = useSelectedChainsStore()
  const { selectedChains } = storeToRefs(selectedChainsStore)
  const smallBalancesStore = useAssetsWithSmallBalancesStore()

  watch(
    () => unref(address),
    (newAddress, oldAddress) => {
      if (newAddress && oldAddress && compareAddresses(newAddress, oldAddress)) {
        smallBalancesStore.cleanAssets()
      }
    },
  )

  const chainsToFetch = computed(() => {
    if (unref(chainId)) {
      if (Array.isArray(unref(chainId))) {
        return unref(chainId) as SupportedChain[]
      }
      return [unref(chainId)]
    }

    return selectedChains.value === 'all' ? enabledChains : selectedChains.value
  })

  const tokensToFetchWithPages = computed(() => {
    if (!chainsToFetch.value || !address) {
      return []
    }

    return chainsToFetch.value.map((chain) => ({
      chain: Number(chain),
      address,
    }))
  })

  const tokenQueries = computed(() => {
    if (!unref(address) || !isAddress(unref(address) as string)) {
      return []
    }

    return tokensToFetchWithPages.value.map(({ chain, address }) => {
      return getFetchUserErc20sQueryOptions(String(chain), address as MaybeRef<string>)
    }) as UseQueriesOptions<AxiosResponse<ERC20AssetSchemaWithBalanceBackendSchema>[]>
  })

  const queriesData = useQueries<
    AxiosResponse<ERC20AssetSchemaWithBalanceBackendSchema>[],
    {
      data: AssetWithAmount[];
      isLoading: boolean;
      refetch:(() => void)[];
        }
        >({
          queries: tokenQueries,
          combine(results) {
            const mergedResults: AssetWithAmount[] = []

            for (const result of results) {
              if (result.isFetched && result.data) {
                // @ts-expect-error data is not null
                const subRes = (result.data?.data as ERC20AssetSchemaWithBalanceBackendSchema[]).map((data) =>
                  AssetWithAmount.createFromBackendModel(data),
                )
                mergedResults.push(...subRes)
              }
            }

            return {
              data: mergedResults,
              isLoading: results.some((result) => result.isFetching),
              refetch: [...results.map((result) => result.refetch)],
            }
          },
        })

  const { fetchNativeToken } = useERC20Fetch()

  const fetchNativeTokensQueries = computed(() => {
    if (!chainsToFetch.value?.length) {
      return []
    }

    return (
      chainsToFetch.value.map((chain) => {
        if (!chain) {
          return null
        }
        return queryOptions({
          queryKey: ['fetchNativeToken', chain, unref(address) as string],
          enabled: !!chain,
          queryFn: async ({ queryKey }) => {
            const [, chainId, address] = queryKey
            const token = await fetchNativeToken(chainId as SupportedChain, address as Address)
            if (token?.amountRaw === 0n) {
              return null
            }
            return token
          },
        })
      }) || []
    ).filter(Boolean)
  })

  const nativeTokensData = useQueries<AssetWithAmount[], AssetWithAmount[]>({
    // @ts-expect-error fetchNativeTokensQueries is not null
    queries: fetchNativeTokensQueries,
    combine: (results) => {
      const v = results.map((result) => result.data as AssetWithAmount).filter(Boolean)
      return v satisfies AssetWithAmount[]
    },
  })

  const tokens = computed(() => [...queriesData.value.data, ...nativeTokensData.value])

  return {
    tokens,
    isLoading: computed(() => queriesData.value.isLoading),
    reload: () => queriesData.value.refetch.forEach((refetch) => refetch()),
  }
}
