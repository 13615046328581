import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as starkscanImage from '@/assets/icons/starknet-white.svg'
import * as openSeaIcon from '@/assets/icons/opensea.svg'
import type { Address } from 'viem'
import { pwnV1_2ContractsStarknet } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://sepolia.starkscan.co'

const STARKNET_SEPOLIA_CONSTANTS: ChainConstants = {
  general: {
    chainId: SupportedChain.StarknetSepolia,
    chainName: 'Starknet Sepolia',
  },
  nodeProvider: {
    httpNodeUrl: 'https://starknet-sepolia.g.alchemy.com/starknet/version/rpc/v0_7',
    wsNodeUrl: `wss://starknet-sepolia.g.alchemy.com/v2/${import.meta.env.VITE_STARKNET_SEPOLIA_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_STARKNET_SEPOLIA_ALCHEMY_NODE_KEY as string,
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/contract/${tokenAddress}`,
    blockExplorerName: 'starkscan',
    blockExplorerIcon: starkscanImage.default,
    nftDetailsLink: (tokenAddress, tokenId) => `https://testnets.opensea.io/assets/sepolia/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: openSeaIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/contract/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://testnets.opensea.io/collection/${slug}`,
  },
  // todo: completely useless
  pwnV1Contracts: {
    pwnSimpleLoan: '0x0679eae6cdd4c2e7266f9c20452bf02a8d26f52a6b9bd930339ccde97a8d9579',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x06d3fe7506cd04cd8788fa99c580e02c11abe8f65a9e861512d5716bbe20c742',
  },
  pwnV1_2Contracts: pwnV1_2ContractsStarknet,
  topTokens: new TopTokens({
    pwnd: '0x78A341cB77e56b71A88a79e50A567e6C29B5cef5',
    pwns: '0xf6d0348A6a27c159d1aCD3AB98B63B14853E4D8E',
    usdt: '0x02ab8758891e84b968ff11361789070c6b1af2df618d6d2f4a78b0757573c6eb',
    usdc: '0x053b40a647cedfca6ca84f542a0fe36736031905a9639a7f19a3c1e66bfd5080',
    weth: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9',
    dai: '0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357',
  }, true),
  // useles, unused for starknet
  gnosisSafe: {
    transactionServiceUrl: 'https://safe-transaction-sepolia.safe.global',
    chainName: 'sep',
  },
}
export default STARKNET_SEPOLIA_CONSTANTS
