import { POSITION, TYPE, useToast } from 'vue-toastification'
import type { ToastContent, ToastOptions } from 'vue-toastification/dist/types/types'
import BaseToast from '@/general-components/notification/BaseToast.vue'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Component } from 'vue'
import { NotificationActionEnumBackendSchema } from '@/modules/common/backend/generated'
import type { ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import type { SupportedChain } from '@/constants/chains/types'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import DateUtils from '@/utils/DateUtils'
import type { Address, Hex } from 'viem'
import NotificationFrontendOnlyActionEnum from './NotificationAction'
import { switchChain } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { PartialWithRequired, UnixTimestamp } from '@/modules/common/typings/customTypes'
import { typeSafeObjectKeys } from '@/utils/typescriptWrappers'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'

const _actionIdToUniqueToastId = {
  [NotificationActionEnumBackendSchema.PROPOSAL_REVOKED]: (proposalId: string) => `${NotificationActionEnumBackendSchema.PROPOSAL_REVOKED}_${proposalId}`,
  [NotificationFrontendOnlyActionEnum.TX_UNWRAP_NATIVE_TOKEN]: (chainId: SupportedChain, amountRaw: bigint) => `${NotificationFrontendOnlyActionEnum.TX_UNWRAP_NATIVE_TOKEN}_${chainId}_${String(amountRaw)}`,
  [NotificationFrontendOnlyActionEnum.TX_WRAP_NATIVE_TOKEN]: (chainId: SupportedChain, amountRaw: bigint) => `${NotificationFrontendOnlyActionEnum.TX_WRAP_NATIVE_TOKEN}_${chainId}_${String(amountRaw)}`,
  [NotificationActionEnumBackendSchema.PROPOSAL_CREATED]: (proposal: BaseProposal) => `${NotificationActionEnumBackendSchema.PROPOSAL_CREATED}_${proposal.uniqueIdentifier}`,
  [NotificationActionEnumBackendSchema.LOAN_CREATED]: (collateral: AssetWithAmount, proposal?: BaseProposal) => `${NotificationActionEnumBackendSchema.LOAN_CREATED}_${collateral.uniqueIdentifier}_${proposal?.uniqueIdentifier}`,
  [NotificationFrontendOnlyActionEnum.TX_APPROVE_ASSETS]: (assets: AssetWithAmount[]) => `${NotificationFrontendOnlyActionEnum.TX_APPROVE_ASSETS}_${assets.map(asset => asset.uniqueIdentifier).join('_')}`,
  [NotificationFrontendOnlyActionEnum.TX_CREATE_BUNDLE]: (assets: AssetWithAmount[]) => `${NotificationFrontendOnlyActionEnum.TX_CREATE_BUNDLE}_${assets.map(asset => asset.uniqueIdentifier).join('_')}`,
  [NotificationActionEnumBackendSchema.LOAN_PAYMENT_CLAIMED]: (loanId: string) => `${NotificationActionEnumBackendSchema.LOAN_PAYMENT_CLAIMED}_${loanId}`,
  [NotificationActionEnumBackendSchema.LOAN_COLLATERAL_CLAIMED]: (loanId: string) => `${NotificationActionEnumBackendSchema.LOAN_COLLATERAL_CLAIMED}_${loanId}`,
  [NotificationActionEnumBackendSchema.LOAN_PAID_BACK]: (loanId: string) => `${NotificationActionEnumBackendSchema.LOAN_PAID_BACK}_${loanId}`,
  [NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUESTED]: (loanId: string, newExpiration: UnixTimestamp) => `${NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUESTED}_${loanId}_${newExpiration}`,
  [NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUEST_CANCELLED]: (loanExtensionId: string) => `${NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUEST_CANCELLED}_${loanExtensionId}`,
  [NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUEST_IGNORED]: (loanExtensionId: string) => `${NotificationActionEnumBackendSchema.LOAN_EXTENSION_REQUEST_IGNORED}_${loanExtensionId}`,
  [NotificationActionEnumBackendSchema.LOAN_EXTENDED]: (loanExtensionId) => `${NotificationActionEnumBackendSchema.LOAN_EXTENDED}_${loanExtensionId}`,
  [NotificationFrontendOnlyActionEnum.TX_BURN_ATR_TOKEN]: (atrTokens: AssetWithAmount[]) => `${NotificationFrontendOnlyActionEnum.TX_BURN_ATR_TOKEN}_${atrTokens.map(atrToken => atrToken.uniqueIdentifier).join(',')}`,
  [NotificationFrontendOnlyActionEnum.TX_CREATE_PWN_SAFE]: (chainId: SupportedChain) => `${NotificationFrontendOnlyActionEnum.TX_CREATE_PWN_SAFE}_${chainId}`,
  [NotificationFrontendOnlyActionEnum.TX_UPDATE_PWN_SAFE]: (chainId: SupportedChain, safeAddress: Address, newName: string) => `${NotificationFrontendOnlyActionEnum.TX_UPDATE_PWN_SAFE}_${chainId}_${safeAddress}_${newName}`,
  [NotificationFrontendOnlyActionEnum.TX_MINT_ATR_TOKEN]: (assets: AssetWithAmount[]) => `${NotificationFrontendOnlyActionEnum.TX_MINT_ATR_TOKEN}_${assets.map(asset => asset.uniqueIdentifier).join(',')}`,
  [NotificationFrontendOnlyActionEnum.TX_UNWRAP_BUNDLE]: (asset: AssetMetadata) => `${NotificationFrontendOnlyActionEnum.TX_UNWRAP_BUNDLE}_${asset.uniqueIdentifier}`,
  [NotificationFrontendOnlyActionEnum.THESES_CREATED]: (thesis: ThesisSchemaWorkaroundBackendSchema) => `${NotificationFrontendOnlyActionEnum.THESES_CREATED}_${thesis.slug}`,
  [NotificationFrontendOnlyActionEnum.MULTI_PROPOSALS_CREATED]: (assets: AssetWithAmount[]) => `${NotificationFrontendOnlyActionEnum.MULTI_PROPOSALS_CREATED}_${assets.map(asset => asset.uniqueIdentifier).join('_')}`,
} as const

export type ToastActionId = keyof typeof _actionIdToUniqueToastId
export const TOAST_ACTION_ID_TO_UNIQUE_ID_FN = _actionIdToUniqueToastId satisfies Record<ToastActionId, (...args: any[]) => string>
export const TOAST_ACTION_IDS = typeSafeObjectKeys(TOAST_ACTION_ID_TO_UNIQUE_ID_FN)

export enum ToastStepStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE',
}

export class ToastStep {
  text: string
  error?: Error
  fn: (step: ToastStep) => Promise<boolean> // TODO can also throw exception, update typings
  status: ToastStepStatus
  txHash?: Hex
  isRunningLong?: boolean

  constructor(step: PartialWithRequired<ToastStep, 'text' | 'fn'>) {
    this.text = step.text
    this.error = step.error
    this.fn = step.fn
    this.status = step.status || ToastStepStatus.INACTIVE // TODO does this default makes sense?
    this.txHash = step.txHash
    this.isRunningLong = step.isRunningLong ?? false
  }

  public static createSwitchChainStep(chainId: SupportedChain): ToastStep {
    return new ToastStep({
      text: 'Switch chain',
      async fn(step) {
        await switchChain(pwnWagmiConfig, { chainId })
        return true
      },
    })
  }
}

interface CreateToast {
  steps: ToastStep[]
  chainId: SupportedChain
  title: string
  firstAsset?: AssetWithAmount
  secondAsset?: AssetWithAmount
  customImage?: Component
  customBottomComponent?: Component
}

export class Toast<ToastAction extends ToastActionId = ToastActionId> {
  // TODO is this enough for unique representation of id?
  id: string
  steps: ToastStep[]
  chainId: SupportedChain
  title: string
  created: string
  stepToPerform: number // starting from 0
  firstAsset?: AssetWithAmount
  secondAsset?: AssetWithAmount
  customImage?: Component
  customBottomComponent?: Component
  _toastOptions?: ToastOptions
  _initInActionFlow?: boolean

  // additionalParameters are corresponding to the parameters needed to pass to the TOAST_ACTION_ID_TO_UNIQUE_ID_FN[action] to get unique notification id
  constructor(toast: CreateToast, action: ToastAction, ...additionalParameters: (ToastAction extends ToastActionId ? Parameters<typeof TOAST_ACTION_ID_TO_UNIQUE_ID_FN[ToastAction]> : never)) {
    // @ts-expect-error not sure why it throws type error, but should work fine
    this.id = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[action](...additionalParameters)

    this.steps = toast.steps.map(step => new ToastStep(step))
    this.chainId = toast.chainId
    this.title = toast.title
    this.created = DateUtils.displayDate(new Date())
    this.stepToPerform = 0
    this.firstAsset = toast.firstAsset
    this.secondAsset = toast.secondAsset
    this.customImage = toast.customImage
    this._initInActionFlow = false
    this.customBottomComponent = toast.customBottomComponent
  }
}

// TODO we used a few special notifications in the past:
// 1) likely rpc issue notification
// 2) user rejected tx notification
export const useToastsStore = defineStore('toasts', () => {
  const toastification = useToast()

  const displayedToasts = ref<Toast[]>([])
  const customBottomComponent = ref<Component | undefined>(undefined)

  function getToast(toastId: string): Toast | undefined {
    return displayedToasts.value.find(displayedToast => displayedToast.id === toastId)
  }

  function displayToast(toast: Toast): void {
    const toastOptions: ToastOptions = {
      id: toast.id, // overriding default toast id
      type: TYPE.INFO,
      closeButton: false,
      timeout: false,
      draggable: false,
      position: POSITION.BOTTOM_RIGHT,
      onClose() {
        const toastToDismissIndex = displayedToasts.value.findIndex(displayedToast => displayedToast.id === toast.id)
        if (toastToDismissIndex === -1) {
          return
        }

        displayedToasts.value.splice(toastToDismissIndex, 1)
      },
    }
    toast._toastOptions = toastOptions
    const toastContent: ToastContent = {
      component: BaseToast,
      props: {
        toastId: toast.id,
      },
    }
    displayedToasts.value.push(toast)
    toastification(toastContent, toastOptions)
  }

  return {
    displayedToasts: computed(() => displayedToasts.value),
    displayToast,
    getToast,
    customBottomComponent,
  }
})
