import { ALL_SUPPORTED_CHAINS_WAGMI } from '@/constants/chains/types'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type { SupportedChain } from '@/constants/chains/types'
import { compareAddresses, formatAmountWithDecimals } from '@/utils/utils'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { Address } from 'viem'
import { getBalance } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { readAnyERC20Balance } from '@/modules/common/adapters/erc20-utils'

export default function useERC20Fetch() {
  const { fetchErc20Metadata } = useMetadataFetch()

  const fetchNativeToken = async (chainId: SupportedChain, userAddress: Address) => {
    const chain = ALL_SUPPORTED_CHAINS_WAGMI.find(chainWagmi => chainWagmi.id === chainId)
    if (!chain) {
      return undefined
    }

    const nativeTokenBalance = await getBalance(pwnWagmiConfig, {
      address: userAddress,
      chainId,
    })

    if (nativeTokenBalance.value === 0n) {
      return AssetWithAmount.createNativeTokenAssetWithAmount(chainId, 0n)
    }

    return AssetWithAmount.createNativeTokenAssetWithAmount(chainId, nativeTokenBalance.value)
  }

  const fetchUserERC20Balance = async (chainId: SupportedChain, tokenAddress: Address, userAddress: Address): Promise<bigint> => {
    return await readAnyERC20Balance(tokenAddress, chainId, userAddress)
  }

  const refreshERC20Balance = async (
    chainId: SupportedChain,
    assetAddress: Address,
    userAddress: Address,
    erc20s: AssetWithAmount[],
  ) => {
    const newBalance = await fetchUserERC20Balance(chainId, assetAddress, userAddress)
    const assetIndex = erc20s.findIndex((erc20) => compareAddresses(erc20.address, assetAddress))

    if (assetIndex > -1 && newBalance <= 0n) {
      erc20s.splice(assetIndex, 1)
    } else if (assetIndex > -1 && newBalance > 0n) {
      erc20s[assetIndex] = erc20s[assetIndex].updateAssetAmounts({
        amount: formatAmountWithDecimals(newBalance, erc20s[assetIndex].decimals),
      })
    } else {
      const metadata = await fetchErc20Metadata({ chainId, contractAddress: assetAddress, userAddress })
      const erc20 = new AssetWithAmount({
        ...metadata,
        amount: formatAmountWithDecimals(newBalance, metadata?.decimals),
      })
      erc20s.push(erc20)
      return erc20
    }
  }

  return {
    fetchUserERC20Balance,
    refreshERC20Balance,
    fetchNativeToken,
  }
}
