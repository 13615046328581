enum LoanStatus {
  ProposalUnacceptable = -4,
  ProposalAccepted = -3,
  InactiveExpired = -2,
  InactiveRevoked = -1,
  NonExistent = 0,
  New = 1,
  Accepted,
  PaidBack,
  Defaulted,
  InactiveDefaulted,
  InactivePaidBack,

  // Client side only
  AcceptedTransferred,
  PaidBackTransferred,
  DefaultedTransferred,
}
export default LoanStatus
