import { watchEffect } from 'vue'
import { handleAccount, starknetAccount, starknetWallet } from '@/modules/common/web3/useCustomAccount'
import { SupportedChain } from '@/constants/chains/types'
import { Provider as RpcProvider, WalletAccount } from 'starknet'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { connect } from '@starknet-io/get-starknet'
import type { StarknetWindowObject } from '@starknet-io/get-starknet'

export const getStarknetTransport = (chain: SupportedChain): RpcProvider => {
  const url = `${CHAINS_CONSTANTS[chain].nodeProvider.httpNodeUrl}/${CHAINS_CONSTANTS[chain].nodeProvider.bearerAuthToken}`
  return new RpcProvider({
    nodeUrl: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${CHAINS_CONSTANTS[chain].nodeProvider.bearerAuthToken}`,
    },
  })
}

export function setupStarknetAccountWatcher() {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  watchEffect(async () => {
    const walletSWO: StarknetWindowObject | null = await connect({ modalMode: 'canAsk', modalTheme: 'dark' })
    if (!walletSWO) {
      return undefined
    }
    // @ts-expect-error enable is not part of the type
    await walletSWO?.enable?.()

    starknetWallet.value = walletSWO
    const account = new WalletAccount(getStarknetTransport(SupportedChain.Sepolia), walletSWO)
    // @ts-expect-error account is not part of the type
    starknetAccount.value = walletSWO?.account || account

    if (starknetWallet.value) {
      starknetWallet.value?.on('accountsChanged', (accounts) => {
        handleAccount(accounts)
      })
    }

    return undefined
  })
}
