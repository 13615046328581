<template>
  <router-link
    v-if="isLocalLink && to"
    :to="to"
    :class="linkClasses"
    active-class="is-active"
    :target="target"
    exact-active-class="is-exact-active">
    <slot/>
  </router-link>
  <a
    v-else
    :rel="isOpenedInNewTab ? 'noopener noreferrer' : undefined"
    :target="target"
    :href="(to as string)"
    :class="linkClasses">
    <slot/>
  </a>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

interface Props {
  to?: Record<string, string> | string | RouteLocationRaw
  target?: '_blank' | '_self' | '_parent' | '_top'
  isUnderlined?: boolean
  isDarkeningEffect?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isUnderlined: true,
  isDarkeningEffect: true,
  target: '_self',
})

const { to, target, isUnderlined, isDarkeningEffect } = toRefs(props)

const linkClasses = computed(() => (['link', { 'link--no-underline': !isUnderlined.value, 'link--no-darkening': !isDarkeningEffect.value }]))

const isOpenedInNewTab = computed(() => target.value === '_blank')
const isLocalLink = computed(() => typeof to.value === 'object')
</script>
