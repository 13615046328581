<template>
  <header :class="['header', {'header__underline': isSimpleHeader}]">
    <div class="header__left">
      <div class="header__logo-wrapper">
        <PWNLogo
          is-link
          height="1.5rem"
          :to-link="RouteName.RevampHomepage"
          class="header__logo"/>
        <div
          class="header__subdomain-name">
          {{ getHeaderName() }}
        </div>
      </div>
      <nav
        v-if="!isSimpleHeader"
        class="header__navigation">
        <ul class="header__navigation-list">
          <router-link
            v-for="(navigationItem, index) of navigationItems"
            :key="index"
            v-slot="{ isActive, navigate }"
            :to="{ name: navigationItem.pathname, params: navigationItem.params }"
            custom>
            <li class="header__navigation-item">
              <BaseLink
                :to="getToRoute(navigationItem)"
                :class="[
                  'header__navigation-item-text',
                  {
                    'header__navigation-item-text--active': isActive ,
                    'header__navigation-item-text--is-thesis': navigationItem.pathname === RouteName.ThesisList
                  }]"
                role="link"
                tabindex="0"
                @click="navigate"
                @keypress.enter="navigate">
                <NewIcon
                  v-if="navigationItem.pathname === RouteName.ThesisList"
                  class="header__navigation-item-new-icon"/>
                {{ navigationItem.label }}
              </BaseLink>
            </li>
          </router-link>
          <ToolSelect/>
        </ul>
      </nav>
    </div>

    <div
      class="header__right">
      <div
        v-if="!isSimpleHeader"
        class="header__chain-switcher-and-shit-filter">
        <MobileNavSearch
          v-if="!isStarknet"
          :search-is-open="searchIsOpen"
          :handle-open-search="handleOpenSearch"
          :handle-close-search="handleCloseSearch"/>
        <CurrencySwitcher/>
        <GlobalFilters
          v-if="!isPwnSpace"
          y-direction="bottom"
          x-direction="left"
          :display-amount-filter="false"/>
        <ChainFilterDropdown
          v-if="enabledChains.length > 1"
          :selected-chains="selectedChains!"
          :set-chain="selectedChainStore.actions.setSelectedChain"/>
      </div>

      <div :class="['header__notifications-and-wallet', {'header__notifications-and-wallet--with-left-border': !isPwnSpace && !isSimpleHeader}]">
        <!-- TODO: Remove silentRelease when ready -->
        <ReputationScoreModal
          v-if="!isStarknet && isConnected && userAddress && !isSimpleHeader"
          :address="userAddress"/>
        <TheWallet/>
      </div>
    </div>

    <div class="header__mobile-controls">
      <MobileNavMenu :navigation-items="mobileNavigationItems"/>
    </div>
  </header>
</template>

<script setup lang="ts">
import TheWallet from '@/layout/header/TheWallet.vue'
import RouteName from '@/router/RouteName'
import { computed, ref } from 'vue'
import ToolSelect from '@/layout/header/ToolSelect.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import MobileNavMenu from '@/layout/header/MobileNavMenu.vue'
import PWNLogo from '@/general-components/PWNLogo.vue'
import { pwnSpaceName, isPwnSpace, isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import CurrencySwitcher from '@/layout/header/CurrencySwitcher.vue'
import MobileNavSearch from '@/layout/header/MobileNavSearch.vue'
import GlobalFilters from '@/modules/common/filters/global/GlobalFilters.vue'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import ChainFilterDropdown from '@/revamp/components/ChainFilterDropdown.vue'
import { storeToRefs } from 'pinia'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import ReputationScoreModal from '@/revamp/components/modals/reputation-score/ReputationScoreModal.vue'
import NewIcon from '@/assets/icons/new-icon.svg'
import { useRoute } from 'vue-router'

const selectedChainStore = useSelectedChainsStore()
const { selectedChains } = storeToRefs(selectedChainStore)
const { isConnected, address: userAddress } = useCustomAccount()
const route = useRoute()

const searchIsOpen = ref(false)
const handleOpenSearch = () => {
  searchIsOpen.value = true
}

const handleCloseSearch = () => {
  searchIsOpen.value = false
}

const navigationItems = computed(() => {
  const elements = [
    {
      pathname: RouteName.RevampHomepage,
      label: 'Proposals',
    },
    {
      pathname: RouteName.ThesisList,
      label: 'Strategies',
    },
    {
      pathname: RouteName.PwnExplorerPageAssets,
      label: 'Explorer',
    },
    {
      pathname: RouteName.Dashboard,
      label: 'Dashboard',
      params: { user: userAddress.value },
    },
  ]
  const filteredElements = isStarknet
    ? elements.filter(el => el.label !== 'Explorer')
    : elements

  return filteredElements
},
)
const mobileNavigationItems = computed(() =>
  [
    ...navigationItems.value,
    {
      pathname: RouteName.PwnSafe,
      label: 'PWN Safe',
    },
    // {
    //   pathname: RouteName.Spaces,
    //   label: 'Spaces',
    // },
  ],
)

const getToRoute = (navigationItem: typeof navigationItems.value[number]) => {
  return navigationItem.params ? { name: navigationItem.pathname, params: navigationItem.params } : { name: navigationItem.pathname }
}

const isSimpleHeader = computed(() => [
  RouteName.ClaimRep,
  RouteName.ClaimRepBasenames,
  RouteName.ClaimRepAave,
  RouteName.ClaimRepPush,
].includes(route.name as RouteName))

const getHeaderName = () => {
  if (isSimpleHeader.value) {
    return 'Achievements'
  } else {
    return pwnSpaceName
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;

  padding: 1.5rem 0;
  background-color: var(--background-color);

  &__underline {
    border-bottom: 1px solid var(--separator-color);
  }

  &__logo-wrapper {
    position: relative;
  }

  &__right {
    display: flex;
    margin-left: auto;
    align-items: center;
  }

  &__left {
    display: flex;
    margin-right: auto;
    align-items: center;
  }

  &__logo {
    margin-top: 3px;
  }

  &__navigation-item-new-icon {
    margin-right: 0.25rem;
    position: absolute;
    top: 0.125rem;
    right: -0.75rem;
  }

  &__subdomain-name {
    position: absolute;
    color: var(--primary-color-1);
    font-family: var(--font-family-oxygen-mono);
    font-size: 0.75rem;
    right: -2px;
    top: 30px;
  }

  &__search {
    width: 20%;
    flex: 1;
    margin: 0 3rem;
    max-width: 477px;
  }

  &__navigation-list {
    display: flex;
    padding: 0;
    margin: 0 0 0 2rem;
    color: var(--text-color);

    align-items: baseline;
  }

  &__navigation-item {
    list-style-type: none;
    position: relative;
    display: flex;
    justify-content: center;
    padding-right: 2.125rem;
    font-size: 1rem;

    &:last-child {
      padding-right: 0;
    }
  }

  &__navigation-item-text {
    padding: 1rem 0;
    cursor: pointer;
    color: var(--text-color);
    text-decoration: none;

    font-family: var(--font-family-screener);
    position: relative;

    &--active {
      color: var(--primary-color-1);
    }

    &:hover {
      color: var(--primary-color-1);
      text-decoration: underline;
    }
  }

  &__notifications-and-wallet {
    gap: 1rem;
    height: 3rem;
    display: flex;
    align-items: center;

    &--with-left-border {
      padding-left: 1rem;
      border-left: 1px solid var(--gray-3);
    }
  }

  &__chain-switcher-and-shit-filter {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 1rem 0 2.625rem;
  }

  &__mobile-controls {
    display: none;
  }

  @media only screen and (--small-viewport) {
    max-width: 320px;
    position: relative;

    &__subdomain-name {
      right: -75px;
      top: 18px;
    }

    &__search,
    &__logo,
    &__navigation,
    &__chain-switcher-and-shit-filter,
    &__notifications-and-wallet {
      display: none;
    }

    &__mobile-controls {
      display: block;
      width: 100%;
    }
  }
}
</style>

<style>
/* stylelint-disable no-descending-specificity */
.header__chain-switcher-and-shit-filter .multiselect__tags,
.header__chain-switcher-and-shit-filter .multiselect,
.mobile-nav-bar .mobile-nav-bar__actions .multiselect__tags,
.mobile-nav-bar .mobile-nav-bar__actions .multiselect {
  height: 2rem !important;
  min-height: 2rem !important;
  width: 2rem;
}

.header__chain-switcher-and-shit-filter .multiselect__tags,
.mobile-nav-bar .multiselect__tags {
  padding: 0 !important;

  &:hover {
    border-color: var(--primary-color-1);
  }

  .multiselect__single {
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.chain-switcher .multiselect__content-wrapper {
  max-height: 16.5rem !important;
  left: -10rem;
}

</style>
