<template>
  <div
    class="loan-detail-page">
    <div class="loan-detail-page__loan-header">
      <HeaderProposalOrLoan
        v-if="loanData"
        :title="title"
        :loan="loanData"
        :type="loanData.type"
        :loan-status="getLoanStatus({status: loanData.status, paidBackAt: loanData.paidBackAt})"/>
      <SetNotificationsButton
        v-if="isBorrowerOrLoanOwner && loanData?.isRunningLoan"
        :is-modal-open="isNotificationModalShown"
        :expiration="loanData?.defaultDate"
        :chain-name="route.params.chainName as string"
        :loan-contract-address="loanTokenContractAddress"
        :loan-id="loanId"/>
    </div>
    <BaseSkeletor v-if="!isFetched"/>
    <div
      v-else>
      <div class="loan-detail-page__content">
        <LoanDetail :loan-data="loanData"/>
        <section class="loan-detail-page__form">
          <LoanDateInfo :loan-data="loanData"/>

          <RepayButton
            v-if="loanData?.hasRepayButton && loanData?.contractAddress"
            :loan-data="loanData"
            :loan-id="loanId"
            :chain-id-where-is-loan="Number(chainIdWhereIsLoan)"
            :asset-to-repay-address="loanData?.creditAsset?.address ? loanData.creditAsset.address as Hex : '0x'"
            :loan-contract-address="loanData.contractAddress as Hex"/>
          <ClaimButton
            v-if="loanData?.contractAddress && (loanData?.hasClaimButton || loanData?.isRunningLoan) && !loanData?.hasRepayButton"
            :loan-data="loanData"
            :has-claim-button="!!loanData?.hasClaimButton"
            :loan-id="loanId"
            :chain-id-where-is-loan="Number(chainIdWhereIsLoan)"/>
        </section>
      </div>
      <!-- todo: decide if we want to show borrow or lend proposals here -->
      <div class="loan-detail-page__proposals">
        <div class="loan-detail-page__filters">
          <div class="loan-detail-page__filters-left">
            <div class="loan-detail-page__filters-row">
              <span class="loan-detail-page__title-proposals">
                <!-- note: - 1 is little hack to exclude the proposal that corresponds to the loan detail itself -->
                Similar Proposals ({{ (injectedProposalsCount === null || injectedProposalsCount === 0 ? 1 : injectedProposalsCount) - 1 }})
              </span>
            </div>
            <div class="loan-detail-page__filters-row">
              <ProposalFilters
                v-model:show-history="showHistory"
                is-read-only
                :selected-collateral="currentCollateral"/>
            </div>
          </div>
        </div>
        <ProposalsTable
          v-if="loanData && loanData?.collateral?.address && loanData?.collateral?.chainId"
          type="all"
          hide-no-proposals-banner
          :show-history="showHistory"
          :selected-collateral-filter-assets="currentCollateral ? [currentCollateral] : undefined"
          :include-collateral-without-price="true"
          :include-credit-without-price="globalFiltersStore.showAssetsWithoutPricing"
          :include-unverified-collateral="true"
          :include-unverified-credit="globalFiltersStore.showUnverifiedAssets"
          :loan-id-to-filter-out="loanId"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, provide } from 'vue'
import HeaderProposalOrLoan from '@/revamp/pages/proposal-or-loan/HeaderProposalOrLoan.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import { compareAddresses } from '@/utils/utils'
import RepayButton from '@/revamp/components/actions/RepayButton.vue'
import { getChainIdFromChainName } from '@/utils/chain'
import { SupportedChain } from '@/constants/chains/types'
import type { ChainName, ChainNameLowercase } from '@/constants/chains/types'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import ClaimButton from '@/revamp/components/actions/ClaimButton.vue'
import { useLoanDetail } from '@/modules/common/backend/generated'
import type { Hex } from 'viem'
import ProposalsTable from '@/revamp/components/tables/ProposalsTable.vue'
import LoanDetail from '@/revamp/pages/loan/LoanDetail.vue'
import ProposalFilters from '@/revamp/components/proposal-filters/ProposalFilters.vue'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { AssetDetail } from '@/revamp/modules/proposals/types'
import { injectedProposalsCountKeyName, useInjectedProposalsCount } from '@/revamp/hooks/useInjectedProposalsCount'
import LoanDateInfo from '@/revamp/pages/loan/LoanDateInfo.vue'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'
import { useLocalStorage } from '@vueuse/core'
import SetNotificationsButton from '@/revamp/components/SetNotificationsButton.vue'
import { parseAddress } from '@/utils/address-utils'

const route = useRoute()
const { address: userAddress } = useCustomAccount()
const title = computed(() => `Loan #${route.params.id as string}`)

const globalFiltersStore = useGlobalFiltersStore()

const showHistory = useLocalStorage('show-history-on-loan-detail', false)

const chainIdWhereIsLoan = computed(() => String(getChainIdFromChainName(route.params.chainName as ChainName | ChainNameLowercase)))
const loanTokenContractAddress = computed(() => parseAddress(route.params.loanTokenContractAddress as string))
const loanId = computed(() => String((route.params.id)))
const loanDetailQuery = useLoanDetail(chainIdWhereIsLoan, loanTokenContractAddress, loanId, {
  query: {
    retry: 3,
    // since we are on unichain sepolia, we need to retry every 60 seconds
    // event listener is super slow there, so that's the way
    retryDelay: chainIdWhereIsLoan.value === String(SupportedChain.UnichainSepolia) ? 60000 : 1000,
  },
})

const loanData = computed(() => {
  const data = loanDetailQuery.data?.value?.data
  if (!data) {
    return null
  }

  const isBorrower = compareAddresses(userAddress.value, data?.borrower as Hex)
  const isLoanOwner = compareAddresses(userAddress.value, data.loanOwner as Hex)
  const isRunningLoan = LoanStatus.Accepted === +data.status as LoanStatus
  const isPaidBack = (LoanStatus.PaidBack === +data.status as LoanStatus || data.paidBackAt)
  const isDefaulted = LoanStatus.Defaulted === +data.status as LoanStatus
  const hasRepayButton = isBorrower && isRunningLoan
  const hasClaimButton = isLoanOwner && (isPaidBack || isDefaulted) && !data.claimedAt

  return { ...data, hasRepayButton, isRunningLoan, hasClaimButton }
})
const isFetched = computed(() => loanDetailQuery.isFetched.value)

const getLoanStatus = ({ status, paidBackAt }:{status: number, paidBackAt: number | null }) => {
  if (status === 0 && paidBackAt) {
    return LoanStatus.PaidBack
  }
  return status
}

const collateralId = computed(() => {
  // negative sign is used to represent that it's client side generated
  return !isNaN(Number(loanData.value?.collateral?.id)) ? Number(loanData.value?.collateral?.id) : Math.random() ** -10 * 3
})

const currentCollateral = computed(() => {
  if (!loanData.value?.collateral) return null
  const v = loanData.value?.collateral as AssetDetail
  // @ts-expect-error: types missmatch
  return new AssetWithAmount({
    ...v,
    id: collateralId.value,
    image: v.thumbnailUrl || undefined,
  })
})

const isBorrowerOrLoanOwner = computed(() => {
  return compareAddresses(userAddress.value, loanData.value?.borrower as Hex) || compareAddresses(userAddress.value, loanData.value?.loanOwner as Hex)
})

const alreadySeenLoans = useLocalStorage('already-seen-loans', [])
const dontShowAgain = useLocalStorage('dont-show-notification-modal', false)

const isNotificationModalShown = computed(() => {
  return isBorrowerOrLoanOwner.value && !alreadySeenLoans.value.find((id: number | string) => id === loanId.value) && !dontShowAgain.value && loanData.value?.isRunningLoan
})

const { injectedProposalsCount, setInjectedProposalsCount } = useInjectedProposalsCount()
provide(injectedProposalsCountKeyName, {
  injectedProposalsCount,
  setInjectedProposalsCount,
  setHasEmptyState: (v: boolean) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
})

</script>

<style scoped>
.loan-detail-page {
  margin-top: 2rem;
  padding-bottom: 1rem;

  &__filters {
    display: flex;
    justify-content: space-between;

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-left {
      @media only screen and (--small-viewport) {
        width: 100%;
      }
    }

    &-row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    @media only screen and (--small-viewport) {
      flex-flow: column nowrap;
    }
  }

  &__proposals {
    margin-top: 2.5rem;
    padding: 1rem;
    border: 1px solid #434343;

    @media only screen and (--small-viewport) {
      margin-bottom: 5rem;
    }
  }

  &__title-proposals {
    font-size: 1.125rem;
  }

  &__form {
    max-width: 413px;
    width: 100%;
    border: 1px solid #434343;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 1rem;
    height: fit-content;
    margin-left: 1.5rem;

    @media only screen and (--mobile-viewport) {
      max-width: 100%;
    }

    @media only screen and (--small-viewport) {
      margin-left: 0;
      margin-top: 1rem;

      max-width: 413px;
    }
  }

  &__form-connected-proposer {
    display: flex;
  }

  &__loan-header {
    font-size: 1.125rem;
    display: flex;
    justify-content: space-between;

    @media only screen and (--mobile-viewport) {
      flex-flow: column nowrap;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &__notification {
    align-self: flex-end;
    margin-bottom: 1.5rem;
  }
}
</style>
