<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.XLarge"
    :variant="ModalVariant.Default"
    :is-closable="true"
    :heading-icon="mobileIcon"
    heading-align="left"
    heading="Mobile Version is in Alpha">
    <template #body>
      <p class="mobile-alpha-modal__text">
        Mobile UI is in early development; expect some issues. For a smoother experience, we suggest using the desktop app.
      </p>
      <p class="mobile-alpha-modal__text mobile-alpha-modal__text-highlighted">
        If you do choose to use the app from your mobile device, we highly recommend using your wallet browser instead of your regular browser!
      </p>

      <div class="mobile-alpha-modal__controls">
        <BaseCheckbox
          v-model="doNotShowModalAgain"
          class="mobile-alpha-modal__text"
          label="Don't show this message again"/>
        <BaseButton
          class="mobile-alpha-modal__text"
          button-text="Understood"
          :color="ButtonColor.Primary"
          @on-button-click="handleClose"/>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import ModalVariant from '@/general-components/ModalVariant'
import ModalSize from '@/general-components/ModalSize'
import { computed, ref } from 'vue'
import { isMobile } from '@/utils/mediaQueries'
import BaseButton, { ButtonColor } from '@/general-components/BaseButton.vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import mobileIcon from '@/assets/icons/mobile-icon.svg'

const LOCAL_STORAGE_SKIP_MOBILE_ALPHA_MODAL = 'skip-mobile-alpha-modal'

const getLocalStorageSkipMobileAlphaModal = computed(() => {
  if (!isMobile.value) return true
  const doNotShowModalAgain = localStorage.getItem(LOCAL_STORAGE_SKIP_MOBILE_ALPHA_MODAL)
  return doNotShowModalAgain === 'true'
})

const isOpen = ref(!getLocalStorageSkipMobileAlphaModal.value)
const doNotShowModalAgain = ref(false)

const handleClose = () => {
  if (doNotShowModalAgain.value) {
    localStorage.setItem(LOCAL_STORAGE_SKIP_MOBILE_ALPHA_MODAL, 'true')
  }
  isOpen.value = false
}
</script>

<style scoped>
.mobile-alpha-modal {
  &__text {
    font-size: 0.875rem;

    &-highlighted {
      color: var(--negative-1);
    }
  }

  &__controls {
    display: flex;
    flex-flow: column;
    margin-top: 1rem;
    align-items: flex-end;
    row-gap: 1rem;
  }
}
</style>
