<template>
  <div
    ref="tableContainerRef"
    class="container"
    style="overflow: auto; position: relative; height: 400px;">
    <table class="thesis-collateral-assets-table">
      <thead class="thesis-collateral-assets-table__head">
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
          class="thesis-collateral-assets-table__head-row">
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            :style="{
              width: `${header.getSize()}px`,
            }"
            :colSpan="header.colSpan">
            <FlexRender
              v-if="!header.isPlaceholder"
              :render="header.column.columnDef.header"
              :props="header.getContext()"/>
          </th>
        </tr>
      </thead>
      <tbody
        class="thesis-collateral-assets-table__body"
        style="display: grid; height: 100%; position: relative;"
        :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
        <TransitionGroup name="thesis-collateral-assets-table--fade">
          <tr
            v-for="row in virtualRows"
            :key="rows[row.index].id"
            :ref="customRefHandler"
            :class="['thesis-collateral-assets-table__row', { 'thesis-collateral-assets-table__row--disabled': !rows[row.index].getCanSelect() }]"
            :data-index="row.index"
            :style="{
              display: 'flex',
              position: 'absolute',
              transform: `translateY(${row.start}px)`,
              width: '100%',
              height: `64px`,
              alignItems: 'center',
              justifyContent: 'space-between',
            }">
            <td
              v-for="cell in rows[row.index].getVisibleCells()"
              :key="cell.id"
              :style="{
                width: `${cell.column.getSize()}px`,
              }">
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"/>
            </td>
          </tr>
        </TransitionGroup>
        <BaseSkeletor v-if="props.isLoading"/>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { FlexRender, getCoreRowModel, getFilteredRowModel, useVueTable } from '@tanstack/vue-table'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { computed, h, ref, toRefs } from 'vue'
import AssetInfoCell from '@/revamp/components/tables/cells/assets/AssetInfoCell.vue'
import { useVirtualizer } from '@tanstack/vue-virtual'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import { isMobile } from '@/utils/mediaQueries'
import AssetPrice from '@/revamp/components/prices/AssetPrice.vue'
import type { ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import type { AssetWithExtraThesisParams } from '@/revamp/hooks/thesis/types'

type Props = {
  thesis: ThesisSchemaWorkaroundBackendSchema;
  data: AssetMetadata[];
  isLoading: boolean;
};

const props = defineProps<Props>()
const { data } = toRefs(props)
const tableContainerRef = ref(null)

const assetsWithAllocation = computed(() => {
  return data.value.map((asset) => {
    const { allocationPercentage, ltv, apr } = props.thesis.collateralAssets?.find((collateral) => collateral.id === String(asset.id)) || {}
    return {
      ...asset,
      allocationPercentage,
      ltv,
      apr,
      routeToAssetPage: () => asset.routeToAssetPage(),
    } as AssetWithExtraThesisParams
  })
})

const table = useVueTable({
  get data() {
    return assetsWithAllocation.value
  },
  getRowId: (row) => String(row.id),
  columns: [
    {
      header: 'Asset',
      id: 'name',
      accessorFn: (row) => row.name,
      cell: (ctx) => {
        return h(AssetInfoCell, {
          asset: ctx.row.original,
        })
      },
      size: isMobile.value ? 220 : 420,
    },
    {
      header: 'Price',
      id: 'unitPrice',
      cell: (ctx) => {
        const asset = ctx.row.original
        if (!asset) return null
        return h(AssetPrice, {
          contractAddress: asset.address,
          tokenId: asset.tokenId ? String(asset.tokenId) : null,
          chainId: String(asset.chainId),
          class: 'thesis-collateral-assets-table__price',
        })
      },
    },
    // {
    //   header: 'Market cap',
    //   id: 'marketCap',
    //   accessorFn: (row) => '$100,000,000',
    // },
    {
      header: 'Allocation',
      id: 'allocation',
      accessorFn: (row) => `${row?.allocationPercentage}%`,
    },
    {
      header: 'LTV',
      id: 'ltv',
      accessorFn: (row) => `${row.ltv}%`,
    },
    {
      header: 'APR',
      id: 'apr',
      accessorFn: (row) => {
        const apr = row.apr || props.thesis.aprMin

        return `${apr}%`
      },
    },
    {
      header: 'Duration',
      id: 'duration',
      cell: () => `${props.thesis.loanDurationDays}d`,
    },
  ],
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rows.value.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 64,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

</script>

<style scoped>
.thesis-collateral-assets-table {
  display: grid;

  padding-right: 1rem;

  th {
    text-align: left;
    font-family: var(--font-family-screener);
    font-size: 0.875rem;
    color: #a4a4a4;
    font-weight: 400;
  }

  &__price {
    text-align: left;
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 5;
    background: var(--background-color);

    display: flex;

    padding: 0 0.5rem;

    border-bottom: 1px dashed #828282;
  }

  &__head-row {
    width: 100%;
    padding: 0.25rem;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    font-family: var(--font-family-screener);
  }

  &__row {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;

    padding: 0.5rem 1rem;
    will-change: background-color;
    transition: background-color 0.3s linear;
    cursor: pointer;

    &--disabled {
      opacity: 0.5;
    }

    &:hover {
      background-color: var(--gray-3);
    }
  }
}

.thesis-collateral-assets-table--fade,
.thesis-collateral-assets-table--fade-move {
  transition: opacity 0.5s;

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave-active {
    position: absolute;
  }
}
</style>
