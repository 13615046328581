<template>
  <BaseSection
    :is-expandable="props.isExpandable"
    with-gray-background
    :is-loading="userNftsIsPending"
    :secondary-heading="secondaryHeading"

    :heading="heading">
    <template #title-icon>
      <NFTIcon class="section-your-coins"/>
    </template>
    <YourNFTsTable
      :is-dashboard="props.isDashboard"
      :table-min-width="tableMinWidth"
      :assets="assets"
      :is-loading="userNftsIsPending"
      :has-first-column-padding="props.hasFirstColumnPadding"
      :table-definition="props.tableDefinition"
      :is-asset-selected="isAssetSelected"
      :is-asset-disabled="isAssetDisabled"
      display-loading-bottom
      :has-edit-tooltip="props.hasEditTooltip"
      @on-asset-select="emitOnAssetSelect"/>
    <template #controls>
      <div class="section-your-nts__controls">
        <span
          v-if="props.hiddenAssets > 0"
          class="section-your-nfts__hidden-assets-text"
          @click="handleClickShowAll">
          {{ checkboxLabelText }}
        </span>
        <TokenTypeFilter
          v-if="hasFilterByAtrTokenType"
          @on-switch-display-coins="handleOnSwitchDisplayCoinsOfAtrToken"
          @on-switch-display-nfts="handleOnSwitchDisplayNftsOfAtrToken"/>
      </div>
    </template>
    <BaseEmptyStateText
      v-if="hasEmptyState"
      :text="emptyStateText"/>
  </BaseSection>
</template>

<script lang="ts" setup>
import BaseSection from '@/general-components/BaseSection.vue'
import YourNFTsTable from '@/modules/sections/your-assets/your-nfts/YourNFTsTable.vue'
import { computed, onMounted, provide, toRefs } from 'vue'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import TokenTypeFilter from '@/general-components/TokenTypeFilter.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { storeToRefs } from 'pinia'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { appliedFiltersKey } from '@/modules/common/injection-keys'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import NFTIcon from '@/assets/icons/asset-offer.svg'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

interface Props {
  tableDefinition: ColumnProperties[]
  hasFirstColumnPadding: boolean
  isExpandable?: boolean
  isDashboard?: boolean
  tableMinWidth?: string
  heading?: string
  isAssetSelected?: (asset: AssetWithAmount) => boolean
  isAssetDisabled?: (asset: AssetWithAmount) => boolean
  amountOfNfts?: number
  emptyStateText?: string
  hasFilterByAtrTokenType?: boolean
  hasEditTooltip?: boolean
  assets: AssetWithAmount[]
  hiddenAssets?: number
}

const { userAddressToDisplay } = useYourNFTs()

const props = withDefaults(defineProps<Props>(), {
  isExpandable: true,
  isDashboard: false,
  hasFilterByAtrTokenType: false,
  emptyStateText: 'No NFTs available',
  heading: 'NFTs ',
  hasEditTooltip: false,
  hiddenAssets: 0,
})

const { assets, amountOfNfts } = toRefs(props)

const userAssetsStore = useUserAssetsStore()
const { userNftsIsPending, selectedNFTsSortOption } = storeToRefs(userAssetsStore)

const { debouncedSearchTerm } = useDashboard()

const selectedChainStore = useSelectedChainsStore()
const globalFiltersStore = useGlobalFiltersStore()

const { showUnverifiedAssets, showAssetsWithLesserAmount } = storeToRefs(globalFiltersStore)
const { selectedChains } = storeToRefs(selectedChainStore)

const checkboxLabelText = computed(() => {
  return `Show Hidden Assets (${props.hiddenAssets})`
})

const appliedFiltersOrSorting = computed(() =>
  // we create snapshot of all applied filters to pass it down to virtual table
  // component and trigger scroll event once value is changed
  JSON.stringify({
    searchTerm: debouncedSearchTerm.value,
    selectedChains: selectedChains.value,
    selectedNFTsSortOption: selectedNFTsSortOption.value,
  }))

provide(appliedFiltersKey, appliedFiltersOrSorting)

const emit = defineEmits<{(e: 'on-asset-select', asset: AssetWithAmount): void;
  (e: 'on-switch-display-nfts-of-atr-token', value: boolean): void;
  (e: 'on-switch-display-coins-of-atr-token', value: boolean): void;
}>()
const emitOnAssetSelect = (asset: AssetWithAmount) => emit('on-asset-select', asset)

const amountOfNftsComputed = computed(() => amountOfNfts.value)
const hasEmptyState = computed(() => !userNftsIsPending.value && amountOfNfts.value === 0)
const secondaryHeading = computed(() => `(${amountOfNftsComputed.value}) `)

const handleOnSwitchDisplayNftsOfAtrToken = (val: boolean) => {
  emit('on-switch-display-nfts-of-atr-token', val)
}
const handleOnSwitchDisplayCoinsOfAtrToken = (val: boolean) => {
  emit('on-switch-display-coins-of-atr-token', val)
}

onMounted(() => {
  if (!props.isDashboard) {
    userAddressToDisplay.value = getAccount(pwnWagmiConfig).address
  }
})

const handleClickShowAll = () => {
  showUnverifiedAssets.value = true
  showAssetsWithLesserAmount.value = true
}
</script>

<style scoped>
.section-your-nfts {
  &__hidden-assets-text {
    font-family: var(--font-family-supreme);
    font-size: 0.875rem;
    font-weight: 400;

    cursor: pointer;
    transition: color 0.3s linear;

    &:hover {
      color: var(--gray);
    }
  }
}

.section-your-nts__controls {
  display: flex;
  gap: 1rem;
}

</style>
