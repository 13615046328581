<template>
  <div
    v-if="isLoginPromptVisible"
    :class="['please-connect-wallet__empty-state', `please-connect-wallet__background--${backgroundImage}`]">
    <div
      v-if="!isConnected"
      class="please-connect-wallet__empty-state-message">
      Please connect your wallet to use {{ message }}
      <BaseButton
        class="please-connect-wallet__connect-wallet-button"
        :font="ButtonFont.Screener"
        button-text="Connect Wallet"
        @on-button-click="handleConnect"/>
    </div>
    <div
      v-if="isLoginPrompt && !isStarknet"
      class="please-connect-wallet__search">
      {{ searchText }}
      <NavSearchBar
        variant="dashboard"
        placeholder="Search"
        class="please-connect-wallet__searchbar"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonFont } from '@/general-components/BaseButton.vue'
import { computed, toRefs } from 'vue'
import { PleaseConnectWalletBgImage } from '@/general-components/PleaseConnectWalletProperties'
import NavSearchBar from '@/modules/sections/nav-searchbar/NavSearchBar.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useCustomConnect } from '@/modules/common/web3/useCustomConnect'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

interface Props {
  message?: string
  isLoginPrompt?: boolean
  backgroundImage?: PleaseConnectWalletBgImage
}
const props = withDefaults(defineProps<Props>(), {
  isLoginPrompt: undefined,
  message: '',
  backgroundImage: PleaseConnectWalletBgImage.Dashboard,
})
const { message, isLoginPrompt, backgroundImage } = toRefs(props)

const { isConnected } = useCustomAccount()

const { handleConnect } = useCustomConnect()

const isLoginPromptVisible = computed(() => isLoginPrompt.value ?? !isConnected.value)

const searchText = computed(() => !isConnected.value ? 'or search for an address or ENS name' : 'Enter someone\'s address or ENS name')
</script>

<style scoped>
.please-connect-wallet {
  padding-bottom: 5rem;

  &__search {
    margin-top: 4.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__searchbar {
    margin-top: 1.5rem;
    width: 40rem;
  }

  &__empty-state {
    position: absolute;
    min-width: var(--app-min-width);
    width: 100%;
    height: calc(100vh - var(--menu-height));
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    font-size: larger;
    font-family: var(--font-family-screener);
    font-weight: 400;
    left: 0;
  }

  &__empty-state-message {
    width: 100%;
    text-align: center;
    margin-top: 13rem;
  }

  &__connect-wallet-button {
    margin-top: 3rem;
  }

  &__background {
    &--safe {
      backdrop-filter: blur(7px);
      -webkit-backdrop-filter: blur(0.5rem);
      z-index: var(--z-index-make-offer-overlay-content);
      max-width: 100%;
      left: 0;
    }

    &--dashboard {
      background-image: url("@/assets/images/dashboard-skeleton.svg");
    }
  }

  @media only screen and (--mobile-viewport) {
    &__empty-state {
      left: 0;
      min-width: var(--mobile-app-min-width);
      max-width: 100%;
    }

    &__searchbar {
      width: 20rem;
    }
  }
}
</style>
