import type { ColumnProperties } from '@/general-components/ColumnProperties'

export const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: '',
    width: '3%',
    slotName: 'checkbox',
  },
  {
    title: 'Collateral',
    width: '37%',
    slotName: 'collateral-info',
  },
  {
    title: 'Appraisal',
    width: '17%',
    slotName: 'appraisal',
    align: 'right',
  },
  {
    title: 'Floor / Price',
    width: '20%',
    slotName: 'floor-price',
    align: 'right',
  },
  {
    title: '',
    width: '5%',
    slotName: 'empty-column',
  },
  {
    title: 'Amount',
    width: '17%',
    slotName: 'amount',
  },
]
