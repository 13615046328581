<template>
  <BaseTable
    v-model:active-sort-option="selectedNFTsSortOption"
    class="your-nfts-table"
    :columns="tableDefinition"
    :items="assets"
    :has-edit-tooltip="hasEditTooltip"
    :table-min-width="tableMinWidth"
    :variant="TableVariant.Assets"
    :is-hoverable="!isDashboard"
    :is-fetching="userNftsIsPending"
    is-virtual
    :item-height="63"
    :virtual-table-overscan="3"
    :has-first-column-padding="hasFirstColumnPadding"
    table-max-height="330px"
    @on-row-click="emitOnAssetSelect">
    <template #checkbox="{ item: token }">
      <BaseCheckbox
        v-if="isAssetSelected !== undefined"
        :class="[
          {
            'your-nfts-table__disabled-row':
              isAssetDisabled?.(token) || (hasEditTooltip && rowShouldBeDisabled(token) && !isDashboard),
          },
        ]"
        :is-disabled="!token.isValid || isAssetDisabled?.(token)"
        :model-value="isAssetSelected(token)"
        @click.prevent/>
    </template>

    <template #asset-info="{ item: token, index }">
      <div
        v-if="(isRowDisabled(token) && hasEditTooltip && !isDashboard) || isAssetDisabled?.(token)"
        class="your-nfts-table__invalid-asset">
        <AssetInfo
          :class="[{ 'your-nfts-table__disabled-row': hasEditTooltip || rowShouldBeDisabled(token) }]"
          :asset="token"
          badge-size="small"
          :show-verification-badge="true"
          :display-rewards-tooltip="isDashboard"
          :is-lazy-loaded="handleIsLazyLoadedInTable(index)"
          :show-link="isDashboard"/>
        <img
          v-if="!isAssetDisabled?.(token)"
          src="@/assets/icons/questionmark.svg?url"
          alt="info">
      </div>
      <div
        v-else-if="isRowDisabled(token) && hasEditTooltip && isDashboard"
        class="your-nfts-table__invalid-asset">
        <AssetInfo
          :asset="token"
          badge-size="small"
          :show-verification-badge="true"
          :display-rewards-tooltip="isDashboard"
          :is-lazy-loaded="handleIsLazyLoadedInTable(index)"
          :show-link="isDashboard"/>
      </div>
      <AssetInfo
        v-else
        :asset="token"
        badge-size="small"
        :show-verification-badge="true"
        :display-rewards-tooltip="isDashboard"
        :is-lazy-loaded="handleIsLazyLoadedInTable(index)"
        :show-link="isDashboard"/>
    </template>

    <template #appraisal="{ item: token }">
      <AssetAppraisal
        :asset-amount="token.amount"
        :asset-metadata="token"
        @asset-appraisal-updated="(log) => smallBalanceAssetsStore.addAsset(token.assetContractAddress, log)"/>
    </template>

    <template #amount="{ item: token }">
      <BasePriceLabel
        :class="[{ 'your-nfts-table__disabled-row': hasEditTooltip && rowShouldBeDisabled(token) && !isDashboard }]"
        :amount="token.amount"/>
    </template>

    <template
      v-if="dashboardUserAddress === userAddress"
      #action="{ item: token }">
      <span
        class="link link--primary link--sm link--font-oxygen-mono your-nfts-table__action"
        @click="useCollateral(token)">Borrow</span>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">
import BaseTable from '@/general-components/BaseTable.vue'
import AssetType from '@/modules/common/assets/AssetType'
import useModalCryptoPunkWrap from '@/general-components/useModalCryptoPunkWrap'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import AssetInfo from '@/general-components/AssetInfo.vue'
import TableVariant from '@/general-components/TableVariant'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import { toRefs } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useEditLoanTooltip from '@/general-components/useEditLoanTooltip'
import useLazyLoading from '@/modules/common/useLazyLoading'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { storeToRefs } from 'pinia'
import BasePriceLabel from '@/general-components/BasePriceLabel.vue'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
import { useAssetsWithSmallBalancesStore } from '@/revamp/hooks/useAssetsWithSmallBalancesStore'

interface Props {
  tableDefinition: ColumnProperties[];
  hasFirstColumnPadding: boolean;
  tableMinWidth?: string;
  isDashboard: boolean;
  isAssetSelected?: (asset: AssetWithAmount) => boolean;
  isAssetDisabled?: (asset: AssetWithAmount) => boolean;
  hasEditTooltip?: boolean;
  assets: AssetWithAmount[];
  isLoading: boolean;
}

const props = defineProps<Props>()
const {
  tableDefinition,
  hasFirstColumnPadding,
  isDashboard,
  isAssetSelected,
  hasEditTooltip,
  assets,
  isAssetDisabled,
} = toRefs(props)

const userAssetsStore = useUserAssetsStore()
const smallBalanceAssetsStore = useAssetsWithSmallBalancesStore()
const { userNftsIsPending, dashboardUserAddress, selectedNFTsSortOption } = storeToRefs(userAssetsStore)

const emit = defineEmits<{(e: 'on-asset-select', asset: AssetWithAmount): void }>()

const { address: userAddress } = useCustomAccount()
const { isRowDisabled } = useEditLoanTooltip()
const { handleIsLazyLoadedInTable } = useLazyLoading()

const isCryptoPunk = (asset: AssetWithAmount) => asset.category === AssetType.CRYPTOPUNKS

const emitOnAssetSelect = (asset: AssetWithAmount) => {
  if (isDashboard.value) {
    return // do nothing
  }

  if (isCryptoPunk(asset)) {
    const { openModal } = useModalCryptoPunkWrap()
    openModal(asset)
  } else {
    emit('on-asset-select', asset)
  }
}
const rowShouldBeDisabled = (asset: AssetWithAmount) => {
  const assetIsDisabled = isAssetDisabled.value
  if (assetIsDisabled?.(asset)) {
    return true
  }
  return isRowDisabled(asset) || isAssetDisabled.value?.(asset)
}

const useCollateral = (asset: AssetWithAmount) => {
  emit('on-asset-select', asset)
}
</script>

<style scoped>
.your-nfts-table {
  &__no-assets {
    color: var(--negative-1);
    font-size: 1.25rem;
  }

  &__disabled-row {
    opacity: 0.35;
  }

  &__invalid-asset {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__action {
    text-align: right;
    display: block;
    padding-right: 0.5rem;
    font-size: 0.875rem;
  }

  @media only screen and (--small-viewport) {
    overflow: auto auto;
    padding-bottom: 2rem;
  }
}
</style>

<style>
.your-nfts-table {
  .asset-price {
    text-align: right;
    white-space: nowrap;
    justify-content: flex-start;
  }
}
</style>
