import Notification from '@/modules/common/notifications/Notification'
import to from '@/utils/await-to-js'
import useNotifications, { NOTIFICATIONS_IN_WALLET_MENU_AT_START } from '@/modules/common/notifications/useNotifications'
import { computed, readonly, ref } from 'vue'
import { compareAddresses } from '@/utils/utils'
import { isPwnSpace, isStarknet, pwnSpaceName } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { includeTestnetsInMultichainResults } from '@/utils/url'
import { notificationNotificationList, notificationUserUnseenNotificationsCount } from '@/modules/common/backend/generated'
import type { NotificationNotificationListParams } from '@/modules/common/backend/generated'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
const isFetchingNotifications = ref(false)
const hasViewAllNotifications = ref(false)
const setIsFetchingNotifications = (isFetching: boolean): void => {
  isFetchingNotifications.value = isFetching
}

const isFetchingNotificationsForWalletMenu = ref(false)
const setIsFetchingNotificationsForWalletMenu = (isFetching: boolean): void => {
  isFetchingNotificationsForWalletMenu.value = isFetching
}

const notificationsFetchOffset = ref(0)
const ALL_NOTIFICATIONS_FETCHED_OFFSET_VALUE = -1
const isAllNotificationsFetched = computed(() => notificationsFetchOffset.value === ALL_NOTIFICATIONS_FETCHED_OFFSET_VALUE)
export default function useNotificationsFetch() {
  const resetNotificationsFetchOffset = (): void => {
    notificationsFetchOffset.value = 0
  }

  const setAllNotificationsFetched = (): void => {
    notificationsFetchOffset.value = ALL_NOTIFICATIONS_FETCHED_OFFSET_VALUE
  }

  const loadUserNotificationsForWalletMenu = async (): Promise<void> => {
    setIsFetchingNotificationsForWalletMenu(true)

    const { amountOfUnseenNotifications, pushToNotificationsStore } = useNotifications()

    // For some reason useCustomAccount() for main app is not working in this place.
    let userAddress
    if (isStarknet) userAddress = useCustomAccount().address.value
    else userAddress = getAccount(pwnWagmiConfig).address

    const requestParams: NotificationNotificationListParams = {
      offset: notificationsFetchOffset.value,
      limit: NOTIFICATIONS_IN_WALLET_MENU_AT_START + 1,
      include_testnets: includeTestnetsInMultichainResults,
      ...(isPwnSpace && pwnSpaceName && !isStarknet && { subdomain: pwnSpaceName }),
    }

    const [error, results] = await to(Promise.all([
      notificationNotificationList(userAddress, requestParams),
      notificationUserUnseenNotificationsCount(
        userAddress,
        {
          include_testnets: includeTestnetsInMultichainResults,
          ...(isPwnSpace && pwnSpaceName && !isStarknet && { subdomain: pwnSpaceName }),
        }),
    ]))
    if (!error) {
      const [notificationsResponse, unseenNotificationsCountResponse] = results

      if (!notificationsResponse?.data?.next) {
        setAllNotificationsFetched()
      }

      hasViewAllNotifications.value = (notificationsResponse?.data?.results?.length ?? 0) > NOTIFICATIONS_IN_WALLET_MENU_AT_START

      const parsedNotifications = (notificationsResponse?.data?.results ?? []).map(Notification.createFromBackendResponse).filter(notification => !!notification)
      amountOfUnseenNotifications.value = unseenNotificationsCountResponse.data?.unseen_notifications_count
      for (const parsedNotification of parsedNotifications) {
        if (parsedNotification) {
          pushToNotificationsStore(parsedNotification)
        }
      }
    }

    setIsFetchingNotificationsForWalletMenu(false)
  }

  const loadAllUserNotifications = async (): Promise<void> => {
    setIsFetchingNotifications(true)

    const requestParams = {
      offset: notificationsFetchOffset.value,
      limit: 50,
      include_testnets: includeTestnetsInMultichainResults,
      ...(isPwnSpace && pwnSpaceName && { subdomain: pwnSpaceName }),
    } satisfies NotificationNotificationListParams

    let next: string | undefined
    do {
      const { address: recipientAddress } = useCustomAccount()
      const [error, response] = await to(notificationNotificationList(recipientAddress.value!, requestParams))
      if (error) break

      if (!compareAddresses(recipientAddress.value, useCustomAccount().address.value)) {
        break
      }

      next = response.data.next ?? undefined

      const parsedNotifications = (response.data.results ?? []).map(notif => Notification.createFromBackendResponse(notif)).filter(notification => !!notification)
      for (const parsedNotification of parsedNotifications) {
        if (parsedNotification) {
          useNotifications().pushToNotificationsStore(parsedNotification)
        }
      }

      if (!next) {
        setAllNotificationsFetched()
      } else {
        notificationsFetchOffset.value += requestParams.limit
        requestParams.offset += notificationsFetchOffset.value
      }
    } while (next)

    setIsFetchingNotifications(false)
  }

  return {
    loadAllUserNotifications,
    isFetchingNotifications: readonly(isFetchingNotifications),
    setIsFetchingNotifications,
    isFetchingNotificationsForWalletMenu: readonly(isFetchingNotificationsForWalletMenu),
    setIsFetchingNotificationsForWalletMenu,
    loadUserNotificationsForWalletMenu,
    notificationsFetchOffset: readonly(notificationsFetchOffset),
    resetNotificationsFetchOffset,
    isAllNotificationsFetched,
    hasViewAllNotifications: readonly(hasViewAllNotifications),
  }
}
