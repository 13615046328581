<template>
  <NotificationCenterSettingsSection title="General">
    <template #options>
      <NotificationCenterSettingsOption
        text="New proposals on PWN"
        tooltip-text="Alerts new loan requests posted &#10; on the PWN Platform"
        :is-recommended="false"
        :toggle-value="userNotificationSettings?.newLoanRequest"
        @toggle="toggleNewLoanRequests">
        <template #modal>
          <NewLoanRequestsModal/>
        </template>
      </NotificationCenterSettingsOption>
      <NotificationCenterSettingsOption
        text="Follow address"
        tooltip-text="Alerts for activity of different &#10; addresses on the PWN Platform"
        :is-recommended="false"
        :toggle-value="userNotificationSettings?.followUsers"
        @toggle="toggleFollowUsers">
        <template #modal>
          <FollowAddressesModal/>
        </template>
      </NotificationCenterSettingsOption>
    </template>
  </NotificationCenterSettingsSection>
</template>

<script setup lang="ts">
import NotificationCenterSettingsOption from '@/modules/pages/notification-center/settings/NotificationCenterSettingsOption.vue'
import NotificationCenterSettingsSection from '@/modules/pages/notification-center/settings/NotificationCenterSettingsSection.vue'
import NewLoanRequestsModal from '@/modules/pages/notification-center/settings/modals/NewLoanRequestsModal.vue'
import FollowAddressesModal from '@/modules/pages/notification-center/settings/modals/FollowAddressesModal.vue'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'

const { userNotificationSettings, toggleNewLoanRequests, toggleFollowUsers } = useNotificationCenterSettings()
</script>
