// Notifications related only to the FE events, that are not stored in BE database.
enum NotificationFrontendOnlyActionEnum {
  TX_CREATE_BUNDLE = 'TX_CREATE_BUNDLE',
  TX_UNWRAP_BUNDLE = 'TX_UNWRAP_BUNDLE',
  TX_CREATE_PWN_SAFE = 'TX_CREATE_PWN_SAFE',
  TX_UPDATE_PWN_SAFE = 'TX_UPDATE_PWN_SAFE',
  TX_TRANSFER_ASSET = 'TX_TRANSFER_ASSET',
  TX_MINT_ATR_TOKEN = 'TX_MINT_ATR_TOKEN',
  TX_BURN_ATR_TOKEN = 'TX_BURN_ATR_TOKEN',
  TX_CLAIM_ATR_TOKEN = 'TX_CLAIM_ATR_TOKEN',
  TX_REMOVE_APPROVAL = 'TX_REMOVE_APPROVAL',
  TOKEN_ADDED_TO_METAMASK = 'TOKEN_ADDED_TO_METAMASK',
  TX_WRAP_NATIVE_TOKEN = 'TX_WRAP_NATIVE_TOKEN',
  TX_UNWRAP_NATIVE_TOKEN = 'TX_UNWRAP_NATIVE_TOKEN',
  TX_APPROVE_ASSETS = 'TX_APPROVE_ASSETS',
  UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS',
  THESES_CREATED = 'THESES_CREATED',
  MULTI_PROPOSALS_CREATED = 'MULTI_PROPOSALS_CREATED',
}
export default NotificationFrontendOnlyActionEnum
