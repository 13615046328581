<template>
  <FeaturedCollateralTable v-if="isPwnSpace && store.isSearchEmpty"/>
  <BaseTable
    v-else
    v-model:active-sort-option="selectedSortOption"
    class="pwn-explorer-collections-tab"
    :columns="PWN_EXPLORER_COLLECTIONS"
    :is-fetching="loadingState.collections!"
    :variant="TableVariant.ExplorerCollections"
    :items="sortedResults || []"
    @on-row-click="goToCollectionOfferPage">
    <template #name="{ item }">
      <div class="pwn-explorer-collections-tab__name">
        <AssetImageWithChain
          :image="item.image"
          :chain-id="item.chainId"/>
        <SafeText
          class="pwn-explorer-collections-tab__name-text"
          :text="item.title"
          :allow-click-through="true"
          :tooltip-text="item.title"/>
        <VerifiedIcon
          v-if="item.isVerified"
          class="pwn-explorer-collections-tab__verified-icon"/>
      </div>
    </template>

    <template #tradingVolume="{ item: collection }">
      <BaseSkeletor
        v-if="pageStore.actions.isFetching(collection)"
        class="pwn-explorer-collections-tab__inline-loading"/>
      <EthAndUsdPriceLabel
        v-else-if="getCollectionStats(collection)?.pastWeekVolume"
        :decimals="2"
        :amount="getCollectionStats(collection)?.pastWeekVolume"/>
      <div v-else>
        ---
      </div>
    </template>

    <template #floor="{ item: collection }">
      <BaseSkeletor
        v-if="pageStore.actions.isFetching(collection)"
        class="pwn-explorer-collections-tab__inline-loading"/>
      <EthAndUsdPriceLabel
        v-else-if="getCollectionStats(collection)?.floorPrice"
        :decimals="2"
        :amount="getCollectionStats(collection)?.floorPrice"/>
      <div v-else>
        ---
      </div>
    </template>

    <template #marketCap="{ item: collection }">
      <BaseSkeletor
        v-if="pageStore.actions.isFetching(collection)"
        class="pwn-explorer-collections-tab__inline-loading"/>
      <EthAndUsdPriceLabel
        v-else-if="getCollectionStats(collection)?.marketCap"
        :decimals="2"
        :amount="getCollectionStats(collection)?.marketCap"/>
      <div v-else>
        ---
      </div>
    </template>

    <template #action="{ item: collection }">
      <BaseButton
        class="pwn-explorer-collections-tab__actions"
        :variant="ButtonVariant.Outline"
        button-text="Make Proposal"
        @click.stop="(event: MouseEvent) => goToCollectionOfferPage(collection, event, true)"/>
    </template>
  </BaseTable>
  <BaseEmptyStateText
    v-if="!loadingState.collections && !results.collections?.length && !store.isSearchEmpty"
    text="No collections matching search criteria"/>

  <SearchCategoryPaginationController :category="SearchCategory.COLLECTIONS"/>

  <div
    v-if="!isSearchEmpty"
    class="pwn-explorer-assets-tab-nft-section">
    <h2 class="title">
      NFTs
    </h2>

    <BaseTable
      v-model:active-sort-option="nftSelectedSortOption"
      class="pwn-explorer-assets-tab-nfts"
      :columns="PWN_EXPLORER_NFTS"
      :variant="TableVariant.ExplorerCollections"
      :is-fetching="loadingState.nfts!"
      :items="sortedNFTs || []"
      @on-row-click="navigate">
      <template #name="{ item }">
        <NftAssetTableRepresentation
          :title="item.title"
          :image="item.image"
          :chain-id="item.chainId"
          :is-verified="item.isVerified"
          :collection-name="item.collectionName"/>
      </template>

      <template #appraisal="{ item: nftAsset }">
        <BaseSkeletor
          v-if="assetsTabStore.actions.nftStatsAreFetching(nftAsset)"
          class="pwn-explorer-assets-tab-nfts__inline-loading"/>
        <EthAndUsdPriceLabel
          v-else-if="getNFTAssetPriceStats(nftAsset)?.appraisal"
          :decimals="2"
          :amount="getNFTAssetPriceStats(nftAsset)?.appraisal"
          :price-source="getNFTAssetPriceStats(nftAsset)?.appraisalSource!"/>
        <div v-else>
          ---
        </div>
      </template>

      <template #floor="{ item: nftAsset }">
        <BaseSkeletor
          v-if="assetsTabStore.actions.nftStatsAreFetching(nftAsset)"
          class="pwn-explorer-assets-tab-nfts__inline-loading"/>
        <EthAndUsdPriceLabel
          v-else-if="getNFTAssetPriceStats(nftAsset)?.floorPrice"
          :decimals="2"
          :amount="getNFTAssetPriceStats(nftAsset)?.floorPrice"
          :price-source="getNFTAssetPriceStats(nftAsset)?.floorPriceSource!"/>
        <div v-else>
          ---
        </div>
      </template>

      <template #action="{ item: nft }">
        <BaseButton
          class="pwn-explorer-assets-tab-nfts__actions"
          :variant="ButtonVariant.Outline"
          button-text="Make Proposal"
          @click.stop="(event: MouseEvent) => navigate(nft, event, true)"/>
      </template>
    </BaseTable>
    <BaseEmptyStateText
      v-if="!loadingState.nfts && !results.nfts?.length"
      text="No NFTs matching search criteria"/>

    <SearchCategoryPaginationController :category="SearchCategory.NFTS"/>
  </div>
</template>

<script lang="ts" setup>
import BaseTable from '@/general-components/BaseTable.vue'
import { PWN_EXPLORER_COLLECTIONS, PWN_EXPLORER_NFTS } from '@/modules/pages/pwn-explorer/tabs/TableDefinitions'
import { useSearchPwnExplorer } from '@/modules/common/pwn/explorer/useSearchStore'
import { storeToRefs } from 'pinia'
import BaseButton, { ButtonVariant } from '@/general-components/BaseButton.vue'
import TableVariant from '@/general-components/TableVariant'
import AssetImageWithChain from '@/modules/pages/pwn-explorer/tabs/AssetImageWithChain.vue'
import router from '@/router'
import { computed, onMounted, onUnmounted } from 'vue'
import type { CollectionSearchResult } from '@/modules/common/pwn/explorer/models/CollectionSearchResult'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import { useCollectionSearchStatsStore } from '@/modules/common/pwn/explorer/stats/useCollectionSearchStatsStore'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import {
  usePwnExplorerCollectionsTabStore,
} from '@/modules/common/pwn/explorer/tab-stores/usePwnExplorerCollectionsTabStore'
import SafeText from '@/general-components/SafeText.vue'
import VerifiedIcon from '@/general-components/icons/VerifiedIcon.vue'
import { SearchCategory } from '@/modules/common/pwn/explorer/models'
import SearchCategoryPaginationController
  from '@/modules/pages/pwn-explorer/tabs/SearchCategoryPaginationController.vue'
import FeaturedCollateralTable from '@/modules/pages/pwn-explorer/FeaturedCollateralTable.vue'
import NftAssetTableRepresentation from '@/modules/common/assets/components/NftAssetTableRepresentation.vue'
import { useNFTSearchPriceStatsStore } from '@/modules/common/pwn/explorer/stats/useNFTSearchPriceStatsStore'
import { usePwnExplorerAssetsTabStore } from '@/modules/common/pwn/explorer/tab-stores/usePwnExplorerAssetsTabStore'
import type { NFTAssetSearchResult } from '@/modules/common/pwn/explorer/models/NFTAssetSearchResult'
import { isPwnSpace, pwnSpaceConfig } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import RouteName from '@/router/RouteName'
import type { ERC20AssetDocumentBackendSchema } from '@/modules/common/backend/generated'

const store = useSearchPwnExplorer()
const statsStore = useCollectionSearchStatsStore()
const nftStatsStore = useNFTSearchPriceStatsStore()
const assetsTabStore = usePwnExplorerAssetsTabStore()
const { nftSelectedSortOption, sortedNFTs } = storeToRefs(assetsTabStore)
const pageStore = usePwnExplorerCollectionsTabStore()
const { loadingState, results, searchTerm } = storeToRefs(store)
const { selectedSortOption, sortedResults } = storeToRefs(pageStore)
const isSearchEmpty = computed(() => !searchTerm.value)

const navigate = (item: NFTAssetSearchResult | ERC20AssetDocumentBackendSchema, event: MouseEvent, isHighlight?: boolean) => {
  if ('pageRoute' in item) {
    if (event && (event.ctrlKey || event.metaKey)) {
      const resolvedRoute = router.resolve(item.pageRoute())
      window.open(resolvedRoute.href, '_blank')
      return
    }
    const pageRoute = item.pageRoute()
    // @ts-expect-error FIXME: Property 'params' does not exist on type 'RouteLocationRaw'.
    if (isHighlight) router.push({ name: RouteName.RevampNftPageLending, params: pageRoute.params, query: { highlight: 'true' } })
    else router.push(pageRoute)
  }
}
const getNFTAssetPriceStats = (asset: NFTAssetSearchResult) => {
  return nftStatsStore.actions.getNftPriceStats(asset)
}
const getCollectionStats = (collection: CollectionSearchResult) => {
  return statsStore.actions.getCollectionStats(collection)
}

const goToCollectionOfferPage = (collection: CollectionSearchResult, event: MouseEvent, isHighlighted?: boolean) => {
  if (event && (event.ctrlKey || event.metaKey)) {
    const resolvedRoute = router.resolve(collection.pageRoute())
    window.open(resolvedRoute.href, '_blank')
    return
  }
  if (isHighlighted) {
    // @ts-expect-error FIXME: Property 'params' does not exist on type 'RouteLocationRaw'.
    router.push({ name: collection.pageRoute().name, params: collection.pageRoute().params, query: { highlight: 'true' } })
  } else router.push(collection.pageRoute())
}

onMounted(() => {
  if (pwnSpaceConfig?.isErc20Only) {
    router.push({ name: RouteName.PwnExplorerPageAssets })
  }
  store.actions.setCategoriesToFetch([SearchCategory.COLLECTIONS, SearchCategory.NFTS])
  statsStore.actions.shouldFetch(true)
  nftStatsStore.actions.shouldFetch(true)
})

onUnmounted(() => {
  nftStatsStore.actions.shouldFetch(false)
  statsStore.actions.shouldFetch(false)
})

</script>

<style scoped>
.title {
  font-family: var(--font-family-screener);
  font-size: 1.875rem;
  font-weight: 550;
  line-height: 2.025rem;
  margin-bottom: 2rem;
}

.pwn-explorer-collections-tab {
  &__name {
    max-width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
  }

  &__name-text {
    margin-left: 1rem;
    font-size: 0.875rem;
    font-family: var(--font-family-oxygen-mono);
    color: var(--text-color);
    font-weight: 400;

    max-width: 80%;
    width: auto;
  }

  &__verified-icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }

  &__actions {
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5rem;
  }

  &__inline-loading {
    max-width: 4rem;
  }

  @media screen and (--mobile-viewport) {
    overflow: auto hidden;
  }
}

.pwn-explorer-assets-tab-nfts {
  &__name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__name-column {
    margin-left: 1rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.35rem;

    overflow: hidden;
    padding-right: 0.5rem;
  }

  &__name-text {
    font-size: 0.875rem;
    font-family: var(--font-family-oxygen-mono);
  }

  &__collection {
    display: flex;
    align-items: center;
  }

  &__collection-name-text {
    font-size: 0.75rem;
    font-family: var(--font-family-oxygen-mono);
  }

  &__collection-verified {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }

  &__actions {
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5rem;
  }

  &__inline-loading {
    max-width: 4rem;
  }
}

@media screen and (--mobile-viewport) {
  .pwn-explorer-assets-tab-nfts {
    overflow: auto hidden;
  }
}
</style>
