<template>
  <div class="nft-page-tabs-and-proposals__buttons">
    <BaseButton
      class="nft-page-tabs-and-proposals__button"
      is-full-width
      :font="ButtonFont.Screener"
      :size="ButtonSize.XL"
      :color="detailsTabColor"
      :variant="ButtonVariant.Outline"
      button-text="Details"
      @on-button-click="handleSetDetailsTab"/>
    <BaseButton
      class="nft-page-tabs-and-proposals__button"
      is-full-width
      :size="ButtonSize.XL"
      :variant="ButtonVariant.Outline"
      :color="offersTabColor"
      :font="ButtonFont.Screener"
      button-text="Proposals"
      @on-button-click="handleSetProposalTab"/>
  </div>
  <div
    v-if="asset && isProposalsTabSelected"
    class="nft-page-tabs-and-proposals__content-proposal">
    <div class="nft-page-tabs-and-proposals__content-proposal-left">
      <div class="nft-page-tabs-and-proposals__proposal-tabs">
        <BaseButton
          class="nft-page-tabs-and-proposals__proposal-tab"
          is-full-width
          :variant="ButtonVariant.Outline"
          :color="activeTab === Tabs.Lend ? ButtonColor.Primary : ButtonColor.White"
          :font="ButtonFont.Screener"
          button-text="LEND"
          @click="() => setActiveTab(Tabs.Lend)"/>
        <BaseButton
          class="nft-page-tabs-and-proposals__proposal-tab"
          is-full-width
          :font="ButtonFont.Screener"
          :color="activeTab === Tabs.Borrow ? ButtonColor.Primary : ButtonColor.White"
          :variant="ButtonVariant.Outline"
          button-text="BORROW"
          @click="() => setActiveTab(Tabs.Borrow)"/>
      </div>

      <div class="nft-page-tabs-and-proposals__filters">
        <div class="nft-page-tabs-and-proposals__filters-left">
          <div class="nft-page-tabs-and-proposals__filters-row">
            <ProposalFilters
              v-model:show-history="showHistory"
              is-read-only
              :selected-collateral="collateral"/>
          </div>
        </div>
      </div>

      <RouterView v-slot="{ Component, route }">
        <Transition name="move-to-left">
          <div :key="route.path">
            <component
              :is="Component"
              v-if="collateral?.address && collateral?.chainId"
              :selected-collateral-filter-assets="collateral ? [collateral] : undefined"
              include-collateral-without-price
              :include-credit-without-price="globalFiltersStore.showAssetsWithoutPricing"
              include-unverified-collateral
              :include-unverified-credit="globalFiltersStore.showUnverifiedAssets"
              :show-history="showHistory"
              :type="activeTab === Tabs.Borrow ? 'borrow' : 'lend'"/>
          </div>
        </Transition>
      </RouterView>
    </div>
    <ProposalForm
      v-if="!isStarknet"
      :has-proposal-type-tabs="false"
      is-collateral-locked
      :selected-collateral="collateral"
      :selected-credit="selectedCredit"
      :is-select-collection-asset="isActiveTabBorrow"
      :select-credit="selectCredit"
      :is-form-submit-disabled="isActiveTabBorrow && connectedWalletDoesNotHaveAsset"
      borrowing-is-enabled
      :is-active-tab-borrow="isActiveTabBorrow"/>
  </div>
</template>

<script lang="ts" setup>

import BaseButton, { ButtonColor, ButtonFont, ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import ProposalForm from '@/revamp/components/proposal-form/ProposalForm.vue'
import router from '@/router'
import RouteName from '@/router/RouteName'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { compareAddresses } from '@/utils/utils'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import { asyncComputed, useLocalStorage } from '@vueuse/core'
import { formatUnits } from 'viem'
import useNFTFetch from '@/modules/common/assets/fetchers/useNFTFetch'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useRoute } from 'vue-router'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import { useNoProposalsStore } from '@/revamp/hooks/useNoProposalsStore'
import ProposalFilters from '@/revamp/components/proposal-filters/ProposalFilters.vue'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

enum Tabs {
  Borrow = 'borrow',
  Lend = 'lend',
  Details = 'details'
}

const { asset, isProposalsTabSelected } = useAssetPage()
const { fetchUserERC1155Balance } = useNFTFetch()
const { address: userAddress } = useCustomAccount()
const route = useRoute()
const proposalFiltersStore = useProposalFilters()

const globalFiltersStore = useGlobalFiltersStore()

const activeTab = useLocalStorage<Tabs>('nftPageLastActiveTab', Tabs.Borrow)
const selectedCredit = ref<AssetWithAmount | null>(null)

const showHistory = useLocalStorage('show-history-on-nft-detail', false)

const collateral = asyncComputed(async () => {
  if (!asset.value) {
    return null
  }
  if (asset.value.isFungible && userAddress.value) {
    const userBalance = await fetchUserERC1155Balance(asset.value.chainId, asset.value.address, asset.value.tokenId, userAddress.value)
    return new AssetWithAmount({
      ...asset.value,
      amount: formatUnits(userBalance, asset.value.decimals),
    })
  }
  return new AssetWithAmount({
    ...asset.value,
    amount: asset.value?.isFungible ? '0' : '1',
  })
})

const offersTabColor = computed<ButtonColor>(() =>
  isProposalsTabSelected.value ? ButtonColor.Primary : ButtonColor.White,
)
const detailsTabColor = computed(() => (isProposalsTabSelected.value ? ButtonColor.White : ButtonColor.Primary))

const connectedWalletDoesNotHaveAsset = computed(() => {
  return !collateral.value?.isFungible && !compareAddresses(collateral.value?.ownerAddress, userAddress.value)
})

const selectCredit = (asset?: AssetWithAmount | AssetMetadata) => {
  selectedCredit.value = asset ? new AssetWithAmount(asset) : null
}

const setActiveTab = (tab: Tabs) => {
  activeTab.value = tab

  switch (tab) {
  case Tabs.Details:
    router.replace({
      name: RouteName.RevampNftPageDetails,
    })
    break
  case Tabs.Borrow:
    router.replace({
      name: RouteName.RevampNftPageBorrowing,
    })
    break
  case Tabs.Lend:
    router.replace({
      name: RouteName.RevampNftPageLending,
    })
    break
  }
}

const setLendTab = () => {
  setActiveTab(Tabs.Lend)
}

const handleSetProposalTab = () => {
  isProposalsTabSelected.value = true
  // TODO if owner set Borrow tab otherwise set Lend tab?
  setLendTab()
}

const handleSetDetailsTab = () => {
  isProposalsTabSelected.value = false
  setActiveTab(Tabs.Details)
}

const isActiveTabBorrow = computed(() => activeTab.value === Tabs.Borrow)

watch(() => route.params, () => {
  if (route.name === RouteName.NftPage) {
    isProposalsTabSelected.value = false
    setActiveTab(Tabs.Details)
  } else {
    setActiveTab(activeTab.value)
  }
}, { immediate: true })

watch(collateral, () => {
  if (collateral.value) {
    proposalFiltersStore.collateralAssetToFilter = collateral.value
  }
}, { immediate: true })

onUnmounted(() => {
  if (route.name !== RouteName.RevampNftPageBorrowing && route.name !== RouteName.RevampNftPageLending) {
    proposalFiltersStore.collateralAssetToFilter = null
  }
})

onMounted(() => {
  const isLendHighlighted = useRoute().query.highlight === 'true'
  if (isLendHighlighted) {
    useNoProposalsStore().handleHighlightForm()
  }
})

</script>

<style scoped>
.nft-page-tabs-and-proposals {
  &__buttons {
    display: flex;
    gap: 2rem;

    margin-bottom: 3.5rem;
  }

  &__button {
    flex-grow: 1;
  }

  &__content-proposal {
    display: flex;
    min-height: 25rem;
    padding-bottom: 4rem;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 1.2rem;
  }

  &__content-proposal-left {
    flex: 1;

    padding: 1rem;
    border: 1px solid #434343;
  }

  &__proposal-tabs {
    display: flex;
    gap: 2rem;

    margin-bottom: 3.5rem;
  }

  &__proposal-tab {
    width: 100%;
  }

  @media only screen and (--small-viewport) {
    &__buttons {
      margin-top: 0;
      display: flex;
      flex-flow: column;
    }

    &__button {
      flex-grow: 0;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }

  &__proposals-title {
    font-size: 1.125rem;
  }
}
</style>
