<template>
  <div
    class="collection-offers-page-description__container"
    :style="bannerImageUrl">
    <div class="collection-offers-page-description">
      <div class="collection-offers-page-description__description-row">
        <div class="collection-offers-page-description__image-wrap">
          <img
            class="collection-offers-page-description__image"
            :src="store.collection.image"
            alt="collection image">
        </div>
        <div class="collection-offers-page-description__description">
          <div class="collection-offers-page-description__title">
            <h1 class="collection-offers-page-description__title-text">
              {{ store?.collection.name }}
            </h1>
            <VerificationBadge :token="store.collection"/>
          </div>
          <div
            ref="descriptionText"
            :class="['collection-offers-page-description__description-text', { 'collection-offers-page-description__description-text--full-height': isFullHeight }]">
            {{ store.collection.description }}
          </div>
          <div
            v-if="isOverflowed"
            class="collection-offers-page-description__see-more"
            @click="toggleIsFullHeight">
            {{ isFullHeight ? 'See less' : 'See more' }}
            <ArrowDownIconSvg
              :class="[{ 'collection-offers-page-description__see-more-icon': isFullHeight }]"
              width="13"
              height="13"
              alt="arrow down icon"/>
          </div>
        </div>
        <div class="collection-offers-page-description__socials">
          <BaseLink
            v-for="social in collectionSocials"
            :key="social.name"
            :to="social.link"
            target="_blank">
            <div
              v-if="social.link"
              :class="['collection-offers-page-description__icon-container', `collection-offers-page-description__icon-${social.name.toLowerCase()}`]">
              <component
                :is="social.icon"
                color="white"/>
            </div>
          </BaseLink>
          <BaseLink
            v-if="store.collection?.openseaUrl"
            :to="store.collection?.openseaUrl"
            target="_blank">
            <div class="collection-offers-page-description__icon-container">
              <img
                class="collection-offers-page-description__icon"
                width="20"
                height="20"
                src="../../../assets/icons/opensea.svg?url"
                alt="link to collection on opensea">
            </div>
          </BaseLink>
        </div>
      </div>
      <div class="collection-offers-page-description__stats">
        <template v-if="!store.collection?.isTokenBundle">
          <AssetStatInfo
            label="Floor Price"
            :tooltip-max-width="450"
            tooltip-text="The floor price refers to the lowest-priced listing of an NFT within a collection">
            <div
              v-if="store?.collectionStats?.floorPrice"
              class="collection-offers-page-description__floor-price-data-source-container">
              <EthAndUsdPriceLabel
                :amount="store?.collectionStats?.floorPrice"
                :eth-icon-width-px="ASSET_STAT_INFO_ETH_ICON_WIDTH_PX"/>
              <DataSource
                v-if="collectionStatsDataSource"
                class="collection-offers-page-description__floor-price-data-source"
                :data-source="store?.collectionStats?.dataSource"
                :logo-width-px="ASSET_STAT_INFO_DATA_SOURCE_ICON_WIDTH_PX"
                :has-tooltip="true">
                <template #tooltipText>
                  <span>
                    Floor prices are provided by {{ collectionStatsDataSource.linkText }} <br>
                    <BaseLink
                      :to="collectionStatsDataSource.link"
                      target="_blank"
                      class="link link--primary link--font-oxygen-mono">
                      click here to learn more about {{ collectionStatsDataSource.linkText }}
                    </BaseLink>
                  </span>
                </template>
              </DataSource>
            </div>
            <div v-else>
              No Data
            </div>
          </AssetStatInfo>

          <AssetStatInfo
            label="Average appraisal"
            tooltip-text="Average appraisal of all NFTs in this collection">
            <div
              v-if="store?.collectionStats?.averageAppraisal"
              class="collection-offers-page-description__average-appraisal-data-source-container">
              <EthAndUsdPriceLabel
                :amount="store?.collectionStats?.averageAppraisal"
                :eth-icon-width-px="ASSET_STAT_INFO_ETH_ICON_WIDTH_PX"/>
              <DataSource
                v-if="collectionStatsDataSource"
                class="collection-offers-page-description__average-appraisal-data-source"
                :data-source="store?.collectionStats?.dataSource"
                :max-tooltip-width="430"
                :logo-width-px="ASSET_STAT_INFO_DATA_SOURCE_ICON_WIDTH_PX"
                has-tooltip>
                <template #tooltipText>
                  <span>
                    Average appraisal of all NFTs in this collection is provided by {{ collectionStatsDataSource.linkText }}
                    <br>
                    <BaseLink
                      :to="collectionStatsDataSource.link"
                      target="_blank"
                      class="link link--primary link--font-oxygen-mono">
                      click here to learn more about {{ collectionStatsDataSource.linkText }}
                    </BaseLink>
                  </span>
                </template>
              </DataSource>
            </div>
            <div v-else>
              No data
            </div>
          </AssetStatInfo>

        <!-- TODO display once we have something to show  -->
        <!-- <AssetStatInfo
          label="Highest Loan Offer"
          tooltip-text="Highest ETH value offer"/> -->

        <!-- TODO display once we have something to show -->
        <!-- <AssetStatInfo
          label="Total Loans"
          tooltip-text="The aggregate count of all loans, including both active and historical funded loans for this collection"
          :tooltip-max-width="580"
          :bottom-row-text="store?.collection.totalLoans"/> -->
        </template>
        <AssetStatInfo
          label="Assets"
          :bottom-row-text="store?.collection.totalNFTs"/>
        <!-- TODO add back after correct on BE -->
        <!-- <AssetStatInfo
          label="Holders"
          :bottom-row-text="store?.collection.totalOwners"/> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { Component } from 'vue'
import BaseLink from '@/general-components/BaseLink.vue'
import VerificationBadge from '@/general-components/VerificationBadge.vue'
import AssetStatInfo, { ASSET_STAT_INFO_DATA_SOURCE_ICON_WIDTH_PX, ASSET_STAT_INFO_ETH_ICON_WIDTH_PX } from '@/general-components/AssetStatInfo.vue'
import { useCollectionPageStore } from '@/revamp/pages/collection/useCollectionPageStore'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import DataSource from '@/general-components/data-source/DataSource.vue'
import TwitterIcon from '@/general-components/icons/TwitterIcon.vue'
import EtherscanIcon from '@/general-components/icons/EtherscanIcon.vue'
import TelegramIcon from '@/general-components/icons/TelegramIcon.vue'
import type { ValuationSourceDetail } from '@/general-components/data-source/AllValuationSources'
import allValuationSources from '@/general-components/data-source/AllValuationSources'
import ArrowDownIconSvg from '@/assets/icons/arrow-down.svg'
import DiscordIconSvg from '@/assets/icons/discord.svg'
import WebsiteIconSvg from '@/assets/icons/external-website.svg'

const store = useCollectionPageStore()

const collectionStatsDataSource = computed<ValuationSourceDetail | undefined>(() => {
  if (!store?.collectionStats?.dataSource) {
    return undefined
  }

  return allValuationSources[store.collectionStats.dataSource]
})

const descriptionText = ref(null)
const isOverflowed = computed(() => {
  if (!descriptionText.value) {
    return false
  }
  // Hacky way to check if the text is overflowed
  // @ts-expect-error FIXME: strictNullChecks
  return descriptionText.value.scrollHeight > descriptionText.value.clientHeight + 3
})
const isFullHeight = ref(false)
const bannerImageUrl = computed(() => (
  { '--background': `url(${store.collection.bannerImageUrl})` }
))

const socialMediaProperties = {
  discordUrl: {
    name: 'Discord',
    icon: DiscordIconSvg,
  },
  twitterUrl: {
    name: 'Twitter',
    icon: TwitterIcon,
  },
  websiteUrl: {
    name: 'Website',
    icon: WebsiteIconSvg,
  },
  etherscanUrl: {
    name: 'Etherscan',
    icon: EtherscanIcon,
  },
  telegramUrl: {
    name: 'Telegram',
    icon: TelegramIcon,
  },
}

type CollectionSocial = {
  name: string
  link: string
  icon: Component
}

const collectionSocials = computed(() => {
  const collectionSocialsArray: CollectionSocial[] = []

  for (const property in socialMediaProperties) {
    if (store.collection[property]) {
      collectionSocialsArray.push({
        name: socialMediaProperties[property].name,
        link: store.collection[property],
        icon: socialMediaProperties[property].icon,
      })
    }
  }
  return collectionSocialsArray
})

const toggleIsFullHeight = () => {
  isFullHeight.value = !isFullHeight.value
}
</script>

<style scoped>
.collection-offers-page-description {
  display: flex;
  flex-direction: column;

  &__container {
    position: relative;
    width: 100%;
    margin: auto;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      top: calc(-1* var(--space-40));
      left: calc(-50vw + 50%);
      width: 99.7vw;
      /* for 100vw some browsers show scrollbar */
      background-image: linear-gradient(to bottom, transparent 0%, transparent 70%, black 99%), var(--background);
      background-size: cover;
      background-position: center;
      opacity: 0.04;
      pointer-events: none;
      z-index: -1;
    }
  }

  &__see-more {
    width: 7rem;
    color: var(--primary-color-1);
    font-family: var(--font-family-oxygen-mono);
  }

  &__see-more:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &__see-more-icon {
    transform: rotate(180deg);
  }

  &__icon-container {
    padding: 0.6rem 0.7rem;
    border: 1px solid var(--gray-3);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.25rem;
  }

  &__socials {
    width: 21.25rem;
    height: 2.5rem;
    display: flex;
    direction: rtl;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__stats {
    margin: 3rem 0 2.5rem;
    display: flex;
    flex-direction: row;
    gap: 4rem;
  }

  &__image {
    width: 13.9375rem;
    height: 13.9375rem;
    object-fit: contain;
    margin-right: 1rem;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  &__title-text {
    font-size: 1.875rem;
    font-family: var(--font-family-autoscape);
    font-weight: 550;
    line-height: 2.375rem;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &__description-text {
    overflow-y: hidden;
    max-height: 5rem;
    max-width: 39.375rem;
    font-family: var(--font-family-oxygen-mono);
    transition: max-height 0.3s ease-in-out;
    font-size: 1.125rem;
    line-height: 1.625rem;

    &--full-height {
      max-height: 35rem;
    }
  }

  &__description-row {
    display: flex;
    flex-direction: row;
  }

  &__floor-price-data-source,
  &__average-appraisal-data-source {
    align-self: center;
  }

  &__floor-price-data-source-container,
  &__average-appraisal-data-source-container {
    display: flex;
    gap: 0.5rem;
  }

  @media only screen and (--small-viewport) {
    &__description-row {
      flex-flow: column;
    }

    &__socials {
      margin-top: 16px;
      direction: ltr;
      max-width: 100%;
    }

    &__image-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__stats {
      flex-direction: column;
    }

    &__image {
      margin: auto;
    }

    &__title {
      flex-flow: column;

      &-text {
        font-size: 1.25rem;
        text-align: center;
      }
    }
  }
}

.nouns-subdomain .collection-offers-page-description {
  &__icon {
    &-twitter svg {
      filter: invert(1);
    }

    &-etherscan svg {
      filter: invert(1);
    }
  }
}
</style>
