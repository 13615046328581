// block.timestamp on Ethereum is saved in seconds, but JS use milliseconds for timestamps
// if you work with dates and they are not working properly, make sure to check if you are using correct time units for timestamps

import { DateTime } from 'luxon'
import type { DurationUnit } from 'luxon'
import { includeTestnetsInMultichainResults } from '@/utils/url'
import { getBlock as getBlockWagmi } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { getStarknetTransport } from '@/modules/common/starknet/usePwnStarknetConfig'
import { SupportedChain } from '@/constants/chains/types'

export default abstract class DateUtils {
  // returned timezones:  https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  static UserTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
  static UserLocale: string = navigator.language ?? 'en-US'
  static DefaultDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  static displayDate(
    dateOrTimestampInMilliseconds: Date | number,
    formatOptions: Intl.DateTimeFormatOptions = this.DefaultDateTimeFormatOptions,
  ): string {
    return new Intl.DateTimeFormat(this.UserLocale, formatOptions).format(dateOrTimestampInMilliseconds)
  }

  static displayShortDate(date: Date | number, hasShortMonthName?: boolean): string {
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    const month = new Intl.DateTimeFormat('en', { month: hasShortMonthName ? 'short' : 'long' }).format(date)
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)

    const nthNumber = (number: number) => {
      if (number > 3 && number < 21) return 'th'
      switch (number % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
      }
    }

    return `${month} ${parseInt(day)}${nthNumber(Number(day))} ${year}`
  }

  static displayLongDate(date: Date | number): string {
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date)
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
    const time = new Intl.DateTimeFormat('en', { hour: '2-digit', minute: '2-digit', hour12: true }).format(date)

    const nthNumber = (number: number) => {
      if (number > 3 && number < 21) return 'th'
      switch (number % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
      }
    }

    return `${parseInt(day)}${nthNumber(Number(day))} ${month} ${year}, ${time}`
  }

  static getFutureDate(daysCount: number, startDateTimestampInMilliseconds: number | null = null): Date {
    if (!startDateTimestampInMilliseconds) {
      // default start date is current date
      startDateTimestampInMilliseconds = Date.now()
    }

    return new Date(startDateTimestampInMilliseconds + 1000 * 3600 * 24 * daysCount)
  }

  static getTimestampInSeconds(datetime: string | number | Date): number {
    return Math.round(new Date(datetime).getTime() / 1000)
  }

  static getNowTimestampInSeconds(): number {
    return this.getTimestampInSeconds(new Date())
  }

  static getDaysDifference(date1: Date, date2: Date): number | undefined {
    const daysDifference = DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2), 'days').toObject().days
    if (daysDifference === undefined) {
      return undefined
    }

    return Math.round(daysDifference)
  }

  static convertDaysToSeconds(daysCount: number): number {
    const SECONDS_IN_DAY = 86_400
    return daysCount * SECONDS_IN_DAY
  }

  static convertSecondsToDays(seconds: number): number {
    // TODO why is this here?
    const fractionDigits = includeTestnetsInMultichainResults ? 2 : 0
    const SECONDS_IN_DAY = 86400
    const fullDays = (seconds / SECONDS_IN_DAY).toFixed(fractionDigits)
    return Number(fullDays)
  }

  static convertSecondsToFullDays(seconds: number): number {
    const SECONDS_IN_DAY = 86400
    const fullDays = seconds / SECONDS_IN_DAY
    return Math.floor(Number(fullDays))
  }

  static getMinutesDifference(date1: Date, date2: Date): number {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    return Math.round(DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2), 'minutes').toObject().minutes)
  }

  static getFormattedTimeAgo(date: Date, isShort?: boolean): string {
    const now = DateTime.now()
    const then = DateTime.fromJSDate(date)
    const diff = now.diff(then)

    if (isShort) {
      if (diff.as('minutes') < 1) {
        return 'just now'
      } else if (Math.round(diff.as('minutes')) === 1) {
        return '1m ago'
      } else if (diff.as('minutes') < 60) {
        return `${Math.round(diff.as('minutes'))}m ago`
      } else if (Math.round(diff.as('hours')) === 1) {
        return '1h'
      } else if (diff.as('hours') < 24) {
        return `${Math.round(diff.as('hours'))}h ago`
      } else if (Math.round(diff.as('days')) === 1) {
        return '1d'
      } else {
        return `${Math.round(diff.as('days'))}d ago`
      }
    }

    if (diff.as('minutes') < 1) {
      return 'just now'
    } else if (Math.round(diff.as('minutes')) === 1) {
      return '1 Minute ago'
    } else if (diff.as('minutes') < 60) {
      return `${Math.round(diff.as('minutes'))} Minutes ago`
    } else if (Math.round(diff.as('hours')) === 1) {
      return '1 Hour ago'
    } else if (diff.as('hours') < 24) {
      return `${Math.round(diff.as('hours'))} Hours ago`
    } else if (Math.round(diff.as('days')) === 1) {
      return '1 Day ago'
    } else {
      return `${Math.round(diff.as('days'))} Days ago`
    }
  }

  static getExpirationRelativeDetailed(expirationDate: Date, smallestUnits: 'minutes' | 'seconds' = 'minutes'): string {
    const units: DurationUnit[] = [
      'days',
      'hours',
      'minutes',
      'seconds',
    ]

    const expirationTime = DateTime.fromJSDate(expirationDate)
    const isPast = expirationTime.diffNow().as('milliseconds') < 0
    if (isPast) return '0h'
    const expirationDuration = expirationTime.diffNow().shiftTo(...units)
    const highestUnit = units.find((unit) => expirationDuration.get(unit) !== 0) || 'seconds'
    const highestUnitIndex = Math.min(units.indexOf(highestUnit), 3)
    const highestUnitPair: DurationUnit[] = units.slice(highestUnitIndex)
    if (smallestUnits !== 'seconds') {
      highestUnitPair.pop()
    }
    const duration = expirationDuration.shiftTo(...highestUnitPair).toObject()

    const expirationString: string[] = []
    for (const property in duration) {
      expirationString.push(`${Math.floor(duration[property])}${property.charAt(0)}`)
    }

    return expirationString.join(' ')
  }

  static async getCurrentBlockTimestamp(): Promise<number> {
    if (isStarknet) {
      // todo: chain needs to be changed here
      const starknetProvider = getStarknetTransport(SupportedChain.StarknetSepolia)
      const block = await starknetProvider.getBlock('latest')
      return Number(block.timestamp)
    }
    const blockNumber = await getBlockWagmi(pwnWagmiConfig)

    return Number(blockNumber.timestamp)

    // const now = new Date()
    // // UTC time in seconds
    // return Math.floor(
    //   Date.UTC(
    //     now.getUTCFullYear(),
    //     now.getUTCMonth(),
    //     now.getUTCDate(),
    //     now.getUTCHours(),
    //     now.getUTCMinutes(),
    //     now.getUTCSeconds(),
    //   ) / 1000,
    // )
  }
}
