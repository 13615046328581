import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { watchDebounced } from '@vueuse/core'
import {
  assetTopCollectionsListExternal,
  assetTopTokensListExternal,
  pwnseaErc20AssetRetrieve,
  pwnseaNftAssetRetrieve,
  pwnseaNftCollectionRetrieve,
  pwnseaUserRetrieve,
} from '@/modules/common/backend/generated'
import to from '@/utils/await-to-js'
import { useRoute, useRouter } from 'vue-router'
import { SupportedChain } from '@/constants/chains/types'
import { CollectionSearchResult } from '@/modules/common/pwn/explorer/models/CollectionSearchResult'
import { NFTAssetSearchResult } from '@/modules/common/pwn/explorer/models/NFTAssetSearchResult'
import { ERC20AssetSearchResult } from '@/modules/common/pwn/explorer/models/ERC20AssetSearchResult'
import { ExplorerStores } from '@/modules/common/pwn/explorer/constants'
import { SearchCategory } from '@/modules/common/pwn/explorer/models'
import { useExplorerSearchPaginationStore } from '@/modules/common/pwn/explorer/useExplorerSearchPaginationStore'
import { includeTestnetsInMultichainResults } from '@/utils/url'
import { useChainId } from '@wagmi/vue'
import { isPwnSpace, pwnSpaceName } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { typeSafeObjectKeys } from '@/utils/typescriptWrappers'
import { WalletSearchResult } from '@/modules/common/pwn/explorer/models/WalletSearchResult'
import { useEnsStore } from '@/modules/common/web3/useEnsStore'
import { isAddress } from 'viem'
import { formatEthSuffix, isEns } from '@/utils/utils'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

export type SearchResults = {
    [SearchCategory.COLLECTIONS]: CollectionSearchResult[] | null
    [SearchCategory.NFTS]: NFTAssetSearchResult[] | null
    [SearchCategory.TOKENS]: ERC20AssetSearchResult[] | null
    [SearchCategory.WALLETS]: WalletSearchResult[] | null
}

export type ApiSearchResults = Record<SearchCategory, AbortController | null>

export type LoadingState = Record<SearchCategory, boolean | null>

const searchResultDefaultState: SearchResults = {
  [SearchCategory.COLLECTIONS]: null,
  [SearchCategory.NFTS]: null,
  [SearchCategory.TOKENS]: null,
  [SearchCategory.WALLETS]: null,
}

const defaultLoadingState: LoadingState = {
  [SearchCategory.COLLECTIONS]: null,
  [SearchCategory.NFTS]: null,
  [SearchCategory.TOKENS]: null,
  [SearchCategory.WALLETS]: null,
}

const defaultCurrentRequests: ApiSearchResults = {
  [SearchCategory.COLLECTIONS]: null,
  [SearchCategory.NFTS]: null,
  [SearchCategory.TOKENS]: null,
  [SearchCategory.WALLETS]: null,
}

const MAX_RESULTS = 4

type SearchStoreConfig = {
    /**
     * The maximum number of results to return for each category
     * @default 4
     */
    limit?: number

    /**
     * If true, search results contain only the results from provided chain, otherwise we don't pass
     * chain_id to a BE request
     * @default false
     */
    strictlyMatchChain?: boolean

    /**
     * If true, the currently connected chain is not considered
     * @default false
     */
    ignoreConnectedChain?: boolean

    /**
     * if true value of pwn/explorer/filters is going to be passed to made requests
     * @default false
     */
    useFilters?: boolean

    /**
     * Used to enable pagination
     * @default false
     */
    usePaginated?: boolean
}

const setUpSearchStore = (
  {
    limit = MAX_RESULTS,
    strictlyMatchChain = false,
    ignoreConnectedChain = false,
    useFilters = false,
    usePaginated = false,
  }: SearchStoreConfig = {},
) => {
  const route = useRoute()
  const router = useRouter()

  const globalFiltersStore = useGlobalFiltersStore()
  const { showVerifiedAssetsOnly, showUnverifiedAssets } = storeToRefs(globalFiltersStore)

  const searchTerm = ref<string>('')
  const results = ref<SearchResults>(searchResultDefaultState)
  const loadingState = ref<LoadingState>(defaultLoadingState)
  const wagmiChainId = useChainId()
  const selectedChainId = ref<SupportedChain | null>(ignoreConnectedChain ? null : wagmiChainId.value)
  const currentRequests = ref<ApiSearchResults>(defaultCurrentRequests)
  const categoriesToFetch = ref<SearchCategory[]>(Object.keys(searchResultDefaultState) as SearchCategory[])
  const isSearchEmpty = computed(() => searchTerm.value === '' || !searchTerm.value)

  const includeTestnets = computed(() => {
    // TODO is this correct?
    return !selectedChainId.value && includeTestnetsInMultichainResults
  })

  const lastFetchedFor = ref<{searchTerm: string | null; chainId: SupportedChain | null}>({
    searchTerm: null,
    chainId: null,
  })

  const pagination = useExplorerSearchPaginationStore()

  const isFetched = computed(() => {
    return Object.values(loadingState.value).some((value) => value === true)
  })

  const setSelectedChainId = (chainId: SupportedChain | null) => {
    selectedChainId.value = chainId
  }

  const setSearchTerm = async (newSearchTerm: string) => {
    searchTerm.value = newSearchTerm

    await router.replace({
      query: { q: newSearchTerm },
    })
  }

  const clearSearchTerm = () => {
    setSearchTerm('')
  }

  const setCategoriesToFetch = (value: SearchCategory[]) => {
    categoriesToFetch.value = value
  }

  const setIsFetching = (key: SearchCategory, value: boolean) => {
    loadingState.value = {
      ...loadingState.value,
      [key]: value,
    }
  }

  const setResultsKey = (key: SearchCategory, value: SearchResults[SearchCategory]) => {
    results.value = {
      ...results.value,
      [key]: value,
    }
  }

  const setCurrentRequestsKey = (key: SearchCategory, value: ApiSearchResults[SearchCategory]) => {
    currentRequests.value = {
      ...currentRequests.value,
      [key]: value,
    }
  }

  const cancelCurrentRequests = () => {
    for (const key of typeSafeObjectKeys(currentRequests.value)) {
      const abortController = currentRequests.value[key]
      if (abortController) {
        abortController.abort()
      }
    }
  }

  const fetchCollectionResults = async (searchTerm: string, chainId: SupportedChain | null) => {
    if (!searchTerm) {
      return
    }

    setIsFetching(SearchCategory.COLLECTIONS, true)
    const currentOffset = useExplorerSearchPaginationStore().categoriesPagination[SearchCategory.COLLECTIONS]?.currentOffset ?? 0

    const controller = new AbortController()
    setCurrentRequestsKey(SearchCategory.COLLECTIONS, controller)
    const [error, response] = await to(pwnseaNftCollectionRetrieve(
      searchTerm,
      {
        limit,
        offset: currentOffset,
        ...(showVerifiedAssetsOnly.value && { is_verified: true }),
        ...(isPwnSpace && pwnSpaceName && { subdomain: pwnSpaceName }),
        ...(strictlyMatchChain && chainId !== null && { chain_id: chainId }),
        ...(includeTestnets.value && { include_testnets: true }),
      },
      {
        signal: controller.signal,
      },
    ))

    if (!error) {
      const successResults: CollectionSearchResult[] = []

      if (usePaginated) {
        useExplorerSearchPaginationStore().actions.setCategoryPagination(SearchCategory.COLLECTIONS, {
          count: response.data.count ?? 0,
          currentOffset,
          limit,
        })
      }

      for (const collection of await Promise.allSettled(
        (response.data?.results ?? []).map(async (collection) => CollectionSearchResult.createFromBackendModel(collection)),
      )) {
        if (collection.status === 'fulfilled') {
          successResults.push(collection.value)
        }
      }

      setResultsKey(SearchCategory.COLLECTIONS, successResults)
    } else {
      setResultsKey(SearchCategory.COLLECTIONS, null)
    }
    setIsFetching(SearchCategory.COLLECTIONS, false)
  }

  const fetchCollectionResultsDefault = async (chainId: SupportedChain) => {
    setIsFetching(SearchCategory.COLLECTIONS, true)
    const controller = new AbortController()
    setCurrentRequestsKey(SearchCategory.COLLECTIONS, controller)
    const [error, response] = await to(assetTopCollectionsListExternal({
      chain_list: chainId ? [chainId] : [SupportedChain.Ethereum, SupportedChain.Polygon, SupportedChain.Bsc, SupportedChain.Arbitrum, SupportedChain.Base, SupportedChain.Optimism],
      ...(showVerifiedAssetsOnly.value && { is_verified: true }),
    }, { signal: controller.signal }))

    if (!error) {
      const successResults = []

      for (const res of response?.data.collections || []) {
        const collection = CollectionSearchResult.createFromNFTAssetCollectionDetailWithStats(res)
        // @ts-expect-error FIXME: strictNullChecks
        successResults.push(collection)
      }
      setResultsKey(SearchCategory.COLLECTIONS, successResults)
    } else {
      setResultsKey(SearchCategory.COLLECTIONS, null)
    }
    setIsFetching(SearchCategory.COLLECTIONS, false)
  }

  const fetchTokenResults = async (searchTerm: string, chainId: SupportedChain | null) => {
    setIsFetching(SearchCategory.TOKENS, true)
    const currentOffset = useExplorerSearchPaginationStore().categoriesPagination[SearchCategory.TOKENS]?.currentOffset ?? 0

    const controller = new AbortController()
    setCurrentRequestsKey(SearchCategory.TOKENS, controller)
    const [error, response] = await to(pwnseaErc20AssetRetrieve(
      searchTerm,
      {
        limit,
        offset: currentOffset,
        ...(isPwnSpace && pwnSpaceName && { subdomain: pwnSpaceName }),
        ...(showVerifiedAssetsOnly.value && { is_verified: true }),
        ...(strictlyMatchChain && { chain_id: chainId! }),
        ...(includeTestnets.value && { include_testnets: true }),
      }, { signal: controller.signal }))

    if (!error) {
      const successResults: ERC20AssetSearchResult[] = []

      if (usePaginated) {
        useExplorerSearchPaginationStore().actions.setCategoryPagination(SearchCategory.TOKENS, {
          count: response.data.count ?? 0,
          currentOffset,
          limit,
        })
      }

      for (const token of await Promise.allSettled(
        (response.data?.results ?? []).map(async (token) => ERC20AssetSearchResult.createFromBackendModel(token)),
      )) {
        if (token.status === 'fulfilled') {
          successResults.push(token.value)
        }
      }
      setResultsKey(SearchCategory.TOKENS, successResults)
    } else {
      setResultsKey(SearchCategory.TOKENS, null)
    }
    setIsFetching(SearchCategory.TOKENS, false)
  }

  const fetchTokenResultsDefault = async (chainId: SupportedChain) => {
    setIsFetching(SearchCategory.TOKENS, true)
    const controller = new AbortController()
    setCurrentRequestsKey(SearchCategory.TOKENS, controller)
    const [error, response] = await to(assetTopTokensListExternal({
      chain_list: chainId ? [chainId] : enabledChains,
      ...(showVerifiedAssetsOnly.value && { is_verified: true }),
    }, { signal: controller.signal }))

    if (!error) {
      const successResults: ERC20AssetSearchResult[] = []
      for (const result of response?.data ?? []) {
        const parsedToken = ERC20AssetSearchResult.createFromBackendModelDexGuru(result)
        successResults.push(parsedToken)
      }
      setResultsKey(SearchCategory.TOKENS, successResults)
    } else {
      setResultsKey(SearchCategory.TOKENS, null)
    }

    setIsFetching(SearchCategory.TOKENS, false)
  }

  const fetchNFTsResults = async (searchTerm: string, chainId: SupportedChain | null) => {
    setIsFetching(SearchCategory.NFTS, true)
    const currentOffset = useExplorerSearchPaginationStore().categoriesPagination[SearchCategory.NFTS]?.currentOffset ?? 0

    const controller = new AbortController()
    setCurrentRequestsKey(SearchCategory.NFTS, controller)
    const [error, response] = await to(pwnseaNftAssetRetrieve(
      searchTerm,
      {
        limit,
        offset: currentOffset,
        ...(isPwnSpace && pwnSpaceName && { subdomain: pwnSpaceName }),
        ...(showVerifiedAssetsOnly.value && { is_verified: true }),
        ...(strictlyMatchChain && { chain_id: chainId! }),
        // TODO what about the include_testnets value here?
        ...(includeTestnets.value && { include_testnets: true }),
      }, { signal: controller.signal }))

    if (!error) {
      const successResults: NFTAssetSearchResult[] = []

      if (usePaginated) {
        useExplorerSearchPaginationStore().actions.setCategoryPagination(SearchCategory.NFTS, {
          count: response.data.count ?? 0,
          currentOffset,
          limit,
        })
      }

      for (const nft of await Promise.allSettled(
        (response.data?.results ?? []).map(async (nft) => NFTAssetSearchResult.createFromBackendModel(nft)),
      )) {
        if (nft.status === 'fulfilled') {
          successResults.push(nft.value)
        }
      }
      setResultsKey(SearchCategory.NFTS, successResults)
    } else {
      setResultsKey(SearchCategory.NFTS, null)
    }
    setIsFetching(SearchCategory.NFTS, false)
  }

  const fetchWalletResults = async (searchTerm: string, chainId?: SupportedChain) => {
    const termIsAddress = isAddress(searchTerm)
    const formattedSearchTerm = !termIsAddress ? formatEthSuffix(searchTerm) : searchTerm
    setIsFetching(SearchCategory.WALLETS, true)
    const controller = new AbortController()
    setCurrentRequestsKey(SearchCategory.WALLETS, controller)

    const [error, response] = await to(pwnseaUserRetrieve(
      formattedSearchTerm, {
        limit,
      }, { signal: controller.signal }))

    if (!error) {
      // if the address is not presented in results we can always generate an entry for it
      if (termIsAddress) {
        const includesReq = response?.data.find((wallet) => wallet.wallet_address === formattedSearchTerm)

        if (!includesReq) {
          response.data.push({
            wallet_address: formattedSearchTerm, ens_name: formattedSearchTerm,
          })
        }
      } else if (isEns(formattedSearchTerm)) {
        const responseIncludesEns = response?.data.find((wallet) => wallet.ens_name === formattedSearchTerm)
        if (!responseIncludesEns) {
          const addressByEnsName = await useEnsStore().resolveEnsName(formattedSearchTerm)
          if (addressByEnsName) {
            response?.data.push({
              wallet_address: addressByEnsName, ens_name: formattedSearchTerm,
            })
          }
        }
      }

      const successResults: WalletSearchResult[] = []
      for (const wallet of await Promise.allSettled(
        response?.data.map(WalletSearchResult.createFromBackendModel),
      )) {
        if (wallet.status === 'fulfilled') {
          successResults.push(wallet.value)
        }
      }

      setResultsKey(SearchCategory.WALLETS, successResults)
    } else {
      setResultsKey(SearchCategory.WALLETS, null)
    }
    setIsFetching(SearchCategory.WALLETS, false)
  }

  const fetchMethods = {
    [SearchCategory.COLLECTIONS]: fetchCollectionResults,
    [SearchCategory.NFTS]: fetchNFTsResults,
    [SearchCategory.TOKENS]: fetchTokenResults,
    [SearchCategory.WALLETS]: fetchWalletResults,
  }

  const defaultFetchMethods = {
    [SearchCategory.COLLECTIONS]: fetchCollectionResultsDefault,
    [SearchCategory.TOKENS]: fetchTokenResultsDefault,
  }

  const fetchSearchResults = async (searchTerm: string, chainId: SupportedChain, categoriesToFetch?: SearchCategory[]) => {
    if (isSearchEmpty.value && !isPwnSpace) {
      await Promise.allSettled(
        Object.keys(defaultFetchMethods)
        // @ts-expect-error FIXME: strictNullChecks
          .filter((key) => categoriesToFetch.includes(key as SearchCategory))
          .map((key) => defaultFetchMethods[key](chainId)),
      )
      lastFetchedFor.value = {
        searchTerm,
        chainId,
      }
      return
    }

    await Promise.allSettled(
      Object.keys(fetchMethods)
      // @ts-expect-error FIXME: strictNullChecks
        .filter((key) => categoriesToFetch.includes(key as SearchCategory))
        .map((key) => fetchMethods[key](searchTerm, chainId, {})),
    )
    lastFetchedFor.value = {
      searchTerm,
      chainId,
    }
  }

  watchDebounced(
    [searchTerm, selectedChainId, showUnverifiedAssets, categoriesToFetch],
    async (
      [debouncedSearchTerm, chain, _, currentSearchFor],
    ) => {
      if (debouncedSearchTerm !== lastFetchedFor.value.searchTerm || chain !== lastFetchedFor.value.chainId) {
        cancelCurrentRequests()
        pagination.actions.resetPagination()
        loadingState.value = defaultLoadingState

        // clear results for everything except the current selection
        for (const key of Object.keys(results.value)) {
          if (!currentSearchFor.includes(key as SearchCategory)) {
            setResultsKey(key as SearchCategory, searchResultDefaultState[key as SearchCategory])
          }
        }
      } else if (currentSearchFor.some((v) => results.value[v]?.length)) {
        // Search conditions didn't change and we already have results for some of the categories
        return
      }

      await fetchSearchResults(debouncedSearchTerm, chain!, currentSearchFor)
    },
    { debounce: 500, maxWait: 1500, immediate: true },
  )

  watch(wagmiChainId, async (newWagmiChainId) => {
    if (ignoreConnectedChain) return
    selectedChainId.value = newWagmiChainId
    await fetchSearchResults(searchTerm.value, newWagmiChainId, categoriesToFetch.value)
  })

  watch(searchTerm, (value) => {
    if (value?.length === 0) {
      results.value = searchResultDefaultState
      loadingState.value = defaultLoadingState
      pagination.actions.resetPagination()
      lastFetchedFor.value = {
        searchTerm: null,
        chainId: null,
      }
      cancelCurrentRequests()
    }
  })

  watch(() => pagination.categoriesPagination, async (value, oldValue) => {
    if (usePaginated) {
      if (value?.tokens && value.tokens.currentOffset !== oldValue.tokens?.currentOffset) {
        await fetchMethods[SearchCategory.TOKENS](searchTerm.value, selectedChainId.value)
      }
      if (value?.nfts && value.nfts.currentOffset !== oldValue.nfts?.currentOffset) {
        await fetchMethods[SearchCategory.NFTS](searchTerm.value, selectedChainId.value)
      }
      if (value?.collections && value.collections.currentOffset !== oldValue.collections?.currentOffset) {
        await fetchMethods[SearchCategory.COLLECTIONS](searchTerm.value, selectedChainId.value)
      }
    }
  }, {
    deep: true,
  })

  watch(route, (currentValue, prevValue) => {
    if (currentValue.path !== prevValue.path) {
      clearSearchTerm()
    }
  })

  return {
    isSearchEmpty,
    searchTerm,
    isFetched,
    results,
    loadingState,
    actions: {
      clearSearchTerm,
      setSearchTerm,
      setSelectedChainId,
      setCategoriesToFetch,
    },
  }
}

export const useSearchStore = defineStore(ExplorerStores.NavbarSearch, () => setUpSearchStore({
  limit: 10, strictlyMatchChain: true, ignoreConnectedChain: true, useFilters: true, usePaginated: true,
}))
export const useSearchStoreHome = defineStore(ExplorerStores.HomepageSearch, () => setUpSearchStore())
export const useSearchPwnExplorer = defineStore(ExplorerStores.ExplorerPageSearch, () => setUpSearchStore({
  limit: 5, strictlyMatchChain: true, ignoreConnectedChain: true, useFilters: true, usePaginated: true,
}))
