<template>
  <div
    class="push-button-container"
    :style="{ width: props.buttonWidth }">
    <BaseButton
      :style="{ width: props.buttonWidth }"
      :color="ButtonColor.PrimaryDark"
      :variant="ButtonVariant.Outline"
      button-text="Push"
      is-full-width
      @on-button-click="emit('open')">
      <template #iconBeforeText>
        <PushLogoSvg/>
      </template>
    </BaseButton>
    <EarnRewardsSvg
      v-if="hasEarnRewardsBadge"
      class="push-button__earn-rewards"/>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import PushLogoSvg from '@/assets/icons/push-protocol.svg'
import EarnRewardsSvg from '@/assets/icons/earn-rewards.svg'

type Props = {
  buttonWidth?: string
  hasEarnRewardsBadge?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasEarnRewardsBadge: true,
})

const emit = defineEmits<{(e: 'open'): void}>()
</script>

<style scoped>
.push-button-container {
  position: relative;
  display: inline-block;
}

.push-button__earn-rewards {
  right: -1rem;
  position: absolute;
  top: -1.5rem;
}
</style>
