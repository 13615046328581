import { readonly } from 'vue'

type Environment = 'production' | 'development'

export const globalConstants = readonly({
  appVersion: __APP_VERSION__, // set in vite.config.js
  environment: import.meta.env.VITE_ENVIRONMENT as Environment,
  isSilentRelease: import.meta.env.VITE_ENVIRONMENT !== 'production',
  sentryDsn: import.meta.env.VITE_SENTRY_DSN as string,
  hotjarSiteId: import.meta.env.VITE_HOTJAR_SITE_ID ? Number(import.meta.env.VITE_HOTJAR_SITE_ID) : null,
  hotjarSnippetVersion: import.meta.env.VITE_HOTJAR_SNIPPET_VERSION ? Number(import.meta.env.VITE_HOTJAR_SNIPPET_VERSION) : null,
  txConfirmationsRequired: 1, // todo move somewhere else?
  farcasterFramesUrl: import.meta.env.VITE_FARCASTER_FRAMES_URL as string,
  backendUrl: import.meta.env.VITE_PWN_BACKEND_URL as string,
  backendStaticFilesUrl: `${import.meta.env.VITE_PWN_BACKEND_URL}/static`,
  defaultErc20Decimals: 0, // global constant that we are assigning in case we are missing a 'decimals' info for an erc20 asset
} as const)
