<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    heading="CryptoPunk Wrapping">
    <template #body>
      <figure
        v-if="openedCryptoPunk?.name"
        class="modal-crypto-punk-wrap__figure">
        <TokenMedia
          :token="openedCryptoPunk"
          width="40"
          height="40"
          class="modal-crypto-punk-wrap__image"/>
        <figcaption class="modal-crypto-punk-wrap__figcaption">
          <h3 class="modal-crypto-punk-wrap__name">
            {{ openedCryptoPunk.name }}
          </h3>
        </figcaption>
      </figure>
      <p class="modal-crypto-punk-wrap__text">
        CryptoPunks are not natively ERC-721 (NFT) compliant. In order to use your Punk as collateral you will need
        to "wrap" it as a standard NFT using a third party wrapping contract at:
        <BaseLink
          to="https://wrappedpunks.com"
          target="_blank"
          class="link--primary">
          wrappedpunks.com
        </BaseLink>.
      </p>
      <p class="modal-crypto-punk-wrap__text modal-crypto-punk-wrap__text--note">
        Note: It may take up to 30 minutes for your Wrapped Punk to appear on PWN.
      </p>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import useModalCryptoPunkWrap from '@/general-components/useModalCryptoPunkWrap'
import BaseLink from '@/general-components/BaseLink.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import ModalSize from '@/general-components/ModalSize'

const { isOpen, openedCryptoPunk } = useModalCryptoPunkWrap()
</script>

<style scoped>
.modal-crypto-punk-wrap {
  &__image {
    object-fit: contain;
  }

  &__figure {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 2.5rem 0 1.5rem;
  }

  &__name {
    font-size: 1.25rem;
    margin: 0.5rem 0 0;
  }

  &__text {
    color: var(--negative-1);
    line-height: 1.5rem;
    text-align: center;
    max-width: 70ch;
    margin: 0 auto;

    &--note {
      margin-top: 1.5rem;
      max-width: 75ch;
    }
  }
}
</style>
