import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as niftyFairIcon from '@/assets/icons/niftyfair.svg'
import * as gnosisWhite from '@/assets/icons/gnosis-white.svg'
import type { Address } from 'viem'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://gnosisscan.io/'

const GNOSIS_CONSTANTS: ChainConstants = {
  gnosisSafe: { chainName: 'gno', transactionServiceUrl: 'https://safe-transaction-gno.safe.global' },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x4188C513fd94B0458715287570c832d9560bc08a',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x431131622e088Fb0F9828Ca05b62210fc9eDcC04',
  topTokens: new TopTokens({
    'usdc.e': '0x2a22f9c3b484c3629090feed35f17ff8f88f76f0',
    usdc: '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
    usdt: '0x4ecaba5870353805a9f068101a40e0f32ed605c6',
    wxdai: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
  }),
  general: {
    chainId: SupportedChain.Gnosis,
    chainName: 'Gnosis',
  },
  nodeProvider: {
    httpNodeUrl: 'https://gnosis-mainnet.g.alchemy.com/v2/',
    wsNodeUrl: `wss://gnosis-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_GNOSIS_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_GNOSIS_ALCHEMY_NODE_KEY as string,
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'gnosisscan',
    blockExplorerIcon: gnosisWhite.default,
    nftDetailsLink: (tokenAddress, tokenId) => `https://niftyfair.io/gnosis/asset/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'niftyfair',
    nftExplorerIcon: niftyFairIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://niftyfair.io/gnosis/collection/${slug}`,
  },
}

export default GNOSIS_CONSTANTS
