<template>
  <BaseTooltip
    :max-width="maxWidth"
    class="question-mark-tooltip"
    :is-interactive="isInteractive"
    append-to-body
    :border-color="borderColor">
    <template #trigger>
      <component
        :is="questionmarkIcon"
        :style="{ width: iconSize, height: iconSize}"
        class="question-mark-tooltip__icon"
        :width="questionMarkSize"
        alt="info"/>
    </template>
    <template #default>
      <div>
        <div
          :class="['question-mark-tooltip__text', {'question-mark-tooltip__text--negative': isNegative}]"
          v-text="tooltipText"/>
        <slot/>
      </div>
    </template>
  </BaseTooltip>
</template>

<script lang="ts" setup>
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import { computed, toRefs } from 'vue'
import QuestionmarkDefault from '@/assets/icons/questionmark.svg'
import QuestionmarkOrange from '@/assets/icons/questionmark-orange.svg'
import QuestionmarkYellow from '@/assets/icons/questionmark-yellow.svg'

const props = withDefaults(defineProps<{
  tooltipText?: string
  isInteractive?: boolean
  iconSize?: string
  maxWidth?: number
  questionMarkSize?: number
  isNegative?: boolean
  isWarning?: boolean
}>(), {
  isInteractive: false,
  isNegative: false,
  questionMarkSize: 20,
  iconSize: '20px',
})
const { tooltipText, iconSize, maxWidth, isNegative } = toRefs(props)

const borderColor = computed(() => {
  return isNegative.value ? TooltipBorderColor.Orange : TooltipBorderColor.White
})

const questionmarkIcon = computed(() => {
  if (props.isWarning) {
    return QuestionmarkYellow
  }
  return isNegative.value ? QuestionmarkOrange : QuestionmarkDefault
})

</script>

<style>
.question-mark-tooltip {
  text-align: center;
  line-height: 0;
  font-family: var(--font-family-oxygen-mono);

  &__icon {
    cursor: help;
    line-height: 1.375rem;
    font-size: 0.75rem;
    color: var(--text-color);
  }

  &__text {
    text-align: center;
    white-space: pre-line;

    &--negative {
      color: var(--negative-1);
    }
  }
}
</style>
