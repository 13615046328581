<template>
  <div
    v-if="dataToDisplay.length > 0"
    class="theses-table">
    <div class="theses-table__title">
      <div
        v-if="!props.forUser"
        class="theses-table__title-text">
        Strategies
        <QuestionMarkTooltip
          class="theses-table__tooltip"
          tooltip-text="Strategies are simplified commitments to a wider variety of collateral assets with unified terms, using multiple sources of lending capital."/>
        <div
          v-if="isLoading"
          class="theses-table__loader-wrapper">
          <RefreshIconSvg
            width="16"
            height="16"
            :class="['theses-table__loader', { 'theses-table__loader--spinning': isLoading }]"
            alt="refresh icon"/>
        </div>
      </div>
      <div v-else>
        Your Commitments ({{ rows.length }})
      </div>
    </div>
    <div
      ref="tableContainerRef"
      class="container"
      style="overflow: auto; position: relative;"
      :style="{ maxHeight: '650px' }">
      <table style="display: grid;">
        <thead class="theses-table__header">
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
            :style="{
              display: 'flex',
              justifyContent: 'space-between',
            }">
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              :colspan="header.colSpan"
              :style="{ width: `${header.column.getSize()}px` }"
              :class="['theses-table__header-cell', {
                'theses-table__header-cell--sorted' : header.column.getIsSorted(),
                'theses-table__header-cell--ltv' : header.id === 'ltv',
                'theses-table__header-cell--apr' : header.id === 'apr',
              }]">
              <div
                v-if="!header.isPlaceholder"
                :class="[header.column.getCanSort() ? 'cursor-pointer select-none' : '', {
                  'theses-table__header-cell--sorted' : header.column.getIsSorted(),
                  'theses-table__header-cell--curator' : header.id === 'curator',
                }]"
                @click="getSortingHandler($event, header.column.getToggleSortingHandler())">
                <div
                  style="position: relative; width: fit-content; display: flex; align-items: center; white-space: nowrap;">
                  <FlexRender
                    :render="header.column.columnDef.header"
                    :props="header.getContext()"/>
                  <TableSortingIndicator
                    v-if="header.column.getCanSort() && header.id !== columnIds.collateral"
                    :value="header.column.getIsSorted() || 'none'"/>
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody
          ref="tableBodyRef"
          style="display: grid; height: 100%; position: relative;"
          :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
          <BaseSkeletor v-if="isLoading"/>
          <TransitionGroup name="theses-table--fade">
            <tr
              v-for="row in virtualRows"
              :key="row.index"
              :ref="customRefHandler"
              :data-index="row.index"
              :style="{
                display: 'flex',
                position: 'absolute',
                transform: `translateY(${row.start}px)`,
                width: '100%',
                justifyContent: 'space-between',
              }"
              @click="handleRowClick(rows[row.index].original)">
              <td
                v-for="cell in rows[row.index].getVisibleCells()"
                :key="cell.id"
                :class="['theses-table__cell', {
                  'theses-table__cell--ltv' : cell.column.id === 'ltv',
                }]"
                :style="{
                  width: `${cell.column.getSize()}px`,
                }">
                <FlexRender
                  :render="cell.column.columnDef.cell"
                  :props="cell.getContext()"/>
              </td>
            </tr>
          </TransitionGroup>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useThesisList } from '@/revamp/hooks/thesis/useThesisList'
import { createColumnHelper, FlexRender, getCoreRowModel, getSortedRowModel, useVueTable } from '@tanstack/vue-table'
import { computed, h, ref, unref } from 'vue'
import CollateralAssetsInfoCell from '@/revamp/components/tables/cells/thesis/CollateralAssetsInfoCell.vue'
import { useVirtualizer } from '@tanstack/vue-virtual'
import TableSortingIndicator from '@/revamp/components/tables/TableSortingIndicator.vue'
import LenderCommitFunds from '@/revamp/components/tables/cells/thesis/LenderCommitFunds.vue'
import RouteName from '@/router/RouteName'
import { useRouter } from 'vue-router'
import type { ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import ThesisCommitedFunds from '@/revamp/components/tables/cells/thesis/ThesisCommitedFunds.vue'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import ThesisCurator from '@/revamp/components/tables/cells/thesis/ThesisCurator.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { getThesisCollateralAssetsApr, getThesisCollateralAssetsLtv } from '@/revamp/hooks/thesis/utils'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import Networks from '@/revamp/components/Networks.vue'
import RefreshIconSvg from '@/assets/icons/refresh.svg'
import RewardsForThesis from '@/revamp/features/points/RewardsForThesis.vue'
import type { Address } from 'viem'
import type { EligibleRewardsInput } from '@/general-components/promo/rewardsRegistry'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { SupportedChain } from '@/constants/chains/types'

type Props = {
  forUser?: boolean
  hideCancelButton?: boolean
  onUpdate?: (thesisId: string) => void
}

const props = defineProps<Props>()
const { address: userAddress } = useCustomAccount()

const { theses, refetch, isLoading } = useThesisList(props.forUser ? userAddress : undefined)

const columnHelper = createColumnHelper<ThesisSchemaWorkaroundBackendSchema>()
const tableContainerRef = ref(null)

const columnIds = {
  collateral: 'collateralAssets',
  actions: 'actions',
}

const columns = [
  columnHelper.accessor('collateralAssets', {
    header: 'Collateral',
    id: columnIds.collateral,
    enableSorting: false,
    size: 250,
    cell: (props) => {
      return h(
        CollateralAssetsInfoCell, {
          collateralAssets: props.row.original?.collateralAssets || [],
          thesisTitle: props.row.original.title,
          thesisId: `Strategy #${props.row.original.id.padStart(4, '0')}`,
          iconWidth: '2rem',
          thesisDescription: props.row.original.description || '',
        },
      )
    },
  }),
  columnHelper.accessor('ltv', {
    header: 'Chains',
    id: 'chains',
    enableSorting: false,
    size: 50,
    cell: (props) => {
      const chains = [...new Set(props.row.original?.collateralAssets?.map((asset) => asset.chainId))]

      return h(
        Networks, {
          chains,
          class: 'theses-table__value theses-table__value--chains',
        },
      )
    },
  }),
  columnHelper.accessor('ltv', {
    header: 'LTV',
    id: 'ltv',
    sortingFn: (a, b) => {
      const aMaxLtv = Math.max(...(a.original.collateralAssets || []).map(v => Number(v.ltv)))
      const bMaxLtv = Math.max(...(b.original.collateralAssets || []).map(v => Number(v.ltv)))

      return aMaxLtv - bMaxLtv
    },
    size: 135,
    cell: (props) => {
      const ltv = getThesisCollateralAssetsLtv(props.row.original)

      return h(
        'span', {
          class: 'theses-table__value theses-table__value--ltv',
        }, ltv,
      )
    },
  }),
  columnHelper.accessor('aprMin', {
    header: 'APR',
    id: 'apr',
    sortingFn: (a, b) => {
      const aMaxApr = Math.max(...(a.original.collateralAssets || []).map(v => Number(v.apr))) || a.original.aprMin
      const bMaxApr = Math.max(...(b.original.collateralAssets || []).map(v => Number(v.apr))) || b.original.aprMin

      return aMaxApr - bMaxApr
    },
    size: 135,
    cell: (props) => {
      const apr = getThesisCollateralAssetsApr(props.row.original)
      const allAssets = [...(props.row.original.collateralAssets?.map(
        (asset) => ({
          chainId: asset.chainId,
          address: asset.address as Address,
          type: 'collateral',
        }),
      ) || []), ...(props.row.original.creditsStats || []).map(v => ({
        chainId: v.creditAssetMetadata.chainId,
        address: v.creditAssetMetadata.address as Address,
        type: 'credit',
      }))]

      return h(
        'div', {
          class: 'theses-table__value--apr-wrap',
        },
        [
          h('span', {
            class: 'theses-table__value theses-table__value--apr',
          }, apr),
          h(RewardsForThesis, {
            assetsForRewards: allAssets as EligibleRewardsInput[],
            alwaysShowTooltip: true,
            apr,
          }),
        ],
      )
    },
  }),
  columnHelper.accessor('loanDurationDays', {
    header: 'Duration',
    size: 55,
    cell: (props) => {
      return h(
        'span', {
          class: 'theses-table__value',
        }, `${props.row.original.loanDurationDays}d`,
      )
    },
  }),
  columnHelper.accessor('id', {
    header: 'Overall commited',
    sortingFn: 'alphanumericCaseSensitive',
    size: 140,
    cell: (props) => {
      return h(
        ThesisCommitedFunds, {
          stats: props.row.original.creditsStats,
          class: 'theses-table__value',
        },
      )
    },
  }),
  columnHelper.accessor('id', {
    header: 'Curator',
    id: 'curator',
    enableSorting: false,
    size: 90,
    cell: (props) => {
      return h(
        ThesisCurator, {
          curator: props.row.original.curator?.name || 'Community',
          curatorIcon: props.row.original.curator?.avatar || '',
          curatorDescription: props.row.original.curator?.description || '',
          class: 'theses-table__curator',
        },
      )
    },
  }),
  columnHelper.display({
    header: '',
    id: columnIds.actions,
    size: props.forUser ? 230 : 120,
    cell: (ctx) => {
      return h(
        LenderCommitFunds, {
          class: 'theses-table__value',
          thesis: ctx.row.original,
          forUser: props.forUser,
          hideCancelButton: props.hideCancelButton,
          onUpdate: (thesisId: string) => {
            refetch()
            props.onUpdate?.(thesisId)
          },
        },
      )
    },
  }),
]

const dataToDisplay = computed(() => {
  const data = props.forUser ? theses.value.filter((thesis) => thesis.creditsStats.some(v => v.amountsStats.totalCommittedAmount !== '0')) : theses.value
  // TODO : FIX This after mainnet starknet release
  return isStarknet ? data.filter((thesis) => thesis.collateralAssets![0].chainId === SupportedChain.StarknetSepolia) : data.filter((thesis) => thesis.collateralAssets![0].chainId !== SupportedChain.StarknetSepolia)
})

const table = useVueTable<ThesisSchemaWorkaroundBackendSchema>({
  get data() {
    return unref(dataToDisplay)
  },
  columns,
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rows.value.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 64,
    getTotalSize: unref(theses).length,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 20,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const getSortingHandler = (e: Event, fn: any) => {
  return fn(e)
}

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}
const router = useRouter()

const handleRowClick = (row: ThesisSchemaWorkaroundBackendSchema) => {
  router.push({
    name: RouteName.Thesis,
    params: {
      id: row.id,
    },
  })
}

</script>

<style scoped>
th {
  text-align: left;
  font-weight: 400;
}

.container {
  margin: 1rem auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-family: var(--font-family-oxygen-mono);
  size: 14px;
  table-layout: fixed;

  tbody {
    tr {
      padding: 1rem 0;
      border-bottom: 1px solid var(--separator-color);

      &:hover {
        background: var(--gray-3);
        cursor: pointer;
      }
    }
  }
}

.theses-table {
  &__loader-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__loader {
    &--spinning {
      animation: spinning 2s linear infinite;
    }
  }

  &__tooltip {
    margin-left: 0.5rem;
    margin-top: 0.125rem;
  }

  &__title {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    font-family: var(--font-family-screener);
    font-size: 1.25rem;
    font-weight: 400;
    width: 100%;
    background-color: var(--gray-3);

    &-text {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
    }
  }

  &__header {
    position: sticky;
    top: 0;

    background-color: var(--background-color);
    z-index: 1;

    border-bottom: 1px dashed #828282;
  }

  &__header-cell {
    position: relative;
    padding-bottom: 0.5rem;
    white-space: nowrap;
    cursor: pointer;
    font-family: var(--font-family-screener);
    font-size: 14px;
    color: var(--gray-2);

    &--sorted {
      color: var(--text-color);
    }

    &--ltv,
    &--apr {
      display: flex;
      justify-content: center;
    }

    &--apr {
      justify-content: flex-start;
    }

    &--curator {
      text-align: right;
      display: flex;
      justify-content: center;
    }
  }

  &__cell {
    &--ltv,
    &--apr {
      display: flex;
      justify-content: center;
    }
  }

  &__value--apr-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }

  &__value {
    height: 100%;
    display: inline-flex;
    align-items: center;
    padding-right: 0.5rem;
    width: 100%;
    justify-content: flex-end;

    &--chains {
      justify-content: center;
    }

    &--ltv {
      justify-content: center;
    }

    &--apr {
      justify-content: center;
    }
  }
}

</style>
