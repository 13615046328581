<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    class="table-sorting-control"
    :class="[props.value && 'table-sorting-control--' + props?.value]">
    <path
      d="M3.5 3L4.5 1H5H5.5L6.5 3V4.75H3.5V3Z"
      class="table-sorting-control__arrow table-sorting-control__arrow-top"/>
    <path
      d="M3.5 7.5L4.5 9.5H5H5.5L6.5 7.5V5.75H3.5V7.5Z"
      class="table-sorting-control__arrow table-sorting-control__arrow-bottom"/>
  </svg>
</template>

<script lang="ts" setup>
type Props = {
  value?: 'asc' | 'desc' | 'none';
};

const props = defineProps<Props>()
</script>

<style>
.table-sorting-control {
  --default-color: var(--gray-2);
  --accent-color: var(--primary-color-1);
  margin-left: 0.125rem;

  transform: scale(1.1);

  path {
    transition: fill 0.3s linear;
    fill: var(--default-color);
  }

  &--asc {
    .table-sorting-control__arrow-top {
      fill: var(--accent-color);
    }
  }

  &--desc {
    .table-sorting-control__arrow-bottom {
      fill: var(--accent-color);
    }
  }
}
</style>
