<template>
  <form
    style="width: 100%;"
    @submit="
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }">
    <div
      ref="tableContainerRef"
      class="user-bundle-selection__container">
      <table class="user-bundle-selection">
        <thead class="user-bundle-selection__head">
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
            class="user-bundle-selection__head-row">
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              :style="{ width: `${header.column.getSize()}px` }"
              class="user-bundle-selection__head-column"
              :colSpan="header.colSpan">
              <FlexRender
                v-if="!header.isPlaceholder"
                :render="header.column.columnDef.header"
                :props="header.getContext()"/>
            </th>
          </tr>
        </thead>
        <form.Field name="assets">
          <template #default>
            <tbody
              class="user-bundle-selection__body"
              style="display: grid; height: 100%; position: relative;"
              :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
              <tr
                v-for="row in virtualRows"
                :key="rows[row.index].id"
                :ref="customRefHandler"
                class="user-bundle-selection__row"
                :data-index="row.index"
                :style="{
                  display: 'flex',
                  position: 'absolute',
                  transform: `translateY(${row.start}px)`,
                  width: '100%',
                }">
                <td>
                  <BaseCheckbox
                    :model-value="rows[row.index].getIsSelected()"
                    @update:model-value="(value) => handleUpdateCheckbox(value, row.index)"/>
                </td>
                <td
                  v-for="cell in rows[row.index].getVisibleCells()"
                  :key="cell.id"
                  class="user-bundle-selection__column"
                  :style="{
                    width: `${cell.column.getSize()}px`,
                  }">
                  <FlexRender
                    :render="cell.column.columnDef.cell"
                    :props="cell.getContext()"/>
                </td>
              </tr>
              <BaseSkeletor v-if="props.isLoading"/>
            </tbody>
          </template>
        </form.Field>
      </table>
    </div>
    <div class="user-bundle-selection__footer">
      <div class="user-bundle-selection__full-value">
        Bundle Value:
        <span class="select-your-collateral__amount">
          {{ summarizedAmount.formatted }}
        </span>
      </div>

      <div class="user-bundle-selection__controls">
        <div
          class="link"
          @click="handleGoBack">
          back
        </div>
        <form.Subscribe>
          <template #default="{ canSubmit }">
            <BaseButton
              :is-disabled="!canSubmit"
              is-type-submit
              button-text="Use as collateral"/>
          </template>
        </form.Subscribe>
      </div>
      <div
        v-if="isInvalidAmount"
        class="user-bundle-selection__error">
        Some of the amounts are not correct.
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { FlexRender, getCoreRowModel, getFilteredRowModel, useVueTable } from '@tanstack/vue-table'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, h, nextTick, ref, toRefs, unref } from 'vue'
import AssetInfoCell from '@/revamp/components/tables/cells/assets/AssetInfoCell.vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import AssetAmountWithAppraisal from '@/revamp/components/tables/cells/assets/AssetAmountWithAppraisal.vue'
import { useVirtualizer } from '@tanstack/vue-virtual'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import useSelectAssetModal from '@/revamp/components/modals/select-your-collateral/useSelectAssetModal'
import SelectAmount from '@/revamp/components/modals/select-your-collateral/SelectAmount.vue'
import { useForm } from '@tanstack/vue-form'
import BaseButton from '@/general-components/BaseButton.vue'
import AssetType from '@/modules/common/assets/AssetType'
import { useAssetListPrice } from '@/revamp/hooks/asset-prices/useAssetListPrice'

type Props = {
  isLoading?: boolean
  selectedAssets: any
  handleGoBack: VoidFunction
  handleBundleButton: (assets: any) => void
};

const props = defineProps<Props>()
const tableContainerRef = ref(null)
const { selectedAssets: data } = toRefs(props)
const { selectedRows } = useSelectAssetModal()
const emit = defineEmits<{(e: 'handle-bundle', asset: AssetMetadata, isAdding: boolean)}>()
const isInvalidAmount = ref(false)

interface AssetToUpdate {
  rowData: AssetWithAmount;
  isAdded: boolean;
}
const assetsToUpdate = ref<AssetToUpdate[]>([])

const checkValidAmounts = (assets: AssetWithAmount[]) => {
  return assets.every(asset => {
    if (asset.category === AssetType.ERC1155 || asset.category === AssetType.ERC20) {
      return asset.isAssetAmountInputValid
    }
    return true
  })
}

const form = useForm({
  defaultValues: {
    assets: data.value,
  },
  onSubmit: async (value) => {
    for (const asset of assetsToUpdate.value) {
      emit('handle-bundle', asset.rowData, asset.isAdded)
    }
    // nextTick() is used so the assets are properly updated
    await nextTick()

    let updatedAssets = value.value.assets.map(asset => new AssetWithAmount(asset))
    updatedAssets = updatedAssets.filter(asset => data.value.some(selectedAsset => selectedAsset.id === asset.id))
    if (!checkValidAmounts(updatedAssets)) {
      isInvalidAmount.value = true
      return
    }
    isInvalidAmount.value = false
    props.handleBundleButton(updatedAssets)
  },
  // todo: add validators here instead of on submit
})

const table = useVueTable<AssetWithAmount>({
  get data() {
    return data.value
  },
  state: {
    get rowSelection() {
      return selectedRows.value
    },
  },
  getRowId: (row) => String(row.id),
  columns: [
    {
      header: `Assets (${data.value.length})`,
      id: 'name',
      accessorFn: (row) => row.name + ' ' + row.symbol,
      size: 450,
      cell: (ctx) => {
        return h(AssetInfoCell, {
          asset: ctx.row.original,
        })
      },
    },
    {
      header: 'You Own',
      accessorFn: (row) => {
        return row.amount
      },
      cell: (ctx) => {
        return h(AssetAmountWithAppraisal, {
          asset: ctx.row.original,
          amount: ctx.row.original.amount,
        })
      },
      size: 145,
    },
    {
      header: 'Select Amount',
      accessorFn: (row) => {
        return row.amount
      },
      cell: (ctx) => {
        if (ctx.row.original.isFungible) {
          return h(SelectAmount, {
            formKey: ctx.row.index,
            name: `assets[${ctx.row.index}].amountInput`,
            asset: ctx.row.original,
            form,
          })
        }
        return h(AssetAmountWithAppraisal, {
          asset: ctx.row.original,
          amount: ctx.row.original.amount,
        })
      },
      size: 160,
    },
  ],
  getCoreRowModel: getCoreRowModel(),
  enableMultiRowSelection: (row) => {
    return true
  },
  enableRowSelection(row) {
    return true
  },
  getFilteredRowModel: getFilteredRowModel(),
})

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rows.value.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 42,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

const handleUpdateCheckbox = (value: boolean, index: number) => {
  const row = rows.value[index]
  selectedRows.value = {
    ...selectedRows.value,
    [row.id]: value,
  }
  assetsToUpdate.value.push({ rowData: row.original, isAdded: value })

  row.toggleSelected(value)
}

const formState = form.useStore(v => v.values)
const formAssets = computed(() => formState.value.assets.filter(asset => unref(selectedRows)[asset.id]))

const { summarizedAmount } = useAssetListPrice(formAssets, undefined, false)

</script>

<style scoped>
.user-bundle-selection {
  display: grid;

  padding-right: 1rem;

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding-top: 1rem;
    border-top: 1px solid var(--gray-2);
  }

  &__form {
    width: 100%;
  }

  &__container {
    width: 100%;
    overflow: auto;
    position: relative;
    height: 400px;
  }

  &__head {
    position: sticky;
    top: 0;
    z-index: 5;

    display: flex;

    background: var(--gray-3);
    padding: 0 0.5rem;
  }

  &__head-row {
    width: 100%;
    padding: 0.5rem;
    display: flex;
  }

  &__head-column {
    font-family: var(--font-family-supreme);
    font-weight: 400;
    text-align: right;

    &:first-of-type {
      text-align: left;
      margin-left: 0;
    }

    &:nth-child(2) {
      padding-right: 1rem;
      margin-left: auto;
    }

    &:last-of-type {
      margin: 0;
      text-align: left;
    }
  }

  &__row {
    width: 100%;
    display: inline-flex;
    align-items: center;

    padding: 0.5rem 1rem;
  }

  &__column {
    display: flex;
    margin: auto;

    &:nth-of-type(2) {
      margin-left: 1rem;
    }

    &:nth-of-type(3) {
      justify-content: flex-end;
    }

    &:last-of-type {
      margin: 0;
      justify-content: flex-end;
    }
  }

  &__controls {
    display: flex;
    margin-top: 1rem;
    gap: 2rem;
    align-items: center;
  }

  &__error {
    color: var(--negative-1);
    margin-top: 1rem;
  }
}
</style>
