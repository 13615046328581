import { computed, watch } from 'vue'
import type { Ref } from 'vue'
import { ToastStepStatus, useToastsStore } from './useToastsStore'
import type { Toast } from './useToastsStore'
import to from '@/utils/await-to-js'

// note: if we would need, we can create something similar that is not dependent on a toast instance passed
export default function useActionFlow(toast: Ref<Toast>) {
  if (toast.value._initInActionFlow) {
    throw new Error('Every toast should be called in useActionFlow(toast as Ref<Toast>) just once.')
  }
  toast.value._initInActionFlow = true

  const toastsStore = useToastsStore()
  const _displayedToast = computed(() => toastsStore.getToast(toast.value?.id))
  const unwatcher = watch(_displayedToast, (newDisplayedToast) => {
    // TODO is this enough?
    if (!newDisplayedToast) {
      return
    }

    toast.value = newDisplayedToast
  })

  const continueFlow = async () => {
    const toastsStore = useToastsStore()
    let displayedToast = toastsStore.getToast(toast.value.id)
    if (!displayedToast) {
      toastsStore.displayToast(toast.value)
      displayedToast = toastsStore.getToast(toast.value.id)!
    }

    for (const step of displayedToast.steps.slice(displayedToast?.stepToPerform)) {
      // TODO what to do if the toast is dismissed in the meantime? it should not be possible though
      // to dismiss the toast when action is running
      step.status = ToastStepStatus.PENDING
      const [error, success] = await to(step.fn(step))
      if (error || !success) {
        step.status = ToastStepStatus.ERROR
        if (error) {
          step.error = error
          throw error
        } else {
          throw new Error(`Error while performing a step number ${displayedToast.stepToPerform} on toast ID ${displayedToast.id}.`)
        }
      } else {
        step.status = ToastStepStatus.SUCCESS
        if (displayedToast && displayedToast?.stepToPerform !== displayedToast?.steps.length - 1) {
          displayedToast.stepToPerform++
        }
      }
    }

    unwatcher()
    // @ts-expect-error FIXME: strictNullChecks
    toast.value = undefined
  }

  return {
    continueFlow,
  }
}
