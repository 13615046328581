<template>
  <BaseTooltip
    is-interactive
    append-to-body
    :class="{'calendar-helper--is-like-button': isLikeButton}"
    placement="center-start"
    :border-color="TooltipBorderColor.White">
    <template #trigger>
      <CalendarIconSvg
        width="20"
        alt="calendar"/>
      <span
        v-if="isLikeButton"
        class="calendar-helper__calendar-text">
        Calendar
      </span>
    </template>
    <template #default>
      <div class="calendar-helper__options">
        <div
          class="calendar-helper__option"
          @click="createGoogleCalendarEvent">
          <div class="calendar-helper__icon-container">
            <img
              width="20"
              alt="google-calendar"
              src="@/assets/icons/google-calendar.svg?url">
          </div>
          <SafeText text="Google Calendar"/>
        </div>
        <div
          class="calendar-helper__option"
          @click="createIcsFile">
          <div class="calendar-helper__icon-container">
            <SaveIconSvg
              width="20"
              alt="save-calendar"/>
          </div>
          <SafeText text=".ics file"/>
        </div>
      </div>
    </template>
  </BaseTooltip>
</template>

<script lang="ts" setup>
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import { toRefs } from 'vue'
import SafeText from '@/general-components/SafeText.vue'
import { saveAs } from 'file-saver'
import type { EventAttributes } from 'ics'
import * as ics from 'ics'
import * as Sentry from '@sentry/vue'
import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import linkTo from '@/constants/links'
import CalendarIconSvg from '@/assets/icons/calendar.svg'
import SaveIconSvg from '@/assets/icons/save.svg'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'

const props = defineProps<{ loan: BaseLoan | BaseProposal, isLikeButton?: boolean }>()
const { loan } = toRefs(props)

const unixLoanExpiration = loan.value.expiration ? loan.value.expiration * 1000 : 0
const ONE_HOUR_UNIX = 3600000
const PREFERRED_WARNING_TIME_IN_HOURS = 12
const eventStartDate = new Date(unixLoanExpiration - (ONE_HOUR_UNIX * PREFERRED_WARNING_TIME_IN_HOURS))
const eventEndDate = new Date(unixLoanExpiration)

const formatDateForGoogle = (date: Date) => {
  return date.toISOString().replace(/(-|:|\.)/g, '')
}

const createGoogleCalendarEvent = () => {
  const beginExpirationTime = formatDateForGoogle(eventStartDate)
  const expirationTime = formatDateForGoogle(eventEndDate)

  window.open(`https://calendar.google.com/calendar/u/0/r/eventedit?text=PWN+Loan+${loan.value.id}+Due&dates=${beginExpirationTime}/${expirationTime}&location=https%3A%2F%2Fapp.pwn.xyz%2F%23%2Floan%2F${loan.value.id}`)
}

const createIcsFile = () => {
  const currentDate = new Date(Date.now())

  const eventAttributes: EventAttributes = {
    created: [currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes()],
    start: [eventStartDate.getFullYear(), eventStartDate.getMonth() + 1, eventStartDate.getDate(), eventStartDate.getHours(), eventStartDate.getMinutes()],
    end: [eventEndDate.getFullYear(), eventEndDate.getMonth() + 1, eventEndDate.getDate(), eventEndDate.getHours(), eventEndDate.getMinutes()],
    title: `PWN Loan ${loan.value.id} Due`,
    description: `${linkTo.pwnAppProduction}/#/loan/${loan.value.id}`,
  }

  ics.createEvent(eventAttributes, (error, createdEventString) => {
    if (error) {
      Sentry.captureException(error)
      return
    }

    const createdEventStringAsBlob = new Blob([createdEventString], { type: 'text/plain;charset=utf-8' })
    saveAs(createdEventStringAsBlob, `PWN-Loan-${loan.value.id}.ics`)
  })
}
</script>

<style scoped>
.calendar-helper {
  &--is-like-button {
    border: solid 1px var(--primary-color-2);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 1rem;
    width: fit-content;
  }

  &__calendar-text {
    margin-left: 0.5rem;
    color: var(--primary-color-1);
  }

  &__options {
    cursor: pointer;
  }

  &__option {
    display: flex;
    padding-top: 0.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  &__option:hover {
    background-color: var(--gray-3);
  }

  &__icon-container {
    margin-right: 0.5rem;
  }
}
</style>
