<template>
  <div class="search">
    <img
      :style="{ height: props.iconSize, width: props.iconSize }"
      src="@/assets/icons/search_icon.svg?url"
      alt="Search icon"
      :class="['search__icon', { 'search__icon--homepage': props.isOnHomePage }]">
    <input
      :id="props?.id"
      v-model="searchTerm"
      :type="inputType"
      class="search__input"
      :placeholder="props.searchPlaceholder"
      :autofocus="autoFocus"
      @focusin="() => props?.setFocus?.(true)"
      @focusout="() => props?.setFocus?.(false)">
    <slot
      name="actions"
      :clear-search="clearSearch">
      <img
        v-if="props.hasClearButton"
        width="12"
        height="12"
        src="@/assets/icons/close.svg?url"
        alt="Search icon"
        class="search__close"
        @click="clearSearch">
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { isMobile } from '@/utils/mediaQueries'

interface Props {
  modelValue: string;
  searchPlaceholder?: string;
  hasClearButton?: boolean;
  setFocus?: (v: boolean) => void;
  iconSize?: string;
  autoFocus?: boolean;
  isOnHomePage?: boolean;
  onlyDigits?: boolean;
  id?: string;
}

const props = withDefaults(defineProps<Props>(), {
  searchPlaceholder: 'Search',
  hasClearButton: false,
  autoFocus: false,
  isOnHomePage: false,
  onlyDigits: false,
})

const inputType = computed(() => {
  return isMobile.value && props.onlyDigits ? 'number' : 'search'
})

const emit = defineEmits<{(e: 'update:modelValue', value: string): void }>()

const searchTerm = computed({
  get: (): string => props.modelValue,
  set: (value: string) => emit('update:modelValue', value),
})

const clearSearch = () => {
  emit('update:modelValue', '')
}
</script>

<style scoped>
.search {
  height: 100%;
  border-bottom: 1px solid var(--text-color);
  background-color: var(--input-color);
  display: flex;
  align-items: baseline;

  &__icon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;

    &--homepage {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  &__input {
    background: transparent;
    color: var(--text-color);
    border: none;
    padding: 0.25rem 1rem 0.25rem 0.5rem;
    flex: 1;

    &:focus {
      outline: none;
    }

    /* Disable native controls for the numbers input */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      appearance: textfield;
    }
  }

  &__close {
    margin-right: 1rem;
    cursor: pointer;
  }
}

input:placeholder-shown {
  width: 100%;
  text-overflow: ellipsis;
}
</style>
