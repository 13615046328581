import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import useAppInit from '@/modules/common/useAppInit'
import { VueQueryPlugin } from '@tanstack/vue-query'
import type { VueQueryPluginOptions } from '@tanstack/vue-query'
import Toastification from '@/plugins/toastification'
import { globalConstants } from '@/constants/globals'
import { createPinia } from 'pinia'
import { ENGINE_RPC_OPTS } from '@walletconnect/sign-client'
import { vMaska } from 'maska/vue'

// Import CSS files for external packages
import 'vue-skeletor/dist/vue-skeletor.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'

// Import CSS file with our custom global styles. Order matters here, since we also override some external packages
// styles in our base.css, so this way we ensure that the last imported styles will be applied.
import '@/styles/base.css'
import { WagmiPlugin, serialize } from '@wagmi/vue'
import { pwnWagmiConfig } from './modules/common/web3/usePwnWagmiConfig'
import { isStarknet } from './modules/common/pwnSpace/pwnSpaceDetail'
import Decimal from 'decimal.js'
// import { persistQueryClient } from '@tanstack/query-persist-client-core'
// import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
// importing our own base styles after external css files are imported, since we also override some external styles
// in our base.css

// overwrite for default walletconnect session request TTL expiry,
//  as atm there seems to be no easy way to pass a custom value
//  for the request expiry from wagmi when sending a tx or message
//  signing request...
//  https://github.com/WalletConnect/walletconnect-monorepo/pull/4159#issuecomment-2203487454
//  https://github.com/WalletConnect/walletconnect-monorepo/issues/4181
ENGINE_RPC_OPTS.wc_sessionRequest.req.ttl = 604800 // 7 days
ENGINE_RPC_OPTS.wc_sessionRequest.res.ttl = 604800 // 7 days

Decimal.config({ toExpPos: 9000000000000000 }) // make .toString() return normal (non-scientific) notation

// thisone

/* eslint-disable no-console */
console.info(`CURRENT ENVIRONMENT: ${globalConstants.environment}`)
console.info(`CURRENT VERSION: ${globalConstants.appVersion}`)
console.info(`BACKEND URL: ${globalConstants.backendUrl}`)
if (!globalConstants.appVersion) {
  console.error(`Please make sure you run dev server using yarn (e.g. 'yarn serve').
                  When you run the dev server directly without using yarn (e.g. with 'vite dev'),
                  the 'process.env.npm_package_version' is not set.`)
}
/* eslint-enable no-console */

const { initBeforeAppCreated, initBeforeAppMount } = useAppInit()

await initBeforeAppCreated()

let app = createApp(App)

const pinia = createPinia()

if (!isStarknet) {
  // TODO what about other options we can pass? reconnectOnMount & initialState
  app.use(WagmiPlugin, { config: pwnWagmiConfig })
}
// TODO what is correct order here? pinia before or after vue query?
app.use(pinia)

const vueQueryOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        queryKeyHashFn(queryKey) {
          // override the default JSON.stringify fn to allow also BigInt in the queryKey
          return serialize(queryKey)
        },
        gcTime: 1000 * 60 * 60 * 24,
        staleTime: 1000 * 60 * 60 * 24,
      },
    },
  },
  // clientPersister: (queryClient) => {
  //   return persistQueryClient({
  //     queryClient,
  //     persister: createSyncStoragePersister({ storage: localStorage }),
  //   })
  // },
}

app.use(VueQueryPlugin, vueQueryOptions)
app.use(router)
app.use(Toastification)
app.directive('maska', vMaska)

app = await initBeforeAppMount(app)

const rootContainerId = '#pwn-app-rocks'
app.mount(rootContainerId)
