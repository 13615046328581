<template>
  <PleaseConnectWallet
    message="Token Bundler"/>
  <div
    v-if="isConnected"
    class="unpack-bundle">
    <BreadcrumbsHeader
      :active-step="activeStep!"
      :set-active-step="setActiveStep"
      :steps="UNPACK_BUNDLE_STEPS_DEFINITION"/>
    <router-view/>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useUnpackBundle from '@/modules/pages/token-bundler/unpack-bundle/useUnpackBundle'
import BreadcrumbsHeader from '@/general-components/BreadcrumbsHeader.vue'
import UNPACK_BUNDLE_STEPS_DEFINITION from '@/modules/pages/token-bundler/unpack-bundle/unpackBundleStepsDefinition'
import PleaseConnectWallet from '@/general-components/PleaseConnectWallet.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const { initPage, setActiveStep, activeStep } = useUnpackBundle()
const { isConnected } = useCustomAccount()

const route = useRoute()
const router = useRouter()
initPage(route, router)

</script>

<style scoped>
.unpack-bundle {
  padding-bottom: 5rem;
}
</style>
