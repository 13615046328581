<template>
  <RevampBaseModal
    v-model:is-open="isOpen"
    :heading="headerText"
    class-name="select-their-collateral"
    heading-align="left">
    <template #trigger>
      <button
        v-if="withTrigger"
        class="select-their-collateral__token-select"
        tabindex="0"
        type="button"
        @click="isOpen = true">
        <span v-if="!isAssetSelected"> Select Asset </span>
        <span
          v-else-if="selectedToken"
          class="select-their-collateral__selected-asset-container">
          <span class="select-their-collateral__selected-asset">
            <TokenMedia
              :token="selectedToken"
              height="20"
              width="20"/>
            <SafeText :text="selectedToken.isSymbolMissing ? selectedToken.name : selectedToken.symbol"/>
          </span>
        </span>
        <ArrowSelectIcon/>
      </button>
    </template>
    <template #body>
      <div class="select-their-collateral__search">
        <SearchBar
          v-model="searchBar"
          class="select-their-collateral__search"
          placeholder="Select asset name or address"/>
      </div>

      <div class="select-their-collateral__filters-row">
        <ChainGroupFilter
          :show-all-networks="false"
          :disabled-chains="isEdit ? nonSelectedChains : undefined"
          :selected-chains="[selectedChain]"
          :set-chain="handleSetSelectedChain"/>
        <div class="select-their-collateral__currency-switcher-wrap">
          <CurrencySwitcher class="select-their-collateral__currency-switcher"/>
        </div>
      </div>

      <div class="select-their-collateral__tabs">
        <BaseButton
          is-full-width
          :class="[
            'select-their-collateral__tab',
            {
              'select-their-collateral__tab--active': isTabSelected(Tabs.ASSETS),
            },
          ]"
          :color="isTabSelected(Tabs.ASSETS) ? ButtonColor.Primary : ButtonColor.White"
          button-text="Tokens"
          style="width: 100%;"
          :variant="ButtonVariant.Outline"
          @on-button-click="() => setSelectedTab(Tabs.ASSETS)">
          <template #iconBeforeText>
            <TokenIcon class="select-their-collateral__tab-icon"/>
          </template>
        </BaseButton>
        <BaseButton
          is-full-width
          :class="[
            'select-their-collateral__tab',
            {
              'select-their-collateral__tab--active': isTabSelected(Tabs.NFTS),
            },
          ]"
          :color="isTabSelected(Tabs.NFTS) ? ButtonColor.Primary : ButtonColor.White"
          button-text="NFTs"
          style="width: 100%;"
          :variant="ButtonVariant.Outline"
          @on-button-click="() => setSelectedTab(Tabs.NFTS)">
          <template #iconBeforeText>
            <NFTIcon class="select-their-collateral__tab-icon"/>
          </template>
        </BaseButton>
      </div>

      <div class="select-their-collateral__content">
        <component
          :is="activeComponent"
          :chain-id="selectedChain"
          :q="throttledSearchBar"
          is-collateral-selection
          @select-asset="handleSelectedAsset"/>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import BaseButton, { ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import SafeText from '@/general-components/SafeText.vue'
import SearchBar from '@/revamp/components/SearchBar.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import CurrencySwitcher from '@/layout/header/CurrencySwitcher.vue'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import type { VNode } from 'vue'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import NFTsTable from '@/revamp/components/modals/select-their-collateral/NFTsTable.vue'
import { useLocalStorage, useThrottle } from '@vueuse/core'
import type { SupportedChain } from '@/constants/chains/types'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import TokensTable from '@/revamp/components/modals/select-their-collateral/TokensTable.vue'
import NFTIcon from '@/assets/icons/asset-offer.svg'
import TokenIcon from '@/assets/icons/token.svg'
import useSelectTheirCollateralModal from '@/revamp/components/modals/select-their-collateral/useSelectTheirCollateralModal'
import ArrowSelectIcon from '@/assets/icons/arrow-select.svg'

interface Props {
  selectedToken?: AssetMetadata;
  chainId?: SupportedChain;
  headerText?: string;
  isModalOpen?: boolean;
  withTrigger?: boolean;
  bundleImage?: VNode;
  isEdit?: boolean;
  hideYouOwnColumn?: boolean;
  isFungibleProposal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  withTrigger: true,
  headerText: 'Select Their Collateral Asset',
})

const emit = defineEmits<{(e: 'select-asset', asset: AssetMetadata) }>()

const { isOpen } = useSelectTheirCollateralModal()
const searchBar = ref('')

watch(
  () => props.isModalOpen,
  (newVal) => {
    isOpen.value = newVal
  },
)

const handleSelectedAsset = (asset?: AssetMetadata | unknown) => {
  if (!(asset instanceof AssetMetadata)) return

  if (asset.isErc20) {
    emit('select-asset', asset)
    isOpen.value = false
    return
  }
  const assetCollectionToEmit = new AssetMetadata({
    chainId: asset.chainId,
    address: asset.address,
    name: asset.collection?.name,
    image: asset.collection?.image,
    symbol: asset.collection?.symbol,
    collection: asset.collection,
    id: parseInt(asset.collection?.id) + Math.random() * -10000,
    category: asset.category,
  })
  emit('select-asset', assetCollectionToEmit)
  isOpen.value = false
}

const throttledSearchBar = useThrottle(searchBar, 300)

const selectedChainStore = useSelectedChainsStore()
const { selectedValues, nonSelectedChains } = storeToRefs(selectedChainStore)
// @ts-expect-error FIXME: strictNullChecks/
const selectedChain = useLocalStorage<SupportedChain>('$pwn-select-their-collateral-chain', selectedValues.value[0])
const handleSetSelectedChain = (chain: SetChainFilterOption) => {
  if (chain !== 'all') {
    selectedChain.value = chain
  }
}

const isAssetSelected = computed(() => !!props.selectedToken?.address)

enum Tabs {
  ASSETS = 'Assets',
  NFTS = 'NFTs',
}

const selectedTab = ref<Tabs>(Tabs.ASSETS)
const setSelectedTab = (tab: Tabs) => {
  selectedTab.value = tab
}
const isTabSelected = (tab: Tabs): boolean => selectedTab.value === tab

const componentsByTab = {
  [Tabs.ASSETS]: TokensTable,
  [Tabs.NFTS]: NFTsTable,
}

const activeComponent = computed(() => componentsByTab[selectedTab.value])
</script>

<style scoped>
.select-their-collateral {
  &__tabs {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    width: 100%;
  }

  &__tab {
    font-size: 1rem;

    &--active {
      .select-their-collateral__tab-icon * {
        fill: var(--primary-color-1);
      }
    }
  }

  &__tab-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  &__content {
    width: 100%;
  }

  &__token-select {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--background-color);
    height: 28px;
    width: 136px;
    font-size: 1rem;
    border: 1px solid var(--text-color);
    color: var(--text-color);

    user-select: none;

    &:hover {
      border: 1px solid var(--text-color);
    }
  }

  &__search {
    width: 100%;
    display: flex;
    gap: 1rem;

    background-color: var(--input-color);

    border-color: var(--gray);
  }

  &__search-bar {
    width: 100%;
  }

  &__selected-asset {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 10.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__selected-asset-container {
    display: contents;
  }

  &__filters-row {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
</style>

<style lang="postcss">
.select-their-collateral__currency-switcher-wrap {
  .multiselect__tags {
    padding: 0.5rem;
  }

  .multiselect__single {
    margin-bottom: 0;
  }

  &__tab {
    .button__text {
      font-size: 1rem;
    }

    &:hover {
      .button__text {
        color: var(--primary-color-1);
      }

      svg * {
        fill: var(--primary-color-1);
      }
    }
  }
}

.select-their-collateral {
  max-height: 723px !important;

  @media only screen and (--small-viewport) {
    max-height: 100% !important;
  }

  .modal__body {
    min-width: 944px;

    @media only screen and (--small-viewport) {
      min-width: 100%;
    }
  }
}
</style>

<style>
.select-their-collateral {
  .multiselect__single {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
