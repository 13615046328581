<template>
  <div v-if="!loading">
    <div
      v-if="!eligible && !claimed && !success"
      class="achievements-page__connect-wallet">
      <slot name="not-eligible"/>
    </div>
    <div
      v-if="eligible && !claimed && !success"
      class="achievements-page__connect-wallet">
      <h2 class=" achievements-page__heading">
        You are eligible to claim 1 rep
      </h2>
      <div class="achievements-page__address">
        {{ addressNameShort }}
      </div>
      <BaseButton
        class="achievements-page__connect-wallet-button"
        :font="ButtonFont.Screener"
        :color="ButtonColor.Primary"
        :size="ButtonSize.XXL"
        :button-text="isPending ? 'Claiming...' : 'Claim'"
        @on-button-click="handleClaimRepMutateAsync()"/>
    </div>
    <div
      v-if="claimed && !success"
      class="achievements-page__connect-wallet">
      <slot name="already-claimed"/>
    </div>
    <div
      v-if="success"
      class="achievements-page__connect-wallet">
      <div class="">
        <h2 class="achievements-page__heading">
          You claimed <span class="achievements-page__heading__highlight">1 rep</span>
        </h2>
      </div>
      <BaseButton
        class="achievements-page__connect-wallet-button"
        button-text="Share on"
        link-target="_blank"
        :font="ButtonFont.Screener"
        :color="ButtonColor.Primary"
        :size="ButtonSize.XXL"
        :to="twitterLink">
        <template #icon-after-text>
          <TwitterIconSvg
            class="achievements-page__reputation-score-icon"
            height="25"
            width="25"/>
        </template>
      </BaseButton>
      <GetMoreRep/>
      <LearnMore/>
    </div>
    <img
      src="@/assets/animations/points-spin.gif"
      class="points-spin"
      alt="Points Spin">
  </div>
  <div v-else>
    <div
      class="achievements-page__connect-wallet">
      <h2 class="achievements-page__heading">
        Checking Eligibility...
      </h2>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs, watch } from 'vue'
import BaseButton, { ButtonFont, ButtonColor, ButtonSize } from '@/general-components/BaseButton.vue'
import { useMutation } from '@tanstack/vue-query'
import { signEip191 } from '@/modules/common/web3/useSignatures'
import { getAccount, switchChain } from '@wagmi/core'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { claimReputationMessageRetrive, claimReputationSubmitMessage, useUserReputationScoreWithParametersRetrive } from '@/modules/common/backend/generated'
import { SupportedChain } from '@/constants/chains/types'
import { isAddressEqual, zeroAddress } from 'viem'
import type { Address } from 'viem'
import { useAddressProfile } from '@/modules/common/web3/useAddressProfile'
import LearnMore from './LearnMore.vue'
import GetMoreRep from './GetMoreRep.vue'
import TwitterIconSvg from '@/assets/icons/twitter.svg'

type Props = {
  address: Address
  parameterName: string
  eligible: boolean
  shareMessage: string
}

const props = defineProps<Props>()
const { address, parameterName, eligible, shareMessage } = toRefs(props)

const encodedLinkToThisPage = encodeURIComponent(window?.location.href)
const twitterLink = computed(() => `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage.value)}&url=${encodedLinkToThisPage}`)

const addressProfileData = useAddressProfile(address)
const addressProfile = computed(() => addressProfileData.data.value)
const addressNameShort = computed(() => {
  if (!address.value) return undefined
  if (addressProfile.value?.name) {
    return addressProfile.value.name
  } else if (isAddressEqual(address.value, zeroAddress)) {
    return 'NullAddress'
  } else {
    return address.value
  }
})

const success = ref(false)
const loading = ref(true)
const connectedUserRepQuery = useUserReputationScoreWithParametersRetrive(address.value, { refresh: false })

const handleClaimRep = async () => {
  const { address: userAddress, chainId: connectedChainId } = getAccount(pwnWagmiConfig)
  if (connectedChainId !== SupportedChain.Ethereum) {
    await switchChain(pwnWagmiConfig, { chainId: SupportedChain.Ethereum })
  }
  const messageToSign = await claimReputationMessageRetrive(userAddress!, parameterName.value)
  const { signature } = await signEip191({ message: messageToSign.data })
  await claimReputationSubmitMessage(userAddress!, SupportedChain.Ethereum, parameterName.value, signature)
}

const claimed = computed(() => {
  if (!address.value) return false
  const repItems = connectedUserRepQuery.data.value?.data.parameters
  if (!repItems) return false
  const userRepScoreItem = repItems.find(param => param.parameterName === parameterName.value)
  return !(userRepScoreItem!.multiplier === 0 && userRepScoreItem!.linear === 0 && userRepScoreItem!.base === 0)
})

const { isPending, mutateAsync: handleClaimRepMutateAsync } = useMutation({
  mutationKey: ['handleClaimRepMutateAsync'],
  mutationFn: async () => {
    await handleClaimRep()
  },
  onSuccess: (data, variables, context) => {
    success.value = true
  },
})

watch([eligible, claimed, addressProfile], ([newEligible, newClaimed, newAddressProfile]) => {
  if (newEligible !== undefined && newClaimed !== undefined && newAddressProfile !== undefined) {
    loading.value = false
  }
})
</script>

<style scoped>
.achievements-page {
  margin-top: 1rem;

  &__info {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #434343;
    width: 35rem;
    margin-top: 3rem;
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    padding: 2rem;

    &__highlight {
      color: var(--primary-color-1);
    }
  }

  &__connect-wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__connect-wallet-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  &__address {
    margin-top: 3rem;
    width: 35rem;
    font-family: var(--font-family-supreme);
    font-size: 1.2rem;
    text-align: center;
    padding: 1.5rem;
    border: 1px solid;
    border-color: #434343;
    background: linear-gradient(#1e1e1e 0%, #111 100%);
  }

  &__claim-more-rep {
    width: 35rem;
  }

  &__info-text {
    font-size: 1.2rem;
    text-align: center;
  }

  &__reputation-score-icon {
    margin-left: 0.4rem;
    margin-right: 0.5rem;
    fill: var(--primary-color-1);
  }
}

.points-spin {
  margin-top: 3rem;
  width: 100%;
  mix-blend-mode: lighten;
}

@media (max-width: 768px) {
  .achievements-page__connect-wallet-button,
  .achievements-page__address {
    width: 90%;
  }
}
</style>
