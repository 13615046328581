import type { SignMessageParameters, SignTypedDataParameters } from '@wagmi/vue/actions'
import { getAccount, signMessage, signTypedData, switchChain, watchContractEvent } from '@wagmi/vue/actions'
import { hashMessage, hashTypedData } from 'viem'
import type { HashTypedDataParameters, Hex, TypedData } from 'viem'
import { pwnWagmiConfig } from './usePwnWagmiConfig'
import { readSafeWalletCompatibilityFallbackHandlerGetMessageHash, safeWalletAbi } from '@/contracts/generated'
import type { SupportedChain } from '@/constants/chains/types'
import { useConnectedAccountTypeStore } from './useConnnectedAccountTypeStore'
import { useMultisigWalletModalStore } from '@/general-components/multisig-wallet/useMultisigWalletSignModalStore'

/* eslint-disable no-console */

const waitForSafeWalletOnchainSignature = async (chainId: SupportedChain, hash: Hex): Promise<Hex> => {
  const safeWalletAddress = getAccount(pwnWagmiConfig).address!
  const messageHash = await readSafeWalletCompatibilityFallbackHandlerGetMessageHash(pwnWagmiConfig, {
    address: safeWalletAddress,
    args: [hash],
    chainId,
  })

  console.log(`Original hash = ${hash}, safeMessageHash = ${messageHash}.`)
  console.log(`Listening for SignMsg event with safeMessageHash = ${messageHash} on connected SafeWallet on chain ID = ${chainId} and address ${safeWalletAddress}.`)

  return await new Promise<Hex>((resolve) => {
    const unwatch = watchContractEvent(pwnWagmiConfig, {
      // TODO we can probably merge safeWalletAbi and compat fallback handler abi together
      abi: safeWalletAbi,
      address: safeWalletAddress,
      eventName: 'SignMsg',
      chainId,
      onLogs(logs) {
        console.log(`Received SignMsg logs on safe wallet address = ${safeWalletAddress}:`)
        console.log(logs)
        const log = logs.find(_log => _log.args.msgHash === messageHash)
        if (log) {
          console.log('Found matching log with signed message. Resolving the waitForSafeWalletOnchainSignature with signature = 0x.')
          unwatch()
          resolve('0x')
        }
      },
    })
  })
}

export async function signEip712<
    const typedData extends TypedData | Record<string, unknown>,
    primaryType extends keyof typedData | 'EIP712Domain',
>(parameters: SignTypedDataParameters<typedData, primaryType>, _chainId?: SupportedChain): Promise<{ hash: Hex; signature: Hex; }> {
  console.log('Signing EIP712 typed data details:')
  console.log('Parameters passed to signTypedData:')
  console.log(parameters)

  const chainId = _chainId || parameters.domain?.chainId as SupportedChain
  const connectedChainId = getAccount(pwnWagmiConfig).chainId
  console.log(`connectedChainId=${connectedChainId}; eip712Chain.chainId=${chainId}`)

  if (connectedChainId !== chainId) {
    console.log(`Switching chain from ${connectedChainId} to ${chainId}.`)
    // TODO check the return value from switchChain to see if the user switched the chain correctly?
    await switchChain(pwnWagmiConfig, { chainId })
  }

  const hash = hashTypedData({
    domain: parameters.domain,
    message: parameters.message,
    primaryType: parameters.primaryType,
    types: parameters.types,
  } as HashTypedDataParameters<typedData, primaryType>)
  console.log(`Hash of the typed data: ${hash}.`)

  let signature: Hex
  if (useConnectedAccountTypeStore().isConnectedContractWallet) {
    const multisigWalletModalStore = useMultisigWalletModalStore()
    multisigWalletModalStore.isOpen = true

    signature = await Promise.any([
      signTypedData(pwnWagmiConfig, parameters),
      waitForSafeWalletOnchainSignature(chainId, hash),
    ])

    multisigWalletModalStore.isOpen = false
  } else {
    signature = await signTypedData(pwnWagmiConfig, parameters)
  }

  console.log(`Final signTypedData signature: ${signature}`)

  return {
    hash,
    signature,
  }
}

export async function signEip191(parameters: SignMessageParameters): Promise<{hash: Hex; signature: Hex;}> {
  console.log('Signing EIP191 message details:')
  console.log('Parameters passed to signEip191:')
  console.log(parameters)

  const hash = hashMessage(parameters.message)
  console.log(`Hash of the message to be signed: ${hash}`)

  let signature: Hex
  if (useConnectedAccountTypeStore().isConnectedContractWallet) {
    const multisigWalletModalStore = useMultisigWalletModalStore()
    multisigWalletModalStore.isOpen = true

    signature = await Promise.any([
      signMessage(pwnWagmiConfig, parameters),
      waitForSafeWalletOnchainSignature(getAccount(pwnWagmiConfig).chainId!, hash),
    ])

    multisigWalletModalStore.isOpen = false
  } else {
    signature = await signMessage(pwnWagmiConfig, parameters)
  }

  console.log(`Final signature of the message: ${signature}.`)

  return {
    hash,
    signature,
  }
}

/* eslint-enable no-console */
