<template>
  <BaseModal
    v-model:is-open="isOpen"
    :size="ModalSize.Large"
    :heading-icon="walletConnectIconBlueBg.default"
    heading="WalletConnect">
    <template #trigger>
      <BaseButton
        class="wc-modal-step-1__button"
        :color="ButtonColor.Primary"
        :variant="ButtonVariant.Outline"
        is-icon-before-text
        is-smaller-enlarge-effect
        button-text="WalletConnect"
        @on-button-click="handleOpenModal">
        <template #icon>
          <img
            class="wc-modal-step-1__wallet-connect-icon"
            src="@/assets/icons/wallet-connect.svg?url"
            alt="WalletConnect icon">
        </template>
      </BaseButton>
    </template>

    <template #body>
      <p class="wc-modal-step-1__text">
        PWN Safes are smart contract wallets which means you can connect them to PWN or any other application.
      </p>
      <p
        class="wc-modal-step-1__text">
        Doing this allows you to interact with those applications as your PWN Safe instead of your regular wallet, this is done through Safe™.
      </p>

      <div class="wc-modal-step-1__info-image-container">
        <img
          width="450"
          class="wc-modal-step-1__info-image"
          src="@/assets/images/pwn-safe-wallet-connect-a.png"
          alt="connect pwn safe with WalletConnect">
      </div>
      <div class="wc-modal-step-1__buttons-bottom">
        <BaseCheckbox
          :model-value="isDoNotShowAgain"
          label="Don't show this message again"
          input-name="doNotShowAgain"
          @update:model-value="handleOnSwitchDoNotShowAgain"/>

        <BaseButton
          :color="ButtonColor.Primary"
          button-text="Continue"
          @on-button-click="handleContinue"/>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import ModalSize from '@/general-components/ModalSize'
import * as walletConnectIconBlueBg from '@/assets/icons/wallet-connect-blue-bg.svg'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import { ref } from 'vue'

import usePwnSafeDashboard from '@/modules/pages/pwn-safe/pwn-safe-dashboard/usePwnSafeDashboard'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'

const isOpen = ref(false)
const isDoNotShowAgain = ref(false)
const LOCAL_STORAGE_SKIP_STEP_NAME = 'skip-pwn-safe-connect-wc-step-1'

const { isOpenWcModalStep2 } = usePwnSafeDashboard()

const handleOnSwitchDoNotShowAgain = (modelValue: boolean) => {
  isDoNotShowAgain.value = modelValue
  window.localStorage.setItem(LOCAL_STORAGE_SKIP_STEP_NAME, JSON.stringify(modelValue))
}

const handleOpenModal = () => {
  const skipStep1 = window.localStorage.getItem(LOCAL_STORAGE_SKIP_STEP_NAME)
  if (skipStep1 === 'true') {
    isOpenWcModalStep2.value = true
    return
  }
  isOpen.value = true
}

const handleContinue = () => {
  isOpenWcModalStep2.value = true
  isOpen.value = false
}

</script>

<style scoped>
.wc-modal-step-1 {
  &__text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5rem;
    font-size: 1rem;
  }

  &__wallet-connect-icon {
    width: 1.6875rem;
    height: 1rem;
  }

  &__buttons-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1rem;
  }

  &__info-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1.5625rem 0 2rem;
  }

  &__info-image {
    margin: auto;
  }

  @media only screen and (--mobile-viewport) {
    &__info-image {
      width: 100%;
    }

    &__buttons-bottom {
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>

<style>
.wc-modal-step-1__button .button {
  border-color: #3b99fc !important;
  color: #3b99fc !important;
}
</style>
