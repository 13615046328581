<template>
  <div class="rep-score-list">
    <div class="rep-score-list__list-items">
      <div
        v-for="(repScoreItem, index) in repScoreItems"
        :key="index"
        :class="['rep-score-list__row-item', { 'rep-score-list__row-item--completed': isCompleted(repScoreItem.parameterName)}]">
        <div class="rep-score-list__row-item-left-side">
          <component
            :is="repScoreItem.icon"
            width="48"
            height="48"
            :alt="repScoreItem.label"/>
          <label>
            {{ repScoreItem.label }}
          </label>
        </div>
        <div class="rep-score-list__row-item-right-side">
          <BaseLink
            v-if="repScoreItem.link"
            :to="repScoreItem.link"
            target="_blank">
            <BaseButton

              :size="ButtonSize.S"
              button-text="Follow PWN">
              <template #iconBeforeText>
                <component
                  :is="repScoreItem.linkIcon"
                  class="rep-score-list__link-icon"
                  width="20"
                  height="20"
                  :alt="repScoreItem.label"/>
              </template>

              <template #icon-after-text>
                <ExternalIconSvg
                  class="rep-score-list__external-link-icon"
                  width="16"
                  height="16"
                  alt="external link icon"/>
              </template>
            </BaseButton>
          </BaseLink>

          <div :class="['rep-score-list__row-item-right-side-text', {'rep-score-list__completed-rep-score-item':isCompleted(repScoreItem.parameterName)}]">
            <div v-if="isCompleted(repScoreItem.parameterName)">
              Unlocked!
            </div>
            <div :class="[{'rep-score-list__completed-rep-score-multiplier-and-points':isCompleted(repScoreItem.parameterName)}]">
              <span v-if="repScoreItem.base && isCompleted(repScoreItem.parameterName)">+{{ repScoreItem.base }}
                <span
                  v-if="+(repScoreItem.multiplier || 0)"
                  class="rep-score-list__separator"> |
                </span>
              </span>
              <span v-if="repScoreItem.base && !isCompleted(repScoreItem.parameterName) && connectedUserData?.totalMultiplier">+{{ (+repScoreItem.base * connectedUserData?.totalMultiplier).toFixed(3) }}
                <span
                  v-if="+(repScoreItem.multiplier || 0)"
                  class="rep-score-list__separator"> |
                </span>
              </span>
              <span v-if="repScoreItem.points">+{{ isCompleted(repScoreItem.parameterName) ? getRepScoreParameterPoints(repScoreItem.parameterName) : repScoreItem.points }}
                <span
                  v-if="+(repScoreItem.multiplier || 0)"
                  class="rep-score-list__separator"> |
                </span>
              </span>
              <span v-if="+(repScoreItem.multiplier || 0)">x{{ repScoreItem.multiplier }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useUserReputationScoreWithParametersRetrive } from '@/modules/common/backend/generated'
import type { UserReputationWithParametersResponse } from '@/revamp/types'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import ExternalIconSvg from '@/assets/icons/external-primary-color.svg'
import BaseButton, { ButtonSize } from '@/general-components/BaseButton.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import type { RepScoreItem } from '@/revamp/components/modals/achievements-and-boost/repScoreItems'

type Props = {
  repScoreItems: RepScoreItem[];
};
defineProps<Props>()

const { address: userAddress } = useCustomAccount()
const userAddressRef = computed(() => userAddress.value || '')
const connectedUserRepQuery = useUserReputationScoreWithParametersRetrive(userAddressRef, { refresh: true })

const connectedUserData = computed(() => {
  if (connectedUserRepQuery.data.value?.data) {
    return connectedUserRepQuery.data.value.data as UserReputationWithParametersResponse
  }
  return undefined
})

const isCompleted = (repScoreItemName: string) => {
  const userRepScoreItem = (connectedUserData.value as any).parameters.find(param => param.parameterName === repScoreItemName)
  if (userRepScoreItem) {
    return userRepScoreItem.multiplier !== 0 || userRepScoreItem.linear !== 0 || userRepScoreItem.base !== 0
  } return false
}

const getRepScoreParameterPoints = (repScoreItemName: string) => {
  const userRepScoreItem = (connectedUserData.value as any).parameters.find(param => param.parameterName === repScoreItemName)
  if (userRepScoreItem) {
    return (userRepScoreItem.base * userRepScoreItem.multiplier) + userRepScoreItem.linear
  }
}

</script>

<style scoped>
.rep-score-list {
  &__list-items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: auto;
    height: 500px;
  }

  &__row-item {
    display: flex;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    border: 1px solid #434343;
    box-shadow: 0 0 16px 0 #000;
    height: 4rem;

    &--completed {
      background: rgb(90 255 63 / 10%);
    }
  }

  &__row-item-left-side {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__row-item-right-side {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }

  &__external-link-icon {
    margin-left: 0.5rem;
  }

  &__link-icon {
    margin-right: 0.5rem;
  }

  &__row-item-right-side-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__completed-rep-score-item {
    color: #5aff3f;
    line-height: 1.5rem;
  }

  &__completed-rep-score-multiplier-and-points {
    font-weight: bold;
  }

  &__separator {
    font-weight: normal;
    margin: 0 0.25rem;
  }
}
</style>
