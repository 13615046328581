<template>
  <div class="calendar-notification__options">
    <BaseButton
      style="width: 18.5rem;"
      :color="ButtonColor.PrimaryDark"
      :variant="ButtonVariant.Outline"
      button-text="Google Calendar"
      is-full-width
      @on-button-click="createGoogleCalendarEvent">
      <template #iconBeforeText>
        <img
          width="20"
          alt="google-calendar"
          src="@/assets/icons/google-calendar.svg?url">
      </template>
    </BaseButton>
    <BaseButton
      style="width: 18.5rem;"
      :color="ButtonColor.PrimaryDark"
      :variant="ButtonVariant.Outline"
      button-text=".ics file"
      is-full-width
      @on-button-click="createIcsFile">
      <template #iconBeforeText>
        <SaveIconSvg
          width="20"
          alt="save-calendar"/>
      </template>
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { saveAs } from 'file-saver'
import type { EventAttributes } from 'ics'
import * as ics from 'ics'
import * as Sentry from '@sentry/vue'
import linkTo from '@/constants/links'
import SaveIconSvg from '@/assets/icons/save.svg'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'

interface Props {
  expiration?: number
  loanId: number | string
  chainName: string
  loanContractAddress: string
}
const props = defineProps<Props>()

const unixLoanExpiration = props.expiration ? props.expiration * 1000 : 0
const ONE_HOUR_UNIX = 3600000
const PREFERRED_WARNING_TIME_IN_HOURS = 12
const eventStartDate = new Date(unixLoanExpiration - (ONE_HOUR_UNIX * PREFERRED_WARNING_TIME_IN_HOURS))
const eventEndDate = new Date(unixLoanExpiration)

const formatDateForGoogle = (date: Date) => {
  return date.toISOString().replace(/(-|:|\.)/g, '')
}

const createGoogleCalendarEvent = () => {
  const beginExpirationTime = formatDateForGoogle(eventStartDate)
  const expirationTime = formatDateForGoogle(eventEndDate)

  window.open(`https://calendar.google.com/calendar/u/0/r/eventedit?text=PWN+Loan+${props.loanId}+Due&dates=${beginExpirationTime}/${expirationTime}&location=https%3A%2F%2Fapp.pwn.xyz%2F%23%2Floan%2F${props.chainName}%2F${props.loanContractAddress}%2F${props.loanId}`)
}

const createIcsFile = () => {
  const currentDate = new Date(Date.now())

  const eventAttributes: EventAttributes = {
    created: [currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes()],
    start: [eventStartDate.getFullYear(), eventStartDate.getMonth() + 1, eventStartDate.getDate(), eventStartDate.getHours(), eventStartDate.getMinutes()],
    end: [eventEndDate.getFullYear(), eventEndDate.getMonth() + 1, eventEndDate.getDate(), eventEndDate.getHours(), eventEndDate.getMinutes()],
    title: `PWN Loan ${props.loanId} Due`,
    description: `${linkTo.pwnAppProduction}/#/loan/${props.chainName}/${props.loanContractAddress}/${props.loanId}`,
  }

  ics.createEvent(eventAttributes, (error, createdEventString) => {
    if (error) {
      Sentry.captureException(error)
      return
    }

    const createdEventStringAsBlob = new Blob([createdEventString], { type: 'text/plain;charset=utf-8' })
    saveAs(createdEventStringAsBlob, `PWN-Loan-${props.loanId}.ics`)
  })
}
</script>

<style scoped>
.calendar-notification {
  &__calendar-text {
    margin-left: 0.5rem;
    color: var(--primary-color-1);
  }

  &__options {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__option {
    display: flex;
    padding-top: 0.5rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  &__option:hover {
    background-color: var(--gray-3);
  }

  &__icon-container {
    margin-right: 0.5rem;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  &__button-text {
    font-size: 14px;
    text-align: center;
    color: var(--text-color);
  }

  &__bell-icon {
    fill: var(--primary-color-1);
  }
}
</style>
