import type { ChainConstants, ChainWithLooksRareExplorer } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as etherscanImage from '@/assets/icons/etherscan.svg'
import * as openSeaIcon from '@/assets/icons/opensea.svg'
import type { Address } from 'viem'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://etherscan.io'

const ETHEREUM_CONSTANTS: ChainConstants<ChainWithLooksRareExplorer> = {
  general: {
    chainId: SupportedChain.Ethereum,
    chainName: 'Ethereum',
  },
  nodeProvider: {
    httpNodeUrl: 'https://eth-mainnet.alchemyapi.io/v2/',
    wsNodeUrl: `wss://eth-mainnet.alchemyapi.io/v2/${import.meta.env.VITE_MAINNET_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_MAINNET_ALCHEMY_NODE_KEY as string,
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'etherscan',
    blockExplorerIcon: etherscanImage.default,
    nftDetailsLink: (tokenAddress, tokenId) => `https://opensea.io/assets/ethereum/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: openSeaIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
    looksRareNftDetailsLink: (tokenAddress, tokenId) => `https://looksrare.org/collections/${tokenAddress}/${tokenId}`,
    nftOpenseaCollectionLink: (slug: string) => `https://opensea.io/collection/${slug}`,
  },
  pwnBetaContracts: {
    pwn: '0x0709b8e46e26b45d76CC5C744CAF5dE70a82578B',
    pwnLoan: '0xcFe385287200F0c10a54100e9b22855A73664156',
    pwnVault: '0xb98eFE56deCCeb1BeC9fAEeAF62500deb0953474',
  },
  deprecatedPwnV1Contracts: {
    pwnSimpleLoan: '0x50160ff9c19fbE2B5643449e1A321cAc15af2b2C',
    pwnSimpleLoanSimpleOffer: '0xAbA34804D2aDE17dd5064Ac7183e7929E4F940BD',
  },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x57c88D78f6D08b5c88b4A3b7BbB0C1AA34c3280A',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x19e3293196aee99BB3080f28B9D3b4ea7F232b8d',
  pwnSafeContracts: {
    assetTransferRightsGuardProxy: '0xc390f85B5286DBA62C4f1AEC3f451b4267d594DA',
    assetTransferRights: '0xb20a1745692e8312bd4a2A0092b887526e547F9D',
    pwnSafeFactory: '0x408F179dBB365D6601083fb8fF01ff0E1C66AE28',
  },
  topTokens: new TopTokens({
    dai: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    usdc: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    usdt: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    weth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  }),
  nftContractsAddresses: {
    cryptoPunks: '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB',
    chickenBonds: '0xa8384862219188a8f03c144953Cf21fc124029Ee',
    kyberSwapElastic: '0xe222fBE074A436145b255442D919E4E3A6c6a480',
  },
  gnosisSafe: {
    transactionServiceUrl: 'https://safe-transaction-mainnet.safe.global',
    chainName: 'eth',
  },
  aave: {
    uiPoolDataProvider: '0x5c5228aC8BC1528482514aF3e27E692495148717',
    poolAddressesProvider: '0x2f39d218133AFaB8F2B819B1066c7E434Ad94E9e',
    pool: '0x87870Bca3F3fD6335C3F4ce8392D69350B4fA4E2',
  },
  compound: {
    pools: ['0xc3d688B66703497DAA19211EEdff47f25384cdc3', '0xA17581A9E3356d9A858b789D68B4d866e593aE94', '0x3Afdc9BCA9213A35503b077a6072F3D0d5AB0840', '0x3D0bb1ccaB520A66e607822fC55BC921738fAFE3'],
  },
}
export default ETHEREUM_CONSTANTS
