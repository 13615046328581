<template>
  <component
    :is="mediaHtmlElement"
    v-bind="mediaElementProps"
    class="token-media"
    @error="fallbackTryVideoTagOrDisplayDefaultPlaceholderImage"/>
</template>

<script setup lang="ts">
import defaultErc20ImageUrl from '@/assets/images/default-erc20-logo-placeholder.svg?url'
import defaultNftImageUrl from '@/assets/images/default-nft-image-placeholder.svg?url'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import { computed, ref, toRefs } from 'vue'
import { isPwnAsset } from '@/utils/contractAddresses'
import { isAddress } from 'viem'
import { parseAddress } from '@/utils/address-utils'

interface Props {
  token: AssetMetadata | NFTAssetCollection;
  isLazyLoaded?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isLazyLoaded: true,
})

const { token, isLazyLoaded } = toRefs(props)

enum AllowedMediaHtmlElement {
  IMG = 'img',
  VIDEO = 'video',
}

const tokenContractAddress = computed(() => {
  return !(token.value instanceof AssetMetadata) ? token.value?.contractAddress : token.value.address
})

const fallbackToDefault = ref(false)
const tokenIsNft = computed(() => token.value instanceof NFTAssetCollection || token.value?.isNft)
const tokenDefaultImage = computed(() => (tokenIsNft.value ? defaultNftImageUrl : defaultErc20ImageUrl))

const shouldUseServerFallback = computed(() => {
  if (!isAddress(tokenContractAddress.value, { strict: false })) {
    return false
  }

  return isPwnAsset(token.value.chainId, parseAddress(tokenContractAddress.value))
})

const getIconByAssetImageUrl = (
  path?: string,
): string => {
  if (!shouldUseServerFallback.value || fallbackToDefault.value) {
    if (path?.includes('default_erc20_asset_image.svg') || (!path && !tokenIsNft.value)) {
      return defaultErc20ImageUrl
    }
    if (path?.includes('default_nft_asset_image.svg') || (!path && tokenIsNft.value)) {
      return defaultNftImageUrl
    }
  }

  return path!
}

const tokenValue = computed(() => getIconByAssetImageUrl(token.value.image))

const imageElementProps = computed(() => ({
  loading: isLazyLoaded.value ? 'lazy' : 'eager',
  src: fallbackToDefault.value ? tokenDefaultImage.value : tokenValue.value,
  alt: token.value?.name,
}))

const videoElementProps = computed(() => ({
  autoplay: true,
  loop: true,
  muted: true,
  src: token.value?.image,
}))
const mediaHtmlElement = ref<AllowedMediaHtmlElement>(AllowedMediaHtmlElement.IMG)
const mediaElementProps = computed(() => {
  if (tokenDefaultImage.value === token.value?.image) {
    return imageElementProps.value
  }
  if (mediaHtmlElement.value === AllowedMediaHtmlElement.IMG) {
    return imageElementProps.value
  }
  return videoElementProps.value
})

const fallbackTryVideoTagOrDisplayDefaultPlaceholderImage = () => {
  if (!fallbackToDefault.value) {
    if (mediaHtmlElement.value === AllowedMediaHtmlElement.IMG) {
      // the <img> throws error, first try if it is not possible to display media using `<video>` tag (e.g. .mov file)
      mediaHtmlElement.value = AllowedMediaHtmlElement.VIDEO
    } else if (mediaHtmlElement.value === AllowedMediaHtmlElement.VIDEO) {
      // if we already tried also <video> tag that also failed, just fallback to <img> tag and default token images
      mediaHtmlElement.value = AllowedMediaHtmlElement.IMG
      fallbackToDefault.value = true
    }
  }
}
</script>

<style scoped>
.token-media {
  object-fit: contain;
}
</style>
