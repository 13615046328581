import StoreIds from '@/constants/storeIds'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { AssetWithAmount, AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { useLocalStorage } from '@vueuse/core'
import type { RemovableRef } from '@vueuse/core'

export const useModalKycStore = defineStore(StoreIds.modalKyc, () => {
  const skippedContracts : RemovableRef<Array<string>> = useLocalStorage('skipped-contracts', [])
  const skipModal = ref(false)
  const isOpen = ref(false)
  const asset = ref<AssetWithAmount | AssetMetadata>()
  const submitAction = ref<() => Promise<never>>()

  const handleSkipModal = () => {
    if (skipModal.value) {
      skippedContracts.value.push(asset.value!.address)
      skipModal.value = false
    }
  }

  const shouldSkipModal = (collateral: AssetWithAmount | AssetMetadata) => {
    return skippedContracts.value.includes(collateral.address) || skippedContracts.value.includes(collateral?.collection?.contractAddress)
  }

  const setModalAsset = (collateral: AssetWithAmount | AssetMetadata) => {
    asset.value = collateral
  }
  const setSkipModal = () => {
    skipModal.value = !skipModal.value
  }

  return {
    skipModal,
    isOpen,
    setModalAsset,
    setSkipModal,
    asset,
    submitAction,
    skippedContracts,
    shouldSkipModal,
    handleSkipModal,
  }
})
