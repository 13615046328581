import type { ColumnProperties } from '@/general-components/ColumnProperties'
import { sortNotificationsByTime } from '@/general-components/sorting/SortFunctions'

export enum SortOption {
  Time = 'time',
}

export const SORT_OPTIONS_LOOKUP = {
  [SortOption.Time]: sortNotificationsByTime,
}

export const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Time',
    width: '14%',
    slotName: 'time',
    sortOptionName: SortOption.Time,
  },
  {
    title: 'Notification Info',
    width: '25%',
    slotName: 'id',
  },
  {
    title: '',
    width: '35%',
    slotName: 'type',
  },
  {
    title: 'Chain',
    width: '12%',
    slotName: 'chain',
  },
  {
    title: 'Go to',
    width: '14%',
    slotName: 'details',
  },
]
