<template>
  <div
    v-if="!isProposalsTabSelected && !isSmallScreen"
    class="nft-page-sections__content">
    <div class="nft-page-sections__content--wrapper">
      <div class="nft-page-sections__column nft-page-sections__column--left">
        <SectionDetails/>
        <SectionProperties/>

        <SectionCollectionStats v-if="!isTokenBundle"/>
      </div>

      <div class="nft-page-sections__column nft-page-sections__column--right">
        <SectionNFTAppraisal v-if="!isTokenBundle || asset?.isBundleWithSingleAsset"/>
        <SectionBundleAssetsTable v-if="isTokenBundle"/>
        <SectionPriceHistory v-if="!isTokenBundle || asset?.isBundleWithSingleAsset"/>
        <SectionOffersOnAssets v-if="isTokenBundle"/>
        <SectionOffers v-else/>
        <SectionListings v-if="!isTokenBundle || asset?.isBundleWithSingleAsset"/>
        <SectionNFTEvents/>
      </div>
    </div>
  </div>

  <!-- MOBILE -->
  <div v-if="!isProposalsTabSelected && isSmallScreen">
    <SectionBundleAssetsTable v-if="isTokenBundle"/>
    <SectionPriceHistory
      v-if="!isTokenBundle || asset?.isBundleWithSingleAsset"
      class="nft-page-sections__price-history"/>
    <SectionDetails/>
    <SectionOffersOnAssets v-if="isTokenBundle"/>
    <SectionOffers v-else/>
    <SectionProperties/>
    <SectionListings v-if="!isTokenBundle || asset?.isBundleWithSingleAsset"/>
    <SectionNFTEvents/>
    <SectionNFTAppraisal v-if="!isTokenBundle || asset?.isBundleWithSingleAsset"/>
    <SectionCollectionStats v-if="!isTokenBundle || asset?.isBundleWithSingleAsset"/>
  </div>
</template>

<script lang="ts" setup>

import SectionPriceHistory from '@/modules/pages/asset/nft-page/SectionPriceHistory/SectionPriceHistory.vue'
import SectionListings from '@/modules/pages/asset/nft-page/SectionListings/SectionListings.vue'
import SectionOffers from '@/modules/pages/asset/nft-page/SectionOffers/SectionOffers.vue'
import SectionCollectionStats from '@/modules/pages/asset/nft-page/SectionCollectionStats/SectionCollectionStats.vue'
import SectionNFTEvents from '@/modules/pages/asset/nft-page/SectionNFTEvents/SectionNFTEvents.vue'
import SectionDetails from '@/modules/pages/asset/nft-page/SectionDetails/SectionDetails.vue'
import SectionOffersOnAssets from '@/modules/pages/asset/nft-page/SectionOffersOnAssets/SectionOffersOnAssets.vue'
import SectionBundleAssetsTable from '@/modules/pages/asset/nft-page/SectionAssetTable/SectionBundleAssetsTable.vue'
import SectionProperties from '@/modules/pages/asset/nft-page/SectionProperties/SectionProperties.vue'
import SectionNFTAppraisal from '@/modules/pages/asset/nft-page/SectionNFTAppraisal/SectionNFTAppraisal.vue'
import { isSmallScreen } from '@/utils/mediaQueries'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import { computed } from 'vue'

const { asset, isProposalsTabSelected } = useAssetPage()
const isTokenBundle = computed(() => asset.value?.isTokenBundle)
</script>

<style scoped>
.nft-page-sections {
  &__content {
    display: flex;
    gap: 1.2rem;
    min-height: 2300px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &--left {
      width: 100%;
      max-width: 29.125rem;
    }

    &--right {
      width: 100%;
    }
  }

  &__content--wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
  }

  @media only screen and (--small-viewport) {
    &__content--wrapper {
      flex-direction: column;
    }

    &__column {
      &--left {
        max-width: 100%;
      }
    }

    &__price-history {
      display: none;
    }
  }
}
</style>
