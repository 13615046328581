/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

export type EventTypeEnumBackendSchema = typeof EventTypeEnumBackendSchema[keyof typeof EventTypeEnumBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventTypeEnumBackendSchema = {
  mint: 'mint',
  sale: 'sale',
  transfer: 'transfer',
} as const;
