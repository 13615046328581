<template>
  <div
    :class="[
      'proposal-form',
      { 'proposal-form--with-border': hasBorder, 'proposal-form--is-highlight': noProposalsStore.isHighlightForm },
    ]">
    <div
      v-if="hasTabs"
      class="proposal-form__action-summary">
      {{ actionSummary }}
    </div>

    <slot name="trigger"/>

    <form
      v-show="isOpen"
      class="proposal-form__form"
      @submit="
        async (e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }
      ">
      <!-- Edit -->
      <div
        v-if="isEdit"
        class="proposal-form__edit-note">
        Note: This action cancels your existing proposal and posts a new one with updated terms and ID.
      </div>

      <!--  Collateral -->
      <SelectAssetInput
        :label="collateralAssetInputLabel"
        :form="form"
        :is-check-user-balance="isActiveTabBorrow"
        :show-only-owned="isActiveTabBorrow"
        asset-field-name="collateralAsset"
        amount-field-name="collateralAmount"
        :amount-schema="collateralAmountSchema"
        :bundle-image="BundleImageComponent"
        :modal-type="collateralAssetModalType"
        :chain-id="selectAssetInputChainId('collateral')"
        appraisal-field-name="collateralAppraisal"
        :is-select-collection-asset="props.isSelectCollectionAsset"
        is-collateral
        :former-collateral="formerCollateral"
        :is-asset-locked="props.isCollateralLocked"
        :selected-asset="selectedCollateral"
        :is-open-modal="isOpenCollateralModal"
        :is-focus-input="isFocusCollateralInput"
        :is-amount-always-one="false"
        :is-fungible-proposal="isElasticProposal"
        amount-field-on-change-listen-to="ltv"
        :is-borrowing="isActiveTabBorrow"
        @on-amount-change="(val) => recalculateOnCollateralOrCreditChange({
          focusedInput: 'collateral',
          form: form,
          isActiveTabBorrow,
          collateralUnitPrice: collateralUnitPrice?.price?.usdAmount ?? 0,
          creditUnitPrice: creditUnitPrice?.price?.usdAmount ?? 0,
        })"
        @select-asset="(asset) => handleAssetChange('collateral', asset as AssetWithAmount)"/>

      <!-- Credit  -->
      <SelectAssetInput
        :label="creditAssetInputLabel"
        :form="form"
        only-erc20
        :is-check-user-balance="selectedCreditIsStables ? false : !isActiveTabBorrow"
        :show-only-owned="!isActiveTabBorrow"
        asset-field-name="creditAsset"
        amount-field-name="creditAmount"
        :modal-type="isActiveTabBorrow ? 'credit-asset' : 'lent-asset'"
        :amount-schema="creditAmountSchema"
        :hide-max-button="isActiveTabBorrow"
        :chain-id="selectAssetInputChainId('credit')"
        appraisal-field-name="creditAppraisal"
        :selected-asset="selectedCredit"
        :is-open-modal="isOpenCreditModal"
        :is-focus-input="isFocusCreditInput"
        :is-fungible-proposal="isElasticProposal"
        amount-field-on-change-listen-to="ltv"
        :is-borrowing="isActiveTabBorrow"
        @on-amount-change="(val) => recalculateOnCollateralOrCreditChange({
          focusedInput: 'credit',
          form: form,
          isActiveTabBorrow,
          collateralUnitPrice: collateralUnitPrice?.price?.usdAmount ?? 0,
          creditUnitPrice: creditUnitPrice?.price?.usdAmount ?? 0,
        })"
        @select-asset="(asset) => handleAssetChange('credit', asset as AssetWithAmount)"/>

      <!-- LTV -->
      <form.Field
        name="ltv"
        :validators="{
          onChange: ({ value, fieldApi }) => {
            const parsed = ltvSchema.safeParse(value);
            if (!parsed.success) {
              return parsed.error.errors?.[0]?.message;
            }
            const v = ltvIsFocused || isLtvButtonClicked ?
              recalculateOnLtvChange({
                ltv: Number(value),
                form: fieldApi.form,
                isActiveTabBorrow,
                collateralUnitPrice: collateralUnitPrice?.price?.usdAmount ?? 0,
                creditUnitPrice: creditUnitPrice?.price?.usdAmount ?? 0,
              })
              : false
            isLtvButtonClicked = false;
            if (!v) {
              return undefined;
            }
          },
        }">
        <template #default="{ field }">
          <LTVInput
            :field="field"
            :collateral-per-credit-text="collateralPerCreditText"
            :collateral-per-credit-amount="collateralPerCreditAmount"
            :set-preset-value="(v) => {
              isLtvButtonClicked = true;
              handleLtvChange(v, field)
            }"
            :is-disabled="isLtvDisabled">
            <template #default>
              <input
                :id="field.name"
                v-maska="maskPercentTwoDecimals"
                autocomplete="off"
                type="text"
                lang="en"
                inputmode="decimal"
                step="any"
                class="ltv-input"
                :class="[`ltv-input`, `ltv-input--${ltvStyle(field.state.value)}`]"
                :name="field.name"
                placeholder="0%"
                :disabled="isLtvDisabled"
                :value="displayLtv(field)"
                @blur="
                  (v) => {
                    ltvIsFocused = false;
                    return field.handleBlur();
                  }
                "
                @focus="ltvIsFocused = true"
                @wheel.stop.prevent
                @input="(e) => handleLtvChange((e.target as HTMLInputElement).value, field)">
            </template>

            <template #error>
              {{ splitErrorsCommaIfAny(field.state.meta.errors?.[0]) }}
            </template>
          </LTVInput>
        </template>
      </form.Field>

      <!-- APR -->
      <form.Field
        name="apr"
        :validators="{
          onChange: aprSchema,
          onChangeAsyncDebounceMs: 300,
        }">
        <template #default="{ field }">
          <div class="apr-input-container">
            <label class="apr-input-container__label">APR</label>
            <input
              :id="field.name"
              v-maska="maskPercentTwoDecimals"
              autocomplete="off"
              type="text"
              inputmode="decimal"
              class="apr-input"
              :name="field.name"
              placeholder="10"
              step="any"
              :value="displayApr(field)"
              @focus="aprIsFocused = true"
              @blur="() => {
                aprIsFocused = false
                field.handleBlur()
              }"
              @wheel.stop.prevent
              @input="(e) => handleAPRChange(e, field)">
            <div class="apr-input-repay-amount">
              {{ interestAppraisalText }}
            </div>

            <div class="apr-input-container__rep-score">
              <RewardsByAmount
                :credit-address="creditAssetAddress"
                :collateral-address="selectedAssetAddress"
                :chain-id="creditAssetChainId"
                :amount-input="amountForPoints"/>
            </div>

            <div class="apr-input-container__suggest-loan-terms">
              <SuggestApy
                :chain-id="creditAssetChainId"
                :credit-symbol="creditAssetSymbol"
                @on-click-match-apr-terms="handleSuggestApy"/>
            </div>

            <span class="error-message">
              {{ splitErrorsCommaIfAny(field.state.meta.errors?.[0]) }}
            </span>
          </div>
        </template>
      </form.Field>

      <!-- Duration -->
      <form.Field
        name="loanDurationDays"
        :validators="{
          onChange: loanDurationDaysSchema,
          onChangeAsyncDebounceMs: 300,
        }">
        <template #default="{ field }">
          <DurationField
            :field="field"
            :set-preset-value="(v) => field.handleChange(v)">
            <template #default>
              <input
                :id="field.name"
                type="number"
                inputmode="numeric"
                pattern="\d*"
                autocomplete="off"
                class="duration-input"
                :name="field.name"
                placeholder="0"
                :value="field.state.value"
                @focus="durationIsFocused = true"
                @blur="() => {
                  durationIsFocused = false
                  field.handleBlur()
                }"
                @wheel.stop.prevent
                @input="(e) => field.handleChange(Number((e.target as HTMLInputElement).value))">
              <div class="duration-input-days">
                {{ field.state.value === 1 ? "Day" : "Days" }}
              </div>
            </template>
          </DurationField>
        </template>
      </form.Field>

      <!-- Expiration -->
      <form.Field
        name="expirationDays"
        :validators="{ onChange: expirationDaysSchema }">
        <template #default="{ field }">
          <div class="field-container">
            <label class="expiration-input-container__label">Your proposal is valid for</label>
            <div
              v-if="isEditingExpirationDays"
              class="expiration-input-container">
              <input
                :id="field.name"
                v-maska="'###'"
                autocomplete="off"
                type="text"
                inputmode="numeric"
                pattern="\d*"
                class="expiration-input"
                :name="field.name"
                placeholder="0"
                :value="field.state.value"
                @input="(e) => field.handleChange(Number((e.target as HTMLInputElement).value))">

              <div class="expiration-input-days">
                {{ field.state.value === 1 ? "Day" : "Days" }}
              </div>
            </div>
            <div
              v-else
              class="expiration-edit-and-days-static">
              <div class="expiration-days-static">
                {{ field.state.value }} Days
              </div>
              <div
                class="expiration-edit"
                @click="isEditingExpirationDays = !isEditingExpirationDays">
                <EditSvg class="expiration-edit-icon"/>
                Edit
              </div>
            </div>
            <span class="error-message">
              {{ splitErrorsCommaIfAny(field.state.meta.errors?.[0]) }}
            </span>
          </div>
        </template>
      </form.Field>

      <div
        v-if="hasAdvancedSettings"
        class="advanced-settings__button"
        @click="handleToggleAdvancedSettings">
        <span class="advanced-settings__content">
          <SettingsIconSvg/> Advanced Settings <ArrowDownIconSvg :class="['advanced-settings__arrow', {'advanced-settings__arrow--open': isAdvancedSettingsOpen}]"/>
        </span>
      </div>

      <!-- Minimum loan amount -->
      <div v-show="isAdvancedSettingsOpen">
        <form.Field
          name="minCreditPercent"
          :validators="{
            onChange: minCreditPercentSchema,
          }">
          <template #default="{ field }">
            <MinCreditPercent
              :field="field"
              :set-preset-value="(v) => handleMinPercentCreditChange(v, field)">
              <template #default>
                <input
                  :id="field.name"
                  v-maska="maskPercentTwoDecimals"
                  autocomplete="off"
                  type="text"
                  lang="en"
                  inputmode="decimal"
                  step="any"
                  class="min-loan-amount"
                  :name="field.name"
                  placeholder="5%"
                  :value="displayMinLoanAmountPercent(field)"
                  @blur="
                    (v) => {
                      isFocusedMinLoanAmount = false;
                      return field.handleBlur();
                    }
                  "
                  @focus="isFocusedMinLoanAmount = true"
                  @wheel.stop.prevent
                  @input="(e) => handleMinPercentCreditChange((e.target as HTMLInputElement).value, field)">
              </template>

              <template #error>
                {{ splitErrorsCommaIfAny(field.state.meta.errors?.[0]) }}
              </template>
            </MinCreditPercent>
          </template>
        </form.Field>
      </div>

      <form.Subscribe>
        <template #default="{ canSubmit, isSubmitting }">
          <BaseButton
            is-type-submit
            is-full-width
            :button-text="isSubmitting ? 'Posting Proposal...' : 'Post Proposal'"
            :is-disabled="!canSubmit || props.isFormSubmitDisabled"/>
        </template>
      </form.Subscribe>
    </form>
  </div>

  <ModalMultiProposalPreview
    :is-open="showMultiProposalPreview"
    :form-value="_parsedProposalToCreate"
    :on-close="() => showMultiProposalPreview = false"/>
</template>

<script lang="ts" setup>
import BaseButton from '@/general-components/BaseButton.vue'
import { useForm } from '@tanstack/vue-form'
import type { ValidationError } from '@tanstack/vue-form'
import { computed, onUnmounted, ref, toRefs, watch } from 'vue'
import EditSvg from '@/assets/icons/edit-text-color.svg'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import { z } from 'zod'
import { zodValidator } from '@tanstack/zod-form-adapter'
import useProposalPreviewModal from '@/revamp/components/modals/proposal-preview-modal/useProposalPreviewModal'
import {
  DEFAULT_LOAN_DURATION_IN_DAYS,
  EXTENDED_MAX_LOAN_DURATION_IN_DAYS,
  MAX_LOAN_REQUEST_EXPIRATION_IN_DAYS,
} from '@/constants/loans'
import SelectAssetInput from '@/revamp/components/proposal-form/SelectAssetInput.vue'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { useCreateProposalPayload } from '@/revamp/components/proposal-form/useCreateProposalPayload'
import type { CreateProposalFormValue } from '@/revamp/components/proposal-form/useCreateProposalPayload'
import type { SupportedChain } from '@/constants/chains/types'
import { watchAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import { compareAddresses, formatDecimalPoint, getRawAmount } from '@/utils/utils'
import { useAssetPrice } from '@/revamp/hooks/asset-prices/useAssetPrice'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import { useNoProposalsStore } from '@/revamp/hooks/useNoProposalsStore'
import {
  maskPercentTwoDecimals,
} from '@/revamp/utils/inputs'
import LTVInput from '@/revamp/components/proposal-form/LTVInput.vue'
import DurationField from '@/revamp/components/proposal-form/DurationField.vue'
import { calculateCollateralAmountFungibleProposal, calculateCreditPerCollateralUnit } from '@/modules/common/pwn/utils'
import ArrowDownIconSvg from '@/assets/icons/arrow-down-white.svg'
import SettingsIconSvg from '@/assets/icons/settings-dials.svg'
import ModalMultiProposalPreview from '@/revamp/components/modals/proposal-preview-modal/ModalMultiProposalPreview.vue'
import RewardsByAmount from '@/revamp/features/points/RewardsByAmount.vue'
import SuggestApy from '@/revamp/components/proposal-form/SuggestApy.vue'
import { useLocalStorage } from '@vueuse/core'
import MinCreditPercent from '@/revamp/components/proposal-form/MinCreditPercent.vue'
import {
  recalculateOnCollateralOrCreditChange,
  recalculateOnLtvChange,
} from '@/revamp/components/proposal-form/proposalFormCalculations'

// TODO
// +refactor into smaller components
// +resolve missing typescript (FieldApi, etc)

interface Props {
  hasTabs?: boolean;
  hasProposalTypeTabs?: boolean;
  hasBorder?: boolean;
  isOpen?: boolean;
  isOffer?: boolean;
  isEdit?: boolean;
  nonceToRevoke?: bigint;
  isActiveTabBorrow?: boolean;
  borrowingIsEnabled?: boolean;
  isCollateralLocked?: boolean;
  isSelectCollectionAsset?: boolean;
  isRedirectToProposalPage?: boolean;
  formerCollateral?: AssetWithAmount | null;
  selectedCollateral?: AssetWithAmount | null;
  selectedCredit?: AssetWithAmount | null;
  selectCollateral?: (
    asset: AssetWithAmount | AssetMetadata | Array<AssetMetadata | AssetWithAmount> | undefined,
  ) => void;
  selectCredit?: (asset: AssetWithAmount | AssetMetadata | undefined) => void;
  isFormSubmitDisabled?: boolean;
  isFungibleProposal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hasTabs: true,
  hasProposalTypeTabs: true,
  hasBorder: true,
  isOpen: true,
  isRedirectToProposalPage: true,
  isOffer: undefined,
  borrowingIsEnabled: true,
})
const { selectedCredit, selectedCollateral } = toRefs(props)

const selectedCreditIsStables = computed(() => {
  return selectedCredit.value?.symbol === '$PWN-ALL-YOUR-STABLES'
})

const {
  isOpen: isProposalModalOpen,
  proposalClass,
  proposalToBeSemiComplete,
  isEdit: isEditProposalModal,
  isProposalPosted,
  ltvInProposalModal,
  nonceToRevoke: nonceToRevokeProposalModal,
  createBatchImageBundle,
  isRedirectToProposalPage: isRedirectToProposalPageModal,
} = useProposalPreviewModal()
const { createProposalPayload } = useCreateProposalPayload()
const proposalFiltersStore = useProposalFilters()
const noProposalsStore = useNoProposalsStore()
const { bundledAssets } = useProposalPreviewModal()

const minCreditPercentDefault = useLocalStorage('minCreditPercentDefault', '15')

const selectedChain = ref<SupportedChain | null>(null)
const isEditingExpirationDays = ref(false)
const isOpenCollateralModal = ref(false)
const isOpenCreditModal = ref(false)
const isFocusCollateralInput = ref(false)
const isFocusCreditInput = ref(false)
const isFocusedMinLoanAmount = ref(false)
const isAdvancedSettingsOpen = ref(false)
const isLtvButtonClicked = ref(false)

const isActiveTabBorrowComputed = computed(() => props.isActiveTabBorrow)
const collateralAssetInputLabel = computed(() => {
  return isActiveTabBorrowComputed.value ? 'My Collateral' : 'Their Collateral'
})
const creditAssetInputLabel = computed(() => {
  return isActiveTabBorrowComputed.value ? 'Borrow' : 'Lend'
})
const isElasticProposal = computed(() => {
  return props.selectedCollateral?.isErc20 && !selectedCreditIsStables.value
})
const hasAdvancedSettings = computed(() => {
  return isElasticProposal.value && selectedCredit.value
})

const collateralAmountSchema = z.coerce
  .number()
  .refine((value) => Number.isFinite(value), { message: 'Collateral amount must be finite' }) // Ensure value is finite
  .refine((value) => value > 0, { message: 'Collateral value must exceed $0' }) // Ensure value is positive

const creditAmountSchema = z.coerce
  .number()
  .refine((value) => {
    return value > 0
  }, { message: 'Lent amount must exceed $0' })

const availableCreditLimitSchema = z.coerce
  .number({
    message: 'Total Commitment amount must be a number',
  })

const creditAssetSchema = z.object({ address: z.string().nullable(), chainId: z.number() })

const ltvSchema = z.coerce
  .number({
    message: 'LTV must be a number',
  })
  .transform((value) => {
    return Number(value)
  })
  .refine((value) => Number.isFinite(value), { message: 'LTV must be finite' })
  .refine((value) => value >= 0, { message: 'LTV must be above 0%' })

const loanDurationDaysSchema = z.coerce
  .number({
    message: 'Loan duration must be a number',
  })
  .min(1, 'The max loan duration must be more than one day')
  .max(EXTENDED_MAX_LOAN_DURATION_IN_DAYS, 'You most likely won’t be alive to repay this loan')

const aprSchema = z.coerce
  .string()
  .regex(/^\d+(\.\d*)?$/, { message: 'APR amount must be a number' })
  .transform((value) => {
    if (value.includes(',') && value.indexOf(',') !== value.length - 1) {
      return Number(value.replace(',', '.'))
    }

    return Number(value)
  })
  .refine((value) => Number.isFinite(value), { message: 'APR amount must be finite' }) // Ensure value is finite
  .refine((value) => Number(value) > 0, { message: 'APR value must exceed $0' }) // Ensure value is positive

const expirationDaysSchema = z.coerce
  .number()
  .min(1, 'Minimum is one Day')
  .max(MAX_LOAN_REQUEST_EXPIRATION_IN_DAYS, 'Maximum expiration is 30 Days')

const collateralAssetSchema = z.object({ address: z.string(), chainId: z.number() })

const assetIsMultipleStables = (asset: AssetWithAmount) => {
  return asset.symbol === '$PWN-ALL-YOUR-STABLES'
}

const fullZodSchemaClassicProposal = z.object({
  collateralAmount: collateralAmountSchema,
  collateralAsset: collateralAssetSchema,
  creditAsset: creditAssetSchema,
  creditAmount: creditAmountSchema,
  loanDurationDays: loanDurationDaysSchema,
  apr: aprSchema,
  expirationDays: expirationDaysSchema,
})

const fullZodSchemaFungibleProposal = z.object({
  collateralAsset: collateralAssetSchema,
  creditAsset: creditAssetSchema,
  loanDurationDays: loanDurationDaysSchema,
  apr: aprSchema,
  expirationDays: expirationDaysSchema,
  availableCreditLimit: availableCreditLimitSchema,
  creditAmount: creditAmountSchema,
})

const fullZodSchema = computed(() => {
  return isElasticProposal.value ? fullZodSchemaFungibleProposal : fullZodSchemaClassicProposal
})

const BundleImageComponent = computed(() => {
  if (!bundledAssets.value) {
    return
  }
  return createBatchImageBundle(bundledAssets.value)
})

const handleAssetChange = (source: 'collateral' | 'credit', asset?: AssetWithAmount, chainId?: SupportedChain) => {
  if (source === 'collateral') {
    props?.selectCollateral?.(asset)
    if (asset?.isTokenBundle || !asset?.isFungible) {
      isFocusCollateralInput.value = false
    } else {
      isFocusCollateralInput.value = true
    }
  }

  if (source === 'credit') {
    props?.selectCredit?.(asset)
    isFocusCreditInput.value = false
    setTimeout(() => {
      isFocusCreditInput.value = true
    }, 100)
  }

  if (Array.isArray(asset)) {
    bundledAssets.value = asset
    selectedChain.value = asset[0].chainId
  } else {
    selectedChain.value = asset?.chainId || chainId || null
  }
}

const showMultiProposalPreview = ref(false)
interface CreateProposalFormValueWithPercent extends CreateProposalFormValue {
  minCreditPercent?: string;
}

const _parsedProposalToCreate = ref<CreateProposalFormValueWithPercent | null>(null)

const _creditAsset = computed(() => {
  if (selectedCreditIsStables.value && selectedCredit.value?.bundleAssets) {
    return selectedCredit.value.bundleAssets[0]
  }
  return selectedCredit.value
})

const creditAssetChainId = computed(() => String(_creditAsset.value?.chainId) || null)
const creditAssetAddress = computed(() => _creditAsset.value?.address || null)
const creditAssetTokenId = computed(() =>
  selectedCredit.value?.tokenId ? String(_creditAsset.value?.tokenId) : null,
)
const creditAssetCategory = computed(() => _creditAsset.value?.category)
const creditAssetSymbol = computed(() => _creditAsset.value?.symbol || null)

const { priceData: creditUnitPrice } = useAssetPrice(
  creditAssetChainId,
  creditAssetAddress,
  creditAssetTokenId,
  creditAssetCategory,
)

const selectedAssetChainId = computed(() => String(selectedCollateral.value?.chainId) || null)
const selectedAssetAddress = computed(() => selectedCollateral.value?.address || null)
const selectedAssetTokenId = computed(() =>
  selectedCollateral.value?.tokenId ? String(selectedCollateral.value?.tokenId) : null,
)
const selectedAssetCategory = computed(() => {
  return selectedCollateral.value?.category
})

const { priceData: collateralUnitPrice } = useAssetPrice(
  selectedAssetChainId,
  selectedAssetAddress,
  selectedAssetTokenId,
  selectedAssetCategory,
)

const isLtvDisabled = computed(() => {
  return !collateralUnitPrice.value?.price?.usdAmount || !creditUnitPrice.value?.price?.usdAmount || !selectedCollateral.value || !selectedCredit.value
})

const form = useForm({
  defaultValues: {
    collateralAmount: '',
    collateralAsset: undefined,
    creditAmount: '',
    creditAsset: undefined,
    ltv: '50',
    loanDurationDays: DEFAULT_LOAN_DURATION_IN_DAYS,
    apr: 10,
    expirationDays: 7,
    creditPerCollateralUnit: '',
    availableCreditLimit: '',
    minCollateralAmount: '',
    minCreditAmount: '',
    minCreditPercent: minCreditPercentDefault.value,
  },
  onSubmit: async ({ value }: { value: CreateProposalFormValueWithPercent}) => {
    if (isLtvDisabled.value) {
      value.ltv = ''
    }
    // sepearate flow for the simple proposal with multi proposals
    if (selectedCredit.value && assetIsMultipleStables(selectedCredit.value)) {
      showMultiProposalPreview.value = true
      _parsedProposalToCreate.value = value
    } else {
      const isCollectionProposal =
      value?.collateralAsset?.isNft &&
      (value.collateralAsset?.tokenId === undefined || value.collateralAsset?.tokenId === null)

      const proposalType = () => {
        if (isElasticProposal.value && value.minCreditPercent !== '100') {
          return V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL
        }

        if (isCollectionProposal) {
          return V1_2ProposalType.SIMPLE_LOAN_LIST_PROPOSAL
        }

        return V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL
      }

      if (proposalType() === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
        const creditBigInt = getRawAmount(String(value.creditAmount), value.creditAsset?.decimals)
        const collateralBigInt = getRawAmount(String(value.collateralAmount), value.collateralAsset?.decimals)
        value.creditPerCollateralUnit = calculateCreditPerCollateralUnit(creditBigInt, collateralBigInt)

        if (value.minCreditPercent) {
          const minCreditAmount = String(+value.creditAmount * (+value.minCreditPercent / 100))
          const minCreditAmountRaw = getRawAmount(minCreditAmount, value.creditAsset?.decimals ?? 0).toString() ?? '0'
          value.minCollateralAmount = calculateCollateralAmountFungibleProposal({
            creditPerCollateralUnit: value.creditPerCollateralUnit,
            collateralDecimals: value.collateralAsset?.decimals ?? 0,
            availableCreditLimit: minCreditAmountRaw,
            returnBigInt: true,
          }).toString()
        }
      }

      const isOffer = props.isOffer ?? !isActiveTabBorrowComputed.value
      const proposalBodyToBESemiComplete = await createProposalPayload(value, isOffer, proposalType())

      const collateralAsset: AssetWithAmount = new AssetWithAmount({
        ...value.collateralAsset,
        amountInput: value.collateralAmount,
      })
      const parsedProposal = await ProposalFactory.createProposalFromBackendModel(
        proposalBodyToBESemiComplete,
        collateralAsset,
        value.creditAsset,
        true,
      )

      isRedirectToProposalPageModal.value = props.isRedirectToProposalPage

      if (props.isEdit) {
        isEditProposalModal.value = true
        nonceToRevokeProposalModal.value = props.nonceToRevoke
      } else {
        isEditProposalModal.value = false
      }

      ltvInProposalModal.value = Number(value.ltv ?? 0)
      isProposalPosted.value = false
      isProposalModalOpen.value = true
      proposalClass.value = parsedProposal
      proposalToBeSemiComplete.value = proposalBodyToBESemiComplete
    }
  },
  validatorAdapter: zodValidator(),
  validators: {
    //  TODO fix validation "onMount: ({ value, formApi })"
    onChangeAsync: fullZodSchema.value,
  },
})

const formStore = form.useStore((store) => store)

const creditAmount = form.useStore((store) => store.values.creditAmount)

const amountForPoints = computed(() => {
  return creditAmount.value
})

const minCreditPercentSchema = z.coerce
  .number({
    message: 'Minimum loan amount must be a number',
  })
  .refine((value) => value <= 50 || value === 100, { message: 'Must be Full Amount or less than 51%' })

watch(isActiveTabBorrowComputed, (v, oldV) => {
  if (props.isCollateralLocked) return
  if (v !== oldV) {
    // TODO how exactly handle assets change after tab switch?
    // for now is set "reset" to prevent mistakenly select assets, which are invalid for creation of proposal
    if (form.getFieldValue('creditAmount')) {
      form.setFieldValue('creditAmount', '')
    }
    if (form.getFieldValue('collateralAmount')) {
      form.setFieldValue('collateralAmount', '')
    }
    const collateral = form.getFieldValue('collateralAsset')
    const credit = form.getFieldValue('creditAsset')

    if (collateral) {
      handleAssetChange('collateral', collateral)
    }
    if (credit) {
      handleAssetChange('credit', credit)
    }
  }
})

const unwatch = watchAccount(pwnWagmiConfig, {
  onChange(account, prevAccount) {
    if (
      (!account.address && !prevAccount.address) ||
      compareAddresses(account.address, prevAccount.address) ||
      !prevAccount.address
    ) {
      return
    }
    const chainId = selectedCollateral.value?.chainId || selectedCredit.value?.chainId
    if (props.isActiveTabBorrow) {
      proposalFiltersStore.actions.updateCreditAssetsFilter([])
      proposalFiltersStore.actions.updateCollateralAssetsFilter([])
      handleAssetChange('collateral', undefined, chainId)
      form.setFieldValue('collateralAmount', '1')
    } else {
      proposalFiltersStore.actions.updateCreditAssetsFilter([])
      proposalFiltersStore.actions.updateCollateralAssetsFilter([])
      handleAssetChange('credit', undefined, chainId)
      form.setFieldValue('creditAmount', '1')
    }
    // form.reset()
  },
})
onUnmounted(() => {
  unwatch()
})

const interestAppraisalText = computed(() => {
  if (Number.isNaN(formStore.value.values.apr)) return ''
  const dailyInterest = formStore.value.values.apr / 100 / 365

  const interestBase = +formStore.value.values.creditAmount

  // TODO should we rather display the interest in USD without repayment?
  // const interest = (+formStore.value.values.creditAppraisal * dailyInterest * formStore.value.values.loanDurationDays).toFixed(2)
  // return `${interest} USD by day ${form.state.values.loanDurationDays}`

  const stablecoins = formStore.value.values.creditAsset
    ? useTopTokensStore().getStablecoinsByChainId(
      (formStore.value.values.creditAsset as unknown as AssetWithAmount)?.chainId,
    )
    : []

  const creditAsset = formStore.value.values.creditAsset as unknown as AssetWithAmount
  const selectedMultupleCredit = selectedCreditIsStables.value

  const isStablecoin = selectedMultupleCredit || stablecoins.some((stablecoin) =>
    compareAddresses((creditAsset as unknown as AssetWithAmount)?.address, stablecoin.address),
  )

  const interestPlusRepayment =
    interestBase * dailyInterest * formStore.value.values.loanDurationDays +
    interestBase

  const repaymentIsNotNaN = !Number.isNaN(interestPlusRepayment)

  const interestPlusRepaymentFormatted =
    isStablecoin && repaymentIsNotNaN
      ? (interestPlusRepayment || 0).toFixed(2)
      : formatDecimalPoint(interestPlusRepayment || 0)

  if (selectedMultupleCredit) {
    return `$${interestPlusRepaymentFormatted} by day ${form.state.values.loanDurationDays} ${isElasticProposal.value ? 'at full utilization' : ''}`
  }

  return `${interestPlusRepaymentFormatted} ${creditAsset?.symbol || ''} by day ${form.state.values.loanDurationDays} ${isElasticProposal.value ? 'at full utilization' : ''}`
})

const actionSummary = computed(() => {
  return props.isActiveTabBorrow ? 'Make a custom borrowing offer' : 'Make a custom lending offer'
})

enum LtvType {
  Lowest = 'lowest',
  Low = 'low',
  Average = 'average',
  High = 'high',
  DangerHigh = 'danger-high',
  Unavailable = 'unavailable',
}

const ltvStyle = (ltv) => {
  if (!ltv || isNaN(ltv)) {
    return LtvType.Unavailable
  } else if (ltv <= 20) {
    return LtvType.Lowest
  } else if (ltv <= 60) {
    return LtvType.Low
  } else if (ltv <= 80) {
    return LtvType.Average
  } else if (ltv <= 100) {
    return LtvType.High
  } else {
    return LtvType.DangerHigh
  }
}

const handleLtvChange = (value: number | string, fieldApi) => {
  const unMaskedValue = String(value).replace('%', '')
  fieldApi.handleChange(unMaskedValue)
}

const handleMinPercentCreditChange = (value: number | string, fieldApi) => {
  const unMaskedValue = String(value).replace('%', '')
  minCreditPercentDefault.value = unMaskedValue
  fieldApi.handleChange(unMaskedValue)
}

const handleAPRChange = (e: Event, fieldApi) => {
  const input = (e.target as HTMLInputElement).value
  const unMaskedValue = input.replace('%', '')
  fieldApi.handleChange(unMaskedValue)
}

const splitErrorsCommaIfAny = (errorMessage: ValidationError) => {
  if (!errorMessage) return
  const arraySplit = errorMessage.split(', ')
  if (arraySplit.length >= 1) {
    return arraySplit[0]
  }
  return errorMessage
}

const ltvIsFocused = ref(false)
const aprIsFocused = ref(false)
const durationIsFocused = ref(false)

const displayLtv = (field) => {
  const v = String(field.state.value).replace(',', '.')
  if ((!ltvIsFocused.value && field.state.value !== 0 && !field.state.value) || ltvIsFocused.value) {
    return v
  }
  return `${v}%`
}

const displayApr = (field) => {
  const v = String(field.state.value).replace(',', '.')
  if ((!aprIsFocused.value && field.state.value !== 0 && !field.state.value) || aprIsFocused.value) {
    return v
  }
  return `${v}%`
}

const displayMinLoanAmountPercent = (field) => {
  const v = String(field.state.value).replace(',', '.')
  if ((!isFocusedMinLoanAmount.value && field.state.value !== 0 && !field.state.value) || isFocusedMinLoanAmount.value) {
    return v
  }
  return `${v}%`
}

const collateralAssetModalType = computed(() => {
  return props.isActiveTabBorrow ? 'your-assets' : 'their-assets'
})

const handleToggleAdvancedSettings = () => {
  isAdvancedSettingsOpen.value = !isAdvancedSettingsOpen.value
}

const selectAssetInputChainId = (assetType: 'credit' | 'collateral') => {
  if (assetType === 'credit') {
    if (selectedCollateral.value) {
      return selectedCollateral.value?.chainId
    }
    return null
  }
  if (assetType === 'collateral') {
    if (selectedCredit.value) {
      return selectedCredit.value?.chainId
    }
    return null
  }
}

const handleSuggestApy = (apy: number) => {
  form.setFieldValue('apr', apy)
}

const collateralPerCreditAmount = computed(() => {
  if (!formStore.value.values.creditAmount || !formStore.value.values.collateralAmount) {
    return null
  }
  const unFormated = +formStore.value.values.collateralAmount / +formStore.value.values.creditAmount
  return formatDecimalPoint(unFormated)
})

const collateralPerCreditText = computed(() => {
  if (collateralPerCreditAmount.value && selectedCollateral.value && selectedCredit.value && isElasticProposal.value) {
    return `${selectedCollateral.value.symbol} per 1 ${selectedCredit.value.symbol}`
  }
  return ''
})

</script>

<style scoped>
.proposal-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 24rem;

  /*noinspection ALL*/
  @media only screen and (--mobile-viewport) {
    font-size: 1rem;
    min-width: 290px;
    z-index: 1;
    width: 100%;
  }

  @keyframes pulse {
    0% {
      box-shadow: var(--primary-color-4);
    }

    20% {
      box-shadow: 0 -10px 30px 0 rgb(var(--primary-color-base) / 25%);
    }

    80% {
      box-shadow: 0 -10px 30px 0 rgb(var(--primary-color-base) / 25%);
    }

    100% {
      box-shadow: var(--primary-color-4);
    }
  }

  &__action-summary {
    font-size: 1rem;
    font-family: var(--font-family-screener);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    padding: 0.875rem 0;
  }

  &--is-highlight {
    animation: pulse 3.5s 1 ease-in-out;
  }

  &--with-border {
    border: 1px solid #434343;
    padding: 1rem;
  }

  &__collateral-appraisal {
    color: var(--separation-grey, #828282);
    font-size: 0.75rem;
    position: absolute;
    top: -20px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    min-width: 350px;

    /*noinspection ALL*/
    @media only screen and (--mobile-viewport) {
      min-width: unset;
    }
  }

  &__edit-note {
    border: 1px solid var(--gray, #696678);
    padding: 1rem;
  }
}

.field-container {
  position: relative;
}

.expiration-input-container,
.apr-input-container,
.ltv-input-container,
.duration-input-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 0.5rem;
  position: relative;

  &__label {
    font-size: 0.875rem;
    font-family: var(--font-family-screener);
    font-weight: 400;
    color: #a4a4a4;
  }
}

.expiration-input-container {
  margin-top: 0.5rem;
}

.expiration-input,
.apr-input,
.ltv-input,
.min-loan-amount,
.duration-input {
  margin-top: 0.5rem;
  all: unset;
  max-width: 100%;
  color: var(--text-color);
  border: 1px solid var(--gray, #696678);
  background: var(--input-color, #080808);
  padding-left: 1rem;
  height: 2.75rem;
}

.duration-input {
  max-width: 5.5rem;
}

.apr-input {
  margin-top: 0.5rem;
  all: unset;
  max-width: 100%;
  color: var(--text-color);
  border: 1px solid var(--gray, #696678);
  background: var(--input-color, #080808);
  padding: 16px 0 41px 16px;
}

.apr-input-repay-amount {
  position: absolute;
  top: 4.5rem;
  left: 1rem;
  font-size: 12px;
  color: var(--separation-grey, #828282);
}

.apr-input-percent,
.ltv-input-percent,
.duration-input-days {
  color: var(--text-color);
  position: absolute;
  right: 1rem;
  top: 2.6rem;
}

.duration-input-days {
  font-size: 0.75rem;
  top: 1rem;
}

.apr-input-percent,
.ltv-input-percent {
  font-size: 1rem;
}

.expiration-edit-and-days-static {
  display: flex;
  gap: 1rem;
}

.expiration-days-static {
  color: var(--text-color);
  border: 1px solid var(--gray, #696678);
  height: 2.6875rem;
  text-align: center;
  width: fit-content;
  padding: 12px;
  margin-top: 8px;
}

.expiration-input-days {
  color: var(--text-color);
  font-size: 0.75rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.expiration-edit {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  cursor: pointer;
}

.expiration-edit-icon {
  color: var(--text-color);
}

.error-message {
  color: var(--negative-1);
  font-size: 0.9rem;
  position: absolute;
  margin-top: 4px;
  right: 0;
  bottom: -22px;
}

.min-loan-amount {
  display: inline-flex;
  max-width: 3rem;
}

.ltv-input {
  display: inline-flex;
  max-width: 5.1rem;

  /* &--lowest {
    color: var(--primary-color-1);
  }

  &--low {
    color: var(--positive-bright);
  }

  &--average {
    color: var(--warning-1);
  }

  &--high {
    color: var(--negative-1);
  }

  &--danger-high {
    color: var(--danger-1);
  } */

  &--unavailable {
    color: var(--gray-2);
  }
}

.min-loan-amount:disabled,
.ltv-input:disabled {
  background: var(--gray-3);
  color: var(--gray-2);
  cursor: not-allowed;
}

.advanced-settings {
  &__button {
    display: flex;
    height: 43px;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    gap: 0.5rem;
    align-self: stretch;
    background: var(--gray-3, #222);

    &:hover {
      background: var(--gray-3);
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  &__arrow {
    fill: var(--text-color);

    &--open {
      rotate: 180deg;
    }
  }
}

.apr-input-container {
  &__rep-score {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    cursor: help;
  }

  &__suggest-loan-terms {
    position: absolute;
    right: 1rem;
    bottom: 2.5rem;
    cursor: pointer;
  }
}

.elastic-proposal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__checkbox {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

</style>
