import { z } from 'zod'
import type {
  ProposalAndLoanListSchemaWorkaroundBackendSchema,
} from '@/modules/common/backend/generated'
// import { V1_2SimpleLoanDutchAuctionProposal } from '@/modules/common/pwn/proposals/ProposalClasses'

export const zChainId = z.union([
  z.literal(1),
  z.literal(137),
  z.literal(25),
  z.literal(8453),
  z.literal(10),
  z.literal(42161),
  z.literal(56),
  z.literal(100),
  z.literal(11155111),
  z.literal(1301),
  z.literal(11155112),
  z.literal(112211),
])

export const zProposalStatus = z.union([
  z.literal(-4),
  z.literal(-3),
  z.literal(-2),
  z.literal(-1),
  z.literal(1),
])

export const zAssetCategory = z.union([
  z.literal(-1),
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(420),
  z.literal(421),
])

export const zProposalType = z.enum([
  'pwn_contracts.betaproposal',
  'pwn_contracts.v1_1simpleloansimpleproposal',
  'pwn_contracts.v1_1simpleloanlistproposal',
  'pwn_contracts.v1_2simpleloansimpleproposal',
  'pwn_contracts.v1_2simpleloanlistproposal',
  'pwn_contracts.v1_2simpleloanfungibleproposal',
  'pwn_contracts.v1_2simpleloandutchauctionproposal',
])
export const zAssetDetailSchema = z.object({
  address: z.string(),
  category: zAssetCategory.optional().or(z.number()),
  decimals: z.number().optional().nullable(),
  chainId: z.number(),
  thumbnailUrl: z.string().nullable().optional(),
  name: z.string().optional().nullable(), //
  isVerified: z.boolean().optional().nullable(),
  isKycRequired: z.boolean().nullable().optional(), // TODO: remove optional when implemented in BE response?
  tokenId: z.string().nullable().optional(), // TODO: remove optional when implemented in BE response?
  symbol: z.string().optional().nullable(),
})

export const zContract = z.object({
  chainId: zChainId,
  address: z.string(),
  category: zAssetCategory.optional(),
  decimals: z.number().nullable(),
  isWhitelistedForPwnsafe: z.boolean(),
  isDynamicContract: z.boolean(),
  isKycRequired: z.boolean().nullable().optional(),
  isVerified: z.boolean().optional().nullable(),
  name: z.string().optional(),
  thumbnailUrl: z.string().nullable(),
  tokenId: z.string().nullable(),
  symbol: z.string().optional(),
  kycDetails: z.null().optional(),
})

export const zAssetMetadata = z.object({
  contract: zContract,
  tokenId: z.string().optional().nullable(),
  name: z.string(),
  description: z.string().optional().nullable(),
  type: z.string(),
  symbol: z.string().optional().nullable(),
  isVerified: z.boolean(),
  isVerifiedSource: z.string(),
  thumbnailUrl: z.string(),
  websiteUrl: z.null().optional(),
  discordUrl: z.null().optional(),
  twitterUrl: z.null().optional(),
  instagramUrl: z.null().optional(),
  githubUrl: z.null().optional(),
  id: z.number(),
  atrTokensOfUser: z.null().optional(),
  latestPrice: z.object({
    price: z.object({
      ethAmount: z.string(),
      usdAmount: z.string(),
    }),
    unitPrice: z.null().optional(),
    pricePercentageDeviation: z.null().optional(),
    priceSource: z.string(),
    createdAt: z.string(),
    date: z.null().optional(),
    priceRangeLow: z.null().optional(),
    priceRangeHigh: z.null().optional(),
  }).nullable(),
})

export const zProposal = z.object({
  chainId: z.number(),
  collateralAmount: z.string(),
  duration: z.number(),
  expiration: z.number().nullable(),
  id: z.string(),
  status: z.number(),
  loanContract: z.string(),
  proposalContractAddress: z.string().nullable(),
  proposer: z.string(),
  collateral: zAssetDetailSchema,
  creditAsset: zAssetDetailSchema,
  creditData: z.object({
    amount: z.string(),
    apr: z.number().nullable(),
    ltv: z.number().nullable(),
  }),
  type: z.string(),
})

export const zProposalDetail = z.object({
  accruingInterestApr: z.number().nullable().optional(),
  allowedAcceptor: z.string().nullable(),
  availableCreditLimit: z.string(),
  chainId: zChainId,
  checkCollateralStateFingerprint: z.boolean(),
  collateral: zAssetDetailSchema.nullable(),
  collateralAmount: z.string().optional(), // todo: should be bigInt?
  collateralStateFingerprint: z.string().nullable(),
  createdAt: z.number(),
  creditAmount: z.number().optional().nullable(), // todo: remove optional when implemented in BE response?
  creditAsset: zAssetDetailSchema.nullable(),
  duration: z.number(),
  expiration: z.number(),
  fixedInterestAmount: z.string().optional().nullable(),
  hash: z.string().nullable(), // todo: empty after migration
  id: z.string(),
  isOffer: z.boolean(),
  isOnChain: z.boolean(),
  loanContractAddress: z.string().nullable().optional(),
  multiproposalMerkleRoot: z.string().nullable(),
  nonce: z.string().nullable(),
  nonceSpace: z.string().nullable(), // todo: added because @arman asked me to
  proposalContractAddress: z.string(),
  proposer: z.string(),
  proposerSpecHash: z.string().optional().nullable(), // todo: remove optional when implemented in BE response?
  refinancingLoanId: z.string().nullable(),
  revokedAt: z.number().nullable().optional(), // todo: remove optional when implemented in BE response?
  signature: z.string().optional().nullable(), // todo: remove optional when implemented in BE response?
  status: zProposalStatus,
  type: zProposalType.nullable(),
  creditData: z.object({
    accruingInterestApr: z.number().nullable(),
    amount: z.string().nullable(),
    apr: z.number().nullable(),
    creditPerCollateralUnit: z.string().nullable(),
    fixedInterestAmount: z.string().nullable(),
    ltv: z.number().nullable(),
    maxAmount: z.string().nullable(),
    minAmount: z.string().nullable(),
    totalRepaymentAmount: z.string().nullable(),
  }),
})

// TODO rather specify own type for proposal payload
export const zProposalDetailPayload = zProposalDetail.omit({
  createdAt: true,
  id: true,
  revokedAt: true,
  status: true,
  creditData: true,
  creditAsset: true,
}).partial({
  nonce: true,
  nonceSpace: true,
  hash: true,
  collateral: true,
  collateralAmount: true,
}).extend({
  refinancingLoanId: z.number().nullable(),
  creditAmount: z.string().optional(),
  collateralAddress: z.string(),
  collateralCategory: z.number(),
  collateralId: z.string().or(z.number()).optional(), // TODO should be string? Need adjustment in BE
})

export type ProposalDetailPayloadType = z.infer<typeof zProposalDetailPayload>
export type Proposal = ProposalAndLoanListSchemaWorkaroundBackendSchema
export type ProposalDetailType = z.infer<typeof zProposalDetail>
export type AssetDetail = z.infer<typeof zAssetDetailSchema>
export type ProposalStatus = z.infer<typeof zProposalStatus>
export type ChainId = z.infer<typeof zChainId>
export type AssetMetadata = z.infer<typeof zAssetMetadata>

export const toCamelCase = (str: string) => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
}

export const transformKeys = <T>(obj: any, transformFunc: (key: string) => string): T => {
  if (Array.isArray(obj)) {
    return obj.map(item => transformKeys(item, transformFunc)) as unknown as T
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((result, key) => {
      const transformedKey = transformFunc(key)
      result[transformedKey] = transformKeys(obj[key], transformFunc)
      return result
    }, {} as any) as T
  }
  return obj
}

export const parseAndTransform = <T>(schema: z.ZodType<T>, data: unknown): T => {
  const transformedData = transformKeys<T>(data, toCamelCase)
  const result = schema.safeParse(transformedData)

  if (!result.success) {
    const errors = result.error.issues.map(e => e.message + ' -> ' + e.path.join(', ')).join('\n')
    // eslint-disable-next-line no-console
    console.error(errors)
    throw new Error(errors)
  }

  return result.data
}
