import { SortDirection } from '@/general-components/sorting/SortDirection'

export interface ActiveSortOption {
  id: string;
  direction: SortDirection;
  viewName: string; // key for local storage
}

export const loadSortOption = (viewName: string, defaultSortOption: ActiveSortOption, allSortOptions: string[]): ActiveSortOption => {
  const savedSortOption = window.localStorage.getItem(viewName)
  if (savedSortOption) {
    const parsedSavedSortOption = JSON.parse(savedSortOption) as ActiveSortOption
    // We need to also check if the key exists in the sort options.
    // If not (e.g. after making a changes to the names of the keys in sort option), return default sort option.
    if (allSortOptions.includes(parsedSavedSortOption?.id)) return parsedSavedSortOption
  }
  return defaultSortOption
}

export const saveSortOption = (selectedSortOption: ActiveSortOption, viewName: string): void => {
  window.localStorage.setItem(viewName, JSON.stringify(selectedSortOption))
}

export const toggleSort = (newSortKey: string, oldSortOption: ActiveSortOption): ActiveSortOption => {
  const result = {
    id: newSortKey,
    direction: newSortKey === oldSortOption.id
      ? oldSortOption.direction === SortDirection.Descending ? SortDirection.Ascending : SortDirection.Descending
      : SortDirection.Descending,
    viewName: oldSortOption.viewName,
  }
  saveSortOption(result, oldSortOption.viewName)
  return result
}
