import { SupportedChain } from '@/constants/chains/types'

export const KERNEL_ASSETS = {
  [SupportedChain.Ethereum]: [
    '0xf02C96DbbB92DC0325AD52B3f9F2b951f972bf00', // krETH
    '0x513D27c94C0D81eeD9DC2a88b4531a69993187cF', // ksETH
    '0x0bB9aB78aAF7179b7515e6753d89822b91e670C4', // kUSD
    '0xC3A20131Ee6853Fc4d444c9AD952c93f77c0B71B', // ksETH PT
    '0xdeB0Ef575C92188091D33dd1EC5b3519182aaA22', // krETH PT
    '0x581b928365b2C339dB2B66572E8d9d7cFCCa0f80', // kUSD PT
    '0xeF578B2a16Ee408d89Cd4e83085D90126846416f', // krETH LP
    '0x66Fbb4FcA7fD1095a6BD62D2225647cfEFbcDDF4', // kUSD LP
    '0x1Ec04dF27C0f5460A4CEf9Bd9790D760aF381385', // ksETH LP
  ],
} as const
