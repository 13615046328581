<template>
  <div class="selected-atr-token-card">
    <AssetBaseInfo
      :display-amount="false"
      :asset="atrToken"/>
    <div class="selected-atr-token-card__holding-rights-text">
      Holding Rights:
    </div>
    <AssetBaseInfo :asset="atrToken.assetOfAtrToken"/>
  </div>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { toRefs } from 'vue'
import AssetBaseInfo from '@/general-components/AssetBaseInfo.vue'

interface Props {
    atrToken: AssetWithAmount
}
const props = defineProps<Props>()
const { atrToken } = toRefs(props)
</script>

<style scoped>
.selected-atr-token-card {
  max-width: 18rem;
  border: 1px solid var(--gray);
  padding: 1rem;
  background-color: var(--background-color);

  &__holding-rights-text {
    font-size: 0.75rem;
    margin: 1rem 0;
  }
}
</style>
