<template>
  <div class="thesis-collateral-assets-info-cell">
    <div class="thesis-collateral-assets-info-cell__assets">
      <div
        v-for="(asset, index) in props.collateralAssets.slice(0, 2)"
        :key="asset.address"
        class="thesis-collateral-assets-info-cell__asset"
        :style="{
          marginLeft: `-${index}rem`,
        }">
        <TokenMedia
          :token="getParsedAsset(asset)"
          class="thesis-collateral-assets-info-cell__logo"
          :style="{
            width: props.iconWidth,
            height: props.iconWidth,
          }"/>
      </div>
    </div>

    <div class="thesis-collateral-assets-info-cell__info">
      <div class="thesis-collateral-assets-info-cell__name">
        {{ props.thesisTitle }}

        <BaseTooltip
          append-to-body
          is-interactive
          :placement="'top'"
          :border-color="TooltipBorderColor.Gray">
          <template #trigger>
            <DescriptionIcon class="thesis-collateral-assets-info-cell__description-icon"/>
          </template>
          <div class="thesis-collateral-assets-info-cell__description">
            {{ props.thesisDescription }}
          </div>
        </BaseTooltip>
      </div>
      <div class="thesis-collateral-assets-info-cell__id">
        {{ props.thesisId }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TokenMedia from '@/general-components/TokenMedia.vue'
import type { AssetInListSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import type { Address } from 'viem'
import { useInterval } from '@vueuse/core'
import { ref, watch } from 'vue'
import DescriptionIcon from '@/assets/icons/description-icon.svg'
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'

const getParsedAsset = (asset: AssetInListSchemaWorkaroundBackendSchema) => {
  return new AssetMetadata({
    address: asset.address as Address,
    chainId: asset.chainId,
    category: asset.category,
    id: Number(asset.id),
    image: asset.thumbnailUrl,
    name: asset.name || '',
  })
}

type Props = {
  collateralAssets: AssetInListSchemaWorkaroundBackendSchema[];

  thesisTitle: string;
  thesisId: string;
  thesisDescription: string;

  iconWidth: string;
};

const props = withDefaults(defineProps<Props>(), {
  iconWidth: '2rem',
})

const counter = useInterval(1500)

const assetToShow = ref(0)

watch(counter, () => {
  if (props.collateralAssets.length >= 2) {
    assetToShow.value = (assetToShow.value + 1) % props.collateralAssets.length
  }
})
</script>

<style scoped>
.thesis-collateral-assets-info-cell {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;

  &__description {
    font-family: var(--font-family-supreme);
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
  }

  &__description-icon {
    margin-bottom: -0.25rem;
    margin-left: 0.125rem;
  }

  &__assets {
    display: flex;
    position: relative;
  }

  &__asset {
    display: flex;
    align-items: center;
  }

  &__chain-icon {
    position: absolute;
    bottom: 0;
    right: 0;

    width: 1rem;
    height: 1rem;
  }

  &__info {
    display: flex;
    flex-flow: column nowrap;
  }

  &__name {
    font-family: var(--font-family-supreme);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  &__id {
    font-family: var(--font-family-supreme);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    color: var(--gray-2);
  }

  &__logo {
    border-radius: 50%;
  }
}
</style>
