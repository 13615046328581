<template>
  <div class="filters-form">
    <div class="filters-form__form filters-form--ltv">
      <MinMaxForm
        :set-min="(v) => proposalFiltersStore.actions.updateLtvFilter(v, filters.ltv.to)"
        :set-max="(v) => proposalFiltersStore.actions.updateLtvFilter(filters.ltv.from, v)"
        :min="filters.ltv.from"
        :max="filters.ltv.to"
        :min-schema="minSchema"
        :max-schema="maxSchema"
        :reset-to-default="() => proposalFiltersStore.actions.resetToDefault('ltv')"
        label="LTV:"
        mask="%">
        <template #min="{ field, onFocus, value }">
          <input
            v-maska="maskPercentCappedIntegers(4)"
            :value="value"
            class="filters-form__input filters-form__input--ltv"
            @input="(e) => field.handleChange(Number((e.target as HTMLInputElement).value))"
            @blur="field.handleBlur"
            @focus="onFocus">
        </template>

        <template #max="{ field, onFocus, value }">
          <input
            v-maska="maskPercentCappedIntegers(15)"
            :value="value"
            class="filters-form__input filters-form__input--ltv"
            @input="(e) => field.handleChange(Number((e.target as HTMLInputElement).value))"
            @blur="field.handleBlur"
            @focus="onFocus">
        </template>
      </MinMaxForm>
    </div>

    <div class="filters-form__form filters-form--interest">
      <MinMaxForm
        :set-min="(v) => proposalFiltersStore.actions.updateAprFilter(v, filters.apr.to)"
        :set-max="(v) => proposalFiltersStore.actions.updateAprFilter(filters.apr.from, v)"
        :min="filters.apr.from"
        :max="filters.apr.to"
        :min-schema="minSchema"
        :max-schema="maxSchema"
        :reset-to-default="() => proposalFiltersStore.actions.resetToDefault('apr')"
        label="APR:"
        mask="%">
        <template #min="{ field, value, onFocus }">
          <input
            v-maska="maskPercentCappedIntegers(4)"
            :value="value"
            class="filters-form__input filters-form__input--apr"
            @input="(e) => field.handleChange(Number((e.target as HTMLInputElement).value))"
            @blur="field.handleBlur"
            @focus="onFocus">
        </template>

        <template #max="{ field, value, onFocus }">
          <input
            v-maska="maskPercentCappedIntegers(15)"
            :value="value"
            class="filters-form__input filters-form__input--apr"
            @input="(e) => field.handleChange(Number((e.target as HTMLInputElement).value))"
            @blur="field.handleBlur"
            @focus="onFocus">
        </template>
      </MinMaxForm>
    </div>

    <div class="filters-form__form filters-form__duration">
      <MinMaxForm
        :set-min="(v) => proposalFiltersStore.actions.updateDurationFilter(v, filters.duration.to)"
        :set-max="(v) => proposalFiltersStore.actions.updateDurationFilter(filters.duration.from, v)"
        :min="filters.duration.from"
        :max="filters.duration.to"
        :min-schema="minDurationSchema"
        :max-schema="maxDurationSchema"
        :reset-to-default="() => proposalFiltersStore.actions.resetToDefault('duration')"
        label="Duration:"
        mask=" Days">
        <template #min="{ field, onFocus, value }">
          <input
            v-maska="maskDaysCappedIntegers(4)"
            :value="value"
            class="filters-form__input filters-form__input--duration"
            @input="(e) => field.handleChange(Number((e.target as HTMLInputElement).value))"
            @blur="field.handleBlur"
            @focus="onFocus">
        </template>

        <template #max="{ field, onFocus, value }">
          <input
            v-maska="maskDaysCappedIntegers(6)"
            :value="value"
            class="filters-form__input filters-form__input--duration"
            @input="(e) => field.handleChange(Number((e.target as HTMLInputElement).value))"
            @blur="field.handleBlur"
            @focus="onFocus">
        </template>
      </MinMaxForm>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import MinMaxForm from '@/revamp/components/proposal-filters/MinMaxForm.vue'
import { storeToRefs } from 'pinia'
import { z } from 'zod'
import { maskDaysCappedIntegers, maskPercentCappedIntegers } from '@/revamp/utils/inputs'

const proposalFiltersStore = useProposalFilters()
const { filters } = storeToRefs(proposalFiltersStore)

const minSchema = z.coerce
  .string()
  .transform((val) => Number(String(val).replace(/%/g, '')))
  .refine((val) => val >= 0 && val <= 149, {
    message: 'Value should be between 0 and 149',
  })

const maxSchema = z.coerce
  .string()
  .transform((val) => Number(String(val).replace(/%/g, '')))

const minDurationSchema = z.coerce
  .string()
  .transform((val) => Number(String(val).replace(/Days/g, '')))
  .default('1 Day')
  .refine((val) => val >= 0 && val <= 360, {
    message: 'Value should be between 1 and 360',
  })

const maxDurationSchema = z.coerce
  .string()
  .transform((val) => Number(String(val).replace(/Days/g, '')))
  .default('30 Days')
  .refine((val) => val >= 0 && val <= 36500, {
    message: 'Value should be between 0 and 36500',
  })
</script>

<style scoped>
.filters-form {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media screen and (--small-viewport) {
    flex-flow: row wrap;
    row-gap: 1rem;
    justify-content: space-around;
  }

  @media only screen and (--mobile-viewport) {
    justify-content: left;
  }

  &__input-container {
    position: relative;
    display: inline-block;
  }

  input {
    width: 80px;
    border: 1px solid var(--gray);
    background-color: var(--background-color);

    padding: 0.25rem 0.5rem;
  }

  &__input {
    color: var(--text-color);

    &--ltv,
    &--apr {
      width: 4rem !important;
    }

    &--duration {
      width: 6rem !important;
    }
  }

  &__active-custom {
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    top: -0.35rem;
    right: -0.35rem;
    background-color: var(--primary-color-1);
  }
}
</style>
