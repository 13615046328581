<template>
  <BaseSection
    :heading="heading"
    :is-expandable="false"
    with-gray-background
    is-subsection>
    <template #controls>
      <div class="section-your-lending__checkbox-container">
        <BaseCheckbox
          :model-value="showHistory"
          label="Show History"
          @update:model-value="(v) => (showHistory = v)"/>
      </div>
    </template>

    <UserProposals
      type="lend"
      include-loans
      :show-history="showHistory"/>

    <EmptyTableCTA
      v-if="hasEmptyTableCTA"
      :link-to-other-page="RouteName.RevampLend"
      button-text="Post Lending Proposal"
      heading="Not currently lending"/>
    <BaseEmptyStateText
      v-else-if="hasEmptyState"
      text="This user hasn’t lent any funds"/>
  </BaseSection>
</template>

<script lang="ts" setup>
import BaseSection from '@/general-components/BaseSection.vue'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import { computed, provide, ref } from 'vue'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import RouteName from '@/router/RouteName'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import EmptyTableCTA from '@/general-components/EmptyTableCTA.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { compareAddresses } from '@/utils/utils'
import UserProposals from '@/revamp/components/tables/dashboard/UserProposals.vue'
import { useLocalStorage } from '@vueuse/core'
import { useInjectedProposalsCount, injectedProposalsCountKeyName } from '@/revamp/hooks/useInjectedProposalsCount'

const { dashboardUserAddress } = useDashboard()
const { address: userAddress } = useCustomAccount()

const isDashboardOfConnectedUser = computed(() => {
  return compareAddresses(dashboardUserAddress.value, userAddress.value)
})

const showHistory = useLocalStorage('show-lending-loan-history-new', true)
const hasEmptyState = ref(false)

const { injectedProposalsCount, setInjectedProposalsCount } = useInjectedProposalsCount()
provide(injectedProposalsCountKeyName, {
  injectedProposalsCount,
  setInjectedProposalsCount,
  setHasEmptyState: (v: boolean) => {
    hasEmptyState.value = v
  },
})

const heading = computed(() => {
  if (isDashboardOfConnectedUser.value) {
    return `Your Lending (${injectedProposalsCount.value})`
  }
  return `Lending (${injectedProposalsCount.value})`
})

const hasEmptyTableCTA = computed(() => hasEmptyState.value && isDashboardOfConnectedUser.value)
</script>

<style scoped>
.section-your-lending {
  &__start-lending {
    height: var(--section-controls-height);
  }

  &__checkbox-container {
    height: 2.625rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
