export const ABI = [
  {
    type: 'impl',
    name: 'PwnSimpleLoanImpl',
    interface_name: 'pwn::loan::terms::simple::loan::interface::IPwnSimpleLoan',
  },
  {
    type: 'struct',
    name: 'core::integer::u256',
    members: [
      {
        name: 'low',
        type: 'core::integer::u128',
      },
      {
        name: 'high',
        type: 'core::integer::u128',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::lib::signature_checker::Signature',
    members: [
      {
        name: 'r',
        type: 'core::felt252',
      },
      {
        name: 's',
        type: 'core::felt252',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::loan::types::ProposalSpec',
    members: [
      {
        name: 'proposal_contract',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'proposal_data',
        type: 'core::array::Array::<core::felt252>',
      },
      {
        name: 'proposal_inclusion_proof',
        type: 'core::array::Array::<core::integer::u256>',
      },
      {
        name: 'signature',
        type: 'pwn::loan::lib::signature_checker::Signature',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::loan::types::LenderSpec',
    members: [
      {
        name: 'source_of_funds',
        type: 'core::starknet::contract_address::ContractAddress',
      },
    ],
  },
  {
    type: 'enum',
    name: 'core::bool',
    variants: [
      {
        name: 'False',
        type: '()',
      },
      {
        name: 'True',
        type: '()',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::loan::types::CallerSpec',
    members: [
      {
        name: 'refinancing_loan_id',
        type: 'core::felt252',
      },
      {
        name: 'revoke_nonce',
        type: 'core::bool',
      },
      {
        name: 'nonce',
        type: 'core::felt252',
      },
      {
        name: 'permit_data',
        type: 'core::felt252',
      },
    ],
  },
  {
    type: 'enum',
    name: 'core::option::Option::<core::array::Array::<core::felt252>>',
    variants: [
      {
        name: 'Some',
        type: 'core::array::Array::<core::felt252>',
      },
      {
        name: 'None',
        type: '()',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::loan::types::ExtensionProposal',
    members: [
      {
        name: 'loan_id',
        type: 'core::felt252',
      },
      {
        name: 'compensation_address',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'compensation_amount',
        type: 'core::integer::u256',
      },
      {
        name: 'duration',
        type: 'core::integer::u64',
      },
      {
        name: 'expiration',
        type: 'core::integer::u64',
      },
      {
        name: 'proposer',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'nonce_space',
        type: 'core::felt252',
      },
      {
        name: 'nonce',
        type: 'core::felt252',
      },
    ],
  },
  {
    type: 'enum',
    name: 'pwn::multitoken::library::MultiToken::Category',
    variants: [
      {
        name: 'ERC20',
        type: '()',
      },
      {
        name: 'ERC721',
        type: '()',
      },
      {
        name: 'ERC1155',
        type: '()',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::multitoken::library::MultiToken::Asset',
    members: [
      {
        name: 'category',
        type: 'pwn::multitoken::library::MultiToken::Category',
      },
      {
        name: 'asset_address',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'id',
        type: 'core::felt252',
      },
      {
        name: 'amount',
        type: 'core::integer::u256',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::loan::types::GetLoanReturnValue',
    members: [
      {
        name: 'status',
        type: 'core::integer::u8',
      },
      {
        name: 'start_timestamp',
        type: 'core::integer::u64',
      },
      {
        name: 'default_timestamp',
        type: 'core::integer::u64',
      },
      {
        name: 'borrower',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'original_lender',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'loan_owner',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'accruing_interest_APR',
        type: 'core::integer::u32',
      },
      {
        name: 'fixed_interest_amount',
        type: 'core::integer::u256',
      },
      {
        name: 'credit',
        type: 'pwn::multitoken::library::MultiToken::Asset',
      },
      {
        name: 'collateral',
        type: 'pwn::multitoken::library::MultiToken::Asset',
      },
      {
        name: 'original_source_of_funds',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'repayment_amount',
        type: 'core::integer::u256',
      },
    ],
  },
  {
    type: 'struct',
    name: 'core::byte_array::ByteArray',
    members: [
      {
        name: 'data',
        type: 'core::array::Array::<core::bytes_31::bytes31>',
      },
      {
        name: 'pending_word',
        type: 'core::felt252',
      },
      {
        name: 'pending_word_len',
        type: 'core::integer::u32',
      },
    ],
  },
  {
    type: 'interface',
    name: 'pwn::loan::terms::simple::loan::interface::IPwnSimpleLoan',
    items: [
      {
        type: 'function',
        name: 'claim_loan',
        inputs: [
          {
            name: 'loan_id',
            type: 'core::felt252',
          },
        ],
        outputs: [],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'create_loan',
        inputs: [
          {
            name: 'proposal_spec',
            type: 'pwn::loan::terms::simple::loan::types::ProposalSpec',
          },
          {
            name: 'lender_spec',
            type: 'pwn::loan::terms::simple::loan::types::LenderSpec',
          },
          {
            name: 'caller_spec',
            type: 'pwn::loan::terms::simple::loan::types::CallerSpec',
          },
          {
            name: 'extra',
            type: 'core::option::Option::<core::array::Array::<core::felt252>>',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'extend_loan',
        inputs: [
          {
            name: 'extension',
            type: 'pwn::loan::terms::simple::loan::types::ExtensionProposal',
          },
          {
            name: 'signature',
            type: 'pwn::loan::lib::signature_checker::Signature',
          },
        ],
        outputs: [],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'make_extension_proposal',
        inputs: [
          {
            name: 'extension',
            type: 'pwn::loan::terms::simple::loan::types::ExtensionProposal',
          },
        ],
        outputs: [],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'repay_loan',
        inputs: [
          {
            name: 'loan_id',
            type: 'core::felt252',
          },
          {
            name: 'permit_data',
            type: 'core::felt252',
          },
        ],
        outputs: [],
        state_mutability: 'external',
      },
      {
        type: 'function',
        name: 'ACCRUING_INTEREST_APR_DECIMALS',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u16',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'ACCRUING_INTEREST_APR_DENOMINATOR',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u64',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'DOMAIN_SEPARATOR',
        inputs: [],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'EXTENSION_PROPOSAL_TYPEHASH',
        inputs: [],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'MAX_ACCRUING_INTEREST_APR',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u32',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'MAX_EXTENSION_DURATION',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u64',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'MINUTES_IN_YEAR',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u64',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'MIN_EXTENSION_DURATION',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u64',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'MIN_LOAN_DURATION',
        inputs: [],
        outputs: [
          {
            type: 'core::integer::u64',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'VERSION',
        inputs: [],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_lender_spec_hash',
        inputs: [
          {
            name: 'calladata',
            type: 'pwn::loan::terms::simple::loan::types::LenderSpec',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_loan_repayment_amount',
        inputs: [
          {
            name: 'loan_id',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'core::integer::u256',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_extension_hash',
        inputs: [
          {
            name: 'extension',
            type: 'pwn::loan::terms::simple::loan::types::ExtensionProposal',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_loan',
        inputs: [
          {
            name: 'loan_id',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'pwn::loan::terms::simple::loan::types::GetLoanReturnValue',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_extension_proposal_made',
        inputs: [
          {
            name: 'extension_hash',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'core::bool',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_is_valid_asset',
        inputs: [
          {
            name: 'asset',
            type: 'pwn::multitoken::library::MultiToken::Asset',
          },
        ],
        outputs: [
          {
            type: 'core::bool',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_loan_metadata_uri',
        inputs: [],
        outputs: [
          {
            type: 'core::byte_array::ByteArray',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'get_state_fingerprint',
        inputs: [
          {
            name: 'token_id',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
    ],
  },
  {
    type: 'impl',
    name: 'ERC721Impl',
    interface_name: 'openzeppelin::token::erc721::interface::IERC721Receiver',
  },
  {
    type: 'struct',
    name: 'core::array::Span::<core::felt252>',
    members: [
      {
        name: 'snapshot',
        type: '@core::array::Array::<core::felt252>',
      },
    ],
  },
  {
    type: 'interface',
    name: 'openzeppelin::token::erc721::interface::IERC721Receiver',
    items: [
      {
        type: 'function',
        name: 'on_erc721_received',
        inputs: [
          {
            name: 'operator',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'from',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'token_id',
            type: 'core::integer::u256',
          },
          {
            name: 'data',
            type: 'core::array::Span::<core::felt252>',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
    ],
  },
  {
    type: 'impl',
    name: 'ERC1155Impl',
    interface_name: 'openzeppelin::token::erc1155::interface::IERC1155Receiver',
  },
  {
    type: 'struct',
    name: 'core::array::Span::<core::integer::u256>',
    members: [
      {
        name: 'snapshot',
        type: '@core::array::Array::<core::integer::u256>',
      },
    ],
  },
  {
    type: 'interface',
    name: 'openzeppelin::token::erc1155::interface::IERC1155Receiver',
    items: [
      {
        type: 'function',
        name: 'on_erc1155_received',
        inputs: [
          {
            name: 'operator',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'from',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'token_id',
            type: 'core::integer::u256',
          },
          {
            name: 'value',
            type: 'core::integer::u256',
          },
          {
            name: 'data',
            type: 'core::array::Span::<core::felt252>',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
      {
        type: 'function',
        name: 'on_erc1155_batch_received',
        inputs: [
          {
            name: 'operator',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'from',
            type: 'core::starknet::contract_address::ContractAddress',
          },
          {
            name: 'token_ids',
            type: 'core::array::Span::<core::integer::u256>',
          },
          {
            name: 'values',
            type: 'core::array::Span::<core::integer::u256>',
          },
          {
            name: 'data',
            type: 'core::array::Span::<core::felt252>',
          },
        ],
        outputs: [
          {
            type: 'core::felt252',
          },
        ],
        state_mutability: 'view',
      },
    ],
  },
  {
    type: 'impl',
    name: 'SRC5Impl',
    interface_name: 'openzeppelin::introspection::interface::ISRC5',
  },
  {
    type: 'interface',
    name: 'openzeppelin::introspection::interface::ISRC5',
    items: [
      {
        type: 'function',
        name: 'supports_interface',
        inputs: [
          {
            name: 'interface_id',
            type: 'core::felt252',
          },
        ],
        outputs: [
          {
            type: 'core::bool',
          },
        ],
        state_mutability: 'view',
      },
    ],
  },
  {
    type: 'constructor',
    name: 'constructor',
    inputs: [
      {
        name: 'hub',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'loan_token',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'config',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'revoked_nonce',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'category_registry',
        type: 'core::starknet::contract_address::ContractAddress',
      },
    ],
  },
  {
    type: 'struct',
    name: 'pwn::loan::terms::simple::loan::types::Terms',
    members: [
      {
        name: 'lender',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'borrower',
        type: 'core::starknet::contract_address::ContractAddress',
      },
      {
        name: 'duration',
        type: 'core::integer::u64',
      },
      {
        name: 'collateral',
        type: 'pwn::multitoken::library::MultiToken::Asset',
      },
      {
        name: 'credit',
        type: 'pwn::multitoken::library::MultiToken::Asset',
      },
      {
        name: 'fixed_interest_amount',
        type: 'core::integer::u256',
      },
      {
        name: 'accruing_interest_APR',
        type: 'core::integer::u32',
      },
      {
        name: 'lender_spec_hash',
        type: 'core::felt252',
      },
      {
        name: 'borrower_spec_hash',
        type: 'core::felt252',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanCreated',
    kind: 'struct',
    members: [
      {
        name: 'loan_id',
        type: 'core::felt252',
        kind: 'data',
      },
      {
        name: 'proposal_hash',
        type: 'core::felt252',
        kind: 'data',
      },
      {
        name: 'proposal_contract',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'refinancing_loan_id',
        type: 'core::felt252',
        kind: 'data',
      },
      {
        name: 'terms',
        type: 'pwn::loan::terms::simple::loan::types::Terms',
        kind: 'data',
      },
      {
        name: 'lender_spec',
        type: 'pwn::loan::terms::simple::loan::types::LenderSpec',
        kind: 'data',
      },
      {
        name: 'extra',
        type: 'core::option::Option::<core::array::Array::<core::felt252>>',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanPaidBack',
    kind: 'struct',
    members: [
      {
        name: 'loan_id',
        type: 'core::felt252',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanClaimed',
    kind: 'struct',
    members: [
      {
        name: 'loan_id',
        type: 'core::felt252',
        kind: 'data',
      },
      {
        name: 'defaulted',
        type: 'core::bool',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanExtended',
    kind: 'struct',
    members: [
      {
        name: 'loan_id',
        type: 'core::felt252',
        kind: 'data',
      },
      {
        name: 'original_default_timestamp',
        type: 'core::integer::u64',
        kind: 'data',
      },
      {
        name: 'extended_default_timestamp',
        type: 'core::integer::u64',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::ExtensionProposalMade',
    kind: 'struct',
    members: [
      {
        name: 'extension_hash',
        type: 'core::felt252',
        kind: 'data',
      },
      {
        name: 'proposer',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'extension_proposal',
        type: 'pwn::loan::terms::simple::loan::types::ExtensionProposal',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::VaultPull',
    kind: 'struct',
    members: [
      {
        name: 'asset',
        type: 'pwn::multitoken::library::MultiToken::Asset',
        kind: 'data',
      },
      {
        name: 'origin',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::VaultPush',
    kind: 'struct',
    members: [
      {
        name: 'asset',
        type: 'pwn::multitoken::library::MultiToken::Asset',
        kind: 'data',
      },
      {
        name: 'beneficiary',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::VaultPushFrom',
    kind: 'struct',
    members: [
      {
        name: 'asset',
        type: 'pwn::multitoken::library::MultiToken::Asset',
        kind: 'data',
      },
      {
        name: 'origin',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'beneficiary',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::PoolWithdraw',
    kind: 'struct',
    members: [
      {
        name: 'asset',
        type: 'pwn::multitoken::library::MultiToken::Asset',
        kind: 'data',
      },
      {
        name: 'pool_adapter',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'pool',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'owner',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::PoolSupply',
    kind: 'struct',
    members: [
      {
        name: 'asset',
        type: 'pwn::multitoken::library::MultiToken::Asset',
        kind: 'data',
      },
      {
        name: 'pool_adapter',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'pool',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
      {
        name: 'owner',
        type: 'core::starknet::contract_address::ContractAddress',
        kind: 'data',
      },
    ],
  },
  {
    type: 'event',
    name: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::Event',
    kind: 'enum',
    variants: [
      {
        name: 'VaultPull',
        type: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::VaultPull',
        kind: 'nested',
      },
      {
        name: 'VaultPush',
        type: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::VaultPush',
        kind: 'nested',
      },
      {
        name: 'VaultPushFrom',
        type: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::VaultPushFrom',
        kind: 'nested',
      },
      {
        name: 'PoolWithdraw',
        type: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::PoolWithdraw',
        kind: 'nested',
      },
      {
        name: 'PoolSupply',
        type: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::PoolSupply',
        kind: 'nested',
      },
    ],
  },
  {
    type: 'event',
    name: 'openzeppelin::token::erc721::erc721_receiver::ERC721ReceiverComponent::Event',
    kind: 'enum',
    variants: [],
  },
  {
    type: 'event',
    name: 'openzeppelin::introspection::src5::SRC5Component::Event',
    kind: 'enum',
    variants: [],
  },
  {
    type: 'event',
    name: 'openzeppelin::token::erc1155::erc1155_receiver::ERC1155ReceiverComponent::Event',
    kind: 'enum',
    variants: [],
  },
  {
    type: 'event',
    name: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::Event',
    kind: 'enum',
    variants: [
      {
        name: 'LoanCreated',
        type: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanCreated',
        kind: 'nested',
      },
      {
        name: 'LoanPaidBack',
        type: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanPaidBack',
        kind: 'nested',
      },
      {
        name: 'LoanClaimed',
        type: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanClaimed',
        kind: 'nested',
      },
      {
        name: 'LoanExtended',
        type: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::LoanExtended',
        kind: 'nested',
      },
      {
        name: 'ExtensionProposalMade',
        type: 'pwn::loan::terms::simple::loan::pwn_simple_loan::PwnSimpleLoan::ExtensionProposalMade',
        kind: 'nested',
      },
      {
        name: 'VaultEvent',
        type: 'pwn::loan::vault::pwn_vault::PwnVaultComponent::Event',
        kind: 'nested',
      },
      {
        name: 'ERC721Event',
        type: 'openzeppelin::token::erc721::erc721_receiver::ERC721ReceiverComponent::Event',
        kind: 'flat',
      },
      {
        name: 'SRC5Event',
        type: 'openzeppelin::introspection::src5::SRC5Component::Event',
        kind: 'flat',
      },
      {
        name: 'ERC1155Event',
        type: 'openzeppelin::token::erc1155::erc1155_receiver::ERC1155ReceiverComponent::Event',
        kind: 'flat',
      },
    ],
  },
] as const
