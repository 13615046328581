<template>
  <img
    v-if="shouldBeDisplayed"
    class="search-result-image"
    :src="(image as string)"
    alt=""
    @error="handleOnError">
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import * as defaultIcon from '@/assets/icons/default-asset-icon.svg?url'
import * as defaultErc20ImageUrl from '@/assets/images/default-erc20-logo-placeholder.svg?url'

type Props = {
  image: string | null
  showFallback?: boolean
  isErc20?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showFallback: true,
})

const avatarLoadError = ref(false)
const handleOnError = () => {
  avatarLoadError.value = true
}

const image = computed(() => {
  if (!props.image || avatarLoadError.value || props.image?.includes('default_erc20_asset_image.svg')) {
    if (props.isErc20) {
      return defaultErc20ImageUrl.default
    }
    return defaultIcon.default
  } else {
    return props.image
  }
})

const shouldBeDisplayed = computed(() => {
  if (avatarLoadError.value || !props.image) {
    return props.showFallback
  }
  return true
})

</script>
