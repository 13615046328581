<template>
  <div :class="['notification-type-icon', `notification-type-icon--${variant}`]">
    <div
      v-if="hasLoader"
      :class="['notification-type-icon__loader',`notification-type-icon__loader--${variant}`]"/>
    <svg
      v-else-if="!hasLoader"
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"><g
        clip-path="url(#a)"
        fill="currentColor"><path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.97 14.4a6.4 6.4 0 1 0 0-12.8 6.4 6.4 0 0 0 0 12.8zm0 1.6a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/><path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.97 3.2a.8.8 0 0 1 .8.8v4.8a.8.8 0 0 1-1.6 0V4a.8.8 0 0 1 .8-.8z"/><circle
              cx="7.97"
              cy="12"
              r=".8"/></g><defs><clipPath id="a"><path d="M0 0h16v16H0z"/></clipPath></defs></svg>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { NotificationTypeEnumBackendSchema } from '@/modules/common/backend/generated'

const props = defineProps<{variant: NotificationTypeEnumBackendSchema}>()
const { variant } = toRefs(props)

const hasLoader = computed(() => [NotificationTypeEnumBackendSchema.pending, NotificationTypeEnumBackendSchema.success].some(notificationVariant => notificationVariant === variant.value))
</script>

<style scoped>
.notification-type-icon {
  display: flex;
  align-items: center;
  width: 16px;
  position: relative;

  &--info {
    color: var(--text-color);
  }

  &--error {
    color: var(--negative-1);
  }

  &--danger {
    color: var(--danger-1);
  }

  &__loader {
    width: 16px;
    height: 16px;

    border: 2px solid var(--primary-color-1);
    border-radius: 50%;

    &--success {
      border-color: var(--primary-color-1);
    }

    &--pending {
      position: absolute;
      top: 50%;
      left: 50%;

      border-color: var(--warning);
      border-top-color: transparent;

      @keyframes rotate {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }

        100% {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
      animation: rotate 1s linear infinite;
    }
  }
}
</style>
