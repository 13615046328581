<template>
  <div class="collateral-info__wrapper">
    <div
      :class="['collateral-info__border', {[`collateral-info__border--${borderColor}`]: !!props.borderColor}]">
      <AssetTooltip
        v-if="hasTooltip"
        :asset="collateral"
        tooltip-placement="top">
        <TokenMedia
          :is-lazy-loaded="isLazyLoaded"
          class="collateral-info__logo"
          width="60"
          height="60"
          :token="collateral"/>

        <AssetChainIcon
          class="collateral-info__chain-icon"
          :chain-id="collateral.chainId"/>
      </AssetTooltip>
      <div
        v-else
        class="collateral-info__token-media-wrapper">
        <TokenMedia
          :is-lazy-loaded="isLazyLoaded"
          width="60"
          height="60"
          class="collateral-info__logo"
          :token="collateral"/>

        <AssetChainIcon
          class="collateral-info__chain-icon-tooltip"
          :chain-id="collateral.chainId"/>
      </div>
      <div class="collateral-info__description">
        <div class="collateral-info__primary-info-wrapper">
          <SafeText
            :class="['collateral-info__primary-info', {'collateral-info__primary-info--missing': collateral?.isPrimaryInfoMissing, 'collateral-info__primary-info--no-amount-info': !collateral?.isFungible}]"
            :has-tooltip="hasExplanationTooltips"
            :text="collateral?.primaryInfoWithoutAmount"/>
          <span
            v-if="collateral.isFungible && hasAmountInPrimaryInfo"
            class="collateral-info__primary-info-amount-wrapper">(
            <TokenAndEthAndUsdPriceLabel
              is-only-amount
              :token="collateral"/>
            )</span>
        </div>
        <div class="collateral-info__secondary-info-wrapper">
          <SafeText
            :has-tooltip="hasExplanationTooltips"
            :class="['collateral-info__secondary-info', {'collateral-info__secondary-info--missing': collateral?.isSecondaryInfoMissing}]"
            :text="collateral?.secondaryInfo"/>
          <VerificationBadge
            :token="collateral"
            :has-tooltip="hasExplanationTooltips"
            is-generic-verification
            :display-text="false"
            class="collateral-info__verification-badge"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SafeText from '@/general-components/SafeText.vue'
import { toRefs } from 'vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import AssetTooltip from '@/general-components/AssetTooltip.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import VerificationBadge from '@/general-components/VerificationBadge.vue'
import TokenAndEthAndUsdPriceLabel from '@/general-components/TokenAndEthAndUsdPriceLabel.vue'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'

interface Props {
  collateral: AssetWithAmount,
  borderColor?: 'teal' | 'gray'
  hasTooltip? : boolean
  isLazyLoaded?: boolean
  hasAmountInPrimaryInfo?: boolean
  hasExplanationTooltips?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasTooltip: true,
  hasAmountInPrimaryInfo: true,
  hasExplanationTooltips: true,
})

const { collateral, borderColor, hasTooltip, isLazyLoaded } = toRefs(props)

</script>

<style scoped>
.collateral-info {
  &__wrapper {
    max-width: 100%;
    display: flex;
    align-items: center;
  }

  &__secondary-info-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1rem;
    padding-right: 2rem;
  }

  &__border {
    max-width: 100%;
    display: inherit;
    align-items: inherit;
  }

  &__border--teal {
    border: solid 1px var(--primary-color-1);
    padding: 0.8rem 4rem 0.8rem 0.8rem;
  }

  &__border--gray {
    border: solid 1px var(--gray);
    padding: 0.8rem 4rem 0.8rem 0.8rem;
  }

  &__logo {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    object-fit: contain;
    margin-right: 0.75rem;
  }

  &__description {
    min-width: 0;
  }

  &__primary-info {
    display: block;
    font-size: 0.875rem;
    line-height: 1.75rem;
    color: inherit;
    width: unset;

    &--missing {
      color: var(--negative-1);
    }

    &--no-amount-info {
      padding-right: 2rem;
    }
  }

  &__primary-info-amount-wrapper {
    display: inline-flex;
    align-items: center;
    margin-left: 0.75rem;
    padding-right: 2rem;
  }

  &__secondary-info {
    display: block;
    font-size: 0.75rem;
    color: inherit;
    width: auto;

    &--missing {
      color: var(--negative-1);
    }
  }

  &__primary-info-wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }

  &__token-media-wrapper {
    position: relative;
  }

  &__chain-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    bottom: 0;
    right: 0.5rem;

    &-tooltip {
      width: 2rem;
      height: 2rem;
      position: absolute;
      bottom: 0;
      right: 0.5rem;
      padding: 0.2rem;
    }
  }
}
</style>
