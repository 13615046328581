import StoreIds from '@/constants/storeIds'
import { defineStore } from 'pinia'
import { markRaw, ref } from 'vue'
import type { FunctionalComponent, Raw, SVGAttributes } from 'vue'
import EthereumIcon from '@/assets/icons/ethereum-white.svg'
import USDIcon from '@/assets/icons/usd-oxygen-mono.svg'
import { useLocalStorage } from '@vueuse/core'

type CurrencyOption = {
  label: string;
  icon: Raw<FunctionalComponent<SVGAttributes>>;
}

export const useCurrencySwitcherStore = defineStore(StoreIds.currencySwitcher, () => {
  const currencyOptions: CurrencyOption[] = [
    {
      label: 'USD',
      icon: markRaw(USDIcon),
    },
    {
      label: 'ETH',
      icon: markRaw(EthereumIcon),
    },
  ]
  const selectedOptionInStorage = useLocalStorage('selected-currency', currencyOptions[0].label)
  const selectedCurrency = ref(
    currencyOptions.find((option) => option.label === selectedOptionInStorage.value) || currencyOptions[0])

  const onCurrencySwitch = (value: CurrencyOption) => {
    selectedCurrency.value = currencyOptions.find((option) => option.label === value?.label) || selectedCurrency.value

    selectedOptionInStorage.value = selectedCurrency.value.label
  }

  return {
    currencyOptions,
    selectedCurrency,
    onCurrencySwitch,
  }
})
