import useApprove from '@/modules/common/assets/useApprove'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { ref } from 'vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { maxUint256 } from 'viem'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { useConnectedAccountTypeStore } from '@/modules/common/web3/useConnnectedAccountTypeStore'

const isBundleApproved = ref(false)

export default function useBundleApprove() {
  const { isApproved, approve } = useApprove()

  const checkAssetApprovalForBundler = async (asset: AssetWithAmount): Promise<boolean> => {
    if (asset.isNativeToken) {
      // TODO does this make sense?
      return true
    }

    const assetAmount = asset.amountInputRaw === 0n ? asset.maxAvailableAmountRaw : asset.amountInputRaw
    const bundlerContract = CHAINS_CONSTANTS[asset.chainId].tokenBundlerContract
    if (!bundlerContract) {
      throw new Error(`Bundler contract not found for chain ${asset.chainId}`)
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    return await isApproved(asset.chainId, asset.category, asset.address, getAccount(pwnWagmiConfig).address!, bundlerContract, assetAmount)
  }

  const checkSelectedAssetsApprovalsForBundler = async (assetsToCheck: AssetWithAmount[]): Promise<boolean> => {
    const assets = await Promise.all(assetsToCheck.map(checkAssetApprovalForBundler))
    return assets.every(asset => asset)
  }

  const approveAssetForBundlerIfNeeded = async (asset: AssetWithAmount): Promise<boolean> => {
    let isAssetApproved = await checkAssetApprovalForBundler(asset)

    if (!isAssetApproved) {
      const allowanceAmountToCheck = asset.amountInputRaw === 0n ? asset.maxAvailableAmountRaw : asset.amountInputRaw

      if (!CHAINS_CONSTANTS[asset.chainId].tokenBundlerContract) {
        throw new Error(`Bundler contract not found for chain ${asset.chainId}`)
      }

      isAssetApproved = await approve({
        asset,
        spender: CHAINS_CONSTANTS[asset.chainId].tokenBundlerContract!,
        amount: maxUint256,
        minAllowanceAmountToCheck: allowanceAmountToCheck,
      })
    }
    return isAssetApproved
  }

  const approveAssetsForBundlerIfNeeded = async (assetsToApprove: AssetWithAmount[]): Promise<boolean> => {
    const uniqueAssets = [
      ...new Map(assetsToApprove.map((item) => [item.address.toLowerCase(), item])).values(),
    ]

    // TODO why this if?
    if (useConnectedAccountTypeStore().isConnectedContractWallet) {
      // TODO try send as batch transaction. Gnosis safe accept one transaction request in time. The next request is ignored if pending modal with submit transaction.
      for (const asset of uniqueAssets) {
        await approveAssetForBundlerIfNeeded(asset)
      }

      // TODO await here?
      return await checkSelectedAssetsApprovalsForBundler(assetsToApprove)
    }

    const assetApprovals = await Promise.all(uniqueAssets.map(approveAssetForBundlerIfNeeded))
    return assetApprovals.every(Boolean)
  }

  return {
    approveAssetsForBundlerIfNeeded,
    checkSelectedAssetsApprovalsForBundler,
    isBundleApproved,
  }
}
