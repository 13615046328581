export enum SelectBorderType {
  Solid = 'solid',
  Dashed = 'dashed',
  None = 'none'
}

export interface OptionItem<ValueType = unknown, TrackByType = string | number, LabelType = string> {
  value?: ValueType;
  trackBy?: TrackByType; // must be unique for the item in options array
  label?: LabelType;
  isSelected?: boolean;
}

export enum DropdownCaretType {
  Default = 'default',
  None = 'none',
}
