<template>
  <BaseModal
    v-model:is-open="isOpen"
    heading-align="left"
    heading="Follow Address">
    <template #trigger>
      <NotificationCenterSettingsCustomizeButton
        :is-customizeable="userNotificationSettings?.followUsers"
        @click="handleClick"/>
    </template>

    <template #body>
      <div class="follow-address-modal__body">
        <div class="follow-address-modal__input-container">
          <div class="follow-address-modal__input">
            <BaseInput
              v-model="input"
              :is-valid-input="displayWarningText"
              placeholder="Wallet Address / ENS Name"
              warning-text="Please enter a valid address or ENS"/>
          </div>
          <!-- PENDING RESOLUTION FROM SLACK CONVO https://pwn-xyz.slack.com/archives/C049GV6J12L/p1687347749515249 -->
          <!-- <div class="follow-address-modal__icon">
            <img
              src="@/assets/icons/paste.svg?url"
              alt="paste">
          </div> -->
        </div>
        <BaseButton
          :variant="ButtonVariant.Outline"
          :is-disabled="!isValidInput"
          button-text="Add +"
          @on-button-click="addAddressToFollow"/>
      </div>
      <div
        v-if="displayFollowedAddressesTitle"
        class="follow-address-modal__followed-addresses">
        Followed Addresses:
      </div>
      <div class="follow-address-modal__address-list-container">
        <BaseButton
          v-for="(address, i) in userNotificationSettings?.followedUsers"
          :key="i"
          :variant="ButtonVariant.Outline"
          :button-text="getButtonText(address)"
          @on-button-click="removeAddressToFollow(address)">
          <template #icon>
            <CloseIconSvg alt="close"/>
          </template>
        </BaseButton>
      </div>
      <div class="follow-address-modal__button-container">
        <BaseButton
          button-text="Discard"
          :color="ButtonColor.White"
          :variant="ButtonVariant.Underline"
          @on-button-click="isOpen = false"/>
        <BaseButton
          button-text="Save Settings"
          @on-button-click="saveSettings"/>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import NotificationCenterSettingsCustomizeButton from '@/modules/pages/notification-center/settings/NotificationCenterSettingsCustomizeButton.vue'
import { computed, ref } from 'vue'
import BaseInput from '@/general-components/BaseInput.vue'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import { asyncComputed } from '@vueuse/core'
import { isEns, isValidAddressOrEns, shortenAddress } from '@/utils/utils'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'
import WalletProfile from '@/modules/common/assets/WalletProfile'
import { useEnsStore } from '@/modules/common/web3/useEnsStore'
import CloseIconSvg from '@/assets/icons/close-primary-color.svg'
import { isAddress } from 'viem'

const { userNotificationSettings, updateNotificationSettings } = useNotificationCenterSettings()

const isOpen = ref(false)
const input = ref('')
// @ts-expect-error FIXME: strictNullChecks
const userToFollow = ref<WalletProfile>(new WalletProfile({ walletAddress: null }))
const addressesToFollow = ref<WalletProfile[]>(userNotificationSettings.value?.followedUsers ?? [])
const isValidInput = asyncComputed<boolean>(async() => await isValidAddressOrEns(input.value), undefined)
const displayWarningText = computed(() => isValidInput.value || !input.value)
const displayFollowedAddressesTitle = computed(() => addressesToFollow.value?.length > 0)

const handleClick = () => {
  if (!userNotificationSettings.value?.followUsers) return
  isOpen.value = true
}

const addAddressToFollow = async () => {
  if (isEns(input.value)) {
    // @ts-expect-error FIXME: strictNullChecks
    userToFollow.value.walletAddress = await useEnsStore().resolveEnsName(input.value)
    userToFollow.value.ensName = input.value
  } else if (isAddress(input.value)) {
    userToFollow.value.walletAddress = input.value
  } else {
    // TODO any notification here?
    return
  }

  // @ts-expect-error FIXME: strictNullChecks
  userNotificationSettings?.value.followedUsers.push(userToFollow.value)
  input.value = ''
  // @ts-expect-error FIXME: strictNullChecks
  userToFollow.value = new WalletProfile({ walletAddress: null })
}

const removeAddressToFollow = (addressToRemove: WalletProfile) => {
  // @ts-expect-error FIXME: strictNullChecks
  const index = userNotificationSettings?.value.followedUsers.findIndex(address => addressToRemove.ensName === address.ensName || addressToRemove.walletAddress === address.walletAddress)
  // @ts-expect-error FIXME: strictNullChecks
  userNotificationSettings?.value.followedUsers.splice(index, 1)
}

const getButtonText = (user: WalletProfile) => {
  return user.ensName ? user.ensName : shortenAddress(user.walletAddress)
}

const saveSettings = () => {
  updateNotificationSettings()
  isOpen.value = false
}
</script>

<style scoped>
.follow-address-modal {
  &__body {
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__input-container {
    display: flex;
    width: 70%;
    min-width: 20rem;
  }

  &__input {
    width: 100%;
  }

  &__icon {
    display: flex;
    margin-left: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__followed-addresses {
    margin-bottom: 0.75rem;
  }

  &__address-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 2.5rem;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
