import { computed, ref } from 'vue'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import { SORT_OPTIONS_LOOKUP, SortOption } from '@/modules/pages/token-bundler/unpack-bundle/section-choose-bundle/ChooseBundleTableDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { filterAssetsInSearchResults } from '@/utils/search'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { storeToRefs } from 'pinia'

const searchTerm = ref('')

const selectedSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-bundle-assets',
  { id: SortOption.Name, direction: SortDirection.Descending, viewName: 'sort-option-bundle-assets' },
  Object.keys(SORT_OPTIONS_LOOKUP),
))

export default function useChooseBundle() {
  const userAssetsStore = useUserAssetsStore()
  const { userNfts } = storeToRefs(userAssetsStore)

  const userBundles = computed(() => {
    return userNfts.value.filter(userNft => userNft.isTokenBundle)
  })

  const filterBundlesByName = (): AssetWithAmount[] => {
    return filterAssetsInSearchResults(userBundles.value, searchTerm.value)
  }

  const sortedBundles = computed((): AssetWithAmount[] => {
    const bundles = filterBundlesByName()
    return SORT_OPTIONS_LOOKUP[selectedSortOption.value.id](bundles, selectedSortOption.value.direction)
  })

  return {
    selectedSortOption,
    sortedBundles,
    searchTerm,
  }
}
