<template>
  <div class="section-properties">
    <BaseAssetPageSection
      icon="properties"
      label="Properties"
      :has-data="hasData"
      :is-fetching="isLoadingBasicAssetDetails">
      <template #content>
        <div class="section-properties__content">
          <div
            v-for="trait in allTraits"
            :key="trait.title"
            class="section-properties__property-cell">
            <div class="section-properties__property-name">
              <SafeText
                :text="trait.title"
                :allow-click-through="false"/>
            </div>
            <div class="section-properties__property-value">
              <SafeText
                :text="trait.value"
                :allow-click-through="false"/>
            </div>
            <div
              v-if="AssetTraits.isStringTrait(trait) && trait.rarityInPercentage"
              class="section-properties__property-rarity">
              {{ trait.rarityInPercentage }}
            </div>
          </div>
        </div>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import { computed } from 'vue'
import type { NumericTrait, StringTrait } from '@/modules/pages/asset/nft-page/types/AssetTraits'
import AssetTraits from '@/modules/pages/asset/nft-page/types/AssetTraits'
import SafeText from '@/general-components/SafeText.vue'

const { asset, isLoadingBasicAssetDetails } = useAssetPage()

// todo display here only string traits, move numeric traits with max value to the "Levels" section (see designs in Figma for more details)
const allTraits = computed<Array<StringTrait | NumericTrait>>(() => asset.value?.traits?.allTraits || [])

const hasData = computed(() => allTraits.value?.length > 0)
</script>

<style scoped>
.section-properties {
  &__content {
    --column-gap: 1.25rem;
    --column-count: 2;
    padding: 0.75rem;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - (var(--column-gap) / var(--column-count))));
    gap: 0.75rem var(--column-gap);
    overflow: auto;
    min-height: 7rem;
    max-height: 12.7rem;
  }

  &__property-cell {
    padding: 0.5rem;
    text-align: center;
    background-image: var(--border-white-dashed);
  }

  &__property-name {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: var(--gray);
  }

  &__property-value {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__property-rarity {
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: var(--gray);
  }
}

</style>

<style>
.nouns-subdomain .section-properties__property-cell {
  filter: invert(1);

  --text-color: var(--gray-3);
}
</style>
