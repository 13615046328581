<template>
  <BaseModal
    v-model:is-open="isOpenWcModalStep2"
    :size="ModalSize.Large"
    :heading-icon="walletConnectIconBlueBg.default"
    heading="WalletConnect">
    <template #body>
      <br>
      <div class="wc-modal-step-2__point">
        <img
          width="45"
          src="@/assets/icons/1-in-dashed-box.svg?url"
          alt="number point"> Connect your wallet into
        <span
          class="link link--primary"
          @click="handleGoToGnosisSafe">
          Safe™
        </span>
      </div>
      <div class="wc-modal-step-2__point">
        <img
          width="45"
          src="@/assets/icons/2-in-dashed-box.svg?url"
          alt="number point"> Select your PWN Safe address in the left menu.
      </div>
      <div class="wc-modal-step-2__point">
        <img
          width="45"
          src="@/assets/icons/3-in-dashed-box.svg?url"
          alt="number point"> Click the “Use WalletConnect” button.
      </div>
      <div class="wc-modal-step-2__point">
        <img
          width="45"
          src="@/assets/icons/4-in-dashed-box.svg?url"
          alt="number point"> Follow the Safe™ instructions.
      </div>

      <p class="wc-modal-step-2__text">
        If you wish to use your PWN Safe on PWN, click on the following button:
      </p>
      <BaseButton
        class="wc-modal-step-2__wc-button-wrapper"
        :variant="ButtonVariant.Outline"
        :color="ButtonColor.White"
        is-icon-before-text
        @on-button-click="handleConnectWalletConnect">
        <template #icon>
          <WalletConnectIcon/>
        </template>
      </BaseButton>
      <p class="wc-modal-step-2__text">
        Copy & paste the QR address into the Safe™ WalletConnect module.
      </p>
      <div class="wc-modal-step-2__info-image-container">
        <img
          width="770"
          class="wc-modal-step-2__info-image"
          src="@/assets/images/pwn-safe-wallet-connect-b.png"
          alt="connect pwn safe with WalletConnect">
      </div>
      <div class="wc-modal-step-2__buttons-bottom">
        <BaseButton
          :variant="ButtonVariant.Underline"
          :color="ButtonColor.White"
          button-text="Cancel"
          @on-button-click="handleCloseModal"/>

        <BaseButton
          :color="ButtonColor.Primary"
          :variant="ButtonVariant.Outline"
          button-text="Go to Safe™"
          is-icon-before-text
          @on-button-click="handleGoToGnosisSafe">
          <template #icon>
            <img
              class="wc-modal-step-2__gnosis-safe-logo"
              src="@/assets/icons/gnosis-safe-logo.svg?url"
              alt="safe logo">
          </template>
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import ModalSize from '@/general-components/ModalSize'
import * as walletConnectIconBlueBg from '@/assets/icons/wallet-connect-blue-bg.svg'
import BaseButton, { ButtonColor, ButtonVariant } from '@/general-components/BaseButton.vue'
import { disconnect } from '@wagmi/vue/actions'
import usePwnSafeDashboard from '@/modules/pages/pwn-safe/pwn-safe-dashboard/usePwnSafeDashboard'
import linkTo from '@/constants/links'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import WalletConnectIcon from '@/assets/icons/wallet-connect-logo-white-plus-text.svg'

const { isOpenWcModalStep2 } = usePwnSafeDashboard()
const handleCloseModal = () => {
  isOpenWcModalStep2.value = false
}

const handleConnectWalletConnect = async () => {
  await disconnect(pwnWagmiConfig)
  // TODO does this work?
  useWeb3Modal().open()
  isOpenWcModalStep2.value = false
}

const handleGoToGnosisSafe = () => {
  window.open(linkTo.safe)
}

</script>

<style scoped>
.wc-modal-step-2 {
  &__text,
  &__point {
    line-height: 1.5rem;
    font-size: 1rem;
  }

  &__text {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__point {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  &__wallet-connect-icon {
    width: 1.6875rem;
    height: 1rem;
  }

  &__buttons-bottom {
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 1rem;
  }

  &__wc-button-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__info-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
  }

  &__info-image {
    margin: auto;
  }

  &__gnosis-safe-logo {
    width: 1.25rem;
    height: 1.25rem;
  }

  @media only screen and (--mobile-viewport) {
    &__info-image {
      width: 100%;
    }
  }
}
</style>
