import type { BreadcrumbActiveStep } from '@/general-components/IBreadcrumbsHeader'
import RouteName from '@/router/RouteName'

const UNPACK_BUNDLE_STEPS_DEFINITION: BreadcrumbActiveStep[] = [
  {
    stepNumber: 1,
    headingText: 'Choose Bundle',
    hint: 'Please choose the bundle you want to unpack.',
    stepName: '1. Choose Bundle',
    route: RouteName.UnpackBundleChooseBundle,
  },
  {
    stepNumber: 2,
    headingText: 'Unpack Bundle',
    stepName: '2. Unpack Bundle',
    route: RouteName.UnpackBundleSubmit,
  },
]
export default UNPACK_BUNDLE_STEPS_DEFINITION
