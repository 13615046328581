<template>
  <div class="pwn-safe-transfer-modal-confirmation-step__container">
    <p class="pwn-safe-transfer-modal-confirmation-step__success-message">
      {{ successMessage }}
    </p>
    <div class="pwn-safe-transfer-modal-confirmation-step__buttons-container">
      <BaseButton
        v-if="!isTransferToCustomAddress"
        class="pwn-safe-transfer-modal-confirmation-step__button"
        :button-text="backToSenderWalletButtonText"
        :color="ButtonColor.White"
        :variant="ButtonVariant.Underline"
        @on-button-click="closeModal"/>
      <BaseButton
        class="pwn-safe-transfer-modal-confirmation-step__button"
        :button-text="goToReceiverWalletButtonText"
        @on-button-click="goToReceiverWalletButtonAction"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, { ButtonVariant, ButtonColor } from '@/general-components/BaseButton.vue'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import { computed } from 'vue'
import usePwnSafeTransferModal from '@/modules/pages/pwn-safe/transfer/modal/usePwnSafeTransferModal'
import { capitalizeFirstLetter } from '@/utils/utils'
import { useRouter } from 'vue-router'
import RouteName from '@/router/RouteName'

const router = useRouter()

const { toWallet, fromWallet, isTransferToPwnSafe, isTransferToCustomAddress } = usePwnSafeTransfer()
const { transferringAssetsInfo, closeModal } = usePwnSafeTransferModal()

const successMessage = computed(() => {
  return `Success! ${capitalizeFirstLetter(transferringAssetsInfo.value)} has been transferred to ${toWallet.value?.name}`
})

const backToSenderWalletButtonText = computed(() => `Go back to ${fromWallet.value?.name}`)
const goToReceiverWalletButtonText = computed(() => {
  if (isTransferToCustomAddress.value) {
    return `Go back to ${fromWallet.value?.name}`
  } else {
    return `Go to ${toWallet.value?.name}`
  }
})

const goToReceiverWalletButtonAction = () => {
  closeModal()

  if (isTransferToPwnSafe.value) {
    router.push({
      name: RouteName.PwnSafeDetailAssets,
      params: {
        chainId: toWallet.value?.chainId,
        safeAddress: toWallet.value?.safeAddress,
      },
    })
  } else if (!isTransferToCustomAddress.value) {
    router.push({ name: RouteName.PwnSafeCurrentWalletAssets })
  }
}
</script>

<style scoped>
.pwn-safe-transfer-modal-confirmation-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 2rem;
  }

  &__success-message {
    color: var(--positive-bright);
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button:only-child {
    margin-left: auto;
  }
}
</style>
