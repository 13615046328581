<template>
  <div class="section-offers">
    <BaseAssetPageSection
      icon="cart"
      question-mark-tooltip="Active offers from different NFT marketplaces for this asset"
      :is-fetching="isFetchingOffers"
      :has-data="hasData"
      label="Purchase Offers">
      <template #content>
        <div class="section-offers__content">
          <BaseTable
            :columns="TABLE_DEFINITION"
            :variant="TableVariant.AssetPage"
            :items="sortedOffers"
            table-max-height="254px"
            table-min-width="700px"
            :is-fetching="isFetchingOffers"
            @on-row-click="goToMarketplaceLink">
            <template #price="{ item: offer }">
              <TokenAndEthAndUsdPriceLabel
                class="section-offers__price"
                :token="offer.orderAsset"/>
            </template>

            <template #floorDifferenceHead>
              <div class="section-offers__floor-difference-head">
                <QuestionMarkTooltip
                  tooltip-text="The percentage amount increase/decrease from the entire collection floor"/>
              </div>
            </template>

            <template #floorDifference="{ item: offer }">
              <div
                v-if="displayFloorPriceDifference(offer)"
                :class="['section-offers__floor-difference', getFloorDifferenceCSSClass(displayFloorPriceDifference(offer))]">
                {{ handleFloorDifference(displayFloorPriceDifference(offer)) }}
              </div>
            </template>

            <template #expiration="{ item: offer }">
              <div
                v-if="offer.expiration"
                class="section-offers__expiration">
                {{ DateUtils.displayDate(offer.expiration) }}
              </div>
            </template>

            <template #from="{ item: offer }">
              <div class="section-offers__ens">
                <img
                  v-if="offer.marketplace === DataSourceType.OPENSEA"
                  class="section-offers__from-icon"
                  src="@/assets/icons/opensea.svg?url"
                  alt="opensea">
                <img
                  v-else-if="offer.marketplace === DataSourceType.LOOKSRARE"
                  class="section-offers__from-icon"
                  src="@/assets/icons/looksrare.svg?url"
                  alt="opensea">
                <div class="section-offers__ens-name-wrap">
                  <CryptoAddress
                    :address="offer.creatorAddress"
                    :chain-id="asset?.chainId"/>
                </div>
              </div>
            </template>
          </BaseTable>
        </div>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import BaseTable from '@/general-components/BaseTable.vue'
import { TABLE_DEFINITION } from '@/modules/pages/asset/nft-page/SectionOffers/SectionOffersTableDefinitions'
import DateUtils from '@/utils/DateUtils'
import TableVariant from '@/general-components/TableVariant'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import useSectionOffers from '@/modules/pages/asset/nft-page/SectionOffers/useSectionOffers'
import TokenAndEthAndUsdPriceLabel from '@/general-components/TokenAndEthAndUsdPriceLabel.vue'
import { functionExists } from '@/utils/utils'
import { getNftDetailLinkOnRelatedMarketplace } from '@/utils/chain'
import type { NFTOrder } from '@/modules/common/assets/typings/NFTOrder'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import { computed } from 'vue'
import useSectionCollectionStats from '@/modules/pages/asset/nft-page/SectionCollectionStats/useSectionCollectionStats'

const { asset } = useAssetPage()
const { isFetchingOffers, sortedOffers } = useSectionOffers()
const { collectionStats } = useSectionCollectionStats()

const hasData = computed(() => sortedOffers.value.length > 0)

const goToMarketplaceLink = (offer: NFTOrder) => {
  // @ts-expect-error FIXME: strictNullChecks
  return window.open(getNftDetailLinkOnRelatedMarketplace(offer.marketplace, asset.value.chainId, asset.value.address, asset.value.tokenId), '_blank')
}

// until we cache floor price on BE for easy calculations of this, we are calculating floor price difference on FE
const displayFloorPriceDifference = (offer: NFTOrder): string => {
  if (offer.floorPriceDifferencePercentage) {
    // TODO check formatting
    return `${offer.floorPriceDifferencePercentage}%`
  }

  if (!collectionStats.value?.floorPrice?.ethAmount || !offer?.orderAsset?.appraisal?.price?.ethAmount) {
    return ''
  }

  const difference = Number(offer.orderAsset.appraisal.price.ethAmount) / Number(collectionStats.value.floorPrice.ethAmount)
  const differencePercentage = (difference * 100) - 100
  return `${differencePercentage.toFixed(2)}%`
}

const handleFloorDifference = (floorDifference: string): string => {
  if (!functionExists(floorDifference?.startsWith)) {
    return ''
  }
  return `${floorDifference} ${floorDifference.startsWith('-') ? 'Below' : 'Above'}`
}

const getFloorDifferenceCSSClass = (floorDifference: string): string => {
  if (!functionExists(floorDifference?.startsWith)) {
    return ''
  }
  return `section-offers__floor-difference--${floorDifference?.startsWith('-') ? 'negative' : 'positive'}`
}
</script>

<style scoped>
.section-offers {
  &__content {
    height: var(--default-asset-section-height);
    position: relative;
  }

  &__question-mark {
    cursor: help;
    line-height: 1.375rem;
    font-size: 0.75rem;
    color: var(--gray);
  }

  &__floor-difference {
    &--negative {
      color: var(--danger-1);
    }

    &--positive {
      color: var(--positive-bright);
    }
  }

  &__ens {
    display: flex;
    align-items: center;
  }

  &__ens-name-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__from-icon {
    width: 0.875rem;
    height: 0.875rem;
    min-width: 0.875rem;
    margin-right: 0.5rem;
  }

  &__floor-difference-head {
    margin-left: 0.3rem;
    line-height: 1;
  }
}
</style>
