import type { BaseOffer } from '@/modules/common/pwn/offers/OfferClasses'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import { AssetWithAmount, DesiredLoanTerms } from '@/modules/common/assets/AssetClasses'
import type { SupportedChain } from '@/constants/chains/types'
import type BaseLoanContract from '@/modules/common/pwn/contracts/BaseLoanContract'
import DateUtils from '@/utils/DateUtils'
import type { DurationUnit } from 'luxon'
import { DateTime } from 'luxon'
import BetaLoanContract from '@/modules/common/pwn/contracts/beta/BetaLoanContract'
import V1SimpleLoanSimpleRequestContract from '@/modules/common/pwn/contracts/v1/V1SimpleLoanSimpleRequestContract'
import type { LoanExtensionRequest } from './LoanExtensionRequest'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { compareAddresses } from '@/utils/utils'
import ltv from '@/utils/ltv'
import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import V1SimpleLoanContract from '@/modules/common/pwn/contracts/v1/V1SimpleLoanContract'
import type { UnixTimestamp } from '@/modules/common/typings/customTypes'
import type { Address, TransactionReceipt } from 'viem'
import type { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
// import V1_2SimpleLoanSimpleProposalContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanSimpleProposalContract'
// import V1_2SimpleLoanDutchAuctionProposalContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanDutchAuctionProposalContract'
// import V1_2SimpleLoanContract from '@/modules/common/pwn/contracts/v1.2/V1_2SimpleLoanContract'
// import V1_2RevokedNonceContract from '@/modules/common/pwn/contracts/v1.2/V1_2RevokedNonceContract'
// import { DEFAULT_LOAN_DURATION_IN_DAYS } from '@/constants/loans'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export const BETA_LOAN_TYPE = 'pwn_contracts.betaloan'
export const V1_1_LOAN_TYPE = 'pwn_contracts.v1_1simpleloan'
export const V1_2_LOAN_TYPE = 'pwn_contracts.v1_2simpleloan'

export const ALL_LOAN_TYPES = [BETA_LOAN_TYPE, V1_1_LOAN_TYPE, V1_2_LOAN_TYPE] as const
export type LoanType = typeof ALL_LOAN_TYPES[number]

export abstract class BaseLoan {
  // V1.1 specific
  abstract loanContract: BaseLoanContract
  abstract loanRequestContract: V1SimpleLoanSimpleRequestContract
  // loan naming
  desiredLoanTerms: DesiredLoanTerms | undefined
  loanTokenOwner: Address
  proposalType?: V1_2ProposalType

  // common fields in proposal
  id: string
  borrower: Address
  collateral: AssetWithAmount
  chainId: SupportedChain
  onChainId: string
  contractAddress: Address
  expiration: UnixTimestamp

  // loan specific
  status: LoanStatus
  acceptedOffer: BaseOffer
  pendingOffers: BaseOffer[]
  paidBack: UnixTimestamp
  createdAt: Date
  isFeatured: boolean

  // TODO move this to V1SimpleLoan
  latestLoanExtensionRequest: LoanExtensionRequest

  public ltv: number | undefined

  get ltvAmount(): number | undefined {
    if (compareAddresses(this.collateral.address, CHAINS_CONSTANTS[this.collateral.chainId].tokenBundlerContract)) {
      if (!this.desiredLoanTerms) {
        return undefined
      }

      const amount = new AmountInEthAndUsd('0', '0')

      for (const asset of this.collateral.bundleAssets) {
        const priceForAnAsset = asset.getAppraisalFullAmount(asset.appraisal)

        if (priceForAnAsset) {
          amount.ethAmount = (parseFloat(amount.ethAmount) + parseFloat(priceForAnAsset.ethAmount)).toString()
        }
      }

      return ltv(new AssetWithAmount({
        ...this.collateral,
        amount: amount.ethAmount,
      }), this.desiredLoanTerms)
    }

    if (this.ltv) {
      return this.ltv
    }
  }

  constructor(loan?: Partial<BaseLoan>) {
    this.id = loan?.id || '0' // collection offer loan have set '0' to pass few operations with loans in app
    this.status = loan?.status || LoanStatus.NonExistent
    this.borrower = loan?.borrower || '0x'
    this.expiration = loan?.expiration || 0
    // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount | null' is not assignable to... Remove this comment to see the full error message
    this.collateral = loan?.collateral ? new AssetWithAmount(loan.collateral) : null
    this.desiredLoanTerms = loan?.desiredLoanTerms ? new DesiredLoanTerms(loan.desiredLoanTerms) : undefined
    // @ts-expect-error TS(2322) FIXME: Type 'BaseOffer | undefined' is not assignable to ... Remove this comment to see the full error message
    this.acceptedOffer = loan?.acceptedOffer
    this.pendingOffers = loan?.pendingOffers ?? []
    this.paidBack = loan?.paidBack || 0
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.onChainId = loan?.onChainId || undefined
    // @ts-expect-error TS(2322) FIXME: Type 'SupportedChain | undefined' is not assignabl... Remove this comment to see the full error message
    this.chainId = loan?.chainId
    // @ts-expect-error TS(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
    this.createdAt = loan?.createdAt
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
    this.contractAddress = loan?.contractAddress
    this.isFeatured = loan?.isFeatured ?? false
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
    this.loanTokenOwner = loan?.loanTokenOwner
    // @ts-expect-error TS(2322) FIXME: Type 'LoanExtensionRequest | undefined' is not ass... Remove this comment to see the full error message
    this.latestLoanExtensionRequest = loan?.latestLoanExtensionRequest
    // TODO add assigning of LTV and other stuff?
    this.ltv = loan?.ltv
  }

  get uniqueIdentifier(): string {
    return `${this.collateral.uniqueIdentifier}`
  }

  get validPendingOffersCount(): number {
    if (!this.pendingOffers || !this.pendingOffers?.length) return 0
    return this.pendingOffers.filter(offer => offer.isAcceptable).length
  }

  get lender(): Address {
    if (this.loanTokenOwner) return this.loanTokenOwner
    return this.acceptedOffer?.lender
  }

  get expirationRelativeFullDays(): number {
    const nowTimestamp = DateUtils.getNowTimestampInSeconds()
    return DateUtils.convertSecondsToFullDays(this.expiration - nowTimestamp)
  }

  get loanAssetSymbol(): string {
    return this.acceptedOffer?.loanAssetSymbol ?? ''
  }

  get expirationDate(): string {
    return DateUtils.displayDate(this.expiration * 1000) // in milliseconds
  }

  get expirationJsDate(): Date {
    return new Date(this.expiration * 1000) // in milliseconds
  }

  get paidBackDate(): string {
    return this.paidBack ? DateUtils.displayDate(this.paidBack * 1000) : '' // in milliseconds
  }

  get expirationRelativeDetailed(): string {
    const units: DurationUnit[] = [
      'days',
      'hours',
      'minutes',
      'seconds',
    ]

    const expirationTime = DateTime.fromSeconds(this.expiration || 0)
    const isPast = expirationTime.diffNow().as('milliseconds') < 0
    if (isPast) return '0h'

    const expirationDuration = expirationTime.diffNow().shiftTo(...units)
    const highestUnit = units.find((unit) => expirationDuration.get(unit) !== 0) || 'seconds'
    const highestUnitIndex = Math.min(units.indexOf(highestUnit), 2)
    const highestUnitPair = [units[highestUnitIndex], units[highestUnitIndex + 1]]
    const duration = expirationDuration.shiftTo(...highestUnitPair).toObject()

    const expirationString = []
    for (const property in duration) {
      const timeValue = Math.floor(duration[property])
      if (timeValue > 0) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        expirationString.push(`${Math.floor(duration[property])}${property.charAt(0)}`)
      }
    }

    return expirationString.join(', ')
  }

  get collateralAssetTokenId(): bigint {
    return this.collateral?.tokenId
  }

  get desiredLoanTermsAddress(): Address | undefined {
    return this.desiredLoanTerms?.address
  }

  get collateralAmount(): string {
    return this.collateral?.amount ?? '1'
  }

  get collateralAmountRaw(): bigint {
    return this.collateral?.amountRaw
  }

  get collateralAssetAddress(): Address {
    return this.collateral?.address ?? ''
  }

  get apr(): string | null {
    if (this.acceptedOffer) {
      return this.acceptedOffer.interestAPRVerbose
    } else if (this.desiredLoanTerms) {
      return this.desiredLoanTerms.desiredAPRVerbose
    }
    return null
  }

  // eslint-disable-next-line
  get statusName() {
    switch (this.status) {
    case LoanStatus.New:
      return this.validPendingOffersCount > 0 ? 'Available offers' : 'Waiting for offers'
    case LoanStatus.Accepted:
    case LoanStatus.AcceptedTransferred:
      return 'Active'
    case LoanStatus.PaidBack:
    case LoanStatus.PaidBackTransferred:
      return 'Paid Back'
    case LoanStatus.Defaulted:
    case LoanStatus.DefaultedTransferred:
      return 'Defaulted'
    case LoanStatus.InactiveRevoked:
      return 'Inactive (Revoked)'
    case LoanStatus.InactiveDefaulted:
      return 'Inactive (Defaulted)'
    case LoanStatus.InactivePaidBack:
      return 'Inactive (Paid back)'
    case LoanStatus.InactiveExpired:
      return 'Inactive (Expired)'
    default:
      return ''
    }
  }

  abstract get isSignedRequestValid(): boolean;

  public async payBack(step: ToastStep): Promise<TransactionReceipt> {
    return await this.loanContract.payBack(this, step)
  }

  public async claimLoan(step: ToastStep): Promise<TransactionReceipt> {
    return await this.loanContract.claimLoan(this, step)
  }

  public async isApprovedForPayBack(walletAddress: Address): Promise<boolean> {
    return await this.loanContract.isApprovedForPayBack(this, walletAddress)
  }

  public async approveForPayBack(step: ToastStep): Promise<boolean> {
    return await this.loanContract.approveForPayback(this, step)
  }
}

export class BetaLoan extends BaseLoan {
  loanContract: BetaLoanContract
  // @ts-expect-error no loanRequestContract for BetaLoan
  loanRequestContract: V1SimpleLoanSimpleRequestContract

  constructor(loan?: Partial<BetaLoan>) {
    super(loan)
    this.loanContract = new BetaLoanContract()
  }

  override get isSignedRequestValid(): boolean {
    // beta loans does not have signed request concept
    return false
  }
}

export class V1SimpleLoan extends BaseLoan {
  loanContract: V1SimpleLoanContract
  loanRequestContract: V1SimpleLoanSimpleRequestContract

  // TODO what type in constructor? similar in other loan/offer classes
  constructor(loan?: Partial<V1SimpleLoan>) {
    super(loan)
    this.loanContract = new V1SimpleLoanContract()
    this.loanRequestContract = new V1SimpleLoanSimpleRequestContract()
  }

  override get isSignedRequestValid(): boolean {
    return this.status === LoanStatus.New && !!this.desiredLoanTerms?.loanRequestSignature
  }
}

// ////////////////////////////////// //
// // PWNSimpleLoanSimpleProposal // ///
// ///////////////////////////////// //
//

// ////////////////////////////////// //
// // PWNSimpleLoanListProposal // ///
// ///////////////////////////////// //
// bytes32 collateralIdsWhitelistMerkleRoot;
//

// ////////////////////////////////// //
// // PWNSimpleLoanDutchAuctionProposal //
// ///////////////////////////////// //
// intendedCreditAmount?: bigint
// slippage?: bigint
// uint256 minCreditAmount;
// uint256 maxCreditAmount;
// uint40 auctionStart;
// uint40 auctionDuration;
//

// ////////////////////////////////// //
// // PWNSimpleLoanFungibleProposal //
// ///////////////////////////////// //
// uint256 minCollateralAmount;
// uint256 creditPerCollateralUnit;
//
