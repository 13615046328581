<template>
  <div class="pwn-safe-action-header">
    <div class="pwn-safe-action-header__title-container">
      <slot name="icons"/>
      <div :class="['pwn-safe-action-header__title', {'pwn-safe-action-header__title--shrink': isShrunk}]">
        {{ props.title }}
      </div>
      <div class="pwn-safe-action-header__address-details">
        {{ props.addressDetails }}
      </div>
    </div>
    <div class="pwn-safe-action-header__subheading">
      <slot name="subheading"/>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  title: string
  addressDetails?: string
  isShrunk?: boolean
}
const props = defineProps<Props>()
</script>

<style scoped>
.pwn-safe-action-header {
  font-size: 2.375rem;
  font-family: var(--font-family-autoscape);
  margin-bottom: 2rem;

  &__title-container {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-left: 0.5rem;

    &--shrink {
      font-size: 1.875rem;
    }
  }

  &__address-details {
    font-size: 1.25rem;
    margin-left: 1rem;
    color: var(--gray-2);
    align-self: center;
  }

  &__subheading {
    max-width: 85%;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
    font-family: var(--font-family-oxygen-mono);
  }
}
</style>
