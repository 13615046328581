import { ref } from 'vue'
import { SORT_OPTIONS_LOOKUP, SortOption } from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/PwnSafeTokenizeCoinsAndNftsDefinitions'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { filterAssetsInSearchResults } from '@/utils/search'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

const selectedSortOption = ref<ActiveSortOption>(loadSortOption(
  'sort-option-pwn-safe-tokenize-coins-and-nfts',
  { id: SortOption.Name, direction: SortDirection.Descending, viewName: 'sort-option-your-coins' },
  Object.keys(SORT_OPTIONS_LOOKUP),
))
const searchTerm = ref('')

const getSortedAssetsInTokenizeSection = (assets: AssetWithAmount[]) => {
  assets = filterAssetsInSearchResults(assets, searchTerm.value)
  assets = useGlobalFiltersStore().applyShowUnverifiedAssetsFilter(assets)
  return SORT_OPTIONS_LOOKUP[selectedSortOption.value.id](assets, selectedSortOption.value.direction)
}

export default function usePwnSafeTokenizeCoinsAndNfts() {
  return {
    searchTerm,
    getSortedAssetsInTokenizeSection,
    selectedSortOption,
  }
}
