<template>
  <div class="asset-name-and-maybe-amount__asset-label">
    <SafeText
      :text="assetNameAndMaybeAmountForPwnSafe">
      <template #default>
        <span :class="['asset-name-and-maybe-amount__asset-label--name', {'asset-name-and-maybe-amount__asset-label--missing': asset.isPrimaryInfoMissing}]">
          {{ asset.primaryInfoWithoutAmount }}
        </span>
        <span
          v-if="asset.isFungible"
          :class="['asset-name-and-maybe-amount__asset-label--amount', {'asset-name-and-maybe-amount__asset-label--missing': asset.isPrimaryInfoMissing}]">
          (<BasePriceLabel
            :amount="assetAmount"
            :symbol="asset.symbol"/>)
        </span>
      </template>
    </SafeText>
  </div>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { toRefs, computed } from 'vue'
import BasePriceLabel from '@/general-components/BasePriceLabel.vue'
import SafeText from '@/general-components/SafeText.vue'

const props = defineProps<{
  asset: AssetWithAmount
}>()

const { asset } = toRefs(props)

const assetAmount = computed(() => asset.value.maxAvailableAmountRaw === 0n ? asset.value.tokenizedAmount : asset.value.maxAvailableAmount)
const assetNameAndMaybeAmountForPwnSafe = computed(() => `${asset.value.primaryInfoWithoutAmount} ${asset.value.isFungible ? `(${assetAmount.value})` : ''}`)

</script>

<style scoped>
.asset-name-and-maybe-amount {
  &__asset-label {
    height: 1.2rem;

    &--name {
      font-size: 1.25rem;
    }

    &--amount {
      display: inline-flex;
      font-size: 1rem;
    }

    &--missing {
      color: var(--negative-1);
    }
  }
}
</style>
