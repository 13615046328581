enum TestIds {
  makeOfferFormCreditRequiredInput = 'makeOfferFormCreditRequiredInput',
  makeOfferFormCreditOfferedInput = 'makeOfferFormCreditOfferedInput',
  makeOfferFormDurationInput = 'makeOfferFormDurationInput',
  makeOfferFormRepaymentInput = 'makeOfferFormRepaymentInput',

  selectedAssetsTableRadioButton = 'selectedAssetsTableRadioButton',

  shitFilter = 'shitFilter',

  loanPreviewLinkToAssetPage = 'loanPreviewLinkToAssetPage',

  tokenSelectModalAllAssets = 'tokenSelectModalAllAssets',
  tokenSelectModalSearch = 'tokenSelectModalSearch',
  tokenSelectModalOwnedAssets = 'tokenSelectModalOwnedAssets',

  modalFilterBorrowingToggleOperator = 'modalFilterBorrowingToggleOperator',
}
export default TestIds
