<template>
  <section class="loan-detail__info">
    <CollateralDetail
      v-if="loanData && loanData.collateral"
      class="loan-detail__collateral-detail"
      has-internal-link
      :collateral="{
        chainId: loanData.collateral.chainId,
        address: loanData.collateral.address,
        category: loanData.collateral.category,
        tokenId: loanData.collateral.tokenId ?? ''
      }"
      :amount="loanData.collateralAmount"/>

    <div class="loan-detail__fieldset">
      <div class="loan-detail__title">
        <span>Loan Details</span>
      </div>
      <div
        class="loan-detail__row">
        <div class="loan-detail__label">
          <span>Borrower</span>
        </div>
        <div class="loan-detail__value">
          <CryptoAddress
            is-link-to-dashboard
            class="loan-description-borrower__ens"
            :address="loanData?.borrower as Address"
            :chain-id="loanData?.chainId"/>
        </div>
      </div>

      <div
        class="loan-detail__row">
        <div class="loan-detail__label">
          <span>Lender</span>
        </div>
        <div class="loan-detail__value">
          <CryptoAddress
            is-link-to-dashboard
            class="loan-description-borrower__ens"
            :address="loanData?.loanOwner as Address"
            :chain-id="loanData?.chainId"/>
        </div>
      </div>

      <div class="loan-detail__row">
        <div class="loan-detail__label">
          <span>Borrowed</span>
        </div>
        <div class="loan-detail__value loan-detail__credit-info">
          <AssetAmount
            v-if="loanData"
            is-appraisal-aligned-right
            is-display-symbol
            link-is-internal
            :asset-amount="loanData?.creditData.amount ?? '0'"
            :asset="loanData.creditAsset"/>
        </div>
      </div>

      <div class="loan-detail__row">
        <div class="loan-detail__label">
          <span>LTV</span>
        </div>
        <div class="loan-detail__value">
          <LtvColored :ltv="loanData?.creditData.ltv || 0"/>
        </div>
      </div>

      <div class="loan-detail__row">
        <div class="loan-detail__label">
          <span>Interest</span>
        </div>
        <div class="proposal-detail-info__value">
          <InterestInfo
            v-if="loanData"
            :credit-asset="{...loanData.creditAsset, name: loanData.creditAsset?.name ?? 'Missing name'}"
            :collateral-asset="{...loanData.collateral, name: loanData.collateral?.name ?? 'Missing name'}"
            :interest="loanData?.creditData.apr"
            :duration="duration"
            :type="loanData.type"
            :credit-amount="creditAmount"
            :apr="loanData?.creditData.apr"
            :total-repayment="totalRepayment"/>
        </div>
      </div>

      <div class="loan-detail__row">
        <div class="loan-detail__label">
          <span>Max Loan Duration</span>
        </div>
        <div class="loan-detail__value">
          <span>{{ duration }} {{ singularOrPlural(duration ?? 0, 'Day') }}</span>
        </div>
      </div>
      <slot name="expiration"/>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import { formatUnits } from 'viem'
import type { Address } from 'viem'
import LtvColored from '@/revamp/components/LtvColored.vue'
import AssetAmount from '@/revamp/components/AssetAmount.vue'
import InterestInfo from '@/revamp/components/InterestInfo.vue'
import CollateralDetail from '@/revamp/components/CollateralDetail.vue'
import type { LoanDetailSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import DateUtils from '@/utils/DateUtils'
import { getProposalOrLoanType, ProposalOrLoanEnum } from '@/modules/common/pwn/utils'
import { singularOrPlural } from '@/utils/utils'

interface Props {
  loanData: LoanDetailSchemaWorkaroundBackendSchema | null
}
const props = defineProps<Props>()

const duration = computed(() => {
  if (!props.loanData?.duration) return
  return props.loanData.duration / 60 / 60 / 24
})

const isPrevVersion = computed(() => {
  if (!props.loanData?.type) return false
  return getProposalOrLoanType(props.loanData.type) === ProposalOrLoanEnum.V1_1 ||
    getProposalOrLoanType(props.loanData.type) === ProposalOrLoanEnum.BETA
})

const totalRepayment = computed(() => {
  if (isPrevVersion.value) {
    return formatUnits(BigInt(props.loanData?.creditData?.totalRepaymentAmount ?? 0), props.loanData?.creditAsset?.decimals ?? 18)
  }
  const aprPaddingWith100Percent = 10 ** APR_DECIMAL_POINT_PADDING * 100
  if (!props.loanData?.creditData?.accruingInterestApr) return null
  const dailyInterest = props.loanData?.creditData?.accruingInterestApr / aprPaddingWith100Percent / 365
  const creditAmountFormated = formatUnits(BigInt(props.loanData?.creditData?.amount ?? 0), props.loanData?.creditAsset?.decimals ?? 18)
  const durationInDays = DateUtils.convertSecondsToDays(props.loanData?.duration)
  return String((+creditAmountFormated * dailyInterest * durationInDays) + +creditAmountFormated)
})

const creditAmount = computed(() => {
  if (!props.loanData?.creditData?.amount) return undefined
  return formatUnits(BigInt(props.loanData?.creditData?.amount ?? 0), props.loanData?.creditAsset?.decimals ?? 18)
})

</script>

<style scoped>
.loan-detail {
  &__info {
    display: flex;
    width: 100%;

    padding: 1.5rem;
    border: 1px solid #434343;

    @media only screen and (--mobile-viewport) {
      flex-flow: column nowrap;
    }
  }

  &__collateral-detail {
    width: 100%;
    padding: 0 2rem 0 0;
    border-right: 1px solid #434343;

    @media only screen and (--small-viewport) {
      width: 100%;
    }

    @media only screen and (--mobile-viewport) {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #434343;
    }
  }

  &__title {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
  }

  &__proposal-detail {
    padding: 2rem 0 0 40px;
  }

  &__fieldset {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    padding: 0 0 0 2rem;
    width: 100%;

    @media only screen and (--small-viewport) {
      width: 100%;
      padding: 0 0 0 1.5rem;
    }

    @media only screen and (--mobile-viewport) {
      width: 100%;
      padding: 1.5rem 0 0;
    }
  }

  &__row {
    display: flex;
    min-width: 22.5rem;
    padding: 0.5rem 0;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    font-size: 0.875rem;
    font-family: var(--font-family-supreme);
    color: var(--text-color-secondary);
  }

  &__value {
    font-size: 0.875rem;
  }

  &__credit-info {
    display: flex;
    justify-content: end;
    gap: 0.5rem;
    flex-direction: column;
  }

  &__appraisal {
    display: flex;
    justify-content: end;
    color: var(--gray);
  }
}
</style>
