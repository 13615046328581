<template>
  <BaseSection
    heading="Bundles "
    :secondary-heading="`(${sortedBundles.length}) `"
    :is-expandable="false">
    <ChooseBundleTable/>

    <BaseEmptyStateText
      v-if="hasEmptyState"
      text="No bundles yet"/>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import ChooseBundleTable from '@/modules/pages/token-bundler/unpack-bundle/section-choose-bundle/ChooseBundleTable.vue'
import useChooseBundle from '@/modules/pages/token-bundler/unpack-bundle/section-choose-bundle/useChooseBundle'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import { computed } from 'vue'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { storeToRefs } from 'pinia'

const userAssetsStore = useUserAssetsStore()
const { userNftsIsPending } = storeToRefs(userAssetsStore)

const { sortedBundles } = useChooseBundle()

const hasEmptyState = computed(() => !userNftsIsPending.value && sortedBundles.value.length === 0)
</script>
