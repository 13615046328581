import type { Stringified } from '@/modules/common/typings/customTypes'
import type { NotificationBackendSchema } from '@/modules/common/backend/generated'

export enum ReceivedSseEvent {
  Notification = 'notification_event',
  Update = 'update_event',
}

type ReceivedSseEventTypes = `${ReceivedSseEvent}`

export type ReceivedSseEventDataPropTypes = string | NotificationBackendSchema

interface ExtendedMessageEvent<DataType extends ReceivedSseEventDataPropTypes = ReceivedSseEventDataPropTypes, EventName extends ReceivedSseEventTypes = ReceivedSseEventTypes> extends MessageEvent {
  data: Stringified<DataType> // todo use just DataType and Stringified it in the exact types?
  type: EventName
}

// TODO Refactor Listener Types to handle Loan/Offer Data
// https://www.notion.so/pwndao/0f177f2e103c4ba0a1fad0c4d2717c79?v=9a8f838972a44acea77fae62931c9d0d&p=ebdff94caca5439ab25ba9095604430a&pm=c
export type ReceivedNotificationSseEvent = ExtendedMessageEvent<NotificationBackendSchema, ReceivedSseEvent.Notification>
export type ReceivedUpdateSseEvent = ExtendedMessageEvent<NotificationBackendSchema, ReceivedSseEvent.Update>
