import { isChainWithLooksRareExplorer } from '@/constants/chains/types'
import type { SupportedChain } from '@/constants/chains/types'
import { computed, readonly, ref } from 'vue'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import to from '@/utils/await-to-js'
import { NFTOrder } from '@/modules/common/assets/typings/NFTOrder'
import { sortNftOrdersByUsdValue } from '@/general-components/sorting/SortFunctions'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { Address } from 'viem'
import { fetchNftListings } from '@/modules/common/backend/generated'

const listings = ref<NFTOrder[]>([])
const isFetchingListings = ref(false)

const sortedListings = computed(() => {
  if (!listings.value?.length) {
    return []
  }

  return sortNftOrdersByUsdValue(listings.value, SortDirection.Ascending)
})

export default function useSectionListings() {
  const clearListings = (): void => {
    listings.value = []
  }

  const loadListingsFromMarketplace = async (marketplace: DataSourceType.LOOKSRARE | DataSourceType.OPENSEA, chainId: SupportedChain, contractAddress: Address, tokenId: string): Promise<void> => {
    let next: string | undefined
    do {
      const [error, response] = await to(fetchNftListings(
        String(chainId),
        contractAddress,
        tokenId,
        {
          marketplace,
          ...(next && { page: next }),
        }))
      if (error || !response) {
        return
      }
      next = response.data.next ?? undefined
      for (const order of response.data.orders || []) {
        const parsedOrder = NFTOrder.createFromBackendResponse(order)
        if (parsedOrder) {
          listings.value.push(parsedOrder)
        }
      }
    } while (next)
  }

  const loadListings = async (chainId: SupportedChain, contractAddress: Address, tokenId: string): Promise<void> => {
    isFetchingListings.value = true
    let marketplacesToFetch: [DataSourceType.OPENSEA] | [DataSourceType.OPENSEA, DataSourceType.LOOKSRARE]
    if (isChainWithLooksRareExplorer(CHAINS_CONSTANTS[chainId])) {
      marketplacesToFetch = [DataSourceType.OPENSEA, DataSourceType.LOOKSRARE]
    } else {
      marketplacesToFetch = [DataSourceType.OPENSEA]
    }

    await to(Promise.all(marketplacesToFetch.map(marketplace =>
      loadListingsFromMarketplace(
        marketplace,
        chainId,
        contractAddress,
        tokenId,
      ),
    )), { additionalErrorInfo: { chainIdToFetch: chainId, contractAddressToFetch: contractAddress, tokenIdToFetch: tokenId } },
    )
    isFetchingListings.value = false
  }

  return {
    listings: computed(() => listings.value),
    loadListings,
    isFetchingListings: readonly(isFetchingListings),
    clearListings,
    sortedListings,
  }
}
