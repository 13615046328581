import AssetType, { NFT_CATEGORIES, parseAssetCategoryToAssetType } from '@/modules/common/assets/AssetType'
import { SupportedChain } from '@/constants/chains/types'
import useUuid from '@/utils/useUuid'
import NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import AssetTraits from '@/modules/pages/asset/nft-page/types/AssetTraits'
import type { AllAssetModels, NFTAssetSchemaBackendSchema } from '@/modules/common/backend/generated/aliases'
import type {
  AssetContractSchemaBackendSchema,
  AssetContractWithNameAndImageBackendSchema,
  AssetMetadataBackendSchema,
  AtrTokenOfAssetSchemaBackendSchema,
  ERC20AssetSchemaBackendSchema,
  MetadataSourceBackendSchema,
} from '@/modules/common/backend/generated'
import { AssetCategoryBackendSchema } from '@/modules/common/backend/generated'
import { parseChainIdFromResponse } from '@/modules/common/backend/backendUtils'
import { globalConstants } from '@/constants/globals'
import {
  compareAddresses,
  displayTokenAmount,
  formatAmountWithDecimals,
  formatToken,
  getRawAmount,
  shortenNumber,
  singularOrPlural,
} from '@/utils/utils'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { sortAssetsByName } from '@/general-components/sorting/SortFunctions'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import * as Sentry from '@sentry/vue'
import DateUtils from '@/utils/DateUtils'
import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import { AssetPrice, NFTAppraisal } from '@/modules/common/assets/typings/AssetPriceClasses'
import type { RouteLocationRaw } from 'vue-router'
import RouteName from '@/router/RouteName'
import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import { calculateApr, calculateInterest } from '@/utils/calculations'
import type NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'
import {
  DAI_SEPOLIA_ADDRESS,
  generateFakeAssetPrice,
  generateFakeErc20Price, LINK_SEPOLIA_ADDRESS,
  PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS,
  PWN_FAUCET_ERC721_SEPOLIA_ADDRESS,
  PWN_FAUCET_PWND_SEPOLIA_ADDRESS,
  PWN_FAUCET_PWNS_SEPOLIA_ADDRESS, WETH_SEPOLIA_ADDRESS,
} from './fakeAppraisals'
import type { Address } from 'viem'
import { getAddress, isAddress, zeroAddress } from 'viem'
import type { PartialAllowNulls } from '@/modules/common/typings/customTypes'
import {
  CustomAssetDescription,
  getAssetCustomDescriptionKey,
} from '@/modules/pages/asset/nft-page/CustomAssetDescription'
import { isLoanToken, isPwnAsset, isTokenBundle } from '@/utils/contractAddresses'
import { getNativeTokenIconUrl, getWagmiChain } from '@/utils/chain'
import { DEFAULT_LOAN_DURATION_IN_DAYS } from '@/constants/loans'
import type { ValuationSource } from '@/general-components/data-source/DataSourceType'
import type { DepositProtocol } from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { parseAddress } from '@/utils/address-utils'

export class AssetContract {
  chainId: SupportedChain
  address: Address
  category: AssetType
  isWhitelistedForPwnSafe?: boolean
  // dynamic contract == contract that should be wrapped before it can be used in pwn
  // reasons are e.g. that the underlying value of the NFT can change without a wrapping
  // which is a case with e.g. uniswap lp nfts
  isDynamicContract?: boolean
  name?: string
  image?: string
  // kyc required == contract that if the loan is defaulted collateral will require kyc for claim
  isKycRequired?: boolean

  constructor(assetContract: PartialAllowNulls<AssetContract>) {
    this.chainId = assetContract.chainId!
    this.address = assetContract?.address ? parseAddress(assetContract.address) : (assetContract?.address as Address)
    this.category = assetContract.category!
    this.isWhitelistedForPwnSafe = assetContract?.isWhitelistedForPwnSafe ?? false
    this.isDynamicContract = assetContract?.isDynamicContract ?? undefined
    this.name = assetContract?.name ?? undefined
    this.image = assetContract?.image ?? undefined
    this.isKycRequired = assetContract?.isKycRequired ?? undefined
  }

  public static createFromBackendModel(
    assetContract: AssetContractSchemaBackendSchema | AssetContractWithNameAndImageBackendSchema,
  ): AssetContract {
    return new AssetContract({
      chainId: parseChainIdFromResponse(assetContract.chain_id),
      address: parseAddress(assetContract.address as Address),
      category: parseAssetCategoryToAssetType(assetContract.category),
      isWhitelistedForPwnSafe: assetContract.is_whitelisted_for_pwnsafe,
      isDynamicContract: assetContract.is_dynamic_contract,
      name: 'name' in assetContract ? assetContract.name : undefined,
      image: 'thumbnail_url' in assetContract ? assetContract.thumbnail_url : undefined,
      isKycRequired: assetContract.is_kyc_required,
    })
  }

  get isNft(): boolean {
    return NFT_CATEGORIES.includes(this.category)
  }
}

export class AtrTokenInfo {
  contractAddress: Address
  tokenId: bigint
  createdBy: Address
  tokenizedAssetOwner: Address
  tokenizedAmount: bigint
  chainId: SupportedChain

  constructor(atrTokenInfo: Partial<AtrTokenInfo>) {
    this.contractAddress = atrTokenInfo.contractAddress!
    this.tokenId = atrTokenInfo.tokenId!
    this.createdBy = atrTokenInfo.createdBy!
    this.tokenizedAssetOwner = atrTokenInfo.tokenizedAssetOwner!
    this.tokenizedAmount = atrTokenInfo.tokenizedAmount!
    this.chainId = atrTokenInfo.chainId!
  }

  public static createFromBackendModel(atrTokenInfo: AtrTokenOfAssetSchemaBackendSchema, chainId: SupportedChain): AtrTokenInfo {
    return new AtrTokenInfo({
      contractAddress: getAddress(atrTokenInfo.contract_address),
      tokenId: BigInt(atrTokenInfo.token_id),
      createdBy: getAddress(atrTokenInfo.atr_token_created_by),
      tokenizedAssetOwner: getAddress(atrTokenInfo.tokenized_asset_owner),
      tokenizedAmount: BigInt(atrTokenInfo.tokenized_amount),
      chainId,
    })
  }

  public static createFromAsset(asset: AssetWithAmount, amount: string): AtrTokenInfo {
    return new AtrTokenInfo({
      contractAddress: asset.address,
      tokenId: asset.tokenId,
      createdBy: asset.ownerAddress,
      tokenizedAssetOwner: asset.ownerAddress,
      tokenizedAmount: BigInt(amount),
      chainId: asset.chainId,
    })
  }

  get isTokenizedAssetInCreatorSafe(): boolean {
    return compareAddresses(this.tokenizedAssetOwner, this.createdBy)
  }
}

// todo divide this into BaseAsset, ERC20Asset and NFTAsset (or something similar)
export class AssetMetadata {
  public static MISSING_NAME_PLACEHOLDER = 'Missing name'
  public static MISSING_SYMBOL_PLACEHOLDER = 'Missing symbol'

  public id: number // internal ID for :key attribute on table row in BaseTable

  public chainId: SupportedChain
  public address: Address // native token has zeroAddress
  public category: AssetType
  public name: string
  public symbol: string
  public image: string
  public isVerified: boolean
  public isVerifiedSource?: MetadataSourceBackendSchema
  public decimals: number
  public tokenId: bigint

  public collection: NFTAssetCollection // todo delete from ERC20Asset once we have the distinction between NFTAsset and ERC20Asset

  public websiteUrl?: string // todo delete from NFTAsset and keep just in NFTAssetCollection once we have the distinction between NFTAsset and ERC20Asset
  public discordUrl?: string // todo delete from NFTAsset and keep just in NFTAssetCollection once we have the distinction between NFTAsset and ERC20Asset
  public twitterUrl?: string // todo delete from NFTAsset and keep just in NFTAssetCollection once we have the distinction between NFTAsset and ERC20Asset
  public instagramUrl?: string // todo delete from NFTAsset and keep just in NFTAssetCollection once we have the distinction between NFTAsset and ERC20Asset
  public githubUrl?: string // todo delete from NFTAsset and keep just in NFTAssetCollection once we have the distinction between NFTAsset and ERC20Asset

  public traits: AssetTraits // todo delete from NFTAsset once we have the distinction between NFTAsset and ERC20Asset
  public relatedLoans: BaseLoan[]

  public ownerAddress: Address
  public atrTokens: AtrTokenInfo[] // active ATR tokens created for this asset by the user

  // bundle specific
  public bundleAssets: AssetWithAmount[]

  // atr token specific
  public assetOfAtrToken: AssetWithAmount

  // pwnsafe specific
  public isWhitelistedForPwnSafe: boolean

  public appraisal: AssetPrice | NFTAppraisal
  public nftPriceStats?: NFTPriceStats

  // dynamic contract == contract that should be wrapped before it can be used in pwn
  // reasons are e.g. that the underlying value of the NFT can change without a wrapping
  // which is a case with e.g. uniswap lp nfts
  public isDynamicContract?: boolean

  // kyc required == contract that if the loan is defaulted collateral will require kyc for claim
  public isKycRequired?: boolean | null
  public kycTitle?: string | null
  public kycDescription?: string | null
  public kycLogo?: string
  public kycUrl?: string

  public totalBundledAssets?: number
  public verifiedBundledAssets?: number

  constructor(asset?: Partial<AssetMetadata>) {
    this.id = asset?.id || useUuid().getUuid()

    // @ts-expect-error FIXME: strict null checks
    this.chainId = asset?.chainId
    // TODO check if changing default from '' to `null` does not break something
    if (isStarknet) {
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | null' is not assignable to t... Remove this comment to see the full error message
      this.address = asset?.address?.toLocaleLowerCase()
    } else {
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | null' is not assignable to t... Remove this comment to see the full error message
      this.address = asset?.address && isAddress(asset.address) ? getAddress(asset.address) : null
    }
    this.category = Number(asset?.category)
    this.name = asset?.name || AssetMetadata.MISSING_NAME_PLACEHOLDER
    this.symbol = asset?.symbol || AssetMetadata.MISSING_SYMBOL_PLACEHOLDER
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.image = asset?.image
    this.isVerified = asset?.isVerified ?? false
    this.isVerifiedSource = asset?.isVerifiedSource
    this.decimals = asset?.decimals || 0
    // @ts-expect-error TS(2322) FIXME: Type 'bigint | ""' is not assignable to type 'bigi... Remove this comment to see the full error message
    this.tokenId = asset?.tokenId

    // @ts-expect-error TS(2322) FIXME: Type 'NFTAssetCollection | undefined' is not assig... Remove this comment to see the full error message
    this.collection = asset?.collection

    this.websiteUrl = asset?.websiteUrl
    this.discordUrl = asset?.discordUrl
    this.twitterUrl = asset?.twitterUrl
    this.instagramUrl = asset?.instagramUrl
    this.githubUrl = asset?.githubUrl

    this.traits = asset?.traits || new AssetTraits()
    this.relatedLoans = asset?.relatedLoans || []
    this.bundleAssets = asset?.bundleAssets || []

    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
    this.ownerAddress = asset?.ownerAddress
    // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount | undefined' is not assignab... Remove this comment to see the full error message
    this.assetOfAtrToken = asset?.assetOfAtrToken
    this.atrTokens = asset?.atrTokens || []
    // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.isWhitelistedForPwnSafe = asset?.isWhitelistedForPwnSafe

    // @ts-expect-error TS(2322) FIXME: Type 'AssetPrice | NFTAppraisal | undefined' is no... Remove this comment to see the full error message
    this.appraisal = asset?.appraisal
    this.isDynamicContract = asset?.isDynamicContract
    this.nftPriceStats = asset?.nftPriceStats
    this.isKycRequired = asset?.isKycRequired ?? null
    this.kycTitle = asset?.kycTitle ?? null
    this.kycDescription = asset?.kycDescription ?? null
    this.kycLogo = asset?.kycLogo
    this.kycUrl = asset?.kycUrl
    this.totalBundledAssets = asset?.totalBundledAssets
    this.verifiedBundledAssets = asset?.verifiedBundledAssets
  }

  public static createFromBackendModel(asset: AllAssetModels): AssetMetadata | undefined {
    if (!asset?.contract) {
      Sentry.captureMessage('asset.contract is undefined')
      return
    }

    const isAssetNFTAsset = (assetToCheck: AllAssetModels): assetToCheck is NFTAssetSchemaBackendSchema => {
      return [AssetCategoryBackendSchema.NUMBER_1, AssetCategoryBackendSchema.NUMBER_2, AssetCategoryBackendSchema.NUMBER_3, AssetCategoryBackendSchema.NUMBER_420].some(assetCategory => assetCategory === assetToCheck.contract.category) ||
      (assetToCheck.contract.category === AssetCategoryBackendSchema['_-1'] && assetToCheck.token_id !== null && assetToCheck.token_id !== undefined)
    }

    let bundleAssets: AssetWithAmount[] = []
    if (isAssetNFTAsset(asset) && asset?.bundled_assets?.length) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      bundleAssets = asset.bundled_assets.map(bundledAsset => AssetWithAmount.createFromBackendModel(bundledAsset))
      bundleAssets = sortAssetsByName(bundleAssets, SortDirection.Ascending)
    }

    const chainId = parseChainIdFromResponse(asset.contract.chain_id)

    let atrTokens: AtrTokenInfo[] = []
    if (asset?.atr_tokens_of_user?.length) {
      atrTokens = asset.atr_tokens_of_user.map(atrToken => AtrTokenInfo.createFromBackendModel(atrToken, chainId))
    }

    const getVerifiedSource = (asset: AllAssetModels): MetadataSourceBackendSchema | undefined => {
      if (isAssetNFTAsset(asset)) {
        return asset.collection?.is_verified_source ?? undefined
      } else {
        return asset?.is_verified_source ?? undefined
      }
    }

    const category = parseAssetCategoryToAssetType(asset.contract.category)
    const assetAddress = parseAddress(asset.contract.address as Address)

    let appraisal: AssetPrice | NFTAppraisal | null = null
    if (asset.latest_price) {
      if (NFT_CATEGORIES.includes(category)) {
        appraisal = NFTAppraisal.createFromBackendModel(asset.latest_price)
      } else {
        appraisal = AssetPrice.createFromBackendModel(asset.latest_price)
      }
    } else if (chainId === SupportedChain.Sepolia) {
      if ([
        PWN_FAUCET_PWNS_SEPOLIA_ADDRESS,
        PWN_FAUCET_PWND_SEPOLIA_ADDRESS,
        PWN_FAUCET_ERC721_SEPOLIA_ADDRESS,
        PWN_FAUCET_ERC1155_SEPOLIA_ADDRESS,
        WETH_SEPOLIA_ADDRESS,
        LINK_SEPOLIA_ADDRESS,
        DAI_SEPOLIA_ADDRESS,
      ].includes(assetAddress)) {
        appraisal = generateFakeAssetPrice(assetAddress, category)
      }
    }

    let isVerified: boolean
    if (isPwnAsset(chainId, asset.contract.address as Address)) {
      isVerified = true // hotfix until we have verification sorted out for token bundles
    } else {
      isVerified = (isAssetNFTAsset(asset) ? asset.is_verified : asset.is_verified) ?? false
    }

    const fallbackDecimals = asset.contract?.category === AssetType.ERC20 ? 18 : undefined
    // TODO is fine to remove asset?.decimals?
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to ty... Remove this comment to see the full error message
    const originDecimals = asset.contract?.decimals ?? asset?.decimals as number | undefined
    const decimals = originDecimals ?? fallbackDecimals as number

    return new AssetMetadata({
      id: Number(asset.id),
      chainId,
      address: assetAddress,
      category,
      name: asset.name ?? undefined,
      symbol: (isAssetNFTAsset(asset) ? asset.collection?.symbol : asset.symbol) ?? undefined,
      image: asset?.thumbnail_url ?? undefined,
      isVerified,
      isVerifiedSource: getVerifiedSource(asset),
      decimals,
      tokenId: asset.token_id ? BigInt(asset.token_id) : undefined,
      // @ts-expect-error TS(2322) FIXME: Type 'NFTAssetCollection | null' is not assignable... Remove this comment to see the full error message
      collection: isAssetNFTAsset(asset) && asset.collection ? NFTAssetCollection.createFromBackendModel(asset.collection) : null,
      // todo only assign social links to ERC20Asset after the distinction between ERC20Asset and NFTAsset is made
      websiteUrl: (isAssetNFTAsset(asset) ? asset?.collection?.website_url : asset?.website_url) ?? undefined,
      discordUrl: (isAssetNFTAsset(asset) ? asset.collection?.discord_url : asset?.discord_url) ?? undefined,
      twitterUrl: (isAssetNFTAsset(asset) ? asset.collection?.twitter_url : asset?.twitter_url) ?? undefined,
      instagramUrl: (isAssetNFTAsset(asset) ? asset.collection?.instagram_url : asset?.instagram_url) ?? undefined,
      githubUrl: (isAssetNFTAsset(asset) ? asset.collection?.github_url : asset?.github_url) ?? undefined,
      traits: new AssetTraits(),
      relatedLoans: [],
      bundleAssets,
      // can be address of pwn-safe where the asset is located or fetched separately (e.g. on the asset page)
      // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | null' is not assignable to t... Remove this comment to see the full error message
      ownerAddress: 'owner' in asset ? asset.owner as Address : null,
      // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount | null' is not assignable to... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      assetOfAtrToken: isAssetNFTAsset(asset) && asset?.asset_of_atr_token ? AssetWithAmount.createFromBackendModel({ ...asset?.asset_of_atr_token }) : null,
      atrTokens,
      isWhitelistedForPwnSafe: asset.contract?.is_whitelisted_for_pwnsafe ?? undefined,
      isDynamicContract: asset.contract?.is_dynamic_contract ?? undefined,
      // @ts-expect-error TS(2322) FIXME: Type 'AssetPrice | NFTAppraisal | null' is not ass... Remove this comment to see the full error message
      appraisal,
      isKycRequired: asset.contract?.is_kyc_required,
      kycTitle: asset.contract.kyc_details?.title ?? undefined,
      kycDescription: asset.contract?.kyc_details?.description ?? undefined,
      kycLogo: asset.contract?.kyc_details?.logo ?? undefined,
      kycUrl: asset.contract?.kyc_details?.website_url ?? undefined,
      totalBundledAssets: isAssetNFTAsset(asset) ? asset.bundled_assets_total_count ?? undefined : undefined,
      verifiedBundledAssets: isAssetNFTAsset(asset) ? asset.bundled_assets_verified_count ?? undefined : undefined,

    })
  }

  public static createFromTokenInTokenListBackendSchema(token: AssetMetadataBackendSchema): AssetMetadata {
    return new AssetMetadata({
      chainId: parseChainIdFromResponse(token.chain_id),
      address: token.contract_address as Address,
      decimals: token.decimals ?? undefined,
      image: token.thumbnail_url ?? undefined,
      name: token.name ?? undefined,
      symbol: token.symbol ?? undefined,
      category: AssetType.ERC20,
      isVerified: token.is_verified ?? undefined,
      id: token.asset_db_id ?? undefined,
    })
  }

  get uniqueIdentifier(): string {
    if (this.isNft) {
      return `${this.chainId}:${this.address}:${this.tokenId}`
    }
    return `${this.chainId}_${this.address}`
  }

  get displayedName(): string {
    return this.isBundleWithSingleAsset ? `Wrapped ${this.bundledAsset.name}` : this.name
  }

  get hasAtrToken(): boolean {
    return !!this.atrTokens?.length
  }

  get isAtrToken(): boolean {
    return AssetMetadata.isAtrToken(this)
  }

  get collectionName(): string {
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    return this.assetCollection?.name
  }

  get assetCollection(): NFTAssetCollection {
    if (this.isBundleWithSingleAsset) {
      if (!this.bundledAsset.isErc20) {
        return this.bundledAsset.assetCollection
      }
    }
    return this.collection
  }

  get collectionImage(): string {
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    return this.assetCollection?.image
  }

  get collectionDescription(): string {
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    return this.assetCollection?.description
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get categoryVerbose() {
    switch (this.category) {
    case AssetType.ERC20:
      return 'ERC20'
    case AssetType.ERC721:
      return 'ERC721'
    case AssetType.ERC1155:
      return 'ERC1155'
    case AssetType.CRYPTOKITTIES:
      return 'CRYPTOKITTIES'
    case AssetType.CRYPTOPUNKS:
      return 'CRYPTOPUNKS'
    case AssetType.UNSUPPORTED:
      return 'UNSUPPORTED'
    }
  }

  get routeToErc20AssetPage(): RouteLocationRaw {
    return {
      name: RouteName.TokenPage,
      params: {
        chainName: CHAINS_CONSTANTS[this.chainId].general.chainName,
        contractAddress: this.address,
      },
    }
  }

  routeToAssetPage(): RouteLocationRaw {
    if (this.isNft && this.tokenId) {
      return {
        name: RouteName.NftPage,
        params: {
          contractAddress: this.address,
          tokenId: this.tokenId?.toString(),
          chainName: CHAINS_CONSTANTS[this.chainId].general.chainName.toLowerCase(),
        },
      }
    }

    if (this.isNft && !this.tokenId) {
      return {
        name: RouteName.CollectionByContractAddress,
        params: {
          contractAddress: this.address,
          chainName: CHAINS_CONSTANTS[this.chainId].general.chainName,
        },
      }
    }

    return this.routeToErc20AssetPage
  }

  get isTokenBundle(): boolean {
    return isTokenBundle(this.chainId, this.address)
  }

  get isLoanToken(): boolean {
    return isLoanToken(this.chainId, this.address)
  }

  get isNft(): boolean {
    return NFT_CATEGORIES.includes(this.category)
  }

  get isFungible(): boolean {
    return [AssetType.ERC20, AssetType.ERC1155, AssetType.NATIVE_TOKEN].includes(this.category)
  }

  get isErc20(): boolean {
    return this.category === AssetType.ERC20
  }

  get isValid(): boolean {
    if (isPwnAsset(this.chainId, this.address)) {
      return true
    }

    if (getAssetCustomDescriptionKey(this, this.chainId) === CustomAssetDescription.KyberSwap) {
      // There is no sense to do anything with closed position
      if (this.name.toLowerCase().includes('closed')) {
        return false
      }
    }

    return this.category !== AssetType.UNSUPPORTED
  }

  get hasSingleOwner(): boolean {
    // ERC1155 can have multiple owners
    return this.isNft && this.category !== AssetType.ERC1155
  }

  get isSymbolMissing(): boolean {
    return (
      this.symbol === AssetMetadata.MISSING_SYMBOL_PLACEHOLDER ||
      (this.isBundleWithSingleAsset && this.bundledAsset?.symbol === AssetMetadata.MISSING_SYMBOL_PLACEHOLDER)
    )
  }

  get isAssetNameMissing(): boolean {
    return (
      this.name === AssetMetadata.MISSING_NAME_PLACEHOLDER ||
      (this.isBundleWithSingleAsset && this.bundledAsset?.name === AssetMetadata.MISSING_NAME_PLACEHOLDER)
    )
  }

  // wrapped asset
  get isBundleWithSingleAsset(): boolean {
    return this.bundleAssets?.length === 1 && this.isTokenBundle
  }

  get isBundleWithMultipleAssets(): boolean {
    return this.bundleAssets?.length > 1 && this.isTokenBundle
  }

  get bundledAsset(): AssetWithAmount {
    if (!this.isBundleWithSingleAsset) throw new Error('Asset is not a single bundled asset')
    return this.bundleAssets[0]
  }

  get isMixedBundle(): boolean {
    return this.totalBundledAssets !== this.verifiedBundledAssets && this.verifiedBundledAssets !== 0
  }

  get isUnsupportedNft(): boolean {
    return this.category === AssetType.UNSUPPORTED && this.tokenId !== null && this.tokenId !== undefined
  }

  get isUnsupportedToken(): boolean {
    return this.category === AssetType.UNSUPPORTED && !this.isUnsupportedNft
  }

  get primaryInfoWithoutAmount(): string {
    switch (this.category) {
    case AssetType.ERC20:
    case AssetType.NATIVE_TOKEN:
      return this.symbol
    case AssetType.UNSUPPORTED:
      return this.isUnsupportedToken ? this.symbol : this.displayedName
    case AssetType.CRYPTOPUNKS:
    case AssetType.ERC721:
    case AssetType.ERC1155:
    default:
      return this.displayedName
    }
  }

  get secondaryInfo(): string {
    switch (this.category) {
    case AssetType.ERC20:
    case AssetType.NATIVE_TOKEN:
      return this.displayedName
    case AssetType.UNSUPPORTED:
      return this.isUnsupportedToken ? this.displayedName : this.collectionName
    case AssetType.CRYPTOKITTIES:
    case AssetType.CRYPTOPUNKS:
    case AssetType.ERC721:
    case AssetType.ERC1155:
    default:
      return this.collectionName
    }
  }

  get isPrimaryInfoMissing(): boolean {
    switch (this.category) {
    case AssetType.ERC20:
    case AssetType.NATIVE_TOKEN:
      return this.isSymbolMissing
    case AssetType.UNSUPPORTED:
      return this.isUnsupportedToken ? this.isSymbolMissing : this.isAssetNameMissing
    case AssetType.CRYPTOPUNKS:
    case AssetType.ERC721:
    case AssetType.ERC1155:
    default:
      return this.isAssetNameMissing
    }
  }

  get isSecondaryInfoMissing(): boolean {
    switch (this.category) {
    case AssetType.ERC20:
    case AssetType.NATIVE_TOKEN:
      return this.isAssetNameMissing
    case AssetType.UNSUPPORTED:
      return this.isUnsupportedToken ? this.isAssetNameMissing : this.assetCollection?.isNameMissing
    case AssetType.CRYPTOPUNKS:
    case AssetType.ERC721:
    case AssetType.ERC1155:
    default:
      return this.assetCollection?.isNameMissing
    }
  }

  get isNativeToken(): boolean {
    return this.category === AssetType.NATIVE_TOKEN ||
      this.address === zeroAddress ||
      // polygon have two native tokens and one has 0x0000000000000000000000000000000000001010 address
      (this.chainId === SupportedChain.Polygon && compareAddresses(this.address, '0x0000000000000000000000000000000000001010'))
  }

  public static createDaiAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_svg_icons/dai.svg`,
      isVerified: true,
      decimals: 18,
    })
  }

  public static createUsdcAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'USD Coin',
      symbol: 'USDC',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_svg_icons/usdc.svg`,
      isVerified: true,
      decimals: chainId === SupportedChain.Bsc ? 18 : 6,
    })
  }

  public static createPolygonUsdceAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'USD Coin',
      symbol: 'USDC.e',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_svg_icons/usdc.svg`,
      isVerified: true,
      decimals: 6,
    })
  }

  public static createUsdtAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'Tether USD',
      symbol: 'USDT',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_svg_icons/usdt.svg`,
      isVerified: true,
      decimals: chainId === SupportedChain.Bsc ? 18 : 6,
    })
  }

  public static createMainnetUsdtAssetMetadata() {
    return this.createUsdtAssetMetadata(
      SupportedChain.Ethereum,
      getAddress('0xdAC17F958D2ee523a2206206994597C13D831ec7'),
    )
  }

  public static createSepoliaUsdtAssetMetadata() {
    return this.createUsdtAssetMetadata(
      SupportedChain.Sepolia,
      getAddress('0x7169d38820dfd117c3fa1f22a697dba58d90ba06'),
    )
  }

  public static createWethAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'Wrapped Ether',
      symbol: 'WETH',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_png_icons/weth.png`,
      isVerified: true,
      decimals: 18,
    })
  }

  public static createWmaticAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'Wrapped Matic',
      symbol: 'WMATIC',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_png_icons/wmatic.svg`,
      isVerified: true,
      decimals: 18,
    })
  }

  public static createWcronosAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'Wrapped Cronos',
      symbol: 'WCRO',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_svg_icons/wcro.svg`,
      isVerified: true,
      decimals: 18,
    })
  }

  public static createWbnbAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_svg_icons/wbnb.svg`,
      isVerified: true,
      decimals: 18,
      ...(chainId === SupportedChain.Sepolia && { appraisal: generateFakeErc20Price(address) }),
    })
  }

  public static createEtherFiWETHAssetMetadata(): AssetMetadata {
    return new AssetMetadata({
      chainId: SupportedChain.Ethereum,
      address: getAddress('0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee'),
      category: AssetType.ERC20,
      name: 'Wrapped eETH',
      symbol: 'weETH',
      image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/28695.png',
      isVerified: true,
      decimals: 18,
    })
  }

  public static createEtherFiEETHAssetMetadata(): AssetMetadata {
    return new AssetMetadata({
      chainId: SupportedChain.Ethereum,
      address: getAddress('0x35fA164735182de50811E8e2E824cFb9B6118ac2'),
      category: AssetType.ERC20,
      name: 'ether.fi ETH',
      symbol: 'eETH',
      image: 'https://s3.coinmarketcap.com/static-gravity/image/6a2b46c7b1044ab79fbda111ce06a25e.png',
      isVerified: true,
      decimals: 18,
    })
  }

  public static createPwndAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'PWN D Faucet Token',
      symbol: 'PWND',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_svg_icons/pwnd.svg`,
      isVerified: true,
      decimals: 18,
      ...(chainId === SupportedChain.Sepolia && { appraisal: generateFakeErc20Price(address) }),
    })
  }

  public static createPwnsAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'PWN S Faucet Token',
      symbol: 'PWNS',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_svg_icons/pwns.svg`,
      isVerified: true,
      decimals: 18,
    })
  }

  public static createGnosisWxdaiAssetMetadata(chainId: SupportedChain, address: Address): AssetMetadata {
    return new AssetMetadata({
      chainId,
      address,
      category: AssetType.ERC20,
      name: 'Wrapped XDAI',
      symbol: 'WXDAI',
      image: `${globalConstants.backendStaticFilesUrl}/erc20_coin_png_icons/wxdai.png`,
      isVerified: true,
    })
  }

  public static createNativeTokenAssetMetadata(chainId: SupportedChain): AssetMetadata {
    const wagmiChain = getWagmiChain(chainId)

    return new AssetMetadata({
      address: zeroAddress,
      category: AssetType.NATIVE_TOKEN,
      name: wagmiChain.nativeCurrency.name,
      symbol: wagmiChain.nativeCurrency.symbol,
      image: getNativeTokenIconUrl(wagmiChain.id),
      isVerified: true,
      decimals: wagmiChain.nativeCurrency.decimals,
      collectionName: undefined,
      tokenId: undefined,
      chainId,
    })
  }

  public static isAtrToken(asset: AssetMetadata): asset is AssetWithAmount {
    if (!isAddress(asset.address)) {
      return false
    }
    return compareAddresses(asset.address, CHAINS_CONSTANTS[asset.chainId].pwnSafeContracts?.assetTransferRights)
  }
}

export class AssetWithAmount extends AssetMetadata {
  public readonly amount: string
  public readonly tokenizedAmount: string
  public amountInput: string
  public protocol?: DepositProtocol
  public apy?: string
  public sourceOfFunds?: Address // address of a pool
  public aTokenAddress?: Address // address of a corresponding Aave token
  public healthFactor?: string

  constructor(asset?: Partial<AssetWithAmount>) {
    super(asset)

    this.amount = asset?.amount ?? ''
    this.tokenizedAmount = asset?.tokenizedAmount ?? '0'
    this.amountInput = asset?.amountInput ?? ''
    this.apy = asset?.apy ?? undefined
    this.protocol = asset?.protocol ?? undefined
    this.sourceOfFunds = asset?.sourceOfFunds ?? undefined
    this.aTokenAddress = asset?.aTokenAddress ?? undefined
    this.healthFactor = asset?.healthFactor ?? undefined
  }

  public static override createFromBackendModel(asset: AllAssetModels & { balance: string }): AssetWithAmount {
    const assetMetadata = super.createFromBackendModel(asset)

    let tokenizedAmount = 0n
    if (assetMetadata?.hasAtrToken) {
      for (const atrToken of (asset.atr_tokens_of_user ?? [])) {
        if (!atrToken.is_tokenized_asset_in_user_safe || !atrToken.tokenized_amount) {
          continue
        }
        tokenizedAmount = tokenizedAmount + BigInt(atrToken.tokenized_amount)
      }
    }

    const parsedTokenizedAmount = tokenizedAmount !== 0n ? formatAmountWithDecimals(tokenizedAmount, asset.contract.decimals ?? undefined) : null

    const erc20Decimals = asset?.contract?.decimals ?? 18

    const decimalsFallback = asset.contract.category === AssetType.ERC20 ? erc20Decimals : undefined
    return new AssetWithAmount({
      ...assetMetadata,
      amount: asset.balance && decimalsFallback ? formatAmountWithDecimals(BigInt(asset.balance), decimalsFallback) : asset.balance,
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      tokenizedAmount: parsedTokenizedAmount,
    })
  }

  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
  public updateAssetAmounts({ amount = null, tokenizedAmount = null }: { amount?: string, tokenizedAmount?: string }): this {
    // little JS/TS magic, so this method works fine also for child classes of AssetWithAmount, for example DesiredLoanTerms
    // inspired by: https://stackoverflow.com/questions/73256370/typescript-creating-child-class-instance-inside-parent-class
    const AssetWithAmountOrItsChildClassConstructor: new (asset: this) => this = Object.getPrototypeOf(this).constructor
    return new AssetWithAmountOrItsChildClassConstructor({
      ...this,
      ...(amount !== null && { amount }),
      ...(tokenizedAmount !== null && { tokenizedAmount }),
    })
  }

  override get uniqueIdentifier(): string {
    return `${super.uniqueIdentifier}_${String(this.amountRaw)}_${this.amountInput}`
  }

  get isTransferDisabled(): boolean {
    return this.maxAvailableAmountRaw <= 0n
  }

  get isTokenizeDisabled(): boolean {
    return this.maxAvailableAmountRaw <= 0n || !this.isWhitelistedForPwnSafe
  }

  get amountInputRaw(): bigint {
    return getRawAmount(this.amountInput, this.decimals)
  }

  get isAssetAmountInputValid(): boolean {
    if (this.isNativeToken) {
      return this.amountInputRaw > 0n && this.amountInputRaw < this.maxAvailableAmountRaw
    }
    return this.amountInputRaw > 0n && this.amountInputRaw <= this.maxAvailableAmountRaw
  }

  get isAssetAmountInputValidOrEmpty(): boolean {
    return this.isAssetAmountInputValid || this.amountInput === '' // todo || !this.address ?
  }

  get isInsufficientAmountOfNativeToken(): boolean {
    if (this.isNativeToken) {
      return this.amountInputRaw >= this.maxAvailableAmountRaw
    }
    return false
  }

  get maxAvailableAmount(): string {
    return formatAmountWithDecimals(this.amountRaw - this.tokenizedAmountRaw, this.decimals)
  }

  get maxAvailableAmountRaw(): bigint {
    return getRawAmount(this.maxAvailableAmount, this.decimals)
  }

  get maxAvailableAmountFormatted(): string {
    return formatToken(this.maxAvailableAmount)
  }

  get amountInputFormatted(): string {
    return formatToken(this.amountInput)
  }

  get amountFormatted(): string {
    return formatToken(this.amount)
  }

  get amountShortened(): string {
    return shortenNumber(this.amount)
  }

  get displayAmount(): string {
    // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    return displayTokenAmount(this.amount)
  }

  get amountRaw(): bigint {
    return getRawAmount(this.amount, this.decimals)
  }

  get tokenizedAmountRaw(): bigint {
    return getRawAmount(this.tokenizedAmount, this.decimals)
  }

  get amountFormattedWithCurrency(): string {
    return `${this.amountFormatted} ${this.symbol || ''}`
  }

  get isMaxAvailableAmountDivisible(): boolean {
    return this.category === AssetType.ERC20 || this.category === AssetType.NATIVE_TOKEN || (this.category === AssetType.ERC1155 && this.maxAvailableAmount !== '1')
  }

  get isTotalAmountDivisible(): boolean {
    return this.category === AssetType.ERC20 || this.category === AssetType.NATIVE_TOKEN || (this.category === AssetType.ERC1155 && this.amount !== '1')
  }

  // @ts-expect-error TS(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  get primaryInfoWithAmountInput(): string {
    switch (this.category) {
    case AssetType.ERC20:
    case AssetType.NATIVE_TOKEN:
      return `${this.amountInput} ${this.symbol}`
    case AssetType.UNSUPPORTED:
      return `${this.amountInput} ${this.isUnsupportedToken ? this.symbol : this.name}`
    case AssetType.ERC721:
      return this.name
    case AssetType.ERC1155:
      return this.isTokenBundle ? this.name : `${this.amountInput} ${this.name}`
    }
  }

  get primaryInfo(): string {
    switch (this.category) {
    case AssetType.ERC20:
    case AssetType.NATIVE_TOKEN:
      return `${this.amount} ${this.symbol}`
    case AssetType.UNSUPPORTED:
      return `${this.amount} ${this.isUnsupportedToken ? this.symbol : this.name}`
    case AssetType.ERC721:
      return this.name
    case AssetType.ERC1155:
      return this.isTokenBundle ? this.name : `${this.amount} ${this.name}`
    default:
      throw new Error('Unsupported asset type')
    }
  }

  primaryInfoToRepay(amount: string | number): string {
    switch (this.category) {
    case AssetType.ERC20:
    case AssetType.NATIVE_TOKEN:
      return `${amount} ${this.symbol}`
    case AssetType.UNSUPPORTED:
      return `${amount} ${this.isUnsupportedToken ? this.symbol : this.name}`
    case AssetType.ERC721:
      return this.name
    case AssetType.ERC1155:
      return this.isTokenBundle ? this.name : `${amount} ${this.name}`
    default:
      throw new Error('Unsupported asset type')
    }
  }

  get primaryInfoFormatted(): string {
    switch (this.category) {
    case AssetType.ERC20:
    case AssetType.NATIVE_TOKEN:
      return `${this.amountFormatted} ${this.symbol}`
    case AssetType.UNSUPPORTED:
      return `${this.amountFormatted} ${this.isUnsupportedToken ? this.symbol : this.name}`
    case AssetType.ERC721:
      return this.name
    case AssetType.ERC1155:
      return this.isTokenBundle ? this.name : `${this.amountFormatted} ${this.name}`
    default:
      return ''
    }
  }

  getAppraisalFullAmount = (appraisal: AssetPrice): AmountInEthAndUsd => {
    const fullAmountCalculation = (ethPrice: string, usdPrice: string) => {
      const tokenAmount = +(this.isBundleWithSingleAsset ? this.bundledAsset.amount : this.amount)
      const ethAmount = String(+ethPrice * tokenAmount)
      const usdAmount = String(+usdPrice * tokenAmount)
      if (
        ethAmount === '0' ||
        ethAmount === 'NaN' ||
        usdAmount === '0' ||
        usdAmount === 'NaN'
      ) return undefined
      return new AmountInEthAndUsd(ethAmount, usdAmount)
    }
    if (this.isNft && !this.isFungible) {
      if (
        (!appraisal?.price?.ethAmount || appraisal?.price?.ethAmount === '0') &&
        (!this?.nftPriceStats?.appraisal?.ethAmount || this?.nftPriceStats?.appraisal?.ethAmount === '0')
      // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'Amount... Remove this comment to see the full error message
      ) return undefined
      return this?.nftPriceStats?.appraisal || appraisal?.price
    }

    if (this.isNft && this.isFungible) {
      if (appraisal?.priceFullAmount) {
        return appraisal.priceFullAmount
      }
      if (this?.nftPriceStats?.appraisal) {
        // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
        return fullAmountCalculation(this?.nftPriceStats?.appraisal.ethAmount, this?.nftPriceStats?.appraisal.usdAmount)
      }
      if (appraisal?.price) {
        // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
        return fullAmountCalculation(appraisal?.price.ethAmount, appraisal?.price.usdAmount)
      }
      // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'Amount... Remove this comment to see the full error message
      return undefined
    }

    if (this.isErc20) {
      if (appraisal?.priceFullAmount) {
        return appraisal.priceFullAmount
      }
      if (appraisal?.price) {
        // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
        return fullAmountCalculation(appraisal?.price.ethAmount, appraisal?.price.usdAmount)
      }
      // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'Amount... Remove this comment to see the full error message
      return undefined
    }

    // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'Amount... Remove this comment to see the full error message
    return undefined
  }

  get appraisalFullAmount(): AmountInEthAndUsd | undefined {
    return this.getAppraisalFullAmount(this.isBundleWithSingleAsset ? this.bundledAsset.appraisal : this?.appraisal)
  }

  calculateFullAmountInput(appraisal: AmountInEthAndUsd): AmountInEthAndUsd | undefined {
    const fullAmountCalculation = (ethPrice: string, usdPrice: string) => {
      const tokenAmount = +(this.isBundleWithSingleAsset ? this.bundledAsset.amount : this.amount)
      const amountInput = +(this.isBundleWithSingleAsset ? this.bundledAsset.amountInput : this.amountInput)
      const ethAmount = String(+ethPrice * (amountInput || tokenAmount))
      const usdAmount = String(+usdPrice * (amountInput || tokenAmount))
      if (
        ethAmount === '0' ||
        ethAmount === 'NaN' ||
        usdAmount === '0' ||
        usdAmount === 'NaN'
      ) return undefined
      return new AmountInEthAndUsd(ethAmount, usdAmount)
    }

    if (this.isNft) {
      if (
        (!appraisal?.ethAmount || appraisal?.ethAmount === '0') &&
        (!this?.nftPriceStats?.appraisal?.ethAmount || this?.nftPriceStats?.appraisal?.ethAmount === '0')
      ) return undefined

      if (!this.isFungible) {
        return this?.nftPriceStats?.appraisal || appraisal
      }

      if (this?.nftPriceStats?.appraisal) {
        return fullAmountCalculation(this?.nftPriceStats?.appraisal?.ethAmount, this?.nftPriceStats?.appraisal.usdAmount)
      }
      if (appraisal) {
        return fullAmountCalculation(appraisal?.ethAmount, appraisal?.usdAmount)
      }
      return undefined
    }

    if (this.isErc20) {
      if (appraisal) {
        return fullAmountCalculation(appraisal?.ethAmount, appraisal?.usdAmount)
      }
      return undefined
    }

    return undefined
  }

  get appraisalFullAmountInput(): AmountInEthAndUsd | undefined {
    const appraisal = this.isBundleWithSingleAsset ? this.bundledAsset?.appraisal : this?.appraisal
    if (appraisal && appraisal.price) {
      return this.calculateFullAmountInput(appraisal.price)
    }
  }

  get floorPrice(): AmountInEthAndUsd | undefined {
    return this?.nftPriceStats?.floorPrice || this.assetCollection?.collectionStats?.floorPrice
  }

  get floorPriceSource(): ValuationSource | undefined {
    return this?.nftPriceStats?.floorPriceSource || this.assetCollection?.collectionStats?.dataSource
  }

  get appraisalPrice(): AmountInEthAndUsd | undefined {
    if (this.isBundleWithSingleAsset) {
      return this.bundledAsset?.appraisal?.priceFullAmount
    }

    if (!this.appraisal?.price && this.appraisalFullAmount && this.amount) {
      const ethAmount = String(Number(this.appraisalFullAmount?.ethAmount) / Number(this.amount))
      const usdAmount = String(Number(this.appraisalFullAmount?.usdAmount) / Number(this.amount))
      return new AmountInEthAndUsd(ethAmount, usdAmount)
    }

    return this?.nftPriceStats?.appraisal || this.appraisal?.price
  }

  get appraisalPriceSource(): ValuationSource | undefined {
    // the order is set same as in appraisal related getters - 1.nftPriceStats 2.appraisal, due more updated data in nftPriceStats
    if (this.isNft) {
      if (this.isBundleWithSingleAsset) {
        return this.bundledAsset.appraisalPriceSource
      }
      return this?.nftPriceStats?.appraisalSource || this.appraisal?.priceSource
    }
    return this.appraisal?.priceSource
  }

  public static createNativeTokenAssetWithAmount(chainId: SupportedChain, amount: bigint): AssetWithAmount {
    const assetMetadata = super.createNativeTokenAssetMetadata(chainId)
    const wagmiChain = getWagmiChain(chainId)
    return new AssetWithAmount({
      ...assetMetadata,
      chainId,
      id: -chainId,
      amount: formatAmountWithDecimals(amount, wagmiChain.nativeCurrency.decimals),
    })
  }
}

export class AssetWithVolume extends AssetMetadata {
  public pastWeekVolume: AmountInEthAndUsd | undefined
  public price: AssetPrice | undefined

  constructor(asset?: Partial<AssetWithVolume>) {
    super(asset)

    // @ts-expect-error TS(2322) FIXME: Type 'AmountInEthAndUsd | undefined' is not assign... Remove this comment to see the full error message
    this.pastWeekVolume = asset.pastWeekVolume
    // @ts-expect-error TS(2322) FIXME: Type 'AssetPrice | null | undefined' is not assign... Remove this comment to see the full error message
    this.price = asset.price
  }

  public static override createFromBackendModel(asset: AllAssetModels): AssetWithVolume {
    const assetMetadata = super.createFromBackendModel(asset)

    return new AssetWithVolume({
      ...assetMetadata,
      price: asset.latest_price ? AssetPrice.createFromBackendModel(asset.latest_price) : undefined,
    })
  }
}

export enum InterestType {
  Interest = 'Interest %',
  APR = 'APR %'
}
export class DesiredLoanTerms extends AssetWithAmount {
  public durationDays: number
  public interestType: InterestType
  public desiredRepayment: string
  public loanExpirationDate?: Date
  public hasInstantFunding: boolean
  public loanRequestSignature?: string
  public loanRequestNonce?: bigint | null
  public wasCreatedWithInstantFunding?: boolean
  public loanRequestHash?: string
  public fixedInterestAmount?: bigint

  constructor(desiredLoanTerms?: Partial<DesiredLoanTerms>) {
    super(desiredLoanTerms)

    this.durationDays = desiredLoanTerms?.durationDays || DEFAULT_LOAN_DURATION_IN_DAYS
    this.interestType = desiredLoanTerms?.interestType || InterestType.APR
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.desiredRepayment = isNaN(Number(desiredLoanTerms?.desiredRepayment)) ? '' : desiredLoanTerms?.desiredRepayment
    this.hasInstantFunding = desiredLoanTerms?.hasInstantFunding ?? Boolean(desiredLoanTerms?.loanRequestSignature)
    this.loanRequestSignature = desiredLoanTerms?.loanRequestSignature || ''
    // @ts-expect-error TS(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
    this.loanExpirationDate = desiredLoanTerms?.loanExpirationDate || null
    this.loanRequestNonce = desiredLoanTerms?.loanRequestNonce || null
    this.wasCreatedWithInstantFunding = desiredLoanTerms?.wasCreatedWithInstantFunding
    this.loanRequestHash = desiredLoanTerms?.loanRequestHash
    this.fixedInterestAmount = desiredLoanTerms?.fixedInterestAmount
  }

  public static createDesiredLoanTermsFromLoanModel(
    {
      desiredAsset,
      desiredAmount,
      desiredRepayment,
      desiredDuration,
      loanExpirationDate,
      loanRequestSignature,
      loanRequestNonce,
      loanRequestHash,
    } : {
      desiredAsset: ERC20AssetSchemaBackendSchema,
      desiredAmount: string,
      desiredRepayment: string,
      desiredDuration: number,
      loanExpirationDate?: Date,
      loanRequestSignature?: string,
      loanRequestNonce?: string,
      loanRequestHash?: string,
    }): DesiredLoanTerms {
    const assetWithAmount = super.createFromBackendModel({
      ...desiredAsset,
      balance: desiredAmount,
    })
    return new DesiredLoanTerms({
      ...assetWithAmount,
      amountInput: desiredAmount ? formatAmountWithDecimals(BigInt(desiredAmount), desiredAsset.contract.decimals ?? undefined) : undefined,
      durationDays: DateUtils.convertSecondsToDays(desiredDuration),
      desiredRepayment: desiredRepayment ? formatAmountWithDecimals(BigInt(desiredRepayment), desiredAsset.contract.decimals ?? undefined) : undefined,
      loanExpirationDate,
      loanRequestHash,
      loanRequestSignature,
      hasInstantFunding: Boolean(loanRequestSignature),
      wasCreatedWithInstantFunding: Boolean(loanRequestSignature),
      loanRequestNonce: loanRequestNonce ? BigInt(loanRequestNonce) : undefined,
    })
  }

  get durationVerbose(): string {
    return this.durationDays ? `${this.durationDays}d` : ''
  }

  get loanExpirationDays(): number | null {
    if (!this.loanExpirationDate) {
      return null
    }
    const days = DateUtils.getDaysDifference(this.loanExpirationDate, new Date())
    if (days === undefined) {
      return null
    }

    return days
  }

  get isLoanRequestExpired(): boolean {
    return !!this.loanExpirationDate && this.loanExpirationDate < new Date()
  }

  get loanRequestExpirationDaysVerbose(): string {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | undefined' is not assigna... Remove this comment to see the full error message
    if (this.isLoanRequestExpired) return DateUtils.getFormattedTimeAgo(this.loanExpirationDate)
    return `${this.loanExpirationDays} ${singularOrPlural(this.durationDays, 'Day')}`
  }

  get durationVerboseLong(): string {
    return `${this.durationDays} ${singularOrPlural(this.durationDays, 'Day')}`
  }

  get desiredAmountRaw(): bigint {
    return getRawAmount(this.amountInput, this.decimals)
  }

  get desiredRepaymentRaw(): bigint {
    return getRawAmount(this.desiredRepayment, this.decimals)
  }

  get desiredLoanYieldRaw(): bigint {
    return this.desiredRepaymentRaw - this.desiredAmountRaw
  }

  get desiredRepaymentAsToken(): AssetWithAmount {
    return this.updateAssetAmounts({ amount: this.desiredRepayment })
  }

  get desiredInterestVerbose(): string {
    return String(calculateInterest(
      this.amount,
      this.desiredRepayment,
    )) + '%'
  }

  get desiredAPR(): number | null {
    const apr = calculateApr(
      this.amount,
      this.desiredRepayment,
      this.durationDays,
    )
    if (!apr && apr !== 0) { return null }
    return apr
  }

  get desiredAPRVerbose(): string | null {
    if (this.desiredAPR === 0) return this.desiredAPR + '%'
    return this.desiredAPR ? this.desiredAPR + '%' : null
  }
}
