import type { CollectionSearchResult } from '@/modules/common/pwn/explorer/models/CollectionSearchResult'
import type { SortDirection } from '@/general-components/sorting/SortDirection'
import { compareNumbers, compareStrings } from '@/general-components/sorting/SortFunctions'
import type CollectionStats from '@/modules/common/assets/typings/CollectionStats'
import type TokenPriceStats from '@/modules/common/assets/typings/TokenPriceStats'
import type { ERC20AssetSearchResult } from '@/modules/common/pwn/explorer/models/ERC20AssetSearchResult'
import type NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'

export type CollectionSearchResultWithStats = CollectionSearchResult & {
    stats: CollectionStats
}

export const sortCollectionResultsByTradingVolume = (collections: CollectionSearchResultWithStats[], order: SortDirection): CollectionSearchResultWithStats[] => {
  return collections?.sort((a, b) => {
    if (!a.stats?.totalVolume) {
      return 1
    } else if (!b.stats?.totalVolume) {
      return -1
    } else {
      return compareNumbers(Number(a.stats.totalVolume.ethAmount), Number(b.stats.totalVolume.ethAmount), order)
    }
  })
}

export const sortCollectionResultsByPastWeekVolume = (collections: CollectionSearchResultWithStats[], order: SortDirection): CollectionSearchResultWithStats[] => {
  return collections?.sort((a, b) => {
    if (!a.stats?.pastWeekVolume) {
      return 1
    } else if (!b.stats?.pastWeekVolume) {
      return -1
    } else {
      return compareNumbers(Number(a.stats.pastWeekVolume.ethAmount), Number(b.stats.pastWeekVolume.ethAmount), order)
    }
  })
}

export const sortCollectionResultsByName = (collections: CollectionSearchResultWithStats[], order: SortDirection): CollectionSearchResultWithStats[] => {
  return collections?.sort((a, b) => {
    if (!a.title) {
      return 1
    } else if (!b.title) {
      return -1
    } else {
      return compareStrings(a.title, b.title, order)
    }
  })
}

export const sortCollectionResultsByMarketCap = (collections: CollectionSearchResultWithStats[], order: SortDirection): CollectionSearchResultWithStats[] => {
  return collections?.sort((a, b) => {
    if (!a.stats?.marketCap) {
      return 1
    } else if (!b.stats?.marketCap) {
      return -1
    } else {
      return compareNumbers(Number(a.stats.marketCap.usdAmount), Number(b.stats.marketCap.usdAmount), order)
    }
  })
}

export type TokenSearchResultWithStats = ERC20AssetSearchResult & {
    stats: TokenPriceStats
}

export const sortTokenResultsByTradingVolume = (tokens: TokenSearchResultWithStats[], order: SortDirection): TokenSearchResultWithStats[] => {
  return tokens?.sort((a, b) => {
    if (!a.stats?.volume24h?.usdAmount || !a.stats?.volume24h?.ethAmount) {
      return 1
    } else if (!b.stats?.volume24h?.usdAmount || !b.stats?.volume24h?.ethAmount) {
      return -1
    } else {
      // it covers the case when one of the tokens has no volume in eth or the volume might not be correct
      const valueToUse = (a.stats?.volume24h?.ethAmount && b.stats?.volume24h?.ethAmount) && (a.chainId === b.chainId) ? 'ethAmount' : 'usdAmount'
      return compareNumbers(Number(a.stats?.volume24h?.[valueToUse]), Number(b.stats?.volume24h?.[valueToUse]), order)
    }
  })
}

export const sortTokenResultsByName = (tokens: TokenSearchResultWithStats[], order: SortDirection): TokenSearchResultWithStats[] => {
  return tokens?.sort((a, b) => {
    if (!a.title) {
      return 1
    } else if (!b.title) {
      return -1
    } else {
      return compareStrings(a.title, b.title, order)
    }
  })
}

export const sortTokenResultsByPrice = (tokens: TokenSearchResultWithStats[], order: SortDirection): TokenSearchResultWithStats[] => {
  return tokens?.sort((a, b) => {
    if (!a.stats?.latestPrice?.price) {
      return 1
    } else if (!b.stats?.latestPrice?.price) {
      return -1
    } else {
      return compareNumbers(Number(a.stats.latestPrice.price.ethAmount), Number(b.stats.latestPrice.price.ethAmount), order)
    }
  })
}

export type NFTSearchResultWithStats = ERC20AssetSearchResult & {
    stats: NFTPriceStats
}

export const sortNFTResultsByAppraisal = (nfts: NFTSearchResultWithStats[], order: SortDirection): NFTSearchResultWithStats[] => {
  return nfts?.sort((a, b) => {
    if (!a.stats?.appraisal) {
      return 1
    } else if (!b.stats?.appraisal) {
      return -1
    } else {
      return compareNumbers(Number(a.stats.appraisal.ethAmount), Number(b.stats.appraisal.ethAmount), order)
    }
  })
}

export const sortNFTResultsByName = (nfts: NFTSearchResultWithStats[], order: SortDirection): NFTSearchResultWithStats[] => {
  return nfts?.sort((a, b) => {
    if (!a.title) {
      return 1
    } else if (!b.title) {
      return -1
    } else {
      return compareStrings(a.title, b.title, order)
    }
  })
}
