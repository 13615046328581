<template>
  <div
    class="the-wallet-trigger"
    @click.stop="handleWalletClick">
    <div
      v-if="amountOfUnseenNotifications && userAddress"
      class="the-wallet-trigger__amount-of-notifications">
      {{ amountOfUnseenNotifications }}
    </div>
    <WalletIconSvg
      class="the-wallet-trigger__icon"
      alt="wallet icon"/>
    <div
      v-if="hasAddress"
      class="the-wallet-trigger__address-and-caret">
      <div class="the-wallet-trigger__address">
        <CryptoAddress
          :has-tooltip="false"
          :has-avatar="false"
          hide-text-you
          :address="userAddress!"/>
      </div>
    </div>
    <span
      v-else-if="!hasAddress"
      class="the-wallet-trigger__connect">
      Connect
    </span>
  </div>
</template>

<script setup lang="ts">
import WalletIconSvg from '@/assets/icons/wallet-white.svg'
import CryptoAddress from '@/general-components/CryptoAddress.vue'
import useNotifications from '@/modules/common/notifications/useNotifications'
import { computed } from 'vue'
import { shortenAddress } from '@/utils/utils'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useCustomConnect } from '@/modules/common/web3/useCustomConnect'

const { amountOfUnseenNotifications } = useNotifications()
const { address: userAddress } = useCustomAccount()
const { handleConnect } = useCustomConnect()

const addressShort = computed(() => userAddress.value ? shortenAddress(userAddress.value) : '')
const hasAddress = computed(() => userAddress.value && addressShort.value.length > 0)

const emit = defineEmits<{(e: 'toggle-menu'): void; }>()

const handleWalletClick = async () => {
  if (hasAddress.value) {
    emit('toggle-menu')
  } else {
    // https://docs.walletconnect.com/web3modal/vue/composables#useweb3modal
    // TODO do we care about additional parameter `view`?
    await handleConnect()
  }
}

</script>

<style scoped>
.the-wallet-trigger {
  width: 8.5625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  padding: 0 0.5rem;
  font-size: 0.75rem;
  height: 2rem;
  border: 1px solid rgb(var(--text-color-base) / 50%);

  &__icon {
    width: 1.25rem;
  }

  &:hover {
    cursor: pointer;
    color: var(--primary-color-1);
    border-color: var(--primary-color-1);

    .the-wallet-trigger__icon {
      fill: var(--primary-color-1);
    }
  }

  &__amount-of-notifications {
    --amount-of-notificaitons-div-height: 1.5rem;
    position: absolute;
    top: -14px;
    right: -14px;

    display: grid;
    place-items: center;

    min-width: var(--amount-of-notificaitons-div-height);
    height: var(--amount-of-notificaitons-div-height);
    z-index: var(--z-index-unseen-notifications-amount);

    border: solid 1px var(--primary-color-1);
    background: var(--background-color);
    font-family: var(--font-family-supreme);
    line-height: var(--amount-of-notificaitons-div-height);
  }

  &__address-and-caret {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 6.3125rem;
  }

  &__address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    width: 100%;
    padding-left: 0.5rem;
    margin-bottom: -2px;
  }

  &__connect {
    width: 100%;
    text-align: center;
    padding-left: 0.5rem;
    margin-bottom: -2px;
  }
}
</style>
