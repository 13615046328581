<template>
  <PleaseConnectWallet
    v-if="isLoginPrompt"
    :is-login-prompt="isLoginPrompt"
    message="your dashboard"/>
  <div
    v-else-if="isStarknet || isValidUser || isCheckingValidUser"
    class="dashboard">
    <DashboardHeader
      v-if="route.params.user"/>

    <section class="dashboard__main-tile">
      <DashboardBigTabButtons/>
      <BaseEmptyStateText
        v-if="hasNoSearchResults"
        :text="getEmptyStateText"/>
      <SectionUserAssets
        v-if="isComponentDisplayed('assets')"
        :nfts="userNfts"
        :tokens="userErc20s"
        :positions="positions"
        :is-loading-positions="isLoading"
        :error-positions="error"
        :filtered-nfts="totalExcludedUserNfts"
        :filtered-tokens="totalExcludedUserErc20s"
        class="dashboard__your-assets"/>
      <SectionAllYourLending
        v-if="isComponentDisplayed('lending')"
        class="dashboard__your-lending"/>
      <SectionAllYourBorrowing
        v-if="isComponentDisplayed('borrowing')"
        class="dashboard__your-borrowing"
        :is-dashboard="true"/>
    </section>
  </div>
  <div v-else-if="!isValidUser">
    <PageNotFound
      image-size="s"
      text="Invalid address, user not found"/>
  </div>
</template>

<script setup lang="ts">
import DashboardHeader from '@/modules/pages/dashboard/DashboardHeader.vue'
import SectionUserAssets from '@/modules/sections/SectionYourAssets.vue'
import SectionAllYourBorrowing from '@/modules/sections/SectionAllYourBorrowing.vue'
import SectionAllYourLending from '@/modules/sections/SectionAllYourLending.vue'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PageNotFound from '@/modules/pages/not-found/PageNotFound.vue'
import BaseEmptyStateText from '@/general-components/BaseEmptyStateText.vue'
import PleaseConnectWallet from '@/general-components/PleaseConnectWallet.vue'
import RouteName from '@/router/RouteName'
import { compareAddresses } from '@/utils/utils'
import useDashboardCache from '@/modules/pages/dashboard/hooks/useDashboardCache'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import useDepositedAssets from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import DashboardBigTabButtons from '@/modules/pages/dashboard/DashboardBigTabButtons.vue'

const { dashboardComponentToDisplay, searchTerm, initPage, isValidUser, isCheckingValidUser, reset, checkAndParseUser } = useDashboard()
const { dashboardUserAddress } = useDashboard()
const userAssetsStore = useUserAssetsStore()
const { userNfts, userErc20s, totalExcludedUserNfts, totalExcludedUserErc20s } = storeToRefs(userAssetsStore)
const { isConnected, address: userAddress, chainId: connectedChainId } = useCustomAccount()

const isComponentDisplayed = (name: string) => dashboardComponentToDisplay.value.toString() === name || searchTerm.value
const hasNoSearchResults = computed(() => {
  return (
    searchTerm.value &&
    userNfts.value.length === 0 &&
    userErc20s.value.length === 0
  )
})
const getEmptyStateText = computed(() => `No results for your search "${searchTerm.value}"`)

const route = useRoute()
const router = useRouter()

watch(
  [() => route.params.user as string, userAddress],
  async ([routerParamsUser, currentUserAddress], [oldRouterParams]) => {
    if (routerParamsUser !== oldRouterParams) {
      reset()
    }

    const targetUserAddress = await checkAndParseUser(routerParamsUser)

    if (targetUserAddress) {
      dashboardUserAddress.value = targetUserAddress

      const isLoggedInUser = currentUserAddress ? compareAddresses(currentUserAddress, targetUserAddress) : false

      userAssetsStore.loadUserAssets(targetUserAddress)
      const cacheDate = await useDashboardCache().loadCacheState({
        userAddress: targetUserAddress,
        // @ts-expect-error FIXME: strictNullChecks
        chainId: connectedChainId.value,
      })
      if (useDashboardCache().shouldAutomaticallyInvalidate(cacheDate) || isLoggedInUser) {
        await userAssetsStore.invalidateAndRefetch(targetUserAddress)
      }
    } else {
      isValidUser.value = false
    }

    await initPage(route)
  },
  {
    immediate: true,
  },
)

watch(userAddress, async (currentAddress, prevAddress) => {
  if (!route.params.user || prevAddress === route.params.user) {
    await router.push({ name: RouteName.Dashboard, params: { user: currentAddress } })
  }
})

const { data: positions, isLoading, error } = useQuery({
  queryKey: ['graphData', dashboardUserAddress],
  queryFn: async () => {
    return useDepositedAssets().fetchAllPositions(dashboardUserAddress.value)
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 30,
})

const isLoginPrompt = computed(() => (!route.params.user && !isConnected.value) || (isConnected.value && route.params.user === ''))
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 2.5rem;
  padding-top: 3rem;

  &__main-tile {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    border: 1px solid #434343;
    margin-bottom: 7rem;
  }

  &__empty-state {
    position: absolute;
    background-image: url("@/assets/images/dashboard-skeleton.svg");
    max-width: var(--app-max-width);
    min-width: var(--app-min-width);
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    font-size: larger;
    font-family: var(--font-family-autoscape);
  }

  &__empty-state-message {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 20%;
  }

  &__connect-wallet-button {
    margin-top: 3rem;
  }

  @media only screen and (--mobile-viewport) {
    &__header {
      padding-top: 0;
      margin-top: 1rem;
    }

    &__your-assets {
      margin-bottom: 5rem;
    }
  }
}
</style>
