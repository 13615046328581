import type {
  ChainIdEnumBackendSchema,
} from '@/modules/common/backend/generated'
import {
  web3authMessageToSignRetrieve,
  web3authTokenCreate,
  web3authTokenRefreshCreate,
} from '@/modules/common/backend/generated'
import to from '@/utils/await-to-js'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { signEip191 } from '@/modules/common/web3/useSignatures'
import type { AxiosError } from 'axios'
import { useAccessTokenStore } from './useAccessTokenStore'

export default function useAuth() {
  const loginAndCreateTokens = async () => {
    const { address: userAddress, chainId: connectedChainId } = getAccount(pwnWagmiConfig)
    const messageToSign = await web3authMessageToSignRetrieve(userAddress!)

    const { signature } = await signEip191({ message: messageToSign.data })

    return await web3authTokenCreate(
      userAddress!,
      {
        chain_id: connectedChainId as unknown as ChainIdEnumBackendSchema,
        signature,
      },
    )
  }

  const tryFetchAccessTokenFromRefresh = async (): Promise<boolean> => { // returns true on success, otherwise false
    const { address: userAddress } = getAccount(pwnWagmiConfig)

    const [error, fetchedAccessToken] = await to(web3authTokenRefreshCreate(userAddress!))
    const hasFetchedAccessToken = fetchedAccessToken?.data && !error

    if (hasFetchedAccessToken) {
      useAccessTokenStore().setAccessToken(fetchedAccessToken.data)
    }
    return !!hasFetchedAccessToken
  }

  const checkIfUserNeedsToLogin = async (): Promise<boolean> => {
    if (useAccessTokenStore().accessToken.value?.isValid) {
      return false
    }

    const hasFetchedAccessToken = await tryFetchAccessTokenFromRefresh()
    return !hasFetchedAccessToken
  }

  type FunctionReturningPromise<T> = () => Promise<T>
  const fetchRestrictedEndpoint = async <T>(functionReturningPromise: FunctionReturningPromise<T>): Promise<T> => {
    if (useAccessTokenStore().accessToken.value?.isValid) {
      const [error, response] = await to(functionReturningPromise()) as [AxiosError, T]
      if (!error) {
        return response
      } else if (error && error?.status !== 401 && error?.status !== 403) {
        // We received error, but it's not related to missing auth/permissions. In that case return the error.
        throw error
      }
    }

    const hasSuccessfullyFetchedAccessToken = await tryFetchAccessTokenFromRefresh()
    if (hasSuccessfullyFetchedAccessToken) {
      return await functionReturningPromise()
    }

    const accessTokenResponse = await loginAndCreateTokens()
    useAccessTokenStore().setAccessToken(accessTokenResponse.data)
    return await functionReturningPromise()
  }

  return {
    fetchRestrictedEndpoint,
    checkIfUserNeedsToLogin,
    loginAndCreateTokens,
  }
}
