import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as polygonIcon from '@/assets/icons/polygon-white.svg'
import * as openSeaIcon from '@/assets/icons/opensea.svg'
import type { Address } from 'viem'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'

const BLOCK_EXPLORER_URL = 'https://polygonscan.com'

const POLYGON_CONSTANTS: ChainConstants = {
  general: {
    chainId: SupportedChain.Polygon,
    chainName: 'Polygon',
  },
  nodeProvider: {
    httpNodeUrl: 'https://polygon-mainnet.g.alchemy.com/v2/',
    wsNodeUrl: `wss://polygon-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_POLYGON_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_POLYGON_ALCHEMY_NODE_KEY as string,
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'polygonscan',
    blockExplorerIcon: polygonIcon.default,
    nftDetailsLink: (tokenAddress, tokenId) => `https://opensea.io/assets/matic/${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: openSeaIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://opensea.io/collection/${slug}`,
  },
  pwnBetaContracts: {
    pwn: '0xBCdE56e9FB8c30aBB2D19Fb33D7DeD5031102da2',
    pwnLoan: '0x8680AEE63E48AACB51Ddc5Ad15979FC169C1cf2B',
    pwnVault: '0xaF0d978275a2e7e3109F8C6307Ffd281774C623E',
  },
  deprecatedPwnV1Contracts: {
    pwnSimpleLoan: '0x50160ff9c19fbE2B5643449e1A321cAc15af2b2C',
    pwnSimpleLoanSimpleOffer: '0xAbA34804D2aDE17dd5064Ac7183e7929E4F940BD',
  },
  pwnV1Contracts: {
    pwnSimpleLoan: '0x57c88D78f6D08b5c88b4A3b7BbB0C1AA34c3280A',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0xe52405604bf644349f57b36ca6e85cf095fab8da',
  pwnSafeContracts: {
    assetTransferRightsGuardProxy: '0xc390f85B5286DBA62C4f1AEC3f451b4267d594DA',
    assetTransferRights: '0xb20a1745692e8312bd4a2A0092b887526e547F9D',
    pwnSafeFactory: '0x408F179dBB365D6601083fb8fF01ff0E1C66AE28',
  },
  topTokens: new TopTokens({
    dai: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    usdc: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
    'usdc.e': '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    usdt: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    weth: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    wmatic: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  }),
  gnosisSafe: {
    transactionServiceUrl: 'https://safe-transaction-polygon.safe.global',
    chainName: 'matic',
  },
  nftContractsAddresses: {
    aavegotchis: '0x86935F11C86623deC8a25696E1C19a8659CbF95d',
    aavegotchiVault: '0x869d456197a3f56CA61dA1fa6D1032638a4644E8',
    meanFinanceDca: '0x20bdAE1413659f47416f769a4B27044946bc9923',
    kyberSwapElastic: '0xe222fBE074A436145b255442D919E4E3A6c6a480',
  },
  aave: {
    uiPoolDataProvider: '0x5598BbFA2f4fE8151f45bBA0a3edE1b54B51a0a9',
    poolAddressesProvider: '0xa97684ead0e402dC232d5A977953DF7ECBaB3CDb',
    pool: '0x794a61358D6845594F94dc1DB02A252b5b4814aD',
  },
  compound: {
    pools: ['0xF25212E676D1F7F89Cd72fFEe66158f541246445', '0xaeB318360f27748Acb200CE616E389A6C9409a07'],
  },
}
export default POLYGON_CONSTANTS
