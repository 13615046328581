<template>
  <div class="asset-base-info">
    <div class="asset-base-info__image-container">
      <AssetTooltip
        :asset="asset"
        append-to-body
        tooltip-placement="top">
        <TokenMedia
          class="asset-base-info__asset-image"
          width="50"
          height="50"
          :token="asset"/>
      </AssetTooltip>
    </div>

    <div class="asset-base-info__description">
      <AssetNameAndMaybeAmount
        v-if="displayAmount"
        :asset="asset"/>
      <div
        v-else
        class="asset-base-info__primary-info-without-amount">
        <SafeText
          :text="asset.primaryInfoWithoutAmount">
          <template #default>
            <span :class="['asset-name-and-maybe-amount__asset-label--name', {'asset-name-and-maybe-amount__asset-label--missing': asset.isPrimaryInfoMissing}]">
              {{ asset.primaryInfoWithoutAmount }}
            </span>
          </template>
        </SafeText>
      </div>
      <div class="asset-base-info__asset-collection">
        <SafeText
          :class="{'asset-base-info__asset-collection--missing': asset?.isSecondaryInfoMissing}"
          :text="asset?.secondaryInfo"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import TokenMedia from '@/general-components/TokenMedia.vue'
import SafeText from '@/general-components/SafeText.vue'
import { toRefs } from 'vue'
import AssetNameAndMaybeAmount from '@/modules/pages/pwn-safe/AssetNameAndMaybeAmount.vue'
import AssetTooltip from '@/general-components/AssetTooltip.vue'

interface Props {
  asset: AssetWithAmount
  displayAmount?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  displayAmount: true,
})
const { asset, displayAmount } = toRefs(props)
</script>

<style scoped>
.asset-base-info {
  --component-height: 3.125rem;
  display: flex;
  gap: 0.5rem;
  height: var(--component-height);
  align-items: center;

  &__image-container {
    width: 3.125rem;
    display: flex;
    justify-content: center;
  }

  &__description {
    flex-direction: column;
    display: flex;
    gap: 0.5rem;
    width: 11.25rem;
  }

  &__primary-info-without-amount {
    &--missing {
      color: var(--negative-1);
    }
  }

  &__asset-collection {
    font-size: 1rem;
    height: 1rem;
  }

  &__asset-image {
    object-fit: contain;
  }
}
</style>

<style>
.asset-base-info__asset-collection--missing {
  color: var(--negative-1);
}

</style>
