<template>
  <div
    class="header-proposal-or-loan">
    <header
      class="header-proposal-or-loan__container">
      <BaseBackButton
        class="header-proposal-or-loan__back-button"/>
      <h1 class="header-proposal-or-loan__title">
        {{ props.title }}
      </h1>
      <LoanStatusInfo
        v-if="loanStatus !== null && loanStatus !== undefined"
        :loan-status="loanStatus"
        :loan="loan"
        class="header-proposal-or-loan__status"/>
      <!--      <div-->
      <!--        v-if="loan?.status && [LoanStatus.Accepted, LoanStatus.AcceptedTransferred].includes((loan as BaseLoan).status)"-->
      <!--        class="header-proposal-or-loan__funded-date"/>-->
    </header>
  </div>
</template>

<script setup lang="ts">
import type LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import LoanStatusInfo from '@/general-components/LoanStatusInfo.vue'
import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import type { ProposalAndLoanTypes } from '@/modules/common/pwn/utils'
import type { LoanDetailSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'

interface Props {
    title: string
    loan?: BaseLoan | undefined | LoanDetailSchemaWorkaroundBackendSchema
    loanStatus?: LoanStatus
    type?: ProposalAndLoanTypes
}
const props = defineProps<Props>()

</script>

<style scoped>
.header-proposal-or-loan {
  position: sticky;
  top: var(--menu-height);
  z-index: var(--z-index-loan-detail-header);

  &__title {
    font-family: var(--font-family-screener);
    font-weight: 400;

    @media only screen and (--mobile-viewport) {
      font-size: 1.5rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: var(--background-color);
    padding: 0.2rem 0 2rem;

    @media only screen and (--mobile-viewport) {
      padding: 0.2rem 0 0.5rem;
    }
  }

  &__back-button {
    margin-right: 1rem;
    font-family: var(--font-family-screener);
    font-weight: 400;
  }

  &__status {
    margin: 0 0 0 var(--space-20);
  }
}
</style>
