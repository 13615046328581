import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { SupportedChain } from '@/constants/chains/types'
import { compareAddresses } from './utils'
import type { Address } from 'viem'
import { getAddress } from 'viem'

export const isTokenBundle = (chainId: SupportedChain, address: Address): boolean => {
  return compareAddresses(address, CHAINS_CONSTANTS[chainId]?.tokenBundlerContract)
}

export const isAtrToken = (chainId: SupportedChain, address: Address): boolean => {
  return compareAddresses(address, CHAINS_CONSTANTS[chainId]?.pwnSafeContracts?.assetTransferRights)
}

export const isLoanToken = (chainId: SupportedChain, address: Address): boolean => {
  return (
    compareAddresses(address, CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnLoan) ||
    compareAddresses(address, CHAINS_CONSTANTS[chainId]?.pwnV1Contracts?.pwnLoanToken) ||
    compareAddresses(address, CHAINS_CONSTANTS[chainId]?.pwnBetaContracts?.pwnLoan)
  )
}

export const isPwnAsset = (chainId: SupportedChain, address: Address): boolean => {
  return isTokenBundle(chainId, address) || isAtrToken(chainId, address) || isLoanToken(chainId, address)
}

export const REVOKED_OFFER_NONCE_CONTRACT_ADDRESS = getAddress('0xFFa73Eacce930BBd92a1Ef218400cBd1036c437e')

export const REVOKED_REQUEST_NONCE_CONTRACT_ADDRESS = getAddress('0x472361E75d28597b0a7F86146fbB4a86f173d10D')
