<template>
  <slot
    name="trigger"/>
  <teleport to="body">
    <aside
      v-if="isOpenLocal"
      class="modal-image"
      @click="close">
      <section class="modal-image__body">
        <div class="modal-image__header">
          <svg
            class="modal-image__close"
            width="40"
            height="40"
            viewBox="0 0 25 25"
            @click.stop="close">
            <line
              x1="2.06066"
              y1="1.93934"
              x2="23.2739"
              y2="23.1525"
              stroke="currentColor"
              stroke-width="2"
              @click.stop="close"/>
            <line
              x1="1.93934"
              y1="23.1542"
              x2="23.1525"
              y2="1.94098"
              stroke="currentColor"
              stroke-width="2"
              @click.stop="close"/>
          </svg>
        </div>
        <slot name="body"/>
      </section>
    </aside>
  </teleport>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted } from 'vue'

const props = defineProps<{
  isOpen: boolean;
}>()

const emit = defineEmits<{(e: 'update:isOpen', isOpen: boolean)}>()

const isOpenLocal = computed({
  get: (): boolean => props.isOpen,
  set: (value: boolean): void => emit('update:isOpen', value),
})

const isClickedOutside = (event): boolean => event.target !== event.currentTarget

const close = (event: MouseEvent | KeyboardEvent): void => {
  if (event instanceof MouseEvent && isClickedOutside(event)) return
  if (event instanceof KeyboardEvent && event.key !== 'Escape') return
  isOpenLocal.value = false
}

onMounted(() => {
  document.addEventListener('keydown', close)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', close)
})

</script>

<style scoped>
.modal-image {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-modal);

  display: grid;
  place-items: center;

  background: rgb(var(--background-color-base) / 75%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 3rem;
    color: var(--text-color);

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
