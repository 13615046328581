<template>
  <div
    class="achievements-page">
    <Claim
      v-if="address"
      :address="address"
      :parameter-name="`SetupPUSHNotifications`"
      :eligible="eligible"
      share-message="Hey, I just claimed my reputation on @pwndao for subscribing to @pushprotocol notifications!"
      class="achievements-page__background">
      <template #not-eligible>
        <h2 class=" achievements-page__heading">
          Let's set-up your PUSH Notifications for PWN.
        </h2>
        <BaseButton
          button-text="Subscribe"
          :size="ButtonSize.XXL"
          @on-button-click="handleSubscribe"/>
      </template>
      <template #already-claimed>
        <h2 class=" achievements-page__heading">
          You already have reputation for subscribing to PWN PUSH notifications.
        </h2>
        <GetMoreRep/>
        <LearnMore/>
      </template>
    </Claim>
    <Connect
      v-if="!address"
      :heading="`Attention PUSH users!`"
      class="achievements-page__background"/>
  </div>
</template>

<script lang="ts" setup>
import { useAccount } from '@wagmi/vue'
import Claim from '@/revamp/pages/claim-rep/Claim.vue'
import Connect from '@/revamp/pages/claim-rep/Connect.vue'
import BaseButton, { ButtonSize } from '@/general-components/BaseButton.vue'
import usePushNotificationModal from '@/modules/pages/notification-center/settings/modals/usePushNotificationModal'
import LearnMore from '@/revamp/pages/claim-rep/LearnMore.vue'
import GetMoreRep from '@/revamp/pages/claim-rep/GetMoreRep.vue'
import { asyncComputed } from '@vueuse/core'

const { address } = useAccount()

const { isSubscribedToPush, handleSubscribe, fetchIsSubscribedToPush } = usePushNotificationModal()

const eligible = asyncComputed(async () => {
  if (!address) return false
  await fetchIsSubscribedToPush()
  return isSubscribedToPush.value
})
</script>

<style scoped>
.achievements-page {
  margin-top: 1rem;

  &__background {
    background-color: #171717;
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    padding: 2rem;
  }
}
</style>
