<template>
  <div
    class="unpacking-animation">
    <img
      v-for="bundleAsset in bundleAssets"
      :key="bundleAsset.id"
      ref="bundleAssetsRefs"
      alt="unpacking bundle"
      class="unpacking-animation__asset"
      :src="bundleAsset.image">
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, toRefs, ref } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { sleep } from '@/modules/common/utils'

const props = defineProps<{
  bundleAssets: AssetWithAmount[]
}>()

const animationInterval = ref()
const { bundleAssets } = toRefs(props)
const bundleAssetsRefs = ref<HTMLImageElement[]>()

// in milliseconds
const ANIMATION_TIME = 700
const TIME_BETWEEN_INTERVALS = 500

const directions = ['right', 'right-top', 'top', 'left-top', 'left', 'left-down', 'down', 'right-down']

onMounted(async () => {
  const runAnimation = async () => {
    if (bundleAssetsRefs.value) {
      for (const [index, asset] of bundleAssetsRefs.value.entries()) {
        const randomWay = Math.floor(Math.random() * directions.length)
        const activeDirection = directions[randomWay]
        if (index > 0) await sleep(ANIMATION_TIME)
        asset.style.animation = `unpacking-${activeDirection} 0.5s ease-in-out 0s`
      }
    }
  }

  runAnimation()

  animationInterval.value = setInterval(
    () => { runAnimation() },
    bundleAssets.value.length * ANIMATION_TIME + TIME_BETWEEN_INTERVALS,
  )
})

onUnmounted(() => {
  clearInterval(animationInterval.value)
})

</script>

<style>
.unpacking-animation {
  --stretch-move: 7%;
  --stretch-move-minus: -7%;
  --move-forward: 150%;
  --move-forward-minus: -150%;
  position: relative;

  &__asset {
    opacity: 0;
    position: absolute;
    width: 5.6rem;
    height: 5.6rem;
    object-fit: contain;
    top: -8.7rem;
    left: -2.7rem;
  }

  @keyframes unpacking-right {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
      transform: translate(var(--stretch-move), 0%);
    }

    50% {
      opacity: 1;
      transform: translate(0%, 0%);
    }

    65% {
      opacity: 1;
    }

    100% {
      transform: translate(var(--move-forward), 0%);
      opacity: 0;
    }
  }

  @keyframes unpacking-right-top {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
      transform: translate(var(--stretch-move-minus), var(--stretch-move));
    }

    50% {
      opacity: 1;
      transform: translate(0%, 0%);
    }

    65% {
      opacity: 1;
    }

    100% {
      transform: translate(var(--move-forward), var(--move-forward-minus));
      opacity: 0;
    }
  }

  @keyframes unpacking-top {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
      transform: translate(0%, var(--stretch-move));
    }

    50% {
      opacity: 1;
      transform: translate(0%, 0%);
    }

    65% {
      opacity: 1;
    }

    100% {
      transform: translate(0%, var(--move-forward-minus));
      opacity: 0;
    }
  }

  @keyframes unpacking-left-top {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
      transform: translate(var(--stretch-move), var(--stretch-move));
    }

    50% {
      opacity: 1;
      transform: translate(0%, 0%);
    }

    65% {
      opacity: 1;
    }

    100% {
      transform: translate(var(--move-forward-minus), var(--move-forward-minus));
      opacity: 0;
    }
  }

  @keyframes unpacking-left {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
      transform: translate(var(--stretch-move), 0%);
    }

    50% {
      opacity: 1;
      transform: translate(0%, 0%);
    }

    65% {
      opacity: 1;
    }

    100% {
      transform: translate(var(--move-forward-minus), 0%);
      opacity: 0;
    }
  }

  @keyframes unpacking-left-down {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
      transform: translate(var(--stretch-move), var(--stretch-move-minus));
    }

    50% {
      opacity: 1;
      transform: translate(0%, 0%);
    }

    65% {
      opacity: 1;
    }

    100% {
      transform: translate(var(--move-forward-minus), var(--move-forward));
      opacity: 0;
    }
  }

  @keyframes unpacking-down {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
      transform: translate(0%, var(--stretch-move-minus));
    }

    50% {
      opacity: 1;
      transform: translate(0%, 0%);
    }

    65% {
      opacity: 1;
    }

    100% {
      transform: translate(0%, var(--move-forward));
      opacity: 0;
    }
  }

  @keyframes unpacking-right-down {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
      transform: translate(var(--stretch-move-minus), var(--stretch-move-minus));
    }

    50% {
      opacity: 1;
      transform: translate(0%, 0%);
    }

    65% {
      opacity: 1;
    }

    100% {
      transform: translate(var(--move-forward), var(--move-forward));
      opacity: 0;
    }
  }
}

</style>
