import { computed } from 'vue'
import type { Ref } from 'vue'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import type { Address } from 'viem'
import { useUserNfts } from '@/revamp/hooks/useUserNfts'
import { useUserTokens } from '@/revamp/hooks/useUserTokens'

export const useCurrentWalletAssetsDetail = (userAddress: Ref<Address>) => {
  const { nfts, isLoading: nftsAreLoading } = useUserNfts(userAddress)
  const { tokens, isLoading: tokensAreLoading } = useUserTokens(userAddress)

  const atrTokens = computed(() => {
    return nfts.value.filter((nft) => nft.isAtrToken)
  })

  const nonAtrTokens = computed(() => {
    return nfts.value.filter((nft) => !nft.isAtrToken)
  })

  // TODO this does not have to be computed
  const disabledChains = computed(() => {
    return enabledChains.filter((chain) => !CHAINS_CONSTANTS[chain].pwnSafeContracts)
  })

  return {
    selectedChainsTokens: computed(() => tokens.value),
    atrTokens,
    nonAtrTokens,
    disabledChains,
    tokensAreLoading,
    nftsAreLoading,
  }
}
