import type { inferQueryKeyStore } from '@lukemorales/query-key-factory'
import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import type { SupportedChain } from '@/constants/chains/types'
import type { MaybeRef } from 'vue'
import type { Address } from 'viem'
import { unref } from 'vue'
import to from '@/utils/await-to-js'
import type { FetchUserNftsMetadataSource } from '@/modules/common/backend/generated'
import { pwnsafeSafeRetrieve, pwnsafeUserSafesList, spacesList, web3authUserProfileStatsRetrieve } from '@/modules/common/backend/generated'
import { isAddress } from 'viem'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { fetchUserTokens } from '@/modules/common/assets/useUserAssets'
import useNFTFetch from '@/modules/common/assets/fetchers/useNFTFetch'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { compareAddresses } from '@/utils/utils'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import WalletStats from '@/modules/common/web3/typings/WalletStats'
import PwnSpaceConfig from '@/modules/common/pwnSpace/PwnSpaceConfig'

const fetchAllUserNfts = async (address: Address, chain: SupportedChain, { signal }: { signal: AbortSignal }) => {
  const useNftFetch = useNFTFetch()

  let next: string | undefined
  const assets: AssetWithAmount[] = []
  let metadataSource: FetchUserNftsMetadataSource | undefined

  do {
    const localResponse = await useNftFetch.fetchUserNFTsPage(
      {
        chainId: chain,
        userAddress: address,
        metadata_source: metadataSource,
        page: next,
      },
      {
        signal,
      },
    )

    next = localResponse?.next ?? undefined
    metadataSource = localResponse?.metadata_source as FetchUserNftsMetadataSource

    if (localResponse) {
      localResponse.results.map((asset) => assets.push(asset))
    }
  } while (next)

  return assets
}

export const queries = createQueryKeyStore({
  user: {
    walletTokens: (address: MaybeRef<Address>, chain: MaybeRef<SupportedChain>) => ({
      queryKey: [address, chain],
      queryFn: ({ signal }: { signal: AbortSignal }) => {
        return fetchUserTokens(unref(chain), unref(address), { signal })
      },
    }),
    walletNfts: (address: MaybeRef<Address>, chain: MaybeRef<SupportedChain>) => ({
      queryKey: [address, chain],
      queryFn: ({ signal }: { signal: AbortSignal }) => {
        return fetchAllUserNfts(unref(address), unref(chain), { signal })
      },
    }),
    stats: (address: MaybeRef<Address>) => ({
      queryKey: [address],
      queryFn: async (queryKey) => {
        const address = queryKey.queryKey[2]
        const [error, response] = await to(web3authUserProfileStatsRetrieve(address as Address))
        if (error) {
          throw error
        }
        return WalletStats.createFromBackendModel(response.data)
      },
    }),
  },
  tokenList: {
    all: (chain: MaybeRef<SupportedChain>) => ({
      queryKey: [chain],
    }),
  },
  safe: {
    detail: (address: MaybeRef<Address | undefined>, chain: MaybeRef<SupportedChain | undefined>) => {
      return {
        queryKey: [address, chain],
        enabled: unref(address) && isAddress(unref(address)!) && unref(chain),
        queryFn: async ({ queryKey }) => {
          const [, , safeAddress, chainId] = queryKey
          const [error, response] = await to(pwnsafeSafeRetrieve(String(chainId), String(safeAddress)))

          if (!error) {
            return PwnSafe.parseResponseFromBackendModel(response.data)
          } else {
            throw error
          }
        },

        contextQueries: {
          nfts: {
            queryKey: null,
            queryFn: async ({ queryKey, signal }) => {
              const [, , userAddress, chainId] = queryKey
              return await fetchAllUserNfts(userAddress as Address, chainId as SupportedChain, { signal })
            },
          },
          tokens: {
            // eslint-disable-next-line @tanstack/query/exhaustive-deps
            queryKey: null,
            queryFn: async ({ queryKey, signal }) => {
              const [error, response] = await to(fetchUserTokens(unref(chain)!, unref(address)!, { signal }))

              if (!error) {
                return response
              } else {
                throw error
              }
            },
          },
        },
      }
    },
    list(address: MaybeRef<Address>, chain: MaybeRef<SupportedChain>) {
      return {
        queryKey: [address, chain],
        select(data) {
          return data.filter((wallet) => !compareAddresses(wallet.safeAddress, usePwnSafeDetailStore().selectedPwnSafe?.safeAddress))
        },
        queryFn: async ({ queryKey }) => {
          const [, , userAddress, chainId] = queryKey

          const [error, response] = await to(pwnsafeUserSafesList(String(chainId), String(userAddress)))
          if (error) {
            throw error
          }

          return (response?.data?.results ?? []).map(pwnSafe => PwnSafe.parseResponseFromBackendModel(pwnSafe))
        },
      }
    },
  },
  spaces: {
    list: () => {
      return {
        queryKey: ['spaces'],
        queryFn: async () => {
          const [error, response] = await to(spacesList({ limit: 30, offset: 0 }))
          if (error) {
            throw error
          }

          const res: PwnSpaceConfig[] = (response?.data?.results ?? []).map(space => PwnSpaceConfig.createFromBackendModel(space))
          return res.filter(space => space.showInSpacePage)
        },
      }
    },
  },
  // proposal: {
  //   detail: (proposalId: string) => {
  //     return {
  //       queryKey: ['proposal', { id: proposalId }],
  //       queryFn: async () => {
  // TODO: remove mock and use real BE data ..
  // if (!proposalId) return
  // const { simpleProposalBEResponse } = await mockSimpleProposal(false)
  // const proposal = await ProposalFactory.createProposalFromBackendModel(simpleProposalBEResponse)
  // proposal.id = '1253'
  //
  // TODO how to properly fetch missing asset metadata?
  // const [collateral, creditAsset] = await Promise.all([
  //   useAddMissingAssetMetadata(proposal?.collateral),
  //   useAddMissingAssetMetadata(proposal?.creditAsset),
  // ])
  // proposal.collateral = collateral || proposal.collateral
  // proposal.creditAsset = creditAsset || proposal.creditAsset
  // return proposal
  //       },
  //     }
  //   },
  // },
})

export type QueryKeys = inferQueryKeyStore<typeof queries>;
