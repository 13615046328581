<template>
  <div class="collateral-detail-pre-bundle">
    <div class="collateral-detail-pre-bundle__header">
      <span class="collateral-detail__title">Collateral Info</span>
      <div class="collateral-detail__chain">
        <AssetChainIcon :chain-id="bundledAssets[0].chainId"/>
        {{ getChainName(bundledAssets[0].chainId) }}
      </div>
    </div>
    <div class="collateral-detail-pre-bundle__collateral">
      <component
        :is="BundleImageComponent"
        v-if="BundleImageComponent"
        class="collateral-detail-pre-bundle__image"/>
      <span class="collateral-detail-pre-bundle__collateral-name">Token Bundle</span>
    </div>
    <div class="collateral-detail-pre-bundle__stats">
      <!--
      <div
        class="collateral-detail-pre-bundle__row collateral-detail-pre-bundle__collateral-value">
        <div class="collateral-detail-pre-bundle__label">
          <span>Collateral Value</span>
        </div>
        <div class="collateral-detail-pre-bundle__value">
          <span> {{ bundleValue }} </span>
        </div>
      </div>
      -->
    </div>
    <div class="collateral-detail-pre-bundle__label">
      <span>Assets will be bundled upon proposal posting.</span>
    </div>
  </div>
</template>

<script setup lang="ts">

import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, toRefs } from 'vue'
import useProposalPreviewModal from '@/revamp/components/modals/proposal-preview-modal/useProposalPreviewModal'
import { getChainName } from '@/utils/chain'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'

interface Props {
  bundledAssets: AssetWithAmount[]
}
const props = defineProps<Props>()
const { bundledAssets } = toRefs(props)
const { createBatchImageBundle } = useProposalPreviewModal()

const BundleImageComponent = computed(() => {
  if (!bundledAssets.value) {
    return
  }
  return createBatchImageBundle(bundledAssets.value, true)
})

// todo: implement this
/*
const bundleValue = computed(() => {
  return '$' + 100
})
 */
</script>

<style scoped>
.collateral-detail-pre-bundle {
  display: flex;
  flex-flow: column;
  gap: 2rem;

  &__image {
    width: 7rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__chain {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }

  &__collateral {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &___collateral-name {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
  }

  &__stats {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
  }

  &__row {
    display: flex;
    padding: 0.5rem 0;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    font-size: 0.875rem;
    font-family: var(--font-family-supreme);
    color: var(--text-color-secondary);
  }

  &__value {
    font-size: 0.875rem;
    font-family: var(--font-family-supreme);
  }
}

</style>
