<template>
  <router-link
    v-if="isLink"
    :to="{ name: toLink }"
    class="pwn-logo">
    <PwnIconSvg :style="{ height }"/>
  </router-link>
  <div
    v-else
    class="pwn-logo">
    <PwnIconSvg :style="{ height }"/>
  </div>
</template>

<script setup lang="ts">
import type RouteName from '@/router/RouteName'
import { toRefs } from '@vueuse/core'
import PwnIconSvg from '@/assets/icons/pwn.svg'

interface Props {
  height: string
  isLink: boolean
  toLink: RouteName
}
const props = defineProps<Props>()
const { height, isLink, toLink } = toRefs(props)
</script>

<style>
.pwn-logo {
  margin-right: auto;
  transition: transform var(--primary-transition-time);

  &:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
  }
}
</style>
