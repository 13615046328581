<template>
  <Transition
    type="transition"
    name="analytics__animation">
    <div
      v-if="isPopUpDisplayed"
      class="analytics">
      <span class="analytics__text">
        Help us improve by allowing us to collect anonymous usage statistics.
      </span>

      <br>

      <span class="analytics__text">
        See our: <BaseLink
          class="analytics__text analytics__link"
          target="_blank"
          :to="linkTo.privacyPolicy">privacy policy</BaseLink>
      </span>

      <div class="analytics__buttons">
        <BaseButton
          is-full-width
          :color="ButtonColor.Gray"
          button-text="Reject"
          class="analytics__button"
          @on-button-click="handleRejectAnalytics"/>
        <BaseButton
          is-full-width
          button-text="Allow"
          class="analytics__button"
          @on-button-click="handleAcceptAnalytics"/>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>

import linkTo from '@/constants/links'
import BaseLink from '@/general-components/BaseLink.vue'
import BaseButton, { ButtonColor } from '@/general-components/BaseButton.vue'
import useAnalytics from '@/modules/common/analytics/useAnalytics'

const { handleRejectAnalytics, handleAcceptAnalytics, isPopUpDisplayed } = useAnalytics()

</script>

<style scoped>
.analytics {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  border: 1px solid var(--dark-separator-color, #828282);
  box-shadow: 0 4px 50px 0 #000;
  padding: 1rem;
  z-index: var(--z-index-app-analytics);

  max-width: 21rem;

  display: flex;
  flex-flow: column nowrap;
  background-color: var(--background-color);

  &__text {
    font-size: 0.75rem;
    font-family: var(--font-family-oxygen-mono);
  }

  &__link {
    color: var(--primary-color-1);
  }

  &__buttons {
    margin-top: 1rem;
    display: flex;
    flex-flow: row nowrap;
    column-gap: 0.5rem;
    font-size: 0.875rem;
  }

  &__button {
    width: 100%;
  }
}

.analytics__animation-enter-active,
.analytics__animation-leave-active {
  transition: opacity 0.3s ease-in;
}

.analytics__animation-enter-from,
.analytics__animation-leave-to {
  opacity: 0;
}
</style>
