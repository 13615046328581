import type { ColumnProperties } from '@/general-components/ColumnProperties'

export const TABLE_DEFINITION: ColumnProperties[] = [
  {
    title: 'Asset (a-z)',
    width: '50%',
    slotName: 'asset',
  },
  {
    title: 'Appraisal',
    width: '25%',
    slotName: 'appraisal',
  },
  {
    title: 'Amount',
    width: '25%',
    slotName: 'amount',
  },
]
