import type { Directive } from 'vue'

const vSelectText: Directive = {
  mounted(el: HTMLInputElement, binding, vnode) {
    el.addEventListener('focus', (event) => {
      if (el.type === 'text' || el.type === 'textarea') {
        el.setSelectionRange(0, el.value?.length ?? 0)
      }
    })
  },
}
export default vSelectText
