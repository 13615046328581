<template>
  <div
    v-if="isDisplayed"
    class="the-top-banner"
    @click.stop>
    <BaseLink
      :to="LINK"
      target="_blank"
      class="the-top-banner__text link--primary">
      Read cryptonative economy report
    </BaseLink>
    <CloseIconSvg
      class="the-top-banner__close-icon"
      alt="close"
      @click="handleCloseClick"/>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import BaseLink from '@/general-components/BaseLink.vue'
import linkTo from '@/constants/links'
import CloseIconSvg from '@/assets/icons/close-primary-color.svg'

// change this BANNER_ID & LINK when changing an active campaign, so the proper value from local storage is taken
const BANNER_ID = 'cryptonative_report'
const LINK = linkTo.cryptonativeReport

const LOCAL_STORAGE_WAS_BANNER_DISPLAYED_KEY = `was_top_banner_displayed--${BANNER_ID}`
// const isDisplayed = ref(!localStorage.getItem(LOCAL_STORAGE_WAS_BANNER_DISPLAYED_KEY))
const isDisplayed = ref(false)

const handleCloseClick = () => {
  isDisplayed.value = false
}

onMounted(() => {
  // display banner only once
  window.localStorage.setItem(LOCAL_STORAGE_WAS_BANNER_DISPLAYED_KEY, 'true')
})
</script>

<style scoped>
.the-top-banner {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 1rem;
  align-items: center;
  padding: 0.5rem 1.125rem;
  background-color: var(--primary-color-4);
  position: relative;
  z-index: var(--z-index-app-top-menu-banner);

  &__text {
    grid-column-start: 2;

    text-align: center;
  }

  &__close-icon {
    justify-self: end;

    @media only screen and (max-width: 50rem) {
      width: 2rem;
    }

    &:hover {
      cursor: pointer;

      transition: all var(--primary-transition-time);
      filter: brightness(60%);
    }
  }

  @media only screen and (--mobile-viewport) {
    display: none;
  }
}
</style>
