<template>
  <div class="asset-info__fake-asset-wrapper">
    <component
      :is="wrapperElement"
      :class="['asset-info__wrapper', {'asset-info__fake-asset': isAssetFakeComputed}]"
      v-bind="wrapperElementProps">
      <div class="asset-info__token-image-wrapper">
        <TokenMedia
          :token="asset"
          width="40"
          height="40"
          :is-lazy-loaded="isLazyLoaded"
          class="asset-info__logo"/>
        <AssetChainIcon
          class="asset-info__chain-icon"
          :chain-id="asset.chainId"/>
      </div>
      <div class="asset-info__description">
        <div
          v-if="showOnlyAssetPrimaryInfo"
          class="asset-info__primary-info">
          <SafeText
            :style="assetPrimaryInfoStyles"
            :text="asset.primaryInfoWithoutAmount"/>
          <DangerAssetIcon
            v-if="isAssetFakeComputed"
            tooltip-text="This asset is likely fake."/>
        </div>
        <div
          v-else-if="showAssetPrimaryInfoWithAmount"
          class="asset-info__primary-info">
          <AssetNameAndMaybeAmount
            :style="assetPrimaryInfoStyles"
            :asset="asset"/>
          <DangerAssetIcon
            v-if="isAssetFakeComputed"
            tooltip-text="This asset is likely fake."/>
        </div>
        <BaseTooltip
          v-else-if="isCryptoPunk"
          :border-color="TooltipBorderColor.Orange">
          <template #trigger>
            <div
              :style="assetPrimaryInfoStyles"
              class="asset-info__name--invalid">
              {{ asset.primaryInfoWithoutAmount }}
            </div>
          </template>
          <div>
            Unwrapped CryptoPunk detected
          </div>
        </BaseTooltip>
        <BaseTooltip
          v-else-if="!asset.isValid && !hideInvalidAssetTooltip"
          :border-color="TooltipBorderColor.Orange">
          <template #trigger>
            <div class="asset-info__invalid-asset">
              <div
                :style="assetPrimaryInfoStyles"
                class="asset-info__name--invalid">
                {{ asset.primaryInfoWithoutAmount }}
              </div>
              <span class="asset-info__warning-icon"/>
            </div>
          </template>
          <div>
            Invalid or unsupported asset
          </div>
        </BaseTooltip>
        <div class="asset-info__secondary-info-wrapper">
          <SafeText
            :style="assetSecondaryInfoStyles"
            :text="asset.secondaryInfo"/>
          <VerificationBadge
            v-if="showVerificationBadge"
            :token="asset"
            is-generic-verification
            :display-text="false"
            icon-width="0.9rem"
            :class="['asset-info__verification-badge', `asset-info__verification-badge--${badgeSize}`]"/>
        </div>
      </div>
      <UnpackBundleTooltip
        v-if="showUnpackTooltip"
        class="asset-info__unpack-bundle-tooltip"
        :asset="asset"/>
      <RewardsTooltip
        v-if="props.displayRewardsTooltip"
        class="asset-info__points-icon"
        :assets="[assetForRewards]"
        :credit-address="[asset.address]"
        :chain-id="asset.chainId"/>
    </component>
  </div>
</template>

<script setup lang="ts">
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import BaseTooltip, { TooltipBorderColor } from '@/general-components/BaseTooltip.vue'
import SafeText from '@/general-components/SafeText.vue'
import AssetType from '@/modules/common/assets/AssetType'
import { computed, toRefs } from 'vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import UnpackBundleTooltip from '@/general-components/UnpackBundleTooltip.vue'
import useAssetPage from '@/modules/pages/asset/nft-page/useAssetPage'
import AssetNameAndMaybeAmount from '@/modules/pages/pwn-safe/AssetNameAndMaybeAmount.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import VerificationBadge from '@/general-components/VerificationBadge.vue'
import AssetChainIcon from '@/general-components/AssetChainIcon.vue'
import DangerAssetIcon from '@/general-components/DangerAssetIcon.vue'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import RewardsTooltip from './promo/RewardsTooltip.vue'
import type { EligibleRewardsInput } from './promo/rewardsRegistry'

interface Props {
  asset: AssetWithAmount
  showLink: boolean
  showAmount?: boolean
  showUnpackTooltip?: boolean
  isLazyLoaded?: boolean
  showVerificationBadge?: boolean
  badgeSize?: 'small' | 'normal'
  hideInvalidAssetTooltip?: boolean;
  displayRewardsTooltip?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  showAmount: false,
  showUnpackTooltip: true,
  isLazyLoaded: true,
  showVerificationBadge: false,
  badgeSize: 'normal',
  hideInvalidAssetTooltip: false,
  displayRewardsTooltip: false,
})
const { asset, showLink, showAmount, showUnpackTooltip, hideInvalidAssetTooltip } = toRefs(props)

const { getAssetPageRoute } = useAssetPage()
const isAssetFakeComputed = computed(() => useTopTokensStore().isAssetFake(asset.value))

const wrapperElement = computed(() =>
  (showLink.value && !asset.value.isNativeToken)
    ? BaseLink
    : 'div',
)

const wrapperElementProps = computed(() => {
  if (!showLink.value) {
    return {}
  }

  return {
    to: getAssetPageRoute(asset.value),
    isDarkeningEffect: !isAssetFakeComputed.value,
    isUnderlined: true,
  }
})

const isCryptoPunk = computed(() => asset.value.category === AssetType.CRYPTOPUNKS)
const showOnlyAssetPrimaryInfo = computed(() => !isCryptoPunk.value && (asset.value.isValid || hideInvalidAssetTooltip) && !showAmount.value)
const showAssetPrimaryInfoWithAmount = computed(() => !isCryptoPunk.value && (asset.value.isValid || hideInvalidAssetTooltip) && showAmount.value)

const assetPrimaryInfoStyles = computed(() => ({
  display: 'block',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: asset.value.isPrimaryInfoMissing ? 'var(--negative-1)' : 'inherit',
}))

const assetSecondaryInfoStyles = computed(() => ({
  display: 'block',
  fontSize: '0.75rem',
  lineHeight: '1.25rem',
  color: asset.value.isSecondaryInfoMissing ? 'var(--negative-1)' : 'inherit',
  width: 'auto',
  maxWidth: '10rem',
}))

const assetForRewards = computed<EligibleRewardsInput>(() => ({
  chainId: asset.value.chainId,
  address: asset.value.address,
}))
</script>

<style scoped>
.asset-info {
  &__wrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    font-family: var(--font-family-oxygen-mono);
    text-decoration: none;
  }

  &__fake-asset-wrapper {
    position: relative;
  }

  &__fake-asset {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--fake-asset-overlay);
      z-index: var(--z-index-fake-asset-overlay);
    }
  }

  &__primary-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__chain-icon {
    position: absolute;
    bottom: 0;
    right: 0.5rem;
    width: 1rem;
    height: 1rem;

    &-toltip {
      width: 2rem;
      height: 2rem;
      padding: 0.2rem;
    }
  }

  &__points-icon {
    margin-left: auto;
  }

  &__token-image-wrapper {
    position: relative;
  }

  &__logo {
    min-width: 2.5rem;
    object-fit: contain;
    margin-right: 0.75rem;
  }

  &__description {
    min-width: 0;
  }

  &__invalid-asset {
    cursor: help;
    display: flex;
    align-items: center;
    height: 1.5rem;
  }

  &__warning-icon {
    &::after {
      content: "\24d8";
      color: var(--negative-1);
      font-size: 0.875rem;
      margin: 0 0.5rem;
    }
  }

  &__name {
    &--invalid {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__unpack-bundle-tooltip {
    margin-left: 1rem;
  }

  &__secondary-info-wrapper {
    display: inline-flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;
    position: relative;
  }

  &__verification-badge {
    height: 1rem;
    width: 1rem;
  }
}
</style>

<style>
.asset-info__verification-badge--small .verification-badge__question-mark-icon {
  width: 1rem;
  height: 1rem;
}
</style>
