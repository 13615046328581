/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ATRTokenMetadataBackendSchema,
  PaginatedAtrTokenOfSafeListSchemaWorkaroundListBackendSchema,
  PaginatedSafeListBackendSchema,
  PwnsafeAtrTokensOfSafeListParams,
  PwnsafeUserSafesListParams,
  SafeBackendSchema,
  SafeOffchainDataBackendSchema,
  SafeOffchainDataRequestBackendSchema,
  UpdateSafeNameRequestBackendSchema
} from '../schemas'
import { customInstance } from '../customAxiosInstance';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const pwnsafeAtrTokensOfSafeList = (
    chainId: MaybeRef<string>,
    walletAddress: MaybeRef<string>,
    params?: MaybeRef<PwnsafeAtrTokensOfSafeListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
walletAddress = unref(walletAddress);
params = unref(params);
      
      return customInstance<PaginatedAtrTokenOfSafeListSchemaWorkaroundListBackendSchema>(
      {url: `/api/v1/pwnsafe/atr-tokens-of-safe/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(walletAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnsafeAtrTokensOfSafeListQueryKey = (chainId: MaybeRef<string>,
    walletAddress: MaybeRef<string>,
    params?: MaybeRef<PwnsafeAtrTokensOfSafeListParams>,) => {
    return ['api','v1','pwnsafe','atr-tokens-of-safe',chainId,walletAddress, ...(params ? [params]: [])] as const;
    }

    
export const getPwnsafeAtrTokensOfSafeListQueryOptions = <TData = Awaited<ReturnType<typeof pwnsafeAtrTokensOfSafeList>>, TError = unknown>(chainId: MaybeRef<string>,
    walletAddress: MaybeRef<string>,
    params?: MaybeRef<PwnsafeAtrTokensOfSafeListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnsafeAtrTokensOfSafeList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnsafeAtrTokensOfSafeListQueryKey(chainId,walletAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnsafeAtrTokensOfSafeList>>> = ({ signal }) => pwnsafeAtrTokensOfSafeList(chainId,walletAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(walletAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnsafeAtrTokensOfSafeList>>, TError, TData> 
}

export type PwnsafeAtrTokensOfSafeListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnsafeAtrTokensOfSafeList>>>
export type PwnsafeAtrTokensOfSafeListQueryError = unknown

export const usePwnsafeAtrTokensOfSafeList = <TData = Awaited<ReturnType<typeof pwnsafeAtrTokensOfSafeList>>, TError = unknown>(
 chainId: MaybeRef<string>,
    walletAddress: MaybeRef<string>,
    params?: MaybeRef<PwnsafeAtrTokensOfSafeListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnsafeAtrTokensOfSafeList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnsafeAtrTokensOfSafeListQueryOptions(chainId,walletAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const pwnsafeAtrtokenMetadataRetrieve = (
    chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    atrTokenHexId: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
contractAddress = unref(contractAddress);
atrTokenHexId = unref(atrTokenHexId);
      
      return customInstance<ATRTokenMetadataBackendSchema>(
      {url: `/api/v1/pwnsafe/atrtoken/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(contractAddress))}/${encodeURIComponent(String(atrTokenHexId))}/metadata`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnsafeAtrtokenMetadataRetrieveQueryKey = (chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    atrTokenHexId: MaybeRef<string>,) => {
    return ['api','v1','pwnsafe','atrtoken',chainId,contractAddress,atrTokenHexId,'metadata'] as const;
    }

    
export const getPwnsafeAtrtokenMetadataRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnsafeAtrtokenMetadataRetrieve>>, TError = unknown>(chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    atrTokenHexId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnsafeAtrtokenMetadataRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnsafeAtrtokenMetadataRetrieveQueryKey(chainId,contractAddress,atrTokenHexId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnsafeAtrtokenMetadataRetrieve>>> = ({ signal }) => pwnsafeAtrtokenMetadataRetrieve(chainId,contractAddress,atrTokenHexId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(contractAddress) && unref(atrTokenHexId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnsafeAtrtokenMetadataRetrieve>>, TError, TData> 
}

export type PwnsafeAtrtokenMetadataRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnsafeAtrtokenMetadataRetrieve>>>
export type PwnsafeAtrtokenMetadataRetrieveQueryError = unknown

export const usePwnsafeAtrtokenMetadataRetrieve = <TData = Awaited<ReturnType<typeof pwnsafeAtrtokenMetadataRetrieve>>, TError = unknown>(
 chainId: MaybeRef<string>,
    contractAddress: MaybeRef<string>,
    atrTokenHexId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnsafeAtrtokenMetadataRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnsafeAtrtokenMetadataRetrieveQueryOptions(chainId,contractAddress,atrTokenHexId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const pwnsafeSafeRetrieve = (
    chainId: MaybeRef<string>,
    safeAddress: MaybeRef<string>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
safeAddress = unref(safeAddress);
      
      return customInstance<SafeBackendSchema>(
      {url: `/api/v1/pwnsafe/safe/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(safeAddress))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getPwnsafeSafeRetrieveQueryKey = (chainId: MaybeRef<string>,
    safeAddress: MaybeRef<string>,) => {
    return ['api','v1','pwnsafe','safe',chainId,safeAddress] as const;
    }

    
export const getPwnsafeSafeRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof pwnsafeSafeRetrieve>>, TError = unknown>(chainId: MaybeRef<string>,
    safeAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnsafeSafeRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnsafeSafeRetrieveQueryKey(chainId,safeAddress);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnsafeSafeRetrieve>>> = ({ signal }) => pwnsafeSafeRetrieve(chainId,safeAddress, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(safeAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnsafeSafeRetrieve>>, TError, TData> 
}

export type PwnsafeSafeRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pwnsafeSafeRetrieve>>>
export type PwnsafeSafeRetrieveQueryError = unknown

export const usePwnsafeSafeRetrieve = <TData = Awaited<ReturnType<typeof pwnsafeSafeRetrieve>>, TError = unknown>(
 chainId: MaybeRef<string>,
    safeAddress: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnsafeSafeRetrieve>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnsafeSafeRetrieveQueryOptions(chainId,safeAddress,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const pwnsafeSafeUpdate = (
    chainId: MaybeRef<string>,
    safeAddress: MaybeRef<string>,
    updateSafeNameRequestBackendSchema: MaybeRef<UpdateSafeNameRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      chainId = unref(chainId);
safeAddress = unref(safeAddress);
updateSafeNameRequestBackendSchema = unref(updateSafeNameRequestBackendSchema);
      
      return customInstance<SafeBackendSchema>(
      {url: `/api/v1/pwnsafe/safe/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(safeAddress))}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateSafeNameRequestBackendSchema
    },
      options);
    }
  


export const getPwnsafeSafeUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pwnsafeSafeUpdate>>, TError,{chainId: string;safeAddress: string;data: UpdateSafeNameRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof pwnsafeSafeUpdate>>, TError,{chainId: string;safeAddress: string;data: UpdateSafeNameRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pwnsafeSafeUpdate>>, {chainId: string;safeAddress: string;data: UpdateSafeNameRequestBackendSchema}> = (props) => {
          const {chainId,safeAddress,data} = props ?? {};

          return  pwnsafeSafeUpdate(chainId,safeAddress,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PwnsafeSafeUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof pwnsafeSafeUpdate>>>
    export type PwnsafeSafeUpdateMutationBody = UpdateSafeNameRequestBackendSchema
    export type PwnsafeSafeUpdateMutationError = unknown

    export const usePwnsafeSafeUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pwnsafeSafeUpdate>>, TError,{chainId: string;safeAddress: string;data: UpdateSafeNameRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof pwnsafeSafeUpdate>>,
        TError,
        {chainId: string;safeAddress: string;data: UpdateSafeNameRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getPwnsafeSafeUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const pwnsafeSafeOffChainDataCreate = (
    chainId: MaybeRef<string>,
    safeOffchainDataRequestBackendSchema: MaybeRef<SafeOffchainDataRequestBackendSchema>,
 options?: SecondParameter<typeof customInstance>,) => {
      chainId = unref(chainId);
safeOffchainDataRequestBackendSchema = unref(safeOffchainDataRequestBackendSchema);
      
      return customInstance<SafeOffchainDataBackendSchema>(
      {url: `/api/v1/pwnsafe/safe/${encodeURIComponent(String(chainId))}/off_chain_data/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: safeOffchainDataRequestBackendSchema
    },
      options);
    }
  


export const getPwnsafeSafeOffChainDataCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pwnsafeSafeOffChainDataCreate>>, TError,{chainId: string;data: SafeOffchainDataRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof pwnsafeSafeOffChainDataCreate>>, TError,{chainId: string;data: SafeOffchainDataRequestBackendSchema}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pwnsafeSafeOffChainDataCreate>>, {chainId: string;data: SafeOffchainDataRequestBackendSchema}> = (props) => {
          const {chainId,data} = props ?? {};

          return  pwnsafeSafeOffChainDataCreate(chainId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PwnsafeSafeOffChainDataCreateMutationResult = NonNullable<Awaited<ReturnType<typeof pwnsafeSafeOffChainDataCreate>>>
    export type PwnsafeSafeOffChainDataCreateMutationBody = SafeOffchainDataRequestBackendSchema
    export type PwnsafeSafeOffChainDataCreateMutationError = unknown

    export const usePwnsafeSafeOffChainDataCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pwnsafeSafeOffChainDataCreate>>, TError,{chainId: string;data: SafeOffchainDataRequestBackendSchema}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof pwnsafeSafeOffChainDataCreate>>,
        TError,
        {chainId: string;data: SafeOffchainDataRequestBackendSchema},
        TContext
      > => {

      const mutationOptions = getPwnsafeSafeOffChainDataCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const pwnsafeUserSafesList = (
    chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<PwnsafeUserSafesListParams>,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      chainId = unref(chainId);
userAddress = unref(userAddress);
params = unref(params);
      
      return customInstance<PaginatedSafeListBackendSchema>(
      {url: `/api/v1/pwnsafe/user-safes/${encodeURIComponent(String(chainId))}/${encodeURIComponent(String(userAddress))}/`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getPwnsafeUserSafesListQueryKey = (chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<PwnsafeUserSafesListParams>,) => {
    return ['api','v1','pwnsafe','user-safes',chainId,userAddress, ...(params ? [params]: [])] as const;
    }

    
export const getPwnsafeUserSafesListQueryOptions = <TData = Awaited<ReturnType<typeof pwnsafeUserSafesList>>, TError = unknown>(chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<PwnsafeUserSafesListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnsafeUserSafesList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getPwnsafeUserSafesListQueryKey(chainId,userAddress,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pwnsafeUserSafesList>>> = ({ signal }) => pwnsafeUserSafesList(chainId,userAddress,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(chainId) && unref(userAddress))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pwnsafeUserSafesList>>, TError, TData> 
}

export type PwnsafeUserSafesListQueryResult = NonNullable<Awaited<ReturnType<typeof pwnsafeUserSafesList>>>
export type PwnsafeUserSafesListQueryError = unknown

export const usePwnsafeUserSafesList = <TData = Awaited<ReturnType<typeof pwnsafeUserSafesList>>, TError = unknown>(
 chainId: MaybeRef<string>,
    userAddress: MaybeRef<string>,
    params?: MaybeRef<PwnsafeUserSafesListParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pwnsafeUserSafesList>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPwnsafeUserSafesListQueryOptions(chainId,userAddress,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



