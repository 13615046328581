<template>
  <div class="the-wallet-notifications-settings">
    <header class="the-wallet-notifications-settings__header">
      <BaseBackButton
        has-emit
        class="the-wallet-notifications-settings__back-button"
        @go-back="emit('close-notifications-setting')"/>
      <h1 class="the-wallet-notifications-settings__tab-title">
        Notifications Settings
      </h1>
      <div
        class="the-wallet-notifications-settings__default-settings-container"
        @click="setDefaultSettings">
        <span class="the-wallet-notifications-settings__default-settings-text">Revert</span>
        <RevertSvg
          height="14"
          class="the-wallet-notifications-settings__revert-icon"
          alt="revert settings"/>
      </div>
    </header>
    <div class="the-wallet-notifications-settings__options-section">
      <label class="the-wallet-notifications-settings__options-label">Notification Setup</label>
      <div class="the-wallet-notifications-settings__setup-items">
        <DiscordNotificationModal
          button-width="100%"/>
        <PushNotificationModal
          :has-earn-rewards-badge="false"
          button-width="100%"/>
        <EmailNotificationModal
          button-width="100%"/>
      </div>
    </div>
    <div class="the-wallet-notifications-settings__options-section the-wallet-notifications-settings__options-section--general">
      <label class="the-wallet-notifications-settings__options-label the-wallet-notifications-settings__options-label--general">General</label>
      <NotificationCenterSettingsOption
        class="the-wallet-notifications-settings__option"
        text="New proposals on PWN"
        is-small
        tooltip-text="Alerts new loan requests posted &#10; on the PWN Platform"
        :is-recommended="false"
        :toggle-value="userNotificationSettings?.newLoanRequest"
        @toggle="toggleNewLoanRequests">
        <template #modal>
          <NewLoanRequestsModal/>
        </template>
      </NotificationCenterSettingsOption>
      <NotificationCenterSettingsOption
        class="the-wallet-notifications-settings__option"
        text="Follow address"
        is-small
        tooltip-text="Alerts for activity of different &#10; addresses on the PWN Platform"
        :is-recommended="false"
        :toggle-value="userNotificationSettings?.followUsers"
        @toggle="toggleFollowUsers">
        <template #modal>
          <FollowAddressesModal/>
        </template>
      </NotificationCenterSettingsOption>
    </div>

    <div class="the-wallet-notifications-settings__options-section the-wallet-notifications-settings__options-section--general">
      <label class="the-wallet-notifications-settings__options-label the-wallet-notifications-settings__options-label--general">Your Proposals and Loans</label>
      <NotificationCenterSettingsOption
        class="the-wallet-notifications-settings__option"
        text="Loan default warning"
        tooltip-text="The amount of days you will get an alert before &#10; your final repayment date on a loan"
        is-small
        :is-recommended="true"
        :toggle-value="userNotificationSettings?.loanDefaultWarning"
        @toggle="toggleLoanDefaultWarning">
        <template #modal>
          <LoanDefaultWarningModal/>
        </template>
      </NotificationCenterSettingsOption>
    </div>
  </div>
</template>

<script setup lang="ts">

import BaseBackButton from '@/general-components/BaseBackButton.vue'
import PushNotificationModal from '@/modules/pages/notification-center/settings/modals/PushNotificationModal.vue'
import EmailNotificationModal from '@/modules/pages/notification-center/settings/modals/EmailNotificationModal.vue'
import DiscordNotificationModal from '@/modules/pages/notification-center/settings/modals/DiscordNotificationModal.vue'
import NotificationCenterSettingsOption
  from '@/modules/pages/notification-center/settings/NotificationCenterSettingsOption.vue'
import NewLoanRequestsModal from '@/modules/pages/notification-center/settings/modals/NewLoanRequestsModal.vue'
import FollowAddressesModal from '@/modules/pages/notification-center/settings/modals/FollowAddressesModal.vue'
import useNotificationCenterSettings from '@/modules/pages/notification-center/settings/useNotificationCenterSettings'
import LoanDefaultWarningModal from '@/modules/pages/notification-center/settings/modals/LoanDefaultWarningModal.vue'
import RevertSvg from '@/assets/icons/revert.svg'

const { userNotificationSettings, toggleNewLoanRequests, toggleFollowUsers, toggleLoanDefaultWarning, setDefaultSettings } = useNotificationCenterSettings()
const emit = defineEmits<{(e: 'close-notifications-setting'): void }>()

</script>

<style scoped>
.the-wallet-notifications-settings {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
  margin-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -1rem;
  padding-right: 1rem;

  &__header {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray);
    width: 100%;
    flex-wrap: wrap;
  }

  &__default-settings-container {
    display: flex;
    font-family: var(--font-family-supreme);
    width: max-content;
    margin-left: auto;
    align-items: center;
    gap: 0.2rem;
    color: var(--gray);

    &:hover {
      cursor: pointer;

      --gray: var(--text-color);
    }
  }

  &__revert-icon {
    fill: var(--gray);
  }

  &__default-settings-text {
    font-size: 0.75rem;
    font-family: var(--font-family-screener);
  }

  &__back-button {
    scale: 0.9;
    padding: 0;
  }

  &__tab-title {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    font-family: var(--font-family-screener);
  }

  &__options-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 0;
    border-bottom: 1px solid var(--gray);
    width: 100%;

    &--general {
      gap: 0;
    }
  }

  &__options-label {
    font-weight: 400;
    margin: 0;
    font-family: var(--font-family-screener);
    font-size: 0.875rem;

    &--general {
      margin: 0 0 0.5rem;
    }
  }

  &__setup-items {
    display: flex;
    gap: 0.5rem;
    width: 100%;

    @media only screen and (--mobile-viewport) {
      flex-wrap: wrap;
    }

    --primary-color-1: var(--text-color);
    --primary-color-3: var(--gray);
  }

  &__option {
    margin-top: 1rem;
    min-width: unset;
    font-size: 0.875rem;
  }
}

</style>
