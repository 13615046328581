<template>
  <div class="connect-wallet">
    <BaseButton
      :variant="ButtonVariant.Outline"
      :button-text="['connecting', 'reconnecting'].includes(status) ? 'Connecting Wallet...' : 'Connect wallet'"
      @click="handleConnect"/>
  </div>
</template>

<script setup lang="ts">
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import BaseButton, { ButtonVariant } from '@/general-components/BaseButton.vue'
import { useCustomConnect } from '@/modules/common/web3/useCustomConnect'

const { status } = useCustomAccount()
const { handleConnect } = useCustomConnect()

</script>

<style scoped>
.connect-wallet {
  padding: 4rem;
  width: 100%;

  border: 1px solid var(--gray-2);

  display: flex;
  justify-content: center;
}
</style>
