import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import { ref } from 'vue'
import RouteName from '@/router/RouteName'
import { useCollectionPageStore } from '@/revamp/pages/collection/useCollectionPageStore'
import router from '@/router'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'

export const useCollectionToAssetNavigator = defineStore(StoreIds.collectionPageNavigateByTokenId, () => {
  const userInput = ref('')
  const isFocused = ref(false)
  const inputIsValid = ref(null)

  const setFocused = (v: boolean) => {
    isFocused.value = v
  }

  const setUserInput = (v: string) => {
    if (v !== '') {
      // only positive integers and no fractions allowed
      // @ts-expect-error TS(2322) FIXME: Type 'boolean' is not assignable to type 'null'.
      inputIsValid.value = !isNaN(Number(v)) && Number(v) >= 0 && Number(v) % 1 === 0 && !v.includes('.')
    } else {
      inputIsValid.value = null
    }
    userInput.value = v
  }

  const openAssetPage = () => {
    if (!inputIsValid.value) return
    const collectionStore = useCollectionPageStore()

    router.push({
      name: RouteName.NftPage,
      params: {
        contractAddress: collectionStore.collection.contractAddress,
        chainName: CHAINS_CONSTANTS[collectionStore.collection.chainId].general.chainName.toLowerCase(),
        tokenId: userInput.value,
      },

    })
  }

  return {
    userInput,
    isFocused,
    inputIsValid,
    actions: {
      setFocused,
      setUserInput,
      openAssetPage,
    },
  }
})
