/**
 * Parse the decimal repeated value
 * @param value — The value to parse 0.000000000005
 * @returns The parsed value
 */
export const parseRepeatedDecimal = (value: string): {
  repeatedZeroes: number
  restDecimals: string
} | null => {
  const [, decimalPart] = value.split('.')

  const decimalPartLength = decimalPart.length
  const firstNonZeroIndex = decimalPart.split('').findIndex(v => Number(v) > 0)

  if (decimalPartLength < 4 || firstNonZeroIndex === -1 || firstNonZeroIndex <= 3) {
    return null
  }

  return {
    repeatedZeroes: firstNonZeroIndex - 1,
    restDecimals: decimalPart.slice(firstNonZeroIndex, decimalPartLength),
  }
}
