import UserOfferFilterSettings from '@/modules/common/notifications/UserOfferFilterSettings'
import UserLoanRequestFilterSettings from '@/modules/common/notifications/UserLoanRequestFilterSettings'
import WalletProfile from '@/modules/common/assets/WalletProfile'
import type { NotificationSettingsBackendSchema, NotificationSettingsRequestBackendSchema } from '@/modules/common/backend/generated'

export default class UserNotificationSettings {
  public offersOnUserLoanRequestFilterSettings?: UserOfferFilterSettings
  public newLoanRequestFilterSettings?: UserLoanRequestFilterSettings[]
  public followedUsers?: WalletProfile[]
  public offersOnUserLoanRequest?: boolean
  public loanDefaultWarning?: boolean
  public firstAlertDaysBeforeLoanDefault?: number
  public secondAlertDaysBeforeLoanDefault?: number
  public offerAccepted?: boolean
  public offerNotSelected?: boolean
  public newLoanRequest?: boolean
  public followUsers?: boolean

  constructor(notificationSettings: UserNotificationSettings) {
    this.offersOnUserLoanRequestFilterSettings = notificationSettings.offersOnUserLoanRequestFilterSettings
    this.newLoanRequestFilterSettings = notificationSettings.newLoanRequestFilterSettings
    this.followedUsers = notificationSettings.followedUsers
    this.offersOnUserLoanRequest = notificationSettings.offersOnUserLoanRequest
    this.loanDefaultWarning = notificationSettings.loanDefaultWarning
    this.firstAlertDaysBeforeLoanDefault = notificationSettings.firstAlertDaysBeforeLoanDefault
    this.secondAlertDaysBeforeLoanDefault = notificationSettings.secondAlertDaysBeforeLoanDefault
    this.offerAccepted = notificationSettings.offerAccepted
    this.offerNotSelected = notificationSettings.offerNotSelected
    this.newLoanRequest = notificationSettings.newLoanRequest
    this.followUsers = notificationSettings.followUsers
  }

  public static createFromBackendModel(notificationSettings: NotificationSettingsBackendSchema): UserNotificationSettings {
    return new UserNotificationSettings({
      offersOnUserLoanRequestFilterSettings: UserOfferFilterSettings.createFromBackendModel(notificationSettings.offers_on_user_loan_request_filter_settings),
      newLoanRequestFilterSettings: notificationSettings.new_loan_request_filter_settings.map((filter) => UserLoanRequestFilterSettings.createFromBackendModel(filter)),
      followedUsers: notificationSettings.followed_users.map(user => WalletProfile.createFromBackendModel(user)),
      offersOnUserLoanRequest: notificationSettings.offers_on_user_loan_request,
      loanDefaultWarning: notificationSettings.loan_default_warning,
      firstAlertDaysBeforeLoanDefault: notificationSettings.first_alert_days_before_loan_default,
      secondAlertDaysBeforeLoanDefault: notificationSettings.second_alert_days_before_loan_default,
      offerAccepted: notificationSettings.offer_accepted,
      offerNotSelected: notificationSettings.offer_not_selected,
      newLoanRequest: notificationSettings.new_loan_request,
      followUsers: notificationSettings.follow_users,
    })
  }

  public static createRequestBody(notificationSettings: UserNotificationSettings): NotificationSettingsRequestBackendSchema {
    return {
      offers_on_user_loan_request_filter_settings: UserOfferFilterSettings.createRequestBody(notificationSettings.offersOnUserLoanRequestFilterSettings!),
      new_loan_request_filter_settings: (notificationSettings?.newLoanRequestFilterSettings ?? []).map((filter) => UserLoanRequestFilterSettings.createRequestBody(filter)),
      followed_users: (notificationSettings?.followedUsers ?? []).map(user => WalletProfile.createRequestBody(user)),
      offers_on_user_loan_request: notificationSettings.offersOnUserLoanRequest,
      loan_default_warning: notificationSettings.loanDefaultWarning,
      first_alert_days_before_loan_default: notificationSettings.firstAlertDaysBeforeLoanDefault,
      second_alert_days_before_loan_default: notificationSettings.secondAlertDaysBeforeLoanDefault,
      offer_accepted: notificationSettings.offerAccepted,
      offer_not_selected: notificationSettings.offerNotSelected,
      new_loan_request: notificationSettings.newLoanRequest,
      follow_users: notificationSettings.followUsers,
    }
  }
}
