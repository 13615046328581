<template>
  <div>
    <BaseSelect
      label="name"
      open-direction="top"
      :model-value="toWallet!"
      :options="wallets"
      @update:model-value="handleWalletSelectChange">
      <template #option="{ option }">
        <AddressInfoSelectOption :address-info="option"/>
      </template>

      <template #singleLabel>
        <AddressInfoSelectOption :address-info="toWallet!"/>
      </template>
    </BaseSelect>
  </div>
</template>

<script setup lang="ts">
import BaseSelect from '@/general-components/BaseSelect.vue'
import usePwnSafeTransfer from '@/modules/pages/pwn-safe/transfer/usePwnSafeTransfer'
import AddressInfoSelectOption from '@/general-components/AddressInfoSelectOption.vue'
import type { SupportedChain } from '@/constants/chains/types'
import { computed, toRefs } from 'vue'
import { queries } from '@/modules/queries'
import { useQuery } from '@tanstack/vue-query'
import { isAddress } from 'viem'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { storeToRefs } from 'pinia'

const { toWallet, handleWalletSelectChange } = usePwnSafeTransfer()
const { address: userAddress } = useCustomAccount()
const pwnSafeDetailStore = usePwnSafeDetailStore()
const { isSelectedCurrentWallet, walletAsSafe } = storeToRefs(pwnSafeDetailStore)

type Props = {
  selectedChain?: SupportedChain;
};

const props = defineProps<Props>()
const { selectedChain } = toRefs(props)

const availableWalletsToTransferQuery = useQuery({
  // @ts-expect-error FIXME: strictNullChecks
  ...queries.safe.list(userAddress, selectedChain),
  // @ts-expect-error FIXME: strictNullChecks
  enabled: isAddress(userAddress.value) && !!selectedChain.value,

})

const wallets = computed(() => {
  const shouldAppendCurrentWallet = !isSelectedCurrentWallet.value
  const results = availableWalletsToTransferQuery.data.value || []
  return [shouldAppendCurrentWallet ? walletAsSafe.value : null, ...results].filter(Boolean)
})
</script>
