import AssetType from '@/modules/common/assets/AssetType'
import type { SupportedChain } from '@/constants/chains/types'
import { erc20Abi, erc721Abi } from 'viem'
import type { Address } from 'viem'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import { erc1155Abi, usdtMainnetAbi } from '@/contracts/abis'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { compareAddresses, compareAssets } from '@/utils/utils'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

export default function useTransfer() {
  const transferERC20 = async (contractAddress: Address, receiver: Address, amount: bigint, chainId: SupportedChain, safeAddress: Address | undefined) => {
    const parsedAsset = new AssetWithAmount({
      address: contractAddress,
      chainId,
      category: AssetType.ERC20,
    })

    const assetIsUSDT = compareAssets({
      assetA: parsedAsset,
      assetB: AssetWithAmount.createMainnetUsdtAssetMetadata(),
    }) || compareAssets({
      assetA: parsedAsset,
      assetB: AssetWithAmount.createSepoliaUsdtAssetMetadata(),
    })

    const abi = !assetIsUSDT ? erc20Abi : usdtMainnetAbi

    return await sendTransaction({
      abi,
      functionName: 'transfer',
      address: contractAddress,
      chainId,
      args: [receiver, amount],
    }, { safeAddress })
  }

  const transferERC721 = async (contractAddress: Address, from: Address, to: Address, tokenId: bigint, chainId: SupportedChain, safeAddress: Address | undefined) => {
    return await sendTransaction({
      abi: erc721Abi,
      functionName: 'safeTransferFrom',
      address: contractAddress,
      chainId,
      args: [from, to, tokenId],
    },
    { safeAddress })
  }

  const transferERC1155 = async (contractAddress: Address, from: Address, to: Address, tokenId: bigint, tokenAmount: bigint, chainId: SupportedChain, safeAddress: Address | undefined) => {
    return await sendTransaction({
      abi: erc1155Abi,
      functionName: 'safeTransferFrom',
      address: contractAddress,
      args: [from, to, tokenId, tokenAmount, '0x'],
      chainId,
    }, { safeAddress })
  }

  interface TransferFunctionParams {
    assetERCType: AssetType
    contractAddress: Address
    owner: Address
    receiver: Address
    amount?: bigint
    tokenId?: bigint
    chainId: SupportedChain
  }
  const transfer = async ({ assetERCType, contractAddress, chainId, owner, receiver, amount, tokenId }: TransferFunctionParams) => {
    const { address: connectedUserAddress } = getAccount(pwnWagmiConfig)
    const safeAddress = !compareAddresses(owner, connectedUserAddress) ? owner : undefined

    switch (assetERCType) {
    case AssetType.ERC20:
      return await transferERC20(contractAddress, receiver, amount!, chainId, safeAddress)
    case AssetType.ERC721:
      return await transferERC721(contractAddress, owner, receiver, tokenId!, chainId, safeAddress)
    case AssetType.ERC1155:
      return await transferERC1155(contractAddress, owner, receiver, tokenId!, amount!, chainId, safeAddress)
    }
  }

  return {
    transfer,
  }
}
