<template>
  <div
    v-if="isLoan && loanData?.isRunningLoan"
    class="proposal-status">
    <Countdown
      v-if="loanData?.defaultDate"
      smallest-units="minutes"
      :expiration-date="new Date(loanData?.defaultDate * 1000)"/>
  </div>
  <span
    v-else-if="paidBackAtDate"
    class="proposal-status__date">{{ paidBackAtDate }}</span>
  <span
    v-else-if="loanData?.defaultDate && (isDefaulted || isDefaultedAndClaimed)"
    class="proposal-status__date">{{ DateUtils.getFormattedTimeAgo(new Date(loanData?.defaultDate * 1000), true) }}</span>
  <div
    v-else-if="props.proposal.expiration"
    class="proposal-status">
    <span v-if="isExpired && expirationDate">
      {{ DateUtils.getFormattedTimeAgo(expirationDate, true) }}
    </span>
    <span v-else-if="isCancelled">
      Cancelled
    </span>
    <Countdown
      v-if="expirationDate && !isExpired && !isCancelled"
      :expiration-date="expirationDate"
      without-info-sign
      @on-expiration="handleExpiration"/>
  </div>
</template>

<script setup lang="ts">
import type { Proposal } from '@/revamp/modules/proposals/types'
import DateUtils from '@/utils/DateUtils'
import Countdown from '@/general-components/Countdown.vue'
import { computed } from 'vue'
import { getProposalAndLoanListQueryKey, useLoanDetail } from '@/modules/common/backend/generated'
import { useQueryClient } from '@tanstack/vue-query'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'

type Props = {
  proposal: Proposal
}
const props = defineProps<Props>()

const chainId = computed(() => String(props.proposal.chainId))
const onChainId = computed(() => String(props.proposal.onChainId))
const loanTokenContractAddress = computed(() => CHAINS_CONSTANTS[chainId.value]?.pwnV1_2Contracts?.pwnLoan || '0x missing contract address')
const loanDetailQuery = useLoanDetail(chainId, loanTokenContractAddress, onChainId, {
  query: {
    enabled:
      props.proposal.status === LoanStatus.Accepted ||
      props.proposal.status === LoanStatus.PaidBack ||
      props.proposal.status === LoanStatus.Defaulted ||
      props.proposal.status === LoanStatus.NonExistent,
  },
})

const loanData = computed(() => {
  const data = loanDetailQuery.data?.value?.data
  if (!data) {
    return null
  }

  const isRunningLoan = LoanStatus.Accepted === +data.status as LoanStatus
  const isPaidBack = LoanStatus.PaidBack === +data.status as LoanStatus || data.paidBackAt

  return { ...data, isRunningLoan, isPaidBack }
})

const queryClient = useQueryClient()

const isExpired = computed(() => {
  const now = DateUtils.getTimestampInSeconds(new Date())
  if (!props.proposal.expiration) return true
  return props.proposal.expiration < now
})

const isCancelled = computed(() => {
  return props.proposal?.status === -1
})

const expirationDate = computed(() => {
  if (!props.proposal.expiration) return undefined
  return new Date((props.proposal.expiration) * 1000)
})

const LOAN_TYPES = [
  'pwn_contracts.betaloan',
  'pwn_contracts.v1_1simpleloan',
  'pwn_contracts.v1_2simpleloan',
]

const isLoan = computed(() => LOAN_TYPES.includes(props.proposal.type))

const handleExpiration = async () => {
  await queryClient.invalidateQueries({
    queryKey: getProposalAndLoanListQueryKey({ isOffer: props.proposal?.isOffer }),
  })
}

const paidBackAtDate = computed(() => {
  if (!loanData.value?.paidBackAt) return
  return DateUtils.getFormattedTimeAgo(new Date(loanData.value.paidBackAt * 1000), true)
})

const isDefaulted = computed(() => {
  return loanData.value?.status === 4
})

const isDefaultedAndClaimed = computed(() => {
  return loanData.value?.status === 0 && loanData.value?.claimedAt && !loanData.value?.paidBackAt
})

</script>

<style scoped>
.proposal-status {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;

  &__date {
    font-size: 0.875rem;
    white-space: nowrap;
    line-height: 2.2;
  }
}
</style>
