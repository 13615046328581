<template>
  <RevampBaseModal
    v-model:is-open="modalIsShown"
    heading-align="left"
    custom-max-width="52rem"
    :heading="'Multi proposal preview'"
    :size="ModalSize.Medium">
    <template #body>
      <div class="modal-multi-proposal-preview__wrapper">
        <div class="modal-multi-proposal-preview__collateral-and-proposal-info">
          <CollateralDetail
            v-if="proposalModel"
            class="modal-multi-proposal-preview__collateral-detail"
            :collateral="{
              chainId: proposalModel.chainId,
              address: proposalModel.collateral.address,
              category: proposalModel.collateral.category,
              tokenId: parsedProposal?.collateralId,
            }"
            is-proposal-preview
            :is-dynamic="proposalModel?.collateral?.isDynamicContract"
            :amount="proposalModel?.collateral.category === AssetType.ERC721 ? 1 : proposalModel?.collateralAmount.toString() || parsedProposal?.collateralAmount "/>
        </div>

        <ProposalDetail
          v-if="parsedProposal && proposalModel"
          class="modal-multi-proposal-preview__proposal-detail"
          is-proposal-preview
          :credit-asset="creditAsset as AssetDetail"
          :collateral-asset="collateralAsset as AssetDetail"
          :ltv="Number(formValue?.ltv)"
          :credit-amount="parsedProposal.creditAmount ?? (proposalModel?.creditAmount.toString() || '0')"
          :apr="parsedProposal.accruingInterestApr ?? 0"
          :credit-amount-label="parsedProposal.isOffer ? 'Lend' : 'Borrow'"
          :duration="parsedProposal.duration"
          :total-repayment="String(amountToRepayEnd)"
          :is-offer="parsedProposal.isOffer"
          :proposer="parsedProposal.proposer"
          :type="parsedProposal.type"
          :chain-id="parsedProposal.chainId">
          <template #credit>
            <MultipleAssetsAmount
              :credit-assets="assetsWithMatchingAmount || []"
              :asset-amount="formValue?.creditAmount ?? '0'"
              :is-display-symbol="true"
              :is-appraisal-aligned-right="true"/>
          </template>
          <template #expiration>
            <div class="modal-multi-proposal-preview__expires-in">
              <span>Expires in</span>
              <span>{{ DateUtils.getDaysDifference(new Date(proposalModel.expiration * 1000), new Date()) }} days</span>
            </div>
          </template>
        </ProposalDetail>
      </div>

      <AprChart
        v-if="proposalModel && amountToRepayEnd && amountToRepayStart"
        :loan-duration-days="proposalModel?.loanDurationDays"
        :amount-to-repay-start="+amountToRepayStart"
        :token-symbol="'In Stables'"
        :amount-to-repay-end="+amountToRepayEnd"/>

      <div class="modal-multi-proposal-preview__warnings-and-button-container">
        <div class="modal-multi-proposal-preview__warnings-and-button-container__warnings">
          <ProposalWarnings
            :is-apr-too-high="isAprTooHigh"
            :is-ltv-too-high="isLtvTooHigh"
            :is-offer="parsedProposal?.isOffer ?? false"/>
        </div>

        <BaseButton
          :is-disabled="createMultiProposal.isPending.value"
          :color="(isLtvTooHigh || isAprTooHigh) ? ButtonColor.Warning : ButtonColor.Primary"
          class="modal-proposal-preview__button"
          :size="ButtonSize.XL"
          :button-text="'Create Multi Proposal'"
          @on-button-click="handlePostMultiProposalClick"/>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import ModalSize from '@/general-components/ModalSize'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import BaseButton, { ButtonColor, ButtonSize } from '@/general-components/BaseButton.vue'
import { computed, ref, toRefs, watch, watchEffect } from 'vue'
import { useCreateProposalPayload } from '@/revamp/components/proposal-form/useCreateProposalPayload'
import type { CreateProposalFormValue } from '@/revamp/components/proposal-form/useCreateProposalPayload'
import type { BaseProposal } from '@/modules/common/pwn/proposals/ProposalClasses'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import { computedAsync } from '@vueuse/core'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import AssetType from '@/modules/common/assets/AssetType'
import CollateralDetail from '@/revamp/components/CollateralDetail.vue'
import { parseAndTransform, zProposalDetailPayload } from '@/revamp/modules/proposals/types'
import type { AssetDetail } from '@/revamp/modules/proposals/types'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import { formatUnits } from 'viem'
import DateUtils from '@/utils/DateUtils'
import ProposalDetail from '@/revamp/components/ProposalDetail.vue'
import MultipleAssetsAmount from '@/revamp/components/MultipleAssetsAmount.vue'
import AprChart from '@/revamp/components/modals/proposal-preview-modal/AprChart.vue'
import { useMultiCreditsProposal } from '@/revamp/hooks/useMultiCreditsProposal'
import { getRawAmount } from '@/utils/utils'
import ProposalWarnings from '@/revamp/components/modals/proposal-preview-modal/ProposalWarnings.vue'

type Props = {
  isOpen: boolean
  formValue: CreateProposalFormValue | null
  onClose: () => void
}

const props = defineProps<Props>()
const { formValue } = toRefs(props)

const modalIsShown = ref(false)
const { createProposalPayload } = useCreateProposalPayload()
const { getProposalsToCreate, createMultiProposal } = useMultiCreditsProposal(
  () => {
    modalIsShown.value = false
    props.onClose()
  },
)

const proposalToBeModel = computedAsync(async () => {
  if (!formValue.value) {
    return null
  }

  const proposalBodyToBESemiComplete = await createProposalPayload(formValue.value, true, V1_2ProposalType.SIMPLE_LOAN_SIMPLE_PROPOSAL)

  return proposalBodyToBESemiComplete
}, null)

const parsedProposal = computed(() => {
  if (!proposalToBeModel.value) {
    return null
  }
  return parseAndTransform(zProposalDetailPayload, proposalToBeModel.value)
})

const proposalModel = ref<BaseProposal | null>(null)

// eslint-disable-next-line @typescript-eslint/no-misused-promises
watchEffect(async () => {
  if (proposalToBeModel.value && formValue.value) {
    const collateralAsset: AssetWithAmount = new AssetWithAmount({
      ...formValue.value.collateralAsset,
      amountInput: formValue.value.collateralAmount,
    })

    const creditAsset: AssetWithAmount = new AssetWithAmount({
      ...formValue.value.creditAsset,
      amountInput: formValue.value.creditAmount,
    })

    proposalModel.value = await ProposalFactory.createProposalFromBackendModel(
      proposalToBeModel.value,
      collateralAsset,
      creditAsset,
      true,
    )
  }
})

const amountToRepayStart = computed(() => proposalModel.value && formatUnits(proposalModel.value.creditAmount, proposalModel.value?.creditAsset?.decimals))
const amountToRepayEnd = computed(() => {
  if (!proposalModel.value) return null
  const principal = formatUnits(proposalModel.value.creditAmount, proposalModel.value.creditAsset?.decimals)
  const apr = formatUnits(BigInt(proposalModel.value.accruingInterestAPR), APR_DECIMAL_POINT_PADDING)
  return +principal * (1 + (+apr / 100) * (proposalModel.value.loanDurationDays / 365))
})

watch(() => props.isOpen, (newVal) => {
  modalIsShown.value = newVal
})

watch(() => modalIsShown.value, (newVal) => {
  if (!newVal) {
    props.onClose()
  }
})

const isLtvTooHigh = computed(() => {
  if (!props.formValue?.ltv) return false
  return Number(props.formValue.ltv) > 99
})

const isAprTooHigh = computed(() => {
  if (!proposalModel.value) return false
  const aprFormated = proposalModel.value.accruingInterestAPR / (10 ** APR_DECIMAL_POINT_PADDING)
  return aprFormated >= 150
})

const assetsWithMatchingAmount = computed(() => {
  return props.formValue?.creditAsset?.bundleAssets.filter((asset) => {
    const parsedCreditAmount = getRawAmount(props.formValue?.creditAmount ?? '0', asset.decimals)
    const parsedAssetAmount = getRawAmount(asset.amount, asset.decimals)

    return parsedAssetAmount >= parsedCreditAmount
  }) || []
})

const handlePostMultiProposalClick = () => {
  if (!assetsWithMatchingAmount.value?.length || !props.formValue) {
    return
  }

  const values = getProposalsToCreate(assetsWithMatchingAmount.value, props.formValue)

  createMultiProposal.mutate({ values })
}

const creditAsset = computed<AssetDetail | null>(() => {
  if (!proposalModel.value) return null
  return {
    address: proposalModel.value?.creditAsset?.address,
    chainId: proposalModel.value?.creditAsset?.chainId,
    thumbnailUrl: proposalModel.value?.creditAsset?.image,
    symbol: proposalModel.value?.creditAsset?.symbol,
    decimals: proposalModel.value?.creditAsset?.decimals,
    isVerified: proposalModel.value?.creditAsset?.isVerified,
  }
})

const collateralAsset = computed<AssetDetail | null>(() => {
  if (!proposalModel.value) return null
  return {
    address: proposalModel.value?.collateral?.address,
    chainId: proposalModel.value?.collateral?.chainId,
    thumbnailUrl: proposalModel.value?.collateral?.image,
    symbol: proposalModel.value?.collateral?.symbol,
    decimals: proposalModel.value?.collateral?.decimals,
    isVerified: proposalModel.value?.collateral?.isVerified,
  }
})

</script>

<style scoped>
.modal-multi-proposal-preview {
  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }

  &__collateral-and-proposal-info {
    display: flex;
    width: 100%;

    padding-right: 2rem;
    border-right: 1px solid #828282;
  }

  &__collateral-detail {
    width: 100%;
    min-width: 21rem;
  }

  &__expires-in {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 14px;
  }

  &__warnings-and-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
