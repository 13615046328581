import { computed } from 'vue'
import usePwnSafeNfts from '@/modules/pages/pwn-safe/pwn-safe-detail/safe-assets/usePwnSafeNfts'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import usePwnSafeAssetsDashboard from '@/modules/pages/pwn-safe/usePwnSafeAssetsDashboard'
import { ATR_SORT_OPTIONS_LOOKUP } from '@/modules/pages/pwn-safe/tables/PwnSafeTableDefinitions'

export default function usePwnSafeAtrTokens() {
  const { sortedNFTs } = usePwnSafeNfts()

  const sortedPwnSafeAtrTokens = computed<AssetWithAmount[]>(() => {
    const atrTokens = sortedNFTs.value.filter(nft => AssetMetadata.isAtrToken(nft))
    const { selectedSortOption } = usePwnSafeAssetsDashboard()
    return ATR_SORT_OPTIONS_LOOKUP[selectedSortOption.value.id](atrTokens, selectedSortOption.value.direction)
  })

  return {
    sortedPwnSafeAtrTokens,
  }
}
