<template>
  <div class="proposal-interest">
    <span class="proposal-interest__value">{{ valueToDisplay }}</span>

    <RewardsTooltip
      v-if="formattedRewardsToBeEarned"
      :assets="rewardsAssets"
      :proposal-id="proposalId"
      :proposal-rewards-amount="formattedRewardsToBeEarned"
      :apr="valueToDisplay">
      <template #trigger>
        <div class="proposal-interest__tooltip-trigger">
          <RepScoreIcon
            width="14"
            class="rewards-tooltip__trigger-icon"
            height="14"/>

          <span class="proposal-interest__points">
            <SafeDisplayDecimals :value="formattedRewardsToBeEarned"/>
          </span>
        </div>
      </template>

      <template #fallback>
        <div class="proposal-interest__tooltip-trigger">
          <RepScoreIcon
            width="14"
            class="rewards-tooltip__trigger-icon"
            height="14"/>

          <span class="proposal-interest__points">
            <SafeDisplayDecimals :value="formattedRewardsToBeEarned"/>
          </span>
        </div>
      </template>
    </RewardsTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import RewardsTooltip from '@/general-components/promo/RewardsTooltip.vue'
import type { SupportedChain } from '@/constants/chains/types'
import type { Address } from 'viem'
import { useRewardsForCreditToBeEarned } from '@/revamp/features/points/useRewardsForCreditToBeEarned'
import { formatDecimalPoint } from '@/utils/utils'
import RepScoreIcon from '@/assets/icons/rep-score-primary-color.svg'
import SafeDisplayDecimals from '@/general-components/safe-display-decimals/SafeDisplayDecimals.vue'

type Props = {
  interest: number | null
  proposalId: number | string | null
  chainId: SupportedChain
  creditAddress: Address
  collateralAddress: Address
  amount: string | null
};

const props = defineProps<Props>()
const { creditAddress, chainId, amount, collateralAddress } = toRefs(props)

const { rewardsToBeEarned } = useRewardsForCreditToBeEarned(creditAddress, chainId, amount)
const formattedRewardsToBeEarned = computed(() => {
  if (rewardsToBeEarned.value === 0) {
    return ''
  }
  return formatDecimalPoint(rewardsToBeEarned.value)
})

const valueToDisplay = computed(() => {
  if (!props.interest) return '-'
  const interestValue = props.interest * 10 ** -APR_DECIMAL_POINT_PADDING
  if (interestValue > 9999) {
    return '+9999%'
  }
  return interestValue.toFixed(0) + '%'
})

const rewardsAssets = computed(() => {
  if (!creditAddress.value || !collateralAddress.value) return []
  return [{ chainId: chainId.value, address: creditAddress.value, type: 'credit' }, { chainId: chainId.value, address: collateralAddress.value, type: 'collateral' }]
})

</script>

<style scoped>
.proposal-interest {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
  height: 100%;

  &__tooltip-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.2rem;
  }

  &__trigger-icon {
    margin-top: -2px;
  }

  &__value {
    font-size: 0.875rem;
    color: var(--text-color);
    margin-right: auto;
  }

  &__rep-points {
    align-self: flex-start;
  }

  &__points {
    color: var(--primary-color-1);
    font-family: var(--font-family-supreme);
    font-size: 0.75rem;
  }
}
</style>
