import type { BaseLoan } from '@/modules/common/pwn/loans/LoanClasses'
import type { UnixTimestamp } from '@/modules/common/typings/customTypes'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type { SupportedChain } from '@/constants/chains/types'
import { compareAddresses, formatAmountWithDecimals, formatToken, getRawAmount } from '@/utils/utils'
import { calculateInterest, calculateApr } from '@/utils/calculations'
import type { DurationUnit } from 'luxon'
import { DateTime } from 'luxon'
import DateUtils from '@/utils/DateUtils'
import LoanStatus from '@/modules/common/pwn/loans/LoanStatus'
import type BaseOfferContract from '@/modules/common/pwn/contracts/BaseOfferContract'
import type { AssetPrice } from '@/modules/common/assets/typings/AssetPriceClasses'
import ltv from '@/utils/ltv'
import BetaOfferContract from '@/modules/common/pwn/contracts/beta/BetaOfferContract'
import type BaseV1SimpleLoanOfferContract from '@/modules/common/pwn/contracts/v1/BaseV1SimpleLoanOfferContract'
import V1SimpleLoanSimpleOfferContract from '@/modules/common/pwn/contracts/v1/V1SimpleLoanSimpleOfferContract'
import type { RouteLocationRaw } from 'vue-router'
import RouteName from '@/router/RouteName'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import V1SimpleLoanListOfferContract from '@/modules/common/pwn/contracts/v1/V1SimpleLoanListOfferContract'
import AssetType from '@/modules/common/assets/AssetType'
import { zeroAddress } from 'viem'
import type { Address, Hex, TransactionReceipt } from 'viem'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'

export enum OfferType {
  BetaOffer = 'BetaOffer',
  V1SimpleLoanSimpleOffer = 'V1SimpleLoanSimpleOffer',
  V1SimpleLoanListOffer = 'V1SimpleLoanListOffer',
}

export abstract class BaseOffer {
  // V1.1 specific
  abstract offerContract: BaseOfferContract

  // common fields in proposal
  id: string
  loanId: string | null
  lender: Address
  nonce: unknown
  loanDurationDays: number
  expiration: UnixTimestamp // in seconds // rename to expiration

  // offer naming / move into BaseV1SimpleLoanOffer and use proposalHash etc?
  offerHash: Hex | undefined // rename to hash
  offerSignature: Hex // rename to signature?
  offerType: OfferType // rename to proposal type
  fixedInterestAmount?: bigint

  hasLenderSufficientAllowance: boolean
  hasLenderSufficientBalance: boolean
  collateral?: AssetWithAmount
  chainId: SupportedChain
  contractAddress: Address

  loanAsset: AssetWithAmount
  repaymentAmount: string
  isRevoked: boolean
  loan: BaseLoan // TODO rather create ILoan interface and use it here?

  // @ts-expect-error TS(2564) FIXME: Property 'loanAssetAppraisalWhenAccepted' has no i... Remove this comment to see the full error message
  loanAssetAppraisalWhenAccepted: AssetPrice

  constructor(offer?: Partial<BaseOffer>) {
    this.id = offer?.id || ''
    this.loanId = offer?.loanId ?? null
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | ""' is not assignable to typ... Remove this comment to see the full error message
    this.lender = offer?.lender || ''
    this.loanAsset = offer?.loanAsset || new AssetWithAmount()
    this.repaymentAmount = offer?.repaymentAmount || ''
    // @ts-expect-error TS(2322) FIXME: Type 'BaseLoan | undefined' is not assignable to t... Remove this comment to see the full error message
    this.loan = offer?.loan
    this.loanDurationDays = offer?.loanDurationDays || 0
    this.expiration = offer?.expiration || 0
    this.nonce = offer?.nonce || ''
    this.offerHash = offer?.offerHash
    this.offerSignature = offer?.offerSignature || '0x'
    this.isRevoked = offer?.isRevoked || false
    // @ts-expect-error TS(2322) FIXME: Type 'SupportedChain | undefined' is not assignabl... Remove this comment to see the full error message
    this.chainId = offer?.chainId
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
    this.contractAddress = offer?.contractAddress
    // @ts-expect-error TS(2322) FIXME: Type 'OfferType | undefined' is not assignable to ... Remove this comment to see the full error message
    this.offerType = offer?.offerType
    this.hasLenderSufficientBalance = offer?.hasLenderSufficientBalance ?? true
    this.hasLenderSufficientAllowance = offer?.hasLenderSufficientAllowance ?? true
    this.collateral = offer?.collateral ? new AssetWithAmount(offer.collateral) : undefined
    this.fixedInterestAmount = offer?.fixedInterestAmount
  }

  get uniqueIdentifier(): string {
    return `${this.loanAsset.uniqueIdentifier}_${this.repaymentAmount}_${this.loanDurationDays}_${this.expiration}_${String(this.nonce)}`
  }

  get isAcceptedOffer(): boolean {
    return this.loan?.acceptedOffer?.offerHash?.toLowerCase() === this.offerHash?.toLowerCase()
  }

  get isUnavailable(): boolean {
    return !this.hasLenderSufficientBalance || !this.hasLenderSufficientAllowance
  }

  get isAcceptable(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (this instanceof V1SimpleLoanListOffer) {
      return !this.isAccepted && !this.isUnavailable && !this.hasOfferExpired && !this.isRevoked
    }
    return !this.isUnavailable && !this.hasOfferExpired && !this.isRevoked
  }

  get hasOfferExpired(): boolean {
    const expirationTime = DateTime.fromSeconds(this.expiration || 0)
    return expirationTime.diffNow().as('milliseconds') < 0
  }

  get offerUnavailabilityReason(): string | null {
    if (this.loan && this.loan?.status !== LoanStatus.New && !this.isRevoked && !this.hasOfferExpired && this.offerType !== OfferType.V1SimpleLoanListOffer) {
      return `Your offer wasn't selected by the borrower. However, the offer itself remains valid.

In other words, anyone with collateral can still accept your offer at any time. Your offer will become invalid once it expires. In case you don't want to wait for it to expire, you can also cancel it manually before its expiry date.`
    } else if (!this.hasLenderSufficientBalance) {
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      if (compareAddresses(this.lender, userAddress)) {
        return 'You currently do not have sufficient funds.'
      } else {
        return 'This lender currently does not have sufficient funds.'
      }
    } else if (!this.hasLenderSufficientAllowance) {
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      if (compareAddresses(this.lender, userAddress)) {
        return 'You currently do not have sufficient amount of credit asset approved for use.'
      } else {
        return 'This lender currently does not have sufficient amount of credit asset approved for use.'
      }
    } else {
      return null
    }
  }

  get loanAssetAddress(): Address {
    return this.loanAsset?.address
  }

  get loanAssetSymbol(): string {
    return this.loanAsset?.symbol ?? ''
  }

  get loanAssetImage(): string {
    return this.loanAsset?.image
  }

  get repaymentAmountRaw(): bigint {
    return getRawAmount(this.repaymentAmount, this.loanAsset?.decimals)
  }

  get repaymentAmountFormatted(): string {
    return formatToken(this.repaymentAmount)
  }

  get repaymentAssetWithAmount(): AssetWithAmount {
    return new AssetWithAmount({
      ...this.loanAsset,
      amount: formatAmountWithDecimals(this.repaymentAmountRaw, this.loanAsset.decimals),
    })
  }

  get loanAmountFormatted(): string {
    return this.loanAsset.amountFormatted
  }

  get loanAmountRaw(): bigint {
    return this.loanAsset.amountRaw
  }

  get loanAmountInputRaw(): bigint {
    return this.loanAsset.amountInputRaw
  }

  get interest(): number | '' {
    return calculateInterest(this.loanAsset.amount || this.loanAsset.amountInput, this.repaymentAmount)
  }

  get interestVerbose(): string {
    return (this.interest || this.interest === 0) ? `${this.interest}%` : 'x'
  }

  get interestAPRVerbose(): string | null {
    const apr = calculateApr(
      this.loanAsset.amount || this.loanAsset.amountInput,
      this.repaymentAmount,
      this.loanDurationDays,
    )
    if (!apr && apr !== 0) { return null }
    return String(apr) + '%'
  }

  get durationVerbose(): string {
    return `${this.loanDurationDays}d`
  }

  get offerExpirationDate(): string {
    return DateUtils.displayDate(this.expiration * 1000) // in milliseconds
  }

  get offerExpirationDateObject(): Date {
    return new Date(this.expiration * 1000) // in milliseconds
  }

  get offerExpirationRelativeDetailed(): string {
    const units: DurationUnit[] = [
      'days',
      'hours',
      'minutes',
      'seconds',
    ]

    const expirationTime = DateTime.fromSeconds(this.expiration || 0)
    const isPast = expirationTime.diffNow().as('milliseconds') < 0
    if (isPast) return '0h'

    const expirationDuration = expirationTime.diffNow().shiftTo(...units)
    const highestUnit = units.find((unit) => expirationDuration.get(unit) !== 0) || 'seconds'
    const highestUnitIndex = Math.min(units.indexOf(highestUnit), 2)
    const highestUnitPair = [units[highestUnitIndex], units[highestUnitIndex + 1]]
    const duration = expirationDuration.shiftTo(...highestUnitPair).toObject()

    const expirationString = []
    for (const property in duration) {
      const timeValue = Math.floor(duration[property])
      if (timeValue > 0) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        expirationString.push(`${Math.floor(duration[property])}${property.charAt(0)}`)
      }
    }

    return expirationString.join(', ')
  }

  get relativeRepaymentDateFromNow(): string {
    return DateUtils.displayLongDate(DateUtils.getFutureDate(this.loanDurationDays))
  }

  get isListOffer(): boolean {
    return this.offerType === OfferType.V1SimpleLoanListOffer
  }

  get isTokenListOffer(): boolean {
    return this.offerType === OfferType.V1SimpleLoanListOffer && this.loan.collateral.isErc20
  }

  get isCollectionListOffer(): boolean {
    return this.offerType === OfferType.V1SimpleLoanListOffer && !this.loan.collateral.isErc20
  }

  public async acceptOffer(step: ToastStep): Promise<TransactionReceipt> {
    return this.offerContract.acceptOffer(this, this?.loan, step)
  }

  public async revokeOffer(step: ToastStep): Promise<TransactionReceipt> {
    return await this.offerContract.revokeOffer(this, step)
  }

  public async isApprovedForMakeOffer(loanAmountRaw: bigint): Promise<boolean> {
    return await this.offerContract.isApprovedForMakeOffer(this, loanAmountRaw)
  }

  public async approveForMakeOffer(loanAmountRaw: bigint): Promise<boolean> {
    return await this.offerContract.approveForMakeOffer(this, loanAmountRaw)
  }

  public async isApprovedForAcceptOffer(walletAddress: Address): Promise<boolean> {
    return await this.offerContract.isApprovedForAcceptOffer(this, walletAddress)
  }

  public async approveForAcceptOffer(collateral: AssetWithAmount, step?: ToastStep): Promise<boolean> {
    return await this.offerContract.approveForAcceptOffer(collateral, step)
  }

  public createOfferStruct(loanAmountRaw: bigint, collateralAmountRaw: bigint) {
    return this.offerContract.createOfferStruct(this, loanAmountRaw, collateralAmountRaw)
  }

  get ltv(): number {
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    return ltv(this.collateral || this.loan?.collateral, this.loanAsset)
  }
}

export class BetaOffer extends BaseOffer {
  public offerContract: BetaOfferContract
  public declare nonce: Hex

  constructor(offer?: Partial<BetaOffer>) {
    super(offer)
    this.offerContract = new BetaOfferContract()
  }
}

export abstract class BaseV1SimpleLoanOffer extends BaseOffer {
  abstract offerContract: BaseV1SimpleLoanOfferContract<BaseV1SimpleLoanOffer>
  public declare nonce: bigint

  public borrower: Address | undefined // borrower address that can accept the offer, if null then anyone with collateral can accept

  constructor(offer?: Partial<BaseV1SimpleLoanOffer>) {
    super(offer)
    this.borrower = offer?.borrower
  }

  public async encodeOfferData(loanAmountRaw: bigint, collateralAmountRaw: bigint): Promise<Hex> {
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '`0x${stri... Remove this comment to see the full error message
    return await this.offerContract.encodeOfferData(this, loanAmountRaw, collateralAmountRaw)
  }
}

export class V1SimpleLoanSimpleOffer extends BaseV1SimpleLoanOffer {
  public offerContract: V1SimpleLoanSimpleOfferContract

  constructor(offer?: Partial<V1SimpleLoanSimpleOffer>) {
    super(offer)
    this.offerContract = new V1SimpleLoanSimpleOfferContract()
  }

  get routeToCollateral(): RouteLocationRaw {
    return {
      name: RouteName.NftPage,
      params: {
        chainName: CHAINS_CONSTANTS[this.chainId].general.chainName.toLowerCase(),
        contractAddress: this.collateral?.address,
        // @ts-expect-error TS(2322) FIXME: Type 'bigint | undefined' is not assignable to typ... Remove this comment to see the full error message
        tokenId: this.collateral?.tokenId,
      },
    }
  }

  public static createOfferRequestBody(offer: V1SimpleLoanSimpleOffer, loanAmountRaw: bigint, collateralAmountRaw: bigint) {
    const { address: userAddress } = getAccount(pwnWagmiConfig)

    if (!offer.loanId) {
      return {
        lender_address: userAddress!,
        asset_address: offer.loanAssetAddress,
        asset_amount: loanAmountRaw.toString(),
        loan_yield: (offer.repaymentAmountRaw - loanAmountRaw).toString(),
        duration: DateUtils.convertDaysToSeconds(offer.loanDurationDays),
        expiration: offer.expiration,
        chain_id: offer.chainId,
        contract_address: CHAINS_CONSTANTS[offer.chainId].pwnV1Contracts.pwnSimpleLoanSimpleOffer,
        hash: offer.offerHash!,
        signature: offer.offerSignature,
        nonce: offer.nonce.toString(),
        collateral_amount: collateralAmountRaw.toString(),
        collateral_token_id: offer.collateral?.tokenId ? String(offer.collateral.tokenId) : null,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        collateral_address: offer.collateral.address,
        borrower_address: offer.borrower,
        // version: V1SimpleLoanSimpleOfferListBackendSchemaVersion['v_11/PWNSimpleLoanSimpleOffer'],
      }
    }
    return {
      // TODO change this with addition of asset offers, currently it's only suitable for offer on loan request
      borrower_address: zeroAddress,
      loan_id: Number(offer.loanId),
      lender_address: offer.lender,
      asset_address: offer.loanAssetAddress,
      asset_amount: loanAmountRaw.toString(),
      loan_yield: (offer.repaymentAmountRaw - loanAmountRaw).toString(),
      duration: DateUtils.convertDaysToSeconds(offer.loanDurationDays),
      expiration: offer.expiration,
      chain_id: offer.chainId,
      contract_address: CHAINS_CONSTANTS[offer.chainId].pwnV1Contracts.pwnSimpleLoanSimpleOffer,
      hash: offer.offerHash!,
      signature: offer.offerSignature,
      nonce: offer.nonce.toString(),
      // version: V1SimpleLoanSimpleOfferListBackendSchemaVersion['v_11/PWNSimpleLoanSimpleOffer'],
    }
  }
}

export class V1SimpleLoanListOffer extends BaseV1SimpleLoanOffer {
  public offerContract: V1SimpleLoanListOfferContract
  public collateralAddress: Address
  public isAccepted: boolean

  constructor(offer?: Partial<V1SimpleLoanListOffer>) {
    super(offer)
    this.offerContract = new V1SimpleLoanListOfferContract()
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
    this.collateralAddress = offer?.collateralAddress
    // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.isAccepted = offer?.isAccepted
  }

  public static createOfferRequestBody = (offer: V1SimpleLoanListOffer, loanAmountRaw: bigint, collateralAmountRaw: bigint) => {
    return {
      lender_address: offer.lender,
      borrower_address: null,
      asset_address: offer.loanAssetAddress,
      asset_amount: loanAmountRaw.toString(),
      loan_yield: (offer.repaymentAmountRaw - loanAmountRaw).toString(),
      duration: DateUtils.convertDaysToSeconds(offer.loanDurationDays),
      expiration: offer.expiration,
      chain_id: offer.chainId,
      contract_address: CHAINS_CONSTANTS[offer.chainId].pwnV1Contracts.pwnSimpleLoanListOffer,
      nonce: offer.nonce.toString(),
      signature: offer.offerSignature,
      hash: offer.offerHash,
      collateral_amount: [AssetType.ERC721].includes(offer.loan.collateral.category) ? '1' : collateralAmountRaw.toString(),
      collateral_ids_whitelist_merkle_root: null, // TODO change with persistent offer
      collateral_address: offer.loan.collateral.address,
      collateral_ids_whitelist: null, // TODO change with persistent offer
      // version: V1SimpleLoanListOfferListBackendSchemaVersion['v_11/PWNSimpleLoanListOffer'],
    }
  }

  get repaymentDate(): string {
    const MILLISECONDS_IN_SECOND = 1000
    const timeNowInMiliseconds = new Date().getTime()
    return DateUtils.displayDate(timeNowInMiliseconds + DateUtils.convertDaysToSeconds(this.loanDurationDays * MILLISECONDS_IN_SECOND))
  }

  get routeToAssetErc20ListOffers(): RouteLocationRaw {
    return {
      name: RouteName.TokenPage,
      params: {
        chainName: CHAINS_CONSTANTS[this.chainId].general.chainName,
        contractAddress: this.collateralAddress,
      },
    }
  }
}
