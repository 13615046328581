export const generatePaginationArray = (maxPages: number, currentPage: number): (number | string)[] => {
  const paginationArray = []

  if (maxPages <= 5) {
    // If there are 5 or fewer pages, display them all.
    for (let i = 1; i <= maxPages; i++) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      paginationArray.push(i)
    }
  } else {
    // Display 5 closest results on the right of the current page with "..." offset.
    const halfOffset = 2 // Number of pages to display on either side of the current page.

    // Display the first page.
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    paginationArray.push(1)

    // Calculate the range around the current page.
    const start = Math.max(2, currentPage - halfOffset)
    const end = Math.min(maxPages - 1, currentPage + halfOffset)

    // Add "..." if needed.
    if (start > 2) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      paginationArray.push('...')
    }

    // Generate the range of pages to display.
    for (let i = start; i <= end; i++) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      paginationArray.push(i)
    }

    // Add "..." if needed.
    if (end < maxPages - 1) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      paginationArray.push('...')
    }

    // Display the last page.
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    paginationArray.push(maxPages)
  }

  return paginationArray
}

export const getPageElementOffset = (arrayOfPages: (number | string)[], page: number | string, limit: number, index?: number): number | undefined => {
  if (page === '...' || typeof page === 'string') {
    // ... is in the first half of the pagination array
    if (index && index < (arrayOfPages.length / 2)) {
      const nextNonDotsPage = arrayOfPages[index + 1]
      if (typeof nextNonDotsPage === 'number') {
        return (Math.ceil(nextNonDotsPage / 2)) * limit
      }
      // ... is in the second half of the pagination array
    } else if (index && index > (arrayOfPages.length / 2)) {
      const nextNonDotsPage = arrayOfPages[index + 1]
      const prevNonDotsPage = arrayOfPages[index - 1]
      if (typeof nextNonDotsPage === 'number' && typeof prevNonDotsPage === 'number') {
        const nextPage = ((nextNonDotsPage - prevNonDotsPage) / 2) - 1
        return (Math.ceil(prevNonDotsPage + nextPage)) * limit
      }
    }
  }
}
