import { defineStore } from 'pinia'
import StoreIds from '@/constants/storeIds'
import { useLocalStorage } from '@vueuse/core'
import type { ChainFilterOptions, SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import { parseChainIdFromResponse } from '@/modules/common/backend/backendUtils'
import { computed } from 'vue'
import type { SupportedChain } from '@/constants/chains/types'
import { isPwnSpace, pwnSpaceName } from './pwnSpace/pwnSpaceDetail'
import { enabledChains } from './web3/useEnabledChains'
import type { ChainIdEnumBackendSchema } from './backend/generated'

const SELECTED_CHAIN_LOCAL_STORAGE_KEY = 'selected-chains'

const decodeSelectedChains = (selectedChains: string): ChainFilterOptions | undefined => {
  if (!selectedChains) {
    return undefined
  }

  if (selectedChains === 'all') {
    return 'all'
  }

  const selectedChainsArray = selectedChains.split(',').map(chainId => parseChainIdFromResponse(Number(chainId) as ChainIdEnumBackendSchema))
  return selectedChainsArray as ChainFilterOptions
}

const encodeSelectedChains = (selectedChains: ChainFilterOptions | undefined): string => {
  if (!selectedChains) {
    return ''
  }

  if (selectedChains === 'all') {
    return 'all'
  }

  return selectedChains.join(',')
}

export const useSelectedChainsStore = defineStore(StoreIds.selectedChains, () => {
  const selectedChainKey = isPwnSpace ? `${pwnSpaceName}:${SELECTED_CHAIN_LOCAL_STORAGE_KEY}` : SELECTED_CHAIN_LOCAL_STORAGE_KEY
  const persistentSelectedChains = useLocalStorage<ChainFilterOptions | undefined>(selectedChainKey, 'all', {
    serializer: {
      read: decodeSelectedChains,
      write: encodeSelectedChains,
    },
  })

  const setSelectedChain = (chainId: SetChainFilterOption) => {
    if (chainId === 'all') {
      persistentSelectedChains.value = 'all'
    } else {
      const currentSelectedChains = persistentSelectedChains.value
      if (currentSelectedChains === 'all') {
        persistentSelectedChains.value = [chainId]
      } else {
        const currentSelectedChainsArray = Array.from(currentSelectedChains ?? [])
        const chainIndex = currentSelectedChainsArray.indexOf(chainId)
        if (chainIndex !== -1) {
          currentSelectedChainsArray.splice(chainIndex, 1)
        } else {
          currentSelectedChainsArray.push(chainId)
        }
        persistentSelectedChains.value = currentSelectedChainsArray as ChainFilterOptions
      }
    }
  }

  /**
   * Returns the selected values, if 'all' is selected, it returns all supported chains
   */
  const selectedValues = computed(() => {
    if (persistentSelectedChains.value === 'all') {
      return enabledChains
    }
    return persistentSelectedChains.value
  })

  const nonSelectedChains = computed(() => {
    if (persistentSelectedChains.value === 'all') {
      return []
    }

    const _nonSelectedChains: SupportedChain[] = []
    for (const supportedChain of enabledChains) {
      if (!(persistentSelectedChains.value ?? []).includes(supportedChain)) {
        _nonSelectedChains.push(supportedChain)
      }
    }

    return _nonSelectedChains
  })

  return {
    selectedChains: persistentSelectedChains,
    nonSelectedChains,
    selectedValues,
    actions: {
      setSelectedChain,
    },
  }
})
