<template>
  <RevampBaseModal
    v-model:is-open="isOpen"
    :heading="'Select Your Collateral'"
    class-name="select-your-collateral"
    heading-align="left">
    <template #trigger>
      <slot
        v-if="withTrigger"
        name="trigger"
        :handle-open="() => isOpen = true">
        <button
          class="select-your-collateral__token-select"
          type="button"
          @click="isOpen = true">
          <span v-if="!isAssetSelected"> Select Asset </span>

          <div
            v-if="bundleImage && multipleAssetsSelected"
            class="select-your-collateral__bundled">
            <component
              :is="bundleImage"/>
            <SafeText
              v-if="selectedToken?.isTokenBundle && selectedToken?.isBundleWithSingleAsset"
              :text="selectedToken.bundleAssets?.[0]?.isSymbolMissing ? selectedToken.bundledAsset.name : selectedToken.bundledAsset.symbol"/>
            <SafeText
              v-else
              class="select-your-collateral__bundled-text">
              <template #text>
                <div class="select-your-collateral__bundled-text">
                  Bundle
                </div>
              </template>
            </SafeText>
          </div>
          <div
            v-else-if="selectedToken"
            class="select-your-collateral__selected-asset-container">
            <div class="select-your-collateral__selected-asset">
              <TokenMedia
                :token="selectedToken"
                height="20"
                width="20"/>
              <SafeText :text="selectedToken.isSymbolMissing ? selectedToken.name : selectedToken.symbol"/>
            </div>
          </div>
          <ArrowDownIconSvg/>
        </button>
      </slot>
    </template>

    <template #subheading>
      <div class="select-your-collateral__subheader">
        <span v-if="isSecondStep">
          Bundle Selected Assets:
        </span>
      </div>
    </template>

    <template #body>
      <template v-if="!isSecondStep">
        <div class="select-your-collateral__search">
          <SearchBar
            v-model="searchBar"
            class="select-your-collateral__search-bar"
            placeholder="Select asset name or address"/>
          <RefreshButton
            :handle-refresh="handleRefreshAssets"
            class="select-your-collateral__refresh-button"
            :is-loading="isRefreshingCache"/>
        </div>

        <div class="select-your-collateral__filters-row">
          <ChainGroupFilter
            :selected-chains="selectedChains!"
            :disabled-chains="isEdit ? nonSelectedChains : undefined"
            :set-chain="selectedChainStore.actions.setSelectedChain"/>

          <div class="select-your-collateral__currency-switcher">
            <CurrencySwitcher/>
          </div>

          <GlobalFilters
            v-if="!isPwnSpace"
            x-direction="left"
            y-direction="bottom"
            :display-pricing-filter="false">
            <template #appendOnRight>
              <span
                v-if="assetsHiddenByFilter >= 1"
                class="select-your-collateral__filter-detail">
                Hidden Assets ({{ assetsHiddenByFilter }})
              </span>
            </template>
          </GlobalFilters>

          <div class="select-your-collateral__select-multiple">
            <BaseCheckbox
              :model-value="multipleAssetsSelected"
              input-name="select-multiple"
              @click="handleResetBundleSelection()"
              @update:model-value="(value) => (multipleAssetsSelected = value)"/>
            Select multiple assets
            <QuestionMarkTooltip
              tooltip-text="Bundling assets lets you use multiple assets to obtain higher-value collateral. Creating and unpacking these bundles costs gas."/>
          </div>
        </div>

        <ConnectWallet v-if="status !== 'connected'"/>

        <UserAssetsTable
          v-else
          :q="searchBar"
          :selected-chain="selectedChain"
          @select-asset="handleTokenSelected"
          @total-removed-by-filter="v => assetsHiddenByFilter = v"
          @handle-bundle="(asset, isAdding) => handleBundle(asset as AssetWithAmount, isAdding)"/>
        <BaseButton
          v-if="multipleAssetsSelected"
          style="width: 100%; justify-content: flex-end; display: flex;"
          :is-disabled="assetsToBundle.length === 0"
          button-text="Continue"
          @on-button-click="handleChangePage"/>
      </template>
      <template v-else>
        <UserBundleSelection
          :handle-go-back="() => isSecondStep = false"
          :handle-bundle-button="handleBundleButton"
          :selected-assets="assetsToBundle"
          @handle-bundle="(asset, isAdding) => handleBundle(asset as AssetWithAmount, isAdding)"/>
      </template>
    </template>
  </RevampBaseModal>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import SearchBar from '@/revamp/components/SearchBar.vue'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import CurrencySwitcher from '@/layout/header/CurrencySwitcher.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import type { AssetMetadata, AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useUserNfts } from '@/revamp/hooks/useUserNfts'
import { useUserTokens } from '@/revamp/hooks/useUserTokens'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import RefreshButton from '@/modules/common/assets/components/RefreshButton.vue'
import TokenMedia from '@/general-components/TokenMedia.vue'
import SafeText from '@/general-components/SafeText.vue'
import useSelectAssetModal from '@/revamp/components/modals/select-your-collateral/useSelectAssetModal'
import useDashboardCache from '@/modules/pages/dashboard/hooks/useDashboardCache'
import BaseButton from '@/general-components/BaseButton.vue'
import UserAssetsTable from '@/revamp/components/modals/select-your-collateral/UserAssetsTable.vue'
import type { SupportedChain } from '@/constants/chains/types'
import UserBundleSelection from '@/revamp/components/modals/select-your-collateral/UserBundleSelection.vue'
import type { VNode } from 'vue'
import ConnectWallet from '@/revamp/components/modals/ConnectWallet.vue'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import { isPwnSpace } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import GlobalFilters from '@/modules/common/filters/global/GlobalFilters.vue'
import ArrowDownIconSvg from '@/assets/icons/arrow-select.svg?component'

interface Props {
  showOnlyOwned?: boolean;
  selectedToken?: AssetMetadata;
  chainId?: SupportedChain
  headerText?: string
  withTrigger?: boolean
  isModalOpen?: boolean;
  bundleImage?: VNode;
  isEdit?: boolean;
  hideYouOwnColumn?: boolean;
  isFungibleProposal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showOnlyOwned: false,
  withTrigger: true,
})

const { isOpen, multipleAssetsSelected, isSecondStep } = useSelectAssetModal()

const { status, address } = useCustomAccount()
const searchBar = ref('')
const assetsHiddenByFilter = ref(0)

const selectedChainStore = useSelectedChainsStore()
const { selectedChains, selectedValues, nonSelectedChains } = storeToRefs(selectedChainStore)
const { resetCacheState } = useDashboardCache()

const isAssetSelected = computed(() => !!props.selectedToken?.address)
const emit = defineEmits<{(e: 'select-asset', asset: AssetMetadata | AssetWithAmount[])}>()

const { reload: reloadNfts } = useUserNfts(address)
const { reload: reloadTokens } = useUserTokens(address)
const web3Modal = useWeb3Modal()

const assetsToBundle = ref<AssetWithAmount[]>([])
const selectedChain = computed(() => assetsToBundle.value[0]?.chainId || undefined)
const handleTokenSelected = (asset: AssetMetadata) => {
  emit('select-asset', asset)
  isOpen.value = false
}

watch(() => (props.isModalOpen), (newVal) => {
  isOpen.value = newVal
})

watch(isOpen, async (newIsOpen) => {
  if (newIsOpen && status.value !== 'connected') {
    await web3Modal.open()
  }
})
const handleBundle = (asset: AssetWithAmount, isAdding: boolean) => {
  if (isAdding) {
    assetsToBundle.value.push(asset)
  } else {
    assetsToBundle.value = assetsToBundle.value.filter((a) => a.id !== asset.id)
    if (assetsToBundle.value.length === 0) {
      isSecondStep.value = false
    }
  }
}

const handleChangePage = () => {
  isSecondStep.value = true
}

const isRefreshingCache = ref(false)

const handleRefreshAssets = async () => {
  const selectedChains = selectedValues?.value?.map((v) => v) || []

  const cachePurgeQueries = [
    ...selectedChains.map((v) =>
      resetCacheState({
        userAddress: address.value!,
        chainId: v,
      }),
    ),
  ]
  isRefreshingCache.value = true
  await Promise.allSettled(cachePurgeQueries)
  reloadNfts()
  reloadTokens()
  isRefreshingCache.value = false
}
const handleBundleButton = async (updatedAssets: AssetWithAmount[]) => {
  assetsToBundle.value = updatedAssets
  emit('select-asset', assetsToBundle.value)
  isOpen.value = false
}

const handleResetBundleSelection = () => {
  assetsToBundle.value = []
  useSelectAssetModal().resetRows()
}

/*
const bundleValue = computed(() => {
  const total = 0
  // todo: implement this logic
  for (const asset of assetsToBundle.value) {
    console.log('single asset', asset)
    const { priceData: currentUnitPrice } = useAssetPrice(
      asset.chainId as string,
      asset.address,
      asset.tokenId ? asset.tokenId as string : null,
      asset.category,

    )
    const price = parseFloat(currentUnitPrice.value)
    if (!isNaN(price)) {
      total += price
    }
  }

  return total
})
*/
</script>

<style scoped>
.select-your-collateral {
  &__filters-row {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    gap: 1rem;
    align-items: center;

    @media only screen and (--mobile-viewport) {
      flex-flow: row wrap;
    }
  }

  &__controls {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }

  &__full-value {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__subheader {
    font-size: 1.125rem;
    font-family: var(--font-family-screener);
    font-weight: 400;
    padding-top: 0.5rem;
  }

  &__bundled {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-flow: nowrap;
  }

  &__tab {
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--text-color);
    padding: 1rem 2rem;
    width: 100%;

    font-weight: 700;
    font-size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: border-color 0.3s,
      color 0.3s;

    &--active {
      border-color: var(--primary-color-1);
      color: var(--primary-color-1);

      path {
        transition: fill 0.3s;
      }
    }
  }

  &__tab-icon {
    margin-right: 0.5rem;
  }

  &__select-multiple {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
  }

  &__wallet-not-connected {
    padding: 4rem;
    width: 100%;

    border: 1px solid var(--gray-2);

    display: flex;
    justify-content: center;
  }

  &__search {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  &__search-bar {
    width: 100%;
  }

  &__filter-detail {
    font-size: 0.75rem;
    color: #a4a4a4;

    border-left: 1px solid #a4a4a4;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
  }

  &__tables {
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 1rem;

    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__footer {
    display: flex;
    flex-flow: row nowrap;
  }

  &__token-select {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--background-color);
    height: 28px;
    width: 136px;
    font-size: 1rem;
    border: 1px solid var(--text-color);
    color: var(--text-color);

    user-select: none;

    &:hover {
      border: 1px solid var(--text-color);
    }
  }

  &__selected-asset {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 10.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bundled-text {
    font-size: 0.85rem;
    font-weight: 400;
  }

  &__selected-asset-container {
    display: contents;
  }
}
</style>

<style>
.select-your-collateral__tab--active path {
  fill: var(--primary-color-1);
}

.select-your-collateral__currency-switcher .multiselect__tags {
  padding: 0.5rem;
}

.select-your-collateral__currency-switcher .multiselect {
  height: auto;
  min-height: auto;
}

.select-your-collateral__currency-switcher .multiselect__single {
  margin-bottom: 0;
  display: flex;
}

.select-your-collateral {
  .modal__body {
    min-width: 944px;

    @media only screen and (--small-viewport) {
      min-width: 100%;
    }
  }
}

.select-your-collateral__filters-row {
  .global-filters__icon-wrapper {
    height: 2.5rem;
  }
}
</style>

<style>
.select-your-collateral {
  .multiselect__tags {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .multiselect__single {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__refresh-button {
    .button {
      width: 3rem !important;
      height: 3rem !important;

      padding: 0.25rem !important;
    }
  }
}
</style>
