<template>
  <div class="thesis-committed">
    <div class="thesis-committed__header">
      <span class="thesis-committed__header-title">
        Your current commitment
      </span>

      <span
        class="thesis-committed__header-value"
        :class="{
          'thesis-committed__header-value--empty': totalCommittedAmount === 'Not committed'
        }">
        {{ totalCommittedAmount }}
      </span>
    </div>

    <div class="thesis-committed__body">
      <BaseButton
        :variant="ButtonVariant.Default"
        is-full-width
        :button-text="!hideRevoke ? 'Commit More' : 'Commit'"
        class="thesis-committed__button-commit"
        @click="handleOpenModal">
        <template #icon>
          <div class="thesis-committed__commit-icons">
            <div class="thesis-committed__commit-icons-wrapper">
              <div
                v-for="(asset, index) in parsedCreditAssets"
                :key="asset.id"
                :style="{
                  position: 'relative',
                  zIndex: index + 1,
                  marginLeft: index !== 0 ? '-0.5rem' : '0'
                }">
                <TokenMedia
                  :token="asset"
                  class="thesis-committed__commit-icon"/>
              </div>
            </div>

            <!-- <span class="thesis-committed__text-more">
              +4 more tokens
            </span> -->
          </div>
        </template>
      </BaseButton>

      <RevokeThesisProposals
        v-if="Object.keys(noncesToRevoke).length && !hideRevoke"
        :on-update="props.onUpdate"
        :title-text="titleText"
        asset-name="All"
        :thesis-id="props.thesis.id"
        :nonces="noncesToRevoke"/>
    </div>

    <ThesisSelectCommitedAsset
      :thesis="thesis"
      :error-text="errorText"
      :on-confirm="values => handleContinueWithSelected(values, thesis)"
      :on-close="() => {modalIsVisible = false}"
      :is-submitting="formIsSubmitting"
      :selected-assets="parsedCreditAssets"
      :stats-data="props.thesisStatsData"
      :is-visible="modalIsVisible"/>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, unref } from 'vue'
import BaseButton, { ButtonVariant } from '@/general-components/BaseButton.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import TokenMedia from '@/general-components/TokenMedia.vue'
import type { ThesisCreditStatsSchemaWorkaroundBackendSchema, ThesisSchemaWorkaroundBackendSchema } from '@/modules/common/backend/generated'
import ThesisSelectCommitedAsset
  from '@/revamp/components/modals/thesis-select-commited-asset/ThesisSelectCommitedAsset.vue'
import { usePopulateThesis } from '@/revamp/hooks/thesis/usePopulateThesis'
import { parseThesisAsset } from '@/revamp/hooks/thesis/utils'
import { formatUnits } from 'viem'
import type { Address } from 'viem'
import { useAssetListPrice } from '@/revamp/hooks/asset-prices/useAssetListPrice'
import RevokeThesisProposals from '@/revamp/components/modals/thesis/RevokeThesisProposals.vue'
import type { SupportedChain } from '@/constants/chains/types'
import type { AssetWithExtraThesisParams } from '@/revamp/hooks/thesis/types'
import { extractErrorMessage } from '@/modules/common/utils'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { createDepositedAssets } from '@/constants/depositedAssets'
import { compareAddresses } from '@/utils/utils'

type Props = {
  thesis: ThesisSchemaWorkaroundBackendSchema;
  thesisStatsData: Record<Address, ThesisCreditStatsSchemaWorkaroundBackendSchema>
  onUpdate: (thesisId: string) => void
  hideRevoke: boolean
}
const props = defineProps<Props>()
const modalIsVisible = ref(false)
const { address } = useCustomAccount()

const userAssetsWithTotalCommited = computed(() => {
  if (!address.value) {
    return []
  }
  const userAssets = Object.values(props.thesisStatsData).map(v => {
    const parsedAsset = parseThesisAsset(v.creditAssetMetadata)
    return parsedAsset.updateAssetAmounts({
      amount: formatUnits(BigInt(v.amountsStats.totalCommittedAmount), v.creditAssetMetadata.decimals ?? 18),
    })
  })
  return userAssets
})

const { summarizedAmount } = useAssetListPrice(userAssetsWithTotalCommited)

const totalCommittedAmount = computed(() => {
  const parsedSummarizedAmount = summarizedAmount.value.formatted.replace(/\D/g, '')
  const isGreaterThanZero = Number(parsedSummarizedAmount) > 0

  if (summarizedAmount.value && isGreaterThanZero) {
    return summarizedAmount.value.formatted
  }
  return 'Not committed'
})

const parsedCollateralAssets = computed(() => props.thesis.collateralAssets?.map(v => {
  const parsedAsset = parseThesisAsset(v)
  return {
    ...parsedAsset,
    allocationPercentage: v.allocationPercentage,
  } as AssetWithExtraThesisParams
}) || [])
const parsedCreditAssets = computed(() => {
  const parsedThesisAssets = props.thesis.creditsStats?.map((v) => parseThesisAsset(v.creditAssetMetadata))
  if (!parsedThesisAssets) return []

  const { transformedDepositedAssets, assetsToFilter } = createDepositedAssets(parsedThesisAssets, parsedThesisAssets)
  const filteredParsedThesisAssets = parsedThesisAssets.filter((v) => { return !assetsToFilter.some((asset) => compareAddresses(asset.address, v.address)) })
  return [...(filteredParsedThesisAssets || []), ...(transformedDepositedAssets || [])]
})

const reactiveThesis = computed(() => props.thesis)

const { populateThesisMutation, getProposalsToCreate, checkCollateralKyc } = usePopulateThesis(
  parsedCollateralAssets,
  parsedCreditAssets,
  reactiveThesis,
  () => {
    modalIsVisible.value = false
  },
)

const errorText = computed(() => {
  if (!populateThesisMutation.error.value) return ''
  return extractErrorMessage(populateThesisMutation.error.value)
})

const formIsSubmitting = populateThesisMutation.isPending

const noncesToRevoke = computed(() => {
  const result: Partial<Record<SupportedChain, bigint[]>> = {}

  const stats = Object.values(props.thesisStatsData)

  stats.forEach(v => {
    const chainId = v.creditAssetMetadata.chainId
    if (!result[chainId]) {
      result[chainId] = []
    }

    if (v.proposalNonces) {
      result[chainId]!.push(...v.proposalNonces.map(BigInt))
    }
  })

  return result
})

const submitAction = (selectedAssets: AssetWithAmount[], thesis: ThesisSchemaWorkaroundBackendSchema) => {
  populateThesisMutation.mutate(
    {
      values: getProposalsToCreate(selectedAssets, thesis),
      previousNonces: noncesToRevoke.value,
    },
    {
      onSuccess: () => {
        props.onUpdate(thesis.id)
      },
    },
  )
}

const handleContinueWithSelected = async (selectedAssets: AssetWithAmount[], thesis: ThesisSchemaWorkaroundBackendSchema) => {
  const isKyc = await checkCollateralKyc(thesis, () => submitAction(selectedAssets, thesis))
  if (isKyc) return
  submitAction(selectedAssets, thesis)
}

const titleText = computed(() => {
  return `Cancel All Your ${unref(reactiveThesis).title} Proposals`
})

const handleOpenModal = () => {
  modalIsVisible.value = true
}

</script>

<style lang="postcss" scoped>
.thesis-committed {
  border: 1px solid #434343;

  display: flex;
  flex-flow: column nowrap;
  white-space: nowrap;
  padding: 1rem;
  gap: 0.75rem;

  min-width: 413px;

  @media only screen and (--mobile-viewport) {
    min-width: auto;
  }

  &__commit-icons {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    gap: 0.5rem;

    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      margin-left: auto;
    }
  }

  &__button-commit {
    width: 100%;
  }

  &__commit-icon {
    width: 1rem;
    height: 1rem;
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__header-value {
    font-size: 0.875rem;
    font-weight: 700;
    font-family: var(--font-family-supreme);
    color: var(--text-color);

    &--empty {
      color: #a4a4a4;
    }
  }

  &__text-more {
    color: var(--gray-2);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    font-family: var(--font-family-supreme);
  }

  &__body {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.75rem;
  }
}
</style>

<style>
.thesis-committed__body .button_wrapper .button {
  height: 4rem;
}
</style>
