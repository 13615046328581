<template>
  <BaseSection
    heading="Borrowing Stats"
    with-gray-background
    is-subsection
    :is-loading="isFetchingWalletStats">
    <YourBorrowingStats :wallet-stats="walletStats"/>
  </BaseSection>
</template>

<script lang="ts" setup>
import BaseSection from '@/general-components/BaseSection.vue'
import YourBorrowingStats from '@/modules/sections/your-stats/YourBorrowingStats.vue'
import useWalletStats from '@/modules/common/web3/useWalletStats'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'

const { dashboardUserAddress } = useDashboard()

// @ts-expect-error FIXME: strictNullChecks
const { walletStats, isFetchingWalletStats } = useWalletStats(dashboardUserAddress)
</script>
