<template>
  <div class="proposal-filters">
    <div
      class="proposal-filters__controls"
      :class="{ 'filters-expanded': isOpen }">
      <button
        :class="['proposal-filters__button', {
          'proposal-filters__button--active': isDifferentFromDefault
        }]"
        @click="toggle">
        Filter
        <ChevronRightWhiteIconSvg :class="['proposal-filters__arrow', {'proposal-filters__arrow--opened': isOpen}]"/>
      </button>
      <Transition>
        <FiltersForm
          v-if="isOpen"
          class="proposal-filters__form"/>
      </Transition>
      <BaseCheckbox
        :model-value="showHistory"
        label="Show History"
        class="proposal-filters__show-history"
        @update:model-value="value => emit('update:showHistory', value)"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import ChevronRightWhiteIconSvg from '@/assets/icons/chevron-right-white.svg'
import { ref, toRefs } from 'vue'
import FiltersForm from '@/revamp/components/proposal-filters/FiltersForm.vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import { storeToRefs } from 'pinia'

interface Props {
  showHistory: boolean;
}
const props = defineProps<Props>()
const { showHistory } = toRefs(props)

const proposalFiltersStore = useProposalFilters()
const { isDifferentFromDefault } = storeToRefs(proposalFiltersStore)

const emit = defineEmits<{(e: 'update:showHistory', value: boolean): void}>()

const isOpen = ref(false || isDifferentFromDefault.value)

const toggle = () => {
  isOpen.value = !isOpen.value
}
</script>

<style scoped>
.proposal-filters {
  display: inline-flex;
  position: relative;
  width: 100%;

  &__controls {
    display: flex;
    align-items: center;
    width: 100%;
    flex-flow: row nowrap;
    gap: 1rem;
    transition: all 0.3s ease;

    @media only screen and (--small-viewport) {
      flex-flow: row wrap;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    position: relative;
    background-color: transparent;
    color: var(--text-color);
    padding: 0.3125rem 0.75rem;
    border: 1px solid #434343;
    cursor: pointer;
    will-change: border-color, color;
    transition: 0.3s linear all;

    &:hover {
      border-color: var(--primary-color-1);
      color: var(--primary-color-1);
    }

    &--active {
      &::after {
        content: "";
        position: absolute;
        width: 0.75rem;
        height: 0.75rem;
        background-color: var(--primary-color-1);
        top: -0.375rem;
        right: -0.375rem;
      }
    }
  }

  &__arrow {
    transition: transform 0.5s ease;

    &--opened {
      transform: rotate(180deg);
    }
  }

  &__form {
    @media only screen and (--small-viewport) {
      order: 3;
    }
  }

  &__show-history {
    transition: all 0.3s ease;
    width: 100%;

    @media only screen and (--small-viewport) {
      order: 2;
      margin-left: 0;
      width: auto;
    }
  }

  .filters-expanded {
    .proposal-filters__show-history {
      margin-left: auto;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style>
.proposal-filters {
  button {
    &:hover {
      svg path {
        fill: var(--primary-color-1);
      }
    }
  }
}
</style>
