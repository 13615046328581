import type { EventOptions, TrackPageOptions } from '@/modules/common/analytics/analytics'
import type IAnalyticsTracker from '@/modules/common/analytics/analytics'
import Hotjar from '@hotjar/browser'
import { globalConstants } from '@/constants/globals'

export default class HotJarTracker implements IAnalyticsTracker {
  init(): void {
    if (!Hotjar.isReady() && import.meta.env.VITE_HOTJAR_SITE_ID) {
      const isInitialized = Hotjar.init(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
        globalConstants.hotjarSiteId,
        globalConstants.hotjarSnippetVersion,
        {
          debug: import.meta.env.DEV,
        },
      )

      if (!isInitialized) {
        // eslint-disable-next-line no-console
        console.error('Hotjar failed to initialize')
      }
    }
  }

  trackEvent(options: EventOptions): void {
    Hotjar.event(options.name)
  }

  trackPageView(options: TrackPageOptions): void {
    Hotjar.stateChange(options.url)
  }
}
