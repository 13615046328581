<template>
  <div class="section-nft-appraisal">
    <BaseAssetPageSection
      icon="stats"
      question-mark-tooltip="Details about the pricing of this particular asset, you can use these to get an approximation of this NFT’s true value"
      label="Appraisal"
      :has-data="hasNFTAppraisal"
      :is-fetching="isFetchingNFTAppraisal">
      <template #content>
        <div class="section-nft-appraisal__content">
          <div class="section-nft-appraisal__content-row--header">
            <div class="section-nft-appraisal__left-header-cell">
              Name
            </div>
            <div class="section-nft-appraisal__right-header-cell">
              Value
            </div>
          </div>

          <div
            v-for="stat in nftAppraisalWithLabels"
            :key="stat.description.label"
            class="section-nft-appraisal__content-row">
            <div class="section-nft-appraisal__left-cell">
              <span>{{ stat.description.label }}</span>
              <span class="section-nft-appraisal__question-mark-tooltip">
                <QuestionMarkTooltip
                  v-if="stat.description.questionMarkTooltip"
                  :tooltip-text=" stat.description.questionMarkTooltip"/>
              </span>
            </div>

            <div class="section-nft-appraisal__right-cell">
              <span v-if="!hasCellValidValue(stat)">No data</span>
              <span v-else-if="stat.value.type === StatValueTypeEnum.Text">
                {{ stat.value.value }} </span>
              <EthAndUsdPriceLabel
                v-else-if="isValuePrice(stat.value.value, stat.value.type)"
                class="section-nft-appraisal__price "
                :amount="stat.value.value"/>
              <div
                v-else-if="isValueDate(stat.value.value, stat.value.type)"
                class="section-nft-appraisal__expiration">
                {{ DateUtils.displayDate(stat.value.value) }}
              </div>
              <div
                v-else-if="isValuePriceRange(stat.value.value, stat.value.type)"
                class="section-nft-appraisal__predicted-range">
                <PriceRange
                  :low="stat.value.value[0]"
                  :high="stat.value.value[1]"/>
              </div>
            </div>
          </div>

          <div class="section-nft-appraisal__footer">
            <div>
              Last updated: {{ DateUtils.displayDate(nftAppraisal!.appraisalCreatedDate) }}
            </div>
            <DataSource
              :data-source="nftAppraisal!.priceSource">
              <template #textBefore>
                Data source
              </template>
            </DataSource>
          </div>
        </div>
      </template>
    </BaseAssetPageSection>
  </div>
</template>

<script lang="ts" setup>
import BaseAssetPageSection from '@/modules/pages/asset/nft-page/BaseAssetPageSection.vue'
import DateUtils from '@/utils/DateUtils'
import PriceRange from '@/modules/pages/asset/nft-page/SectionNFTAppraisal/PriceRange.vue'
import type {
  AllowedStatValueTypes,
  CollectionOrAppraisalRow,
} from '@/modules/pages/asset/nft-page/types/CollectionOrAppraisalRow'
import {
  StatValueTypeEnum,
} from '@/modules/pages/asset/nft-page/types/CollectionOrAppraisalRow'
import useSectionNFTAppraisal from '@/modules/pages/asset/nft-page/SectionNFTAppraisal/useSectionNFTAppraisal'
import EthAndUsdPriceLabel from '@/general-components/EthAndUsdPriceLabel.vue'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import type { AmountInEthAndUsd, PriceRange as PriceRangeType } from '@/modules/common/assets/typings/prices'
import DataSource from '@/general-components/data-source/DataSource.vue'

const { nftAppraisalWithLabels, isFetchingNFTAppraisal, hasNFTAppraisal, nftAppraisal } = useSectionNFTAppraisal()

const hasCellValidValue = (stat: CollectionOrAppraisalRow): boolean => {
  if (stat.value.type === StatValueTypeEnum.PredictedRange) {
    const priceRangeValue = stat.value?.value as PriceRangeType
    return !!(priceRangeValue?.length && (priceRangeValue[0]?.usdAmount || priceRangeValue[0]?.ethAmount))
  }
  return !!stat.value?.value
}

const isValuePriceRange = (value: AllowedStatValueTypes, type: StatValueTypeEnum): value is PriceRangeType => {
  return type === StatValueTypeEnum.PredictedRange
}

const isValueDate = (value: AllowedStatValueTypes, type: StatValueTypeEnum): value is Date | number => {
  return type === StatValueTypeEnum.Date
}

const isValuePrice = (value: AllowedStatValueTypes, type: StatValueTypeEnum): value is AmountInEthAndUsd => {
  return type === StatValueTypeEnum.Price
}
</script>

<style scoped>
.section-nft-appraisal {
  &__content-row,
  &__content-row--header {
    display: grid;
    grid-template-columns: repeat(1, 2.7fr 3.5fr);
    font-size: 0.875rem;
  }

  &__content-row:nth-child(even) {
    background-color: var(--gray-3);
  }

  &__left-header-cell,
  &__right-header-cell {
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid white;
  }

  &__left-header-cell {
    text-align: left;
    border-right: 1px solid white;
  }

  &__right-header-cell {
    text-align: center;
  }

  &__left-cell,
  &__right-cell {
    padding: 0.5rem 0.75rem 0.4rem;
    position: relative;
  }

  &__left-cell {
    text-align: left;
    border-right: 1px solid white;
    display: flex;
    align-items: center;
  }

  &__right-cell {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    padding: 0.75rem;
    font-size: 0.75rem;
    border-top: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__footer-icon {
    margin: -0.1rem 0.5rem 0;
  }

  &__predicted-range {
    width: 100%;
  }

  &__question-mark-tooltip {
    margin-left: 0.2rem;
    line-height: 1;
  }
}

</style>
