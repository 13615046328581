<template>
  <div class="address-info">
    <component
      :is="icon"
      alt="address icon"
      class="address-info__icon"/>
    <span class="address-info__name">{{ name }}</span>
    <span class="address-info__address">({{ shortAddress }})</span>
  </div>
</template>

<script setup lang="ts">
import { shortenAddress } from '@/utils/utils'
import type { Address } from 'viem'
import { computed, toRefs } from 'vue'
import type { Component } from 'vue'

interface Props {
    address: Address
    name: string
    icon: Component
}

const props = defineProps<Props>()
const { address, name, icon } = toRefs(props)

const shortAddress = computed(() => shortenAddress(address.value))
</script>

<style scoped>
.address-info {
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  gap: 0.625rem;
  align-items: center;

  &__address {
    color: var(--gray-2);
  }
}
</style>
