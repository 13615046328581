import type { WalletStatsBackendSchema } from '@/modules/common/backend/generated'

export class WalletSearchStats {
  constructor(
        public totalLoans: number,
  ) {
  }

  static createFromBackendModel(walletStats: WalletStatsBackendSchema): WalletSearchStats {
    const totalLoans = (walletStats?.total_lent ?? 0) + (walletStats?.total_borrowed ?? 0)
    return new WalletSearchStats(totalLoans)
  }
}
