<template>
  <BaseTable
    v-model:active-sort-option="selectedCoinsSortOption"
    class="your-coins-table"
    :columns="tableDefinition"
    :items="assets"
    :variant="TableVariant.Assets"
    :is-fetching="isLoading"
    :has-first-column-padding="hasFirstColumnPadding"
    :is-hoverable="!isDashboard"
    is-virtual
    :item-height="63"
    :virtual-table-overscan="3"
    :table-min-width="tableMinWidth"
    table-max-height="330px"
    display-loading-bottom
    :is-disabled="isAssetDisabled"
    @on-row-click="emitOnAssetSelect">
    <template #checkbox="{ item: token }">
      <BaseCheckbox
        v-if="isAssetSelected !== undefined"
        :model-value="isAssetSelected(token)"
        :is-disabled="!token.isValid"
        @click.prevent/>
    </template>

    <template #asset-info="{ item: token, index }">
      <AssetInfo
        :class="['your-coins-table__asset-info', { 'your-coins-table__disabled-row': isAssetDisabled?.(token) }]"
        :asset="token"
        badge-size="small"
        :show-verification-badge="true"
        :show-link="isDashboard"
        :display-rewards-tooltip="isDashboard"
        :hide-invalid-asset-tooltip="hideInvalidAssetTooltip"
        :is-lazy-loaded="handleIsLazyLoadedInTable(index)"/>
    </template>

    <template #amount="{ item: token }">
      <BasePriceLabel :amount="token.amount"/>
    </template>

    <template #appraisal="{ item: token }">
      <AssetAppraisal
        :asset-amount="token.amount"
        :category="token.category"
        :asset-metadata="token"
        @asset-appraisal-updated="(log) => smallBalanceAssetsStore.addAsset(token, log)"/>
    </template>

    <!--
    <template #appraisal="{ item: token }">
      <LazyAssetPrice
        :asset="token"
        type="token-appraisal"/>
    </template>
    -->

    <template
      v-if="dashboardUserAddress === userAddress"
      #action="{ item: token }">
      <div class="your-coins-table__actions">
        <ModalUnwrapNativeToken
          v-if="isWrappedNativeTokenAndHasUnwrapAction(token)"
          :wrapped-native-token="token"/>
        <ModalWrapNativeToken
          v-else-if="hasWrapping(token)"
          :native-token="token"
          is-modal-with-input/>
        <span
          v-if="hasSeparator(token)"
          class="your-coins-table__action-separator">/</span>
        <span
          :class="[
            'link link--primary link--sm link--font-oxygen-mono',
            { 'your-coins-table__unsupported-asset': isAssetDisabled ? isAssetDisabled(token) : !token.isValid },
          ]"
          @click="useCollateral(token)">Use as collateral</span>
      </div>
    </template>
  </BaseTable>
</template>

<script setup lang="ts">
import BaseTable from '@/general-components/BaseTable.vue'
import BasePriceLabel from '@/general-components/BasePriceLabel.vue'
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import AssetInfo from '@/general-components/AssetInfo.vue'
import TableVariant from '@/general-components/TableVariant'
import { getNativeTokenWrapper } from '@/utils/chain'
import { toRefs } from 'vue'
import BaseCheckbox from '@/general-components/BaseCheckbox.vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import useLazyLoading from '@/modules/common/useLazyLoading'
import ModalUnwrapNativeToken from '@/general-components/ModalUnwrapNativeToken.vue'
import ModalWrapNativeToken from '@/general-components/ModalWrapNativeToken.vue'
import { compareAddresses } from '@/utils/utils'
import { useUserAssetsStore } from '@/modules/common/assets/useUserAssets'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { storeToRefs } from 'pinia'
import { SupportedChain } from '@/constants/chains/types'
import AssetAppraisal from '@/revamp/components/tables/cells/AssetAppraisal.vue'
import { useAssetsWithSmallBalancesStore } from '@/revamp/hooks/useAssetsWithSmallBalancesStore'

interface Props {
  tableDefinition: ColumnProperties[];
  hasFirstColumnPadding: boolean;
  isDashboard: boolean;
  isAssetSelected?: (asset: AssetWithAmount) => boolean;
  isAssetDisabled?: (asset: AssetWithAmount) => boolean;
  tableMinWidth?: string;
  assets: AssetWithAmount[];
  isLoading?: boolean;
  hideInvalidAssetTooltip?: boolean;
}

const props = defineProps<Props>()
const { tableDefinition, hasFirstColumnPadding, isDashboard, isLoading, hideInvalidAssetTooltip } = toRefs(props)
const smallBalanceAssetsStore = useAssetsWithSmallBalancesStore()

const emit = defineEmits<{(e: 'on-asset-select', asset: AssetWithAmount): void }>()

const { address: userAddress } = useCustomAccount()
const userAssetsStore = useUserAssetsStore()
const { dashboardUserAddress, selectedCoinsSortOption } = storeToRefs(userAssetsStore)
const { handleIsLazyLoadedInTable } = useLazyLoading()

const emitOnAssetSelect = (asset: AssetWithAmount) => {
  if (isDashboard.value) {
    return // do nothing
  }

  emit('on-asset-select', asset)
}

const useCollateral = (asset: AssetWithAmount) => {
  emit('on-asset-select', asset)
}

const isWrappedNativeTokenAndHasUnwrapAction = (token: AssetWithAmount) => {
  if (
    compareAddresses(token.address, '0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee') &&
    token.chainId === SupportedChain.Ethereum
  ) {
    return true
  }

  if (getNativeTokenWrapper(token.chainId)) {
    return compareAddresses(getNativeTokenWrapper(token.chainId).address, token.address)
  }
  return false
}

const hasWrapping = (token: AssetWithAmount) =>
  token.isNativeToken || (compareAddresses(token.address, '0x35fA164735182de50811E8e2E824cFb9B6118ac2') && token.chainId === SupportedChain.Ethereum)
const hasSeparator = (token: AssetWithAmount) => isWrappedNativeTokenAndHasUnwrapAction(token) || hasWrapping(token)
</script>

<style scoped>
.your-coins-table {
  &__no-assets {
    color: var(--negative-1);
    font-size: 1.2rem;
  }

  &__unsupported-asset {
    cursor: default;

    &:hover {
      filter: none;
    }
  }

  &__disabled-row {
    opacity: 0.35;
  }

  &__action-separator {
    margin: 0 0.5rem;
    color: var(--dark-separator-color);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (--small-viewport) {
    overflow: auto auto;
    padding-bottom: 2rem;
  }
}
</style>
