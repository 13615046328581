<template>
  <header :class="headerCssClass">
    <div class="breadcrumbs-header__heading-container">
      <BaseBackButton
        v-if="hasBackButton"
        class="breadcrumbs-header__back-button"/>
      <div
        v-if="image"
        class="breadcrumbs-header__icon">
        <img
          alt="icon"
          :src="imageUrl">
      </div>
      <BaseLink
        v-if="headingIsLinkTo"
        class="breadcrumbs-header__heading-link-to"
        :to="{ name: headingIsLinkTo }">
        <h1
          class="breadcrumbs-header__heading">
          <img
            class="breadcrumbs-header__heading-back-arrow"
            height="25"
            src="@/assets/icons/arrow-left-white.svg?url"
            alt="Back"><span>
              {{ activeStep.headingText }}

            </span>
        </h1>
      </BaseLink>
      <h1
        v-else
        class="breadcrumbs-header__heading">
        {{ activeStep.headingText }}
      </h1>
    </div>
    <div class="breadcrumbs-header__breadcrumbs">
      <div
        v-for="(step, index) in steps"
        :key="step.stepNumber"
        @click="handleRouting(step)">
        <span :class="stepClasses(step.stepNumber)">
          {{ step.stepName }}
        </span>
        <span
          v-if="(index + 1) < steps.length"
          :class="[
            'breadcrumbs-header__breadcrumbs-delimiter',
            { 'breadcrumbs-header__breadcrumbs-delimiter--visited': isStepVisited(step.stepNumber) }]">
          >
        </span>
      </div>
    </div>
  </header>
  <p
    v-if="activeStep.hint"
    class="breadcrumbs-header__hint">
    {{ activeStep.hint }}
  </p>
  <p
    v-if="activeStep.hintHighlighted"
    class="breadcrumbs-header__hint-highlighted">
    {{ activeStep.hintHighlighted }}
  </p>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { onBeforeRouteUpdate, useRouter } from 'vue-router'
import type { BreadcrumbActiveStep } from '@/general-components/IBreadcrumbsHeader'
import BaseBackButton from '@/general-components/BaseBackButton.vue'
import BaseLink from '@/general-components/BaseLink.vue'

const props = defineProps<{
  setActiveStep?:(step: BreadcrumbActiveStep) => void
  activeStep: BreadcrumbActiveStep
  steps: BreadcrumbActiveStep[]
  hasBackButton?: boolean
  image?: string
  customHeaderClass?: string
  disabledSteps?: number[]
  headingIsLinkTo?: string
}>()

const { activeStep, steps, setActiveStep, hasBackButton, image, customHeaderClass, disabledSteps } = toRefs(props)
const router = useRouter()
const imageUrl = computed(() => new URL(`/src/assets/icons/${image.value}`, import.meta.url).href)
const isStepVisited = (stepNumber: number) => {
  if (disabledSteps.value?.includes(stepNumber)) return false
  return stepNumber < activeStep.value.stepNumber
}
const headerCssClass = computed(() => customHeaderClass.value ? customHeaderClass.value : 'breadcrumbs-header')

const isCurrentActive = (stepNumber: number) => stepNumber === activeStep.value.stepNumber

const stepClasses = (stepNumber: number) => {
  if (disabledSteps.value?.includes(stepNumber)) {
    return ['breadcrumbs-header__step breadcrumbs-header__step--disabled']
  }
  return [
    { 'breadcrumbs-header__step': true },
    { 'breadcrumbs-header__step--visited': isStepVisited(stepNumber) },
    { 'breadcrumbs-header__step--active': isCurrentActive(stepNumber) },
  ]
}

onBeforeRouteUpdate((to, from) => {
  // @ts-expect-error FIXME: strictNullChecks
  const ROUTES: string[] = steps.value.map(step => step.route)
  if (!ROUTES.includes(to.name as string)) return
  const newStep = steps.value.find(step => step.route === to.name)
  if (!setActiveStep.value) return
  // @ts-expect-error FIXME: strictNullChecks
  setActiveStep.value(newStep)
})

const handleRouting = (step: BreadcrumbActiveStep) => {
  if (disabledSteps.value?.includes(step.stepNumber)) return
  if (isStepVisited(step.stepNumber)) {
    if (!setActiveStep.value) return
    setActiveStep.value(step)
    router.push({ name: step.route })
  }
}
</script>

<style scoped>
.breadcrumbs-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  margin-top: 3rem;
  padding-bottom: 1.5rem;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-image: var(--border-gray-dashed);
    background-size: auto 2px;
    /* small hack how to hide buggy double border (top + bottom), when height is 1px */
  }

  &__heading-container {
    display: flex;
  }

  &__back-button {
    margin-right: 1.5rem;
  }

  &__icon {
    margin-right: 1rem;
  }

  &__heading {
    font-family: var(--font-family-screener);
    font-size: 1.875rem;
    font-weight: var(--default-font-weight);
  }

  &__hint {
    margin: 1.5rem 0 1.25rem;
    line-height: 1.5rem;
  }

  &__hint-highlighted {
    margin-bottom: 4rem;
    color: var(--negative-1);
    white-space: pre-line;
  }

  &__breadcrumbs {
    color: var(--gray);
    font-size: 1rem;
    display: flex;
    gap: 1rem;
  }

  &__breadcrumbs-delimiter {
    &--visited {
      color: var(--text-color);
    }
  }

  &__step {
    font-family: var(--font-family-screener);

    &--visited {
      color: var(--text-color);
      cursor: pointer;

      &:hover {
        color: var(--primary-color-1);
      }
    }

    &--active {
      color: var(--primary-color-1);
    }

    &--disabled {
      color: var(--gray);
      cursor: default;
      text-decoration: line-through;
    }
  }

  &__heading-link-to {
    text-decoration: none;
  }

  &__heading-back-arrow {
    margin-right: 0.5rem;
    position: relative;
    top: 0.1rem;
  }

  @media only screen and (max-width: 1320px) {
    &__heading {
      font-size: 1.6rem;
    }

    &__heading-back-arrow {
      top: 0.15rem;
    }
  }

  @media only screen and (--small-viewport) {
    flex-flow: column;
    margin-top: 2rem;

    &__heading {
      font-size: 1.25rem;
    }

    &__heading-back-arrow {
      top: 0.3rem;
    }

    &__breadcrumbs {
      margin-top: 1rem;
      flex-flow: row wrap;
    }
  }
}
</style>
