<template>
  <div :class="['notification-center-settings-option', { 'notification-center-settings-option--opaque': isOpaque }]">
    <div class="notification-center-settings-option__option-text-container">
      <h3 :class="['notification-center-settings-option__option-text', {'notification-center-settings-option__option-text--is-small': isSmall}]">
        {{ text }}
      </h3>
      <div class="notification-center-settings-option__tooltip">
        <QuestionMarkTooltip
          :is-interactive="isTooltipInteractive"
          :icon-size="isSmall ? '14px' : '20px'"
          :tooltip-text="tooltipText">
          <slot name="tooltip-extra-content"/>
        </QuestionMarkTooltip>
      </div>
    </div>
    <slot name="action"/>
    <div class="notification-center-settings-option__toggle">
      <slot name="modal"/>
      <div>
        <BaseToggle
          :selected="toggleValue"
          @toggle="handleToggle"/>
        <span
          v-if="isRecommended"
          class="notification-center-settings-option__recommended">*recommended</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import BaseToggle from '@/general-components/BaseToggle.vue'
import { computed, toRefs } from 'vue'

type Props = {
  text: string
  tooltipText: string
  isRecommended: boolean
  toggleValue: boolean | undefined
  isModal?: boolean
  isTooltipInteractive?: boolean
  isSmall?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSmall: false,
})

const { text, tooltipText, isRecommended, toggleValue, isModal, isTooltipInteractive } = toRefs(props)
const isOpaque = computed(() => isModal.value && !toggleValue.value)
const emit = defineEmits<{(e: 'toggle', value: boolean): void }>()
const handleToggle = (value: boolean) => {
  emit('toggle', value)
}
</script>

<style scoped>
.notification-center-settings-option {
  display: flex;
  justify-content: space-between;
  min-width: 35rem;

  &--opaque {
    opacity: 0.6;
  }

  &__option-text-container {
    display: flex;
  }

  &__option-text {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: var(--font-family-supreme);
    font-weight: 400;

    &--is-small {
      font-size: 0.875rem;
    }
  }

  &__tooltip {
    display: flex;
    margin: auto;
    margin-left: 0.5rem;
  }

  &__toggle {
    display: flex;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  &__recommended {
    position: absolute;
    right: 0;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: var(--primary-color-1);
  }

  @media only screen and (max-width: 630px) {
    flex-direction: column;
    gap: 0.5rem;
    min-width: 0;

    &__recommended {
      position: static;
    }
  }
}
</style>
