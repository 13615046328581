<template>
  <div :class="[{ table__wrapper: hasStickyHead }]">
    <table
      ref="tableElement"
      class="table"
      :style="{ minWidth: tableMinWidth ?? '1000px' }">
      <thead :class="[hasStickyHead ? 'table__head--sticky' : 'table__head', `table__head--variant-${variant}`]">
        <tr>
          <!-- TODO change this :key="index"? to something like :key="column.id" -->
          <th
            v-for="(column, index) in columns"
            :key="index"
            scope="col"
            :class="[
              'table__head-column',
              `table__head-column--variant-${variant}`,
              `table__head-column--${column.title.toLowerCase()}`,
              { 'table__head-column--first-column-padding': hasFirstColumnPadding },
              { 'table__head-column--sortable': column.sortOptionName },
              { 'table__head-column--active': isSortedByThisColumn(column) },
            ]"
            :style="{ width: column.width }"
            @click.stop="column.sortOptionName ? changeSortOption(column) : null">
            <div
              :style="{ justifyContent: column.align }"
              :class="['table__head-container', `table__head-container--${column.title.toLowerCase()}`]">
              {{ column.title }}
              <div v-if="hasTooltips">
                <ValuationTooltip
                  v-if="column.title === 'Appraisal'"
                  class="table__tooltip"/>
                <span>
                  <QuestionMarkTooltip
                    v-if="column.title === 'Floor Price'"
                    :max-width="450"
                    class="table__tooltip"
                    tooltip-text="The floor price refers to the lowest-priced listing of an NFT within a collection"/>
                </span>
                <span>
                  <QuestionMarkTooltip
                    v-if="column.title === 'Price'"
                    :max-width="350"
                    class="table__tooltip"
                    tooltip-text="The price refers to the market price of 1 token"/>
                </span>
                <span>
                  <QuestionMarkTooltip
                    v-if="column.title === 'Floor / Price'"
                    :max-width="450"
                    class="table__tooltip"
                    tooltip-text="The 'Floor' refers to the lowest-priced listing of an NFT within a collection. The 'Price' refers to the market price of 1 token"/>
                </span>
              </div>
              <slot :name="column.headSlotName"/>
              <TableSortingIndicator
                v-if="isSortedByThisColumn(column) && activeSortOption"
                class="table__arrow"
                :value="activeSortOption.direction"/>
            </div>
          </th>
        </tr>
      </thead>

      <tbody
        ref="tableBodyElement"
        :class="['table__body', `table__body--variant-${variant}`]"
        :style="{ maxHeight: tableMaxHeight }">
        <tr v-if="isLoadingTop">
          <BaseSkeletor/>
        </tr>

        <BaseVirtualTable
          :max-height="tableHeightInPixels"
          :items="items"
          :item-height="props.itemHeight"
          :overscan="props.virtualTableOverscan"
          :is-enabled="isVirtual"
          @on-load-more="() => emit('onLoadMore')">
          <template #content="{ items: toRender }">
            <template
              v-for="(item, index) in toRender"
              :key="props?.getKeyValue ? props.getKeyValue(item) : item.id">
              <tr
                :class="[
                  'table__row',
                  `table__row--variant-${variant}`,
                  {
                    'table__row--hoverable': isHoverable,
                    'table__row--disabled': isDisabled ? isDisabled(item) : item?.isValid === false,
                    'table__row--highlighted': item?.isHighlighted,
                  },
                ]"
                @mouseover="onRowHover(item)"
                @click="onRowClick(item, $event)">
                <template
                  v-for="(column, _columnIndex) in columns"
                  :key="_columnIndex">
                  <td
                    :class="[
                      'table__td',
                      `table__td--variant-${variant}`,
                      { 'table__td--first-column-padding': hasFirstColumnPadding },
                    ]"
                    :style="{ width: column.width, ...(tdPadding && { padding: tdPadding }) }">
                    <slot
                      v-if="column.slotName"
                      :name="column.slotName"
                      :item="item"
                      :index="index"/>
                    <template v-else-if="column?.objectKey">
                      {{ item[column.objectKey] }}
                    </template>
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </BaseVirtualTable>
        <tr v-if="isLoadingBottom">
          <BaseSkeletor/>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
export interface TableItem {
  id: string | number;
  isValid?: boolean;
  isHighlighted?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any; // todo change this to unknown and resolve TS errors
}
</script>

<script setup lang="ts">
/* eslint-disable import/first */
import type { ColumnProperties } from '@/general-components/ColumnProperties'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { toggleSort } from '@/general-components/sorting/useSorting'
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from 'vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import TableVariant from '@/general-components/TableVariant'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import ValuationTooltip from '@/general-components/ValuationTooltip.vue'
import BaseVirtualTable from '@/general-components/BaseVirtualTable.vue'
import TableSortingIndicator from '@/revamp/components/tables/TableSortingIndicator.vue'

interface Props {
  columns: ColumnProperties[];
  // currently vue does not support generic component props: https://github.com/vuejs/core/issues/3102
  // once it is implemented we should change this to generic type extending from { id: string | number }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: TableItem[] | any[];
  isHoverable?: boolean;
  activeSortOption?: ActiveSortOption; // if the activeSortOption is not passed that means that the table instance does not use sorting
  isFetching?: boolean;
  isVirtual?: boolean;
  itemHeight?: number;
  virtualTableOverscan?: number;
  tableMaxHeight?: string;
  tableMinWidth?: string;
  variant?: TableVariant;
  hasFirstColumnPadding?: boolean;
  displayLoadingBottom?: boolean;
  hasStickyHead?: boolean;
  hasEditTooltip?: boolean;
  hasTooltips?: boolean;
  onHover?: (item: TableItemType) => void;
  tdPadding?: string;
  getKeyValue?: (item: TableItemType) => string;
  isDisabled?: (item: TableItemType) => boolean;
}
const props = withDefaults(defineProps<Props>(), {
  activeSortOption: undefined,
  isHoverable: true,
  isFetching: false,
  isVirtual: false,
  variant: TableVariant.Underlined,
  hasFirstColumnPadding: true,
  displayLoadingBottom: false,
  hasStickyHead: false,
  hasEditTooltip: false,
  hasTooltips: false,
})

const {
  onHover,
  columns,
  items,
  isHoverable,
  isVirtual,
  activeSortOption,
  isFetching,
  tableMaxHeight,
  variant,
  hasFirstColumnPadding,
  displayLoadingBottom,
  hasStickyHead,
  hasTooltips,
  isDisabled,
} = toRefs(props)

const isLoadingTop = computed(() => isFetching.value && !displayLoadingBottom.value)
const isLoadingBottom = computed(() => isFetching.value && displayLoadingBottom.value)
type TableItemType = (typeof items.value)[number];

// eslint-disable-next-line
const emit = defineEmits<{
  (e: 'onRowClick', clickedItem, clickEvent: MouseEvent): void;
  (e: 'update:activeSortOption', newActiveSortOption: ActiveSortOption): void;
  (e: 'onHover', item: TableItemType): void;
  (e: 'onLoadMore'): void;
}>()

const onRowClick = (item: TableItemType, $event: MouseEvent) => emit('onRowClick', item, $event)
const onRowHover = (item: TableItemType) => {
  onHover.value?.(item)
  emit('onHover', item)
}

const activeSortOptionLocal = computed({
  get: () => activeSortOption.value,
  set: (value: ActiveSortOption | undefined) => {
    if (value) {
      emit('update:activeSortOption', value)
    }
  },
})

const changeSortOption = (column: ColumnProperties): void => {
  activeSortOptionLocal.value = toggleSort(column.sortOptionName!, activeSortOptionLocal.value!)
}

const isSortedByThisColumn = (column: ColumnProperties): boolean => {
  if (!column.sortOptionName || !activeSortOption.value?.id) return false
  else return column.sortOptionName === activeSortOption.value.id
}

// Since we have scrollbar on table body, we need to dynamically set the width of <thead> in order
// to make the column headers and column data correctly aligned.
const tableElement = ref<HTMLTableElement>()
const tableBodyElement = ref<HTMLTableSectionElement>()

const resizeObserver = new ResizeObserver(() => {
  if (!tableBodyElement.value) return

  const hasScrollbar = tableBodyElement.value.scrollHeight > tableBodyElement.value.clientHeight
  // @ts-expect-error FIXME: strictNullCheks
  tableElement.value.style.setProperty('--table-scrollbar-width', hasScrollbar ? 'var(--scrollbar-width)' : '0%')
})

const tableHeightInPixels = computed(() => {
  if (!tableMaxHeight.value) return null
  const loadingBarHeight = 20
  return parseInt(tableMaxHeight.value, 10) - loadingBarHeight
})

onMounted(() => {
  if (tableBodyElement.value) {
    resizeObserver.observe(tableBodyElement.value)
  }
})

onBeforeUnmount(() => {
  if (tableBodyElement.value) {
    resizeObserver.unobserve(tableBodyElement.value)
  }
})
</script>

<style scoped>
.table {
  --table-scrollbar-width: 0;
  width: 100%;
  border-collapse: collapse;

  --variant-assets-block-spacing: calc(0.5rem + 1px);
  --variant-loan-offers-block-spacing: 0.625rem;
  --variant-asset-page-block-spacing: 0.37rem;

  &__head,
  &__head--sticky,
  &__body tr {
    display: table;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
    width: 100%;
  }

  &__head--sticky {
    background: var(--background-color);
    position: sticky;
    top: var(--menu-height);
  }

  &__head {
    background: var(--background-color);
    position: sticky;
    z-index: var(--z-index-thead);
    top: 0;
    width: calc(100% - var(--table-scrollbar-width));
    font-family: var(--font-family-autoscape);

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 1px;
      width: calc(100% + var(--table-scrollbar-width));
      background-image: var(--border-gray-dashed);
      background-size: auto 2px;
      /* small hack how to hide buggy double border (top + bottom), when height is 1px */
    }

    &--variant {
      &-underlined::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: calc(100% + var(--table-scrollbar-width));
        background-image: var(--border-gray-dashed);
        background-size: auto 2px;
      }

      &-assets::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: calc(100% + var(--table-scrollbar-width));
        background-image: var(--border-gray-dashed);
        background-size: auto 2px;
      }

      &-appraisal {
        justify-content: flex-end;
      }

      &-loan-offers::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: calc(100% + var(--table-scrollbar-width));
        background-image: var(--border-gray-dashed);
        background-size: auto 2px;
      }
    }
  }

  &__head-column {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.75rem 0;
    font-family: var(--font-family-screener);

    &:hover {
      text-decoration: var(--text-color);
    }

    &::first-letter {
      text-transform: capitalize;
    }

    &--first-column-padding:first-child {
      padding-left: var(--default-table-padding);
    }

    &--active {
      color: var(--primary-color-1);
    }

    &--sortable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &--variant {
      &-asset-page:first-child {
        padding-left: 0.5rem;
      }

      &-explorer-collections {
        color: var(--gray);
      }

      &-price {
        justify-content: flex-end;
      }
    }
  }

  &__body {
    display: block;
    overflow: auto;

    &--variant {
      &-assets {
        margin-top: var(--variant-assets-block-spacing);
      }

      &-loan-offers {
        margin-top: var(--variant-loan-offers-block-spacing);
      }
    }
  }

  &__row {
    &--variant {
      &-underlined {
        border-bottom: 1px solid var(--gray-3);
      }

      &-assets {
        border-bottom: 1px solid var(--gray-3);
      }

      &-explorer-collections {
        border-bottom: 1px solid var(--separator-color);
      }
    }

    &--highlighted {
      background: var(--primary-color-4);
    }

    &--disabled {
      opacity: 0.35;
    }
  }

  &__line {
    padding: 0;
  }

  &__td {
    padding: 1rem 0;
    white-space: nowrap;
    overflow: visible;
    /* because of BasePriceLabel hover */
    text-overflow: ellipsis;
    font-size: 1rem;
    vertical-align: middle;
    font-family: var(--font-family-oxygen-mono);

    ::v-deep(img) {
      vertical-align: middle;
      overflow: hidden;
    }

    &--first-column-padding:first-child {
      padding-left: var(--default-table-padding);
    }

    &--variant {
      &-assets {
        padding: var(--variant-assets-block-spacing) 0;
        padding-right: 2px;
      }

      &-selected-assets {
        padding: 1.5rem 0;
      }

      &-loan-offers {
        padding: var(--variant-loan-offers-block-spacing) 0;
        font-size: 1.125rem;
        line-height: normal;
      }

      &-asset-page {
        padding: var(--variant-asset-page-block-spacing) 0;
        font-size: 0.875rem;

        &:first-child {
          padding-left: 0.5rem;
        }
      }
    }
  }

  &__row--hoverable:hover &__td {
    background-color: var(--primary-color-2);
    cursor: pointer;
  }

  &__head-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--appraisal {
      justify-content: center;
    }

    &--price,
    &--appraisal {
      justify-content: flex-start;
    }
  }

  &__arrow {
    margin-left: 8px;
  }

  &__tooltip {
    margin-left: 0.3rem;
  }

  @media only screen and (--small-viewport) {
    &__body {
      overflow: auto;
    }

    &__head--sticky {
      position: relative;
      top: 0;
    }
  }
}
</style>
