import type { Nullable } from '@/modules/common/typings/customTypes'
import DataSourceType from '@/general-components/data-source/DataSourceType'
import { AmountInEthAndUsd } from '@/modules/common/assets/typings/prices'
import useUuid from '@/utils/useUuid'
import type { NFTEventBackendSchema } from '@/modules/common/backend/generated'
import { EventTypeEnumBackendSchema, MarketplaceEnumBackendSchema } from '@/modules/common/backend/generated'
import { formatAmountWithDecimals } from '@/utils/utils'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { AssetPrice } from '@/modules/common/assets/typings/AssetPriceClasses'
import type { Address } from 'viem'
import { parseAddress } from '@/utils/address-utils'

export enum NFTEventType {
  MINT = 'mint',
  SALE = 'sale',
  TRANSFER = 'transfer'
}

export default class NFTEvent {
  id: number
  eventType: NFTEventType
  fromAddress: Address
  toAddress: Address
  date: Date
  marketplace: DataSourceType
  quantity: number
  token: Nullable<AssetWithAmount> // for sale events

  constructor(nftEvent: Partial<NFTEvent>) {
    this.id = useUuid().getUuid()
    // @ts-expect-error TS(2322) FIXME: Type 'NFTEventType | undefined' is not assignable ... Remove this comment to see the full error message
    this.eventType = nftEvent?.eventType
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
    this.fromAddress = nftEvent?.fromAddress
    // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
    this.toAddress = nftEvent?.toAddress
    // @ts-expect-error TS(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
    this.date = nftEvent?.date
    // @ts-expect-error TS(2322) FIXME: Type 'DataSourceType | undefined' is not assignabl... Remove this comment to see the full error message
    this.marketplace = nftEvent?.marketplace
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.quantity = nftEvent?.quantity
    // @ts-expect-error TS(2322) FIXME: Type 'Nullable<AssetWithAmount> | undefined' is no... Remove this comment to see the full error message
    this.token = nftEvent?.token
  }

  get isSaleEvent(): boolean {
    return this.eventType === NFTEventType.SALE
  }

  get saleUsdPrice(): string | undefined {
    return this.token?.appraisal?.price?.usdAmount
  }

  // @ts-expect-error TS(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  static parseEventTypeFromBackend(eventType: EventTypeEnumBackendSchema): NFTEventType {
    if (eventType === EventTypeEnumBackendSchema.mint) {
      return NFTEventType.MINT
    } else if (eventType === EventTypeEnumBackendSchema.transfer) {
      return NFTEventType.TRANSFER
    } else if (eventType === EventTypeEnumBackendSchema.sale) {
      return NFTEventType.SALE
    }
  }

  // @ts-expect-error TS(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  static parseMarketplaceFromBackend(marketplace: MarketplaceEnumBackendSchema): DataSourceType {
    if (marketplace === MarketplaceEnumBackendSchema.OPENSEA) {
      return DataSourceType.OPENSEA
    } else if (marketplace === MarketplaceEnumBackendSchema.LOOKSRARE) {
      return DataSourceType.LOOKSRARE
    }
  }

  static createFromBackendResponse(nftEvent: NFTEventBackendSchema): NFTEvent | null {
    if (!nftEvent) {
      return null
    }

    return new NFTEvent({
      eventType: this.parseEventTypeFromBackend(nftEvent.event_type),
      fromAddress: parseAddress(nftEvent.from_address),
      toAddress: parseAddress(nftEvent.to_address),
      date: new Date(nftEvent.date),
      // @ts-expect-error TS(2322) FIXME: Type 'DataSourceType | null' is not assignable to ... Remove this comment to see the full error message
      marketplace: nftEvent.event_type === EventTypeEnumBackendSchema.SALE ? this.parseMarketplaceFromBackend(nftEvent.marketplace) : null,
      quantity: nftEvent.quantity,
      token: new AssetWithAmount({
        // TODO add eth price to response on BE
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        amount: formatAmountWithDecimals(nftEvent.value?.token_amount, nftEvent.token_metadata?.decimals),
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        address: nftEvent.token_metadata?.contract_address,
        name: nftEvent.token_metadata?.name ?? undefined,
        symbol: nftEvent.token_metadata?.symbol ?? undefined,
        decimals: nftEvent.token_metadata?.decimals ?? undefined,
        image: nftEvent.token_metadata?.thumbnail_url ?? undefined,
        appraisal: new AssetPrice({
          // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          price: new AmountInEthAndUsd(null, String(nftEvent?.value?.usd_amount)),
        }),
      }),
    })
  }
}
