<template>
  <BaseTooltip
    is-interactive
    :has-tooltip="hasTooltip">
    <template #trigger>
      <div
        :class="['ens-name__trigger', { 'ens-name__trigger-copy-button': isCopyButton }]"
        @click="handleClickOnTrigger">
        <UserAvatar
          v-if="hasAvatarComputed"
          :style="userAvatarStyles"
          :avatar-src="addressProfile?.avatar ?? undefined"/>
        <div class="ens-name__text-container">
          <span :class="['ens-name__text', { 'ens-name--copied': isCopied }]">
            {{ addressNameShort }}
            {{ compareAddresses(currentAddress, address) && !hideTextYou ? ' (you)' : '' }}
          </span>
        </div>

        <div
          v-if="isCopyButton"
          class="ens-name__copy-container">
          <CopyIconSvg
            :class="['ens-name__copy-icon', { 'ens-name__copy-icon--copied': isCopied }]"
            :height="customCopyIconSizePx"
            :width="customCopyIconSizePx"
            alt="copy"
            @click="handleCopy"/>
          <CopyBottomLeftIconSvg
            v-if="isCopied"
            class="ens-name__copy-text"
            alt="copied"/>
        </div>
      </div>
    </template>

    <div
      v-if="hasTooltip"
      class="ens-name__tooltip-wrapper">
      <p
        :class="['ens-name__address-in-tooltip', { 'ens-name--copied': isCopiedInTooltip }]"
        @click="handleCopyInTooltip">
        <!-- TODO does this work fine? -->
        <CopyBottomLeftIconSvg
          v-if="isCopiedOnLeftSide"
          class="ens-name__copy-text"
          alt="copied"/>
        {{ address }}
        <CopyBottomLeftIconSvg
          v-if="isCopiedOnRightSide"
          class="ens-name__copy-text"
          alt="copied"/>
      </p>
      <BaseLink
        class="link--primary ens-name__explorer-link-in-tooltip"
        target="_blank"
        :to="addressDetailsLink">
        {{ explorerName }}
        <ExplorerIcon
          alt="explorer image"
          class="ens-name__explorer-icon-in-tooltip"/>
      </BaseLink>
    </div>
  </BaseTooltip>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from 'vue'
import { capitalizeFirstLetter, compareAddresses, shortenAddress } from '@/utils/utils'
import BaseTooltip from '@/general-components/BaseTooltip.vue'
import BaseLink from '@/general-components/BaseLink.vue'
import type { SupportedChain } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import UserAvatar from '@/general-components/UserAvatar.vue'
import RouteName from '@/router/RouteName'
import { useRouter } from 'vue-router'
import CopyBottomLeftIconSvg from '@/assets/icons/copy-bottom-left.svg'
import CopyIconSvg from '@/assets/icons/copy.svg'
import { isAddressEqual, zeroAddress } from 'viem'
import type { Address } from 'viem'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { useAddressProfile } from '@/modules/common/web3/useAddressProfile'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

interface Props {
  address: Address
  chainId?: SupportedChain // does not have to be passed when hasTooltip === false
  customAvatarSize?: string
  hasTooltip?: boolean
  hasAvatar?: boolean
  isCopyButton?: boolean
  isCopyButtonOnLeftSide?: boolean
  tooltipPosition?: 'left' | 'right'
  isLinkToDashboard?: boolean
  customCopyIconSizePx?: number
  hideTextYou?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  chainId: undefined,
  hasTooltip: true,
  customAvatarSize: undefined,
  isCopyButton: false,
  isLinkToDashboard: false,
  hasAvatar: false,
  customCopyIconSizePx: 17,
  isCopyButtonOnLeftSide: false,
  tooltipPosition: 'right',
  hideTextYou: false,
})
const { address, hasTooltip, chainId, customAvatarSize, isCopyButton, isLinkToDashboard, hasAvatar, tooltipPosition } = toRefs(props)

const { address: currentAddress } = useCustomAccount()

const isCopied = ref(false)
const isCopiedInTooltip = ref(false)

const addressProfileData = useAddressProfile(address)
const addressProfile = computed(() => addressProfileData.data.value)
const addressNameShort = computed(() => {
  if (!address.value) return undefined
  if (addressProfile.value?.name) {
    return addressProfile.value.name
  } else if (!isStarknet && isAddressEqual(address.value, zeroAddress)) {
    return 'NullAddress'
  } else {
    return shortenAddress(address.value)
  }
})

const hasAvatarComputed = computed(() => hasAvatar.value || isLinkToDashboard.value)

const isCopiedOnLeftSide = computed(() => isCopiedInTooltip.value && tooltipPosition.value === 'left')
const isCopiedOnRightSide = computed(() => isCopiedInTooltip.value && tooltipPosition.value === 'right')

const userAvatarStyles = computed<Partial<CSSStyleDeclaration>>(() => {
  const styles: Partial<CSSStyleDeclaration> = {}
  if (customAvatarSize.value) {
    styles.width = customAvatarSize.value
    styles.height = customAvatarSize.value
  }
  if (!addressProfile.value?.name) {
    styles.marginBottom = '1%'
  }
  return styles
})

// @ts-expect-error FIXME: strictNullChecks
const explorer = computed(() => CHAINS_CONSTANTS[chainId.value]?.explorer)
const explorerName = computed(() => explorer.value?.blockExplorerName ? capitalizeFirstLetter(explorer.value.blockExplorerName) : undefined)
const ExplorerIcon = computed(() => explorer.value?.blockExplorerIcon)
const addressDetailsLink = computed(() => explorer.value?.addressDetailsLink?.(address.value))

const router = useRouter()
const goToUserDashboard = () => {
  router.push({
    name: RouteName.Dashboard,
    params: {
      user: address.value,
    },
  })
}

const handleCopy = async () => {
  await navigator.clipboard.writeText(address.value)
  isCopied.value = true
  setTimeout(() => {
    isCopied.value = false
  }, 2000)
}

const handleCopyInTooltip = async () => {
  await navigator.clipboard.writeText(address.value)
  isCopiedInTooltip.value = true
  setTimeout(() => {
    isCopiedInTooltip.value = false
  }, 2000)
}

const handleClickOnTrigger = () => {
  if (isLinkToDashboard.value) {
    goToUserDashboard()
  } else if (isCopyButton.value) {
    handleCopy()
  }
}
</script>

<style scoped>
.ens-name {
  &__text {
    font-family: var(--font-family-screener);
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text-container {
    display: contents;
  }

  &--link {
    text-decoration: underline;

    &:hover {
      color: var(--primary-color-1);
    }
  }

  &__trigger-copy-button {
    cursor: copy !important;
  }

  &--copied {
    color: var(--primary-color-1);
  }

  &__trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 100%;

    &:hover {
      color: var(--primary-color-1);
    }
  }

  &__tooltip-wrapper {
    font-family: var(--font-family-oxygen-mono);
  }

  &__address-in-tooltip {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: copy;
  }

  &__explorer-link-in-tooltip {
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;

    font-size: 1rem;
    font-family: var(--font-family-oxygen-mono);
  }

  &__copy-container {
    position: relative;
  }

  &__copy-icon {
    margin-left: 0.5rem;
    filter: grayscale(1);

    &--copied {
      filter: grayscale(0);
    }
  }

  &__copy-text {
    width: 7.5rem;
    position: absolute;
    margin: -1rem 0 0 0.5rem;
    transform-origin: left;
    animation: scale 2s ease-in both;
  }

  &__explorer-icon-in-tooltip {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media only screen and (max-width: 420px) {
    &__copy-text {
      width: 5rem;
    }
  }

  @keyframes scale {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    10% {
      transform: scale(1);
      opacity: 1;
    }

    90% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
}
</style>

<style>
.nouns-subdomain .ens-name__explorer-icon-in-tooltip {
  filter: invert(1);
}
</style>
