<template>
  <VueMultiSelect
    v-model="selectValueLocal"
    :options="options"
    :label="label"
    :track-by="trackBy"
    :placeholder="placeholder"
    :group-values="groupValues"
    :group-label="groupLabel"
    :show-labels="false"
    :close-on-select="closeOnSelect"
    :searchable="searchable"
    :open-direction="openDirection"
    deselect-label="remove selected"
    :class="['select', `select--border-${borderType}`]"
    @open="emit('onSelectOpen')"
    @close="emit('onSelectClose')">
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope">
      <slot
        :name="slot"
        v-bind="scope"/>
    </template>
    <template #noResult>
      No results
    </template>
    <template
      v-if="dropdownCaretType !== DropdownCaretType.Default"
      #caret>
      <span v-if="dropdownCaretType === DropdownCaretType.None"/>
    </template>
  </VueMultiSelect>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import VueMultiSelect from 'vue-multiselect/src/index.js'
import type { OptionItem } from '@/general-components/BaseSelectProperties'
import { SelectBorderType, DropdownCaretType } from '@/general-components/BaseSelectProperties'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'

// sometimes useful for debugging
// const select = ref()
// onMounted(() => {
//   select.value.isOpen = true
// })

type ModelValueType = OptionItem | number | string | PwnSafe
interface Props {
  modelValue?: ModelValueType
  placeholder?: string
  options: OptionItem[] | string[] | Record<string, unknown>[] | unknown
  label?: string
  trackBy?: string
  groupValues?: string
  groupLabel?: string
  searchable?: boolean
  closeOnSelect?: boolean
  borderType?: SelectBorderType
  openDirection?: 'top' | 'bottom'
  dropdownCaretType?: DropdownCaretType
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'select',
  searchable: true,
  closeOnSelect: true,
  borderType: SelectBorderType.Solid,
  openDirection: 'bottom',
  dropdownCaretType: DropdownCaretType.Default,
})

// TODO: add typescript for value and check is correct in all BaseSelect components
const emit = defineEmits<{(e: 'update:modelValue', value): void;
  (e: 'onSelectOpen'): void;
  (e: 'onSelectClose'): void;
}>()

const selectValueLocal = computed({
  get: (): ModelValueType | undefined => props.modelValue,
  set: (value: ModelValueType | undefined): void => emit('update:modelValue', value),
})
</script>

<style>
/* stylelint-disable no-descending-specificity */
.select {
  font-family: var(--font-family-oxygen-mono);

  &--border {
    &-solid {
      .multiselect__tags {
        border: 1px solid rgb(var(--text-color-base) / 50%);

        &:hover {
          border: 1px solid var(--text-color);
        }
      }
    }

    &-dashed {
      .multiselect__tags {
        background-image: var(--border-gray-dashed);

        &:hover,
        &:focus-within {
          background-image: var(--border-white-dashed);
        }
      }
    }

    &-none {
      border: none;
    }
  }

  &__icon {
    &::before,
    &::after {
      content: "";

      display: block;
      position: absolute;
      top: 50%;
      right: 20px;

      width: 1px;
      height: 12px;

      margin-top: 0;

      background-color: var(--text-color);
      border: none;
    }

    &::before {
      transform: rotate(-45deg) translateY(-50%);
    }

    &::after {
      transform: rotate(45deg) translateY(-50%);
    }
  }
}

.multiselect {
  height: var(--input-height);
  min-height: var(--input-height);

  /* dropdown caret */

  &__select {
    background: transparent !important;
    pointer-events: auto;

    &::before {
      position: absolute;
      right: 14px;
      top: 5px;
      height: 12px;
      width: 12px;
      transform: rotate(45deg);
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: var(--text-color);
      content: "";
    }

    &:hover ~ .multiselect__tags {
      border-color: var(--text-color);
    }
  }

  /* serves as an input, placeholder and tags container */

  &__tags {
    display: flex;
    align-items: center;
    border-radius: 0;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1.875rem 0 0.5rem;

    height: var(--input-height);
    min-height: var(--input-height);
  }

  /* background behind the input (must set all three) */

  &__tags,
  &__single,
  &__input {
    background-color: var(--background-color);
    color: var(--text-color);
  }

  &__input {
    padding-left: 0;

    /* placeholder visible after expanding the dropdown with options */

    &::placeholder {
      font-size: 14px; /* setting to same value as in '&__placeholder' below */
      color: var(--text-color);
    }
  }

  /* Placeholder visible when the dropdown is not expanded */

  &__placeholder {
    font-size: 14px; /* setting to same font-size as a .multiselect__input::placeholder above */
    color: var(--text-color);
  }

  /* Single label slot */
  &__single {
    padding-left: 0;
    padding-bottom: 1px;
    font-size: 14px;
  }

  &__content {
    min-width: auto;
    width: 100%;
    vertical-align: top;
    overflow-x: hidden;
  }

  /* wrapper for dropdown with options */

  &__content-wrapper {
    min-width: fit-content;
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
    background-color: var(--background-color);
    border: 1px solid var(--text-color) !important;
    border-radius: 0 !important;
    color: var(--text-color);
  }

  &__element {
    scroll-snap-align: start;
  }

  /* single option (item) inside the dropdown */

  &__option {
    display: flex;
    align-items: center;
    color: var(--text-color);
    min-height: auto;
    max-height: 40px;

    &--highlight:not(&--selected) {
      background-color: var(--background-color);
      cursor: pointer;
      color: var(--text-color);

      /* Section with "Press enter to select" */

      &::after {
        background: none;
      }
    }

    &:hover {
      background-color: var(--gray-3);
      cursor: pointer;
      color: var(--primary-color-1);

      /* Section with "Press enter to select" */

      &::after {
        background: none;
      }
    }

    &--selected {
      background-color: var(--primary-color-4);
    }

    &--selected&--highlight {
      background-color: var(--primary-color-4);

      &:hover {
        opacity: 0.7;
      }

      /* Section with "Press enter to remove" */

      &::after {
        background: none;
      }
    }

    &--group {
      max-height: 24px;
      font-size: 12px;
      cursor: default !important;
      background: transparent !important;
      color: var(--text-color) !important;
    }
  }
}

.select--border-dashed .multiselect__select:hover ~ .multiselect__tags {
  background-image: var(--border-white-dashed);
}

.multiselect--active .multiselect__placeholder {
  display: block;
}

</style>
