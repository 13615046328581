<template>
  <PwnSafeAssetsDashboard
    header-label="Current Wallet"
    :header-icon="walletIcon.default"
    :header-address="userAddress!"
    :search-term="searchTerm"
    :sorted-nfts="sortedNonAtrTokens"
    :sorted-coins="sortedErc20s"
    :sorted-atr-tokens="sortedAtrTokens"
    :is-fetching-nfts="nftsAreLoading"
    :is-fetching-coins="tokensAreLoading"
    :has-wallet-connect="false"
    :available-safes="selectedSafes"
    :handle-reload="handleRefreshCurrentWallet"
    @on-set-search-term="handleSetSearchTerms"/>
</template>

<script setup lang="ts">
import * as walletIcon from '@/assets/icons/wallet.svg'
import useYourNFTs from '@/modules/sections/your-assets/your-nfts/useYourNFTs'
import useYourCoins from '@/modules/sections/your-assets/your-coins/useYourCoins'
import { computed, onMounted, watch } from 'vue'
import PwnSafeAssetsDashboard from '@/modules/pages/pwn-safe/PwnSafeAssetsDashboard.vue'
import router from '@/router'
import RouteName from '@/router/RouteName'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import usePwnSafeNfts from '@/modules/pages/pwn-safe/pwn-safe-detail/safe-assets/usePwnSafeNfts'
import { storeToRefs } from 'pinia'
import usePwnSafeAssetsDashboard from '@/modules/pages/pwn-safe/usePwnSafeAssetsDashboard'
import { useCurrentWalletAssetsDetail } from '@/modules/queryHooks/safe/useCurrentWalletAssetsDetail'
import { queries } from '@/modules/queries'
import { useMutation, useQueries } from '@tanstack/vue-query'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { queryMutations } from '@/modules/mutations'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { walletAsSafe } = storeToRefs(pwnSafeDetailStore)

const { getFilteredAndSortedNfts } = usePwnSafeNfts()
const { getSortedAndFilteredCoins, selectedSortOption: selectedTokensSortOption } = useYourCoins()
const { selectedSortOption: selectedNftsSortOption } = useYourNFTs()
const { selectedSortOption: selectedAtrSortOption, getSortedAndFilteredATRs: getSortedAndFilteredATRNFTs } = usePwnSafeAssetsDashboard()
const { searchTerm } = usePwnSafeNfts()
const selectedChainsStore = useSelectedChainsStore()
const { selectedValues } = storeToRefs(selectedChainsStore)
const { address: userAddress } = useCustomAccount()

// @ts-expect-error FIXME: strictNullChecks
const { selectedChainsTokens, disabledChains, nonAtrTokens, atrTokens, tokensAreLoading, nftsAreLoading } = useCurrentWalletAssetsDetail(userAddress)

const sortedAtrTokens = computed(() => {
  const res = getSortedAndFilteredATRNFTs(
    atrTokens.value,
    searchTerm.value,
    selectedAtrSortOption.value,
  )
  return [...res]
})

const sortedNonAtrTokens = computed(() => {
  const res = getFilteredAndSortedNfts(
    nonAtrTokens.value,
    searchTerm.value,
    selectedNftsSortOption.value,
  )
  // have to create a new array to reset the computed state
  return [...res]
})

const sortedErc20s = computed(() => {
  return getSortedAndFilteredCoins(
    selectedChainsTokens.value.filter((coin) => !disabledChains.value.includes(coin.chainId)),
    searchTerm.value,
    selectedTokensSortOption.value,
  )
})

const selectedChainSafes = computed(() => {
  // @ts-expect-error FIXME: strictNullChecks
  return selectedValues.value.map((chain) => {
    // @ts-expect-error FIXME: strictNullChecks
    return queries.safe.list(userAddress, chain)
  })
})

const selectedChainSafesQuery = useQueries({
  queries: selectedChainSafes,
  combine: (results) => {
    const safes = results.reduce((acc, result) => {
      // @ts-expect-error FIXME: strictNullChecks
      return acc.concat(result.data)
    }, [])

    return {
      data: safes,
      isLoading: results.some(v => v.isLoading),
    }
  },
})

const selectedSafes = computed(() => {
  return selectedChainSafesQuery.value.data
})

const handleSetSearchTerms = (searchTermValue: string) => {
  searchTerm.value = searchTermValue
  useYourCoins().searchTerm.value = searchTermValue
}

onMounted(() => {
  searchTerm.value = ''
  useYourCoins().searchTerm.value = ''
  pwnSafeDetailStore.setSelectedPwnSafe(walletAsSafe.value)
})

watch(userAddress, () => {
  router.push({ name: RouteName.PwnSafe })
})

const mutations = queryMutations()

const refreshMutation = useMutation(
  mutations.safe.batchRefreshAssets,
)

const handleRefreshCurrentWallet = () => {
  refreshMutation.mutate({
    userAddress: userAddress.value,
    selectedChains: selectedValues.value,
    safesToRefetch: [],
  })
}

</script>
