<template>
  <div class="pwn-safe-settings-rename">
    <div class="pwn-safe-settings-rename__title-container">
      <div class="pwn-safe-settings-rename__title-icon">
        <WriteOnPaperIconSvg
          width="24"
          height="24"
          alt="rename icon"/>
      </div>
      <h1 class="pwn-safe-settings-rename__line-title">
        Rename
      </h1>
    </div>
    <div class="pwn-safe-settings-rename__line-description">
      You can change the name of your PWN Safe at any time, this action does not cost gas.
    </div>
    <div class="pwn-safe-settings-rename__line-title">
      Current Name
    </div>
    <div class="pwn-safe-settings-rename__line-description">
      {{ selectedPwnSafe?.name }}
    </div>
    <div class="pwn-safe-settings-rename__line-title">
      Change to
    </div>
    <BaseInput
      v-model="safeName"
      placeholder="Input PWN Safe name"
      :append-text="charCount"/>
    <BaseSkeletor v-if="isPending"/>
    <div class="pwn-safe-settings-rename__button-wrapper">
      <BaseButton
        :is-disabled="isDisabled"
        button-text="Change"
        @on-button-click="handleButtonClick"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import BaseInput from '@/general-components/BaseInput.vue'
import BaseButton from '@/general-components/BaseButton.vue'
import { computed, onUnmounted, ref } from 'vue'
import type { Ref } from 'vue'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import useCreateNewPwnSafe from '@/modules/pages/pwn-safe/create-new-pwn-safe/useCreateNewPwnSafe'
import WriteOnPaperIconSvg from '@/assets/icons/write-on-paper.svg'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { queries } from '@/modules/queries'
import PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import { compareAddresses } from '@/utils/utils'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import { pwnsafeSafeUpdate } from '@/modules/common/backend/generated'
import useAuth from '@/modules/common/backend/useAuth'
import { TOAST_ACTION_ID_TO_UNIQUE_ID_FN, Toast, ToastStep } from '@/modules/common/notifications/useToastsStore'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import { storeToRefs } from 'pinia'

const safeName = ref('')

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const { MAX_SAFE_NAME_LENGTH } = useCreateNewPwnSafe()

const { address: userAddress } = useCustomAccount()

const charCount = computed(() => `${safeName.value.length}/${MAX_SAFE_NAME_LENGTH}`)
const isDisabled = computed(() => !safeName.value || safeName.value.length > 40)

const queryClient = useQueryClient()

const { mutateAsync, isPending } = useMutation({
  mutationFn: async (pwnSafe: PwnSafe) => {
    const response = await useAuth().fetchRestrictedEndpoint(() => pwnsafeSafeUpdate(
      String(pwnSafe.chainId),
      pwnSafe.safeAddress,
      {
        name: safeName.value,
      },
    ))
    return PwnSafe.parseResponseFromBackendModel(response.data)
  },
  onSuccess(data, variables, context) {
    safeName.value = ''

    const safeNameUpdater = (safe: PwnSafe) => {
      if (compareAddresses(safe.safeAddress, selectedPwnSafe.value?.safeAddress)) {
        return data
      }
      return safe
    }

    selectedPwnSafe.value = data

    queryClient.setQueriesData(
      {
        // @ts-expect-error FIXME: strictNullChecks
        ...queries.safe.list(userAddress, selectedPwnSafe.value?.chainId),
      },
      // @ts-expect-error FIXME: strictNullChecks
      (oldData: PwnSafe[]): PwnSafe[] => {
        return oldData?.map(safeNameUpdater)
      },
    )

    queryClient.setQueryData(
      // @ts-expect-error FIXME: strictNullChecks
      queries.safe.detail(selectedPwnSafe.value?.safeAddress, selectedPwnSafe.value?.chainId).queryKey,
      (oldData: PwnSafe): PwnSafe => {
        return safeNameUpdater(oldData)
      },
    )
  },
})

const getToastSteps = async () => {
  const steps: ToastStep[] = []

  const needsLogin = await useAuth().checkIfUserNeedsToLogin()
  if (needsLogin) {
    steps.push(new ToastStep({
      text: 'Signing login message...',
      async fn(step) {
        await useAuth().loginAndCreateTokens()
        return true
      },
    }))
  }

  steps.push(new ToastStep({
    text: 'Renaming PWN Safe',
    async fn(step) {
      // @ts-expect-error FIXME: strictNullChecks
      await mutateAsync(selectedPwnSafe.value)
      return true
    },
  }))

  return steps
}

let continueFlow
const toast = ref<Toast>()

const handleButtonClick = async () => {
  // @ts-expect-error null checks
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_UPDATE_PWN_SAFE](selectedPwnSafe.value.chainId, selectedPwnSafe.value.safeAddress, safeName.value)

  if (toast.value?.id !== actionId) {
    toast.value = new Toast({
      title: 'Rename PWN safe',
      // @ts-expect-error FIXME: strictNullChecks
      chainId: selectedPwnSafe.value.chainId,
      steps: await getToastSteps(),
    }, NotificationFrontendOnlyActionEnum.TX_UPDATE_PWN_SAFE, selectedPwnSafe.value!.chainId, selectedPwnSafe.value?.safeAddress, safeName.value);
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}

onUnmounted(() => {
  safeName.value = ''
})
</script>

<style scoped>
.pwn-safe-settings-rename {
  border: 1px solid var(--text-color);
  padding: 1rem;

  &__title-container {
    display: flex;
  }

  &__title-icon {
    margin-right: 0.5rem;
  }

  &__line-title {
    font-family: var(--font-family-autoscape);
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  &__line-description {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 2rem;
  }

  &__button-wrapper {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
  }

  @media only screen and (--mobile-viewport) {
    &__line-title {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    &__line-description {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &__button-wrapper {
      padding-bottom: 4rem;
    }
  }
}
</style>
