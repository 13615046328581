/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.0.0
 */

export type MarketplaceEnumBackendSchema = typeof MarketplaceEnumBackendSchema[keyof typeof MarketplaceEnumBackendSchema];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MarketplaceEnumBackendSchema = {
  LOOKSRARE: 'LOOKSRARE',
  OPENSEA: 'OPENSEA',
} as const;
