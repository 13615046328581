<template>
  <div class="pwn-explorer">
    <div
      v-if="isPwnSpace"
      class="pwn-explorer__banner-img-container">
      <img
        class="pwn-explorer__banner-img"
        :height="BANNER_IMG_HEIGHT"
        :src="pwnSpaceConfig!.bannerUrl"
        :alt="`${pwnSpaceConfig!.pwnSpaceName} banner`">
    </div>
    <TheHero
      v-else/>
    <div class="pwn-explorer__controls">
      <NavSearchBar
        variant="explorer"
        :show-results="false"/>
      <ChainGroupFilter
        v-if="selectedTab !== RouteName.PwnExplorerPageLoans && selectedTab !== RouteName.LendExplorerPageLoans"
        :selected-chains="selectedChain === 'all' ? 'all' : [selectedChain]"
        :set-chain="setSelectedChain"/>
    </div>

    <section>
      <div class="pwn-explorer__table-container">
        <div class="pwn-explorer__tabs">
          <BaseButton
            v-for="button in buttons"
            :key="button.id"
            class="pwn-explorer__tab"
            :size="ButtonSize.XXL"
            :font="ButtonFont.Screener"
            :color="sectionIsActive(button.link) ? ButtonColor.Primary : ButtonColor.White"
            :variant="ButtonVariant.Outline"
            :button-text="button.text"
            :is-full-width="true"
            @click="() => router.push(getNavigationLink(button.link))"/>
        </div>

        <div class="pwn-explorer__content">
          <div class="pwn-explorer__tab-content">
            <router-view/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import NavSearchBar from '@/modules/sections/nav-searchbar/NavSearchBar.vue'
import BaseButton, { ButtonColor, ButtonFont, ButtonSize, ButtonVariant } from '@/general-components/BaseButton.vue'
import { computed, ref, watch } from 'vue'
import type { Ref } from 'vue'
import ChainGroupFilter from '@/general-components/ChainGroupFilter.vue'
import type { ChainFilterOptions, SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import { useSearchPwnExplorer } from '@/modules/common/pwn/explorer/useSearchStore'
import RouteName from '@/router/RouteName'
import { useUrlSearchParams } from '@vueuse/core'
import router from '@/router'
import type { RouteLocationNamedRaw } from 'vue-router'
import { useRoute } from 'vue-router'
import { pwnSpaceConfig, isPwnSpace } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import TheHero from '@/modules/pages/pwn-explorer/TheHero.vue'

const route = useRoute()
const store = useSearchPwnExplorer()
const BANNER_IMG_HEIGHT = 240

type Params = {
  q?: string
  chain?: ChainFilterOptions
}

const params = useUrlSearchParams<Params>('hash')
const selectedChain = ref<SetChainFilterOption>(Array.isArray(params.chain) ? Number(params.chain[0]) : Number(params.chain) || 'all')
const selectedTab = computed(() => router.currentRoute.value.name)

const setSelectedChain = (chain: SetChainFilterOption) => {
  selectedChain.value = chain
  store.actions.setSelectedChainId(chain === 'all' ? null : chain)
}

watch(route, (r) => {
  const searchTerm = Array.isArray(r.query.q) ? r.query.q[0] : r.query.q
  // @ts-expect-error FIXME: strictNullChecks
  store.actions.setSearchTerm(searchTerm)
  setSelectedChain(selectedChain.value)
}, {
  immediate: true,
})

const sectionIsActive = (link: RouteLocationNamedRaw) => router.currentRoute.value.name === link.name

const getNavigationLink = (link: RouteLocationNamedRaw) => {
  return {
    name: link.name,
    query: {
      q: store.searchTerm,
    },
  }
}

type Button = {
  id: string
  text: string
  link: RouteLocationNamedRaw
}
const buttons: Ref<Button[]> = computed(() => {
  const buttons = [
    {
      id: 'collections',
      text: 'NFTs',
      link: {
        name: RouteName.PwnExplorerPageCollections,
      },
    },
    {
      id: 'assets',
      text: 'Tokens',
      link: {
        name: RouteName.PwnExplorerPageAssets,
      },
    },
  ]
  const nftIndex = buttons.findIndex(button => button.id === 'collections')
  const erc20Index = buttons.findIndex(button => button.id === 'assets')
  if (isPwnSpace && pwnSpaceConfig?.isNftOnly) {
    buttons.splice(erc20Index, 1)
  } else if (isPwnSpace && pwnSpaceConfig?.isErc20Only) {
    buttons.splice(nftIndex, 1)
  }
  return buttons
})

</script>

<style scoped>
.pwn-explorer {
  margin-top: 5rem;

  &__container {
    margin-bottom: 5rem;
  }

  &__banner-img-container {
    margin-bottom: 3rem;
  }

  &__table-container {
    border: 1px solid #434343;
    padding: 1rem;
  }

  &__controls {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    margin-top: 3rem;
  }

  &__banner-img {
    /* corners */
    /* stylelint-disable-next-line max-line-length */
    clip-path: polygon(0 1.0625rem, 1.0625rem 0, calc(100% - 1.0625rem) 0, 100% 1.0625rem, 100% calc(100% - 1.0625rem), calc(100% - 1.0625rem) 100%, 1.0625rem 100%, 0 calc(100% - 1.0625rem));
    object-position: center;
    object-fit: cover;
    width: 100%;
  }

  &__title {
    text-align: center;
    font-size: 1.5rem;
    font-family: var(--font-family-autoscape);
    font-weight: 550;
    line-height: 2.5rem;
  }

  &__searchbar {
    max-width: 800px;
  }

  &__content {
    position: relative;
    margin-top: 1.5rem;
  }

  &__tabs {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 1.5rem;
  }

  &__tab {
    flex: 1;
    white-space: nowrap;
    width: 100%;
  }

  &__tab-content {
    overflow-x: auto;
  }

  @media only screen and (--small-viewport) {
    &__tabs {
      flex-flow: row wrap;
      justify-content: center;
    }

    &__tab {
      max-width: 100%;
    }
  }

  @media screen and (--mobile-viewport) {
    padding-bottom: 4rem;
    margin-top: 2rem;

    &__title {
      font-size: 1.25rem;
    }

    &__tabs {
      flex-flow: column nowrap;
    }
  }
}
</style>

<style>
.pwn-explorer__searchbar .search {
  padding: 0.75rem;
  box-sizing: content-box;
}

.pwn-explorer__searchbar .search .search__icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}
</style>
