import { DateTime } from 'luxon'
import { readonly, ref } from 'vue'

interface IReceivedAccessToken {
    readonly access: string
    readonly access_expiration: string // serialized datetime
  }

class AccessToken {
  constructor(
      public token?: string,
      public expiration?: DateTime,
  ) {}

  get isValid(): boolean {
    return !!this.token && !!this.expiration && this.expiration > DateTime.now()
  }

  // eslint-disable-next-line camelcase
  setAccessToken({ access, access_expiration }: IReceivedAccessToken): void {
    this.token = access
    this.expiration = DateTime.fromISO(access_expiration, { zone: 'utc' })
  }
}

const accessToken = ref(new AccessToken())

export function useAccessTokenStore() {
  const _createAccessToken = ({ access, access_expiration }: IReceivedAccessToken) => {
    return new AccessToken(access, DateTime.fromISO(access_expiration, { zone: 'utc' }))
  }

  const setAccessToken = ({ access, access_expiration }: IReceivedAccessToken) => {
    accessToken.value = _createAccessToken({ access, access_expiration })
  }

  return {
    accessToken: readonly(accessToken),
    setAccessToken,
  }
}
