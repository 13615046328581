import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { Address } from 'viem'
import type { SupportedChain } from '@/constants/chains/types'
import { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { compareAddresses } from '@/utils/utils'
import type { NFTAssetSchemaWithBalanceBackendSchema } from '@/modules/common/backend/generated'

export enum CustomAssetDescription {
  KyberSwap = 'KyberSwap',
}

export const getAssetCustomDescriptionKey = (asset: AssetMetadata | NFTAssetSchemaWithBalanceBackendSchema, chainId: SupportedChain): CustomAssetDescription | null => {
  const { kyberSwapElastic } = CHAINS_CONSTANTS[chainId]?.nftContractsAddresses || {}

  let assetAddress: Address
  if (asset instanceof AssetMetadata) {
    assetAddress = asset.isBundleWithSingleAsset ? asset.bundledAsset.address : asset.address
  } else if ('bundled_assets' in asset && asset.bundled_assets?.length === 1) {
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '`0x${stri... Remove this comment to see the full error message
    assetAddress = asset.bundled_assets[0].contract.address
  } else {
    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '`0x${stri... Remove this comment to see the full error message
    assetAddress = asset.contract.address
  }

  if (kyberSwapElastic && compareAddresses(assetAddress, kyberSwapElastic)) {
    return CustomAssetDescription.KyberSwap
  }

  return null
}
