<template>
  <BaseModal
    v-if="nativeToken"
    :is-open="isOpen"
    :size="ModalSize.Large"
    :custom-z-index="Number(modalZIndex)"
    :heading="heading"
    @update:is-open="handleIsOpenUpdate">
    <template
      v-if="hasTrigger"
      #trigger>
      <span
        v-if="isModalWithInput"
        :class="['link link--primary link--sm link--font-oxygen-mono']"
        @click="store.openModal(nativeToken)">Wrap</span>
      <BaseButton
        v-else
        :button-text="buttonText"
        :is-disabled="!amountIsValid"
        is-pulsing
        @on-button-click="store.openModal(nativeToken)"/>
    </template>
    <template #body>
      <p class="modal-wrap-native-token__text">
        Native assets cannot currently be used as collateral on PWN. Please wrap the asset first.
      </p>

      <div
        v-if="isModalWithInput"
        class="modal-wrap-native-token__input-container">
        <label class="modal-wrap-native-token__label-choose-amount"> Select the amount to wrap: </label>
        <BaseInput
          v-model="amountInput"
          button-text="max"
          class="modal-wrap-native-token__input"
          placeholder="0"
          :is-valid-input="isAssetAmountInputValidOrEmpty"
          :warning-text="warningTextForInput"
          :is-small-and-long-warning="isInsufficientAmountOfNativeToken"
          @button-clicked="handleInputMaxButtonClick"/>
      </div>

      <div v-else>
        <p class="modal-wrap-native-token__label-going-to-wrap">
          You're about to wrap:
        </p>
        <p class="modal-wrap-native-token__amount-to-wrap">
          {{ nativeToken.amountInput }} {{ nativeToken.symbol }}
        </p>
      </div>

      <BaseButton
        class="modal-wrap-native-token__button"
        is-pulsing
        is-full-width
        :is-disabled="isDisabledWrapButton"
        button-text="Wrap"
        @on-button-click="handleWrapNativeToken"/>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/general-components/BaseModal.vue'
import { useModalWrapNativeTokenStore } from '@/general-components/useModalWrapNativeTokenStore'
import ModalSize from '@/general-components/ModalSize'
import BaseButton from './BaseButton.vue'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { computed, ref, toRefs, watch } from 'vue'
import type { Ref } from 'vue'
import BaseInput from './BaseInput.vue'
import { useCssVar } from '@vueuse/core'
import { getChainName, getWagmiChain } from '@/utils/chain'
import { Toast, TOAST_ACTION_ID_TO_UNIQUE_ID_FN, ToastStep } from '@/modules/common/notifications/useToastsStore'
import NotificationFrontendOnlyActionEnum from '@/modules/common/notifications/NotificationAction'
import { useMutation } from '@tanstack/vue-query'
import { queryMutations } from '@/modules/mutations'
import type { SupportedChain } from '@/constants/chains/types'
import { compareAssets, getRawAmount } from '@/utils/utils'
import { formatUnits, maxUint256 } from 'viem'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

const store = useModalWrapNativeTokenStore()

interface Props {
  nativeToken: AssetWithAmount;
  buttonText?: string;
  isModalWithInput?: boolean;
  hasTrigger?: boolean;
}

const { address } = useCustomAccount()
const props = withDefaults(defineProps<Props>(), {
  buttonText: 'Wrap',
  hasTrigger: true,
})
const { isModalWithInput, nativeToken } = toRefs(props)

const amountInput = ref('')

watch(
  () => nativeToken.value.amountInput,
  (_new, _old) => {
    if (_new !== _old || !_old) {
      amountInput.value = _new
    }
  },
)

const isOpen = store.getIsOpen(props.nativeToken)

const emit = defineEmits<{(
    e: 'on-wrap-native-token',
    nativeAndWrappedNativeToken: {
      nativeToken: AssetWithAmount;
      wrappedNativeToken: AssetWithAmount;
    },
  ): void;
}>()

const amountInputRaw = computed(() => {
  return getRawAmount(amountInput.value, getWagmiChain(props.nativeToken.chainId).nativeCurrency.decimals)
})

const isInsufficientAmountOfNativeToken = computed(() => {
  return amountInputRaw.value >= props.nativeToken.maxAvailableAmountRaw
})

const amountIsValid = computed(() => {
  return amountInputRaw.value > 0n && !isInsufficientAmountOfNativeToken.value
})

const isAssetAmountInputValidOrEmpty = computed(() => {
  return amountIsValid.value || amountInput.value === ''
})

const formattedAmountInputRaw = computed(() => {
  if (amountIsValid.value) {
    return formatUnits(amountInputRaw.value, getWagmiChain(props.nativeToken.chainId).nativeCurrency.decimals)
  }
  return undefined
})

const handleIsOpenUpdate = (newIsOpen: boolean) => {
  newIsOpen ? store.openModal(props.nativeToken) : store.closeModal(props.nativeToken)
}

const tokenShouldBeWrapped = computed(() => compareAssets({
  assetA: nativeToken.value,
  assetB: AssetWithAmount.createEtherFiEETHAssetMetadata(),
}))
const mutations = queryMutations()

const fnToWrap = () => {
  if (tokenShouldBeWrapped.value) {
    return mutations.etherFi.wrap
  }
  return mutations.wrap
}

const { isPending: isWrapping, mutateAsync: wrapNativeToken } = useMutation({
  ...fnToWrap(),
  onSuccess(data, variables, context) {
    fnToWrap().onSuccess?.(data, variables, context)
    emit('on-wrap-native-token', data)
    store.closeModal(props.nativeToken)
  },
})

const toast = ref<Toast>()
let continueFlow

const { mutateAsync: checkOldApproval } = useMutation(mutations.checkOldApproval)
const { mutateAsync: setApproval } = useMutation(mutations.setApproval)

const isDisabledWrapButton = computed(() => isWrapping.value || !amountIsValid.value)
const hasSufficientApproval = ref(false)

const handleWrapNativeToken = async () => {
  const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationFrontendOnlyActionEnum.TX_WRAP_NATIVE_TOKEN](
    props.nativeToken.chainId,
    amountInputRaw.value,
  )

  const getToastSteps = () => {
    if (!tokenShouldBeWrapped.value) {
      return [
        new ToastStep({
          text: `Wrapping ${formattedAmountInputRaw.value} ${props.nativeToken.symbol}...`,
          async fn(step) {
            await wrapNativeToken({ nativeToken: props.nativeToken, amount: amountInputRaw.value, step })
            return true
          },
        }),
      ]
    } else {
      return [
        new ToastStep({
          text: `Checking previous approval on ${formattedAmountInputRaw.value} ${props.nativeToken.symbol}...`,
          async fn(step) {
            const hasValidApproval = await checkOldApproval({
              asset: props.nativeToken,
              amount: amountInputRaw.value,
              spender: AssetWithAmount.createEtherFiWETHAssetMetadata().address,
              owner: address.value!,
              step,
            })

            hasSufficientApproval.value = hasValidApproval

            return true
          },
        }),
        new ToastStep({
          text: `Approving ${formattedAmountInputRaw.value} ${props.nativeToken.symbol}...`,
          async fn(step) {
            if (!hasSufficientApproval.value) {
              await setApproval({
                asset: props.nativeToken,
                amount: maxUint256,
                spender: AssetWithAmount.createEtherFiWETHAssetMetadata().address,
                step,
              })
            }
            return true
          },
        }),
        new ToastStep({
          text: `Wrapping ${formattedAmountInputRaw.value} ${props.nativeToken.symbol}...`,
          async fn(step) {
            await wrapNativeToken({ nativeToken: props.nativeToken, amount: amountInputRaw.value, step })
            return true
          },
        }),
      ]
    }
  }

  const title = tokenShouldBeWrapped.value ? 'Wrap eETH to weETH' : 'Wrap native token'

  if (toast.value?.id !== actionId) {
    toast.value = new Toast(
      {
        steps: getToastSteps(),
        chainId: props.nativeToken.chainId as SupportedChain,
        title,
        firstAsset: props.nativeToken,
      },
      NotificationFrontendOnlyActionEnum.TX_WRAP_NATIVE_TOKEN,
      props.nativeToken.chainId,
      amountInputRaw.value,
    );
    ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
  }

  await continueFlow()
}

const handleInputMaxButtonClick = () => {
  amountInput.value = props.nativeToken.maxAvailableAmount
}

const heading = computed(() =>
  isModalWithInput.value
    ? `Wrap native ${props.nativeToken.symbol} on ${getChainName(props.nativeToken.chainId)}`
    : 'Native Asset Detected!',
)

const warningTextForInput = computed(() => {
  return isInsufficientAmountOfNativeToken.value
    ? 'Insufficient amount to for gas cost (choose a lower amount)'
    : 'Invalid Amount'
})

const modalZIndex = useCssVar('--z-index-native-token-wrap-modal')
</script>

<style scoped>
.modal-wrap-native-token {
  &__text {
    line-height: 1.5rem;
    max-width: 46.875rem;
    margin: 1.5rem auto 4rem;
    text-align: center;
  }

  &__label-choose-amount {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  &__label-going-to-wrap {
    margin: 4rem 0 0;
    text-align: center;
  }

  &__amount-to-wrap {
    color: var(--primary-color-1);
    text-align: center;
    font-family: var(--font-family-autoscape);
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 3rem;
  }

  &__button {
    margin: 0 auto 1rem;
    max-width: 20rem;
  }

  &__input-container {
    width: fit-content;
    margin: 0 auto 4rem;
  }
}
</style>

<style>
.modal-wrap-native-token__input {
  width: 14.5rem;
  margin-top: 1rem;
  position: relative;
}
</style>
