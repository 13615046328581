<template>
  <div>
    <BaseSkeletor v-if="isFetchingNotifications"/>
    <template
      v-for="notification in (sortedNotifications as Notification[])"
      :key="notification.id">
      <div
        class="notification-center-grid__wrap"
        :style="notification.isHighlighted ? 'border-left: 1px solid var(--primary-color-1)' : ''"
        @mouseover="onHover(notification)">
        <div class="notification-center-grid__header">
          <div>
            {{ notification.displayCreateDate.split('. ').join('.') }}
            {{ notification.displayCreateTime }}
          </div>
          <div>
            <NotificationTypeIcon
              class="notification-center-grid__notification-type-icon"
              :variant="notification.variant"/>
          </div>
        </div>

        <div class="notification-center-grid__loan-id">
          <div class="notification-center-grid__image">
            <TokenMedia
              v-if="!!notification.firstAsset"
              :token="notification.firstAsset"
              :width="imageSize(notification)"
              :height="imageSize(notification)"
              class="notification-center-grid__collateral-image"/>
            <TokenMedia
              v-if="!!notification.secondAsset"
              :token="notification.secondAsset"
              :width="imageSize(notification)"
              :height="imageSize(notification)"
              :class="['notification-center-grid__asset-image', { 'notification-center-grid__asset-image--composite': hasCompositeImage(notification) }]"/>
          </div>
          <!-- <div class="notification-center-grid__description">
            <div v-if="notification.firstAsset">
              <div>
                {{ notification.firstAsset.name }}
                {{ '#' + notification.firstAsset.id }}
              </div>
              <div>
                {{ notification.firstAsset.collectionName }}
                <img
                  v-if="notification.firstAsset.isVerified"
                  src="@/assets/icons/verified.svg"
                  alt="verified">
              </div>
            </div>
            <div v-if="notification.secondAsset">
              <div>
                {{ notification.secondAsset.name }}
                {{ '#' + notification.secondAsset.id }}
              </div>
              <div class="notification-center-grid__description-collection">
                {{ notification.secondAsset.collectionName }}
                <img
                  v-if="notification.secondAsset.isVerified"
                  src="@/assets/icons/verified.svg"
                  alt="verified">
              </div>
            </div>
          </div> -->
          <div class="notification-center-grid__notification-type">
            {{ notification.message }}
          </div>
        </div>

        <div
          v-if="notification?.loanOnChainId"
          :class="['notification-center-grid__loan-id-link']">
          <BaseLink
            :to="notification.loanDetailRoute"
            class="link--font-oxygen-mono link--primary">
            Loan: {{ '#' + notification.loanOnChainId }}
          </BaseLink>
        </div>
        <div
          v-else-if="notification?.proposalId"
          :class="['notification-center-grid__loan-id-link']">
          <BaseLink
            :to="notification.proposalDetailRoute"
            class="link--font-oxygen-mono link--primary">
            Proposal: {{ '#' + notification.proposalId }}
          </BaseLink>
        </div>

        <div v-if="notification.desiredAmount && notification.desiredDuration">
          {{ notification.desiredAmount }}
          {{ 'Duration: ' + notification.desiredDuration }}
        </div>

        <div class="notification-center-grid__footer">
          <ChainInfo
            v-if="notification?.chainId"
            :chain-id="notification.chainId"/>

          <BaseLink
            v-if="notification?.loanOnChainId"
            :to="notification.loanDetailRoute"
            class="link--font-oxygen-mono notification-center-grid__tx-detail">
            Go To Loan
          </BaseLink>
          <BaseLink
            v-else-if="notification?.proposalId"
            :to="notification.proposalDetailRoute"
            class="link--font-oxygen-mono notification-center-grid__tx-detail">
            Go To Proposal
          </BaseLink>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import BaseLink from '@/general-components/BaseLink.vue'
import NotificationTypeIcon from '@/general-components/NotificationTypeIcon.vue'
import useNotificationCenter from '@/modules/pages/notification-center/useNotificationCenter'
import TokenMedia from '@/general-components/TokenMedia.vue'
import type Notification from '@/modules/common/notifications/Notification'
import ChainInfo from '@/general-components/ChainInfo.vue'
import useNotifications from '@/modules/common/notifications/useNotifications'
import useNotificationsFetch from '@/modules/common/notifications/useNotificationsFetch'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'

const { sortedNotifications } = useNotificationCenter()
const { isFetchingNotifications } = useNotificationsFetch()

const hasCompositeImage = (notification: Notification) => notification?.firstAsset?.image && notification?.secondAsset?.image
const imageSize = (notification: Notification) => hasCompositeImage(notification) ? '48' : '72'

const onHover = async (notification: Notification) => {
  return !notification.hasSeen ? await useNotifications().markNotificationsAsSeen([notification.id!]) : null
}
</script>

<style scoped>
.notification-center-grid {
  &__wrap {
    display: flex;
    flex-direction: column;
    border: 1px solid #434343;
    padding: 1rem;
    gap: 1rem;
  }

  &--highlight {
    border-left: 1px solid var(--primary-color);
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__loan-id {
    display: flex;
    align-items: center;
  }

  &__notification-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: normal;
  }

  &__image {
    min-width: 4.5rem;
    height: 4.5rem;
    margin-right: 0.75rem;
    position: relative;
  }

  &__asset-image {
    position: absolute;

    object-fit: contain;
    object-position: center;

    &--composite {
      left: 1.5rem;
      top: 1.5rem;
    }
  }

  &__collateral-image {
    position: absolute;
    object-fit: contain;
    object-position: center;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__description-collection {
    font-size: 0.75rem;
  }
}
</style>
