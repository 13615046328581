import { computed, ref } from 'vue'
import BaseNotification from '@/general-components/notification/BaseNotification.vue'
import NotificationClose from '@/general-components/notification/NotificationClose.vue'
import { useToast } from 'vue-toastification'
import Notification from '@/modules/common/notifications/Notification'
import { NotificationTypeEnumBackendSchema, notificationsMarkAsSeen, notificationsMarkAsSeenAll } from '@/modules/common/backend/generated'
import type { ToastContent, ToastOptions } from 'vue-toastification/dist/types/types'
import useNotificationsFetch from '@/modules/common/notifications/useNotificationsFetch'
import type { PartialWithRequired } from '@/modules/common/typings/customTypes'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'

export type CreateNotificationOfGivenTypeParams = PartialWithRequired<Notification, 'action' | 'chainId'>

export const NOTIFICATIONS_IN_WALLET_MENU_AT_START = 20

const notifications = ref<Notification[]>([])
const notificationsInMenu = computed(() => {
  return notifications.value.slice(0, NOTIFICATIONS_IN_WALLET_MENU_AT_START)
})

const amountOfViewMoreNotifications = computed(() => {
  return notifications.value.length - NOTIFICATIONS_IN_WALLET_MENU_AT_START
})

const amountOfUnseenNotifications = ref(0)

export type NotificationId = string | number

export default function useNotifications() {
  const toast = useToast()
  const { resetNotificationsFetchOffset } = useNotificationsFetch()

  const markNotificationsAsSeen = async (notificationsIds: number[]): Promise<void> => {
    for (const notificationId of notificationsIds) {
      const notification = notifications.value.find(unseenNotification => unseenNotification.id === notificationId)
      if (notification) {
        notification.hasSeen = true
        if (amountOfUnseenNotifications.value > 0) {
          amountOfUnseenNotifications.value -= 1
        }
      }
    }

    const { address: userAddress } = useCustomAccount()
    await notificationsMarkAsSeen(userAddress.value!, { notifications: notificationsIds })
  }

  const markAllUnseenNotificationsAsSeen = async (): Promise<void> => {
    for (const notification of notifications.value) {
      notification.hasSeen = true
    }
    amountOfUnseenNotifications.value = 0

    const { address: userAddress } = useCustomAccount()
    await notificationsMarkAsSeenAll(userAddress.value!)
  }

  const notify = (notification: Notification): NotificationId => {
    const toastContent: ToastContent = {
      component: BaseNotification,
      props: {
        notification,
        markAsSeenOnHover: false,
      },
    }

    const toastOptions: ToastOptions = {
      type: notification.toastificationType,
      closeButton: NotificationClose,
      ...(notification.id && { onClose: () => markNotificationsAsSeen([notification.id!]) }),
    }

    return toast(toastContent, toastOptions)
  }

  const createPendingNotification = (notification: CreateNotificationOfGivenTypeParams): Notification => {
    return new Notification({
      created: new Date(),
      ...notification,
      variant: NotificationTypeEnumBackendSchema.pending,
    })
  }

  const displayPendingNotification = (pendingNotification: CreateNotificationOfGivenTypeParams): NotificationId => {
    return notify(createPendingNotification(pendingNotification))
  }

  const displayErrorNotification = (notification: CreateNotificationOfGivenTypeParams): NotificationId => {
    return notify(new Notification({
      created: new Date(),
      ...notification,
      variant: NotificationTypeEnumBackendSchema.error,
    }))
  }

  const displaySuccessNotification = (notification: CreateNotificationOfGivenTypeParams): NotificationId => {
    return notify(new Notification({
      created: new Date(),
      ...notification,
      variant: NotificationTypeEnumBackendSchema.success,
    }))
  }

  const dismissNotification = (id: NotificationId): void => toast.dismiss(id)

  const updateNotification = (notificationId: NotificationId, notification: Notification): NotificationId => {
    /* We can not use toast.update() function due to the fact that Vue does not register change of the property
        on the notification object (updated notification does not get rerendered).
        This means that we update by dismissing the old notification and creating a new one with updated info. */
    dismissNotification(notificationId)
    return notify(notification)
  }

  const clearAllUserNotifications = (): void => {
    resetNotificationsFetchOffset()
    notifications.value = []
  }

  const pushNotificationToStart = (notification: Notification): void => {
    if (notification.id === undefined || notifications.value.every(existingNotification => existingNotification.id !== notification.id)) {
      notifications.value.unshift(notification)
    }
  }

  const pushToNotificationsStore = (notification: Notification): void => {
    if (notifications.value.every(existingNotification => existingNotification.id !== notification.id)) {
      notifications.value.push(notification)
    }
  }

  return {
    notifications,
    pushNotificationToStart,
    clearAllUserNotifications,
    markNotificationsAsSeen,
    markAllUnseenNotificationsAsSeen,
    notify,
    createPendingNotification,
    displayPendingNotification,
    displayErrorNotification,
    displaySuccessNotification,
    updateNotification,
    dismissNotification,
    notificationsInMenu,
    amountOfUnseenNotifications,
    pushToNotificationsStore,
    amountOfViewMoreNotifications,
  }
}
