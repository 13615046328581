import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import type PwnSafe from '@/modules/common/pwn/safe/PwnSafe'
import type { QueryClient } from '@tanstack/vue-query'
import { useQueryClient } from '@tanstack/vue-query'
import type { MutationOptions } from '@tanstack/query-core'
import { compareAddresses, compareAssets, formatAmountWithDecimals } from '@/utils/utils'
import {
  subtractAssetAmounts,
  sumAssetAmounts,
  updateAndDecrementTokenizedAmount,
} from '@/modules/common/assets/priceAmountUpdater'
import { queries } from '@/modules/queries'
import type { SupportedChain } from '@/constants/chains/types'
import useDashboardCache from '@/modules/pages/dashboard/hooks/useDashboardCache'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import usePwnSafeClaimAndBurn from '@/modules/pages/pwn-safe/claim-and-burn/usePwnSafeClaimAndBurn'
import usePwnSafeClaimAndBurnModal, {
  PwnSafeClaimAndBurnModalStep,
} from '@/modules/pages/pwn-safe/claim-and-burn/modal/usePwnSafeClaimAndBurnModal'
import usePwnSafeTokenizeModalTokenizingWithTransfersStep, {
  CurrentAction,
} from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModalTokenizingWithTransfersStep'
import type { Address } from 'viem'
import { formatEther, getAddress, parseEther } from 'viem'
import { getAccount } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from './common/web3/usePwnWagmiConfig'
import type { ToastStep } from './common/notifications/useToastsStore'
import { useUserAssetsStore } from './common/assets/useUserAssets'
import { getNativeTokenWrapper } from '@/utils/chain'
import { sendTransaction } from './common/web3/useTransactions'
import { etherfiWETHAbi, wethAbi } from '@/contracts/abis'
import useTokenBundlerContract from './common/pwn/contracts/useTokenBundlerContract'
import useApprove from '@/modules/common/assets/useApprove'
import AssetType from '@/modules/common/assets/AssetType'

type RefreshAssetsMutationParams = {
  assetsToTransfer: AssetWithAmount[];
  fromSafe: PwnSafe;
  toSafe: PwnSafe;
};

type RefreshAssetsMutationContext = {
  toInternalWallet: boolean;
  fromCurrentWallet: boolean;
  toCurrentWallet: boolean;
  userAddress: Address;
};

const transferAssetsMutation = (
  queryClient: QueryClient,
): MutationOptions<AssetWithAmount[], null, RefreshAssetsMutationParams, RefreshAssetsMutationContext> => {
  return {
    onMutate: (variables): RefreshAssetsMutationContext => {
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      const fromSafe = variables.fromSafe
      const toSafe = variables.toSafe
      return {
        toInternalWallet:
          fromSafe.owners.some((owner) => compareAddresses(owner, userAddress)) ||
          compareAddresses(toSafe.safeAddress, userAddress),
        fromCurrentWallet: compareAddresses(fromSafe.safeAddress, userAddress),
        toCurrentWallet: compareAddresses(toSafe.safeAddress, userAddress),
        // @ts-expect-error TS(2322) FIXME: Type '`0x${string}` | undefined' is not assignable... Remove this comment to see the full error message
        userAddress,
      }
    },
    onSuccess(_, variables, context) {
      const updateData = (queryKey, updateFn) => {
        queryClient.setQueryData(queryKey, updateFn)
      }

      const subtractNftFn = (existing: AssetWithAmount[]) =>
        subtractAssetAmounts(
          existing,
          variables.assetsToTransfer.filter((asset) => asset.isNft),
        )
      const subtractErc20Fn = (existing: AssetWithAmount[]) =>
        subtractAssetAmounts(
          existing,
          variables.assetsToTransfer.filter((asset) => !asset.isNft),
        )

      const sumNftFn = (existing: AssetWithAmount[]) =>
        sumAssetAmounts(
          existing,
          variables.assetsToTransfer.filter((asset) => asset.isNft),
        )
      const sumErc20Fn = (existing: AssetWithAmount[]) =>
        sumAssetAmounts(
          existing,
          variables.assetsToTransfer.filter((asset) => !asset.isNft),
        )

      const fromSafeQueryKey = queries.safe.detail(variables.fromSafe.safeAddress, variables.toSafe.chainId!)
      const toSafeQueryKey = queries.safe.detail(variables.toSafe.safeAddress, variables.toSafe.chainId!)

      const userTokensQueryKey = queries.user.walletTokens(context.userAddress, variables.toSafe.chainId!)
      const userNftsQueryKey = queries.user.walletNfts(context.userAddress, variables.toSafe.chainId!)

      if (context.fromCurrentWallet) {
        updateData(userTokensQueryKey.queryKey, subtractErc20Fn)
        updateData(userNftsQueryKey.queryKey, subtractNftFn)
      }

      updateData(fromSafeQueryKey._ctx.nfts.queryKey, subtractNftFn)
      updateData(fromSafeQueryKey._ctx.tokens.queryKey, subtractErc20Fn)

      if (context.toInternalWallet) {
        if (context.toCurrentWallet) {
          updateData(userTokensQueryKey.queryKey, sumErc20Fn)
          updateData(userNftsQueryKey.queryKey, sumNftFn)
        } else {
          updateData(toSafeQueryKey._ctx.nfts.queryKey, sumNftFn)
          updateData(toSafeQueryKey._ctx.tokens.queryKey, sumErc20Fn)
        }
      }
    },
  }
}

export type TokenizeAssetsMutationParams = {
  assetsToTokenize: AssetWithAmount[];
  safe: PwnSafe;
  step: ToastStep;
};

const mintAtrTokensMutation = (
  queryClient: QueryClient,
): MutationOptions<AssetWithAmount[], null, TokenizeAssetsMutationParams, RefreshAssetsMutationContext> => {
  const { mintAtrTokenAndRefreshAssets, mintAtrTokenBatchAndRefreshAssets, successfullyTokenizedAssets } =
    usePwnSafeTokenize()
  const { currentAction } = usePwnSafeTokenizeModalTokenizingWithTransfersStep()

  return {
    mutationFn: async (variables) => {
      let mintedTokens = []

      currentAction.value = CurrentAction.ConfirmTokenize
      if (variables.assetsToTokenize.length === 1) {
        // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount' is not assignable to type '... Remove this comment to see the full error message
        mintedTokens = [await mintAtrTokenAndRefreshAssets(variables.safe, variables.assetsToTokenize[0], variables.step)]
      } else {
        // @ts-expect-error TS(2322) FIXME: Type 'AssetWithAmount[]' is not assignable to type... Remove this comment to see the full error message
        mintedTokens = await mintAtrTokenBatchAndRefreshAssets(variables.safe, variables.assetsToTokenize, variables.step)
      }

      successfullyTokenizedAssets.value = mintedTokens

      return mintedTokens
    },
    onSuccess: (mintedAtrs, variables, context) => {
      const updateData = (queryKey, updateFn) => {
        queryClient.setQueryData(queryKey, updateFn)
      }

      // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetWithAmount[] | undefined' i... Remove this comment to see the full error message
      const sumAtrToken = (existing: AssetWithAmount[] | undefined) => sumAssetAmounts(existing, mintedAtrs)

      const safeQueryKey = queries.safe.detail(variables.safe.safeAddress, variables.safe.chainId as SupportedChain)

      updateData(safeQueryKey._ctx.nfts.queryKey, sumAtrToken)
      // TODO do we also need to update somehow the tokenized amount?

      useDashboardCache().resetCacheState({
        userAddress: variables.safe.safeAddress,
        chainId: variables.safe.chainId as SupportedChain,
      })
    },
  }
}

type BatchRefreshAssetsMutationParams = {
  safesToRefetch: PwnSafe[];
  selectedChains?: readonly SupportedChain[];
  userAddress?: Address | undefined;
};

const batchRefreshAssetsMutation = (queryClient: QueryClient) => {
  const { resetCacheState } = useDashboardCache()

  const batchRefetch = async (safesToRefresh: PwnSafe[]) => {
    queryClient.refetchQueries({
      queryKey: queries.safe.list._def,
    })
    await Promise.allSettled(
      safesToRefresh.map(async (safe) => {
        await resetCacheState({
          userAddress: safe.safeAddress,
          // @ts-expect-error TS(2322) FIXME: Type 'SupportedChain | null' is not assignable to ... Remove this comment to see the full error message
          chainId: safe.chainId,
        })
        queryClient.refetchQueries(
          // @ts-expect-error TS(2345) FIXME: Argument of type 'SupportedChain | null' is not as... Remove this comment to see the full error message
          queries.safe.detail(safe.safeAddress, safe.chainId)._ctx.nfts,
        )
        queryClient.refetchQueries(
          // @ts-expect-error TS(2345) FIXME: Argument of type 'SupportedChain | null' is not as... Remove this comment to see the full error message
          queries.safe.detail(safe.safeAddress, safe.chainId)._ctx.tokens,
        )
      }),
    )
  }

  const updateCurrentSafeSelectedChains = async (userAddress: Address, selectedValues: readonly SupportedChain[]) => {
    await Promise.allSettled(
      selectedValues.map(async (chainId) => {
        await resetCacheState({
          userAddress,
          chainId,
        })
        queryClient.refetchQueries({
          ...queries.safe.detail(userAddress, chainId)._ctx.nfts,
          exact: false,
        })
        queryClient.refetchQueries({
          ...queries.safe.detail(userAddress, chainId)._ctx.tokens,
          exact: false,
        })
      }),
    )
  }

  return {
    mutationKey: ['batchRefreshAssets'],
    mutationFn: async (variables: BatchRefreshAssetsMutationParams) => {
      const { safesToRefetch, selectedChains, userAddress } = variables
      if (userAddress && selectedChains?.length) {
        await updateCurrentSafeSelectedChains(userAddress, selectedChains)
      }
      await batchRefetch(safesToRefetch)
    },
  }
}

type BurnOrClaimAtrTokensMutationParams = {
  assetsToTokenize: AssetWithAmount[];
  safe: PwnSafe;
  step?: ToastStep;
};

const burnOrClaimAtrTokensMutation = (
  queryClient: QueryClient,
): MutationOptions<
  boolean,
  null,
  BurnOrClaimAtrTokensMutationParams,
  Pick<RefreshAssetsMutationContext, 'userAddress' | 'fromCurrentWallet'>
> => {
  const { burnOrClaimAtrTokens } = usePwnSafeClaimAndBurn()
  const { isWaitingForTxConfirmation, activeStep } = usePwnSafeClaimAndBurnModal()

  return {
    mutationKey: ['burnOrClaimAtrTokens'],
    mutationFn: (variables) => burnOrClaimAtrTokens(variables.assetsToTokenize, variables.step),
    // @ts-expect-error TS(2322) FIXME: Type '(variables: BurnOrClaimAtrTokensMutationPara... Remove this comment to see the full error message
    onMutate(variables) {
      isWaitingForTxConfirmation.value = true
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      return {
        fromCurrentWallet: compareAddresses(variables.safe.safeAddress, userAddress),
        userAddress,
      }
    },
    onError(_, variables, context) {
      isWaitingForTxConfirmation.value = false
    },
    onSuccess(_, variables, context) {
      isWaitingForTxConfirmation.value = false
      activeStep.value = PwnSafeClaimAndBurnModalStep.Confirmation

      const underlyingAssets = variables.assetsToTokenize.map((asset) => asset.assetOfAtrToken)

      const underlyingNfts = underlyingAssets.filter((asset) => asset.isNft)
      const underlyingTokens = underlyingAssets.filter((asset) => !asset.isNft)

      const updateData = (queryKey, updateFn) => {
        queryClient.setQueryData(queryKey, updateFn)
      }

      const subtractAtrToken = (existing: AssetWithAmount[] | undefined) =>
        subtractAssetAmounts(existing ?? [], variables.assetsToTokenize)
      const sumUnderlyingAssets = (assets: AssetWithAmount[]) => (existing: AssetWithAmount[] | undefined) =>
        sumAssetAmounts(existing ?? [], assets)
      const updateTokenizedAmount = (assets: AssetWithAmount[]) => (existing: AssetWithAmount[] | undefined) =>
        updateAndDecrementTokenizedAmount(existing ?? [], variables.assetsToTokenize, assets)

      const fromSafeQueryKey = queries.safe.detail(variables.safe.safeAddress, variables.safe.chainId || undefined)

      if (context.fromCurrentWallet) {
        if (variables.safe.chainId) {
          const userTokensQueryKey = queries.user.walletTokens(context.userAddress, variables.safe.chainId)
          const userNftsQueryKey = queries.user.walletNfts(context.userAddress, variables.safe.chainId)

          updateData(userNftsQueryKey.queryKey, subtractAtrToken)

          updateData(userTokensQueryKey.queryKey, sumUnderlyingAssets(underlyingTokens))
          updateData(userNftsQueryKey.queryKey, sumUnderlyingAssets(underlyingNfts))
        }
      }

      updateData(fromSafeQueryKey._ctx.nfts.queryKey, subtractAtrToken)

      if (!context.fromCurrentWallet) {
        updateData(fromSafeQueryKey._ctx.nfts.queryKey, updateTokenizedAmount(underlyingNfts))
        updateData(fromSafeQueryKey._ctx.tokens.queryKey, updateTokenizedAmount(underlyingTokens))
      }
    },
  }
}

type WrapNativeTokenMutationReturnType = {
  nativeToken: AssetWithAmount;
  wrappedNativeToken: AssetWithAmount;
};

type WrapNativeTokenMutationParams = {
  nativeToken: AssetWithAmount;
  amount: bigint;
  step: ToastStep;
};

const wrapNativeTokenMutation = (): MutationOptions<
  WrapNativeTokenMutationReturnType,
  null,
  WrapNativeTokenMutationParams
> => {
  return {
    mutationKey: ['wrapNativeToken'],
    onSuccess({ nativeToken, wrappedNativeToken }, variables, context) {
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      if (!userAddress) {
        return
      }

      const userAssetsStore = useUserAssetsStore()
      userAssetsStore.updateTokenQueries([nativeToken], userAddress, nativeToken.chainId)
      userAssetsStore.updateTokenQueries([wrappedNativeToken], userAddress, nativeToken.chainId)
      useDashboardCache().resetCacheState({
        userAddress,
        chainId: nativeToken.chainId,
        target: 'userErc20s',
        isOnlyPurgeCache: true,
      })
    },
    mutationFn: async ({ nativeToken, amount, step }) => {
      const nativeTokenWrapper = getNativeTokenWrapper(nativeToken.chainId)

      await sendTransaction(
        {
          abi: wethAbi,
          functionName: 'deposit',
          args: [],
          value: amount,
          chainId: nativeToken.chainId,
          address: nativeTokenWrapper.address,
        },
        { step },
      )

      const updatedNativeToken = nativeToken.updateAssetAmounts({
        amount: formatAmountWithDecimals(nativeToken.amountRaw - amount, nativeToken.decimals),
      })

      const userAssetsStore = useUserAssetsStore()
      const wrappedNativeTokenInUserAssets = userAssetsStore.userErc20s.find((userErc20) =>
        compareAddresses(userErc20.address, nativeTokenWrapper.address),
      )

      let wrappedNativeToken: AssetWithAmount
      if (!wrappedNativeTokenInUserAssets) {
        wrappedNativeToken = new AssetWithAmount({
          ...nativeTokenWrapper,
          amount: formatAmountWithDecimals(amount, nativeToken.decimals),
          amountInputRaw: amount,
          chainId: nativeToken.chainId,
          name: 'Wrapped ' + nativeToken.name,
          category: AssetType.ERC20,
        })
      } else {
        wrappedNativeToken = wrappedNativeTokenInUserAssets.updateAssetAmounts({
          amount: formatAmountWithDecimals(wrappedNativeTokenInUserAssets.amountRaw + amount, nativeToken.decimals),
        })
      }

      return { nativeToken: updatedNativeToken, wrappedNativeToken }
    },
  }
}

const wrapEtherFiETHMutation = (): MutationOptions<
  WrapNativeTokenMutationReturnType,
  null,
  WrapNativeTokenMutationParams
> => {
  return {
    mutationKey: ['wrapEtherFiETH'],
    onSuccess({ nativeToken, wrappedNativeToken }, variables, context) {
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      if (!userAddress) {
        return
      }

      const userAssetsStore = useUserAssetsStore()

      let existingWWETH = userAssetsStore.userErc20s.find((userErc20) =>
        compareAddresses(userErc20.address, wrappedNativeToken.address),
      )
      let existingEETH = userAssetsStore.userErc20s.find((userErc20) =>
        compareAddresses(userErc20.address, nativeToken.address),
      )

      if (!existingWWETH) {
        existingWWETH = wrappedNativeToken
      } else {
        existingWWETH = existingWWETH.updateAssetAmounts({
          amount: formatAmountWithDecimals(existingWWETH.amountRaw + variables.amount, existingWWETH.decimals),
        })
      }

      if (!existingEETH) {
        existingEETH = nativeToken
      } else {
        existingEETH = existingEETH.updateAssetAmounts({
          amount: formatAmountWithDecimals(existingEETH.amountRaw - variables.amount, existingEETH.decimals),
        })
      }

      userAssetsStore.updateTokenQueries([existingEETH], userAddress, nativeToken.chainId)
      userAssetsStore.updateTokenQueries([existingWWETH], userAddress, nativeToken.chainId)
      useDashboardCache().resetCacheState({
        userAddress,
        chainId: nativeToken.chainId,
        target: 'userErc20s',
        isOnlyPurgeCache: true,
      })
    },
    mutationFn: async ({ amount, step, nativeToken: { chainId } }) => {
      const res = await sendTransaction(
        {
          address: AssetWithAmount.createEtherFiWETHAssetMetadata().address,
          abi: etherfiWETHAbi,
          functionName: 'wrap',
          args: [amount],
          chainId,
        },
        { step },
      )
      if (!res) {
        throw Error('Could not wrap eETH')
      }

      const eeth = AssetWithAmount.createEtherFiEETHAssetMetadata()

      const weth = AssetWithAmount.createEtherFiWETHAssetMetadata()

      return {
        nativeToken: new AssetWithAmount({
          ...eeth,
        }),
        wrappedNativeToken: new AssetWithAmount({
          ...weth,
          amount: formatAmountWithDecimals(amount, weth.decimals),
        }),
      }
    },
  }
}

type UnwrapNativeTokenMutationReturnType = {
  nativeToken: AssetWithAmount;
  wrappedNativeToken: AssetWithAmount;
};

type UnwrapNativeTokenMutationParams = {
  wrappedNativeToken: AssetWithAmount;
  amount: bigint;
  step: ToastStep;
};

const unwrapNativeTokenMutation = (): MutationOptions<
  UnwrapNativeTokenMutationReturnType,
  null,
  UnwrapNativeTokenMutationParams
> => {
  return {
    mutationKey: ['unwrapNativeToken'],
    onSuccess({ nativeToken, wrappedNativeToken }, variables, context) {
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      if (!userAddress) {
        return
      }

      const userAssetsStore = useUserAssetsStore()
      userAssetsStore.updateTokenQueries([wrappedNativeToken], userAddress, wrappedNativeToken.chainId)
      userAssetsStore.updateTokenQueries([nativeToken], userAddress, wrappedNativeToken.chainId)
      useDashboardCache().resetCacheState({
        userAddress,
        chainId: nativeToken.chainId,
        target: 'userErc20s',
        isOnlyPurgeCache: true,
      })
    },
    mutationFn: async ({ wrappedNativeToken, amount, step }) => {
      await sendTransaction(
        {
          abi: wethAbi,
          functionName: 'withdraw',
          args: [amount],
          chainId: wrappedNativeToken.chainId,
          address: wrappedNativeToken.address,
        },
        { step },
      )

      const updatedWrappedNativeToken = wrappedNativeToken.updateAssetAmounts({
        amount: formatAmountWithDecimals(wrappedNativeToken.amountRaw - amount, wrappedNativeToken.decimals),
      })

      const userAssetsStore = useUserAssetsStore()
      const nativeTokenInUserAssets = userAssetsStore.userErc20s.find(
        (userErc20) => userErc20.isNativeToken && userErc20.chainId === wrappedNativeToken.chainId,
      )

      let nativeToken: AssetWithAmount
      if (!nativeTokenInUserAssets) {
        // eslint-disable-next-line no-console
        console.warn('Native token not found')
        nativeToken = AssetWithAmount.createNativeTokenAssetWithAmount(updatedWrappedNativeToken.chainId, amount)
        nativeToken.amountInput = nativeToken.amount
      } else {
        nativeToken = nativeTokenInUserAssets.updateAssetAmounts({
          amount: formatAmountWithDecimals(
            nativeTokenInUserAssets.amountRaw + amount,
            nativeTokenInUserAssets.decimals,
          ),
        })
      }

      return { nativeToken, wrappedNativeToken: updatedWrappedNativeToken }
    },
  }
}

type BundleAssetsMutationParams = {
  assets: AssetWithAmount[];
  step: ToastStep;
};

export type UnbundleAssetsMutationParams = {
  asset: AssetWithAmount;
  step: ToastStep;
};

const bundleAssetsMutation = (): MutationOptions<AssetWithAmount, null, BundleAssetsMutationParams> => {
  return {
    mutationKey: ['bundleAssets'],
    mutationFn: async (variables) => {
      return await useTokenBundlerContract().createBundle(variables.assets, variables.step) as AssetWithAmount
    },
    onSuccess(createdBundle, variables, context) {
      const userAssetsStore = useUserAssetsStore()
      const { address: userAddress } = getAccount(pwnWagmiConfig)

      const assetsToUpdate: AssetWithAmount[] = []

      for (const asset of createdBundle.bundleAssets) {
        const posInErc20s = userAssetsStore.userErc20s.findIndex((userErc20) =>
          compareAssets({
            assetA: userErc20,
            assetB: asset,
          }),
        )
        if (posInErc20s !== -1) {
          let existingAsset = userAssetsStore.userErc20s[posInErc20s]
          existingAsset = asset.updateAssetAmounts({
            amount: formatAmountWithDecimals(existingAsset.amountRaw - asset.amountRaw, existingAsset.decimals),
          })
          assetsToUpdate.push(existingAsset)
          continue
        }

        const posInNfts = userAssetsStore.userNfts.findIndex((userNft) =>
          compareAssets({
            assetA: userNft,
            assetB: asset,
          }),
        )
        if (posInNfts !== -1) {
          let existingAsset = userAssetsStore.userNfts[posInNfts]
          existingAsset = asset.updateAssetAmounts({
            amount: formatAmountWithDecimals(existingAsset.amountRaw - asset.amountRaw, existingAsset.decimals),
          })
          assetsToUpdate.push(existingAsset)
          continue
        }
      }

      userAssetsStore.batchUpdateAssets([...assetsToUpdate, createdBundle], userAddress!, createdBundle.chainId)
      useDashboardCache().resetCacheState({
        userAddress: userAddress!,
        chainId: createdBundle.chainId,
      })
    },
  }
}

const unbundleAssetsMutation = (): MutationOptions<AssetWithAmount[], null, UnbundleAssetsMutationParams> => {
  return {
    mutationKey: ['unbundleAssets'],
    mutationFn: async (variables) => {
      return await useTokenBundlerContract().unbundle(variables.asset, variables.step)
    },
    async onSuccess(unbundledAssets, variables, context) {
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      const userAssetsStore = useUserAssetsStore()

      const assetsToUpdate: AssetWithAmount[] = []

      for (const asset of unbundledAssets) {
        const posInErc20s = userAssetsStore.userErc20s.findIndex((userErc20) =>
          compareAssets({
            assetA: userErc20,
            assetB: asset,
          }),
        )
        if (posInErc20s !== -1) {
          let existingAsset = userAssetsStore.userErc20s[posInErc20s]
          existingAsset = asset.updateAssetAmounts({
            amount: formatAmountWithDecimals(existingAsset.amountRaw + asset.amountRaw, existingAsset.decimals),
          })
          assetsToUpdate.push(existingAsset)
          continue
        }

        const posInNfts = userAssetsStore.userNfts.findIndex((userNft) =>
          compareAssets({
            assetA: userNft,
            assetB: asset,
          }),
        )
        if (posInNfts !== -1) {
          let existingAsset = userAssetsStore.userNfts[posInNfts]
          existingAsset = asset.updateAssetAmounts({
            amount: formatAmountWithDecimals(existingAsset.amountRaw + asset.amountRaw, existingAsset.decimals),
          })
          assetsToUpdate.push(existingAsset)
          continue
        }

        if (posInErc20s === -1 && posInNfts === -1) {
          assetsToUpdate.push(asset)
        }
      }

      userAssetsStore.removeUserNft(variables.asset, userAddress!, variables.asset.chainId)
      userAssetsStore.batchUpdateAssets(assetsToUpdate, userAddress!, variables.asset.chainId)
      useDashboardCache().resetCacheState({
        userAddress: userAddress!,
        chainId: variables.asset.chainId,
      })
    },
  }
}

type SetERC20SpendingApprovalMutationParams = {
  asset: AssetWithAmount;
  amount: bigint;
  spender: Address;
  step: ToastStep;
};

const setERC20SpendingApprovalMutation = (): MutationOptions<boolean, null, SetERC20SpendingApprovalMutationParams> => {
  return {
    mutationKey: ['setERC20SpendingApproval'],
    mutationFn: async ({ asset, amount, spender, step }) => {
      const res = await useApprove().approve(
        {
          asset,
          amount,
          spender,
        },
        step,
      )

      if (!res) {
        throw new Error('Could not approve the spending')
      }
      return res
    },
  }
}

type CheckAndRemovePreviousApprovalMutationParams = SetERC20SpendingApprovalMutationParams & {
  owner: Address;
};

const checkPreviousApprovalMutation = (): MutationOptions<
  boolean,
  null,
  CheckAndRemovePreviousApprovalMutationParams
> => {
  return {
    mutationKey: ['checkAndRemovePreviousApproval'],
    mutationFn: async ({ asset, spender, step, owner, amount }) => {
      const previousAllowance = await useApprove().getPreviousApprovalAmount(
        asset.chainId,
        asset.address,
        asset.category,
        spender,
        owner,
      )

      return !!(previousAllowance && previousAllowance >= amount)
    },
  }
}

const unwrapEtherFiWETHMutation = (): MutationOptions<
  UnwrapNativeTokenMutationReturnType,
  null,
  UnwrapNativeTokenMutationParams
> => {
  return {
    mutationKey: ['unwrapEtherFiETH'],
    onSuccess({ nativeToken, wrappedNativeToken }, variables, context) {
      const { address: userAddress } = getAccount(pwnWagmiConfig)
      if (!userAddress) {
        return
      }

      const userAssetsStore = useUserAssetsStore()

      let existingWETH = userAssetsStore.userErc20s.find((userErc20) =>
        compareAddresses(userErc20.address, wrappedNativeToken.address),
      )
      let existingEETH = userAssetsStore.userErc20s.find((userErc20) =>
        compareAddresses(userErc20.address, nativeToken.address),
      )

      if (!existingWETH) {
        existingWETH = wrappedNativeToken
      } else {
        existingWETH = existingWETH.updateAssetAmounts({
          amount: formatAmountWithDecimals(existingWETH.amountRaw - variables.amount, existingWETH.decimals),
        })
      }

      if (!existingEETH) {
        existingEETH = nativeToken
      } else {
        existingEETH = existingEETH.updateAssetAmounts({
          amount: formatAmountWithDecimals(existingEETH.amountRaw + variables.amount, existingEETH.decimals),
        })
      }

      userAssetsStore.updateTokenQueries([existingEETH], userAddress, nativeToken.chainId)
      userAssetsStore.updateTokenQueries([existingWETH], userAddress, nativeToken.chainId)
      useDashboardCache().resetCacheState({
        userAddress,
        chainId: nativeToken.chainId,
        target: 'userErc20s',
        isOnlyPurgeCache: true,
      })
    },
    mutationFn: async ({ amount, step, wrappedNativeToken: { chainId, address, amount: exitingAmount } }) => {
      const res = await sendTransaction(
        {
          address: getAddress(address),
          abi: etherfiWETHAbi,
          functionName: 'unwrap',
          args: [amount],
          chainId,
        },
        { step },
      )

      if (!res) {
        throw new Error('Could not unwrap the wETH')
      }

      return {
        nativeToken: new AssetWithAmount({
          ...AssetWithAmount.createEtherFiEETHAssetMetadata(),
          amount: formatEther(amount),
        }),
        wrappedNativeToken: new AssetWithAmount({
          ...AssetWithAmount.createEtherFiWETHAssetMetadata(),
          amount: formatEther(parseEther(exitingAmount) - amount),
        }),
      }
    },
  }
}

/**
 * @description Must be used in the context of a component
 */
// Types are correctly inferred here
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const queryMutations = () => {
  const queryClient = useQueryClient()

  return {
    safe: {
      transferAssets: transferAssetsMutation(queryClient),
      batchRefreshAssets: batchRefreshAssetsMutation(queryClient),
      mintAtrTokens: mintAtrTokensMutation(queryClient),
      burnOrClaimAtrTokens: burnOrClaimAtrTokensMutation(queryClient),
    },
    setApproval: setERC20SpendingApprovalMutation(),
    checkOldApproval: checkPreviousApprovalMutation(),
    wrap: wrapNativeTokenMutation(),
    unwrap: unwrapNativeTokenMutation(),
    etherFi: {
      wrap: wrapEtherFiETHMutation(),
      unwrap: unwrapEtherFiWETHMutation(),
    },
    tokenBundler: {
      bundle: bundleAssetsMutation(),
      unbundle: unbundleAssetsMutation(),
    },
  }
}
