import BaseLoanContract from '@/modules/common/pwn/contracts/BaseLoanContract'
import type { V1SimpleLoan } from '@/modules/common/pwn/loans/LoanClasses'
import { sendTransaction } from '@/modules/common/web3/useTransactions'
import type { ChainWithDeprecatedV1Contracts } from '@/constants/chains/types'
import { SupportedChain } from '@/constants/chains/types'
import ETHEREUM_CONSTANTS from '@/constants/chains/ethereum'
import POLYGON_CONSTANTS from '@/constants/chains/polygon'
// import CRONOS_CONSTANTS from '@/constants/chains/cronos'
import { compareAddresses } from '@/utils/utils'
// import type { UnixTimestamp } from '@/modules/common/typings/customTypes'
// import BASE_CONSTANTS from '@/constants/chains/base'
// import OPTIMISM_CONSTANTS from '@/constants/chains/optimism'
// import ARBITRUM_ONE_CONSTANTS from '@/constants/chains/arbitrum-one'
// import BSC_CONSTANTS from '@/constants/chains/bsc'
// import SEPOLIA_CONSTANTS from '@/constants/chains/sepolia'
import type { Address, TransactionReceipt } from 'viem'
import { pwnV1_2SimpleLoanAbi } from '@/contracts/generated'
import type { UnixTimestamp } from '@/modules/common/typings/customTypes'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import type { ToastStep } from '@/modules/common/notifications/useToastsStore'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { starknetRepayLoan } from '@/revamp/hooks/thesis/starknet/proposals'

export default class V1_2SimpleLoanContract extends BaseLoanContract<V1SimpleLoan> {
  public getChainIdToPwnVaultAddressMapping(simpleOfferContractAddress: Address): Record<SupportedChain, Address> | Record<ChainWithDeprecatedV1Contracts, Address> {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (compareAddresses(simpleOfferContractAddress, ETHEREUM_CONSTANTS.deprecatedPwnV1Contracts.pwnSimpleLoanSimpleOffer)) {
      return {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        [SupportedChain.Ethereum]: ETHEREUM_CONSTANTS.deprecatedPwnV1Contracts.pwnSimpleLoan,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        [SupportedChain.Polygon]: POLYGON_CONSTANTS.deprecatedPwnV1Contracts.pwnSimpleLoan,
      }
    } else {
      return {
        [SupportedChain.Ethereum]: ETHEREUM_CONSTANTS.pwnV1_2Contracts.pwnSimpleLoan,
        [SupportedChain.Polygon]: POLYGON_CONSTANTS.pwnV1_2Contracts.pwnSimpleLoan,
        // [SupportedChain.Sepolia]: SEPOLIA_CONSTANTS.pwnV1_2Contracts.pwnSimpleLoan,
        // [SupportedChain.Cronos]: CRONOS_CONSTANTS.pwnV1_2Contracts.pwnSimpleLoan,
        // [SupportedChain.Base]: BASE_CONSTANTS.pwnV1_2Contracts.pwnSimpleLoan,
        // [SupportedChain.Optimism]: OPTIMISM_CONSTANTS.pwnV1_2Contracts.pwnSimpleLoan,
        // [SupportedChain.Arbitrum]: ARBITRUM_ONE_CONSTANTS.pwnV1_2Contracts.pwnSimpleLoan,
        // [SupportedChain.Bsc]: BSC_CONSTANTS.pwnV1_2Contracts.pwnSimpleLoan,
      }
    }
  }

  public async payBack({ onChainId, chainId, step }: {chainId: SupportedChain, onChainId: string, step?: ToastStep}): Promise<TransactionReceipt> {
    if (isStarknet) {
      const tx = await starknetRepayLoan(BigInt(onChainId))
      if (step) {
        step.txHash = tx.transaction_hash as `0x${string}`
      }
      // @ts-expect-error types mismatch here but we don't care
      return tx
    }

    return await sendTransaction({
      abi: pwnV1_2SimpleLoanAbi,
      functionName: 'repayLOAN',
      args: [BigInt(onChainId), '0x'],
      address: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan || '0x',
      chainId,
    }, { step })
  }

  public async claimLoan({ onChainId, chainId }: {chainId: SupportedChain, onChainId: string}): Promise<TransactionReceipt> {
    return await sendTransaction({
      abi: pwnV1_2SimpleLoanAbi,
      functionName: 'claimLOAN',
      args: [BigInt(onChainId)],
      address: CHAINS_CONSTANTS[chainId]?.pwnV1_2Contracts?.pwnSimpleLoan || '0x',
      chainId,
    })
  }

  public async extendLoanExpirationDate(loan: V1SimpleLoan, newExpirationTimestampInSeconds: UnixTimestamp): Promise<TransactionReceipt> {
    return undefined as any
    // return await sendTransaction({
    //   abi: pwnV1_2SimpleLoanAbi,
    //   chainId: loan.chainId,
    //   args: [BigInt(loan.onChainId), newExpirationTimestampInSeconds],
    //   address: loan.contractAddress,
    //   functionName: 'extendLOAN',
    // })
  }
}
