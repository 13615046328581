import { DisplayTypeEnumBackendSchema } from '@/modules/common/backend/generated'
import type { TraitSchemaBackendSchema } from '@/modules/common/backend/generated'

export class StringTrait {
  constructor(
    public title: string,
    public value: string,
    public rarityInPercentage?: string, // how many NFTs from the collection has this trait
  ) {}
}

export class NumericTrait {
  constructor(
    public title: string,
    public value: number,
    public maxValue?: number,
  ) {}
}

export default class AssetTraits {
  stringTraits: Array<StringTrait>
  numericTraits: Array<NumericTrait>

  constructor() {
    this.stringTraits = []
    this.numericTraits = []
  }

  static createFromBackendResponse(traits: TraitSchemaBackendSchema[]): AssetTraits {
    const assetTraits = new AssetTraits()
    if (!traits) {
      return assetTraits
    }

    for (const trait of traits) {
      if (trait.trait_type.display_type === DisplayTypeEnumBackendSchema.NUMBER || trait.trait_type.max_value) {
        assetTraits.numericTraits.push(new NumericTrait(trait.trait_type.trait_type, Number(trait.value), trait.trait_type.max_value ?? undefined))
      } else {
        // todo resolve rarity_in_percentage format
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        assetTraits.stringTraits.push(new StringTrait(trait.trait_type.trait_type, trait.value, trait.rarity_in_percentage ? String(trait.rarity_in_percentage) : null))
      }
    }
    return assetTraits
  }

  static isStringTrait(trait: StringTrait | NumericTrait): trait is StringTrait {
    return trait instanceof StringTrait
  }

  get allTraits(): Array<StringTrait | NumericTrait> {
    return [...(this.stringTraits ?? []), ...(this.numericTraits ?? [])]
  }
}
