import type { ChainConstants } from '@/constants/chains/types'
import { SupportedChain, TopTokens } from '@/constants/chains/types'
import * as unichainImage from '@/assets/icons/unichain.svg'
import * as openSeaIcon from '@/assets/icons/opensea.svg'
import { pwnV1_2Contracts } from '@/constants/chains/pwnV1_2Contracts'
import type { Address } from 'viem'

const BLOCK_EXPLORER_URL = 'https://sepolia.uniscan.xyz'

const UNICHAIN_SEPOLIA_CONSTANTS: ChainConstants = {
  general: {
    chainId: SupportedChain.UnichainSepolia,
    chainName: 'Unichain Sepolia',
  },
  nodeProvider: {
    httpNodeUrl: 'https://unichain-sepolia.g.alchemy.com/v2/',
    wsNodeUrl: `wss://unichain-sepolia.g.alchemy.com/v2/${import.meta.env.VITE_UNICHAIN_SEPOLIA_ALCHEMY_NODE_KEY}`,
    bearerAuthToken: import.meta.env.VITE_UNICHAIN_SEPOLIA_ALCHEMY_NODE_KEY as string,
  },
  explorer: {
    contractDetailsLink: (tokenAddress: Address) => `${BLOCK_EXPLORER_URL}/token/${tokenAddress}`,
    blockExplorerName: 'uniscan',
    blockExplorerIcon: unichainImage.default,
    nftDetailsLink: (tokenAddress, tokenId) => `https://testnets.opensea.io/assets/unichain-sepolia /${tokenAddress}/${tokenId}`,
    nftExplorerName: 'opensea',
    nftExplorerIcon: openSeaIcon.default,
    transactionDetailsLink: (transactionHash: string) => `${BLOCK_EXPLORER_URL}/tx/${transactionHash}`,
    addressDetailsLink: (address: Address) => `${BLOCK_EXPLORER_URL}/address/${address}`,
    nftOpenseaCollectionLink: (slug: string) => `https://testnets.opensea.io/collection/${slug}`,
  },
  // completely irrelevant
  pwnV1Contracts: {
    pwnSimpleLoan: '0x4188C513fd94B0458715287570c832d9560bc08a',
    pwnSimpleLoanSimpleOffer: '0x5E551f09b8d1353075A1FF3B484Ee688aCAc02F6',
    pwnSimpleLoanSimpleRequest: '0x9Cb87eC6448299aBc326F32d60E191Ef32Ab225D',
    pwnSimpleLoanListOffer: '0xDA027058708961Be3676daEB68Fde1758B210065',
    pwnLoanToken: '0x4440C069272cC34b80C7B11bEE657D0349Ba9C23',
  },
  pwnV1_2Contracts,
  tokenBundlerContract: '0x1381F509f56f2aaA0faBD3012455901eA53F0BbD',
  // TODO: update addresses
  topTokens: new TopTokens({
    pwnd: '0x12C615185bBdF94E8C66A596696AaA523f0A7dEC',
    pwns: '0x2a11aAE0e89da3B4803320425C040C259b2045e6',
    // usdt: '0x7169D38820dfd117C3FA1f22a697dBA58d90BA06',
    weth: '0x4200000000000000000000000000000000000006',
    // dai: '0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357',
  }),
  // todo: unused
  gnosisSafe: {
    transactionServiceUrl: 'https://safe-transaction-sepolia.safe.global',
    chainName: 'unichain-sepolia',
  },
}

export default UNICHAIN_SEPOLIA_CONSTANTS
