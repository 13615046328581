import PwnSpaceConfig from './PwnSpaceConfig'
import { pwnadminSubdomainConfigRetrieve } from '@/modules/common/backend/generated'
import { compareAddresses, hexToRgb } from '@/utils/utils'
import { PUBLIC_TESTNET_HOSTNAME } from '@/utils/url'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { globalConstants } from '@/constants/globals'

const DEV_HOSTNAME = 'dev.pwn-frontend.pages.dev'
const NON_PWN_SPACE_PROD_HOSTNAMES = [DEV_HOSTNAME, 'app.pwn.xyz', PUBLIC_TESTNET_HOSTNAME]

const { hostname } = window.location

function isPwnSpacesUrl(): boolean {
  if (import.meta.env.VITE_PWN_SPACE_OVERRIDE) {
    // is overriden in .env.development.local for local dev purposes
    return true
  }

  return Boolean(hostname) && !NON_PWN_SPACE_PROD_HOSTNAMES.includes(hostname) && hostname.endsWith('.pwn.xyz')
}
export const isPwnSpace = isPwnSpacesUrl()

export const isStarknet = import.meta.env.VITE_IS_STARKNET === 'true' || hostname.includes('starknet')
export const isTestnetOrDevOrLocalhost = hostname === PUBLIC_TESTNET_HOSTNAME || globalConstants.environment === 'development'

function getPwnSpaceName() {
  if (import.meta.env.VITE_PWN_SPACE_OVERRIDE) {
    // is overriden in .env.development.local for local dev purposes
    return import.meta.env.VITE_PWN_SPACE_OVERRIDE as string
  }

  if (
    NON_PWN_SPACE_PROD_HOSTNAMES.includes(hostname) ||
    !hostname.endsWith('.pwn.xyz')
  ) {
    return null
  }

  return hostname.split('.')?.[0]
}
export const pwnSpaceName = getPwnSpaceName()

export let pwnSpaceConfig: PwnSpaceConfig | undefined
export async function loadPwnSpaceConfig(): Promise<PwnSpaceConfig | undefined> {
  if (!isPwnSpace) {
    return
  }

  const response = await pwnadminSubdomainConfigRetrieve(pwnSpaceName!)
  pwnSpaceConfig = PwnSpaceConfig.createFromBackendModel(response.data)
  document.documentElement.classList.add(`${pwnSpaceName}-subdomain`)

  if (pwnSpaceConfig?.pwnSpaceName === 'nouns' && pwnSpaceConfig?.primaryColor) {
    document.documentElement.style.setProperty(
      '--warning',
      pwnSpaceConfig?.primaryColor,
    )
  }

  if (pwnSpaceConfig?.backgroundColor) {
    document.documentElement.style.setProperty(
      '--background-color-base',
      hexToRgb(pwnSpaceConfig?.backgroundColor).join(' '),
    )
  }
  if (pwnSpaceConfig?.textColor) {
    document.documentElement.style.setProperty(
      '--text-color-base',
      hexToRgb(pwnSpaceConfig.textColor).join(' '),
    )
  }
  if (pwnSpaceConfig?.primaryColor) {
    document.documentElement.style.setProperty(
      '--primary-color-base',
      hexToRgb(pwnSpaceConfig?.primaryColor).join(' '),
    )
  }
  if (pwnSpaceConfig?.separatorColor) {
    document.documentElement.style.setProperty('--separator-color', pwnSpaceConfig.separatorColor)
    // Pwn spaces don't have the configurable `gray-3`, and as a temporary solution we fallback to similar color which is --separator-color
    document.documentElement.style.setProperty('--gray-3', pwnSpaceConfig.separatorColor)
  }
  if (pwnSpaceConfig?.darkSeparatorColor) {
    document.documentElement.style.setProperty('--dark-separator-color', pwnSpaceConfig.darkSeparatorColor)
  }
  if (pwnSpaceConfig?.inputColor) {
    document.documentElement.style.setProperty('--input-color', pwnSpaceConfig.inputColor)
  }

  return pwnSpaceConfig
}

export const filterOutNonPwnSpaceAssets = <T extends AssetMetadata>(assets: T[]): T[] => {
  if (!isPwnSpace) {
    return assets
  }

  const allowedContracts = pwnSpaceConfig?.allowedContracts ?? []
  return assets.filter(asset => {
    return allowedContracts.some(allowedContract => compareAddresses(allowedContract.address, asset.address) && allowedContract.chainId === asset.chainId)
  })
}
