import { ref, watch, computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { Address } from 'viem'
import { getFetchUserNftsQueryOptions } from '@/modules/common/backend/generated'
import type { FetchUserNftsQueryResult } from '@/modules/common/backend/generated'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import { keepPreviousData, useQueries } from '@tanstack/vue-query'
import type { SupportedChain } from '@/constants/chains/types'
import type { FetchUserNftsPageParams } from '@/modules/common/assets/fetchers/useNFTFetch'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { compareAddresses } from '@/utils/utils'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { useAssetsWithSmallBalancesStore } from './useAssetsWithSmallBalancesStore'

type UseUserNfts = {
  nfts: AssetWithAmount[];
  isLoading: boolean;
  reload: VoidFunction;
};

const nextUserNftPages = ref<
  Partial<Record<SupportedChain, [string | null | undefined, FetchUserNftsPageParams['metadata_source']]>>
>({})

export const useUserNfts = (
  address: MaybeRef<Address | undefined | null> | undefined,
  chainId?: MaybeRef<SupportedChain | undefined> | undefined,
) => {
  const smallBalancesStore = useAssetsWithSmallBalancesStore()

  watch(
    () => unref(address),
    (newAddress, oldAddress) => {
      if (newAddress && oldAddress && compareAddresses(newAddress, oldAddress)) {
        nextUserNftPages.value = {}
        smallBalancesStore.cleanAssets()
      }
    },
  )

  const selectedChainsStore = useSelectedChainsStore()
  const { selectedChains } = storeToRefs(selectedChainsStore)

  const chainsToFetch = computed(() => {
    if (unref(chainId)) {
      return [unref(chainId)]
    }

    return selectedChains.value === 'all' ? enabledChains : selectedChains.value || []
  })

  const nftsToFetchWithPages = computed(() => {
    if (!chainsToFetch.value || !unref(address)) {
      return []
    }

    const initialResultsToFetch = chainsToFetch.value.map((chain) => ({
      chain: Number(chain),
      address: unref(address),
      metadataSource: undefined,
      next: null,
    }))

    const nextPagesToFetch = Object.entries(nextUserNftPages.value).map(([next, [chain, metadataSource]]) => {
      if (!next || !chainsToFetch.value.includes(Number(chain))) {
        return null
      }

      return {
        chain: Number(chain),
        address: unref(address),
        metadataSource,
        next,
      }
    })

    return [...initialResultsToFetch, ...nextPagesToFetch.filter(Boolean)]
  })

  const nftQueries = computed(() => {
    if (!unref(address)) {
      return []
    }

    // @ts-expect-error FIXME: strictNullChecks/
    return nftsToFetchWithPages.value.map(({ chain, address, next, metadataSource }) => {
      const fetchParams = getFetchUserNftsQueryOptions(chain, address, {
        page: next || undefined,
        metadata_source: metadataSource,
      })

      return {
        ...fetchParams,
        queryFn: async (values) => {
          // @ts-expect-error FIXME: strictNullChecks/
          const res = await fetchParams.queryFn(values)

          const nextCursor = res.data.next
          if (!nextCursor) {
            return res
          }

          nextUserNftPages.value = {
            ...nextUserNftPages.value,
            [nextCursor]: [chain, res.metadata_source],
          }

          return res
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        structuralSharing: false, // this is needed to avoid stale data i.e. update balance
        keepPreviousData: true,
        retry: 3,
        placeholderData: keepPreviousData,
        staleTime: 1000 * 60 * 5, // 5 minutes
      }
    })
  })

  const queryData = useQueries<FetchUserNftsQueryResult[], UseUserNfts>({
    // @ts-expect-error FIXME: strictNullChecks/
    queries: nftQueries,
    combine(result) {
      const parsedWouldBe: AssetWithAmount[] = result
        .map((res) => {
          // @ts-expect-error FIXME: strictNullChecks/
          if (res.data?.data?.assets) {
            // @ts-expect-error FIXME: strictNullChecks/
            return res.data?.data?.assets.map(AssetWithAmount.createFromBackendModel)
          }
          return null
        })
        .flat()
        .filter(Boolean)

      return {
        nfts: parsedWouldBe,
        isLoading: result.some((query) => query.isFetching),
        reload: () => {
          result.forEach((query) => query.refetch())
        },
      }
    },
  })

  return {
    nfts: computed(() => queryData.value.nfts),
    isLoading: computed(() => queryData.value.isLoading),
    reload: queryData.value.reload,
  }
}
