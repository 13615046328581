import type { EventOptions, TrackPageOptions } from '@/modules/common/analytics/analytics'
import type IAnalyticsTracker from '@/modules/common/analytics/analytics'
import { useCookies } from '@vueuse/integrations/useCookies'
import { computed } from 'vue'
import {
  ANALYTICS_CONSENT_COOKIE_NAME,
  ANALYTICS_COOKIE_TTL_DAYS,
  AnalyticsConsent,
} from '@/modules/common/analytics/constants'
import DateUtils from '@/utils/DateUtils'
import { globalConstants } from '@/constants/globals'
import Hotjar from '@hotjar/browser'

const cookies = useCookies()

let trackerTool: IAnalyticsTracker

const isAllowed = computed(() => {
  return (
    Hotjar.isReady() &&
    (cookies.get(ANALYTICS_CONSENT_COOKIE_NAME) === AnalyticsConsent.accepted ||
    localStorage.getItem(ANALYTICS_CONSENT_COOKIE_NAME) === AnalyticsConsent.accepted)
  )
})

const isRejected = computed(() => {
  return (
    cookies.get(ANALYTICS_CONSENT_COOKIE_NAME) === AnalyticsConsent.rejected ||
    localStorage.getItem(ANALYTICS_CONSENT_COOKIE_NAME) === AnalyticsConsent.rejected
  )
})

const migrateRejectedConsentsFromCookiesToLocalStorage = () => {
  if (cookies.get(ANALYTICS_CONSENT_COOKIE_NAME) === AnalyticsConsent.rejected) {
    localStorage.setItem(ANALYTICS_CONSENT_COOKIE_NAME, AnalyticsConsent.rejected)
    cookies.remove(ANALYTICS_CONSENT_COOKIE_NAME)
  }
}

const isPopUpDisplayed = computed(() => {
  // Don't display modal in development
  if (globalConstants.environment === 'development') {
    return false
  }

  return (
    cookies.get(ANALYTICS_CONSENT_COOKIE_NAME) === undefined && !localStorage.getItem(ANALYTICS_CONSENT_COOKIE_NAME)
  )
})

const setConsent = (v: AnalyticsConsent) => {
  cookies.set(ANALYTICS_CONSENT_COOKIE_NAME, v, {
    sameSite: 'strict',
    secure: true,
    expires: DateUtils.getFutureDate(ANALYTICS_COOKIE_TTL_DAYS),
  })
  const cookieIsSet = cookies.get(ANALYTICS_CONSENT_COOKIE_NAME) === v
  if (!cookieIsSet) {
    localStorage.setItem(ANALYTICS_CONSENT_COOKIE_NAME, v)
  }
}

const initAnalytics = (tracker: IAnalyticsTracker) => {
  trackerTool = tracker
  if (!isRejected.value) {
    trackerTool.init()
  }
  migrateRejectedConsentsFromCookiesToLocalStorage()
}

const trackEvent = (options: EventOptions) => isAllowed.value && trackerTool.trackEvent(options)

const trackPageView = (options: TrackPageOptions) => isAllowed.value && trackerTool.trackPageView(options)

const handleRejectAnalytics = () => {
  // We don't set cookies anymore since we are using localStorage to store rejected consent
  localStorage.setItem(ANALYTICS_CONSENT_COOKIE_NAME, AnalyticsConsent.rejected)
  trackEvent({ name: EventNames.AnalyticsRejected })
  // Reloading page to de-initialize Hotjar
  window.location.reload()
}

const handleAcceptAnalytics = () => {
  setConsent(AnalyticsConsent.accepted)
  trackEvent({ name: EventNames.AnalyticsConsent })
}

const handleShowMakeOfferForm = () => {
  trackEvent({ name: EventNames.MakeOfferFormShown })
}

export default function useAnalytics() {
  return {
    initAnalytics,
    trackEvent,
    trackPageView,
    handleRejectAnalytics,
    handleAcceptAnalytics,
    handleShowMakeOfferForm,
    isPopUpDisplayed,
    isAllowed,
  }
}

export enum EventNames {
  CreateLoanFlowConnectedWallet = 'Create Loan Wallet Connected',
  CreateLoanFlowCollateralSelected = 'Create Loan Collateral Selected',
  CreateLoanFlowAssetToBorrowSelected = 'Create Loan Asset To Borrow Selected',
  CreateLoanFlowAcceptedTerms = 'Create Loan Accepted Loan Terms',
  CreateLoanFlowSubmittedLoan = 'Create Loan Submitted Loan Request',
  CreateLoanFlowFinish = 'Loan Request Created',

  PageNotFound = 'Page not found',
  AnalyticsConsent = 'Analytics accepted',
  AnalyticsRejected = 'Analytics rejected',

  // Proposal created
  BorrowingProposalCreatedHomepage = 'Borrowing Proposal Created Homepage',
  LendingProposalCreatedHomepage = 'Lending Proposal Created Homepage',

  BorrowingProposalCreatedAssetPage = 'Borrowing Proposal Created Asset Page',
  LendingProposalCreatedAssetPage = 'Lending Proposal Created Asset Page',

  // Proposal accepted
  BorrowingProposalAcceptedHomepage = 'Borrowing Proposal Accepted Homepage',
  LendingProposalAcceptedHomepage = 'Lending Proposal Accepted Homepage',

  BorrowingProposalAcceptedProposalPage = 'Borrowing Proposal Accepted Proposal Page',
  LendingProposalAcceptedProposalPage = 'Lending Proposal Accepted Proposal Page',

  BorrowingProposalAcceptedAssetPage = 'Borrowing Proposal Accepted Asset Page',
  LendingProposalAcceptedAssetPage = 'Lending Proposal Accepted Asset Page',

  // Thesis
  ThesisCreated = 'Thesis Created',

  // Features

  ShareOnFarcasterClicked = 'Share on Farcaster Clicked',
  ShareOnTwitterClicked = 'Share on Twitter Clicked',
  ShareOnDiscordClicked = 'Share on Discord Clicked',

  InterestTypeInterestSelected = 'Interest Type Interest Selected',
  InterestTypeAPRSelected = 'Interest Type APR Selected',
  OpenSupportTicketClickInToast = 'Open Support Ticket Click In Toast',
  MakeOfferFormShown = 'Make Offer Form Shown',
}
