import type { SupportedChain } from '@/constants/chains/types'
import type { Address } from 'viem'
import { isAddress } from 'viem'
import type { FetchUserNftsPageParams } from '@/modules/common/assets/fetchers/useNFTFetch'
import useNFTFetch from '@/modules/common/assets/fetchers/useNFTFetch'
import to from '@/utils/await-to-js'
import { computed, ref } from 'vue'
import { keepPreviousData, useIsFetching, useQueries, useQueryClient } from '@tanstack/vue-query'
import { defineStore, storeToRefs } from 'pinia'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { filterAssetsInSearchResults } from '@/utils/search'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'
import { isPwnSpace, pwnSpaceName } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import useDashboardCache from '@/modules/pages/dashboard/hooks/useDashboardCache'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import type { ActiveSortOption } from '@/general-components/sorting/useSorting'
import { loadSortOption } from '@/general-components/sorting/useSorting'
import {
  SORT_OPTIONS_LOOKUP as NFTS_SORT_OPTIONS_LOOKUP,
  SortOption as NFTSortOption,
} from '@/modules/sections/your-assets/your-nfts/YourNFTsDefinitions'
import {
  SORT_OPTIONS_LOOKUP as COINS_SORT_OPTIONS_LOOKUP,
  SortOption as TokenSortOption,
} from '@/modules/sections/your-assets/your-coins/YourCoinsDefinitions'
import { SortDirection } from '@/general-components/sorting/SortDirection'
import NFTPriceStats from '@/modules/common/assets/typings/NFTPriceStats'
import { queries } from '@/modules/queries'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'
import { compareAddresses, compareAssets } from '@/utils/utils'
import StoreIds from '@/constants/storeIds'
import { fetchUserErc20s } from '@/modules/common/backend/generated'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'

const dashboardUserAddress = ref<Address | null>(null)
const nextUserNftPages = ref<
  Partial<Record<SupportedChain, [string | null | undefined, FetchUserNftsPageParams['metadata_source']]>>
>({})
const selectedNFTsSortOption = ref<ActiveSortOption>(
  loadSortOption(
    'sort-option-your-nfts',
    { id: NFTSortOption.Name, direction: SortDirection.Descending, viewName: 'sort-option-your-nfts' },
    Object.keys(NFTS_SORT_OPTIONS_LOOKUP),
  ),
)

const selectedCoinsSortOption = ref<ActiveSortOption>(
  loadSortOption(
    'sort-option-your-coins',
    { id: TokenSortOption.Symbol, direction: SortDirection.Descending, viewName: 'sort-option-your-coins' },
    Object.keys(COINS_SORT_OPTIONS_LOOKUP),
  ),
)

export const fetchNftsWithOffsetByChain = async (
  chain: SupportedChain,
  addressToFetch: Address,
  page: string | undefined,
  metadataSource: FetchUserNftsPageParams['metadata_source'] | undefined = undefined,
  signal?: AbortSignal,
) => {
  const useNftFetch = useNFTFetch()

  const [error, result] = await to(
    useNftFetch.fetchUserNFTsPage({
      userAddress: addressToFetch,
      chainId: chain,
      metadata_source: metadataSource,
      page,
      ...(isPwnSpace && pwnSpaceName && { subdomain: pwnSpaceName }),
    },
    { signal },
    ),
  )

  if (error) {
    throw error
  }

  if (result.next) {
    nextUserNftPages.value = {
      ...nextUserNftPages.value,
      [result.next]: [chain, result.metadata_source],
    }
  }

  const [, cryptoPunkAssets] = await to(useNftFetch.loadUserCryptoPunks(chain))

  return result.results.concat(cryptoPunkAssets ?? [])
}

export const fetchUserTokens = async (
  chain: SupportedChain,
  addressToFetch: Address,
  {
    signal,
  }: {
    signal: AbortSignal;
  },
) => {
  const { fetchNativeToken } = useERC20Fetch()

  const result = await fetchUserErc20s(
    String(chain),
    addressToFetch,
    {
      ...(isPwnSpace && pwnSpaceName && { subdomain: pwnSpaceName }),
    },
    {
      signal,
    },
  )

  const allTokens: AssetWithAmount[] = (result?.data ?? []).map((token) => AssetWithAmount.createFromBackendModel(token))
  const [nativeTokenError, nativeToken] = await to(fetchNativeToken(chain, addressToFetch))

  if (!nativeTokenError && nativeToken) {
    allTokens.push(nativeToken)
  }

  return allTokens
}

export const useUserAssetsStore = defineStore(StoreIds.userAssets, () => {
  const selectedChainsStore = useSelectedChainsStore()
  const { selectedChains, selectedValues } = storeToRefs(selectedChainsStore)

  const queryClient = useQueryClient()

  const nftsToFetchWithPages = computed(() => {
    const chainsToFetch = selectedChains.value === 'all' ? enabledChains : selectedChains.value
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const initialResultsToFetch = chainsToFetch.map((chain) => ({
      chain,
      address: dashboardUserAddress.value,
      metadataSource: undefined,
      next: null,
    }))

    const nextPagesToFetch = Object.entries(nextUserNftPages.value).map(([next, [chain, metadataSource]]) => {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (!next || !chainsToFetch.includes(Number(chain))) {
        return null
      }

      return {
        chain: Number(chain),
        address: dashboardUserAddress.value,
        metadataSource,
        next,
      }
    })

    return [...initialResultsToFetch, ...nextPagesToFetch.filter(Boolean)]
  })

  const nftQueries = computed(() => {
    if (!dashboardUserAddress.value) {
      return []
    }

    const chainsToFetch = selectedChains.value === 'all' ? enabledChains : selectedChains.value

    // @ts-expect-error FIXME: strictNullChecks
    return nftsToFetchWithPages.value.map(({ chain, address, next, metadataSource }) => ({
      queryKey: [...queries.user.walletNfts(address, chain).queryKey, next, metadataSource],
      queryFn: ({ signal }) => {
        return fetchNftsWithOffsetByChain(chain, address, next, metadataSource, signal)
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      structuralSharing: false, // this is needed to avoid stale data i.e. update balance
      keepPreviousData: true,
      enabled: isAddress(address) && chainsToFetch?.includes(chain as SupportedChain),
      retry: 3,
      placeholderData: keepPreviousData,
      staleTime: 1000 * 60 * 5, // 5 minutes
    }))
  })

  const nftAssetsQueries = useQueries({
    queries: nftQueries,
    combine: (results) => {
      const allUserNfts = results
        .map((result) => result.data)
        .flat()
        .filter(Boolean) as AssetWithAmount[]

      const uniqueUserNfts = allUserNfts.filter((value, index, self) => {
        return index === self.findIndex((userNft) => compareAssets({ assetA: value, assetB: userNft }))
      })

      return {
        data: uniqueUserNfts,
      }
    },
  })

  const invalidateAndRefetch = async (userAddress: Address, chainId?: SupportedChain) => {
    if (chainId) {
      await useDashboardCache().resetCacheState({
        userAddress,
        chainId,
      })
      await queryClient.refetchQueries({
        ...queries.user.walletNfts(userAddress, chainId),
        exact: false,
      })
      await queryClient.refetchQueries({
        ...queries.user.walletTokens(userAddress, chainId),
        exact: false,
      })
      await useDashboardCache().loadCacheState({
        userAddress,
        chainId,
      })
      return
    }

    const chainsToReset = selectedValues.value

    if (!chainsToReset) {
      return
    }

    await to(
      Promise.allSettled(
        chainsToReset.map((chain) => {
          return useDashboardCache().resetCacheState({
            userAddress,
            chainId: chain,
          })
        }),
      ),
    )

    await to(
      Promise.all([
        queryClient.refetchQueries({
          queryKey: [...queries.user.walletNfts._def, userAddress],
          exact: false,
        }),
        queryClient.refetchQueries({
          queryKey: [...queries.user.walletTokens._def, userAddress],
          exact: false,
        }),
      ]),
    )
  }

  const tokenQueries = computed(() => {
    if (!dashboardUserAddress.value) {
      return []
    }

    // @ts-expect-error FIXME: strictNullChecks
    const toFetchWithoutPages = nftsToFetchWithPages.value.filter(({ next }) => !next)

    // @ts-expect-error FIXME: strictNullChecks
    return toFetchWithoutPages.map(({ chain, address }) => ({
      ...queries.user.walletTokens(address, chain),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      structuralSharing: false, // this is needed to avoid stale data i.e. update balance
      keepPreviousData: true,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      enabled: isAddress(address) && selectedValues.value.includes(chain as SupportedChain),
      retry: 3,
      placeholderData: keepPreviousData,
      staleTime: 1000 * 60 * 5, // 5 minutes
    }))
  })

  const updateTokenQueries = (tokens: AssetWithAmount[], userAddress: Address, chain: SupportedChain) => {
    const queryKey = queries.user.walletTokens(userAddress, chain)
    queryClient.setQueriesData({
      ...queryKey,
      exact: false,
    }, (oldData: AssetWithAmount[] | undefined) => {
      if (!oldData) {
        return tokens
      }

      const newData = oldData.filter((oldAsset) => {
        return !tokens.some((token) => {
          if (token.isNativeToken) {
            return oldAsset.isNativeToken
          }
          return compareAddresses(oldAsset.address, token.address)
        })
      })

      tokens = tokens.filter(token => token.amountRaw > 0n)

      return newData.concat(tokens)
    })
  }

  const updateNftQueries = (nfts: AssetWithAmount[], userAddress: Address, chain: SupportedChain) => {
    const queryKey = queries.user.walletNfts(userAddress, chain)
    queryClient.setQueriesData(
      {
        ...queryKey,
        exact: false,
      },
      (oldData: AssetWithAmount[] | undefined) => {
        if (!oldData) {
          return nfts
        }

        const newData = oldData.filter((oldAsset) => {
          return !nfts.some((nft) => {
            return compareAssets({
              assetA: nft,
              assetB: oldAsset,
            })
          })
        })

        nfts = nfts.filter((nft) => nft.amountRaw > 0n)

        return newData.concat(nfts)
      },
    )
  }

  const batchUpdateAssets = (assets: AssetWithAmount[], userAddress: Address, chain: SupportedChain) => {
    const tokens: AssetWithAmount[] = []
    const nfts: AssetWithAmount[] = []

    for (const asset of assets) {
      if (asset.isNft) {
        nfts.push(asset)
      } else {
        tokens.push(asset)
      }
    }

    if (tokens.length) {
      updateTokenQueries(tokens, userAddress, chain)
    }

    if (nfts.length) {
      updateNftQueries(nfts, userAddress, chain)
    }
  }

  const removeUserNft = (asset: AssetWithAmount, userAddress: Address, chain: SupportedChain) => {
    const queryKey = queries.user.walletNfts(userAddress, chain)
    queryClient.setQueriesData(
      {
        ...queryKey,
        exact: false,
      },
      (oldData: AssetWithAmount[] | undefined) => {
        if (!oldData) {
          return oldData
        }

        return oldData.filter((oldAsset) => !compareAssets({ assetA: oldAsset, assetB: asset }))
      },
    )
  }

  const tokenAssetsQueries = useQueries({
    queries: tokenQueries,
    combine: (results) => {
      const allUserErc20s = results
        .map((result) => result.data)
        .flat()
        .filter(Boolean) as AssetWithAmount[]

      const uniqueUserErc20s = allUserErc20s.filter((value, index, self) => {
        return index === self.findIndex((userErc20) => compareAssets({ assetA: value, assetB: userErc20 }))
      }).filter((asset) => asset.amount !== '-')

      return {
        keys: results.map((result) => result),
        data: uniqueUserErc20s,
      }
    },
  })

  const loadUserAssets = (loadingFor: Address) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '`0x${string}` | null' is not ass... Remove this comment to see the full error message
    if (!compareAddresses(dashboardUserAddress.value, loadingFor)) {
      nextUserNftPages.value = {}
    }
    dashboardUserAddress.value = loadingFor
  }

  const applyFiltering = (assets: AssetWithAmount[]) => {
    const { debouncedSearchTerm } = useDashboard()
    return filterAssetsInSearchResults(assets, debouncedSearchTerm.value)
  }

  const nftsAreFetching = useIsFetching({
    queryKey: [...queries.user.walletNfts._def, dashboardUserAddress],
    exact: false,
    type: 'active',
    fetchStatus: 'fetching',
    predicate(query) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      return selectedValues.value.includes(query.queryKey?.[3] as SupportedChain)
    },
  })

  const tokensAreFetching = useIsFetching({
    queryKey: [...queries.user.walletTokens._def, dashboardUserAddress],
    exact: false,
    type: 'active',
    fetchStatus: 'fetching',
    predicate(query) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      return selectedValues.value.includes(query.queryKey?.[3] as SupportedChain)
    },
  })

  const getAssetsWithPrices = (assets: AssetWithAmount[]) => {
    const assetPricesData = queryClient.getQueriesData({
      queryKey: ['assetPrice'],
      exact: false,
      predicate(query) {
        const type = query.queryKey?.[1]
        // should we also filter not related queries here?
        if (type === 'appraisal' || type === 'token-appraisal') {
          return true
        }
        return type === 'floorPrice' || type === 'price'
      },
    })

    // O(1) lookup instead of O(n ^^ m) lookup
    const nftPrices = {}
    const nftFloorPrices = {}
    const tokenAppraisals = {}
    const tokenPrices = {}

    for (const nftPrice of assetPricesData) {
      if (nftPrice[0][1] === 'appraisal') {
        const key = `${nftPrice[0][2]}-${nftPrice[0][3]}-${nftPrice[0][4]}`
        nftPrices[key] = nftPrice[1]
      }
      if (nftPrice[0][1] === 'floorPrice') {
        const key = `${nftPrice[0][2]}-${nftPrice[0][3]}`
        nftFloorPrices[key] = nftPrice[1]
      }
      if (nftPrice[0][1] === 'token-appraisal') {
        const key = `${nftPrice[0][2]}-${nftPrice[0][3]}`
        tokenAppraisals[key] = nftPrice[1]
      }
      if (nftPrice[0][1] === 'price') {
        const key = `${nftPrice[0][2]}-${nftPrice[0][3]}`
        tokenPrices[key] = nftPrice[1]
      }
    }

    const parseAssetsWithPricesFromQueryData = (): AssetWithAmount[] => {
      const result = []

      for (const asset of assets) {
        // we need to copy the asset here to avoid modifying the original asset

        if (asset.isNativeToken) {
          // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetWithAmount' is not assignab... Remove this comment to see the full error message
          result.push(asset)
          // backend method is not returning the price for native token yet
          continue
        }

        if (asset.isNft) {
          const assetKey = `${asset.chainId}-${asset.address}`
          const uniqueAssetKey = `${assetKey}-${asset.tokenId}`

          const hasPrice = nftPrices[uniqueAssetKey] || nftFloorPrices[assetKey]
          if (hasPrice) {
            const assetCopy = new AssetWithAmount(asset)
            if (nftPrices[uniqueAssetKey]) {
              assetCopy.appraisal = nftPrices[uniqueAssetKey]
            }
            if (nftFloorPrices[assetKey]) {
              assetCopy.nftPriceStats = new NFTPriceStats({
                floorPrice: nftFloorPrices[assetKey].floorPrice,
                floorPriceSource: nftFloorPrices[assetKey].floorPriceSource,
              })
            }
            // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetWithAmount' is not assignab... Remove this comment to see the full error message
            result.push(assetCopy)
          } else {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetWithAmount' is not assignab... Remove this comment to see the full error message
            result.push(asset)
          }
        } else if (asset.isErc20) {
          const assetKey = `${asset.chainId}-${asset.address}`
          const hasPrice = nftPrices[assetKey]
          if (hasPrice) {
            const assetCopy = new AssetWithAmount(asset)
            assetCopy.appraisal = tokenPrices[assetKey]
            // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetWithAmount' is not assignab... Remove this comment to see the full error message
            result.push(assetCopy)
          } else {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetWithAmount' is not assignab... Remove this comment to see the full error message
            result.push(asset)
          }
        }
      }

      return result
    }

    return parseAssetsWithPricesFromQueryData()
  }

  const applyNFTsSorting = (
    assets: AssetWithAmount[],
    selectedSorting: {
      id: string;
      direction: SortDirection;
      viewName: string;
    },
  ): AssetWithAmount[] => {
    const parsedAssets = getAssetsWithPrices(assets)
    return NFTS_SORT_OPTIONS_LOOKUP[selectedSorting.id](parsedAssets, selectedSorting.direction)
  }

  const applyCoinsSorting = (
    assets: AssetWithAmount[],
    selectedSorting: {
      id: string;
      direction: SortDirection;
      viewName: string;
    },
  ): AssetWithAmount[] => {
    const parsedAssets = getAssetsWithPrices(assets)
    return COINS_SORT_OPTIONS_LOOKUP[selectedSorting.id](parsedAssets, selectedSorting.direction)
  }

  const totalExcludedUserNfts = ref(0)
  const totalExcludedUserErc20s = ref(0)

  const userNfts = computed<AssetWithAmount[]>(() => {
    const selectedSorting = selectedNFTsSortOption.value
    const filteredByLowBalance = useGlobalFiltersStore().applyShowAssetsWithLesserAmountFilter(nftAssetsQueries.value.data)
    const filteredNfts = useGlobalFiltersStore().applyShowUnverifiedAssetsFilter(filteredByLowBalance)
    totalExcludedUserNfts.value = nftAssetsQueries.value.data.length - filteredNfts.length
    return applyNFTsSorting(applyFiltering(filteredNfts), selectedSorting)
  })

  const userErc20s = computed<AssetWithAmount[]>(() => {
    const selectedSorting = selectedCoinsSortOption.value
    const filteredByLowBalance = useGlobalFiltersStore().applyShowAssetsWithLesserAmountFilter(tokenAssetsQueries.value.data)
    const filteredErc20s = useGlobalFiltersStore().applyShowUnverifiedAssetsFilter(filteredByLowBalance)
    totalExcludedUserErc20s.value = tokenAssetsQueries.value.data.length - filteredErc20s.length
    return applyCoinsSorting(applyFiltering(filteredErc20s), selectedSorting)
  })

  return {
    loadUserAssets,
    dashboardUserAddress,
    userNfts,
    userErc20s,
    userNftsIsPending: computed(() => nftsAreFetching.value !== 0),
    userErc20sIsPending: computed(() => tokensAreFetching.value !== 0),
    updateTokenQueries,
    updateNftQueries,
    batchUpdateAssets,
    invalidateAndRefetch,
    selectedNFTsSortOption,
    selectedCoinsSortOption,
    removeUserNft,
    totalExcludedUserNfts,
    totalExcludedUserErc20s,
  }
})
