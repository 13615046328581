<template>
  <RevampBaseModal
    v-model:is-open="isOpen"
    heading-align="left"
    custom-max-width="52rem"
    :heading="heading"
    :size="ModalSize.Medium">
    <template #body>
      <div class="modal-proposal-preview__collateral-and-proposal-info">
        <!-- todo: create a placeholder CollateralDetail if to be bundled -->
        <CollateralDetail
          v-if="parsedProposal && !bundledAssets"
          class="modal-proposal-preview__collateral-detail"
          :collateral="{
            chainId: parsedProposal.chainId,
            address: parsedProposal.collateralAddress,
            category: parsedProposal.collateralCategory,
            tokenId: parsedProposal.collateralId,
          }"
          is-proposal-preview
          :is-dynamic="proposalClass?.collateral?.isDynamicContract"
          :amount="
            parsedProposal.collateralCategory === AssetType.ERC721
              ? 1
              : proposalClass?.collateralAmount.toString() || parsedProposal.collateralAmount
          "/>
        <CollateralDetailPreBundle
          v-else-if="bundledAssets"
          class="modal-proposal-preview__collateral-detail"
          :bundled-assets="bundledAssets"/>
        <ProposalDetail
          v-if="parsedProposal && proposalClass"
          class="modal-proposal-preview__proposal-detail"
          is-proposal-preview
          :credit-asset="creditAsset as AssetDetail"
          :collateral-asset="collateralAsset as AssetDetail"
          :ltv="ltvInProposalModal"
          :credit-amount="parsedProposal.creditAmount ?? (parsedProposal.availableCreditLimit) ?? (proposalClass?.creditAmount.toString() || '0')"
          :apr="parsedProposal.accruingInterestApr ?? 0"
          :credit-amount-label="parsedProposal.isOffer ? 'Lend' : 'Borrow'"
          :duration="parsedProposal.duration"
          :total-repayment="String(amountToRepayEnd)"
          :is-offer="parsedProposal.isOffer"
          :proposer="parsedProposal.proposer"
          :type="parsedProposal.type"
          :chain-id="parsedProposal.chainId">
          <template #expiration>
            <div class="modal-proposal-preview__expires-in">
              <span>Expires in</span>
              <span>{{ DateUtils.getDaysDifference(new Date(proposalClass.expiration * 1000), new Date()) }} days</span>
            </div>
          </template>
        </ProposalDetail>
      </div>
      <AprChart
        v-if="proposalClass && amountToRepayEnd && amountToRepayStart"
        :loan-duration-days="proposalClass?.loanDurationDays"
        :amount-to-repay-start="+amountToRepayStart"
        :token-symbol="proposalClass.creditAsset.symbol"
        :amount-to-repay-end="+amountToRepayEnd"/>
      <div class="modal-proposal-preview__warnings-and-button-container">
        <ProposalWarnings
          :is-apr-too-high="isAprTooHigh"
          :is-ltv-too-high="isLtvTooHigh"
          :is-offer="parsedProposal?.isOffer ?? false"/>

        <BaseButton
          :is-disabled="isPending"
          :color="isLtvTooHigh || isAprTooHigh ? ButtonColor.Warning : ButtonColor.Primary"
          class="modal-proposal-preview__button"
          :size="ButtonSize.XL"
          :button-text="isProposalPosted ? 'Go to Proposal detail' : 'Post Proposal'"
          @on-button-click="isProposalPosted ? handleGoToProposalDetail() : handlePostProposalMutateAsync()"/>
      </div>
    </template>
  </RevampBaseModal>
</template>

<script setup lang="ts">
import ModalSize from '@/general-components/ModalSize'
import BaseButton, { ButtonColor, ButtonSize } from '@/general-components/BaseButton.vue'
import useProposalPreviewModal from '@/revamp/components/modals/proposal-preview-modal/useProposalPreviewModal'
import type { Ref } from 'vue'
import { computed, ref, unref } from 'vue'
import { Toast, TOAST_ACTION_ID_TO_UNIQUE_ID_FN, ToastStep } from '@/modules/common/notifications/useToastsStore'
import { getChainId } from '@wagmi/vue/actions'
import { pwnWagmiConfig } from '@/modules/common/web3/usePwnWagmiConfig'
import { NotificationActionEnumBackendSchema, useProposalCreate } from '@/modules/common/backend/generated'
import useActionFlow from '@/modules/common/notifications/useActionFlow'
import { checkProposerApprovalAndBalance } from '@/revamp/modules/proposals/useProposalApprovals'
import router from '@/router'
import RouteName from '@/router/RouteName'
import { useMutation } from '@tanstack/vue-query'
import AprChart from '@/revamp/components/modals/proposal-preview-modal/AprChart.vue'
import type { Address } from 'viem'
import { formatUnits } from 'viem'
import { APR_DECIMAL_POINT_PADDING } from '@/constants/loans'
import { parseAndTransform, zProposalDetailPayload } from '@/revamp/modules/proposals/types'
import type { AssetDetail } from '@/revamp/modules/proposals/types'
import ProposalDetail from '@/revamp/components/ProposalDetail.vue'
import CollateralDetail from '@/revamp/components/CollateralDetail.vue'
import DateUtils from '@/utils/DateUtils'
import AssetType from '@/modules/common/assets/AssetType'
import RevampBaseModal from '@/revamp/components/RevampBaseModal.vue'
import useBundleApprove from '@/modules/common/assets/useBundleApprove'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { queryMutations } from '@/modules/mutations'
import ProposalFactory from '@/modules/common/pwn/proposals/ProposalFactory'
import { useCreateProposalPayload } from '@/revamp/components/proposal-form/useCreateProposalPayload'
import CollateralDetailPreBundle from '@/revamp/components/CollateralDetailPreBundle.vue'
import useSelectAssetModal from '@/revamp/components/modals/select-your-collateral/useSelectAssetModal'
import { compareAssets } from '@/utils/utils'
import useApprove from '@/modules/common/assets/useApprove'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import type { SupportedChain } from '@/constants/chains/types'
import { compoundCometAllow, getPoolAdapter } from '@/contracts/abis'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import { DepositProtocol } from '@/modules/sections/your-assets/your-deposited-assets/useDepositedAssets'
import useAnalytics, { EventNames } from '@/modules/common/analytics/useAnalytics'
import ProposalWarnings from '@/revamp/components/modals/proposal-preview-modal/ProposalWarnings.vue'
import { useRoute } from 'vue-router'

const {
  isOpen,
  proposalClass,
  isProposalPosted,
  nonceToRevoke,
  proposalToBeSemiComplete,
  isEdit,
  ltvInProposalModal,
  bundledAssets,
  isRedirectToProposalPage,
} = useProposalPreviewModal()

const mutationCreateProposal = useProposalCreate()
const { createProposalPayloadFromExistingProposal } = useCreateProposalPayload()
const { trackEvent } = useAnalytics()
const route = useRoute()

const proposalResponse = ref()
const EMPTY_32_BYTES = '0x0000000000000000000000000000000000000000000000000000000000000000'

const { isBundleApproved, approveAssetsForBundlerIfNeeded } = useBundleApprove()

const createdBundle = ref<AssetWithAmount>()
const isSuccessfullyBundled = ref(false)
const mutations = queryMutations()
const { isNeedRemovePreviousApproval, approve, getPreviousApprovalAmount } = useApprove()

const { mutateAsync: bundleAssetsMutateAsync } = useMutation({
  ...mutations.tokenBundler.bundle,
  async onSuccess(_createdBundle, variables, context) {
    createdBundle.value = _createdBundle
    isSuccessfullyBundled.value = true
    mutations.tokenBundler.bundle.onSuccess?.(_createdBundle, variables, context)

    const newProposalPayloadToBeSemiComplete = await createProposalPayloadFromExistingProposal(
      proposalToBeSemiComplete.value,
      createdBundle.value,
    )
    const parsedProposal = await ProposalFactory.createProposalFromBackendModel(
      newProposalPayloadToBeSemiComplete,
      createdBundle.value,
      proposalClass.value?.creditAsset,
    )
    proposalClass.value = parsedProposal

    proposalToBeSemiComplete.value = newProposalPayloadToBeSemiComplete
  },
})

const sendProposalCreatedEvent = () => {
  let eventName: EventNames | null = null
  const routeName = route.name as RouteName

  if (routeName === RouteName.RevampBorrow) {
    eventName = EventNames.BorrowingProposalCreatedHomepage
  } else if (routeName === RouteName.RevampLend) {
    eventName = EventNames.LendingProposalCreatedHomepage
  } else if (routeName === RouteName.RevampTokenBorrowing) {
    eventName = EventNames.BorrowingProposalCreatedAssetPage
  } else if (routeName === RouteName.RevampTokenLending) {
    eventName = EventNames.LendingProposalCreatedAssetPage
  } else if (routeName === RouteName.RevampNftPageBorrowing) {
    eventName = EventNames.BorrowingProposalCreatedAssetPage
  } else if (routeName === RouteName.RevampNftPageLending) {
    eventName = EventNames.LendingProposalCreatedAssetPage
  }

  if (eventName) {
    trackEvent({ name: eventName })
  }
}

const { mutateAsync: approveAssetsForBundleMutateAsync } = useMutation({
  mutationKey: ['approveForBundle'],
  mutationFn: async (variables) => {
    isBundleApproved.value = await approveAssetsForBundlerIfNeeded(bundledAssets.value!)
    return isBundleApproved.value
  },
})

const { mutateAsync: approveDynamicAssetForBundleMutateAsync } = useMutation({
  mutationKey: ['approveForBundle'],
  mutationFn: async (asset: AssetWithAmount) => {
    isBundleApproved.value = await approveAssetsForBundlerIfNeeded([asset])
    return isBundleApproved.value
  },
})

const tokenShouldBeWrapped = computed(
  () =>
    proposalClass.value?.collateral &&
    compareAssets({
      assetA: proposalClass.value?.collateral,
      assetB: AssetWithAmount.createEtherFiEETHAssetMetadata(),
    }),
)

const fnToWrap = () => {
  if (tokenShouldBeWrapped.value) {
    return mutations.etherFi.wrap
  }
  return mutations.wrap
}

const { mutateAsync: wrapNativeToken } = useMutation({
  ...fnToWrap(),
  async onSuccess(data, variables, context) {
    await fnToWrap().onSuccess?.(data, variables, context)
    const newProposalPayloadToBeSemiComplete = await createProposalPayloadFromExistingProposal(
      proposalToBeSemiComplete.value,
      data.wrappedNativeToken,
    )
    const parsedProposal = await ProposalFactory.createProposalFromBackendModel(
      newProposalPayloadToBeSemiComplete,
      data.wrappedNativeToken,
      proposalClass.value?.creditAsset,
    )
    proposalClass.value = parsedProposal

    proposalToBeSemiComplete.value = newProposalPayloadToBeSemiComplete
  },
})

const handlePostProposal = async () => {
  if (!proposalClass.value) return
  const { hasProposerApproval } = await checkProposerApprovalAndBalance({
    proposalClass: proposalClass.value,
    proposerAddress: proposalClass.value?.proposer,
  })

  let continueFlow: () => Promise<void>
  const toast = ref<Toast>()
  // TODO add option for swap to get credit

  const getBundleToastSteps = () => {
    const steps: ToastStep[] = []

    if (!isBundleApproved.value) {
      steps.push(
        new ToastStep({
          text: 'Approving...',
          async fn(step) {
            return await approveAssetsForBundleMutateAsync()
          },
        }),
      )
    }

    if (!isSuccessfullyBundled.value) {
      steps.push(
        new ToastStep({
          text: 'Bundling...',
          async fn(step) {
            return !!(await bundleAssetsMutateAsync({ assets: bundledAssets.value!, step }))
          },
        }),
      )
    }

    return steps
  }

  const getThirdPartyProposalSteps = () => {
    const steps: ToastStep[] = []
    // approve aToken, spender: PoolAdapter, amount: fixed amount
    steps.push(
      new ToastStep({
        text: 'Approving Third-party Asset...',
        async fn(step) {
          const pool = proposalClass.value?.sourceOfFunds
          if (!pool) return false
          const poolAdapter = await getPoolAdapter(pwnWagmiConfig, {
            address: CHAINS_CONSTANTS[String(proposalClass.value?.chainId)].pwnV1_2Contracts.pwnConfig,
            chainId: proposalClass.value?.chainId,
            args: [pool],
          })

          const aToken = await useMetadataFetch().fetchErc20Metadata({
            chainId: proposalClass.value?.chainId as SupportedChain,
            contractAddress: proposalClass.value?.creditAsset.aTokenAddress as Address,
          })

          if (!aToken || !poolAdapter || !proposalClass.value?.creditAmount) return false

          if (proposalClass.value?.creditAsset.protocol === DepositProtocol.COMPOUND) {
            const isAllowed = await compoundCometAllow(pwnWagmiConfig, {
              address: pool,
              chainId: proposalClass.value?.chainId,
              args: [poolAdapter, true],
            })
            return !!isAllowed
          }
          if (proposalClass.value?.creditAsset.protocol === DepositProtocol.AAVE) {
            return await useApprove().approve({
              asset: aToken,
              spender: poolAdapter,
              amount: proposalClass.value?.creditAmount,
            }, step)
          } else return false
        },
      }),
    )
    return steps
  }

  const getDynamicAssetSteps = () => {
    const steps: ToastStep[] = []

    steps.push(
      new ToastStep({
        text: 'Approving...',
        async fn(step) {
          return await approveDynamicAssetForBundleMutateAsync(proposalClass.value?.collateral as AssetWithAmount)
        },
      }),
    )

    steps.push(
      new ToastStep({
        text: 'Wrapping Dynamic Asset...',
        async fn(step) {
          return !!(await bundleAssetsMutateAsync({
            assets: [proposalClass.value?.collateral as AssetWithAmount],
            step,
          }))
        },
      }),
    )

    return steps
  }

  const getCreateLoanToastSteps = () => {
    const toastSteps: ToastStep[] = []

    const connectedChainId = getChainId(pwnWagmiConfig)
    if (connectedChainId !== proposalClass.value?.chainId && proposalClass.value?.chainId) {
      toastSteps.push(ToastStep.createSwitchChainStep(proposalClass.value?.chainId))
    }

    if (!isEdit.value && bundledAssets.value) {
      toastSteps.push(...getBundleToastSteps())
    }

    if (proposalClass.value?.sourceOfFunds && !hasProposerApproval) {
      toastSteps.push(...getThirdPartyProposalSteps())
    }

    if (proposalClass.value?.collateral.isDynamicContract) {
      toastSteps.push(...getDynamicAssetSteps())
    }

    if (isEdit.value) {
      toastSteps.push(
        new ToastStep({
          text: 'Cancel Proposal',
          async fn(step) {
            if (!proposalClass.value) return false
            const res = await proposalClass.value.revokeNonce(nonceToRevoke.value)
            return Boolean(res)
          },
        }),
      )
    }

    if (proposalClass.value?.collateral.isNativeToken) {
      toastSteps.push(
        new ToastStep({
          text: 'Wrapping native token...',
          async fn(step) {
            if (!proposalClass.value) throw new Error('Proposal class is not defined')

            await wrapNativeToken({
              nativeToken: proposalClass.value.collateral,
              amount: proposalClass.value.collateralAmount,
              step,
            })
            return true
          },
        }),
      )
    }

    if (!hasProposerApproval) {
      const assetToCheck = proposalClass.value?.isOffer
        ? proposalClass.value?.creditAsset
        : proposalClass.value?.collateral
      if (!assetToCheck) throw new Error('Asset to check is not defined')

      toastSteps.push(new ToastStep({
        text: 'Checking approval...',
        async fn(step) {
          if (!proposalClass.value) return false

          const existingAllowance = await getPreviousApprovalAmount(
            proposalClass.value.chainId,
            assetToCheck.address,
            assetToCheck.category,
            CHAINS_CONSTANTS[proposalClass.value.chainId].pwnV1_2Contracts.pwnSimpleLoan,
            proposalClass.value.proposer,
          )

          if (existingAllowance !== null && existingAllowance > 0n && isNeedRemovePreviousApproval(
            proposalClass.value.chainId,
            assetToCheck.address,
            assetToCheck.category,
          )) {
            step.text = 'Removing previous approval...'
            const isApproved = await approve({
              asset: assetToCheck,
              amount: 0n,
              spender: CHAINS_CONSTANTS[proposalClass.value.chainId].pwnV1_2Contracts.pwnSimpleLoan,
            }, step)
            return Boolean(isApproved)
          }
          return true
        },
      }))

      toastSteps.push(new ToastStep({
        text: `${proposalClass.value?.isOffer ? 'Approving Asset...' : 'Approving Collateral...'}`,
        async fn(step) {
          if (proposalClass.value?.isOffer) {
            const isApproved = await proposalClass.value?.approveLender(proposalClass.value?.creditAmount, step)
            return Boolean(isApproved)
          } else {
            const isApproved = await proposalClass.value?.approveBorrower(proposalClass.value?.collateralAmount, step)
            return Boolean(isApproved)
          }
        },
      }))
    }

    toastSteps.push(
      new ToastStep({
        async fn(step) {
          if (!proposalClass.value || !proposalToBeSemiComplete.value) return false
          const {
            proposalHash,
            signature,
            // @ts-expect-error TS(2322) FIXME:  Argument of type 'BaseProposal' is not assignable to parameter of type 'V1_2SimpleLoanDutchAuctionProposal & V1_2SimpleLoanSimpleProposal...
          } = await proposalClass.value.proposalContract.createProposalHashAndSignature(proposalClass.value)
          proposalToBeSemiComplete.value.hash = proposalHash
          proposalToBeSemiComplete.value.signature = signature
          proposalToBeSemiComplete.value.nonce_space = String(proposalClass.value.nonceSpace)
          proposalToBeSemiComplete.value.proposer_spec_hash = proposalClass.value.isOffer
            ? proposalClass.value.proposerSpecHash
            : EMPTY_32_BYTES
          proposalToBeSemiComplete.value.nonce = proposalClass.value.nonce.toString()
          return true
        },
        text: 'Signing Proposal...',
      }),
    )

    toastSteps.push(new ToastStep({
      text: 'Creating Proposal...',
      async fn(step) {
        // add zod?
        const payload = unref(proposalToBeSemiComplete.value)
        proposalResponse.value = await mutationCreateProposal.mutateAsync({ data: payload! })
        step.txHash = proposalResponse.value?.data?.txHash
        if (!proposalResponse.value?.data?.id) return false
        isProposalPosted.value = true
        if (isEdit.value) {
          isEdit.value = false
        } else {
          sendProposalCreatedEvent()
        }

        isOpen.value = false
        if (isRedirectToProposalPage.value) {
          await router.push({
            name: RouteName.ProposalDetail,
            params: { id: String(proposalResponse.value.data.id) },
          })
        }
        return true
      },
    }),
    )

    return toastSteps
  }

  const handleCreateProposal = async () => {
    if (!proposalClass.value?.chainId) return
    // TODO setup notification for create proposal
    const actionId = TOAST_ACTION_ID_TO_UNIQUE_ID_FN[NotificationActionEnumBackendSchema.LOAN_CREATED](
      proposalClass.value?.collateral,
    )
    if (toast.value?.id !== actionId) {
      toast.value = new Toast(
        {
          title: 'Proposal',
          chainId: proposalClass.value.chainId,
          steps: getCreateLoanToastSteps(),
          firstAsset: proposalClass.value?.collateral,
          secondAsset: proposalClass.value?.creditAsset,
        },
        NotificationActionEnumBackendSchema.LOAN_CREATED,
        proposalClass.value?.collateral,
      );
      ({ continueFlow } = useActionFlow(toast as Ref<Toast>))
    }

    await continueFlow()
  }

  await handleCreateProposal()

  bundledAssets.value = null
}

const { isPending, mutateAsync: handlePostProposalMutateAsync } = useMutation({
  mutationKey: ['handlePostProposalMutateAsync'],
  mutationFn: async (variables) => {
    await handlePostProposal()
  },
  onSuccess: (data, variables, context) => {
    proposalResponse.value = undefined
    useSelectAssetModal().resetMultiselect()
    // invalidate and re-fetch queries are handled by sseListener
  },
})

const handleGoToProposalDetail = async () => {
  if (!proposalResponse.value?.data?.id) return
  isOpen.value = false
  await router.push({ name: RouteName.ProposalDetail, params: { id: String(proposalResponse.value.data.id) } })
}

const heading = computed(() =>
  proposalClass.value?.isOffer ? 'Lending Proposal Preview' : 'Borrowing Proposal Preview',
)

const amountToRepayStart = computed(
  () =>
    proposalClass.value && formatUnits(proposalClass.value.creditAmount, proposalClass.value?.creditAsset?.decimals),
)
const amountToRepayEnd = computed(() => {
  if (!proposalClass.value) return null
  const principal = formatUnits(proposalClass.value.creditAmount, proposalClass.value.creditAsset?.decimals)
  const apr = formatUnits(BigInt(proposalClass.value.accruingInterestAPR), APR_DECIMAL_POINT_PADDING)
  return +principal * (1 + (+apr / 100) * (proposalClass.value.loanDurationDays / 365))
})

const parsedProposal = computed(() => {
  if (!proposalToBeSemiComplete.value) {
    return null
  }
  return parseAndTransform(zProposalDetailPayload, proposalToBeSemiComplete.value)
})

const isLtvTooHigh = computed(() => {
  if (!ltvInProposalModal.value) return false
  return ltvInProposalModal.value > 99
})

const isAprTooHigh = computed(() => {
  if (!proposalClass.value) return false
  const aprFormated = proposalClass.value.accruingInterestAPR / 10 ** APR_DECIMAL_POINT_PADDING
  return aprFormated >= 150
})

const creditAsset = computed<AssetDetail | null>(() => {
  if (!proposalClass.value?.creditAsset) return null
  return {
    address: proposalClass.value.creditAsset.address,
    chainId: proposalClass.value.creditAsset.chainId,
    thumbnailUrl: proposalClass.value.creditAsset.image,
    symbol: proposalClass.value.creditAsset.symbol,
    decimals: proposalClass.value.creditAsset.decimals,
    isVerified: proposalClass.value.creditAsset.isVerified,
  }
})

const collateralAsset = computed<AssetDetail | null>(() => {
  if (!proposalClass.value?.collateral) return null
  return {
    address: proposalClass.value.collateral.address,
    chainId: proposalClass.value.collateral.chainId,
    thumbnailUrl: proposalClass.value.collateral.image,
    symbol: proposalClass.value.collateral.symbol,
    decimals: proposalClass.value.collateral.decimals,
  }
})

</script>

<style scoped>
.modal-proposal-preview {
  &__body {
    margin-top: 5rem;
    flex-direction: column;
    height: 452px;
    display: flex;
  }

  &__button {
    display: flex;
    justify-content: end;
  }

  &__info {
    max-width: 49rem;
    height: 22rem;
    overflow: auto;
    word-break: break-all;
  }

  &__collateral-and-proposal-info {
    display: flex;
    gap: 2rem;
    width: 100%;
  }

  &__collateral-detail {
    width: 50%;
    border-right: 1px solid gray;
    padding: 0 40px 2rem 0;
  }

  &__expires-in {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 14px;
  }

  &__warnings-and-button-container {
    display: flex;
    justify-content: end;
    font-size: 15px;
    width: 100%;
  }

  &__proposal-detail {
    width: 50%;
  }

  &__rewards-to-be-earned {
    margin: auto 1rem;
  }
}
</style>
