import { BrowserProvider, JsonRpcSigner } from 'ethers'
import type { Account, Chain, Client, Transport } from 'viem'
import { useConnectorClient } from '@wagmi/vue'
import type { SupportedChain } from '@/constants/chains/types'
import type { PwnWagmiConfig } from './usePwnWagmiConfig'
import { computed } from 'vue'
import type { Ref } from 'vue'

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new BrowserProvider(transport, network)
  const signer = new JsonRpcSigner(provider, account.address)
  return signer
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner(chainId: Ref<SupportedChain>) {
  const { data: client } = useConnectorClient<PwnWagmiConfig>({ chainId })
  // TODO does this work fine or do we need some watchConnectorClient?
  return computed(() => client.value ? clientToSigner(client.value) : undefined)
}
