<template>
  <div class="token-page">
    <TokenPageHeader
      class="token-page__header"
      :refresh-erc20="handleRefresh"
      :is-asset-refreshing="isRefetching"
      :chain-id="parsedErc20?.chainId"/>
    <div
      id="asset-content"
      class="token-page__content">
      <div class="token-page__column token-page__column--left">
        <TokenPageBasicInfo
          :is-refetching="isRefetching"
          @refresh="handleRefresh"/>
      </div>

      <div class="token-page__column token-page__column--right">
        <TokenPageChart
          v-if="parsedErc20?.symbol"
          :address="parsedErc20.address"
          :chain-id="parsedErc20.chainId"/>
      </div>
    </div>
  </div>

  <div class="token-page__table-and-footer">
    <div class="token-page__content">
      <div class="token-page__content-left">
        <div class="token-page__tabs">
          <BaseButton
            class="token-page__tab"
            is-full-width
            :variant="ButtonVariant.Outline"
            :color="activeTab === Tabs.Lend ? ButtonColor.Primary : ButtonColor.White"
            :font="ButtonFont.Screener"
            button-text="LEND"
            @click="() => setActiveTab(Tabs.Lend)"/>
          <BaseButton
            class="token-page__tab"
            is-full-width
            :font="ButtonFont.Screener"
            :color="activeTab === Tabs.Borrow ? ButtonColor.Primary : ButtonColor.White"
            :variant="ButtonVariant.Outline"
            button-text="BORROW"
            @click="() => setActiveTab(Tabs.Borrow)"/>
        </div>

        <div class="token-page__filters">
          <div class="token-page__filters-left">
            <div class="token-page__filters-row">
              <ProposalFilters
                v-model:show-history="showHistory"
                is-read-only
                :selected-collateral="parsedErc20"/>
            </div>
          </div>
        </div>

        <RouterView v-slot="{ Component, route }">
          <div :key="route.path">
            <KeepAlive>
              <component
                :is="Component"
                v-if="parsedErc20?.chainId"
                :selected-chains="[parsedErc20?.chainId]"
                :selected-collateral-filter-assets="[parsedErc20]"
                :include-collateral-without-price="true"
                :include-credit-without-price="globalFiltersStore.showAssetsWithoutPricing"
                :include-unverified-collateral="true"
                :include-unverified-credit="globalFiltersStore.showUnverifiedAssets"
                :show-history="showHistory"
                :type="activeTab === Tabs.Borrow ? 'borrow' : 'lend'"/>
            </KeepAlive>
          </div>
        </RouterView>
      </div>
      <ProposalForm
        v-if="!isStarknet"
        is-collateral-locked
        :selected-collateral="parsedErc20"
        :selected-credit="selectedCredit"
        :is-select-collection-asset="isActiveTabBorrow"
        :select-credit="selectCredit"
        borrowing-is-enabled
        :is-active-tab-borrow="isActiveTabBorrow"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { computed, onMounted, ref, watch } from 'vue'
import TokenPageBasicInfo from '@/revamp/pages/token/TokenPageBasicInfo.vue'
import TokenPageHeader from '@/revamp/pages/token/TokenPageHeader.vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import TokenPageChart from '@/revamp/pages/token/TokenPageChart.vue'
import type { Address } from 'viem'
import ProposalForm from '@/revamp/components/proposal-form/ProposalForm.vue'
import BaseButton, { ButtonColor, ButtonFont, ButtonVariant } from '@/general-components/BaseButton.vue'
import router from '@/router'
import RouteName from '@/router/RouteName'
import type { AssetMetadata } from '@/modules/common/assets/AssetClasses'
import { AssetWithAmount } from '@/modules/common/assets/AssetClasses'
import { getChainIdFromChainName } from '@/utils/chain'
import type { ChainName, ChainNameLowercase } from '@/constants/chains/types'
import { useFetchAssetMetadata } from '@/modules/common/backend/generated'
import useERC20Fetch from '@/modules/common/assets/fetchers/useERC20Fetch'
import { asyncComputed, useLocalStorage } from '@vueuse/core'
import { useNoProposalsStore } from '@/revamp/hooks/useNoProposalsStore'
import ProposalFilters from '@/revamp/components/proposal-filters/ProposalFilters.vue'
import { useGlobalFiltersStore } from '@/modules/common/filters/global/useGlobalFiltersStore'
import { isStarknet } from '@/modules/common/pwnSpace/pwnSpaceDetail'

const { address: userAddress } = useCustomAccount()
const { fetchUserERC20Balance } = useERC20Fetch()
enum Tabs {
  Borrow = 'borrow',
  Lend = 'lend',
}

const activeTab = useLocalStorage<Tabs>('tokenPageLastActiveTab', Tabs.Borrow)
const selectedCredit = ref<AssetWithAmount | null>(null)

const globalFiltersStore = useGlobalFiltersStore()

const route = useRoute()
const showHistory = useLocalStorage('show-history-on-token-detail', false)

const chainId = computed(() => getChainIdFromChainName(route.params.chainName as ChainName | ChainNameLowercase))
const contractAddress = computed(() => route.params.contractAddress as Address)

const refresh = ref(false)
const metadataParams = computed(() => ({
  refresh: refresh.value,
}))

const erc20MetadataQuery = useFetchAssetMetadata(
  chainId as unknown as string,
  contractAddress,
  '',
  metadataParams,
  {
    query: {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 2,
    },
  },
)

const parsedErc20 = asyncComputed(async () => {
  if (!erc20MetadataQuery.data?.value?.data) {
    return null
  }
  const userBalance = userAddress.value ? await fetchUserERC20Balance(chainId.value, contractAddress.value, userAddress.value) : 0n

  const erc20 = AssetWithAmount.createFromBackendModel({
    ...erc20MetadataQuery.data?.value?.data,
    balance: userBalance.toString(),
  })
  return erc20
})

const MIN_REFETCH_DURATION = 1000 // 1 second minimum duration

const isRefetching = ref(false)

watch(() => erc20MetadataQuery.isRefetching.value, (newIsRefetching) => {
  if (newIsRefetching) {
    isRefetching.value = true
    setTimeout(() => {
      if (!erc20MetadataQuery.isRefetching.value) {
        isRefetching.value = false
      }
    }, MIN_REFETCH_DURATION)
  } else {
    setTimeout(() => {
      isRefetching.value = false
    }, MIN_REFETCH_DURATION)
  }
}, { immediate: true })

const handleRefresh = async () => {
  refresh.value = true
  await erc20MetadataQuery.refetch()
}

const selectCredit = (asset?: AssetWithAmount | AssetMetadata) => {
  selectedCredit.value = asset ? new AssetWithAmount(asset) : null
}

const setActiveTab = (tab: Tabs) => {
  activeTab.value = tab

  switch (tab) {
  case Tabs.Borrow:
    router.replace({
      name: RouteName.RevampTokenBorrowing,
    })
    break
  case Tabs.Lend:
    router.replace({
      name: RouteName.RevampTokenLending,
    })
    break
  }
}
const isActiveTabBorrow = computed(() => activeTab.value === Tabs.Borrow)

watch(() => route.params, () => {
  if (route.name === RouteName.TokenPage) {
    setActiveTab(activeTab.value)
  }
}, { immediate: true })
onMounted(() => {
  const isLendHighlighted = useRoute().query.highlight === 'true'
  if (isLendHighlighted) {
    useNoProposalsStore().handleHighlightForm()
  }
})
</script>

<style scoped>
.token-page {
  position: relative;

  &__header {
    width: 100%;
    font-family: var(--font-family-screener);
  }

  &__image {
    width: 29.125rem;
    height: 29.125rem;
    object-fit: contain;
    object-position: center;
  }

  &__content {
    display: flex;
    min-height: 25rem;
    padding-bottom: 4rem;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 1.2rem;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    &--left {
      max-width: 30rem;
      width: 100%;
    }

    &--right {
      width: 40rem;
      margin-left: auto;
    }
  }

  &__tabs {
    display: flex;
    gap: 2rem;

    margin-bottom: 1.5rem;
  }

  &__tab {
    width: 100%;
  }

  &__content-left {
    flex: 1;

    padding: 1rem;
    border: 1px solid #434343;
  }

  &__content-right {
    flex: 1;

    padding: 1rem;
    border: 1px solid #434343;

    max-width: 360px;
  }

  &__table-and-footer {
    position: relative;
  }

  &__table-history {
    margin: 1rem 0;
    padding: 0;

    &--bottom-dashed-line {
      padding: 0;

      &::after {
        content: "";
        display: block;
        height: 1px;
        background-image: var(--border-gray-dashed);
        background-size: auto 2px;
        /* small hack how to hide buggy double border (top + bottom), when height is 1px */
      }
    }
  }

  @media only screen and (--small-viewport) {
    margin-top: 1rem;

    padding-top: 0;

    &__content {
      flex-direction: column;
      gap: 2rem;
    }

    &__column--right {
      width: 100%;
      min-width: 100%;
    }
  }

  &__table {
    position: relative;
    width: 100%;
    padding-bottom: 7rem;

    &--edit-proposal {
      width: calc(100% - 27rem);
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &-left,
    &-right {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }

  &__proposals-title {
    font-size: 1.125rem;
  }
}
</style>
