<template>
  <div class="pwn-safe-tokenize-modal-confirmation-step__container">
    <p class="pwn-safe-tokenize-modal-confirmation-step__success-text">
      Success! {{ atrTokenInfo }} Minted!
    </p>
    <div class="pwn-safe-tokenize-modal-confirmation-step__selected-assets-container">
      <div class="pwn-safe-tokenize-modal-confirmation-step__tokenized-assets-container">
        <SelectedAssetCard
          v-for="(asset, i) in assetsToDisplay"
          :key="asset.id"
          display-input
          display-amount
          class="pwn-safe-tokenize-modal-confirmation-step__tokenized-asset"
          :style="getPositioning(i)"
          :asset="asset.assetOfAtrToken"/>
      </div>
      <div class="pwn-safe-tokenize-modal-confirmation-step__arrow">
        <ArrowRightLongIconSvg
          width="80"
          alt="arrow-right"/>
      </div>
      <div class="pwn-safe-tokenize-modal-confirmation-step__atr-tokens-container">
        <SelectedAtrTokenCard
          v-for="(asset, i) in assetsToDisplay"
          :key="asset.id"
          class="pwn-safe-tokenize-modal-confirmation-step__atr-tokens"
          :style="getPositioning(i)"
          :atr-token="asset"/>
      </div>
    </div>
    <p class="pwn-safe-tokenize-modal-confirmation-step__note">
      You can now use your ATR Token in a Loan Request and maintain your holding rights
      while the loan is active and funded, any benefits provided by your NFT will be held by
      <BaseLink
        class="pwn-safe-tokenize-modal-confirmation-step__safe-link"
        :to="pwnSafeRoute"
        @click="handleClose">
        {{ pwnSafeWhereTokenizing?.name }}
      </BaseLink>
    </p>
    <div class="pwn-safe-tokenize-modal-confirmation-step__buttons-container">
      <BaseButton
        :button-text="buttonText"
        @click="handleClose"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/general-components/BaseButton.vue'
import usePwnSafeTokenizeModal from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/modal/usePwnSafeTokenizeModal'
import usePwnSafeTokenize from '@/modules/pages/pwn-safe/pwn-safe-detail/tokenize/usePwnSafeTokenize'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { computed } from 'vue'
import SelectedAssetCard from '@/general-components/SelectedAssetCard.vue'
import SelectedAtrTokenCard from '@/general-components/SelectedAtrTokenCard.vue'
import RouteName from '@/router/RouteName'
import BaseLink from '@/general-components/BaseLink.vue'
import { useRouter } from 'vue-router'
import type { RouteLocationRaw } from 'vue-router'
import ArrowRightLongIconSvg from '@/assets/icons/arrow-right-long.svg'
import { storeToRefs } from 'pinia'

const MAX_ASSETS_TO_DISPLAY = 4
const ROUNDING_CONSTANT = 100

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { isSelectedCurrentWallet, selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const { closeModal } = usePwnSafeTokenizeModal()
const { pwnSafeWhereTokenizing, successfullyTokenizedAssets } = usePwnSafeTokenize()

const router = useRouter()
const handleClose = async () => {
  closeModal()
  if (isSelectedCurrentWallet.value) {
    await router.push({ name: RouteName.PwnSafeCurrentWalletAssets })
  } else {
    await router.push({
      name: RouteName.PwnSafeDetailAssets,
      params: {
        chainId: selectedPwnSafe.value?.chainId,
        safeAddress: selectedPwnSafe.value?.safeAddress,
      },
    })
  }
}

const buttonText = computed(() => {
  // @ts-expect-error FIXME: strictNullChecks
  return isSelectedCurrentWallet.value ? 'Go to current wallet' : `Go to ${pwnSafeWhereTokenizing.value.name}`
})
const assetsToDisplay = computed(() => successfullyTokenizedAssets.value.slice(-4))
const atrTokenInfo = computed(() => assetsToDisplay.value.map(atrToken => atrToken.primaryInfo).join(', '))

const getLeftPosition = (i: number) => {
  return Math.round((i / (assetsToDisplay.value.length - 1)) * MAX_ASSETS_TO_DISPLAY * ROUNDING_CONSTANT) / ROUNDING_CONSTANT
}

const getRightPosition = (i: number) => {
  return MAX_ASSETS_TO_DISPLAY - Math.round((i / (assetsToDisplay.value.length - 1)) * MAX_ASSETS_TO_DISPLAY * ROUNDING_CONSTANT) / ROUNDING_CONSTANT
}

const getPositioning = (i: number) => {
  return {
    left: `${getLeftPosition(i) || 0}rem`,
    right: `${getRightPosition(i) || 0}rem`,
  }
}
const pwnSafeRoute: RouteLocationRaw = {
  name: RouteName.PwnSafeDetailAssets,
  params: {
    // @ts-expect-error FIXME: strictNullChecks
    safeAddress: pwnSafeWhereTokenizing.value.safeAddress,
    // @ts-expect-error FIXME: strictNullChecks
    chainId: pwnSafeWhereTokenizing.value.chainId,
  },
}
</script>

<style scoped>
p {
  margin: 0;
  line-height: 1.5;
}

.pwn-safe-tokenize-modal-confirmation-step {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-top: 4rem;
  }

  &__success-text {
    color: var(--positive-bright);
  }

  &__selected-assets-container {
    display: flex;
    min-height: 11.25rem;
  }

  &__tokenized-assets-container {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    opacity: 0.5;
    width: 45%;
  }

  &__tokenized-asset,
  &__atr-tokens {
    margin: auto;
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  &__atr-tokens-container {
    display: flex;

    justify-content: center;
    width: 45%;
  }

  &__safe-link {
    color: var(--primary-color-1);
  }

  &__buttons-container {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  @media only screen and (--mobile-viewport) {
    &__container {
      margin-top: 2rem;
    }

    &__selected-assets-container {
      flex-direction: column;
      gap: 3rem;
    }

    &__tokenized-assets-container,
    &__atr-tokens-container {
      width: 100%;
    }

    &__arrow {
      transform: rotate(90deg);
    }

    &__note {
      font-size: 0.8rem;
    }
  }
}
</style>
