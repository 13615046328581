import {
  SupportedChain,
  ALL_SUPPORTED_CHAINS,
  ALL_SUPPORTED_CHAINS_WAGMI,
  TESTNET_CHAINS,
} from '@/constants/chains/types'
import { includeTestnetsInMultichainResults } from '@/utils/url'
import { isPwnSpace, isStarknet, pwnSpaceConfig } from '@/modules/common/pwnSpace/pwnSpaceDetail'

// TODO delete this and just use useChains() or getChains?
function getEnabledChains() {
  if (isPwnSpace) {
    // if this would be pwn space, the pwnSpaceConfig would already have value, since we are
    //  waiting for fetching of the pwn space config in the initBeforeAppCreated
    if (!pwnSpaceConfig?.supportedChains) {
      throw new Error('Pwn space config is not loaded yet.')
    }

    return pwnSpaceConfig.supportedChains as [SupportedChain, ...SupportedChain[]]
  }

  const chainIdsArray = (import.meta.env.VITE_SUPPORTED_CHAINS as string).split(',')
  return chainIdsArray.flatMap(chainId => {
    if (!ALL_SUPPORTED_CHAINS.includes(Number(chainId))) {
      // misconfiguration
      throw new RangeError(`Chain with id '${chainId}' is not in SupportedChain enum.`)
    }

    if (!includeTestnetsInMultichainResults && TESTNET_CHAINS.includes(Number(chainId))) {
      return []
    }

    return [Number(chainId)]
  }) as [SupportedChain, ...SupportedChain[]]
}

function getEnabledChainsWagmi(enabledChains: [SupportedChain, ...SupportedChain[]]) {
  const _allEnabledChainsWagmi = ALL_SUPPORTED_CHAINS_WAGMI.filter(wagmiChain => enabledChains.includes(wagmiChain.id))
  if (!_allEnabledChainsWagmi?.length) {
    throw new Error('There must always be at least 1 enabled chain passed to wagmi.')
  }
  return _allEnabledChainsWagmi as [typeof _allEnabledChainsWagmi[number], ...typeof _allEnabledChainsWagmi[number][]]
}

// TODO make those readonly on export?
type EnabledChains = ReturnType<typeof getEnabledChains>
export let enabledChains: EnabledChains

// TODO computed?
type EnabledChainsWagmi = ReturnType<typeof getEnabledChainsWagmi>
export let enabledChainsWagmi: EnabledChainsWagmi

export function initEnabledChains() {
  if (enabledChains !== undefined || enabledChainsWagmi !== undefined) {
    // eslint-disable-next-line no-console
    console.warn('initEnabledChains should be called only once!')
    return
  }

  if (!isStarknet) {
    enabledChains = getEnabledChains()
    enabledChainsWagmi = getEnabledChainsWagmi(enabledChains)
  } else {
    enabledChains = [SupportedChain.StarknetSepolia]
  }
  // eslint-disable-next-line no-console
  console.debug(`Enabled chains: ${enabledChains.join(', ')}`)
}
