<template>
  <div
    v-if="parsedDecimalPoints === null"
    class="safe-display-decimals">
    {{ value }}
  </div>
  <div
    v-else
    class="safe-display-decimals">
    <span>{{ parsedValue.integerPart }}</span>
    <span>.</span>
    <div>0<span class="safe-display-decimals__repeated-zeroes">{{ parsedDecimalPoints?.repeatedZeroes }}</span>{{ parsedDecimalPoints?.restDecimals }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, toRefs } from 'vue'
import { parseRepeatedDecimal } from './utils'

const props = defineProps<{
  value: string
}>()

const { value } = toRefs(props)

const parsedDecimalPoints = computed(() => {
  if (value.value === '0' || value.value === '0.00' || !value.value || !value.value.toString().includes('.')) {
    return null
  }

  const parsed = parseRepeatedDecimal(value.value)
  return parsed
})

const parsedValue = computed(() => {
  const [integerPart, decimalPart] = value.value.split('.')

  return {
    integerPart,
    decimalPart,
  }
})

</script>

<style scoped>
.safe-display-decimals {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: center;
  white-space: nowrap;

  width: min-content;

  &__repeated-zeroes {
    font-size: 70%;
    transform: translateY(25%);
    display: inline-block;
  }
}
</style>
