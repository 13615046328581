import { computed, unref } from 'vue'
import type { MaybeRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { queries } from '@/modules/queries'
import { isAddress } from 'viem'
import type { Address } from 'viem'
import type { SupportedChain } from '@/constants/chains/types'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import { storeToRefs } from 'pinia'

export const useSafesList = (userAddress: MaybeRef<Address>, chainId: MaybeRef<SupportedChain>) => {
  const pwnSafeDetailStore = usePwnSafeDetailStore()
  const { isSelectedCurrentWallet, walletAsSafe } = storeToRefs(pwnSafeDetailStore)

  const availableWalletsToTransferQuery = useQuery({
    ...queries.safe.list(userAddress, chainId),
    enabled: isAddress(unref(userAddress)) && !!unref(chainId),
  })

  const safes = computed(() => {
    const shouldAppendCurrentWallet = !isSelectedCurrentWallet.value
    const results = availableWalletsToTransferQuery.data.value || []
    return [shouldAppendCurrentWallet ? walletAsSafe.value : null, ...results].filter(Boolean)
  })

  return {
    safes,
    isLoading: availableWalletsToTransferQuery.isLoading,
  }
}
