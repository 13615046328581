<template>
  <div class="pwn-safe-settings">
    <PwnSafePageHeader
      is-settings-view
      :label="selectedPwnSafe?.name!"
      :icon="safeIcon.default"
      :selected-pwn-safe="selectedPwnSafe"
      :address="selectedPwnSafe?.safeAddress!"
      @go-back="router.back()"/>
    <div class="pwn-safe-settings__container">
      <PwnSafeDetailSettingsRename class="pwn-safe-settings__rename"/>
      <PwnSafeDetailSettingsNetwork class="pwn-safe-settings__network"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import PwnSafePageHeader from '@/modules/pages/pwn-safe/PwnSafePageHeader.vue'
import { usePwnSafeDetailStore } from '@/modules/pages/pwn-safe/pwn-safe-detail/usePwnSafeDetailStore'
import * as safeIcon from '@/assets/icons/safe.svg'
import PwnSafeDetailSettingsRename from '@/modules/pages/pwn-safe/pwn-safe-detail/settings/PwnSafeDetailSettingsRename.vue'
import PwnSafeDetailSettingsNetwork from '@/modules/pages/pwn-safe/pwn-safe-detail/settings/PwnSafeDetailSettingsNetwork.vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

const pwnSafeDetailStore = usePwnSafeDetailStore()
const { selectedPwnSafe } = storeToRefs(pwnSafeDetailStore)

const router = useRouter()
</script>

<style scoped>
.pwn-safe-settings {
  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__rename {
    width: 70%;
  }

  &__network {
    width: 28%;
  }

  @media only screen and (--mobile-viewport) {
    &__container {
      flex-direction: column-reverse;
      gap: 1rem;
    }

    &__rename {
      width: 100%;
    }

    &__network {
      width: 100%;
    }
  }
}
</style>
