<template>
  <BaseSection
    :heading="heading"
    :is-expandable="false">
    <SelectedAssetsTable
      :selected-assets="selectedAssets"
      :is-radio-button-table="isRadioButtonTable"
      @on-asset-amount-change="emitOnAssetAmountChange"
      @on-asset-select="emitOnAssetSelect"/>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import SelectedAssetsTable from '@/modules/sections/selected-assets/SelectedAssetsTable.vue'
import { computed, toRefs } from 'vue'
import type { AssetWithAmount } from '@/modules/common/assets/AssetClasses'

interface Props {
  selectedAssets: AssetWithAmount[],
  hasHeading?: boolean
  isRadioButtonTable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasHeading: true,
  isRadioButtonTable: false,
})
const { selectedAssets, hasHeading } = toRefs(props)

const heading = computed(() => hasHeading.value ? 'Selection' : '')

interface Emits {
  (e: 'on-asset-select', asset: AssetWithAmount): void
  (e: 'on-asset-amount-change', asset: AssetWithAmount): void
}
const emit = defineEmits<Emits>()

const emitOnAssetSelect = (asset: AssetWithAmount) => emit('on-asset-select', asset)
const emitOnAssetAmountChange = (asset: AssetWithAmount) => emit('on-asset-amount-change', asset)
</script>
