<template>
  <BaseSection
    class="section-your-lending"
    with-gray-background
    heading="">
    <SectionYourLendingStats
      v-if="!searchTerm"
      class="section-your-lending__stats"/>
    <SectionYourThesisComittments
      class="section-your-lending__thesis-committments"/>
    <SectionYourLending
      class="section-your-lending__lending"/>
    <SectionYourOffers
      class="section-your-lending__offers"/>
  </BaseSection>
</template>

<script setup lang="ts">
import BaseSection from '@/general-components/BaseSection.vue'
import SectionYourLending from '@/modules/sections/your-lending/SectionYourLending.vue'
import SectionYourOffers from '@/modules/sections/your-offers/SectionYourOffers.vue'
import SectionYourLendingStats from '@/modules/sections/your-stats/SectionYourLendingStats.vue'
import SectionYourThesisComittments from '@/modules/sections/your-thesis-committments/SectionYourThesisComittments.vue'
import useDashboard from '@/modules/pages/dashboard/hooks/useDashboard'

const { searchTerm } = useDashboard()

</script>

<style scoped>
.section-your-lending {
  &__card {
    width: 521px;
    height: 242px;
    margin: 0 auto;
  }

  &__start-lending {
    height: var(--section-controls-height);
  }

  &__lending,
  &__offers,
  &__stats,
  &__card {
    margin-bottom: 3rem;
  }

  @media only screen and (--small-viewport) {
    &__offers {
      margin-bottom: 4rem;
    }

    &__lending,
    &__offers {
      row-gap: 1rem;
      display: flex;
      flex-flow: column;
    }

    &__card {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
