<template>
  <Pagination
    v-if="pagination"
    :limit="pagination?.limit"
    :current-offset="pagination.currentOffset"
    :total-results="pagination.count"
    @on-offset-change="setPage"/>
</template>

<script setup lang="ts">
import type { SearchCategory } from '@/modules/common/pwn/explorer/models'
import { useExplorerSearchPaginationStore } from '@/modules/common/pwn/explorer/useExplorerSearchPaginationStore'
import { computed } from 'vue'
import Pagination from '@/modules/common/pagination/Pagination.vue'

type Props = {
  category: SearchCategory
}
const props = defineProps<Props>()

const paginationStore = useExplorerSearchPaginationStore()
const pagination = computed(() => paginationStore.categoriesPagination[props.category])

const setPage = (newOffset: number) => {
  paginationStore.actions.setCurrentOffset(props.category, newOffset)
}
</script>
