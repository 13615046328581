import { defineStore } from 'pinia'
import type NFTAssetCollection from '@/modules/common/assets/NFTAssetCollection'
import type CollectionStats from '@/modules/common/assets/typings/CollectionStats'
import RouteName from '@/router/RouteName'
import router from '@/router'
import { getChainIdFromChainName } from '@/utils/chain'
import useMetadataFetch from '@/modules/common/assets/fetchers/useMetadataFetch'
import { compareAddresses, isObject } from '@/utils/utils'
import { ref } from 'vue'
import type { ChainName, SupportedChain } from '@/constants/chains/types'
import { CHAINS_CONSTANTS } from '@/constants/chains/all'
import * as Sentry from '@sentry/vue'
import { getAddress, isAddress } from 'viem'
import type { Address } from 'viem'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import { useCustomAccount } from '@/modules/common/web3/useCustomAccount'
import StoreIds from '@/constants/storeIds'

export type CollectionRouteParams = {
  chainName?: ChainName
  contractAddress?: string
  slug?: string
}

export type _FetchCollectionDataWithSlugParams = {
  chainId: SupportedChain
  contractAddress: Address
  slug?: never
}

export type _FetchCollectionDataWithChainAndAddressParams = {
  chainId?: never
  contractAddress?: never
  slug: string
}
export type FetchCollectionDataParams = (_FetchCollectionDataWithChainAndAddressParams | _FetchCollectionDataWithSlugParams) & {refresh?: boolean}

export const useCollectionPageStore = defineStore(StoreIds.collectionPage, () => {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const collection = ref<NFTAssetCollection>(null)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const collectionStats = ref<CollectionStats>(null)
  const isFetchingCollection = ref(false)
  const isFetchingCollectionOffers = ref(false)
  const isCollectionOffersFetched = ref(false)
  const isOffersTabSelectedFromLastVisit = ref(false)

  const { isConnected } = useCustomAccount()

  const goToMakeCollectionOffer = () => {
    if (!isConnected.value) {
      useWeb3Modal().open()
    }

    router.push({
      name: RouteName.CollectionMakeOfferByContractAddress,
      params: {
        chainName: CHAINS_CONSTANTS[collection.value.chainId].general.chainName,
        contractAddress: collection.value.contractAddress,
      },
    })
  }

  const isValidRouteParams = (routeParams: CollectionRouteParams): boolean => {
    return Boolean(routeParams && (routeParams?.slug || (routeParams?.contractAddress && routeParams?.chainName && isAddress(routeParams.contractAddress) && getChainIdFromChainName(routeParams.chainName))))
  }

  const parseRouteParams = (routeParams: CollectionRouteParams): FetchCollectionDataParams | null => {
    if (!isValidRouteParams(routeParams)) {
      Sentry.captureMessage(`User tried to go to collection page with invalid url params: ${isObject(routeParams) ? JSON.stringify(routeParams) : routeParams}`)
      return null
    }

    if (routeParams?.slug) {
      return {
        slug: routeParams.slug,
      }
    } else {
      return {
        chainId: getChainIdFromChainName(routeParams.chainName!),
        contractAddress: getAddress(routeParams.contractAddress!),
      }
    }
  }

  const fetchCollectionDataIfNotAlreadyFetched = async (collectionParams: ReturnType<typeof parseRouteParams>) => {
    if (!collectionParams?.slug && (!collectionParams?.contractAddress || !collectionParams?.chainId)) {
      return null
    }

    if (!collectionParams?.refresh && collectionParams?.slug && collection.value?.openseaSlug === collectionParams.slug) {
      // refresh flag was not passed and the stored collection in the store is the same as requested one
      return true
    }

    if (!collectionParams?.refresh && collectionParams?.contractAddress && collectionParams?.chainId && compareAddresses(collection.value?.contractAddress, collectionParams.contractAddress) && collection.value?.chainId === collectionParams.chainId) {
      // refresh flag was not passed and the stored collection in the store is the same as requested one
      return true
    }

    isFetchingCollection.value = true
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const collectionData = await useMetadataFetch().fetchNftCollection(collectionParams?.slug || collectionParams?.contractAddress, collectionParams?.chainId, collectionParams?.refresh)

    if (collectionData) {
      collection.value = collectionData
    } else {
      isFetchingCollection.value = false
      return null
    }

    const stats = await useMetadataFetch().fetchCollectionStats(collectionData.chainId, collection.value?.contractAddress, collectionParams?.refresh)
    if (stats) {
      collectionStats.value = stats
    }
    isFetchingCollection.value = false

    return true
  }

  return {
    collection,
    collectionStats,
    isFetchingCollection,
    isFetchingCollectionOffers,
    isCollectionOffersFetched,
    goToMakeCollectionOffer,
    fetchCollectionDataIfNotAlreadyFetched,
    parseRouteParams,
    isValidRouteParams,
    isOffersTabSelectedFromLastVisit,
  }
})
