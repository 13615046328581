<template>
  <AsyncNoProposals
    v-if="showNoProposalsBanner"/>
  <div
    v-if="withTitle"
    class="proposals-table__title">
    Proposals
  </div>
  <div
    v-if="!noProposals"
    ref="tableContainerRef"
    class="container"
    style="overflow: auto; position: relative;"
    :style="{ maxHeight: maxHeight }"
    @scroll="fetchMoreOnBottomReached">
    <table style="display: grid;">
      <thead class="proposals-table__header">
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
          }">
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            :colspan="header.colSpan"
            :style="{ width: `${header.column.getSize()}px` }"
            :class="['proposals-table__header-cell', {
              'proposals-table__header-cell--sorted' : header.column.getIsSorted(),
            }]">
            <div
              v-if="!header.isPlaceholder"
              :class="['text-left', header.column.getCanSort() ? 'cursor-pointer select-none' : '', {'proposals-table__header-cell--sorted' : header.column.getIsSorted(),}]"
              @click="getSortingHandler($event, header.column.getToggleSortingHandler())">
              <div
                style="position: relative; width: fit-content; display: flex; align-items: center; white-space: nowrap;">
                <FlexRender
                  :render="header.column.columnDef.header"
                  :props="header.getContext()"/>
                <TableSortingIndicator
                  v-if="header.column.getCanSort() && header.id !== columnIds.ltv"
                  :value="header.column.getIsSorted() || 'none'"/>
              </div>
            </div>
          </th>
        </tr>
      </thead>

      <tbody
        ref="tableBodyRef"
        style="display: grid; height: 100%; position: relative;"
        :style="{ height: `${rowVirtualizer.getTotalSize()}px` }">
        <TransitionGroup name="proposals-table--fade">
          <tr
            v-for="row in virtualRows"
            :key="row.index"
            :ref="customRefHandler"
            :data-index="row.index"
            :class="[ {'proposals-table__row--disabled': isRowDisabled(rows[row.index].original)} ]"
            :style="{
              display: 'flex',
              position: 'absolute',
              transform: `translateY(${row.start}px)`,
              width: '100%',
              justifyContent: 'space-between',
            }"
            @click="(event) => isRowDisabled(rows[row.index].original) ? '' : navigateToProposalPage(rows[row.index].original, event)">
            <td
              v-for="cell in rows[row.index].getVisibleCells()"
              :key="cell.id"
              :style="{
                width: `${cell.column.getSize()}px`,
              }">
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"/>
            </td>
          </tr>
        </TransitionGroup>
      </tbody>
    </table>
  </div>
  <div v-if="isFetching">
    <BaseSkeletor/>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, h, watchEffect, watch, toRefs, inject, defineAsyncComponent } from 'vue'
import { useVirtualizer } from '@tanstack/vue-virtual'

import { FlexRender, getCoreRowModel, useVueTable, createColumnHelper, getFilteredRowModel } from '@tanstack/vue-table'
import type { BuiltInSortingFn, ColumnFiltersState, SortingState } from '@tanstack/vue-table'
import ProposalCollateral from '@/revamp/components/tables/cells/ProposalCollateral.vue'
import ProposalInterest from '@/revamp/components/tables/cells/ProposalInterest.vue'
import ProposalStatus from '@/revamp/components/tables/cells/ProposalStatus.vue'
import ProposalActions from '@/revamp/components/actions/ProposalActions.vue'
import {

  ProposalAndLoanListStatusesItem,
  useProposalAndLoanListInfinite,
} from '@/modules/common/backend/generated'
import type { ProposalAndLoanListOrderByItem, ProposalAndLoanListParams } from '@/modules/common/backend/generated'
import { useSelectedChainsStore } from '@/modules/common/useSelectedChains'
import { storeToRefs } from 'pinia'
import type { AssetDetail, Proposal } from '@/revamp/modules/proposals/types'
import BaseSkeletor from '@/general-components/BaseSkeletor.vue'
import QuestionMarkTooltip from '@/general-components/QuestionMarkTooltip.vue'
import { useProposalFilters } from '@/revamp/modules/proposals/useProposalFilters'
import { keepPreviousData } from '@tanstack/vue-query'
import ProposalDuration from '@/revamp/components/tables/cells/ProposalDuration.vue'
import { useRouter } from 'vue-router'
import AssetAmount from '@/revamp/components/AssetAmount.vue'
import LoanStatusCell from '@/revamp/components/tables/cells/LoanStatusCell.vue'
import { useThrottle } from '@vueuse/core'
import { formatUnits } from 'viem'
import type { Address, Hex } from 'viem'
import { getChainName } from '@/utils/chain'
import TableSortingIndicator from '@/revamp/components/tables/TableSortingIndicator.vue'
import type { SetChainFilterOption } from '@/modules/pages/pwn-explorer/types'
import { injectedProposalsCountKeyName } from '@/revamp/hooks/useInjectedProposalsCount'
import type { AssetWithAmount, AssetMetadata } from '@/modules/common/assets/AssetClasses'
import type { CollateralTypeFilter } from '@/modules/common/filters/collateral-type/useCollateralTypeFilter'
import {
  getLoanTokenAddressForProposalOrLoan,
  isLoan,
} from '@/modules/common/pwn/utils'
import { V1_2ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import type { ProposalType } from '@/modules/common/pwn/proposals/ProposalClasses'
import RouteName from '@/router/RouteName'
import LtvColored from '@/revamp/components/LtvColored.vue'
import { isPwnSpace, isStarknet, pwnSpaceConfig } from '@/modules/common/pwnSpace/pwnSpaceDetail'
import { useTopTokensStore } from '@/constants/useTopTokensStore'
import { enabledChains } from '@/modules/common/web3/useEnabledChains'

const AsyncNoProposals = defineAsyncComponent(() =>
  import('@/revamp/components/NoProposals.vue'),
)

type Props = {
  type: 'borrow' | 'lend' | 'all';
  proposalIdToFilterOut?: string;
  loanIdToFilterOut?: string;
  q?: string;

  userAddress?: Address;
  includeLoans?: boolean;
  includeProposals?: boolean;
  showHistory?: boolean;
  hideNoProposalsBanner?: boolean;

  selectedCollateralFilterAssets?: AssetMetadata[] | AssetWithAmount[];
  selectedCreditFilterAssets?: AssetMetadata[] | AssetWithAmount[];

  collateralType?: CollateralTypeFilter

  includeCollateralWithoutPrice?: boolean
  includeCreditWithoutPrice?: boolean
  includeUnverifiedCollateral: boolean
  includeUnverifiedCredit: boolean

  /**
   * if true, the chains filter will be ignored
   */
  ignoreChains?: boolean;

  /**
   * default is 600px
   */
  maxHeight?: string;

  // unused by now needs for interface compatability with the explorer tables
  selectedChains?: SetChainFilterOption[] | 'all';

  withTitle?: boolean;
  thesisId?: string;
  isHideHandForOwnProposal?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  maxHeight: '650px',
})

const isHideHandForOwnProposal = computed(() => props.isHideHandForOwnProposal ?? false)

const router = useRouter()

const fetchSize = 15
const tableContainerRef = ref(null)
const selectedChainsStore = useSelectedChainsStore()
const { selectedValues } = storeToRefs(selectedChainsStore)
const { q, userAddress, type, showHistory, includeCollateralWithoutPrice, includeCreditWithoutPrice, includeUnverifiedCollateral, includeUnverifiedCredit } = toRefs(props)
const { isAssetFake } = useTopTokensStore()
const columnIds = {
  collateral: 'collateral',
  ltv: 'ltv',
  usdAmount: 'usdAmount',
  interest: 'interest',
  duration: 'duration',
  expiration: 'expiration',
}
type FrontendOrderingOptions = keyof typeof columnIds | 'createdAt';

const sortingMap: Record<FrontendOrderingOptions, ProposalAndLoanListOrderByItem> = {
  ltv: 'ltv',
  interest: 'apr',
  usdAmount: 'usd_credit_value',
  duration: 'duration',
  expiration: 'expiration',
  collateral: 'collateral_name',
  createdAt: 'created_at',
}

const defaultSortingState: [{ id: 'createdAt'; desc: true }] = [
  {
    id: 'createdAt',
    desc: true,
  },
]
const sorting = ref<SortingState>(defaultSortingState)

const proposalFiltersStore = useProposalFilters()
const { filters } = storeToRefs(proposalFiltersStore)

const switchingTab = ref(false)

watch(() => props.type, (newType) => {
  switchingTab.value = true
  setTimeout(() => {
    switchingTab.value = false
  }, 200)
}, { immediate: true })

const injectedCountCtx = inject(injectedProposalsCountKeyName, {
  setInjectedProposalsCount: (v: number) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  injectedProposalsCount: null,
  setHasEmptyState: (v: boolean) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
})

const localFilters = ref<ColumnFiltersState>()

const parseFiltersToTableStruct = (filtersToParse: typeof filters) => {
  const res: ColumnFiltersState = []

  for (const key of Object.keys(filtersToParse.value)) {
    // if (key === 'ltv') {
    //   if (filters.value[key]) {
    //     res.push({
    //       id: key,
    //       value: filters.value[key],
    //     })
    //   }
    // }

    if (key === 'duration') {
      if (filters.value[key]) {
        res.push({
          id: key,
          value: filters.value[key],
        })
      }
    }
  }

  return res
}

const getSortingHandler = (e: Event, fn: any) => {
  return fn(e)
}

const stateSortingToOrderBy = (currentSorting: typeof sorting): ProposalAndLoanListOrderByItem[] => {
  const sortingVal = currentSorting.value[0]
  return [`${sortingVal.desc ? '-' : ''}${sortingMap[sortingVal.id]}` as ProposalAndLoanListOrderByItem]
}

const debouncedSearch = useThrottle<string | undefined>(q, 300)

const _getCommonReqParams = (): ProposalAndLoanListParams => {
  return {
    limit: fetchSize,
    orderBy: stateSortingToOrderBy(sorting),
    q: debouncedSearch.value || undefined,
    ...(isPwnSpace && !isStarknet && pwnSpaceConfig?.allowedAssetCategory && { collateralAssetCategory: [pwnSpaceConfig.allowedAssetCategory] }),
    // TODO add filtering of credit assets on pwn space
  }
}

const getReqParamsForListOnUserDashboard = (): ProposalAndLoanListParams => {
  const res = _getCommonReqParams()
  res.includeProposals = props.includeProposals
  res.includeLoans = props.includeLoans
  res.chains = selectedValues.value

  if (props.ignoreChains) {
    res.chains = enabledChains
  }

  const historyOnStatuses = [
    ProposalAndLoanListStatusesItem.NUMBER_0,
    ProposalAndLoanListStatusesItem.NUMBER_1,
    ProposalAndLoanListStatusesItem.NUMBER_2,
    ProposalAndLoanListStatusesItem.NUMBER_3,
    ProposalAndLoanListStatusesItem.NUMBER_4,
    ProposalAndLoanListStatusesItem.NUMBER_MINUS_1,
    ProposalAndLoanListStatusesItem.NUMBER_MINUS_2,
    ProposalAndLoanListStatusesItem.NUMBER_MINUS_3,
  ]

  const historyOffStatusesLend = [
    ProposalAndLoanListStatusesItem.NUMBER_1,
    ProposalAndLoanListStatusesItem.NUMBER_2,
  ]
  // todo: number 3 is not always correct as the lender could not have the loan token anymore
  const historyOffStatusesBorrow = [
    ProposalAndLoanListStatusesItem.NUMBER_1,
    ProposalAndLoanListStatusesItem.NUMBER_2,
    ProposalAndLoanListStatusesItem.NUMBER_3,
    ProposalAndLoanListStatusesItem.NUMBER_4,
  ]

  if (type.value === 'borrow') {
    res.isOffer = true
    res.statuses = showHistory.value ? historyOnStatuses : historyOffStatusesBorrow
  } else {
    res.isOffer = false
    res.statuses = showHistory.value ? historyOnStatuses : historyOffStatusesLend
  }

  if (type.value !== 'lend') {
    res.lenderAddress = userAddress.value
  } else {
    res.borrowerAddress = userAddress.value
  }

  if (props.thesisId) {
    res.relatedThesisId = props.thesisId
  }

  return res
}

const getReqParamsForOtherList = () => {
  const shouldIncludeLoans = !stateSortingToOrderBy(sorting).find((v) => v.endsWith('expiration'))

  const getStatuses = () =>
    showHistory.value
      ? [
        ProposalAndLoanListStatusesItem.NUMBER_0,
        ProposalAndLoanListStatusesItem.NUMBER_1,
        ProposalAndLoanListStatusesItem.NUMBER_2,
        ProposalAndLoanListStatusesItem.NUMBER_3,
        ProposalAndLoanListStatusesItem.NUMBER_4,
      ]
      : [ProposalAndLoanListStatusesItem.NUMBER_1]

  const getCollateralAssets = () => {
    const collateralAssets: string[] = []

    let assetsToParse: (AssetMetadata | AssetDetail)[] = []

    if (props.selectedCollateralFilterAssets || proposalFiltersStore.collateralAssetToFilter) {
      if (props.selectedCollateralFilterAssets) {
        assetsToParse = props.selectedCollateralFilterAssets
      } else if (proposalFiltersStore.collateralAssetToFilter) {
        assetsToParse = [proposalFiltersStore.collateralAssetToFilter]
      }
    } else {
      assetsToParse = filters.value.collateralAsset?.assets || []
    }

    for (const asset of assetsToParse) {
      if (asset) {
        if (asset?.tokenId) {
          collateralAssets.push(`${asset.chainId},${asset.address}`)
        } else {
          collateralAssets.push(`${asset.chainId},${asset.address}`)
        }
      }
    }
    return collateralAssets
  }

  const getCreditAssets = () => {
    const creditAssetsToFiler: string[] = []

    const assetsToParse = props.selectedCreditFilterAssets || filters.value.creditAsset?.assets || []

    for (const asset of assetsToParse) {
      if (asset) {
        if (asset.tokenId) {
          creditAssetsToFiler.push(`${asset.chainId},${asset.address}`)
        } else {
          creditAssetsToFiler.push(`${asset.chainId},${asset.address}`)
        }
      }
    }
    return creditAssetsToFiler
  }

  const getDurationParams = () => {
    if (filters.value.duration?.from === undefined) {
      return {}
    }

    return {
      durationInSeconds_min: filters.value.duration.from * 60 * 60 * 24,
      durationInSeconds_max: filters.value.duration.to * 60 * 60 * 24,
    }
  }

  const getAprParams = () => {
    if (filters.value.apr?.from === undefined) {
      return {}
    }

    return {
      apr_min: String(Number(filters.value.apr.from) * 100),
      apr_max: String(Number(filters.value.apr.to) * 100),
    }
  }

  const getLtvParams = () => {
    if (!filters.value.ltv?.from === undefined) {
      return {}
    }

    return {
      ltv_min: filters.value.ltv.from,
      ltv_max: filters.value.ltv.to,
    }
  }

  const res = _getCommonReqParams()

  if (type.value === 'borrow') {
    res.isOffer = true
  } else if (type.value === 'lend') {
    res.isOffer = false
  }

  const collateralAssets = getCollateralAssets()
  if (collateralAssets.length > 0) {
    res.collateralAsset = collateralAssets
  }

  const creditAssets = getCreditAssets()
  if (creditAssets.length > 0) {
    res.creditAsset = creditAssets
  }

  if (!res.creditAsset?.length && !res.collateralAsset?.length) {
    // only pass `chains` arg if neither creditAsset nor collateralAsset
    //  are passed, because if they are passed, the chains are filtered
    //  using the passed assets
    res.chains = selectedValues.value
  }

  return {
    ...res,
    ...getDurationParams(),
    ...getAprParams(),
    ...getLtvParams(),
    includeProposals: true,
    statuses: getStatuses(),
    includeLoans: shouldIncludeLoans,
    relatedThesisId: props.thesisId,
    collateralType: props.collateralType,
    includeUnverifiedCollateral: includeUnverifiedCollateral.value,
    includeUnverifiedCredit: includeUnverifiedCredit.value,
    ...(includeCollateralWithoutPrice.value !== undefined && { includeCollateralWithoutPrice: includeCollateralWithoutPrice.value }),
    ...(includeCreditWithoutPrice.value !== undefined && { includeCreditWithoutPrice: includeCreditWithoutPrice.value }),
  } as const satisfies ProposalAndLoanListParams
}

const filtersToReqParams = () => {
  if (props.includeLoans && props.includeProposals) {
    throw new Error('Only one of onlyLoan and onlyProposals can be true')
  }

  let res: ProposalAndLoanListParams

  if (userAddress.value) {
    // called from UserProposals.vue component that is used on proposal/loan tables in user dashboard
    res = getReqParamsForListOnUserDashboard()
  } else {
    // not related to the user dashboard proposal/loan tables, we want to apply filters here
    res = getReqParamsForOtherList()
  }
  proposalFiltersStore.proposalsTableRequestParams = res
  return res
}

const reqParams = computed(() => filtersToReqParams())

const proposalData = useProposalAndLoanListInfinite(reqParams, {
  query: {
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 15,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      return allPages.length * fetchSize
    },
    placeholderData: keepPreviousData,
  },
})

const flatData = computed(() => {
  return proposalData.data.value?.pages
    ? proposalData.data.value.pages
      .flatMap((page) => page.data?.results ?? [])
      .filter((item) => item.id !== props.proposalIdToFilterOut)
      .filter((item) => item.onChainId !== props.loanIdToFilterOut)
      .filter((item) => {
        if (!showHistory.value) {
          if (item.status === ProposalAndLoanListStatusesItem.NUMBER_MINUS_1) return false
          if (item.status === ProposalAndLoanListStatusesItem.NUMBER_MINUS_2) return false
        }
        // TODO after we filter out these almost empty fungible proposals on BE, then we can remove this filter
        if (item.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL) {
          const minCreditAmount = BigInt(item.creditData.maxAmount ?? '0') / 100n
          return BigInt(item.creditData.amount ?? '0') > minCreditAmount
        }
        return true
      })
    : []
})

const totalDBRowCount = computed(
  () => proposalData.data.value?.pages[proposalData.data.value?.pages.length - 1]?.data.count || 0,
)
const noProposals = computed(() => flatData.value.length === 0)
const hasAcceptableProposals = computed(() => flatData.value.some((v) => v.status === ProposalAndLoanListStatusesItem.NUMBER_1))
const isFetchingData = computed(() => proposalData.isFetching.value)
const isTabSwitching = computed(() => switchingTab.value)
const isUserLoggedIn = computed(() => !!userAddress.value)
const shouldHideNoProposalsBanner = computed(() => props.hideNoProposalsBanner)

const showNoProposalsBanner = computed(() => {
  const noValidProposals = noProposals.value || !hasAcceptableProposals.value
  const shouldShowBanner = !isFetchingData.value && !isTabSwitching.value && !isUserLoggedIn.value && !shouldHideNoProposalsBanner.value
  return noValidProposals && shouldShowBanner
})

const isRowDisabled = (row: Proposal) => {
  return isAssetFake(row.creditAsset) || isAssetFake(row.collateral)
}

watchEffect(() => {
  const newCount = flatData.value.length
  if ((newCount || newCount === 0) && proposalData.isSuccess.value) {
    if (injectedCountCtx) {
      if (q?.value?.length === 0 && injectedCountCtx.setHasEmptyState && !newCount) {
        injectedCountCtx.setHasEmptyState(true)
      } else {
        injectedCountCtx.setHasEmptyState(false)
      }

      injectedCountCtx.setInjectedProposalsCount(totalDBRowCount.value)
    }
  }
})

onMounted(() => {
  if (injectedCountCtx) {
    injectedCountCtx.setInjectedProposalsCount(totalDBRowCount.value)
  }
})

const columnHelper = createColumnHelper<Proposal>()

const isCheckForFungibleProposalCreditAmount = (proposal: Proposal) => (
  Boolean(proposal.type === V1_2ProposalType.SIMPLE_LOAN_FUNGIBLE_PROPOSAL &&
  proposal?.creditData?.maxAmount &&
  proposal?.isOffer &&
  proposal?.creditData?.creditPerCollateralUnit))

const columns = [
  columnHelper.accessor('collateral.name', {
    header: 'Collateral',
    id: columnIds.collateral,
    sortingFn: 'alphanumeric' as BuiltInSortingFn,
    size: 250,
    cell: (props) => {
      return h(ProposalCollateral, {
        address: props.row.original.collateral.address,
        thumbnail: props.row.original.collateral.thumbnailUrl ?? '',
        name: props.row.original.collateral?.symbol ?? props.row.original.collateral?.name ?? 'Default name', // todo: replace
        isVerified: props.row.original.collateral?.isVerified ?? false,
        chainId: props.row.original.collateral.chainId,
        proposal: props.row.original,
        collateralMetadata: props.row.original.collateral,
        proposer: props.row.original.proposer as Hex,
        category: props.row.original.collateral.category,
        type: props.row.original.type,
        isLoan: isLoan(props.row.original.type),
        isOffer: props.row.original.isOffer,
        isFake: isAssetFake(props.row.original.collateral),
        isHideHandForOwnProposal: isHideHandForOwnProposal.value,
      })
    },
  }),
  {
    accessorKey: 'collateral.amount',
    id: columnIds.usdAmount,
    enableSorting: true,
    header: (v) => {
      if (v.table.getState().globalFilter === 'borrow') {
        return 'Borrow Amount'
      } else if (v.table.getState().globalFilter === 'lend') {
        return 'Lend Amount'
      } else if (v.table.getState().globalFilter === 'all') {
        return 'Credit Amount'
      }
    },
    cell: (props) => {
      return h(AssetAmount, {
        assetAmount: props.row.original.creditData.amount,
        asset: props.row.original.creditAsset,
        isDisplaySymbol: true,
        withLink: false,
        isCheckForFungibleProposalCreditAmount: isCheckForFungibleProposalCreditAmount(props.row.original),
        proposer: props.row.original.proposer,
        loanContract: props.row.original.loanContract,
        isFake: isAssetFake(props.row.original.creditAsset),
        sourceOfFunds: props.row.original.sourceOfFunds,
      })
    },
    size: 130,
  },
  {
    id: columnIds.ltv,
    accessorKey: 'ltv',
    header: (props) => {
      return h(
        'div',
        {
          className: 'proposals-table__ltv-header',
        },
        [
          h('span', 'LTV'),
          h(TableSortingIndicator, {
            value: props.column.getIsSorted() || 'none',
          }),
          h(
            QuestionMarkTooltip,
            {
              tooltipPosition: 'right',
            },
            {
              default: () =>
                h(
                  'div',
                  {
                    className: 'proposals-table__ltv-tooltip--content',
                    style: {
                      textAlign: 'left',
                    },
                  },
                  [
                    h(
                      'div',
                      {
                        style: {
                          marginBottom: '0.5rem',
                        },
                      },
                      'LTV - Loan to Value ratio \n is an assessment of lending risk for lenders and is calculated using the estimated value of the asset and the desired terms of the borrower.',
                    ),
                    h('div', 'The lower the LTV is in %, usually the better it is for lenders.'),
                  ],
                ),
            },
          ),
        ],
      )
    },
    cell: (props) => {
      return h(LtvColored, {
        ltv: props.row.original.creditData.ltv || 0,
        isMissingLtvDash: true,
      })
    },
    size: 65,
  },
  {
    id: columnIds.interest,
    accessorKey: 'interest',
    header: 'APR',
    sortingFn: 'alphanumeric' as BuiltInSortingFn, // use built-in sorting function by name
    cell: (props) => {
      const proposalAmount = formatUnits(props.row.original.creditData.amount, props.row.original.creditAsset.decimals)

      return h(ProposalInterest, {
        chainId: props.row.original.creditAsset.chainId,
        creditAddress: props.row.original.creditAsset.address,
        collateralAddress: props.row.original.collateral.address,
        interest: props.row.original.creditData.apr,
        proposalId: props.row.original.id,
        amount: proposalAmount,
      })
    },
    size: 44,
  },
  {
    id: columnIds.duration,
    accessorFn: (v) => v.duration / 60 / 60 / 24, // Assuming duration is in seconds, converting to days
    header: 'Duration',
    sortingFn: 'alphanumeric' as BuiltInSortingFn, // use built-in sorting function by name
    cell: (v) => {
      return h(ProposalDuration, {
        duration: v.getValue(v.cell.id),
      })
    },
    filterFn: (row, columnId, filterValue) => {
      const rowValue = row.original.duration / 60 / 60 / 24 // Assuming duration is in seconds, converting to days

      const from = filterValue.from
      const to = filterValue.to

      return rowValue >= from && rowValue <= to
    },
    size: 70,
  },
  {
    accessorKey: 'expiration',
    id: columnIds.expiration,
    header: 'Expiration',
    cell: (props) => {
      return h(ProposalStatus, {
        proposal: props.row.original,
      })
    },
    size: 70,
  },
  {
    accessorKey: 'id',
    enableSorting: false,
    header: '',
    cell: (v) => {
      if (isLoan(v.row.original.type)) {
        return h(LoanStatusCell, {
          proposal: v.row.original,
        })
      }
      return h(ProposalActions, {
        buttonText: v.row.original.isOffer ? 'Borrow' : 'Lend',
        proposal: v.row.original,
      })
    },
    size: 100,
  },
]
const table = useVueTable<Proposal>({
  get data() {
    return flatData.value
  },
  state: {
    get sorting() {
      return sorting.value
    },
  },
  enableGlobalFilter: false,
  columns,
  enableSortingRemoval: true,
  onSortingChange: (updaterOrValue) => {
    let nextSortingValue = typeof updaterOrValue === 'function' ? updaterOrValue(sorting.value) : updaterOrValue

    if (nextSortingValue.length === 0) {
      nextSortingValue = defaultSortingState
    }

    sorting.value = nextSortingValue
  },
  manualSorting: true,
  getCoreRowModel: getCoreRowModel(),
  // getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
})

watchEffect(() => {
  table.setGlobalFilter(props.type)
})
const isFetching = computed(() => proposalData.isFetching.value)

const rows = computed(() => table.getRowModel().rows)

const rowVirtualizerOptions = computed(() => {
  return {
    get count() {
      return rows.value.length
    },
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => 73,
    getTotalSize: totalDBRowCount.value,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 20,
  }
})

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions)

const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems())

const fetchMoreOnBottomReached = () => {
  const container = tableContainerRef.value
  if (container) {
    const { scrollHeight, scrollTop, clientHeight } = container
    if (
      scrollHeight - scrollTop - clientHeight < 500 &&
      !proposalData.isFetching.value &&
      proposalData.hasNextPage.value &&
      rows.value.length < totalDBRowCount.value
    ) {
      proposalData.fetchNextPage()
    }
  }
}

onMounted(() => {
  fetchMoreOnBottomReached()
})

const customRefHandler = (node) => {
  if (node) {
    rowVirtualizer.value.measureElement(node)
  }
}

watchEffect(() => {
  localFilters.value = parseFiltersToTableStruct(filters)
})

watch([filters, sorting], (v) => {
  if (flatData.value.length > 0) {
    rowVirtualizer.value.scrollToIndex(0)
  }
})

const navigateToProposalPage = (proposal: Proposal, event: MouseEvent) => {
  const proposalRoute = {
    name: RouteName.ProposalDetail,
    params: {
      id: proposal.id,
    },
  }
  const loanRoute = {
    name: RouteName.RevampLoanDetail,
    params: {
      chainName: getChainName(proposal.chainId).toLowerCase(),
      loanTokenContractAddress: getLoanTokenAddressForProposalOrLoan(proposal.chainId, proposal.type as ProposalType),
      id: proposal.onChainId,
    },
  }

  if (event && (event.ctrlKey || event.metaKey)) {
    const resolvedRoute = router.resolve(isLoan(proposal.type) ? loanRoute : proposalRoute)
    window.open(resolvedRoute.href, '_blank')
    return
  }

  if (isLoan(proposal.type)) {
    router.push(loanRoute)
  } else {
    router.push(proposalRoute)
  }
}
</script>

<style scoped>
thead {
  background: var(--background-color);
}

th {
  text-align: left;
  font-weight: 400;
}

.container {
  margin: 1rem auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-family: var(--font-family-oxygen-mono);
  size: 14px;
  table-layout: fixed;

  tbody {
    tr {
      padding: 1rem 0;
      border-bottom: 1px solid var(--separator-color);

      &:hover {
        background: var(--gray-3);
        cursor: pointer;
      }
    }
  }
}

.proposals-table {
  @media only screen and (--mobile-viewport) {
    max-width: 24rem;
  }

  &__title {
    background-color: var(--background-color);
    padding: 0.5rem;

    font-family: var(--font-family-screener);
    font-size: 1.25rem;
    font-weight: 400;
  }

  &__header {
    position: sticky;
    top: 0;

    background-color: var(--background-color);
    z-index: 1;

    border-bottom: 1px dashed #828282;
  }

  &__header-cell {
    position: relative;
    padding-bottom: 0.5rem;
    white-space: nowrap;
    cursor: pointer;
    font-family: var(--font-family-screener);
    font-size: 14px;
    color: var(--gray-2);

    &--sorted {
      color: var(--text-color);
    }
  }

  &__ltv-header {
    display: flex;
    align-items: center;
  }

  &__ltv-tooltip--content {
    text-align: left !important;
  }

  &__row {
    &--disabled::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--fake-asset-overlay);
      z-index: var(--z-index-fake-asset-overlay);
    }
  }
}

.proposals-table--fade,
.proposals-table--fade-move {
  transition: opacity 0.5s;

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave-active {
    position: absolute;
  }
}
</style>
